export const pushNewValue = (reduxFormInput, value) => {
  const oldArray = reduxFormInput.value;
  const valueTrimmed = value?.trim ? value.trim() : value;
  const newArray = oldArray ? [...oldArray, valueTrimmed] : [valueTrimmed];
  reduxFormInput.onChange(newArray);
  return newArray;
};

export const updateValue = (reduxFormInput, value, valueIndex) => {
  const oldArray = reduxFormInput.value;
  const newArray = oldArray.map((oldValue, index) => {
    const oldValueTrimmed = oldValue?.trim ? oldValue.trim() : oldValue;
    const valueTrimmed = value?.trim ? value.trim() : value;
    return index === valueIndex ? valueTrimmed : oldValueTrimmed;
  });
  reduxFormInput.onChange(newArray);
  return newArray;
};

export const deleteValue = (reduxFormInput, indexToDelete) => {
  const oldArray = reduxFormInput.value;
  const newArray = oldArray.filter((_oldValue, index) => index !== indexToDelete);
  reduxFormInput.onChange(newArray);
  return newArray;
};

export const findIndexInFieldValue = (reduxFormInput, value) => {
  if (Array.isArray(reduxFormInput.value)) {
    return reduxFormInput.value.findIndex((inputValue) => inputValue === value);
  }
  return -1;
};

export const toggleFieldValue = (reduxFormInput, value) => {
  const valueIndex = findIndexInFieldValue(reduxFormInput, value);
  if (valueIndex !== -1) {
    return deleteValue(reduxFormInput, valueIndex);
  }

  return pushNewValue(reduxFormInput, value);
};
