import { get, head } from 'lodash';
import ActAmountComputator from './ActAmountComputator';
import { RamqExchange, RamqExchangeType } from '../../ramq/constants';
import { compareMostRecentStatementProductionDate } from '../computeExpectedAmountUtil';
import DefaultActAmountComputator from './ActAmountComputator.Default';

export default class PaidActAmountComputator extends ActAmountComputator {
  compute() {
    const paymentExplanationResponses = get(this.activity, 'ramqExchange', [])
      .filter(({ type }) => type === RamqExchangeType.PAYMENT_EXPLANATION_RESPONSE)
      .sort(compareMostRecentStatementProductionDate);

    if (paymentExplanationResponses.length) {
      const latestStatementProductionDate = (head(paymentExplanationResponses) as RamqExchange)
        .statementProductionDate!;

      return paymentExplanationResponses
        .filter(({ statementProductionDate }) => statementProductionDate === latestStatementProductionDate)
        .reduce((acc, current) => acc + (current.balance ? parseFloat(current.balance) : 0), 0)
        .toFixed(2);
    }

    return new DefaultActAmountComputator(this.activity).compute();
  }
}
