import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { adjustUnixWithTimeObject } from '../../../../shared/utils/dateTime/dateTimeUtils';
import { getTimeObjectFromUnix } from '../../../../utils/dateTimeUtils';
import CustomTimePicker from '../custom/CustomTimePicker';
import { selectSelectedDate } from '../../../containers/ActForm/selectors';

export class TimePickerSyncedWithDate extends React.Component {
  componentDidMount() {
    if (this.props.input.value) {
      this.syncValueWithDate();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.dateToSyncWith !== prevProps.dateToSyncWith && this.props.input.value) {
      this.syncValueWithDate();
    }
  }

  syncValueWithDate() {
    const { dateToSyncWith, currentStartTime } = this.props;
    const timeObject = getTimeObjectFromUnix(this.props.input.value);
    const adjustedDate = adjustUnixWithTimeObject(currentStartTime || dateToSyncWith, timeObject);

    this.props.input.onChange(adjustedDate);
  }

  render() {
    const { dateToSyncWith, ...props } = this.props;
    return <CustomTimePicker unixDateToSyncWith={dateToSyncWith} {...props} />;
  }
}

export const mapStateToProps = (_state, ownProps) =>
  createStructuredSelector({
    dateToSyncWith: (ownProps.dateSelector ?? selectSelectedDate)()
  });

TimePickerSyncedWithDate.propTypes = {
  dateToSyncWith: PropTypes.node,
  input: PropTypes.object.isRequired,
  currentStartTime: PropTypes.number,
  dateSelector: PropTypes.func
};

TimePickerSyncedWithDate.defaultProps = {
  currentStartTime: undefined,
  dateSelector: undefined,
  input: {}
};

export default connect(mapStateToProps, null)(TimePickerSyncedWithDate);
