import SURGERY_CONTEXT_ELEMENT from 'shared/ramq/requiredDetails/surgeryContextElement';
import Act from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';

export const exceptionnalSurgeryMutuallyExclusiveWithAwaitingSurgery = (act: Act) => {
  const hasCode5917 = act.codes?.find((code) => code.code === '5917');
  const hasAwaitingSurgeryContextElement = act.codes?.some((code) => code[SURGERY_CONTEXT_ELEMENT]);
  if (hasCode5917 && hasAwaitingSurgeryContextElement) {
    return {
      [actFormFields.codes]: {
        _error:
          'Le code 5917 ne peut être facturé en même temps qu’un code avec service rendu pour un patient en attente d’une chirurgie'
      }
    };
  }

  return {};
};

export default exceptionnalSurgeryMutuallyExclusiveWithAwaitingSurgery;
