const HOSPITAL_ACTIVITY_AREA_MAP = {
  externalClinic: '1',
  geriatricSection: '2',
  generalCare: '3',
  extendedCareProlonges: '4',
  extendedCareHebergement: '5',
  psychiatricSection: '8',
  coronaryUnit: '15',
  intensiveCareUnit: '16',
  emergencyClinic: '35',
  burnVictimsCenter: '43',
  heavyReadaptationUnit: '49',
  familyMedicineUnit: '52',
  chronicPainCenter: '53',
  neonatalUnit: '54',
  hepatologyUnit: '55'
};

export type ActivityArea = keyof typeof HOSPITAL_ACTIVITY_AREA_MAP;

export default HOSPITAL_ACTIVITY_AREA_MAP;
