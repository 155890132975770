import { defineMessages } from 'react-intl';
import COST_OF_STAY_FEE_TYPES from '../../../shared/ramq/domainValues/costOfStayFeeTypes';
import TRANSPORT_FEES_TYPES from '../../../shared/ramq/domainValues/transportFeesTypes';
import TRANSPORT_TYPES from '../../../shared/ramq/domainValues/transportTypes';

export default defineMessages({
  [Object.keys(TRANSPORT_TYPES)[0]]: {
    id: 'TransportType.autobus',
    defaultMessage: 'Autobus'
  },
  [Object.keys(TRANSPORT_TYPES)[1]]: {
    id: 'TransportType.covoiturage',
    defaultMessage: 'Covoiturage'
  },
  [Object.keys(TRANSPORT_TYPES)[2]]: {
    id: 'TransportType.taxi',
    defaultMessage: 'Taxi'
  },
  [Object.keys(TRANSPORT_TYPES)[3]]: {
    id: 'TransportType.train',
    defaultMessage: 'Train'
  },
  [Object.keys(TRANSPORT_TYPES)[4]]: {
    id: 'TransportType.traversier',
    defaultMessage: 'Traversier'
  },
  [Object.keys(TRANSPORT_TYPES)[5]]: {
    id: 'TransportType.vehiculeLoue',
    defaultMessage: 'Vehicule loué'
  },
  [Object.keys(TRANSPORT_TYPES)[6]]: {
    id: 'TransportType.vehiculePerso',
    defaultMessage: 'Véhicule personnel'
  },
  [Object.keys(TRANSPORT_TYPES)[7]]: {
    id: 'TransportType.volCommercial',
    defaultMessage: 'Vol Commercial'
  },
  [Object.keys(TRANSPORT_TYPES)[8]]: {
    id: 'TransportType.volPerso',
    defaultMessage: 'Vol Personnel'
  },
  [Object.keys(TRANSPORT_TYPES)[9]]: {
    id: 'TransportType.volNolise',
    defaultMessage: 'Vol Nolisé'
  },
  [Object.keys(COST_OF_STAY_FEE_TYPES)[0]]: {
    id: 'CostOfStayType.hotel',
    defaultMessage: 'Hotel'
  },
  [Object.keys(TRANSPORT_FEES_TYPES)[0]]: {
    id: 'TransportFeeType.rentalCar',
    defaultMessage: 'Location de véhicule'
  },
  [Object.keys(TRANSPORT_FEES_TYPES)[1]]: {
    id: 'TransportFeeType.gaz',
    defaultMessage: 'Gaz'
  },
  [Object.keys(TRANSPORT_FEES_TYPES)[2]]: {
    id: 'TransportFeeType.parking',
    defaultMessage: 'Stationnement'
  },
  [Object.keys(TRANSPORT_FEES_TYPES)[3]]: {
    id: 'TransportFeeType.general',
    defaultMessage: 'Général'
  }
});
