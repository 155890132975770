import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import AttachFile from '@material-ui/icons/AttachFile';
import { ButtonBase } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { useMemo, useRef } from 'react';
import getPicture from 'app/containers/ActForm/Camera/nativeCamera';
import { getFileEntryFromFileURI } from 'app/containers/Storage/cordova/cordovaLocalFile';
import { logDefault, logError } from 'shared/utils/plog';
import prepareImageFile from 'application/utilities/prepare-image-file';
import { usePostHog } from 'posthog-js/react';

export const styles = () => ({
  hiddenInput: {
    display: 'none'
  }
});

interface Props extends WithStyles<typeof styles> {
  fileSelectedHandler: (file: File) => void;
}

const FileUploader = ({ fileSelectedHandler, classes }: Props) => {
  const posthog = usePostHog();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pictureInputRef = useRef<HTMLInputElement>(null);

  const isAndroid = useMemo(() => {
    try {
      // @ts-ignore
      return window.cordova && device?.platform === 'Android';
    } catch (_error) {
      return false;
    }
  }, []);

  const handleCameraInputChange = (event) => {
    if (!event.currentTarget.value) return;

    posthog.capture('act_form_attachment', { type: 'camera' });

    if (isAndroid) {
      doUploadFile(event.currentTarget.files[0]);
    } else {
      prepareImageFile(event.currentTarget.files[0]).then(doUploadFile);
    }
  };

  const handlePaperClipInputChange = (event) => {
    posthog.capture('act_form_attachment', { type: 'file' });

    if (event.currentTarget.value) {
      doUploadFile(event.currentTarget.files[0]);
    }
  };

  const doUploadFile = (fileToUpload) => {
    if (fileToUpload) {
      fileSelectedHandler(fileToUpload);
    }
  };

  const handleCameraClick = async () => {
    if (isAndroid) {
      try {
        const fileURI = await getPicture();
        const fileEntry = await getFileEntryFromFileURI(fileURI);

        logDefault({
          type: 'file',
          text: 'FileUploader/Button/handleCameraClick',
          array: ['fileEntry is', fileEntry]
        });
        doUploadFile(fileEntry);
      } catch (e) {
        /* istanbul ignore next */
        logError({
          type: 'file',
          text: 'FileUploader/Button/handleClick',
          array: ['Error when taking/persisting/uploading file', e]
        });
      }
    } else {
      pictureInputRef.current?.click();
    }
  };

  const handlePaperClipClick = () => {
    fileInputRef.current?.click();
  };

  const renderCameraForCordova = () => {
    if (!window.cordova) return null;

    return (
      <>
        <input
          ref={pictureInputRef}
          type="file"
          id="upload-picture-input"
          name="pictureInput"
          accept="image/*"
          capture="environment"
          onChange={handleCameraInputChange}
          className={classes.hiddenInput}
        />
        <ButtonBase onClick={handleCameraClick} focusRipple>
          <AddPhotoIcon />
        </ButtonBase>
      </>
    );
  };

  const renderPaperClip = () => (
    <>
      <input
        ref={fileInputRef}
        type="file"
        id="upload-file-input"
        name="fileInput"
        accept="image/jpeg;application/pdf"
        onChange={handlePaperClipInputChange}
        className={classes.hiddenInput}
      />
      <ButtonBase onClick={handlePaperClipClick} focusRipple>
        <AttachFile />
      </ButtonBase>
    </>
  );

  return (
    <>
      {renderPaperClip()}
      {renderCameraForCordova()}
    </>
  );
};

export default withStyles(styles)(FileUploader);
