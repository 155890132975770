import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { getDateDayAbbreviation, getDateDayNumber } from '../../../../utils/dateTimeUtils';
import { isPerdiemsSelectionValid } from '../../../validations/training/claimingAmount';
import PerdiemCheckboxesWithClaimedAmountField from './fields/PerdiemCheckboxesWithClaimedAmount/PerdiemCheckboxesWithClaimedAmountField';

export const styles = (theme) => ({
  dayContainer: {
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    'borderBottom': `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      border: 'none'
    }
  },
  unitContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    paddingRight: theme.spacing(3)
  },
  dayInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: theme.spacing(7)
  }
});

export const validatePerdiems = (error) => (list) =>
  list === undefined || isPerdiemsSelectionValid(list) ? undefined : error;

export class DailyTrainingForm extends React.PureComponent {
  errorMessage = (<FormattedMessage id="Training.validations.maxSelectPerdiems" />);

  render() {
    const { fieldName, fieldIndex, fields, classes } = this.props;
    const { date } = fields.get(fieldIndex);

    return (
      <div className={classes.dayContainer}>
        <div className={classes.unitContainer}>
          <div className={classes.dayInfo}>
            <Typography variant="caption">{getDateDayAbbreviation(date)}</Typography>
            <Typography variant="body2">{getDateDayNumber(date)}</Typography>
          </div>
          <Field
            name={`${fieldName}.perdiems`}
            validate={validatePerdiems(this.errorMessage)}
            component={PerdiemCheckboxesWithClaimedAmountField}
          />
        </div>
      </div>
    );
  }
}

DailyTrainingForm.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired
};

export default withStyles(styles)(DailyTrainingForm);
