import { get } from 'lodash';
import { SingleLineField } from '../../../components/Form/Fields';

const summaryRender = (code) => [
  {
    name: 'autorisation',
    value: get(code, 'authorizationNumber', '-')
  }
];

const associatedFields = [
  {
    label: 'No Autorisation, non requis pour <18 ans',
    getName: (index) => `codes[${index}].authorizationNumber`,
    type: SingleLineField,
    notRequired: true
  }
];

const notRequiredNoAutorisation = { associatedFields, summaryRender };

export default notRequiredNoAutorisation;
