import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_DOCTOR,
  addDoctorToUserPreferenceFailure,
  GET_DOCTORS,
  getDoctorsPreferencesFailure,
  getDoctorsPreferencesSuccess,
  REMOVE_DOCTOR,
  removeDoctorFromUserPreferenceFailure,
  UPDATE_DOCTOR_COLLECTION,
  updateDoctorsFromUserPreferencesFailure,
  updateDoctorsFromUserPreferencesSuccess,
  GET_RESIDENTS,
  getResidentsPreferencesSuccess,
  getResidentsPreferencesFailure,
  UPDATE_RESIDENT_COLLECTION,
  updateResidentsFromUserPreferencesSuccess,
  updateResidentsFromUserPreferencesFailure,
  ADD_RESIDENT,
  addResidentToUserPreferenceFailure,
  REMOVE_RESIDENT,
  removeResidentFromUserPreferenceFailure
} from './actions';
import GetDoctorsFavoritesUseCase from '../usecases/GetDoctorsFavoritesUseCase';
import GetResidentsFavoritesUseCase from '../usecases/GetResidentsFavoritesUseCase';
import RemoveDoctorFromFavoritesUseCase from '../usecases/RemoveDoctorFromFavoritesUseCase';
import UpsertDoctorToFavoritesUseCase from '../usecases/UpsertDoctorToFavoritesUseCase';
import UpsertResidentToFavoritesUseCase from '../usecases/UpsertResidentToFavoritesUseCase';
import RemoveResidentFromFavoritesUseCase from '../usecases/RemoveResidentFromFavoritesUseCase';
import { updateCollectionInUserContext } from '../../firebase/document/documentSagas';
import { DOCTORS_PREFERENCES, RESIDENTS_PREFERENCES } from '../../../shared/collection/collectionConstants';

export default function* doctorsPreferencesSagas() {
  yield takeEvery(ADD_DOCTOR, UpsertDoctorToFavoritesUseCase.upsert, addDoctorToUserPreferenceFailure);
  yield takeEvery(REMOVE_DOCTOR, RemoveDoctorFromFavoritesUseCase.remove, removeDoctorFromUserPreferenceFailure);
  yield takeLatest(
    GET_DOCTORS,
    GetDoctorsFavoritesUseCase.get,
    getDoctorsPreferencesSuccess,
    getDoctorsPreferencesFailure
  );
  yield takeEvery(
    UPDATE_DOCTOR_COLLECTION,
    updateCollectionInUserContext,
    DOCTORS_PREFERENCES,
    updateDoctorsFromUserPreferencesSuccess,
    updateDoctorsFromUserPreferencesFailure
  );

  yield takeEvery(ADD_RESIDENT, UpsertResidentToFavoritesUseCase.upsert, addResidentToUserPreferenceFailure);
  yield takeEvery(REMOVE_RESIDENT, RemoveResidentFromFavoritesUseCase.remove, removeResidentFromUserPreferenceFailure);
  yield takeLatest(
    GET_RESIDENTS,
    GetResidentsFavoritesUseCase.get,
    getResidentsPreferencesSuccess,
    getResidentsPreferencesFailure
  );
  yield takeEvery(
    UPDATE_RESIDENT_COLLECTION,
    updateCollectionInUserContext,
    RESIDENTS_PREFERENCES,
    updateResidentsFromUserPreferencesSuccess,
    updateResidentsFromUserPreferencesFailure
  );
}
