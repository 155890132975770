export const DISABLE_SURGERY_MAIN_CODE_MEASUREMENT_ELEMENTS = 'disableMainSurgeryCodeMeasurementElements';
export const DETAILED_MIXTE_HOURS = 'detailedMixteHours';
export const FAVORITE_ACT_CODES_LIST_NAME = 'favoriteActCodesListName';
export const MIXTE_MAX_CODES_PER_DAY = 'maxMixteCodesPerDay';
export const LUMPSUM_MAX_CODES_PER_DAY = 'maxLumpSumCodesPerDay';
export const MIXTE_CODE_LIST_NAME = 'mixteCodeListName';
export const LUMPSUM_CODE_LIST_NAME = 'lumpSumCodeListName';
export const ACT_ADDITIONAL_CONTEXT_ELEMENTS = 'actAdditionalContextElements';
export const ACT_END_TIME_VISIBLE = 'actEndTimeVisible';
export const ACT_REMP_TIME_VISIBLE = 'actRempTimeVisible';
export const URGENT_CARE_DETAIL_REQUIRED = 'urgentCareDetailRequired';
export const ACT_DOCTOR_FIELD_VISIBLE = 'actDoctorFieldVisible';
export const CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR = 'canUseRamqContextElementsSelector';
export const CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR_FOR_SURGERY_CODES =
  'canUseRamqContextElementsSelectorForSurgeryCodes';
export const ACTIVITY_AREA_LIST = 'activityAreaList';
export const ACTIVITY_SECTOR_DEFAULT_OPEN = 'activitySectorDefaultOpen';
export const CODES_DATA_SPECIALTY_OVERRIDE = 'codesDataSpecialtyOverride';
export const CODES_DATA_SPECIALTY_OVERRIDE_INTERNIST = 'internistActCodesOverride';
export const CONTEXT_ELEMENT_LIST_NAME = 'contextElementListName';
export const REQUIRED_DETAILS_FOR_INTERNISTS = 'requiredDetailsForInternists';
export const REQUIRED_DETAILS_FOR_PNEUMOLOGISTS = 'requiredDetailsForPneumologists';
export const REQUIRED_DETAILS_FOR_SURGERY = 'requiredDetailsForSurgery';
export const LIMITED_INTENSIVE_CARE_DIAGNOSTIC_LIST_NAME = 'limitedIntensiveCareDiagnosticListName';
export const DUPLICATE_ANOTHER_DAY_ACTS = 'duplicateAnotherDayActivities';
export const SHOW_DIAGNOSTIC_IN_ACT_FORM = 'showDiagnosticInActForm';
export const DEFAULT_START_TIME = 'defaultStartTime';
export const VALIDATE_R1_SURGERY_SUPPLEMENTS_CODE = 'validateR1SurgerySupplementsCode';
export const SPECIALTY_CONTEXT_ELEMENTS_PRESET_LIST_NAME = 'specialtyContextElementsPresetListName';
export const REQUIRE_END_TIME_FOR_SURGERY_CODES = 'requireEndTimeForSurgeryCodes';
export const MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM = 'minimumDurationByMixteHalfPerdiem';
export const MINIMUM_DURATION_BY_MIXTE_PERDIEMS = 'minimumDurationByMixtePerdiems';
export const ACT_END_TIME_REQUIRED_FOR_SUPPLEMENT_BILLED_BETWEEN_7_AND_19 =
  'actEndTimeRequiredForSupplementBilledBetween7And19';
export const PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS = 'pmPerdiemEndHoursForOtherActs';
export const DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL = 'displaySurgeryRoomRequiredDetail';
export const MAXIMUM_AMOUNT_FOR_MAIN_CODE_ELIGIBLE_FOR_INCREASE = 'maximumAmountForMainCodeEligibleForIncrease';
export const DAILY_CHRONIC_PAIN_PLAN = 'dailyChronicPainPlan';
export const ACT_HISTORY_WITH_NAM = 'actHistoryWithNAM';
