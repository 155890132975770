import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { compose } from 'redux';

export const styles = () => ({
  tab: {
    maxWidth: 'none'
  },
  slideContainer: {
    flex: 1,
    WebkitOverflowScrolling: 'touch'
  }
});

const initialState = {
  tabIndex: 0
};

export class SwipeableTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleTabIndexChange = this.handleTabIndexChange.bind(this);
  }

  handleTabIndexChange(index) {
    this.setState({ tabIndex: index });
  }

  render() {
    const { tabs, swipeableViewsProps, classes } = this.props;
    const { tabIndex } = this.state;

    return (
      <>
        <Tabs
          value={tabIndex}
          onChange={(event, newIndex) => this.handleTabIndexChange(newIndex)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {tabs.map((tab) => (
            <Tab key={`${tab.key}-tab`} className={classes.tab} icon={tab.icon} />
          ))}
        </Tabs>
        <SwipeableViews
          id="slide-container"
          index={tabIndex}
          onChangeIndex={(newIndex) => this.handleTabIndexChange(newIndex)}
          className={classNames(classes.slideContainer, this.props.swipeableViewsClassName)}
          {...swipeableViewsProps}
        >
          {tabs.map((tab) => (
            <Fragment key={tab.key}>{tab.component}</Fragment>
          ))}
        </SwipeableViews>
      </>
    );
  }
}

SwipeableTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string,
      icon: PropTypes.node,
      component: PropTypes.node
    })
  ).isRequired,
  swipeableViewsClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  swipeableViewsProps: PropTypes.object
};

SwipeableTabs.defaultProps = {
  swipeableViewsClassName: '',
  swipeableViewsProps: undefined
};

export default compose(withMobileDialog(), withStyles(styles))(SwipeableTabs);
