import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';
import { getDateDenseWithWeekDay } from '../../../../../utils/dateTimeUtils';
import { currencyFormat } from '../../../../../utils/wordFormatUtilities';
import StatementTable from '../../../../components/Table/Statement/StatementTable/StatementTable';
import { computeExpectedAmountForActivities } from '../../../../../shared/amountComputation/computeExpectedAmount';
import { isLumpSum, isMixte } from '../../../../../shared/amountComputation/computeExpectedAmountUtil';
import computeMixteDaysIncome from './computeIncome/computeMixteDaysIncome';
import computeLumpSumDaysIncome from './computeIncome/computeLumpSumDaysIncome';

export const styles = () => ({
  noVerticalSpace: {
    paddingTop: 0,
    marginBottom: 0
  }
});

export const dateColumn = {
  name: 'Date',
  primaryText: (day) => getDateDenseWithWeekDay(moment(day.date))
};

const perdiemColumn = {
  name: 'Perdiems',
  align: 'right',
  primaryText: (day) => day.numberOfPerdiems
};

const hoursColumn = {
  name: 'Heures',
  align: 'right',
  primaryText: (day) => day.hoursWorked
};

const amountColumn = {
  name: 'Montant',
  align: 'right',
  primaryText: (day) => currencyFormat(day.amount)
};

export function PeriodsPaymentRequestTable({ activities, classes }) {
  return (
    <>
      <StatementTable
        className={classes.noVerticalSpace}
        title="Mixtes"
        titleSum={currencyFormat(computeExpectedAmountForActivities(activities.filter(isMixte)))}
        columns={[dateColumn, hoursColumn, perdiemColumn, amountColumn]}
        data={computeMixteDaysIncome(activities.filter(isMixte))}
      />
      <StatementTable
        className={classes.noVerticalSpace}
        title="Forfaitaires"
        titleSum={currencyFormat(computeExpectedAmountForActivities(activities.filter(isLumpSum)))}
        columns={[dateColumn, hoursColumn, amountColumn]}
        data={computeLumpSumDaysIncome(activities.filter(isLumpSum))}
      />
    </>
  );
}

PeriodsPaymentRequestTable.defaultProps = {
  activities: []
};

PeriodsPaymentRequestTable.propTypes = {
  activities: PropTypes.array
};

export default withStyles(styles)(PeriodsPaymentRequestTable);
