import { takeLatest, call, put } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import {
  GET_USERS,
  getUsersSuccess,
  SEARCH_USERS,
  getUsersByFiltersSuccess,
  getUsersError,
  getUsersByFiltersError
} from './actions';
import { searchUsers } from '../../infrastructure/api/api';

export function* getUsers() {
  try {
    const { data = [] } = yield call(searchUsers, {});
    yield put(getUsersSuccess(data));
  } catch (_e) {
    yield put(getUsersError());
  }
}

export function* getUsersByFilters({ filters = {} }) {
  try {
    if (isEmpty(filters)) {
      yield put(getUsersByFiltersSuccess(null));
    } else {
      const { data = [] } = yield call(searchUsers, filters);
      const userIds = data.map(({ id }) => id);
      yield put(getUsersByFiltersSuccess(userIds));
    }
  } catch (_e) {
    yield put(getUsersByFiltersError());
  }
}

export default function* usersSaga() {
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(SEARCH_USERS, getUsersByFilters);
}
