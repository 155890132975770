import ActCode34 from './ActCode34';
import ActCode36 from './ActCode36';
import ActCode39 from './ActCode39';
import ActCode9150 from './ActCode9150';
import ActCode9152 from './ActCode9152';
import ActCode9160 from './ActCode9160';
import ActCode15483 from './ActCode15483';
import ActCode19746 from './ActCode19746';
import ActCode42187 from './ActCode42187';
import ActCode42188 from './ActCode42188';
import ActCode42189 from './ActCode42189';
import ActCode42195 from './ActCode42195';
import ActCode6946 from './ActCode6946';

const ActCodes = {
  ActCode34,
  ActCode36,
  ActCode39,
  ActCode9150,
  ActCode9152,
  ActCode9160,
  ActCode15483,
  ActCode19746,
  ActCode42187,
  ActCode42188,
  ActCode42189,
  ActCode42195,
  ActCode6946
};

export default ActCodes;
