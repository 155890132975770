import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  deletePictureButton: {
    color: theme.palette.common.white,
    zIndex: 2
  }
});

export function DeletePictureButton({ classes, ...props }) {
  return (
    <IconButton
      onClick={() => {
        props.deleteSavedPicture();
        props.closePicturePreview();
      }}
      className={classes.deletePictureButton}
    >
      <DeleteIcon />
    </IconButton>
  );
}

DeletePictureButton.propTypes = {
  deleteSavedPicture: PropTypes.func.isRequired,
  closePicturePreview: PropTypes.func.isRequired
};

export default withStyles(styles)(DeletePictureButton);
