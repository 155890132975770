import PropTypes from 'prop-types';
import React from 'react';
import {
  ACTIVITY_PAYMENT_EXPLANATION_LINE_TYPE,
  ACTIVITY_PAYMENT_STATEMENT_LINE_TYPE
} from '../../../../../../shared/ramq/constants';
import DescriptionHeaderItem from '../../../../List/Item/DescriptionHeaderItem/DescriptionHeaderItem';
import ActivityResponseExplanationDivider from '../ActivityResponseExplanationDivider/ActivityResponseExplanationDivider';
import ActResponseLine from '../ActResponseLine/ActResponseLine';

function ActPaymentExplanationResponse({ ramqResponse }) {
  const explanationLines = ramqResponse.lines.filter((line) => line.type === ACTIVITY_PAYMENT_EXPLANATION_LINE_TYPE);
  const statementLines = ramqResponse.lines.filter((line) => line.type === ACTIVITY_PAYMENT_STATEMENT_LINE_TYPE);
  return (
    <>
      <DescriptionHeaderItem
        iconDescription="# ligne"
        textDescription="Explication"
        actionDescription="Montant calculé"
      />
      {statementLines.length > 0 && (
        <>
          <ActivityResponseExplanationDivider label="État de compte" />
          {statementLines.map((line) => (
            <ActResponseLine key={line.number} actResponseLine={line} />
          ))}
        </>
      )}
      {explanationLines.length > 0 && (
        <>
          <ActivityResponseExplanationDivider label="Explication" />
          {explanationLines.map((line) => (
            <ActResponseLine key={line.number} actResponseLine={line} />
          ))}
        </>
      )}
    </>
  );
}

ActPaymentExplanationResponse.propTypes = {
  ramqResponse: PropTypes.object.isRequired
};

export default ActPaymentExplanationResponse;
