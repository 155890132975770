import { formValueSelector } from 'redux-form';
import MULTI_ACT_FORM_NAME from '../ui/component/MultiActForm/constants';

export const selectMultiActFormSelector = (state) => state.multiActForm;

export const selectMultiActReduxFormSelector = (state) => {
  const stateForm = state.form;

  return stateForm[MULTI_ACT_FORM_NAME]?.values ?? {};
};

export const selectMultiActBillingType = () => (state) => formValueSelector(MULTI_ACT_FORM_NAME)(state, 'billingType');

export const selectMultiActInterval = () => (state) => formValueSelector(MULTI_ACT_FORM_NAME)(state, 'interval');

export const selectMultiActActivityArea = () => (state) =>
  formValueSelector(MULTI_ACT_FORM_NAME)(state, 'activityArea');

export const selectMultiActVisitCode = () => (state) => formValueSelector(MULTI_ACT_FORM_NAME)(state, 'visitCode');

export const selectMultiActCodes = () => (state) => formValueSelector(MULTI_ACT_FORM_NAME)(state, 'codes');

export const selectMultiActPlace = () => (state) => formValueSelector(MULTI_ACT_FORM_NAME)(state, 'place');
