import React from 'react';
import { StyleRulesCallback, Theme, WithStyles, withStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { selectUserProfileInContext } from '../../User/selectors';
import User from '../../../../shared/domain/user/User';
import isDefined from '../../../../shared/utils/isDefined';
import Alert from '../../../components/Alert/Alert';
import PeriodReportsForm from './PeriodReportsForm';
import AlertSeverity from '../../../ui/shared/severity/AlertSeverity';

export const styles: StyleRulesCallback<Theme, Props> = () =>
  ({
    pageCentered: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  doctor: User;
}

export const PeriodReportsPage: React.FunctionComponent<Props> = ({ doctor, classes }: Props) => {
  if (!isDefined(doctor?.email)) {
    return (
      <div className={classes.pageCentered}>
        <Alert
          noMargin
          showIcon
          title="Attention"
          severity={AlertSeverity.WARNING}
          message="L'email de l'utilisateur n'est pas défini."
        />
      </div>
    );
  }

  return <PeriodReportsForm doctor={doctor} />;
};

const mapStateToProps = createStructuredSelector({
  doctor: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps), withStyles(styles))(PeriodReportsPage) as React.FunctionComponent;
