import { pickBy } from 'lodash';

const getObjectKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

export const isEmpty = (object) => Object.keys(object).length === 0 && object.constructor === Object;

export const objectElementToLowerCase = (path) => (object) =>
  typeof object[path] === 'string' ? object[path].toLowerCase() : object[path];

export const isPlainObject = (input) => input && !Array.isArray(input) && typeof input === 'object';

const notUndefinedPredicate = (el) => el !== undefined;

export const sanitizeFromUndefined = (entity) => {
  if (Array.isArray(entity)) {
    return entity.filter(notUndefinedPredicate).map((el) => sanitizeFromUndefined(el));
  }

  if (entity !== null && typeof entity === 'object') {
    const sanitizedObjectOnFirstLevel = pickBy(entity, notUndefinedPredicate);

    for (const [key, value] of Object.entries(sanitizedObjectOnFirstLevel)) {
      sanitizedObjectOnFirstLevel[key] = sanitizeFromUndefined(value);
    }
    return sanitizedObjectOnFirstLevel;
  }

  return entity;
};

export default getObjectKeyByValue;

export const hasAll = (object, keys) => keys.every((key) => key in object);
