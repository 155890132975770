import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { StyledComponentProps, Theme } from '@material-ui/core';
import useTranslate from 'application/hooks/use-translate';
import FormElement from '../../../../../../components/Form/FormElement/FormElement';
import DynamicPeriodCodesSelection from '../../LegacyDayUnit/DynamicPeriodCodesSelector/DynamicPeriodCodesSelector';
import ActivityType from '../../../../../../../shared/core/activity/domain/ActivityType';
import { SelectorField } from '../../../../../../components/Form/Fields';
import { latestDispensaryAreaMap } from '../../../../../../activityArea/domain/mixte/data';
import HourMinuteInput from '../../../../../../components/Form/custom/HourMinuteInput/HourMinuteInput';

export const styles: StyleRulesCallback<Theme, Props> = ({ spacing, breakpoints }) => ({
  unitContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: spacing(1),
    paddingRight: spacing(3),
    [breakpoints.down('xs')]: {
      justifyContent: 'start',
      paddingLeft: 0
    }
  },
  codeSelection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  noFullFlex: {
    flex: '0 1 auto',
    minWidth: 92
  }
});

export interface Props extends StyledComponentProps {
  name: string;
  index: number;
  fields: any;
}

export const MixteDayUnitDetailCode: React.FunctionComponent<Props> = ({ name, index, fields, classes }: Props) => {
  const translate = useTranslate();
  return (
    <div className={classes!.unitContainer}>
      <span className={classes!.codeSelection}>
        <FormElement
          name={`${name}.code`}
          data-testid="code-input"
          className={classes!.noFullFlex}
          component={DynamicPeriodCodesSelection}
          activityType={ActivityType.MIXTE}
        />
      </span>
      <FormElement
        name={`${name}.duration`}
        data-testid="duration-input"
        label={translate('global.duration')}
        maxHour={10}
        minutesPrecision={15}
        component={HourMinuteInput}
      />
      <FormElement
        name={`${name}.activityArea`}
        data-testid="activityArea-input"
        label={translate('act-form.activity-area.activity-area')}
        component={SelectorField}
        list={latestDispensaryAreaMap}
      />
      <IconButton data-testid="remove-button" disabled={fields.length === 1} onClick={() => fields.remove(index)}>
        <ClearIcon />
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(MixteDayUnitDetailCode);
