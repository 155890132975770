import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import { calculateBalanceTotalAmount } from '../StatementReportUtils';
import { StatementLine, StatementLineOnAboveZeroAmount } from './StatementDoctorInfo';
import StatementSectionContainer from './StatementSectionContainer';

export function StatementBalances({ generalInformation }) {
  const balancesTotalAmount = calculateBalanceTotalAmount(generalInformation);
  const balanceTransferLines = generalInformation.balanceTransferLines.map(
    ({ groupTransferNumber, transferAmount }) => (
      <>{StatementLineOnAboveZeroAmount(`Provenant du compte de groupe #${groupTransferNumber}`, transferAmount)}</>
    )
  );
  const balanceLines = generalInformation.balanceLines.map(
    ({ postponedBalance, toBePostponedBalance, balanceChanges, paidAmount }) => (
      <>
        {StatementLineOnAboveZeroAmount('Solde à reporter', toBePostponedBalance)}
        {StatementLineOnAboveZeroAmount('Solde reporté', postponedBalance)}
        {StatementLineOnAboveZeroAmount('Modification du solde', balanceChanges)}
        {StatementLineOnAboveZeroAmount('Paiement reçu', paidAmount)}
      </>
    )
  );

  return (
    <StatementSectionContainer withDivider>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Soldes: ', currencyFormat(balancesTotalAmount))}
          secondaryLines={[...balanceTransferLines, ...balanceLines]}
        />
      </Grid>
    </StatementSectionContainer>
  );
}

StatementBalances.propTypes = {
  generalInformation: PropTypes.object.isRequired
};

export default StatementBalances;
