import { Button, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import Activity from 'shared/core/activity/domain/Activity';
import { useLocation } from 'react-use';
import moment, { Moment } from 'moment';
import Period from 'shared/periods/common/Period';
import { saveActWithoutPostProcessing } from 'app/containers/Act/actions';
import { Alert } from '@material-ui/lab';
import { activitiesCollectionRef } from 'app/firebase/collection/collectionReferences';
import User from 'shared/domain/user/User';
import Mixte from 'shared/core/activity/domain/Mixte';
import deviceDetector from '../../../../../components/utils/DeviceDetector';
import { selectUserProfileInContext } from '../../../../User/selectors';

const styles = () =>
  ({
    container: {
      display: 'flex',
      justifyContent: 'end',
      position: 'absolute',
      right: 0,
      top: 0
    },
    copyUserInfosButton: {
      color: '#fff'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  currentUserProfile: User;
  periodActivities: Activity[];
  saveActivity: (activity: Activity) => void;
}

function ExportImportActivities({ classes, currentUserProfile, periodActivities, saveActivity }: Props) {
  const location = useLocation();
  const [date, setDate] = React.useState<Moment>(moment());
  const [period, setPeriod] = React.useState<Period | null>(null);
  const [periodStart, setPeriodStart] = React.useState<string>('');
  const [periodEnd, setPeriodEnd] = React.useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);

  React.useEffect(() => {
    if (location.href) {
      const dateString = location.href.split('/').pop();
      if (dateString) {
        const momentDate = moment(parseInt(dateString, 10));
        setDate(momentDate);
        const period = Period.actBillingPeriodOfDate(momentDate);
        setPeriod(period);
        setPeriodStart(period.getStart().format('YYYY-MM-DD'));
        setPeriodEnd(period.getEnd().format('YYYY-MM-DD'));
      }
    }
  }, [location]);

  if (!deviceDetector.isBrowser() || !currentUserProfile) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadActivities = (activities: Activity[], fileName) => {
    const redactedActivities = activities.map((activity) => ({
      ...activity,
      nam: undefined,
      assets: undefined,
      attachments: undefined,
      billNumberMap: undefined
    }));

    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(redactedActivities, null, 2)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const exportDay = () => {
    const dayActivities = periodActivities.filter((activity) => {
      if (Object.hasOwn(activity, 'days')) {
        return (activity as Mixte).days?.find((day) => moment(day.date).isSame(date, 'day'));
      }
      if (activity.date) {
        return moment(activity.date).isSame(date, 'day');
      }
      return false;
    });
    downloadActivities(
      dayActivities,
      `${currentUserProfile.firstName} ${currentUserProfile.lastName} ${date.format('YYYY-MM-DD')}`
    );
    setSnackbarMessage('Journée exportée avec succès!');
    setShowSnackbar(true);
    handleClose();
  };

  const exportPeriod = () => {
    downloadActivities(
      periodActivities,
      `${currentUserProfile.firstName} ${currentUserProfile.lastName} ${period
        ?.getStart()
        .format('YYYY-MM-DD')}-${period?.getEnd().format('YYYY-MM-DD')}`
    );
    setSnackbarMessage('Période exportée avec succès!');
    setShowSnackbar(true);
    handleClose();
  };

  const importActivities = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (readerEvent: any) => {
        const content = readerEvent.target.result;
        const activities = JSON.parse(content);
        const importedActivityIds: String[] = [];
        activities.forEach((activity: Activity) => {
          const newDocumentId = activitiesCollectionRef().doc().id;
          const activityCopy = {
            ...activity,
            id: newDocumentId,
            userId: currentUserProfile.id,
            synchronized: true
          };
          saveActivity(activityCopy);
          importedActivityIds.push(activity.id);
        });
        setSnackbarMessage(`${importedActivityIds.length} activités importées avec succès!`);
        setShowSnackbar(true);
      };
    };
    input.click();
    handleClose();
  };

  return (
    <Restrict atLeastRole={Role.Developer}>
      <div className={classes.container}>
        <Button className={classes.copyUserInfosButton} onClick={handleClick}>
          Exporter / Importer
          <KeyboardArrowDown />
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={exportDay}>Journée {date.format('YYYY-MM-DD')}</MenuItem>
          <MenuItem onClick={exportPeriod}>
            Période {periodStart} au {periodEnd}
          </MenuItem>
          <MenuItem onClick={importActivities}>Importer</MenuItem>
        </Menu>
      </div>
      <Snackbar open={showSnackbar} onClose={() => setShowSnackbar(false)} autoHideDuration={3000}>
        <Alert severity="success">{snackbarMessage}</Alert>
      </Snackbar>
    </Restrict>
  );
}

export const mapStateToProps = createStructuredSelector({
  currentUserProfile: selectUserProfileInContext(),
  periodActivities: selectActivitiesInContext()
});

export const mapDispatchToProps = (dispatch: any) => ({
  saveActivity: (activity: Activity) => dispatch(saveActWithoutPostProcessing(activity))
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ExportImportActivities);
