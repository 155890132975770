import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const associatedFields: AssociatedField[] = [
  {
    label: 'Réaffectation autre lieu ?',
    getName: (index) => `codes[${index}].reassignmentOtherPlace`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  }
];

const reassignmentOtherPlace: RequiredDetail = {
  associatedFields
};

export default reassignmentOtherPlace;
