import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import { PROVINCE_LIST } from '../../../../../components/Form/constants';
import { ConditionalField, SelectorField, SingleLineField } from '../../../../../components/Form/Fields';
import OutsideProvinceAdditionalSteps from './OutsideProvinceAdditionalSteps';

const OutsideProvinceForm: React.FunctionComponent = () => (
  <CardContent>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        Identification du patient
      </Typography>
      <Divider />
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.lastName" required label="Nom du patient" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.firstName"
          required
          label="Prénom du patient"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.nam" label="Numéro d'assurance maladie" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.province"
          required
          label="Province"
          component={SelectorField}
          list={PROVINCE_LIST}
        />
      </Grid>

      <Grid item xs={12}>
        <ConditionalField conditionalOnFieldName="patientInformation.province">
          <OutsideProvinceAdditionalSteps />
        </ConditionalField>
      </Grid>
    </Grid>
  </CardContent>
);

export default OutsideProvinceForm;
