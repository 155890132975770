import moment from 'moment-timezone';

class NewStructureMixteConstraint {
  public static readonly NEW_MIXTE_RULES_DATE = moment('2021-10-01', 'YYYY-MM-DD');

  static isSatisfiedBy(activityType: string, date: number): boolean {
    return moment(date).isSameOrAfter(NewStructureMixteConstraint.NEW_MIXTE_RULES_DATE) && activityType === 'mixte';
  }
}

export default NewStructureMixteConstraint;
