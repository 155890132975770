import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import withStyles from '@material-ui/core/styles/withStyles';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import { WithStyles } from '@material-ui/core/styles';
import ActFile from 'app/containers/Storage/cordova/ActFile';
import { useDispatch, useSelector } from 'react-redux';
import { MULTI_ACT_FILE_TYPE, MULTI_ACT_INITIATE_NAM_OCR } from 'app/act/multiAct/adapters/fileUpload/constants';
import getPicture from 'app/containers/ActForm/Camera/nativeCamera';
import { logDefault } from 'shared/utils/plog';
import { getFileEntryFromFileURI } from 'app/containers/Storage/cordova/cordovaLocalFile';
import prepareImageFile from 'application/utilities/prepare-image-file';
import { selectUserIdInContext } from 'app/containers/User/selectors';
import { firestore } from 'server/Firebase';
import { selectActId } from 'app/containers/ActForm/selectors';
import { SYNCHRONIZED_PICTURE } from '../../Storage/cordova/constants';
import PictureBackground from './PictureBackground';
import { captureError } from '../../../../utils/sentry';
import { INITIATE_NAM_OCR } from '../actions';
import { usePostHog } from 'posthog-js/react';

export const styles = (theme) =>
  ({
    smallCardElement: {
      marginRight: theme.spacing(1),
      minWidth: '50px',
      flex: 1 / 8
    },
    hiddenInput: {
      display: 'none'
    },
    image: {
      'position': 'relative',
      'marginRight': theme.spacing(1),
      'width': theme.spacing(10),
      'height': theme.spacing(6),
      '&:hover': {
        zIndex: 1
      },
      '&:hover $imageBackdrop': {
        opacity: 0.15
      },
      '&:hover $searchIcon': {
        opacity: 1
      },
      '&:hover $checkIcon': {
        opacity: 0
      }
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity')
    },
    searchIcon: {
      position: 'absolute',
      color: theme.palette.common.white,
      opacity: 0,
      zIndex: 2,
      transition: theme.transitions.create('opacity')
    },
    checkIcon: {
      position: 'absolute',
      color: theme.palette.green[500],
      opacity: 1,
      zIndex: 2,
      transition: theme.transitions.create('opacity')
    }
  }) as const;

interface CameraProps extends WithStyles<typeof styles> {
  openOnMount?: boolean;
  togglePicturePreview: () => void;
  onFileReadyForUpload: (type: string, file: File, persistFileLocallyBeforeUpload: boolean) => void;
  savedPicture?: object;
  fileType?: string;
}

export const Camera = ({
  openOnMount = false,
  togglePicturePreview,
  onFileReadyForUpload,
  savedPicture,
  fileType,
  classes
}: CameraProps) => {
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const [fileEntry, setFileEntry] = useState<File | null>(null);
  const [usersToLog, setUsersToLog] = useState<string[]>([]);
  const userId = useSelector(selectUserIdInContext());
  const actId = useSelector(selectActId());

  useEffect(() => {
    firestore
      .collection('pictureLogs')
      .doc('users')
      .get()
      .then((doc) => {
        if (doc.exists && doc.data()) {
          setUsersToLog(doc.data().ids);
        } else {
          setUsersToLog([]);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  const logAction = useCallback(
    (action: string) => {
      if (usersToLog.includes(userId)) {
        firestore
          .collection('pictureLogs')
          .doc(userId)
          .collection('acts')
          .doc(actId)
          .set(
            {
              [action]: new Date()
            },
            { merge: true }
          );
      }
    },
    [actId, userId, usersToLog]
  );

  const fileInputRef = useRef<HTMLInputElement>(null);

  const isAndroid = useMemo(() => {
    try {
      // @ts-ignore
      return window.cordova && device?.platform === 'Android';
    } catch (_error) {
      return false;
    }
  }, []);

  useEffect(() => {
    if (openOnMount) {
      openCamera();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const persistImageFile = useCallback((imageFile) => {
    try {
      logAction('5- persistImageFile');
      onFileReadyForUpload(SYNCHRONIZED_PICTURE, imageFile, !!window.cordova /* persistFileLocallyBeforeUpload */);
    } catch (error) {
      captureError(error, {
        type: 'picture',
        text: 'Camera/Input/handleChange',
        array: ['Error when persisting/uploading picture', error]
      });
    }
    // onFileReadyForUpload is wrongly implemented in ActForm, we have to ignore it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateNamOcr = useCallback(
    (imageFile) => {
      try {
        logAction('8- initiateNamOcr');
        if (fileType === MULTI_ACT_FILE_TYPE) {
          dispatch({
            type: MULTI_ACT_INITIATE_NAM_OCR,
            payload: new ActFile(actId, imageFile)
          });
        } else {
          dispatch({
            type: INITIATE_NAM_OCR,
            actAttachment: new ActFile(actId, imageFile)
          });
        }
      } catch (error) {
        captureError(error, {
          type: 'namOcr',
          text: 'Camera/FileEntry/NamOcrDispatch',
          array: ['Error when dispatching initiateNamOcr', error]
        });
      }
    },
    [actId, dispatch, fileType, logAction]
  );

  useEffect(() => {
    if (!fileEntry) return;
    if (isAndroid) return;

    logAction('4- prepareImageFile');

    prepareImageFile(fileEntry).then((imageFile) => {
      persistImageFile(imageFile);
      initiateNamOcr(imageFile);
    });
  }, [fileEntry, dispatch, fileType, persistImageFile, initiateNamOcr, isAndroid, logAction]);

  const openCamera = async () => {
    logAction('1- openCamera');

    if (savedPicture) {
      togglePicturePreview();
      return;
    }

    if (isAndroid) {
      await takePictureWithCamera();
      return;
    }

    logAction('2- clickFileInput');
    fileInputRef.current?.click();
  };

  const takePictureWithCamera = async () => {
    let pictureFile;
    try {
      const fileURI = await getPicture();

      logDefault({
        type: 'picture',
        text: 'Camera/Button/handleClick',
        array: ['Called getPicture successfully, got fileURI', fileURI]
      });

      pictureFile = await getFileEntryFromFileURI(fileURI);

      logDefault({
        type: 'picture',
        text: 'Camera/Button/handleClick',
        array: ['Called getFileEntryFromFileURI successfully, got fileEntry', pictureFile]
      });
    } catch (cameraError) {
      captureError(cameraError, {
        type: 'picture',
        text: 'Camera/Button/handleClick',
        array: ['Error when taking/persisting/uploading picture', cameraError]
      });
    }

    if (!pictureFile) return;

    persistImageFile(pictureFile);
    initiateNamOcr(pictureFile);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value !== '') {
      logAction('3- handleChange');
      posthog.capture('NAM OCR', { action: 'Camera - Picture selected', actId });
      setFileEntry(event.currentTarget.files![0]);
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        id="upload-picture-input"
        name="picture"
        accept="image/*"
        capture="environment"
        onChange={handleChange}
        className={classes.hiddenInput}
      />
      <ButtonBase onClick={openCamera} focusRipple className={classes.image}>
        {savedPicture && <PictureBackground savedPicture={savedPicture} />}
        <span className={classes.imageBackdrop} />
        {savedPicture && <SearchIcon className={classes.searchIcon} />}
        {savedPicture && <CheckIcon className={classes.checkIcon} />}
        {!savedPicture && <AddPhotoIcon />}
      </ButtonBase>
    </>
  );
};

export default withStyles(styles)(Camera);
