import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';
import PeriodFormTransformer from './PeriodFormTransformer';

import LumpSumPeriodTransformer from './lumpSum/LumpSumPeriodTransformer';
import MixtePeriodTransformer from './mixte/MixtePeriodTransformer';

class PeriodTransformerFactory {
  static create(type: ActivityType): PeriodFormTransformer<any, any> {
    if (type === ActivityType.LUMP_SUM) {
      return new LumpSumPeriodTransformer();
    }

    return new MixtePeriodTransformer();
  }
}

export default PeriodTransformerFactory;
