import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import Section from 'application/components/form-controls/section';
import FieldContainer from 'application/components/form-controls/field-container';
import TextInput from 'application/components/form-controls/text-input';
import SelectInput from 'application/components/form-controls/select-input';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import Button from 'application/components/button';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import PracticeNumberInput from 'application/components/form-controls/practice-number-input';

interface Props {
  user: FirestoreUser;
  formIsValid: boolean;
  registerField: (field: string) => ValidatedFieldProps;
  onChange: (user: FirestoreUser) => void;
}

const SettingsGeneralInformations = ({ user, formIsValid: isValid, registerField, onChange }: Props) => {
  const translate = useTranslate('pages.settings.details.general-informations');
  const specialtyTranslate = useTranslate('global.specialty');

  return (
    <Section label={translate('title')}>
      <FieldContainer label={translate('user.title.label')} required>
        <SelectInput
          {...registerField('title')}
          value={user.title}
          options={[
            { value: 'Dr', label: translate('user.title.dr') },
            { value: 'Dre', label: translate('user.title.dre') }
          ]}
          onChange={(title) => onChange({ ...user, title })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.first-name')} required>
        <TextInput
          {...registerField('firstName')}
          value={user.firstName}
          onChange={(firstName) => onChange({ ...user, firstName })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.last-name')} required>
        <TextInput
          {...registerField('lastName')}
          value={user.lastName}
          onChange={(lastName) => onChange({ ...user, lastName })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.specialty')} required>
        <Restrict atLeastRole={Role.Agent} fallback={specialtyTranslate(user.specialty)}>
          <SelectInput
            {...registerField('specialty')}
            value={user.specialty}
            options={[
              { value: SpecialtyName.ANESTHESIOLOGIST, label: specialtyTranslate('anesthesiologist') },
              {
                value: SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST,
                label: specialtyTranslate('anesthesiologistIntensivist')
              },
              { value: SpecialtyName.CARDIOLOGIST, label: specialtyTranslate('cardiologist') },
              { value: SpecialtyName.GENERAL_SURGEON, label: specialtyTranslate('generalSurgeon') },
              { value: SpecialtyName.ORTHOPEDIC_SURGEON, label: specialtyTranslate('orthopedicSurgeon') },
              { value: SpecialtyName.ENDOCRINOLOGIST, label: specialtyTranslate('endocrinologist') },
              { value: SpecialtyName.INTERNIST, label: specialtyTranslate('internist') },
              { value: SpecialtyName.OTOLARYNGOLOGIST, label: specialtyTranslate('otolaryngologist') },
              { value: SpecialtyName.PNEUMOLOGIST, label: specialtyTranslate('pneumologist') }
            ]}
            onChange={(specialty) => onChange({ ...user, specialty })}
            required
          />
        </Restrict>
      </FieldContainer>
      <FieldContainer
        label={translate('user.practice-number.label')}
        helper={translate('user.practice-number.helper')}
        required
      >
        <PracticeNumberInput
          {...registerField('practiceNumber')}
          value={user.practiceNumber}
          onChange={(practiceNumber) => onChange({ ...user, practiceNumber })}
          required
        />
      </FieldContainer>
      <Section.Footer>
        <Button type="submit" disabled={!isValid} className="btn btn-primary">
          {translate('save')}
        </Button>
      </Section.Footer>
    </Section>
  );
};

export default SettingsGeneralInformations;
