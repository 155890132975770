import { ApolloError } from '@apollo/client';
import useToast from 'application/hooks/use-toast';
import useTranslateError from 'application/hooks/use-translate-error';
import { GraphQLFormattedError } from 'graphql';
import { useCallback } from 'react';

interface CustomGraphQLError extends GraphQLFormattedError {
  resource?: string;
  field?: string;
  errorCode?: string;
}

export default function useToastedApolloError() {
  const { addToast } = useToast();
  const translateError = useTranslateError();

  return useCallback(
    (error: ApolloError, icon?: string) =>
      error.graphQLErrors.forEach((graphQLError: CustomGraphQLError) => {
        addToast({
          icon,
          message: translateError(graphQLError),
          type: 'error'
        });
      }),
    [addToast, translateError]
  );
}
