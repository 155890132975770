import { intersection } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isNotAValidValue } from '../../../../validations/common/reduxFormValidation';
import ActWarningBox from './ActWarningBox';
import ActsWarningWithCodesAndStartTime from './ActWarningBox/ActsWarningWithCodesAndStartTime';
import mapCodesOnly from './utils';

function SameCodeWithoutNamWarning({ currentNam, currentCodes, previous24HoursActs }) {
  let actsWithSameCodes = [];
  if (isNotAValidValue(currentNam)) {
    actsWithSameCodes = previous24HoursActs
      .filter((act) => isNotAValidValue(act.nam))
      .filter((act) => intersection(mapCodesOnly(act.codes), mapCodesOnly(currentCodes)).length > 0);
  }

  return actsWithSameCodes.length > 0 ? (
    <ActWarningBox
      DetailsComponent={ActsWarningWithCodesAndStartTime}
      acts={actsWithSameCodes}
      message={
        <FormattedMessage id="act-form.nam-warning.same-code-no-nam" values={{ number: actsWithSameCodes.length }} />
      }
    />
  ) : null;
}

SameCodeWithoutNamWarning.propTypes = {
  previous24HoursActs: PropTypes.array.isRequired,
  currentCodes: PropTypes.array.isRequired,
  currentNam: PropTypes.string
};

SameCodeWithoutNamWarning.defaultProps = {
  currentNam: undefined
};

export default SameCodeWithoutNamWarning;
