import { get, last, sortBy } from 'lodash';
import { isNotErrorExchange } from '../../models/utils/activitiesUtils';
import {
  DEFERRED_PAYMENT_CONFIRMATION_TYPE,
  DEFERRED_PAYMENT_ERROR_TYPE,
  DEFERRED_PAYMENT_REQUEST_RESPONSE_TYPE
} from '../../ramq/constants';
import isDefined from '../../utils/isDefined';

export const isPaymentConfirmed = (ramqExchange) => ramqExchange.type === DEFERRED_PAYMENT_CONFIRMATION_TYPE;
const isDeferredPayment = (ramqExchange) =>
  isDefined(ramqExchange) && ramqExchange.type === DEFERRED_PAYMENT_REQUEST_RESPONSE_TYPE;
export const isPaymentExchange = (ramqExchange) =>
  ramqExchange.type === DEFERRED_PAYMENT_REQUEST_RESPONSE_TYPE ||
  ramqExchange.type === DEFERRED_PAYMENT_CONFIRMATION_TYPE ||
  ramqExchange.type === DEFERRED_PAYMENT_ERROR_TYPE;

export const getLastSuccessDeferredPayment = (period) =>
  last(sortBy(get(period, 'ramqExchange', []).filter(isPaymentConfirmed), 'createdOn'));

export const hasPendingPaymentValidation = (period) => {
  const ramqExchange = get(period, 'ramqExchange', []);
  const lastPaymentExchange = last(
    sortBy(ramqExchange.filter(isPaymentExchange).filter(isNotErrorExchange), 'createdOn')
  );
  return isDeferredPayment(lastPaymentExchange);
};
