/* On reprend du legacy code ici, on doit remove les safeguards */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect, useMemo, useState } from 'react';
import PaymentRequestHeader from 'app/containers/ReportsRoute/PaymentRequest/PaymentRequestHeader/PaymentRequestHeader';
import BillingTypeGroupablePaymentRequestReport from 'app/containers/ReportsRoute/PaymentRequest/PaymentRequestReport/BillingTypeGroupablePaymentRequestReport/BillingTypeGroupablePaymentRequestReport';
import { ACTIVITIES_ACT_TYPE } from 'shared/collection/collectionConstants';
import { fetchPaymentRequestForPool } from 'app/infrastructure/api/ReportAPI';
import { PeriodByActivities } from 'app/containers/ReportsRoute/shared/type';
import { getBillingPeriodFromDate } from 'shared/periods/periods';
import { prepareActivitiesData } from 'app/containers/ReportsRoute/PaymentRequest/paymentRequestUtils';
import BillingType from 'shared/domain/billing/model/BillingType';
import PrintAct from 'app/containers/Act/PrintAct/PrintActButton';
import { useParams } from 'react-router-dom';
import LoadingState from './components/reports/loading-state';

const GroupPaymentRequestsPage = () => {
  const { groupId } = useParams();

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodByActivities | null>(null);
  const [activitiesData, setActivitiesData] = useState<unknown | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!groupId || !selectedDate) return;

    setLoading(true);

    const period = getBillingPeriodFromDate(selectedDate) as PeriodByActivities;

    setSelectedPeriod(period);

    fetchPaymentRequestForPool(groupId, period[ACTIVITIES_ACT_TYPE].startDate.valueOf())
      .then((response: any) => {
        const activitiesWithUserData: unknown = response.reduce(
          (acc, { activities, ...userData }) =>
            acc.concat(activities.map((activity: object) => ({ ...activity, ...userData }))),
          []
        );

        setActivitiesData(activitiesWithUserData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [groupId, selectedDate]);

  const preparedActivities = useMemo(() => {
    if (!activitiesData || !selectedPeriod) return null;
    return prepareActivitiesData(activitiesData, selectedPeriod);
  }, [activitiesData, selectedPeriod]);

  const dataIsReady = !loading && selectedPeriod && preparedActivities;

  return (
    <div className="max-w-3xl overflow-hidden border">
      <PaymentRequestHeader
        undocked
        blockedPaymentType
        selectedDate={selectedDate}
        selectedPeriod={selectedPeriod || undefined}
        onChangeSelectedDate={setSelectedDate}
      />
      {dataIsReady ? (
        <BillingTypeGroupablePaymentRequestReport
          availableBillingTypes={[BillingType.POOL, BillingType.END]}
          period={selectedPeriod!}
          preparedActivities={preparedActivities}
          PrintButton={<PrintAct acts={preparedActivities[ACTIVITIES_ACT_TYPE]} />}
          pool
        />
      ) : (
        <LoadingState />
      )}
    </div>
  );
};

export default GroupPaymentRequestsPage;
