import { put, select } from 'redux-saga/effects';
import { selectPlacesPreferenceFromUser } from '../User/selectors';
import { saveFavoritePlace } from './actions';

export function* updatePlaceSignatorySaga({ signatory, placeId }) {
  const favoritePlaces = yield select(selectPlacesPreferenceFromUser());

  const placeToUpdate = favoritePlaces.filter((place) => place.id === placeId)[0];
  if (!placeToUpdate) return;

  const updatedPlace = { ...placeToUpdate, signatory };
  yield put(saveFavoritePlace(updatedPlace));
}
