import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core/styles';
import '@fontsource/roboto/latin.css';

const defaultTheme = createTheme();

const breakpoints = createBreakpoints({});
const themeOverride = {
  palette: {
    primary: {
      15: '#BCB3D7',
      25: '#ABA0CD',
      50: '#9A8DC3',
      100: '#897AB9',
      200: '#7967AF',
      300: '#6854A5',
      400: '#57419B',
      500: '#3B2C78',
      600: '#412A85',
      700: '#3B2678',
      800: '#34226B',
      900: '#2E1E5D',
      A100: '#9A8DC3',
      A200: '#7967AF',
      A400: '#57419B',
      A700: '#0d0563',
      contrastDefaultColor: 'light'
    },
    red: {
      200: '#EE655F',
      300: '#E95D59',
      400: '#E74B47',
      500: '#e53935',
      700: '#bc2f2c'
    },
    error: {
      300: '#E95D59',
      400: '#E74B47',
      500: '#e53935',
      700: '#bc2f2c'
    },
    green: {
      100: '#69CC75',
      300: '#3EBD4E',
      500: '#14af27',
      700: '#119020'
    },
    turquoise: {
      500: '#00ACC1',
      700: '#008D9E'
    },
    yellow: {
      5: '#fff4e5',
      300: '#fadc6c',
      500: '#d9e021',
      700: '#B2B81C',
      900: '#6b4900'
    }
  },
  overrides: {
    Dialog: {
      paper: {
        margin: defaultTheme.spacing(4)
      }
    },
    MuiDialogActions: {
      root: {
        paddingTop: defaultTheme.spacing(2),
        paddingBottom: defaultTheme.spacing(2)
      }
    },
    MuiDialogContent: {
      root: {
        [breakpoints.down('xs')]: {
          paddingLeft: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(2)
        }
      }
    },
    MuiChip: {
      root: {
        width: 'fit-content'
      }
    },
    MuiTabs: {
      root: {
        minHeight: defaultTheme.spacing(4)
      }
    },
    MuiTab: {
      root: {
        minHeight: defaultTheme.spacing(4)
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: defaultTheme.spacing(4.5)
      }
    },
    MuiIconButton: {
      root: {
        padding: 0
      }
    }
  }
};

export default createTheme(themeOverride);
