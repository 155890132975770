import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { groupBy, some } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';
import { currencyFormat } from '../../../../../utils/wordFormatUtilities';
import { isLumpSum, isMixte } from '../../../../../shared/amountComputation/computeExpectedAmountUtil';
import computeMixteDaysIncome, { computeExpectedAmountAndPerdiems } from './computeIncome/computeMixteDaysIncome';
import PeriodDayDetails from '../PoolPaymentRequestReport/PeriodDayDetails';
import { computeExpectedAmountForActivities } from '../../../../../shared/amountComputation/computeExpectedAmount';
import computeLumpSumDaysIncome, {
  computeExpectedAmountAndHoursWorked
} from './computeIncome/computeLumpSumDaysIncome';

export const styles = (theme) => ({
  'tableHead': {
    backgroundColor: theme.palette.primary[500]
  },
  'tableHeadCell': {
    color: theme.palette.common.white
  },
  '@media (max-width: 460px)': {
    denseTableCell: {
      'padding': '4px 10px',
      '&:first-child': {
        paddingLeft: '14px'
      },
      '&:last-child': {
        paddingRight: '14px'
      }
    }
  },
  'noPadding': {
    '&:last-child': {
      padding: '0'
    }
  },
  'noWrap': {
    whiteSpace: 'nowrap'
  },
  'userRowHeader': {
    backgroundColor: '#d7d7d7'
  },
  'insetShadow': {
    boxShadow: 'inset 0 0 4px 0 #d7d7d7',
    padding: '2px'
  }
});

function TableHeader(activities, title, classes) {
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell className={classes.tableHeadCell} classes={{ root: classes.denseTableCell }} size="small">
          <Typography variant="subtitle1" color="inherit">
            {title}
          </Typography>
        </TableCell>
        <TableCell
          colspan="2"
          className={classes.tableHeadCell}
          classes={{ root: classes.denseTableCell }}
          align="right"
          size="small"
        >
          <Typography variant="subtitle1" color="inherit">
            {currencyFormat(computeExpectedAmountForActivities(activities))}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

function ColumnHeader(columns, classes) {
  return (
    <TableHead>
      {columns.map(({ label, numeric }) => (
        <TableCell classes={{ root: classes.denseTableCell }} size="small" align={numeric ? 'right' : 'left'}>
          {label}
        </TableCell>
      ))}
    </TableHead>
  );
}

const TableContent = (activities, type) => {
  const rows = createTableRowsData(activities, type);

  return rows.map((row) => <PeriodDayDetails day={row} />);
};

const removeActivitiesDaysThatDontMatchCurrentDay = (activitiesByDoctors, day) =>
  activitiesByDoctors.map((activity) => {
    const doctorDays = activity.days.filter(({ date }) => date === day.date);
    return { ...activity, days: doctorDays };
  });

const createDoctorsTableData = (daysByActivitiesByDoctors, day) =>
  daysByActivitiesByDoctors.map((activitiesByDoctors) => {
    const modifiedActivities = removeActivitiesDaysThatDontMatchCurrentDay(activitiesByDoctors, day);
    const type = modifiedActivities[0]?.type;

    let computedActivities;

    switch (type) {
      case ActivityType.MIXTE:
        computedActivities = computeExpectedAmountAndPerdiems(modifiedActivities, day.date);
        break;

      case ActivityType.LUMP_SUM:
        computedActivities = computeExpectedAmountAndHoursWorked(modifiedActivities, day.date);
        break;

      default:
        computedActivities = {};
        break;
    }

    return {
      ...computedActivities,
      fullName: `${activitiesByDoctors[0].firstName} ${activitiesByDoctors[0].lastName}`,
      practiceNumber: activitiesByDoctors[0].practiceNumber
    };
  });

const findActivitiesWhereDaysMatchesCurrentDay = (activities, day) =>
  activities.filter(({ days: activityDays }) => some(activityDays, ['date', day.date]));

export const createTableRowsData = (activities, type) => {
  let computedDays;

  switch (type) {
    case ActivityType.MIXTE:
      computedDays = computeMixteDaysIncome(activities);
      break;

    case ActivityType.LUMP_SUM:
      computedDays = computeLumpSumDaysIncome(activities);
      break;

    default:
      computedDays = [];
      break;
  }

  return computedDays.map((computedDay) => {
    const activitiesWithMatchingDays = findActivitiesWhereDaysMatchesCurrentDay(activities, computedDay);
    const activitiesWithMatchingDaysByDoctors = Object.values(groupBy(activitiesWithMatchingDays, 'practiceNumber'));
    const doctors = createDoctorsTableData(activitiesWithMatchingDaysByDoctors, computedDay);
    return { doctors, ...computedDay };
  });
};

function MixteTable(activities, classes) {
  const columns = [
    { label: 'Jour', numeric: false },
    { label: 'Per diems', numeric: true },
    { label: 'Montant préliminaire', numeric: true }
  ];

  return (
    <Table>
      {TableHeader(activities, 'Mixtes', classes)}
      {ColumnHeader(columns, classes)}
      {TableContent(activities, ActivityType.MIXTE)}
    </Table>
  );
}

function LumpSumTable(activities, classes) {
  const columns = [
    { label: 'Jour', numeric: false },
    { label: 'Heures', numeric: true },
    { label: 'Montant préliminaire', numeric: true }
  ];

  return (
    <Table>
      {TableHeader(activities, 'Forfaitaires', classes)}
      {ColumnHeader(columns, classes)}
      {TableContent(activities, ActivityType.LUMP_SUM)}
    </Table>
  );
}

export function PoolPeriodsPaymentRequestTable({ activities, classes }) {
  const mixtes = activities.filter(isMixte);
  const lumpSums = activities.filter(isLumpSum);

  return (
    <>
      {mixtes.length > 0 && MixteTable(mixtes, classes)}
      {lumpSums.length > 0 && LumpSumTable(lumpSums, classes)}
    </>
  );
}

PoolPeriodsPaymentRequestTable.propTypes = {
  activities: PropTypes.object.isRequired
};

export default withStyles(styles)(PoolPeriodsPaymentRequestTable);
