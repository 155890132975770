import { FEMALE_GENDER, MALE_GENDER } from '../../../shared/ramq/domainValues/genders';

export const PROFESSIONAL_TYPE_LIST = [
  { label: 'Médecin', value: '1' },
  { label: 'Optométriste', value: '2' },
  { label: 'Infirmière praticienne spécialisée', value: '3' },
  { label: 'Travailleur social', value: '4' },
  { label: 'Ergothérapeute', value: '5' },
  { label: 'Sage-femme', value: '6' },
  { label: 'Physiothérapeute', value: '7' },
  { label: 'Psychologue', value: '8' },
  { label: 'Audiologiste', value: '9' },
  { label: 'Audioprothésiste', value: '10' },
  { label: 'Coroner', value: '11' },
  { label: 'Médecin de la commission', value: '12' },
  { label: 'Orthophoniste', value: '13' },
  { label: 'Pharmacien', value: '14' },
  { label: 'Médecin omnipraticien', value: '15' },
  { label: 'Médecin spécialiste', value: '16' },
  { label: 'Résident', value: '17' },
  { label: 'Dentiste', value: '18' },
  { label: 'Autres', value: '99' }
];

export const GENDER_LIST = [
  { label: 'Masculin', value: MALE_GENDER },
  { label: 'Féminin', value: FEMALE_GENDER }
];

export const PROVINCE_LIST = [
  { label: 'Nouveau-Brunswick', value: 'NB' },
  { label: 'Ontario', value: 'ON' },
  { label: 'Alberta', value: 'AB' },
  { label: 'Manitoba', value: 'MB' },
  { label: 'Colombie-Britannique', value: 'BC' },
  { label: 'Saskatchewan', value: 'SK' },
  { label: 'Terre-Neuve-et-Labrador', value: 'NL' },
  { label: 'Île-Du-Prince-Édouard', value: 'PE' },
  { label: 'Nouvelle-Écosse', value: 'NS' },
  { label: 'Yukon', value: 'YT' },
  { label: 'Territoires du Nord-Ouest', value: 'NT' },
  { label: 'Nunavut', value: 'NU' }
];

export const UNIVERSITY_LIST = [
  { label: 'Université de Sherbrooke', value: '50043', key: '1' },
  { label: 'Université Laval', value: '50013', key: '2' },
  { label: 'Université de Montréal', value: '50023', key: '3' },
  { label: 'Université McGill', value: '50033', key: '4' }
];
