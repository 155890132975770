import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import DailyTrainingForm from '../../../../../components/Training/DailyTrainingForm/DailyTrainingForm';

export const styles = () => ({
  textAlignCenter: {
    textAlign: 'center'
  }
});

export function PeriodField({ fields: reduxFormFields, meta: { touched, error, submitFailed }, classes }) {
  return (
    <>
      {reduxFormFields.map((fieldName, fieldIndex, fields) => (
        <DailyTrainingForm fieldName={fieldName} fieldIndex={fieldIndex} fields={fields} key={fieldName} />
      ))}
      {(touched || submitFailed) && error && (
        <FormHelperText className={classes.textAlignCenter} error>
          <FormattedMessage id={error} />
        </FormHelperText>
      )}
    </>
  );
}

PeriodField.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default withStyles(styles)(PeriodField);
