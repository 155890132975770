import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useFirestoreUserProfile, { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import useValidatedForm from 'application/hooks/use-validated-form';
import SettingsAccountingInformations from 'application/pages/settings/details/settings-accounting-informations';
import useToast from 'application/hooks/use-toast';
import useTranslate from 'application/hooks/use-translate';
import { setUserProfileInContext } from 'app/containers/User/actions';
import SettingsGeneralInformations from './details/settings-general-informations';
import SettingsCommunications from './details/settings-communications';
import SettingsAddress from './details/settings-address';
import SettingsSubscription from './details/settings-subscription';
import RAMQAccreditation from './details/ramq-accreditation';

interface UserReduxState {
  authentication: {
    user: {
      uid: string;
    };
  };
}

const SettingsDetailsPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector<UserReduxState, string>((state) => state.authentication.user.uid);
  const [user, updateUser] = useFirestoreUserProfile(userId);
  const [stagedUser, setStagedUser] = useState<FirestoreUser | null>(null);

  const { isValid: formIsValid, registerField } = useValidatedForm();

  const translate = useTranslate('pages.settings.details');

  const { addToast } = useToast();

  useEffect(() => {
    setStagedUser(user);
  }, [user]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!formIsValid || !stagedUser) return;

    updateUser(stagedUser)
      .then((updatedUser) => {
        dispatch(setUserProfileInContext(updatedUser));
        addToast({
          autoDismiss: true,
          message: translate('saved-success'),
          type: 'success'
        });
      })
      .catch(() => {
        addToast({
          autoDismiss: true,
          message: translate('saved-error'),
          type: 'error'
        });
      });
  };

  if (!stagedUser) return;

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <SettingsGeneralInformations
        user={stagedUser}
        formIsValid={formIsValid}
        registerField={registerField}
        onChange={(section) => setStagedUser({ ...stagedUser, ...section })}
      />
      <SettingsCommunications
        user={stagedUser}
        formIsValid={formIsValid}
        registerField={registerField}
        onChange={(section) => setStagedUser({ ...stagedUser, ...section })}
      />
      <SettingsAddress
        user={stagedUser}
        formIsValid={formIsValid}
        registerField={registerField}
        onChange={(section) => setStagedUser({ ...stagedUser, ...section })}
      />
      <SettingsAccountingInformations
        user={stagedUser}
        formIsValid={formIsValid}
        registerField={registerField}
        onChange={(section) => setStagedUser({ ...stagedUser, ...section })}
      />
      <RAMQAccreditation user={stagedUser} />
      {!window.cordova && <SettingsSubscription user={stagedUser} />}
    </form>
  );
};

export default SettingsDetailsPage;
