import SelectInput from 'application/components/form-controls/select-input';
import useTranslate from 'application/hooks/use-translate';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import React from 'react';

interface Props extends ValidatedFieldProps {
  value: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

const MonthSelectInput = (props: Props) => {
  const translate = useTranslate();

  return (
    <SelectInput
      options={[
        { value: '1', label: translate('january') },
        { value: '2', label: translate('february') },
        { value: '3', label: translate('march') },
        { value: '4', label: translate('april') },
        { value: '5', label: translate('may') },
        { value: '6', label: translate('june') },
        { value: '7', label: translate('july') },
        { value: '8', label: translate('august') },
        { value: '9', label: translate('september') },
        { value: '10', label: translate('october') },
        { value: '11', label: translate('november') },
        { value: '12', label: translate('december') }
      ]}
      {...props}
    />
  );
};

export default MonthSelectInput;
