import moment from 'moment-timezone';
import Act, { isAct } from '../../../../shared/domain/activity/act/model/Act';
import Activity from '../../../../shared/domain/activity/global/model/Activity';
import { codeIsInRole2 } from '../../../../shared/domain/activity/act/service/actCodeUtils';
import { isEmpty } from '../../../../shared/utils/objectManipulation';
import {
  ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS,
  ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS,
  COEUR_POUMON,
  GREATER_THAN_4_HOURS
} from '../../../../shared/ramq/contextElements/globalContextElements';
import { RAMQCodes, TimedAttributeKey } from '../../../../shared/ramq/RamqCodes';
import { ActCode, NatureType } from '../../../../shared/core/activity/domain/Act';
import { RAMQContextElements } from '../../../../shared/ramq/RamqContextElements';
import { getStore } from '../../../reduxStore/configureStore';
import { selectSpecialty } from '../../../containers/User/selectors';

export const contextElement1922Warning = (form: Partial<Activity>) => {
  const activity = form as Act;
  const contextElements = activity.contextElements ?? [];

  if (isEmpty(activity) || !isAct(activity) || activity.remp || contextElements?.includes(COEUR_POUMON)) {
    return [];
  }

  const act = activity as Act;
  const contextElement = RAMQContextElements.get('1922');

  if (
    contextElement &&
    RAMQContextElements.isEffectiveOn(contextElement, act.date) &&
    !hasGreaterThan4HoursContextElement(act) &&
    isDurationInHoursAtLeast(act, 8) &&
    act.codes.find((code) => codeIsApplicable(code, act))
  ) {
    activity.contextElements = [...(activity.contextElements || []), GREATER_THAN_4_HOURS];
    return ["L'élément 1922 sera ajouté automatiquement car la durée est de plus de 8 heures (règle 16)."];
  }

  return [];
};

const codeIsApplicable = (code: ActCode, act: Act) => {
  const userSpecialty = selectSpecialty()(getStore().getState());
  const ramqCode = RAMQCodes.get(code.code);

  return (
    ramqCode &&
    !code.isSupplement &&
    codeIsInRole2(code, act, userSpecialty) &&
    [NatureType.ANESTHESIOLOGY, NatureType.PDT, NatureType.SURGERY].includes(ramqCode.natureType) &&
    !RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isAddendum8, act.date)
  );
};

const hasGreaterThan4HoursContextElement = (act: Act) =>
  act.contextElements?.find((contextElement) =>
    [
      GREATER_THAN_4_HOURS,
      ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS,
      ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS
    ].includes(contextElement)
  );

const isDurationInHoursAtLeast = (act: Act, hours: number) => {
  if (!act.start || !act.end) {
    return false;
  }
  return moment(act.end).diff(moment(act.start), 'minutes') >= hours * 60;
};

export default contextElement1922Warning;
