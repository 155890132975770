import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import SingleSelectCheckboxGroup from '../../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { GENDER_LIST } from '../../../../../components/Form/constants';
import { DateField, SingleLineField } from '../../../../../components/Form/Fields';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import YES_NO_CHECKBOX_VALUES from '../../../../../ui/shared/form/constants';

const useStyles = makeStyles((theme) => ({
  disclaimerSection: {
    marginBottom: theme.spacing(2)
  },
  disclaimerText: {
    margin: theme.spacing(2, 0, 2, 0)
  }
}));

export function NoNamUrgentForm() {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <CardContent>
      <Grid item xs={12} sm={12}>
        <Typography gutterBottom variant="h6">
          {translate('no-nam.emergency.patient-identification')}
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={12} className={classes.disclaimerSection}>
        <Typography variant="body2" className={classes.disclaimerText}>
          {translate('no-nam.emergency.patient-identification-warning-message')}
        </Typography>
        <FormElement
          name="patientInformation.urgent.disclaimerAccepted"
          required
          label={translate('no-nam.emergency.confirmation-message')}
          list={YES_NO_CHECKBOX_VALUES}
          component={SingleSelectCheckboxGroup}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.lastName"
            label={translate('no-nam.emergency.patient-lastname')}
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.firstName"
            label={translate('no-nam.emergency.patient-firstname')}
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement name="patientInformation.gender" component={SingleSelectCheckboxGroup} list={GENDER_LIST} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.birthDate"
            label={translate('no-nam.date-of-birth')}
            deletable
            component={DateField}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography gutterBottom variant="h6">
            {translate('no-nam.emergency.patient-address')}
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.address"
            label={translate('no-nam.emergency.address')}
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.city"
            label={translate('no-nam.emergency.city')}
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.zipCode"
            label={translate('no-nam.emergency.postal-code')}
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.province"
            label={translate('no-nam.emergency.province')}
            component={SingleLineField}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default NoNamUrgentForm;
