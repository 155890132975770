import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Role from 'shared/domain/authentication/Role';

interface PotentiallyLoggedOutState {
  authentication?: {
    user?: {
      role?: Role;
    };
  };
}

const LobbyOutlet = () => {
  const userRole = useSelector<PotentiallyLoggedOutState, Role | undefined>(
    (state) => state.authentication?.user?.role
  );

  if (userRole) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default LobbyOutlet;
