import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  MULTI_ACT_FILE_READY_FOR_UPLOAD,
  MULTI_ACT_FILE_TYPE,
  MULTI_ACT_FILE_UPLOAD_ERROR,
  MULTI_ACT_INITIATE_NAM_OCR
} from '../constants';
import multiActFileReadyForUpload from './fileUploadStart';
import multiActFileUploadError from './fileUploadError';
import multiActFileSuccessfullyUploaded from './fileUploadSucceed';
import { UPLOAD_SUCCESS } from '../../../../../containers/Storage/actions';
import initiateMultipleNamOcr from '../../nams/sagas/initiateMultipleNamOcr';

export default function* multiActUploadFileSagas() {
  yield takeEvery(MULTI_ACT_FILE_READY_FOR_UPLOAD, multiActFileReadyForUpload);
  yield takeEvery(isActionUploadSuccessForMultiActPicture, multiActFileSuccessfullyUploaded);
  yield takeEvery(MULTI_ACT_FILE_UPLOAD_ERROR, multiActFileUploadError);
  yield takeLatest(MULTI_ACT_INITIATE_NAM_OCR, initiateMultipleNamOcr);
}
export const isActionUploadSuccessForMultiActPicture = (action) =>
  action.type === UPLOAD_SUCCESS && action.uploadTask.type === MULTI_ACT_FILE_TYPE;
