const styles = (theme) => ({
  wrapperPage: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    height: '100%',
    width: '100%'
  },
  mainPage: {
    'height': '100%',
    'backgroundColor': theme.palette.background.default,
    'display': 'flex',
    'flexDirection': 'column',
    'overflowY': 'hidden',
    'WebkitOverflowScrolling': 'touch',
    '& > *': {
      flex: '0 0 auto'
    },
    'width': '100%'
  },
  root: {
    display: 'none !important',
    mainPage: {
      display: 'none !important'
    }
  },
  mainPageContent: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column',
    alignItems: 'center'
  },
  onBottomMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white
  }
});

export default styles;
