import React from 'react';
import Icon from 'application/components/icon';
import Button from 'application/components/button';

interface Props {
  label: string;
  helper: string;
  icon?: string;
  buttonLabel: string;
  onClick: () => void;
}

const DangerZone = ({ label, helper, icon, buttonLabel, onClick }: Props) => (
  <div className="inline-flex w-full flex-col items-start justify-start overflow-hidden rounded border border-error bg-white shadow">
    <div className="inline-flex items-center justify-start gap-2 self-stretch p-4">
      <div className="flex h-6 w-6 items-center justify-center">
        {icon && <Icon icon={icon} className="fa-2x text-error" />}
      </div>
      <div className="text-xl text-error">{label}</div>
    </div>
    <div className="inline-flex items-start justify-start self-stretch pb-8 pl-12 pr-4">
      <div className="text-center font-['Roboto'] text-sm font-normal text-neutral-900">{helper}</div>
    </div>
    <div className="inline-flex items-center justify-end gap-2 self-stretch bg-neutral-100 p-4">
      <div className="flex items-start justify-start">
        <Button className="btn btn-error" onClick={onClick}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  </div>
);

export default DangerZone;
