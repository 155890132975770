import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import Section from 'application/components/form-controls/section';
import TextInput from 'application/components/form-controls/text-input';
import useTranslate from 'application/hooks/use-translate';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import React from 'react';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import MonthSelectInput from 'application/components/form-controls/month-select-input';
import Divider from 'application/components/form-controls/divider';
import DateInput from 'application/components/form-controls/date-input';

interface Props {
  user: FirestoreUser;
  formIsValid: boolean;
  registerField: (field: string) => ValidatedFieldProps;
  onChange: (user: FirestoreUser) => void;
}

const SettingsAccountingInformations = ({ user, formIsValid: isValid, registerField, onChange }: Props) => {
  const translate = useTranslate('pages.settings.details.accounting-informations');

  return (
    <Section label={translate('title')}>
      <Divider subtitle={translate('user.group.title')} />

      <FieldContainer label={translate('user.fiscal-year-start')}>
        <MonthSelectInput
          {...registerField('fiscalYearStart')}
          value={user.fiscalYearStart}
          onChange={(fiscalYearStart) => onChange({ ...user, fiscalYearStart })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.group.name')}>
        <TextInput
          {...registerField('groupName')}
          value={user.groupName}
          onChange={(groupName) => onChange({ ...user, groupName })}
        />
      </FieldContainer>
      <FieldContainer label={translate('user.group.number')}>
        <TextInput
          {...registerField('groupNumber')}
          value={user.groupNumber}
          onChange={(groupNumber) => onChange({ ...user, groupNumber })}
        />
      </FieldContainer>

      <Divider subtitle={translate('user.previous-agency.title')} />

      <FieldContainer label={translate('user.previous-agency.name')}>
        <TextInput
          {...registerField('agencyName')}
          value={user.agencyName}
          onChange={(agencyName) => onChange({ ...user, agencyName })}
        />
      </FieldContainer>
      <FieldContainer label={translate('user.previous-agency.number')}>
        <TextInput
          {...registerField('agencyNumber')}
          value={user.agencyNumber}
          onChange={(agencyNumber) => onChange({ ...user, agencyNumber })}
        />
      </FieldContainer>
      <FieldContainer label={translate('user.previous-agency.switch-date')}>
        <DateInput
          {...registerField('agencySwitchDate')}
          value={user.agencySwitchDate}
          type="date"
          onChange={(agencySwitchDate) => onChange({ ...user, agencySwitchDate })}
        />
      </FieldContainer>

      <Section.Footer>
        <Button type="submit" disabled={!isValid} className="btn btn-primary">
          {translate('save')}
        </Button>
      </Section.Footer>
    </Section>
  );
};

export default SettingsAccountingInformations;
