import Grid from '@material-ui/core/Grid';
import { flatten, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import biopsyNotRelatedToSurgicalAct from '../../../../codeSpecificLogic/requiredDetails/BiopsyNotRelatedToSurgicalAct/biopsyNotRelatedToSurgicalAct';
import ActCodeRequiredDetailsFactory from '../../../../codeSpecificLogic/requiredDetails/ActCodeRequiredDetails/ActCodeRequiredDetailsFactory';
import intensiveCareRole1StartTime from '../../../../codeSpecificLogic/requiredDetails/IntensiveCareRole1StartTime/IntensiveCareRole1StartTime';
import measurementElements from '../../../../codeSpecificLogic/requiredDetails/MeasurementElements/measurementElements';
import preSurgeryIntervention from '../../../../codeSpecificLogic/requiredDetails/PreSurgeryIntervention/preSurgeryIntervention';
import roleSelect from '../../../../codeSpecificLogic/requiredDetails/RoleSelect/roleSelect';

const getRequiredDetailsSummaryForCode = (code, act) =>
  ActCodeRequiredDetailsFactory.create(code, act).map((requiredDetail) =>
    requiredDetail.summaryRender ? requiredDetail.summaryRender(code) : []
  );

const getOptionalFieldSummaryForCode = (code) => [
  roleSelect.summaryRender(code),
  preSurgeryIntervention.summaryRender(code),
  biopsyNotRelatedToSurgicalAct.summaryRender(code),
  measurementElements.summaryRender(code),
  intensiveCareRole1StartTime.summaryRender(code)
];

const getSummaryForCode = (code, act) => ({
  code,
  summaries: flatten([...getRequiredDetailsSummaryForCode(code, act), ...getOptionalFieldSummaryForCode(code)])
});

export function RequiredDetailsSummary({ act }) {
  const requiredDetailsSummaries = isArray(act.codes) ? act.codes.map((code) => getSummaryForCode(code, act)) : [];

  return (
    <Grid container>
      {!isEmpty(requiredDetailsSummaries) &&
        requiredDetailsSummaries.map((requiredDetailSummary) => (
          <Grid key={requiredDetailSummary.code.id} item sm={6}>
            {requiredDetailSummary.code.code}
            {requiredDetailSummary.summaries.map((summary) => (
              <div key={summary.name}>{`${summary.name}: ${summary.value || ''}`}</div>
            ))}
          </Grid>
        ))}
    </Grid>
  );
}

RequiredDetailsSummary.propTypes = {
  act: PropTypes.object.isRequired
};

export default RequiredDetailsSummary;
