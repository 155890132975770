import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CODE_NATURE_TYPES from '../../../../shared/ramq/domainValues/natureTypes';
import muiTheme from '../../../../utils/muiTheme';

const styles = (theme) => ({
  activityContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2)
    },
    paddingRight: theme.spacing(9)
  },
  processingActivity: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    top: 0,
    display: 'flex'
  },
  progress: {
    marginRight: '10px'
  },
  activityHighlight: {
    backgroundColor: theme.palette.grey[300],
    borderBottom: `1px solid ${theme.palette.grey[600]}`
  },
  guard: {
    backgroundColor: muiTheme.palette.yellow[5]
  }
});

const shouldHighlight = (activityCodes) =>
  activityCodes &&
  activityCodes.some((code) => [CODE_NATURE_TYPES.administratif, CODE_NATURE_TYPES.forfait].includes(code.natureType));

function ActivityContainer({ onClick, className, classes, children, highlight, activityCodes, isGuard, ...props }) {
  return (
    <ListItem
      button
      onClick={onClick}
      className={classNames(
        classes.activityContainer,
        (highlight || shouldHighlight(activityCodes)) && classes.activityHighlight,
        isGuard && classes.guard,
        className
      )}
      {...props}
    >
      {props.disabled && (
        <div className={classes.processingActivity}>
          <CircularProgress color="primary" size={15} className={classes.progress} />
          <Typography variant="body2" align="center">
            <FormattedMessage id="activity.processing" />
          </Typography>
        </div>
      )}
      {children}
    </ListItem>
  );
}

ActivityContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  activityCodes: PropTypes.array,
  highlight: PropTypes.bool,
  isGuard: PropTypes.bool
};

ActivityContainer.defaultProps = {
  className: {},
  disabled: false,
  highlight: false,
  isGuard: false
};

export default withStyles(styles)(ActivityContainer);
