import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  paper: {
    height: '100%'
  },
  container: {
    padding: '12px 12px'
  }
});

export function CommentList({ comments, title, children, className, classes }) {
  const paperClassNames = classNames(classes.paper, className);
  return comments.length > 0 ? (
    <Paper className={paperClassNames}>
      <div className={classes.container}>
        {title}
        {children(comments)}
      </div>
    </Paper>
  ) : null;
}

CommentList.propTypes = {
  className: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  comments: PropTypes.array,
  children: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired
};

CommentList.defaultProps = {
  className: '',
  comments: []
};

export default withStyles(styles)(CommentList);
