import NoteIcon from '@material-ui/icons/Subject';
import React from 'react';
import PropTypes from 'prop-types';
import DetailsItem from '../Act/Details/DetailsItem/DetailsItem';

function DetailsNote({ activity }) {
  return activity.note ? <DetailsItem icon={<NoteIcon />} text={activity.note} /> : null;
}

DetailsNote.propTypes = {
  activity: PropTypes.object.isRequired
};

export default DetailsNote;
