import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Chip, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Alert from 'app/components/Alert/Alert';
import AlertSeverity from 'app/ui/shared/severity/AlertSeverity';
import { useLocation, useNavigate } from 'react-router-dom';
import { GROUPS_ROUTE, GROUP_USERS } from 'application/routes';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import { NumberField, SelectorField, SingleLineField } from '../../../../components/Form/Fields';
import SpecificBillingGroupField from '../../../../components/Form/BilingGroup/SpecificBillingGroupField/SpecificBillingGroupField';

export const styles = (theme) =>
  ({
    chip: {
      width: '75px',
      marginRight: theme.spacing(1)
    }
  }) as const;

export const monthList = [
  {
    value: '1',
    label: 'Janvier'
  },
  {
    value: '2',
    label: 'Février'
  },
  {
    value: '3',
    label: 'Mars'
  },
  {
    value: '4',
    label: 'Avril'
  },
  {
    value: '5',
    label: 'Mai'
  },
  {
    value: '6',
    label: 'Juin'
  },
  {
    value: '7',
    label: 'Juillet'
  },
  {
    value: '8',
    label: 'Août'
  },
  {
    value: '9',
    label: 'Septembre'
  },
  {
    value: '10',
    label: 'Octobre'
  },
  {
    value: '11',
    label: 'Novembre'
  },
  {
    value: '12',
    label: 'Décembre'
  }
];

export interface Props {
  groups: Array<{ id: string; name: string }>;
  pools: Array<{ id: string; name: string; number: string }>;
}

export const BillingGroupSection: React.FunctionComponent<Props & WithStyles<typeof styles>> = ({
  groups = [],
  pools = [],
  classes
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography gutterBottom variant="h6">
          Groupe de facturation
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="groupName" label="Groupe par défaut" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="groupNumber" label="Numéro du groupe par défaut" component={NumberField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="specificBillingGroups[0]"
          label="Groupe spécifique"
          component={SpecificBillingGroupField}
          readOnly={pools.length}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="fiscalYearStart"
          label="Début d'année financière"
          list={monthList}
          component={SelectorField}
        />
      </Grid>

      {groups.length + pools.length === 0 && (
        <Grid item xs={12}>
          <Alert
            message="Cet utilisateur n'est attributé à aucun groupe de gestion ni de pool. Pour l'ajouter dans un groupe, rendez-vous dans la section Groupes et Pools."
            noMargin
            severity={AlertSeverity.WARNING}
            showIcon
            title="Utilisateur orphelin"
          />
        </Grid>
      )}

      {groups.length > 0 && (
        <Grid item xs={12} sm={6}>
          <List dense>
            <ListItemText primary="Groupe(s)" />
            {groups.map((group) => (
              <ListItem
                key={group.id}
                className="link-hover link"
                onClick={() =>
                  navigate({ pathname: `/${GROUPS_ROUTE}/${group.id}/${GROUP_USERS}`, search: location.search })
                }
              >
                <ListItemText primary={group.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      {pools.length > 0 && (
        <Grid item xs={12} sm={6}>
          <List dense>
            <ListItemText primary="Pool(s)" />
            {pools.map((pool) => (
              <ListItem
                key={pool.id}
                className="link-hover link"
                onClick={() =>
                  navigate({ pathname: `/${GROUPS_ROUTE}/${pool.id}/${GROUP_USERS}`, search: location.search })
                }
              >
                <ListItemIcon>
                  <Chip size="small" className={classes.chip} label={pool.number} />
                </ListItemIcon>
                <ListItemText primary={pool.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(BillingGroupSection);
