import React, { MouseEventHandler, MouseEvent, useState } from 'react';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import { ClickAwayListener, Theme, WithStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export const styles: StyleRulesCallback<Theme, Props> = () => ({
  container: {
    display: 'flex',
    position: 'relative'
  },
  mainActionButtonWithoutRightBorderRadius: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  secondaryActionButton: {
    padding: '8px 6px',
    minWidth: '40px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  secondaryActionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export interface Props extends WithStyles<typeof styles> {
  mainAction: SplitButtonAction;
  secondaryActions?: SplitButtonAction[];
  loading?: boolean;
}

type SplitButtonAction = {
  label: string;
  callback: MouseEventHandler;
  disabled?: boolean;
};

export const SplitButton: React.FunctionComponent<Props> = ({
  mainAction,
  secondaryActions = [],
  loading,
  classes
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const onClose = () => setOpen(false);
  const onAction = (callback: MouseEventHandler) => (event: MouseEvent) => {
    setOpen(false);
    callback(event);
  };

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget);
    setOpen(!open);
  };

  const renderActions = () => (
    <>
      <Button
        data-testid="main-action"
        variant="contained"
        className={secondaryActions?.length > 0 ? classes.mainActionButtonWithoutRightBorderRadius : ''}
        onClick={mainAction.callback}
      >
        {mainAction.label}
      </Button>
      {secondaryActions?.length > 0 && (
        <>
          <Button
            variant="contained"
            data-testid="more-action"
            className={classes.secondaryActionButton}
            aria-describedby="popover-trigger"
            onClick={handleClick}
          >
            <ArrowDropDownIcon />
          </Button>
          <Popper open={open} id="popover-trigger" disablePortal placement="top-end" transition anchorEl={anchorEl}>
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={onClose}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper data-testid="secondary-actions" className={classes.secondaryActionsContainer}>
                    {secondaryActions.map((action: SplitButtonAction) => (
                      <Button
                        key={`secondary-action-${action.label}`}
                        onClick={onAction(action.callback)}
                        disabled={!!action.disabled}
                      >
                        {action.label}
                      </Button>
                    ))}
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </>
      )}
    </>
  );

  return (
    <div className={classes.container}>
      {!loading ? renderActions() : <CircularProgress color="primary" size={24} />}
    </div>
  );
};

export default withStyles(styles)(SplitButton);
