// Finally decided to reduce the width / height since it reduces considerably the size
// keeping a better picture quality than compression.
// Ex : targetHeight: 1000, targetWidth: 1200 --> ~37 kB
//      targetHeight: 1200, targetWidth: 1500 --> ~100 kB

const getPicture = (
  options = {
    targetHeight: 1200,
    targetWidth: 1500,
    destinationType: window.Camera.DestinationType.FILE_URI,
    encodingType: window.Camera.EncodingType.JPEG,
    correctOrientation: true
  }
) =>
  new Promise((resolve, reject) => {
    window.navigator.camera.getPicture(resolve, reject, options);
  });

export default getPicture;
