import 'whatwg-fetch';
import * as queryString from 'qs';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import PdfGenerationAPI from 'app/infrastructure/api/PdfGenerationAPI';
import {
  ACTIVITIES_LUMP_SUM_TYPE,
  ACTIVITIES_MIXTE_TYPE,
  ACTIVITIES_TRAINING_TYPE
} from '../../../shared/collection/collectionConstants';
import API_BASE_URL from '../../../shared/server/apiConfig';
import request from './apiUtils';
import { perror } from '../../../shared/utils/plog';

export function extractPDF(periodId, activityType, period) {
  let pdfUrl = '';
  if ([ACTIVITIES_LUMP_SUM_TYPE, ACTIVITIES_MIXTE_TYPE].includes(activityType)) {
    return PdfGenerationAPI.getPaymentRequestFormPdfUrl([periodId]);
  }
  if (activityType === ACTIVITIES_TRAINING_TYPE) {
    pdfUrl = `${API_BASE_URL}/createTrainingPdf/${periodId}`;
  }

  return request(pdfUrl, { method: 'POST', body: JSON.stringify(period) });
}

export async function fetchIntercomUserHash() {
  const url = `${API_BASE_URL}/createIntercomUserHash`;
  const response = await request(url, { method: 'GET' });
  return response.json();
}

export async function generateUserInformationRequestPdf(userId) {
  const CREATE_USER_INFORMATION_REQUEST_PDF = `${API_BASE_URL}/createUserInformationRequestPdf/private/${userId}`;

  const response = await request(CREATE_USER_INFORMATION_REQUEST_PDF, { method: 'POST' });
  return response.json();
}

export function billRAMQ(activityId, options: { billNumber?: number; manualXml: string }) {
  const BILL_RAMQ_URL = `${API_BASE_URL}/directPayments/bill`;
  return request(BILL_RAMQ_URL, { method: 'POST', body: JSON.stringify({ id: activityId, ...options }) });
}

export function previewXml(id, type) {
  if ([ActivityType.LUMP_SUM, ActivityType.MIXTE].includes(type)) {
    const PREVIEW_XML_URL = `${API_BASE_URL}/deferredPayments/generateXml/${id}`;
    return request(PREVIEW_XML_URL, { method: 'POST' }).then((result) => result.json());
  }
  const PREVIEW_XML_URL = `${API_BASE_URL}/directPayments/generateXml`;
  return request(PREVIEW_XML_URL, { method: 'POST', body: JSON.stringify({ id }) }).then((result) => result.json());
}

export function deferredBillRAMQ(periodId) {
  const DEFERRED_BILL_URL = `${API_BASE_URL}/deferredPayments/${periodId}`;
  return request(DEFERRED_BILL_URL, { method: 'POST' });
}

export function cancelRAMQ(activityId, billNumber) {
  const CANCEL_RAMQ_URL = `${API_BASE_URL}/directPayments/cancel`;
  return request(CANCEL_RAMQ_URL, { method: 'POST', body: JSON.stringify({ id: activityId, billNumber }) });
}

export function searchActivities(searchEngineQuery, searchOptions = {}) {
  const SEARCH_URL = `${API_BASE_URL}/search/`;
  return request(SEARCH_URL, {
    method: 'POST',
    body: JSON.stringify({
      searchEngineQuery,
      searchOptions
    })
  }).then((result) => result.json());
}

export function searchUsers(userFilters) {
  const SEARCH_URL = `${API_BASE_URL}/users?${queryString.stringify(userFilters, { skipNulls: true, indices: false })}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export function userSearch(userFilters) {
  const SEARCH_URL = `${API_BASE_URL}/users/search?${queryString.stringify(userFilters, {
    skipNulls: true,
    indices: true
  })}`;
  return request(SEARCH_URL, { method: 'GET' })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}

export function updateUserEmail({ id, email }) {
  const UPDATE_URL = `${API_BASE_URL}/users/${id}/email`;
  return request(UPDATE_URL, { method: 'PUT', body: JSON.stringify({ email }) })
    .then((result) => result.json())
    .catch((error) => {
      perror(error);
      return error;
    });
}
