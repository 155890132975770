import React from 'react';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import MaskedInput from 'application/components/form-controls/masked-input';

interface Props extends ValidatedFieldProps {
  value: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

const PhoneInput = (maskedInputProps: Props) => (
  <MaskedInput mask="(___) ___-____" placeholder="(514) 555-1234" {...maskedInputProps} />
);

export default PhoneInput;
