import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';
import { AssociatedField, RequiredDetail } from '../requiredDetail.type';

const associatedFields: AssociatedField[] = [
  {
    label: 'En direct / En différé',
    getName: (index) => `codes[${index}].broadcastDelay`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: [
      { label: 'En direct', value: 'live' },
      { label: 'En différé', value: 'deferred' }
    ]
  }
];

const broadcastDelay: RequiredDetail = { associatedFields };

export default broadcastDelay;
