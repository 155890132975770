import { isEmpty, some } from 'lodash';
import moment from 'moment-timezone';
import {
  dateIsDuringPlaceHolidayOrWeekEnd,
  dateIsDuringWeekend
} from '../../../../../shared/utils/dateTime/dateValidations';
import Act from '../../../../../shared/domain/activity/act/model/Act';
import {
  doctorIsOnIntensiveCareUnitSupportOnThisDay,
  hasIntensiveCareOnGuardCodeAndIsNotCanceled
} from '../../../../containers/Act/SaveAct/intensiveCareOptimizations/utils';
import { selectActivitiesInContext } from '../../../../containers/Activities/selectors';
import { selectSpecialty } from '../../../../containers/User/selectors';
import { getStore } from '../../../../reduxStore/configureStore';
import codesThatCannotBeBillWithEmergencyContextElement from '../../../../../shared/domain/activity/act/act-codes-that-cannot-be-billed-with-emergency-context-element';
import SpecialtyName from '../../../../../shared/core/doctor/domain/SpecialtyName';
import isDefined from '../../../../../shared/utils/isDefined';
import { isActBetween0And7 } from '../../../../../shared/act/domain/utils';

export const isExceptionalEmergencyDuringOffWeekPeriod = (act: Act): boolean => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  return !(
    isEmpty(act) ||
    isEmpty(act.place) ||
    (act.codes || []).length === 0 ||
    !isDefined(act?.start) ||
    hasIntensiveCareOnGuardCodeAndIsNotCanceled(act) ||
    userSpecialty === SpecialtyName.ANESTHESIOLOGIST ||
    userSpecialty === SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST ||
    isActMadeDuringNormalWorkingDay(act) ||
    actHasCodesThatCantBeBilledWithEmergencyContextElement(act)
  );
};

const doctorsTodayActivities = (act) => {
  const activitiesInContext = selectActivitiesInContext()(getStore().getState()) || [];

  return activitiesInContext.filter((activityInContext) => {
    const dayBeforeAt7 = moment(act.date).subtract(1, 'day').hours(7);
    const todayAt7 = moment(act.date).hours(7);

    if (isActBetween0And7(act)) {
      return moment(activityInContext.start).isBetween(dayBeforeAt7, todayAt7, undefined, '[)');
    }

    return activityInContext.date === act.date;
  });
};

const isActMadeDuringNormalWorkingDay = (act) => {
  if (
    [0, 6].includes(moment(act.start).weekday()) ||
    isDuringWeekendOrPlaceDayOff(act) ||
    !isDuringWorkingNormalTimeRange(act)
  ) {
    return false;
  }

  return true;
};

const isDuringWorkingNormalTimeRange = (act) => {
  const { place, start } = act;
  const todaysActivities = doctorsTodayActivities(act);
  const isDoctorIsOnIntensiveCareUnitSupportOnThisDay = doctorIsOnIntensiveCareUnitSupportOnThisDay(todaysActivities);
  const billByAct = place?.billByAct || false;

  return moment(start).isBetween(
    moment(start).set({ hours: 7, minutes: 0, seconds: 0, milliseconds: 0 }),
    moment(start).set({
      hours: isDoctorIsOnIntensiveCareUnitSupportOnThisDay || billByAct ? 19 : 21,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    }),
    undefined,
    '[)'
  );
};

const isActMadeBetweenIntensiveCareUnitSupportPeriod = (start) => {
  const isDoctorIsOnIntensiveCareUnitSupportOnThisDay = doctorIsOnIntensiveCareUnitSupportOnThisDay(
    doctorsTodayActivities(moment(start).startOf('day').valueOf())
  );

  if (!isDoctorIsOnIntensiveCareUnitSupportOnThisDay) {
    return false;
  }

  return (
    isDoctorIsOnIntensiveCareUnitSupportOnThisDay &&
    moment(start).isBetween(
      moment(start).set({ hours: 7, minutes: 0, seconds: 0, milliseconds: 0 }),
      moment(start).set({ hours: 19, minutes: 0, seconds: 0, milliseconds: 0 }),
      undefined,
      '[)'
    )
  );
};

const isDuringWeekendOrPlaceDayOff = (act) => {
  const { start, place } = act;
  const todaysActivities = doctorsTodayActivities(act);
  const isDoctorIsOnIntensiveCareUnitSupportOnThisDay = doctorIsOnIntensiveCareUnitSupportOnThisDay(todaysActivities);
  const dateIsDuringWeekendOrDayOff = dateIsDuringWeekend(start) || dateIsDuringPlaceHolidayOrWeekEnd(start, place);
  const lowerBound = moment(act.date).hours(7);
  const upperBound = moment(act.date).hours(19);

  if (
    isDoctorIsOnIntensiveCareUnitSupportOnThisDay &&
    dateIsDuringWeekendOrDayOff &&
    moment(act.start).isBetween(lowerBound, upperBound, undefined, '(]')
  ) {
    return false;
  }

  if (!dateIsDuringWeekendOrDayOff) {
    return false;
  }

  if (isActMadeBetweenIntensiveCareUnitSupportPeriod(start)) {
    return false;
  }

  return true;
};

const actHasCodesThatCantBeBilledWithEmergencyContextElement = ({ codes }): boolean =>
  some(codes, ({ code }) => codesThatCannotBeBillWithEmergencyContextElement.includes(code));
