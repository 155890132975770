import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import Collapser from '../../../../../Collapser/Collapser';
import CollapserContent from '../../../../../Collapser/CollapserContent';
import CollapserHeader from '../../../../../Collapser/CollapserHeader';
import DateSummary from '../../../ActivityResponse/DateSummary/DateSummary';
import RamqInfoIcon from '../../../RamqInfoIcon/RamqInfoIcon';

export const styles = (theme) => ({
  header: {
    backgroundColor: theme.palette.red[400],
    flexDirection: 'column'
  },
  messages: {
    padding: theme.spacing(1)
  }
});

export function ActivityResponseError({ ramqResponse, initiallyOpen, classes }) {
  return (
    <Collapser initiallyOpen={initiallyOpen}>
      <CollapserHeader>
        <RamqInfoIcon ramqLogId={ramqResponse.ramqLogId} />
        <ListItem button className={classes.header}>
          <DateSummary date={ramqResponse.createdOn} />
          <ListItemSecondaryAction>
            <Typography>Erreur</Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </CollapserHeader>
      <CollapserContent>
        <div className={classes.messages}>
          <Typography>{ramqResponse.errorLog.message}</Typography>
          {ramqResponse.errorLog.xmlErrors?.map((xmlError) => (
            <Typography key={xmlError}>{xmlError}</Typography>
          ))}
          {ramqResponse.errorLog.response && (
            <>
              <Typography variant="caption">{`Status: ${ramqResponse.errorLog.response.status}`}</Typography>
              <Typography variant="caption">{ramqResponse.errorLog.response.statusText}</Typography>
            </>
          )}
        </div>
      </CollapserContent>
    </Collapser>
  );
}

ActivityResponseError.propTypes = {
  ramqResponse: PropTypes.object.isRequired,
  initiallyOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(ActivityResponseError);
