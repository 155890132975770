import { RAMQContextElements } from '../../../../shared/ramq/RamqContextElements';
import recursiveItemsCallback from '../../../../utils/recursive-items-callback';

export const ADD_CONTEXT_ELEMENT = 'addContextElementToUserPreferences';
export const addContextElementToUserPreferences = (document) => ({
  type: ADD_CONTEXT_ELEMENT,
  document
});

export const ADD_CONTEXT_ELEMENT_FAILURE = 'addContextElementFailure';
export const addContextElementToUserPreferencesFailure = (error) => ({
  type: ADD_CONTEXT_ELEMENT_FAILURE,
  error
});

export const REMOVE_CONTEXT_ELEMENT = 'removeContextElementFromUserPreferences';
export const removeContextElementFromUserPreferences = (document) => ({
  type: REMOVE_CONTEXT_ELEMENT,
  document
});

export const REMOVE_CONTEXT_ELEMENT_FAILURE = 'removeContextElementFailure';
export const removeContextElementFromUserPreferencesFailure = (error) => ({
  type: REMOVE_CONTEXT_ELEMENT_FAILURE,
  error
});

export const EDIT_CONTEXT_ELEMENT = 'editContextElementFromUserPreferences';
export const editContextElementFromUserPreferences = (document) => ({
  type: EDIT_CONTEXT_ELEMENT,
  document
});

export const EDIT_CONTEXT_ELEMENT_FAILURE = 'editContextElementFailure';
export const editContextElementFromUserPreferencesFailure = (error) => ({
  type: EDIT_CONTEXT_ELEMENT_FAILURE,
  error
});

export const UPDATE_CONTEXT_ELEMENT_COLLECTION = 'updateContextElementFromUserPreferences';
export const updateContextElementFromUserPreferences = (documentMap) => ({
  type: UPDATE_CONTEXT_ELEMENT_COLLECTION,
  documentMap
});

export const UPDATE_CONTEXT_ELEMENT_COLLECTION_SUCCESS = 'updateContextElementSuccess';
export const updateContextElementFromUserPreferencesSuccess = () => ({
  type: UPDATE_CONTEXT_ELEMENT_COLLECTION_SUCCESS
});

export const UPDATE_CONTEXT_ELEMENT_COLLECTION_FAILURE = 'updateContextElementFailure';
export const updateContextElementFromUserPreferencesFailure = (error) => ({
  type: UPDATE_CONTEXT_ELEMENT_COLLECTION_FAILURE,
  error
});

export const GET_CONTEXT_ELEMENTS_PREFERENCES = 'getContextElementsPreferences';
export const getContextElementsPreferences = () => ({
  type: GET_CONTEXT_ELEMENTS_PREFERENCES
});

export const GET_CONTEXT_ELEMENTS_PREFERENCES_SUCCESS = 'getContextElementsPreferencesSuccess';
export const getContextElementsPreferencesSuccess = (contextElements) => ({
  type: GET_CONTEXT_ELEMENTS_PREFERENCES_SUCCESS,
  contextElements: recursiveItemsCallback(contextElements, (contextElement) => ({
    ...contextElement,
    ...(RAMQContextElements.get(contextElement.code) || {}),
    ...(contextElement.description && { description: contextElement.description })
  }))
});

export const GET_CONTEXT_ELEMENTS_PREFERENCES_FAILURE = 'getContextElementsPreferencesFailure';
export const getContextElementsPreferencesFailure = (error) => ({
  type: GET_CONTEXT_ELEMENTS_PREFERENCES_FAILURE,
  error
});

export const GET_SPECIALTY_CONTEXT_ELEMENTS = 'getSpecialtyContextElements';
export const getSpecialtyContextElements = () => ({
  type: GET_SPECIALTY_CONTEXT_ELEMENTS
});

export const GET_SPECIALTY_CONTEXT_ELEMENTS_SUCCESS = 'getSpecialtyContextElementsSuccess';
export const getSpecialtyContextElementsSuccess = (specialtyContextElements) => ({
  type: GET_SPECIALTY_CONTEXT_ELEMENTS_SUCCESS,
  specialtyContextElements: recursiveItemsCallback(specialtyContextElements, (specialtyContextElement) => ({
    ...specialtyContextElement,
    ...(RAMQContextElements.get(specialtyContextElement.code) || {}),
    ...(specialtyContextElement.description && { description: specialtyContextElement.description })
  }))
});

export const GET_SPECIALTY_CONTEXT_ELEMENTS_FAILURE = 'getSpecialtyContextElementsFailure';
export const getSpecialtyContextElementsFailure = (specialtyContextElementsError) => ({
  type: GET_SPECIALTY_CONTEXT_ELEMENTS_FAILURE,
  specialtyContextElementsError
});
