/* eslint-disable no-console */
import * as SentryReact from '@sentry/react';
import * as SentryCordova from 'sentry-cordova';
import AuthUserDomain from '../shared/domain/authentication/AuthUser';
import Permission from '../shared/domain/authorization/model/Permission';
import getExecutionEnv, { getBuildNumber } from '../shared/utils/executionEnvironment';
import { logError } from '../shared/utils/plog';

const IGNORED_ERRORS = [/Missing or insufficient permissions/, /The device maybe offline/];

export const setup = () => {
  const reactDsn = process.env.REACT_APP_SENTRY_WEBAPP_DSN;
  const cordovaDsn = process.env.REACT_APP_SENTRY_MOBILE_DSN;

  if (cordovaDsn && window.cordova) {
    SentryCordova.init({
      dsn: cordovaDsn,
      environment: getExecutionEnv(),
      release: getBuildNumber(),
      dist: 'mobile',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,

      beforeSend(event, hint) {
        const errorMessage = hint?.originalException?.toString();

        if (errorMessage && IGNORED_ERRORS.some((ignoredError) => ignoredError.test(errorMessage))) {
          return null;
        }

        return event;
      }
    });
  } else if (reactDsn) {
    SentryReact.init({
      dsn: reactDsn,
      environment: getExecutionEnv(),
      release: getBuildNumber(),
      dist: 'webapp',
      attachStacktrace: true,
      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category === 'console') {
          return null;
        }
        return breadcrumb;
      },

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.5,

      beforeSend(event, hint) {
        // SentryReact types are wrong, originalException is typed as unknown
        const originalException = hint?.originalException as Error | string | null;
        const errorMessage = originalException?.toString();

        if (hint.mechanism?.handled) {
          return null;
        }

        if (errorMessage && IGNORED_ERRORS.some((ignoredError) => ignoredError.test(errorMessage))) {
          return null;
        }

        return event;
      }
    });
  } else {
    console.log('No Sentry DSN found, skipping Sentry init.');
  }
};

export const captureError = (catchedError, loggedData) => {
  const reactDsn = process.env.REACT_APP_SENTRY_WEBAPP_DSN;
  const cordovaDsn = process.env.REACT_APP_SENTRY_MOBILE_DSN;

  if (cordovaDsn) {
    SentryCordova.captureException(catchedError, { extra: loggedData });
  } else if (reactDsn) {
    SentryReact.captureException(catchedError, { extra: loggedData });
  } else {
    logError(loggedData);
  }
};

export const setUserContext = (userProfile, authenticatedUser: AuthUserDomain) => {
  const reactDsn = process.env.REACT_APP_SENTRY_WEBAPP_DSN;
  const cordovaDsn = process.env.REACT_APP_SENTRY_MOBILE_DSN;
  if (!reactDsn && !cordovaDsn) {
    console.log('No Sentry DSN found, skipping Sentry user.');
    return;
  }

  const user: any = {};
  user.id = userProfile.id;
  user.email = userProfile.email;
  user.name = `${userProfile.firstName} ${userProfile.lastName}`;
  user.specialty = userProfile.specialty;

  const authUserDto = authenticatedUser.toDto();

  if (!authUserDto.uid) return;

  const userBeingImpersonatedByAdminOrAgent: boolean =
    authenticatedUser.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS) &&
    userProfile.id !== authUserDto.uid;

  if (userBeingImpersonatedByAdminOrAgent) {
    user.administrator = `${authUserDto.email}`;
  }

  if (cordovaDsn) {
    SentryCordova.setUser(user);
  } else if (reactDsn) {
    SentryReact.setUser(user);
  }
};
