import { actFormFields } from 'app/containers/ActForm/constants';
import Act from 'shared/domain/activity/act/model/Act';
import { RAMQPlaces } from 'shared/ramq/RamqPlaces';

const NO_ERRORS = {};
const CODES_THAT_MUST_BE_BILLED_IN_A_CMS = ['42268'];

const codeCanOnlyBeBilledInACmsValidation = (act: Act): Record<string, unknown> => {
  if (!act.codes || act.codes.length === 0) return NO_ERRORS;

  const codeThatMustBeBilledInACms = act.codes.find(({ code }) => CODES_THAT_MUST_BE_BILLED_IN_A_CMS.includes(code));

  if (!codeThatMustBeBilledInACms) return NO_ERRORS;

  if (!act.place) return NO_ERRORS;

  const ramqPlace = RAMQPlaces.get(act.place.number);

  if (ramqPlace && ramqPlace.type === 'physical' && ramqPlace.isCMS) return NO_ERRORS;

  return {
    [actFormFields.place]: `Le code ${codeThatMustBeBilledInACms.code} ne peut être facturé que dans un CMS`
  };
};

export default codeCanOnlyBeBilledInACmsValidation;
