export const OPEN_SEARCH_ACTIVITIES = 'openActivitiesSearch';
export const openActivitiesSearch = () => ({
  type: OPEN_SEARCH_ACTIVITIES
});

export const CLOSE_SEARCH_ACTIVITIES = 'closeActivitiesSearch';
export const closeActivitiesSearch = () => ({
  type: CLOSE_SEARCH_ACTIVITIES
});

export const GET_ACTIVITY_BY_ID = 'getActivityById';
export const getActivityById = (activityId) => ({
  type: GET_ACTIVITY_BY_ID,
  activityId
});

export const GET_ACTIVITY_BY_ID_SUCCESS = 'getActivityByIdSuccess';
export const getActivityByIdSuccess = (activity) => ({
  type: GET_ACTIVITY_BY_ID_SUCCESS,
  activity
});

export const GET_ACTIVITY_BY_ID_ERROR = 'getActivityByIdError';
export const getActivityByIdError = (error) => ({
  type: GET_ACTIVITY_BY_ID_ERROR,
  error
});
