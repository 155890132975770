export const PARTICULAR_SITUATION_TYPES = [
  { label: 'Infecté', value: 'infected' },
  { label: 'Quarantaine', value: 'quarantine' },
  { label: 'Enceinte', value: 'pregnant' }
];

export const PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY = 'particularSituation';
export const TELEMEDICINE_OR_REMOTE_REQUIRED_DETAIL_KEY = 'telemedicineOrRemote';
export const REMOTE_REQUIRED_DETAIL_KEY = 'remote';
export const TELEMEDICINE_REQUIRED_DETAIL_KEY = 'telemedicine';
