import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import Props from './props';
import BillingType from '../../../shared/domain/billing/model/BillingType';

const DefaultBillingTypes = [BillingType.POOL, BillingType.PRIV, BillingType.END];

const BillingTypeSelector: React.FunctionComponent<Props> = ({
  classes,
  onChange,
  value,
  billingTypes = DefaultBillingTypes
}): JSX.Element => {
  const handleChange = (event, newValue) => {
    if (newValue === value) return;
    onChange(newValue);
  };

  const tabElement = (type: string): JSX.Element => (
    <Tab label={type} value={type} key={`billing-type-${type}`} className={classes.tab} />
  );

  return (
    <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
      {billingTypes!.map(tabElement)}
    </Tabs>
  );
};

export default compose(withStyles(styles))(BillingTypeSelector);
