import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToTimeWithHourPrefixValue } from '../../../../shared/utils/dateTime/dateTimeUtils';
import Hours from '../Hours/Hours';

function FormattedUnixHours({ activity, ...props }) {
  return (
    <Hours
      activity={{
        start: activity.start ? formatUnixToTimeWithHourPrefixValue(activity.start) : activity.start,
        end: activity.end ? formatUnixToTimeWithHourPrefixValue(activity.end) : activity.end,
        remp: activity.remp ? formatUnixToTimeWithHourPrefixValue(activity.remp) : activity.remp
      }}
      {...props}
    />
  );
}

FormattedUnixHours.propTypes = {
  activity: PropTypes.object.isRequired
};

export default FormattedUnixHours;
