import Fab from '@material-ui/core/Fab';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { BOTTOM_NAVIGATION_HEIGHT } from '../../BottomNavigation/BottomNavigation';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    addActButton: {
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
      [breakpoints.down('sm')]: {
        bottom: theme.spacing(2) + BOTTOM_NAVIGATION_HEIGHT,
        right: theme.spacing(2)
      }
    }
  };
};

export function FloatingButton({ icon, color, classes, className, id, onClick }) {
  return (
    <Fab id={id} color={color} className={classNames(classes.addActButton, className)} onClick={onClick}>
      {icon}
    </Fab>
  );
}

FloatingButton.propTypes = {
  icon: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,

  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  id: PropTypes.string
};

FloatingButton.defaultProps = {
  id: '',
  className: ''
};

export default withStyles(styles)(FloatingButton);
