import { has, orderBy } from 'lodash';
import Act, { PatientContext } from '../../activity/act/model/Act';
import { sanitizeFromUndefined } from '../../../utils/objectManipulation';
import ActivityStatus from '../../../core/activity/domain/ActivityStatus';

class PatientContextExtractor {
  static extractFromActs(acts: Act[]): PatientContext | undefined {
    const discardCancelledActs = ({ status }) => status !== ActivityStatus.CANCELED;
    const codeHasRequiredFields = (code) => has(code, 'diagnostic') || has(code, 'dateHospitalisation');
    const orderedActs = orderBy(acts, ['date'], ['desc']);
    const activeActs = orderedActs.filter(discardCancelledActs);

    if (activeActs.length === 0) {
      return undefined;
    }

    let lastDiagnostic;
    let lastDateHospitalisation;

    activeActs.forEach(({ codes = [], patientDiagnostic }) => {
      codes.filter(codeHasRequiredFields).forEach(({ diagnostic, dateHospitalisation }) => {
        lastDiagnostic = lastDiagnostic || diagnostic;
        lastDateHospitalisation = lastDateHospitalisation || dateHospitalisation;
      });

      lastDiagnostic = lastDiagnostic || patientDiagnostic;
    });

    // Some codes have multiple diagnostics, other have only one
    // Since some components expects an array, we need to make sure it is always an array
    if (lastDiagnostic && !Array.isArray(lastDiagnostic)) {
      lastDiagnostic = [lastDiagnostic];
    }

    return sanitizeFromUndefined({ admissionDate: lastDateHospitalisation, diagnostic: lastDiagnostic });
  }
}

export default PatientContextExtractor;
