import TRAVEL_FEES_TYPES from '../../../shared/ramq/domainValues/travelFeesTypes';
import { validateValueIsDefined } from '../common/reduxFormValidation';

const isCostOfStayFee = (fee) => fee.type === TRAVEL_FEES_TYPES.costOfStayFee;

const validateStayTypeAndAmount = (fee, errorMap, feeFieldName) => {
  validateValueIsDefined(fee, 'stayType', feeFieldName, errorMap);
  validateValueIsDefined(fee, 'amount', feeFieldName, errorMap);
};

const costOfStayFeeValidation = (fees) => {
  const errorMap = {};
  fees.forEach((fee, index) => {
    if (isCostOfStayFee(fee)) {
      const feeFieldName = `fees[${index}]`;
      validateStayTypeAndAmount(fee, errorMap, feeFieldName);
    }
  });
  return errorMap;
};

export default costOfStayFeeValidation;
