import momentTimezone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Act, { isAct } from 'shared/domain/activity/act/model/Act';
import { URGENT } from 'shared/ramq/contextElements/globalContextElements';
import Recommendation, { NO_RECOMMENDATIONS } from 'app/act/recommendations/types';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import Activity from 'shared/domain/activity/global/model/Activity';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import { selectSpecialty } from 'app/containers/User/selectors';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import { codeIsInRole2 } from 'shared/domain/activity/act/service/actCodeUtils';

const EXCEPTIONS = ['911'];

const moment = extendMoment(momentTimezone as any);

export default (partialAct: Partial<Act>): Array<Recommendation<string[]>> => {
  const simultaneousActsInRoleTwo = findSimultaneousActsInRoleTwoIfCurrentActIsInRoleTwo(partialAct);

  if (simultaneousActsInRoleTwo.length === 0) {
    return NO_RECOMMENDATIONS;
  }

  return [
    {
      apply: ({ contextElements = [] }) => ({
        fieldName: 'contextElements',
        newValue: [...(contextElements ?? []), URGENT],
        oldValue: contextElements
      }),
      message:
        'Il y a déjà un acte facturé aux mêmes heures. SVP cliquer sur le crochet pour ajouter URG si urgence grave',
      details: simultaneousActsInRoleTwo.map(formatRecommendationDetails)
    }
  ];
};

const findSimultaneousActsInRoleTwoIfCurrentActIsInRoleTwo = (partialAct: Partial<Act>): Act[] => {
  if ((partialAct.codes ?? []).some(hasExceptionCodes)) return [];

  const userSpecialty = selectSpecialty()(getStore().getState());

  if (![SpecialtyName.ANESTHESIOLOGIST, SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST].includes(userSpecialty)) {
    return [];
  }

  const { contextElements = [], type } = partialAct;

  if (type !== ActivityType.ACT) return [];

  if (contextElements.includes(URGENT)) return [];

  if (!hasCodeWithRoleTwo(partialAct, userSpecialty)) return [];

  return hasBilledOtherActsAtSameTimeInRoleTwo(partialAct, userSpecialty);
};

const hasExceptionCodes = ({ code }) => EXCEPTIONS.includes(code);

const hasCodeWithRoleTwo = (partialAct: Partial<Act>, userSpecialty) => {
  const { codes = [] } = partialAct;

  return codes.some((code) => codeIsInRole2(code, partialAct as Act, userSpecialty));
};

const hasBilledOtherActsAtSameTimeInRoleTwo = (
  referencePartialAct: Partial<Act>,
  userSpecialty: SpecialtyName
): Act[] => {
  const { end, remp, start } = referencePartialAct;

  if (!end) return [];

  if (!start && !remp) return [];

  const activitiesInContext = selectActivitiesInContext()(getStore().getState()) ?? [];

  const referencePartialActStartTime = moment(remp ?? start);
  const referencePartialActDurationRange = moment.range(referencePartialActStartTime, moment(end));

  return activitiesInContext.filter((activityInContext: Activity) => {
    if (!isAct(activityInContext)) return false;

    const { codes = [], end, id, remp, start, status } = activityInContext;

    if (codes.some(hasExceptionCodes)) return false;

    if (id === referencePartialAct.id) return false;

    if (status === ActivityStatus.CANCELED) return false;

    if (!hasCodeWithRoleTwo(activityInContext, userSpecialty)) return false;

    if (!end) return false;

    if (!start && !remp) return false;

    const activityInContextStartTime = moment(remp ?? start);

    if (activityInContextStartTime.isAfter(referencePartialActStartTime)) return false;

    const activityInContextDurationRange = moment.range(activityInContextStartTime, moment(end));

    return activityInContextDurationRange.overlaps(referencePartialActDurationRange, { adjacent: false });
  });
};

const formatRecommendationDetails = (act: Act) => {
  const { nam, start, end, codes } = act;

  return `${nam || 'Sans NAM'} ${moment(start).format('HH:mm')}-${moment(end).format('HH:mm')} (${codes.map(({ code }) => code).join(', ')})`;
};
