import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useNavigate } from 'react-router-dom';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';
import ConfirmationDialog from '../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import DialogTrigger from '../../../../components/Dialogs/DialogTrigger/DialogTrigger';
import PlaceWithSignatoryName from '../../../../components/Places/PlaceWithSignatoryName/PlaceWithSignatoryName';
import PlacesDialog from '../../../PlacesPreferencesDialog/PlacesDialog';
import { selectFormType } from '../../selectors';

export const styles = (theme) => ({
  selectedPlaceContainer: {
    color: theme.palette.common.white,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
});

const PeriodPlaceSelector = ({ selectedPlace, onChangePlaceContent, formType, classes }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedPlaceInfo, setSelectedPlaceInfo] = React.useState(undefined);

  const isSamePlaceSelectedWithDifferentSignatory = (value) =>
    !isEqual(value.signatory, selectedPlace.signatory) || !isEqual(!!value.billByAct, !!selectedPlace.billByAct);

  const confirmSignatoryModification = () => {
    onChangePlaceContent(selectedPlaceInfo);
    setSelectedPlaceInfo(undefined);
  };

  const currentFormType = () => (formType === ActivityType.MIXTE ? 'mixte' : 'forfaitaire');

  const applyPlaceQueryParam = (placeNumber) => {
    const currentParams = queryString.parse(location.search, { ignoreQueryPrefix: true });

    return queryString.stringify({
      ...currentParams,
      place: placeNumber
    });
  };

  return (
    <>
      <DialogTrigger
        DialogComponent={PlacesDialog}
        onItemSelected={(value) => {
          if (selectedPlace.number !== value.number) {
            navigate(`${location.pathname}?${applyPlaceQueryParam(value.number)}`);
          } else if (isSamePlaceSelectedWithDifferentSignatory(value)) {
            setSelectedPlaceInfo(value);
          }
        }}
      >
        <Button className={classes.selectedPlaceContainer}>
          <PlaceWithSignatoryName place={selectedPlace} />
          <ExpandMoreIcon />
        </Button>
      </DialogTrigger>
      <ConfirmationDialog
        text={`Le signataire est différent de celui enregistré
          pour ce ${currentFormType()}. Voulez-vous le mettre à jour ?`}
        onClose={() => setSelectedPlaceInfo(undefined)}
        confirmationLabel="Oui"
        cancelLabel="Non"
        open={!!selectedPlaceInfo}
        onItemSelected={confirmSignatoryModification}
        title="Modification du signataire"
      />
    </>
  );
};

PeriodPlaceSelector.propTypes = {
  selectedPlace: PropTypes.object.isRequired,
  onChangePlaceContent: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired
};

export const mapStateToProps = createStructuredSelector({
  formType: selectFormType()
});

export default compose(connect(mapStateToProps, null), withStyles(styles))(PeriodPlaceSelector);
