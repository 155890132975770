import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';

function LoadingButton({ id, label, disabled, loading, onClick, className, ...props }) {
  return (
    <Button id={id} disabled={disabled || loading} onClick={onClick} className={className} {...props}>
      {loading ? <CircularProgress data-testid="loadingButton-loader" color="primary" size={24} /> : label}
    </Button>
  );
}

LoadingButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

LoadingButton.defaultProps = {
  id: undefined,
  onClick: undefined,
  className: '',
  loading: false,
  disabled: false
};

export default LoadingButton;
