import Typography from '@material-ui/core/Typography';
import React, { PropsWithChildren, useRef, useState } from 'react';
import withStyles, { StyleRulesCallback, StyledComponentProps } from '@material-ui/core/styles/withStyles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Theme } from '@material-ui/core';

export interface Props extends StyledComponentProps {
  initialValue: string | number;
  valueFormatter?: (value: string | number) => string;
  edited: boolean;
  onSave: (value: string | number) => void;
  onDelete: () => void;
  EditingComponent: any;
}

export const EditableField: React.FunctionComponent<Props> = ({
  initialValue,
  valueFormatter,
  edited,
  EditingComponent,
  onSave,
  onDelete,
  classes
}: PropsWithChildren<Props>) => {
  const [isOpened, setIsOpened] = useState(false);
  const [value, setValue] = useState<string | number>(initialValue);
  const labelRef = useRef(null);

  const handleClick = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSave(value);
      handleClose();
    }
  };

  return (
    <div>
      <Typography
        ref={labelRef}
        data-testid="editableField-formattedValue"
        variant="body1"
        className={classNames(classes?.editableField, { [classes?.edited ?? '']: edited })}
        onClick={handleClick}
      >
        {valueFormatter ? valueFormatter(initialValue) : initialValue}
      </Typography>
      <Popover
        open={isOpened}
        className="popover"
        anchorEl={labelRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div data-testid="editableField-form" className={classes!.editableForm}>
          <EditingComponent className="editingComponent" value={value} onChange={setValue} onKeyDown={handleKeyDown} />
          <Button
            data-testid="editableField-saveButton"
            className="saveButton"
            onClick={() => {
              onSave(value);
              handleClose();
            }}
          >
            <DoneIcon />
          </Button>
          <Button
            data-testid="editableField-deleteButton"
            className="deleteButton"
            onClick={() => {
              onDelete();
              handleClose();
            }}
          >
            <CloseIcon />
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  editableField: {
    textDecoration: 'underline dashed',
    cursor: 'pointer'
  },
  edited: {
    color: 'red'
  },
  editableForm: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(1)
  }
});

export default withStyles(styles)(EditableField);
