import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { WithStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import useSearchRAMQPlaces from 'application/hooks/use-search-ramq-places';
import MaxSearchReachedDialogActions from '../../components/Dialogs/DialogActionsWithMessage/MaxSearchReachedDialogActions';
import DialogCenteredTitle from '../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import PlaceSettingsDialog from '../../components/Dialogs/PlaceSettingsDialog/PlaceSettingsDialog';
import PlaceWithSignatoryName from '../../components/Places/PlaceWithSignatoryName/PlaceWithSignatoryName';
import SwipeableTabs from '../../components/SwipeableTabs/SwipeableTabs';
import { removePlaceFromForm } from '../ActForm/actions';
import { updateSearchValue } from '../SearchSuggestions/actions';
import getNextPriority from '../User/preferencesUtils';
import { selectPlacesPreferenceFromUser } from '../User/selectors';
import { removeFavoritePlace, saveFavoritePlace } from './actions';
import FavoriteItem from './FavoriteItem/FavoriteItem';
import messages from './messages';
import isDefined from '../../../shared/utils/isDefined';
import Place from '../../../shared/domain/place/model/Place';
import { SearchSuggestions } from '../SearchSuggestions/SearchSuggestions';
import PlaceSuggestion from './PlaceSuggestion';
import { selectSelectedDate } from '../ActForm/selectors';

export const styles = (theme) =>
  ({
    paper: {
      minHeight: '90vh',
      height: '100%'
    },
    searchContainer: {
      'height': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    },
    slideChildContainer: {
      'height': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    },
    suggestionList: {
      flex: 1,
      overflowY: 'scroll',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  fullScreen: boolean;
  open: boolean;
  onClose: () => void;
  onItemSelected: (item) => void;
  saveFavoritePlace: (item) => void;
  placesPreference: Place[];
  createNewRouteFromPlace: (place) => void;
  currentActDate: number;
  removeFavoritePlace: (place) => void;
}

export const PlacesDialog: React.FC<Props> = (props) => {
  const {
    fullScreen,
    open,
    onClose,
    onItemSelected,
    saveFavoritePlace,
    placesPreference,
    createNewRouteFromPlace,
    currentActDate,
    removeFavoritePlace,
    classes
  } = props;

  const [signatoryDialogOpen, setSignatoryDialogOpen] = useState(false);
  const [tempSelectedPlace, setTempSelectedPlace] = useState<any>(undefined);
  const [inputValue, setInputValue] = useState('');

  const handleClose = () => {
    onClose();
  };

  const resetSelector = () => {
    setSignatoryDialogOpen(false);
    setTempSelectedPlace(undefined);
    setInputValue('');
  };

  const handleSuggestionClicked = (place) => {
    setTempSelectedPlace(place);
    setSignatoryDialogOpen(true);
  };

  const incrementPriorities = (mainPlaceNumber, placesPreference) => {
    placesPreference.forEach((place) => {
      if (place.number !== mainPlaceNumber) {
        saveFavoritePlace({
          ...place,
          priority: place.priority + 1
        });
      }
    });
  };

  const handleSignatorySave = (signatory, annexe19, billByAct, mainHospital) => {
    if (!tempSelectedPlace) return;
    let priority = tempSelectedPlace.priority || getNextPriority(placesPreference);

    if (mainHospital) {
      priority = 1;
      incrementPriorities(tempSelectedPlace.number, placesPreference);
    }

    saveFavoritePlace({
      ...(tempSelectedPlace.id && { id: tempSelectedPlace.id }),
      number: tempSelectedPlace.number,
      billByAct: !!billByAct,
      annexe19,
      signatory,
      priority,
      type: tempSelectedPlace.type
    });

    resetSignatoryDialog();
  };

  const resetSignatoryDialog = () => {
    setSignatoryDialogOpen(false);
    setTempSelectedPlace(undefined);
  };

  const handleEditClicked = (editedPlace) => {
    setTempSelectedPlace(editedPlace);
    setSignatoryDialogOpen(true);
  };

  const handleFavoriteSelected = (item) => {
    onItemSelected(item);
    handleClose();
  };

  const handleDeleteClicked = (place) => {
    removeFavoritePlace(place);
  };

  const sortedPlacesPreference = sortBy(placesPreference, 'priority');

  const options = useSearchRAMQPlaces(inputValue.replace(',', ''), currentActDate);

  return (
    <Dialog
      id="places-Dialog"
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionProps={{ onExited: resetSelector }}
      classes={{ paper: classes.paper }}
    >
      <DialogCenteredTitle label={<FormattedMessage {...messages.title} />} />

      <SwipeableTabs
        swipeableViewsProps={{
          containerStyle: { height: '100%' },
          slideStyle: { overflow: 'hidden' }
        }}
        tabs={[
          {
            key: 'favorites',
            icon: <FavoriteIcon />,
            component: (
              <div className={classes.slideChildContainer}>
                <DialogContent className={classes.searchContainer}>
                  <List dense>
                    {sortedPlacesPreference.map((place) => (
                      <FavoriteItem
                        key={place.number}
                        chipNumber={place.number}
                        primaryText={<PlaceWithSignatoryName dark place={place} principal={place.priority === 1} />}
                        onItemEdit={() => handleEditClicked(place)}
                        onItemSelect={() => handleFavoriteSelected(place)}
                        onItemRemove={() => handleDeleteClicked(place)}
                      />
                    ))}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button id="close-Dialog" onClick={() => handleClose()} color="primary">
                    <FormattedMessage {...messages.close} />
                  </Button>
                </DialogActions>
              </div>
            )
          },
          {
            key: 'search',
            icon: <SearchIcon />,
            component: (
              <div className={classes.slideChildContainer}>
                <DialogContent className={classes.searchContainer}>
                  <TextField
                    onChange={(e) => setInputValue(e.target.value)}
                    label={<FormattedMessage {...messages.searchLabel} />}
                  />
                  <SearchSuggestions
                    className={classes.suggestionList}
                    hits={options}
                    clearSearch={() => setInputValue('')}
                    onSuggestionClick={handleSuggestionClicked}
                    SuggestionItemComponent={PlaceSuggestion}
                    getKeyFunction={(place) => place.number}
                  />
                </DialogContent>
                <MaxSearchReachedDialogActions maxSearchNumber={20} hitsCount={options.length}>
                  <Button id="close-Dialog" onClick={onClose} color="primary">
                    <FormattedMessage {...messages.close} />
                  </Button>
                </MaxSearchReachedDialogActions>
              </div>
            )
          }
        ]}
      />
      <PlaceSettingsDialog
        open={signatoryDialogOpen}
        onCancel={resetSignatoryDialog}
        place={isDefined(tempSelectedPlace) ? tempSelectedPlace : undefined}
        onSave={handleSignatorySave}
        createNewRouteFromPlace={createNewRouteFromPlace || (() => {})}
      />
    </Dialog>
  );
};

export const mapStateToProps = createStructuredSelector({
  placesPreference: selectPlacesPreferenceFromUser(),
  currentActDate: selectSelectedDate()
});

const mapDispatchToProps = {
  saveFavoritePlace,
  removeFavoritePlace,
  updateSearchValue,
  removePlaceFromForm
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withMobileDialog(),
  withStyles(styles)
)(PlacesDialog);
