import {
  FIRESTORE_OFFLINE_FAIL,
  LOGGED_IN_ACTION,
  LOGGED_OUT_ACTION,
  LOGIN_FAILURE,
  LOGIN_REMOVE_FAILURE,
  TRY_LOGIN,
  MFA_LOGIN,
  MFA_CANCEL,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  MFA_REQUIRED
} from './actions';

export const initialState = {
  user: {
    pendingAuth: true
  },
  firestoreStatus: {
    error: undefined
  },
  login: {
    isLoginIn: false,
    error: undefined,
    pendingMfa: null
  },
  updatePassword: {
    success: false
  }
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN_ACTION:
      return {
        ...state,
        user: action.user,
        login: {
          ...state.login,
          isLoginIn: false,
          error: undefined,
          pendingMfa: null
        }
      };
    case LOGGED_OUT_ACTION:
      return { ...state, user: {} };
    case TRY_LOGIN:
    case MFA_LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          isLoginIn: true,
          error: undefined
        }
      };
    case MFA_REQUIRED:
      return {
        ...state,
        login: {
          ...state.login,
          isLoginIn: false,
          pendingMfa: {
            verificationId: action.verificationId,
            resolver: action.resolver
          }
        }
      };
    case MFA_CANCEL:
      return {
        ...state,
        login: {
          ...state.login,
          isLoginIn: false,
          pendingMfa: null,
          error: undefined
        }
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          isLoginIn: false,
          error: action.error
        }
      };
    case LOGIN_REMOVE_FAILURE:
      return {
        ...state,
        login: {
          ...state.login,
          error: undefined
        }
      };
    case FIRESTORE_OFFLINE_FAIL:
      return {
        ...state,
        firestoreStatus: {
          error: action.error
        }
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatePassword: {
          ...state.updatePassword,
          success: false
        }
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePassword: {
          ...state.updatePassword,
          success: true
        }
      };
    default:
      return state;
  }
};

export default authenticationReducer;
