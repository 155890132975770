import React from 'react';
import NAM from '../NAM/NAM';
import PATIENT_WITHOUT_ID_TYPE, {
  OUTSIDE_RAMQ_PATIENT_TYPE
} from '../../../../../shared/ramq/domainValues/situationTypes';
import NoNamIdentification from '../NoNamIdentification/NoNamIdentification';
import Act, { OutsideRamqPatientType } from '../../../../../shared/domain/activity/act/model/Act';

export interface Props {
  act: Act;
}

function getType({ patientInformation }: Act) {
  if (patientInformation?.outsideRamq?.patientType === OutsideRamqPatientType.OUTSIDE_RAMQ) {
    return patientInformation.province;
  }

  return patientInformation?.outsideRamq?.patientType;
}

const PatientIdentification: React.FunctionComponent<Props> = ({ act }: Props) => {
  const { patientInformation = {} } = act;

  switch (patientInformation.type) {
    case PATIENT_WITHOUT_ID_TYPE.patientNeedingUrgentCare:
      return (
        <NoNamIdentification
          type="urgent"
          firstName={patientInformation.firstName || ''}
          lastName={patientInformation.lastName || ''}
        />
      );
    case OUTSIDE_RAMQ_PATIENT_TYPE:
      return (
        <NoNamIdentification
          type={getType(act)}
          firstName={patientInformation.firstName || ''}
          lastName={patientInformation.lastName || ''}
        />
      );

    case PATIENT_WITHOUT_ID_TYPE.refundRequest:
    case PATIENT_WITHOUT_ID_TYPE.childUnderOneYear:
    default:
      return <NAM act={act} />;
  }
};

export default PatientIdentification;
