export const EXTRACT_PDF = 'extractPDF';
export const EXTRACT_PDF_SUCCESS = 'extractPDFSuccess';
export const EXTRACT_PDF_FAILURE = 'extractPDFFailure';

export function extractPDF(document, readOnly = false) {
  return {
    type: EXTRACT_PDF,
    isReadOnly: readOnly,
    document
  };
}

export function extractPDFSuccess() {
  return {
    type: EXTRACT_PDF_SUCCESS
  };
}

export function extractPDFFailure(error) {
  return {
    type: EXTRACT_PDF_FAILURE,
    error
  };
}
