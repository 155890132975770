import { get, set } from 'lodash';
import TRANSPORT_TYPES from '../../../shared/ramq/domainValues/transportTypes';
import TRAVEL_FEES_TYPES from '../../../shared/ramq/domainValues/travelFeesTypes';
import TRANSPORT_FEES_TYPES from '../../../shared/ramq/domainValues/transportFeesTypes';
import isDefined from '../../../shared/utils/isDefined';
import validateDateAndTransportType from './common/commonFeeValidation';

const isTransportFee = (fee) => fee.type === TRAVEL_FEES_TYPES.transportFee;

const validatePersonalCarOption = (fee, errorMap, feeFieldName) => {
  if (fee.transportType === TRANSPORT_TYPES.vehiculePerso && !isDefined(fee.goingKilometers)) {
    set(errorMap, `${feeFieldName}.goingKilometers`, 'Veuillez indiquer le nombre de kilomètres parcourus');
  }
};

const validateOtherTransportOption = (fee, additionalTransportFees, errorMap, feeFieldName) => {
  if (
    fee.transportType &&
    fee.transportType !== TRANSPORT_TYPES.vehiculePerso &&
    additionalTransportFees.length === 0
  ) {
    set(errorMap, `${feeFieldName}.additionalTransportFees._error`, 'Veuillez ajouter au moins un frais additionnel');
  }
};

const validateIncompleteAdditionalTransportFees = (additionalTransportFees, errorMap, feeFieldName) => {
  additionalTransportFees.forEach((additionalFee, index) => {
    if (!isDefined(additionalFee.type) || !isDefined(additionalFee.amount)) {
      set(
        errorMap,
        `${feeFieldName}.additionalTransportFees[${index}].type`,
        "Veuillez remplir toutes les informations du frais ou l'enlever"
      );
    }
  });
};

const validateIncompatibleAdditionalFeeForRentedCar = (fee, additionalTransportFees, errorMap, feeFieldName) => {
  if (fee.transportType !== TRANSPORT_TYPES.vehiculeLoue) return;

  additionalTransportFees.forEach((additionalFee, index) => {
    if (additionalFee.type !== TRANSPORT_FEES_TYPES.general) return;

    set(
      errorMap,
      `${feeFieldName}.additionalTransportFees[${index}].type`,
      "Le type de frais n'est pas compatible avec le type de transport"
    );
  });
};

const transportFeeValidation = (fees) => {
  const errorMap = {};
  fees.forEach((fee, index) => {
    if (isTransportFee(fee)) {
      const feeFieldName = `fees[${index}]`;
      const additionalTransportFees = get(fee, 'additionalTransportFees', []);
      validateDateAndTransportType(fee, errorMap, feeFieldName);
      validatePersonalCarOption(fee, errorMap, feeFieldName);
      validateOtherTransportOption(fee, additionalTransportFees, errorMap, feeFieldName);
      validateIncompatibleAdditionalFeeForRentedCar(fee, additionalTransportFees, errorMap, feeFieldName);
      validateIncompleteAdditionalTransportFees(additionalTransportFees, errorMap, feeFieldName);
    }
  });

  return errorMap;
};

export default transportFeeValidation;
