import React from 'react';
import useFeatureFlags, { FeatureFlags } from 'application/hooks/use-feature-flags';
import medyx from 'images/medyx-horizontal.svg';
import useTranslate from 'application/hooks/use-translate';

interface Props {
  children: JSX.Element;
}

export default ({ children }: Props) => {
  const translate = useTranslate('maintenance');
  const isMaintenanceEnabled = useFeatureFlags(FeatureFlags.MAINTENANCE);

  if (!isMaintenanceEnabled) return children;

  return (
    <div className="flex h-screen items-center justify-center sm:bg-primary">
      <div className="bg-base-100 pt-6 sm:card sm:shadow-xl">
        <img src={medyx as string} alt="Maintenance planifiée" width="50%" className="mb-4 ml-6 sm:mx-auto" />
        <div className="card-body">
          <h2 className="card-title">{translate('title')}</h2>
          <p>{translate('message')}</p>
          <p>{translate('thanks')}</p>
        </div>
      </div>
    </div>
  );
};
