import { logDefault } from '../../../../shared/utils/plog';
import { buildFromFileEntry } from './actFileBuilder';
import { getRootDirectory, getSubDirectory } from './cordovaLocalFile';
import MobileFileRepository from './mobileFileRepository';

const MEDYX_ASSETS_DIRECTORY = 'medyxAssets';
const MEDYX_CARD_PICTURES = 'medyxCardPictures';

const getActPicturesDirectory = async () => {
  const rootDirectory = await getRootDirectory();
  const medyxDirectory = await getSubDirectory(rootDirectory, MEDYX_ASSETS_DIRECTORY);
  return getSubDirectory(medyxDirectory, MEDYX_CARD_PICTURES);
};

class ActPictureRepository extends MobileFileRepository {
  constructor() {
    super(getActPicturesDirectory);
  }

  getOlderPicturesForAct = async (actPicture) => {
    const localPictures = await this.listAllFiles();
    const localActPictures = localPictures.map(buildFromFileEntry);
    return localActPictures.filter(
      (currentActPicture) =>
        currentActPicture &&
        currentActPicture.actId === actPicture.actId &&
        currentActPicture.timestamp <= actPicture.timestamp
    );
  };

  removeOldPicturesForAct = async (actPicture) => {
    const olderPicturesForAct = await this.getOlderPicturesForAct(actPicture);
    olderPicturesForAct.forEach((currentActPicture) => this.removeFile(currentActPicture.fileName));
  };

  getAllActPictures = async () => {
    const localPictures = await this.listAllFiles();
    logDefault(['All pictures found in the filesystem: ', localPictures.map((fileEntry) => fileEntry.name)]);
    const localActPictures = localPictures.map(buildFromFileEntry);
    return keepOnePicturePerAct(localActPictures);
  };
}

export const keepOnePicturePerAct = (actPictureList) =>
  actPictureList.filter((actPicture) => actPicture && isMostRecentForAct(actPicture, actPictureList));

export const isMostRecentForAct = (actPicture, actPictureList) => {
  const moreRecentActPictures = actPictureList.filter(
    (actPictureToCompare) =>
      actPictureToCompare &&
      actPicture &&
      actPictureToCompare.actId === actPicture.actId &&
      actPictureToCompare.timestamp > actPicture.timestamp
  );

  return moreRecentActPictures.length === 0;
};

const actPictureRepository = new ActPictureRepository();
export default actPictureRepository;
