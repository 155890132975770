import { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface ErrorParams {
  resource?: string;
  field?: string;
  errorCode?: string;
}

export default function useTranslateError() {
  const intl = useIntl();

  const genericError = intl.formatMessage({
    id: 'errors.generic'
  });

  return useCallback(
    (params: ErrorParams) =>
      intl.formatMessage({
        id: `errors.${params.resource}.${params.field}.${params.errorCode}`,
        defaultMessage: genericError
      }),
    [genericError, intl]
  );
}
