import { CHANGE } from 'redux-form/lib/actionTypes';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import { savePeriod } from '../PeriodsBilling/sagas';
import { selectFormType } from '../PeriodsBilling/selectors';
import { SAVE_MIXTE, saveMixteFailure, toggleManualMixteEditionForSpecificDay } from './actions';

const isMixteForm = (formType) => formType === ActivityType.MIXTE;
const isRelevantEdition = (field) => field.includes('perdiems') || field.includes('code');

export function* toggleManualMixteEdition(action) {
  const formType = yield select(selectFormType());
  const { field } = action.meta;
  if (isMixteForm(formType) && isRelevantEdition(field)) {
    const currentDayFieldName = field.split('.')[0];
    yield put(toggleManualMixteEditionForSpecificDay(currentDayFieldName));
  }
}

export default function* mixtesSaga() {
  yield takeLatest(SAVE_MIXTE, savePeriod, saveMixteFailure);
  yield takeEvery(CHANGE, toggleManualMixteEdition);
}
