import { select } from 'redux-saga/effects';
import ActFile from '../../../../../containers/Storage/cordova/ActFile';
import StorageUploadContext from '../../../../../containers/Storage/uploader/StorageUploadContext';
import { selectUserIdInContext } from '../../../../../containers/User/selectors';
import { getStore } from '../../../../../reduxStore/configureStore';
import storageUploader from '../../../../../containers/Storage/uploader/StorageUploader';
import { logError } from '../../../../../../shared/utils/plog';
import { trackErrorInFirestore } from '../../../../../firebase/document/documentSagas';
import { MULTI_ACT_FILE_TYPE } from '../constants';
import actPictureRepository from '../../../../../containers/Storage/cordova/actPictureRepository';

export const DOCUMENT_ID = 'temporaryOcrFile';

export default function* multiActFileReadyForUpload(action) {
  try {
    const { attachmentType, fileEntry, persistFileLocallyBeforeUpload } = action;
    const actAttachment = new ActFile(DOCUMENT_ID, fileEntry);

    const uploadContext = new StorageUploadContext(DOCUMENT_ID, fileEntry, persistFileLocallyBeforeUpload);
    uploadContext.fileWrapper = actAttachment;
    uploadContext.uploadType = attachmentType;
    uploadContext.userId = yield select(selectUserIdInContext());
    uploadContext.localFileRepository = getLocalFileRepository(attachmentType);
    uploadContext.storeDispatch = getStore().dispatch;

    storageUploader.initiateUpload(uploadContext);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'MultiActDialogSagas/actFileReadyForUpload',
      array: ['actFileReadyForUpload ERROR', action, e]
    });
    yield* trackErrorInFirestore(e, { type: 'actFileReadyForUploadError' });
  }
}

function getLocalFileRepository(attachmentType) {
  if (attachmentType === MULTI_ACT_FILE_TYPE) {
    return actPictureRepository;
  }

  return undefined;
}
