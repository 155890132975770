import React, { useEffect, useMemo, useRef } from 'react';
import Chart from 'chart.js/auto';
import Spinner from 'application/components/spinner';

interface Props {
  type: 'bar' | 'doughnut' | 'line';
  loading?: boolean;
  title?: string;
  data: Array<{
    label: string;
    value: number;
    color?: string;
  }>;
  showLegend?: boolean;
  onClick?: (label: string, value: number) => void;
}

const monochrome = '#115566';
const chartColors = [
  '#115566',
  '#38A06C',
  '#F8CD46',
  '#3B2C78',
  '#0091B6',
  '#09723D',
  '#C97B20',
  '#D9D2F5',
  '#687182',
  '#0ECE6E',
  '#FF964C',
  '#644571',
  '#315495',
  '#327811',
  '#735F22',
  '#635B83',
  '#1D293F',
  '#295216',
  '#7B3A0D',
  '#471E58'
];

const ChartComponent = ({ loading, type, title, data, showLegend = false, onClick }: Props) => {
  const canvasRef = useRef(null);

  const colors = data.reduce((acc: string[], row) => {
    if (row.color) {
      acc.push(row.color);
    }
    return acc;
  }, []);

  const backgroundColor = useMemo(() => {
    if (colors.length) {
      return colors;
    }
    return type === 'doughnut' ? chartColors : monochrome;
  }, [colors, type]);

  useEffect(() => {
    if (!canvasRef.current) return;

    const chart = new Chart(canvasRef.current, {
      type,
      data: {
        labels: data.map((row) => row.label),
        datasets: [
          {
            label: title,
            data: data.map((row) => row.value),
            backgroundColor
          }
        ]
      },
      options: {
        onHover: (event, chartElement) => {
          if (onClick) {
            // @ts-ignore
            // eslint-disable-next-line
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          }
        },
        onClick: (_event, elements, chart) => {
          if (!onClick) return;
          if (!elements || !elements.length) return;
          if (!chart.data.labels) return;
          if (!chart.data.datasets[0]?.data) return;

          const i = elements[0].index;
          onClick(chart.data.labels[i] as string, chart.data.datasets[0].data[i] as number);
        },
        responsive: true,
        plugins: {
          title: {
            display: !!title,
            text: title
          },
          legend: {
            display: showLegend
          }
        }
      }
    });

    return () => chart.destroy();
  }, [canvasRef, title, data, type, showLegend, colors, backgroundColor, onClick]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div style={{ position: 'relative', height: '50vh', width: '50vw' }} className="max-w-screen-md">
      <canvas ref={canvasRef} />
    </div>
  );
};

export default ChartComponent;
