import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';
import { WithStyles } from '@material-ui/core/styles';
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { gql, useQuery } from '@apollo/client';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import isValidNam from 'app/validations/act/nam/namValidation';
import RAMQCodes from 'shared/ramq/RamqCodes';
import { getActCodesDataWithSpecialtyOverrides } from '../../../../shared/data/actCodesData';
import CodeSelector from '../../../components/CodeSelector/CodeSelector';
import ErrorPopup from '../../../components/Errors/ErrorPopup/ErrorPopup';
import FormRow from '../../../components/Form/FormRow/FormRow';
import ActCodeSearchDialog from '../../../favorite/actCode/ui/ActCodesSearchDialog';
import SelectedActCodes from '../SelectedActCodes/SelectedActCodes';
import RecommendationsPrompt from '../RecommendationsPrompt/RecommendationsPrompt';
import { selectUserProfileInContext } from '../../User/selectors';
import { selectNam, selectSelectedDate } from '../selectors';

export const styles = (theme) =>
  ({
    formElement: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '100%'
    },
    codesRow: {
      alignItems: 'flex-start',
      justifyContent: 'start'
    },
    chipSelector: {
      flex: 1,
      marginRight: 20,
      position: 'relative'
    },
    searchButtonContainer: {
      height: 38,
      paddingTop: 10
    },
    searchButton: {
      minWidth: '40px',
      padding: 0,
      marginRight: theme.spacing(1)
    },
    container: {
      marginBottom: theme.spacing(3),
      overflow: 'visible'
    }
  }) as const;

const MAXIMUM_NUMBER_OF_SELECTED_CODE = 10;

export interface Props extends WithStyles<typeof styles>, WrappedFieldArrayProps<any> {
  userProfile: {
    id: string;
    activityRecommendationsEnabled?: boolean;
  };
  nam: string;
  selectedDate: number;
  disabled?: boolean;
}

const SEARCH_PATIENT_ACTIVITIES_QUERY = gql`
  query FetchPatientActivities($query: GetActivitiesInput!) {
    activitiesResult(query: $query) {
      activities {
        id
        type
        codes {
          code
        }
      }
    }
  }
`;

interface ActivitiesResultQuery {
  activitiesResult: {
    activities: Array<{
      id: string;
      type: ActivityType;
      codes: Array<{
        code: string;
      }>;
    }>;
  };
}

export const ActCodeSelector: React.FunctionComponent<Props> = ({
  classes,
  fields: reduxFormFields,
  disabled,
  meta,
  userProfile,
  nam,
  selectedDate
}) => {
  const [codeDialogOpen, setCodeDialogOpen] = useState(false);
  const [showTooManyCodesWarning, setShowTooManyCodesWarning] = useState(false);

  const { data } = useQuery<ActivitiesResultQuery>(SEARCH_PATIENT_ACTIVITIES_QUERY, {
    variables: {
      query: {
        nams: [nam],
        userIds: [userProfile.id]
      }
    },
    skip: !isValidNam(nam)
  });

  const handleAddActCodes = useCallback(
    (actCodes) => {
      if (reduxFormFields.length + actCodes.length > MAXIMUM_NUMBER_OF_SELECTED_CODE) {
        setShowTooManyCodesWarning(true);
      } else {
        actCodes.forEach((actCode) => {
          reduxFormFields.push({
            id: shortid.generate(),
            ...actCode
          });
        });
        setCodeDialogOpen(false);
      }
    },
    [reduxFormFields]
  );

  const openCodeSearchDialog = () => {
    setCodeDialogOpen(true);
  };

  const handleCodeDelete = (_reduxFormField, index) => {
    reduxFormFields.remove(index);
  };

  const actCodes = (reduxFormFields.getAll() as any) || [];

  useEffect(() => {
    const interpretCode = RAMQCodes.get('15761');
    const shouldAddInterpret =
      interpretCode &&
      RAMQCodes.isCodeEffectiveOn(interpretCode, selectedDate) &&
      !actCodes.some(({ code }) => code === interpretCode.code) &&
      (data?.activitiesResult.activities || []).some((activity) =>
        activity.codes.some(({ code }) => code === interpretCode.code)
      );

    if (!shouldAddInterpret) return;

    handleAddActCodes([interpretCode]);
    /* IMPORTANT NOTE
     * We have disabled the rule, in order to not execute the effect when actCodes or handleAddActCodes
     * causes the component to re-render. This should do the job for now, however we must at all costs apply
     * a refactoring on this component to re-enable the validation rule according to the dependency list.
     * Otherwise it could eventually bite us in the ass. Ideally the interpreter logic should be part of
     * the ActForm, but right now that's not possible because it's a class component that doesn't
     * support React hooks.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedDate]);

  const codes = actCodes.map((actCode) => actCode.code);

  return (
    <>
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
      <ErrorPopup
        onClose={() => setShowTooManyCodesWarning(false)}
        isOpen={showTooManyCodesWarning}
        message={`Vous ne pouvez pas ajouter plus de ${MAXIMUM_NUMBER_OF_SELECTED_CODE} codes`}
      />
      <ActCodeSearchDialog
        open={codeDialogOpen}
        maximumNumberOfSelectedCode={MAXIMUM_NUMBER_OF_SELECTED_CODE}
        onClose={() => setCodeDialogOpen(false)}
        onConfirm={handleAddActCodes}
      />
      <FormRow className={classes.codesRow}>
        <CodeSelector
          disabled={disabled}
          codesData={getActCodesDataWithSpecialtyOverrides()}
          onHandleSelect={handleAddActCodes}
          className={classes.chipSelector}
        />
        <div className={classes.searchButtonContainer}>
          <Button
            disabled={disabled}
            data-testid="search-button"
            className={classes.searchButton}
            variant="contained"
            onClick={() => openCodeSearchDialog()}
          >
            <SearchIcon />
          </Button>
        </div>
      </FormRow>
      {reduxFormFields.length > 0 ? (
        <FormRow>
          <SelectedActCodes actCodes={actCodes} onDelete={handleCodeDelete} />
        </FormRow>
      ) : (
        ''
      )}

      {userProfile.activityRecommendationsEnabled && <RecommendationsPrompt codes={codes} />}
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfileInContext(),
  nam: selectNam(),
  selectSelectedDate: selectSelectedDate()
});

export default compose<typeof React.Component>(withStyles(styles), connect(mapStateToProps))(ActCodeSelector);
