import React from 'react';
import { compose } from 'redux';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Theme } from '@material-ui/core';
import Props from './props';
import {
  selectUserBillingType,
  selectUserDefaultGroupNumber,
  selectUserOriginalPoolNumber
} from '../../../containers/User/selectors';
import UserBillingType from '../../../../shared/domain/user/UserBillingType';
import BillingType from '../../../../shared/domain/billing/model/BillingType';

const createBillingTypeLabelText = ({ activity, userDefaultBillingGroupNumber, userDefaultPoolNumber }) => ({
  pool: `${activity?.billingType} - ${activity?.poolNumber || userDefaultPoolNumber || 'N/A'}`,
  priv: userDefaultBillingGroupNumber
    ? `${activity?.billingType} - ${userDefaultBillingGroupNumber}`
    : `${activity?.billingType}`,
  end: `${activity?.billingType} - ${activity?.poolNumber || userDefaultPoolNumber || 'N/A'}`
});

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  container: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.spacing(13),
    textTransform: 'uppercase'
  },
  dense: {
    minWidth: theme.spacing(7),
    padding: theme.spacing(0.5)
  },
  [BillingType.POOL]: {
    backgroundColor: theme.palette.common.black
  },
  [BillingType.PRIV]: {
    backgroundColor: theme.palette.primary[500]
  },
  [BillingType.END]: {
    backgroundColor: theme.palette.error[500]
  }
});

const BillingTypeComponent: React.FunctionComponent<Props> = ({
  classes,
  className,
  activity,
  userBillingType,
  userDefaultBillingGroupNumber,
  userDefaultPoolNumber
}): JSX.Element | null => {
  const computedClass = classNames(classes.container, classes.dense, className, {
    [classes[activity?.billingType]]: true
  });
  const labelText = createBillingTypeLabelText({
    activity,
    userDefaultBillingGroupNumber,
    userDefaultPoolNumber
  })[activity?.billingType];

  if (userBillingType && userBillingType === UserBillingType.POOL) {
    return (
      <Paper className={computedClass}>
        <Typography color="inherit" variant="caption">
          {labelText}
        </Typography>
      </Paper>
    );
  }

  return null;
};

export const mapStateToProps = createStructuredSelector({
  userBillingType: selectUserBillingType(),
  userDefaultBillingGroupNumber: selectUserDefaultGroupNumber(),
  userDefaultPoolNumber: selectUserOriginalPoolNumber()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(BillingTypeComponent);
