import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';

export const TELEMEDICINE_OR_REMOTE_ACTIVITY_TYPES = [
  { label: 'Téléphone', value: 'phone' },
  { label: 'Visio', value: 'visio' }
];

const associatedFields: AssociatedField[] = [
  {
    label: 'Télémédecine',
    getName: (index) => `codes[${index}].telemedicineOrRemoteType`,
    type: SingleSelectCheckboxGroup,
    list: TELEMEDICINE_OR_REMOTE_ACTIVITY_TYPES,
    notRequired: true
  }
];

const telemedicine: RequiredDetail = {
  associatedFields
};

export default telemedicine;
