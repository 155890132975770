import CardHeader from '@material-ui/core/CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import processString from 'react-process-string';
import openUrl from 'application/utilities/open-url';
import { Button } from '@material-ui/core';
import useTranslate from 'application/hooks/use-translate';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';

export const styles = (theme) => ({
  correctionContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[300],
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    wordBreak: 'break-word'
  },
  link: {
    color: theme.palette.primary[500]
  },
  correctionButton: {
    display: 'flex',
    justifyContent: 'end'
  }
});

export function CorrectionDisplay({ input, status, classes, onAcknowledgeCorrectionClick }) {
  const translate = useTranslate('act-form');
  const isLastCorrectionAcknowledgeable =
    input.value && input.value.length > 0 ? input.value[input.value.length - 1].isAcknowledgeable : false;
  const lastCorrection = input.value && input.value.length > 0 ? input.value[input.value.length - 1].content : '';
  const processStringConfig = [
    {
      regex: /\[([^[\]]*)]\((.*?)\)/gim,
      fn: (key, result) => (
        <a key={key} href={result[2]} className={classes.link} onClick={() => openUrl(result[2])}>
          {result[1]}
        </a>
      )
    },
    {
      regex: /_\*{1}(.+?)\*_{1}/gim,
      fn: (key, result) => (
        <u>
          <strong>{result[1]}</strong>
        </u>
      )
    },
    {
      regex: /\*{1}(.+?)\*{1}/gim,
      fn: (key, result) => <strong>{result[1]}</strong>
    },
    {
      regex: /_{1}(.+?)_{1}/gim,
      fn: (key, result) => <u>{result[1]}</u>
    }
  ];
  const processedString = processString(processStringConfig)(lastCorrection);

  return (
    lastCorrection &&
    status === ActivityStatus.NEED_FIX && (
      <CardHeader
        className={classes.correctionContainer}
        title={
          <Typography color="error" variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
            {processedString}
          </Typography>
        }
        subheader={
          isLastCorrectionAcknowledgeable && (
            <div className={classes.correctionButton}>
              <Button
                id="accept-correction"
                onClick={() => onAcknowledgeCorrectionClick()}
                variant="contained"
                color="primary"
              >
                {translate('accept-and-send')}
              </Button>
            </div>
          )
        }
      />
    )
  );
}

CorrectionDisplay.propTypes = {
  input: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  onAcknowledgeCorrectionClick: PropTypes.func.isRequired
};

export default compose(withStyles(styles))(CorrectionDisplay);
