import PropTypes from 'prop-types';
import React from 'react';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import FormElement from '../../components/Form/FormElement/FormElement';
import AdditionalTransportContextElement from './AdditionalTransportContextElement';

function RestrictedAdditionalTransportContextElement({ fieldName, label, list }) {
  return (
    <Restrict atLeastRole={Role.Agent}>
      <FormElement
        name={fieldName ? `${fieldName}.contextElements` : 'contextElements'}
        component={AdditionalTransportContextElement}
        label={label}
        list={list}
      />
    </Restrict>
  );
}

RestrictedAdditionalTransportContextElement.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired
};

RestrictedAdditionalTransportContextElement.defaultProps = {
  fieldName: undefined
};

export default RestrictedAdditionalTransportContextElement;
