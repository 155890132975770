import React from 'react';
import withStyles, { StyleRulesCallback, WithStyles } from '@material-ui/core/styles/withStyles';
import { Paper, Theme } from '@material-ui/core';
import {
  ACTIVITIES_ACT_TYPE,
  ACTIVITIES_LUMP_SUM_TYPE,
  ACTIVITIES_MIXTE_TYPE
} from '../../../../../../shared/collection/collectionConstants';
import PeriodActs from '../../classes/PeriodActs';
import PaymentRequestReport from '../PaymentRequestReport/PaymentRequestReport';
import BillingTypeSelector from '../../../../../components/BillingTypeSelector';
import { computeExpectedAmountForActivities } from '../../../../../../shared/amountComputation/computeExpectedAmount';
import { PeriodByActivities } from '../../../shared/type';
import BillingType from '../../../../../../shared/domain/billing/model/BillingType';

export const styles: StyleRulesCallback<Theme, Props> = () => ({
  selectorContainer: {
    width: '100%',
    maxWidth: 800,
    marginBottom: '16px'
  }
});

export interface Props extends WithStyles {
  period: PeriodByActivities;
  preparedActivities: {
    [ACTIVITIES_ACT_TYPE]: any[];
    [ACTIVITIES_MIXTE_TYPE]: any[];
    [ACTIVITIES_LUMP_SUM_TYPE]: any[];
  };
  PrintButton: React.ReactNode;
  availableBillingTypes?: string[];
  pool?: boolean;
  individual?: boolean;
}

interface State {
  billingType: string;
}

export class BillingTypeGroupablePaymentRequestReport extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      billingType: BillingType.POOL
    };
  }

  getLumpSumActivities(activities) {
    const { billingType } = this.state;
    return activities[ACTIVITIES_LUMP_SUM_TYPE].filter(this.isBilledAs(billingType));
  }

  getMixteActivities(activities) {
    const { billingType } = this.state;
    return activities[ACTIVITIES_MIXTE_TYPE].filter(this.isBilledAs(billingType));
  }

  getActsForPreparedActivities(activities, selectedPeriod) {
    const { billingType } = this.state;
    return new PeriodActs(
      selectedPeriod[ACTIVITIES_ACT_TYPE],
      activities[ACTIVITIES_ACT_TYPE].filter(this.isBilledAs(billingType))
    );
  }

  private isBilledAs(billingType: string) {
    return (activity) => {
      if (billingType === BillingType.PRIV) {
        return [undefined, BillingType.PRIV].includes(activity.billingType);
      }

      return activity.billingType === billingType;
    };
  }

  private calculateTotalByType(preparedActivities, billingType) {
    return computeExpectedAmountForActivities(
      preparedActivities[ACTIVITIES_ACT_TYPE].filter(this.isBilledAs(billingType)).concat(
        preparedActivities[ACTIVITIES_LUMP_SUM_TYPE].filter(this.isBilledAs(billingType)),
        preparedActivities[ACTIVITIES_MIXTE_TYPE].filter(this.isBilledAs(billingType))
      )
    );
  }

  render() {
    const { availableBillingTypes, period, preparedActivities, classes, ...rest } = this.props;
    const { billingType } = this.state;

    const total = this.calculateTotalByType(preparedActivities, billingType);

    return (
      <>
        <span className={classes.selectorContainer}>
          <Paper square>
            <BillingTypeSelector
              billingTypes={availableBillingTypes}
              value={billingType}
              onChange={(newBillingType) => this.setState({ billingType: newBillingType })}
            />
          </Paper>
        </span>
        {/* @ts-ignore */}
        <PaymentRequestReport
          acts={this.getActsForPreparedActivities(preparedActivities, period)}
          lumpSum={this.getLumpSumActivities(preparedActivities)}
          mixte={this.getMixteActivities(preparedActivities)}
          total={total}
          pool
          {...rest}
        />
      </>
    );
  }
}

export default withStyles(styles)(BillingTypeGroupablePaymentRequestReport) as typeof React.Component;
