/* eslint-disable no-shadow, no-unused-vars */
enum ActivityType {
  ACT = 'act',
  MIXTE = 'mixte',
  LUMP_SUM = 'lumpSum',
  TRAVEL_EXPENSES = 'travelExpenses',
  TRAINING = 'training'
}

export default ActivityType;
