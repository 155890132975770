import { call, put } from 'redux-saga/effects';
import { logDefault, logError } from '../../../../../../shared/utils/plog';
import { MULTI_ACT_FILE_TYPE } from '../constants';
import { trackErrorInFirestore } from '../../../../../firebase/document/documentSagas';
import storageUploader from '../../../../../containers/Storage/uploader/StorageUploader';
import actPictureRepository from '../../../../../containers/Storage/cordova/actPictureRepository';
import { buildFromFileName } from '../../../../../containers/Storage/cordova/actFileBuilder';
import updatePictureInMultiActForm from '../actions';

export default function* multiActFileSuccessfullyUploaded(action) {
  const { downloadUrl, uploadTask } = action;

  try {
    if (uploadTask.type === MULTI_ACT_FILE_TYPE) {
      logDefault({
        type: 'saga',
        text: 'MultiActDialogSagas/actFileSuccessfullyUploaded',
        array: ['Updating picture in act form']
      });
      yield put(updatePictureInMultiActForm(downloadUrl, uploadTask));
    }

    yield* removeTemporaryFileFromDeviceLocalStorage(uploadTask.fileName, uploadTask.type);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'MultiActDialogSagas/actFileSuccessfullyUploaded',
      array: ['actFileSuccessfullyUploaded ERROR', uploadTask, downloadUrl, e]
    });
    yield* trackErrorInFirestore(e, { type: 'ActFileSuccessfullyUploadedError' });
  } finally {
    if (uploadTask.type === MULTI_ACT_FILE_TYPE) {
      storageUploader.completeUploadProcessForFile(uploadTask.fileName);
    }
  }
}

function* removeTemporaryFileFromDeviceLocalStorage(fileName, fileType) {
  const actFile = buildFromFileName(fileName);
  if (fileType === MULTI_ACT_FILE_TYPE) {
    logDefault({
      type: 'saga',
      text: 'MultiActDialogSagas/removeTemporaryFileFromDeviceLocalStorage',
      array: ['Removing temporary picture', fileName]
    });

    yield call([actPictureRepository, actPictureRepository.removeOldPicturesForAct], actFile);
  }
}
