import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import EditActivity from '../../../../../components/Activities/EditActivity/EditActivity';
import SafeActivityByIdFetcher from '../../../../ActivitiesSearch/SafeActivityByIdFetcher';

export const styles = () => ({
  container: {
    flex: 1,
    maxWidth: 900,
    overflowY: 'auto',
    height: 'calc(100vh - 160px)',
    top: '0'
  },
  activityDetailWrapper: {
    padding: '16px',
    paddingTop: 0,
    position: 'sticky',
    top: 0
  },
  activityInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  withSpaceBetween: {
    justifyContent: 'space-between'
  },
  formCard: {
    marginTop: 0,
    maxWidth: 'unset'
  }
});

export function AdminActivityDetail({ activities, classes }) {
  const navigate = useNavigate();
  const { activityId } = useParams();
  const location = useLocation();

  return (
    <div className={classes.container}>
      <SafeActivityByIdFetcher
        activityId={activityId}
        activities={activities}
        render={({ loading, activity, activityIsInProvidedList, error }) => (
          <>
            {loading && <Typography>Récupération du document...</Typography>}
            {!loading && error && <Typography>Ce document n'existe pas...</Typography>}
            {!loading && activity && (
              <div className={classes.activityDetailWrapper}>
                <div
                  className={classNames(classes.activityInfo, {
                    [classes.withSpaceBetween]: !activityIsInProvidedList
                  })}
                >
                  {!activityIsInProvidedList && (
                    <Typography>Attention - Cette activité ne fait pas partie de cette liste filtrée</Typography>
                  )}
                  <IconButton onClick={() => navigate({ pathname: '..', search: location.search })}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <EditActivity activity={activity} returnUrl="" formClassesOverride={{ card: classes.formCard }} />
              </div>
            )}
          </>
        )}
      />
    </div>
  );
}

AdminActivityDetail.propTypes = {
  activities: PropTypes.array.isRequired
};

export default withStyles(styles)(AdminActivityDetail);
