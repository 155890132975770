import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { objectElementToLowerCase } from '../../../../../../../shared/utils/objectManipulation';
import FavoriteItem from '../../../../../../containers/PlacesPreferencesDialog/FavoriteItem/FavoriteItem';
import CollapsableCategoryTree from '../../../../../../shared/category/ui/CollapsableCategoryTree/CollapsableCategoryTree';
import { transformToCategorizableTree } from '../../../../../../shared/editable/domain/CategorizableCollectionTransformer';

import CategoryItem from '../../../../domain/CategoryItem';
import ItemAndCategoriesMapping from '../../../../../../shared/editable/domain/ItemAndCategoriesMapping';

export interface Props {
  favorites: ItemAndCategoriesMapping;
  advancedModeEnabled: boolean;
  /* eslint-disable no-unused-vars */
  onSelectItem: (item: CategoryItem) => void;
  isItemSelected?: (item: CategoryItem) => boolean;
  /* eslint-enable */
}

export class BasicEditor extends React.PureComponent<Props> {
  getItemsAndCategoriesInRightOrder(favorites, hasEditableFavoritesEnabled) {
    const { itemsWithoutCategory, categories } = transformToCategorizableTree(favorites);

    let orderedItemsWithoutCategories = itemsWithoutCategory.filter((item) => item !== undefined);

    if (!hasEditableFavoritesEnabled) {
      orderedItemsWithoutCategories = orderBy(
        orderedItemsWithoutCategories,
        [objectElementToLowerCase('description')],
        ['asc']
      );
    }

    return {
      categories,
      orderedItemsWithoutCategories
    };
  }

  renderItem(isCodeSelected, onSelectCode) {
    return (item: CategoryItem) => (
      <FavoriteItem
        key={item.code}
        chipNumber={item.code}
        selected={isCodeSelected(item)}
        primaryText={<Typography>{item.description}</Typography>}
        secondaryText=""
        withMarkup={false}
        onItemSelect={() => onSelectCode(item)}
      />
    );
  }

  render() {
    const { favorites, onSelectItem, isItemSelected = () => false, advancedModeEnabled } = this.props;

    const { categories, orderedItemsWithoutCategories } = this.getItemsAndCategoriesInRightOrder(
      favorites,
      advancedModeEnabled
    );

    return (
      <List dense>
        {orderedItemsWithoutCategories.map(this.renderItem(isItemSelected, onSelectItem))}
        <CollapsableCategoryTree
          initiallyOpen={false}
          categories={categories}
          renderItem={this.renderItem(isItemSelected, onSelectItem)}
        />
      </List>
    );
  }
}

export default BasicEditor;
