import { createSelector } from '@reduxjs/toolkit';

const selectActivitiesSearch = (state) => state.activitiesSearch;

export const selectSearchingActivities = () =>
  createSelector(selectActivitiesSearch, (state) => state.searchingActivities);

export const selectSearchingSpecificActivity = () =>
  createSelector(selectActivitiesSearch, (state) => state.searchingSpecificActivity);

export const selectSelectedActivity = () =>
  createSelector(selectActivitiesSearch, (state) => state.selectedActivity && state.selectedActivity);

export const selectSearchingActivityError = () => createSelector(selectActivitiesSearch, (state) => state.error);
