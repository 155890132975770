import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { sortBy } from 'lodash';
import classnames from 'classnames';
import UserHistory from 'application/pages/profile/user-history';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { can } from 'app/act/shared/domain/roles/roles.utils';
import UserRegistration from 'application/pages/profile/user-registration';
import DevicesInfo from 'application/pages/profile/devices-info';
import { GeneralInformations } from './Partials/GeneralInformations';
import BillingGroupSection from './Partials/BillingGroupSection';
import { Medavie } from './Partials/Medavie';
import { Places } from './Partials/Places';
import { AdditionalInformations } from './Partials/AdditionalInformations';
import { Agencies } from './Partials/Agencies';
import { Configuration } from './Partials/Configuration';
import { Fees } from './Partials/Fees';
import { Subscription } from './Partials/Subscription';
import FeaturesConfiguration from './FeaturesConfiguration/FeatureConfiguration';
import { Ramq } from './Partials/Ramq';

export const styles = (theme) => ({
  container: {
    maxWidth: 920,
    width: '100%'
  },
  readonly: {
    background: '#F0F0F0'
  },
  card: {
    height: 'max-content',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  row: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  disabledInput: {
    '& *': {
      color: 'initial'
    }
  },
  actions: {
    justifyContent: 'center',
    marginBottom: theme.spacing(5)
  }
});

export class Profile extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.props.initialize({
      actCodeSpecialtyListChoice: this.props.user.specialty,
      ...this.props.user,
      absences: this.sortAbsencesInDescendingOrder(this.props.user.absences)
    });

    this.state = {};
  }

  sortAbsencesInDescendingOrder = (absences) => (absences ? sortBy(absences, 'startDate').reverse() : absences);

  render() {
    const { placesPreferences, user, userBillingType, userRole, handleSubmit, onSave, classes, ocrEnabled, change } =
      this.props;
    const userCantEditAgencies = !can('users.profile.agencies.edit');
    const userCantEditConfiguration = !can('users.profile.configuration.edit');
    const userCantEditFees = !can('users.profile.fees.edit');
    const userCantEditSubsciption = !can('users.profile.subscription.edit');

    return (
      <div className={classes.container}>
        <Paper className={classes.card} elevation={1}>
          <UserRegistration userId={user.id} practiceNumber={user.practiceNumber} />
        </Paper>

        <Paper className={classes.card} elevation={1}>
          <GeneralInformations />
        </Paper>

        <Paper className={classes.card} elevation={1}>
          <BillingGroupSection groups={user.groups} pools={user.pools} />
        </Paper>

        <Paper className={classes.card} elevation={1}>
          <Medavie />
        </Paper>

        <Paper className={classes.card} elevation={1}>
          <Ramq userId={user.id} />
        </Paper>

        <Paper className={classes.card} elevation={1}>
          <Places placesPreferences={placesPreferences} />
        </Paper>

        <Paper className={classes.card} elevation={1}>
          <AdditionalInformations />
        </Paper>

        <Paper className={classnames(classes.card, { [classes.readonly]: userCantEditAgencies })} elevation={1}>
          <Agencies userProfile={user} readonly={userCantEditAgencies} />
        </Paper>

        <Paper className={classnames(classes.card, { [classes.readonly]: userCantEditConfiguration })} elevation={1}>
          <Configuration
            readonly={userCantEditConfiguration}
            userRole={userRole}
            userId={user.id}
            billingType={userBillingType}
            change={change}
          />
        </Paper>

        <Paper className={classnames(classes.card, { [classes.readonly]: userCantEditFees })} elevation={1}>
          <Fees readonly={userCantEditFees} />
        </Paper>

        <Paper className={classnames(classes.card, { [classes.readonly]: userCantEditSubsciption })} elevation={1}>
          <Subscription />

          <Grid item xs={12} sm={12} style={{ marginTop: '34px' }}>
            <Typography gutterBottom variant="h6">
              Indicateurs de fonctionnalité
            </Typography>

            <FeaturesConfiguration ocrEnabled={ocrEnabled} change={change} readonly={userCantEditSubsciption} />
          </Grid>
        </Paper>

        <Restrict atLeastRole={Role.Admin}>
          <Paper className={classes.card} elevation={1}>
            <DevicesInfo userId={user.id} />
          </Paper>
        </Restrict>

        <Restrict atLeastRole={Role.Developer}>
          <UserHistory userId={user.id} />
        </Restrict>

        <CardActions className={classes.actions}>
          <Button id="send-act-button" onClick={handleSubmit(onSave)} variant="contained" color="primary">
            Sauvegarder
          </Button>
        </CardActions>
      </div>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object,
  ocrEnabled: PropTypes.bool,
  userBillingType: PropTypes.string,
  userRole: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  placesPreferences: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired
};

Profile.defaultProps = {
  user: {},
  ocrEnabled: false,
  userBillingType: undefined,
  userRole: undefined
};

const mapStateToProps = (state) => {
  const ocrEnabled = selector(state, 'ocrEnabled');
  const userBillingType = selector(state, 'billingType');
  const userRole = selector(state, 'role');

  return {
    ocrEnabled,
    userBillingType,
    userRole
  };
};

const selector = formValueSelector('userInfoForm');

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'userInfoForm'
  }),
  connect(mapStateToProps)
)(Profile);
