import { gql } from '@apollo/client';
import apolloClient, { removeTypenames } from 'utils/apolloClient';

export interface Vertex {
  x: number;
  y: number;
}

export interface ExtractedNam {
  nam: string;
  isValid: boolean;
  confidence: number;
  vertexes: Vertex[];
}

export default class VisionService {
  public static async fetchExtractedNams(base64Content: string) {
    try {
      if (!navigator.onLine) {
        return [];
      }

      const { data } = await apolloClient.query<{ extractedNams: ExtractedNam[] }>({
        query: gql`
          query ExtractNams($base64Content: String!) {
            extractedNams: extractNams(base64Content: $base64Content) {
              nam
              isValid
              confidence
              vertexes {
                x
                y
              }
            }
          }
        `,
        variables: {
          base64Content
        }
      });

      return removeTypenames<ExtractedNam>(data.extractedNams);
    } catch (_error) {
      return [];
    }
  }
}
