/* eslint-disable @typescript-eslint/no-unused-vars */
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';
import React, { useState } from 'react';
import { TextField, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { useDebounce } from 'react-use';
import omitBy from 'lodash/omitBy';
import { DirectoryPhysicianType } from 'app/containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import MaxSearchReachedDialogActions from '../../../../components/Dialogs/DialogActionsWithMessage/MaxSearchReachedDialogActions';
import DoctorSuggestion from '../DoctorSuggestion/DoctorSuggestion';
import isDoctorInDoctorsPreferences from '../../../domain/doctorPreferences';
import SearchSuggestions from '../../../../containers/SearchSuggestions/SearchSuggestions';

export const styles = (theme) => ({
  searchContainer: {
    'maxHeight': '80vh',
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  },
  suggestionList: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch'
  }
});

const omitTypenames = (directoryPhysician) => ({
  ...omitBy(directoryPhysician, (_value, key) => key === '__typename'),
  specialties: directoryPhysician.specialties.map((specialty) =>
    omitBy(specialty, (_value, key) => key === '__typename')
  )
});

export function DoctorsRegistrySearchSuggestions({
  classes,
  onClickSuggestion,
  onClickFavorite,
  onClose,
  doctorsPreferences,
  type
}) {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');

  const SEARCH_DIRECTORY_PHYSICIAN = gql`
    query SearchDirectoryPhysicians($searchTerm: String!, $type: DirectoryPhysicianType) {
      directoryPhysicians(searchTerm: $searchTerm, type: $type, limit: 21) {
        id
        practiceNumber
        firstName
        lastName
        facility
        isResident
        specialties {
          label
        }
      }
    }
  `;

  const { data } = useQuery(SEARCH_DIRECTORY_PHYSICIAN, {
    variables: { searchTerm: debouncedInputValue, type },
    skip: !debouncedInputValue
  });

  const options = data?.directoryPhysicians.map((directoryPhysician) => omitTypenames(directoryPhysician)) || [];

  useDebounce(() => setDebouncedInputValue(inputValue.replace(',', '')), 300, [inputValue]);

  return (
    <>
      <DialogContent className={classes.searchContainer}>
        <TextField
          onChange={(e) => setInputValue(e.target.value)}
          label={
            <FormattedMessage
              id={type === DirectoryPhysicianType.RESIDENT ? 'residents.searchLabel' : 'doctors.searchLabel'}
            />
          }
        />
        <SearchSuggestions
          className={classes.suggestionList}
          hits={options}
          onClick={onClickSuggestion}
          onClickFavorite={onClickFavorite}
          isInFavoritesFunction={(doctor) => isDoctorInDoctorsPreferences(doctor, doctorsPreferences)}
          SuggestionItemComponent={DoctorSuggestion}
          getKeyFunction={(suggestion) => suggestion.practiceNumber}
        />
      </DialogContent>
      <MaxSearchReachedDialogActions maxSearchNumber={20} hitsCount={options.length}>
        <Button id="close-Dialog" onClick={onClose} color="primary">
          <FormattedMessage id="global.close" />
        </Button>
      </MaxSearchReachedDialogActions>
    </>
  );
}

DoctorsRegistrySearchSuggestions.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickSuggestion: PropTypes.func.isRequired,
  onClickFavorite: PropTypes.func.isRequired,
  doctorsPreferences: PropTypes.array.isRequired,
  type: PropTypes.string
};

export default withStyles(styles)(DoctorsRegistrySearchSuggestions);
