import { getRequiredDetailsAssociatedWithCode } from '../codeToRequiredDetailsMapping';
import ActCodes from './index';
import { RequiredDetails } from '../requiredDetail.type';

export type NestedCode = { code: string };

class ActCodeRequiredDetailsFactory {
  static create(nestedCode: NestedCode, act: any): RequiredDetails {
    const ActCodeClassName = `ActCode${nestedCode.code}`;

    // @ts-ignore
    const DynamicActCode = ActCodes[ActCodeClassName];

    if (!act || !DynamicActCode) {
      // @ts-ignore
      return getRequiredDetailsAssociatedWithCode(nestedCode, act.date);
    }

    const actCode = new DynamicActCode(act);
    return actCode.getRequiredDetails();
  }
}

export default ActCodeRequiredDetailsFactory;
