import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getActs24BeforeDateFailure, getActs24HoursBeforeDate } from '../../../../containers/ActForm/actions';
import { selectActs24HoursLoaded, selectStartTime } from '../../../../containers/ActForm/selectors';
import ActsWarnings from '../ActsWarnings/ActsWarnings';

export class NamWithPrevious24HoursActsFetcher extends React.Component {
  componentDidMount() {
    this.fetchPreviousActIfStartTimeIsDefined();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startTime !== this.props.startTime) {
      this.fetchPreviousActIfStartTimeIsDefined();
    }
  }

  fetchPreviousActIfStartTimeIsDefined = () => {
    if (this.props.startTime) {
      this.props.getPrevious24HoursActs(this.props.startTime);
    } else {
      this.props.setPrevious24HoursActsFailure('Could not get previous act, no start date defined');
    }
  };

  render() {
    const { previousActsLoaded } = this.props;
    return previousActsLoaded ? <ActsWarnings /> : null;
  }
}

NamWithPrevious24HoursActsFetcher.propTypes = {
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  previousActsLoaded: PropTypes.bool.isRequired,
  getPrevious24HoursActs: PropTypes.func.isRequired,
  setPrevious24HoursActsFailure: PropTypes.func.isRequired
};

NamWithPrevious24HoursActsFetcher.defaultProps = {
  startTime: undefined
};

export const mapStateToProps = createStructuredSelector({
  startTime: selectStartTime(),
  previousActsLoaded: selectActs24HoursLoaded()
});

export const mapDispatchToProps = {
  getPrevious24HoursActs: getActs24HoursBeforeDate,
  setPrevious24HoursActsFailure: getActs24BeforeDateFailure
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NamWithPrevious24HoursActsFetcher);
