import moment from 'moment-timezone';
import Act, { isAct } from '../../../../shared/domain/activity/act/model/Act';
import Activity from '../../../../shared/domain/activity/global/model/Activity';
import { codeIsInRole2 } from '../../../../shared/domain/activity/act/service/actCodeUtils';
import { isEmpty } from '../../../../shared/utils/objectManipulation';
import { selectSpecialty } from '../../../containers/User/selectors';
import { getStore } from '../../../reduxStore/configureStore';

export const incompletePeriodWarning = (form: Partial<Activity>) => {
  const activity = form as Activity;

  if (isEmpty(activity) || !isAct(activity)) {
    return [];
  }

  const act = activity as Act;
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (
    moment(act.date).isAfter('2023-01-31') &&
    isPeriodIncomplete(act) &&
    act.codes.some((code) => codeIsInRole2(code, act, userSpecialty))
  ) {
    return ['ATTENTION - Seules les 15 minutes complétées sont payées. SVP modifier votre heure de fin si possible'];
  }

  return [];
};

const isPeriodIncomplete = (act: Act) => {
  if (!act.start || !act.end) {
    return false;
  }
  return moment(act.end).diff(moment(act.start), 'minutes') % 15 !== 0;
};

export default incompletePeriodWarning;
