import moment from 'moment-timezone';
import { isBilled, isNotBilledNotCanceled } from '../../../../../shared/models/utils/activitiesUtils';
import { computeExpectedAmountForActivity } from '../../../../../shared/amountComputation/computeExpectedAmount';
import isDefined from '../../../../../shared/utils/isDefined';

export default class ActsDay {
  constructor(day) {
    this.day = moment(day);
    this.billedActivities = [];
    this.notBilledActivities = [];
    this.ignoredActivities = [];
    this.totalAmount = 0;
  }

  addActivities(activities) {
    activities.forEach((activity) => this.addActivity(activity));
  }

  addActivity(activity) {
    if (this.isActivityIgnored(activity)) {
      this.ignoredActivities.push(activity);
      return;
    }

    this.calculateActivityAddition(activity);
  }

  isActivityIgnored(activity) {
    return !isBilled(activity) && !isNotBilledNotCanceled(activity);
  }

  calculateActivityAddition(activity) {
    if (isBilled(activity) || isDefined(activity.manualCorrection)) {
      this.addBilledActivity(activity);
    }

    if (isNotBilledNotCanceled(activity) && isDefined(activity.manualCorrection) === false) {
      this.addNotBilledActivity(activity);
    }

    this.totalAmount += parseFloat(computeExpectedAmountForActivity(activity));
  }

  addBilledActivity(activity) {
    this.billedActivities.push(activity);
  }

  addNotBilledActivity(activity) {
    this.notBilledActivities.push(activity);
  }

  get billedCount() {
    return this.billedActivities.length;
  }

  get notBilledCount() {
    return this.notBilledActivities.length;
  }
}
