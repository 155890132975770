import { useCallback, useState } from 'react';
import getActivityHistory from '../../usesCases/fetchActivityHistory';
import calculateDeltaBetweenTwoActivityVersions from '../../usesCases/calculateDeltaBetweenTwoActivityVersions/calculateDeltaBetweenTwoActivityVersions';

export default function useFetchActivityHistory(activityId) {
  const [versionsWithDeltas, setVersionsWithDeltas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchActivityHistory = useCallback(() => {
    setLoading(true);
    return getActivityHistory(activityId)
      .then((activityVersions) => {
        setVersionsWithDeltas(calculateDeltaBetweenTwoActivityVersions(activityVersions.reverse()));
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [activityId]);

  return { fetchActivityHistory, versionsWithDeltas, loading, error };
}
