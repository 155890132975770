import Act, { ActCode } from '../../../../../shared/domain/activity/act/model/Act';
import { AssociatedField } from '../../../requiredDetails/requiredDetail.type';
import billingByActForSedationAnalgesiaOnly from '../../../requiredDetails/BillingByActForSedationAnalgesiaOnly/billingByActForSedationAnalgesiaOnly';
import { RULE_28_ACT_CODES } from '../../../../../shared/ramq/requiredDetails/rule28';
import PlaceDomain from '../../../../../shared/domain/place/model/PlaceDomain';
import billingForSedationAnalgesiaOnly from '../../../requiredDetails/BillingForSedationAnalgesiaOnly/billingForSedationAnalgesiaOnly';
import { extractRule28ActCodesAndPg28WithPdtOrSurgeryNatureTypeActCodes } from './extractRule28ActCodesAndNotPg28WithPdtOrSurgeryNatureTypeActCodes';

function add7000XCodesBilledOnlyWith28CodesRuleAppender(act: Act, code: ActCode): (AssociatedField[] | undefined)[] {
  const { pg28WithPDTorSurgeryNatureType } = extractRule28ActCodesAndPg28WithPdtOrSurgeryNatureTypeActCodes(act);

  if (RULE_28_ACT_CODES.includes(code.code) && pg28WithPDTorSurgeryNatureType.size() === 0) {
    const place = PlaceDomain.fromDto(act.place);

    if (place.isBilledByAct()) {
      return [billingByActForSedationAnalgesiaOnly.associatedFields];
    }

    return [billingForSedationAnalgesiaOnly.associatedFields];
  }

  return [];
}

export default add7000XCodesBilledOnlyWith28CodesRuleAppender;
