import { GET_RAMQ_PLACES_FAILURE, GET_RAMQ_PLACES_SUCCESS } from './actions';

const places = [];
const loaded = false;
const errors = undefined;

export const initialState = { places, loaded, errors };

function ramqPlacesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RAMQ_PLACES_SUCCESS:
      return {
        ...state,
        places: action.ramqPlaces,
        loaded: true
      };
    case GET_RAMQ_PLACES_FAILURE:
      return {
        ...state,
        errors: action.error,
        loaded: true
      };
    default:
      return state;
  }
}

export default ramqPlacesReducer;
