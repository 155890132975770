import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import PeriodsPaymentRequestTable from '../../MixtePaymentRequest/PeriodsPaymentRequestTable';
import PoolPeriodsPaymentRequestTable from '../../MixtePaymentRequest/PoolPeriodsPaymentRequestTable';
import PeriodActsTable from '../PeriodActsTable/PeriodActsTable';
import ReportSummary from '../ReportSummary/ReportSummary';

export const styles = (theme) => ({
  paymentRequestReport: {
    width: '100%',
    maxWidth: '800px',
    padding: '16px',
    overflowX: 'auto'
  },
  reportSection: {
    marginBottom: theme.spacing(4)
  },
  safariWorkaround: {
    '-webkit-transform': 'translate3d(0,0,0)'
  },
  card: {
    '&:last-child': {
      marginBottom: 0
    }
  }
});

const getPeriodPaymentRequestComponent = (activities, pool, printButton, individual) =>
  pool && individual === false ? (
    <PoolPeriodsPaymentRequestTable activities={activities} PrintButton={printButton} />
  ) : (
    <PeriodsPaymentRequestTable activities={activities} PrintButton={printButton} />
  );

export function PaymentRequestReport({ acts, PrintButton, mixte, lumpSum, total, pool, individual, classes }) {
  return (
    <Paper className={classes.paymentRequestReport}>
      <Card className={classes.reportSection}>
        <CardContent>
          <ReportSummary total={total} />
        </CardContent>
      </Card>

      <Card className={classNames(classes.reportSection, classes.card)}>
        <PeriodActsTable acts={acts} pool={pool} individual={individual} PrintButton={PrintButton} />
      </Card>

      {mixte.length > 0 && (
        <Card className={classNames(classes.reportSection, classes.safariWorkaround, classes.card)}>
          {getPeriodPaymentRequestComponent(mixte, pool, PrintButton, individual)}
        </Card>
      )}

      {lumpSum.length > 0 && (
        <Card className={classNames(classes.reportSection, classes.safariWorkaround, classes.card)}>
          {getPeriodPaymentRequestComponent(lumpSum, pool, PrintButton, individual)}
        </Card>
      )}
    </Paper>
  );
}

PaymentRequestReport.defaultProps = {
  PrintButton: '',
  pool: false,
  individual: false
};

PaymentRequestReport.propTypes = {
  acts: PropTypes.object.isRequired,
  pool: PropTypes.bool,
  individual: PropTypes.bool,
  PrintButton: PropTypes.node,
  mixte: PropTypes.array.isRequired,
  lumpSum: PropTypes.array.isRequired,
  total: PropTypes.string.isRequired
};

export default withStyles(styles)(PaymentRequestReport);
