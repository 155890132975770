import get from 'lodash/get';
import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { InterventionSide } from '../../../../shared/ramq/requiredDetails/interventionSide';

export const getAssociatedFields = (configuration): AssociatedField[] => {
  const sideSelection: AssociatedField = {
    label: 'Côté',
    getName: (index) => `codes[${index}].interventionSide`,
    type: SingleSelectCheckboxGroup,
    list: [
      { label: 'Gauche', value: InterventionSide.LEFT },
      { label: 'Droit', value: InterventionSide.RIGHT }
    ]
  };
  const canBeBothSides = get(configuration, 'canBeBothSides', false);

  if (canBeBothSides) {
    sideSelection.list!.push({ label: 'Bilatéral', value: InterventionSide.BILATERAL });
  }

  return [sideSelection];
};

const interventionSide = (configuration): RequiredDetail => ({
  associatedFields: getAssociatedFields(configuration)
});

export default interventionSide;
