import { Composite } from 'spec-pattern';
import isDefined from '../../../../utils/isDefined';
import { isAct } from '../../../activity/act/model/Act';
import Activity from '../../../activity/global/model/Activity';
import NAM from '../../../ramq/NAM';

class ActBilledWithValidRamqPatientAndAtLeastOneCode extends Composite<Activity, unknown> {
  isSatisfiedBy(activity: Activity): boolean {
    return (
      isAct(activity) &&
      isDefined(activity.nam) &&
      NAM.isValidForV1(activity.nam as string) &&
      activity.codes.length > 0
    );
  }
}

export default ActBilledWithValidRamqPatientAndAtLeastOneCode;
