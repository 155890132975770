import React, { useEffect } from 'react';
import { change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import isValidNam from 'app/validations/act/nam/namValidation';
import { gql, useQuery } from '@apollo/client';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { CNESST_EVENT } from 'shared/domain/activity/act/PatientEvent';
import FormElement from '../../../components/Form/FormElement/FormElement';
import { ACT_FORM_NAME, actFormFields } from '../constants';
import messages from './messages';
import { DateField, PatientEventSelectField } from '../../../components/Form/Fields';
import { selectCurrentCodes, selectPatientEvent } from '../selectors';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { isCNESSTCode } from 'app/codeSpecificLogic/ui/rules/askEventDateForCNESSTCodes';
import FormRow from 'app/components/Form/FormRow/FormRow';

export const styles = (theme) => ({
  patientEventRow: {
    flexWrap: 'unset'
  },
  patientEventType: {
    flex: 'unset',
    paddingRight: theme.spacing(2),
    width: '75%'
  },
  patientEventDate: {
    marginBottom: theme.spacing(1) + 2 // No comments about the "+ 2", except that it aligns well with the left field
  }
});

const PATIENT_QUERY = gql`
  query FetchPatientLatestEvent($nam: String!, $userId: String!) {
    patient(nam: $nam, userId: $userId) {
      nam
      events {
        code
        date
      }
    }
  }
`;

export function PatientEvent({ actCodes, nam, user, patientEvent, classes, changeEventType, changeEventDate }) {
  const { data } = useQuery(PATIENT_QUERY, {
    variables: {
      nam,
      userId: user.id
    },
    skip: !isValidNam(nam)
  });

  const shouldUpdatePatientEvent =
    !patientEvent && data?.patient.events[0] && data?.patient.events[0].code === CNESST_EVENT.id;

  useEffect(() => {
    if (!shouldUpdatePatientEvent) return;
    changeEventType(data.patient.events[0].code);
    changeEventDate(moment(data.patient.events[0].date).valueOf());
  }, [shouldUpdatePatientEvent, data, changeEventType, changeEventDate]);

  useEffect(() => {
    if (!actCodes || actCodes.length === 0 || !actCodes.some(isCNESSTCode)) {
      return;
    }

    changeEventType(CNESST_EVENT.id);
  }, [changeEventType, actCodes]);

  return (
    <Restrict atLeastRole={Role.Agent}>
      <FormRow className={classes.patientEventRow}>
        <FormElement
          className={classes.patientEventType}
          name={`${actFormFields.patientEvent}.eventType`}
          label={<FormattedMessage {...messages.patientEventType} />}
          component={PatientEventSelectField}
        />
        <FormElement
          className={classes.patientEventDate}
          name={`${actFormFields.patientEvent}.eventDate`}
          label={<FormattedMessage {...messages.patientEventDate} />}
          deletable
          component={DateField}
        />
      </FormRow>
    </Restrict>
  );
}

PatientEvent.propTypes = {
  nam: PropTypes.string,
  user: PropTypes.object.isRequired,
  patientEvent: PropTypes.object,
  changeEventType: PropTypes.func.isRequired,
  changeEventDate: PropTypes.func.isRequired,
  actCodes: PropTypes.array
};

export const mapStateToProps = createStructuredSelector({
  patientEvent: selectPatientEvent(),
  actCodes: selectCurrentCodes()
});

export const mapDispatchToProps = (dispatch) => ({
  changeEventType: (type) => {
    dispatch(change(ACT_FORM_NAME, `${actFormFields.patientEvent}.eventType`, { id: type }));
  },
  changeEventDate: (date) => {
    dispatch(change(ACT_FORM_NAME, `${actFormFields.patientEvent}.eventDate`, date));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(PatientEvent);
