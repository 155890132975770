import { get, has } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import StatementReport from '../StatementReport/StatementReport';

export const sortSectionsByType = (sections) =>
  sections.sort((section1, section2) => {
    if (section1.type === section2.type) {
      return 0;
    }

    if (section1.type === 'personal') {
      return -1;
    }
    return 1;
  });

export class StatementRenderer extends React.Component {
  getGroupAndPersonalSections = () => {
    const { statements, selectedDate } = this.props;

    const selectedStatement = selectedDate
      ? statements.find((specificStatement) => specificStatement.statementDate === selectedDate)
      : statements[0];

    // Previous statement structure stored a maximum of two sections, one in selectedStatement.personal
    // and the other in selectedStatement.group
    // Now we have a single selectedStatement.sections key which is an array
    // Once everyone is migrated to the new version we can run a database migration and delete this if
    if (!has(selectedStatement, 'sections')) {
      const sections = [];
      if (has(selectedStatement, 'personal')) {
        sections.push(selectedStatement.personal);
      }
      if (has(selectedStatement, 'group')) {
        sections.push(selectedStatement.group);
      }
      return { fullName: get(selectedStatement, 'fullName', ''), sections };
    }

    const sections = Object.values(selectedStatement.sections);
    sortSectionsByType(sections);

    return { fullName: get(selectedStatement, 'fullName', ''), sections };
  };

  render() {
    const { showSection20 } = this.props;

    return <StatementReport showSection20={showSection20} statements={this.getGroupAndPersonalSections()} />;
  }
}

StatementRenderer.propTypes = {
  statements: PropTypes.array.isRequired,
  selectedDate: PropTypes.string,
  showSection20: PropTypes.bool
};

StatementRenderer.defaultProps = {
  selectedDate: null,
  showSection20: true
};

export default compose()(StatementRenderer);
