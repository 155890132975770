import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

function StatementSectionContainer({ children, withDivider, classes }) {
  return (
    <>
      <Grid container spacing={2} className={classes.container}>
        {children}
      </Grid>
      {withDivider && <Divider />}
    </>
  );
}

StatementSectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  withDivider: PropTypes.bool
};

StatementSectionContainer.defaultProps = {
  withDivider: false
};

export default withStyles(styles)(StatementSectionContainer);
