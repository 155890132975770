import React, { FunctionComponent } from 'react';
import CategoryItem from 'app/favorite/shared/domain/CategoryItem';
import { getStore } from 'app/reduxStore/configureStore';
import { addLumpSumToUserPreferences, updateLumpSumsFromUserPreferences } from 'app/containers/User/actions';
import { useSelector } from 'react-redux';
import { RootState } from 'application/types/redux-state';
import { selectFavoriteLumpSumPreferencesFromUser } from '../../../containers/User/selectors';
import ItemAndCategoriesMapping from '../../../shared/editable/domain/ItemAndCategoriesMapping';
import LumpSumDialog from './LumpSumDialog';

export interface Props {
  onItemSelected: (item: CategoryItem) => void;
  onClose: () => void;
  open: boolean;
}

export const ContextElementSearchDialog: FunctionComponent<Props> = ({ open, onItemSelected, onClose }: Props) => {
  const favorites = useSelector<RootState, ItemAndCategoriesMapping>(selectFavoriteLumpSumPreferencesFromUser());

  const handleOnItemSelected = (item: CategoryItem) => {
    onItemSelected(item);
    onClose();
  };

  const handleOnAddToFavorites = (item: CategoryItem) => {
    getStore().dispatch(addLumpSumToUserPreferences(item));
  };

  const handleOnUpdateFavorites = (favorites: ItemAndCategoriesMapping) => {
    getStore().dispatch(updateLumpSumsFromUserPreferences(favorites));
  };

  return (
    <LumpSumDialog
      open={open}
      favorites={favorites}
      advancedEditionEnabled
      onAddToFavorites={handleOnAddToFavorites}
      onUpdateFavorites={handleOnUpdateFavorites}
      onItemSelected={handleOnItemSelected}
      onClose={onClose}
    />
  );
};

export default ContextElementSearchDialog;
