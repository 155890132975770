import actCodesData from './specialist-act-codes.json';
import internistCodesDataOverride from './internist-act-codes-overrides.json';
import { getFeatureConfig } from '../../userCustomization/featureToggle';
import {
  CODES_DATA_SPECIALTY_OVERRIDE,
  CODES_DATA_SPECIALTY_OVERRIDE_INTERNIST
} from '../../userCustomization/featureNames';
import { ActCode } from '../domain/activity/act/model/Act';

const findActCodeIndex = (actCode, actCodeToFind) => actCode.code === actCodeToFind;

export const getMergedActCodes = (sourceActCodes, actCodesToMerge) => {
  const mergedActCodes = [...sourceActCodes];

  if (actCodesToMerge && actCodesToMerge.length > 0) {
    actCodesToMerge.forEach((actCodeToMerge) => {
      const indexInSourceArray = mergedActCodes.findIndex((actCode) => findActCodeIndex(actCode, actCodeToMerge.code));
      if (indexInSourceArray === -1) {
        mergedActCodes.push(actCodeToMerge);
      } else {
        mergedActCodes[indexInSourceArray] = actCodeToMerge;
      }
    });
  }

  return mergedActCodes;
};

export const getActCodesDataWithSpecialtyOverrides = (): ActCode[] => {
  let data: ActCode[];

  if (getFeatureConfig(CODES_DATA_SPECIALTY_OVERRIDE, false) === CODES_DATA_SPECIALTY_OVERRIDE_INTERNIST) {
    data = getMergedActCodes(actCodesData, internistCodesDataOverride);
  } else {
    data = actCodesData as ActCode[];
  }

  return data;
};

export function findCodeDetail(code: string): ActCode | undefined {
  return getActCodesDataWithSpecialtyOverrides().find((actCode) => actCode.code === code);
}
