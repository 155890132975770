import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import VisionService from 'application/services/vision-service';
import { firestore } from 'server/Firebase';
import { logDefault, logError, logWarning } from '../../../shared/utils/plog';
import { ACTIVITIES_ACT_TYPE } from '../../../shared/collection/collectionConstants';
import queryBuilder from '../../../shared/collection/QueryBuilder';
import { ONE_DAY_IN_MILLISECONDS } from '../../../utils/dateTimeUtils';
import { sleep } from '../../../utils/timeUtils';
import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import { syncCollection } from '../../firebase/collection/collectionSync';
import {
  replaceArrayItemWithinTransaction,
  trackErrorInFirestore,
  updateDocument
} from '../../firebase/document/documentSagas';
import { saveActFailure } from '../Act/actions';
import { UPLOAD_ERROR, UPLOAD_SUCCESS } from '../Storage/actions';
import { ATTACHMENTS, CARD_ATTACHMENT_TYPE, CARD_PICTURE_ASSET_TYPE } from '../Storage/constants';
import ActFile from '../Storage/cordova/ActFile';
import { buildFromFileName } from '../Storage/cordova/actFileBuilder';
import actPictureRepository from '../Storage/cordova/actPictureRepository';
import actAttachmentRepository from '../Storage/cordova/actAttachmentRepository';
import { SYNCHRONIZED_ATTACHMENT, SYNCHRONIZED_PICTURE } from '../Storage/cordova/constants';
import { getFileNameFromAbsolutePath } from '../Storage/utils';
import { getStore } from '../../reduxStore/configureStore';
import { selectHasOCREnabled, selectUserIdInContext } from '../User/selectors';
import {
  ACT_FILE_READY_FOR_UPLOAD,
  FETCH_UP_TO_DATE_PATIENT_CONTEXT_ACCORDING_TO_NAM,
  GET_ACTS_24_HOURS_BEFORE_DATE,
  getActs24BeforeDateFailure,
  getActs24BeforeDateSuccess,
  INITIATE_NAM_OCR,
  openNamSelectorDialog,
  updateNAMInActForm,
  updateExtractedNamsInActForm,
  updatePatientContext,
  updateSavedAttachmentsInActForm,
  updateSavedPictureInActForm
} from './actions';
import { selectActId, selectAttachments, selectNam } from './selectors';
import storageUploader from '../Storage/uploader/StorageUploader';
import StorageUploadContext from '../Storage/uploader/StorageUploadContext';
import isDefined from '../../../shared/utils/isDefined';
import FetchPatientContextFromNamUseCase from '../../user/patientContext/usecases/FetchPatientContextFromNamUseCase';

export function* getActs24HoursBeforeDate(action) {
  const userId = yield select(selectUserIdInContext());
  const timeRange = { startTime: action.date - ONE_DAY_IN_MILLISECONDS + 1, endTime: action.date };
  const query = queryBuilder
    .withBaseQuery(activitiesCollectionRef())
    .withUserId(userId)
    .withStartTimeRange(timeRange)
    .withType(ACTIVITIES_ACT_TYPE)
    .build();
  yield* syncCollection(query, getActs24BeforeDateSuccess, getActs24BeforeDateFailure);
}

export function* initiateNamOCR(action) {
  const ocrEnabled = yield select(selectHasOCREnabled());
  const userId = yield select(selectUserIdInContext());
  if (!ocrEnabled) {
    logDefault('OCR Disabled for user');
    return;
  }

  logAction('9- saga NamOcr', userId, action.actAttachment.actId);

  const base64Content = yield action.actAttachment.getDataAsBase64();
  const extractedNams = yield VisionService.fetchExtractedNams(base64Content);

  logAction('10- namOcrCompleted', userId, action.actAttachment.actId);

  if (extractedNams.length === 0) {
    return;
  }

  const nams = [];
  const currentNam = yield select(selectNam());
  if (currentNam) nams.push(currentNam);
  nams.push(...extractedNams.map(({ nam }) => nam).filter((nam) => nam !== currentNam));

  if (nams.length === 1) {
    yield put(updateNAMInActForm(nams[0]));
  } else if (nams.length > 1) {
    yield put(openNamSelectorDialog(nams));
  }

  yield put(updateExtractedNamsInActForm(extractedNams));
}

export function* fetchUpToDatePatientContextAccordingToNam({ nam, uid }) {
  const patientContext = yield new FetchPatientContextFromNamUseCase().execute(nam, uid);

  yield put(updatePatientContext(patientContext));
}

export function* actFileReadyForUpload(action) {
  logDefault({
    type: 'saga',
    text: 'ActFormSagas/actFileReadyForUpload',
    array: ['Starting actFileReadyForUpload. action is', action]
  });

  try {
    const { attachmentType, documentId, fileEntry, persistFileLocallyBeforeUpload } = action;
    const actAttachment = new ActFile(documentId, fileEntry);
    const userId = yield select(selectUserIdInContext());

    const uploadContext = new StorageUploadContext(documentId, fileEntry, persistFileLocallyBeforeUpload);
    uploadContext.fileWrapper = actAttachment;
    uploadContext.uploadType = attachmentType;
    uploadContext.userId = userId;
    uploadContext.localFileRepository = getLocalFileRepository(attachmentType);
    uploadContext.postLocalPersistFileHook = getPostLocalPersistHook(attachmentType);
    uploadContext.storeDispatch = getStore().dispatch;

    logAction('6- uploadInitiated', userId, documentId);
    storageUploader
      .initiateUpload(uploadContext)
      .then(() => {
        logAction('7- uploadCompleted', userId, documentId);
      })
      .catch((error) => {
        logAction('uploadFailed', userId, documentId);
        trackErrorInFirestore(error, { type: 'ActFileReadyForUploadError' });
      });
  } catch (e) {
    logError({
      type: 'saga',
      text: 'ActFormSagas/actFileReadyForUpload',
      array: ['actFileReadyForUpload ERROR', action, e],
      online: navigator.onLine
    });
    yield* trackErrorInFirestore(e, { type: 'actFileReadyForUploadError' });
  }
}

function logAction(action, userId, documentId) {
  firestore
    .collection('pictureLogs')
    .doc('users')
    .get()
    .then((doc) => {
      if (doc.exists && doc.data()) {
        const userIds = doc.data().ids;
        if (userIds && userIds.includes(userId)) {
          firestore
            .collection('pictureLogs')
            .doc(userId)
            .collection('acts')
            .doc(documentId)
            .set(
              {
                [action]: new Date()
              },
              { merge: true }
            );
        }
      }
    })
    .catch((error) => {
      trackErrorInFirestore(error, { type: 'pictureLogsError' });
    });
}

function getLocalFileRepository(attachmentType) {
  if (attachmentType === SYNCHRONIZED_PICTURE) {
    return actPictureRepository;
  }

  if (attachmentType === SYNCHRONIZED_ATTACHMENT) {
    return actAttachmentRepository;
  }

  return undefined;
}

function getPostLocalPersistHook(attachmentType) {
  if (attachmentType === SYNCHRONIZED_PICTURE) {
    return postLocalPersistActPicture;
  }

  if (attachmentType === SYNCHRONIZED_ATTACHMENT) {
    return postLocalPersistAttachment;
  }

  return undefined;
}

export function postLocalPersistActPicture(actId, addedActPicture, localPath) {
  logDefault({
    type: 'saga',
    text: 'ActFormSagas/postLocalPersistActPicture',
    array: ['Dispatching updateSavedPictureInActForm action with act picture', addedActPicture]
  });
  getStore().dispatch(updateSavedPictureInActForm(localPath, addedActPicture.fileName, true /* temporary */));
}

export function postLocalPersistAttachment(actId, addedAttachment, localPath) {
  logDefault({
    type: 'saga',
    text: 'ActFormSagas/postLocalPersistAttachment',
    array: [
      'Starting postLocalPersistAttachment. actId',
      actId,
      'addedAttachment is',
      addedAttachment,
      'localPath is',
      localPath
    ]
  });

  const currentAttachments = selectAttachments()(getStore().getState());
  const updatedAttachments = [
    ...currentAttachments,
    {
      type: CARD_ATTACHMENT_TYPE,
      location: localPath,
      fileName: addedAttachment.fileName,
      originalFileName: addedAttachment.originalFileName,
      temporary: true
    }
  ];
  getStore().dispatch(updateSavedAttachmentsInActForm(updatedAttachments));
}

export const updatePictureAsset = (assets, newAsset) => {
  const updatedAssets = assets ? assets.filter((asset) => asset.type !== newAsset.type) : [];
  updatedAssets.push(newAsset);
  return updatedAssets;
};

function isActSynchronizedInDB(actRef) {
  return isDefined(actRef) && actRef.exists && actRef.data().synchronized === true;
}

export const getActWithBackoff = async (actId) => {
  const actReference = activitiesCollectionRef().doc(actId);
  let act;
  let waitTime = 250;

  do {
    try {
      act = await actReference.get(); // eslint-disable-line no-await-in-loop
    } catch (e) {
      logWarning({
        type: 'saga',
        text: 'ActFormSagas/getActWithBackoff',
        array: ['ERROR getting act with backoff', actId, waitTime, e]
      });
      trackErrorInFirestore(e, { type: 'UpdateDocumentWithUploadedAsset', actId });
    }

    if (!isActSynchronizedInDB(act)) {
      await sleep(waitTime); // eslint-disable-line no-await-in-loop
      // istanbul ignore next
      if (waitTime < 32000) {
        waitTime *= 2;
      }
    }
  } while (!isActSynchronizedInDB(act));

  return act;
};

const getFileNameFromFirebaseDownloadUrl = (downloadUrl) => {
  const url = new URL(downloadUrl);
  const encodedStorageUrl = url.pathname.split('/').pop();
  return decodeURIComponent(encodedStorageUrl).split('/').pop();
};

export const assetsMatchDownloadUrl = (assets, downloadUrl) => {
  if (!Array.isArray(assets)) {
    return false;
  }

  const filteredAssets = assets.filter((currentAsset) => {
    let fileName;
    if (currentAsset.temporary) {
      fileName = getFileNameFromAbsolutePath(currentAsset.location);
    } else {
      fileName = getFileNameFromFirebaseDownloadUrl(currentAsset.location);
    }
    return downloadUrl.indexOf(fileName) >= 0;
  });
  return filteredAssets.length > 0;
};

export function* updateAssetInPersistedAct(actId, downloadUrl) {
  logDefault({
    type: 'saga',
    text: 'ActFormSagas/updateAssetInPersistedAct',
    array: ['updateAssetInPersistedAct', downloadUrl, actId]
  });
  try {
    const act = yield getActWithBackoff(actId);
    const actData = act.data();
    if (act.exists) {
      if (assetsMatchDownloadUrl(actData.assets, downloadUrl)) {
        const assets = updatePictureAsset(actData.assets, {
          type: CARD_PICTURE_ASSET_TYPE,
          location: downloadUrl,
          temporary: false
        });
        // Here we have a bug where we still delete local picture when updating act fails ===> tech debt
        yield* updateDocument(activitiesCollectionRef(), saveActFailure, { document: actData, changes: { assets } });
      } else {
        logDefault({
          type: 'saga',
          text: 'ActFormSagas/updateAssetInPersistedAct',
          array: ['Asset does not match, cannot update', actId, downloadUrl, act.exists, actData]
        });
      }
    } else {
      logDefault({
        type: 'saga',
        text: 'ActFormSagas/updateAssetInPersistedAct',
        array: ['Act does not exist', actId, downloadUrl, act.exists, actData]
      });
    }
  } catch (e) {
    logError({
      type: 'saga',
      text: 'ActFormSagas/updateAssetInPersistedAct',
      array: ['ERROR updateAssetInPersistedAct', downloadUrl, actId, e]
    });
    yield* trackErrorInFirestore(e, { type: 'UpdateDocumentWithUploadedAsset', actId });
    throw e;
  }
}

const findAttachmentFromDownloadURL = (attachments, downloadUrl) => {
  const index = findAttachmentIndexFromDownloadURL(attachments, downloadUrl);
  if (index > -1) {
    return attachments[index];
  }

  return undefined;
};

const findAttachmentIndexFromDownloadURL = (attachments, downloadUrl) => {
  if (!Array.isArray(attachments)) {
    return -1;
  }

  const attachmentIndex = attachments.findIndex((currentAttachment) => {
    let fileName;
    if (currentAttachment.temporary) {
      fileName = getFileNameFromAbsolutePath(currentAttachment.location);
    } else {
      fileName = getFileNameFromFirebaseDownloadUrl(currentAttachment.location);
    }
    return downloadUrl.indexOf(fileName) >= 0;
  });

  return attachmentIndex;
};

export function* updateAttachmentInPersistedAct(actId, downloadUrl) {
  logDefault({
    type: 'saga',
    text: 'ActFormSagas/updateAttachmentInPersistedAct',
    array: ['updateAttachmentInPersistedAct', downloadUrl, actId]
  });
  try {
    const act = yield getActWithBackoff(actId);
    const actData = act.data();

    if (act.exists) {
      const attachmentToRemove = findAttachmentFromDownloadURL(actData.attachments, downloadUrl);
      logDefault({
        type: 'saga',
        text: 'ActFormSagas/updateAttachmentInPersistedAct',
        array: ['attachmentToRemove is', attachmentToRemove]
      });
      if (attachmentToRemove) {
        const attachmentToAdd = { ...attachmentToRemove };
        attachmentToAdd.location = downloadUrl;
        attachmentToAdd.temporary = false;

        logDefault({
          type: 'saga',
          text: 'ActFormSagas/updateAttachmentInPersistedAct',
          array: ['attachmentToAdd is', attachmentToAdd]
        });

        // To avoid race conditions during the attachments' location update, a transaction is required
        // otherwise uploading multiple files "simultaneously" (when going from offline to online for example)
        // could update some indexes with stale data as other uploads are completed. The transaction is there
        // to ensure a fresh document with the latest entries updated
        yield* replaceArrayItemWithinTransaction(activitiesCollectionRef(), saveActFailure, {
          documentId: actId,
          keyToUpdate: ATTACHMENTS,
          itemToRemove: attachmentToRemove,
          itemToAdd: attachmentToAdd
        });
      } else {
        logDefault({
          type: 'saga',
          text: 'ActFormSagas/updateAttachmentInPersistedAct',
          array: ['Attachment not found in act attachments', downloadUrl]
        });
      }
    } else {
      logDefault({
        type: 'saga',
        text: 'ActFormSagas/updateAttachmentInPersistedAct',
        array: ['Act does not exist', actId, downloadUrl, act.exists, actData]
      });
    }
  } catch (e) {
    logError({
      type: 'saga',
      text: 'ActFormSagas/updateAttachmentInPersistedAct',
      array: ['ERROR updateAttachmentInPersistedAct', downloadUrl, actId, e]
    });
    yield* trackErrorInFirestore(e, { type: 'UpdateDocumentWithUploadedAsset', actId });
    throw e;
  }
}

export function* actFileSuccessfullyUploaded(action) {
  const { downloadUrl, uploadTask } = action;
  const currentFormActId = yield select(selectActId());
  logDefault({
    type: 'saga',
    text: 'ActFormSagas/actFileSuccessfullyUploaded',
    array: ['Starting actFileSuccessfullyUploaded with action', action, 'and currentFormActId', currentFormActId]
  });

  try {
    if (currentFormActId === uploadTask.actId) {
      if (uploadTask.type === SYNCHRONIZED_PICTURE) {
        logDefault({
          type: 'saga',
          text: 'ActFormSagas/actFileSuccessfullyUploaded',
          array: ['Updating picture in act form']
        });
        yield put(updateSavedPictureInActForm(downloadUrl, uploadTask.fileName));
      } else if (uploadTask.type === SYNCHRONIZED_ATTACHMENT) {
        const attachments = yield select(selectAttachments());
        const updatedAttachments = getUpdatedAttachments(attachments, uploadTask, downloadUrl);
        logDefault({
          type: 'saga',
          text: 'ActFormSagas/actFileSuccessfullyUploaded',
          array: ['Updating attachment in act form', updatedAttachments]
        });
        yield put(updateSavedAttachmentsInActForm(updatedAttachments));
      }
    }

    // In all cases, the update*InPersistedAct methods must be called. For example, the user previously persisted an act with a
    // local file path while offline. Then, he reopens the act and goes online. In this case, upon upload success, the file URL
    // must be updated in both the form and the persisted act (in case he does not update the form). If the attachment hasn't
    // been saved yet, then the update method should just do nothing.
    if (uploadTask.type === SYNCHRONIZED_PICTURE) {
      logDefault({
        type: 'saga',
        text: 'ActFormSagas/actFileSuccessfullyUploaded',
        array: ['Updating picture in persisted act']
      });
      yield* updateAssetInPersistedAct(uploadTask.actId, downloadUrl);
    } else if (uploadTask.type === SYNCHRONIZED_ATTACHMENT) {
      logDefault({
        type: 'saga',
        text: 'ActFormSagas/actFileSuccessfullyUploaded',
        array: ['Updating attachment in persisted act']
      });
      yield* updateAttachmentInPersistedAct(uploadTask.actId, downloadUrl);
    } else {
      logDefault({
        type: 'saga',
        text: 'ActFormSagas/actFileSuccessfullyUploaded',
        array: ['Upload task type not known to this saga. Skipping any processing.', uploadTask.type]
      });
    }

    yield* removeTemporaryFileFromDeviceLocalStorage(uploadTask.fileName, uploadTask.type);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'ActFormSagas/actFileSuccessfullyUploaded',
      array: ['actFileSuccessfullyUploaded ERROR', uploadTask, downloadUrl, e]
    });
    yield* trackErrorInFirestore(e, { type: 'ActFileSuccessfullyUploadedError' });
  } finally {
    if (uploadTask.type === SYNCHRONIZED_PICTURE || uploadTask.type === SYNCHRONIZED_ATTACHMENT) {
      storageUploader.completeUploadProcessForFile(uploadTask.fileName);
    }
  }
}

export function* removeTemporaryFileFromDeviceLocalStorage(fileName, fileType) {
  const actFile = buildFromFileName(fileName);
  if (fileType === SYNCHRONIZED_PICTURE) {
    logDefault({
      type: 'saga',
      text: 'ActFormSagas/removeTemporaryFileFromDeviceLocalStorage',
      array: ['Removing temporary picture', fileName]
    });

    yield call([actPictureRepository, actPictureRepository.removeOldPicturesForAct], actFile);
  } else if (fileType === SYNCHRONIZED_ATTACHMENT) {
    logDefault({
      type: 'saga',
      text: 'ActFormSagas/removeTemporaryFileFromDeviceLocalStorage',
      array: ['Removing temporary attachment', fileName]
    });

    yield call([actAttachmentRepository, actAttachmentRepository.removeAttachmentForAct], actFile);
  }
}

export function* actFileUploadError(action) {
  const { uploadTask } = action;

  yield call(storageUploader.completeUploadProcessForFile, uploadTask.fileName);
}

export function getUpdatedAttachments(attachments = [], uploadTask, downloadUrl) {
  const updatedAttachments = attachments.slice(0);
  const attachmentToUpdateIndex = updatedAttachments.findIndex(
    (attachment) => attachment.fileName === uploadTask.fileName
  );

  if (attachmentToUpdateIndex > -1) {
    const attachmentToUpdate = { ...updatedAttachments[attachmentToUpdateIndex] };
    attachmentToUpdate.location = downloadUrl;
    attachmentToUpdate.temporary = false;

    updatedAttachments[attachmentToUpdateIndex] = attachmentToUpdate;
  } else {
    const attachmentFromBrowserToAdd = {
      type: CARD_ATTACHMENT_TYPE,
      location: downloadUrl,
      fileName: uploadTask.fileName,
      originalFileName: uploadTask.originalFileName,
      temporary: false
    };

    updatedAttachments.push(attachmentFromBrowserToAdd);
  }

  return updatedAttachments;
}

export default function* actFormSagas() {
  yield takeEvery(ACT_FILE_READY_FOR_UPLOAD, actFileReadyForUpload);
  yield takeLatest(INITIATE_NAM_OCR, initiateNamOCR);
  yield takeEvery(UPLOAD_ERROR, actFileUploadError);
  yield takeEvery(isActionUploadSuccessForActAttachment, actFileSuccessfullyUploaded);
  yield takeLatest(GET_ACTS_24_HOURS_BEFORE_DATE, getActs24HoursBeforeDate);
  yield takeLatest(FETCH_UP_TO_DATE_PATIENT_CONTEXT_ACCORDING_TO_NAM, fetchUpToDatePatientContextAccordingToNam);
}

export const isActionUploadSuccessForActAttachment = (action) =>
  action.type === UPLOAD_SUCCESS && [SYNCHRONIZED_ATTACHMENT, SYNCHRONIZED_PICTURE].includes(action.uploadTask.type);

/*
 * manual tests => should be acceptance test but quite hard to setup at the moment
 *
 * online
 * [] Picture in new act in form -> logDefault cannot update but picture is saved properly and available online after save
 * [] Second picture in new act in form -> two logDefault cannot update but as expected online after save
 * [] New Picture in existing act in form and leave form without saving -> picture is not there
 * [] Replace picture in existing act in form and leave form without saving -> old picture is there
 * [] New Picture in existing act in form and save -> picture is updated
 * [] Replace picture in existing act in form and save -> new picture saved
 * [] Take new picture and save very quickly -> picture is updated
 * [] Take new picture and exit without saving very quickly -> picture is not updated
 * [] Add attachment(s) and exit without saving -> the attachments list is not displayed when reopening the act
 * [] Add attachment(s), save and exit -> the attachments list displays the files when reopening the act
 * [] Remove attachment(s) and exit without saving -> old attachment(s) are displayed when reopening the act
 * [] Remove attachment(s), save and exit -> the attachments list displays the attachments when reopening the act
 *
 * offline
 * [] Picture in new act in form
 * [] Second picture in new act in form
 * [] New Picture in existing act in form and leave form without saving -> picture is not there
 * [] Replace picture in existing act in form and leave form without saving -> old picture is there
 * [] New Picture in existing act in form and save -> picture is updated
 * [] Replace picture in existing act in form and save -> new picture saved
 * [] Take new picture and save very quickly -> picture is updated
 *    (though on iOS it seems to be slower and it is possible to save before the ActForm is updated,
 *    in that case we loose the picture but wontfix)
 * [] Take new picture and exit without saving very quickly -> picture is not updated
 * [] Add attachment(s) and leave without saving -> the attachments list is not displayed
 * [] Add attachment(s) and save -> the attachments list is displayed even if the files are not uploaded
 * [] Remove attachment(s) and exit without saving -> old attachment(s) are displayed when reopening the act
 * [] Remove attachment(s), save and exit -> the attachments list displays the attachments when reopening the act
 *
 */
