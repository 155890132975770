import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Field } from 'redux-form';
import ActivityType from '../../../../../../../shared/core/activity/domain/ActivityType';
import computeExpectedAmountForMixte from '../../../../../../../shared/amountComputation/computeMixteExpectedAmount';
import computeExpectedAmountForLumpSum from '../../../../../../../shared/amountComputation/computeLumpSumExpectedAmount';
import EditableField from '../../../../../../components/EditableField/EditableField';
import CashAmountInput from '../../../../../../components/Form/custom/CashAmountInput';
import { currencyFormat } from '../../../../../../../utils/wordFormatUtilities';
import isDefined from '../../../../../../../shared/utils/isDefined';
import Place from '../../../../../../../shared/domain/place/model/Place';

export interface Props {
  fieldName: string;
  activityType: ActivityType;
  place: Place;
}

const DayAmountField: React.FunctionComponent<Props> = ({ fieldName, activityType, place }: Props) => (
  <Field
    name={`${fieldName}`}
    component={({ input }) => {
      const day = {
        ...input.value,
        place
      };

      let initialValue = 0;

      if (!isDefined(day.manualCorrection)) {
        if (activityType === ActivityType.MIXTE) {
          initialValue = computeExpectedAmountForMixte(day);
        }

        if (activityType === ActivityType.LUMP_SUM) {
          initialValue = computeExpectedAmountForLumpSum(day);
        }
      } else {
        initialValue = day.manualCorrection;
      }

      return (
        <Typography variant="body2">
          <EditableField
            initialValue={initialValue}
            valueFormatter={currencyFormat}
            edited={!!day.manualCorrection}
            EditingComponent={CashAmountInput}
            onSave={(value) => {
              input.onChange({
                ...day,
                manualCorrection: value
              });
            }}
            onDelete={() => {
              input.onChange({
                ...day,
                manualCorrection: null
              });
            }}
          />
        </Typography>
      );
    }}
  />
);

export default DayAmountField;
