const COMMUNICATION_THERAPY_CODE = '8925';
const VISIT_NATURE_TYPE = 'Visite';
const DURATION_MORE_THAN_60_MINUTES_REQUIRED_DETAIL_NAME = 'durationMoreThan60Minutes';

export const communicationTherapyCodeBilledWithAnotherVisitCode = ({ codes = [] }) => {
  const communicationTherapyCode = codes.find(({ code }) => code === COMMUNICATION_THERAPY_CODE);
  const visitCode = codes.find(({ natureType = '' }) => natureType === VISIT_NATURE_TYPE);

  if (communicationTherapyCode) {
    const durationMoreThan60Minutes = communicationTherapyCode[DURATION_MORE_THAN_60_MINUTES_REQUIRED_DETAIL_NAME];

    if (!durationMoreThan60Minutes && visitCode) {
      return {
        codes: {
          _error: `Le code ${COMMUNICATION_THERAPY_CODE} ne peut être facturé avec un code de visite (${visitCode.code})
            si la durée est inférieur à 60 minutes`
        }
      };
    }
  }

  return {};
};

export default communicationTherapyCodeBilledWithAnotherVisitCode;
