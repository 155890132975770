import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import React, { useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'qs';
import { newUnixDateAtStartOfDay } from '../../../../shared/utils/dateTime/dateTimeUtils';
import ActivitiesSearchIcon from '../../../containers/ActivitiesSearch/ActivitiesSearchIcon';
import {
  ACTIVITY_ID_ROUTE_PARAM,
  ACTS_ROUTE,
  ACTIVITIES_ROUTE,
  LUMP_SUM_ROUTE,
  MIXTE_ROUTE,
  TRAVEL_EXPENSES_ROUTE
} from '../../../containers/Navigation/constants';
import FiltersDisplayer from '../../FiltersDisplayer/FiltersDisplayer';
import AppHeaderContainer from '../AppHeaderContainer/AppHeaderContainer';
import SimpleHeader from '../SimpleHeader/SimpleHeader';

export const styles = (theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 600
  },
  searchIcon: {
    color: theme.palette.common.black
  }
});

export function FilteredViewHeader({ classes }) {
  const navigate = useNavigate();
  const location = useLocation();

  const filters = useMemo(() => QueryString.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  return (
    <Routes>
      <Route path={ACTS_ROUTE}>
        <Route path={ACTIVITY_ID_ROUTE_PARAM} element={<SimpleHeader titleHeaderIntlId="editAct" />} />
      </Route>

      <Route path={TRAVEL_EXPENSES_ROUTE}>
        <Route path={ACTIVITY_ID_ROUTE_PARAM} element={<SimpleHeader titleHeaderIntlId="editTravelExpenses" />} />
      </Route>

      <Route path={MIXTE_ROUTE}>
        <Route path={ACTIVITY_ID_ROUTE_PARAM} element={<SimpleHeader titleHeaderIntlId="mixte" />} />
      </Route>

      <Route path={LUMP_SUM_ROUTE}>
        <Route path={ACTIVITY_ID_ROUTE_PARAM} element={<SimpleHeader titleHeaderIntlId="lumpsum" />} />
      </Route>

      <Route
        index
        element={
          <AppHeaderContainer classes={{ toolBar: classes.appBar }}>
            <div className={classes.headerContainer}>
              <IconButton
                onClick={() =>
                  navigate({
                    pathname: `/${ACTIVITIES_ROUTE}/${newUnixDateAtStartOfDay()}`,
                    search: location.search
                  })
                }
              >
                <PrevArrowIcon />
              </IconButton>
              <FiltersDisplayer filters={filters} defaultText="Tous" />
              <ActivitiesSearchIcon className={classes.searchIcon} />
            </div>
          </AppHeaderContainer>
        }
      />
    </Routes>
  );
}

export default withStyles(styles)(FilteredViewHeader);
