import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LumpSum from '../../../LumpSum/LumpSum';

function LumpSumRoute() {
  return (
    <Routes>
      <Route index element={<LumpSum />} />
    </Routes>
  );
}

export default LumpSumRoute;
