const PATIENT_WITHOUT_ID_TYPES = {
  childUnderOneYear: '2',
  patientNeedingUrgentCare: '3',
  patientInCHSLD: '4',
  patientBetween14And18WithoutAuthorization: '5',
  personWithTribunalRequestToAttestMentalState: '6',
  personFromCoronerRequest: '7',
  asylumAsker: '8',
  refundRequest: '9',
  nonQuebecDonor: '14',
  patientRequiringServiceRenderedUnderLawsRelatingOccupationalSealthAndSafety: '17'
};
export const OUTSIDE_RAMQ_PATIENT_TYPE = 'outsideRamq';

/*
Domaine de valeurs
2 : Enfant âgé de moins d'un an
3 : Patient requérant des soins urgents
4 : Patient admis dans un CHSLD (incluant ceux pour les autochtones cris), centre d'accueil ou centre de réadaptation
5 : Patient âgé entre 14 ans et 18 ans sans l'autorisation des parents
6 : Personne faisant l’objet d’une ordonnance du tribunal pour l’évaluation de son état mental
7 : Service professionnel à la demande du coroner
8 : Demandeur d’asile lorsque refus de  paiement des services médicaux par Croix Bleue Medavie
9 : Dans le cadre d'une demande de remboursement à remettre au patient
14 : Donneur non québécois
*/

export default PATIENT_WITHOUT_ID_TYPES;
