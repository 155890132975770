import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import Section from 'application/components/form-controls/section';
import FieldContainer from 'application/components/form-controls/field-container';
import TextInput from 'application/components/form-controls/text-input';
import SelectInput from 'application/components/form-controls/select-input';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import Button from 'application/components/button';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import { GroupType } from 'application/types/groups';
import { isUserAtLeast } from 'application/utilities/authentication';
import { useSelector } from 'react-redux';
import { RootState } from 'application/types/redux-state';

interface FormValues {
  name: string;
  number: string;
  type: string;
}

interface Props {
  isFormValid: boolean;
  onCancel: () => void;
  onChange: (values: FormValues) => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  registerField: (field: string) => ValidatedFieldProps;
  values: FormValues;
}

const DetailsForm = ({ isFormValid, onCancel, onChange, onSubmit, registerField, values }: Props) => {
  const translate = useTranslate('pages.group.details');
  const translateGroupType = useTranslate('group-type');
  const userRole = useSelector<RootState, Role>((state) => state.authentication.user.role);

  return (
    <form onSubmit={onSubmit}>
      <Section label={translate('informations.title')}>
        <FieldContainer label={translate('informations.attributes.name')} required>
          <TextInput
            {...registerField('name')}
            disabled={!isUserAtLeast(userRole, Role.Admin)}
            onChange={(name: string) => onChange({ ...values, name })}
            required
            value={values.name}
          />
        </FieldContainer>

        <FieldContainer label={translate('informations.attributes.type')} required>
          <SelectInput
            {...registerField('type')}
            disabled={!isUserAtLeast(userRole, Role.Admin)}
            onChange={(type) => onChange({ ...values, type, ...(type === GroupType.MANAGEMENT ? { number: '' } : {}) })}
            options={[GroupType.MANAGEMENT, GroupType.POOL].map((groupType: string) => ({
              label: translateGroupType(groupType),
              value: groupType
            }))}
            required
            value={values.type}
          />
        </FieldContainer>

        <FieldContainer label={translate('informations.attributes.number')} required={values.type === GroupType.POOL}>
          <TextInput
            {...registerField('number')}
            disabled={values.type === GroupType.MANAGEMENT || !isUserAtLeast(userRole, Role.Admin)}
            onChange={(number: string) => onChange({ ...values, number })}
            required={values.type === GroupType.POOL}
            value={values.number}
          />
        </FieldContainer>

        <Section.Footer>
          <Restrict atLeastRole={Role.Admin}>
            <div className="flex">
              <Button className="btn btn-ghost" onClick={onCancel} type="button">
                {translate('informations.cancel')}
              </Button>

              <Button type="submit" disabled={!isFormValid} className="btn btn-primary">
                {translate('informations.save')}
              </Button>
            </div>
          </Restrict>
        </Section.Footer>
      </Section>
    </form>
  );
};

export default DetailsForm;
