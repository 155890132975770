import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const shouldFieldBeHidden = ({ telemedicineOrRemote }: { telemedicineOrRemote: boolean }) => !telemedicineOrRemote;

export const TELEMEDICINE_OR_REMOTE_ACTIVITY_TYPES = [
  { label: 'Téléphone', value: 'phone' },
  { label: 'Visio', value: 'visio' }
];

const associatedFields: AssociatedField[] = [
  {
    label: 'Télémédecine ou à distance ?',
    getName: (index) => `codes[${index}].telemedicineOrRemote`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  },
  {
    getName: (index) => `codes[${index}].telemedicineOrRemoteType`,
    shouldFieldBeHidden,
    type: SingleSelectCheckboxGroup,
    list: TELEMEDICINE_OR_REMOTE_ACTIVITY_TYPES
  }
];

const telemedicineOrRemote: RequiredDetail = {
  associatedFields
};

export default telemedicineOrRemote;
