import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isDefined from '../../../../../shared/utils/isDefined';
import { getFeatureConfig } from '../../../../../userCustomization/featureToggle';
import { LUMPSUM_MAX_CODES_PER_DAY, MIXTE_MAX_CODES_PER_DAY } from '../../../../../userCustomization/featureNames';
import { ACTIVITIES_LUMP_SUM_TYPE, ACTIVITIES_MIXTE_TYPE } from '../../../../../shared/collection/collectionConstants';
import { createEmptyDayElement } from '../../../../../shared/periods/periods';
import { selectDaysFromDate } from '../../selectors';

export class AddPeriodDayUnitButton extends React.Component {
  getMaximumCodesNumberPerDay = () => {
    const { activityType } = this.props;

    switch (activityType) {
      case ACTIVITIES_MIXTE_TYPE:
        return getFeatureConfig(MIXTE_MAX_CODES_PER_DAY, 2);
      case ACTIVITIES_LUMP_SUM_TYPE:
        return getFeatureConfig(LUMPSUM_MAX_CODES_PER_DAY, 6);
      default:
        return 3;
    }
  };

  shouldBeDisplayed = () => {
    const { daysWithSameDate, fieldIndex } = this.props;
    const selectedCode = daysWithSameDate[0] && daysWithSameDate[0].code;
    const isFirstElementInDaysWithSameDate = daysWithSameDate[0]?.fieldIndex === fieldIndex;

    return (
      isFirstElementInDaysWithSameDate &&
      daysWithSameDate.length < this.getMaximumCodesNumberPerDay() &&
      isDefined(selectedCode)
    );
  };

  getSavedPlace = (daysWithSameDate) => daysWithSameDate[0].place;

  render() {
    const { date, fields, fieldIndex, daysWithSameDate } = this.props;

    return (
      this.shouldBeDisplayed() && (
        <IconButton
          onClick={() =>
            fields.insert(fieldIndex + 1, createEmptyDayElement(date, this.getSavedPlace(daysWithSameDate)))
          }
        >
          <AddIcon />
        </IconButton>
      )
    );
  }
}

AddPeriodDayUnitButton.propTypes = {
  date: PropTypes.number.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  daysWithSameDate: PropTypes.object.isRequired,
  activityType: PropTypes.string.isRequired
};

export const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    daysWithSameDate: selectDaysFromDate(ownProps.fieldIndex)
  });

export default compose(connect(mapStateToProps))(AddPeriodDayUnitButton);
