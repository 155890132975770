import MixteSummary from './MixteSummary.model';
import MixteSummaryDayLineMaker from './MixteSummaryDayLineMaker';
import { extractLastSuccessDeferredPaymentFromMixte } from './makeMixteSummaryUtils';
import { Mixte } from '../../domain/collection/activity/Mixte.types';
import BillingType from '../../domain/billing/model/BillingType';

export default class MixteSummaryMaker {
  public static make(mixte: Mixte): MixteSummary {
    return new MixteSummary(
      mixte.billingType || BillingType.PRIV,
      extractLastSuccessDeferredPaymentFromMixte(mixte),
      mixte.complementaryInformation,
      mixte.externalReferenceNumber,
      mixte.startDate,
      mixte.endDate,
      mixte.place,
      MixteSummaryDayLineMaker.make(mixte.days),
      mixte.poolNumber
    );
  }
}
