import React from 'react';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import MaskedInput from 'application/components/form-controls/masked-input';

interface Props extends ValidatedFieldProps {
  value: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

const ZipCodeInput = (maskedInputProps: Props) => (
  <MaskedInput mask="___ ___" placeholder="A1A 1A1" {...maskedInputProps} />
);

export default ZipCodeInput;
