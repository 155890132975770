import { GET_RAMQ_CALL_LOG, GET_RAMQ_CALL_LOG_ERROR, GET_RAMQ_CALL_LOG_SUCCESS } from './actions';

export const initialState = {
  loading: true,
  ramqLog: {}
};

function ramqCallLogsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RAMQ_CALL_LOG:
      return {
        ...state,
        loading: true,
        log: undefined
      };
    case GET_RAMQ_CALL_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        ramqLog: action.ramqLog ?? {},
        error: undefined
      };
    case GET_RAMQ_CALL_LOG_ERROR:
      return {
        ...state,
        loading: false,
        ramqLog: {},
        error: action.error
      };
    default:
      return state;
  }
}

export default ramqCallLogsReducer;
