import { CategoryPreset, Item } from 'application/services/presets-service';
import DataTable from 'application/components/data-table';
import useTranslate from 'application/hooks/use-translate';
import React, { useState } from 'react';
import RAMQCodes from 'shared/ramq/RamqCodes';
import Input from 'application/components/form/input';
import Icon from 'application/components/icon';
import NewItemForm from './new-item-form';

interface Props {
  category: CategoryPreset;
  onChange: (category: CategoryPreset) => void;
}

const BLANK_NEW_ITEM = { code: '', description: '' };

const CategoryPresetEditor: React.FunctionComponent<Props> = ({ category, onChange }) => {
  const translate = useTranslate('presets');
  const [newItem, setNewItem] = useState<Item | null>(null);

  const getRamqDescription = (code: string) => {
    const ramqCode = RAMQCodes.get(code);
    return ramqCode ? ramqCode.description : '';
  };

  const handleNewItemSubmit = (item: Item | null) => {
    if (!item || !item.code) {
      return;
    }
    const updatedCategory = {
      ...category,
      items: [...category.items, item]
    };
    onChange(updatedCategory);
    setNewItem(null);
  };

  const handleDeleteItem = async (updatingItem: Item) => {
    const updatedCategory = {
      ...category,
      items: category.items.filter((item) => item !== updatingItem)
    };
    onChange(updatedCategory);
  };

  const onDescriptionChange = (updatingItem: Item, description: string) => {
    const updatedCategory = {
      ...category,
      items: category.items.map((item) => {
        if (item !== updatingItem) {
          return item;
        }
        return {
          ...updatingItem,
          description
        };
      })
    };
    onChange(updatedCategory);
  };

  return (
    <DataTable
      title={category.category}
      count={category.items.length}
      collapsable
      headerButton={{
        label: translate('new'),
        onClick: () => setNewItem(BLANK_NEW_ITEM),
        hide: !!newItem,
        onCancel: () => setNewItem(null),
        cancelLabel: translate('cancel')
      }}
      columns={[
        { title: 'Code', width: '10%' },
        { title: 'Description RAMQ', width: '45%' },
        { title: 'Alias', width: '*' },
        { title: '', width: '100px' }
      ]}
    >
      {category.items.map((item) => (
        <tr className="hover">
          <td>{item.code}</td>
          <td>{getRamqDescription(item.code)}</td>
          <td>
            <Input
              type="search"
              value={item.description}
              onChange={(updatedValue: string) => onDescriptionChange(item, updatedValue)}
            />
          </td>
          <td>
            <button type="button" className="btn btn-square btn-outline" onClick={() => handleDeleteItem(item)}>
              <Icon icon="times" />
            </button>
          </td>
        </tr>
      ))}

      {newItem && (
        <NewItemForm
          newItem={newItem}
          onChange={(item) => setNewItem(item)}
          onSubmit={handleNewItemSubmit}
          onCancel={() => setNewItem(null)}
          getDescription={getRamqDescription}
        />
      )}
    </DataTable>
  );
};

export default CategoryPresetEditor;
