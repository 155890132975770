import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Modal, { ModalAction } from 'application/components/modal';
import { useLocalStorage } from 'react-use';
import useTranslated, { Translation } from 'application/hooks/use-translated';
import useTranslate from 'application/hooks/use-translate';
import { gql, useQuery } from '@apollo/client';
import { DaisyAlertVariant, DaisyButtonVariant } from 'application/types/daisy';
import Icon from './icon';

export interface CommunicationBanner {
  id?: string;
  variant: DaisyAlertVariant;
  expiredAt: Date | null;
  translatedLabel: Translation;
  translatedContent?: Translation;
}

interface Props {
  communicationBanner?: CommunicationBanner;
}

const ACTIVE_COMMUNICATION_BANNER_QUERY = gql`
  query {
    activeCommunicationBanner {
      id
      variant
      expiredAt
      translatedLabel {
        fr
        en
      }
      translatedContent {
        fr
        en
      }
    }
  }
`;

const FIFTEEN_MINUTES_IN_MS = 900000;

const CommunicationBanner = ({ communicationBanner: communicationBannerProp }: Props) => {
  const translate = useTranslate('communication-banner');
  const [hiddenBannerId, setHiddenBannerId] = useLocalStorage<string>('hiddenBannerId', '');
  const [open, setOpen] = useState<boolean>(false);

  const { data } = useQuery<{ activeCommunicationBanner: CommunicationBanner | null }>(
    ACTIVE_COMMUNICATION_BANNER_QUERY,
    {
      pollInterval: FIFTEEN_MINUTES_IN_MS
    }
  );

  const communicationBanner = communicationBannerProp ?? data?.activeCommunicationBanner;
  const isHidden = !communicationBanner || (communicationBanner && hiddenBannerId === communicationBanner.id);

  useEffect(() => {
    if (communicationBannerProp) return;

    document.body.classList.toggle('communication-banner', !isHidden);
  }, [data, communicationBannerProp, isHidden]);

  const label = useTranslated(communicationBanner?.translatedLabel);
  const content = useTranslated(communicationBanner?.translatedContent);

  const handleOnHide = () => {
    setOpen(false);
    if (communicationBanner && communicationBanner.id) {
      setHiddenBannerId(communicationBanner.id);
    }
  };

  const actions: ModalAction[] = [];

  actions.push({
    label: translate('hide'),
    onClick: handleOnHide,
    variant: DaisyButtonVariant.OUTLINE
  });
  actions.push({
    label: translate('close'),
    onClick: () => setOpen(false),
    variant: DaisyButtonVariant.PRIMARY
  });

  if (!communicationBanner) {
    return null;
  }

  if (!communicationBannerProp && isHidden) {
    return null;
  }

  const getIconFromVariant = () => {
    switch (communicationBanner.variant) {
      case DaisyAlertVariant.ERROR:
        return 'circle-xmark';
      case DaisyAlertVariant.WARNING:
        return 'triangle-exclamation';
      case DaisyAlertVariant.SUCCESS:
        return 'check-circle';
      case DaisyAlertVariant.INFO:
        return 'info-circle';
      default:
        throw new Error('Unknown variant');
    }
  };

  return (
    <>
      <Modal
        title={label}
        onClose={() => setOpen(false)}
        actions={actions}
        className="modal-bottom sm:modal-middle"
        open={open}
      >
        {content}
      </Modal>

      <div
        className={classNames('flex items-center justify-between p-4', {
          'bg-error': communicationBanner.variant === DaisyAlertVariant.ERROR,
          'bg-warning': communicationBanner.variant === DaisyAlertVariant.WARNING,
          'bg-info': communicationBanner.variant === DaisyAlertVariant.INFO,
          'bg-success': communicationBanner.variant === DaisyAlertVariant.SUCCESS
        })}
      >
        <div className="flex items-center gap-2 truncate">
          <Icon icon={getIconFromVariant()} />
          <span className="truncate">{label}</span>
        </div>

        <div className="flex gap-2">
          <button type="button" className="btn btn-sm" onClick={handleOnHide}>
            {translate('hide')}
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              if (communicationBanner.translatedContent) setOpen(true);
            }}
          >
            {translate('view-details')}
          </button>
        </div>
      </div>
    </>
  );
};

export default CommunicationBanner;
