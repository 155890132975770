import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CloseIcon from '@material-ui/icons/Close';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { get, omit } from 'lodash';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import { END_DATE, START_DATE } from '../../../shared/collection/queryConstants';
import { defaultFormatMomentDate } from '../../../shared/utils/dateTime/dateTimeUtils';

type DateRange = {
  startDate?: string | number;
  endDate?: string | number;
};

export interface Props {
  dateRange?: DateRange;
  onChange: (dateRange: DateRange) => void;
}

const ActivitiesSearchDateOptions: React.FunctionComponent<Props> = ({ dateRange = {}, onChange }) => {
  const translate = useTranslate('activities-search-options');
  return (
    <List subheader={<ListSubheader style={{ backgroundColor: 'white' }}>{translate('by-date')}</ListSubheader>}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker
          TextFieldComponent={({ onClick }) => (
            <ListItem button onClick={onClick}>
              <ListItemText
                primary={
                  (get(dateRange, 'startDate') && defaultFormatMomentDate(Number(get(dateRange, 'startDate')))) ||
                  translate('start-date')
                }
              />
              {get(dateRange, 'startDate') && (
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onChange(omit(dateRange, START_DATE))}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          )}
          value={moment(get(dateRange, 'startDate'))}
          leftArrowIcon={<PrevArrowIcon />}
          rightArrowIcon={<NextArrowIcon />}
          onChange={(startDate) =>
            onChange({ ...dateRange, [START_DATE]: startDate?.startOf('day').valueOf() || undefined })
          }
        />
      </MuiPickersUtilsProvider>

      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker
          TextFieldComponent={({ onClick }) => (
            <ListItem button onClick={onClick}>
              <ListItemText
                primary={
                  (get(dateRange, 'endDate') && defaultFormatMomentDate(Number(get(dateRange, 'endDate')))) ||
                  translate('end-date')
                }
              />
              {get(dateRange, 'endDate') && (
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onChange(omit(dateRange, END_DATE))}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          )}
          value={moment(get(dateRange, 'endDate'))}
          leftArrowIcon={<PrevArrowIcon />}
          rightArrowIcon={<NextArrowIcon />}
          onChange={(endDate) => onChange({ ...dateRange, [END_DATE]: endDate?.startOf('day').valueOf() || undefined })}
        />
      </MuiPickersUtilsProvider>
    </List>
  );
};

export default ActivitiesSearchDateOptions;
