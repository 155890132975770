import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import MoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { getDateMonthName, getDateYear } from '../../../../utils/dateTimeUtils';

export const styles = (theme) => ({
  monthName: {
    textTransform: 'capitalize'
  },
  calendarWhiteButton: {
    color: theme.palette.common.white,
    paddingTop: 0,
    paddingBottom: 0
  },
  calendarButtonLabel: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start'
  },
  calendarInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

export function MonthButton({ momentDate, onClick, white, classes }) {
  return (
    <Button
      classes={{ label: classes.calendarButtonLabel }}
      className={white ? classes.calendarWhiteButton : ''}
      onClick={onClick}
    >
      <div className={classes.calendarInfo}>
        <Typography variant="h6" color="inherit" className={classes.monthName}>
          {getDateMonthName(momentDate.valueOf())}
        </Typography>
        <Typography variant="caption" color="inherit">
          {getDateYear(momentDate.valueOf())}
        </Typography>
      </div>
      <MoreIcon />
    </Button>
  );
}

MonthButton.defaultProps = {
  white: false
};

MonthButton.propTypes = {
  momentDate: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  white: PropTypes.bool
};

export default compose(withStyles(styles))(MonthButton);
