import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { buildUnitsString, findUserSpecialtyRelatedCodeUnits } from '../../../../act/shared/domain/roles/roles.utils';

const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    codeItemSelected: {
      backgroundColor: theme.palette.primary[500],
      color: theme.palette.common.white
    },
    codeItemText: {
      '& span': {
        '& em': {
          fontStyle: 'normal',
          padding: '0 0 1px',
          background: 'inherit',
          boxShadow: 'inset 0 -2px 0 0 rgb(71, 46, 145)',
          color: 'inherit'
        }
      },
      [breakpoints.down('xs')]: {
        paddingRight: 0,
        paddingLeft: 0
      }
    },
    unitText: {
      textAlign: 'right',
      [breakpoints.down('xs')]: {
        paddingRight: 0
      }
    },
    icon: {
      color: theme.palette.grey[500]
    },
    selected: {
      color: theme.palette.primary[500]
    },
    lessPaddingPlease: {
      right: -theme.spacing(0.5)
    },
    markup: {
      backgroundColor: theme.palette.primary.main,
      top: '-11px',
      color: 'white',
      border: 'solid',
      right: '10px',
      position: 'relative',
      borderRadius: '100%',
      padding: '0 5px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    smallText: {
      fontSize: '0.75rem !important'
    },
    smallGutters: {
      [breakpoints.down('xs')]: {
        paddingLeft: '2px',
        paddingRight: '2px'
      }
    },
    minWidth: {
      minWidth: theme.spacing(9)
    },
    paddingRight: {
      paddingRight: theme.spacing(3)
    }
  };
};

class CodeSuggestion extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { suggestion, isSelected, isInFavorites } = this.props;
    return (
      nextProps.suggestion.code !== suggestion.code ||
      nextProps.isSelected !== isSelected ||
      nextProps.isInFavorites !== isInFavorites
    );
  }

  handleClick = () => {
    const { onSuggestionClick, suggestion } = this.props;
    onSuggestionClick(suggestion);
  };

  handleAddToFavoritesClick = () => {
    const { onFavoriteClick, suggestion, isInFavorites = false } = this.props;

    if (isInFavorites) return;

    onFavoriteClick(suggestion);
  };

  render() {
    const { suggestion, isInFavorites, isSelected, withMarkup, onFavoriteClick, classes, small } = this.props;
    return (
      <ListItem button onClick={this.handleClick} classes={{ gutters: classes.smallGutters }}>
        <ListItemIcon className={classes.paddingRight}>
          <>
            <Chip
              label={suggestion.code}
              classes={small ? { label: classes.smallText } : {}}
              className={isSelected ? classNames(classes.codeItemSelected, classes.minWidth) : classes.minWidth}
            />
            {withMarkup && <Typography className={classes.markup}>%</Typography>}
          </>
        </ListItemIcon>
        <ListItemText
          primary={suggestion.description}
          classes={small ? { primary: classes.smallText, secondary: classes.smallText } : {}}
          secondary={
            suggestion.unitsBySpecialty ? buildUnitsString(findUserSpecialtyRelatedCodeUnits(suggestion.code)) : null
          }
          className={classes.codeItemText}
        />
        {onFavoriteClick && (
          <ListItemSecondaryAction className={classes.lessPaddingPlease}>
            <IconButton onClick={() => this.handleAddToFavoritesClick(suggestion)}>
              <FavoriteIcon className={classNames(classes.icon, { [classes.selected]: isInFavorites })} />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

CodeSuggestion.defaultProps = {
  isSelected: false,
  isInFavorites: false,
  withMarkup: false,
  onFavoriteClick: undefined,
  small: false
};

CodeSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  isInFavorites: PropTypes.bool,
  withMarkup: PropTypes.bool,
  onSuggestionClick: PropTypes.func.isRequired,
  onFavoriteClick: PropTypes.func,
  small: PropTypes.bool
};

export default withStyles(styles)(CodeSuggestion);
