import React from 'react';
import Icon from 'application/components/icon';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  icon?: string;
  iconPosition?: 'left' | 'right';
  type?: 'button' | 'submit' | 'reset';
  theme?: 'primary' | 'link';
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  tabIndex?: number;
  solid?: boolean;
  onClick?: () => void;
}

const Button = ({
  children,
  disabled,
  icon,
  iconPosition = 'right',
  loading,
  theme,
  className,
  solid,
  onClick,
  ...htmlButtonProps
}: Props) => (
  <button
    type="button"
    className={classNames(className, {
      'btn btn-primary': theme === 'primary',
      'btn btn-link': theme === 'link',
      'flex-row-reverse': iconPosition === 'left'
    })}
    disabled={loading || disabled}
    onClick={onClick}
    {...htmlButtonProps}
  >
    {children}
    {icon && !loading && <Icon solid={solid} icon={icon} />}
    {loading && <Icon solid={solid} icon="spinner-third" className="fa-spin" />}
  </button>
);

export default Button;
