import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';

export function FormattedStringMessage({ intl, ...message }) {
  return <>{intl.formatMessage(message)}</>;
}

FormattedStringMessage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

export default injectIntl(FormattedStringMessage);
