import { deburr } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import contextElements from '../data/context-elements.json';

export interface RAMQContextElement {
  code: string;
  description: string;
  effectiveStartDate: string;
  effectiveEndDate: string;
}

export class RAMQContextElements {
  private static ramqContextElements: Map<string, RAMQContextElement>;

  static get contextElementsMap() {
    if (!this.ramqContextElements) {
      this.ramqContextElements = this.buildRamqDict();
    }

    return this.ramqContextElements;
  }

  private static buildRamqDict(): Map<string, RAMQContextElement> {
    const contextElementsMap = new Map<string, RAMQContextElement>();
    (contextElements as RAMQContextElement[]).forEach((entry) => contextElementsMap.set(entry.code, entry));
    return contextElementsMap;
  }

  public static get(code: string): RAMQContextElement | undefined {
    return this.contextElementsMap.get(code);
  }

  public static search(
    searchTerm: string,
    serviceDate: Moment | number | undefined = undefined,
    maxResult: number = 21
  ): RAMQContextElement[] {
    const searchTokens = deburr(searchTerm).toLowerCase().split(' ');
    const date = serviceDate ? moment(serviceDate) : null;

    const foundCodes: RAMQContextElement[] = [];
    for (const code of Array.from(this.contextElementsMap.values())) {
      const descriptionLower = deburr(code.description).toLowerCase();
      if (
        (!date || this.isEffectiveOn(code, date)) &&
        searchTokens.every((searchToken) => code.code === searchToken || descriptionLower.includes(searchToken))
      ) {
        foundCodes.push(code);
      }
      if (foundCodes.length === maxResult) {
        break;
      }
    }

    return foundCodes;
  }

  public static isEffectiveOn(
    ramqContextElement: RAMQContextElement,
    serviceDate: Moment | number | undefined
  ): boolean {
    const date = moment(serviceDate); // When serviceDate is undefined, fallback on current date

    if (!ramqContextElement.effectiveEndDate) {
      return date.isSameOrAfter(ramqContextElement.effectiveStartDate);
    }

    return date.isBetween(
      moment(ramqContextElement.effectiveStartDate),
      moment(ramqContextElement.effectiveEndDate),
      undefined, // Granularity
      '[]' // Inclusivity
    );
  }
}
