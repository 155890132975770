import { logError } from '../../../shared/utils/plog';
import { RAMQPlaces } from '../../../shared/ramq/RamqPlaces';

export const SAVE_PLACE = 'addPlace';
export const SAVE_PLACE_FAILURE = 'addPlaceFailure';
export const UPDATE_PLACE_SIGNATORY = 'updatePlaceSignatory';
export const REMOVE_PLACE = 'removePlace';
export const REMOVE_PLACE_FAILURE = 'removePlaceFailure';
export const GET_PLACES = 'getPlaces';
export const GET_PLACES_SUCCESS = 'getPlacesSuccess';
export const GET_PLACES_FAILURE = 'getPlacesFailure';

export function saveFavoritePlace(document) {
  return {
    type: SAVE_PLACE,
    document
  };
}

export function updatePlaceSignatory(signatory, placeId) {
  return {
    type: UPDATE_PLACE_SIGNATORY,
    signatory,
    placeId
  };
}

export function removeFavoritePlace(document) {
  return {
    type: REMOVE_PLACE,
    document
  };
}

export function getPlacesPreferences() {
  return {
    type: GET_PLACES
  };
}

export function getPlacesPreferencesSuccess(places) {
  return {
    type: GET_PLACES_SUCCESS,
    places: places.map((placePreference) => ({
      ...placePreference,
      ...(RAMQPlaces.get(placePreference.number) || {})
    }))
  };
}

export function addPlaceToUserPreferenceFailure(error) {
  return {
    type: SAVE_PLACE_FAILURE,
    error
  };
}

export function getPlacesPreferencesFailure(error) {
  logError(['getPlacesPreferencesFailure', error]);
  return {
    type: GET_PLACES_FAILURE,
    error
  };
}

export function removePlaceFromUserPreferenceFailure(error) {
  return {
    type: REMOVE_PLACE_FAILURE,
    error
  };
}
