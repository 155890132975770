import orderBy from 'lodash/orderBy';
import SpecialtyContextElementRepository from '../domain/SpecialtyContextElementRepository';
import ContextElement from '../domain/ContextElement';
import Category from '../../shared/domain/Category';
import { specialtyContextElementsCollectionRef } from '../../../firebase/collection/collectionReferences';

class FirestoreSpecialtyContextElementRepository implements SpecialtyContextElementRepository {
  public async listPresetCategoriesIn(listName: string): Promise<Category<ContextElement>[]> {
    const snapshot = await specialtyContextElementsCollectionRef(listName).get();
    const documents: Category<ContextElement>[] = [];

    snapshot.forEach((document) => documents.push(document.data()));

    return orderBy(documents, ['order']);
  }
}

export default FirestoreSpecialtyContextElementRepository;
