import LumpSum, { LumpSumDay } from '../../domain/activity/lumpSum/model/LumpSum';
import { Mixte, MixteDay } from '../../domain/activity/mixte/model/Mixte';
import { TrainingDay, Training } from '../../domain/activity/training/model/Training';

abstract class PeriodAmountService {
  // eslint-disable-next-line no-unused-vars
  constructor(protected activity: LumpSum | Mixte | Training) {}

  abstract getTotalAmount(): number;

  abstract getTotalAmountByCode(): TotalAmountByCode[];

  abstract getAmountByDay(): DayWithComputedAmount[];

  protected mapDaysWithPopulatedPlace() {
    return (this.activity.days as any[]).map((day) => ({
      ...day,
      place: this.activity.place
    }));
  }
}

export type DayWithComputedAmount = (MixteDay | LumpSumDay | TrainingDay) & { amount: number };

export type TotalAmountByCode = {
  code: string;
  amount: number;
};

export default PeriodAmountService;
