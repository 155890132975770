import Activity from '../../domain/activity/global/model/Activity';
import { isManualCorrectionDefinedInActivity } from '../computeExpectedAmountUtil';
import ActAmountComputator from './ActAmountComputator';
import ManualCorrectionActAmountComputator from './ActAmountComputator.ManualCorrection';
import PaidActAmountComputator from './ActAmountComputator.Paid';
import DefaultActAmountComputator from './ActAmountComputator.Default';
import ActivityStatus from '../../core/activity/domain/ActivityStatus';

export default class ActAmountComputatorFactory {
  public create(activity: Activity): ActAmountComputator {
    if (isManualCorrectionDefinedInActivity(activity)) {
      return new ManualCorrectionActAmountComputator(activity);
    }

    if (activity.status === ActivityStatus.PAID) {
      return new PaidActAmountComputator(activity);
    }

    return new DefaultActAmountComputator(activity);
  }
}
