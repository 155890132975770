import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import Section from 'application/components/form-controls/section';
import useTranslate from 'application/hooks/use-translate';
import useFirestoreUserProfile from 'application/hooks/use-firestore-user-profile';
import React, { useState } from 'react';
import OptionsMenu from 'application/components/options-menu';
import TextInput from 'application/components/form-controls/text-input';
import Divider from 'application/components/form-controls/divider';
import Icon from 'application/components/icon';
import { useSelector } from 'react-redux';
import useFirestoreUserAccountants, { UserAccountant } from 'application/hooks/use-firestore-user-accountants';

const DEFAULT_ACCOUNTANT = { fullName: '', email: '' };

const Accountants = () => {
  const translate = useTranslate('pages.settings.partners.accountants');
  const userId = useSelector<{ authentication: { user: { uid: string } } }, string>(
    (state) => state.authentication.user.uid
  );
  const { accountants, addAccountant, deleteAccountant } = useFirestoreUserAccountants(userId);
  const [user] = useFirestoreUserProfile(userId);
  const [newAccountant, setNewAccountant] = useState(DEFAULT_ACCOUNTANT);

  const handleAddAccountant = () => {
    if (!(newAccountant.email && newAccountant.fullName)) return;

    addAccountant(newAccountant);

    setNewAccountant(DEFAULT_ACCOUNTANT);
  };

  const handleRemoveAccountant = (accountant: UserAccountant) => {
    deleteAccountant(accountant.id);
  };

  if (!user) return null;

  return (
    <Section helper={translate('helper')} label={translate('title')}>
      <div className="relative w-full">
        {!user.stripeSubscriptionMetadata?.isExpert && (
          <div className="absolute z-50 flex h-full w-full flex-col items-center justify-center gap-3 bg-gray-50/90 p-12 text-gray-500">
            <Icon className="size self-center text-3xl text-primary" icon="lock" />
            <h3 className="font-bold">{translate('feature-unavailable')}</h3>
            <p className="text-sm">{translate('upgrade-to-expert-package')}</p>
          </div>
        )}

        <FieldContainer label={translate('full-name')}>
          <TextInput
            disabled={!user.stripeSubscriptionMetadata?.isExpert}
            onChange={(value: string) => setNewAccountant({ ...newAccountant, fullName: value })}
            type="text"
            value={newAccountant.fullName}
          />
        </FieldContainer>
        <FieldContainer label={translate('email-address')}>
          <TextInput
            disabled={!user.stripeSubscriptionMetadata?.isExpert}
            onChange={(value: string) => setNewAccountant({ ...newAccountant, email: value })}
            type="text"
            value={newAccountant.email}
          />
        </FieldContainer>
        <Section.Footer>
          <Button
            disabled={!(newAccountant.email && newAccountant.fullName && user.stripeSubscriptionMetadata?.isExpert)}
            className="btn btn-primary"
            type="button"
            onClick={handleAddAccountant}
          >
            {translate('add-accountant')}
          </Button>
        </Section.Footer>

        {accountants && accountants.length > 0 && (
          <>
            <hr className="w-full" />

            <Divider subtitle={translate('added-accountants')} />

            <table className="table table-zebra">
              <tbody>
                {accountants?.map((accountant) => (
                  <tr key={accountant.email}>
                    <td>
                      <strong>{accountant.fullName}</strong>
                      <br />
                      <a href={`mailto:${accountant.email}`}>{accountant.email}</a>
                    </td>
                    <td className="text-right">
                      <OptionsMenu>
                        <OptionsMenu.Item
                          label={translate('remove')}
                          onClick={() => handleRemoveAccountant(accountant)}
                        />
                      </OptionsMenu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Section>
  );
};

export default Accountants;
