import { ActCode } from './ActCode';
import { RequiredDetail, RequiredDetails } from '../requiredDetail.type';
import unitNumberMeasurementElement from '../UnitNumberMeasurementElement/unitNumberMeasurementElement';
import endTimeRequired from '../EndTimeRequired/endTimeRequired';
import reassignmentOtherPlace from '../ReassignmentOtherPlace/reassignmentOtherPlace';
import canceledActivities from '../CanceledActivities/canceledActivities';
import particularSituation from '../ParticularSituation/particularSituation';
import remote from '../Remote/remote';
import telemedicine from '../Telemedicine/telemedicine';

export default class ActCode42188 implements ActCode {
  getRequiredDetails(): RequiredDetails {
    return [
      unitNumberMeasurementElement,
      endTimeRequired as RequiredDetail,
      reassignmentOtherPlace,
      telemedicine,
      remote,
      canceledActivities,
      particularSituation
    ];
  }
}
