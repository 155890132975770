import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import GroupsIndex from 'application/pages/groups/index';
import { ADMINISTRATION_ROUTE, GROUPS_ROUTE } from 'application/routes';
import AdministrationIndex from 'application/pages/administration';
import CommunicationBanner from 'application/components/communication-banner';
import Role from 'shared/domain/authentication/Role';
import RestrictedOutlet from 'application/components/restricted-outlet';
import SideNavigation from 'application/components/side-navigation';
import SettingsIndex from 'application/pages/settings/settings.index';
import { useSelector } from 'react-redux';
import FullScreenLoading from 'app/components/Loadings/FullScreenLoading/FullScreenLoading';
import AppHeader from '../../components/AppHeader/AppHeader';
import BottomNavigation from '../../components/BottomNavigation/BottomNavigation';
import { ON_BOTTOM_MENU_CONTAINER_ID } from '../../components/Portals/OnTopBottomNavigationPortal/OnTopBottomNavigationPortal';
import ActivitiesRoute from '../ActivitiesRoute/ActivitiesRoute';
import SearchActivitiesRoute from '../ActivitiesRoute/SearchActivitiesRoute/SearchActivitiesRoute';
import AssistantRoute from '../AssistantRoute/AssistantRoute';
import {
  ACTIVITIES_ROUTE,
  ADMIN_REPORT_ROUTE,
  ASSISTANT_ROUTE,
  RAMQ_LOG_ROUTE,
  REPORTS_ROUTE,
  SEARCH_ROUTE,
  SETTINGS_ROUTE,
  USERS_ROUTE
} from '../Navigation/constants';
import RamqCallLog from '../RamqCallLog/RamqCallLog';
import ReportsRoute from '../ReportsRoute/ReportsRoute';
import UsersRoute from '../UsersRoute/UsersRoute';
import styles from './styles';
import IntercomWidget from '../../components/Intercom/IntercomWidget';
import AdminReportContainer from '../AdminReports/AdminReportsContainer/AdminReportContainer';

export function Main({ classes }) {
  const isUserProfileReady = useSelector((state) => state.user?.isProfileReady ?? false);
  const isExperimentalMemoryOptimization = useSelector(
    (state) => state.user?.profile?.isExperimentalMemoryOptimization ?? false
  );

  if (!isUserProfileReady) {
    return <FullScreenLoading />;
  }

  return (
    <IntercomWidget>
      <div className={classes.wrapperPage}>
        <Hidden smDown>
          <SideNavigation />
        </Hidden>
        <main id="main-page" className={classes.mainPage}>
          <AppHeader />
          <div className={classes.mainPageContent}>
            <Routes>
              <Route path={`${ACTIVITIES_ROUTE}/*`} element={<ActivitiesRoute />} />
              <Route path={SEARCH_ROUTE}>
                <Route path={`${ACTIVITIES_ROUTE}/*`} element={<SearchActivitiesRoute />} />
              </Route>
              <Route path={`${ASSISTANT_ROUTE}/*`} element={<AssistantRoute />} />
              <Route path={`${REPORTS_ROUTE}/*`} element={<ReportsRoute />} />
              <Route path={`${SETTINGS_ROUTE}/*`} element={<SettingsIndex />} />
              <Route path={`${GROUPS_ROUTE}/*`} element={<GroupsIndex />} />
              <Route element={<RestrictedOutlet atLeastRole={Role.Agent} />}>
                <Route path={RAMQ_LOG_ROUTE} element={<RamqCallLog />} />
                <Route path={`${USERS_ROUTE}/*`} element={<UsersRoute />} />
                <Route path={ADMIN_REPORT_ROUTE} element={<AdminReportContainer />} />
              </Route>
              <Route element={<RestrictedOutlet atLeastRole={Role.Admin} />}>
                <Route path={`${ADMINISTRATION_ROUTE}/*`} element={<AdministrationIndex />} />
              </Route>
              <Route
                index
                element={<Navigate to={isExperimentalMemoryOptimization ? ASSISTANT_ROUTE : ACTIVITIES_ROUTE} />}
              />
            </Routes>
          </div>

          <CommunicationBanner />

          <Hidden mdUp>
            <div id={ON_BOTTOM_MENU_CONTAINER_ID} className={classes.onBottomMenuContainer} />
            <BottomNavigation />
          </Hidden>
        </main>
      </div>
    </IntercomWidget>
  );
}

export default withStyles(styles)(Main);
