import get from 'lodash/get';
/* eslint-disable quote-props */
import Act from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';
import PlaceDomain from '../../../../shared/domain/place/model/PlaceDomain';

const placePrefixesByCode = new Map<string, string>([
  ['9150', '0'],
  ['9060', '0'],
  ['9160', '0'],
  ['15386', '0'],
  ['15387', '0'],
  ['9152', '0'],
  ['9161', '0'],
  ['78', '0'],
  ['99', '0'],
  ['9168', '0'],
  ['9162', '0'],
  ['9170', '0'],
  ['15709', '0'],
  ['15391', '0'],
  ['9164', '0'],
  ['15272', '0'],
  ['15388', '0'],
  ['15389', '0'],
  ['15390', '0'],
  ['9147', '0'],
  ['9296', '0'],
  ['9176', '0'],
  ['9148', '0'],
  ['9201', '6'],
  ['9212', '6'],
  ['9202', '6'],
  ['9171', '6'],
  ['9172', '6']
]);

const validateCodeCanBeUsedInSelectedPlace = (act: Act) => {
  if (!get(act, 'place.number')) return {};

  const place = PlaceDomain.fromDto(act.place);

  if (place.isCms()) return {};

  const hasIncorrectPlacePrefix = act.codes.some(({ code, isCabinetOnly }) => {
    let prefixes;

    if (isCabinetOnly) {
      prefixes = ['5', '6'];
    } else if (placePrefixesByCode.has(code)) {
      prefixes = [placePrefixesByCode.get(code)];
    }

    const [placeNumberFirstDigit] = act.place.number;

    return prefixes && !prefixes.includes(placeNumberFirstDigit);
  });

  return hasIncorrectPlacePrefix
    ? {
        [actFormFields.place]: "L'établissement choisi ne correspond pas au type de code entré"
      }
    : {};
};

export default validateCodeCanBeUsedInSelectedPlace;
