import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  container: {
    minHeight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

function CenteredActivityResponseText({ text, classes }) {
  return (
    <div className={classes.container}>
      <Typography>{text}</Typography>
    </div>
  );
}

CenteredActivityResponseText.propTypes = {
  text: PropTypes.string.isRequired
};

export default withStyles(styles)(CenteredActivityResponseText);
