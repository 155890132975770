import { ActCode, NatureType } from 'shared/core/activity/domain/Act';
import { AssociatedField } from 'app/codeSpecificLogic/requiredDetails/requiredDetail.type';
import telemedicine from 'app/codeSpecificLogic/requiredDetails/Telemedicine/telemedicine';
import { chronicPainCodes } from 'shared/ramq/domainValues/codeActivityAreas';

const addTelemedicineRequiredDetailIfNeeded = (actCode: ActCode): AssociatedField[][] => {
  if (actCode.natureType === NatureType.VISIT || chronicPainCodes.includes(actCode.code)) {
    return [telemedicine.associatedFields];
  }
  return [];
};

export default addTelemedicineRequiredDetailIfNeeded;
