import doctorSpecialty from '../../domain/doctorSpecialties';

export default function getSpecialtyOptions(localeTranslateKeys) {
  return Object.keys(doctorSpecialty)
    .map((key) => ({
      label: localeTranslateKeys[`doctor.specialty.${key}`],
      value: key
    }))
    .sort((aSpecialty, anotherSpecialty) => {
      if (aSpecialty.label === anotherSpecialty.label) return 0;
      if (aSpecialty.label < anotherSpecialty.label) return -1;

      return 1;
    });
}
