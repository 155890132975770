import PropTypes from 'prop-types';
import React from 'react';
import ConfirmationDialog from '../../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import DialogTrigger from '../../../../../components/Dialogs/DialogTrigger/DialogTrigger';
import RadioListDialog from '../../../../../components/Dialogs/RadioListDialog/RadioListDialog';
import LoadingButton from '../../../../../components/button/LoadingButton/LoadingButton';

const renderButton = (loading, disabled) => (
  <LoadingButton label="Ramq Annuler" loading={loading} disabled={disabled} />
);

function RamqCancelButton({ loading, onCancelRamq, activeBills, disabled }) {
  return activeBills.length > 1 ? (
    <DialogTrigger
      DialogComponent={RadioListDialog}
      dialogProps={{
        items: activeBills,
        title: 'RAMQ Annuler - Sélectionnez une facture'
      }}
      onItemSelected={(selectedBillNumber) => onCancelRamq(selectedBillNumber)}
    >
      {renderButton(loading, disabled)}
    </DialogTrigger>
  ) : (
    <DialogTrigger
      DialogComponent={ConfirmationDialog}
      onItemSelected={() => onCancelRamq()}
      dialogProps={{
        title: 'Annuler la facture',
        text: 'Êtes-vous sûr de vouloir annuler cette facture ?',
        confirmationLabel: 'Oui',
        cancelLabel: 'Non'
      }}
    >
      {renderButton(loading, disabled)}
    </DialogTrigger>
  );
}

RamqCancelButton.propTypes = {
  activeBills: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onCancelRamq: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default RamqCancelButton;
