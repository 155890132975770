import React from 'react';
import COST_OF_STAY_FEE_TYPES from '../../../shared/ramq/domainValues/costOfStayFeeTypes';
import TRANSPORT_FEES_TYPES from '../../../shared/ramq/domainValues/transportFeesTypes';
import TRANSPORT_TYPES from '../../../shared/ramq/domainValues/transportTypes';
import FormattedStringMessage from '../../components/FormattedStringMessage/FormattedStringMessage';
import messages from './messages';

export const TRAVEL_EXPENSES_FORM_NAME = 'travelExpensesForm';
export const TRANSPORT_TYPES_LIST = [
  { label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[0]]} />, value: TRANSPORT_TYPES.autobus },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[1]]} />,
    value: TRANSPORT_TYPES.covoiturage
  },
  { label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[2]]} />, value: TRANSPORT_TYPES.taxi },
  { label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[3]]} />, value: TRANSPORT_TYPES.train },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[4]]} />,
    value: TRANSPORT_TYPES.traversier
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[5]]} />,
    value: TRANSPORT_TYPES.vehiculeLoue
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[6]]} />,
    value: TRANSPORT_TYPES.vehiculePerso
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[7]]} />,
    value: TRANSPORT_TYPES.volCommercial
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[8]]} />,
    value: TRANSPORT_TYPES.volPersonnel
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_TYPES)[9]]} />,
    value: TRANSPORT_TYPES.volNolise
  }
];

export const TRAVEL_EXPENSE_ATTACHMENT = 'travelExpenseAttachment';
export const TRAVEL_EXPENSES_ATTACHMENTS_LIST_FIELD = 'attachments';
export const TRAVEL_EXPENSES_UPLOAD_ATTACHMENT_FIELD = 'uploadAttachment';

export const STAY_TYPES_LIST = [
  {
    label: <FormattedStringMessage {...messages[Object.keys(COST_OF_STAY_FEE_TYPES)[0]]} />,
    value: COST_OF_STAY_FEE_TYPES.hotel
  }
];

export const TRANSPORT_FEES_LIST = [
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_FEES_TYPES)[0]]} />,
    value: TRANSPORT_FEES_TYPES.rentalCar
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_FEES_TYPES)[1]]} />,
    value: TRANSPORT_FEES_TYPES.gaz
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_FEES_TYPES)[2]]} />,
    value: TRANSPORT_FEES_TYPES.parking
  },
  {
    label: <FormattedStringMessage {...messages[Object.keys(TRANSPORT_FEES_TYPES)[3]]} />,
    value: TRANSPORT_FEES_TYPES.general
  }
];

export const GLOBAL_POSSIBLE_CONTEXT_ELEMENTS = [{ label: "Déplacement pour prélèvement d'organes", value: '450' }];
export const LINE_SPECIFIC_POSSIBLE_CONTEXT_ELEMENTS = [
  { label: 'Demande de remboursement des frais de transport déjà transmise', value: '1718' },
  { label: "Temps d'attente imprévu", value: '1719' },
  { label: "Temps d'attente imprévu entre 7 h et 21 h", value: '1814' }
];
