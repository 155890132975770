/* eslint-disable no-shadow, no-unused-vars */
enum ActivityStatus {
  NO_STATUS = 'na',
  SENT = 'sent',
  WAITING = 'waiting',
  NEED_FIX = 'needFix',
  PROCESSING = 'processing',
  PAID = 'paid',
  CANCELED = 'canceled'
}
/* eslint-enable no-unused-vars */

export default ActivityStatus;
