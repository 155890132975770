import moment from 'moment';

export const LETTRE_ENTENTE_241_EFFECTIVE_DATE = moment('07-12-2020', 'DD-MM-YYYY').valueOf();
export const SNT_NON_AMBULATOIRE = 'sntNonAmbulatoire';
export const SNT_AMBULATOIRE_CLINIQUE_DESIGNE_EVALUATION = 'sntAmbulatoireCliniqueDesigneEvaluation';
export const MD_REAFFECTE = 'mdReaffecte';
export const MD_ENCEINTE = 'mdEnceinte';
export const MD_INFECTE = 'mdInfecte';
export const MD_QUARANTAINE = 'mdQuarantaine';
export const MD_REAFFECTE_AUTRE_LIEU = 'mdReaffecteAutreLieu';
export const MD_REAFFECTE_COVID_ETABLISS = 'mdReaffecteCovidEtabliss';
export const SERVICE_PATIENT_COVID = 'servicePatientCovid';
export const TELEMEDECINE_PAR_TELEPHONE = 'telemedecineParTelephone';
export const TELEMEDECINE_PAR_VISIOCONSULTATION = 'telemedecineParVisioconsultation';
export const WEBINAIRE_DIRECT = 'webinaireDirect';
export const WEBINAIRE_DIFFERE = 'webinaireDiffere';
export const SERVICE_DISCONTINU_TELEMEDECINE = 'serviceDiscontinuTelemedecine';
export const ACTIVITES_ANNULEES = 'activitesAnnulees';
export const AUCUN_PER_DIEM = 'aucunPerDiem';
export const DEMI_PER_DIEM = 'demiPerDiem';
export const DEMI_PER_DIEM_DURING_DAY = 'demiPerDiemDuringDay';
export const PER_DIEM = 'perDiem';
