import { Spec } from 'spec-pattern';
import ActBillingContext from '../ActBillingContext';
import { ActBillingContextOptimizationAction } from '../ActBillingContextOptimizationAction';
import OptimizationResponse from '../OptimizationResponse';

export default class ActOptimisationScenario<T> {
  private specification: Spec<ActBillingContext, unknown> | undefined;
  private optimisation: ActBillingContextOptimizationAction<T> | undefined;

  public when(specification: Spec<ActBillingContext, unknown>): this {
    this.specification = specification;

    return this;
  }

  public then(optimisation: ActBillingContextOptimizationAction<T>): this {
    if (this.specification === undefined) {
      throw new Error('specification must be defined before optimisation');
    }

    this.optimisation = optimisation;

    return this;
  }

  public run(context: ActBillingContext): OptimizationResponse<T> | undefined {
    if (this.specification === undefined || this.optimisation === undefined) {
      throw new Error('specification and optimisation must be defined before running scenario');
    }

    if (this.specification.isSatisfiedBy(context)) {
      return this.optimisation.apply(context);
    }

    return undefined;
  }
}
