import moment from 'moment-timezone';

const applyAuditInformation = (object, modifiedBy) =>
  Object.assign(object, {
    auditInformation: {
      modifiedBy,
      modifiedOn: moment().valueOf()
    },
    synchronized: false
  });

export default applyAuditInformation;
