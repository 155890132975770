import classNames from 'classnames';
import useTranslate from 'application/hooks/use-translate';
import React, { useEffect, useState } from 'react';
import CommunicationBanner from 'application/components/communication-banner';
import { Translation } from 'application/hooks/use-translated';
import Card from 'application/components/card';
import TranslatedInput from 'application/components/form/translated-input';
import DatePicker from 'application/components/form/date-picker';
import { gql, useMutation, useQuery } from '@apollo/client';
import useToastApolloError from 'application/hooks/use-toast-apollo-error';
import FieldContainer from 'application/components/form/field-container';
import { DaisyAlertVariant } from 'application/types/daisy';

interface CommunicationBanner {
  variant: DaisyAlertVariant;
  expiredAt: Date | null;
  translatedLabel: Translation;
  translatedContent: Translation;
}

const ACTIVE_COMMUNICATION_BANNER_QUERY = gql`
  query {
    activeCommunicationBanner {
      id
      variant
      expiredAt
      translatedLabel {
        fr
        en
      }
      translatedContent {
        fr
        en
      }
    }
  }
`;

const COMMUNICATION_BANNER_MUTATION = gql`
  mutation UpdateCommunicationBanner($data: UpdateCommunicationBannerInput!) {
    updateCommunicationBanner(data: $data) {
      id
      variant
      expiredAt
      translatedLabel {
        fr
        en
      }
      translatedContent {
        fr
        en
      }
    }
  }
`;

const BLANK_COMMUNICATION_BANNER: CommunicationBanner = {
  variant: DaisyAlertVariant.INFO,
  expiredAt: null,
  translatedLabel: { fr: '', en: '' },
  translatedContent: { fr: '', en: '' }
};

const CommunicationsPage = () => {
  const toastApolloError = useToastApolloError();
  const translate = useTranslate('pages.administration.communications');
  const daisyVariantTranslate = useTranslate('daisy-variant');

  const { data, loading: initialLoading } = useQuery<{ activeCommunicationBanner: CommunicationBanner | null }>(
    ACTIVE_COMMUNICATION_BANNER_QUERY
  );

  const [updateCommunicationBanner] = useMutation<{ updateCommunicationBanner: CommunicationBanner }>(
    COMMUNICATION_BANNER_MUTATION
  );

  const [stagedCommunicationBanner, setStagedCommunicationBanner] =
    useState<CommunicationBanner>(BLANK_COMMUNICATION_BANNER);

  useEffect(() => {
    if (initialLoading || !data?.activeCommunicationBanner) return;
    setStagedCommunicationBanner({
      variant: data.activeCommunicationBanner.variant,
      expiredAt: data.activeCommunicationBanner.expiredAt,
      translatedLabel: {
        fr: data.activeCommunicationBanner.translatedLabel.fr,
        en: data.activeCommunicationBanner.translatedLabel.en
      },
      translatedContent: {
        fr: data.activeCommunicationBanner.translatedContent.fr,
        en: data.activeCommunicationBanner.translatedContent.en
      }
    });
  }, [initialLoading, data, setStagedCommunicationBanner]);

  const onSubmit = () => {
    updateCommunicationBanner({
      variables: {
        data: stagedCommunicationBanner
      }
    }).catch(toastApolloError);
  };

  if (initialLoading) return null;

  return (
    <Card title={translate('header')} className="h-full">
      <form onSubmit={onSubmit}>
        <FieldContainer label={translate('theme')}>
          <div className="join flex flex-wrap">
            {Object.values(DaisyAlertVariant).map((theme) => (
              <button
                key={theme}
                type="button"
                className={classNames('btn join-item grow', {
                  'btn-error': theme === DaisyAlertVariant.ERROR,
                  'btn-warning': theme === DaisyAlertVariant.WARNING,
                  'btn-info': theme === DaisyAlertVariant.INFO,
                  'btn-success': theme === DaisyAlertVariant.SUCCESS
                })}
                onClick={() =>
                  setStagedCommunicationBanner({
                    ...stagedCommunicationBanner,
                    variant: theme
                  })
                }
              >
                {daisyVariantTranslate(theme)}
              </button>
            ))}
          </div>
        </FieldContainer>

        <TranslatedInput
          label={translate('title')}
          required
          value={stagedCommunicationBanner.translatedLabel}
          onChange={(updatedTranslatedLabel) =>
            setStagedCommunicationBanner({
              ...stagedCommunicationBanner,
              translatedLabel: updatedTranslatedLabel
            })
          }
        />

        <TranslatedInput
          type="textarea"
          required
          label={translate('content')}
          value={stagedCommunicationBanner.translatedContent}
          onChange={(updatedTranslatedContent) =>
            setStagedCommunicationBanner({
              ...stagedCommunicationBanner,
              translatedContent: updatedTranslatedContent
            })
          }
        />

        <DatePicker
          label={translate('expire-at')}
          onChange={(updatedExpiredAt) =>
            setStagedCommunicationBanner({
              ...stagedCommunicationBanner,
              expiredAt: updatedExpiredAt
            })
          }
          value={stagedCommunicationBanner.expiredAt}
        />

        <Card.Actions>
          <button
            type="button"
            className="btn btn-error"
            onClick={() => setStagedCommunicationBanner(data?.activeCommunicationBanner ?? BLANK_COMMUNICATION_BANNER)}
          >
            {translate('cancel')}
          </button>

          <button type="submit" className="btn btn-primary">
            {translate('save')}
          </button>
        </Card.Actions>
      </form>

      <FieldContainer label={translate('preview')}>
        <CommunicationBanner communicationBanner={stagedCommunicationBanner} />
      </FieldContainer>
    </Card>
  );
};

export default CommunicationsPage;
