import React from 'react';
import classNames from 'classnames';

interface Props {
  fixedWidth?: boolean;
  icon?: string;
  loading?: boolean;
  className?: string;
  solid?: boolean;
  onClick?: () => void;
}

const Icon = ({ fixedWidth, icon, loading, className, onClick, solid = false }: Props) => {
  if (!icon && loading === undefined) {
    throw new Error('Icon component must have either an icon or loading prop');
  }

  return (
    <i
      onClick={onClick}
      className={classNames(solid ? 'fa-solid' : 'fa-duotone', loading ? 'fa-spinner-third' : `fa-${icon}`, className, {
        'fa-spin': loading,
        'cursor-pointer': onClick,
        'fa-fw': fixedWidth
      })}
    />
  );
};

export default Icon;
