import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import 'viewerjs/dist/viewer.css';
import DeletePictureButton from './DeletePictureButton';
import PlaceHolderImagePreview from './PlaceHolderImagePreview';
import createViewer from './viewerConfig';

export const styles = (theme) => ({
  previewContainer: {
    width: '100%'
  },
  collapsePreviewContainer: {
    width: '100%',
    height: 250,
    position: 'relative',
    marginBottom: theme.spacing(2)
  }
});

export class PicturePreview extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.actId !== this.props.actId) {
      this.closePicturePreview();
    }

    if (prevProps.focusedNam !== this.props.focusedNam) {
      this.focusNam(this.props.extractedNams, this.props.focusedNam);
    }
  }

  componentWillUnmount() {
    this.closePicturePreview();
  }

  closePicturePreview = () => {
    if (this.viewer) {
      this.viewer.destroy();
    }
    this.props.closePicturePreview();
  };

  focusNam(extractedNams, focusedNam) {
    if (!focusedNam || !extractedNams || !this.viewer?.canvas) return;
    const matchingExtractedNam = extractedNams.find(({ nam }) => nam === focusedNam);

    if (!matchingExtractedNam) return;

    const containerWidth = this.viewer.canvas.clientWidth;
    const containerHeight = this.viewer.canvas.clientHeight;

    const topLeftCorner = matchingExtractedNam.vertexes[0];
    const bottomRightCorner = matchingExtractedNam.vertexes[2];
    const width = bottomRightCorner.x - topLeftCorner.x;
    const height = bottomRightCorner.y - topLeftCorner.y;

    this.viewer
      .zoomTo(1)
      .moveTo(
        topLeftCorner.x * -1 + containerWidth / 2 - width / 2,
        topLeftCorner.y * -1 + containerHeight / 2 - height / 2
      );
  }

  async renderView(extractedNams, focusedNam) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.viewer = createViewer({
      viewed() {
        self.focusNam(extractedNams, focusedNam);
      }
    });
  }

  render() {
    const { open, savedPicture, focusedNam, extractedNams, classes } = this.props;

    return savedPicture ? (
      <Collapse
        in={open}
        onEntered={() => this.renderView(extractedNams, focusedNam)}
        onExited={() => this.viewer.destroy()}
      >
        <div className={classes.collapsePreviewContainer}>
          <PlaceHolderImagePreview savedPicture={savedPicture} extractedNams={extractedNams} />
          <DeletePictureButton
            closePicturePreview={this.props.closePicturePreview}
            deleteSavedPicture={this.props.deleteSavedPicture}
          />
        </div>
      </Collapse>
    ) : null;
  }
}

PicturePreview.propTypes = {
  open: PropTypes.bool.isRequired,
  closePicturePreview: PropTypes.func.isRequired,
  actId: PropTypes.string.isRequired,
  savedPicture: PropTypes.object,
  deleteSavedPicture: PropTypes.func.isRequired,
  extractedNams: PropTypes.array.isRequired,
  focusedNam: PropTypes.string
};

PicturePreview.defaultProps = {
  savedPicture: undefined,
  extractedNams: [],
  focusedNam: undefined
};

export default compose(withStyles(styles))(PicturePreview);
