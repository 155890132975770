import { FETCH_INTERCOM_USER_HASH_SUCCEEDED, SET_INTERCOM_UNREAD_MESSAGE_COUNT } from './actions';

export const initialState = {
  hash: undefined,
  count: 0
};

const intercomReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INTERCOM_USER_HASH_SUCCEEDED:
      return {
        ...state,
        hash: action.hash
      };
    case SET_INTERCOM_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        count: action.count
      };
    default:
      return state;
  }
};

export default intercomReducer;
