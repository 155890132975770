import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { openActivitiesSearch } from './actions';
import { usePostHog } from 'posthog-js/react';

export function ActivitiesSearchIcon({ onOpenActivitiesSearch, className }) {
  const posthog = usePostHog();

  const handleOpenActivitiesSearch = () => {
    onOpenActivitiesSearch();
    posthog.capture('Open activities search');
  };

  return (
    <IconButton color="inherit" className={className} onClick={handleOpenActivitiesSearch}>
      <SearchIcon />
    </IconButton>
  );
}

ActivitiesSearchIcon.propTypes = {
  onOpenActivitiesSearch: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ActivitiesSearchIcon.defaultProps = {
  className: ''
};

export const mapDispatchToProps = {
  onOpenActivitiesSearch: openActivitiesSearch
};

export default connect(null, mapDispatchToProps)(ActivitiesSearchIcon);
