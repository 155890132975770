import Icon from 'application/components/icon';
import Markdown from 'application/components/markdown';
import useTranslate from 'application/hooks/use-translate';
import React from 'react';
import { useToggle } from 'react-use';

interface Props {
  label: string;
  helper?: string;
  infoMarkdownSlug?: string;
  required?: boolean;
  children: React.ReactNode;
}

const FieldContainer = ({ label, helper, infoMarkdownSlug, required = false, children }: Props) => {
  const translate = useTranslate('components.form-controls.field-container');
  const [isHelpMardownVisible, toggleIsHelpMardownVisible] = useToggle(false);

  return (
    <div className="flex min-h-[87px] w-full flex-col justify-center gap-2 divide-y border-b border-neutral-200 p-4">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div className="flex-1 justify-start">
          <div className="flex items-center gap-2 text-sm text-neutral-800">
            {label}
            {required && <div className="relative -top-[5px] h-1.5 w-1.5 rounded-full bg-red-500" />}
            {infoMarkdownSlug && (
              <Icon icon="circle-info" solid className="text-pelorous-600" onClick={toggleIsHelpMardownVisible} />
            )}
          </div>
          <div className="text-xs font-normal text-gray-500">{helper}</div>
        </div>

        <div className="mt-3 flex-1 text-sm text-neutral-900 sm:mt-0">{children}</div>
      </div>

      {infoMarkdownSlug && isHelpMardownVisible && (
        <div className="flex flex-col gap-2 p-2">
          <div className="flex items-center gap-2">
            <Icon icon="circle-info" solid className="text-pelorous-600" />
            <div className="text-neutral-800">{translate('info')}</div>
          </div>
          <Markdown slug={infoMarkdownSlug} />
        </div>
      )}
    </div>
  );
};
// Bisoux

export default FieldContainer;
