import { HALF_PERDIEM, NO_PERDIEM, PERDIEM } from './constants';
import MixteSummaryDayLineActivity from './MixteSummaryDayLineActivity.model';
import MixteSummaryDayLineSchedule from './MixteSummaryDayLineSchedule.model';

export default class MixteSummaryDayLine {
  private _activities: MixteSummaryDayLineActivity[];

  private _index: number;

  private _schedule: MixteSummaryDayLineSchedule;

  constructor(index: number, schedule: MixteSummaryDayLineSchedule, activities: MixteSummaryDayLineActivity[] = []) {
    this._activities = activities;
    this._index = index;
    this._schedule = schedule;
  }

  public get activities(): MixteSummaryDayLineActivity[] {
    return this._activities;
  }

  public addActivity(mixteSummaryDayLineActivity: MixteSummaryDayLineActivity): void {
    this._activities.push(mixteSummaryDayLineActivity);
  }

  public get index(): number {
    return this._index;
  }

  public get perDiemReference(): number {
    return this._index + 60;
  }

  public get schedule(): MixteSummaryDayLineSchedule {
    return this._schedule;
  }

  public get duration(): string {
    const addUpDuration = (total: number, activity: MixteSummaryDayLineActivity) =>
      total + parseInt(activity.duration, 10);

    return this._activities.reduce(addUpDuration, 0).toString();
  }

  public get perDiem(): string {
    if (this._schedule.isAllDay) {
      return PERDIEM;
    }

    if (this._schedule.isHalfDay) {
      return HALF_PERDIEM;
    }

    return NO_PERDIEM;
  }
}
