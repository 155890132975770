import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Restrict from 'application/components/restrict';
import useCopyActivityUrl from 'application/hooks/use-copy-activity-url';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Role from 'shared/domain/authentication/Role';

const DesktopActions = ({ activity, onCancel, onSend, onDuplicate, onExport, disabled }) => {
  const copyActivityUrl = useCopyActivityUrl();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Hidden xsDown>
      <IconButton aria-haspopup="true" onClick={handleClick} disabled={disabled}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {onSend && (
          <MenuItem
            onClick={() => {
              onSend();
              handleClose();
            }}
          >
            <FormattedMessage id="act-form.send" />
          </MenuItem>
        )}
        {onDuplicate && (
          <MenuItem
            onClick={() => {
              onDuplicate();
              handleClose();
            }}
          >
            <FormattedMessage id="act-form.duplicate" />
          </MenuItem>
        )}
        {onCancel && (
          <MenuItem
            onClick={() => {
              onCancel();
              handleClose();
            }}
          >
            <FormattedMessage id="global.cancel" />
          </MenuItem>
        )}
        <Restrict atLeastRole={Role.Agent}>
          <MenuItem
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              copyActivityUrl(activity);
              handleClose();
            }}
          >
            Copier URL
          </MenuItem>
        </Restrict>
        {onExport && (
          <Restrict atLeastRole={Role.Developer}>
            <MenuItem
              onClick={() => {
                onExport();
                handleClose();
              }}
            >
              <FormattedMessage id="act-form.export" />
            </MenuItem>
          </Restrict>
        )}
      </Menu>
    </Hidden>
  );
};

DesktopActions.propTypes = {
  activity: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSend: PropTypes.func,
  onDuplicate: PropTypes.func,
  onExport: PropTypes.func,
  disabled: PropTypes.bool
};

DesktopActions.defaultProps = {
  onDuplicate: undefined,
  disabled: false,
  onCancel: undefined
};

export default DesktopActions;
