import { DialogContent, DialogContentText, DialogTitle, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { closeNamSelectorDialog, updateNAMInActForm } from '../actions';
import SingleChoiceNamsSelector from './SingleSelector/SingleChoiceNamsSelector';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

export const DetectedNamsDialog = ({ detectedNams, open, closeDialog, updateNamForm }) => {
  const posthog = usePostHog();

  useEffect(() => {
    if (!open) return;

    posthog.capture('Multiple NAMs Dialog - Opened');
  }, [posthog, open]);

  const handleSelectedNam = (nam) => {
    updateNamForm(nam);
    closeDialog();
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle id="detected-nams-dialog-title">
        <FormattedMessage id="nam.dialog.detectedNams" />
        {' :'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="nam.dialog.editCorrectInvalidNams" />
        </DialogContentText>
        <SingleChoiceNamsSelector nams={detectedNams} onSelectNam={handleSelectedNam} />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button color="primary" onClick={closeDialog}>
          <FormattedMessage id="nam.dialog.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DetectedNamsDialog.defaultProps = {
  detectedNams: [],
  open: false
};

DetectedNamsDialog.propTypes = {
  detectedNams: PropTypes.array,
  open: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  updateNamForm: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  closeDialog: closeNamSelectorDialog,
  updateNamForm: updateNAMInActForm
};

export default compose(connect(null, mapDispatchToProps))(DetectedNamsDialog);
