import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import withStyles, { StyleRulesCallback, WithStyles } from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useMemo, useState } from 'react';
import { compose } from 'redux';
import { AccordionActions, Button, Theme } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'qs';
import FiltersDisplayer from '../../../../../components/FiltersDisplayer/FiltersDisplayer';
import ActivitiesSearchOptions from '../../../../ActivitiesSearch/ActivitiesSearchOptions';
import withSearchLimit from '../../../../ActivitiesSearch/withSearchLimit';
import SearchUrlParameterController from '../../../../ActivitiesSearch/SearchUrlParameter/SearchUrlParameterController';
import ActivityFilterApplier from '../../../../ActivitiesSearch/FilterApplier/ActivityFilterApplier';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  AccordionContainer: {
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 980
  }
});

interface Props extends WithStyles {}

export function ActivitiesSearchBox({ classes }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo<any>(
    () => QueryString.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [searchActivitiesFiltersState, setSearchActivitiesFiltersState] = useState(queryParams);

  return (
    <Accordion className={classes.AccordionContainer} expanded={isExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setIsExpanded((prevState) => !prevState)}>
        <FiltersDisplayer filters={searchActivitiesFiltersState} />
      </AccordionSummary>
      <AccordionDetails>
        <ActivitiesSearchOptions
          searchActivitiesFilters={searchActivitiesFiltersState}
          onChangeSearchFilter={(filter) => {
            const filterApplier = new ActivityFilterApplier();
            setSearchActivitiesFiltersState(filterApplier.apply(searchActivitiesFiltersState, filter));
          }}
        />
      </AccordionDetails>
      <AccordionActions>
        <Button
          color="primary"
          onClick={() => {
            const search = new SearchUrlParameterController(new ActivityFilterApplier()).newSearchQuery(
              {
                ...searchActivitiesFiltersState,
                ...(queryParams.user && { user: queryParams.user })
              },
              null
            );
            setIsExpanded(false);
            navigate({ search });
          }}
        >
          Rechercher
        </Button>
      </AccordionActions>
      <Divider />
    </Accordion>
  );
}

export default compose(withSearchLimit, withStyles(styles))(ActivitiesSearchBox);
