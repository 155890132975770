import statusValidation from '../common/activityValidation';
import { createErrorMapWithEveryValidationFunction, requiredFieldValidations } from '../common/reduxFormValidation';
import expirationValidation from './codes/expirationValidation/expirationValidation';
import sendingValidations from './sendValidation/sendValidation';
import { communicationTherapyCodeBilledWithAnotherVisitCode } from './codes/communicationTherapyCodeBilledWithAnotherVisitCode';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import Act from '../../../shared/domain/activity/act/model/Act';
import User from '../../../shared/domain/user/User';
import article108Validation from './codes/article108Validation';
import durationGreaterThanContextElements from './contextElements/durationGreaterThanContextElements';
import { patientNeedingUrgentCareDisclaimerIsAccepted } from './patientNeedingUrgentCare/patientNeedingUrgentCare';
import codeCanOnlyBeBilledInACmsValidation from './place/codeCanOnlyBeBilledInACmsValidation';
import syntheticPlateSupplementValidation from './codes/syntheticPlateSupplementValidation/syntheticPlateSupplementValidation';
import durationGreaterThan4HoursContextElementValidation from './contextElements/durationGreaterThan4HoursContextElement/durationGreaterThan4HoursContextElement';
import chronicPainVisitTimeValidations from './codes/chronicPainVisitTimeValidation/chronicPainVisitTimeValidations';
import supplement15400CanOnlyBeBilledWithAPrincipalVisitCodeValidation from './codes/supplement15400CanOnlyBeBilledWithAPrincipalVisitCodeValidation';

type ValidateActContext = {
  act: Partial<Act>;
  user: User;
};

const validateAct = ({ act, user }: ValidateActContext) =>
  act.status === ActivityStatus.SENT ? sendingValidations({ act, user }) : basicValidations(act);

export const basicValidations = (act) =>
  createErrorMapWithEveryValidationFunction(
    act,
    statusValidation,
    requiredFieldValidations(['date', 'place']),
    expirationValidation,
    communicationTherapyCodeBilledWithAnotherVisitCode,
    article108Validation,
    durationGreaterThanContextElements,
    patientNeedingUrgentCareDisclaimerIsAccepted,
    codeCanOnlyBeBilledInACmsValidation,
    syntheticPlateSupplementValidation,
    durationGreaterThan4HoursContextElementValidation,
    chronicPainVisitTimeValidations,
    supplement15400CanOnlyBeBilledWithAPrincipalVisitCodeValidation
  );

export const validator = {
  validateAct
};

export default validateAct;
