import React, { useEffect } from 'react';
import FieldContainer from 'application/components/form-controls/field-container';
import TextInput from 'application/components/form-controls/text-input';
import ZipCodeInput from 'application/components/form-controls/zip-code-input';
import useTranslate from 'application/hooks/use-translate';
import useValidatedForm from 'application/hooks/use-validated-form';
import { SectionProps } from 'application/pages/lobby/lobby.signup';
import MonthSelectInput from 'application/components/form-controls/month-select-input';
import Divider from 'application/components/form-controls/divider';
import ProvinceSelectInput from 'application/components/form-controls/province-select-input';
import MaskedInput from 'application/components/form-controls/masked-input';
import DateInput from 'application/components/form-controls/date-input';

const AdditionalInformation = ({ userState, onUserChange, onValidate }: SectionProps) => {
  const translate = useTranslate('pages.lobby.signup.section-additional-information');
  const { registerField, isValid } = useValidatedForm();

  useEffect(() => onValidate(isValid), [isValid, onValidate]);

  return (
    <>
      <FieldContainer required label={translate('previous-agency.switch-date')} infoMarkdownSlug="agency-switch-date">
        <DateInput
          {...registerField('agencySwitchDate')}
          value={userState.agencySwitchDate}
          required
          type="date"
          onChange={(agencySwitchDate) => onUserChange({ ...userState, agencySwitchDate })}
        />
      </FieldContainer>
      <FieldContainer label={translate('address.label')} helper={translate('address.helper')} required>
        <TextInput
          {...registerField('address')}
          value={userState.address}
          onChange={(address) => onUserChange({ ...userState, address })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('city')} required>
        <TextInput
          {...registerField('city')}
          value={userState.city}
          onChange={(city) => onUserChange({ ...userState, city })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('zip-code')} required>
        <ZipCodeInput
          {...registerField('zipCode')}
          value={userState.zipCode}
          onChange={(zipCode) => onUserChange({ ...userState, zipCode })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('province')} required>
        <ProvinceSelectInput
          {...registerField('province')}
          value={userState.province}
          onChange={(province) => onUserChange({ ...userState, province })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('country')}>Canada</FieldContainer>

      <Divider subtitle={translate('billing-group.title')} />

      <FieldContainer label={translate('fiscal-year-start')} required>
        <MonthSelectInput
          {...registerField('fiscalYearStart')}
          value={userState.fiscalYearStart}
          onChange={(fiscalYearStart) => onUserChange({ ...userState, fiscalYearStart })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('billing-group.name')}>
        <TextInput
          {...registerField('groupName')}
          value={userState.groupName}
          onChange={(groupName) => onUserChange({ ...userState, groupName })}
        />
      </FieldContainer>
      <FieldContainer label={translate('billing-group.number')} helper={translate('billing-group.number-helper')}>
        <MaskedInput
          {...registerField('groupNumber')}
          value={userState.groupNumber}
          onChange={(groupNumber) => onUserChange({ ...userState, groupNumber })}
          placeholder="12345"
          mask="_____"
        />
      </FieldContainer>

      <Divider subtitle={translate('previous-agency.title')} />

      <FieldContainer label={translate('previous-agency.name')}>
        <TextInput
          {...registerField('previousAgencyName')}
          value={userState.previousAgencyName}
          onChange={(previousAgencyName) => onUserChange({ ...userState, previousAgencyName })}
        />
      </FieldContainer>
      <FieldContainer label={translate('previous-agency.number')} helper={translate('previous-agency.number-helper')}>
        <MaskedInput
          {...registerField('previousAgencyNumber')}
          value={userState.previousAgencyNumber}
          onChange={(previousAgencyNumber) => onUserChange({ ...userState, previousAgencyNumber })}
          placeholder="61234"
          mask="_____"
        />
      </FieldContainer>
    </>
  );
};

export default AdditionalInformation;
