import { firestore } from '../../../server/Firebase';
import {
  ACTIVITIES,
  CODES_PREFERENCES,
  DIAGNOSTIC_CODES_COLLECTION,
  DIAGNOSTIC_PREFERENCES,
  DOCTORS_PREFERENCES,
  RESIDENTS_PREFERENCES,
  LOGS,
  PLACES_PREFERENCES,
  RAMQ_CALL_LOGS,
  RAMQ_METADATA,
  SPECIALTIES_COLLECTION,
  STATEMENTS_COLLECTION,
  USERS,
  ACT_CODES_COLLECTION,
  ACTIVITIES_HISTORY,
  PLACES,
  CONTEXT_ELEMENT_PREFERENCES,
  CONTEXT_ELEMENTS_COLLECTION,
  OCR_LOGS_COLLECTION,
  MEDYX_GLOBAL_INFO_COLLECTION,
  GLOBAL_INFOS,
  LUMP_SUMS_PREFERENCES
} from '../../../shared/collection/collectionConstants';

export const userDocReference = (userId) => firestore.collection(USERS).doc(userId);

export const usersCollectionRef = () => firestore.collection(USERS);
export const activitiesCollectionRef = () => firestore.collection(ACTIVITIES);
export const activitiesHistoryCollectionRef = () => firestore.collection(ACTIVITIES_HISTORY);
export const statementsCollectionRef = () => firestore.collection(STATEMENTS_COLLECTION);
export const ramqCallLogsCollectionRef = () => firestore.collection(RAMQ_CALL_LOGS);
export const placesCollectionRef = () => firestore.collection(PLACES);
export const placesPreferencesCollectionRef = (userId) => userDocReference(userId).collection(PLACES_PREFERENCES);
export const doctorsPreferencesCollectionRef = (userId) => userDocReference(userId).collection(DOCTORS_PREFERENCES);
export const residentsPreferencesCollectionRef = (userId) => userDocReference(userId).collection(RESIDENTS_PREFERENCES);
export const codesPreferencesCollectionRef = (userId) => userDocReference(userId).collection(CODES_PREFERENCES);
export const diagnosticCodesPreferencesCollectionRef = (userId) =>
  userDocReference(userId).collection(DIAGNOSTIC_PREFERENCES);
export const contextElementsPreferencesCollectionRef = (userId) =>
  userDocReference(userId).collection(CONTEXT_ELEMENT_PREFERENCES);
export const lumpSumPreferencesCollectionRef = (userId) => userDocReference(userId).collection(LUMP_SUMS_PREFERENCES);
export const userLogsCollectionRef = () => firestore.collection(LOGS);
export const ramqMetaDataCollectionRef = () => firestore.collection(RAMQ_METADATA);
export const medyxGlobalInfosCollectionRef = () => firestore.collection(MEDYX_GLOBAL_INFO_COLLECTION);
export const globalInfosRef = () => medyxGlobalInfosCollectionRef().doc(GLOBAL_INFOS);

export const actCodesSpecialtiesCollectionRef = (specialty) =>
  firestore.collection(SPECIALTIES_COLLECTION).doc(specialty).collection(ACT_CODES_COLLECTION);

export const diagnosticCodesSpecialtiesCollectionRef = (specialty) =>
  firestore.collection(SPECIALTIES_COLLECTION).doc(specialty).collection(DIAGNOSTIC_CODES_COLLECTION);

export const specialtyContextElementsCollectionRef = (specialty) =>
  firestore.collection(SPECIALTIES_COLLECTION).doc(specialty).collection(CONTEXT_ELEMENTS_COLLECTION);

export const ocrLogsCollectionRef = () => firestore.collection(OCR_LOGS_COLLECTION);
