/* eslint-disable max-classes-per-file */
import { isEmpty, pickBy } from 'lodash';
import { Boundaries } from './types';

class Range {
  public static Builder = class {
    constructor(
      // eslint-disable-next-line no-unused-vars
      private boundaries: Boundaries = { lowerBound: undefined, upperBound: undefined }
    ) {}

    public lessThan(lowerBound: number): this {
      this.boundaries.lowerBound = (value: number) => value < lowerBound;

      return this;
    }

    public lessThanOrEqual(lowerBound: number): this {
      this.boundaries.lowerBound = (value: number) => value <= lowerBound;

      return this;
    }

    public greaterThan(upperBound: number): this {
      this.boundaries.upperBound = (value: number) => value > upperBound;

      return this;
    }

    public greaterThanOrEqual(upperBound): this {
      this.boundaries.upperBound = (value: number) => value >= upperBound;

      return this;
    }

    public build(): Range {
      const boundaries = pickBy(this.boundaries);

      if (isEmpty(boundaries)) {
        throw new Error('No boundaries has been specified');
      }

      return new Range(boundaries);
    }
  };

  private constructor(
    // eslint-disable-next-line no-unused-vars
    private boundaries: Boundaries
  ) {}

  public includes(value: number): boolean {
    return Object.values(this.boundaries).every((boundary) => boundary(value));
  }
}

export default Range;
