import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import moment from 'moment';
import { LETTRE_ENTENTE_241_EFFECTIVE_DATE } from 'shared/ramq/contextElements/lettreEntente241ContextElements';
import UserUtils from 'utils/userUtils';
import { selectLoggedUser } from '../../../containers/Authentication/selectors';
import MultiSelectCheckboxGroup from '../../Form/Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';
import { getMainContextElements } from './constants';
import { selectSelectedDate } from '../../../containers/ActForm/selectors';
import {
  atLeastOneAdditionalContextElementIsSelected,
  atLeastOneCovidContextElementIsSelected,
  getContextElementsAccordingToUserSecurity,
  getCovidContextElements,
  userCanEnterAdditionalContextElements
} from '../shared/domain';
import ContextElementShortcutPanel from '../ContextElementShortcutPanel/ContextElementShortcutPanel';
import AuthUserDomain from '../../../../shared/domain/authentication/AuthUser';
import getContextElementsTranslation from '../shared/translation';

export const styles = (theme) =>
  ({
    container: {
      position: 'relative',
      paddingRight: theme.spacing(6),
      marginBottom: theme.spacing(2)
    },
    containerBtnCovid: {
      position: 'absolute',
      top: '0px',
      right: '48px'
    },
    removeRightSpace: {
      right: '0px'
    }
  }) as const;

export interface OwnProps extends WrappedFieldProps {}

export interface ReduxStateProps {
  user: AuthUserDomain;
  selectedDate: number;
}

export interface Props extends OwnProps, WithStyles<typeof styles>, ReduxStateProps {}

export const LegacyContextElementsSelector: React.FunctionComponent<Props> = ({
  input,
  user,
  classes,
  meta,
  selectedDate
}: Props) => {
  const COVID_PANEL_TRIGGER_CLASSNAME = `${classes.containerBtnCovid} ${
    userCanEnterAdditionalContextElements(user) ? '' : classes.removeRightSpace
  }`;

  return (
    <div className={classes.container}>
      <MultiSelectCheckboxGroup
        id="context-elements"
        input={input}
        list={getContextElementsTranslation(getMainContextElements())}
        labelProp="label"
        valueProp="key"
      />
      {userCanEnterAdditionalContextElements(user) && (
        <ContextElementShortcutPanel
          data-testid="more-context-elements-shortcut-panel"
          label="Plus"
          input={input}
          list={getContextElementsTranslation(getContextElementsAccordingToUserSecurity(user))}
          selected={atLeastOneAdditionalContextElementIsSelected(input)}
        />
      )}
      {(moment(selectedDate).isBefore(LETTRE_ENTENTE_241_EFFECTIVE_DATE) || UserUtils.isAdminOrAgent()) && (
        <ContextElementShortcutPanel
          data-testid="covid-context-elements-shortcut-panel"
          label="COVID"
          panelTriggerClassName={COVID_PANEL_TRIGGER_CLASSNAME}
          input={input}
          list={getContextElementsTranslation(getCovidContextElements(selectedDate))}
          selected={atLeastOneCovidContextElementIsSelected(input)}
        />
      )}
      {get(meta, 'error', false) && <FormHelperText error>{get(meta, 'error')}</FormHelperText>}
    </div>
  );
};

export const mapStateToProps = createStructuredSelector({
  user: selectLoggedUser(),
  selectedDate: selectSelectedDate()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(LegacyContextElementsSelector);
