import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';
import Icon from 'application/components/icon';
import StripeService from 'application/services/stripe-service';
import React, { useMemo } from 'react';

interface Props {
  email: string;
  priceId: string;
}

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY!;

const SettingsSubscriptionSignupPayment = ({ email, priceId }: Props) => {
  const { data: checkoutOptions, isFetching } = useQuery({
    queryKey: ['stripe-subscription-session'],
    queryFn: () => StripeService.createSubscriptionSession(email, priceId)
  });

  const stripePromise = useMemo(() => loadStripe(STRIPE_PUBLIC_KEY), []);

  if (isFetching || !stripePromise || !checkoutOptions) {
    return (
      <div className="w-full py-12 text-center text-4xl">
        <Icon loading />
      </div>
    );
  }

  return (
    <div className="w-full">
      {checkoutOptions && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={checkoutOptions}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default SettingsSubscriptionSignupPayment;
