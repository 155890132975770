/* eslint-disable no-async-promise-executor */
export const getRootDirectory = () =>
  new Promise((resolve, reject) => {
    if (window.cordova) {
      window.resolveLocalFileSystemURL(window.cordova.file.dataDirectory, resolve, reject);
    }
  });

// exclusive=false option means that it should not fail if you try to create a directory that already exists.
// it will simply return the already created one
export const getSubDirectory = (parentDirectory, subDirectoryName) =>
  new Promise((resolve, reject) => {
    parentDirectory.getDirectory(subDirectoryName, { create: true, exclusive: false }, resolve, reject);
  });

export const getAllFilesInDirectory = (directoryEntry) =>
  new Promise((resolve, reject) => {
    const directoryReader = directoryEntry.createReader();
    directoryReader.readEntries(resolve, reject);
  });

export const getFileEntry = (directoryEntry, fileName, createIfNotExists) =>
  new Promise((resolve, reject) => {
    directoryEntry.getFile(fileName, { create: createIfNotExists, exclusive: false }, resolve, reject);
  });

const createFileWriter = (fileEntry) =>
  new Promise((resolve, reject) => {
    fileEntry.createWriter(resolve, reject);
  });

export const writeFile = (fileEntry, data) =>
  new Promise(async (resolve, reject) => {
    try {
      const fileWriter = await createFileWriter(fileEntry);
      fileWriter.onwriteend = resolve;
      fileWriter.onerror = reject;
      fileWriter.write(data);
    } catch (e) {
      reject(e);
    }
  });

export const removeFile = (fileEntry) =>
  new Promise((resolve, reject) => {
    fileEntry.remove(resolve, reject);
  });

// This is so dumb... For anyone who's crossing this path, take note that the "File" object
// returned by cordova file plugin is NOT a real File object which implements a Blob
// https://developer.mozilla.org/en-US/docs/Web/API/File. It's only a dumb custom cordova File object with
// some properties that look exactly like the real File javascript object. Because of that, we don't have the choice to read the
// file content as dataURL or BytesArray to upload an image to firebase. We can't just pass the File reference like we would do
// in a real browser. However... also note that the "writeFile" cordova method CAN take a "cordova File" object to persist it.
// It doesn't have to be bytes or dataURL ... or REAL blob, and cordova is somehow able to persist it on the file system.
export const getFileObject = (fileEntry) =>
  new Promise((resolve, reject) => {
    fileEntry.file(resolve, reject);
  });

export const READ_AS = {
  ARRAY_BUFFER: 0,
  DATA_URL: 1,
  TEXT: 2
};

export const readFileEntry = async (fileEntry, readType) =>
  new Promise(async (resolve, reject) => {
    let fileObject = fileEntry;
    if (fileEntry.file) {
      fileObject = await getFileObject(fileEntry);
    }

    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    switch (readType) {
      case READ_AS.ARRAY_BUFFER:
        reader.readAsArrayBuffer(fileObject);
        break;
      case READ_AS.DATA_URL:
        reader.readAsDataURL(fileObject);
        break;
      case READ_AS.TEXT:
        reader.readAsText(fileObject);
        break;
      default:
        reader.readAsArrayBuffer(fileObject);
    }
  });

export const getFileEntryFromFileURI = (fileURI) =>
  new Promise((resolve, reject) => {
    window.resolveLocalFileSystemURL(fileURI, resolve, reject);
  });
