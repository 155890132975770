import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import { SingleLineField } from '../../../../../components/Form/Fields';
import DetainedAdditionalSteps from './DetainedAdditionalSteps';

const DetainedForm: React.FunctionComponent = () => (
  <CardContent>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        Identification du patient
      </Typography>
      <Divider />
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.lastName" required label="Nom du patient" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.firstName"
          required
          label="Prénom du patient"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.identificationNumber" label="Numéro" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.detentionCenterAddress"
          label="Adresse du centre de détention"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12}>
        <DetainedAdditionalSteps />
      </Grid>
    </Grid>
  </CardContent>
);

export default DetainedForm;
