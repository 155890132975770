import AlertSeverity from './AlertSeverity';

type SeverityColors = {
  [key in AlertSeverity]: string;
};

const SEVERITY_COLORS: SeverityColors = {
  default: 'default',
  error: '#f44336',
  info: '#2196f3',
  success: '#4caf50',
  none: '#000000',
  warning: '#ff9800',
  recommendation: 'inherit'
};

export default SEVERITY_COLORS;
