import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import ActActivity from '../../containers/ActivitiesRoute/DayRoute/DayActivities/Act/ActActivity';
import ActSummaryActivity from '../../containers/UsersRoute/UserRoute/UserActivities/ActSummaryActivity/ActSummaryActivity';
import LumpSumActivity from './LumpSum/LumpSumActivity';
import LumpSumSummaryActivity from './LumpSum/LumpSumSummaryActivity';
import MixteActivity from './Mixte/MixteActivity/MixteActivity';
import MixteSummaryActivity from './Mixte/MixteSummaryActivity/MixteSummaryActivity';
import TravelExpensesActivity from './TravelExpenses/TravelExpensesActivity';
import TravelExpensesSummaryActivity from './TravelExpenses/TravelExpensesSummaryActivity';
import TrainingActivity from './Training/TrainingActivity/TrainingActivity';
import TrainingSummaryActivity from './Training/TrainingSummaryActivity/TrainingSummaryActivity';

export const summaryActivitiesMapping = {
  [ActivityType.ACT]: ActSummaryActivity,
  [ActivityType.MIXTE]: MixteSummaryActivity,
  [ActivityType.LUMP_SUM]: LumpSumSummaryActivity,
  [ActivityType.TRAVEL_EXPENSES]: TravelExpensesSummaryActivity,
  [ActivityType.TRAINING]: TrainingSummaryActivity
};

export const dayActivitiesMapping = {
  [ActivityType.ACT]: ActActivity,
  [ActivityType.MIXTE]: MixteActivity,
  [ActivityType.LUMP_SUM]: LumpSumActivity,
  [ActivityType.TRAVEL_EXPENSES]: TravelExpensesActivity,
  [ActivityType.TRAINING]: TrainingActivity
};
