export const SNT_NON_AMBULATOIRE_COVID = 'sntNonAmbulatoireCovid';
export const SNT_AMBULATOIRE_COVID = 'sntAmbulatoireCovid';
export const MD_REAFFECTE_COVID_ETABLISS = 'mdReaffecteCovidEtabliss';
export const ENCEINTE_COVID = 'enceinteCovid';
export const TELEMEDECINE_CABINET_COVID = 'telemedecineCabinetCovid';
export const TELEMEDECINE_AILLEURS_COVID = 'telemedecineAilleursCovid';
export const TELEMEDECINE_PAR_TELEPHONE = 'telemedecineParTelephone';
export const TELEMEDECINE_PAR_VISIOCONSULTATION = 'telemedecineParVisioconsultation';
export const MD_INFECTE_COVID = 'mdInfecteCovid';
export const ISOLEMENT_COVID = 'isolementCovid';
export const MD_REAFFECTE_AILLEURS_COVID = 'mdReaffecteAilleursCovid';
export const SERVICE_PATIENT_COVID = 'servicePatientCovid';
