export const UNKNOWN_SPECIALTY_KEY = 'UNKNOWN';

const DoctorSpecialties = {
  EMERGENCY_MEDICINE: 'Emergency Medicine',
  ORAL_AND_MAXILLOFACIAL_ORAL_MEDICINE: 'Oral and Maxillofacial/Oral Medicine',
  FAMILY_MEDICINE: 'Family Medicine',
  INTERNAL_MEDICINE: 'Internal Medicine',
  GENERAL_INTERNAL_MEDICINE: 'General Internal Medicine',
  PEDIATRICS: 'Pediatrics',
  PSYCHIATRY: 'Psychiatry',
  RESPIROLOGY: 'Respirology',
  GERIATRIC_MEDICINE: 'Geriatric Medicine',
  ENDOCRINOLOGY_AND_METABOLISM: 'Endocrinology and Metabolism',
  CLINICAL_IMMUNOLOGY_AND_ALLERGY: 'Clinical Immunology and Allergy',
  OBSTETRICS_AND_GYNECOLOGY: 'Obstetrics and Gynecology',
  ANESTHESIOLOGY: 'Anesthesiology',
  ORTHOPEDIC_SURGERY: 'Orthopedic Surgery',
  CARDIOLOGY: 'Cardiology',
  DIAGNOSTIC_RADIOLOGY: 'Diagnostic Radiology',
  GENERAL_SURGERY: 'General Surgery',
  THORACIC_SURGERY: 'Thoracic Surgery',
  VASCULAR_SURGERY: 'Vascular Surgery',
  OPHTHALMOLOGY: 'Ophthalmology',
  UROLOGY: 'Urology',
  GENERAL_SURGICAL_ONCOLOGY: 'General Surgical Oncology',
  ANATOMICAL_PATHOLOGY: 'Anatomical Pathology',
  GYNECOLOGIC_REPRODUCTIVE_ENDOCRINOLOGY_AND_INFERTI: 'Gynecologic Reproductive Endocrinology and Inferti',
  MEDICAL_MICROBIOLOGY_AND_INFECTIOUS_DISEASES: 'Medical Microbiology and Infectious Diseases',
  NEUROLOGY: 'Neurology',
  NEPHROLOGY: 'Nephrology',
  DERMATOLOGY: 'Dermatology',
  RHEUMATOLOGY: 'Rheumatology',
  CRITICAL_CARE_MEDICINE: 'Critical Care Medicine',
  RADIATION_ONCOLOGY: 'Radiation Oncology',
  CHILD_AND_ADOLESCENT_PSYCHIATRY: 'Child and Adolescent Psychiatry',
  FORENSIC_PSYCHIATRY: 'Forensic Psychiatry',
  NUCLEAR_MEDICINE: 'Nuclear Medicine',
  NEUROSURGERY: 'Neurosurgery',
  PHYSICAL_MEDICINE_AND_REHABILITATION: 'Physical Medicine and Rehabilitation',
  CARDIAC_SURGERY: 'Cardiac Surgery',
  PUBLIC_HEALTH_AND_PREVENTIVE_MEDICINE: 'Public Health and Preventive medicine',
  GASTROENTEROLOGY: 'Gastroenterology',
  HEMATOLOGY: 'Hematology',
  MEDICAL_ONCOLOGY: 'Medical Oncology',
  OTOLARYNGOLOGY_HEAD_AND_NECK_SURGERY: 'Otolaryngology-Head and Neck Surgery',
  INFECTIOUS_DISEASES: 'Infectious Diseases',
  GERIATRIC_PSYCHIATRY: 'Geriatric Psychiatry',
  NEUROPATHOLOGY: 'Neuropathology',
  NEONATAL_PERINATAL_MEDICINE: 'Neonatal-Perinatal Medicine',
  COLORECTAL_SURGERY: 'Colorectal Surgery',
  PEDIATRIC_EMERGENCY_MEDICINE: 'Pediatric Emergency Medicine',
  MEDICAL_BIOCHEMISTRY: 'Medical Biochemistry',
  MATERNAL_FETAL_MEDICINE: 'Maternal-Fetal Medicine',
  PLASTIC_SURGERY: 'Plastic Surgery',
  GYNECOLOGIC_ONCOLOGY: 'Gynecologic Oncology',
  ADOLESCENT_MEDICINE: 'Adolescent Medicine',
  OCCUPATIONAL_MEDICINE: 'Occupational Medicine',
  PEDIATRIC_SURGERY: 'Pediatric Surgery',
  MEDICAL_GENETICS: 'Medical Genetics',
  HEMATOLOGICAL_PATHOLOGY: 'Hematological Pathology',
  PEDIATRIC_HEMATOLOGY_ONCOLOGY: 'Pediatric Hematology / Oncology',
  FORENSIC_PATHOLOGY: 'Forensic Pathology',
  DEVELOPMENTAL_PEDIATRICS: 'Developmental Pediatrics',
  GENERAL_PATHOLOGY: 'General Pathology',
  OBSTETRICS: 'Obstetrics',
  GYNECOLOGY: 'Gynecology',
  PUBLIC_HEALTH: 'Public Health',
  ELECTROENCEPHALOGRAPHY: 'Electroencephalography',
  CARDIO_VASCULAR_AND_THORACIC_SURGERY: 'Cardio-Vascular and Thoracic Surgery'
};

export default DoctorSpecialties;
