import { TRAINING_HALF_DAY_AMOUNT } from './constants';
import { AM_PERDIEM, EVENING_PERDIEM, PM_PERDIEM } from '../../../../shared/periods/common/constants';

export default function calculateTrainingDayAmount(perdiems) {
  if (!isPerdiemsSelectionValid(perdiems)) {
    throw new Error("Can't perform daily training on three perdiems");
  }

  let totalDailyAmount = 0;

  if (perdiems.includes(AM_PERDIEM)) totalDailyAmount += TRAINING_HALF_DAY_AMOUNT;
  if (perdiems.includes(PM_PERDIEM)) totalDailyAmount += TRAINING_HALF_DAY_AMOUNT;
  if (perdiems.includes(EVENING_PERDIEM)) totalDailyAmount += TRAINING_HALF_DAY_AMOUNT;

  return totalDailyAmount;
}

export function isPerdiemsSelectionValid(perdiems) {
  return !(perdiems.includes(AM_PERDIEM) && perdiems.includes(PM_PERDIEM) && perdiems.includes(EVENING_PERDIEM));
}
