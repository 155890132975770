import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { VisitCodeOption } from 'shared/act/services/VisitCodes';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
});

interface Props {
  options: VisitCodeOption[];
  value: string;
  open: boolean;
  onItemSelected: (item: VisitCodeOption | null) => void;
  onClose: () => void;
}

export function VisitCodeDialog({ options, value, open, onItemSelected, onClose }: Props) {
  const classes = useStyles();

  const clearValue = () => {
    onItemSelected(null);
    onClose();
  };

  const handleItemSelected = (item: VisitCodeOption) => {
    onItemSelected(item);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.container}>
        {options.map((item) => (
          <Button key={item.value} onClick={() => handleItemSelected(item)}>
            {item.label}
            {item.shortLabel && item.label !== item.shortLabel && ` - ${item.shortLabel}`}
          </Button>
        ))}
      </DialogContent>
      <DialogActions>
        {value && (
          <Button onClick={clearValue} color="primary">
            Effacer
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
