import { createSelector } from '@reduxjs/toolkit';
import { selectPreferences, getUser } from '../../containers/User/selectors';

const selectDoctors = (state) => state.doctors;

const selectDoctorsLoaded = (state) => state.doctorsLoaded;

const selectResidents = (state) => state.residents;

const selectResidentsLoaded = (state) => state.residentsLoaded;

const selectDoctorsPreferenceFromPreferences = createSelector(selectPreferences, selectDoctors);

const selectResidentsPreferenceFromPreferences = createSelector(selectPreferences, selectResidents);

const selectDoctorsLoadedFromPreference = createSelector(selectPreferences, selectDoctorsLoaded);

export const selectDoctorsPreferencesFromUser = () => createSelector(getUser, selectDoctorsPreferenceFromPreferences);

export const selectResidentsPreferencesFromUser = () =>
  createSelector(getUser, selectResidentsPreferenceFromPreferences);

export const selectDoctorsLoadedFromUser = () => createSelector(getUser, selectDoctorsLoadedFromPreference);
