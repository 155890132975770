import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  icon: {
    fontSize: 20,
    marginLeft: theme.spacing(1)
  }
});

function AddFeeButton({ reduxFormFields, expensesType, label, classes }) {
  return (
    <Button size="small" variant="contained" onClick={() => reduxFormFields.push({ type: expensesType })}>
      {label}
      <AddIcon className={classes.icon} />
    </Button>
  );
}

AddFeeButton.propTypes = {
  expensesType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  reduxFormFields: PropTypes.object.isRequired
};

export default withStyles(styles)(AddFeeButton);
