export const FETCH_INTERCOM_USER_REQUESTED = 'fetchIntercomUserHash';
export const fetchIntercomUserHash = () => ({
  type: FETCH_INTERCOM_USER_REQUESTED
});

export const SET_INTERCOM_UNREAD_MESSAGE_COUNT = 'setIntercomUnreadMessageCount';
export const setIntercomUnreadMessageCount = (count) => ({
  type: SET_INTERCOM_UNREAD_MESSAGE_COUNT,
  count
});

export const FETCH_INTERCOM_USER_HASH_SUCCEEDED = 'fetchIntercomUserHashSucceeded';
export const fetchIntercomUserHashSucceeded = (hash) => ({
  type: FETCH_INTERCOM_USER_HASH_SUCCEEDED,
  hash
});

export const FETCH_INTERCOM_USER_HASH_FAILED = 'fetchIntercomUserHashFailed';
export const fetchIntercomUserHashFailed = (e) => ({
  type: FETCH_INTERCOM_USER_HASH_FAILED,
  message: e.message
});
