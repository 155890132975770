/* eslint-disable max-len */
import { logDefault } from '../../../../shared/utils/plog';
import {
  getAllFilesInDirectory,
  getFileEntry,
  READ_AS,
  readFileEntry,
  removeFile,
  writeFile
} from './cordovaLocalFile';

class MobileFileRepository {
  constructor(getDirectoryFunction) {
    this.getDirectory = getDirectoryFunction;
  }

  async listAllFiles() {
    if (!window.cordova) {
      return [];
    }

    const directoryEntry = await this.getDirectory();
    return getAllFilesInDirectory(directoryEntry);
  }

  async writeFile(fileName, data) {
    if (!window.cordova) {
      return '';
    }

    const directoryEntry = await this.getDirectory();
    const fileEntry = await getFileEntry(directoryEntry, fileName, true);
    await writeFile(fileEntry, data);
    return fileEntry.toURL();
  }

  async removeFile(fileName) {
    if (!window.cordova) {
      return;
    }

    logDefault({
      type: 'mobileFileRepository',
      text: 'mobileFileRepository/removeFile',
      array: ['Removing file with name: ', fileName]
    });
    const directoryEntry = await this.getDirectory();
    const fileEntry = await getFileEntry(directoryEntry, fileName, false);
    await removeFile(fileEntry);
  }

  // We cannot just pass the path we want to reach. The reason is that when the app is natively updated, the UID will change.
  // For example, I saved the image with this url :
  // file:///var/mobile/Containers/Data/Application/9E2BA223-E1FA-4E48-A03C-B34D96E5124C/Library/NoCloud/medyxAssets/medyxCardPictures/MyFdHA0K6SiFeGz8Qzkp.jpeg
  // After updating the app, the file was located in :
  // file:///var/mobile/Containers/Data/Application/EFFF0365-845A-4AD0-A102-AD593E43C9B6/Library/NoCloud/medyxAssets/medyxCardPictures/MyFdHA0K6SiFeGz8Qzkp.jpeg
  // if we simply do a "window.resolveLocalFileSystemURL(filePath)", it will never find the file after an app update
  // We absolutely need to pass down directory by directory to find our files
  async getFileContentAsDataUrl(fileName) {
    if (!window.cordova) {
      return undefined;
    }

    const directoryEntry = await this.getDirectory();
    const fileEntry = await getFileEntry(directoryEntry, fileName, false);
    return readFileEntry(fileEntry, READ_AS.DATA_URL);
  }
}

export default MobileFileRepository;
