import { merge, set } from 'lodash';
import activityAreas from '../../../../shared/ramq/domainValues/activityAreas';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import exceptionalCircumstances from './exceptionalCircumstances';
import EXCEPTIONAL_CIRCUMSTANCES from '../../../../shared/ramq/requiredDetails/exceptionalCircumstances';
import isDefined from '../../../../shared/utils/isDefined';

export const CODES_WHERE_DETAIL_IS_REQUIRED = ['34', '36', '39', '9150', '9152'];

const MANDATORY_FOR_INTENSIVE_CARE = 'Ce champ est obligatoire en S.I.';
const mandatoryForIntensiveCareError = (index) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${EXCEPTIONAL_CIRCUMSTANCES}` },
    `codes[${index}].${EXCEPTIONAL_CIRCUMSTANCES}`,
    MANDATORY_FOR_INTENSIVE_CARE
  );

const valueRequiredForIntensiveCareOnly = (act) => {
  const codesInError = act.codes.map((code, index) => {
    if (CODES_WHERE_DETAIL_IS_REQUIRED.some((requiredCode) => requiredCode === code.code)) {
      if (act.activityArea === activityAreas.intensiveCareUnit && !isDefined(code[EXCEPTIONAL_CIRCUMSTANCES])) {
        return mandatoryForIntensiveCareError(index);
      }
    }

    return {};
  });

  return codesInError.reduce(combineErrors, {});
};

const intensiveCareExceptionalCircumstances = merge(
  {
    associatedFields: [
      {
        notRequired: true
      }
    ],
    associatedValidations: [valueRequiredForIntensiveCareOnly]
  },
  exceptionalCircumstances
);
export default intensiveCareExceptionalCircumstances;
