import { concat, orderBy, remove } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { ACTIVITIES_LUMP_SUM_TYPE, ACTIVITIES_MIXTE_TYPE } from '../../../shared/collection/collectionConstants';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import { getUnixEndOfDay, getUnixStartOfDay } from '../../../shared/utils/dateTime/dateTimeUtils';
import isDefined from '../../../shared/utils/isDefined';
import PeriodTransformerFactory from '../PeriodsBilling/PeriodForm/transformer/PeriodTransformerFactory';

const selectActivities = (state) => state.activities;

export const selectActivitiesInContext = () => createSelector(selectActivities, (state) => state.activities);

const areMixteCodesEmpty = (codes) => codes.every(({ code }) => !isDefined(code));
const isEmptyForMixte = (day, activityType) =>
  activityType === ActivityType.MIXTE &&
  !isDefined(day.code) &&
  (!isDefined(day.codes) || (isDefined(day.codes) && areMixteCodesEmpty(day.codes))) &&
  (!isDefined(day.perdiems) || (isDefined(day.perdiems) && day.perdiems.length === 0));

const isSameDateAndIsNotEmpty = (day, startOfDay, endOfDay, activityType) =>
  day.date >= startOfDay && day.date <= endOfDay && !isEmptyForMixte(day, activityType);

const isPeriodActivity = (activity) =>
  activity.type === ACTIVITIES_MIXTE_TYPE || activity.type === ACTIVITIES_LUMP_SUM_TYPE;

export const selectActivitiesByDayInContext = (date) =>
  createSelector(selectActivities, (state) => {
    const activities = orderBy(state.activities, ['start', 'nam'], ['asc', 'asc']);
    const periodActivities = remove(activities, isPeriodActivity);
    const unixDate = parseInt(date, 10);
    const startOfDay = getUnixStartOfDay(unixDate);
    const endOfDay = getUnixEndOfDay(unixDate);
    const singleDayPeriods = [];
    periodActivities
      .map((activity) => PeriodTransformerFactory.create(activity.type).toPeriodFormDto(activity))
      .forEach((activity) => {
        const daysInSameDate = activity.days;
        if (daysInSameDate) {
          daysInSameDate
            .filter((day) => isSameDateAndIsNotEmpty(day, startOfDay, endOfDay, activity.type))
            .forEach((day, index) =>
              singleDayPeriods.push({
                billingType: activity.billingType,
                poolNumber: activity.poolNumber,
                code: day.code,
                codes: day.codes,
                date: day.date,
                type: activity.type,
                perdiems: day.perdiems,
                activityArea: day.activityArea,
                manualCorrection: day.manualCorrection,
                manualEditionMode: day.manualEditionMode,
                start: day.start,
                end: day.end,
                note: activity.note,
                place: activity.place,
                status: activity.status,
                id: `${activity.id}`,
                key: `${activity.id}-${index}`
              })
            );
        }
      });
    const activitiesOnThisDay = activities.filter(
      (activity) => activity.date >= startOfDay && activity.date <= endOfDay
    );
    return concat(activitiesOnThisDay, singleDayPeriods);
  });

export const selectActivitiesLoading = () => createSelector(selectActivities, (state) => state.loading);

export const selectIsLoadingMoreActivities = () => createSelector(selectActivities, (state) => !!state.loadingMore);

export const selectHasMoreActivities = () => createSelector(selectActivities, (state) => !!state.hasMore);

export const selectFilters = () => createSelector(selectActivities, (state) => state.filters);

export const selectDetailedView = () => createSelector(selectActivities, (state) => state.detailedView);

export const selectSearchNbHits = () => createSelector(selectActivities, (state) => state.nbHits);

export const selectCurrentResultPage = () => createSelector(selectActivities, (state) => state.currentPage);

export const selectDuplicationData = (activityType) =>
  createSelector(selectActivities, (state) => state.duplicationData[activityType] ?? null);
