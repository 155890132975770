import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isDefined from '../../../../../shared/utils/isDefined';
import { updateStartTime } from '../../actions';
import { selectCurrentCodes, selectSelectedDate, selectStartTime } from '../../selectors';
import { getStartTimeForCode, getStartTimeMappingCodes } from './utils';

export class PrefillStartTimeAccordingToCode extends React.Component {
  constructor(props) {
    super(props);

    const { codes, date, startTime } = props;

    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(startTime)) {
      return;
    }

    const code = this.retrieveFirstAllowedCodeInCodesList(codes);

    if (!isDefined(code)) {
      return;
    }

    props.updateStartTime(getStartTimeForCode(code.code, date));
  }

  retrieveFirstAllowedCodeInCodesList = (codes) => codes.find(({ code }) => getStartTimeMappingCodes().includes(code));

  render() {
    return null;
  }
}

PrefillStartTimeAccordingToCode.defaultProps = {
  codes: [],
  date: undefined,
  startTime: ''
};

PrefillStartTimeAccordingToCode.propTypes = {
  codes: PropTypes.array,
  date: PropTypes.number,
  startTime: PropTypes.node,
  updateStartTime: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  codes: selectCurrentCodes(),
  date: selectSelectedDate(),
  startTime: selectStartTime()
});

export const mapDispatchToProps = (dispatch) => ({
  updateStartTime: (time) => {
    dispatch(updateStartTime(time));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrefillStartTimeAccordingToCode);
