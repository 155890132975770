const MEASUREMENT_ELEMENTS = 'measurementElements';

export const measurementElementsUnits = {
  CM: 'Centimètre',
  CM2: 'Centimètre carré',
  DH: 'Demi-heure',
  H: 'Heure',
  MIN: 'Minute',
  QH: "Quart d'heure",
  UN: 'Unité'
};

export default MEASUREMENT_ELEMENTS;
