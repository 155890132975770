import flatMap from 'lodash/flatMap';

export const doesContainDuplicatedValues = (list) => new Set(list).size !== list.length;

export const getFlattenItemsFromCategory = (collection) => {
  // eslint-disable-next-line no-prototype-builtins
  if (collection.hasOwnProperty('subCategories')) {
    return flatMap(collection.subCategories, getFlattenItemsFromCategory);
  }

  return collection.items;
};
