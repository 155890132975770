import React from 'react';

interface Props {
  subtitle: string;
}

const Divider = ({ subtitle }: Props) => (
  <div className="w-full border-b border-neutral-200 bg-neutral-50 p-4 text-sm font-bold text-neutral-900">
    {subtitle}
  </div>
);

export default Divider;
