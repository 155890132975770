/* eslint-disable react/prop-types */
import React from 'react';
import DisplayIf from '../../../../shared/global/DisplayIf/DisplayIf';
import DoctorFavoritesAdvancedEditor from '../../containers/DoctorFavoritesAdvancedEditor/DoctorFavoritesAdvancedEditor';
import DoctorFavoritesSelector from '../../containers/DoctorFavoritesSelector/DoctorFavoritesSelector';

export class DoctorFavoritesTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      advancedEditingMode: false
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  toggleEditMode(advancedEditingMode) {
    this.setState({ advancedEditingMode });
  }

  render() {
    const { advancedEditingMode } = this.state;
    const { onAdvancedSave } = this.props;

    const selector = (
      <DoctorFavoritesSelector
        {...this.props}
        favoritesDoctors={this.props.doctorsPreferences}
        onAdvancedMode={() => this.toggleEditMode(true)}
      />
    );

    const advancedEditor = (
      <DoctorFavoritesAdvancedEditor
        favoritesDoctors={this.props.doctorsPreferences}
        doctorsSort={['lastName:asc', 'firstName:asc']}
        onClose={() => this.toggleEditMode(false)}
        onSaveAction={onAdvancedSave}
      />
    );

    return (
      <DisplayIf condition={advancedEditingMode} DisplayElse={selector}>
        {advancedEditor}
      </DisplayIf>
    );
  }
}

export default DoctorFavoritesTab;
