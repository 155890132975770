import { MixteDay } from '../../../../../../../shared/domain/activity/mixte/model/Mixte';
import MixteDayTransformer from './MixteDayTransformer';

class LegacyMixteDayTransformer implements MixteDayTransformer<MixteDay, MixteDay> {
  toDaysPeriodFormDto(mixteDays: MixteDay[]): MixteDay[] {
    return mixteDays;
  }

  toDomainDays(mixteDays: MixteDay[]): MixteDay[] {
    return mixteDays;
  }
}

export default LegacyMixteDayTransformer;
