import Icon from 'application/components/icon';
import useTranslate from 'application/hooks/use-translate';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import React, { useEffect, useRef, useState } from 'react';

interface Props extends ValidatedFieldProps {
  type?: 'date' | 'datetime-local' | 'time' | 'month' | 'week';
  value: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const DateInput = ({
  type = 'date',
  value = '',
  min,
  max,
  disabled = false,
  required = false,
  touched = false,
  customValidation,
  onChange,
  onBlur,
  onFocus,
  onValidate,
  onTouch
}: Props) => {
  const translate = useTranslate('components.form-controls.date-input');
  const dateRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const isValid = customValidation !== undefined ? customValidation.isValid : !required || (required && !!value);

  useEffect(() => {
    if (!onValidate) return;
    onValidate(isValid);
  }, [isValid, onValidate]);

  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (onTouch) onTouch();
    onChange(value);
  };

  const handleOnBlur = () => {
    if (onTouch) onTouch();
    if (onBlur) onBlur();

    setIsFocused(false);
  };

  const handleOnFocus = () => {
    if (onFocus) onFocus();

    setIsFocused(true);
  };

  const errorIsVisible = !isFocused && !isValid && touched;

  const showPicker = () => {
    dateRef.current?.showPicker();
  };

  return (
    <>
      <div className="relative w-full">
        <input
          type={type}
          value={value}
          min={min}
          max={max}
          disabled={disabled}
          ref={dateRef}
          className="w-full rounded-lg border border-neutral-300 bg-white p-4 text-sm text-neutral-900 focus:border-primary-900 focus:outline-none"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
        />

        <button
          type="button"
          tabIndex={-1}
          onClick={showPicker}
          className="absolute right-4 top-0 flex h-[54px] items-center text-neutral-900"
        >
          <Icon icon="calendar" />
        </button>
      </div>

      {errorIsVisible && (
        <div className="mt-1 text-sm text-red-500" role="alert">
          {customValidation?.errorMessage ?? translate('required-error')}
        </div>
      )}
    </>
  );
};

export default DateInput;
