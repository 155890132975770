import { createSelector } from '@reduxjs/toolkit';
import { TRAVEL_EXPENSES_FORM_NAME } from './constants';

const selectReduxTravelExpensesForm = (state) => state.form[TRAVEL_EXPENSES_FORM_NAME].values;

export const selectStartDate = () =>
  createSelector(selectReduxTravelExpensesForm, (reduxFormState) => reduxFormState.travelInformation.startDate);

export const selectEndDate = () =>
  createSelector(selectReduxTravelExpensesForm, (reduxFormState) => reduxFormState.travelInformation.endDate);

export const selectAttachments = () => (state) => {
  const actFormValues = state.form[TRAVEL_EXPENSES_FORM_NAME]?.values;
  if (actFormValues) {
    return actFormValues.attachments ?? [];
  }

  return undefined;
};

export const selectActId = () => (state) => state.form[TRAVEL_EXPENSES_FORM_NAME]?.values?.id;
