import shortid from 'shortid';
import moment from 'moment-timezone';
import ActBillingContext from '../../../domain/global/optimisation/ActBillingContext';
import { ActBillingContextOptimizationAction } from '../../../domain/global/optimisation/ActBillingContextOptimizationAction';
import RAMQCodes from '../../../ramq/RamqCodes';

type MainCodeSupplementCodePair = {
  mainCode: string;
  supplementCode: string;
};

export type AddSupplementCodeToActPayload = MainCodeSupplementCodePair & {
  type: 'AddSupplementCodeToAct';
};

export class AddSupplementCodeToAct implements ActBillingContextOptimizationAction<AddSupplementCodeToActPayload> {
  // eslint-disable-next-line no-unused-vars
  constructor(private mainCodeSupplementCodePair: MainCodeSupplementCodePair) {}

  apply(context: ActBillingContext) {
    const supplementCodeData = RAMQCodes.get(this.mainCodeSupplementCodePair.supplementCode);
    const optimizedAct = { ...context.act };

    if (!!supplementCodeData && RAMQCodes.isCodeEffectiveOn(supplementCodeData, moment(context.act.date))) {
      optimizedAct.codes = [
        ...(context.act.codes || []),
        {
          id: shortid.generate(),
          ...supplementCodeData
        }
      ];
    }

    return {
      act: optimizedAct,
      payload: this.getPayload()
    };
  }

  private getPayload(): AddSupplementCodeToActPayload {
    return {
      type: 'AddSupplementCodeToAct',
      mainCode: this.mainCodeSupplementCodePair.mainCode,
      supplementCode: this.mainCodeSupplementCodePair.supplementCode
    };
  }
}

export const addSupplementCodeToMainCodeInAct = (mainCodeSupplementCodePair: MainCodeSupplementCodePair) =>
  new AddSupplementCodeToAct(mainCodeSupplementCodePair);
