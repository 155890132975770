import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import CashAmountField from '../../../components/Form/custom/CashAmountField';
import { SelectorField } from '../../../components/Form/Fields';
import SpacedFormElement from '../../../components/Form/SpacedFormElement/SpacedFormElement';
import { LINE_SPECIFIC_POSSIBLE_CONTEXT_ELEMENTS, STAY_TYPES_LIST, TRANSPORT_TYPES_LIST } from '../constants';
import FeeBox from '../FeeBox';
import RestrictedAdditionalTransportContextElement from '../RestrictedAdditionalTransportContextElement';

function CostOfStayFee({ fieldName, fieldIndex, fields }) {
  const translate = useTranslate();
  return (
    <FeeBox fieldIndex={fieldIndex} fields={fields} label={translate('travel-expenses.stay')}>
      <Grid container style={{ padding: 16 }}>
        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.stayType`}
            label={translate('travel-expenses.type-of-stay')}
            component={SelectorField}
            formControlProps={{ fullWidth: true }}
            values={STAY_TYPES_LIST}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.amount`}
            label={translate('travel-expenses.amount')}
            component={CashAmountField}
            values={TRANSPORT_TYPES_LIST}
          />
        </Grid>
      </Grid>
      <RestrictedAdditionalTransportContextElement
        fieldName={fieldName}
        label="E. Contexte (ligne spécifique)"
        list={LINE_SPECIFIC_POSSIBLE_CONTEXT_ELEMENTS}
      />
    </FeeBox>
  );
}

CostOfStayFee.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired
};

export default CostOfStayFee;
