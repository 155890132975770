import { defineMessages } from 'react-intl';

export default defineMessages({
  actHistory: {
    id: 'nam.actHistory',
    defaultMessage: ''
  },
  seeMore: {
    id: 'nam.actHistory.seeMore',
    defaultMessage: ''
  },
  sameNamWarning: {
    id: 'nam.sameNamWarning',
    defaultMessage: ''
  },
  sameCodeNoNamWarning: {
    id: 'nam.sameCodeNoNamWarning',
    defaultMessage: ''
  }
});
