import { PRINT, PRINT_END } from './actions';

export const initialState = {
  printing: false
};

function statementReducer(state = initialState, action) {
  switch (action.type) {
    case PRINT:
      return {
        ...state,
        printing: true
      };
    case PRINT_END:
      return {
        ...state,
        printing: false
      };
    default:
      return state;
  }
}

export default statementReducer;
