import React from 'react';
import PageContainer from 'application/components/page-container';
import PageHeader from 'application/components/page-header';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  SETTINGS_APPLICATION_ROUTE,
  SETTINGS_DETAILS_ROUTE,
  SETTINGS_PARTNERS_ROUTE,
  SETTINGS_PREFERENCES_ROUTE,
  SETTINGS_REFERRAL_ROUTE,
  SETTINGS_SECURITY_ROUTE,
  UPVOTY_ROUTE
} from 'application/routes';
import useTranslate from 'application/hooks/use-translate';
import SettingsDetailsPage from 'application/pages/settings/settings.details';
import SettingsPreferencesPage from 'application/pages/settings/settings.preferences';
import SettingsSecurityPage from 'application/pages/settings/settings.security';
import SettingsApplicationPage from 'application/pages/settings/settings.application';
import Tabs from 'application/components/tabs';
import { gql, useQuery } from '@apollo/client';
import FullScreenLoading from 'app/components/Loadings/FullScreenLoading/FullScreenLoading';
import UpvotyWidget from 'application/pages/settings/upvoty-widget';
import SettingsReferralPage from 'application/pages/settings/settings.referral';
import SettingsPartnersPage from 'application/pages/settings/settings.partners';

interface LoggedInUserQuery {
  authenticatedUser: {
    id: string;
    upvotyCredentials: {
      baseUrl: string;
      boardHash: string;
      ssoToken: string;
    };
  };
}

const LOGGED_IN_USER_QUERY = gql`
  query authenticatedUser {
    authenticatedUser {
      id
      upvotyCredentials {
        baseUrl
        boardHash
        ssoToken
      }
    }
  }
`;

const SettingsIndex = () => {
  const translate = useTranslate('pages.settings');

  const { data, loading } = useQuery<LoggedInUserQuery>(LOGGED_IN_USER_QUERY);

  if (loading) {
    return <FullScreenLoading />;
  }

  const navLinkTabs = [
    { destination: SETTINGS_DETAILS_ROUTE, label: translate('tab.details') },
    { destination: SETTINGS_PREFERENCES_ROUTE, label: translate('tab.preferences') },
    { destination: SETTINGS_SECURITY_ROUTE, label: translate('tab.security') },
    { destination: SETTINGS_PARTNERS_ROUTE, label: translate('tab.partners') },
    { destination: SETTINGS_REFERRAL_ROUTE, label: translate('tab.referral') },
    { destination: SETTINGS_APPLICATION_ROUTE, label: translate('tab.application') }
  ];

  if (data?.authenticatedUser.upvotyCredentials) {
    navLinkTabs.push({ destination: UPVOTY_ROUTE, label: translate('tab.upvoty') });
  }

  return (
    <PageContainer>
      <PageHeader title={translate('account-settings')} />

      <Tabs tabs={navLinkTabs} />

      <Routes>
        <Route path={SETTINGS_DETAILS_ROUTE} element={<SettingsDetailsPage />} />
        <Route path={SETTINGS_PREFERENCES_ROUTE} element={<SettingsPreferencesPage />} />
        <Route path={SETTINGS_SECURITY_ROUTE} element={<SettingsSecurityPage />} />
        <Route path={SETTINGS_PARTNERS_ROUTE} element={<SettingsPartnersPage />} />
        <Route path={SETTINGS_APPLICATION_ROUTE} element={<SettingsApplicationPage />} />
        <Route path={SETTINGS_REFERRAL_ROUTE} element={<SettingsReferralPage />} />
        <Route
          path={UPVOTY_ROUTE}
          element={
            data?.authenticatedUser.upvotyCredentials && (
              <UpvotyWidget
                baseUrl={data.authenticatedUser.upvotyCredentials.baseUrl}
                boardHash={data.authenticatedUser.upvotyCredentials.boardHash}
                ssoToken={data.authenticatedUser.upvotyCredentials.ssoToken}
              />
            )
          }
        />
        <Route index element={<Navigate to={SETTINGS_DETAILS_ROUTE} />} />
      </Routes>
    </PageContainer>
  );
};

export default SettingsIndex;
