import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Field } from 'redux-form';
import BillingRateEditor from '../../../../components/BillingRateEditor/BillingRateEditor';

export interface Props {
  readonly: boolean;
}

export const Fees: React.FunctionComponent<Props> = ({ readonly }) => (
  <Grid container spacing={0}>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6" style={{ marginBottom: '24px' }}>
        Tarifs
      </Typography>

      {/* @ts-ignore */}
      <Field name="billingRates" readonly={readonly} component={BillingRateEditor} />
    </Grid>
  </Grid>
);
