export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const logoutAction = () => ({
  type: LOGOUT_ACTION
});

export const LOGGED_OUT_ACTION = 'LOGGED_OUT_ACTION';
export const loggedOutAction = () => ({
  type: LOGGED_OUT_ACTION
});

export const LOGGED_OUT_ERROR_ACTION = 'LOGGED_OUT_ERROR_ACTION';
export const loggedOutErrorAction = (error) => ({
  type: LOGGED_OUT_ERROR_ACTION,
  error
});

export const LOGGED_IN_ACTION = 'loggedInAction';
export const loggedInAction = (user) => ({
  type: LOGGED_IN_ACTION,
  user
});

export const FIRESTORE_OFFLINE_FAIL = 'firestoreOfflineFail';
export const firestoreOfflineFail = (error) => ({
  type: FIRESTORE_OFFLINE_FAIL,
  error
});

export const TRY_LOGIN = 'tryLogin';
export const tryLogin = (username, password) => ({
  type: TRY_LOGIN,
  username,
  password
});

export const MFA_LOGIN = 'mfaLogin';
export const mfaLogin = (token) => ({
  type: MFA_LOGIN,
  token
});

export const MFA_CANCEL = 'mfaCancel';
export const mfaCancel = () => ({
  type: MFA_CANCEL
});

export const LOGIN_FAILURE = 'loginFailure';
export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  error
});

export const MFA_REQUIRED = 'mfaRequired';
export const mfaRequired = (mfaChallenge) => ({
  type: MFA_REQUIRED,
  ...mfaChallenge
});

export const LOGIN_REMOVE_FAILURE = 'loginRemoveFailure';
export const loginRemoveFailure = () => ({
  type: LOGIN_REMOVE_FAILURE
});

export const UPDATE_PASSWORD_FAILURE = 'updatePasswordFailure';
export const updatePasswordFailure = (error) => ({
  type: UPDATE_PASSWORD_FAILURE,
  error
});

export const UPDATE_PASSWORD_SUCCESS = 'updatePasswordSuccess';
export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
  success: true
});
