/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
import accounting from 'accounting';

export const removeAccentAndLowercase = (
  inStr // thanks to Josh from Qaribou from stackoverflow
) =>
  inStr
    .toLowerCase()
    .replace(
      /([àáâãäå])|([[|*&;$%@"<>()\\+\],])|([ç])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
      (str, a, special, c, e, i, n, o, s, u, y, ae) => {
        /* istanbul ignore next */
        if (a) {
          return 'a';
        } else if (special) {
          return '';
        } else if (c) {
          return 'c';
        } else if (e) {
          return 'e';
        } else if (i) {
          return 'i';
        } else if (n) {
          return 'n';
        } else if (o) {
          return 'o';
        } else if (s) {
          return 's';
        } else if (u) {
          return 'u';
        } else if (y) {
          return 'y';
        } else if (ae) {
          return 'ae';
        }
      }
    );

export const buildRegexMatchingWords = (keywords) =>
  new RegExp(`(?=.*?\\b${keywords.split(' ').join(')(?=.*?\\b')}).*`, 'i');

export const currencyFormat = (string) =>
  accounting.formatMoney(string, {
    symbol: '$',
    precision: 2,
    decimal: ',',
    thousand: String.fromCharCode(160),
    format: `%v${String.fromCharCode(160)}%s`
  });

export const currencyFormatWithoutSymbol = (string) =>
  accounting.formatMoney(string, {
    precision: 2,
    decimal: ',',
    thousand: String.fromCharCode(160),
    format: '%v'
  });
