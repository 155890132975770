import PropTypes from 'prop-types';
import React from 'react';
import ListSelector from '../ListSelector';
import NativeListSelector from '../NativeListSelector/NativeListSelector';

export class HybridListSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = { renderNative: !!window.cordova };
  }

  render() {
    const { renderNative } = this.state;
    return renderNative ? <NativeListSelector {...this.props} /> : <ListSelector {...this.props} />;
  }
}

HybridListSelector.defaultProps = {
  label: undefined,
  disableFirstOption: false
};

HybridListSelector.propTypes = {
  input: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  label: PropTypes.string,
  disableFirstOption: PropTypes.bool
};

export default HybridListSelector;
