import Chip from '@material-ui/core/Chip';
import { find, get } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import isDefined from '../../../../shared/utils/isDefined';
import deviceDetector from '../../utils/DeviceDetector';

export const styles = (theme) => ({
  [theme.breakpoints.down('sm')]: {
    selectmenu: {
      overflowX: 'scroll'
    }
  },
  select: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%'
  },
  selectmenu: {
    display: 'flex'
  },
  smallChip: {
    cursor: 'inherit'
  }
});

const ifString = (...options) => {
  const foundOption = options.find(
    (option) => typeof option === 'string' || typeof option === 'number' || React.isValidElement(option)
  );

  return foundOption !== undefined ? foundOption : options[options.length - 1];
};

const renderSelectedValue = (values) => (placeholder) => (selected) => {
  if (!isDefined(selected) || selected.length === 0) {
    return placeholder;
  }

  if (!Array.isArray(selected)) {
    return get(find(values, { value: selected.toString() }), 'label', selected);
  }

  if (selected.length > 1) {
    return 'Plusieurs items ont été sélectionnés';
  }

  const { label } = find(values, { value: selected[0] });

  return label;
};

export function ListSelector({
  classes,
  values,
  input,
  label,
  className,
  disableFirstOption,
  formControlProps,
  multiple,
  placeholder,
  disabled,
  defaultValue
}) {
  useEffect(() => {
    if (!input.value && defaultValue) {
      input.onChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Retirer ce hack lorsque MUI 5 sera dans le projet
  const [open, setOpen] = useState(false);

  const currentValue =
    multiple && !Array.isArray(input.value) ? [input.value].filter((element) => !!element) : input.value;

  const disabledBecauseEmpty = values.length === 0;

  const fakeProps = {};
  if (deviceDetector.isMobile()) {
    fakeProps.onClick = () => {
      if (!disabledBecauseEmpty && !open) setOpen(true);
    };
    fakeProps.onClose = () => {
      if (!disabledBecauseEmpty && open) setOpen(false);
    };
    fakeProps.open = open;
  }

  const selector = (
    <Select
      displayEmpty
      multiple={multiple}
      disabled={disabledBecauseEmpty || disabled}
      value={currentValue}
      onChange={input.onChange}
      renderValue={renderSelectedValue(values)(placeholder)}
      className={classes.select}
      {...fakeProps}
    >
      {values.map((value, index) => (
        <MenuItem
          hidden={index === 0 && disableFirstOption}
          disabled={index === 0 && disableFirstOption}
          className={classes.selectmenu}
          key={value.key || value.value || value}
          value={ifString(value.value, value)}
          onClick={() => setOpen(false)}
        >
          {multiple && <Checkbox checked={currentValue.includes(ifString(value.value, value))} />}
          {isDefined(value.code) && <Chip className={classes.smallChip} label={value.code} />}
          <ListItemText primary={ifString(value.label, value)} />
        </MenuItem>
      ))}
    </Select>
  );
  return label ? (
    <FormControl className={className} {...formControlProps}>
      <InputLabel>{label}</InputLabel>
      {selector}
    </FormControl>
  ) : (
    selector
  );
}

ListSelector.defaultProps = {
  disableFirstOption: false,
  multiple: false
};

ListSelector.propTypes = {
  input: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableFirstOption: PropTypes.bool,
  formControlProps: PropTypes.object,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string
};

ListSelector.defaultProps = {
  label: undefined,
  className: '',
  formControlProps: undefined,
  placeholder: null,
  disabled: false,
  defaultValue: undefined
};

export default withStyles(styles)(ListSelector);
