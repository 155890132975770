const TRANSPORT_TYPES = {
  autobus: '1',
  covoiturage: '2',
  taxi: '3',
  train: '4',
  traversier: '5',
  vehiculeLoue: '6',
  vehiculePerso: '7',
  volCommercial: '8',
  volPersonnel: '9',
  volNolise: '10'
};

// Code de moyen de transport : Moyen de transport utilisé par le
// professionnel dans le cadre du déplacement.
//
// Domaine de valeurs
// 1 : Autobus
// 2 : Covoiturage
// 3 : Taxi
// 4 : Train
// 5 : Traversier
// 6 : Véhicule loué
// 7 : Véhicule personnel
// 8 : Vol commercial
// 9 : Vol personnel
// 10 : Vol nolisé

export default TRANSPORT_TYPES;
