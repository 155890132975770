import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import getObjectKeyByValue from '../../../../shared/utils/objectManipulation';
import messages from '../../../containers/TravelExpenses/messages';
import InformationBox from '../../InformationBox/InformationBox';

function TravelInformationBoxSelectValue({ label, map, value }) {
  if (!value) return null;

  return (
    <Grid item xs={12} sm={6}>
      <InformationBox
        label={label}
        content={
          <Typography>
            <FormattedMessage {...messages[getObjectKeyByValue(map, value)]} />
          </Typography>
        }
      />
    </Grid>
  );
}

TravelInformationBoxSelectValue.propTypes = {
  label: PropTypes.string.isRequired,
  map: PropTypes.object.isRequired,
  value: PropTypes.string
};

TravelInformationBoxSelectValue.defaultProps = {
  value: undefined
};

export default TravelInformationBoxSelectValue;
