import moment from 'moment-timezone';
import { all, call } from 'redux-saga/effects';
import { selectHasMixteGenerationEnabled } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { logDefault, logError } from '../../../shared/utils/plog';
import { reduxSagaFireBase } from '../../../server/Firebase';
import { ACTIVITIES_ACT_TYPE, ACTIVITIES_MIXTE_TYPE } from '../../../shared/collection/collectionConstants';
import queryBuilder from '../../../shared/collection/QueryBuilder';
import { MIXTE_PERIOD_DAYS_DURATION } from '../../../shared/periods/constants';
import { getWeekBasedEndDateFromDate, getWeekBasedStartDateFromDate } from '../../../shared/periods/periods';
import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import { getAllDataFromSnapshotWithBoundId } from '../../firebase/collection/snapshotDataFetcher';
import {
  operateActivitiesWithUserFilter,
  setDocument,
  trackErrorInFirestore
} from '../../firebase/document/documentSagas';
import { saveMixteFailure } from '../../containers/Act/actions';
import MixteGenerator from './generator/MixteGenerator';

export const updateAllMixte = (mixteToUpdate) =>
  mixteToUpdate.map((mixte) =>
    call(operateActivitiesWithUserFilter, ACTIVITIES_MIXTE_TYPE, setDocument, saveMixteFailure, { document: mixte })
  );

function* generateMixte({ document, userProfile }) {
  const hasMixteGenerationEnabled = selectHasMixteGenerationEnabled()(getStore().getState());
  if (!hasMixteGenerationEnabled) {
    logDefault({
      type: 'saga',
      text: 'generateMixte',
      array: ['Skipping mixte generation per user config', userProfile.id]
    });

    return;
  }

  logDefault({ type: 'saga', text: 'generateMixte', array: ['generateMixte starting'] });
  try {
    const userId = userProfile.id;
    const targetedDate = document.date;

    const actsActivitiesInCurrentDayQuery = queryBuilder
      .withBaseQuery(activitiesCollectionRef())
      .withType(ACTIVITIES_ACT_TYPE)
      .withUserId(userId)
      .withDayRange(targetedDate)
      .build();

    const mixteActivitiesInCurrentPeriodQuery = queryBuilder
      .withBaseQuery(activitiesCollectionRef())
      .withType(ACTIVITIES_MIXTE_TYPE)
      .withUserId(userId)
      .withDateRange({
        startDate: getWeekBasedStartDateFromDate(moment(targetedDate), MIXTE_PERIOD_DAYS_DURATION).valueOf(),
        endDate: getWeekBasedEndDateFromDate(moment(targetedDate), MIXTE_PERIOD_DAYS_DURATION).valueOf()
      })
      .build();

    const { actsSnapshot, mixtesSnapshot } = yield all({
      actsSnapshot: call(reduxSagaFireBase.firestore.getCollection, actsActivitiesInCurrentDayQuery),
      mixtesSnapshot: call(reduxSagaFireBase.firestore.getCollection, mixteActivitiesInCurrentPeriodQuery)
    });

    const actsData = getAllDataFromSnapshotWithBoundId(actsSnapshot);
    const mixtesData = getAllDataFromSnapshotWithBoundId(mixtesSnapshot);
    const mixteToUpdate = new MixteGenerator().generate(targetedDate, actsData, mixtesData);

    logDefault(`Saving ${mixteToUpdate.length} generated mixte`);
    yield all(updateAllMixte(mixteToUpdate));
  } catch (e) {
    logError({ type: 'saga', text: 'generateMixte', array: ['error generating mixte', e] });
    yield* trackErrorInFirestore(e, { type: 'mixteGeneration' });
  }
}

export default generateMixte;
