import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';

const MAX_NUMBER_OF_ACTIVITIES = 4;
const TREATMENT_CODE = '20176';

const removeCodeForInjectableMedication = (
  act: Partial<Act>,
  numberOfActivitiesWithTreatmentCodeDuringCivilYear: number
): Recommendation<ActCode[] | undefined>[] => {
  if (!act.codes?.some((code) => code.code === TREATMENT_CODE)) {
    return NO_RECOMMENDATIONS;
  }
  if (numberOfActivitiesWithTreatmentCodeDuringCivilYear < MAX_NUMBER_OF_ACTIVITIES) {
    return NO_RECOMMENDATIONS;
  }

  return [
    {
      message: `Le code ${TREATMENT_CODE} a atteint son nombre maximum de facturation pour ce patient dans l'année civile (4). Cochez pour retirer.`,
      apply: (partialAct) => {
        const oldValue = partialAct.codes;
        const newValue = partialAct.codes?.filter((actCode) => actCode.code !== TREATMENT_CODE);
        return {
          fieldName: 'codes',
          newValue,
          oldValue
        };
      }
    }
  ];
};

export default removeCodeForInjectableMedication;
