import React from 'react';
import { formatUnixToDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import { currencyFormat } from '../../../../../utils/wordFormatUtilities';

const add2DecimalToStringNumber = (string) => {
  if (string.includes('.')) {
    return string;
  }
  return `${string.substring(0, string.length - 2)}.${string.substring(string.length - 2)}`;
};

// Transform Ramq String "-000017" or "-0.17" to -0.17 float number.
// Note that number of zeros before the actual number and the format is variable.
export const ramqStringAmountToFloat = (string) => parseFloat(add2DecimalToStringNumber(string));

export const amountFormat = (string) => ramqStringAmountToFloat(string).toFixed(2);

const addUpLinesAmount = (property, lines) => {
  const addUpAmounts = (sum, current) => sum + ramqStringAmountToFloat(current[property]);
  return lines.reduce(addUpAmounts, 0).toFixed(2);
};

export const formatCurrencyOrDash = (stringAmount) => (stringAmount !== '0.00' ? currencyFormat(stringAmount) : '-');

export const dateAndPaymentNumber = {
  primaryTitle: 'Date',
  secondaryTitle: 'Numéro virement',
  primaryText: (statement) => <nobr>{formatUnixToDate(statement.generalInformation.headerInfo.date)}</nobr>,
  secondaryText: (statement) => statement.generalInformation.netAmountInfo.transferNumber
};

export const addUpGrossAmount = (statement) =>
  addUpLinesAmount('grossAmount', statement.generalInformation.grossAmountInfoLines);
export const addUpNetAmount = (statement) => statement.generalInformation.netAmountInfo.netAmount;
export const grossAndNetAmount = {
  primaryTitle: 'Montant Brut',
  secondaryTitle: 'Montant Net',
  primaryText: (statement) => formatCurrencyOrDash(addUpGrossAmount(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpNetAmount(statement))
};

export const addUpCeilingCutLines = (statement) =>
  addUpLinesAmount('cutAmount', statement.generalInformation.ceilingCutLines);
export const addUpDeductionAmount = (statement) =>
  addUpLinesAmount('deductionAmount', statement.generalInformation.deductionLines);
export const cutAndDeduction = {
  primaryTitle: 'Coupures trimestrielles',
  secondaryTitle: 'Déductions diverses',
  primaryText: (statement) => formatCurrencyOrDash(addUpCeilingCutLines(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpDeductionAmount(statement))
};

export const addUpFederationAmount = (statement) =>
  addUpLinesAmount('federationAmount', statement.generalInformation.federationContributionsLines);
export const addUpContributionAmount = (statement) =>
  addUpLinesAmount('contributionAmount', statement.generalInformation.clinicianContributionLines);
export const cotisations = {
  primaryTitle: 'Cotisation fédération',
  secondaryTitle: 'Cotisation clinicien',
  primaryText: (statement) => formatCurrencyOrDash(addUpFederationAmount(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpContributionAmount(statement))
};

export const addUpSeizedAmount = (statement) =>
  addUpLinesAmount('seizedAmount', statement.generalInformation.seizureLines);
export const addUpInterestOnNegativeBalance = (statement) =>
  addUpLinesAmount('interestOnNegativeBalance', statement.generalInformation.interestAndAdvancesLines);
export const seizureAndNegativeInterest = {
  primaryTitle: 'Saisies',
  secondaryTitle: 'Intérêts solde nég.',
  primaryText: (statement) => formatCurrencyOrDash(addUpSeizedAmount(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpInterestOnNegativeBalance(statement))
};

export const addUpTransferAmount = (statement) =>
  addUpLinesAmount('transferAmount', statement.generalInformation.balanceTransferLines);
export const addUpInsurancePaidAmount = (statement) =>
  addUpLinesAmount(
    'paidAmount',
    statement.oldLines.liabilityInsuranceE.map((insuranceLine) => insuranceLine.extraLines[0])
  );
export const balanceTransferAndInsurance = {
  primaryTitle: 'Transfert de solde',
  secondaryTitle: 'Assurance responsa.',
  primaryText: (statement) => formatCurrencyOrDash(addUpTransferAmount(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpInsurancePaidAmount(statement))
};

export const addUpInterestOnRecovery = (statement) =>
  addUpLinesAmount('interestOnRecovery', statement.generalInformation.interestAndAdvancesLines);
export const addUpInterestOnAdvance = (statement) =>
  addUpLinesAmount('interestOnAdvance', statement.generalInformation.interestAndAdvancesLines);
export const interests = {
  primaryTitle: 'Intérêts recouvrem.',
  secondaryTitle: 'Intérêts avance',
  primaryText: (statement) => formatCurrencyOrDash(addUpInterestOnRecovery(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpInterestOnAdvance(statement))
};

export const addUpPostponedBalance = (statement) =>
  addUpLinesAmount('postponedBalance', statement.generalInformation.balanceLines);
export const addUpToBePostponedBalance = (statement) =>
  addUpLinesAmount('toBePostponedBalance', statement.generalInformation.balanceLines);
export const balances = {
  primaryTitle: 'Solde reporté',
  secondaryTitle: 'Solde à reporter',
  primaryText: (statement) => formatCurrencyOrDash(addUpPostponedBalance(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpToBePostponedBalance(statement))
};

export const addUpRecoveredAdvances = (statement) =>
  addUpLinesAmount('recoveredAdvances', statement.generalInformation.interestAndAdvancesLines);
export const addUpIssuedAdvances = (statement) =>
  addUpLinesAmount('issuedAdvances', statement.generalInformation.interestAndAdvancesLines);
export const advances = {
  primaryTitle: 'Avances recouvrés',
  secondaryTitle: 'Avances émises',
  primaryText: (statement) => formatCurrencyOrDash(addUpRecoveredAdvances(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpIssuedAdvances(statement))
};

export const addUpBalanceChanges = (statement) =>
  addUpLinesAmount('balanceChanges', statement.generalInformation.balanceLines);
export const addUpBalancePaidAmount = (statement) =>
  addUpLinesAmount('paidAmount', statement.generalInformation.balanceLines);
export const balanceChangeAndPaid = {
  primaryTitle: 'Modification du solde',
  secondaryTitle: 'Paiement reçu',
  primaryText: (statement) => formatCurrencyOrDash(addUpBalanceChanges(statement)),
  secondaryText: (statement) => formatCurrencyOrDash(addUpBalancePaidAmount(statement))
};
