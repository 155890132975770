import React, { useMemo } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectLoggedUser } from '../../Authentication/selectors';
import Permission from '../../../../shared/domain/authorization/model/Permission';
import AuthUserDomain from '../../../../shared/domain/authentication/AuthUser';

interface ReportSection {
  name: string;
  reports: Array<{
    path: string;
    name: string;
    permissions?: Permission[];
  }>;
}

interface ReduxProps {
  connectedUser: AuthUserDomain;
}

interface OwnProps {
  // eslint-disable-next-line no-unused-vars
  onNavigateToReport: (reportPath: string) => void;
  sections: ReportSection[];
}

export interface Props extends OwnProps, ReduxProps {}

export const ReportHeader: React.FunctionComponent<Props> = ({ onNavigateToReport, sections, connectedUser }) => {
  const location = useLocation();
  const reportPath = useMemo(() => {
    const match = location.pathname.match(/\/reports\/([^/]+)/);
    return match ? match[1] : undefined;
  }, [location.pathname]);

  const sectionIndex = reportPath ? getSectionIndexFromReportPath(sections, reportPath) : 0;
  const sectionReports = sections[sectionIndex].reports;
  const reportIndex = reportPath ? getReportIndex(sections[sectionIndex], reportPath) : 0;

  const onReportSelected = (_, index: number) => {
    const report = sectionReports[index];
    onNavigateToReport(report.path);
  };

  const onSectionSelected = (_, index: number) => {
    const firstReport = sections[index].reports[0];
    if (firstReport) {
      onNavigateToReport(firstReport.path);
    }
  };
  return (
    <>
      <Tabs data-testid="section-tabs" variant="fullWidth" value={sectionIndex} onChange={onSectionSelected}>
        {sections.map((section) => (
          <Tab label={section.name} key={section.name} />
        ))}
      </Tabs>

      <Tabs data-testid="report-tabs" variant="fullWidth" value={reportIndex} onChange={onReportSelected}>
        {sectionReports
          .filter((report) => (report.permissions ? connectedUser.canSome(...report.permissions) : true))
          .map((report) => (
            <Tab label={report.name} key={report.path} />
          ))}
      </Tabs>
    </>
  );
};

const getSectionIndexFromReportPath = (sections: ReportSection[], reportPath: string): number =>
  sections.findIndex((section) => section.reports.some((report) => report.path === reportPath));

const getReportIndex = (section: ReportSection, reportPath: string): number =>
  section.reports.findIndex((report) => report.path === reportPath);

const mapStateToProps = createStructuredSelector({
  connectedUser: selectLoggedUser()
});

export default connect<ReduxProps, {}, OwnProps>(mapStateToProps)(ReportHeader);
