const START_TIME_MAPPING = new Map([
  ['42158', 8],
  ['42159', 16],
  ['42160', 20],
  ['42161', 0],
  ['42162', 0],
  ['42163', 0],
  ['42164', 8],
  ['42165', 16],
  ['42166', 20],
  ['42167', 0],
  ['42168', 0],
  ['42169', 0],
  ['42170', 0]
]);

export default START_TIME_MAPPING;
