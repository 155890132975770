import ActivityStatus from '../core/activity/domain/ActivityStatus';

function setStatusToSendIfStatusIsUndefined(periodForm) {
  let mutedPeriodForm = periodForm;

  if (periodForm.status === ActivityStatus.NO_STATUS) {
    mutedPeriodForm = {
      ...periodForm,
      status: ActivityStatus.SENT
    };
  }
  return mutedPeriodForm;
}

export default setStatusToSendIfStatusIsUndefined;
