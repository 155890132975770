import React from 'react';
import useTranslate from 'application/hooks/use-translate';

interface Props {
  onChange: (value: number | null) => void;
  value: number | null;
}

const ReportYearSelector = ({ onChange, value }: Props) => {
  const translate = useTranslate('reports');

  return (
    <select
      id="report-year-selector"
      className="select-bordered select mr-4"
      onChange={(event) => onChange(Number(event.target.value))}
      defaultValue={value || ''}
    >
      <option value="" disabled>
        {translate('year')}
      </option>
      {Array.from({ length: 5 }, (_, index) => {
        const year = new Date().getFullYear() - index;

        return (
          <option key={year} value={year}>
            {year}
          </option>
        );
      })}
    </select>
  );
};

export default ReportYearSelector;
