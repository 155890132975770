import { StyledComponentProps } from '@material-ui/core/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ACTIVITIES_ACT_TYPE } from '../../../../shared/collection/collectionConstants';
import { getBillingPeriodFromDate } from '../../../../shared/periods/periods';
import FullScreenLoading from '../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { prepareActivitiesData } from './paymentRequestUtils';
import { selectUserProfileInContext } from '../../User/selectors';
import { fetchPaymentRequestForDoctor } from '../../../infrastructure/api/ReportAPI';
import IndividualPaymentRequestRenderer from './IndividualPaymentRequestRenderer/IndividualPaymentRequestRenderer';
import { PeriodByActivities } from '../shared/type';
import LoadableDataCollectionState from '../../../ui/shared/global/LoadableDataCollectionState';
import isDefined from '../../../../shared/utils/isDefined';
import PaymentRequestHeader from './PaymentRequestHeader/PaymentRequestHeader';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import MixtePeriodTransformer from '../../PeriodsBilling/PeriodForm/transformer/mixte/MixtePeriodTransformer';

export interface Props extends StyledComponentProps {
  user: { billingType: string; practiceNumber: string };
}

export interface State extends LoadableDataCollectionState<any> {
  selectedDate: string;
  selectedPeriod?: PeriodByActivities;
}

export class PaymentRequest extends Component<Props, State> {
  private _isMounted: boolean = false;

  private set isMounted(updatedIsMounted: boolean) {
    this._isMounted = updatedIsMounted;
  }

  private get isMounted() {
    return this._isMounted;
  }

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      data: null,
      selectedDate: '',
      loading: false
    };
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  changeSelectedDate = (value) => {
    const period = getBillingPeriodFromDate(value) as PeriodByActivities;

    this.setState({
      selectedDate: value,
      selectedPeriod: period
    });

    this.fetchPaymentRequest(period);
  };

  async fetchPaymentRequest(period) {
    if (!isDefined(this.props.user)) {
      return;
    }

    const { practiceNumber } = this.props.user;

    const filters = {
      periodEndDate: period[ACTIVITIES_ACT_TYPE].endDate.valueOf(),
      practiceNumber
    };

    this.setState({ loading: true });

    try {
      const { data } = await fetchPaymentRequestForDoctor(filters);
      if (!this.isMounted || !data) return;

      const dataTransformed = data.map((activity) => {
        if (activity.type === ActivityType.MIXTE) {
          return new MixtePeriodTransformer().toPeriodFormDto(activity);
        }

        return activity;
      });
      this.setState({ data: dataTransformed });
    } finally {
      if (this.isMounted) {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const { data, loading, selectedDate, selectedPeriod } = this.state;
    const { user } = this.props;

    let preparedActivities;
    const hasDateSelected = selectedPeriod?.act !== undefined;

    if (hasDateSelected && isDefined(data)) {
      preparedActivities = prepareActivitiesData(data, selectedPeriod);
    }

    const dataIsReady = !loading && preparedActivities && user;

    return (
      <>
        {user && (
          <PaymentRequestHeader
            practiceNumber={user.practiceNumber}
            selectedDate={selectedDate}
            selectedPeriod={selectedPeriod}
            onChangeSelectedDate={this.changeSelectedDate}
          />
        )}

        {hasDateSelected && dataIsReady && (
          <IndividualPaymentRequestRenderer
            activities={preparedActivities}
            period={selectedPeriod}
            user={user as any}
          />
        )}

        {hasDateSelected && !dataIsReady && <FullScreenLoading parentNotFlex />}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectUserProfileInContext()
});

export default connect(mapStateToProps)(PaymentRequest);
