import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

class RadioListDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: undefined,
      error: undefined
    };
  }

  onConfirm = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) {
      this.setState({ error: 'Veuillez sélectionner au moins un élément' });
    } else {
      this.props.onItemSelected(selectedItem);
      this.props.onClose();
    }
  };

  handleEntering = () => {
    this.radioGroupRef.focus();
    this.setState({ selectedItem: undefined, error: undefined });
  };

  handleSelectedItem = (event, selectedItem) => {
    this.setState({ selectedItem, error: undefined });
  };

  assignRadioGroupRef = (ref) => {
    this.radioGroupRef = ref;
  };

  render() {
    const { open, onClose, items, title } = this.props;
    const { selectedItem, error } = this.state;
    return (
      <Dialog open={open} onClose={onClose} TransitionProps={{ onEntering: this.handleEntering }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {error && <Typography color="error">{error}</Typography>}
          <RadioGroup ref={this.assignRadioGroupRef} value={selectedItem} onChange={this.handleSelectedItem}>
            {items.map((item) => (
              <FormControlLabel value={item} key={item} control={<Radio />} label={item} />
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Annuler
          </Button>
          <Button
            autoFocus
            onClick={() => {
              this.onConfirm();
            }}
            color="primary"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RadioListDialog.propTypes = {
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default RadioListDialog;
