import moment from 'moment-timezone';
import get from 'lodash/get';
import actRepository from '../../../../infrastructure/act/actRepository';
import ActDuplicationService from '../../../../containers/ActForm/DuplicateAct/ActDuplicationService';
import ActivityStatus from '../../../../../shared/core/activity/domain/ActivityStatus';

export default class DuplicateActsUseCase {
  constructor(userId) {
    this.userId = userId;
  }

  async duplicate({ fromDate, toDate }) {
    const actsToDuplicate = await actRepository.queryActsByDateForCurrentUser(fromDate, this.userId);
    const actsInCurrentDay = await actRepository.queryActsByDateForCurrentUser(toDate, this.userId);

    return actsToDuplicate
      .filter((act) => !this.wasActCanceled(act))
      .filter((act) => !this.isActAlreadyActiveInCurrentDayActs(act, actsInCurrentDay))
      .map((act) => ActDuplicationService.duplicateToAnotherDay(act, toDate));
  }

  wasActCanceled(act) {
    return act.status === ActivityStatus.CANCELED;
  }

  isActAlreadyActiveInCurrentDayActs(actToDuplicate, actsInCurrentDay) {
    const sameActs = actsInCurrentDay.filter(
      (currentDayAct) =>
        this.haveSameNAM(currentDayAct, actToDuplicate) &&
        this.haveSameActCode(currentDayAct, actToDuplicate) &&
        this.haveSameHourMinutes(currentDayAct, actToDuplicate) &&
        currentDayAct.status !== ActivityStatus.CANCELED
    );

    return sameActs.length > 0;
  }

  haveSameNAM(act, otherAct) {
    return act.nam === otherAct.nam;
  }

  haveSameActCode(act, otherAct) {
    const getActCode = (anAct) => get(anAct, 'codes[0].code');
    return getActCode(act) === getActCode(otherAct);
  }

  haveSameHourMinutes(act, otherAct) {
    const isSameHourMinutes = (date1 = 0, date2 = 0) => {
      const momentDate1 = moment(date1);
      const momentDate2 = moment(date2);

      return momentDate1.hours() === momentDate2.hours() && momentDate1.minutes() === momentDate2.minutes();
    };

    return isSameHourMinutes(act.start, otherAct.start) && isSameHourMinutes(act.end, otherAct.end);
  }
}
