import { ActOptimisationSpecification } from '../ActOptimisationSpecification';
import ActBillingContext from '../../optimisation/ActBillingContext';
import { codeIsInRole } from '../../../activity/act/service/actCodeUtils';

export class ActContainsCodeWithRole extends ActOptimisationSpecification {
  constructor(
    private readonly code: string,
    private readonly role: number
  ) {
    super();
  }

  isSatisfiedBy(context: ActBillingContext): boolean {
    const actContextCodes = context.act.codes || [];

    for (const codeInActContext of actContextCodes) {
      if (
        codeInActContext.code === this.code &&
        codeIsInRole(codeInActContext, this.role, context.act.date, context.doctor.specialty)
      ) {
        return true;
      }
    }

    return false;
  }
}

export const actContainsCodeWithRole = (code: string, role: number) => new ActContainsCodeWithRole(code, role);
export const actDoesNotContainsCodeWithRole = (code: string, role: number) =>
  new ActContainsCodeWithRole(code, role).not();
