import { CheckboxField, SingleLineField } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import { isNotAValidValue } from '../../../validations/common/reduxFormValidation';
import { noValidAuthorizationFieldError } from '../ReductionMammaire150g/reductionMammaire150g';

const CFC_CODES = ['1310', '1311', '1312', '1405', '1406', '1416', '1417'];

const cfcCodeHasOnlyOneValueSelected = (code) =>
  CFC_CODES.includes(code.code) &&
  ((isNotAValidValue(code.authorizationNumber) && isNotAValidValue(code.cfc)) ||
    (isNotAValidValue(code.authorizationNumber) && !code.cfc) ||
    (!isNotAValidValue(code.authorizationNumber) && code.cfc));

const OnlyOneFieldMustBeFilled = (act) => {
  const codesInError = act.codes.map((code, index) =>
    cfcCodeHasOnlyOneValueSelected(code) ? noValidAuthorizationFieldError(index) : {}
  );
  return codesInError.reduce(combineErrors, {});
};

const associatedValidations = [OnlyOneFieldMustBeFilled];

const summaryRender = (code) => [
  {
    name: code.cfc ? 'cfc' : 'autorisation',
    value: code.cfc ? 'Oui' : code.authorizationNumber
  }
];

const associatedFields = [
  {
    label: 'No Authorisation',
    getName: (index) => `codes[${index}].authorizationNumber`,
    notRequired: true,
    type: SingleLineField
  },
  {
    label: 'Cicatrice située à la face ou au cou',
    getName: (index) => `codes[${index}].cfc`,
    notRequired: true,
    type: CheckboxField
  }
];

const noAutorisationCfc = { associatedFields, associatedValidations, summaryRender };

export default noAutorisationCfc;
