import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  title: {
    textAlign: 'center',
    paddingBottom: 5
  }
});

function DialogCenteredTitle({ label, className, classes }) {
  return <DialogTitle className={classNames(classes.title, className)}>{label}</DialogTitle>;
}

DialogCenteredTitle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  className: PropTypes.string
};

DialogCenteredTitle.defaultProps = {
  className: ''
};

export default withStyles(styles)(DialogCenteredTitle);
