/* eslint-disable react/no-array-index-key */

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  root: {
    pageBreakInside: 'avoid',
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  subtitleToolbar: {
    minHeight: 48,
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white
  }
});

export function StatementBox({ title, ContentComponent, contentArray, classes }) {
  return (
    !isEmpty(contentArray) && (
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={12}>
          <Toolbar className={classes.subtitleToolbar}>
            <Typography variant="subtitle1" color="inherit">
              {title}
            </Typography>
          </Toolbar>
          {contentArray.map((content, index) => (
            <ContentComponent key={index} content={content} />
          ))}
        </Grid>
      </Grid>
    )
  );
}

StatementBox.propTypes = {
  title: PropTypes.string.isRequired,
  contentArray: PropTypes.array.isRequired,
  ContentComponent: PropTypes.func.isRequired
};

export default withStyles(styles)(StatementBox);
