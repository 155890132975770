import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CodeSuggestion from '../../../../components/List/Item/CodeSuggestion/CodeSuggestion';
import CustomCodeDescriptionDialog from '../../../../components/Dialogs/CustomCodeDescriptionDialog/CustomCodeDescriptionDialog';
import { selectCodesPreferencesFromUser } from '../../../../containers/User/selectors';
import { addCodeToUserPreferences } from '../../../../containers/CodeSearchDialog/actions';
import isInFavoritesFunction from '../../../shared/domain/isInFavoritesFunction';
import { ActCodePresetSpecialtyList } from '../../../shared/ui/SpecialtyList/SpecialtyList';

const UNDEFINED_CODE = -1;

export const styles = (theme) => ({
  dialogContent: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  }
});

export class ActCodePresetsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editActCodeDialogOpen: false,
      selectedActCode: {
        code: UNDEFINED_CODE
      }
    };

    this.isInFavorites = this.isInFavorites.bind(this);
    this.handleOnFavoritesClick = this.handleOnFavoritesClick.bind(this);
    this.openEditActCodeDialog = this.openEditActCodeDialog.bind(this);
    this.closeEditActCodeDialog = this.closeEditActCodeDialog.bind(this);
    this.updateSelectedActCodeDescription = this.updateSelectedActCodeDescription.bind(this);
  }

  isInFavorites(actCode) {
    return isInFavoritesFunction(this.props.favoritesActCodes, actCode);
  }

  handleOnFavoritesClick(actCode) {
    this.openEditActCodeDialog(actCode);
  }

  openEditActCodeDialog(actCode) {
    this.setState({
      editActCodeDialogOpen: true,
      selectedActCode: actCode
    });
  }

  closeEditActCodeDialog() {
    this.setState({
      editActCodeDialogOpen: false,
      selectedActCode: {
        code: UNDEFINED_CODE
      }
    });
  }

  updateSelectedActCodeDescription(newDescription) {
    this.props.addCodeToFavorites({
      ...this.state.selectedActCode,
      description: newDescription
    });

    this.closeEditActCodeDialog();
  }

  render() {
    const { onClickItem, onConfirm, onCancel, selectedCodes, classes } = this.props;

    const renderActCode = (item) => (
      <CodeSuggestion
        key={item.id || item.code}
        suggestion={item}
        onSuggestionClick={onClickItem}
        isSelected={selectedCodes.map((code) => code.code).includes(item.code)}
        onFavoriteClick={this.handleOnFavoritesClick}
        isInFavorites={this.isInFavorites(item)}
      />
    );

    return (
      <>
        <DialogContent className={classes.dialogContent}>
          <ActCodePresetSpecialtyList initiallyOpen={false} renderItem={renderActCode} />
          <CustomCodeDescriptionDialog
            mode="append"
            key={this.state.selectedActCode}
            selectedCode={this.state.selectedActCode}
            open={this.state.editActCodeDialogOpen}
            onCancel={this.closeEditActCodeDialog}
            onSave={this.updateSelectedActCodeDescription}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div>
            <Button id="close-Dialog" onClick={onCancel} color="primary">
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button id="confirm-Dialog" onClick={onConfirm} color="primary">
              <FormattedMessage id="global.ok" />
            </Button>
          </div>
        </DialogActions>
      </>
    );
  }
}

ActCodePresetsTab.propTypes = {
  favoritesActCodes: PropTypes.array.isRequired,
  selectedCodes: PropTypes.array.isRequired,
  addCodeToFavorites: PropTypes.func.isRequired,
  onClickItem: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  favoritesActCodes: selectCodesPreferencesFromUser()
});

export const mapDispatchToProps = (dispatch) => ({
  addCodeToFavorites: (code) => dispatch(addCodeToUserPreferences(code))
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ActCodePresetsTab);
