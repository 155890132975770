import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { POSSIBLE_ADMIN_STATUS } from '../../../containers/ActForm/constants';
import ListSelector from '../../Form/ListSelector/ListSelector';
import { makeI18nStatusItem } from '../AdminStatusSelector/AdminStatusSelector';

const styles = (theme) => ({
  adminStatusSelector: {
    minWidth: theme.spacing(15),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
});

function AdminAdminStatusSelector({ classes, value, onChange }) {
  return (
    <ListSelector
      className={classes.adminStatusSelector}
      label="Status Admin"
      values={POSSIBLE_ADMIN_STATUS.map(makeI18nStatusItem)}
      input={{ value, onChange }}
    />
  );
}

AdminAdminStatusSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

AdminAdminStatusSelector.defaultProps = {
  value: ''
};

export default withStyles(styles)(AdminAdminStatusSelector);
