import MIXTE_CREATION_REQUEST, { MixteCreationRequestPayload } from './types';

function requestMixteAutomaticGeneration(payload: MixteCreationRequestPayload) {
  return {
    type: MIXTE_CREATION_REQUEST,
    payload
  };
}

export default requestMixteAutomaticGeneration;
