import { selectUserLanguage } from 'app/containers/User/selectors';
import { RootState } from 'application/types/redux-state';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

enum Language {
  FR = 'fr',
  EN = 'en'
}

export type Translation = {
  [key in Language]: string;
};

export default function useTranslated(translation?: Translation): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const language = useSelector<RootState, 'fr' | 'en'>(selectUserLanguage());
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (!language || !translation) return;

    setValue(translation[language]);
  }, [translation, language]);

  return value;
}
