import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';

export const styles = ({ breakpoints, palette, spacing }) => ({
  doctorNumberSelected: {
    backgroundColor: palette.primary[500],
    color: palette.common.white
  },
  practiceNumberChip: {
    width: '70px'
  },
  noPaddingOnMobile: {
    [breakpoints.down('xs')]: {
      padding: 0
    }
  },
  noRightOverflow: {
    paddingRight: '70px'
  },
  minWidth: {
    minWidth: spacing(9)
  },
  paddingRight: {
    paddingRight: spacing(3)
  }
});

export function DoctorFavoritesListItemText({ doctor, isSelected, classes }) {
  return (
    <>
      <ListItemIcon className={classes.paddingRight}>
        <Chip
          label={doctor.practiceNumber}
          size="small"
          className={classNames(
            {
              [classes.doctorNumberSelected]: isSelected,
              [classes.practiceNumberChip]: true
            },
            classes.minWidth
          )}
        />
      </ListItemIcon>
      <ListItemText
        className={classNames(classes.noPaddingOnMobile, classes.noRightOverflow)}
        primary={
          <Typography variant="body1">
            {doctor.lastName}, {doctor.firstName}
          </Typography>
        }
        secondary={
          <>
            {doctor.facility && <Typography variant="body2">{doctor.facility}</Typography>}
            {doctor.specialties && (
              <Typography variant="caption">
                {doctor.specialties.map((specialty) => specialty.label).join(', ')}
              </Typography>
            )}
          </>
        }
      />
    </>
  );
}

DoctorFavoritesListItemText.propTypes = {
  doctor: PropTypes.object.isRequired,
  isSelected: PropTypes.bool
};

DoctorFavoritesListItemText.defaultProps = {
  isSelected: false
};

export default withStyles(styles)(DoctorFavoritesListItemText);
