import { find, get, pick, uniq } from 'lodash';
import moment from 'moment-timezone';
import ActivityStatus from '../../../../../shared/core/activity/domain/ActivityStatus';
import { verifyDocumentSavedProperly } from '../../../../firebase/document/verificationUtils';
import { INTENSIVE_CARE_UNIT_SUPPORT_DAY } from '../../../../../shared/ramq/contextElements/globalContextElements';
import { getStrictHour } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import applyAuditInformation from '../../../../../utils/auditInformation';
import { activitiesCollectionRef } from '../../../../firebase/collection/collectionReferences';
import {
  addOnGuardCodes,
  addPatientSupportCode,
  addStudentSupervisionCodes,
  doctorIsOnIntensiveCareUnitSupportOnThisDay,
  getActsForSpecificDate,
  hasIntensiveCareUnitSupportCodeAndIsNotCanceled,
  INTENSIVE_CARE_OPTIMIZATIONS
} from './utils';

const getActsFromYesterday = (currentDate, userId) => {
  const yesterdayUnixTime = moment(currentDate).subtract(1, 'days').valueOf();
  return getActsForSpecificDate(yesterdayUnixTime, userId);
};

const actWithSamePlace = (placeWhereIntensiveCareHasBeenMade) => (act) =>
  get(act, ['place', 'number']) === placeWhereIntensiveCareHasBeenMade.number;

const actNotCanceledNotPaid = (act) => act.status !== ActivityStatus.CANCELED && act.status !== ActivityStatus.PAID;

const computeNewCodes = (currentDate, codes, place) => {
  const duplicatedCode = [];
  addPatientSupportCode(codes, duplicatedCode);
  addStudentSupervisionCodes(currentDate, codes, duplicatedCode, place);
  addOnGuardCodes(currentDate, codes, duplicatedCode, place);
  return duplicatedCode;
};

const updateTimeToCurrentDay = (newCodes, actsInCurrentDay, duplicatedAct) => {
  if (newCodes.map((code) => code.code).includes('9098')) {
    return getStrictHour(moment(actsInCurrentDay[0].date), 7).valueOf();
  }
  const momentStartTime = moment(duplicatedAct.start);
  return moment(actsInCurrentDay[0].date).hours(momentStartTime.hours()).minutes(momentStartTime.minutes()).valueOf();
};

const addDuplicatedAct = (actFromYesterday, actsInCurrentDay, newCodes, actsToDuplicate) => {
  const duplicatedAct = pick(actFromYesterday, ['start', 'nam', 'assets', 'place', 'type', 'userId']);
  duplicatedAct.id = activitiesCollectionRef().doc().id;
  duplicatedAct.date = actsInCurrentDay[0].date;
  duplicatedAct.codes = newCodes;
  duplicatedAct.contextElements = uniq(
    get(actFromYesterday, 'contextElements', []).concat(INTENSIVE_CARE_UNIT_SUPPORT_DAY)
  );
  duplicatedAct.status = ActivityStatus.WAITING;
  duplicatedAct.start = updateTimeToCurrentDay(newCodes, actsInCurrentDay, duplicatedAct);
  actsToDuplicate.push(duplicatedAct);
};

const computeActsWhereDuplicationIsNeeded = (actsFromYesterday, actsInCurrentDay) => {
  const placeWhereIntensiveCareHasBeenMade = find(
    actsInCurrentDay,
    hasIntensiveCareUnitSupportCodeAndIsNotCanceled
  ).place;
  const actsMadeFromYesterdayInSamePlace = actsFromYesterday
    .filter(actWithSamePlace(placeWhereIntensiveCareHasBeenMade))
    .filter(actNotCanceledNotPaid);
  const actsToDuplicate = [];
  actsMadeFromYesterdayInSamePlace.forEach((act) => {
    const codesToDuplicate = computeNewCodes(actsInCurrentDay[0].date, act.codes, placeWhereIntensiveCareHasBeenMade);
    if (codesToDuplicate.length > 0) {
      addDuplicatedAct(act, actsInCurrentDay, codesToDuplicate, actsToDuplicate);
    }
  });
  return actsToDuplicate;
};

const setDocumentPromise = (act) => {
  const documentRef = activitiesCollectionRef().doc(act.id);
  const auditedDocument = {
    ...act,
    ...applyAuditInformation({}, INTENSIVE_CARE_OPTIMIZATIONS)
  };

  verifyDocumentSavedProperly(documentRef, auditedDocument, 'intensivistOptimization/setDocumentPromise');
  return documentRef.set(auditedDocument);
};

const savedDuplicatedActs = (acts) => acts.map(setDocumentPromise);

const updateCurrentDocumentToPreventMultipleDuplication = (document) =>
  activitiesCollectionRef()
    .doc(document.id)
    .update({
      duplicationProcessDone: true,
      ...applyAuditInformation({}, INTENSIVE_CARE_OPTIMIZATIONS)
    });

const hasBeenDuplicated = (document) => document.duplicationProcessDone;

const duplicateActsFromYesterdayIfNeeded = async (document, userProfile, actsInCurrentDay) => {
  if (hasIntensiveCareUnitSupportCodeAndIsNotCanceled(document) && !hasBeenDuplicated(document)) {
    const actsFromYesterday = await getActsFromYesterday(document.date, userProfile.id);
    if (doctorIsOnIntensiveCareUnitSupportOnThisDay(actsFromYesterday)) {
      const actsThatNeedsDuplication = computeActsWhereDuplicationIsNeeded(actsFromYesterday, actsInCurrentDay);
      // This promise is really important since Firestore does not resolve promises offline.
      // That means you cannot "await save();" then "await update();". You have to do all promises at once and wait for all
      if (actsThatNeedsDuplication.length > 0) {
        await Promise.all([
          ...savedDuplicatedActs(actsThatNeedsDuplication),
          updateCurrentDocumentToPreventMultipleDuplication(document)
        ]);
      }
    }
  }
};

export default duplicateActsFromYesterdayIfNeeded;
