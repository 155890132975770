import {
  MULTI_ACT_REINIT_DIALOG,
  MULTI_ACT_CLOSE_DIALOG,
  MULTI_ACT_OPEN_DIALOG,
  MULTI_ACT_PROGRESS_UPDATE
} from './dialog/actions';
import { MULTI_ACT_UPDATE_PICTURE } from './fileUpload/constants';
import { MULTI_ACT_CREATE_ACTS_ERROR, MULTI_ACT_CREATE_ACTS_START } from './acts/constants';
import {
  MULTI_ACT_CLOSE_PICTURE_PREVIEW,
  MULTI_ACT_DELETE_PICTURE,
  MULTI_ACT_TOGGLE_PICTURE_PREVIEW
} from './fileUpload/actions';
import { MULTI_ACT_UPDATE_EXTRACTED_NAMS } from './nams/constants';

export const initialState = {
  openMultiActDialog: false,
  multiActProgress: null,
  multiActPicture: undefined,
  creatingActs: false,
  errorWhileCreatingActs: false,
  picturePreviewDisplayed: false
};

export default function multiActReducer(state = initialState, action) {
  switch (action.type) {
    case MULTI_ACT_UPDATE_EXTRACTED_NAMS:
      return {
        ...state,
        extractedNams: action.extractedNams
      };
    case MULTI_ACT_OPEN_DIALOG:
      return {
        ...state,
        multiActProgress: null,
        openMultiActDialog: true
      };
    case MULTI_ACT_PROGRESS_UPDATE:
      return {
        ...state,
        multiActProgress: {
          nam: action.nam,
          progress: action.progress,
          total: action.total
        }
      };
    case MULTI_ACT_CLOSE_DIALOG:
      return { ...initialState };
    case MULTI_ACT_REINIT_DIALOG:
      return {
        ...initialState,
        openMultiActDialog: true
      };
    case MULTI_ACT_UPDATE_PICTURE:
      return {
        ...state,
        multiActPicture: action.pictureData
      };
    case MULTI_ACT_CREATE_ACTS_START:
      return {
        ...state,
        creatingActs: true,
        errorWhileCreatingActs: false
      };
    case MULTI_ACT_CREATE_ACTS_ERROR:
      return {
        ...state,
        creatingActs: false,
        errorWhileCreatingActs: true
      };
    case MULTI_ACT_CLOSE_PICTURE_PREVIEW:
      return {
        ...state,
        picturePreviewDisplayed: false
      };
    case MULTI_ACT_DELETE_PICTURE:
      return {
        ...state,
        multiActPicture: undefined
      };
    case MULTI_ACT_TOGGLE_PICTURE_PREVIEW:
      return {
        ...state,
        picturePreviewDisplayed: action.forcedState === undefined ? !state.picturePreviewDisplayed : action.forcedState
      };
    default:
      return state;
  }
}
