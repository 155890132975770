import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export interface Props {
  xml: string;
  onSave: (xml: string) => void;
  onCancel: () => void;
}

export const XmlEditor: React.FunctionComponent<Props> = ({ xml, onSave, onCancel }: Props) => {
  const [editedXml, setEditedXml] = useState<string>(xml);

  return (
    <>
      <div>
        <Button data-testid="save-button" variant="contained" color="primary" onClick={() => onSave(editedXml)}>
          Sauvegarder et RAMQ Payer
        </Button>
        <Button data-testid="cancel-button" onClick={onCancel}>
          Annuler
        </Button>
      </div>

      <TextField
        multiline
        data-testid="xml-textarea"
        maxRows={Infinity}
        value={editedXml}
        onChange={(event) => setEditedXml(event.target.value)}
        fullWidth
      />
    </>
  );
};

export default XmlEditor;
