import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import calculateHalfDayTrainingClaimingForYearToDay from '../../../../../validations/training/persistence/persistenceUtil';

export interface Props {
  trainingDate: number;
  userId: string;
}

const HalfTrainingDayClaimingForYearToDay: React.FunctionComponent<Props> = ({ trainingDate, userId }: Props) => {
  const [halfDayTrainingClaimingForYearToDay, setHalfDayTrainingClaimingForYearToDay] = useState<Number>(0);

  useEffect(() => {
    calculateHalfDayTrainingClaimingForYearToDay(userId, trainingDate).then((value) => {
      setHalfDayTrainingClaimingForYearToDay(value);
    });
  }, [userId, trainingDate]);

  return (
    <Typography data-testid="halfTrainingDayClaimingForYearToDay">
      <FormattedMessage
        id="trainingActivity.halfDayClaimingMessage"
        values={{
          nb: <b>{halfDayTrainingClaimingForYearToDay.toString()}</b>
        }}
      />
    </Typography>
  );
};

export default HalfTrainingDayClaimingForYearToDay;
