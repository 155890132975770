import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import activityAreaMap from './act/data';
import mixteDispensaryAreaMap from './mixte/data';
import lumpsumDispensaryAreaMap, { adminDispensaryAreaMap } from './lumpsum/data';
import { ActivityAreaChoiceContext } from './types';
import SpecialtyName from '../../../shared/core/doctor/domain/SpecialtyName';
import UserUtils from '../../../utils/userUtils';

export default class ActivityAreaChoiceContextFactory {
  static create(activityType: ActivityType, userSpecialty?: SpecialtyName): ActivityAreaChoiceContext {
    switch (activityType) {
      case ActivityType.ACT: {
        if (!userSpecialty || !activityAreaMap[userSpecialty]) {
          throw new Error(
            `There is no any ActivityAreaChoiceContext implemented 
            for type ${activityType} 
            and user specialty ${userSpecialty}`
          );
        }

        return activityAreaMap[userSpecialty];
      }
      case ActivityType.MIXTE:
        return mixteDispensaryAreaMap;
      case ActivityType.LUMP_SUM:
        if (UserUtils.isAdminOrAgent()) {
          return adminDispensaryAreaMap;
        }
        return lumpsumDispensaryAreaMap;
      default:
        throw new Error(`There is no any ActivityAreaChoiceContext implemented for type ${activityType}`);
    }
  }
}
