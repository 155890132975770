import { mapValues, parseInt } from 'lodash';
import { addAmount } from '../../../../../../shared/amountComputation/computeExpectedAmountUtil';
import computeExpectedAmountForLumpSum, {
  convertRamqHoursWorkedToFloatHours,
  getDetailsOfLumpSum
} from '../../../../../../shared/amountComputation/computeLumpSumExpectedAmount';
import { getRelevantPeriodDaysGrouped } from './computeMixteDaysIncome';

export const computeLumpSumDaysIncome = (lumpSumInPeriod) => {
  const relevantDaysGrouped = getRelevantPeriodDaysGrouped(lumpSumInPeriod);
  return Object.values(mapValues(relevantDaysGrouped, computeExpectedAmountAndHoursWorked));
};

export const computeExpectedAmountAndHoursWorked = (sameDayLumpSumArray, dateKey) =>
  sameDayLumpSumArray.reduce(addUpSingleDayLumpSum, { date: dateKey, amount: '0.00', hoursWorked: 0 });

const addUpSingleDayLumpSum = (accumulator, singleDayLumpSum) => ({
  date: parseInt(accumulator.date),
  amount: addAmount(accumulator.amount, computeExpectedAmountForLumpSum(singleDayLumpSum)),
  hoursWorked: accumulator.hoursWorked + singleDayLumpSum.days.reduce(addUpLumpSumHours, 0)
});

const addUpLumpSumHours = (accumulator, currentDay) => {
  const details = getDetailsOfLumpSum(currentDay);
  return accumulator + details.reduce(addUpLumpSumDetailsHoursWorked, 0);
};

const addUpLumpSumDetailsHoursWorked = (total, current) =>
  total + convertRamqHoursWorkedToFloatHours(current.hoursWorked);

export default computeLumpSumDaysIncome;
