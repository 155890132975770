import { omit } from 'lodash';
import flatMap from 'lodash/flatMap';
import computeExpectedAmountForLumpSum, {
  applyAnnexe19ForNonCovidRelatedCode,
  getDetailsOfLumpSumAccordingToCode
} from '../../computeLumpSumExpectedAmount';
import PeriodAmountService, { DayWithComputedAmount, TotalAmountByCode } from '../PeriodAmountService';
import { LumpSumDay } from '../../../domain/activity/lumpSum/model/LumpSum';
import LumpsumDetailMergeService from '../../../periods/lumpSum/timeSlot/timeSlotCalculators/LumpSumDetailMergeService';

class LumpsumAmountService extends PeriodAmountService {
  getTotalAmount(): number {
    return this.getTotalAmountByCode().reduce((acc, { amount }) => acc + amount, 0);
  }

  getTotalAmountByCode(): TotalAmountByCode[] {
    const daysWithPopulatedPlace = this.mapDaysWithPopulatedPlace();

    const daysWithComputedAmount = flatMap(daysWithPopulatedPlace, (day) =>
      LumpsumDetailMergeService.mergeByCodeAndActivityArea(getDetailsOfLumpSumAccordingToCode(day)).map((detail) => ({
        ...detail,
        manualCorrection: day.manualCorrection,
        place: day.place
      }))
    ).map((day) => ({
      date: day.date,
      amount: day.manualCorrection
        ? Number.parseFloat(day.manualCorrection!)
        : Number.parseFloat(applyAnnexe19ForNonCovidRelatedCode(day, day.amount)),
      code: day.code
    }));

    return daysWithComputedAmount.reduce((acc: Array<{ code: string; amount: number }>, { code, amount }) => {
      const group = acc.find((group) => group.code === code);

      if (!group) {
        acc.push({ code, amount });
      } else {
        group.amount += amount;
      }

      return acc;
    }, []);
  }

  getAmountByDay(): DayWithComputedAmount[] {
    return this.mapDaysWithPopulatedPlace().map((day) => ({
      ...(omit(day, 'place') as LumpSumDay),
      amount: day.manualCorrection
        ? Number.parseFloat(day.manualCorrection!)
        : Number.parseFloat(computeExpectedAmountForLumpSum(day))
    }));
  }
}

export default LumpsumAmountService;
