import values from 'lodash/values';
import orderBy from 'lodash/orderBy';
import { diagnosticCodesSpecialtiesCollectionRef } from '../../../firebase/collection/collectionReferences';

export default class GetUserSpecialtyDiagnosticCodeCollectionUseCase {
  constructor(userProfile) {
    this.userProfile = userProfile;
  }

  async getCollection() {
    const actCodesCollectionSnapshot = await diagnosticCodesSpecialtiesCollectionRef(this.userProfile.specialty).get();

    const actCodeCollection = [];

    actCodesCollectionSnapshot.forEach((doc) => actCodeCollection.push(doc.data()));

    return orderBy(this.convertFirebaseMapIntoCollection(actCodeCollection), ['order']);
  }

  convertFirebaseMapIntoCollection(firebaseMap) {
    return values(
      firebaseMap.map((category) => ({
        ...category,
        items: values(category.items)
      }))
    );
  }
}
