import React from 'react';
import { connect } from 'react-redux';
import { WrappedFieldProps } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import isDefined from '../../../../../shared/utils/isDefined';
import { selectPatientContext, selectSelectedDate } from '../../../../containers/ActForm/selectors';
import { DateField } from '../../Fields';
import { PatientContext } from '../../../../../shared/domain/activity/act/model/Act';

export interface Props extends WrappedFieldProps {
  label: string;
  patientContext: PatientContext;
  date: number;
}

export class DefaultActValueDateField extends React.Component<Props> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (isDefined(this.props.input.value)) {
      return;
    }

    const { admissionDate } = this.props.patientContext;
    if (admissionDate) {
      this.props.input.onChange(admissionDate);
    } else if (this.props.input.value === '') {
      this.props.input.onChange(this.props.date);
    }
  }

  render() {
    const { label, meta, input } = this.props;
    return <DateField deletable={false} input={input} meta={meta} label={label} />;
  }
}

export const mapStateToProps = createStructuredSelector({
  date: selectSelectedDate(),
  patientContext: selectPatientContext()
});

export default connect(mapStateToProps)(DefaultActValueDateField);
