import Role from 'shared/domain/authentication/Role';

export const isUserAtLeast = (userRole: Role | null, requiredRole: Role) => {
  if (userRole === null) {
    return false;
  }

  switch (requiredRole) {
    case Role.Developer:
      return isUserOneOf(userRole, [Role.Developer]);

    case Role.Admin:
      return isUserOneOf(userRole, [Role.Developer, Role.Admin]);

    case Role.Agent:
      return isUserOneOf(userRole, [Role.Developer, Role.Admin, Role.Agent]);

    case Role.Doctor:
      return isUserOneOf(userRole, [Role.Developer, Role.Admin, Role.Agent, Role.Doctor]);

    case Role.Auditor:
      return isUserOneOf(userRole, [Role.Developer, Role.Admin, Role.Agent, Role.Doctor, Role.Auditor]);

    default:
      throw new Error('Unsupported role...');
  }
};

export const isUserOneOf = (userRole: Role, allowedRoles: Role[]) => allowedRoles.includes(userRole);
