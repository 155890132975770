import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import ActResponsePaymentDetails from '../ActResponsePaymentDetails/ActResponsePaymentDetails';

function DefaultFailureComponent({ ramqResponse, classes }) {
  return (
    <>
      <div className={classes.messages}>
        {ramqResponse.messages.map((message, index) => (
          <Typography key={index}>{message}</Typography>
        ))}
      </div>
      {ramqResponse.lines && <ActResponsePaymentDetails ramqResponse={ramqResponse} />}
    </>
  );
}

DefaultFailureComponent.propTypes = {
  ramqResponse: PropTypes.object.isRequired,
  classes: PropTypes.object
};

DefaultFailureComponent.defaultProps = {
  classes: {}
};

export default DefaultFailureComponent;
