import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import InformationBox from '../../InformationBox/InformationBox';

function TravelInformationBoxSingleValue({ label, value }) {
  if (!value) return null;

  return (
    <Grid item xs={12} sm={6}>
      <InformationBox label={label} content={<Typography>{value}</Typography>} />
    </Grid>
  );
}

TravelInformationBoxSingleValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};

TravelInformationBoxSingleValue.defaultProps = {
  value: undefined
};

export default TravelInformationBoxSingleValue;
