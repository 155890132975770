import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import messages from '../../containers/Activities/messages';

export const styles = (theme) => ({
  statusContainer: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.spacing(13)
  },
  dense: {
    minWidth: theme.spacing(7),
    padding: theme.spacing(0.5)
  },
  [ActivityStatus.WAITING]: {
    backgroundColor: theme.palette.turquoise[500]
  },
  [ActivityStatus.SENT]: {
    backgroundColor: theme.palette.primary[500]
  },
  [ActivityStatus.NEED_FIX]: {
    backgroundColor: theme.palette.red[500]
  },
  [ActivityStatus.NO_STATUS]: {
    backgroundColor: theme.palette.grey[500]
  },
  [ActivityStatus.PROCESSING]: {
    backgroundColor: theme.palette.yellow[500]
  },
  [ActivityStatus.CANCELED]: {
    backgroundColor: theme.palette.grey[500]
  },
  [ActivityStatus.PAID]: {
    backgroundColor: theme.palette.green[500]
  }
});

export function Status({ status, dense, className, noBoxShadow, classes }) {
  const computedClass = classNames(classes.statusContainer, className, {
    [classes[status]]: true,
    [classes.dense]: dense
  });

  return (
    <Paper {...(noBoxShadow ? { elevation: 0 } : {})} className={computedClass}>
      <Typography color="inherit" variant="caption">
        <FormattedMessage {...messages[status]} />
      </Typography>
    </Paper>
  );
}

Status.propTypes = {
  noBoxShadow: PropTypes.bool,
  status: PropTypes.string.isRequired,
  dense: PropTypes.bool,
  className: PropTypes.string
};

Status.defaultProps = {
  noBoxShadow: false,
  dense: false,
  className: ''
};

export default withStyles(styles)(Status);
