import sortBy from 'lodash/sortBy';
import { activitiesHistoryCollectionRef } from '../../firebase/collection/collectionReferences';

const fetchActivityHistory = async (activityId) => {
  const snapshot = await activitiesHistoryCollectionRef().where('parentDocumentId', '==', activityId).get();

  const data = snapshot.docs.map((s) => s.data());
  return sortBy(data, (a) => (a.auditInformation ? a.auditInformation.modifiedOn : a.modifiedOn));
};

export default fetchActivityHistory;
