/* eslint-disable no-shadow, no-unused-vars */
enum Permission {
  DO_ADMIN_ACTIONS = 'DO_ADMIN_ACTIONS',
  DO_AGENT_ACTIONS = 'DO_AGENT_ACTIONS',
  DO_DOCTOR_ACTIONS = 'DO_DOCTOR_ACTIONS',
  USERS_ALL = 'users.all',
  USERS_GROUP_RESTRICTED = 'users.groupRestricted',
  POOLS_ALL = 'pools.all',
  POOLS_READ = 'pools.read',
  REPORTS_STATEMENT_READ = 'reports.statement.read',
  REPORTS_PAYMENT_REQUEST_READ = 'reports.paymentRequest.read',
  REPORTS_PAYMENT_REQUEST_BY_SENT_DATE_READ = 'reports.paymentRequestBySentDate.read',
  REPORTS_RECEIVABLE_ACCOUNTS_READ = 'reports.receivableAccounts.read',
  REPORTS_SEND_BY_MAIL = 'reports.sendByMail',
  REPORTS_OUTSIDE_RAMQ_REQUESTS = 'reports.outsideRAMQRequests',
  GROUPS_ALL = 'groups.all',
  GROUPS_MANAGER_RESTRICTED = 'groups.managerRestricted'
}

export default Permission;
