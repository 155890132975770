import { newUnixDate } from '../../../../utils/dateTime/dateTimeUtils';
import { CorrectionProps } from '../../../../core/admin/domain/CorrectionProps';

class Correction {
  static readonly SYSTEM_AUTHOR_NAME = 'System';

  private constructor(
    // eslint-disable-next-line no-unused-vars
    private props: CorrectionProps
  ) {}

  static createSystemCorrection(adminNote: string): Correction {
    return Correction.create(adminNote, Correction.SYSTEM_AUTHOR_NAME);
  }

  static create(adminNote: string, author: string, isAcknowledgeable: boolean = false): Correction {
    return new Correction({
      author,
      date: newUnixDate(),
      content: adminNote,
      isAcknowledgeable
    });
  }

  getProps(): CorrectionProps {
    return Object.freeze(this.props);
  }
}

export default Correction;
