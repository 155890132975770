import get from 'lodash/get';
import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import ContextElementsRequiredDetail from '../../../components/Form/ActSpecificField/ContextElementsRequiredDetail/ContextElementsRequiredDetail';
import ContextElement from '../../../favorite/contextElement/domain/ContextElement';

const summaryRender = (codeToSummarize) => {
  const contextElements = get(codeToSummarize, 'ramqContextElements', []) as ContextElement[];
  if (contextElements.length === 0) {
    return [];
  }

  const contextElementsSummary = contextElements.map(({ code }) => code).join(', ');

  return [
    {
      name: 'E.C',
      value: contextElementsSummary
    }
  ];
};

export const getAssociatedFields = (): AssociatedField[] => {
  const ramqContextElementSelection: AssociatedField = {
    label: 'Éléments de contexte',
    getName: (index) => `codes[${index}].ramqContextElements`,
    type: ContextElementsRequiredDetail,
    notRequired: true
  };

  return [ramqContextElementSelection];
};

const ramqContextElement = (): RequiredDetail => ({
  associatedFields: getAssociatedFields(),
  summaryRender
});

export default ramqContextElement;
