import get from 'lodash/get';
import { AssociatedField, AssociatedValidation, RequiredDetail } from '../requiredDetail.type';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';
import { SURGERY_ROOM } from '../../../../shared/ramq/requiredDetails/surgeryRoom';

const associatedFields: AssociatedField[] = [
  {
    label: "Salle d'opération",
    getName: (index) => `codes[${index}].${SURGERY_ROOM}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    props: {
      defaultValue: false
    }
  }
];

const associatedValidations: AssociatedValidation[] = [];

const summaryRender = (code) => [
  {
    name: "Salle d'opération",
    value: get(code, SURGERY_ROOM, 'Non')
  }
];

const surgeryRoom: RequiredDetail = {
  summaryRender,
  associatedFields,
  associatedValidations
};

export default surgeryRoom;
