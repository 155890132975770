import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

class EditCategoryNameDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      name: props.category.category
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleSave() {
    const { name } = this.state;
    this.props.onSave(name);
  }

  handleClose() {
    this.props.onCancel();
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  render() {
    const { open, category } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="editableCollectionWithCategories.editCategoryTitle" />
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              id="input-category-name"
              label={<FormattedMessage id="editableCollectionWithCategories.categoryName" />}
              defaultValue={category.category}
              onChange={this.handleChangeName}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button id="cancel-dialog" onClick={this.handleClose} color="primary">
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button id="confirm-dialog" onClick={this.handleSave} color="primary" autoFocus>
            <FormattedMessage id="global.save" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditCategoryNameDialog.defaultProps = {
  category: { category: '' }
};

EditCategoryNameDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  category: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default EditCategoryNameDialog;
