import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { selectCurrentCodes } from '../../../../containers/ActForm/selectors';
import Alert from '../../../Alert/Alert';
import { ActCode } from '../../../../../shared/domain/activity/act/model/Act';
import { getFeatureConfig } from '../../../../../userCustomization/featureToggle';
import { PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS } from '../../../../../userCustomization/featureNames';
import AlertSeverity from '../../../../ui/shared/severity/AlertSeverity';

export interface Props extends WrappedFieldProps {
  actCodes: ActCode[];
}

export const ServiceProvidedInContextOfCatchingUpMedicalActivitiesWarning: React.FunctionComponent<Props> = ({
  actCodes = []
}: Props) => {
  const matchingCodeObject = actCodes.find(({ code }) => ['42080', '42268'].includes(code));

  if (matchingCodeObject) {
    const endHour = getFeatureConfig(PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS, 17);
    const message = `La garde ne peut être facturée la même journée que le code ${matchingCodeObject.code} entre 7h et ${endHour}h`;
    return <Alert severity={AlertSeverity.WARNING} message={message} />;
  }

  return null;
};

const mapStateToProps = createStructuredSelector({
  actCodes: selectCurrentCodes()
});

export default compose(connect(mapStateToProps))(ServiceProvidedInContextOfCatchingUpMedicalActivitiesWarning);
