import React from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { withStyles, WithStyles } from '@material-ui/core/styles';

const styles = () =>
  ({
    codeChip: {
      height: 15
    },
    codeChipLabel: {
      padding: '0 8px'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  code: string;
  className?: string;
}

const CodeChip: React.FunctionComponent<Props> = ({ code, className, classes }) => (
  <Chip label={code} classes={{ root: classNames(classes.codeChip, className), label: classes.codeChipLabel }} />
);

export default withStyles(styles)(CodeChip);
