import React from 'react';
import FormElement from 'app/components/Form/FormElement/FormElement';
import useTranslate from 'application/hooks/use-translate';
import { StripeStatus } from 'application/types/stripe-status';
import { DateField } from 'app/components/Form/Fields';

const SubscriptionPeriodEnd = ({ subscriptionStatus }) => {
  const translate = useTranslate('pages.profile.stripe-subscription');
  const getReduxStatePeriodEndName = (subscriptionStatus: string) => {
    if (subscriptionStatus === StripeStatus.TRIALING) {
      return 'stripeSubscriptionMetadata.trialEnd';
    }
    if (subscriptionStatus === StripeStatus.CANCELED) {
      return 'stripeSubscriptionMetadata.cancelEnd';
    }
    return 'stripeSubscriptionMetadata.currentPeriodEnd';
  };
  const getPeriodEndLabel = (subscriptionStatus: string) => {
    if (subscriptionStatus === StripeStatus.TRIALING) {
      return translate('trial-end');
    }
    if (subscriptionStatus === StripeStatus.CANCELED) {
      return translate('cancel-end');
    }
    return translate('period-end');
  };

  return (
    subscriptionStatus !== StripeStatus.PAUSED && (
      <FormElement
        name={getReduxStatePeriodEndName(subscriptionStatus)}
        label={getPeriodEndLabel(subscriptionStatus)}
        disabled
        component={DateField}
      />
    )
  );
};

export default SubscriptionPeriodEnd;
