import { selectUserLanguage } from 'app/containers/User/selectors';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

export function LanguageProvider({ language, messages, children }) {
  const locale = language || 'fr';

  return (
    <IntlProvider locale={locale} key="languageProvider" messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  language: PropTypes.string,
  children: PropTypes.element.isRequired,
  messages: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  language: selectUserLanguage()
});

export default connect(mapStateToProps)(LanguageProvider);
