import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  transformToCategorizableCollection,
  transformToFirebaseDocumentsMap
} from '../../../../shared/editable/domain/CategorizableCollectionTransformer';
import FavoriteItemWithActionMenu from '../../../../containers/PlacesPreferencesDialog/FavoriteItemWithActionMenu/FavoriteItemWithActionMenu';
import CollapsableCategory from '../../../../shared/category/ui/CollapsableCategory/CollapsableCategory';
import CategorizableCollection from '../../../../shared/editable/domain/CategorizableCollection';
import EditableCollectionWithCategories from '../../../../shared/editable/ui/EditableCollectionWithCategories/EditableCollectionWithCategories';
import DeleteCategoryValidationDialog from '../../../../shared/editable/ui/DeleteCategoryValidationDialog/DeleteCategoryValidationDialog';
import CreateNewCategoryDialog from '../../../../shared/editable/ui/CreateNewCategoryDialog/CreateNewCategoryDialog';
import EditCategoryNameDialog from '../../../../shared/editable/ui/EditCategoryNameDialog/EditCategoryNameDialog';
import EditDiagnosticDialog from '../EditItemDialog/EditItemDialog';
import { updateDiagnosticCodesFromUserPreferences } from '../../adapters/actions';

export const styles = (theme) => ({
  searchContainer: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  },
  addCategoryButtonContainer: {
    margin: `${theme.spacing(2)}px 0`,
    display: 'flex',
    justifyContent: 'center'
  },
  dialogActions: {
    justifyContent: 'space-between'
  }
});

export class DiagnosticCodeFavoritesEditor extends React.Component {
  constructor(props) {
    super(props);

    const categorizableCollection = transformToCategorizableCollection(props.favoritesDiagnosticCodes);

    this.state = {
      editDiagnosticDialogOpen: false,
      deleteCategoryValidatorOpen: false,
      createCategoryDialogOpen: false,
      categoryNameEditorOpen: false,
      tempSelectedCode: {
        id: null,
        value: undefined
      },
      tempSelectedCategory: {
        id: null,
        value: undefined
      },
      favoritesDiagnosticCodes: categorizableCollection
    };

    this.getItemComponentBuilder = this.getItemComponentBuilder.bind(this);
    this.getContainerWrapperComponentBuilder = this.getContainerWrapperComponentBuilder.bind(this);
    this.handleOnChangeOrder = this.handleOnChangeOrder.bind(this);
    this.handleOnRemoveItem = this.handleOnRemoveItem.bind(this);
    this.openCodeDescriptionEditor = this.openCodeDescriptionEditor.bind(this);
    this.closeCodeDescriptionEditor = this.closeCodeDescriptionEditor.bind(this);
    this.updateActCodeDescription = this.updateActCodeDescription.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.openDeleteCategoryValidator = this.openDeleteCategoryValidator.bind(this);
    this.closeDeleteCategoryValidator = this.closeDeleteCategoryValidator.bind(this);
    this.openNewCategoryDialog = this.openNewCategoryDialog.bind(this);
    this.closeNewCategoryDialog = this.closeNewCategoryDialog.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.openCategoryNameEditor = this.openCategoryNameEditor.bind(this);
    this.closeCategoryNameEditor = this.closeCategoryNameEditor.bind(this);
    this.editCategoryName = this.editCategoryName.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  getItemComponentBuilder({ item, id }) {
    return (
      <FavoriteItemWithActionMenu
        id={id}
        chipNumber={item.code}
        onItemRemove={() => this.handleOnRemoveItem(id)}
        onItemEdit={() => this.openCodeDescriptionEditor(id)}
        primaryText={item.description}
      />
    );
  }

  getContainerWrapperComponentBuilder({ id, label, level, children }) {
    return (
      <CollapsableCategory
        label={label}
        level={level}
        onRemove={() => this.openDeleteCategoryValidator(id)}
        onEdit={() => this.openCategoryNameEditor(id, { category: label })}
      >
        {children}
      </CollapsableCategory>
    );
  }

  handleOnRemoveItem(id) {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favoritesDiagnosticCodes);

      return {
        ...state,
        favoritesDiagnosticCodes: categorizableCollection.removeItem(id)
      };
    });
  }

  openCodeDescriptionEditor(id) {
    this.setState((state) => ({
      editDiagnosticDialogOpen: true,
      tempSelectedCode: {
        id,
        value: state.favoritesDiagnosticCodes.items[id]
      }
    }));
  }

  closeCodeDescriptionEditor() {
    this.setState({
      editDiagnosticDialogOpen: false,
      tempSelectedCode: {
        id: null,
        value: undefined
      }
    });
  }

  updateActCodeDescription(description) {
    this.setState((state) => {
      const updatedItem = {
        ...state.favoritesDiagnosticCodes.items[state.tempSelectedCode.id],
        description
      };

      return {
        ...state,
        favoritesDiagnosticCodes: new CategorizableCollection(state.favoritesDiagnosticCodes).updateItem(
          state.tempSelectedCode.id,
          updatedItem
        )
      };
    }, this.closeCodeDescriptionEditor);
  }

  handleOnChangeOrder(newCollectionWithCategory) {
    this.setState({ favoritesDiagnosticCodes: newCollectionWithCategory });
  }

  openDeleteCategoryValidator(id) {
    this.setState((state) => ({
      deleteCategoryValidatorOpen: true,
      tempSelectedCategory: {
        id,
        value: new CategorizableCollection(state.favoritesDiagnosticCodes).getFromPath(id)
      }
    }));
  }

  deleteCategory() {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favoritesDiagnosticCodes);

      return {
        ...state,
        favoritesDiagnosticCodes: categorizableCollection.removeCategory(state.tempSelectedCategory.id)
      };
    }, this.closeDeleteCategoryValidator);
  }

  closeDeleteCategoryValidator() {
    this.setState({
      deleteCategoryValidatorOpen: false,
      tempSelectedCategory: {
        id: null,
        value: undefined
      }
    });
  }

  openNewCategoryDialog() {
    this.setState({
      createCategoryDialogOpen: true
    });
  }

  closeNewCategoryDialog() {
    this.setState({
      createCategoryDialogOpen: false
    });
  }

  createCategory({ id, name, path }) {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favoritesDiagnosticCodes);
      return {
        ...state,
        favoritesDiagnosticCodes: categorizableCollection.addCategory({ id, name }, path)
      };
    }, this.closeNewCategoryDialog);
  }

  openCategoryNameEditor(id, category) {
    this.setState({
      categoryNameEditorOpen: true,
      tempSelectedCategory: {
        id,
        value: category
      }
    });
  }

  closeCategoryNameEditor() {
    this.setState({
      categoryNameEditorOpen: false,
      tempSelectedCategory: {
        id: null,
        value: undefined
      }
    });
  }

  editCategoryName(newCategoryName) {
    this.setState((state) => {
      const { id } = state.tempSelectedCategory;

      const categorizableCollection = new CategorizableCollection(state.favoritesDiagnosticCodes);
      return {
        ...state,
        favoritesDiagnosticCodes: categorizableCollection.editCategoryName(id, newCategoryName)
      };
    }, this.closeCategoryNameEditor);
  }

  handleSave() {
    const firebaseDocumentMap = transformToFirebaseDocumentsMap(this.state.favoritesDiagnosticCodes);

    this.props.updateUserDiagnosticCodePreferences(firebaseDocumentMap);
    this.props.onCancel();
  }

  render() {
    const { classes, onCancel } = this.props;

    return (
      <>
        <DialogContent className={classes.searchContainer}>
          <EditableCollectionWithCategories
            collectionWithCategories={this.state.favoritesDiagnosticCodes}
            itemComponent={this.getItemComponentBuilder}
            containerWrapperComponent={this.getContainerWrapperComponentBuilder}
            onChange={this.handleOnChangeOrder}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <div>
            <Button id="add-category" color="primary" onClick={this.openNewCategoryDialog}>
              <FormattedMessage id="editableCollectionWithCategories.addCategory" />
            </Button>
          </div>
          <div>
            <Button id="close-Dialog" color="primary" onClick={onCancel}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button id="accept-Dialog" color="primary" onClick={this.handleSave}>
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        </DialogActions>

        <EditDiagnosticDialog
          key={this.state.tempSelectedCode.value}
          diagnostic={this.state.tempSelectedCode.value}
          mode="edit"
          open={this.state.editDiagnosticDialogOpen}
          onCancel={this.closeCodeDescriptionEditor}
          onSave={this.updateActCodeDescription}
        />

        <DeleteCategoryValidationDialog
          category={this.state.tempSelectedCategory.value}
          open={this.state.deleteCategoryValidatorOpen}
          onCancel={this.closeDeleteCategoryValidator}
          onConfirm={this.deleteCategory}
        />

        <CreateNewCategoryDialog
          open={this.state.createCategoryDialogOpen}
          categoryPaths={new CategorizableCollection(this.state.favoritesDiagnosticCodes).getCategoryPathsFlatMap()}
          onCancel={this.closeNewCategoryDialog}
          onConfirm={this.createCategory}
        />

        <EditCategoryNameDialog
          open={this.state.categoryNameEditorOpen}
          category={this.state.tempSelectedCategory.value}
          onCancel={this.closeCategoryNameEditor}
          onSave={this.editCategoryName}
        />
      </>
    );
  }
}

DiagnosticCodeFavoritesEditor.propTypes = {
  favoritesDiagnosticCodes: PropTypes.array.isRequired,
  updateUserDiagnosticCodePreferences: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export const mapDispatchToProps = (dispatch) => ({
  updateUserDiagnosticCodePreferences: (documentMap) => dispatch(updateDiagnosticCodesFromUserPreferences(documentMap))
});

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(DiagnosticCodeFavoritesEditor);
