import React from 'react';

interface Props {
  label?: string;
  children?: React.ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
}

const CheckboxInput = ({ label, children, value, onChange }: Props) => {
  if (label && children) throw new Error('CheckboxInput cannot have both label and children');
  if (!label && !children) throw new Error('CheckboxInput must have either label or children');

  return (
    <label className="label cursor-pointer justify-start">
      <input type="checkbox" checked={value} className="checkbox-primary checkbox" onChange={() => onChange(!value)} />
      <span className="label-text ml-2 text-sm">{label ?? children}</span>
    </label>
  );
};

export default CheckboxInput;
