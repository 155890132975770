import React, { FunctionComponent } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import Signature from '../../../../components/List/CommentList/Signature/Signature';
import AdministrativeNote from '../../../../../shared/core/activity/domain/AdministrativeNote';

export const styles: StyleRules = {
  root: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  comment: {
    marginTop: '12px',
    whiteSpace: 'pre-line'
  }
};

interface OwnProps {
  comment: AdministrativeNote;
  actions: React.ReactElement | null;
}

type Props = OwnProps & WithStyles<typeof styles>;

export const Comment: FunctionComponent<Props> = ({ comment, classes, actions = null }: Props) => (
  <div className={classes.root}>
    <div>
      <Signature date={moment(comment.date).valueOf()} author={comment.author} />
      <Typography variant="body2" className={classes.comment}>
        {comment.content}
      </Typography>
    </div>
    {actions}
  </div>
);

export default withStyles(styles)(Comment) as React.ComponentType<OwnProps>;
