import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    boxShadow: theme.shadows[2]
  },
  white: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary[500]
  },
  deleteButton: {
    color: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(4),
    position: 'absolute',
    right: 10,
    top: 0
  }
});

function TravelExpenseSectionHeader({ label, white, onDelete, classes }) {
  return (
    <div className={classNames(classes.container, { [classes.white]: white })}>
      <Typography color="inherit" variant="button">
        {label}
      </Typography>
      {onDelete && (
        <IconButton className={classes.deleteButton} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
}

TravelExpenseSectionHeader.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  white: PropTypes.bool
};

TravelExpenseSectionHeader.defaultProps = {
  white: false,
  onDelete: undefined
};

export default withStyles(styles)(TravelExpenseSectionHeader);
