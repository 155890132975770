const styles = (theme) => ({
  iconContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.spacing(6)
  },
  iconButtonLabel: {
    display: 'block',
    width: 'inherit'
  },
  indicatorContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: 'inherit'
  },
  checked: {
    color: theme.palette.secondary.main,
    height: theme.spacing(2),
    width: theme.spacing(2)
  },
  expandIcon: {
    width: '100%',
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', theme.transitions.duration.shortest)
  },
  expandIconExpanded: {
    transform: 'rotate(180deg)'
  }
});

export default styles;
