import Grid from '@material-ui/core/Grid';
import React, { ReactNode } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';
import SEVERITY_ICONS from '../../ui/shared/severity/SeverityIcons';
import AlertSeverity from '../../ui/shared/severity/AlertSeverity';

interface Props {
  classes?: any;
  children?: ReactNode;
  message?: ReactNode | string;
  severity?: AlertSeverity;
  showIcon?: boolean;
  noMargin?: boolean;
  title?: string;
}

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    position: 'relative'
  },
  default: {
    backgroundColor: '#ffffff',
    color: '#000000'
  },
  error: {
    backgroundColor: '#fdecea',
    color: '#611a15'
  },
  errorIcon: {
    color: '#f44336'
  },
  info: {
    backgroundColor: '#e8f4fd',
    color: '#0d3c61'
  },
  infoIcon: {
    color: '#2196f3'
  },
  success: {
    backgroundColor: '#edf7ed',
    color: '#1e4620'
  },
  successIcon: {
    color: '#4caf50'
  },
  none: {
    background: 'none',
    boxShadow: 'none',
    color: '#000000',
    padding: 0
  },
  warning: {
    backgroundColor: '#fff4e5',
    color: '#663c00'
  },
  recommendation: {
    backgroundColor: '#C7C2DA',
    color: '#220d61'
  },
  warningIcon: {
    color: '#ff9800'
  },
  recommendationIcon: {
    color: '#220d61'
  },
  title: {
    fontSize: '1.25em',
    fontWeight: 500
  },
  message: {
    marginTop: theme.spacing(1)
  },
  messageWithPadding: {
    paddingLeft: theme.spacing(4)
  },
  noMargin: {
    margin: 0
  }
});

export function Alert({
  classes,
  children = undefined,
  message = undefined,
  severity = AlertSeverity.DEFAULT,
  noMargin = false,
  showIcon = false,
  title = ''
}: Props) {
  return (
    <Paper className={classNames(classes.container, classes[severity], { [classes.noMargin]: noMargin })} elevation={0}>
      {(showIcon || title) && (
        <Grid container spacing={1} alignItems="center">
          {showIcon && (
            <Grid item>
              {React.createElement(SEVERITY_ICONS[severity] as any, {
                'data-testid': 'severity-icon',
                'className': classes[`${severity}Icon`]
              })}
            </Grid>
          )}
          {title && (
            <Grid item>
              <Typography color="inherit" variant="subtitle1" className={classes.title}>
                {title}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      {message && (
        <Typography
          color="inherit"
          variant="body1"
          className={classNames(classes.message, { [classes.messageWithPadding]: showIcon && title && !children })}
        >
          {message}
        </Typography>
      )}
      {children && (
        <Typography color="inherit" variant="body1" component="span">
          {children}
        </Typography>
      )}
    </Paper>
  );
}

export default withStyles(styles)(Alert);
