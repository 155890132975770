import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  }
});

function InformationBox({ label, content, classes }) {
  return (
    <div className={classes.container}>
      <Typography variant="caption">{label}</Typography>
      {content}
    </div>
  );
}

InformationBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  content: PropTypes.node.isRequired
};

export default withStyles(styles)(InformationBox);
