import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ActivityContainer from '../../ActivityContainer/ActivityContainer';

const styles = (theme) => ({
  periodContainer: {
    backgroundColor: theme.palette.grey[300],
    borderBottom: `1px solid ${theme.palette.grey[600]}`
  }
});

function PeriodsActivityContainer({ onClick, classes, children }) {
  return (
    <Fade in>
      <ActivityContainer className={classes.periodContainer} onClick={onClick}>
        {children}
      </ActivityContainer>
    </Fade>
  );
}

PeriodsActivityContainer.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(PeriodsActivityContainer);
