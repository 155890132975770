import get from 'lodash/get';
import { set } from 'lodash';
import { AssociatedField, AssociatedValidation, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import FollowupType, { FIRST_FOLLOWUP, FOLLOWUP_TYPE } from '../../../../shared/ramq/requiredDetails/FollowupType';
import isDefined from '../../../../shared/utils/isDefined';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import { actFormFields } from '../../../containers/ActForm/constants';
import isCancerDiagnostic from '../../../../shared/diagnostic/domain/isCancerDiagnostic';

interface FollowupTypeOptions {
  askFirstFollowup: boolean;
}

const FOLLOWUP_TYPE_CHOICES = [
  { label: 'Cancer', value: FollowupType.CANCER },
  { label: 'Cancer peau', value: FollowupType.SKIN_CANCER },
  { label: 'Tumeur', value: FollowupType.TUMOR },
  { label: 'Transplantation', value: FollowupType.TRANSPLANTATION }
];

const FOLLOWUPS_WITH_DIAGNOSTIC = [FollowupType.CANCER];

const missingDiagnosticError = () =>
  set({ elementIdToScrollTo: actFormFields.patientDiagnostic }, actFormFields.patientDiagnostic, 'Ce champ est requis');

const mustBeCancerDiagnosticError = () =>
  set(
    { elementIdToScrollTo: actFormFields.patientDiagnostic },
    actFormFields.patientDiagnostic,
    'Doit être un diagnostic cancer'
  );

const isDiagnosticMissing = (act) => !isDefined(act[actFormFields.patientDiagnostic]);

const isDiagnosticRequired = (act) => act.codes.some((code) => FOLLOWUPS_WITH_DIAGNOSTIC.includes(code[FOLLOWUP_TYPE]));

const ensureDiagnosticIsFilled = (act) => {
  if (!isDiagnosticRequired(act)) return {};

  return isDiagnosticMissing(act) ? missingDiagnosticError() : {};
};

const ensureIsCancerDiagnostic = (act) => {
  if (!isDiagnosticRequired(act) || isDiagnosticMissing(act)) return {};

  return !isCancerDiagnostic(act[actFormFields.patientDiagnostic]) ? mustBeCancerDiagnosticError() : {};
};

const associatedValidations: AssociatedValidation[] = [ensureDiagnosticIsFilled, ensureIsCancerDiagnostic];

const summaryRender = (code) => [
  {
    name: 'Type de suivi patient',
    value: get(code, FOLLOWUP_TYPE, '-')
  }
];

const associatedFields = ({ askFirstFollowup }: FollowupTypeOptions): AssociatedField[] => [
  {
    label: 'Type de suivi patient',
    getName: (index) => `codes[${index}].${FOLLOWUP_TYPE}`,
    type: SingleSelectCheckboxGroup,
    list: FOLLOWUP_TYPE_CHOICES,
    notRequired: true,
    props: {
      defaultValue: null
    }
  },
  {
    label: 'Première prise en charge',
    getName: (index) => `codes[${index}].${FIRST_FOLLOWUP}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    shouldFieldBeHidden: () => !askFirstFollowup,
    notRequired: true
  }
];

const followupType = (options: FollowupTypeOptions): RequiredDetail => ({
  summaryRender,
  associatedFields: associatedFields(options),
  associatedValidations
});

export default followupType;
