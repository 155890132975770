import { AM_PERDIEM, PM_PERDIEM } from '../../../../../../shared/periods/common/constants';
import { LatestMixteDayPeriodFormDto, MixteDayCode } from '../../../PeriodForm/type';
import MIXTE_DISPENSARY_AREA_MAP from '../../../../../../shared/ramq/domainValues/mixteDispensaryAreas';

class DayUnitController {
  public static readonly DEFAULT_HALF_PERDIEM_DURATION = 3.5;
  public static readonly MAXIMUM_DURATION_BY_PERDIEMS = 5;

  static generatePerdiemList(days: LatestMixteDayPeriodFormDto[], index: number) {
    const { perdiems = [], codes = [], date } = days[index];

    if (perdiems.length === 1 && codes.length > 1) {
      return [
        { value: AM_PERDIEM, disabled: perdiems[0] === PM_PERDIEM },
        { value: PM_PERDIEM, disabled: perdiems[0] === AM_PERDIEM }
      ];
    }

    if (DayUnitController.isALeastTwoPeriodOnTheSameDay(days, date)) {
      return [
        { value: AM_PERDIEM, disabled: true },
        { value: PM_PERDIEM, disabled: true }
      ];
    }

    return [{ value: AM_PERDIEM }, { value: PM_PERDIEM }];
  }

  static canAddCode(days: LatestMixteDayPeriodFormDto[], index: number): boolean {
    const { perdiems = [], codes = [] } = days[index];

    return perdiems.length <= 1 && codes.length < 3;
  }

  static canAddAnotherPeriodOnTheSameDay(
    holiday: boolean,
    days: LatestMixteDayPeriodFormDto[],
    index: number
  ): boolean {
    const { perdiems = [], codes = [], date } = days[index];

    return (
      !holiday &&
      perdiems.length === 1 &&
      codes.length > 0 &&
      !DayUnitController.isALeastTwoPeriodOnTheSameDay(days, date)
    );
  }

  static isALeastTwoPeriodOnTheSameDay(days: LatestMixteDayPeriodFormDto[], dayDate: number): boolean {
    return days.filter(({ date }) => date === dayDate).length > 1;
  }

  static createComplementaryPeriod(days: LatestMixteDayPeriodFormDto[], index: number): LatestMixteDayPeriodFormDto {
    const { date, perdiems = [null] } = days[index];

    return {
      date,
      manualEditionMode: true,
      perdiems: [perdiems[0] === AM_PERDIEM ? PM_PERDIEM : AM_PERDIEM],
      codes: [DayUnitController.createCodeLine()]
    };
  }

  static createCodeLine(codes: { duration: number }[] = []): MixteDayCode {
    const totalDuration = codes.reduce((acc, { duration }) => acc + duration, 0);

    return {
      activityArea: MIXTE_DISPENSARY_AREA_MAP.generalCare,
      duration:
        totalDuration < DayUnitController.DEFAULT_HALF_PERDIEM_DURATION
          ? DayUnitController.DEFAULT_HALF_PERDIEM_DURATION - totalDuration
          : 0
    };
  }
}

export default DayUnitController;
