import { actionChannel, fork, take, delay } from 'redux-saga/effects';
import generateMixte from '../generateMixte';
import MIXTE_CREATION_REQUEST from './types';

export function* watchRequestsForMixteCreation() {
  const channel = yield actionChannel(MIXTE_CREATION_REQUEST);
  while (true) {
    const { payload } = yield take(channel);
    /*
    fixme : normalement, on devrait utiliser un call au lieu du fork pour avoir un processus bloquant et s'assurer qu'un seul
    mixte soit crée à la fois. Cependant `generateMixte` ne peux pas se terminer completement en mode offline.
    a la place, on a décider d'avoi un processus non bloquant mais de laisser 2 seconde au mixte pour se créer afin
    d'éviter la création du même mixte deux fois de suite
    */
    yield fork(generateMixte, payload);
    yield delay(2000);
  }
}
