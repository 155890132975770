import BillingType from '../billing/model/BillingType';
import User from './User';

enum UserBillingType {
  PRIVATE = 'private',
  POOL = 'pool'
}

export const isUserInPool = (user: User) => user && user.billingType === UserBillingType.POOL;

export const isPoolBillingType = (billingType: UserBillingType) => billingType === UserBillingType.POOL;

export const userBillingTypeToBillingType = (billingType: UserBillingType): BillingType => {
  if (billingType === UserBillingType.POOL) return BillingType.POOL;

  return BillingType.PRIV;
};

export default UserBillingType;
