import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    flexWrap: 'wrap'
  }
});

export function FormRow(props) {
  const { classes, children, className } = props;
  const mergedClassName = classNames(classes.row, className);
  return <div className={mergedClassName}>{children}</div>;
}

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

FormRow.defaultProps = {
  className: ''
};

export default withStyles(styles)(FormRow);
