import { get } from 'lodash';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import statusValidation from '../common/activityValidation';
import {
  createErrorMapWithEveryValidationFunction,
  requiredArrayFieldValidations,
  requiredFieldValidations
} from '../common/reduxFormValidation';
import costOfStayFeeValidation from './costOfStayFeeValidation';
import transportFeeValidation from './transportFeeValidation';
import travelInformationLocationValidation from './travelInformationLocationValidation';
import travelTimeFeeValidation from './travelTimeFeeValidation';

const waitingStatusValidation = (travelExpenses) =>
  createErrorMapWithEveryValidationFunction(
    travelExpenses,
    statusValidation,
    requiredFieldValidations(['travelInformation.travelType', 'travelInformation.startDate'])
  );

export const sentStatusValidation = (travelExpenses) =>
  createErrorMapWithEveryValidationFunction(
    travelExpenses,
    statusValidation,
    requiredFieldValidations([
      'travelInformation.travelType',
      'travelInformation.startDate',
      'travelInformation.startTime',
      'travelInformation.startLocation',
      'travelInformation.endDate',
      'travelInformation.endTime',
      'travelInformation.endLocation'
    ]),
    requiredArrayFieldValidations(['fees']),
    travelInformationLocationValidation,
    feesValidation
  );

const feesValidation = (travelExpenses) =>
  createErrorMapWithEveryValidationFunction(
    get(travelExpenses, 'fees', []),
    transportFeeValidation,
    travelTimeFeeValidation,
    costOfStayFeeValidation
  );

const validateTravelExpenses = (travelExpenses) =>
  travelExpenses.status === ActivityStatus.WAITING
    ? waitingStatusValidation(travelExpenses)
    : sentStatusValidation(travelExpenses);

export default validateTravelExpenses;
