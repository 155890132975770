import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import withImageData from '../PicturePreview/withImageData';

const styles = () => ({
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
  }
});

function PictureBackground({ imageData, classes }) {
  return <span id="picture-preview" className={classes.imageSrc} style={{ backgroundImage: `url(${imageData})` }} />;
}

PictureBackground.propTypes = {
  imageData: PropTypes.string.isRequired
};

export default compose(withImageData, withStyles(styles))(PictureBackground);
