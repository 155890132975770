export const FOLLOWUP_TYPE = 'followupType';
export const FIRST_FOLLOWUP = 'firstFollowup';

/* eslint-disable no-shadow, no-unused-vars */
enum FollowupType {
  CANCER = 'CANCER',
  SKIN_CANCER = 'SKIN_CANCER',
  TUMOR = 'TUMOR',
  TRANSPLANTATION = 'TRANSPLANTATION'
}

export default FollowupType;
