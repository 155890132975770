import actions from 'redux-form/lib/actions';
import { TRAINING_ATTACHMENTS_LIST_FIELD, TRAINING_FORM_NAME } from '../../ui/constants';

export const TRAINING_FILE_READY_FOR_UPLOAD = 'trainingFileReadyForUpload';
export const trainingFileReadyForUpload = (documentId, attachmentType, fileEntry, persistFileLocallyBeforeUpload) => ({
  type: TRAINING_FILE_READY_FOR_UPLOAD,
  documentId,
  attachmentType,
  fileEntry,
  persistFileLocallyBeforeUpload
});

export const updateSavedAttachmentInTrainingForm = (attachments) =>
  actions.change(TRAINING_FORM_NAME, TRAINING_ATTACHMENTS_LIST_FIELD, attachments);

export const SAVE_TRAINING = 'saveTraining';
export const saveTraining = (document) => ({
  type: SAVE_TRAINING,
  document
});

export const SAVE_TRAINING_FAILURE = 'saveTrainingFailure';
export const saveTrainingFailure = (error) => ({
  type: SAVE_TRAINING_FAILURE,
  error
});
