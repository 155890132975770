import PropTypes from 'prop-types';
import React from 'react';
import getPictureDataUrl from './utils';

const withImageData = (WrappedComponent) => {
  class ImageDataComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        imageData: ''
      };
    }

    componentDidMount() {
      this.getPictureData();
    }

    componentDidUpdate() {
      if (!this.state.imageData) {
        this.getPictureData();
      }
    }

    getPictureData = async () => {
      const { savedPicture, extractedNams } = this.props;
      const dataUrl = await getPictureDataUrl(savedPicture);

      if (extractedNams && extractedNams.length > 0) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          ctx.lineWidth = 5;

          extractedNams?.forEach(({ vertexes, isValid }) => {
            ctx.beginPath();
            ctx.strokeStyle = isValid ? 'green' : 'red';

            ctx.moveTo(vertexes[0].x - 5, vertexes[0].y - 5);
            ctx.lineTo(vertexes[1].x + 5, vertexes[1].y - 5);
            ctx.lineTo(vertexes[2].x + 5, vertexes[2].y + 5);
            ctx.lineTo(vertexes[3].x - 5, vertexes[3].y + 5);

            ctx.closePath();
            ctx.stroke();
          });

          this.setState({ imageData: canvas.toDataURL() });
        };
        img.src = dataUrl;
      }

      this.setState({ imageData: dataUrl });
    };

    render() {
      const { imageData } = this.state;
      return <WrappedComponent {...this.props} imageData={imageData} />;
    }
  }

  ImageDataComponent.propTypes = {
    savedPicture: PropTypes.object.isRequired,
    extractedNams: PropTypes.array.isRequired
  };

  ImageDataComponent.defaultProps = {
    savedPicture: undefined,
    extractedNams: []
  };

  return ImageDataComponent;
};

export default withImageData;
