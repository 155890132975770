import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Field, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogCenteredTitle from '../DialogCenteredTitle/DialogCenteredTitle';
import SingleSelectCheckboxGroup from '../../Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import SignatoryField from '../../Form/SignatoryField/SignatoryField';
import PERIOD_FORM_NAME from '../../../containers/PeriodsBilling/constants';
import { saveFavoritePlace } from '../../../containers/PlacesPreferencesDialog/actions';
import { Signatory } from '../../../../shared/domain/place/model/Signatory';
import Place from '../../../../shared/domain/place/model/Place';
import isSignatoryComplete from '../../../validations/placesSignatory/isSignatoryComplete';

const styles = (theme) =>
  ({
    content: {
      minWidth: '400px'
    },
    confirmationMessage: {
      marginBottom: theme.spacing(2)
    },
    signatoryForm: {
      marginTop: theme.spacing(2)
    },
    title: {
      marginTop: theme.spacing(1)
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  open: boolean;
  hideRequestSignatureField: boolean;

  /* eslint-disable no-unused-vars */
  onSubmit: (updatedPlaceSignatory: Signatory | undefined) => void;
  onDismiss: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  saveFavoritePlace: (place: Place) => void;
  /* eslint-enable */

  requestSignature: boolean;
  signatory: Signatory;
}

export const ElectronicSignatureDialog: React.FunctionComponent<Props> = ({
  open,
  hideRequestSignatureField,
  requestSignature,
  signatory,
  onSubmit,
  onDismiss,
  classes
}) => {
  const [updateSignatory, setUpdateSignatory] = useState<boolean>(false);
  const formDisabled = !hideRequestSignatureField && !requestSignature;

  const onUpdateSignatoryChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUpdateSignatory(event.target.checked);
  const onSubmitForm = () => onSubmit(updateSignatory ? signatory : undefined);

  return (
    <Dialog open={open} onClose={onDismiss} classes={{ paper: classes.content }}>
      <DialogCenteredTitle className={classes.title} label="Signature Électronique" />

      <DialogContent>
        {!hideRequestSignatureField && (
          <>
            <Typography align="center" className={classes.confirmationMessage}>
              Voulez-vous faire signer le document par signature électronique?
            </Typography>

            <Field
              name="requestSignature"
              // @ts-ignore
              component={SingleSelectCheckboxGroup}
              checkboxClasses={{}}
              list={YES_NO_CHECKBOX_VALUES}
            />
          </>
        )}

        <DialogContent className={classes.signatoryForm}>
          <SignatoryField name="place.signatory" validateEmail={requestSignature} disabled={formDisabled} />

          <FormControlLabel
            control={
              <Checkbox
                id="checkbox-updateSignatoryInPlace"
                name="Test"
                checked={updateSignatory}
                disabled={formDisabled}
                onChange={onUpdateSignatoryChange}
              />
            }
            label="Utiliser ce signataire pour les futurs envois à créer"
          />
        </DialogContent>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={!hideRequestSignatureField && requestSignature && !isSignatoryComplete(signatory)}
          autoFocus
          onClick={onSubmitForm}
          color="primary"
        >
          Envoyer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const selector = formValueSelector(PERIOD_FORM_NAME);

function mapStateToProps(state: any) {
  return {
    requestSignature: selector(state, 'requestSignature'),
    signatory: selector(state, 'place.signatory')
  };
}

function mapDispatchToProps() {
  return {
    saveFavoritePlace
  };
}

export default compose<typeof React.Component>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ElectronicSignatureDialog);
