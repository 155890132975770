import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { range } from 'lodash';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { PaginationData } from '../../ui/hooks/usePagination';

const MAX_PAGES = 7;
const MAX_PAGES_AROUND_CURRENT_PAGE = 4;
const FIRST_PAGE = 1;
const SECOND_PAGE = 2;
const FIFTH_PAGE = 5;

const styles = (theme) =>
  ({
    wrapper: {
      'display': 'flex',
      'flexDirection': 'row',
      'alignItems': 'center',
      '& > *': {
        margin: `0 ${theme.spacing(0.5)}px`
      }
    },
    button: {
      minWidth: 30
    },
    clickable: {
      '&:hover': {
        cursor: 'pointer'
      }
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  style?: object;
  pagination: PaginationData;
}

export const PageSelector: React.FunctionComponent<Props> = ({
  pagination: { goToNextPage, goToPreviousPage, goToPage, pageNumber: currentPage, numberOfPages: lastPage },
  classes,
  style
}) => {
  if (lastPage === 0) return null;

  const currentPageNumber = parseInt(currentPage.toString(), 10);

  function renderPageButton(page: number) {
    const isCurrentPage = page === currentPageNumber;
    return (
      <Button
        onClick={() => goToPage(page)}
        color="primary"
        className={classes.button}
        key={page}
        variant={isCurrentPage ? 'contained' : undefined}
        size="small"
      >
        {page}
      </Button>
    );
  }

  function renderPlaceholderButton() {
    return (
      <Button color="primary" className={classes.button} size="small">
        ...
      </Button>
    );
  }

  const displayPlaceholders = lastPage > MAX_PAGES;
  const displayLeftPlaceholder = displayPlaceholders && currentPageNumber > MAX_PAGES_AROUND_CURRENT_PAGE;
  const displayRightPlaceholder = displayPlaceholders && currentPageNumber <= lastPage - MAX_PAGES_AROUND_CURRENT_PAGE;

  const middlePageNumbers = calculateMiddlePageNumbers(
    currentPageNumber,
    lastPage,
    displayLeftPlaceholder,
    displayRightPlaceholder
  );

  return (
    <div className={classes.wrapper} style={style}>
      <ChevronLeftIcon
        color={currentPageNumber > FIRST_PAGE ? 'primary' : 'disabled'}
        onClick={goToPreviousPage}
        className={currentPageNumber > FIRST_PAGE && goToPreviousPage ? classes.clickable : undefined}
      />

      {renderPageButton(1)}
      {displayLeftPlaceholder && renderPlaceholderButton()}
      {middlePageNumbers.map((page) => renderPageButton(page))}
      {displayRightPlaceholder && renderPlaceholderButton()}
      {lastPage !== FIRST_PAGE && renderPageButton(lastPage)}

      <ChevronRightIcon
        color={currentPageNumber < lastPage ? 'primary' : 'disabled'}
        onClick={goToNextPage}
        className={goToNextPage ? classes.clickable : undefined}
      />
    </div>
  );
};

function calculateMiddlePageNumbers(
  currentPage: number,
  lastPage: number,
  displayLeftPlaceholder: boolean,
  displayRightPlaceholder: boolean
): number[] {
  if (displayLeftPlaceholder && displayRightPlaceholder) {
    return inclusiveRange(previousPage(currentPage), nextPage(currentPage));
  }

  if (displayLeftPlaceholder) {
    return inclusiveRange(lastPage - MAX_PAGES_AROUND_CURRENT_PAGE, previousPage(lastPage));
  }

  if (displayRightPlaceholder) {
    return inclusiveRange(SECOND_PAGE, FIFTH_PAGE);
  }

  if (lastPage === FIRST_PAGE) {
    return [];
  }

  return inclusiveRange(SECOND_PAGE, previousPage(lastPage));
}

const inclusiveRange = (from: number, to: number): number[] => range(from, to + 1);

const previousPage = (page: number) => page - 1;
const nextPage = (page: number) => page + 1;

export default withStyles(styles)(PageSelector);
