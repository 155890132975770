import { concat, flattenDeep, get, isEmpty, merge, set } from 'lodash';
import { SubmissionError } from 'redux-form';
import isDefined from '../../../shared/utils/isDefined';

export const requiredFieldValidations = (affectedFields) => affectedFields.map(fieldIsPresentValidation);

export const requiredArrayFieldValidations = (affectedFields) =>
  affectedFields.map((field) => (values) => {
    const fieldValue = get(values, field);
    return !fieldValue || fieldValue.length === 0 ? fieldArrayIsIsRequiredError(field) : {};
  });

export const fieldIsPresentValidation = (field) => (values) =>
  isNotAValidValue(get(values, field)) ? fieldIsRequiredError(field) : {};

export const isNotAValidValue = (value) => !value && value !== false && value !== 0;

const fieldArrayIsIsRequiredError = (field) =>
  set({ elementIdToScrollTo: field }, field, { _error: 'Vous devez entrer au moins un code pour pouvoir facturer' });

const fieldIsRequiredError = (field) => set({ elementIdToScrollTo: field }, field, 'Ce champ est requis');

export const createErrorMapWithEveryValidationFunction = (values, ...validationFunctions) => {
  const concatenatedFunctions = concat([], ...validationFunctions);
  const flattenedArrayOfFunctions = flattenDeep(concatenatedFunctions);
  const reducer = (errorMap, currentFunction) => merge(errorMap, currentFunction(values));
  return flattenedArrayOfFunctions.reduce(reducer, {});
};

export const throwSubmissionErrorIfNecessary = (errorMap) => {
  if (!isEmpty(errorMap)) {
    throw new SubmissionError(errorMap);
  }
};

export const scrollToInvalidField = (errors) => {
  const key = errors.elementIdToScrollTo || Object.keys(errors)[0];
  const domElement = document.getElementById(key);
  return domElement && domElement.scrollIntoView();
};

export const validateValueIsDefined = (values, propertyName, fieldName, errorMap) => {
  if (!isDefined(values[propertyName])) {
    set(errorMap, `${fieldName}.${propertyName}`, 'Ce champ est obligatoire');
  }
};
