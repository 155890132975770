import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from '@material-ui/core/styles/withStyles';
import useTranslate from 'application/hooks/use-translate';
import ActivityOtherFilter from '../../../shared/core/activity/domain/ActivityOtherFilter';
import { POSSIBLE_ACTIVITIES_TYPE } from '../../../shared/collection/collectionConstants';
import {
  DATE_RANGE_FILTER,
  STATUS_FIELD,
  ADMIN_STATUS_FIELD,
  TYPE_FIELD,
  OTHER_FILTER,
  OUTSIDE_RAMQ_PATIENT_TYPE_FILTER
} from '../../../shared/collection/queryConstants';
import Permission from '../../../shared/domain/authorization/model/Permission';
import { POSSIBLE_STATUS, POSSIBLE_ADMIN_STATUS, POSSIBLE_OUTSIDE_RAMQ_PATIENT_TYPES } from '../ActForm/constants';
import messages from '../Activities/messages';
import ActivitiesSearchDateOptions from './ActivitiesSearchDateOptions';
import { can } from '../../act/shared/domain/roles/roles.utils';
import AdminStatus from '../../../shared/core/activity/domain/AdminStatus';

const styles = (theme) => ({
  OutsideRamqPatientType: {
    paddingLeft: theme.spacing(3)
  }
});

export function ActivitiesSearchOptions({ classes, searchActivitiesFilters, onChangeSearchFilter }) {
  const translate = useTranslate('activities-search-options');
  const currentUserIsAgent = can(Permission.DO_AGENT_ACTIONS);
  const gridItemWidth = currentUserIsAgent ? 3 : 4;

  return (
    <Grid container>
      <Grid item xs={12} sm={gridItemWidth}>
        <List
          subheader={<ListSubheader style={{ backgroundColor: 'white' }}>{translate('activity-type')}</ListSubheader>}
        >
          {POSSIBLE_ACTIVITIES_TYPE.map((activityType) => (
            <ListItem key={activityType} button onClick={() => onChangeSearchFilter({ type: activityType })}>
              <ListItemText primary={<FormattedMessage {...messages[activityType]} />} />
              <ListItemSecondaryAction>
                <Switch
                  onChange={() => onChangeSearchFilter({ type: activityType })}
                  checked={
                    !!searchActivitiesFilters[TYPE_FIELD] && searchActivitiesFilters[TYPE_FIELD].includes(activityType)
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} sm={gridItemWidth}>
        <List subheader={<ListSubheader style={{ backgroundColor: 'white' }}>{translate('status')}</ListSubheader>}>
          {POSSIBLE_STATUS.map((status) => (
            <ListItem key={status} button onClick={() => onChangeSearchFilter({ status })}>
              <ListItemText primary={<FormattedMessage {...messages[status]} />} />
              <ListItemSecondaryAction>
                <Switch
                  onChange={() => onChangeSearchFilter({ status })}
                  checked={
                    !!searchActivitiesFilters[STATUS_FIELD] && searchActivitiesFilters[STATUS_FIELD].includes(status)
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
      {currentUserIsAgent && (
        <Grid item xs={12} sm={gridItemWidth}>
          <List
            subheader={<ListSubheader style={{ backgroundColor: 'white' }}>{translate('status-admin')}</ListSubheader>}
          >
            {POSSIBLE_ADMIN_STATUS.map((adminStatus) => {
              const adminStatusIsChecked =
                !!searchActivitiesFilters[ADMIN_STATUS_FIELD] &&
                searchActivitiesFilters[ADMIN_STATUS_FIELD].includes(adminStatus);

              const isOutsideRamq = adminStatus === AdminStatus.OUTSIDE_RAMQ;

              return (
                <>
                  <ListItem key={adminStatus} button onClick={() => onChangeSearchFilter({ adminStatus })}>
                    <ListItemText primary={<FormattedMessage {...messages[adminStatus]} />} />
                    <ListItemSecondaryAction>
                      <Switch onChange={() => onChangeSearchFilter({ adminStatus })} checked={adminStatusIsChecked} />
                    </ListItemSecondaryAction>
                  </ListItem>

                  {adminStatusIsChecked &&
                    isOutsideRamq &&
                    POSSIBLE_OUTSIDE_RAMQ_PATIENT_TYPES.map((outsideRamqPatientType) => (
                      <ListItem
                        dense
                        key={outsideRamqPatientType}
                        button
                        onClick={() => onChangeSearchFilter({ outsideRamqPatientType })}
                        className={classes.OutsideRamqPatientType}
                      >
                        <ListItemText primary={<FormattedMessage {...messages[outsideRamqPatientType]} />} />
                        <ListItemSecondaryAction>
                          <Switch
                            onChange={() => onChangeSearchFilter({ outsideRamqPatientType })}
                            checked={
                              !!searchActivitiesFilters[OUTSIDE_RAMQ_PATIENT_TYPE_FILTER] &&
                              searchActivitiesFilters[OUTSIDE_RAMQ_PATIENT_TYPE_FILTER].includes(outsideRamqPatientType)
                            }
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </>
              );
            })}
          </List>
        </Grid>
      )}
      <Grid item xs={12} sm={gridItemWidth}>
        <ActivitiesSearchDateOptions
          dateRange={searchActivitiesFilters[DATE_RANGE_FILTER]}
          onChange={(dateRange) => onChangeSearchFilter({ dateRange })}
        />

        {currentUserIsAgent && (
          <List subheader={<ListSubheader style={{ backgroundColor: 'white' }}>{translate('other')}</ListSubheader>}>
            <ListItem
              key={ActivityOtherFilter.UNSYNCHRONIZED}
              button
              onClick={() => onChangeSearchFilter({ other: ActivityOtherFilter.UNSYNCHRONIZED })}
            >
              <ListItemText primary={<FormattedMessage {...messages[ActivityOtherFilter.UNSYNCHRONIZED]} />} />
              <ListItemSecondaryAction>
                <Switch
                  onChange={() => onChangeSearchFilter({ other: ActivityOtherFilter.UNSYNCHRONIZED })}
                  checked={
                    !!searchActivitiesFilters[OTHER_FILTER] &&
                    searchActivitiesFilters[OTHER_FILTER].includes(ActivityOtherFilter.UNSYNCHRONIZED)
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        )}
      </Grid>
    </Grid>
  );
}

ActivitiesSearchOptions.propTypes = {
  onChangeSearchFilter: PropTypes.func.isRequired,
  searchActivitiesFilters: PropTypes.object.isRequired
};

export default withStyles(styles)(ActivitiesSearchOptions);
