import { ActOptimisationSpecification } from '../ActOptimisationSpecification';
import ActBillingContext from '../../optimisation/ActBillingContext';
import ActCodes from '../../../activity/act/model/ActCodes';

export class ActContainCode extends ActOptimisationSpecification {
  // eslint-disable-next-line no-unused-vars
  constructor(private code: string) {
    super();
  }

  isSatisfiedBy(context: ActBillingContext): boolean {
    const actCodes = new ActCodes(context.act.codes || []);

    return actCodes.isContainsSome(this.code);
  }
}

export const actContainCode = (code: string) => new ActContainCode(code);
export const actDoesNotContainCode = (code: string) => new ActContainCode(code).not();
