import PropTypes from 'prop-types';
import React from 'react';
import { findIndexInFieldValue, toggleFieldValue } from '../../../utils/reduxFormArrayField';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';

const isChecked = (input, value) => findIndexInFieldValue(input, value) !== -1;
function MultiSelectCheckboxGroup({ id, input, list, labelProp, valueProp, checkboxClasses, onChange }) {
  return (
    <CheckboxGroup
      id={id}
      checkboxClasses={checkboxClasses}
      checkboxes={list}
      labelProp={labelProp}
      valueProp={valueProp}
      onCheckboxSelected={(value) => {
        const updatedValues = toggleFieldValue(input, value);
        if (!onChange) return;
        onChange(updatedValues);
      }}
      isChecked={(checkboxObject) => isChecked(input, checkboxObject[valueProp])}
    />
  );
}

MultiSelectCheckboxGroup.propTypes = {
  input: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  labelProp: PropTypes.string,
  valueProp: PropTypes.string,
  checkboxClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  onChange: PropTypes.func
};

MultiSelectCheckboxGroup.defaultProps = {
  checkboxClasses: undefined,
  id: '',
  labelProp: 'label',
  valueProp: 'value',
  onChange: undefined
};

export default MultiSelectCheckboxGroup;
