/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TRANSPORT_FEES_TYPES from '../../../../shared/ramq/domainValues/transportFeesTypes';
import TRANSPORT_TYPES from '../../../../shared/ramq/domainValues/transportTypes';
import getObjectKeyByValue from '../../../../shared/utils/objectManipulation';
import messages from '../../../containers/TravelExpenses/messages';
import InformationBox from '../../InformationBox/InformationBox';
import TravelInformationBoxSelectValue from './TravelInformationBoxSelectValue';
import TravelInformationBoxSingleValue from './TravelInformationBoxSingleValue';

function TransportFeeSummary({ fee }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography align="center" variant="button" style={{ marginBottom: 8 }}>
        Transport
      </Typography>
      <Grid container>
        <TravelInformationBoxSelectValue label="Type de transport" map={TRANSPORT_TYPES} value={fee.transportType} />
        <TravelInformationBoxSingleValue label="Nb. Kilomètres aller" value={fee.goingKilometers} />
        {fee.additionalTransportFees && fee.additionalTransportFees.length > 0 && (
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Frais additionnels</Typography>
            <Grid container>
              {fee.additionalTransportFees.map((additionalTransportFee, index) => (
                <Grid item xs={6} key={index}>
                  <InformationBox
                    label={
                      additionalTransportFee.type ? (
                        <FormattedMessage
                          {...messages[getObjectKeyByValue(TRANSPORT_FEES_TYPES, additionalTransportFee.type)]}
                        />
                      ) : (
                        ''
                      )
                    }
                    content={
                      additionalTransportFee.amount ? <Typography>{additionalTransportFee.amount}</Typography> : ''
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

TransportFeeSummary.propTypes = {
  fee: PropTypes.object.isRequired
};

export default TransportFeeSummary;
