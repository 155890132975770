import { merge } from 'lodash';
import tooManyDurationSupplementValidation from 'app/validations/act/codes/tooManyDurationSupplementValidation';
import { getAdditionalFieldsRequiredForCode } from '../../../codeSpecificLogic/ui/codeSpecificUILogic';
import {
  createErrorMapWithEveryValidationFunction,
  fieldIsPresentValidation,
  requiredArrayFieldValidations,
  requiredFieldValidations
} from '../../common/reduxFormValidation';
import { basicValidations } from '../actValidation';
import codeActivityAreaValidation from '../codes/codeActivityAreaValidation';
import codePlaceValidation from '../codes/codePlaceValidation';
import { excludeR1SurgerySupplements } from '../codes/excludeR1SurgerySupplements';
import exclusiveCodesInR2ForAnesthesiologistOnly from '../codes/exclusiveCodesInR2ForAnesthesiologistOnly';
import namIsValid, { isPatientTypeNam } from '../nam/namValidation';
import getAdditionalValidationsRequiredForCode from '../../../codeSpecificLogic/validations/codeSpecificValidations';
import PATIENT_WITHOUT_ID_TYPES from '../../../../shared/ramq/domainValues/situationTypes';
import outsideRamqValidations from '../outsideRamq/outsideRamqValidations';
import codeSupplementValidation from '../codes/codeSupplementValidation';
import mutuallyExclusivePainCodes from '../codes/mutuallyExclusivePainCodes';
import role2Role3ExclusiveCodesValidation from '../codes/role2SameTimeAsRole3Validation';
import role2PdtAndSurgeryExclusiveCodesValidation from '../codes/role2PdtAndSurgeryExclusiveCodesValidation';
import guardCodeCannotBeBilledWithAPatientNamValidation from '../codes/guardCodeCannotBeBilledWithAPatientNamValidation';
import unitSupportPlanCodeCannotBeBilledWithAPatientNamValidation from '../codes/unitSupportPlanCodeCannotBeBilledWithAPatientNamValidation';
import exceptionnalSurgeryMutuallyExclusiveWithAwaitingSurgery from '../codes/exceptionnalSurgeryMutuallyExclusiveWithAwaitingSurgery';
import differentRoleCodesValidation from '../codes/differentRoleCodesValidation';
import dailyUnitCarePackageValidation from '../codes/dailyUnitCarePackageValidation';
import greaterThan4HoursValidation from '../codes/greaterThan4HoursValidation';
import emergencyIsNotGuardValidation from '../contextElements/emergencyIsNotGuardValidation';
import endTimeForSurgeryCodesBilledBySurgeonValidation from '../hours/endTimeForSurgeryCodesBilledBySurgeonValidation';
import teachingCodesValidation from '../codes/teachingCodesValidation';

export const actSendValidations = ({ act, user }) =>
  createErrorMapWithEveryValidationFunction(
    act,
    basicValidations,
    requiredFieldValidations(['start']),
    requiredArrayFieldValidations(['codes']),
    requiredDetailsValidations,
    namAbsentOrCompleteValidation,
    exclusiveCodesInR2ForAnesthesiologistOnly(user),
    excludeR1SurgerySupplements,
    patientInformationValidations,
    codePlaceValidation,
    codeSupplementValidation,
    codeActivityAreaValidation,
    outsideRamqValidations(user),
    mutuallyExclusivePainCodes,
    role2Role3ExclusiveCodesValidation,
    role2PdtAndSurgeryExclusiveCodesValidation,
    guardCodeCannotBeBilledWithAPatientNamValidation,
    unitSupportPlanCodeCannotBeBilledWithAPatientNamValidation,
    exceptionnalSurgeryMutuallyExclusiveWithAwaitingSurgery,
    differentRoleCodesValidation,
    dailyUnitCarePackageValidation,
    greaterThan4HoursValidation,
    emergencyIsNotGuardValidation,
    endTimeForSurgeryCodesBilledBySurgeonValidation,
    tooManyDurationSupplementValidation,
    teachingCodesValidation
  );

const namAbsentOrCompleteValidation = (act) =>
  !act.nam || namIsValid(act.nam) || !isPatientTypeNam(act) ? {} : { nam: 'Le nam est invalide' };

const requiredDetailsValidations = (act) => {
  const codeList = act.codes || [];
  const missingFieldsValidations = codeList.map(
    getMissingFieldsValidations(getAdditionalFieldsRequiredForCode, codeList, act)
  );
  const additionalValidations = codeList.map((code) => getAdditionalValidationsRequiredForCode(code, act));

  return createErrorMapWithEveryValidationFunction(act, missingFieldsValidations.concat(additionalValidations));
};

const patientInformationValidations = (act) => {
  if (act.patientInformation && act.patientInformation.type === PATIENT_WITHOUT_ID_TYPES.childUnderOneYear) {
    return createErrorMapWithEveryValidationFunction(
      act,
      requiredFieldValidations(['patientInformation.gender']),
      requiredFieldValidations(['patientInformation.birthDate'])
    );
  }

  return {};
};

const getMissingFieldsValidations = (validation, list, act) => (item, index) =>
  validation(item, list, act)
    .filter((field) => !field.notRequired)
    .map((field) => field.getName(index))
    .map(fieldIsPresentValidation);

export const combineErrors = (errorMap, error) => merge(errorMap, error);

export default actSendValidations;
