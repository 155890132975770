import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import React from 'react';
import CollapseContent from './CollapserContent';
import CollapseHeader from './CollapserHeader';

export class Collapser extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.initiallyOpen
    };
  }

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const headerChildren = React.Children.toArray(this.props.children).find(
      (children) => children.type === CollapseHeader
    );
    const enhancedHeaderChildren = React.cloneElement(headerChildren, {
      onClick: this.handleClick,
      isOpen: this.state.open
    });
    const contentChildren = React.Children.toArray(this.props.children).find(
      (children) => children.type === CollapseContent
    );
    return (
      <>
        {enhancedHeaderChildren}
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {contentChildren}
        </Collapse>
      </>
    );
  }
}

Collapser.defaultProps = {
  initiallyOpen: true
};

Collapser.propTypes = {
  initiallyOpen: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default Collapser;
