import QueryString from 'qs';
import RALPH_API_BASE_URL from './ralphApi';
import request from './apiUtils';

export default class PdfGenerationAPI {
  static async getPaymentRequestFormPdfUrl(firebaseIds) {
    const queryParams = QueryString.stringify({ firebaseIds }, { skipNulls: true, indices: false });
    const PAYMENT_REQUEST_FORM_URL = `${RALPH_API_BASE_URL}/activities/payment-request-form?${queryParams}`;
    const response = (await request(PAYMENT_REQUEST_FORM_URL, { method: 'GET' })) as Response;
    const json = (await response.json()) as { location: string };

    return json.location;
  }
}
