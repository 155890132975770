import {
  ENCEINTE_COVID,
  ISOLEMENT_COVID,
  MD_INFECTE_COVID,
  MD_REAFFECTE_AILLEURS_COVID,
  MD_REAFFECTE_COVID_ETABLISS,
  SNT_NON_AMBULATOIRE_COVID,
  TELEMEDECINE_AILLEURS_COVID,
  TELEMEDECINE_CABINET_COVID
} from '../../ramq/contextElements/legacyCovidContextElements';

export const AM_PERDIEM = 'am';
export const PM_PERDIEM = 'pm';

export const EVENING_PERDIEM = 'soir';

export const DEFAULT_MIXTE_PREFIX = '065';
export const ANEST_MIXTE_PREFIX = '084';

export const DEFAULT_CLINICAL_ACTIVITIES_SUFFIX = '030';
export const CMS_MEDICAL_ACTIVITIES_SUFFIX = '356';
export const CMS_MEDICAL_ACTIVITIES_WITH_TELEMEDICINE_SUFFIX = '378';
export const CMS_MEDICAL_SERVICES_MIXTE_CODES = ['084356', '065356'];
export const CLINICAL_ACTIVITIES_WITH_SUPERVISION_SUFFIX = '056';
export const COVID_CONTEXT_ELEMENT_SUFFIX_MAPPING = {
  [SNT_NON_AMBULATOIRE_COVID]: '320',
  [MD_REAFFECTE_COVID_ETABLISS]: '321',
  [ENCEINTE_COVID]: '335',
  [TELEMEDECINE_CABINET_COVID]: '321',
  [TELEMEDECINE_AILLEURS_COVID]: '328',
  [MD_INFECTE_COVID]: '325',
  [ISOLEMENT_COVID]: '326',
  [MD_REAFFECTE_AILLEURS_COVID]: '328'
};

// eslint-disable-next-line no-shadow
export enum Perdiem {
  AM = 'am',
  PM = 'pm'
}
