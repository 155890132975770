/* eslint-disable max-len */
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { displayPlace } from '../../Form/utils/fieldTransformations';
import isDefined from '../../../../shared/utils/isDefined';

const styles = (theme) => ({
  selectedPlaceText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  selectedPlaceName: {
    color: theme.palette.common.white
  },
  supervisorName: {
    fontSize: '0.5rem',
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    textAlign: 'left'
  },
  dark: {
    '& $selectedPlaceName, & $supervisorName': {
      color: theme.palette.text.primary
    }
  },
  miniChip: {
    height: '15px',
    fontSize: '0.5rem',
    marginLeft: '1em'
  }
});

const hasSignatoryName = (signatory = {}) => isDefined(signatory.firstName) || isDefined(signatory.lastName);
const renderBillByAct = (place) => (isDefined(place) && place.billByAct ? "Facturation à l'acte" : '');
const renderSeparator = (place) =>
  isDefined(place) && place.billByAct && hasSignatoryName(place.signatory) ? ' - ' : '';
const renderName = (name) => (isDefined(name) ? name : '');
const renderSignatoryName = (place) =>
  isDefined(place) && hasSignatoryName(place.signatory)
    ? `${renderName(place.signatory.firstName)} ${renderName(place.signatory.lastName)}`
    : '';

function PlaceWithSignatoryName({ place, dark, principal, classes }) {
  return (
    <span className={classNames(classes.selectedPlaceText, { [classes.dark]: dark })}>
      <Typography variant="button" className={classes.selectedPlaceName}>
        {displayPlace(place)}
      </Typography>
      <Typography variant="caption" className={classes.supervisorName}>
        {`${renderBillByAct(place)}${renderSeparator(place)}${renderSignatoryName(place)}`}
        {principal && <Chip color="secondary" label="Principal" className={classes.miniChip} />}
      </Typography>
    </span>
  );
}

PlaceWithSignatoryName.propTypes = {
  place: PropTypes.object.isRequired,
  dark: PropTypes.bool,
  principal: PropTypes.bool
};

PlaceWithSignatoryName.defaultProps = {
  dark: false,
  principal: false
};

export default withStyles(styles)(PlaceWithSignatoryName);
