import moment from 'moment-timezone';
import upperFirst from 'lodash/upperFirst';
import { CAPQ_CODES } from 'shared/periods/lumpSum/timeSlot/timeSlotCalculators/CAPQTimeSlotCalculator';
import TimeSlotCalculatorFactory from 'shared/periods/lumpSum/timeSlot/timeSlotCalculators/TimeSlotCalculatorFactory';

const COMPLEMENTARY_INFORMATION = 'Autorisé à fournir des soins les week-ends et les jours fériés';

export default (documentToSave) => {
  const { complementaryInformation, days = [] } = documentToSave;

  let newComplementaryInformation;

  if (days.length === 0) return documentToSave;

  if (complementaryInformation && complementaryInformation.length > 0) {
    newComplementaryInformation = complementaryInformation.replace(COMPLEMENTARY_INFORMATION, '');
  }

  if (days.some(({ code }) => code === '002064')) {
    newComplementaryInformation = `${COMPLEMENTARY_INFORMATION} ${newComplementaryInformation || ''}`;
  }

  if (newComplementaryInformation && newComplementaryInformation.length === 0) {
    newComplementaryInformation = null;
  }

  let refCursor = 1;

  for (const day of days) {
    if (!day.code || !day.start || !day.end) continue;

    const timeSlots = TimeSlotCalculatorFactory.create(day.code).calculate(day);

    const currentDayFormattedDate = upperFirst(moment(day.start).format('dddd D MMMM'));

    const timeSlotsDescription = timeSlots.reduce((accumulator, { details, timePeriod }) => {
      if (!details.some(({ code }) => CAPQ_CODES.includes(code))) {
        refCursor += 3;

        return accumulator;
      }

      const timeSlotDescription = `REFRE ${refCursor} : ${moment(timePeriod.start).format('HH:mm')}`;

      refCursor += 3;

      return [...accumulator, timeSlotDescription];
    }, []);

    if (timeSlotsDescription.length === 0) continue;

    const currentDayDescription = `${currentDayFormattedDate} : ${timeSlotsDescription.join(', ')}`;

    if (!newComplementaryInformation?.includes(currentDayDescription)) {
      newComplementaryInformation = `${newComplementaryInformation ? `${newComplementaryInformation} ` : ''}${currentDayDescription};`;
    }
  }

  if (complementaryInformation !== newComplementaryInformation) {
    return {
      ...documentToSave,
      complementaryInformation: newComplementaryInformation?.trim()
    };
  }

  return documentToSave;
};
