import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DoctorFavoritesTab from '../../components/DoctorFavoritesTab/DoctorFavoritesTab';
import { selectDoctorsPreferencesFromUser } from '../../../adapters/selectors';

export const styles = () => ({
  addDoctorButton: {
    bottom: 75,
    right: 25,
    position: 'absolute'
  },
  paper: {
    minHeight: '90vh',
    height: '100%',
    position: 'relative'
  },
  secondaryInfo: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export function DoctorsDialogWithoutDoctorRegistry({
  fullScreen,
  open,
  onClose,
  classes,
  value,
  onItemSelected,
  doctorsPreferences
}) {
  return (
    <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DoctorFavoritesTab
        withTitle
        onClose={onClose}
        value={value}
        onItemSelected={onItemSelected}
        doctorsPreferences={doctorsPreferences}
      />
    </Dialog>
  );
}

DoctorsDialogWithoutDoctorRegistry.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([''])]),
  doctorsPreferences: PropTypes.array.isRequired
};

DoctorsDialogWithoutDoctorRegistry.defaultProps = {
  value: ''
};

export const mapStateToProps = createStructuredSelector({
  doctorsPreferences: selectDoctorsPreferencesFromUser()
});

export default compose(
  connect(mapStateToProps),
  withMobileDialog(),
  withStyles(styles)
)(DoctorsDialogWithoutDoctorRegistry);
