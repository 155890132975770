/**
 * Reference: https://medyx.atlassian.net/browse/MED-1773
 *
 * Note that this business rule is no longer in force as of June 1st, 2021
 * http://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/manuels/syra/medecins-specialistes/
 * 158-brochure-5-specialistes/GuideSPECrmx.pdf
 */

import MixteDaysComputator from './MixteDaysComputator';
import { balancePerdiems, makeDayLineFromMixte } from './MixteDaysComputator.legacyUtils';
import { ComputedMixteDay } from './MixteDaysComputator.types';

export default class LegacyMixteDaysComputator extends MixteDaysComputator {
  public compute(): ComputedMixteDay[] {
    const mixteDays = this.mixteDays.map(makeDayLineFromMixte);

    return balancePerdiems(mixteDays);
  }
}
