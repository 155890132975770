import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  detailedViewItem: {
    'flex': 1,
    'padding': `0 0 ${theme.spacing(1)}px 0`,
    'alignItems': 'center',
    '&:last-child': {
      paddingBottom: 0
    }
  },
  itemTextRootOverride: {
    display: 'flex'
  }
});

export function DetailsItem({ icon, text, dense, className, classes }) {
  return (
    <ListItem dense={dense} component="div" className={classNames(classes.detailedViewItem, className)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} classes={{ primary: classes.itemTextRootOverride }} />
    </ListItem>
  );
}

DetailsItem.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  dense: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

DetailsItem.defaultProps = {
  dense: false,
  className: {}
};

export default withStyles(styles)(DetailsItem);
