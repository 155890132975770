import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  selector: {
    width: '100%',
    height: '100%',
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(1)
  }
});

function TimeItem({ size, isSelected, onClick, value, classes }) {
  return (
    <Grid item xs={size} key={value}>
      <Button
        variant={isSelected ? 'contained' : 'text'}
        className={classes.selector}
        onClick={() => onClick(value)}
        color={isSelected ? 'primary' : 'default'}
      >
        {value}
      </Button>
    </Grid>
  );
}

TimeItem.propTypes = {
  size: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default withStyles(styles)(TimeItem);
