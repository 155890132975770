import { MULTI_PLACE_GUARD_ACT_CODES } from '../../../../../shared/act/domain/multiPlaceGuardCode';
import additionalPlace from '../../../requiredDetails/AdditionalPlace/additionalPlace';

const addPlaceReferenceForMultiPlaceGuard = (currentCode) => {
  if (MULTI_PLACE_GUARD_ACT_CODES.includes(currentCode.code)) {
    return additionalPlace.associatedFields;
  }

  return [];
};

export default addPlaceReferenceForMultiPlaceGuard;
