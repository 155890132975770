import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import uniqBy from 'lodash/uniqBy';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import FormRow from '../../FormRow/FormRow';
import ContextElementSearchDialog from '../../../../favorite/contextElement/ui/ContextElementSearchDialog/ContextElementSearchDialog';
import ContextElement from '../../../../favorite/contextElement/domain/ContextElement';

export const styles = () =>
  ({
    rowWithDeleteButton: {
      flexWrap: 'unset'
    },
    formRow: {
      marginBottom: 0
    }
  }) as const;

export interface Props extends WithStyles<typeof styles>, WrappedFieldProps {
  // eslint-disable-next-line no-unused-vars
  buttonRender: (onClick: () => void) => JSX.Element;
  label?: string;
}

interface State {
  contextElementSearchDialogOpen: boolean;
}

export class RamqContextElementsField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      contextElementSearchDialogOpen: false
    };
  }

  handleOnCloseSearchDialog = () => {
    this.setState({
      contextElementSearchDialogOpen: false
    });
  };

  handleOnConfirmSearchDialog = (contextElements: ContextElement[]) => {
    const { input } = this.props;

    const ramqContextElementsInputValue = this.getRamqContextElementInputValue();

    const updatedRamqContextElementsInputValue = uniqBy([...ramqContextElementsInputValue, ...contextElements], 'code');

    input.onChange(updatedRamqContextElementsInputValue);

    this.handleOnCloseSearchDialog();
  };

  handleOnClick = () => {
    this.setState({ contextElementSearchDialogOpen: true });
  };

  private getRamqContextElementInputValue(): ContextElement[] {
    const { input } = this.props;

    const ramqContextElementsInputValue = input.value;

    if (ramqContextElementsInputValue === '') {
      return [];
    }

    return ramqContextElementsInputValue;
  }

  render() {
    const { label, meta, buttonRender, classes } = this.props;
    const { contextElementSearchDialogOpen } = this.state;

    return (
      <>
        {label && <InputLabel>{label}</InputLabel>}
        <FormRow className={classes.formRow}>
          <>
            {buttonRender(this.handleOnClick)}
            <ContextElementSearchDialog
              open={contextElementSearchDialogOpen}
              onConfirm={this.handleOnConfirmSearchDialog}
              onClose={this.handleOnCloseSearchDialog}
            />
          </>
        </FormRow>
        {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
      </>
    );
  }
}

export default withStyles(styles)(RamqContextElementsField);
