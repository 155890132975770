import moment from 'moment-timezone';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const newUnixDateAtStartOfDay = () => moment().startOf('day').valueOf();
export const newUnixDate = () => moment().valueOf();
export const newFormattedDate = () => moment().format(DEFAULT_DATE_FORMAT);
export const formatUnixToDate = (unixDate) => (unixDate ? moment(unixDate).format(DEFAULT_DATE_FORMAT) : '');
export const formatUnixToShortDate = (unixDate) => (unixDate ? moment(unixDate).format('MM-DD') : '');
export const defaultFormatMomentDate = (date) => moment(date).format(DEFAULT_DATE_FORMAT);
export const formatUnixToFullDateAndTime = (value) => `${formatUnixToDate(value)} ${formatUnixToTimeValue(value)}`;
export const getMomentDateFromUnix = (unixDate) => (unixDate ? moment(parseInt(unixDate, 10)) : moment());
export const formatUnixToTimeValue = (unixTime) => (unixTime ? getMomentDateFromUnix(unixTime).format('HH:mm') : '');
export const formatDateStringToUnix = (dateString) => (dateString ? moment(dateString).valueOf() : '');

export const formatUnixToTimeWithHourPrefixValue = (unixTime) =>
  unixTime ? getMomentDateFromUnix(unixTime).format('HH[h]mm') : '';

const formatDateToNumberIfNeeded = (date) => {
  // eslint-disable-next-line no-restricted-globals
  if (moment.isMoment(date) === false && isNaN(date) === false) {
    return parseInt(date, 10);
  }

  return date;
};

export const getStartOfDay = (date) => moment(formatDateToNumberIfNeeded(date)).startOf('day');
export const getEndOfDay = (date) => moment(formatDateToNumberIfNeeded(date)).endOf('day');

export const getDayOfMonth = (date) => moment(date).date();
export const isWeekEnd = (date) => {
  const currentDayNumberInWeek = moment(date).day();
  return currentDayNumberInWeek === 0 || currentDayNumberInWeek === 6;
};
export const getUnixStartOfDay = (date) => getStartOfDay(date).valueOf();
export const getUnixEndOfDay = (date) => getEndOfDay(date).valueOf();
export const tenMinutesFromNowExpiration = () => moment().add(10, 'minutes');
export const getStrictHour = (momentDate, desiredHour) =>
  moment().set({
    year: momentDate.get('year'),
    month: momentDate.get('month'),
    date: momentDate.get('date'),
    hour: desiredHour,
    minute: 0,
    second: 0,
    millisecond: 0
  });
export const adjustUnixWithTimeObject = (timestamp, timeObject) => {
  const currentDate = timestamp ? getMomentDateFromUnix(timestamp) : moment();
  const currentDateWithStrictHour = getStrictHour(currentDate, parseInt(timeObject.hour, 10));

  currentDateWithStrictHour.minutes(parseInt(timeObject.minutes, 10));

  return currentDateWithStrictHour.valueOf();
};

export const setMomentInEastern = () => {
  moment.tz.setDefault('America/Toronto');
};

export const datesAreTheSameDay = (momentDate1, momentDate2) => momentDate1.isSame(momentDate2, 'date');

export const changeDateWithoutTime = (dateTimeToChange, date) => {
  const momentDateTimeToChange = moment(dateTimeToChange);
  const momentDate = moment(date);

  momentDateTimeToChange.set('year', momentDate.get('year'));
  momentDateTimeToChange.set('month', momentDate.get('month'));
  momentDateTimeToChange.set('date', momentDate.get('date'));

  return momentDateTimeToChange.toDate();
};

export const formatDecimalHourToHourMinuteString = (decimalHour) => {
  const hours = `0${Math.floor(decimalHour) % 24}`.slice(-2);
  const minutes = ((decimalHour % 1) * 60).toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const convertToMoment = (timestamp) => (timestamp === undefined ? timestamp : moment(Number(timestamp)));
