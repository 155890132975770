import React, { useState, useCallback, useMemo } from 'react';
import { ToastProps } from 'application/components/toast/toast';
import Toasts from 'application/components/toast/toasts';

export const ToastContext = React.createContext<{
  addToast: (toast: Omit<ToastProps, 'id' | 'onDismiss'>) => void;
  removeToast: (id: number) => void;
} | null>(null);

let id = 1;

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState<Omit<ToastProps, 'onDismiss'>[]>([]);

  const addToast = useCallback(
    (toast: Omit<ToastProps, 'id' | 'onDismiss'>) => {
      if (toasts.some((t) => t.message === toast.message)) return;

      setToasts((toasts) => [
        ...toasts,
        {
          id: id++,
          ...toast
        }
      ]);
    },
    [setToasts, toasts]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
    },
    [setToasts]
  );

  const providerValue = useMemo(() => ({ addToast, removeToast }), [addToast, removeToast]);

  return (
    <ToastContext.Provider value={providerValue}>
      <Toasts toasts={toasts} onDismiss={removeToast} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
