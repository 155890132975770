/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectSelectedDate } from '../../../../containers/ActForm/selectors';
import { DateField } from '../../Fields';

export class DefaultActEndDateField extends React.Component {
  componentDidMount() {
    const { date } = this.props;
    this.props.input.onChange(date);
  }

  render() {
    const { label, meta, input } = this.props;
    return <DateField input={input} meta={meta} label={label} />;
  }
}

DefaultActEndDateField.propTypes = {
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  date: PropTypes.number.isRequired
};

export const mapStateToProps = createStructuredSelector({
  date: selectSelectedDate()
});

export default compose(connect(mapStateToProps, null))(DefaultActEndDateField);
