/* eslint-disable react/no-array-index-key */

import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToDate } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import StatementTable from '../../../../../components/Table/Statement/StatementTable/StatementTable';
import { StatementLine } from '../sections/StatementDoctorInfo';

const measuringElementColumn = {
  name: 'Élément de mesure / Valeur',
  primaryText: (line) => `${line.measuringElementLabel} ${line.measuringElementLabel2}`,
  secondaryText: (line) => line.measuringElementValue
};

const periodColumn = {
  name: 'Début / Fin',
  primaryText: (line) => formatUnixToDate(line.incitativePeriodStartDate),
  secondaryText: (line) => formatUnixToDate(line.incitativePeriodEndDate)
};

const montantCodeExplicatifColumn = {
  name: 'Montant / Codes Explicatifs',
  align: 'right',
  primaryText: (line) => line.paidAmount !== '     S/O ' && line.paidAmount && currencyFormat(line.paidAmount),
  secondaryText: (line) =>
    line.explanationCode1 !== '   ' &&
    line.explanationCode1 !== '000' &&
    `${line.explanationCode1} ${line.explanationCode2}`
};

export function StatementIncentive({ content }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={4} data-testid="statementIncentive-section">
        <StatementSection
          primaryLine={`${content.modTransactionCode} - ${content.modLineTransactionLabel}`}
          secondaryLines={[
            StatementLine(
              'Période:',
              `${formatUnixToDate(content.periodStart)}
                  au ${formatUnixToDate(content.periodEnd)}`
            ),
            StatementLine('N. requête:', content.requestNumber),
            StatementLine('Réception:', formatUnixToDate(content.dateReceived)),
            content.explanationCode1 !== '000' &&
              `codes explicatifs: ${content.explanationCode1} ${content.explanationCode2}`
          ]}
        />
      </Grid>
      {!isEmpty(content.extraLines) && (
        <Grid item xs={12} sm={8} data-testid="statementIncentive-table">
          <StatementTable
            title=""
            columns={[measuringElementColumn, periodColumn, montantCodeExplicatifColumn]}
            data={content.extraLines}
          />
        </Grid>
      )}
    </Grid>
  );
}

StatementIncentive.propTypes = {
  content: PropTypes.object.isRequired
};
export default StatementIncentive;
