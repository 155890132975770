import { useQuery } from '@tanstack/react-query';
import Chart from 'application/components/chart/chart';
import DatePicker from 'application/components/form/date-picker';
import useTranslate from 'application/hooks/use-translate';
import RecentUsers from 'application/pages/administration/stats/recent-users';
import StatisticsService from 'application/services/statistics-service';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

interface UsersGrowthStats {
  from: string | null;
  to: string | null;
  total: number | null;
}

const Configurator = () => null;

const Renderer = () => {
  const translate = useTranslate('pages.administration.stats');
  const [userGrowthStart, setUserGrowthStart] = useState<Date | null>(null);
  const [userGrowthEnd, setUserGrowthEnd] = useState<Date | null>(null);

  const { data, isFetching } = useQuery({
    queryKey: ['users-growth-statistics', userGrowthStart, userGrowthEnd],
    queryFn: () => StatisticsService.getUsersGrowth(userGrowthStart, userGrowthEnd)
  });

  const usersGrowthStats = useMemo(() => {
    const stats: UsersGrowthStats = {
      from: null,
      to: null,
      total: null
    };

    if (!data) return stats;

    stats.from = moment(data[0].month).format('MMMM YYYY');
    stats.to = moment(data[data.length - 1].month).format('MMMM YYYY');
    stats.total = data[data.length - 1].total;

    return stats;
  }, [data]);

  useEffect(() => {
    if (data && !userGrowthStart) {
      setUserGrowthStart(moment(data[0].month).toDate());
    }
  }, [data, userGrowthStart]);

  useEffect(() => {
    if (data && !userGrowthEnd) {
      setUserGrowthEnd(moment(data[data.length - 1].month).toDate());
    }
  }, [data, userGrowthEnd]);

  return (
    <>
      <div className="flex">
        <Chart
          loading={isFetching}
          type="line"
          title={translate('users-growth')}
          data={
            data?.map((row) => ({
              label: moment(row.month).format('MMMM YY'),
              value: row.total
            })) ?? []
          }
        />
        <div className="flex flex-col justify-between">
          <DatePicker
            type="month"
            label={translate('start')}
            onChange={(start) => setUserGrowthStart(start)}
            value={userGrowthStart}
          />
          <DatePicker
            type="month"
            label={translate('end')}
            onChange={(end) => setUserGrowthEnd(end)}
            value={userGrowthEnd}
          />
          <button
            type="button"
            className="btn btn-primary mt-8"
            onClick={() => {
              setUserGrowthStart(null);
              setUserGrowthEnd(null);
            }}
            disabled={!userGrowthStart && !userGrowthEnd}
          >
            {translate('reset')}
          </button>
          <div className="stats shadow">
            <div className="stat">
              <div className="stat-title">Total</div>
              <div className="stat-value">{usersGrowthStats?.total}</div>
              <div className="stat-desc">{`${usersGrowthStats?.from} - ${usersGrowthStats?.to}`}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 w-full">
        <RecentUsers />
      </div>
    </>
  );
};

export default {
  Configurator,
  Renderer
};
