import { WithStyles } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';
import { compose } from 'redux';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import Activity from '../../../../shared/domain/activity/global/model/Activity';
import { computeExpectedAmountForActivity } from '../../../../shared/amountComputation/computeExpectedAmount';
import { currencyFormat } from '../../../../utils/wordFormatUtilities';

export const styles = (theme) =>
  ({
    container: {
      display: 'flex',
      gap: '5px'
    },
    red: {
      color: theme.palette.red[300]
    },
    strikethrough: {
      textDecoration: 'line-through'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  activity: Activity;
}

export const ActivityAmount: React.FunctionComponent<Props> = ({ classes, activity }) => {
  const formattedPaidAmount = currencyFormat(activity.paidAmount || '');
  const formattedActivityAmount = currencyFormat(computeExpectedAmountForActivity(activity));

  return (
    <div className={classes.container}>
      {activity.paidAmount && (
        <Typography variant="caption">
          <div data-testid="paid-amount" className={classes.red}>
            {formattedPaidAmount}
          </div>
        </Typography>
      )}
      <Typography variant="caption">
        <div
          data-testid="activity-amount"
          className={classNames({
            [classes.strikethrough]: activity.paidAmount && activity.status === ActivityStatus.PAID
          })}
        >
          {formattedActivityAmount}
        </div>
      </Typography>
    </div>
  );
};

export default compose(withStyles(styles))(ActivityAmount);
