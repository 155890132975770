import { firestore } from '../../../server/Firebase';
import { ACTIVITIES } from '../../../shared/collection/collectionConstants';

const getActivityById = async (id) => {
  const document = await firestore.collection(ACTIVITIES).doc(id).get();
  if (!document.exists) {
    throw new Error("The document with the provided id doesn't exists");
  }
  return document.data();
};

export default getActivityById;
