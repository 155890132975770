import { call, put, select } from 'redux-saga/effects';
import { values } from 'lodash';
import { firestore } from '../../../../server/Firebase';
import { selectUserProfileInContext } from '../../../containers/User/selectors';
import UserSpecialtyActCodeListService from '../../../../shared/act/domain/service/UserSpecialtyActCodeListService';
import SpecialtyActCodeListRepository from '../../../../shared/act/infra/SpecialtyActCodeListRepository';

export function* fetchSpecialtyPresetActCodes(successAction, failureAction) {
  try {
    const currentUserProfile = yield select(selectUserProfileInContext());

    const userSpecialtyActCodeListService = new UserSpecialtyActCodeListService(
      new SpecialtyActCodeListRepository(firestore)
    );

    const collection = yield call([userSpecialtyActCodeListService, 'getPresetSpecialtyList'], currentUserProfile);
    yield put(successAction(convertFirebaseMapIntoCollection(collection)));
  } catch (e) {
    yield put(failureAction(e));
  }
}

export function* fetchSpecialtyFavoritesActCodes(successAction, failureAction) {
  try {
    const currentUserProfile = yield select(selectUserProfileInContext());

    const specialtyListRepository = new UserSpecialtyActCodeListService(new SpecialtyActCodeListRepository(firestore));

    const collection = yield call([specialtyListRepository, 'getFavoritesSpecialtyList'], currentUserProfile);
    yield put(successAction(convertFirebaseMapIntoCollection(collection)));
  } catch (e) {
    yield put(failureAction(e));
  }
}

function convertFirebaseMapIntoCollection(firebaseMap) {
  return values(
    firebaseMap.map((category) => ({
      ...category,
      items: values(category.items)
    }))
  );
}
