import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import shortid from 'shortid';
import isValidNumber from '../../../../../shared/utils/isValidNumber';
import { selectStartTime } from '../../../../containers/ActForm/selectors';
import TimeSelectorDialog from '../../../../containers/ActForm/TimeSelectorDialog/TimeSelectorDialog';
import DialogTrigger from '../../../Dialogs/DialogTrigger/DialogTrigger';
import { pushNewValue, updateValue } from '../../../utils/reduxFormArrayField';
import FormRow from '../../FormRow/FormRow';
import TimeSyncedWithActStartTime from '../TimeSyncedWithActStartTime/TimeSyncedWithActStartTime';

window.moment = moment;

export class MultiTime extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.input.value !== nextProps.input.value;
  }

  // There is still a bug here where we don't update the additionalTimes array correctly. Reproduce: we have two additional times
  // and we update the act date. The onChange event will be triggered correctly but first time with [newDateValue, oldDateValue]
  // and second time with [oldDateValue, newDateValue], resulting in only the last array element to be synced correctly. TLDR:
  // the second redux form update did not have the first onChange result with the newValue for the index 0.
  // https://github.com/medyxa/medyx/issues/854
  handleDateChange = (time, index) => {
    updateValue(this.props.input, time, index);
  };

  handleCreateNewDate = (time) => {
    if (isValidNumber(time)) {
      pushNewValue(this.props.input, time);
    }
  };

  render() {
    const { input, currentStartTime, maxNumberOfInput } = this.props;

    return (
      <FormRow>
        {input.value
          ? input.value.map((time, index) => (
              <TimeSyncedWithActStartTime
                key={shortid.generate()}
                label=""
                meta={{}}
                input={{
                  value: time,
                  onChange: (event) => this.handleDateChange(event, index)
                }}
              />
            ))
          : ''}
        {!input.value.length || input.value.length < maxNumberOfInput ? (
          <DialogTrigger
            DialogComponent={TimeSelectorDialog}
            onItemSelected={this.handleCreateNewDate}
            dialogProps={{ unixDateToSyncWith: currentStartTime }}
          >
            <Button id="add-additional-hour-button" style={{ margin: 'auto' }}>
              <AddIcon />
            </Button>
          </DialogTrigger>
        ) : (
          ''
        )}
      </FormRow>
    );
  }
}

MultiTime.propTypes = {
  input: PropTypes.object.isRequired,
  currentStartTime: PropTypes.number,
  maxNumberOfInput: PropTypes.number.isRequired
};

MultiTime.defaultProps = {
  currentStartTime: null
};

export const mapStateToProps = createStructuredSelector({
  currentStartTime: selectStartTime()
});

export default connect(mapStateToProps, null)(MultiTime);
