import type MedyxTheme from '../../../utils/muiTheme';

const styles = (theme: typeof MedyxTheme) => ({
  root: {
    width: '100%'
  },
  tab: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
});

export default styles;
