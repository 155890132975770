/* eslint-disable react/no-array-index-key */
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

const styles = () => ({
  separators: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  separator: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  }
});

function TimeSeparators({ classes, numberRows }) {
  return (
    <div className={classes.separators}>
      <Typography variant="caption" className={classes.timeInfo}>
        &nbsp;
      </Typography>
      {Array.from({ length: numberRows }).map((key, index) => (
        <span key={index} className={classes.separator}>
          :
        </span>
      ))}
    </div>
  );
}

TimeSeparators.propTypes = {
  numberRows: PropTypes.number
};

TimeSeparators.defaultProps = {
  numberRows: 6
};

export default withStyles(styles)(TimeSeparators);
