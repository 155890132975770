import Typography from '@material-ui/core/Typography';
import React from 'react';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { StyledComponentProps, Theme } from '@material-ui/core';
import { MixteDayCode } from '../../../../../containers/PeriodsBilling/PeriodForm/type';

export const styles: StyleRulesCallback<Theme, Props> = () => ({
  upperCased: {
    textTransform: 'uppercase'
  }
});

export interface Props extends StyledComponentProps {
  periodDay: { code?: string; codes?: MixteDayCode[] };
}

export const PeriodCodeRow: React.FunctionComponent<Props> = ({ periodDay, classes }) => {
  if ('codes' in periodDay) {
    return (
      <Typography variant="body2" data-testid="day-codes" className={classes!.upperCased}>
        {periodDay.codes!.map(({ code }) => code).join(',')}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" data-testid="day-code" className={classes!.upperCased}>
      {periodDay.code}
    </Typography>
  );
};
export default withStyles(styles)(PeriodCodeRow);
