import moment from 'moment-timezone';
import lowerFirst from 'lodash/lowerFirst';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import Act from 'shared/domain/activity/act/model/Act';
import { RAMQContextElement, RAMQContextElements } from 'shared/ramq/RamqContextElements';

const NO_ERRORS = {};

export default (act: Act) => {
  if (doesActContainAnHalfPerDiemDuringDaysOff(act)) {
    const surgeryContextElementBilledByDoctorOnDate = findSurgeryContextElementBilledByDoctorOnDate(act.date);

    if (!surgeryContextElementBilledByDoctorOnDate) return NO_ERRORS;

    return {
      codes: {
        _error: `Vous ne pouvez facturer le code 42268 la même journée qu'un ${lowerFirst(
          surgeryContextElementBilledByDoctorOnDate.description
        )}`
      }
    };
  }

  const surgeryContextElementInAct = findSurgeryContextElementInAct(act);

  if (surgeryContextElementInAct && doctorHasBilledAnHalfPerDiemDuringDaysOffOnDate(act.date)) {
    return {
      codes: {
        _error: `Vous ne pouvez facturer un ${lowerFirst(
          surgeryContextElementInAct.description
        )} le même jour que le code 42268`
      }
    };
  }

  return NO_ERRORS;
};

const doctorsActiveActsOfOnDate = (date): Act[] => {
  const givenDate = moment(date);
  const activitiesInContext = selectActivitiesInContext()(getStore().getState()) || [];

  return activitiesInContext.filter(
    ({ date, status, type }) =>
      moment(date).isSame(givenDate, 'day') && status !== ActivityStatus.CANCELED && type === ActivityType.ACT
  );
};

const doesActContainAnHalfPerDiemDuringDaysOff = ({ codes = [] }: Act): boolean =>
  codes.some(({ code }) => ['42268', '42080'].includes(code));

const doctorHasBilledAnHalfPerDiemDuringDaysOffOnDate = (date): boolean =>
  doctorsActiveActsOfOnDate(date).some(doesActContainAnHalfPerDiemDuringDaysOff);

const findSurgeryContextElementInAct = ({ codes = [] }: Act): RAMQContextElement | undefined => {
  const actCodeHavingSurgeryContextElement = codes.find(({ surgeryContextElement }) =>
    ['1948', '1949'].includes(surgeryContextElement)
  );

  if (!actCodeHavingSurgeryContextElement) return;

  return RAMQContextElements.get(actCodeHavingSurgeryContextElement.surgeryContextElement);
};

const findSurgeryContextElementBilledByDoctorOnDate = (date): RAMQContextElement | undefined => {
  for (const act of doctorsActiveActsOfOnDate(date)) {
    const surgeryContextElement = findSurgeryContextElementInAct(act);

    if (surgeryContextElement) return surgeryContextElement;
  }
};
