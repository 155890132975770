import moment from 'moment-timezone';

export const isMeasurementElementsEffective = (dateOfUse, effectiveStartDate, effectiveEndDate): boolean => {
  const GRANULARITY = undefined;

  if (!effectiveStartDate) return true;

  if (effectiveEndDate) {
    return moment(dateOfUse).isBetween(effectiveStartDate, effectiveEndDate || undefined, GRANULARITY, '[]');
  }

  return moment(dateOfUse).isSameOrAfter(effectiveStartDate);
};
