/* eslint-disable react/no-array-index-key */

import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToDate } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import StatementTable from '../../../../../components/Table/Statement/StatementTable/StatementTable';
import { StatementLine } from '../sections/StatementDoctorInfo';

export const parseDateFromRamqToReadable = (date) => {
  const momentDate = moment(date, 'YYMMDD');
  return momentDate.isValid() ? momentDate.valueOf() : null;
};

const montantCodeExplicatifColumn = {
  name: 'Montant / Codes Explicatifs',
  align: 'right',
  primaryText: (line) => line.paidAmount !== '     S/O ' && line.paidAmount && currencyFormat(line.paidAmount),
  secondaryText: (line) =>
    line.explanationCode1 !== '   ' &&
    line.explanationCode1 !== '000' &&
    `${line.explanationCode1} ${line.explanationCode2} ${line.explanationCode3}`
};

const typePeriodColumn = {
  name: 'Type / Période',
  secondaryText: (line) => `${formatUnixToDate(parseDateFromRamqToReadable(line.periodStart))}
                  au ${formatUnixToDate(parseDateFromRamqToReadable(line.periodEnd))}`,
  primaryText: (line) => `${line.agreementNumber} - ${line.insuranceType} ${line.insuranceTypeLabel}`
};

const cumulativeColumn = {
  name: 'Montant cumulatif / Montant cumulatif précédent',
  align: 'right',
  primaryText: (line) => line.cumulativeAmount && currencyFormat(line.cumulativeAmount),
  secondaryText: (line) => line.previousCumulativeAmount && currencyFormat(line.previousCumulativeAmount)
};

function StatementLiabilityInsurance({ content }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={4} data-testid="statementLiabilityInsurance-section">
        <StatementSection
          primaryLine={`${content.modTransactionCode} - ${content.modLineTransactionLabel}`}
          secondaryLines={[
            StatementLine('N. attestation:', content.attestationNumber),
            StatementLine('N. requête:', content.requestNumber),
            StatementLine('Réception:', formatUnixToDate(content.dateReceived)),
            content.explanationCode1 !== '000' &&
              `codes explicatifs: ${content.explanationCode1} ${content.explanationCode2} ${content.explanationCode3}`
          ]}
        />
      </Grid>
      {!isEmpty(content.extraLines) && (
        <Grid item xs={12} sm={8} data-testid="statementLiabilityInsurance-table">
          <StatementTable
            title=""
            columns={[typePeriodColumn, cumulativeColumn, montantCodeExplicatifColumn]}
            data={content.extraLines}
          />
        </Grid>
      )}
    </Grid>
  );
}

StatementLiabilityInsurance.propTypes = {
  content: PropTypes.object.isRequired
};
export default StatementLiabilityInsurance;
