import React from 'react';
import { useSelector } from 'react-redux';
import Markdown from 'application/components/markdown';
import classnames from 'classnames';
import useTranslate from 'application/hooks/use-translate';
import { selectRamqPatient } from 'app/containers/ActForm/selectors';

interface Props {
  className?: string;
}

interface RamqPatientAdmissibility {
  isAdmissible: boolean;
}

const NamAdmissibilityWarning: React.FC<Props> = ({ className }): React.ReactNode => {
  const translate = useTranslate();
  const ramqPatient = useSelector(selectRamqPatient()) as RamqPatientAdmissibility | null;

  if (!ramqPatient || ramqPatient.isAdmissible) return null;

  return (
    <details className={classnames('rounded-md border border-yellow-500 bg-yellow-50 p-4', className)}>
      <summary>{translate('act.nam-inadmissible')}</summary>
      <Markdown slug="non-admissible-nam-warning" />
    </details>
  );
};

export default NamAdmissibilityWarning;
