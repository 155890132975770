/* eslint-disable react/prop-types */
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import SpecialDaysIndicator from '../../../components/Activities/Act/ActSummaryHeader/SpecialDaysIndicators/SpecialDaysIndicator';
import { DateField } from '../../../components/Form/Fields';
import { selectSelectedActPlace } from '../selectors';

export const styles = () => ({
  dateSelectorContainer: {
    display: 'inline-block',
    position: 'relative'
  }
});

export function DateSelector({ classes, place, ...props }) {
  return (
    <div className={classes.dateSelectorContainer}>
      <SpecialDaysIndicator date={props.input.value} place={place} mainActInformation={false} />
      <DateField {...props} />
    </div>
  );
}

DateSelector.propTypes = {
  place: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  place: selectSelectedActPlace()
});

export default compose(withStyles(styles), connect(mapStateToProps))(DateSelector);
