import { Moment } from 'moment-timezone';

export interface AdministrativeNote {
  id: string;
  activityId: string;
  date: Moment;
  author: string;
  type: AdministrativeNoteType;
  content: string;
}

export enum AdministrativeNoteType {
  DEFAULT = 'default',
  NEED_FIX = 'needFix',
  ZERO_DOLLARS_WARNING = 'zeroDollarsWarning'
}

export default AdministrativeNote;
