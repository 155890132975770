import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ChipList from '../../../List/ChipList/ChipList';
import FormattedUnixHours from '../../FormattedUnixHours/FormattedUnixHours';
import MainInformationContainer from '../../MainInformationContainer/MainInformationContainer';
import SpecialDaysIndicator from '../ActSummaryHeader/SpecialDaysIndicators/SpecialDaysIndicator';
import PatientIdentification from '../PatientIdentification/PatientIdentification';

export const styles = (theme) => ({
  codeContainer: {
    justifyContent: 'flex-start'
  },
  specialDaysIndicator: {
    top: theme.spacing(0.5),
    left: 180
  }
});

export function ActMainInformation({ activity, classes }) {
  return (
    <MainInformationContainer activity={activity}>
      <FormattedUnixHours activity={activity} />
      <SpecialDaysIndicator
        place={activity.place}
        date={activity.date}
        classes={{ specialDayChip: classes.specialDaysIndicator }}
      />
      <PatientIdentification act={activity} />
      <ChipList
        dense
        list={activity.codes}
        objectPropertyToBeDisplayed="code"
        objectPropertyToUseAsKey="id"
        className={classes.codeContainer}
      />
    </MainInformationContainer>
  );
}

ActMainInformation.propTypes = {
  activity: PropTypes.object.isRequired
};
export default withStyles(styles)(ActMainInformation);
