import { groupBy, identity, pickBy } from 'lodash';
import { MixteDay } from '../../../../../../../shared/domain/activity/mixte/model/Mixte';
import { LatestMixteDayPeriodFormDto } from '../../../type';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../../../../shared/periods/mixte/constants';
import MixteDayTransformer from './MixteDayTransformer';

class LatestMixteDayTransformer implements MixteDayTransformer<LatestMixteDayPeriodFormDto, MixteDay> {
  public toDaysPeriodFormDto(days: MixteDay[]): LatestMixteDayPeriodFormDto[] {
    const daysGroupedByDate: { [key: number]: MixteDay[] } = groupBy(days, 'date');

    const daysDto: LatestMixteDayPeriodFormDto[] = [];

    for (const daysAtDate of Object.values(daysGroupedByDate)) {
      if (daysAtDate.length === 1) {
        const fullDayPeriod = this.extractFullDayPeriod(daysAtDate);
        daysDto.push(fullDayPeriod);
      } else {
        const { am = [], pm = [] }: { [key: string]: MixteDay[] } = groupBy(daysAtDate, 'perdiems');

        if (am.length > 0) {
          daysDto.push(this.extractAmPeriod(am));
        }

        if (pm.length > 0) {
          daysDto.push(this.extractPmPeriod(pm));
        }
      }
    }

    return daysDto;
  }

  public toDomainDays(days: LatestMixteDayPeriodFormDto[]): MixteDay[] {
    const domainDays: MixteDay[] = [];

    days.forEach(({ codes, manualEditionMode, perdiems, manualCorrection, date }) => {
      (codes || []).forEach(({ code, activityArea, duration }) => {
        domainDays.push(
          this.removeUndefinedValues({
            date,
            manualCorrection,
            manualEditionMode,
            activityArea,
            perdiems,
            duration,
            code
          })
        );
      });
    });

    return domainDays;
  }

  private extractAmPeriod(AM: MixteDay[]) {
    return {
      date: AM[0].date,
      manualCorrection: AM[0].manualCorrection,
      manualEditionMode: AM[0].manualEditionMode,
      perdiems: [AM_PERDIEM],
      codes: AM.map(({ code, activityArea, duration }) => ({
        code: code!,
        duration: duration || 0,
        activityArea: activityArea!
      }))
    };
  }

  private extractPmPeriod(PM: MixteDay[]) {
    return {
      date: PM[0].date,
      manualCorrection: PM[0].manualCorrection,
      manualEditionMode: PM[0].manualEditionMode,
      perdiems: [PM_PERDIEM],
      codes: PM.map(({ code, activityArea, duration }) => ({
        code: code!,
        duration: duration || 0,
        activityArea: activityArea!
      }))
    };
  }

  private extractFullDayPeriod(daysAtDate: MixteDay[]) {
    const { code, activityArea, perdiems, duration, manualCorrection, manualEditionMode, date } = daysAtDate[0];

    return {
      date,
      manualCorrection,
      manualEditionMode,
      perdiems,
      codes: [
        {
          code: code!,
          duration: duration || 0,
          activityArea: activityArea!
        }
      ]
    };
  }

  private removeUndefinedValues(mixteDay: MixteDay): MixteDay {
    return pickBy(mixteDay, identity) as MixteDay;
  }
}

export default LatestMixteDayTransformer;
