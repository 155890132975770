import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import classNames from 'classnames';
import DetailsNote from '../../../DetailsNote/DetailsNote';
import TrainingTimeSummary from '../../TrainingTimeSummary/TrainingTimeSummary';
import PeriodDaysSummary from '../../../PeriodsBilling/PeriodDaysSummary/PeriodDaysSummary';

export const styles = (theme) => ({
  detailedViewContent: {
    width: '100%'
  },
  detailedViewList: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0
  },
  periodDaysSummaryListItem: {
    flexDirection: 'column'
  },
  noHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0
  }
});

export function CollapsableDetails({ open, activity, classes }) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit className={classes.detailedViewContent}>
      <List dense className={classes.detailedViewList}>
        <ListItem className={classNames(classes.noHorizontalPadding, classes.periodDaysSummaryListItem)}>
          <PeriodDaysSummary activity={activity} TimeSummaryComponent={TrainingTimeSummary} />
        </ListItem>
        <ListItem className={classes.noHorizontalPadding}>
          <DetailsNote activity={activity} />
        </ListItem>
      </List>
    </Collapse>
  );
}

CollapsableDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  activity: PropTypes.object.isRequired
};

export default withStyles(styles)(CollapsableDetails);
