/* eslint-disable react/prop-types */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { createPortal } from 'react-dom';
import { withStyles } from '@material-ui/core';

const dragEl = document.getElementById('draggable');

export const styles = (theme) => ({
  dragHandle: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  draggableWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  draggableContent: {
    flexGrow: 1
  },
  dragging: {
    border: `solid 1px ${theme.palette.primary[300]}`
  }
});

export class DraggableElement extends React.PureComponent {
  optionalPortal(draggableStyles, element) {
    if (draggableStyles.position === 'fixed') {
      return createPortal(element, dragEl);
    }
    return element;
  }

  render() {
    const { id, index, children, classes } = this.props;

    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <div>
            {this.optionalPortal(
              provided.draggableProps.style,
              <div
                className={`${classes.draggableWrapper} ${snapshot.isDragging ? classes.dragging : ''}`}
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <div className={classes.dragHandle} {...provided.dragHandleProps}>
                  <DragHandleIcon />
                </div>
                <div className={classes.draggableContent}>{children}</div>
              </div>
            )}
          </div>
        )}
      </Draggable>
    );
  }
}

DraggableElement.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};

export default withStyles(styles)(DraggableElement);
