import { withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import FloatingButton from '../../../../components/button/FloatingButton/FloatingButton';
import DialogCenteredTitle from '../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import CategorizableCollection from '../../../../shared/editable/domain/CategorizableCollection';
import { transformToFirebaseDocumentsCollection } from '../../../../shared/editable/domain/CategorizableCollectionTransformer';
import EditableCollectionWithCategories from '../../../../shared/editable/ui/EditableCollectionWithCategories/EditableCollectionWithCategories';
import { FavoritesDoctorsUtils } from '../../../utils/FavoritesDoctorsUtils';
import DoctorDialog from '../../containers/DoctorDialog/DoctorDialog';
import { ItemComponentBuilder } from './ComponentBuilders';

export const styles = () => ({
  addDoctorButton: {
    bottom: 75,
    right: 25,
    position: 'absolute'
  }
});

export class EditableDoctorCollectionWithCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = { doctorInEdit: undefined, doctorDialogOpen: false };

    this.handleOnRemoveItem = this.handleOnRemoveItem.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.openDoctorDialogInEditionMode = this.openDoctorDialogInEditionMode.bind(this);
    this.openDoctorDialogInCreationMode = this.openDoctorDialogInCreationMode.bind(this);
    this.closeDoctorDialog = this.closeDoctorDialog.bind(this);
  }

  openDoctorDialogInCreationMode() {
    this.setState({ doctorInEdit: undefined, doctorDialogOpen: true });
  }

  openDoctorDialogInEditionMode(doctor) {
    this.setState({
      doctorInEdit: doctor,
      doctorDialogOpen: true
    });
  }

  closeDoctorDialog() {
    this.setState({
      doctorDialogOpen: false,
      doctorInEdit: undefined
    });
  }

  handleOnRemoveItem(id) {
    const { favoritesDoctors, onChange } = this.props;
    const categorizableCollection = new CategorizableCollection(favoritesDoctors);

    onChange(categorizableCollection.removeItem(id));
  }

  handleChangeItem(item) {
    const { favoritesDoctors, onChange } = this.props;
    const categorizableCollection = new CategorizableCollection(favoritesDoctors);

    onChange(categorizableCollection.updateItem(item.id, item));
  }

  render() {
    const { favoritesDoctors, containerWrapperComponent, onChange, classes } = this.props;
    const { doctorDialogOpen, doctorInEdit } = this.state;

    const sortedFavoritesDoctors = FavoritesDoctorsUtils.sortFavoritesWithFavoritesDoctors(favoritesDoctors);

    return (
      <>
        <DialogCenteredTitle label="Médecins" />
        <EditableCollectionWithCategories
          collectionWithCategories={sortedFavoritesDoctors}
          itemComponent={ItemComponentBuilder(this.openDoctorDialogInEditionMode, this.handleOnRemoveItem)}
          containerWrapperComponent={containerWrapperComponent}
          blockDropInSelf
          onChange={onChange}
        />
        <FloatingButton
          id="add-doctor-button"
          className={classes.addDoctorButton}
          color="primary"
          icon={<AddIcon />}
          onClick={this.openDoctorDialogInCreationMode}
        />
        <DoctorDialog
          open={doctorDialogOpen}
          onSave={this.handleChangeItem}
          onClose={this.closeDoctorDialog}
          doctorsPreferences={transformToFirebaseDocumentsCollection(sortedFavoritesDoctors)}
          doctor={doctorInEdit}
        />
      </>
    );
  }
}

EditableDoctorCollectionWithCategories.propTypes = {
  favoritesDoctors: PropTypes.object.isRequired,
  containerWrapperComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(EditableDoctorCollectionWithCategories);
