import React, { useEffect } from 'react';
import Act from 'shared/domain/activity/act/model/Act';
import { isExceptionalEmergencyDuringOffWeekPeriod } from 'app/validations/act/codes/exceptionalEmergencyDuringOffWeekPeriod/exceptionalEmergencyDuringOffWeekPeriod';
import SingleSelectCheckboxGroup from 'app/components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from 'app/ui/shared/form/constants';
import TimePickerSyncedWithDate from 'app/components/Form/TimePickerSyncedWithDate/TimePickerSyncedWithDate';
import FormElement from 'app/components/Form/FormElement/FormElement';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { actFormFields } from '../constants';
import { selectUrgentCallDate } from '../selectors';
import DateSelector from '../DateSelector/DateSelector';
import type { updateUrgentCallDetails } from '../actions';

const styles = (theme) => ({
  urgentCallDetailsForm: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
      textAlign: 'center'
    }
  }
});

export interface Props extends WithStyles<typeof styles> {
  act: Act;
  urgentCallDetails: Act['urgentCallDetails'];
  updateUrgentCallDetails: typeof updateUrgentCallDetails;
}

const UrgentCallDetails: React.FunctionComponent<Props> = ({
  act,
  urgentCallDetails,
  updateUrgentCallDetails,
  classes
}) => {
  useEffect(() => {
    if (!act) return;

    const couldHaveUrgentCall = isExceptionalEmergencyDuringOffWeekPeriod(act);
    const hasUrgent = act.contextElements?.includes('urgent');

    if (!urgentCallDetails && hasUrgent && couldHaveUrgentCall) {
      updateUrgentCallDetails({ guard: true });
    }

    if (urgentCallDetails && (!hasUrgent || !couldHaveUrgentCall)) {
      updateUrgentCallDetails(null);
    }
  }, [act, urgentCallDetails, updateUrgentCallDetails]);

  if (!urgentCallDetails) {
    return null;
  }

  return (
    <>
      <FormElement
        name={`${actFormFields.urgentCallDetails}.guard`}
        label="Urgence en garde ?"
        component={SingleSelectCheckboxGroup}
        list={YES_NO_CHECKBOX_VALUES}
        allowUnset={false}
      />
      {act.urgentCallDetails?.guard === false && (
        <div className={classes.urgentCallDetailsForm}>
          <FormElement
            name={`${actFormFields.urgentCallDetails}.time`}
            label="Heure de l'appel"
            component={TimePickerSyncedWithDate}
            dateSelector={selectUrgentCallDate}
          />
          <FormElement
            name={`${actFormFields.urgentCallDetails}.date`}
            label="Date de l'appel"
            component={DateSelector}
          />
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(UrgentCallDetails);
