import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { captureError } from 'utils/sentry';
import getPictureDataUrl from 'app/containers/ActForm/PicturePreview/utils';
import ChipList from '../../../components/List/ChipList/ChipList';
import ConfirmationDialog from '../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog';

export class AttachmentsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentDeletionConfirmationDialogIsOpen: false,
      attachmentIndexToDelete: null
    };
  }

  mapPropertyToBeDisplayed = (attachmentList) => {
    try {
      return attachmentList.map((attachment) => ({
        ...attachment,
        name: attachment.originalFileName || attachment.fileName
      }));
    } catch (error) {
      captureError(error, {
        attachmentList,
        typeof: typeof attachmentList,
        json: JSON.stringify(attachmentList)
      });
    }
  };

  handleAttachmentClick = async (attachment) => {
    if (!attachment) {
      return;
    }

    const signedUrl = await getPictureDataUrl(attachment);

    if (window.cordova) {
      window.cordova.InAppBrowser.open(signedUrl, '_system');
    } else {
      window.open(signedUrl, '_blank');
    }
  };

  handleAttachmentDelete = (index) => {
    this.setState({
      attachmentDeletionConfirmationDialogIsOpen: true,
      attachmentIndexToDelete: index
    });
  };

  closeAttachmentDeletionConfirmationDialog = () => {
    this.setState({
      attachmentDeletionConfirmationDialogIsOpen: false,
      attachmentIndexToDelete: null
    });
  };

  deleteSelectedCommentFromAdminNotes = () => {
    const { fields: reduxFormFields } = this.props;
    const { attachmentIndexToDelete } = this.state;

    if (attachmentIndexToDelete === null) {
      return;
    }

    reduxFormFields.remove(attachmentIndexToDelete);
  };

  render() {
    const { fields: reduxFormFields } = this.props;
    const { attachmentDeletionConfirmationDialogIsOpen } = this.state;

    return (
      <div id={reduxFormFields.name}>
        {reduxFormFields.length === 0 ? null : (
          <ChipList
            list={this.mapPropertyToBeDisplayed(reduxFormFields.getAll())}
            onClick={this.handleAttachmentClick}
            onDelete={(value, index) => this.handleAttachmentDelete(index)}
            objectPropertyToBeDisplayed="name"
            objectPropertyToUseAsKey="id"
          />
        )}

        <ConfirmationDialog
          data-testid="confirmation-dialog"
          title={<FormattedMessage id="attachment-delete-confirmation-dialog.title" />}
          text={<FormattedMessage id="attachment-delete-confirmation-dialog.confirmation-message" />}
          open={attachmentDeletionConfirmationDialogIsOpen}
          onClose={this.closeAttachmentDeletionConfirmationDialog}
          confirmationLabel={<FormattedMessage id="attachment-delete-confirmation-dialog.confirm" />}
          cancelLabel={<FormattedMessage id="attachment-delete-confirmation-dialog.cancel" />}
          onItemSelected={this.deleteSelectedCommentFromAdminNotes}
        />
      </div>
    );
  }
}

AttachmentsList.propTypes = {
  fields: PropTypes.object.isRequired
};

export default AttachmentsList;
