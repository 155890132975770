import { get } from 'lodash';
import { DirectoryPhysicianType } from 'app/containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import MultiLineWithResidentsSearchField from 'app/components/Form/MultiLineWithResidentsSearchField/MultiLineWithResidentsSearchField';
import { UniversitySelectorField } from '../../../components/Form/Fields';

const summaryRender = (code) => [
  {
    name: 'université',
    value: get(code, 'universityNumber', '-')
  },
  {
    name: 'étudiant',
    value: get(code, 'noStudents', '-')
  }
];

const associatedFields = [
  {
    label: 'Université',
    getName: (index) => `codes[${index}].universityNumber`,
    type: UniversitySelectorField
  },
  {
    label: "No d'étudiant (RXXXXX/EXXXXXXXX/5XXXXX/2XXXXX)",
    getName: (index) => `codes[${index}].noStudents`,
    type: MultiLineWithResidentsSearchField,
    props: {
      type: DirectoryPhysicianType.RESIDENT
    }
  }
];

const lieuResident = { associatedFields, summaryRender };

export default lieuResident;
