import PropTypes from 'prop-types';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { TableBody, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { currencyFormat } from '../../../../../utils/wordFormatUtilities';

export const styles = () => ({
  '@media (max-width: 460px)': {
    denseTableCell: {
      'padding': '4px 10px',
      '&:first-child': {
        paddingLeft: '14px'
      },
      '&:last-child': {
        paddingRight: '14px'
      }
    }
  },
  'userRowHeader': {
    backgroundColor: '#d7d7d7'
  },
  'insetShadow': {
    boxShadow: 'inset 0 0 4px 0 #d7d7d7',
    padding: '2px'
  },
  'fixedCol': {
    width: '200px'
  }
});

export function DoctorsPeriodDayDetails({ classes, doctors }) {
  return (
    <Table>
      <TableBody>
        {doctors.map((doctor) => (
          <TableRow>
            <TableCell classes={{ root: classes.denseTableCell }} className={classes.userRowHeader} size="small">
              {doctor.fullName}
            </TableCell>
            <TableCell
              classes={{ root: classes.denseTableCell }}
              className={classNames(classes.userRowHeader, classes.fixedCol)}
              align="right"
              size="small"
            >
              {doctor.numberOfPerdiems}
            </TableCell>
            <TableCell
              classes={{ root: classes.denseTableCell }}
              className={classNames(classes.userRowHeader, classes.fixedCol)}
              align="right"
              size="small"
            >
              {currencyFormat(doctor.amount)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

DoctorsPeriodDayDetails.propTypes = {
  doctors: PropTypes.array.isRequired
};

export default withStyles(styles)(DoctorsPeriodDayDetails);
