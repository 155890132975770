import { orderBy } from 'lodash';

type ItemWithoutCategory = {
  id: string;
  firstName: string;
  lastName: string;
  practiceNumber: string;
};

type BasicCategory = {
  category: string;
  items: ItemWithoutCategory[];
  itemIds: string[];
  order: number;
  subCategories: BasicCategory[];
};

type ItemsWithoutCategoryAndCategories = {
  itemsWithoutCategory: ItemWithoutCategory[];
  categories: BasicCategory[];
};

type AdvancedCategory = {
  category: string;
  itemIds: string[];
  order: number;
  subCategories: {
    [key: string]: AdvancedCategory;
  };
};

type FavoritesDoctors = {
  id: string;
  items: ItemWithoutCategory[];
  itemsWithoutCategory: {
    itemIds: string[];
  };
  categories: {
    [key: string]: AdvancedCategory;
  };
};

export class FavoritesDoctorsUtils {
  static sortItemsWithoutCategoryAndCategories(
    itemsWithoutCategory: ItemWithoutCategory[],
    categories: BasicCategory[]
  ): ItemsWithoutCategoryAndCategories {
    return {
      itemsWithoutCategory: this.sortItemsWithoutCategory(itemsWithoutCategory),
      categories: this.sortCategories(categories)
    };
  }

  static sortFavoritesWithFavoritesDoctors(favoritesDoctors: FavoritesDoctors): FavoritesDoctors {
    let favDoctors = { ...favoritesDoctors };
    if (favDoctors.itemsWithoutCategory && favDoctors.itemsWithoutCategory.itemIds) {
      favDoctors = this.sortItemsIds(favDoctors);
    }
    return this.sortCategoriesWithFavoritesDoctors(favDoctors);
  }

  private static sortCategories(categories: BasicCategory[]): BasicCategory[] {
    return categories.map((category) => this.sortBasicCategory(category));
  }

  private static sortItemsIds(favoritesDoctors: FavoritesDoctors): FavoritesDoctors {
    const itemIds = orderBy(
      favoritesDoctors.itemsWithoutCategory.itemIds,
      this.sortFunctionByLastNameAndFirstNameFromFavoritesDoctors(favoritesDoctors),
      ['asc']
    );

    return {
      ...favoritesDoctors,
      itemsWithoutCategory: {
        ...favoritesDoctors.itemsWithoutCategory,
        itemIds
      }
    };
  }

  private static sortCategoriesWithFavoritesDoctors(favoritesDoctors: FavoritesDoctors): FavoritesDoctors {
    const { categories } = favoritesDoctors;
    for (const [categoryKey, category] of Object.entries(categories)) {
      categories[categoryKey] = this.sortAdvancedCategory(category, favoritesDoctors);
    }

    return {
      ...favoritesDoctors,
      categories
    };
  }

  private static sortBasicCategory(category: BasicCategory): BasicCategory {
    let sortedCategory = { ...category };

    let { items } = category;
    if (items) {
      items = orderBy(items, this.sortItemsFunctions(), ['asc']);
      sortedCategory = { ...sortedCategory, items };
    }

    return sortedCategory;
  }

  private static sortAdvancedCategory(
    category: AdvancedCategory,
    favoritesDoctors: FavoritesDoctors
  ): AdvancedCategory {
    let sortedCategory = { ...category };

    let { itemIds } = sortedCategory;
    if (itemIds) {
      itemIds = orderBy(itemIds, this.sortFunctionByLastNameAndFirstNameFromFavoritesDoctors(favoritesDoctors), [
        'asc'
      ]);
      sortedCategory = { ...sortedCategory, itemIds };
    }

    const subCategories = { ...category.subCategories };
    if (Object.entries(subCategories).length > 0) {
      for (const [subCategoryKey, subCategory] of Object.entries(category.subCategories)) {
        subCategories[subCategoryKey] = this.sortAdvancedCategory(subCategory, favoritesDoctors);
      }
      sortedCategory = { ...sortedCategory, subCategories };
    }

    return sortedCategory;
  }

  private static sortItemsWithoutCategory(itemsWithoutCategory: ItemWithoutCategory[]): ItemWithoutCategory[] {
    return orderBy(itemsWithoutCategory, this.sortItemsFunctions(), ['asc']);
  }

  private static sortItemsFunctions() {
    return [(item: ItemWithoutCategory) => item.lastName, (item: ItemWithoutCategory) => item.firstName];
  }

  private static sortFunctionByLastNameAndFirstNameFromFavoritesDoctors(favoritesDoctors: FavoritesDoctors) {
    return (itemId) => [favoritesDoctors.items[itemId].lastName, favoritesDoctors.items[itemId].firstName];
  }
}
