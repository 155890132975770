import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { FieldArray, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import wrap from 'lodash/wrap';
import { WithStyles } from '@material-ui/core/styles';
import BillingType from 'shared/domain/billing/model/BillingType';
import { selectBillingType, selectDays } from 'app/containers/PeriodsBilling/selectors';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { MixteDay } from 'shared/domain/collection/activity/Mixte.types';
import { ACTIVITIES_LUMP_SUM_TYPE, ACTIVITIES_MIXTE_TYPE } from '../../../../shared/collection/collectionConstants';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import isDefined from '../../../../shared/utils/isDefined';
import ElectronicSignatureDialog from '../../../components/Dialogs/ElectronicSignatureDialog/ElectronicSignatureDialog';
import { NoteField, SingleLineField } from '../../../components/Form/Fields';
import FormElement from '../../../components/Form/FormElement/FormElement';
import HeaderPortal from '../../../components/Portals/HeaderPortal/HeaderPortal';
import ElectronicSignatureAPI from '../../../infrastructure/api/ElectronicSignatureAPI';
import CorrectionDisplay from '../../ActForm/CorrectionDisplay/CorrectionDisplay';
import { selectLoggedUser } from '../../Authentication/selectors';
import { updatePlaceSignatory } from '../../PlacesPreferencesDialog/actions';
import {
  selectHasElectronicSignatureEnabled,
  selectPlacesPreferenceFromUser,
  selectUserProfileInContext
} from '../../User/selectors';
import PERIOD_FORM_NAME from '../constants';
import PeriodContent from '../PeriodContent/PeriodContent';
import PeriodFooter from '../PeriodFooter/PeriodFooter';
import PeriodHeader from '../PeriodHeader/PeriodHeader';
import PeriodSelector from '../PeriodHeader/PeriodSelector/PeriodSelector';
import FormRow from '../../../components/Form/FormRow/FormRow';
import AttachFile from '../../Attachments/AttachFile/AttachFile';
import AttachmentsList from '../../Attachments/AttachmentsList/AttachmentsList';
import {
  PERIOD_ATTACHMENT_TYPE,
  PERIOD_ATTACHMENTS_LIST_FIELD,
  PERIOD_UPLOAD_ATTACHMENT_FIELD
} from './store/constants';
import { periodFileReadyForUpload } from './store/actions';
import {
  isActivityBlocked,
  isActivityProcessingOrPaid
} from '../../../../shared/core/activity/shared/activityStatus.utils';
import MultiplePlacesOnSameDayWarning from '../../../components/Form/MultiplePlacesOnSameDayWarning/MultiplePlacesOnSameDayWarning';
import BillingTypeField from '../../../components/Form/BillingTypeField/BillingTypeField';
import Alert from '../../../components/Alert/Alert';
import DayUnitValidator from '../PeriodContent/DayUnit/LatestMixteDayUnit/DayUnitValidator';
import setStatusToSendIfStatusIsUndefined from '../../../../shared/amountComputation/activityStatus';
import PeriodPlaceField from '../PeriodHeader/PeriodPlaceField/PeriodPlaceField';
import MixteBilledHours from '../BilledHours/MixteBilledHours';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import { Mixte } from '../../../../shared/domain/activity/mixte/model/Mixte';
import LumpSum, { LumpSumDay } from '../../../../shared/domain/activity/lumpSum/model/LumpSum';
import AuthUserDomain from '../../../../shared/domain/authentication/AuthUser';
import Permission from '../../../../shared/domain/authorization/model/Permission';
import { isUserInPool } from '../../../../shared/domain/user/UserBillingType';
import AlertSeverity from '../../../ui/shared/severity/AlertSeverity';
import PerdiemOverlapOnSameDayWarning from './transformer/mixte/PerdiemOverlapOnSameDayWarning';
import CodeSumsCurrentYear from './code-sums-current-year/CodeSumsCurrentYear';

export const styles = (theme) => ({
  periodHeaderWrapper: {
    'display': 'flex',
    'justifyContent': 'center',
    '& div': {
      maxWidth: 700
    }
  },
  card: {
    height: 'max-content',
    maxWidth: 700,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  paddedField: {
    padding: theme.spacing(1)
  }
});

interface Props extends WithStyles<typeof styles> {
  period: (Mixte | LumpSum) & { requestSignature: boolean; electronicSignature: any };
  initialize: any;
  change: any;
  handleSubmit: any;
  fileReadyForUpload: any;
  onPDFButtonClick: any;
  onSave: any;
  user: AuthUserDomain;
  userProfile: any;
  electronicSignatureEnabled: boolean;
  updatePlaceSignatory: any;
  billingType: any;
  days: MixteDay[] | LumpSumDay[];
}

interface State {
  actionAfterElectronicSignature?: any;
  hideRequestSignatureField?: boolean;
}

export class PeriodForm extends React.Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    onPDFButtonClick: () => undefined,
    userProfile: undefined,
    period: {}
  };

  constructor(props: Props) {
    super(props);

    this.state = { actionAfterElectronicSignature: undefined, hideRequestSignatureField: false };
  }

  getDefaultPoolNumber = () => {
    const { userProfile } = this.props;

    if (!userProfile.pools || userProfile.pools.length === 0) {
      return null;
    }

    return userProfile.pools[0].number;
  };

  componentDidUpdate(prevProps) {
    if (this.props.period.id !== prevProps.period.id) {
      this.initializeForm();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.initializeForm();
  }

  initializeForm = () => {
    if ([BillingType.POOL, BillingType.END].includes(this.props.period.billingType) && !this.props.period.poolNumber) {
      Object.assign(this.props.period, { poolNumber: this.getDefaultPoolNumber() });
    }

    this.props.initialize({ ...this.props.period });
  };

  deleteManualCorrection = (dayReferenceName) => {
    this.props.change(`${dayReferenceName}.manualCorrection`, null);
  };

  onAskForElectronicSignature = (callback, ...args) => {
    const { period } = this.props;
    const shouldAskSignature = !period.hasOwnProperty('requestSignature') || !isDefined(period.requestSignature);
    if (!this.props.electronicSignatureEnabled || !shouldAskSignature) {
      callback(...args);
      return;
    }

    this.props.change('requestSignature', false);
    this.setState({ actionAfterElectronicSignature: () => callback(...args) });
  };

  onManuallyAskForElectronicSignature = () => {
    const callback = () => ElectronicSignatureAPI.requestSignature(this.props.period.id!);

    this.setState({
      actionAfterElectronicSignature: callback,
      hideRequestSignatureField: true
    });
  };

  onSubmitElectronicSignature = (updatedSignatory) => {
    this.state.actionAfterElectronicSignature();
    this.setState({
      actionAfterElectronicSignature: undefined,
      hideRequestSignatureField: undefined
    });

    if (updatedSignatory) {
      this.props.updatePlaceSignatory(updatedSignatory, this.props.period.place.id);
    }
  };

  onDismissElectronicSignature = () => {
    this.setState({ actionAfterElectronicSignature: undefined });
  };

  onSaveForm = (status) => {
    const { handleSubmit, onSave } = this.props;

    const callback = handleSubmit(onSave(status));
    if (status === ActivityStatus.SENT) {
      return wrap(callback, this.onAskForElectronicSignature);
    }

    return callback;
  };

  render() {
    const {
      classes,
      handleSubmit,
      onPDFButtonClick,
      period,
      user,
      userProfile,
      fileReadyForUpload,
      billingType,
      days
    } = this.props;
    const isReadOnly =
      !user.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS) &&
      (isActivityProcessingOrPaid(period) || isActivityBlocked(period));

    return (
      <>
        <ElectronicSignatureDialog
          hideRequestSignatureField={!!this.state.hideRequestSignatureField}
          open={!!this.state.actionAfterElectronicSignature}
          onSubmit={this.onSubmitElectronicSignature}
          onDismiss={this.onDismissElectronicSignature}
        />

        <Fade in>
          <>
            {isUserInPool(userProfile) && (
              <BillingTypeField
                user={userProfile}
                billingTypeFieldName="billingType"
                poolNumberFieldName="poolNumber"
                currentBillingType={billingType}
              />
            )}
            <Paper className={classes.card}>
              <HeaderPortal preventRenderOnAdminConsole>
                <div className={classes.periodHeaderWrapper}>
                  <PeriodHeader activity={period}>
                    <PeriodSelector periodStartDate={period.startDate} periodDuration={period.duration} />
                    <FormElement data-testid="period-place-form-element" name="place" component={PeriodPlaceField} />
                  </PeriodHeader>
                </div>
              </HeaderPortal>

              {(period.days || []).some(({ manualEditionMode = false }) => manualEditionMode === true) && (
                <Alert
                  severity={AlertSeverity.WARNING}
                  noMargin
                  showIcon
                  title="Attention"
                  message="
                SVP, veuillez prendre note qu'aucune automatisation n'est effective pour les journées soulignées en
                couleur car une modification manuelle a été faite pour cette journée.
                "
                />
              )}

              {period.isImportedFromV1 && period.type === ACTIVITIES_LUMP_SUM_TYPE && (
                <Typography variant="body2" align="center">
                  {`Notez que comme le forfaitaire provient de la première version de Médyx,
            les heures indiquées peuvent ne pas réfléter les heures de la rencontre.
            Par contre, les périodes et les codes correspondent.`}
                </Typography>
              )}
              <FormElement name="corrections" component={CorrectionDisplay} status={period.status} />
              {period.type === ACTIVITIES_MIXTE_TYPE && <MultiplePlacesOnSameDayWarning currentActivity={period} />}
              {period.type === ACTIVITIES_MIXTE_TYPE && (
                <PerdiemOverlapOnSameDayWarning currentMixte={period as Mixte} />
              )}
              <CodeSumsCurrentYear periodId={period.id} userId={userProfile.id} type={period.type} days={days} />
              <FieldArray
                data-testid="day-fields"
                name="days"
                component={PeriodContent as any}
                onChange={this.deleteManualCorrection}
                place={period.place}
                activityType={period.type}
                readOnly={isReadOnly}
              />
              {period.type === ActivityType.MIXTE && <MixteBilledHours />}
              <FormRow>
                <FormElement className={classes.paddedField} name="note" label="Note" fullWidth component={NoteField} />
                <AttachFile
                  attachmentType={PERIOD_ATTACHMENT_TYPE}
                  documentId={period.id || ''}
                  fieldName={PERIOD_UPLOAD_ATTACHMENT_FIELD}
                  fileReadyForUploadHandler={fileReadyForUpload}
                />
              </FormRow>
              <FormRow>
                <FieldArray name={PERIOD_ATTACHMENTS_LIST_FIELD} component={AttachmentsList} />
              </FormRow>
              <Restrict atLeastRole={Role.Agent}>
                <FormElement
                  name="complementaryInformation"
                  label="Renseignement complémentaire"
                  component={SingleLineField}
                  inputProps={{ maxLength: 200 }}
                  className={classes.paddedField}
                />
                <FormElement
                  name="externalReferenceNumber"
                  label="Numéro de référence externe"
                  component={SingleLineField}
                  inputProps={{ maxLength: 10 }}
                  className={classes.paddedField}
                />
              </Restrict>
              <PeriodFooter
                onSaveButtonClick={this.onSaveForm}
                onPDFButtonClick={handleSubmit((periodForm) => {
                  const mutedPeriodForm = setStatusToSendIfStatusIsUndefined(periodForm);
                  onPDFButtonClick(mutedPeriodForm, isReadOnly);
                })}
                onRequestElectronicSignature={this.onManuallyAskForElectronicSignature}
                activityType={period.type}
                periodStatus={period.status}
                readOnly={isReadOnly}
                electronicSignature={period.electronicSignature}
              />
            </Paper>
          </>
        </Fade>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  placesPreferences: selectPlacesPreferenceFromUser(),
  user: selectLoggedUser(),
  userProfile: selectUserProfileInContext(),
  electronicSignatureEnabled: selectHasElectronicSignatureEnabled(),
  billingType: selectBillingType(),
  days: selectDays()
});

export const mapDispatchToProps = (dispatch) => ({
  fileReadyForUpload: (...args) => dispatch(periodFileReadyForUpload(...args)),
  updatePlaceSignatory: bindActionCreators(updatePlaceSignatory, dispatch)
});

const validate = (values) => {
  const mixte = values;
  let errors = {};
  errors = DayUnitValidator.validate(mixte, errors);
  return errors;
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: PERIOD_FORM_NAME,
    validate
  }),
  // TS2589: Type instantiation is excessively deep and possibly infinite.
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)
)(PeriodForm);
