export const DUPLICATE_ACT = 'duplicateAct';
export const duplicateAct = (duplicateActInfo) => ({
  type: DUPLICATE_ACT,
  duplicateActInfo
});

export const UPDATE_ACT = 'updateAct';
export const updateAct = (document, changes) => ({
  type: UPDATE_ACT,
  document,
  changes
});

export const WATCH_SENT_ACTIVITY = 'watchSentAct';
export const watchSentActivity = (activity) => ({
  type: WATCH_SENT_ACTIVITY,
  activity
});

export const FILTER_WATCHED_ACTS = 'filterWatchedActs';
export const filterWatchedActs = (erroredActs) => ({
  type: FILTER_WATCHED_ACTS,
  erroredActs
});

export const DISPLAY_GLOBAL_ERROR = 'displayGlobalError';
// @deprecated Use displayGlobalMessage instead
export const displayGlobalError = (message) => ({
  type: DISPLAY_GLOBAL_ERROR,
  message,
  severity: 'error'
});

export type GlobalMessageSeverity = 'default' | 'error' | 'info' | 'none' | 'success' | 'warning';
export const DISPLAY_GLOBAL_MESSAGE = 'displayGlobalMessage';
export const displayGlobalMessage = (message: string, severity: GlobalMessageSeverity) => ({
  type: DISPLAY_GLOBAL_MESSAGE,
  message,
  severity
});

export const CLEAR_GLOBAL_MESSAGE = 'clearGlobalMessage';
export const clearGlobalMessage = () => ({
  type: CLEAR_GLOBAL_MESSAGE
});

export const UPDATE_WATCHED_ACTIVITIES = 'updateWatchedActivities';
export const updateWatchedActivities = () => ({
  type: UPDATE_WATCHED_ACTIVITIES
});

export const UPDATE_ACT_WITHOUT_POST_PROCESSING = 'updateActWithoutPostProcessing';
export const updateActWithoutPostProcessing = (document, changes) => ({
  type: UPDATE_ACT_WITHOUT_POST_PROCESSING,
  document,
  changes
});

export const UPDATE_ACT_FAILURE = 'updateActFailure';
export const updateActFailure = (error) => ({
  type: UPDATE_ACT_FAILURE,
  error
});

export const SAVE_ACT = 'saveAct';
export const saveAct = (document) => ({
  type: SAVE_ACT,
  document
});

export const SAVE_ACT_WITHOUT_POST_PROCESSING = 'saveActWithoutPostProcessing';
export const saveActWithoutPostProcessing = (document) => ({
  type: SAVE_ACT_WITHOUT_POST_PROCESSING,
  document
});

export const SAVE_ACT_FAILURE = 'saveActFailure';
export const saveActFailure = (error) => ({
  type: SAVE_ACT_FAILURE,
  error
});

export const SAVE_MIXTE_FAILURE = 'saveMixteFailure';
export const saveMixteFailure = (error) => ({
  type: SAVE_MIXTE_FAILURE,
  error
});
