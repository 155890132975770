import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'application/components/icon';

interface OptionsMenuProps {
  children: React.ReactNode;
}

const Container = ({ children }: OptionsMenuProps) => (
  <div className="dropdown dropdown-left">
    <div tabIndex={0} role="button" className="btn-default btn btn-square btn-sm">
      <Icon icon="ellipsis" solid />
    </div>
    <ul tabIndex={0} className="menu dropdown-content z-10 w-52 rounded-box bg-base-200 p-2 shadow-lg">
      {children}
    </ul>
  </div>
);

interface OptionsMenuItemProps {
  label: string;
  onClick?: () => void;
  route?: string;
}

const Item = ({ label, onClick, route }: OptionsMenuItemProps) => {
  const handleClick = () => {
    const element = document.activeElement as HTMLLIElement;

    if (element) {
      element.blur();
    }
  };

  return (
    <li onClick={handleClick}>
      {route ? (
        <Link to={route}>{label}</Link>
      ) : (
        <button type="button" onClick={onClick}>
          {label}
        </button>
      )}
    </li>
  );
};

export default Object.assign(Container, {
  Item
});
