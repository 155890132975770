import { concat, uniq } from 'lodash';

export const weekBasedOnGuardCodes = [
  '19127',
  '19129',
  '19131',
  '19133',
  '19135',
  '19137',
  '19139',
  '19141',
  '19143',
  '19145',
  '19147',
  '19149',
  '19151',
  '19153',
  '19155',
  '19157',
  '19159',
  '19161',
  '19163',
  '19165',
  '19167',
  '19169',
  '19171',
  '19173',
  '19175',
  '19177',
  '19179',
  '19181',
  '19183',
  '19185',
  '19187',
  '19189',
  '19191',
  '19193',
  '19195',
  '19197',
  '19199',
  '19201',
  '19203',
  '19205',
  '19207',
  '19209',
  '19211',
  '19215',
  '19217',
  '19219',
  '19221',
  '19223',
  '19225',
  '19227',
  '19229',
  '19231',
  '19233',
  '19235',
  '19237',
  '19239',
  '19241',
  '19243',
  '19245',
  '19247',
  '19249',
  '19251',
  '19253',
  '19255',
  '19257',
  '19259',
  '19261',
  '19263',
  '19265',
  '19267',
  '19269',
  '19271',
  '19273',
  '19275',
  '19277',
  '19279',
  '19281',
  '19283',
  '19285',
  '19287',
  '19289',
  '19291',
  '19293',
  '19295',
  '19297',
  '19299',
  '19301',
  '19303',
  '19305',
  '19307',
  '19309',
  '19311',
  '19313',
  '19315',
  '19317',
  '19319',
  '19321',
  '19323',
  '19325',
  '19327',
  '19329',
  '19331',
  '19333',
  '19335',
  '19337',
  '19343',
  '19345',
  '19347',
  '19349',
  '19351',
  '19353',
  '19355',
  '19357',
  '19359',
  '19361',
  '19365',
  '19367',
  '19405',
  '19407',
  '19409',
  '19411',
  '19413',
  '19415',
  '19417',
  '19419',
  '19421',
  '19423',
  '19425',
  '19479',
  '19481',
  '19483',
  '19485',
  '19487',
  '19489',
  '19491',
  '19493',
  '19495',
  '19497',
  '19499',
  '19501',
  '19503',
  '19505',
  '19507',
  '19507',
  '19509',
  '19509',
  '19511',
  '19511',
  '19513',
  '19513',
  '19515',
  '19515',
  '19517',
  '19517',
  '19519',
  '19519',
  '19521',
  '19521',
  '19523',
  '19523',
  '19525',
  '19525',
  '19527',
  '19527',
  '19529',
  '19531',
  '19533',
  '19535',
  '19538',
  '19540',
  '19542',
  '19544',
  '19546',
  '19548',
  '19550',
  '19552',
  '19554',
  '19556',
  '19558',
  '19560',
  '19562',
  '19564',
  '19566',
  '19568',
  '19570',
  '19572',
  '19574',
  '19576',
  '19578',
  '19580',
  '19582',
  '19584',
  '19586',
  '19594',
  '19596',
  '19598',
  '19600',
  '19602',
  '19604',
  '19606',
  '19608',
  '19610',
  '19637',
  '19639',
  '19656',
  '19658',
  '19777',
  '19781',
  '19800',
  '19802',
  '19804',
  '19806',
  '19808',
  '19810',
  '19812',
  '19818',
  '19831',
  '19861',
  '19863',
  '19865',
  '19867',
  '19889',
  '19891',
  '19932',
  '19934',
  '19936',
  '19938',
  '19940',
  '19940',
  '19942',
  '19944',
  '19946',
  '42000',
  '42002',
  '42004',
  '42006',
  '42008',
  '42010',
  '42012',
  '42014',
  '42016',
  '42018',
  '42020',
  '42022',
  '42024',
  '42026',
  '42028',
  '42030',
  '42032',
  '42034',
  '42036',
  '42040',
  '42042',
  '42044',
  '42046',
  '42096',
  '42098',
  '42140',
  '42142',
  '42144',
  '42146',
  '42148',
  '42150',
  '42154',
  '42156',
  '42179',
  '42197',
  '42216',
  '42218',
  '42220',
  '42222',
  '42233',
  '42235',
  '42237',
  '42239',
  '42241',
  '42243',
  '42245'
];

export const weekEndHolidayOnGuardCodes = [
  '19128',
  '19130',
  '19132',
  '19134',
  '19136',
  '19138',
  '19140',
  '19142',
  '19144',
  '19146',
  '19148',
  '19150',
  '19152',
  '19154',
  '19156',
  '19158',
  '19160',
  '19162',
  '19164',
  '19166',
  '19168',
  '19170',
  '19172',
  '19174',
  '19176',
  '19178',
  '19180',
  '19182',
  '19184',
  '19186',
  '19188',
  '19190',
  '19192',
  '19194',
  '19196',
  '19198',
  '19200',
  '19202',
  '19204',
  '19206',
  '19208',
  '19210',
  '19212',
  '19216',
  '19218',
  '19220',
  '19222',
  '19224',
  '19226',
  '19228',
  '19230',
  '19232',
  '19234',
  '19236',
  '19238',
  '19240',
  '19242',
  '19244',
  '19246',
  '19248',
  '19250',
  '19252',
  '19254',
  '19256',
  '19258',
  '19260',
  '19262',
  '19264',
  '19266',
  '19268',
  '19270',
  '19272',
  '19274',
  '19276',
  '19278',
  '19280',
  '19282',
  '19284',
  '19286',
  '19288',
  '19290',
  '19292',
  '19294',
  '19296',
  '19298',
  '19300',
  '19302',
  '19304',
  '19306',
  '19308',
  '19310',
  '19312',
  '19314',
  '19316',
  '19318',
  '19320',
  '19322',
  '19324',
  '19326',
  '19328',
  '19330',
  '19332',
  '19334',
  '19336',
  '19338',
  '19344',
  '19346',
  '19348',
  '19350',
  '19352',
  '19354',
  '19356',
  '19358',
  '19360',
  '19362',
  '19366',
  '19368',
  '19410',
  '19414',
  '19416',
  '19420',
  '19422',
  '19426',
  '19480',
  '19482',
  '19484',
  '19486',
  '19488',
  '19490',
  '19492',
  '19494',
  '19496',
  '19498',
  '19500',
  '19502',
  '19504',
  '19506',
  '19508',
  '19508',
  '19510',
  '19510',
  '19512',
  '19512',
  '19514',
  '19514',
  '19516',
  '19516',
  '19518',
  '19518',
  '19520',
  '19520',
  '19522',
  '19522',
  '19524',
  '19524',
  '19526',
  '19526',
  '19528',
  '19528',
  '19530',
  '19532',
  '19534',
  '19536',
  '19539',
  '19541',
  '19543',
  '19545',
  '19547',
  '19549',
  '19551',
  '19553',
  '19555',
  '19557',
  '19559',
  '19561',
  '19563',
  '19565',
  '19567',
  '19569',
  '19571',
  '19575',
  '19577',
  '19579',
  '19581',
  '19583',
  '19587',
  '19595',
  '19597',
  '19599',
  '19601',
  '19603',
  '19605',
  '19607',
  '19609',
  '19638',
  '19640',
  '19657',
  '19659',
  '19782',
  '19801',
  '19803',
  '19805',
  '19807',
  '19809',
  '19811',
  '19832',
  '19862',
  '19864',
  '19866',
  '19868',
  '19890',
  '19892',
  '19933',
  '19935',
  '19937',
  '19939',
  '19941',
  '19941',
  '19943',
  '19945',
  '19947',
  '42001',
  '42003',
  '42005',
  '42007',
  '42009',
  '42011',
  '42013',
  '42015',
  '42017',
  '42019',
  '42021',
  '42023',
  '42025',
  '42027',
  '42029',
  '42031',
  '42033',
  '42035',
  '42037',
  '42041',
  '42043',
  '42045',
  '42047',
  '42097',
  '42099',
  '42141',
  '42143',
  '42145',
  '42147',
  '42149',
  '42151',
  '42155',
  '42157',
  '42180',
  '42198',
  '42217',
  '42219',
  '42221',
  '42223',
  '42234',
  '42236',
  '42238',
  '42240',
  '42242',
  '42244'
];

export const supportGuardDuringWeekday = [
  '19351',
  '19349',
  '42233',
  '19867',
  '42030',
  '42235',
  '19353',
  '19944',
  '19946',
  '42179',
  '19562',
  '19600',
  '42197',
  '42098',
  '19355',
  '19357',
  '42144',
  '19564',
  '19359',
  '19566'
];

export const supportGuardDuringWeekendAndHoliday = [
  '19352',
  '19350',
  '42234',
  '19868',
  '42031',
  '42236',
  '19354',
  '19945',
  '19947',
  '42180',
  '19563',
  '19601',
  '42198',
  '42099',
  '19356',
  '19358',
  '42145',
  '19565',
  '19360',
  '19567'
];

export const supportGuard = uniq(supportGuardDuringWeekday, supportGuardDuringWeekendAndHoliday);

export const onGuardCodes = concat(weekBasedOnGuardCodes, weekEndHolidayOnGuardCodes);

export default onGuardCodes;

// Soins intensifs 19507 19508
// Grands brûlés 19511 19512
// Trauma non chirurgical 19940 19941
// Unité cardiologique 19517 19518
// Unité chirurgicale 19513 19514
// Unité CCVT 19525 19526
// Unité médicale 19519 19520
// Unité mixte 19509 19510
// Unité neurologique et neurochirurgicale 19521 19522
// Unité neuro-médicale 19527 19528
// Unité pédiatrique 19515 19516
// Unité respiratoire 19523 19524
