import { createSelector } from '@reduxjs/toolkit';
import { find, get } from 'lodash';

const selectRamqPlaces = (state) => state.ramqPlaces;

export const selectPlace = (id) =>
  createSelector(selectRamqPlaces, (state) => {
    const { places } = state;

    return find(places, ['id', id]);
  });

export const selectPlacesTerritory = (id) =>
  createSelector(selectPlace(id), (place) => get(place, 'locality.territory'));

export const selectRamqPlacesLoaded = () => createSelector(selectRamqPlaces, (state) => state.loaded);
