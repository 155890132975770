import { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { RAMQPlace, RAMQPlaces, SearchOptions } from 'shared/ramq/RamqPlaces';

export default function useSearchRAMQPlaces(
  query: string,
  dateInContext?: Moment | number,
  searchOptions?: SearchOptions
): RAMQPlace[] {
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [ramqPlaces, setRAMQPlaces] = useState<RAMQPlace[]>([]);

  useDebounce(() => setDebouncedQuery(query), 300, [query]);

  useEffect(() => {
    if (!debouncedQuery) {
      setRAMQPlaces([]);
    } else {
      const results = RAMQPlaces.search(debouncedQuery, dateInContext, searchOptions);

      setRAMQPlaces(results);
    }
  }, [debouncedQuery, dateInContext, searchOptions]);

  return ramqPlaces;
}
