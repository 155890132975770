import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PageHeader from 'application/components/page-header';
import GroupUsersPage from 'application/pages/groups/group/users';
import GroupReportsPage from 'application/pages/groups/group/reports';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import {
  GROUP_USERS,
  GROUP_PAYMENT_REQUESTS,
  GROUP_REPORTS,
  GROUP_DETAILS,
  GROUP_PDF_GENERATION
} from 'application/routes';
import useTranslate from 'application/hooks/use-translate';
import GroupDetailsPage from 'application/pages/groups/group/details';
import PageContainer from 'application/components/page-container';
import Role from 'shared/domain/authentication/Role';
import { isUserAtLeast } from 'application/utilities/authentication';
import GroupPaymentRequestsPage from 'application/pages/groups/group/payment-requests';
import { useSelector } from 'react-redux';
import { RootState } from 'application/types/redux-state';
import { GroupType } from 'application/types/groups';
import RestrictedOutlet from 'application/components/restricted-outlet';
import Tabs from 'application/components/tabs';
import GroupPdfGenerationPage from './pdf-generation';

interface Group {
  id: string;
  name: string;
  number: string;
  type: string;
  usersCount: number;
}

const GROUP_QUERY = gql`
  query FetchGroup($groupId: String!) {
    group(groupId: $groupId) {
      id
      name
      number
      type
      usersCount
    }
  }
`;

const GroupIndex = () => {
  const translate = useTranslate('pages.group');
  const userRole = useSelector<RootState, Role>((state) => state.authentication.user.role);
  const { groupId } = useParams();
  const { data, loading } = useQuery<{ group: Group }>(GROUP_QUERY, { variables: { groupId } });

  if (loading) return null;
  if (!data?.group || !groupId) return <Navigate to="/" />;

  const navLinks = [
    { destination: GROUP_DETAILS, label: translate('details.tab') },
    { badge: data?.group.usersCount, destination: GROUP_USERS, label: translate('users.tab') }
  ];

  if (data?.group.type === GroupType.POOL) {
    navLinks.push(
      { destination: GROUP_PAYMENT_REQUESTS, label: translate('payment-requests.tab') },
      { destination: GROUP_REPORTS, label: translate('reports.tab') }
    );
  }

  if (isUserAtLeast(userRole, Role.Agent)) {
    navLinks.push({ destination: GROUP_PDF_GENERATION, label: translate('pdf-generation.tab') });
  }

  return (
    <PageContainer>
      <PageHeader
        title={data?.group.name || ''}
        badge={data?.group.number}
        backButton={
          isUserAtLeast(userRole, Role.Agent)
            ? {
                label: translate('back'),
                route: '..'
              }
            : undefined
        }
      />

      <Tabs tabs={navLinks} />

      <Routes>
        <Route path={GROUP_DETAILS} element={<GroupDetailsPage />} />
        <Route path={GROUP_USERS} element={<GroupUsersPage />} />
        <Route path={GROUP_PAYMENT_REQUESTS} element={<GroupPaymentRequestsPage />} />
        <Route path={GROUP_REPORTS} element={<GroupReportsPage />} />
        <Route element={<RestrictedOutlet atLeastRole={Role.Agent} />}>
          <Route path={GROUP_PDF_GENERATION} element={<GroupPdfGenerationPage />} />
        </Route>
        <Route index element={<Navigate to={GROUP_DETAILS} />} />
      </Routes>
    </PageContainer>
  );
};

export default GroupIndex;
