import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { TextField, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { WithStyles } from '@material-ui/styles';
import { useDebounce } from 'react-use';
import messages from '../../../../containers/CodeSearchDialog/messages';
import CodeSuggestion from '../../../../components/List/Item/CodeSuggestion/CodeSuggestion';
import MaxSearchReachedDialogActions from '../../../../components/Dialogs/DialogActionsWithMessage/MaxSearchReachedDialogActions';
import getNextPriority from '../../../../containers/User/preferencesUtils';
import CustomCodeDescriptionDialog from '../../../../components/Dialogs/CustomCodeDescriptionDialog/CustomCodeDescriptionDialog';
import { selectCodesPreferencesFromUser } from '../../../../containers/User/selectors';
import { addCodeToUserPreferences } from '../../../../containers/CodeSearchDialog/actions';
import { ActCode } from '../../../../../shared/core/activity/domain/Act';
import { RAMQCode, RAMQCodes } from '../../../../../shared/ramq/RamqCodes';
import { SearchSuggestions } from '../../../../containers/SearchSuggestions/SearchSuggestions';
import { selectSelectedDate } from '../../../../containers/ActForm/selectors';

export const styles = (theme) =>
  ({
    searchContainer: {
      'height': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    },
    suggestionList: {
      flex: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  onSuggestionClick: (code) => void;
  addCodeToFavorites: (code) => void;
  favoritesActCodes?: ActCode[];
  onClose: () => void;
  currentActDate: number;
}

export const SearchActCodesTab: React.FunctionComponent<Props> = (props: Props) => {
  const { onSuggestionClick, addCodeToFavorites, favoritesActCodes, onClose, currentActDate, classes } = props;
  const [customCodeDescriptionDialogOpen, setCustomCodeDescriptionDialogOpen] = useState(false);
  const [tempSelectedCode, setTempSelectedCode] = useState<RAMQCode>();
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [options, setOptions] = useState<RAMQCode[]>([]);

  const isInFavorites = (act) =>
    favoritesActCodes?.findIndex((favoriteActCode) => favoriteActCode.code === act.code) !== -1;

  const handleFavoriteClick = (code) => {
    setCustomCodeDescriptionDialogOpen(true);
    setTempSelectedCode(code);
  };

  const handleAddFavorite = (description) => {
    if (!tempSelectedCode) return;

    const priority = getNextPriority(favoritesActCodes);

    addCodeToFavorites({
      ...tempSelectedCode,
      description,
      originalDescription: tempSelectedCode.description,
      priority
    });

    reset();
  };

  const reset = () => {
    setCustomCodeDescriptionDialogOpen(false);
    setTempSelectedCode(undefined);
  };

  useDebounce(() => setDebouncedInputValue(inputValue), 300, [inputValue]);

  useEffect(() => {
    if (!debouncedInputValue) {
      setOptions([]);
    } else {
      setOptions(RAMQCodes.search(debouncedInputValue, currentActDate));
    }
  }, [debouncedInputValue, currentActDate]);

  return (
    <>
      <DialogContent className={classes.searchContainer}>
        <TextField
          onChange={(e) => setInputValue(e.target.value)}
          label={<FormattedMessage {...messages.codeSearchLabel} />}
        />
        <SearchSuggestions
          className={classes.suggestionList}
          hits={options}
          clearSearch={() => setInputValue('')}
          onSuggestionClick={onSuggestionClick}
          onFavoriteClick={handleFavoriteClick}
          isInFavoritesFunction={isInFavorites}
          SuggestionItemComponent={CodeSuggestion}
          getKeyFunction={(suggestion) => suggestion.code}
        />
      </DialogContent>
      <MaxSearchReachedDialogActions maxSearchNumber={20} hitsCount={options.length}>
        <Button id="close-Dialog" onClick={onClose} color="primary">
          <FormattedMessage {...messages.close} />
        </Button>
      </MaxSearchReachedDialogActions>

      <CustomCodeDescriptionDialog
        mode="append"
        selectedCode={tempSelectedCode}
        open={customCodeDescriptionDialogOpen}
        onCancel={reset}
        onSave={handleAddFavorite}
      />
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  favoritesActCodes: selectCodesPreferencesFromUser(),
  currentActDate: selectSelectedDate()
});

export const mapDispatchToProps = (dispatch) => ({
  addCodeToFavorites: (code) => dispatch(addCodeToUserPreferences(code))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(SearchActCodesTab);
