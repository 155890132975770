import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonCheckbox from '../ButtonCheckbox/ButtonCheckbox';

const styles = () => ({
  fullWidth: {
    width: '100%'
  }
});

export class SingleCheckbox extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.input.value === '') {
      this.props.input.onChange(false);
    }
  }

  render() {
    const { input, label, fullWidth, classes } = this.props;
    return (
      <ButtonCheckbox
        onClick={() => input.onChange(!input.value)}
        label={label}
        checked={!!input.value}
        buttonClasses={fullWidth ? classes.fullWidth : {}}
      />
    );
  }
}

SingleCheckbox.defaultProps = {
  label: '',
  fullWidth: false
};

SingleCheckbox.propTypes = {
  label: PropTypes.node,
  fullWidth: PropTypes.bool,
  input: PropTypes.object.isRequired
};

export default withStyles(styles)(SingleCheckbox);
