export const USERS = 'users';
export const USERS_HISTORY = 'usersHistory';
export const PLACES_PREFERENCES = 'placesPreferences';
export const DOCTORS_PREFERENCES = 'doctorsPreferences';
export const RESIDENTS_PREFERENCES = 'residentsPreferences';
export const CODES_PREFERENCES = 'codesPreferences';
export const DIAGNOSTIC_PREFERENCES = 'diagnosticPreferences';
export const CONTEXT_ELEMENT_PREFERENCES = 'contextElementPreferences';
export const ACTIVITIES = 'activities';
export const ACTIVITIES_HISTORY = 'activitiesHistory';
export const RAMQ_CALL_LOGS = 'ramqCallLogs';
export const RAMQ_METADATA = 'ramqMetadata';
export const LOGS = 'logs';
export const MEDYX_GLOBAL_INFO_COLLECTION = 'medyxGlobalInfos';
export const GLOBAL_INFOS = 'globalInfos';
export const PLACES = 'places';
export const LUMP_SUMS_PREFERENCES = 'lumpSumsPreferences';

export const STATEMENTS_COLLECTION = 'statements';

export const ACTIVITIES_ACT_TYPE = 'act';
export const ACTIVITIES_MIXTE_TYPE = 'mixte';
export const ACTIVITIES_LUMP_SUM_TYPE = 'lumpSum';
export const ACTIVITIES_TRAVEL_EXPENSES_TYPE = 'travelExpenses';
export const ACTIVITIES_TRAINING_TYPE = 'training';
export const POSSIBLE_ACTIVITIES_TYPE = [
  ACTIVITIES_ACT_TYPE,
  ACTIVITIES_MIXTE_TYPE,
  ACTIVITIES_LUMP_SUM_TYPE,
  ACTIVITIES_TRAVEL_EXPENSES_TYPE,
  ACTIVITIES_TRAINING_TYPE
];

export const ATTESTATION_SEQUENTIAL_NUMBER_METADATA = 'attestationSequentialNumber';
export const LAST_EXPLANATION_FILE_PARSED = 'lastExplanationFileParsed';
export const RAMQ_CREDS = 'creds';
export const RAMQ_CREDS_HISTORY = 'credsHistory';
export const STATEMENT_JOB_RUNNING = 'statementJobRunning';
export const ERRORFILE_JOB_RUNNING = 'errorFileJobRunning';

export const SPECIALTIES_COLLECTION = 'specialties';
export const ACT_CODES_COLLECTION = 'specificActCodes';
export const DIAGNOSTIC_CODES_COLLECTION = 'specificDiagnosticCodes';
export const CONTEXT_ELEMENTS_COLLECTION = 'specificContextElements';

export const USERS_COLLECTION = 'users';
export const CODES_PREFERENCES_COLLECTION = 'codesPreferences';

export const OCR_LOGS_COLLECTION = 'ocrLogs';
