import PropTypes from 'prop-types';
import React from 'react';
import ReactToPrint from 'react-to-print';
import MobilePrintButton from '../MobilePrintButton/MobilePrintButton';

function HybridPrintButton({ componentToPrintRef, ButtonComponent, title }) {
  return window.cordova ? (
    <MobilePrintButton componentToPrintRef={componentToPrintRef} ButtonComponent={ButtonComponent} />
  ) : (
    <ReactToPrint trigger={ButtonComponent} content={componentToPrintRef} documentTitle={title} />
  );
}

HybridPrintButton.propTypes = {
  ButtonComponent: PropTypes.func.isRequired,
  componentToPrintRef: PropTypes.func.isRequired,
  title: PropTypes.string
};

HybridPrintButton.defaultProps = {
  title: ''
};

export default HybridPrintButton;
