import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectUserProfileInContext } from '../../../containers/User/selectors';
import { selectSearchedUserIds } from '../../../containers/UsersRoute/selectors';
import AppHeaderContainer from '../AppHeaderContainer/AppHeaderContainer';
import UserNavigationButtons from './UserNavigationButtons';

export const styles = (theme) => ({
  root: {
    minHeight: theme.spacing(6)
  }
});

export function UserNameHeader({ searchedUserIds, navigable, currentUserProfile, classes }) {
  return (
    <AppHeaderContainer className={classes.root}>
      {currentUserProfile && (
        <Typography type="title" color="inherit" noWrap align="center">
          {currentUserProfile.firstName && `${currentUserProfile.firstName} ${currentUserProfile.lastName}`}
          &nbsp;-&nbsp;
          <FormattedMessage id={`global.specialty.${currentUserProfile.specialty}`} defaultMessage="Inconnu" />
        </Typography>
      )}
      {currentUserProfile && navigable && (
        <UserNavigationButtons userIds={searchedUserIds} currentUserId={currentUserProfile.id} />
      )}
    </AppHeaderContainer>
  );
}

UserNameHeader.propTypes = {
  searchedUserIds: PropTypes.array.isRequired,
  currentUserProfile: PropTypes.object.isRequired,
  navigable: PropTypes.bool
};

UserNameHeader.defaultProps = {
  navigable: false,
  searchedUserIds: [],
  currentUserProfile: {}
};

export const mapStateToProps = createStructuredSelector({
  searchedUserIds: selectSearchedUserIds(),
  currentUserProfile: selectUserProfileInContext()
});

export default compose(withStyles(styles), connect(mapStateToProps, null))(UserNameHeader);
