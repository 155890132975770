import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import english from './assets/english.svg';
import englishLight from './assets/english-light.svg';
import french from './assets/french.svg';
import frenchLight from './assets/french-light.svg';

interface Props {
  theme?: 'light' | 'dark';
}

const AppStoreBadge = ({ theme = 'dark' }: Props) => {
  const intl = useIntl();

  const logo = useMemo(() => {
    switch (intl.locale) {
      case 'fr':
        return theme === 'light' ? frenchLight : french;
      case 'en':
      default:
        return theme === 'light' ? englishLight : english;
    }
  }, [intl.locale, theme]);

  const handleClick = () => {
    window.open('https://apps.apple.com/ca/app/medyx/id1327373943', '_blank');
  };

  return (
    <div onClick={handleClick} className="cursor-pointer">
      <img src={logo} alt="App store" className="w-[180px]" />
    </div>
  );
};

export default AppStoreBadge;
