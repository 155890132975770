import { useQuery } from '@tanstack/react-query';
import UserAPI from 'app/infrastructure/api/UserAPI';
import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import openUrl from 'application/utilities/open-url';
import Section from 'application/components/form-controls/section';
import useTranslate from 'application/hooks/use-translate';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import SettingsSubscriptionSignup from 'application/pages/settings/details/settings-subscription-signup';
import moment from 'moment';
import React, { useState } from 'react';

interface Props {
  user: FirestoreUser;
}

const SettingsSubscription = ({ user }: Props) => {
  const translate = useTranslate('pages.settings.details.subscription');

  const [loading, setLoading] = useState(false);

  const { refetch } = useQuery<{ url: string }>({
    queryKey: [user.stripeCustomerId],
    queryFn: UserAPI.fetchStripeCustomerPortalLink,
    enabled: false
  });

  const openStripeCustomerPortal = async () => {
    setLoading(true);
    const { data } = await refetch();
    setLoading(false);

    if (!data) return;

    openUrl(data.url);
  };

  return (
    <Section label={translate('title')}>
      {user.stripeSubscriptionMetadata && user.stripeSubscriptionMetadata.productName ? (
        <>
          <FieldContainer label={translate('user.subscription-name')}>
            {user.stripeSubscriptionMetadata.productName}
          </FieldContainer>
          {!user.stripeSubscriptionMetadata.cancelEnd && (
            <FieldContainer label={translate('user.renewal-date')}>
              {moment(user.stripeSubscriptionMetadata.currentPeriodEnd).format('YYYY-MM-DD')}
            </FieldContainer>
          )}
          {user.stripeSubscriptionMetadata.cancelEnd && (
            <FieldContainer label={translate('user.cancel-end.label')} helper={translate('user.cancel-end.helper')}>
              {moment(user.stripeSubscriptionMetadata.cancelEnd).format('YYYY-MM-DD')}
            </FieldContainer>
          )}
          <FieldContainer
            label={translate('user.manage-subscription.label')}
            helper={translate('user.manage-subscription.helper')}
          >
            <Button
              type="submit"
              loading={loading}
              className="btn btn-primary"
              icon="external-link"
              onClick={openStripeCustomerPortal}
            >
              {translate('user.manage-subscription-button')}
            </Button>
          </FieldContainer>
        </>
      ) : (
        <SettingsSubscriptionSignup user={user} />
      )}
    </Section>
  );
};

export default SettingsSubscription;
