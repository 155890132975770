import { assignIn, assignInWith, pick, toPairs, get } from 'lodash';
import {
  ADMIN_STATUS_FIELD,
  DATE_RANGE_FILTER,
  STATUS_FIELD,
  TYPE_FIELD,
  OUTSIDE_RAMQ_PATIENT_TYPE_FILTER,
  OTHER_FILTER
} from '../../../../shared/collection/queryConstants';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import AdminStatus from '../../../../shared/core/activity/domain/AdminStatus';
import { OutsideRamqPatientType } from '../../../../shared/domain/activity/act/model/Act';
import ActivityOtherFilter from '../../../../shared/core/activity/domain/ActivityOtherFilter';

const ACTIVITY_FILTER_KEYS = [
  OTHER_FILTER,
  DATE_RANGE_FILTER,
  STATUS_FIELD,
  ADMIN_STATUS_FIELD,
  TYPE_FIELD,
  OUTSIDE_RAMQ_PATIENT_TYPE_FILTER
];
const MULTI_FILTERS_KEYS = [
  STATUS_FIELD,
  ADMIN_STATUS_FIELD,
  TYPE_FIELD,
  OUTSIDE_RAMQ_PATIENT_TYPE_FILTER,
  OTHER_FILTER
];

interface ActiveFilters {
  dateRange?: object;
  other?: ActivityOtherFilter[];
  type?: ActivityType[];
  status?: ActivityStatus[];
  adminStatus?: AdminStatus[];
  outsideRamqPatientType?: OutsideRamqPatientType[];
}

interface AppliedFilters {
  dateRange?: object;
  other?: ActivityOtherFilter;
  type?: ActivityType;
  status?: ActivityStatus;
  adminStatus?: AdminStatus;
  outsideRamqPatientType?: OutsideRamqPatientType[];
}

class ActivityFilterApplier {
  apply(currentFilters: ActiveFilters, newFilter: AppliedFilters) {
    const activityFilters = pick(currentFilters, ACTIVITY_FILTER_KEYS);
    const [newFilterKey, newFilterValue] = toPairs(newFilter)[0];

    if (!MULTI_FILTERS_KEYS.includes(newFilterKey)) {
      return this.cleanupDependentFilters(assignIn(activityFilters, newFilter));
    }

    if (get(activityFilters, newFilterKey, []).includes(newFilterValue)) {
      return this.cleanupDependentFilters(assignInWith(activityFilters, newFilter, this.removeFromFilterValues));
    }

    return this.cleanupDependentFilters(assignInWith(activityFilters, newFilter, this.addToFilterValues));
  }

  private removeFromFilterValues(filterValues: string[], filterValueToRemove: string): string[] {
    return filterValues.filter((currentValue) => currentValue !== filterValueToRemove);
  }

  private addToFilterValues(filterValues: string[] | undefined, filterValueToAdd: string): string[] {
    return [...(filterValues || []), filterValueToAdd];
  }

  private cleanupDependentFilters(updatedFilters: ActiveFilters) {
    let cleanedFilters = updatedFilters;

    if (
      !get(updatedFilters, 'adminStatus', []).includes(AdminStatus.OUTSIDE_RAMQ) &&
      get(updatedFilters, 'outsideRamqPatientType', []).length
    ) {
      cleanedFilters = assignIn(cleanedFilters, { outsideRamqPatientType: [] });
    }

    return cleanedFilters;
  }
}

export default ActivityFilterApplier;
