import Grow from '@material-ui/core/Grow';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslate from 'application/hooks/use-translate';
import Modal from 'application/components/modal';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import DesktopActions from '../../../containers/ActivitiesRoute/DayRoute/DayActivities/Act/DesktopActions/DesktopActions';
import { TRAVEL_EXPENSES_ROUTE } from '../../../containers/Navigation/constants';
import { updateTravelExpenses } from '../../../containers/TravelExpenses/actions';
import { sentStatusValidation } from '../../../validations/travelExpenses/travelExpensesValidation';
import ErrorPopup from '../../Errors/ErrorPopup/ErrorPopup';
import ActivityContainer from '../ActivityContainer/ActivityContainer';
import MainInformationContainer from '../MainInformationContainer/MainInformationContainer';
import TravelDepartureArrival from './TravelDepartureArrival';
import TravelTimeToHour from './TravelTimeToHours';
import SwipeableActions from '../../../../application/components/swipeable-actions';

const TravelExpensesActivity = ({ activity, onChangeTravelExpensesStatus }) => {
  const [validationErrors, setValidationErrors] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const translate = useTranslate('activity-cancellation-contirmation-dialog');

  const sendTravelExpense = () => {
    if (!isEmpty(sentStatusValidation(activity))) {
      setValidationErrors(true);
    } else {
      setValidationErrors(false);
      onChangeTravelExpensesStatus(activity, ActivityStatus.SENT);
    }
  };

  const handleChangeStatus = (activity, newStatus) => {
    if (
      newStatus === ActivityStatus.CANCELED &&
      activity.billNumberMap &&
      Object.keys(activity.billNumberMap).length > 0
    ) {
      setIsCancelConfirmationOpen(true);
    } else {
      onChangeTravelExpensesStatus(activity, newStatus);
    }
  };

  const cancelActivity = (activity) => {
    setIsCancelConfirmationOpen(false);
    onChangeTravelExpensesStatus(activity, ActivityStatus.CANCELED);
  };

  const swipeableActions = [
    {
      label: 'Envoyer',
      onClick: sendTravelExpense,
      theme: 'primary',
      icon: 'send'
    },
    {
      label: 'Attendre',
      onClick: () => onChangeTravelExpensesStatus(activity, ActivityStatus.WAITING),
      theme: 'secondary',
      icon: 'clock'
    }
  ];

  if (!activity.blocked) {
    swipeableActions.push({
      label: 'Annuler',
      onClick: () => handleChangeStatus(activity, ActivityStatus.CANCELED),
      theme: 'error',
      icon: 'ban'
    });
  }

  return (
    <Grow in>
      {/* Cannot be a fragment if we want Grow animation to work */}
      <div>
        <ErrorPopup
          message="Le frais de transport ne peut être envoyé car il n'est pas valide"
          isOpen={validationErrors}
          onClose={() => setValidationErrors(false)}
        />

        <Modal
          actions={[
            {
              label: translate('cancel'),
              onClick: () => setIsCancelConfirmationOpen(false),
              variant: 'ghost'
            },
            {
              label: translate('confirm'),
              onClick: () => cancelActivity(activity),
              variant: 'primary'
            }
          ]}
          open={isCancelConfirmationOpen}
          title={translate('title')}
        >
          <p>{translate('description')}</p>
          <p className="mt-4">{translate('confirmation-message')}</p>
        </Modal>
        <SwipeableActions actions={swipeableActions}>
          <ActivityContainer
            divider
            ContainerComponent="div"
            onClick={() => navigate({ pathname: `${TRAVEL_EXPENSES_ROUTE}/${activity.id}`, search: location.search })}
          >
            <MainInformationContainer activity={activity}>
              <TravelTimeToHour activity={activity} />
              <TravelDepartureArrival activity={activity} />
            </MainInformationContainer>
            <ListItemSecondaryAction>
              <DesktopActions
                activity={activity}
                onCancel={activity.blocked ? undefined : () => handleChangeStatus(activity, ActivityStatus.CANCELED)}
                onSend={sendTravelExpense}
              />
            </ListItemSecondaryAction>
          </ActivityContainer>
        </SwipeableActions>
      </div>
    </Grow>
  );
};

TravelExpensesActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  onChangeTravelExpensesStatus: PropTypes.func.isRequired
};

export const mapDispatchToProps = (dispatch) => ({
  onChangeTravelExpensesStatus: (activity, status) => dispatch(updateTravelExpenses(activity, { status }))
});

export default compose(connect(null, mapDispatchToProps))(TravelExpensesActivity);
