import moment from 'moment-timezone';
import { parseNam as coreParseNam } from '../../medyx-core/utilities/nam';

export const MALE = 'male';
export const FEMALE = 'female';

export const parseNam = (nam) => ({
  name: nam.slice(0, 4),
  year: parseInt(nam.slice(4, 6), 10),
  sex: sexFromNamMonth(nam.slice(6, 8)),
  month: monthFromNam(nam.slice(6, 8)),
  day: parseInt(nam.slice(8, 10), 10)
});

const sexFromNamMonth = (namMonth) => {
  const intNamMonth = parseInt(namMonth, 10);
  return intNamMonth > 50 ? FEMALE : MALE;
};

const monthFromNam = (relevantNumberFromNam) => parseInt(relevantNumberFromNam, 10) % 50;

export const patientIsMinor = (nam) => calculateAgeFromNam(nam) < 18;

export const patientIsElder = (nam) => calculateAgeFromNam(nam) > 75;

export const getPatientMinimumAge = (nam) => calculateAgeFromNam(nam);

const calculateAgeFromNam = (nam) => {
  const { birthYear, birthMonth, birthDay } = coreParseNam(nam);
  const birthDate = moment(`${birthYear}-${birthMonth}-${birthDay}`, 'YYYY-MM-DD');

  return Math.floor(moment().diff(birthDate, 'years', true));
};

export const namFormula = (nam, century) =>
  `${nam.slice(0, 4)}${century}${nam.slice(4, 6)}${sexAndMonth(nam.slice(6, 8))}${nam.slice(8, 11)}`;

const sexAndMonth = (relevantNumberFromNam) => {
  const intValue = parseInt(relevantNumberFromNam, 10);
  return intValue > 50 ? calculateMonthOfBirthForWoman(intValue) : calculateMonthOfBirthForMan(intValue);
};

const calculateMonthOfBirthForMan = (intValue) => (intValue < 10 ? `M0${intValue}` : `M${intValue}`);

const calculateMonthOfBirthForWoman = (intValue) => {
  const valueMinus50 = intValue - 50;
  return valueMinus50 < 10 ? `F0${valueMinus50}` : `F${valueMinus50}`;
};
