import React, { useRef, useState } from 'react';
import Icon from 'application/components/icon';
import classNames from 'classnames';
import { useClickAway } from 'react-use';
import { usePostHog } from 'posthog-js/react';

interface Action {
  label: string;
  icon?: string;
  onClick: () => void;
  theme?: 'primary' | 'secondary' | 'error';
}

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  actions: Action[];
}

enum OpenDirection {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT'
}

const SWIPE_RANGE = 400;
const SWIPE_THRESHOLD = 0.75;

const SwipeableActions = ({ children, disabled, actions }: Props) => {
  const ref = useRef(null);

  const posthog = usePostHog();
  const [isVisible, setIsVisible] = useState(false);
  const [openDirection, setOpenDirection] = useState<OpenDirection | null>(null);
  const [baseX, setBaseX] = useState<number | null>(null);
  const [animationProgress, setAnimationProgress] = useState(0);

  const handleDragProgress = (positionX: number) => {
    const deltaX = Math.abs(positionX - baseX!);
    setAnimationProgress(Math.min(deltaX / SWIPE_RANGE, 1));
    setOpenDirection(positionX > baseX! ? OpenDirection.RIGHT : OpenDirection.LEFT);
  };

  const handleTearDown = () => {
    setIsVisible(false);
    setAnimationProgress(0);
    setOpenDirection(null);
    setBaseX(null);
  };

  useClickAway(ref, handleTearDown);

  return (
    <div
      ref={ref}
      className="relative overflow-hidden"
      onTouchStart={(event) => {
        if (disabled) return;
        setIsVisible(true);
        setBaseX(event.changedTouches[0].clientX);
      }}
      onTouchEnd={() => {
        if (animationProgress === 1) return;
        if (animationProgress > SWIPE_THRESHOLD) {
          setAnimationProgress(1);
          return;
        }
        handleTearDown();
      }}
      onTouchMove={(event) => {
        if (disabled) return;
        handleDragProgress(event.changedTouches[0].clientX);
      }}
    >
      <div>{children}</div>

      {isVisible && (
        <div
          className="absolute inset-0 flex"
          style={{
            transform: `translateX(${openDirection === OpenDirection.RIGHT ? '-' : ''}${
              (1 - animationProgress) * 100
            }%)`,
            opacity: animationProgress * 2
          }}
        >
          {openDirection === OpenDirection.LEFT && (
            <button
              type="button"
              className="flex items-center justify-center bg-gray-800 px-5 text-white"
              onClick={() => handleTearDown()}
            >
              <Icon icon="arrow-right-long-to-line" />
            </button>
          )}
          {actions.map((action) => (
            <button
              type="button"
              key={action.label}
              className={classNames('flex w-full flex-auto flex-col items-center justify-center gap-1 text-white', {
                'bg-gray-400': !action.theme,
                'bg-error': action.theme === 'error',
                'bg-secondary': action.theme === 'secondary',
                'bg-primary': action.theme === 'primary'
              })}
              onClick={() => {
                handleTearDown();
                action.onClick();
                posthog.capture('Swipe action clicked', { action: action.label });
              }}
            >
              {action.icon && <Icon className="text-lg" icon={action.icon} />}
              {action.label}
            </button>
          ))}
          {openDirection === OpenDirection.RIGHT && (
            <button
              type="button"
              className="flex items-center justify-center bg-gray-800 px-5 text-white"
              onClick={() => handleTearDown()}
            >
              <Icon icon="arrow-left-long-to-line" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SwipeableActions;
