import Input from '@material-ui/core/Input';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';

export const styles = () => ({
  namInput: {
    textTransform: 'uppercase',
    width: 60,
    alignItems: 'flex-end'
  }
});

export class NamInputSection extends React.Component {
  onInputChange = (evt) => {
    const currentInputValue = evt.target.value;
    if (currentInputValue.length >= 4) {
      this.props.onInputFull();
      if (currentInputValue.length > 4) {
        evt.target.value = currentInputValue.slice(0, 4); // eslint-disable-line no-param-reassign
      }
    } else if (currentInputValue.length === 0) {
      this.props.onInputEmpty();
    }
    this.props.onChange();
  };

  onPaste = (event) => {
    const pastedText = event.clipboardData.getData('Text');
    if (!pastedText) return;

    this.props.updateNamInputs(pastedText);
  };

  canPreventKeyPress = (evt) => (evt.key.length === 1 || evt.key === 'Unidentified') && !evt.altKey && !evt.ctrlKey;

  onKeyDown = (evt) => {
    const regex = this.props.numeric ? /\d/ : /[a-zA-Z]/;

    const currentInputValue = evt.target.value;

    const char = evt.key;
    /*
     * if character is a simple char without modifier, it can be prevented
     * Then, if input is full, preventDefault and call onInputFull with character to pass it to the next input section
     * Otherwise, if it is not a number, just preventDefault
     */
    if (this.canPreventKeyPress(evt)) {
      if (!char.match(regex)) {
        evt.preventDefault();
        evt.stopPropagation();
      }
    } else if (currentInputValue.length === 0) {
      switch (char) {
        case 'Backspace':
        case 'ArrowLeft':
          this.props.onInputEmpty();
          break;
        case 'ArrowRight':
          this.props.onInputFull();
          break;
        default:
          break;
      }
    }
  };

  render() {
    const { reference, placeholder, numeric, initialValue, error, classes } = this.props;
    return (
      <Input
        key={initialValue}
        error={error}
        placeholder={placeholder}
        defaultValue={initialValue}
        className={classes.namInput}
        inputRef={reference}
        inputProps={{
          style: { textTransform: 'uppercase' },
          onKeyDown: this.onKeyDown,
          onChange: this.onInputChange,
          onPaste: this.onPaste,
          type: 'text',
          title: 'a',
          min: 0,
          // inputMode: 'numeric' on iOS now opens the normal keyboard in iOS 12.2: https://stackoverflow.com/a/47455885/3406911
          inputMode: numeric ? 'tel' : 'text',
          pattern: numeric ? '[0-9]*' : '[A-Z]*'
        }}
      />
    );
  }
}

NamInputSection.defaultProps = {
  error: false,
  numeric: false
};

NamInputSection.propTypes = {
  onChange: PropTypes.func.isRequired,
  numeric: PropTypes.bool,
  error: PropTypes.bool,
  initialValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  reference: PropTypes.func.isRequired,
  onInputFull: PropTypes.func.isRequired,
  onInputEmpty: PropTypes.func.isRequired,
  updateNamInputs: PropTypes.func.isRequired
};

export default compose(withStyles(styles))(NamInputSection);
