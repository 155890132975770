export const ADD_UPLOAD_TASK = 'addUploadTask';
export function addUploadTask(uploadTask) {
  return {
    type: ADD_UPLOAD_TASK,
    uploadTask
  };
}

export const LOCK_UPLOAD_TASK = 'lockUploadTask';
export function lockUploadTask(id) {
  return {
    type: LOCK_UPLOAD_TASK,
    id
  };
}

export const UPDATE_UPLOAD_TASK = 'updateUploadTask';
export function updateUploadTask(uploadTask) {
  return {
    type: UPDATE_UPLOAD_TASK,
    uploadTask
  };
}

export const UNLOCK_UPLOAD_TASK = 'unlockUploadTask';
export function unlockUploadTask(uploadTask) {
  return {
    type: UNLOCK_UPLOAD_TASK,
    uploadTask
  };
}

export const UPLOAD_SUCCESS = 'uploadSuccess';
export function uploadSuccess(uploadTask, downloadUrl) {
  return {
    type: UPLOAD_SUCCESS,
    uploadTask,
    downloadUrl
  };
}

export const UPLOAD_ERROR = 'uploadError';
export function uploadError(uploadTask, error) {
  return {
    type: UPLOAD_ERROR,
    uploadTask,
    error
  };
}
