import { eventChannel } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { logDefault, logError, logWarning } from '../../../shared/utils/plog';
import { reduxSagaFireBase } from '../../../server/Firebase';
import { UPLOAD_STATE_CHANGED } from '../../firebase/storage/storageRepository';
import { ADD_UPLOAD_TASK, updateUploadTask, uploadError, uploadSuccess } from './actions';

export function* updateCurrentUploadTask(task, snapshot) {
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  yield put(
    updateUploadTask({
      id: task.id,
      progress
    })
  );
}

const getFirebaseUploadTask = (storageReference, data, metadata = { contentType: 'image/jpeg' }) => {
  if (data instanceof File || data instanceof Blob || data instanceof ArrayBuffer) {
    return reduxSagaFireBase.storage.uploadFile(storageReference, data, metadata);
  }
  return reduxSagaFireBase.storage.uploadString(storageReference, data, 'data_url');
};

function* dispatchFailureUpload(error, uploadTask) {
  logWarning({
    type: 'saga',
    text: 'Storage/dispatchFailureUpload',
    array: ['dispatchFailureUpload', error, uploadTask && uploadTask.id]
  });

  yield put(uploadError(uploadTask));
}

export function* upload(action) {
  const { id, storageReference, data, metadata } = action.uploadTask;

  try {
    const networkIsOnline = navigator.onLine;
    // Don't even try to upload when offline
    if (networkIsOnline) {
      logDefault({
        type: 'saga',
        text: 'Storage/upload',
        array: [
          'starting upload (id, typeof data, data.size || .length) ',
          id,
          typeof data,
          data && (data.size || data.length)
        ]
      });

      const firebaseUploadTask = getFirebaseUploadTask(storageReference, data, metadata);
      const channel = eventChannel((emit) => firebaseUploadTask.on(UPLOAD_STATE_CHANGED, emit));
      yield takeEvery(channel, updateCurrentUploadTask, action.uploadTask);
      yield firebaseUploadTask;
      const downloadUrl = yield call([firebaseUploadTask.snapshot.ref, firebaseUploadTask.snapshot.ref.getDownloadURL]);

      logDefault({
        type: 'saga',
        text: 'Storage/upload',
        array: ['Upload completed', downloadUrl]
      });

      yield put(uploadSuccess(action.uploadTask, downloadUrl));

      return downloadUrl;
    }
    yield* dispatchFailureUpload(new Error('No internet connexion, did not try to upload'), action.uploadTask);
  } catch (error) {
    logError({
      type: 'saga',
      text: 'Storage/upload',
      array: ['uploading failed', id, error]
    });
    yield* dispatchFailureUpload(error, action.uploadTask);
  }

  return null;
}

export default function* uploadSaga() {
  yield takeEvery(ADD_UPLOAD_TASK, upload);
}
