import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import InputDialogTrigger from '../../../components/Dialogs/DialogTrigger/InputDialogTrigger/InputDialogTrigger';
import FormRow from '../../../components/Form/FormRow/FormRow';

export const styles = (theme) => ({
  inputContainer: {
    position: 'relative',
    width: '100%'
  },
  input: {
    paddingRight: theme.spacing(6)
  },
  placeSelectorContainer: {
    marginBottom: theme.spacing(1)
  }
});

export function PatientEventSelector(props) {
  const { classes, DialogComponent, formatValueFunction, input, label, meta } = props;

  return (
    <FormRow className={classes.placeSelectorContainer}>
      <div className={classes.inputContainer}>
        <InputDialogTrigger
          inputClasses={classes.input}
          input={input}
          label={label}
          meta={meta}
          formatValueFunction={formatValueFunction}
          DialogComponent={DialogComponent}
        />
      </div>
    </FormRow>
  );
}

PatientEventSelector.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  formatValueFunction: PropTypes.func.isRequired,
  DialogComponent: PropTypes.func.isRequired
};

export default withStyles(styles)(PatientEventSelector);
