import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const DURATION_MORE_THAN_60_MINUTES = 'durationMoreThan60Minutes';

const summaryRender = (code) =>
  code[DURATION_MORE_THAN_60_MINUTES] !== undefined
    ? [
        {
          name: '> 60 minutes',
          value: code[DURATION_MORE_THAN_60_MINUTES] ? 'Oui' : 'Non'
        }
      ]
    : [];

const associatedFields = [
  {
    label: 'Durée > 60 minutes',
    getName: (index) => `codes[${index}].${DURATION_MORE_THAN_60_MINUTES}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  }
];

const durationMoreThan60Minutes = { associatedFields, summaryRender };

export default durationMoreThan60Minutes;
