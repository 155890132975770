import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import Section from 'application/components/form-controls/section';
import FieldContainer from 'application/components/form-controls/field-container';
import { Referral } from 'application/pages/settings/settings.referral';
import SettingsReferralStatus from 'application/pages/settings/referral/referral-status';

interface Props {
  referrals: Referral[];
  loading: boolean;
}

const getFullName = (referral: Referral): string =>
  referral.referredUser
    ? `${referral.referredUser.lastName} ${referral.referredUser.firstName}`
    : `${referral.lastName} ${referral.firstName}`;

const SettingsReferralList = ({ referrals, loading }: Props) => {
  const translate = useTranslate('pages.settings.referral.referral-list');

  if (loading || !referrals.length) {
    return;
  }

  return (
    <Section label={translate('title')}>
      {referrals.map((referral: Referral) => (
        <FieldContainer label={getFullName(referral)} key={referral.id}>
          <SettingsReferralStatus referral={referral} />
        </FieldContainer>
      ))}
    </Section>
  );
};

export default SettingsReferralList;
