import { call } from 'redux-saga/effects';
import moment from 'moment-timezone';
import {
  INTENSIVE_CARE_ON_GUARD_DAY,
  INTENSIVE_CARE_UNIT_SUPPORT_DAY
} from '../../../../../shared/ramq/contextElements/globalContextElements';
import HOSPITAL_ACTIVITY_AREA_MAP from '../../../../../shared/ramq/domainValues/activityAreas';
import { logDefault, logError } from '../../../../../shared/utils/plog';
import { trackErrorInFirestore } from '../../../../firebase/document/documentSagas';
import duplicateActsFromYesterdayIfNeeded from './actsDuplication';
import { updateDocumentPromise } from './contextElementUpdate';
import {
  doctorIsOnIntensiveCareOnGuardOnThisDay,
  doctorIsOnIntensiveCareUnitSupportOnThisDay,
  getActsForSpecificDate
} from './utils';
import { isActBetween0And7 } from '../../../../../shared/act/domain/utils';

export const processIntensiveCareActsAsync = async (document, userProfile) => {
  logDefault({
    type: 'saga',
    text: 'processIntensiveCareActsAsync',
    array: ['processIntensiveCareActsAsync starting']
  });
  const savedActsInCurrentDay = await getActsForSpecificDate(document.date, userProfile.id);

  let actsInCurrentDay = savedActsInCurrentDay.filter(({ start }) => {
    const todayAt7 = moment(document.date).hours(7).startOf('hour');

    return moment(start).isSameOrAfter(todayAt7);
  });

  if (doctorIsOnIntensiveCareUnitSupportOnThisDay(actsInCurrentDay)) {
    await duplicateActsFromYesterdayIfNeeded(document, userProfile, actsInCurrentDay);
  }

  if (isActBetween0And7(document)) {
    const savedActsInDayBefore = await getActsForSpecificDate(
      moment(document.date).subtract(1, 'day').valueOf(),
      userProfile.id
    );

    const yesterDayAt7 = moment(document).subtract(1, 'day').hours(7);

    actsInCurrentDay = savedActsInDayBefore.filter(({ start }) => moment(start).isSameOrAfter(yesterDayAt7));
  }

  await updateIntensiveCareContextElements(actsInCurrentDay);
};

export const updateIntensiveCareContextElements = async (acts) => {
  const isOnUnit = doctorIsOnIntensiveCareUnitSupportOnThisDay(acts);
  const isOnGuard = doctorIsOnIntensiveCareOnGuardOnThisDay(acts);

  const updatePromises = acts.map(async (act) => {
    let contextElements = act.contextElements || [];
    let { activityArea } = act;
    const originalNumberOfContextElements = contextElements.length;
    const originalActivityArea = act.activityArea;

    if (isOnUnit) {
      if (!contextElements.includes(INTENSIVE_CARE_UNIT_SUPPORT_DAY)) {
        contextElements.push(INTENSIVE_CARE_UNIT_SUPPORT_DAY);
      }
      if (!activityArea) {
        activityArea = HOSPITAL_ACTIVITY_AREA_MAP.intensiveCareUnit;
      }
    } else {
      contextElements = contextElements.filter((element) => element !== INTENSIVE_CARE_UNIT_SUPPORT_DAY);
    }

    if (isOnGuard) {
      if (!contextElements.includes(INTENSIVE_CARE_ON_GUARD_DAY)) {
        contextElements.push(INTENSIVE_CARE_ON_GUARD_DAY);
      }
    } else {
      contextElements = contextElements.filter((element) => element !== INTENSIVE_CARE_ON_GUARD_DAY);
    }

    if (contextElements.length !== originalNumberOfContextElements || activityArea !== originalActivityArea) {
      await updateDocumentPromise(act, contextElements, activityArea);
    }
  });

  await Promise.all(updatePromises);
};

// Can come from an "update" instead of a "set" which includes "changes"
// We also want to run the optimization on the latest document version that would include the updated fields
export function* runIntensiveCareOptimizations({ document, changes, userProfile }) {
  try {
    const updatedDocument = { ...document, ...changes };
    yield call(processIntensiveCareActsAsync, updatedDocument, userProfile);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'runIntensiveCareOptimizations',
      array: ['error running processIntensiveCareActsAsync', JSON.stringify(document), e]
    });
    yield* trackErrorInFirestore(e, { type: 'intensiveCareOptimizations', document, changes });
  }
}

export default runIntensiveCareOptimizations;
