import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  text: {
    display: 'flex',
    alignItems: 'center',
    width: 20,
    height: 20,
    marginRight: theme.spacing(1)
  }
});

function TextIcon({ text, classes }) {
  return (
    <Typography variant="caption" className={classes.text}>
      {text}
    </Typography>
  );
}

TextIcon.propTypes = {
  text: PropTypes.string.isRequired
};

export default withStyles(styles)(TextIcon);
