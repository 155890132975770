import moment from 'moment-timezone';
import Act from '../../../../shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';
import {
  ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS,
  ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS,
  COEUR_POUMON,
  GREATER_THAN_4_HOURS
} from '../../../../shared/ramq/contextElements/globalContextElements';
import { codeIsInRole2 } from '../../../../shared/domain/activity/act/service/actCodeUtils';
import { RAMQContextElements } from '../../../../shared/ramq/RamqContextElements';
import { RAMQCodes, TimedAttributeKey } from '../../../../shared/ramq/RamqCodes';
import { ActCode, NatureType } from '../../../../shared/core/activity/domain/Act';
import { getStore } from '../../../reduxStore/configureStore';
import { selectSpecialty } from '../../../containers/User/selectors';

const excludedCodes = ['911'];

const addContextElement1922Recommendation = (act: Partial<Act>): Recommendation<string[] | undefined>[] => {
  const { contextElements = [], codes, date, remp } = act;

  if (!codes || !date || remp || contextElements.includes(COEUR_POUMON)) {
    return NO_RECOMMENDATIONS;
  }

  const contextElement = RAMQContextElements.get('1922');

  if (
    contextElement &&
    RAMQContextElements.isEffectiveOn(contextElement, date) &&
    !hasGreaterThan4HoursContextElement(act) &&
    isDurationInHoursAtLeast(act, 4) &&
    codes.find((code) => codeIsApplicable(code, act as Act))
  ) {
    if (!isDurationInHoursAtLeast(act, 8)) {
      return [
        {
          message:
            "La durée est de plus de 4 heures, svp cliquer sur le crochet pour ajouter l'élément de contexte 1922 (règle 16)",
          apply: (partialAct) => ({
            fieldName: 'contextElements',
            newValue: [...(contextElements || []), GREATER_THAN_4_HOURS],
            oldValue: partialAct.contextElements
          })
        }
      ];
    }
  }

  return NO_RECOMMENDATIONS;
};

const codeIsApplicable = (code: ActCode, act: Act) => {
  if (excludedCodes.includes(code.code)) {
    return false;
  }

  const userSpecialty = selectSpecialty()(getStore().getState());
  const ramqCode = RAMQCodes.get(code.code);

  return (
    ramqCode &&
    !code.isSupplement &&
    codeIsInRole2(code, act, userSpecialty) &&
    [NatureType.ANESTHESIOLOGY, NatureType.PDT, NatureType.SURGERY].includes(ramqCode.natureType) &&
    !RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isAddendum8, act.date)
  );
};

const hasGreaterThan4HoursContextElement = (act: Partial<Act>) =>
  act.contextElements?.find((contextElement) =>
    [
      GREATER_THAN_4_HOURS,
      ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS,
      ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS
    ].includes(contextElement)
  );

const isDurationInHoursAtLeast = (act: Partial<Act>, hours: number) => {
  if (!act.start || !act.end || act.remp) {
    return false;
  }
  return moment(act.end).diff(moment(act.start), 'minutes') >= hours * 60;
};

export default addContextElement1922Recommendation;
