import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import Icon from 'application/components/icon';
import useTranslate from 'application/hooks/use-translate';
import { getBuildNumber } from 'shared/utils/executionEnvironment';
import {
  ADMINISTRATION_ROUTE,
  GROUPS_ROUTE,
  GROUP_DETAILS,
  GROUP_REPORTS,
  GROUP_USERS,
  USERS_ROUTE
} from 'application/routes';
import {
  ACTIVITIES_ROUTE,
  ADMIN_REPORT_ROUTE,
  ASSISTANT_ROUTE,
  REPORTS_ROUTE,
  SETTINGS_ROUTE
} from 'app/containers/Navigation/constants';
import Role from 'shared/domain/authentication/Role';
import Restrict from 'application/components/restrict';
import Button from 'application/components/button';
import AuthenticationServiceProvider from 'app/infrastructure/authentication/service/AuthenticationServiceProvider';
import { selectActivities } from 'app/containers/Assistant/selectors';
import Activity from 'shared/core/activity/domain/Activity';
import { useLocalStorage, useToggle } from 'react-use';
import Logo from 'application/components/logo';
import { LocalStorageKeys } from 'userCustomization/LocalStorageKeys';

interface FetchUserNavigation {
  authenticatedUser: {
    id: string;
    managingPools: Array<{
      id: string;
      name: string;
      number: string;
      role: string;
    }>;
  };
}

const CURRENT_USER_NAV_QUERY = gql`
  query FetchUserNavigation {
    authenticatedUser {
      id
      managingPools: groups(types: ["pool"], roles: ["manager"]) {
        id
        role
        number
        name
      }
    }
  }
`;

const SideNavigation = () => {
  const translate = useTranslate('Navigation');
  const advices = useSelector<unknown, Activity[]>(selectActivities());
  const { data } = useQuery<FetchUserNavigation>(CURRENT_USER_NAV_QUERY);

  const [sideNavigationCollapsed, setSideNavigationCollapsed] = useLocalStorage<boolean>(
    LocalStorageKeys.SIDE_NAVIGATION_COLLAPSED,
    false
  );

  const [collapsed, toggleCollapted] = useToggle(sideNavigationCollapsed ?? false);

  useEffect(() => {
    setSideNavigationCollapsed(collapsed);
  }, [collapsed, setSideNavigationCollapsed]);

  return (
    <div
      className={classnames('flex h-full flex-col bg-primary-800 transition-all ease-in-out', {
        'w-[255px]': !collapsed,
        'w-[80px]': collapsed
      })}
    >
      <div className="flex h-[105px] flex-none justify-center bg-primary-900 text-center align-middle">
        <Logo displayEnvironmentBadge type={collapsed ? 'mark' : 'wordmark'} />
      </div>

      <div className="grow space-y-6 overflow-y-auto overflow-x-hidden py-4 text-white">
        <div className="flex flex-col gap-y-1">
          <h2 className={classnames('px-4 py-2 font-bold', { 'opacity-0': collapsed })}>{translate('billing')}</h2>

          <SideNavigationAction
            destination={ACTIVITIES_ROUTE}
            displayLabel={!collapsed}
            icon="list-timeline"
            label={translate('home')}
          />
          <SideNavigationAction
            badge={advices.length > 0 ? advices.length : undefined}
            displayLabel={!collapsed}
            destination={ASSISTANT_ROUTE}
            icon="message-smile"
            label={translate('assistant')}
          />
          <SideNavigationAction
            destination={REPORTS_ROUTE}
            displayLabel={!collapsed}
            icon="receipt"
            label={translate('reports')}
          />
        </div>

        <Restrict isRole={Role.Doctor}>
          {data &&
            data?.authenticatedUser?.managingPools.map((pool) => (
              <div className="flex flex-col gap-y-1" key={`pool-${pool.id}`}>
                <h2 className={classnames('truncate px-4 py-2 font-bold', { 'opacity-0': collapsed })}>
                  {data?.authenticatedUser?.managingPools.length === 1 ? translate('pool') : pool.name}
                </h2>

                <SideNavigationAction
                  destination={`${GROUPS_ROUTE}/${pool.id}/${GROUP_DETAILS}`}
                  displayLabel={!collapsed}
                  icon="square-info"
                  label={translate('pool.details')}
                />
                <SideNavigationAction
                  destination={`${GROUPS_ROUTE}/${pool.id}/${GROUP_USERS}`}
                  displayLabel={!collapsed}
                  icon="user-group"
                  label={translate('pool.members')}
                />
                <SideNavigationAction
                  destination={`${GROUPS_ROUTE}/${pool.id}/${GROUP_REPORTS}`}
                  displayLabel={!collapsed}
                  icon="receipt"
                  label={translate('pool.reports')}
                />
              </div>
            ))}
        </Restrict>

        <Restrict atLeastRole={Role.Agent}>
          <div className="flex flex-col gap-y-1">
            <h2 className={classnames('px-4 py-2 font-bold', { 'opacity-0': collapsed })}>{translate('console')}</h2>

            <SideNavigationAction
              destination={USERS_ROUTE}
              displayLabel={!collapsed}
              icon="user"
              label={translate('users')}
            />
            <SideNavigationAction
              destination={GROUPS_ROUTE}
              displayLabel={!collapsed}
              icon="user-group"
              label={translate('groups')}
            />
            <SideNavigationAction
              destination={ADMIN_REPORT_ROUTE}
              displayLabel={!collapsed}
              icon="receipt"
              label={translate('adminReport')}
            />

            <Restrict atLeastRole={Role.Admin}>
              <SideNavigationAction
                destination={ADMINISTRATION_ROUTE}
                displayLabel={!collapsed}
                icon="screwdriver-wrench"
                label={translate('administration')}
              />
            </Restrict>
          </div>
        </Restrict>
      </div>

      <div className="flex-none">
        <div className="flex flex-col gap-y-1">
          <SideNavigationAction
            destination={SETTINGS_ROUTE}
            displayLabel={!collapsed}
            icon="sliders"
            label={translate('settings')}
          />

          <SideNavigationAction
            displayLabel={!collapsed}
            icon="right-from-bracket"
            label={translate('logout')}
            onClick={() => AuthenticationServiceProvider.getInstance().signOut()}
          />
        </div>

        <div
          className={classnames('mt-4 flex items-center justify-center bg-primary-900', {
            'pl-8': !collapsed
          })}
        >
          {!collapsed && (
            <span className="grow overflow-hidden text-nowrap text-xs text-primary-400">
              Version : {getBuildNumber()}
            </span>
          )}

          <button className={classnames('h-[50px] w-[50px] justify-self-end')} onClick={toggleCollapted} type="button">
            <Icon
              className={classnames('text-base text-white transition-all', {
                'rotate-0': !collapsed,
                'rotate-180': collapsed
              })}
              icon="arrow-left-from-line"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const SideNavigationAction = ({
  badge,
  destination,
  displayLabel = true,
  icon,
  label,
  onClick
}: {
  badge?: number | string;
  destination?: string;
  displayLabel?: boolean;
  icon: string;
  label: string;
  onClick?: () => void;
}) => {
  const { pathname } = useLocation();

  const Children = ({ badge, icon, label }: { badge?: number | string; icon: string; label: string }) => (
    <div className="relative inline-flex w-full items-center justify-start gap-3 text-nowrap">
      <Icon fixedWidth icon={icon} />
      {displayLabel && label}
      {badge && displayLabel && <span className="badge badge-sm absolute right-0 opacity-30">{badge}</span>}
      {badge && !displayLabel && <div className="absolute -end-1 -top-1 size-2 rounded-full bg-red-500" />}
    </div>
  );

  const SIDE_NAVIGATION_ACTION_BASE_CLASSES = `
    ease-in-out
    hover:bg-primary-900
    px-4
    py-2
    mx-4
    relative
    rounded
    text-white
    transition
  `;

  if (destination) {
    return (
      <NavLink
        className={classnames(SIDE_NAVIGATION_ACTION_BASE_CLASSES, {
          'bg-primary-900': pathname.startsWith(`/${destination}`)
        })}
        to={destination}
      >
        {Children({ badge, icon, label })}
      </NavLink>
    );
  }

  if (onClick) {
    return (
      <Button className={SIDE_NAVIGATION_ACTION_BASE_CLASSES} type="button" onClick={onClick}>
        {Children({ badge, icon, label })}
      </Button>
    );
  }
};

export default SideNavigation;
