/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */

import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  'rightAlignCell': {
    textAlign: 'right'
  },
  'titleGrow': {
    flexGrow: 1
  },
  'row': {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  },
  'subtitleToolbar': {
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white
  },
  'tableHead': {
    backgroundColor: theme.palette.primary[500]
  },
  'tableHeadCell': {
    color: theme.palette.common.white
  },
  'statementTableWrapper': {
    display: 'flex',
    gap: 16
  },
  '@media print': {
    root: {
      paddingTop: 16,
      marginBottom: 16
    }
  }
});

function TableHeader({ title, titleSum }, classes) {
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell className={classes.tableHeadCell} classes={{ root: classes.denseTableCell }}>
          <Typography variant="subtitle1" color="inherit" data-testid="statementTable-title">
            {title}
          </Typography>
        </TableCell>
        {!isEmpty(titleSum) && (
          <TableCell
            className={classes.tableHeadCell}
            classes={{ root: classes.denseTableCell }}
            align="right"
            colSpan={3}
          >
            <Typography variant="subtitle1" color="inherit">
              {titleSum}
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

function TableContent(data, columns, classes) {
  return (
    <>
      <TableHead>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell
              key={index}
              className={classNames({ [classes.rightAlignCell]: column.align === 'right' }, 'tableColumnName')}
              size="small"
            >
              {column.name}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((line, index) => (
          <TableRow key={index} className={classes.row}>
            {columns.map((column, columnIndex) => (
              <TableCell
                className={classNames({ [classes.rightAlignCell]: column.align === 'right' })}
                key={columnIndex}
                size="small"
              >
                {column.primaryText(line)}
                <br />
                {column.secondaryText && column.secondaryText(line)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  );
}

const separateInTwo = (original, firstArray, secondArray) => {
  original.forEach((value, index) => {
    if (index % 2) {
      secondArray.push(value);
    } else {
      firstArray.push(value);
    }
  });
};

export function StatementTable({ title, titleSum, columns, data, doubled, classes }) {
  if (isEmpty(data)) {
    return null;
  }

  let firstRow = data;
  let secondRow = [];

  if (doubled) {
    const firstArray = [];
    const secondArray = [];

    separateInTwo(firstRow, firstArray, secondArray);

    firstRow = firstArray;
    secondRow = secondArray;
  }

  return (
    <div className={classes.root}>
      {!isEmpty(title) && <Table size="small">{TableHeader({ title, titleSum }, classes)}</Table>}
      <div className={classes.statementTableWrapper} data-testid="statementTable-content">
        {!isEmpty(firstRow) && <Table size="small">{TableContent(firstRow, columns, classes)}</Table>}
        {!isEmpty(secondRow) && <Table size="small">{TableContent(secondRow, columns, classes)}</Table>}
      </div>
    </div>
  );
}

StatementTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  doubled: PropTypes.bool,
  titleSum: PropTypes.string
};

StatementTable.defaultProps = {
  titleSum: '',
  doubled: false
};

export default withStyles(styles)(StatementTable);
