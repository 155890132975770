import moment from 'moment-timezone';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';
import { findCodeDetail } from '../../../../shared/data/actCodesData';

export interface IntensiveCare900RecommendationActs {
  date: Date | number;
  codes: Array<{
    code: string;
  }>;
}

const switchNonIntensiveCare900Recommendation = (
  act: Partial<Act>,
  activitiesInContext: IntensiveCare900RecommendationActs[]
): Recommendation<ActCode[] | undefined>[] => {
  if (!act.start || !act.codes?.some((code) => code.code === '900')) {
    return NO_RECOMMENDATIONS;
  }

  let message = '';
  let actsThatShouldContain9295Code: IntensiveCare900RecommendationActs[] = [];
  const actStart = moment(act.start);

  if (
    actStart.isBetween(
      actStart.clone().startOf('day'),
      actStart.clone().startOf('day').set({ hour: 7 }),
      'minute',
      '[)'
    )
  ) {
    message = "Remplacer le code 900 par le 928 car le code 9295 n'est pas présent la veille.";
    const previousDay = actStart.clone().subtract(1, 'day').date();
    actsThatShouldContain9295Code = activitiesInContext.filter(
      (contextAct) => moment(contextAct.date).date() === previousDay
    );
  } else {
    message = "Remplacer le code 900 par le 928 car le code 9295 n'est pas présent dans la journée.";
    actsThatShouldContain9295Code = activitiesInContext.filter(
      (contextAct) => moment(contextAct.date).date() === actStart.date()
    );
  }

  if (actsThatShouldContain9295Code.some(({ codes }) => codes?.some((code) => code.code === '9295'))) {
    return NO_RECOMMENDATIONS;
  }

  return [
    {
      message,
      apply: (partialAct) => {
        const oldValue = partialAct.codes;
        const newValue = partialAct.codes?.map((actCode) => {
          if (actCode.code === '900') {
            return {
              ...actCode,
              ...findCodeDetail('928')
            };
          }

          return actCode;
        });

        return {
          fieldName: 'codes',
          newValue,
          oldValue
        };
      }
    }
  ];
};

export default switchNonIntensiveCare900Recommendation;
