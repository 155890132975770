/* eslint-disable max-len */
import capitalize from 'lodash/capitalize';
import User from '../../../../shared/domain/user/User';
import Period from '../../../../shared/periods/common/Period';

interface ReportLink {
  name: string;
  url: string;
}

function buildBody(doctor: User, billingPeriod: Period, reports: ReportLink[]) {
  return `
Bonjour ${capitalize(doctor.title)}. ${doctor.lastName},

Voici votre état de compte du ${billingPeriod
    .getStatementDate()
    .format(
      'DD-MM-YYYY'
    )} et votre document de facturation de la dernière quinzaine (actes, mixtes et forfaitaires) transmis à la Régie au plus tard le ${Period.getNextPeriodFromPeriod(
    billingPeriod
  )
    .getEnd()
    .format('DD-MM-YYYY')}

    
${reports.map((report) => `${report.name}: ${report.url}`).join('\n')}

Veuillez noter que les liens sont valides pour une période de 12 mois. S'il vous plaît, téléchargez les documents avant leur expiration. Si les liens sont expirés, veuillez communiquer avec nous pour les obtenir à nouveau.

Bonne journée
`;
}

export default buildBody;
