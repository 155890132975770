import { ActCode } from './ActCode';
import { RequiredDetail, RequiredDetails } from '../requiredDetail.type';
import endTimeRequired from '../EndTimeRequired/endTimeRequired';
import placeSelection from '../PlaceSelection/placeSelection';
import telemedicineOrRemote from '../TelemedicineOrRemote/telemedicineOrRemote';
import canceledActivities from '../CanceledActivities/canceledActivities';
import unitNumberMeasurementElement from '../UnitNumberMeasurementElement/unitNumberMeasurementElement';

export default class ActCode42187 implements ActCode {
  getRequiredDetails(): RequiredDetails {
    return [
      unitNumberMeasurementElement,
      endTimeRequired as RequiredDetail,
      placeSelection,
      telemedicineOrRemote,
      canceledActivities
    ];
  }
}
