import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import Status from '../../Status/Status';
import ActivityAmount from '../ActivityAmount/ActivityAmount';
import BillingType from '../BillingType';

export const styles = (theme) => ({
  simplifiedViewContent: {
    display: 'flex',
    minHeight: 70,
    width: '100%'
  },
  informationContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '100%',
    overflow: 'hidden'
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    height: '100%'
  },
  manualCorrection: {
    color: theme.palette.red[300]
  }
});

export function MainInformationContainer({ activity, children, noBoxShadow, classes }) {
  return (
    <div className={classes.simplifiedViewContent}>
      <div className={classes.informationContainer}>{children}</div>
      <div className={classes.statusContainer}>
        <Status status={activity.status} noBoxShadow={noBoxShadow} dense />
        <BillingType activity={activity} />
        <ActivityAmount activity={activity} />
      </div>
    </div>
  );
}

MainInformationContainer.defaultProps = {
  noBoxShadow: false
};

MainInformationContainer.propTypes = {
  noBoxShadow: PropTypes.bool,
  activity: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(MainInformationContainer);
