const styles = (theme) => ({
  card: {
    maxWidth: 700,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  formContainer: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  cardContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  submitButtonGroup: {
    display: 'flex',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  namFieldWrapper: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  namIndicator: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(-1.25)
    }
  },
  patientIdentificationForm: {
    maxWidth: 130,
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    paddingLeft: theme.spacing(2),
    marginRight: 80,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0
    }
  },
  timeSelectorsContainer: {
    paddingTop: theme.spacing(1),
    marginBottom: 0,
    position: 'relative'
  },
  invisibleElement: {
    visibility: 'hidden'
  },
  nonDisplayedElement: {
    display: 'none'
  },
  namContainer: {
    position: 'relative',
    // This hack is needed to the absolute NoNamCollapsableIcon doesn't overlap the PatientIdentificationTypeDropDown
    [theme.breakpoints.down('xs')]: {
      marginTop: -25,
      paddingTop: 25
    }
  },
  urgentCallDetails: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3)
    }
  },
  patientEventRow: {
    flexWrap: 'unset'
  },
  patientEventType: {
    flex: 'unset',
    paddingRight: theme.spacing(2),
    width: '100%'
  },
  placeContainer: {
    position: 'relative',
    width: '100%'
  },
  adminContainer: {
    marginBottom: theme.spacing(3)
  },
  manualCorrection: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(3)
  },
  paidAmount: {
    marginTop: theme.spacing(1.5)
  },
  namAdmissibilityWarning: {
    marginBottom: theme.spacing(3)
  },
  patientContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  namWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'start'
  },
  ramqPatientInformations: {
    fontSize: '0.80rem',
    marginTop: theme.spacing(0.5)
  },
  namTextInput: {
    flex: 'none'
  },
  cancelButton: {
    color: 'white',
    backgroundColor: theme.palette.red[500]
  }
});

export default styles;
