import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ForwardIcon from '@material-ui/icons/ArrowForwardIos';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import messages from '../../AppHeader/messages';
import HeaderPortal from '../../Portals/HeaderPortal/HeaderPortal';
import deviceDetector from '../../utils/DeviceDetector';

export const styles = (theme) => ({
  root: {
    position: 'absolute',
    height: theme.spacing(4),
    width: 'auto',
    left: [deviceDetector.isBrowser() ? theme.spacing(2) : 0],
    color: theme.palette.common.white,
    top: 0
  },
  right: {
    left: 'unset',
    right: [deviceDetector.isBrowser() ? theme.spacing(2) : 0],
    [theme.breakpoints.up('md')]: {
      left: 'unset'
    }
  },
  label: {
    justifyContent: 'space-between',
    height: 'inherit'
  },
  icon: {
    fontSize: theme.spacing(2)
  }
});

export function NavigationHeaderButton({
  urlToGoOnClick,
  label,
  position,
  className,
  preventRenderOnAdminConsole,
  classes,
  actionToMakeOnClick
}) {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <HeaderPortal preventRenderOnAdminConsole={preventRenderOnAdminConsole}>
      <IconButton
        classes={{ label: classes.label }}
        className={classNames(classes.root, { [classes.right]: position === 'right' }, className)}
        onClick={actionToMakeOnClick || (() => navigate({ pathname: urlToGoOnClick, search }))}
      >
        {position === 'right' ? (
          <>
            <Typography color="inherit" variant="caption">
              {label || <FormattedMessage {...messages.next} />}
            </Typography>
            <ForwardIcon className={classNames(classes.icon)} />
          </>
        ) : (
          <>
            <BackIcon className={classNames(classes.icon)} />
            <Typography color="inherit" variant="caption">
              {label || <FormattedMessage {...messages.back} />}
            </Typography>
          </>
        )}
      </IconButton>
    </HeaderPortal>
  );
}

NavigationHeaderButton.propTypes = {
  urlToGoOnClick: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['right', 'left']).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  preventRenderOnAdminConsole: PropTypes.bool,
  actionToMakeOnClick: PropTypes.func
};

NavigationHeaderButton.defaultProps = {
  preventRenderOnAdminConsole: false,
  actionToMakeOnClick: undefined
};

NavigationHeaderButton.defaultProps = {
  label: undefined,
  className: ''
};

export default withStyles(styles)(NavigationHeaderButton);
