import React from 'react';
import { Fields } from 'redux-form';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR } from '../../../../userCustomization/featureNames';
import { actFormFields } from '../../../containers/ActForm/constants';
import NewContextElementsSelector from '../NewContextElementsSelector/NewContextElementsSelector';
import FormElement from '../../Form/FormElement/FormElement';
import LegacyContextElementsSelector from '../LegacyContextElementsSelector/LegacyContextElementsSelector';
import SelectedRamqContextElements from '../SelectedRamqContextElements/SelectedRamqContextElements';

export const ContextElementsSelector = () => (
  <>
    {getFeatureConfig(CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR, false) ? (
      <Fields
        data-testid="context-elements-selector"
        names={[actFormFields.contextElements, actFormFields.ramqContextElements]}
        component={NewContextElementsSelector as React.ComponentType}
      />
    ) : (
      <FormElement
        data-testid="context-elements-selector"
        name={actFormFields.contextElements}
        component={LegacyContextElementsSelector}
      />
    )}
    <FormElement
      data-testid="ramq-context-elements-selector"
      name={actFormFields.ramqContextElements}
      component={SelectedRamqContextElements}
    />
  </>
);

export default ContextElementsSelector;
