import moment from 'moment';

const enumerateDaysBetweenDates = (startDate, endDate) => {
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  if (!momentStartDate.isValid()) throw new Error('startDate argument must be a valid date');
  if (!momentEndDate.isValid()) throw new Error('endDate argument must be a valid date');
  if (momentStartDate.isAfter(momentEndDate, 'day')) throw new Error('startDate must be anterior or equal to endDate');
  if (momentStartDate.isSame(momentEndDate, 'day')) return [startDate];

  let currentDay = momentStartDate;
  const period = [currentDay.toDate()];

  do {
    currentDay = currentDay.add(1, 'days');
    period.push(currentDay.toDate());
  } while (!currentDay.isSame(momentEndDate));

  return period;
};

export default enumerateDaysBetweenDates;
