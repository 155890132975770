import { call, put, select, takeEvery } from 'redux-saga/effects';

import storageUploader from '../../../Storage/uploader/StorageUploader';
import { selectActivityPeriodId, selectAttachments } from './selectors';
import { PERIOD_ATTACHMENT_TYPE } from './constants';
import { UPLOAD_ERROR, UPLOAD_SUCCESS } from '../../../Storage/actions';
import { PERIOD_FILE_READY_FOR_UPLOAD, updateSavedAttachmentInPeriodForm } from './actions';
import { SYNCHRONIZED_ATTACHMENT } from '../../../Storage/cordova/constants';
import {
  getUpdatedAttachments,
  removeTemporaryFileFromDeviceLocalStorage,
  updateAttachmentInPersistedAct
} from '../../../ActForm/sagas';
import { trackErrorInFirestore } from '../../../../firebase/document/documentSagas';
import { getStore } from '../../../../reduxStore/configureStore';
import actAttachmentRepository from '../../../Storage/cordova/actAttachmentRepository';
import { selectUserIdInContext } from '../../../User/selectors';
import StorageUploadContext from '../../../Storage/uploader/StorageUploadContext';
import ActFile from '../../../Storage/cordova/ActFile';
import { logDefault, logError } from '../../../../../shared/utils/plog';

export function* periodFileReadyForUpload(action) {
  logDefault({
    type: 'saga',
    text: 'PeriodSagas/periodFileReadyForUpload',
    array: ['Starting periodFileReadyForUpload. action is', action]
  });

  try {
    const uploadContext = yield* getUploadContext(action);

    storageUploader.initiateUpload(uploadContext);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'PeriodSagas/periodFileReadyForUpload',
      array: ['periodFileReadyForUpload ERROR', action, e]
    });
    yield* trackErrorInFirestore(e, { type: 'periodFileReadyForUploadError' });
  }
}

function* getUploadContext(action) {
  const { attachmentType, documentId, fileEntry, persistFileLocallyBeforeUpload } = action;
  const actAttachment = new ActFile(documentId, fileEntry);

  const uploadContext = new StorageUploadContext(documentId, fileEntry, persistFileLocallyBeforeUpload);
  uploadContext.fileWrapper = actAttachment;
  uploadContext.uploadType = attachmentType;
  uploadContext.userId = yield select(selectUserIdInContext());
  uploadContext.localFileRepository = actAttachmentRepository;
  uploadContext.postLocalPersistFileHook = postLocalPersistAttachment;
  uploadContext.storeDispatch = getStore().dispatch;
  return uploadContext;
}

export function postLocalPersistAttachment(actId, addedAttachment, localPath) {
  const currentAttachments = selectAttachments()(getStore().getState());
  const updatedAttachments = [
    ...currentAttachments,
    {
      type: PERIOD_ATTACHMENT_TYPE,
      location: localPath,
      fileName: addedAttachment.fileName,
      originalFileName: addedAttachment.originalFileName,
      temporary: true
    }
  ];

  logDefault({
    type: 'saga',
    text: 'PeriodSagas/postLocalPersistAttachment',
    array: ['Dispatching updateSavedAttachmentInPeriodForm action with attachment', updatedAttachments]
  });
  getStore().dispatch(updateSavedAttachmentInPeriodForm(updatedAttachments));
}

export function* periodFileSuccessfullyUploaded(action) {
  const { downloadUrl, uploadTask } = action;
  const currentFormPeriodId = yield select(selectActivityPeriodId());

  logDefault({
    type: 'saga',
    text: 'PeriodSagas/periodFileSuccessfullyUploaded',
    array: [
      'Starting periodFileSuccessfullyUploaded with action',
      action,
      'and currentFormPeriodId',
      currentFormPeriodId
    ]
  });

  try {
    yield* uploadAttachmentsInForm(currentFormPeriodId, uploadTask, downloadUrl);
    yield* uploadAttachmentsInPersistence(uploadTask, downloadUrl);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'PeriodSagas/periodFileSuccessfullyUploaded',
      array: ['periodFileSuccessfullyUploaded ERROR', uploadTask, downloadUrl, e]
    });
    yield* trackErrorInFirestore(e, { type: 'PeriodFileSuccessfullyUploadedError' });
  } finally {
    completeUploadProcess(uploadTask);
  }
}

function* uploadAttachmentsInForm(currentFormPeriodId, uploadTask, downloadUrl) {
  if (currentFormPeriodId === uploadTask.actId) {
    const attachments = yield select(selectAttachments());
    const updatedAttachments = getUpdatedAttachments(attachments, uploadTask, downloadUrl);

    logDefault({
      type: 'saga',
      text: 'PeriodSagas/periodFileSuccessfullyUploaded',
      array: ['Updating attachment in period form']
    });
    yield put(updateSavedAttachmentInPeriodForm(updatedAttachments));
  }
}

function* uploadAttachmentsInPersistence(uploadTask, downloadUrl) {
  if (uploadTask.type === PERIOD_ATTACHMENT_TYPE) {
    logDefault({
      type: 'saga',
      text: 'PeriodSagas/periodFileSuccessfullyUploaded',
      array: ['Updating attachment in persisted period']
    });
    yield* updateAttachmentInPersistedAct(uploadTask.actId, downloadUrl);
    yield* removeTemporaryFileFromDeviceLocalStorage(uploadTask.fileName, SYNCHRONIZED_ATTACHMENT);
  } else {
    logDefault({
      type: 'saga',
      text: 'PeriodSagas/periodFileSuccessfullyUploaded',
      array: ['Upload task type not known to this saga. Skipping any processing.', uploadTask.type]
    });
  }
}

function completeUploadProcess(uploadTask) {
  if (uploadTask.type === PERIOD_ATTACHMENT_TYPE) {
    storageUploader.completeUploadProcessForFile(uploadTask.fileName);
  }
}

export function* periodFileUploadError(action) {
  const { uploadTask } = action;

  yield call(storageUploader.completeUploadProcessForFile, uploadTask.fileName);
}

export default function* periodsFileUploadSaga() {
  yield takeEvery(PERIOD_FILE_READY_FOR_UPLOAD, periodFileReadyForUpload);
  yield takeEvery(UPLOAD_ERROR, periodFileUploadError);
  yield takeEvery(isActionUploadSuccessForPeriodAttachment, periodFileSuccessfullyUploaded);
}

export const isActionUploadSuccessForPeriodAttachment = (action) =>
  action.type === UPLOAD_SUCCESS && action.uploadTask.type === PERIOD_ATTACHMENT_TYPE;
