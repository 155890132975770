import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACTIVITIES_LUMP_SUM_TYPE } from '../../../shared/collection/collectionConstants';
import { LUMP_SUM_PERIOD_DAYS_DURATION } from '../../../shared/periods/constants';
import EmptyPlacePreferences from '../../components/Activities/EmptyPlacePreferences/EmptyPlacePreferences';
import BackButton from '../../components/button/NavigationHeaderButton/NavigationHeaderButton';
import LoadPreferences from '../LoadPreferences/LoadPreferences';
import { extractPDF } from '../PDF/actions';
import PeriodForm from '../PeriodsBilling/PeriodForm/PeriodForm';
import PeriodsBilling from '../PeriodsBilling/PeriodsBilling';
import { saveLumpSum } from './actions';
import {
  createErrorMapWithEveryValidationFunction,
  throwSubmissionErrorIfNecessary
} from '../../validations/common/reduxFormValidation';
import { validateLumpSum } from '../../codeSpecificLogic/validations/lumpSum/lumpSumValidation';
import overrideRemoteDispensaryAreaForExceptionCode from '../../lumpsum/domain/dispensaryArea';
import overrideComplementaryInformation from '../../lumpsum/domain/overrideComplementaryInformation';

const LumpSum = ({ saveLumpSum, previousUrl = '..', savedLumpSum, formClasses, extractPDF }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <LoadPreferences>
      <>
        <BackButton urlToGoOnClick={previousUrl} position="left" preventRenderOnAdminConsole />
        <EmptyPlacePreferences text={<FormattedMessage id="emptyPlacePreferences.lumpSum" />}>
          <PeriodsBilling
            savedPeriod={savedLumpSum}
            periodDuration={LUMP_SUM_PERIOD_DAYS_DURATION}
            activityType={ACTIVITIES_LUMP_SUM_TYPE}
            allowPeriodsOnWeekEnd
          >
            <PeriodForm
              classes={formClasses}
              onSave={(status) => (periodForm) => {
                throwSubmissionErrorIfNecessary(createErrorMapWithEveryValidationFunction(periodForm, validateLumpSum));

                let documentToSave = { ...periodForm };

                if (status) {
                  documentToSave = {
                    ...periodForm,
                    status
                  };
                }

                documentToSave = overrideRemoteDispensaryAreaForExceptionCode(documentToSave);
                documentToSave = overrideComplementaryInformation(documentToSave);
                documentToSave.onlineSave = navigator.onLine;

                saveLumpSum(documentToSave);
                navigate({ pathname: previousUrl, search: location.search });
              }}
              onPDFButtonClick={(periodForm) => {
                let documentToExtract = { ...periodForm };

                documentToExtract = overrideRemoteDispensaryAreaForExceptionCode(documentToExtract);
                documentToExtract = overrideComplementaryInformation(documentToExtract);

                extractPDF(documentToExtract);
              }}
            />
          </PeriodsBilling>
        </EmptyPlacePreferences>
      </>
    </LoadPreferences>
  );
};

LumpSum.propTypes = {
  previousUrl: PropTypes.string,
  saveLumpSum: PropTypes.func.isRequired,
  extractPDF: PropTypes.func.isRequired,
  savedLumpSum: PropTypes.object,
  formClasses: PropTypes.object
};

LumpSum.defaultProps = {
  savedLumpSum: undefined,
  formClasses: {}
};

export const mapDispatchToProps = {
  saveLumpSum,
  extractPDF
};

export default compose(connect(null, mapDispatchToProps))(LumpSum);
