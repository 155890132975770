import List from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import DraggableElement from '../DraggableElements/DraggableElement';

export const styles = (theme) => ({
  containerMinHeight: {
    minHeight: '50px'
  },
  draggingOver: {
    backgroundColor: theme.palette.grey[200]
  },
  dropDisabled: {
    backgroundColor: theme.palette.grey[300],
    cursor: 'not-allowed'
  }
});

export const DroppableContainer = ({
  id,
  label,
  level,
  category,
  items,
  classes,
  itemComponentBuilder,
  containerWrapperComponentBuilder,
  isDropDisabled
}) => {
  const children = (
    <Droppable droppableId={id} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`${classes.containerMinHeight} ${snapshot.isDraggingOver ? classes.draggingOver : ''} ${isDropDisabled ? classes.dropDisabled : ''}`}
        >
          <List dense>
            {category.itemIds.map(
              (itemKey, index) =>
                items[itemKey] && (
                  <DraggableElement id={itemKey} index={index} key={itemKey}>
                    {itemComponentBuilder({ item: items[itemKey], id: itemKey })}
                  </DraggableElement>
                )
            )}
            {provided.placeholder}
          </List>
        </div>
      )}
    </Droppable>
  );

  return containerWrapperComponentBuilder({ id, label, level, children });
};

DroppableContainer.defaultProps = {
  level: 0,
  label: ''
};

DroppableContainer.propTypes = {
  id: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired,
  label: PropTypes.string,
  level: PropTypes.number,
  items: PropTypes.object.isRequired,
  itemComponentBuilder: PropTypes.func.isRequired,
  containerWrapperComponentBuilder: PropTypes.func.isRequired
};

export default withStyles(styles)(DroppableContainer);
