import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import isDefined from '../../../../shared/utils/isDefined';
import CategoriesList from '../CategoriesList/CategoriesList';

const styles = () => ({
  bold: {
    fontWeight: 'bold'
  }
});

export class Category extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: props.initiallyOpen,
      selectedItems: []
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleSuggestionClick = (suggestion) => {
    const { toggleSelected, onSuggestionClick } = this.props;
    onSuggestionClick(suggestion);

    if (toggleSelected) {
      const { selectedItems } = this.state;

      const currentItemIndex = selectedItems.indexOf(suggestion);
      if (currentItemIndex >= 0) {
        delete selectedItems[currentItemIndex];
      } else {
        selectedItems.push(suggestion);
      }
    }
  };

  handleClick() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  render() {
    const { ItemComponent, category, itemProps, isItemSelected, classes, onSuggestionClick } = this.props;
    return (
      <List dense>
        <ListItem divider button onClick={this.handleClick}>
          <ListItemText classes={{ primary: classes.bold }} primary={category.category} />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {isDefined(category.subCategories) && (
            <CategoriesList
              list={category.subCategories}
              initiallyOpen={false}
              isItemSelected={isItemSelected}
              onItemSelected={onSuggestionClick}
            />
          )}

          {isDefined(category.items) &&
            category.items.map((item) => (
              <ItemComponent
                suggestion={item}
                onSuggestionClick={this.handleSuggestionClick}
                key={shortid.generate()}
                isSelected={isItemSelected(item)}
                {...itemProps}
              />
            ))}
        </Collapse>
      </List>
    );
  }
}

Category.defaultProps = {
  itemProps: {},
  initiallyOpen: true,
  toggleSelected: false,
  onSuggestionClick: () => {}
};

Category.propTypes = {
  initiallyOpen: PropTypes.bool,
  toggleSelected: PropTypes.bool,
  ItemComponent: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  itemProps: PropTypes.object,
  onSuggestionClick: PropTypes.func,
  isItemSelected: PropTypes.func.isRequired
};

export default withStyles(styles)(Category);
