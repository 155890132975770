import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import LoadingButton from '../../button/LoadingButton/LoadingButton';
import LoginFormWrapper from '../LoginFormWrapper/LoginFormWrapper';
import AuthenticationServiceProvider from '../../../infrastructure/authentication/service/AuthenticationServiceProvider';

export const RESET_PASSWORD_FORM_ID = 'medyx-reset-password';

export const styles = (theme) => ({
  successBox: {
    backgroundColor: theme.palette.green[500],
    padding: theme.spacing(2),
    borderRadius: 4
  },
  spacedBottom: {
    marginBottom: theme.spacing(2)
  }
});

export class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailSending: false,
      emailSent: false,
      emailError: undefined
    };
  }

  sendResetPasswordEmail = (event) => {
    event.preventDefault();
    const emailError = !this.emailInput.value ? 'Veuillez entrer votre courriel' : undefined;
    if (!emailError) {
      this.setState({ emailSending: true });
      return AuthenticationServiceProvider.getInstance()
        .sendPasswordResetEmail(this.emailInput.value)
        .then(() => {
          this.setState({ emailError, emailSent: true, emailSending: false });
        })
        .catch(() => {
          // Swallow error so we don't let the person knows that a specific user exists or not
          this.setState({ emailError, emailSent: true, emailSending: false });
        });
    }
    return this.setState({ emailError });
  };

  render() {
    const { classes } = this.props;
    const { emailError, emailSending, emailSent } = this.state;
    return (
      <LoginFormWrapper id={RESET_PASSWORD_FORM_ID} onSubmit={this.sendResetPasswordEmail}>
        {emailSent ? (
          <div className={classes.successBox}>
            <Typography variant="body1">{`Un courriel a été envoyé à ${this.emailInput.value}`}</Typography>
          </div>
        ) : (
          <>
            <TextField
              id="reset-password-user-email"
              error={emailError !== undefined}
              label="Courriel"
              className={classes.spacedBottom}
              inputRef={(ref) => {
                this.emailInput = ref;
              }}
              helperText={emailError}
            />
            <LoadingButton
              className={classes.spacedBottom}
              type="submit"
              label="Envoyer"
              loading={emailSending}
              variant="contained"
              color="primary"
              onClick={this.sendResetPasswordEmail}
            />
          </>
        )}
        <Link to="/login">
          <Typography>Retour</Typography>
        </Link>
      </LoginFormWrapper>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
