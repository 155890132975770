import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Role from 'shared/domain/authentication/Role';
import Restrict from 'application/components/restrict';
import DeferredPaymentAPI from 'app/infrastructure/api/DeferredPaymentAPI';
import AlertApi from 'app/shared/confirm/api/AlertApi';
import AdminAdminStatusSelector from '../../../../../components/Activities/AdminAdminStatusSelector/AdminAdminStatusSelector';
import AdminStatusSelector from '../../../../../components/Activities/AdminStatusSelector/AdminStatusSelector';
import DialogTrigger from '../../../../../components/Dialogs/DialogTrigger/DialogTrigger';
import XMLViewerDialog from '../../../../../components/Dialogs/XMLViewerDialog/XMLViewerDialog';
import ButtonCheckbox from '../../../../../components/Form/Checkbox/ButtonCheckbox/ButtonCheckbox';
import { selectRelatedAsyncCall } from '../../../../AsyncTasks/selectors';
import AdminNotePopup from '../AdminNotePopup/AdminNotePopup';
import RamqBillButton from './RamqBillButton';
import RamqCancelButton from './RamqCancelButton';
import ActivityHistoryDialog from '../../../../../adminConsole/ui/components/ActivityHistoryDialog/ActivityHistoryDialog';
import { PreviewXmlButton } from './PreviewXmlButton';
import { ModifyXmlButton } from './ModifyXmlButton';
import useCopyActivityUrl from 'application/hooks/use-copy-activity-url';

const isCallingRamq = (relatedAsyncCalls) =>
  relatedAsyncCalls.find((relatedAsyncCall) => relatedAsyncCall.loading) !== undefined;

export function AdminActions({
  activity,
  activeBills,
  onUpdate,
  onDuplicate,
  onAddAdminNote,
  onBillRamq,
  onCancelRamq,
  relatedAsyncCalls
}) {
  const copyActivityUrl = useCopyActivityUrl();

  const onValidateXml = async () => {
    try {
      const { xml } = activity;
      await DeferredPaymentAPI.validateXml(xml);
      AlertApi.showConfirm({
        title: 'Validation réussie',
        content: 'Le fichier XML est valide',
        confirmActionLabel: 'Ok',
        onConfirm: () => {},
        displayCancel: false
      });
    } catch (error) {
      AlertApi.showConfirm({
        title: 'Erreur de validation',
        content: error.response.data?.detail.map((detail) => <li>{detail}</li>),
        confirmActionLabel: 'Ok',
        onConfirm: () => {},
        displayCancel: false
      });
    }
  };

  return (
    <div style={{ padding: '8px 12px' }}>
      <AdminStatusSelector onChange={onUpdate} entity={activity} />
      <AdminAdminStatusSelector
        value={activity.adminStatus}
        onChange={({ target }) => onUpdate({ adminStatus: target.value })}
      />
      <AdminNotePopup onSent={onAddAdminNote} act={activity} />
      <PreviewXmlButton activityId={activity.id} type={activity.type} />
      <RamqBillButton loading={isCallingRamq(relatedAsyncCalls)} onBillRamq={onBillRamq} activeBills={activeBills} />
      <RamqCancelButton
        loading={isCallingRamq(relatedAsyncCalls)}
        onCancelRamq={onCancelRamq}
        activeBills={activeBills}
        disabled={!activity.lastActiveBill}
      />
      {onDuplicate && <Button onClick={onDuplicate}>Dupliquer</Button>}
      {activity.xml && (
        <DialogTrigger dialogProps={{ xml: activity.xml }} DialogComponent={XMLViewerDialog}>
          <Button>XML envoyé</Button>
        </DialogTrigger>
      )}

      <ButtonCheckbox
        data-testid="block-status-button-checkbox"
        label="bloquer"
        checked={!!activity.blocked}
        onClick={() => onUpdate({ blocked: !activity.blocked })}
      />

      <DialogTrigger dialogProps={{ activityId: activity.id }} DialogComponent={ActivityHistoryDialog}>
        <Button>Historique</Button>
      </DialogTrigger>

      <Button onClick={() => copyActivityUrl(activity)}>Copier URL</Button>

      {activity.xml && (
        <Restrict atLeastRole={Role.Agent}>
          <ModifyXmlButton
            activity={activity}
            data-testid="button-xml-editor-dialog"
            onSave={(editedXml) => onBillRamq({ manualXml: editedXml })}
          />
          <Button onClick={onValidateXml}>Valider le XML</Button>
        </Restrict>
      )}
    </div>
  );
}

AdminActions.propTypes = {
  activity: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func,
  onAddAdminNote: PropTypes.func.isRequired,
  onBillRamq: PropTypes.func.isRequired,
  onCancelRamq: PropTypes.func.isRequired,
  activeBills: PropTypes.array.isRequired,
  relatedAsyncCalls: PropTypes.object
};

AdminActions.defaultProps = {
  relatedAsyncCalls: [],
  onDuplicate: undefined
};

export const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    relatedAsyncCalls: selectRelatedAsyncCall(ownProps.activity.id)
  });

export default compose(connect(mapStateToProps))(AdminActions);
