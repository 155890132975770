import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import useTranslate from 'application/hooks/use-translate';
import CollapsableIcon from '../../../components/CollapsableIcon/CollapsableIcon';
import FormElement from '../../../components/Form/FormElement/FormElement';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';

export const styles = (theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  collapseToggleWrapper: {
    display: 'flex',
    alignItems: 'end'
  },
  collapseWrapper: {
    width: '100%'
  },
  collapseContainer: {
    padding: theme.spacing(2)
  }
});

type Props = {
  activityAreas: any[];
  // eslint-disable-next-line no-unused-vars
  onChange?: (event?: any, newValue?: any, previousValue?: any) => void;
  initiallyOpen?: boolean;
  collapsable?: boolean;
  formField: string;
  classes: any;
  disabled?: boolean;
};

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
export const ActivityAreaSelector: React.FunctionComponent<Props> = ({
  formField,
  activityAreas,
  onChange,
  initiallyOpen = false,
  collapsable = true,
  classes,
  disabled = false
}) => {
  const translate = useTranslate('act-form.activity-area');
  const [open, setOpen] = useState<boolean>(initiallyOpen);

  return (
    <>
      {collapsable && <CollapsableIcon onClick={() => setOpen(!open)} selected={false} label="S.A." expanded={open} />}
      <Collapse in={open || !collapsable} className={classes.collapseWrapper}>
        <div id="activities-area" className={classes.collapseContainer}>
          <Typography variant="caption">{translate('activity-area')}</Typography>
          {(!activityAreas || activityAreas.length === 0) && (
            <Typography variant="caption">"{translate('no-specialty-linked-to-profile')}"</Typography>
          )}
          <FormElement
            name={formField}
            label=""
            list={activityAreas}
            onChange={onChange}
            fullWidth
            component={SingleSelectCheckboxGroup}
            disabled={disabled}
          />
        </div>
      </Collapse>
    </>
  );
};

// @ts-ignore
export default withStyles(styles)(ActivityAreaSelector);
