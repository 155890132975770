import { gql, useQuery } from '@apollo/client';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDebounce } from 'react-use';
import { WrappedFieldProps } from 'redux-form';
import SearchIcon from '@material-ui/icons/Search';
import deviceDetector from '../../../components/utils/DeviceDetector';
import OptionComponent from './OptionComponent';
import { useDispatch, useSelector } from 'react-redux';
import { selectDoctorsPreferencesFromUser } from 'app/doctorsRegistry/adapters/selectors';
import {
  addDoctorToUserPreferences,
  removeDoctorFromUserPreferences,
  updateDoctorsFromUserPreferences
} from 'app/doctorsRegistry/adapters/actions';
import DoctorDialogWithDoctorRegistry from 'app/doctorsRegistry/ui/containers/DoctorDialogWithDoctorRegistry/DoctorDialogWithDoctorRegistry';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },
  autocomplete: {
    flex: 1,
    marginRight: 20
  },
  button: {
    minWidth: '40px',
    padding: 0
  },
  buttonContainer: {
    height: 38,
    paddingTop: 10
  }
});

export enum DirectoryPhysicianType {
  PHYSICIAN = 'physician',
  RESIDENT = 'resident'
}

interface Props extends WrappedFieldProps {
  type?: DirectoryPhysicianType;
}

export interface DirectoryPhysician {
  practiceNumber: string;
  firstName: string;
  lastName: string;
  facility?: string;
  specialties?: Array<{
    label: string;
  }>;
}

const DoctorAutocomplete = ({ type, input }: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const doctorsPreferences = useSelector(selectDoctorsPreferencesFromUser());
  const dispatch = useDispatch();

  const SEARCH_DIRECTORY_PHYSICIAN = gql`
    query SearchDirectoryPhysicians($searchTerm: String!, $type: DirectoryPhysicianType) {
      directoryPhysicians(searchTerm: $searchTerm, type: $type) {
        id
        practiceNumber
        firstName
        lastName
        facility
        specialties {
          label
        }
      }
    }
  `;

  const { loading, data } = useQuery(SEARCH_DIRECTORY_PHYSICIAN, {
    variables: { searchTerm: debouncedInputValue, type },
    skip: !debouncedInputValue
  });
  const options = data?.directoryPhysicians || [];

  useDebounce(() => setDebouncedInputValue(inputValue.replace(',', '')), 300, [inputValue]);

  const { value } = input;
  useEffect(() => {
    if (!value) return;

    const fullValue: string[] = [];
    if (value.practiceNumber) fullValue.push(value.practiceNumber);
    if (value.lastName) fullValue.push(value.firstName ? `${value.lastName},` : value.lastName);
    if (value.firstName) fullValue.push(value.firstName);
    setInputValue(fullValue.join(' '));
  }, [value]);

  const onInputChange = (_event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };

  const onSelectedDoctorChange = (event, value: DirectoryPhysician | null) => {
    if (value) {
      input.onChange(pick(value, ['practiceNumber', 'firstName', 'lastName', 'facility']));
      onInputChange(event, `${value?.practiceNumber} ${value?.lastName}, ${value?.firstName}`);
    } else {
      input.onChange(null);
      onInputChange(event, '');
    }
  };

  const openDialogIfMobile = () => {
    if (deviceDetector.isMobile()) {
      setOpenDialog(true);
    }
  };

  const handleOnSaveDoctor = (doctor) => {
    dispatch(addDoctorToUserPreferences(doctor));
  };

  const handleOnDeleteFromFavorites = (doctor) => {
    dispatch(removeDoctorFromUserPreferences(doctor));
  };

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'close') => {
    if (reason !== 'backdropClick') {
      setOpenDialog(false);
    }
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        ListboxProps={{ style: { overflowX: 'hidden' } }}
        id="doctor-autocomplete"
        className={classes.autocomplete}
        autoHighlight
        loading={loading}
        loadingText={<FormattedMessage id="global.loading" />}
        noOptionsText={<FormattedMessage id="global.noResults" />}
        options={options}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onOpen={openDialogIfMobile}
        clearOnBlur={false}
        defaultValue={value}
        onChange={onSelectedDoctorChange}
        getOptionLabel={(option: DirectoryPhysician) =>
          option && `${option.practiceNumber} ${option.lastName}, ${option.firstName}`
        }
        filterOptions={(options) => options}
        renderOption={(option: DirectoryPhysician) => <OptionComponent option={option} />}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<FormattedMessage id={type === DirectoryPhysicianType.RESIDENT ? 'act.resident' : 'act.doctor'} />}
            variant="standard"
          />
        )}
      />

      {!deviceDetector.isMobile() && (
        <div className={classes.buttonContainer}>
          <Button variant="contained" onClick={() => setOpenDialog(true)} className={classes.button}>
            <SearchIcon />
          </Button>
        </div>
      )}

      <DoctorDialogWithDoctorRegistry
        open={openDialog}
        addToFavorites={handleOnSaveDoctor}
        onClose={(event) => handleClose(event, 'close')}
        onItemSelected={(value) => onSelectedDoctorChange({}, value)}
        onSave={handleOnSaveDoctor}
        type={type}
        preferences={doctorsPreferences}
        onAdvancedSave={updateDoctorsFromUserPreferences}
        openDialog={openDialog}
        closeDialog={() => setOpenDialog(false)}
        onDeleteDoctor={handleOnDeleteFromFavorites}
        removeFromFavorites={handleOnDeleteFromFavorites}
        onDialogShouldOpen={() => setOpenDialog(true)}
      />
    </div>
  );
};

export default DoctorAutocomplete;
