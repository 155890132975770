import { MULTI_ACT_FILE_READY_FOR_UPLOAD, MULTI_ACT_UPDATE_PICTURE } from './constants';

export default function updateMultiActPicture(location, file, temporary = false) {
  return {
    type: MULTI_ACT_UPDATE_PICTURE,
    pictureData: {
      location,
      file,
      temporary
    }
  };
}

export const multiActFileReadyForUpload = (attachmentType, fileEntry, persistFileLocallyBeforeUpload) => ({
  type: MULTI_ACT_FILE_READY_FOR_UPLOAD,
  attachmentType,
  fileEntry,
  persistFileLocallyBeforeUpload
});

export const MULTI_ACT_CLOSE_PICTURE_PREVIEW = 'multiActClosePicturePreview';
export const closeMultiActPicturePreview = () => ({
  type: MULTI_ACT_CLOSE_PICTURE_PREVIEW
});

export const MULTI_ACT_TOGGLE_PICTURE_PREVIEW = 'multiActTogglePicturePreview';
export const toggleMultiActPicturePreview = (forcedState) => ({
  type: MULTI_ACT_TOGGLE_PICTURE_PREVIEW,
  forcedState
});

export const MULTI_ACT_DELETE_PICTURE = 'multiActDeletePicture';
export const deleteSavedMultiNamPicture = () => ({
  type: MULTI_ACT_DELETE_PICTURE
});
