import React from 'react';
import Spinner from 'application/components/spinner';
import useTranslate from 'application/hooks/use-translate';

const LoadingState = () => {
  const translate = useTranslate('reports');

  return (
    <div className="flex h-96 flex-col items-center justify-center gap-4 bg-base-200">
      <Spinner />

      <span className="w-64 text-center text-gray-400">{translate('please-wait')}</span>
    </div>
  );
};

export default LoadingState;
