import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import HeaderPortal from '../../../components/Portals/HeaderPortal/HeaderPortal';
import HybridPrintButton from '../../../components/button/PrintButtons/HybridPrintButton/HybridPrintButton';

const styles = (theme) => ({
  printHeader: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 0
  },
  gridContainer: {
    justifyContent: 'space-evenly'
  }
});

function PrintHeader({ SelectorComponents, componentToPrintRef, title, classes }) {
  return (
    <HeaderPortal>
      <Paper className={classes.printHeader}>
        <Grid container className={classes.gridContainer}>
          {SelectorComponents.map((Component) => (
            <Grid key={Component} item>
              <Component />
            </Grid>
          ))}
          <Grid item>
            <HybridPrintButton
              componentToPrintRef={componentToPrintRef}
              title={title}
              ButtonComponent={() => <Button>Imprimer</Button>}
            />
          </Grid>
        </Grid>
      </Paper>
    </HeaderPortal>
  );
}

PrintHeader.defaultProps = {
  componentToPrintRef: false
};

PrintHeader.propTypes = {
  SelectorComponents: PropTypes.array.isRequired,
  componentToPrintRef: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: string
};

export default compose(withStyles(styles))(PrintHeader);
