export const SPECIAL_SITUATION_CODES = [
  { label: 'Aucun', value: 'none' },
  { label: 'Test sommeil en cabinet 8h ou +', value: '05012', code: '5012' },
  { label: 'Test sommeil en cabinet 4h-<8h', value: '05014', code: '5014' },
  { label: 'Test sommeil en cabinet <4h', value: '05020', code: '5020' },
  { label: 'Traitement oral', value: '05040', code: '5040' },
  { label: 'Clinique des tumeurs', value: '05047', code: '5047' },
  { label: 'Supervision d’immunoglobulines', value: '05086', code: '5086' },
  { label: 'Autre', value: 'other' }
];

export default SPECIAL_SITUATION_CODES;
