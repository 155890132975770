import { get, intersection, set } from 'lodash';
import isDefined from '../../../../shared/utils/isDefined';
import MultiDiagnosticWithPatientContextField from '../../../components/Form/ActSpecificField/Diagnostics/MultiDiagnosticWithPatientContextField';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import { doesContainDuplicatedValues, getFlattenItemsFromCategory } from '../../../../utils/listUtils';
import { selectSpecialtyDiagnosticCodesFromUser } from '../../../favorite/diagnosticCode/adapters/selectors';
import { getStore } from '../../../reduxStore/configureStore';

export const MULTIPLE_DIAGNOSTICS_CODES = ['28', '29', '30', '31', '36', '42', '43', '48', '51', '15672', '15674'];
const LESS_THAN_1_DIAGNOSTIC_ERROR = 'Min. 1 diagnostic requis';
const MORE_THAN_1_DIAGNOSTIC_PER_SYSTEM_ERROR = 'Max. 1 diagnostic par système';
const DUPLICATED_DIAGNOSTIC_ERROR = 'Vous ne pouvez pas sélectionner deux fois le même diagnostic';

const constructErrorObject = (index, message) =>
  set({ elementIdToScrollTo: `codes[${index}].diagnostic` }, `codes[${index}].diagnostic`, message);

const isMultipleDiagnosticCode = (code) =>
  MULTIPLE_DIAGNOSTICS_CODES.some((multipleDiagnosticCode) => multipleDiagnosticCode === code);

const hasAtLeastOneDiagnostic = (code) => {
  if (!isDefined(code.diagnostic)) {
    return false;
  }

  return !!code.diagnostic.length;
};

const hasAMaximumOfOneDiagnosticPerSystem = (diagnostic) => {
  const selectedDiagnosticCodes = diagnostic.map(({ code }) => code);
  const diagnosticList = selectSpecialtyDiagnosticCodesFromUser()(getStore().getState()).filter((category) => {
    const items = getFlattenItemsFromCategory(category);

    return (
      intersection(
        items.map(({ code }) => code),
        selectedDiagnosticCodes
      ).length > 1
    );
  });

  return diagnosticList.length === 0;
};

const validateAtLeast1Diagnostic = (act) => {
  const codesInError = act.codes.map((code, index) =>
    isMultipleDiagnosticCode(code.code) && !hasAtLeastOneDiagnostic(code)
      ? constructErrorObject(index, LESS_THAN_1_DIAGNOSTIC_ERROR)
      : {}
  );

  return codesInError.reduce(combineErrors, {});
};

const validateNoDuplicatedDiagnosticSelected = ({ codes }) => {
  const codesInError = codes.map(({ code, diagnostic }, index) => {
    if (!isMultipleDiagnosticCode(code) || !isDefined(diagnostic)) {
      return {};
    }

    if (doesContainDuplicatedValues(diagnostic.map(({ code: diagnosticCode }) => diagnosticCode))) {
      return constructErrorObject(index, DUPLICATED_DIAGNOSTIC_ERROR);
    }

    return {};
  });

  return codesInError.reduce(combineErrors, {});
};

const validateMaximumOneDiagnosticPerSystem = ({ codes }) => {
  const codesInError = codes.map(({ code, diagnostic }, index) => {
    if (!isMultipleDiagnosticCode(code) || !isDefined(diagnostic)) {
      return {};
    }

    if (!hasAMaximumOfOneDiagnosticPerSystem(diagnostic)) {
      return constructErrorObject(index, MORE_THAN_1_DIAGNOSTIC_PER_SYSTEM_ERROR);
    }

    return {};
  });

  return codesInError.reduce(combineErrors, {});
};

const summaryRender = (code) => [
  {
    name: 'diagnostic',
    value: get(code, 'diagnostic.code', '-')
  }
];

const associatedFields = [
  {
    label: 'Diagnostics',
    type: MultiDiagnosticWithPatientContextField,
    getName: (index) => `codes[${index}].diagnostic`
  }
];

const associatedValidations = [
  validateAtLeast1Diagnostic,
  validateNoDuplicatedDiagnosticSelected,
  validateMaximumOneDiagnosticPerSystem
];

const multipleDiagnostic = { associatedFields, associatedValidations, summaryRender };

export default multipleDiagnostic;
