import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SIGNUP_ROUTE } from 'application/routes';
import SignupPage from 'application/pages/lobby/lobby.signup';
import { FORGOT_PASSWORD_ROUTE } from '../../containers/Navigation/constants';
import FirebaseLogin from './FirebaseLogin/FirebaseLogin';
import ForgotPassword from './ForgotPassword/ForgotPassword';

export function Login() {
  return (
    <Routes>
      <Route index element={<FirebaseLogin />} />
      <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
      <Route path={SIGNUP_ROUTE} element={<SignupPage />} />
    </Routes>
  );
}

export default Login;
