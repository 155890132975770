import { isArray, set } from 'lodash';
import { formatUnixToTimeValue } from '../../../../shared/utils/dateTime/dateTimeUtils';
import { MultiTimeField } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';

const displayStartTime = (time) => ({
  name: 'heure +',
  value: formatUnixToTimeValue(time)
});

const summaryRender = (code) =>
  isArray(code.additionalStartTimes) ? code.additionalStartTimes.map(displayStartTime) : [];

const associatedFields = (maxNumberOfInput) => [
  {
    getName: (index) => `codes[${index}].additionalStartTimes`,
    label: 'Heures de début additionnelles',
    type: MultiTimeField,
    props: {
      maxNumberOfInput
    },
    notRequired: true
  }
];

export const ADDITIONAL_HOUR_ERROR = "Les heures additionnelles doivent être différentes de l'heure de debut";
const additionalHourMustBeDifferentFromActStartHour = (codeIndex, additionalStartTimeIndex) =>
  set(
    { elementIdToScrollTo: `codes[${codeIndex}].additionalStartTimes[${additionalStartTimeIndex}]` },
    `codes[${codeIndex}].additionalStartTimes[${additionalStartTimeIndex}]`,
    ADDITIONAL_HOUR_ERROR
  );

export const makeSureAdditionalStartHoursAreDifferentFromActStartHour = (act) => {
  const codesInError = [];

  act.codes.forEach(({ additionalStartTimes = [] }, codeIndex) =>
    additionalStartTimes.forEach((additionalStartTime, additionalStartTimeIndex) => {
      if (additionalStartTime === act.start) {
        codesInError.push(additionalHourMustBeDifferentFromActStartHour(codeIndex, additionalStartTimeIndex));
      }
    })
  );
  return codesInError.reduce(combineErrors, {});
};

const associatedValidations = [makeSureAdditionalStartHoursAreDifferentFromActStartHour];

const multiDebut = (maxNumberOfInput) => ({
  associatedFields: associatedFields(maxNumberOfInput),
  summaryRender,
  associatedValidations
});

export default multiDebut;
