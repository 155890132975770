export const GROUPS_ROUTE = 'groups';
export const GROUP_ID_PARAM = ':groupId';
export const GROUP_DETAILS = 'details';
export const GROUP_USERS = 'users';
export const GROUP_REPORTS = 'reports';
export const GROUP_PDF_GENERATION = 'pdf-generation';
export const GROUP_PAYMENT_REQUESTS = 'payment-requests';

export const USERS_ROUTE = 'users';
export const USER_PROFILE = 'profile';

export const ADMINISTRATION_ROUTE = 'administration';
export const STATS_ROUTE = 'stats';
export const COMMUNICATIONS_ROUTE = 'communications';
export const PRESETS_ROUTE = 'presets';

export const UPVOTY_ROUTE = 'upvoty';

export const SETTINGS_DETAILS_ROUTE = 'details';
export const SETTINGS_PREFERENCES_ROUTE = 'preferences';
export const SETTINGS_SECURITY_ROUTE = 'security';
export const SETTINGS_PARTNERS_ROUTE = 'partners';
export const SETTINGS_APPLICATION_ROUTE = 'application';
export const SETTINGS_REFERRAL_ROUTE = 'referral';

export const SIGNUP_ROUTE = 'signup';
