import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import openUrl from 'application/utilities/open-url';
import { extractPDF } from '../../infrastructure/api/api';
import { savePeriod } from '../PeriodsBilling/sagas';
import { EXTRACT_PDF, extractPDFFailure, extractPDFSuccess } from './actions';

export function* fetchPDF(action) {
  try {
    const networkIsOnline = navigator.onLine;
    if (!networkIsOnline) {
      throw new Error('Impossible de générer un PDF hors ligne');
    }

    const { document } = action;
    let saveJob;
    if (!action.isReadOnly) {
      saveJob = yield fork(savePeriod, extractPDFFailure, { document });
    }

    const location = yield call(extractPDF, document.id, document.type, document);

    yield saveJob !== undefined && join(saveJob);
    openUrl(location);
    yield put(extractPDFSuccess());
  } catch (error) {
    yield put(extractPDFFailure(error));
  }
}

export default function* pdfSaga() {
  yield takeLatest(EXTRACT_PDF, fetchPDF);
}
