import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPreviousWeekPeriods } from '../../../../../shared/periods/periods';
import { defaultFormatMomentDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import DialogTrigger from '../../../../components/Dialogs/DialogTrigger/DialogTrigger';
import SingleSelectDialog from '../../../CodeSearchDialog/SingleSelectDialog/SingleSelectDialog';
import PeriodList from './PeriodList';

export const styles = (theme) => ({
  button: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.common.white
  }
});

const PeriodSelector = ({ periodStartDate, periodDuration, classes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { date } = useParams();

  const [periodsCount, setPeriodsCount] = useState(15);

  return (
    <DialogTrigger
      DialogComponent={SingleSelectDialog}
      dialogProps={{
        title: 'Périodes',
        children: PeriodList,
        periods: getPreviousWeekPeriods(moment(), periodsCount, periodDuration),
        onMoreItems: () => setPeriodsCount((prevPeriodsCount) => prevPeriodsCount + 15)
      }}
      onItemSelected={(value) => {
        const unixStartDateValue = value.startDate.valueOf();
        if (date !== `${unixStartDateValue}`) {
          navigate({
            pathname: location.pathname.replace(date, unixStartDateValue),
            search: location.search
          });
        }
      }}
    >
      <Button className={classes.button}>
        <Typography variant="button" color="inherit" noWrap>
          {defaultFormatMomentDate(moment(periodStartDate))}
        </Typography>
        <ExpandMoreIcon />
      </Button>
    </DialogTrigger>
  );
};

PeriodSelector.propTypes = {
  periodStartDate: PropTypes.number.isRequired,
  periodDuration: PropTypes.number.isRequired
};

export default withStyles(styles)(PeriodSelector);
