import Act from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';

const NO_ERRORS = {};
const UNIT_SUPPORT_PLAN_CODE = '9295';

export const unitSupportPlanCodeCannotBeBilledWithAPatientNamValidation = (act: Act) => {
  if (!act.nam) return NO_ERRORS;

  if ((act.codes || []).length === 0) return NO_ERRORS;

  const unitSupportPlantCodeInAct = act.codes.find(({ code }) => UNIT_SUPPORT_PLAN_CODE === code);

  if (!unitSupportPlantCodeInAct) return NO_ERRORS;

  return {
    [actFormFields.codes]: {
      _error: `Le code de prise en charge ${unitSupportPlantCodeInAct.code} ne peut être facturé avec un numéro d'assurance maladie.`
    }
  };
};

export default unitSupportPlanCodeCannotBeBilledWithAPatientNamValidation;
