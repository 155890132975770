import PropTypes from 'prop-types';
import React from 'react';
import Alert from '../../../Alert/Alert';

function ActWarningBox({ acts, message, DetailsComponent }) {
  return (
    <Alert severity="warning" message={message}>
      <DetailsComponent acts={acts} />
    </Alert>
  );
}

ActWarningBox.propTypes = {
  DetailsComponent: PropTypes.func.isRequired,
  acts: PropTypes.array.isRequired,
  message: PropTypes.node.isRequired
};

export default ActWarningBox;
