import intersection from 'lodash/intersection';
import { actFormFields } from 'app/containers/ActForm/constants';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { ROLE3, ROLE4 } from 'shared/ramq/contextElements/globalContextElements';
import { codeIsInRole1, codeIsInRole2 } from 'shared/domain/activity/act/service/actCodeUtils';
import RAMQCodes, { TimedAttributeKey } from 'shared/ramq/RamqCodes';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';

type RoleNumber = 1 | 2 | 3 | 4;

type ActCodeTuple = [string, string];

const EXCEPTION_CODES = ['15761'];

const ALLOWED_ACT_CODE_COMBINATIONS: Array<ActCodeTuple> = [['905', '9162']];

const NO_ERRORS = {};

export const differentRoleCodesValidation = (act: Act) => {
  if (act.codes.length === 0) return NO_ERRORS;

  const roles = getRolesInAct(act);

  if (roles.size <= 1) return NO_ERRORS;

  if (areActCodeCombinationsValid(act.codes)) return NO_ERRORS;

  return {
    [actFormFields.codes]: {
      _error: 'Les codes ne peuvent pas avoir des rôles différents'
    }
  };
};

const getRolesInAct = (act: Act) => {
  const userSpecialty = selectSpecialty()(getStore().getState());
  const roles = new Set<RoleNumber>();

  act.codes.forEach((code) => {
    const ramqCode = RAMQCodes.get(code.code);
    if (!ramqCode) throw new Error(`Code ${code.code} not found in RAMQ codes`);
    const isAddendum8 = RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isAddendum8, act.date);

    if (act.contextElements?.includes(ROLE3)) {
      roles.add(3);
    } else if (act.contextElements?.includes(ROLE4)) {
      roles.add(4);
    } else if (!EXCEPTION_CODES.includes(code.code) && !isAddendum8 && codeIsInRole1(code, act, userSpecialty)) {
      roles.add(1);
    } else if (codeIsInRole2(code, act, userSpecialty)) {
      roles.add(2);
    }
  });

  return roles;
};

const areActCodeCombinationsValid = (actCodes: ActCode[]): boolean => {
  const flattenedActCodes = actCodes.map(({ code }) => code);

  return ALLOWED_ACT_CODE_COMBINATIONS.some(
    (combination) => intersection(combination, flattenedActCodes).length === flattenedActCodes.length
  );
};

export default differentRoleCodesValidation;
