import { LOGIN_ROUTE } from 'app/containers/Navigation/constants';
import Button from 'application/components/button';
import Icon from 'application/components/icon';
import Markdown from 'application/components/markdown';
import AppStoreBadge from 'application/components/mobile-store/apple/app-store-badge';
import PlayStoreBadge from 'application/components/mobile-store/google/play-store-badge';
import useTranslate from 'application/hooks/use-translate';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuccessConfirmation = () => {
  const navigate = useNavigate();
  const translate = useTranslate('pages.lobby.signup');

  return (
    <div className="inline-flex w-full flex-col items-start justify-start overflow-hidden rounded border border-gray-200 bg-white shadow">
      <div className="inline-flex w-full flex-col items-center justify-center gap-4 border-b border-neutral-200 px-4 py-8">
        <div className="relative h-24 w-24">
          <div className="h-24 w-24 rounded-full bg-success opacity-25" />
          <Icon icon="circle-check" solid className="fa-5x absolute left-0 top-0 p-2 text-success" />
        </div>
        <div className="text-center text-xl font-bold text-success">{translate('success')}</div>

        <Markdown slug="signup-success-1" />

        <div className="mb-4 flex w-full flex-col items-start gap-4 md:flex-row md:items-center">
          <AppStoreBadge theme="dark" />
          <PlayStoreBadge />

          <Button type="button" theme="primary" icon="external-link" onClick={() => navigate(`/${LOGIN_ROUTE}`)}>
            {translate('web-app')}
          </Button>
        </div>

        <Markdown slug="signup-success-2" />
      </div>
    </div>
  );
};

export default SuccessConfirmation;
