import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import InputDialogTrigger from '../../../Dialogs/DialogTrigger/InputDialogTrigger/InputDialogTrigger';
import { displayGroup } from '../../utils/fieldTransformations';
import BillingGroupDialog from '../BillingGroupDialog/BillingGroupDialog';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';

export type BillingGroup = {
  name: string;
  number: string;
  codes: string[];
  types: (ActivityType.MIXTE | ActivityType.LUMP_SUM)[];
};

export interface Props extends WrappedFieldProps {}

const SpecificBillingGroupField: React.FunctionComponent<Props> = (props) => (
  <InputDialogTrigger
    {...props}
    formatValueFunction={displayGroup}
    dialogProps={{
      initialValue: props.input.value ? props.input.value : {}
    }}
    DialogComponent={BillingGroupDialog}
  />
);

export default SpecificBillingGroupField;
