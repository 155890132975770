import { assignIn, isEqual } from 'lodash';
import moment from 'moment-timezone';
import { call, put, select } from 'redux-saga/effects';
import { firestore } from '../../../server/Firebase';
import { logDefault, logError, perror } from '../../../shared/utils/plog';
import { add } from '../../../shared/collection/safeCollectionRepository';
import applyAuditInformation from '../../../utils/auditInformation';
import { selectCurrentLoggedUser } from '../../containers/Authentication/selectors';
import { selectUserIdInContext } from '../../containers/User/selectors';
import request from '../../infrastructure/api/apiUtils';
import { activitiesCollectionRef, userDocReference, userLogsCollectionRef } from '../collection/collectionReferences';
import getDocumentReferenceWithBoundId from './documentReferences';
import { verifyDocumentSavedProperly } from './verificationUtils';
import { sleep } from '../../../utils/timeUtils';
import { acquireLock, isLocked, releaseLock } from '../../../utils/lock';
import API_BASE_URL from '../../../shared/server/apiConfig';

export function* operateActivitiesWithUserFilter(
  activityType,
  documentSagaFunction,
  failureAction,
  { document, changes }
) {
  logDefault({
    type: 'saga',
    text: 'documentSagas/operateActivitiesWithUserFilter',
    array: [JSON.stringify(document), JSON.stringify(changes)]
  });

  const userId = yield select(selectUserIdInContext());

  if (document.userId && document.userId !== userId) {
    throw new Error(`Document ${document.id} does not belong to user ${userId}`);
  }

  const documentToOperate = assignIn(document, { userId, type: activityType });
  return yield* documentSagaFunction(activitiesCollectionRef(), failureAction, {
    document: documentToOperate,
    changes
  });
}

export function* updateDocumentInUserContext(collectionName, failure, action) {
  logDefault({
    type: 'saga',
    text: 'documentSagas/updateDocumentInUserContext',
    array: [JSON.stringify(collectionName), JSON.stringify(action)]
  });

  const userId = yield select(selectUserIdInContext());

  if (action?.document?.userId && action?.document?.userId !== userId) {
    throw new Error(`Document ${action?.document?.id} does not belong to user ${userId}`);
  }

  const reference = yield* getCollectionReferenceInUserContext(collectionName);
  yield* updateDocument(reference, failure, action);
}

export function* setDocumentInUserContext(collectionName, failure, action) {
  logDefault({
    type: 'saga',
    text: 'documentSagas/setDocumentInUserContext',
    array: [JSON.stringify(collectionName), JSON.stringify(action)]
  });

  const userId = yield select(selectUserIdInContext());

  if (action?.document?.userId && action?.document?.userId !== userId) {
    throw new Error(`Document ${action?.document?.id} does not belong to user ${userId}`);
  }

  const reference = yield* getCollectionReferenceInUserContext(collectionName);
  yield* setDocument(reference, failure, action);
}

export function* updateCollectionInUserContext(collectionName, success, failure, action) {
  logDefault({
    type: 'saga',
    text: 'documentSagas/setDocumentsInBatchInUserContext',
    array: [JSON.stringify(collectionName), JSON.stringify(action)]
  });

  const { documentMap } = action;

  const UPDATE_USER_PREFERENCE_COLLECTION_URL = `${API_BASE_URL}/updateUserPreferenceCollection/`;
  const userId = yield select(selectUserIdInContext());

  const postMethodOptions = {
    method: 'POST',
    body: JSON.stringify({
      documentMap,
      userId,
      userPreferenceCollectionName: collectionName
    })
  };

  try {
    yield call(request, UPDATE_USER_PREFERENCE_COLLECTION_URL, postMethodOptions);
    yield put(success());
  } catch (error) {
    yield put(failure(error));
  }
}

export function* removeDocumentInUserContext(collectionName, failure, action) {
  logDefault({
    type: 'saga',
    text: 'documentSagas/removeDocumentInUserContext',
    array: [JSON.stringify(collectionName), JSON.stringify(action)]
  });

  const reference = yield* getCollectionReferenceInUserContext(collectionName);

  const batch = firestore.batch();

  yield* removeDocumentReferenceInCategoriesNode(batch, reference, failure, action);
  yield* removeDocument(batch, reference, failure, action);

  yield call([batch, batch.commit]);
}

export function* setDocument(collectionRef, failure, { document }) {
  logDefault({
    type: 'saga',
    text: 'documentSagas/setDocument',
    array: ['Entering setDocument']
  });

  try {
    const userId = yield select(selectUserIdInContext());

    if (document?.userId && document?.userId !== userId) {
      throw new Error(`Document ${document?.id} does not belong to user ${userId}`);
    }

    const documentRef = getDocumentReferenceWithBoundId(collectionRef, document);
    const auditedDocument = yield* addAuditInformation(document);
    verifyDocumentSavedProperly(documentRef, auditedDocument, 'documentRef.set');

    logDefault({
      type: 'saga',
      text: 'documentSagas/setDocument',
      array: [`calling documentRef.set id ${documentRef.id}`, JSON.stringify(auditedDocument)]
    });

    yield call([documentRef, documentRef.set], auditedDocument);

    logDefault({
      type: 'saga',
      text: 'documentSagas/setDocument',
      array: [`called documentRef.set id ${documentRef.id}`]
    });

    return documentRef.id;
  } catch (e) {
    logError({
      type: 'saga',
      text: 'documentSagas/setDocument',
      array: ['setDocument failed', JSON.stringify(document), e.message, e]
    });
    yield* trackErrorInFirestore(e, {
      type: 'saveDocument',
      document: JSON.stringify(document, (_key, value) => (typeof value === 'undefined' ? '!!UNDEFINED!!' : value))
    });
    yield put(failure(e));
    return e;
  }
}

export function* updateDocument(reference, failure, { document, changes }) {
  logDefault({
    type: 'saga',
    text: 'documentSagas/updateDocument',
    array: ['Entering updateDocument']
  });
  try {
    const documentRef = reference.doc(document.id);
    const auditedChanges = yield* addAuditInformation(changes);

    const userId = yield select(selectUserIdInContext());

    if (document?.userId && document?.userId !== userId) {
      throw new Error(`Document ${document?.id} does not belong to user ${userId}`);
    }

    logDefault({
      type: 'saga',
      text: 'documentSagas/updateDocument',
      array: [`calling documentRef.update ${documentRef.id}`, JSON.stringify(auditedChanges)]
    });
    verifyDocumentSavedProperly(documentRef, auditedChanges, 'documentRef.update');

    yield call([documentRef, documentRef.update], auditedChanges);

    logDefault({
      type: 'saga',
      text: 'documentSagas/updateDocument',
      array: [`called documentRef.update ${documentRef.id}`]
    });
  } catch (e) {
    logError({
      type: 'saga',
      text: 'documentSagas/updateDocument',
      array: ['updateDocument failed', JSON.stringify(document), JSON.stringify(changes), e.message, e]
    });
    yield* trackErrorInFirestore(e, { type: 'updateDocument', document });
    yield put(failure(e));
  }
}

const getItemIndex = (list, item) => {
  if (!list) {
    return -1;
  }

  return list.findIndex((currentItem) => isEqual(currentItem, item));
};

export const waitForUpdateLock = async (lockName) => {
  let waitTimeTotal = 0;
  const waitTime = 500;
  const TWO_MINUTES_IN_MS = 120000;
  while (waitTimeTotal < TWO_MINUTES_IN_MS && !acquireLock(lockName)) {
    logDefault({
      type: 'saga',
      text: 'documentSagas/waitForUpdateLock',
      array: [`Waiting to acquire lock ${lockName}. Waited ${waitTimeTotal} ms.`]
    });

    waitTimeTotal += waitTime;
    // eslint-disable-next-line no-await-in-loop
    await sleep(waitTime);
  }

  return isLocked(lockName);
};

export function* replaceArrayItemWithinTransaction(
  collectionRef,
  failure,
  { documentId, keyToUpdate, itemToRemove, itemToAdd }
) {
  const changes = {};
  const auditedChanges = yield* addAuditInformation(changes);
  const lockName = `replaceArrayItemWithinTransaction_${documentId}`;

  try {
    logDefault({
      type: 'saga',
      text: 'documentSagas/replaceArrayItemWithinTransaction',
      array: [`Attempting to acquire lock ${lockName}.`]
    });
    const locked = yield waitForUpdateLock(lockName);
    if (!locked) {
      logDefault({
        type: 'saga',
        text: 'documentSagas/replaceArrayItemWithinTransaction',
        array: [`Could not acquire lock ${lockName}.`]
      });

      throw Error(`Could not acquire lock ${lockName}`);
    }

    const documentRef = collectionRef.doc(documentId);
    const document = yield documentRef.get();
    if (!document.exists) {
      logDefault({
        type: 'saga',
        text: 'documentSagas/replaceArrayItemWithinTransaction',
        array: [`Document with id ${documentId} does not exist.`]
      });

      throw Error(`Document with id ${documentId} does not exist`);
    }

    const dataFromFirestoreCache = document.data();

    if (itemToRemove) {
      const itemIndex = getItemIndex(dataFromFirestoreCache[keyToUpdate], itemToRemove);
      if (itemIndex > -1) {
        auditedChanges[keyToUpdate] = dataFromFirestoreCache[keyToUpdate];
        auditedChanges[keyToUpdate][itemIndex] = itemToAdd;

        yield call([documentRef, documentRef.update], auditedChanges);
      }
    } else {
      auditedChanges[keyToUpdate] = dataFromFirestoreCache[keyToUpdate];
      auditedChanges[keyToUpdate].push(itemToAdd);

      yield call([documentRef, documentRef.update], auditedChanges);
    }
  } catch (e) {
    logError({
      type: 'saga',
      text: 'documentSagas/replaceArrayItemWithinTransaction',
      array: [
        `replaceArrayItemWithinTransaction failed for documentId ${documentId}: ${JSON.stringify(e.message)}`,
        JSON.stringify(e),
        JSON.stringify(auditedChanges)
      ]
    });
    yield* trackErrorInFirestore(e, { type: 'replaceArrayItemWithinTransaction', documentId });
    yield put(failure(e));
  } finally {
    releaseLock(lockName);
  }
}

function* removeDocumentReferenceInCategoriesNode(batch, reference, failure, { document }) {
  const snapshot = yield reference.where('type', '==', 'categories').get();

  if (snapshot.docs.length === 1) {
    const categoriesNodeRef = reference.doc(snapshot.docs[0].id);
    const categoriesNodeSnapshot = yield categoriesNodeRef.get();
    const categoriesNodeObject = categoriesNodeSnapshot.data();

    const keys = Object.keys(categoriesNodeObject.categories);

    for (let i = 0; i < keys.length; i += 1) {
      categoriesNodeObject.categories[keys[i]] = removeItemIdFromCategory(
        document.id,
        categoriesNodeObject.categories[keys[i]]
      );
    }

    categoriesNodeObject.itemsWithoutCategory = removeItemIdFromCategory(
      document.id,
      categoriesNodeObject.itemsWithoutCategory
    );

    yield call([batch, batch.set], categoriesNodeRef, categoriesNodeObject);
  }
}

function removeItemIdFromCategory(itemId, category) {
  const categoryClone = { ...category };

  if (categoryClone.itemIds.includes(itemId)) {
    categoryClone.itemIds = categoryClone.itemIds.filter((item) => item !== itemId);
  } else {
    const keys = Object.keys(categoryClone.subCategories || {});

    for (let i = 0; i < keys.length; i += 1) {
      categoryClone.subCategories[keys[i]] = removeItemIdFromCategory(itemId, categoryClone.subCategories[keys[i]]);
    }
  }

  return categoryClone;
}

export function* removeDocument(batch, reference, failure, { document }) {
  try {
    const documentRef = reference.doc(document.id);
    logDefault({
      type: 'saga',
      text: 'documentSagas/removeDocument',
      array: [`calling documentRef.delete ${documentRef.id}`]
    });

    yield call([batch, batch.delete], documentRef);

    logDefault({
      type: 'saga',
      text: 'documentSagas/removeDocument',
      array: [`called documentRef.delete ${documentRef.id}`]
    });
  } catch (e) {
    logError({
      type: 'saga',
      text: 'documentSagas/removeDocument',
      array: ['removeDocument failed', JSON.stringify(document), e.message, e]
    });
    yield* trackErrorInFirestore(e, { type: 'removeDocument', document });
    yield put(failure(e));
  }
}

/* istanbul ignore next */
export function* trackErrorInFirestore(error, info) {
  try {
    const userId = yield select(selectUserIdInContext());
    const userLogsRef = userLogsCollectionRef();
    yield call(add, userLogsRef, {
      userId,
      ...info,
      error,
      userAgent: navigator.userAgent,
      cordova: !!window.cordova,
      message: error.message,
      stack: error.stack,
      createdOn: moment().valueOf()
    });
  } catch (_e) {
    perror("Can't even log in firestore...");
  }
}

function* addAuditInformation(document) {
  const loggedInUser = yield select(selectCurrentLoggedUser());
  return applyAuditInformation(document, loggedInUser.uid);
}

function* getCollectionReferenceInUserContext(collectionName) {
  const userId = yield select(selectUserIdInContext());
  return userDocReference(userId).collection(collectionName);
}
