import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ACTIVITY_ID_ROUTE_PARAM } from '../../../Navigation/constants';
import Trainings from '../../../../training/ui/Trainings/Trainings';

function TrainingRoute() {
  return (
    <Routes>
      <Route path={`${ACTIVITY_ID_ROUTE_PARAM}`} element={<Trainings />} />
    </Routes>
  );
}

export default TrainingRoute;
