import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';

import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import FullScreenLoading from '../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { getRamqCallLog } from './actions';
import { selectRamqCallLogLoading, selectRamqLog } from './selectors';

export const styles = () => ({
  container: {
    width: '100%',
    overflowX: 'auto'
  }
});

const RamqCallLog = ({ loading, ramqLog, classes, getRamqCallLog }) => {
  const { id } = useParams();

  useEffect(() => {
    getRamqCallLog(id);
  }, [id, getRamqCallLog]);

  return loading ? (
    <FullScreenLoading />
  ) : (
    <div className={classes.container}>
      <ReactJson src={ramqLog} name={false} displayDataTypes={false} displayObjectSize={false} />
    </div>
  );
};

RamqCallLog.propTypes = {
  loading: PropTypes.bool.isRequired,
  ramqLog: PropTypes.object.isRequired,
  getRamqCallLog: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  loading: selectRamqCallLogLoading(),
  ramqLog: selectRamqLog()
});

const mapDispatchToProps = {
  getRamqCallLog
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(RamqCallLog);
