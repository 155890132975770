import { Button, IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { getBuildNumber, isDEV } from '../../../shared/utils/executionEnvironment';
import VersionAPI from '../../infrastructure/api/VersionAPI';
import SEVERITY_COLORS from '../../ui/shared/severity/SeverityColors';
import deviceDetector from '../utils/DeviceDetector';

const VersionCheckerSnackbar: React.FunctionComponent = () => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = React.useState(false);
  const [dismissed, setDismissed] = React.useState(false);

  const { data } = useQuery({
    queryKey: ['version'],
    queryFn: () => VersionAPI.backendVersion(),
    enabled: !isDEV() && deviceDetector.isBrowser() && !dismissed,
    refetchInterval: 300000, // every 5 minutes
    refetchOnWindowFocus: true
  });

  if (!dismissed && data) {
    const { version } = data;
    if (version !== getBuildNumber() && !isNewVersionAvailable) {
      setIsNewVersionAvailable(true);
    }
  }

  const handleClose = () => {
    setDismissed(true);
    setIsNewVersionAvailable(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  if (!isNewVersionAvailable) {
    return null;
  }

  return (
    <Snackbar
      open={isNewVersionAvailable}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
    >
      <SnackbarContent
        style={{ flexWrap: 'nowrap', backgroundColor: SEVERITY_COLORS.info }}
        message={
          <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
            <InfoOutlinedIcon style={{ marginRight: '10px' }} />
            Une nouvelle version est disponible
          </span>
        }
        action={[
          <>
            {isNewVersionAvailable && (
              <Button key="refresh" color="inherit" size="small" onClick={handleRefresh}>
                Mettre à jour
              </Button>
            )}
            <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </>
        ]}
      />
    </Snackbar>
  );
};

export default VersionCheckerSnackbar;
