import withStyles, { StyledComponentProps } from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import React, { FunctionComponent } from 'react';
import LUMPSUM_DISPENSARY_AREA_MAP from '../../../../shared/ramq/domainValues/lumpsumDispensaryAreas';

export const styles = () => ({
  smallChip: {
    height: '18px',
    marginLeft: '1em',
    cursor: 'inherit'
  }
});

interface Props extends StyledComponentProps {
  dispensaryArea?: string;
}

export const RemoteActivityChip: FunctionComponent<Props> = ({ dispensaryArea = '', classes }) =>
  [LUMPSUM_DISPENSARY_AREA_MAP.remote, LUMPSUM_DISPENSARY_AREA_MAP.remoteOverride].includes(dispensaryArea) ? (
    <Chip label="D" className={classes!.smallChip} />
  ) : null;

export default withStyles(styles)(RemoteActivityChip);
