import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import { summaryActivitiesMapping } from '../../../components/Activities/componentsMappings';
import EmptyActivitiesIndicator from '../../../components/Activities/EmptyActivitiesIndicator/EmptyActivitiesIndicator';
import GroupedByTimeActivities from '../../../components/Activities/GroupedByTimeActivities/GroupedByTimeActivities';

const styles = (theme) => ({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    width: `calc(100% - ${theme.spacing(2)}px);`,
    maxWidth: 700,
    margin: theme.spacing(1)
  },
  activitiesContainer: {
    width: '100%'
  },
  adviceTitle: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
});

function AdviceActivitiesCorrection({ advice, classes }) {
  const translate = useTranslate();

  return (
    <Paper className={classes.container}>
      <Typography variant="subtitle1" className={classes.adviceTitle}>
        {translate('assistant.corrections-needed')}
      </Typography>
      <div className={classes.activitiesContainer}>
        <GroupedByTimeActivities
          activitiesLoading={false}
          activities={advice.activities}
          componentsMapping={summaryActivitiesMapping}
          EmptyIndicatorComponent={EmptyActivitiesIndicator}
        />
      </div>
    </Paper>
  );
}

AdviceActivitiesCorrection.propTypes = {
  advice: PropTypes.object.isRequired
};

export default withStyles(styles)(AdviceActivitiesCorrection);
