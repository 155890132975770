import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PATIENT_WITHOUT_ID_TYPE, {
  OUTSIDE_RAMQ_PATIENT_TYPE
} from '../../../../../shared/ramq/domainValues/situationTypes';
import { selectPatientType } from '../../selectors';
import NoNamBebeForm from './NoNamBebeForm/NoNamBebeForm';
import NoNamReleveForm from './NoNamFormReleve/NoNamReleveForm';
import NoNamUrgentForm from './NoNamUrgentForm/NoNamUrgentForm';
import OutsideRamqForm from './OutsideRamqForm/OutsideRamqForm';

export function NoNamFormDoctor(props) {
  const { formVisible, selectedPatientType } = props;
  return (
    <Collapse in={formVisible} timeout="auto" unmountOnExit>
      {selectedPatientType === PATIENT_WITHOUT_ID_TYPE.childUnderOneYear && <NoNamBebeForm />}
      {selectedPatientType === PATIENT_WITHOUT_ID_TYPE.patientNeedingUrgentCare && <NoNamUrgentForm />}
      {selectedPatientType === PATIENT_WITHOUT_ID_TYPE.refundRequest && <NoNamReleveForm />}
      {selectedPatientType === OUTSIDE_RAMQ_PATIENT_TYPE && <OutsideRamqForm />}
    </Collapse>
  );
}

NoNamFormDoctor.defaultProps = {
  selectedPatientType: ''
};

NoNamFormDoctor.propTypes = {
  formVisible: PropTypes.bool.isRequired,
  selectedPatientType: PropTypes.string
};

export const mapStateToProps = createStructuredSelector({
  selectedPatientType: selectPatientType()
});

export default compose(connect(mapStateToProps))(NoNamFormDoctor);
