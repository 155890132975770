import { put, select, takeLatest } from 'redux-saga/effects';
import shortid from 'shortid';
import queryBuilder from '../../../shared/collection/QueryBuilder';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import { syncCollection } from '../../firebase/collection/collectionSync';
import { selectUserIdInContext } from '../User/selectors';
import {
  INITIALIZE_ASSISTANT,
  initializeAssistantFailure,
  removeAdvice,
  updateAdvice,
  UPDATE_UNREAD_MESSAGES_COUNT,
  updateUnreadMessagesCount
} from './actions';
import ADVICE_ACTIVITIES_CORRECTION from './constants';

// Ultimately we'll want to fork multiple task initialization
export const getAdviceActionCreator = (adviceId, adviceType) => (activities) =>
  activities.length > 0 ? updateAdvice({ id: adviceId, type: adviceType, activities }) : removeAdvice(adviceId);

export function* getAssistantAdvices() {
  try {
    const userId = yield select(selectUserIdInContext());
    const query = queryBuilder
      .withBaseQuery(activitiesCollectionRef())
      .withUserId(userId)
      .withStatus(ActivityStatus.NEED_FIX)
      .build();
    const adviceId = shortid.generate();
    const adviceType = ADVICE_ACTIVITIES_CORRECTION;

    yield* syncCollection(query, getAdviceActionCreator(adviceId, adviceType), initializeAssistantFailure);
  } catch (error) {
    yield put(initializeAssistantFailure(error));
  }
}

export default function* assistantSaga() {
  yield takeLatest(INITIALIZE_ASSISTANT, getAssistantAdvices);
  yield takeLatest(UPDATE_UNREAD_MESSAGES_COUNT, updateUnreadMessagesCount);
}
