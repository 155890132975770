import { put, throttle } from 'redux-saga/effects';
import { UPDATE_INPUT_VALUE, updateSearchValue } from './actions';

export function* updateSearchValueInStore(action) {
  yield put(updateSearchValue(action.value));
}

export default function* searchSuggestionsSaga() {
  yield throttle(500, UPDATE_INPUT_VALUE, updateSearchValueInStore);
}
