import some from 'lodash/some';
import { selectActivitiesInContext } from '../../../../containers/Activities/selectors';
import { getStore } from '../../../../reduxStore/configureStore';
import Act, { isAct } from '../../../../../shared/domain/activity/act/model/Act';
import ActivityStatus from '../../../../../shared/core/activity/domain/ActivityStatus';

const UNIT_SUPPORT_PLAN_CODE = '9295';
const PATIENT_CARE_CODES = ['9097', '9098'];
const NO_WARNINGS = [];

const unitSupportPlanWarning = (form: Partial<Act>): string[] => {
  const jsForm = form as Act;

  if (!patientCareCodeIsBilled(jsForm.codes)) {
    return NO_WARNINGS;
  }

  if (doctorHasBilledUnitSupportPlanCodeOnDate(jsForm.date)) {
    return NO_WARNINGS;
  }

  return [composeWarningAccordingToNetworkStatus()];
};

const patientCareCodeIsBilled = (codes) => some(codes, ({ code }): boolean => PATIENT_CARE_CODES.includes(code));

const doctorHasBilledUnitSupportPlanCodeOnDate = (date): boolean => {
  const activitiesInContext = selectActivitiesInContext()(getStore().getState()) || [];
  const actsOfTheDay = activitiesInContext.filter(notCanceledActsOfTheDay(date));
  const unitSupportPlanCode = actsOfTheDay.find(actHasAUnitSupportPlanCode);

  return !!unitSupportPlanCode;
};

const notCanceledActsOfTheDay = (date) => (activity) =>
  isAct(activity) && activity.status !== ActivityStatus.CANCELED && activity.date === date;

const actHasAUnitSupportPlanCode = ({ codes }) => codes.find(({ code }) => code === UNIT_SUPPORT_PLAN_CODE);

const composeWarningAccordingToNetworkStatus = () => {
  const offlineWarning =
    "Lorsque vous serez en ligne, le forfait de prise en charge d'unité de soins intensifs (9295) sera automatiquement ajouté.";
  const onlineWarning =
    "Le forfait de prise en charge d'unité de soins intensifs (9295) sera automatiquement ajouté lors de l'envoi de ce carton.";

  if (!window.navigator.onLine) {
    return offlineWarning;
  }

  return onlineWarning;
};

export default unitSupportPlanWarning;
