import EXECUTION_ENV from '../config';
import { plog } from './plog';
import packageJson from '../../../package.json';

let executionEnv = process.env.REACT_APP_EXECUTION_ENV || EXECUTION_ENV;

plog(`Current execution environment is : ${getExecutionEnv()}`);

export const LEGACY_PROD = 'PROD';
export const PRODUCTION = 'Production';
export const PRODUCTION_CANADA = 'ProductionCanada';
export const CI = 'CI';
export const DEV = 'DEV';

export const getBuildNumber = () => process.env.REACT_APP_APP_VERSION || packageJson.version;

export default function getExecutionEnv() {
  return executionEnv;
}

export function isPROD() {
  return [PRODUCTION, PRODUCTION_CANADA, LEGACY_PROD].includes(getExecutionEnv());
}

export function isCI() {
  const currentExecutionEnv = getExecutionEnv();
  return currentExecutionEnv === CI || currentExecutionEnv === DEV;
}

export function isDEV() {
  return getExecutionEnv() === DEV;
}

export function setExecutionEnv(value) {
  executionEnv = value;
}

export function resetExecutionEnv() {
  executionEnv = EXECUTION_ENV;
}

export function ionicChannel() {
  return process.env.REACT_APP_CHANNEL_NAME;
}
