import React from 'react';
import { useToggle } from 'react-use';
import TextInput from 'application/components/form-controls/text-input';
import Icon from 'application/components/icon';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import useTranslate from 'application/hooks/use-translate';

enum PasswordStrenght {
  WEAK = 'WEAK',
  MEDIUM = 'MEDIUM',
  STRONG = 'STRONG'
}

interface Props extends ValidatedFieldProps {
  value: string;
  disabled?: boolean;
  required?: boolean;
  validateStrenght?: boolean;
  onChange: (value: string) => void;
}

const evaluatePasswordStrenght = (password: string): PasswordStrenght => {
  if (password.length < 8) return PasswordStrenght.WEAK;
  if (/[^A-Za-z0-9]/.test(password)) return PasswordStrenght.STRONG;

  return PasswordStrenght.MEDIUM;
};

const PasswordInput = ({ value, validateStrenght = false, customValidation, ...textInputProps }: Props) => {
  const [passwordIsVisible, togglePasswordIsVisible] = useToggle(false);
  const translate = useTranslate('components.form-controls.password-input');

  const passwordStrenght = validateStrenght ? evaluatePasswordStrenght(value) : null;

  return (
    <>
      <div className="relative w-full">
        <TextInput
          type={passwordIsVisible ? 'text' : 'password'}
          value={value}
          placeholder="***********"
          customValidation={
            passwordStrenght && value
              ? {
                  isValid: passwordStrenght !== PasswordStrenght.WEAK,
                  errorMessage: translate('strenght-error')
                }
              : customValidation
          }
          {...textInputProps}
        />

        <button
          type="button"
          tabIndex={-1}
          className="absolute right-4 top-0 flex h-[54px] items-center text-neutral-900"
          onClick={togglePasswordIsVisible}
        >
          <Icon icon={passwordIsVisible ? 'eye-slash' : 'eye'} />
        </button>
      </div>

      {passwordStrenght && passwordStrenght !== PasswordStrenght.WEAK && (
        <div className="mt-1 text-sm" role="alert">
          {translate('password-strenght-prefix')}
          {passwordStrenght === PasswordStrenght.MEDIUM && (
            <span className="font-bold text-yellow-500">{translate('stength-medium')}</span>
          )}
          {passwordStrenght === PasswordStrenght.STRONG && (
            <span className="font-bold text-green-500">{translate('stength-strong')}</span>
          )}
        </div>
      )}
    </>
  );
};

export default PasswordInput;
