import { actFormFields } from 'app/containers/ActForm/constants';
import { selectActivitiesByDayInContext } from 'app/containers/Activities/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import Act, { ActCode } from 'shared/domain/activity/act/model/Act';

const NO_ERRORS = {};
const MAX_DURATION_SUPPLEMENT = 3;
const DURATION_SUPPLEMENT_CODES = ['15392', '15394', '15399', '15401'];
const EMERGENCY_ROOM_EXCEPTION_CODES = ['51', '15404'];

export const tooManyDurationSupplementValidation = (act: Act) => {
  if ((act.codes ?? []).some(hasEmergencyRoomCode)) return NO_ERRORS;

  if (act.codes.every((code) => !willAddDurationSupplement(code))) {
    return NO_ERRORS;
  }

  const activities = selectActivitiesByDayInContext(act.date)(getStore().getState());
  const acts = activities.filter((activity) => activity.id !== act.id && isValidAct(activity));

  const actsWithDurationSupplement = acts.filter((activity) =>
    activity.codes.some((code) => !hasEmergencyRoomCode(code) && hasDurationSupplementCode(code))
  );

  if (actsWithDurationSupplement.length >= MAX_DURATION_SUPPLEMENT) {
    const durationSupplementCode = act.codes.find((code) => hasDurationSupplementCode(code));
    if (durationSupplementCode) {
      return {
        [actFormFields.codes]: {
          _error: `Vous avez atteint le nombre maximum de suppléments de durée (${MAX_DURATION_SUPPLEMENT}). Veuillez enlever le code ${durationSupplementCode.code} ou le supplément de durée sur un autre acte de la journée.`
        }
      };
    }

    return {
      [actFormFields.codes]: {
        _error: `Vous avez atteint le nombre maximum de suppléments de durée (${MAX_DURATION_SUPPLEMENT}). Veuillez enlever le détail requis de durée sur cet acte ou le supplément de durée sur un autre acte de la journée.`
      }
    };
  }
};

const hasDurationSupplementCode = ({ code }: ActCode) => DURATION_SUPPLEMENT_CODES.includes(code);

const hasEmergencyRoomCode = ({ code }: ActCode) => EMERGENCY_ROOM_EXCEPTION_CODES.includes(code);

const willAddDurationSupplement = (code: ActCode) =>
  code.endTimeWhenDurationIsOver30Minutes || code.endTimeWhenDurationIsOver60Minutes || hasDurationSupplementCode(code);

const isValidAct = (activity) => activity.type === ActivityType.ACT && activity.status !== ActivityStatus.CANCELED;

export default tooManyDurationSupplementValidation;
