import { isBrowser, isMobile } from 'react-device-detect';

class DeviceDetector {
  isMobile() {
    return isMobile;
  }

  isBrowser() {
    return isBrowser;
  }
}

const deviceDetector = new DeviceDetector();
export default deviceDetector;
