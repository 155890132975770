import PropTypes from 'prop-types';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import FileUploader from '../../../components/FileUploader/FileUploader';
import FormElement from '../../../components/Form/FormElement/FormElement';

export const styles = () => ({
  noFullFlex: {
    flex: '0 1 auto'
  }
});

export class AttachFile extends React.Component {
  fileSelectedHandler = (fileEntry) => {
    const { attachmentType, documentId } = this.props;

    /*
     * fileEntry looks like this :
     * lastModified: 1559857735573
     * lastModifiedDate: Thu Jun 06 2019 17:48:55 GMT-0400 (heure avancée de l’Est) {}
     * name: "oatey-pvc-medium-gray-cement.pdf"
     * size: 284751
     * type: "application/pdf"
     * webkitRelativePath: ""
     */
    const persistFileLocallyBeforeUpload = !!window.cordova;
    this.props.fileReadyForUploadHandler(documentId, attachmentType, fileEntry, persistFileLocallyBeforeUpload);
  };

  render() {
    const { classes, fieldName } = this.props;

    return (
      <FormElement
        className={classes.noFullFlex}
        name={fieldName}
        component={FileUploader}
        fileSelectedHandler={this.fileSelectedHandler}
      />
    );
  }
}

AttachFile.propTypes = {
  attachmentType: PropTypes.string.isRequired,
  documentId: PropTypes.string.isRequired,
  fileReadyForUploadHandler: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired
};

export default withStyles(styles)(AttachFile);
