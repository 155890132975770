import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LobbyLayout from 'application/components/layouts/lobby.layout';
import useTranslate from 'application/hooks/use-translate';
import Account from 'application/pages/lobby/signup/account';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import Button from 'application/components/button';
import Section from 'application/components/form-controls/section';
import AdditionalInformation from 'application/pages/lobby/signup/additional-information';
import Security from 'application/pages/lobby/signup/security';
import Payment from 'application/pages/lobby/signup/payment';
import PaidPlan, { DEMO_FAKE_PRICE_ID } from 'application/pages/lobby/signup/paid-plan';
import { gql, useMutation } from '@apollo/client';
import SuccessConfirmation from 'application/pages/lobby/signup/success-confirmation';
import useQueryParams from 'application/hooks/use-query-params';
import { useQuery } from '@tanstack/react-query';
import StripeService from 'application/services/stripe-service';
import Icon from 'application/components/icon';
import ReCAPTCHA from 'react-google-recaptcha';
import Error from 'application/pages/lobby/signup/error';

interface SignupState {
  user: {
    title: string;
    firstName: string;
    lastName: string;
    specialty: SpecialtyName | null;
    practiceNumber: string;
    address: string;
    city: string;
    zipCode: string;
    email: string;
    province: string;
    phone: string;
    password: string;
    fiscalYearStart: string;
    groupName: string;
    groupNumber: string;
    previousAgencyName: string;
    previousAgencyNumber: string;
    agencySwitchDate: string;
    productId?: string;
    referralId?: string;
  };
  temporary: {
    stripePriceId: string | null;
  };
}

export interface SectionProps {
  userState: SignupState['user'];
  temporaryState: SignupState['temporary'];
  generateRecaptchaToken?: () => Promise<string | undefined>;
  onUserChange: (updatedUser: SignupState['user']) => void;
  onTemporaryStateChange: (updatedTemporaryState: SignupState['temporary']) => void;
  onValidate: (isValid: boolean) => void;
  onError: () => void;
  onForceSuccess: () => void;
}

interface QueryParams {
  'checkout-session-id'?: string;
  'referralId'?: string;
  'firstName'?: string;
  'lastName'?: string;
  'email'?: string;
}

enum SignupSectionSlug {
  ACCOUNT = 'ACCOUNT',
  SECURITY = 'SECURITY',
  ACCOUNT_TYPE = 'ACCOUNT_TYPE',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
  PAYMENT = 'PAYMENT',
  PAID_PLAN = 'PAID_PLAN'
}

const INITIAL_SIGNUP_STATE: SignupState = {
  user: {
    title: '',
    firstName: '',
    lastName: '',
    specialty: null,
    practiceNumber: '',
    address: '',
    city: '',
    zipCode: '',
    email: '',
    province: 'QC',
    phone: '',
    password: '',
    fiscalYearStart: '1',
    groupName: '',
    groupNumber: '',
    previousAgencyName: '',
    previousAgencyNumber: '',
    agencySwitchDate: '',
    productId: undefined,
    referralId: undefined
  },
  temporary: {
    stripePriceId: null
  }
};

const SIGNUP_MUTATION = gql`
  mutation Signup($user: CreateUserInput!) {
    createUser(user: $user) {
      id
    }
  }
`;

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const SignupPage = () => {
  const recaptchaRef = useRef<{ executeAsync: () => Promise<string> }>();
  const translate = useTranslate('pages.lobby.signup');
  const [isInError, setIsInError] = useState(false);
  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const [currentSectionIsValid, setCurrentSectionIsValid] = useState(false);
  const [currentSectionSlug, setCurrentSectionSlug] = useState<SignupSectionSlug>(SignupSectionSlug.ACCOUNT);
  const queryParams = useQueryParams<QueryParams>();
  const [signupState, setSignupState] = useState<SignupState>({
    ...INITIAL_SIGNUP_STATE,
    user: {
      ...INITIAL_SIGNUP_STATE.user,
      firstName: queryParams.firstName ?? '',
      lastName: queryParams.lastName ?? '',
      email: queryParams.email ?? '',
      referralId: queryParams.referralId
    }
  });

  const { data: sessionStatus, isFetching: isFetchingStatus } = useQuery({
    queryKey: ['stripe-subscription-session-status'],
    queryFn: () => {
      if (!queryParams['checkout-session-id']) return;
      return StripeService.getSubscriptionSessionStatus(queryParams['checkout-session-id']);
    },
    enabled: !!queryParams['checkout-session-id']
  });

  useEffect(() => {
    if (sessionStatus?.status === 'paid') {
      setIsSignupSuccessful(true);
    }

    if (sessionStatus?.status === 'unpaid') {
      setIsInError(true);
    }
  }, [sessionStatus?.status]);

  const [signupUser, { loading }] = useMutation(SIGNUP_MUTATION);

  const { currentSectionIndex, currentSection, previousSection, nextSection } = useMemo(() => {
    const sections = [
      {
        title: translate('sections.account'),
        slug: SignupSectionSlug.ACCOUNT,
        component: Account
      },
      {
        title: translate('sections.security'),
        slug: SignupSectionSlug.SECURITY,
        component: Security
      },
      {
        title: translate('sections.paid-plan'),
        slug: SignupSectionSlug.PAID_PLAN,
        component: PaidPlan
      },
      {
        title: translate('sections.additional-information'),
        slug: SignupSectionSlug.ADDITIONAL_INFORMATION,
        component: AdditionalInformation
      },
      {
        title: translate('sections.payment'),
        slug: SignupSectionSlug.PAYMENT,
        component: Payment
      }
    ];

    const currentSectionIndex = sections.findIndex(({ slug }) => slug === currentSectionSlug);
    const currentSection = sections[currentSectionIndex];

    return {
      currentSectionIndex,
      currentSection,
      previousSection: currentSectionIndex > 0 ? sections[currentSectionIndex - 1] : null,
      nextSection: currentSectionIndex < sections.length - 1 ? sections[currentSectionIndex + 1] : null
    };
  }, [currentSectionSlug, translate]);

  const generateRecaptchaToken = useCallback(async () => {
    if (!recaptchaRef.current) return undefined;

    return recaptchaRef.current.executeAsync();
  }, [recaptchaRef]);

  const handleSignup = useCallback(async () => {
    try {
      const recaptchaToken = await recaptchaRef.current?.executeAsync();
      await signupUser({
        variables: { user: signupState.user },
        context: {
          headers: {
            'recaptcha-token': recaptchaToken
          }
        }
      });

      if (signupState.temporary.stripePriceId === DEMO_FAKE_PRICE_ID) {
        setIsSignupSuccessful(true);
      } else {
        setCurrentSectionSlug(SignupSectionSlug.PAYMENT);
      }
    } catch (error) {
      setIsInError(true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [signupUser, signupState]);

  const isReadyToSubmit =
    (currentSectionSlug === SignupSectionSlug.PAID_PLAN &&
      signupState.temporary.stripePriceId === DEMO_FAKE_PRICE_ID) ||
    currentSectionSlug === SignupSectionSlug.ADDITIONAL_INFORMATION;

  if (isFetchingStatus) {
    return (
      <LobbyLayout title={translate('title')} subtitle={translate('subtitle')}>
        <Section>
          <div className="w-full py-12 text-center text-4xl">
            <Icon loading />
          </div>
        </Section>
      </LobbyLayout>
    );
  }

  if (isInError) {
    return (
      <LobbyLayout title={translate('title')} subtitle={translate('subtitle')}>
        <Error />
      </LobbyLayout>
    );
  }

  if (isSignupSuccessful) {
    return (
      <LobbyLayout title={translate('title')} subtitle={translate('subtitle')}>
        <SuccessConfirmation />
      </LobbyLayout>
    );
  }

  return (
    <LobbyLayout title={translate('title')} subtitle={translate('subtitle')}>
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_KEY} />
      <Section label={currentSection.title}>
        <currentSection.component
          userState={signupState.user}
          temporaryState={signupState.temporary}
          generateRecaptchaToken={generateRecaptchaToken}
          onError={() => setIsInError(true)}
          onUserChange={(user) => setSignupState((state) => ({ ...state, user }))}
          onTemporaryStateChange={(temporary) => setSignupState((state) => ({ ...state, temporary }))}
          onValidate={setCurrentSectionIsValid}
          onForceSuccess={() => setIsSignupSuccessful(true)}
        />
        <Section.Footer justify={currentSectionIndex === 0 ? 'right' : 'between'}>
          {previousSection && currentSectionSlug !== SignupSectionSlug.PAYMENT && (
            <Button
              type="button"
              icon="arrow-left"
              iconPosition="left"
              theme="link"
              tabIndex={-1}
              solid
              onClick={() => setCurrentSectionSlug(previousSection.slug)}
            >
              <span className="hidden sm:inline-block">{translate('previous')}</span>
            </Button>
          )}

          {nextSection && !isReadyToSubmit && (
            <Button
              type="button"
              icon="arrow-right"
              theme="primary"
              disabled={!currentSectionIsValid}
              solid
              onClick={() => setCurrentSectionSlug(nextSection.slug)}
            >
              {translate('next')}
            </Button>
          )}

          {isReadyToSubmit && (
            <Button
              type="button"
              icon="circle-check"
              theme="primary"
              loading={loading}
              disabled={!currentSectionIsValid}
              onClick={handleSignup}
            >
              {translate('next')}
            </Button>
          )}
        </Section.Footer>
      </Section>
    </LobbyLayout>
  );
};

export default SignupPage;
