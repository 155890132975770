import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import CONTRALATERAL_BREAST from '../../../../shared/ramq/requiredDetails/contralateralBreast';

const associatedFields: AssociatedField[] = [
  {
    label: 'Chirurgie sein contralatérale même séance ?',
    getName: (index) => `codes[${index}].${CONTRALATERAL_BREAST}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    notRequired: true
  }
];

const contralateralBreast: RequiredDetail = {
  associatedFields
};

export default contralateralBreast;
