import React from 'react';
import PropTypes from 'prop-types';
import CalendarRangePicker from '../../../../../components/CalendarRangePicker/CalendarRangePicker';
import generateDays from '../../../../../validations/training/Period';

function CalendarRangePickerField({ startDate, endDate, date, updateDays }) {
  const startDateInput = startDate.input;
  const endDateInput = endDate.input;
  const dateInput = date.input;

  return (
    <CalendarRangePicker
      startDate={new Date(startDateInput.value)}
      endDate={new Date(endDateInput.value)}
      onChangeRange={(newStartDate, newEndDate) => {
        dateInput.onChange(newStartDate.valueOf());
        startDateInput.onChange(newStartDate.valueOf());
        endDateInput.onChange(newEndDate.valueOf());

        updateDays(generateDays(newStartDate, newEndDate));
      }}
    />
  );
}

CalendarRangePickerField.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
  updateDays: PropTypes.func.isRequired
};

export default CalendarRangePickerField;
