import Collapse from '@material-ui/core/Collapse';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import DefaultNoNamFormAdmin from './DefaultNoNamFormAdmin';
import { OUTSIDE_RAMQ_PATIENT_TYPE } from '../../../../../shared/ramq/domainValues/situationTypes';
import OutsideRamqForm from '../Doctor/OutsideRamqForm/OutsideRamqForm';
import { selectPatientType } from '../../selectors';

interface Props {
  formVisible: boolean;
  selectedPatientType?: string;
}

export function NoNamFormAdmin({ formVisible, selectedPatientType }: Props) {
  return (
    <Collapse in={formVisible} timeout="auto" unmountOnExit>
      {selectedPatientType !== OUTSIDE_RAMQ_PATIENT_TYPE ? <DefaultNoNamFormAdmin /> : <OutsideRamqForm />}
    </Collapse>
  );
}

const mapStateToProps = createStructuredSelector({
  selectedPatientType: selectPatientType()
});

export default connect(mapStateToProps, null)(NoNamFormAdmin);
