import PropTypes from 'prop-types';
import React from 'react';

class DialogTrigger extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false
    };
  }

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  render() {
    const { children, DialogComponent, onItemSelected, dialogProps, disabled } = this.props;
    const childrenWithOpenDialogAction = React.cloneElement(children, { onClick: () => this.openDialog() });

    return (
      <>
        {disabled ? children : childrenWithOpenDialogAction}
        <DialogComponent
          onClose={this.closeDialog}
          open={this.state.dialogOpen}
          onItemSelected={onItemSelected}
          {...dialogProps}
        />
      </>
    );
  }
}

DialogTrigger.propTypes = {
  DialogComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  children: PropTypes.object.isRequired,
  onItemSelected: PropTypes.func,
  disabled: PropTypes.bool,
  dialogProps: PropTypes.object
};

DialogTrigger.defaultProps = {
  dialogProps: {},
  disabled: false,
  onItemSelected: undefined
};

export default DialogTrigger;
