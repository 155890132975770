export const USER_STATUS = 'status';
export const PRACTICE_NUMBER = 'practiceNumber';
export const FULL_NAME = 'fullName';
export const SPECIALTY = 'specialty';
export const GROUP_NAME = 'groupName';
export const GROUP_MANAGERS_NAMES = 'groupManagersNames';
export const POOL_NAME = 'poolName';
export const POOL_MANAGERS_NAMES = 'poolManagersNames';
export const SUBSCRIPTION_PACKAGE = 'stripeProductId';
export const PLACE = 'place';
export const ACTIVE_ABSENCE = 'activeAbsence';
export const ROLE = 'role';
export const ID = 'id';
