import { set } from 'lodash';
import PRE_SURGERY_INTERVENTION from '../../../../shared/ramq/requiredDetails/preSurgeryIntervention';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

export const PDT_ERROR = 'Vous ne pouvez facturer ce PDT';
const pdtCannotBeBilledError = (index) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${PRE_SURGERY_INTERVENTION}` },
    `codes[${index}].${PRE_SURGERY_INTERVENTION}`,
    PDT_ERROR
  );

export const makeSurePDTCodeCanBeBill = (act) => {
  const codesInError = act.codes.map((code, index) =>
    code[PRE_SURGERY_INTERVENTION] === false ? pdtCannotBeBilledError(index) : {}
  );
  return codesInError.reduce(combineErrors, {});
};

const summaryRender = (code) =>
  code[PRE_SURGERY_INTERVENTION] !== undefined
    ? [{ name: 'Int. préchir.', value: code[PRE_SURGERY_INTERVENTION] ? 'Oui' : 'Non' }]
    : [];

const associatedFields = [
  {
    label: 'Intervention préchirurgicale ?',
    getName: (index) => `codes[${index}].${PRE_SURGERY_INTERVENTION}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  }
];

const associatedValidations = [makeSurePDTCodeCanBeBill];

export default { associatedFields, associatedValidations, summaryRender };
