import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import FormRow from '../../../../components/Form/FormRow/FormRow';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    dialog: {
      width: '100%',
      maxWidth: '400px',
      [breakpoints.down('sm')]: {
        maxWidth: '600px'
      }
    },
    dialogContent: {
      paddingBottom: theme.spacing(1)
    },
    customCodeDescriptionTitle: {
      paddingBottom: theme.spacing(2)
    },
    keepSameDescriptionButtonContainer: {
      justifyContent: 'center',
      margin: 0
    }
  };
};

export class EditItemDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      description: this.props.mode === 'edit' ? this.props.diagnostic.description : ''
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
  }

  handleSave() {
    const { description } = this.state;
    if (description.trim() === '') {
      this.props.onSave(this.props.diagnostic.description);
    } else {
      this.props.onSave(description);
    }
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleChangeDescription(event) {
    this.setState({ description: event.target.value });
  }

  render() {
    const { open, diagnostic, onSave, classes } = this.props;
    const { description } = this.state;

    return (
      <Dialog open={open} onClose={this.handleCancel} classes={{ paper: classes.dialog }}>
        <DialogTitle id="alert-dialog-title" className={classes.customCodeDescriptionTitle}>
          <FormattedMessage id="favorites.diagnostic.editDiagnosticDescription" />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl fullWidth>
            <TextField
              id="input-description"
              label={<FormattedMessage id="favorites.diagnostic.diagnosticDescription" />}
              value={description}
              onChange={this.handleChangeDescription}
            />
          </FormControl>
          <FormRow className={classes.keepSameDescriptionButtonContainer}>
            <Button id="keep-original-description-button" onClick={() => onSave(diagnostic.description)}>
              <FormattedMessage id="favorites.global.keepOriginalDescription" />
            </Button>
          </FormRow>
        </DialogContent>
        <DialogActions>
          <Button id="cancel-dialog" onClick={this.handleCancel} color="primary">
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button id="confirm-dialog" onClick={this.handleSave} color="primary" autoFocus>
            <FormattedMessage id="global.save" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditItemDialog.defaultProps = {
  diagnostic: { description: '' }
};

EditItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['edit', 'append']).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  diagnostic: PropTypes.object
};

export default withStyles(styles)(EditItemDialog);
