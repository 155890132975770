import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { RAMQAccreditationStatus } from 'application/hooks/use-firestore-user-profile';
import useTranslate from 'application/hooks/use-translate';
import Button from 'application/components/button';
import downloadFile from 'application/utilities/download-file';
import useToast from 'application/hooks/use-toast';
import RalphApi from 'application/services/ralph-api';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import { SelectorField, SingleLineField } from '../../../../components/Form/Fields';

interface Props {
  userId: string;
}

export const Ramq: React.FunctionComponent<Props> = ({ userId }: Props) => {
  const translate = useTranslate('ramq-accreditation-status');

  const [isFetchingRAMQAccreditationForm, setIsFetchingRAMQAccreditationForm] = useState(false);

  const { addToast } = useToast();

  const fetchRAMQAccreditationForm = () => {
    setIsFetchingRAMQAccreditationForm(true);

    return RalphApi.sendRequest(`users/${userId}/ramq-accreditation-request`, 'GET')
      .then((response) => {
        if (response.status === 404) {
          addToast({
            message: translate('no-data-available'),
            type: 'warning'
          });

          return;
        }

        if (!response.ok) {
          addToast({
            message: translate('error-downloading-report'),
            type: 'error'
          });

          return;
        }

        downloadFile(response);
      })
      .catch(() => {
        addToast({
          message: translate('error-downloading-report'),
          type: 'error'
        });
      })
      .finally(() => {
        setIsFetchingRAMQAccreditationForm(false);
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography gutterBottom variant="h6">
          RAMQ
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="ramqUsername"
          label="Nom d'utilisateur"
          component={SingleLineField}
          type="password"
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="ramqPassword" label="Mot de passe" component={SingleLineField} type="password" disabled />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          component={SelectorField}
          label="Statut de l'accréditation"
          list={Object.values(RAMQAccreditationStatus).map((value) => ({ label: translate(value), value }))}
          name="ramqAccreditationStatus"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Button
          className="btn btn-primary"
          icon="arrow-down-to-line"
          iconPosition="left"
          loading={isFetchingRAMQAccreditationForm}
          onClick={fetchRAMQAccreditationForm}
          type="button"
        >
          Télécharger le formulaire de la RAMQ
        </Button>
      </Grid>
    </Grid>
  );
};
