import { detailedDiff } from 'deep-object-diff';
import { useEffect, useState } from 'react';
import FirebaseUserRepository, { UserVersion } from '../../../infrastructure/users/FirebaseUserRepository';

interface UserVersionWithDelta {
  delta: {
    added: object;
    deleted: object;
    updated: object;
  };
  userVersion: UserVersion;
}

export default function useFetchUserHistory(userId: string) {
  const [versionsWithDeltas, setVersionsWithDeltas] = useState<UserVersionWithDelta[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const fetchUserHistory = async () => {
      setLoading(true);
      const userVersions = await new FirebaseUserRepository().getHistory(userId);
      const versionsWithDelta = calculateDelta(userVersions);
      setVersionsWithDeltas(versionsWithDelta);
    };
    try {
      fetchUserHistory();
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  return { versionsWithDeltas, loading, error };
}

function calculateDelta(userVersions: UserVersion[]) {
  const versionsWithDelta: UserVersionWithDelta[] = [
    {
      delta: {
        added: userVersions[0].user,
        deleted: {},
        updated: {}
      },
      userVersion: userVersions[0]
    }
  ];
  let previousUserVersion: UserVersion | undefined;

  for (const userVersion of userVersions) {
    if (previousUserVersion) {
      const delta = detailedDiff(previousUserVersion.user, userVersion.user);
      versionsWithDelta.push({ delta, userVersion });
    }
    previousUserVersion = userVersion;
  }

  return versionsWithDelta;
}
