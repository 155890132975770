import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ActDetailsRow from '../ActDetailsRow/ActDetailsRow';

const startTime = (act) => act.remp || act.start;
export function ActsDayDetailTable({ acts, pool }) {
  return (
    <Table>
      <TableBody>
        {sortBy(acts.billedActivities, startTime).map((act) => (
          <ActDetailsRow key={act.id} act={act} pool={pool} billed />
        ))}
        {sortBy(acts.notBilledActivities, startTime).map((act) => (
          <ActDetailsRow key={act.id} act={act} pool={pool} />
        ))}
      </TableBody>
    </Table>
  );
}

ActsDayDetailTable.propTypes = {
  acts: PropTypes.object.isRequired,
  pool: PropTypes.bool.isRequired
};

export default ActsDayDetailTable;
