import { set } from 'lodash';
import { AssociatedField, AssociatedValidation, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { BILLING_BY_ACT_FOR_SEDATION_ANALGESIA_ONLY_MADE_BY_ANOTHER_DOCTOR_REQUIRED_DETAIL } from '../../../../shared/ramq/requiredDetails/rule28';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';

export const requiredDetailKey = BILLING_BY_ACT_FOR_SEDATION_ANALGESIA_ONLY_MADE_BY_ANOTHER_DOCTOR_REQUIRED_DETAIL;

export const ERROR = 'Vous ne pouvez facturer ce code seul sans un PDT';

const generateError = (index) =>
  set({ elementIdToScrollTo: `codes[${index}].${requiredDetailKey}` }, `codes[${index}].${requiredDetailKey}`, ERROR);

export const cannotBillCodeWithoutPdtWhenSedationAnalgesiaNotMadeByAnotherDoctor = (act) => {
  const codesInError = act.codes.map((code, index) => (code[requiredDetailKey] === false ? generateError(index) : {}));
  return codesInError.reduce(combineErrors, {});
};

const associatedFields: AssociatedField[] = [
  {
    label: 'ACTE PG-28 par autre MD.',
    getName: (index) => `codes[${index}].${requiredDetailKey}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  }
];

const associatedValidations: AssociatedValidation[] = [
  cannotBillCodeWithoutPdtWhenSedationAnalgesiaNotMadeByAnotherDoctor
];

const billingByActForSedationAnalgesiaOnly: RequiredDetail = {
  associatedFields,
  associatedValidations
};

export default billingByActForSedationAnalgesiaOnly;
