import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import useTranslate from 'application/hooks/use-translate';

export function ActivitiesSearchBar({ changeHandler, value }) {
  const translate = useTranslate('activities-search-options');
  return (
    <TextField
      value={value}
      onChange={(evt) => changeHandler(evt.target.value)}
      fullWidth
      placeholder={translate('search-bar-placeholder')}
      variant="outlined"
    />
  );
}

ActivitiesSearchBar.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default ActivitiesSearchBar;
