import actions from 'redux-form/lib/actions';
import { ASSETS, ATTACHMENTS, CARD_PICTURE_ASSET_TYPE } from '../Storage/constants';
import { ACT_FORM_NAME, actFormFields } from './constants';
import { selectPatientType } from './selectors';
import { getStore } from '../../reduxStore/configureStore';

const NOOP_ACTION = { type: 'NOOP' };

export const GET_ACTS_24_HOURS_BEFORE_DATE = 'getActs24HoursBeforeDate';
export const getActs24HoursBeforeDate = (date) => ({
  type: GET_ACTS_24_HOURS_BEFORE_DATE,
  date
});

export const GET_ACTS_24_HOURS_BEFORE_DATE_SUCCESS = 'getActs24HoursBeforeDateSuccess';
export const getActs24BeforeDateSuccess = (acts) => ({
  type: GET_ACTS_24_HOURS_BEFORE_DATE_SUCCESS,
  acts
});

export const GET_ACTS_24_HOURS_BEFORE_DATE_FAILURE = 'getActs24HoursBeforeDateFailure';
export const getActs24BeforeDateFailure = (error) => ({
  type: GET_ACTS_24_HOURS_BEFORE_DATE_FAILURE,
  error
});

export const ACT_FILE_READY_FOR_UPLOAD = 'actFileReadyForUpload';
export const actFileReadyForUpload = (documentId, attachmentType, fileEntry, persistFileLocallyBeforeUpload) => ({
  type: ACT_FILE_READY_FOR_UPLOAD,
  documentId,
  attachmentType,
  fileEntry,
  persistFileLocallyBeforeUpload
});

export function updateUrgentCallDetails(details) {
  return actions.change(ACT_FORM_NAME, actFormFields.urgentCallDetails, details);
}

export function updateStartTime(value) {
  return actions.change(ACT_FORM_NAME, actFormFields.start, value);
}

export function updateEndTime(value) {
  return actions.change(ACT_FORM_NAME, actFormFields.end, value);
}

export function updateSavedPictureInActForm(fileUrl, fileName, temporary = false) {
  return actions.change(ACT_FORM_NAME, ASSETS, [
    {
      type: CARD_PICTURE_ASSET_TYPE,
      location: fileUrl,
      fileName,
      temporary
    }
  ]);
}

export function updateSavedAttachmentsInActForm(attachments) {
  return actions.change(ACT_FORM_NAME, ATTACHMENTS, attachments);
}

export const INITIATE_NAM_OCR = 'initiateNamOcr';
export const initiateNamOcr = (actAttachment) => ({
  type: INITIATE_NAM_OCR,
  actAttachment
});

export function updateNAMInActForm(nam) {
  if (selectPatientType()(getStore().getState()) !== 'NAM') return NOOP_ACTION;

  return actions.change(ACT_FORM_NAME, actFormFields.nam, nam);
}

export function updateExtractedNamsInActForm(extractedNams) {
  if (selectPatientType()(getStore().getState()) !== 'NAM') return NOOP_ACTION;

  return actions.change(ACT_FORM_NAME, actFormFields.extractedNams, extractedNams);
}

export function deleteSavedPicture() {
  return actions.change(ACT_FORM_NAME, ASSETS, null);
}

export function removePlaceFromForm() {
  return actions.change(ACT_FORM_NAME, actFormFields.place, null);
}

export const CLOSE_PICTURE_PREVIEW = 'closePicturePreview';
export const closePicturePreview = () => ({
  type: CLOSE_PICTURE_PREVIEW
});

export const TOGGLE_PICTURE_PREVIEW = 'togglePicturePreview';
export const togglePicturePreview = () => ({
  type: TOGGLE_PICTURE_PREVIEW
});

export const OPEN_NAM_SELECTOR_DIALOG = 'openNamSelectorDialog';
export const openNamSelectorDialog = (detectedNams) => ({
  type: OPEN_NAM_SELECTOR_DIALOG,
  detectedNams
});

export const CLOSE_NAM_SELECTOR_DIALOG = 'closeNamSelectorDialog';
export const closeNamSelectorDialog = () => ({
  type: CLOSE_NAM_SELECTOR_DIALOG
});

export const FETCH_UP_TO_DATE_PATIENT_CONTEXT_ACCORDING_TO_NAM = 'fetchUpToDatePatientContextAccordingToNam';
export const fetchUpToDatePatientContextAccordingToNam = (nam, uid) => ({
  type: FETCH_UP_TO_DATE_PATIENT_CONTEXT_ACCORDING_TO_NAM,
  nam,
  uid
});

export const updatePatientContext = (patientContext) => {
  if (patientContext) {
    return actions.change(ACT_FORM_NAME, 'patientContext', patientContext);
  }

  return actions.unregisterField(ACT_FORM_NAME, 'patientContext');
};
