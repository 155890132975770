import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { compose } from 'redux';
import TableCell from '@material-ui/core/TableCell';
import classNames from 'classnames';
import ChipList from '../../../../../components/List/ChipList/ChipList';
import { formatUnixToTimeValue } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import RamqExchangeSummary from '../../../../Act/PrintAct/PrintActComponent/RamqExchangeSummary';
import ExpandableTableRow from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRow';
import ExpandableTableRowHeader from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRowHeader';
import ExpandableTableRowContent from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRowContent';
import ActLinkButton from '../../../../../components/Activities/Act/ActLinkButton/ActLinkButton';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import { computeExpectedAmountForDirectPaymentActivity } from '../../../../../../shared/amountComputation/computeExpectedAmountUtil';

export const styles = (theme) => ({
  tableRow: {
    backgroundColor: '#f4f4f4'
  },
  codeChip: {
    margin: '3px'
  },
  noPadding: {
    padding: '0'
  },
  ramqExchange: {
    '-webkit-transform': 'translate3d(0,0,0)',
    '& ul, & li, & div': {
      margin: '0'
    }
  },
  alignLeft: {
    justifyContent: 'left'
  },
  timeColumn: {
    width: '150px'
  },
  namColumn: {
    width: '65px'
  },
  guard: {
    backgroundColor: theme.palette.yellow[5]
  }
});

export function ActDetailsRow({ act, classes, width, pool, billed }) {
  return (
    <ExpandableTableRow>
      <ExpandableTableRowHeader className={classNames({ [classes.tableRow]: true, [classes.guard]: act.guard })}>
        <TableCell size="small" className={classes.timeColumn}>
          {formatUnixToTimeValue(act.start)}
          {act.end && (
            <>
              &nbsp;-&nbsp;
              {formatUnixToTimeValue(act.end)}
            </>
          )}
          {act.remp && <>&nbsp; ({formatUnixToTimeValue(act.remp)})</>}
        </TableCell>
        <TableCell className={classes.namColumn}>{act.nam && act.nam.slice(0, 4)}</TableCell>
        <TableCell size="small">
          <ChipList
            list={act.codes}
            objectPropertyToBeDisplayed="code"
            dense={!isWidthUp('sm', width)}
            className={classes.alignLeft}
          />
        </TableCell>
        <TableCell align="right" size="small">
          {billed
            ? currencyFormat(computeExpectedAmountForDirectPaymentActivity(act))
            : !pool && <ActLinkButton act={act} />}
        </TableCell>
      </ExpandableTableRowHeader>
      <ExpandableTableRowContent canOpen={billed}>
        <TableCell colSpan="100" className={classes.noPadding}>
          <RamqExchangeSummary act={act} className={classes.ramqExchange} />
        </TableCell>
      </ExpandableTableRowContent>
    </ExpandableTableRow>
  );
}

ActDetailsRow.defaultProps = {
  billed: false
};

ActDetailsRow.propTypes = {
  act: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  pool: PropTypes.bool.isRequired,
  billed: PropTypes.bool
};

export default compose(withStyles(styles), withWidth())(ActDetailsRow);
