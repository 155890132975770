import measurementElements from '../../requiredDetails/MeasurementElements/measurementElements';

const measurementElementsAlreadyHandledByOptimizationsCodes = [
  '5917',
  '9403',
  '9404',
  '9405',
  '42187',
  '42188',
  '42189',
  '42230',
  '42249',
  '42250',
  '42295'
];

const hasMeasurementElement = (code) => code.measurementElements && code.measurementElements.length > 0;

const handledByOptimizations = (actCode) => measurementElementsAlreadyHandledByOptimizationsCodes.includes(actCode);

export const measurementElementsShouldBeHandled = (code) =>
  hasMeasurementElement(code) && !handledByOptimizations(code.code);

const addMeasurementElementsFieldIfNeeded = (currentCode, act) =>
  measurementElementsShouldBeHandled(currentCode) ? measurementElements.associatedFields(act) : [];

export default addMeasurementElementsFieldIfNeeded;
