import AuthenticationService from '../../../../shared/domain/authentication/service/AuthenticationService';
import FirebaseAuthenticationService from './FirebaseAuthenticationService';

class AuthenticationServiceProvider {
  private static service: AuthenticationService | null = null;

  static getInstance(): AuthenticationService {
    if (this.service === null) {
      this.service = new FirebaseAuthenticationService();
    }

    return this.service;
  }
}

export default AuthenticationServiceProvider;
