import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  ACTIVITIES_DATE_ROUTE,
  ACTIVITIES_ROUTE,
  ASSISTANT_ROUTE,
  DUPLICATE_ACT_ROUTE,
  EDIT_ACT_ROUTE,
  EDIT_TRAVEL_EXPENSES_ROUTE,
  LUMP_SUM_ROUTE,
  MIXTE_ROUTE,
  NEW_ACT_ROUTE,
  NEW_TRAVEL_EXPENSES_ROUTE,
  RAMQ_LOG_ROUTE,
  REPORTS_ROUTE,
  SEARCH_ROUTE,
  SETTINGS_ROUTE,
  TRAINING_ROUTE,
  USERS_ROUTE,
  USER_ROUTE
} from '../../containers/Navigation/constants';
import FilteredViewHeader from './FilteredViewHeader/FilteredViewHeader';
import MonthHeader from './MonthHeader/MonthHeader';
import SimpleHeader from './SimpleHeader/SimpleHeader';
import UserNameHeader from './UserNameHeader/UserNameHeader';

export const HEADER_CONTAINER_PORTAL_ID = 'header-container-portal';
function AppHeader() {
  return (
    <AppBar position="relative">
      <Routes>
        <Route path={SETTINGS_ROUTE} element={<SimpleHeader titleHeaderIntlId="settings" />} />

        <Route path={ACTIVITIES_DATE_ROUTE}>
          <Route path={NEW_ACT_ROUTE} element={<SimpleHeader titleHeaderIntlId="newAct" />} />
          <Route path={DUPLICATE_ACT_ROUTE} element={<SimpleHeader titleHeaderIntlId="newAct" />} />
          <Route path={EDIT_ACT_ROUTE} element={<SimpleHeader titleHeaderIntlId="editAct" />} />
          <Route path={MIXTE_ROUTE} element={<SimpleHeader titleHeaderIntlId="mixte" />} />
          <Route
            exact
            path={NEW_TRAVEL_EXPENSES_ROUTE}
            element={<SimpleHeader titleHeaderIntlId="newTravelExpenses" />}
          />
          <Route path={EDIT_TRAVEL_EXPENSES_ROUTE} element={<SimpleHeader titleHeaderIntlId="editTravelExpenses" />} />
          <Route path={LUMP_SUM_ROUTE} element={<SimpleHeader titleHeaderIntlId="lumpsum" />} />
          <Route path={TRAINING_ROUTE} element={<SimpleHeader titleHeaderIntlId="newTraining" />} />
          <Route index element={<MonthHeader />} />
        </Route>

        <Route path={ASSISTANT_ROUTE} element={<SimpleHeader titleHeaderIntlId="assistant" />} />

        <Route path={`${REPORTS_ROUTE}/*`} element={<SimpleHeader titleHeaderIntlId="reports" />} />

        <Route path={SEARCH_ROUTE}>
          <Route path={`${ACTIVITIES_ROUTE}/*`} element={<FilteredViewHeader />} />
        </Route>

        <Route path={USERS_ROUTE}>
          <Route path={`${USER_ROUTE}/*`} element={<UserNameHeader navigable />} />
          <Route index element={<SimpleHeader titleHeaderIntlId="users" />} />
        </Route>

        <Route path={RAMQ_LOG_ROUTE} element={<SimpleHeader titleHeaderIntlId="ramqLog" />} />
      </Routes>

      <div id={HEADER_CONTAINER_PORTAL_ID} />
    </AppBar>
  );
}

export default AppHeader;
