import PropTypes from 'prop-types';
import React from 'react';
import {
  ACTIVITY_PAYMENT_EXPLANATION_TYPE,
  ACT_RESPONSE_CANCEL_REQUEST_TYPE,
  ACT_RESPONSE_MODIFICATION_REQUEST_TYPE,
  ACT_RESPONSE_PAYMENT_REQUEST_TYPE,
  DEFERRED_PAYMENT_CONFIRMATION_TYPE,
  DEFERRED_PAYMENT_REQUEST_RESPONSE_TYPE
} from '../../../../../../../shared/ramq/constants';
import Collapser from '../../../../../Collapser/Collapser';
import CollapserContent from '../../../../../Collapser/CollapserContent';
import CollapserHeader from '../../../../../Collapser/CollapserHeader';
import ActivityResponseSummary from '../../../ActivityResponse/ActivityResponseSummary/ActivityResponseSummary';
import ActPaymentExplanationResponse from '../../../ActivityResponse/ActPaymentExplanationResponse/ActPaymentExplanationResponse';
import ActResponseCancelDetails from '../../../ActivityResponse/ActResponseCancelDetails/ActResponseCancelDetails';
import ActResponsePaymentDetails from '../../../ActivityResponse/ActResponsePaymentDetails/ActResponsePaymentDetails';
import DeferredPaymentConfirmationDetails from '../../../ActivityResponse/DeferredPaymentConfirmationDetails/DeferredPaymentConfirmationDetails';
import DeferredPaymentResponseDetails from '../../../ActivityResponse/DeferredPaymentResponseDetails/DeferredPaymentResponseDetails';
import RamqInfoIcon from '../../../RamqInfoIcon/RamqInfoIcon';

const responseTypeComponents = {
  [ACT_RESPONSE_PAYMENT_REQUEST_TYPE]: ActResponsePaymentDetails,
  [ACT_RESPONSE_MODIFICATION_REQUEST_TYPE]: ActResponsePaymentDetails,
  [ACT_RESPONSE_CANCEL_REQUEST_TYPE]: ActResponseCancelDetails,
  [DEFERRED_PAYMENT_REQUEST_RESPONSE_TYPE]: DeferredPaymentResponseDetails,
  [ACTIVITY_PAYMENT_EXPLANATION_TYPE]: ActPaymentExplanationResponse,
  [DEFERRED_PAYMENT_CONFIRMATION_TYPE]: DeferredPaymentConfirmationDetails
};

function ActivityResponseSuccess({ ramqResponse, initiallyOpen }) {
  const ResponseContentComponent = responseTypeComponents[ramqResponse.type];
  return (
    <Collapser initiallyOpen={initiallyOpen}>
      <CollapserHeader>
        <RamqInfoIcon ramqLogId={ramqResponse.ramqLogId} />
        <ActivityResponseSummary ramqResponse={ramqResponse} />
      </CollapserHeader>
      <CollapserContent>
        <ResponseContentComponent ramqResponse={ramqResponse} />
      </CollapserContent>
    </Collapser>
  );
}

ActivityResponseSuccess.propTypes = {
  ramqResponse: PropTypes.object.isRequired,
  initiallyOpen: PropTypes.bool.isRequired
};

export default ActivityResponseSuccess;
