import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import DialogTrigger from '../../../../../../../components/Dialogs/DialogTrigger/DialogTrigger';
import ChipList from '../../../../../../../components/List/ChipList/ChipList';
import SingleSelectDialog from '../../../../../../CodeSearchDialog/SingleSelectDialog/SingleSelectDialog';

const styles = () => ({
  chip: { minWidth: 92 }
});

function PeriodCodeSelector({ input, dialogComponent, dialogProps }) {
  return (
    <DialogTrigger
      DialogComponent={dialogComponent}
      dialogProps={dialogProps}
      onItemSelected={(code) => input.onChange(code.code)}
    >
      {input.value ? (
        <ChipList
          list={[{ code: input.value }]}
          onDelete={() => input.onChange(null)}
          objectPropertyToBeDisplayed="code"
          objectPropertyToUseAsKey="code"
        />
      ) : (
        <Button>Code</Button>
      )}
    </DialogTrigger>
  );
}

PeriodCodeSelector.propTypes = {
  input: PropTypes.object.isRequired,
  dialogComponent: PropTypes.object,
  dialogProps: PropTypes.object.isRequired
};

PeriodCodeSelector.defaultProps = {
  dialogComponent: SingleSelectDialog
};

export default withStyles(styles)(PeriodCodeSelector);
