/* eslint-disable no-restricted-syntax */
import { put, takeEvery, select } from 'redux-saga/effects';
import { captureError } from 'utils/sentry';
import { logDefault, logError } from '../../../shared/utils/plog';
import { selectLoginInfo } from './selectors';
import {
  loggedOutAction,
  loggedOutErrorAction,
  loginFailure,
  mfaRequired,
  LOGOUT_ACTION,
  TRY_LOGIN,
  MFA_LOGIN,
  updatePasswordFailure,
  updatePasswordSuccess
} from './actions';
import AuthenticationServiceProvider from '../../infrastructure/authentication/service/AuthenticationServiceProvider';

export function* logoutUser() {
  try {
    yield AuthenticationServiceProvider.getInstance().signOut();
    yield put(loggedOutAction());
  } catch (_e) {
    yield put(loggedOutErrorAction());
  }
}

export function* tryLogin({ username, password }) {
  try {
    const loginResult = yield AuthenticationServiceProvider.getInstance().signIn(username, password);

    // Test if we got a MFA challenge to resolve
    if (loginResult.resolver && loginResult.verificationId) {
      yield put(mfaRequired(loginResult));
    }
  } catch (error) {
    if (!['auth/invalid-email', 'auth/user-not-found', 'auth/wrong-password'].includes(error.code)) {
      captureError(error, { extra: { username } });
    }

    yield put(loginFailure(error));
  }
}

export function* mfaLogin({ token }) {
  const loginState = yield select(selectLoginInfo());
  if (!loginState.pendingMfa) return;

  try {
    yield AuthenticationServiceProvider.getInstance().mfaSignIn(token, loginState.pendingMfa);
  } catch (e) {
    yield put(loginFailure(e));
  }
}

export function* tryUpdatePassword({ username, password, newPassword }) {
  try {
    logDefault('Authentication/tryUpdatePassword: signing in');
    yield AuthenticationServiceProvider.getInstance().signIn(username, password);
    logDefault('Authentication/tryUpdatePassword: signin success');
  } catch (e) {
    logError(['Authentication/tryUpdatePassword: signin failed', e]);
    yield put(loginFailure(e));
    // Do not update password if authentication failed
    // yes this is client side "security" but Firebase has good enough
    // server side security on the updatePassword call
    return;
  }

  try {
    logDefault('Authentication/tryUpdatePassword: launching password update');
    yield AuthenticationServiceProvider.getInstance().updatePassword(username, newPassword);
    logDefault('Authentication/tryUpdatePassword: password update success');
    yield put(updatePasswordSuccess());
  } catch (e) {
    logError(['Authentication/tryUpdatePassword: password update failed', e]);
    yield put(updatePasswordFailure(e));
  }
}

export default function* authenticationSaga() {
  yield takeEvery(LOGOUT_ACTION, logoutUser);
  yield takeEvery(TRY_LOGIN, tryLogin);
  yield takeEvery(MFA_LOGIN, mfaLogin);
}
