import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import { NumberField, SelectorField, SingleLineField } from '../../../../components/Form/Fields';
import { billingAgencyRepository } from '../../../../../shared/infrastructure/user/profile';

const billingAgencyList = billingAgencyRepository.listAll().map((agency) => ({
  label: `${agency.getName()} (${agency.getNumber()})`,
  value: agency.getNumber()
}));

export interface Props {
  readonly: boolean;
}

export const Agencies: React.FunctionComponent<Props> = ({ readonly }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
      <Typography gutterBottom variant="h6">
        Ancienne agence
      </Typography>
    </Grid>

    <Grid item xs={12} sm={4}>
      <FormElement name="agencyName" label="Nom agence" disabled={readonly} component={SingleLineField} />
    </Grid>

    <Grid item xs={12} sm={4}>
      <FormElement name="agencyNumber" label="Numéro agence" disabled={readonly} component={NumberField} />
    </Grid>

    <Grid item xs={12} sm={4}>
      <FormElement
        name="agencySwitchDate"
        label="Date de changement d'agence"
        disabled={readonly}
        component={SingleLineField}
      />
    </Grid>

    <Grid item xs={12} sm={12} style={{ marginTop: '24px' }}>
      <Typography gutterBottom variant="h6">
        Nouvelle agence
      </Typography>
    </Grid>

    <Grid item xs={12} sm={6}>
      <FormElement
        name="billingAgency"
        label="Agence actuelle"
        disabled={readonly}
        component={SelectorField}
        list={billingAgencyList}
      />
    </Grid>
  </Grid>
);
