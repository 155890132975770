import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';

export const styles = (theme) => ({
  dateContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white,
    top: 0
  },
  text: {
    marginLeft: theme.spacing(1)
  }
});

export function SimpleDate({ act, classes }) {
  return (
    <div className={classes.dateContainer}>
      <Typography color="inherit" className={classes.text}>
        {formatUnixToDate(act.date)}
      </Typography>
    </div>
  );
}

SimpleDate.propTypes = {
  act: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleDate);
