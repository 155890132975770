import PropTypes from 'prop-types';
import React from 'react';

function CollapseHeader({ onClick, children }) {
  return (
    <div role="button" onClick={onClick} style={{ position: 'relative' }}>
      {children}
    </div>
  );
}

CollapseHeader.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
};

CollapseHeader.defaultProps = {
  onClick: undefined
};

export default CollapseHeader;
