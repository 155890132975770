import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import FullScreenLoading from '../../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import DuplicateAct from '../../../ActForm/DuplicateAct/DuplicateAct';
import ModifyAct from '../../../ActForm/ModifyAct/ModifyAct';
import NewAct from '../../../ActForm/NewAct/NewAct';
import { selectActivitiesLoading } from '../../../Activities/selectors';
import { ACTIVITY_ID_ROUTE_PARAM } from '../../../Navigation/constants';

export function ActRoute({ loading }) {
  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <Routes>
      <Route path="new" element={<NewAct />} />
      <Route path="new/duplicate" element={<DuplicateAct />} />
      <Route path={ACTIVITY_ID_ROUTE_PARAM} element={<ModifyAct />} />
    </Routes>
  );
}

ActRoute.propTypes = {
  loading: PropTypes.bool.isRequired
};

export const mapStateToProps = createStructuredSelector({
  loading: selectActivitiesLoading()
});

export default compose(connect(mapStateToProps, null))(ActRoute);
