import Activity from '../../domain/activity/global/model/Activity';
import ActivityType from '../../core/activity/domain/ActivityType';
import LumpsumAmountService from './lumpsum/LumpsumAmountService';
import LumpSum from '../../domain/activity/lumpSum/model/LumpSum';
import MixteAmountService from './mixte/MixteAmountService';
import { Mixte } from '../../domain/activity/mixte/model/Mixte';
import PeriodAmountService from './PeriodAmountService';
import TrainingAmountService from './training/TrainingAmountService';
import { Training } from '../../domain/activity/training/model/Training';

class PeriodAmountServiceFactory {
  static create(activity: Activity): PeriodAmountService {
    switch (activity.type) {
      case ActivityType.LUMP_SUM:
        return new LumpsumAmountService(activity as LumpSum);
      case ActivityType.MIXTE:
        return new MixteAmountService(activity as Mixte);
      case ActivityType.TRAINING:
        return new TrainingAmountService(activity as Training);
      default:
        throw new Error(`Not Implemented for type ${activity.type}`);
    }
  }
}

export default PeriodAmountServiceFactory;
