import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import COST_OF_STAY_FEE_TYPES from '../../../../shared/ramq/domainValues/costOfStayFeeTypes';
import TravelInformationBoxSelectValue from './TravelInformationBoxSelectValue';
import TravelInformationBoxSingleValue from './TravelInformationBoxSingleValue';

function CostOfStayFeeSummary({ fee }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography align="center" variant="button" style={{ marginBottom: 8 }}>
        Séjour
      </Typography>
      <Grid container>
        <TravelInformationBoxSelectValue label="Type de séjour" map={COST_OF_STAY_FEE_TYPES} value={fee.stayType} />
        <TravelInformationBoxSingleValue label="Montant" value={fee.amount} />
      </Grid>
    </Grid>
  );
}

CostOfStayFeeSummary.propTypes = {
  fee: PropTypes.object.isRequired
};

export default CostOfStayFeeSummary;
