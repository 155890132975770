import { find } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import { getActivityById, getActivityByIdSuccess } from './actions';
import { selectSearchingActivityError, selectSearchingSpecificActivity, selectSelectedActivity } from './selectors';

export const SafeActivityByIdFetcher = ({
  activities,
  render,
  activity,
  loading,
  error,
  getActivityById,
  getActivityByIdSuccess
}) => {
  const { activityId } = useParams();
  const [activityIsInProvidedList, setActivityIsInProvidedList] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    let activityIsInProvidedList = true;
    const relatedActivity = find(activities, (activity) => activity.id === activityId);
    if (!relatedActivity) {
      getActivityById(activityId);
      activityIsInProvidedList = false;
    } else {
      getActivityByIdSuccess(relatedActivity);
    }
    setActivityIsInProvidedList(activityIsInProvidedList);
    setIsInitialized(true);
  }, [activities, activityId, getActivityById, getActivityByIdSuccess]);

  if (!isInitialized) return;
  return render({ loading, activity, error, activityIsInProvidedList });
};

SafeActivityByIdFetcher.propTypes = {
  activities: PropTypes.array.isRequired,
  activity: PropTypes.object,
  error: PropTypes.string,
  getActivityById: PropTypes.func.isRequired,
  getActivityByIdSuccess: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired
};

SafeActivityByIdFetcher.defaultProps = {
  error: undefined,
  activity: undefined
};

export const mapStateToProps = createStructuredSelector({
  loading: selectSearchingSpecificActivity(),
  activity: selectSelectedActivity(),
  error: selectSearchingActivityError()
});

const mapDispatchToProps = {
  getActivityById,
  getActivityByIdSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(SafeActivityByIdFetcher);
