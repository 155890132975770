import { call, put } from 'redux-saga/effects';
import { Action } from 'redux';
import { SPECIALTY_CONTEXT_ELEMENTS_PRESET_LIST_NAME } from '../../../../userCustomization/featureNames';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { specialtyContextElementRepository } from '../infrastructure';
import ContextElement from '../domain/ContextElement';

const FALLBACK_PRESET_LIST_NAME = 'default';

export default function* fetchSpecialtyContextElements(
  /* eslint-disable no-unused-vars */
  successAction: (contextElements: ContextElement[]) => Action,
  failureAction: (error: any) => Action
  /* eslint-enable */
) {
  try {
    const listName = getFeatureConfig(SPECIALTY_CONTEXT_ELEMENTS_PRESET_LIST_NAME, FALLBACK_PRESET_LIST_NAME) as string;
    const contextElements = yield call(specialtyContextElementRepository.listPresetCategoriesIn, listName);

    yield put(successAction(contextElements));
  } catch (e) {
    yield put(failureAction(e));
  }
}
