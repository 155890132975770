import Act from 'shared/domain/activity/act/model/Act';
import moment from 'moment-timezone';
import { GREATER_THAN_4_HOURS } from 'shared/ramq/contextElements/globalContextElements';

const NO_ERRORS = {};

const durationGreaterThan4HoursContextElementValidation = ({ start, end, contextElements = [] }: Act) => {
  if (!end) {
    return NO_ERRORS;
  }
  if (!contextElements.includes(GREATER_THAN_4_HOURS)) {
    return NO_ERRORS;
  }
  if (moment(end).diff(moment(start), 'hours') >= 4) {
    return NO_ERRORS;
  }

  return {
    contextElements:
      'La durée indiquée est inférieure à 4h. Svp modifier pour le >4h REPL ou >8h REPL si vous avez été remplacé ou retiré.'
  };
};

export default durationGreaterThan4HoursContextElementValidation;
