/* eslint-disable */
import upvoty from 'application/vendors/upvoty/upvoty';
import React, { useEffect } from 'react';

interface UpvotyWidgetProps {
  baseUrl: string;
  boardHash: string;
  ssoToken: string;
}

const UpvotyWidget = ({ baseUrl, boardHash, ssoToken }: UpvotyWidgetProps) => {
  useEffect(() => {
    upvoty.init('render', {
      baseUrl,
      boardHash,
      ssoToken
    });
    return () => {
      upvoty.destroy();
    };
  }, [baseUrl, boardHash, ssoToken]);

  return <div className="w-full" data-upvoty />;
};

export default UpvotyWidget;
