import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import withStyles, { StyleRulesCallback, WithStyles } from '@material-ui/core/styles/withStyles';
import { TableHead, TableRow, Theme } from '@material-ui/core';
import groupByUserId from './utils';
import ExpandableTableRow from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRow';
import ExpandableTableRowHeader from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRowHeader';
import ExpandableTableRowContent from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRowContent';
import ActsDayDetailTable from '../ActsDayDetailTable/ActsDayDetailTable';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';

export const styles: StyleRulesCallback<Theme, Props> = () => ({
  noPadding: {
    '&:last-child': {
      padding: '0'
    }
  },
  noWrap: {
    whiteSpace: 'nowrap'
  },
  userRowHeader: {
    backgroundColor: '#d7d7d7'
  },
  insetShadow: {
    boxShadow: 'inset 0 0 4px 0 #d7d7d7',
    padding: '2px'
  }
});

export interface Props extends WithStyles {
  acts: any;
  tableCellClass: any;
}

export const PoolActsDayDetailTable: React.FunctionComponent<Props> = ({ acts, tableCellClass, classes }) => {
  const actByDaysGroupedByDoctor = groupByUserId(acts);

  return (
    <div className={classes!.insetShadow}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.denseTableCell }}>Nom</TableCell>
            <TableCell classes={{ root: classes.denseTableCell }} align="right">
              Non fact.
            </TableCell>
            <TableCell classes={{ root: classes.denseTableCell }} align="right">
              Fact.
            </TableCell>
            <TableCell classes={{ root: classes.denseTableCell }} align="right">
              Montant préliminaire
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(actByDaysGroupedByDoctor).map((key) => {
            const { actsDay, doctor } = actByDaysGroupedByDoctor[key];

            return (
              <ExpandableTableRow key={key} data-testid={key}>
                <ExpandableTableRowHeader>
                  <TableCell classes={{ root: tableCellClass }} className={classes!.userRowHeader} size="small">
                    {`${doctor.firstName} ${doctor.lastName}`}
                  </TableCell>
                  <TableCell
                    classes={{ root: tableCellClass }}
                    className={classes!.userRowHeader}
                    align="right"
                    size="small"
                  >
                    {actsDay.notBilledActivities.length}
                  </TableCell>
                  <TableCell
                    classes={{ root: tableCellClass }}
                    className={classes!.userRowHeader}
                    align="right"
                    size="small"
                  >
                    {actsDay.billedActivities.length}
                  </TableCell>
                  <TableCell
                    classes={{ root: tableCellClass }}
                    className={classes!.userRowHeader}
                    align="right"
                    size="small"
                  >
                    {currencyFormat(actsDay.totalAmount)}
                  </TableCell>
                </ExpandableTableRowHeader>
                <ExpandableTableRowContent canOpen={actsDay.billedCount + actsDay.notBilledCount > 0}>
                  <TableCell classes={{ root: classes!.noPadding }} colSpan={100}>
                    <ActsDayDetailTable acts={actsDay} pool />
                  </TableCell>
                </ExpandableTableRowContent>
              </ExpandableTableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(PoolActsDayDetailTable);
