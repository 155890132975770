import flatten from 'lodash/flatten';
import { createSelector } from '@reduxjs/toolkit';

const selectAssistant = (state) => state.assistant;

export const selectAdvices = () => createSelector(selectAssistant, (state) => state.advices);

export const selectUnreadMessagesCount = () => createSelector(selectAssistant, (state) => state.unreadMessagesCount);

export const selectActivities = () =>
  createSelector(selectAdvices(), (advices) => flatten(advices.map((advice) => advice.activities)));

export const selectLoading = () => createSelector(selectAssistant, (state) => state.loading);
