/* eslint-disable react/prop-types */
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React, { useEffect, useMemo } from 'react';
import useTranslate from 'application/hooks/use-translate';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { DefaultBillingType } from 'application/hooks/use-firestore-user-profile';
import ActivityAreaSelector from 'app/activityArea/component/ActivityAreaSelector/ActivityAreaSelector';
import ActivityAreaChoiceContextFactory from 'app/activityArea/domain/ActivityAreaChoiceContextFactory';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { getCabinetVisitCodeOptions, getVisitCodeOptions } from 'shared/act/services/VisitCodes';
import { VisitCodeTrigger } from 'app/components/Form/VisitCode/VisitCodeTrigger';
import BillingType from '../../../../../../shared/domain/billing/model/BillingType';
import UserBillingType from '../../../../../../shared/domain/user/UserBillingType';
import HourMinuteInput from '../../../../../components/Form/custom/HourMinuteInput/HourMinuteInput';
import BillingTypeField from '../../../../../components/Form/BillingTypeField/BillingTypeField';
import styles from './styles';
import { scrollToInvalidField } from '../../../../../validations/common/reduxFormValidation';
import Camera from '../../../../../containers/ActForm/Camera/Camera';
import {
  closeMultiActPicturePreview,
  deleteSavedMultiNamPicture,
  multiActFileReadyForUpload,
  toggleMultiActPicturePreview
} from '../../../adapters/fileUpload/actions';
import {
  selectMultiActDate,
  selectMultiActPicture,
  selectMultiActPicturePreviewDisplayed
} from '../../../adapters/fileUpload/selectors';
import { MULTI_ACT_FILE_TYPE } from '../../../adapters/fileUpload/constants';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import messages from '../../../../../containers/ActForm/messages';
import TimePickerSyncedWithDate from '../../../../../components/Form/TimePickerSyncedWithDate/TimePickerSyncedWithDate';
import ActCodeSelector from '../../../../../containers/ActForm/ActCodeSelector/ActCodeSelector';
import PatientFields from '../../../../../components/Form/PatientFields/PatientFields';
import validateForm from './validate';
import { isCreatingActs, isErrorWhileCreatingActs, selectExtractedNams } from '../../../adapters/nams/selectors';
import LoadingButton from '../../../../../components/button/LoadingButton/LoadingButton';
import { getMomentDateFromUnix } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import MULTI_ACT_FORM_NAME from './constants';
import PicturePreview from '../../../../../containers/ActForm/PicturePreview/PicturePreview';
import {
  clearMultiActFormActivityArea,
  clearMultiActFormPatientsVisitCode
} from '../../../../../containers/ReduxForm/actions';
import { selectUserProfileInContext, selectDefaultPlace } from '../../../../../containers/User/selectors';
import { PlaceSelectField } from '../../../../../components/Form/Fields';
import { selectActivitiesInContext } from '../../../../../containers/Activities/selectors';
import {
  getActsInContextInTheSameDay,
  getLastBillingTypeUsedInContext,
  getLastPlaceUsedInContext
} from '../../../../../codeSpecificLogic/ui/codeSpecificUILogicUtilities';
import {
  selectMultiActActivityArea,
  selectMultiActBillingType,
  selectMultiActVisitCode,
  selectMultiActCodes,
  selectMultiActPlace
} from '../../../adapters/selectors';

const getDefaultActBillingType = (userBillingType, lastBillingType, defaultBillingType) => {
  if (defaultBillingType && defaultBillingType !== DefaultBillingType.AUTO) {
    return defaultBillingType;
  }

  if (lastBillingType) {
    return lastBillingType;
  }
  return userBillingType === BillingType.POOL ? BillingType.POOL : BillingType.PRIV;
};

const MultiActForm = ({
  initialize,
  placePreferences,
  userProfile,
  placeByDefault,
  activitiesInContext,
  namPicture,
  onFileReadyForUpload,
  handleSubmit,
  creatingActs,
  onSave,
  onSaveAndCreateMore,
  onCancel,
  picturePreviewOpen,
  onClosePicturePreview,
  onTogglePicturePreview,
  onDeletePicturePreview,
  extractedNams,
  billingType,
  change,
  classes,
  activityArea,
  visitCode,
  codes,
  place
}) => {
  const translate = useTranslate();
  const { date } = useParams();
  const dispatch = useDispatch();

  const defaultPoolNumber = (userProfile?.pools ?? [])[0]?.number ?? null;

  useEffect(() => {
    const isOnline = navigator.onLine;
    const currentDateInContext = getMomentDateFromUnix(date).valueOf();
    const activitiesOnSameDay = getActsInContextInTheSameDay(activitiesInContext, currentDateInContext);
    const lastPlace = getLastPlaceUsedInContext(activitiesOnSameDay, placePreferences);
    const { defaultBillingType } = userProfile;
    const lastBillingType = getLastBillingTypeUsedInContext(activitiesOnSameDay);
    const billingType = getDefaultActBillingType(userProfile.billingType, lastBillingType, defaultBillingType);

    initialize({
      namPicture: undefined,
      date: currentDateInContext,
      start: moment(currentDateInContext).hours(8, 'hours').valueOf(),
      codes: [],
      patients: [{}],
      billingType,
      poolNumber: billingType === BillingType.PRIV ? null : (defaultPoolNumber ?? null),
      interval: undefined,
      place: lastPlace || placeByDefault,
      onlineCreation: isOnline
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activityAreas = useMemo(
    () => ActivityAreaChoiceContextFactory.create(ActivityType.ACT, userProfile.specialty),
    [userProfile.specialty]
  );

  const visitCodeOptions = useMemo(() => {
    if (!place) return [];

    if (place.isCabinet && !place.isCMS) {
      return getCabinetVisitCodeOptions(userProfile.specialty) || [];
    }
    return getVisitCodeOptions(userProfile.specialty, activityArea) || [];
  }, [userProfile.specialty, activityArea, place]);

  // If the visit codes change
  // we need to clear the visit codes of the patients since they may be invalid
  useEffect(() => {
    dispatch(clearMultiActFormPatientsVisitCode());
  }, [dispatch, visitCodeOptions]);

  useEffect(() => {
    if (!place) return;

    if (place.isCabinet && !place.isCMS) {
      dispatch(clearMultiActFormActivityArea());
    }
  }, [dispatch, place]);

  // Prevent rendering the component before `initialize` has been called
  if (!billingType) return;

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        {userProfile.billingType === UserBillingType.POOL && (
          <div className={classes.billingTypeSelector}>
            <BillingTypeField
              billingTypeFieldName="billingType"
              poolNumberFieldName="poolNumber"
              currentBillingType={billingType}
              user={userProfile}
              onChange={(newBillingType) => {
                if (newBillingType === BillingType.PRIV) {
                  change('poolNumber', null);
                } else {
                  change('poolNumber', defaultPoolNumber);
                }
              }}
            />
          </div>
        )}
        <Grid container>
          <Grid item xs={12}>
            <FormElement
              name="place"
              label={<FormattedMessage id="act-form.location" />}
              component={PlaceSelectField}
            />
          </Grid>

          <Grid item xs={12}>
            <ActivityAreaSelector
              formField="activityArea"
              collapsable={false}
              activityAreas={activityAreas}
              disabled={place?.isCabinet && !place.isCMS}
            />
          </Grid>

          <Grid item xs={4} sm={2} className={classes.alignCenter}>
            <Field
              name="namPicture"
              component={Camera}
              openOnMount
              savedPicture={namPicture}
              togglePicturePreview={onTogglePicturePreview}
              onFileReadyForUpload={(attachmentType, fileEntry, persistFileLocallyBeforeUpload) =>
                onFileReadyForUpload(MULTI_ACT_FILE_TYPE, fileEntry, persistFileLocallyBeforeUpload)
              }
              fileType={MULTI_ACT_FILE_TYPE}
            />
          </Grid>

          <Grid item xs={8} sm={10}>
            <div className={classes.buttonRow}>
              <FormElement
                className={classes.startInput}
                name="start"
                label={<FormattedMessage {...messages.start} />}
                component={TimePickerSyncedWithDate}
                dateSelector={selectMultiActDate}
              />

              <Field
                data-testid="interval-input"
                className={classes.intervalInput}
                name="interval"
                component={HourMinuteInput}
                minutesOnly
                minMinutes={5}
                maxMinutes={60}
                minutesPrecision={5}
                label={<FormattedMessage {...messages.interval} />}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <PicturePreview
              savedPicture={namPicture}
              closePicturePreview={onClosePicturePreview}
              deleteSavedPicture={onDeletePicturePreview}
              open={picturePreviewOpen}
              actId={MULTI_ACT_FORM_NAME}
              extractedNams={extractedNams}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center">
              {visitCodeOptions.length > 0 && (
                <Grid item xs={4}>
                  <Field
                    name="visitCode"
                    component={VisitCodeTrigger}
                    label={translate('global.visit')}
                    props={{
                      disabled: (codes ?? []).length > 0,
                      visitCodeOptions
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={8}>
                <FieldArray
                  disabled={!!visitCode}
                  name="codes"
                  label={<FormattedMessage {...messages.codes} />}
                  component={ActCodeSelector}
                />
              </Grid>
            </Grid>

            <div className={classes.patientManager}>
              <FieldArray
                name="patients"
                label="Patients"
                component={PatientFields}
                props={{
                  visitCodeOptions
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <Paper elevation={3} className={classes.footer}>
        <Button id="cancel-button" onClick={onCancel}>
          {translate('global.cancel')}
        </Button>
        <LoadingButton
          variant="outlined"
          color="primary"
          label={<FormattedMessage {...messages.createMore} />}
          loading={creatingActs}
          onClick={handleSubmit(validateForm(onSaveAndCreateMore))}
        />
        <LoadingButton
          id="send-act-button"
          variant="contained"
          color="primary"
          label={translate('multi-act-form.create-acts')}
          loading={creatingActs}
          onClick={handleSubmit(validateForm(onSave))}
        />
      </Paper>
    </div>
  );
};

MultiActForm.defaultProps = {
  namPicture: undefined,
  picturePreviewOpen: false,
  onClosePicturePreview: undefined,
  onTogglePicturePreview: undefined,
  onDeletePicturePreview: undefined,
  placeByDefault: undefined,
  extractedNams: [],
  billingType: undefined
};

MultiActForm.propTypes = {
  namPicture: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  creatingActs: PropTypes.bool.isRequired,
  userProfile: PropTypes.object.isRequired,
  errorWhileCreatingActs: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAndCreateMore: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFileReadyForUpload: PropTypes.func.isRequired,
  picturePreviewOpen: PropTypes.bool,
  onClosePicturePreview: PropTypes.func,
  onTogglePicturePreview: PropTypes.func,
  onDeletePicturePreview: PropTypes.func,
  placeByDefault: PropTypes.object,
  activitiesInContext: PropTypes.array,
  isOnline: PropTypes.bool.isRequired,
  extractedNams: PropTypes.array
};

export const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfileInContext(),
  namPicture: selectMultiActPicture(),
  creatingActs: isCreatingActs(),
  errorWhileCreatingActs: isErrorWhileCreatingActs(),
  picturePreviewOpen: selectMultiActPicturePreviewDisplayed(),
  placeByDefault: selectDefaultPlace(),
  activitiesInContext: selectActivitiesInContext(),
  extractedNams: selectExtractedNams(),
  billingType: selectMultiActBillingType(),
  activityArea: selectMultiActActivityArea(),
  visitCode: selectMultiActVisitCode(),
  codes: selectMultiActCodes(),
  place: selectMultiActPlace()
});

export const mapDispatchToProps = {
  onFileReadyForUpload: multiActFileReadyForUpload,
  onClosePicturePreview: closeMultiActPicturePreview,
  onTogglePicturePreview: toggleMultiActPicturePreview,
  onDeletePicturePreview: deleteSavedMultiNamPicture
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: MULTI_ACT_FORM_NAME,
    onSubmitFail: scrollToInvalidField
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(MultiActForm);
