import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { createStructuredSelector } from 'reselect';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MomentUtils from '@date-io/moment';
import { useParams } from 'react-router-dom';
import { saveAct } from '../../../containers/Act/actions';
import { getMomentDateFromUnix } from '../../../../shared/utils/dateTime/dateTimeUtils';
import DuplicateActsUseCase from './usecases/DuplicateActsUseCase';
import { selectUserIdInContext } from '../../../containers/User/selectors';
import { usePostHog } from 'posthog-js/react';

export const styles = (theme) => ({
  tooltip: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: `${theme.typography.round(14 / 10)}em`
  }
});

export function DuplicateActsOfAnotherDayButton({ userId, saveDuplicatedAct, classes, open, closeActivitiesOptions }) {
  const { date } = useParams();
  const posthog = usePostHog();
  const dateToDuplicate = getMomentDateFromUnix(date);
  const duplicateActsUseCase = new DuplicateActsUseCase(userId);

  const onDuplicate = (date) => {
    duplicateActsUseCase.duplicate({ fromDate: date, toDate: dateToDuplicate }).then((duplicatedActs) => {
      duplicatedActs.forEach(saveDuplicatedAct);
    });
  };

  const handleClick = (onClick) => {
    posthog.capture('FAB clicked', { type: 'duplicate' });
    onClick();
    closeActivitiesOptions();
  };

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <DatePicker
        TextFieldComponent={({ onClick }) => (
          <SpeedDialAction
            id="create-duplicata-button"
            icon={<FileCopyIcon />}
            tooltipTitle="Duplicata"
            TooltipClasses={{ tooltip: classes.tooltip }}
            tooltipOpen
            open={open}
            onClick={(event) => handleClick(() => onClick(event))}
          />
        )}
        leftArrowIcon={<PrevArrowIcon />}
        rightArrowIcon={<NextArrowIcon />}
        value={moment(dateToDuplicate).subtract(1, 'days')}
        onChange={onDuplicate}
      />
    </MuiPickersUtilsProvider>
  );
}

DuplicateActsOfAnotherDayButton.propTypes = {
  userId: PropTypes.string.isRequired,
  saveDuplicatedAct: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeActivitiesOptions: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  userId: selectUserIdInContext()
});

export const mapDispatchToProps = (dispatch) => ({
  saveDuplicatedAct: (act) => dispatch(saveAct(act))
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DuplicateActsOfAnotherDayButton);
