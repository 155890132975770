import { UPDATE_PERIOD_FAILURE } from '../PeriodsBilling/actions';
import { SAVE_MIXTE, SAVE_MIXTE_FAILURE } from './actions';

export const initialState = {
  error: undefined
};

function mixtesReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_MIXTE:
      return {
        ...state,
        error: undefined
      };
    case SAVE_MIXTE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_PERIOD_FAILURE:
      return state;
    default:
      return state;
  }
}

export default mixtesReducer;
