import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import useTranslate from 'application/hooks/use-translate';
import Input from 'application/components/form/input';
import OptionsMenu from 'application/components/options-menu';
import { GROUP_USERS, GROUP_REPORTS } from 'application/routes';
import Icon from 'application/components/icon';
import useToastApolloError from 'application/hooks/use-toast-apollo-error';
import PageContainer from 'application/components/page-container';
import DataTable from 'application/components/data-table';
import classnames from 'classnames';
import { isUserAtLeast } from 'application/utilities/authentication';
import Role from 'shared/domain/authentication/Role';
import { GroupType } from 'application/types/groups';
import { RootState } from 'application/types/redux-state';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

interface Group {
  id: string;
  membersCount: number;
  name: string;
  number: string;
  type: string;
  owners: Array<{
    id: string;
    fullName: string;
  }>;
}

interface FetchGroups {
  groups: Group[];
}

const GROUPS_QUERY = gql`
  query FetchGroups {
    groups {
      id
      membersCount: usersCount(groupRole: "member")
      name
      number
      type
      owners {
        id
        fullName
      }
    }
  }
`;

interface CreateGroup {
  createGroup: {
    id: string;
  };
}

const CREATE_MUTATION = gql`
  mutation CreateGroup($data: GroupInput!) {
    createGroup(data: $data) {
      id
    }
  }
`;

export type NewGroup = Omit<Group, 'id' | 'type' | 'owners' | 'membersCount'>;

const BLANK_GROUP = { name: '', number: '' };

const GroupsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const translate = useTranslate('pages.groups');
  const translateGroupType = useTranslate('group-type');
  const toastApolloError = useToastApolloError();
  const userRole = useSelector<RootState, Role>((state) => state.authentication.user.role);

  const [groupType, setGroupType] = useState<GroupType>(GroupType.POOL);
  const [newGroup, setNewGroup] = useState<NewGroup | null>(null);

  const { data } = useQuery<FetchGroups>(GROUPS_QUERY);
  const [createGroup] = useMutation<CreateGroup>(CREATE_MUTATION);

  const handleNewGroupSubmit = () => {
    const group = {
      ...newGroup,
      type: groupType,
      number: groupType === GroupType.MANAGEMENT ? undefined : newGroup!.number
    };

    createGroup({ variables: { data: group } })
      .then((response) => {
        const newGroupId = response.data!.createGroup.id;
        navigate({ pathname: `${newGroupId}/${GROUP_USERS}`, search: location.search });
      })
      .catch(toastApolloError);
  };

  return (
    <PageContainer>
      <div className="tabs tabs-bordered mb-8 w-fit">
        <button
          type="button"
          className={classnames('tab', { 'tab-active': groupType === GroupType.MANAGEMENT })}
          onClick={() => setGroupType(GroupType.MANAGEMENT)}
        >
          {translateGroupType(GroupType.MANAGEMENT)}
          <div className="badge badge-ghost ml-2">
            {data?.groups.filter((group) => group.type === GroupType.MANAGEMENT).length || 0}
          </div>
        </button>
        <button
          type="button"
          className={classnames('tab', { 'tab-active': groupType === GroupType.POOL })}
          onClick={() => setGroupType(GroupType.POOL)}
        >
          {translateGroupType(GroupType.POOL)}
          <div className="badge badge-ghost ml-2">
            {data?.groups.filter((group) => group.type === GroupType.POOL).length || 0}
          </div>
        </button>
      </div>

      <DataTable
        title={translateGroupType(groupType)}
        count={data?.groups.filter((group) => group.type === groupType).length || 0}
        headerButton={
          isUserAtLeast(userRole, Role.Admin)
            ? {
                label: `${translate('new')} ${translateGroupType(groupType)}`,
                onClick: () => setNewGroup(BLANK_GROUP),
                hide: !!newGroup,
                onCancel: () => setNewGroup(null),
                cancelLabel: translate('cancel')
              }
            : undefined
        }
        columns={[
          { title: translate('attributes.name'), width: '*' },
          { title: translate('attributes.members'), width: '*' },
          { title: translate('attributes.owner'), width: '*' },
          ...(groupType === GroupType.POOL ? [{ title: translate('attributes.number'), width: '180px' }] : []),
          { title: '', width: '150px' }
        ]}
      >
        {newGroup && (
          <tr>
            <td>
              <Input
                placeholder={translate('attributes.name')}
                value={newGroup.name}
                onChange={(name: string) => setNewGroup({ ...newGroup, name })}
              />
            </td>
            <td />
            <td />
            {groupType === GroupType.POOL && (
              <td>
                <Input
                  placeholder={translate('attributes.number')}
                  value={newGroup.number}
                  onChange={(number: string) => setNewGroup({ ...newGroup, number })}
                />
              </td>
            )}
            <td className="flex justify-end gap-2">
              <button className="btn btn-square btn-primary" type="button" onClick={handleNewGroupSubmit}>
                <Icon icon="check" />
              </button>
              <button className="btn btn-square btn-outline" type="button" onClick={() => setNewGroup(null)}>
                <Icon icon="times" />
              </button>
            </td>
          </tr>
        )}
        {data?.groups
          .filter((group) => group.type === groupType)
          .map((group: Group) => (
            <tr key={group.id}>
              <td className="link-hover link" onClick={() => navigate({ pathname: group.id, search: location.search })}>
                {group.name}
              </td>
              <td>
                <div className="badge badge-ghost ml-1">{group.membersCount}</div>
              </td>
              <td>{group.owners.map((owner) => owner.fullName).join(', ')}</td>
              {groupType === GroupType.POOL && <td>{group.number}</td>}
              <td className="text-right">
                <OptionsMenu>
                  <OptionsMenu.Item label={translate('open')} route={group.id} />
                  <OptionsMenu.Item label={translate('users')} route={`${group.id}/${GROUP_USERS}`} />
                  {group.type === GroupType.POOL && (
                    <OptionsMenu.Item label={translate('reports')} route={`${group.id}/${GROUP_REPORTS}}`} />
                  )}
                </OptionsMenu>
              </td>
            </tr>
          ))}
      </DataTable>
    </PageContainer>
  );
};

export default GroupsPage;
