export const INITIALIZE_ASSISTANT = 'initializeAssistant';
export const INITIALIZE_ASSISTANT_FAILURE = 'initializeAssistantFailure';
export const REMOVE_ADVICE = 'removeAdvice';
export const UPDATE_ADVICE = 'updateAdvice';
export const UPDATE_UNREAD_MESSAGES_COUNT = 'updateUnreadMessagesCount';

export function initializeAssistant() {
  return {
    type: INITIALIZE_ASSISTANT
  };
}

export function initializeAssistantFailure(error) {
  return {
    type: INITIALIZE_ASSISTANT_FAILURE,
    error
  };
}

export function removeAdvice(id) {
  return {
    type: REMOVE_ADVICE,
    id
  };
}

export function updateAdvice(advice) {
  return {
    type: UPDATE_ADVICE,
    advice
  };
}

export function updateUnreadMessagesCount(unreadMessagesCount) {
  return {
    type: UPDATE_UNREAD_MESSAGES_COUNT,
    unreadMessagesCount
  };
}
