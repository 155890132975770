import { ADD_ASYNC_TASK, ASYNC_CALL_DELETE, ASYNC_CALL_FAILURE, ASYNC_CALL_SUCCESS } from './actions';

export const initialState = {
  asyncCalls: []
};

const updateCallInList = (asyncCallId, newObjectState, state) => {
  const { asyncCalls } = state;
  const objectToUpdateIndex = asyncCalls.findIndex((call) => call.id === asyncCallId);
  return {
    ...state,
    asyncCalls: [
      ...asyncCalls.slice(0, objectToUpdateIndex),
      newObjectState,
      ...asyncCalls.slice(objectToUpdateIndex + 1)
    ]
  };
};

const deleteCallInList = (asyncCallId, state) => {
  const { asyncCalls } = state;
  const asyncCallToDeleteIndex = asyncCalls.findIndex((call) => call.id === asyncCallId);
  return {
    ...state,
    asyncCalls: [...asyncCalls.slice(0, asyncCallToDeleteIndex), ...asyncCalls.slice(asyncCallToDeleteIndex + 1)]
  };
};

const addAsyncTask = (asyncCallId, state, additionalInformation) => {
  const { asyncCalls } = state;
  const asyncCallAlreadySaved = asyncCalls.find((call) => call.id === asyncCallId);
  if (asyncCallAlreadySaved) {
    return updateCallInList(asyncCallId, { id: asyncCallId, loading: true }, state);
  }
  return {
    ...state,
    asyncCalls: [
      ...asyncCalls,
      {
        id: asyncCallId,
        loading: true,
        error: undefined,
        additionalInformation
      }
    ]
  };
};

function asyncTasksReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ASYNC_TASK: {
      const { asyncCallId } = action;
      return addAsyncTask(asyncCallId, state);
    }
    case ASYNC_CALL_SUCCESS: {
      const { asyncCallId } = action;
      return deleteCallInList(asyncCallId, state);
    }
    case ASYNC_CALL_FAILURE: {
      const { asyncCallId, error } = action;
      return updateCallInList(asyncCallId, { id: asyncCallId, loading: false, error }, state);
    }
    case ASYNC_CALL_DELETE: {
      const { asyncCallId } = action;
      return deleteCallInList(asyncCallId, state);
    }
    default:
      return state;
  }
}

export default asyncTasksReducer;
