import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import useTranslate from 'application/hooks/use-translate';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import Status from '../../../components/Status/Status';
import BillingType from '../../../components/Activities/BillingType';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    width: '100%',
    backgroundColor: theme.palette.primary[500],
    alignItems: 'flex-start',
    position: 'relative',
    justifyContent: 'space-between'
  },
  actInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  statusContainer: {
    paddingTop: '8px',
    paddingRight: '16px',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%'
  },
  status: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  activityBillingType: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  activityType: {
    position: 'absolute',
    right: '0',
    left: '0',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.common.white
  },
  [ActivityStatus.SENT]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary[500]
  }
});

function PeriodHeader({ activity, children, classes, displayBillingType }) {
  const translate = useTranslate();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.actInfoContainer}>{children}</div>
        <div className={classes.statusContainer}>
          <Status dense status={activity.status} classes={{ [ActivityStatus.SENT]: classes[ActivityStatus.SENT] }} />
          {displayBillingType && <BillingType activity={activity} />}
        </div>
      </div>
      {displayBillingType && (
        <Typography variant="h6" className={classes.activityType}>
          {translate(`activity.type.${activity.type}`)}
        </Typography>
      )}
    </>
  );
}

PeriodHeader.propTypes = {
  activity: PropTypes.object.isRequired,
  children: PropTypes.node,
  displayBillingType: PropTypes.bool
};

PeriodHeader.defaultProps = {
  children: null,
  displayBillingType: false
};

export default withStyles(styles)(PeriodHeader);
