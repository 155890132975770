import classNames from 'classnames';
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useTranslate from 'application/hooks/use-translate';
import FormElement from '../Form/FormElement/FormElement';
import { ACT_FORM_NAME, actFormFields } from '../../containers/ActForm/constants';
import PATIENT_WITHOUT_ID_TYPE, { OUTSIDE_RAMQ_PATIENT_TYPE } from '../../../shared/ramq/domainValues/situationTypes';
import { SelectorField } from '../Form/Fields';

export const styles = () =>
  ({
    adjustedMarginTop: {
      marginTop: '15px'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  className?: string;
  patientInformationType?: string;
  onChangeField: typeof change;
}

export const PatientIdentificationTypeDropDown: React.FunctionComponent<Props> = ({
  classes,
  className,
  patientInformationType,
  onChangeField
}) => {
  const translate = useTranslate('act-form.nam-dropdown');
  useEffect(() => {
    if (patientInformationType && patientInformationType !== 'NAM') {
      onChangeField(ACT_FORM_NAME, actFormFields.nam, '');
      onChangeField(ACT_FORM_NAME, actFormFields.ramqPatient, null);
    }
  }, [patientInformationType, onChangeField]);

  return (
    <FormElement
      name={`${actFormFields.patientInformation}.type`}
      className={classNames(classes.adjustedMarginTop, className)}
      component={SelectorField}
      list={[
        { label: translate('nam'), value: 'NAM' },
        { label: translate('baby'), value: PATIENT_WITHOUT_ID_TYPE.childUnderOneYear },
        { label: translate('emergency'), value: PATIENT_WITHOUT_ID_TYPE.patientNeedingUrgentCare },
        { label: translate('refund-request'), value: PATIENT_WITHOUT_ID_TYPE.refundRequest },
        { label: translate('outside-ramq'), value: OUTSIDE_RAMQ_PATIENT_TYPE }
      ]}
    />
  );
};

const selector = formValueSelector(ACT_FORM_NAME);

function mapStateToProps(state) {
  return {
    patientInformationType: selector(state, `${actFormFields.patientInformation}.type`)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onChangeField: change }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PatientIdentificationTypeDropDown) as any);
