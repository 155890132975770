import partition from 'lodash/partition';
import RAMQCodes, { TimedAttributeKey } from 'shared/ramq/RamqCodes';
import { ActCode, NatureType, isAct } from '../../../../shared/domain/activity/act/model/Act';
import Activity from '../../../../shared/domain/activity/global/model/Activity';
import { isEmpty } from '../../../../shared/utils/objectManipulation';

const NO_WARNINGS = [];

export const surgeryInReplacement = (activity: Partial<Activity>) => {
  const unsafeActivity = activity as Activity;

  if (isEmpty(unsafeActivity) || !isAct(unsafeActivity)) return NO_WARNINGS;

  const [surgeryActCodes, otherActCodes] = partition(
    unsafeActivity.codes,
    natureTypeIsSurgeryAndNotA81(unsafeActivity.date)
  );

  if (!unsafeActivity.remp || surgeryActCodes.length === 0 || otherActCodes.length === 0) {
    return NO_WARNINGS;
  }

  return [
    `Un nouveau carton sera créé pour ${otherActCodes.length > 1 ? 'les ' : "l'"}acte${
      otherActCodes.length > 1 ? 's' : ''
    } ${otherActCodes.map(({ code }) => code).join(', ')} car un seul acte peut figurer sur un carton de remplacement`
  ];
};

const natureTypeIsSurgeryAndNotA81 =
  (date: number) =>
  ({ code }: ActCode): boolean => {
    const ramqCode = RAMQCodes.get(code);

    if (!ramqCode) return false;

    if (RAMQCodes.getTimedAttribute<boolean>(ramqCode, TimedAttributeKey.isAddendum8, date)) return false;

    return ramqCode.natureType === NatureType.SURGERY;
  };

export default surgeryInReplacement;
