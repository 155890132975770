export const ROLE3 = 'role3';
export const ROLE4 = 'role4';
export const URGENT = 'urgent';
export const INTERRUPT = 'interrupt';
export const SDIFF = 'sdiff';
export const NONURGENT = 'nonUrgent';
export const INTENSIVE_CARE_ON_GUARD_DAY = 'intensiveCareOnGuardDay';
export const INTENSIVE_CARE_UNIT_SUPPORT_DAY = 'intensiveCareUnitSupportDay';
export const COEUR_POUMON = 'coeurPoumon';
export const GREATER_THAN_4_HOURS = 'greaterThan4Hours';
export const ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS = 'anestheticComplexityGreaterThan4Hours';
export const ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS = 'anestheticComplexityGreaterThan8Hours';
