import React from 'react';
import { Field } from 'redux-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MultiSelectCheckboxGroup from '../../../../Form/Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';
import { AM_PERDIEM, EVENING_PERDIEM, PM_PERDIEM } from '../../../../../../shared/periods/common/constants';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import { TRAINING_HALF_DAY_AMOUNT } from '../../../../../validations/training/claimingAmount/constants';

export const styles = (theme) => ({
  timeSelectionContainer: {
    flex: '0 1 auto',
    minWidth: 92,
    display: 'flex'
  },
  perdiemsSection: {
    display: 'block'
  },
  checkboxButton: {
    'minWidth': 0,
    'width': theme.spacing(7),
    'margin': 0,
    'marginRight': theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  amountContainer: {
    minWidth: 70,
    textAlign: 'right'
  },
  periodSelectionContainer: {
    flex: '0 1 auto',
    minWidth: 92,
    display: 'flex',
    flexDirection: 'column'
  }
});

export function PerdiemCheckboxesWithClaimedAmount({ classes, input, meta }) {
  return (
    <>
      <div className={classes.periodSelectionContainer}>
        <Field
          name={input.name}
          className={classes.timeSelectionContainer}
          component={MultiSelectCheckboxGroup}
          labelProp="value"
          valueProp="value"
          checkboxClasses={{ root: classes.checkboxButton }}
          list={[{ value: AM_PERDIEM }, { value: PM_PERDIEM }, { value: EVENING_PERDIEM }]}
        />
        {meta.error && (
          <FormHelperText className={classes.textAlignCenter} error={!!meta.error}>
            {meta.error}
          </FormHelperText>
        )}
      </div>
      <div className={classes.amountContainer}>
        <Typography variant="body2">{currencyFormat(input.value.length * TRAINING_HALF_DAY_AMOUNT)}</Typography>
      </div>
    </>
  );
}

PerdiemCheckboxesWithClaimedAmount.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default withStyles(styles)(PerdiemCheckboxesWithClaimedAmount);
