import { WithStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import useTranslate from 'application/hooks/use-translate';
import SingleSelectCheckboxGroup from '../../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { SelectorField } from '../../../../../components/Form/Fields';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import YES_NO_CHECKBOX_VALUES from '../../../../../ui/shared/form/constants';
import { actFormFields } from '../../../constants';
import OutsideRamqPatientTypeSwitch from '../OutsideRamqPatientTypeSwitch/OutsideRamqPatientTypeSwitch';
import { BILLING_MANAGER_CHECKBOX_VALUES, PATIENT_TYPES_LIST } from './constants';
import { selectOutsideRamqPatientType } from '../../../selectors';

export const styles = ({ spacing }) =>
  ({
    form: {
      paddingBottom: spacing(2)
    },
    field: {
      marginTop: spacing(2)
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {}

export function OutsideRamqForm({ classes }: Props) {
  const translate = useTranslate();

  return (
    <div className={classes.form}>
      <FormElement
        data-testid="patient-type-field"
        name={`${actFormFields.patientInformation}.outsideRamq.patientType`}
        label={translate('no-nam.outside-ramq.patient-type')}
        component={SelectorField}
        list={PATIENT_TYPES_LIST}
      />

      <FormElement
        className={classes.field}
        name={`${actFormFields.patientInformation}.outsideRamq.billingManager`}
        label={translate('no-nam.outside-ramq.authorize-billing-by-medyx')}
        required
        list={BILLING_MANAGER_CHECKBOX_VALUES}
        component={SingleSelectCheckboxGroup}
      />

      <FormElement
        name={`${actFormFields.patientInformation}.outsideRamq.allowMedyxToContactWithPatient`}
        required
        label={translate('no-nam.outside-ramq.authorize-medyx-patient-communication')}
        list={YES_NO_CHECKBOX_VALUES}
        component={SingleSelectCheckboxGroup}
      />

      <OutsideRamqPatientTypeSwitch />
    </div>
  );
}

export const mapStateToProps = createStructuredSelector({
  outsideRamqPatientType: selectOutsideRamqPatientType()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(OutsideRamqForm) as React.FunctionComponent;
