import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import withStyles, { StyledComponentProps } from '@material-ui/core/styles/withStyles';
import FormElement from '../../../../../../components/Form/FormElement/FormElement';
import DynamicPeriodCodesSelection from '../../LegacyDayUnit/DynamicPeriodCodesSelector/DynamicPeriodCodesSelector';
import ActivityType from '../../../../../../../shared/core/activity/domain/ActivityType';

export const styles = ({ palette }) => ({
  noFullFlex: {
    flex: '0 1 auto',
    minWidth: 92
  },
  chip: {
    flex: '0 1 auto',
    minWidth: 92,
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
    zIndex: 3
  }
});

export interface Props extends StyledComponentProps {
  name: string;
  codes?: any[];
  holiday: boolean;
  onChange: any;
  errorContainer: any;
}

export const DayCodeSummary: React.FunctionComponent<Props> = ({
  name,
  codes = [],
  holiday,
  onChange,
  errorContainer,
  classes
}: Props) => {
  if (holiday) {
    return (
      <Chip
        data-testid="holiday-chip"
        label={<Typography variant="button">Férié</Typography>}
        className={classes!.chip}
      />
    );
  }

  if (codes.length > 1) {
    return (
      <Chip
        data-testid="multi-code-chip"
        label={<Typography variant="button">{`${codes.length} Codes`}</Typography>}
        className={classes!.chip}
      />
    );
  }

  return (
    <FormElement
      data-testid="day-code-selector"
      errorContainer={errorContainer}
      name={name}
      onChange={onChange}
      component={DynamicPeriodCodesSelection}
      className={classes!.noFullFlex}
      activityType={ActivityType.MIXTE}
    />
  );
};

export default withStyles(styles)(DayCodeSummary);
