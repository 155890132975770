import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import DirectRamqCallEnabledConfig from '../DirectRamqCallEnabledConfig';

export interface Props {
  // @ts-ignore
  placesPreferences: PropTypes.array.isRequired;
}

export const Places: React.FunctionComponent<Props> = ({ placesPreferences }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
      <Typography gutterBottom variant="h6">
        Établissements
      </Typography>
    </Grid>

    <Grid item xs={12} sm={12}>
      <FormElement
        name="directRamqCallEnabledPlaces"
        placesPreferences={placesPreferences}
        component={DirectRamqCallEnabledConfig}
      />
    </Grid>
  </Grid>
);
