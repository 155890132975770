const displayAttachmentsOnWeb = (attachments) => {
  if (!attachments || isOnMobile()) {
    return;
  }

  attachments.forEach((attachment) => {
    const { location } = attachment;
    window.open(location, '_blank');
    window.focus();
  });
};

const isOnMobile = () => window.cordova;

export default displayAttachmentsOnWeb;
