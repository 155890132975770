import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { displayGlobalMessage } from 'app/containers/Act/actions';
import { selectUserProfileInContext } from 'app/containers/User/selectors';
import Button from 'application/components/button';
import UsersTypeahead, { TypeaheadUser } from 'application/components/users-typeahead';
import { UserRole } from 'application/types/users';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from 'server/Firebase';
import firebase from 'firebase/compat/app';
import {
  CODES_PREFERENCES,
  CONTEXT_ELEMENT_PREFERENCES,
  DIAGNOSTIC_PREFERENCES,
  DOCTORS_PREFERENCES,
  LUMP_SUMS_PREFERENCES,
  PLACES_PREFERENCES,
  USERS_COLLECTION
} from 'shared/collection/collectionConstants';
import useTranslate from 'application/hooks/use-translate';

const useStyles = makeStyles({
  select: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  alignCenter: {
    alignItems: 'center'
  },
  alignEnd: {
    alignItems: 'end'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px'
  },
  flexGrow: {
    flex: '1 0 200px'
  },
  largeGap: {
    gap: '24px'
  },
  dialogActions: {
    justifyContent: 'space-between'
  }
});

interface PreferencesState {
  preferencesToCopy: firebase.firestore.QuerySnapshot | null;
  preferencesToDelete: firebase.firestore.QuerySnapshot | null;
}

const OPTIONS = [
  { label: 'codes', value: CODES_PREFERENCES },
  { label: 'diagnostics', value: DIAGNOSTIC_PREFERENCES },
  { label: 'context-elements', value: CONTEXT_ELEMENT_PREFERENCES },
  { label: 'places', value: PLACES_PREFERENCES },
  { label: 'lump-sums', value: LUMP_SUMS_PREFERENCES },
  { label: 'doctors', value: DOCTORS_PREFERENCES }
];

const CopyFavorites = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate('pages.profile.copy-favorites');
  const user = useSelector(selectUserProfileInContext());
  const [selectedUser, setSelectedUser] = useState<TypeaheadUser | null>(null);
  const [selectedPreferences, setSelectedPreferences] = useState<string | null>(null);
  const [preferences, setPreferences] = useState<PreferencesState>({
    preferencesToCopy: null,
    preferencesToDelete: null
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const options = useMemo(
    () =>
      OPTIONS.map((option) => ({
        label: translate(option.label),
        value: option.value
      })),
    [translate]
  );

  useEffect(() => {
    if (selectedPreferences && selectedUser) {
      const preferencesToCopyPromise = firestore
        .collection(USERS_COLLECTION)
        .doc(selectedUser.id)
        .collection(selectedPreferences)
        .get();

      const preferencesToDeletePromise = firestore
        .collection(USERS_COLLECTION)
        .doc(user.id)
        .collection(selectedPreferences)
        .get();

      Promise.all([preferencesToCopyPromise, preferencesToDeletePromise]).then((result) => {
        setPreferences({
          preferencesToCopy: result[0],
          preferencesToDelete: result[1]
        });
      });
    } else {
      setPreferences({
        preferencesToCopy: null,
        preferencesToDelete: null
      });
    }
  }, [selectedPreferences, selectedUser, user]);

  const handleCopyPreferences = async (overwrite) => {
    if (!preferences.preferencesToCopy) {
      return;
    }

    setIsLoading(true);

    if (preferences.preferencesToDelete && overwrite) {
      for (const doc of preferences.preferencesToDelete.docs) {
        doc.ref.delete();
      }
    }

    for (const doc of preferences.preferencesToCopy.docs) {
      await firestore
        .collection(USERS_COLLECTION)
        .doc(user.id)
        .collection(selectedPreferences)
        .doc(doc.id)
        .set(doc.data());
    }

    dispatch(displayGlobalMessage(translate('success-confirmation'), 'success'));

    setIsOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <Dialog open={isOpen}>
        <DialogTitle>{translate('dialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translate('dialog.description', {
              sourceName: selectedUser?.fullName || '',
              sourceSize: preferences.preferencesToCopy?.size || 0,
              targetName: `${user.firstName} ${user.lastName}`,
              targetSize: preferences.preferencesToDelete?.size || 0
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <MuiButton onClick={() => setIsOpen(false)}>{translate('dialog.cancel')}</MuiButton>
          <MuiButton onClick={() => handleCopyPreferences(true)}>{translate('dialog.overwrite')}</MuiButton>
          <MuiButton onClick={() => handleCopyPreferences(false)}>{translate('dialog.keep')}</MuiButton>
        </DialogActions>
      </Dialog>

      <div className={classes.flexColumn}>
        <Typography gutterBottom variant="h6">
          {translate('dialog.title')}
        </Typography>

        <div className={classes.flexColumn}>
          <div className={classNames(classes.flexRow, classes.alignEnd)}>
            <Select
              className={classNames(classes.select, classes.flexGrow)}
              value={selectedPreferences}
              onChange={(event) => setSelectedPreferences(event.target.value as string)}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>

            <UsersTypeahead
              placeholder={translate('user-placeholder')}
              userRoles={[
                UserRole.DEVELOPER,
                UserRole.ADMINISTRATOR,
                UserRole.AGENT,
                UserRole.AUDITOR,
                UserRole.DOCTOR
              ]}
              onUserSelect={setSelectedUser}
              className={classes.flexGrow}
              autoFocus={false}
              inputType="search"
              formatFunction={(user) => user.fullName}
            />

            <Button
              theme="primary"
              onClick={() => setIsOpen(true)}
              disabled={!selectedPreferences || !selectedUser}
              loading={isLoading}
            >
              {translate('copy')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyFavorites;
