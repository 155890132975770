import React, { useMemo, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import { buildUnitsString } from '../../../act/shared/domain/roles/roles.utils';
import RAMQCodes from '../../../../shared/ramq/RamqCodes';
import { selectSpecialty } from '../../User/selectors';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';
import { selectSelectedDate } from '../selectors';

export interface OwnProps {
  actCode: ActCode;
  onDelete?: () => void;
  toolTipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

interface StateProps {
  actDate: number;
  userSpecialty: SpecialtyName;
}

type Props = StateProps & OwnProps;

export const SelectedActCode: React.FunctionComponent<Props> = ({
  actCode,
  onDelete,
  toolTipPlacement,
  actDate,
  userSpecialty
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const unitsString = useMemo(
    () => buildUnitsString(RAMQCodes.getRoleUnitsForSpecialty(actCode.code, userSpecialty)),
    [actCode, userSpecialty]
  );

  const { codeDescription } = useMemo(() => ({ codeDescription: RAMQCodes.get(actCode.code)?.description }), [actCode]);

  return (
    <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true
        }}
        onClose={() => setTooltipOpen(false)}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement={toolTipPlacement}
        title={`${codeDescription} ${unitsString}`}
      >
        <Chip
          key={actCode.id}
          style={{ margin: 2 }}
          label={
            <>
              {actCode.code}
              <Restrict atLeastRole={Role.Agent}>
                {` (${RAMQCodes.getRolesForCodeEffectiveOnAndForSpecialty(
                  actCode.code,
                  actDate,
                  userSpecialty
                ).toString()})`}
              </Restrict>
            </>
          }
          onClick={() => setTooltipOpen(true)}
          onDelete={onDelete}
        />
      </Tooltip>
    </ClickAwayListener>
  );
};

export const mapStateToProps = createStructuredSelector({
  actDate: selectSelectedDate(),
  userSpecialty: selectSpecialty()
});

export default connect<StateProps, null, OwnProps>(mapStateToProps)(SelectedActCode);
