import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  explanationText: {
    whiteSpace: 'pre-line',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[200],
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(4)
  }
});

function RamqExplanationText({ explanation, classes }) {
  return <ListItemText className={classes.explanationText} primary={explanation} />;
}

RamqExplanationText.defaultProps = {
  explanation: ''
};

RamqExplanationText.propTypes = {
  explanation: PropTypes.node
};

export default withStyles(styles)(RamqExplanationText);
