import Typography from '@material-ui/core/Typography';
import User from 'shared/domain/user/User';
import moment, { Moment } from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { CircularProgress, MenuItem, Select, WithStyles, withStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import useTranslate from 'application/hooks/use-translate';
import React, { useState } from 'react';
import ReportAPI from 'app/infrastructure/api/ReportAPI';
import { displayGlobalError } from 'app/containers/Act/actions';
import classNames from 'classnames';

export const styles = (theme) =>
  ({
    dateMessage: {
      marginRight: theme.spacing(1)
    },
    openPDFButton: {
      marginLeft: theme.spacing(4)
    }
  }) as const;

export interface OwnProps {
  user: User;
  periodStart: Moment | null;
  periodend: Moment | null;
  onStartDateChange: (date: Moment | null) => void;
}

export interface ReduxDispatchProps {
  onDisplayGlobalError: (error: string) => any;
}

export interface Props extends OwnProps, WithStyles<typeof styles>, ReduxDispatchProps {}

export const TravelDistancesReportForm: React.FunctionComponent<Props> = (props: Props) => {
  const translate = useTranslate('reports');
  const translateError = useTranslate('errors');
  const { user, onDisplayGlobalError, periodStart, periodend, onStartDateChange, classes } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadFile = async () => {
    if (!periodStart || !periodend) return;

    const options = {
      userId: user.id,
      periodStart: periodStart.valueOf(),
      periodEnd: periodend.valueOf()
    };

    setIsLoading(true);
    ReportAPI.downloadTravelDistancesReport(options)
      .catch(() => {
        onDisplayGlobalError(translateError('generic'));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Typography className={classes.dateMessage}>{translate('start-date')}:</Typography>

      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker
          emptyLabel={translate('start-date')}
          value={periodStart}
          disableFuture
          format="YYYY-MM-DD"
          leftArrowIcon={<PrevArrowIcon />}
          rightArrowIcon={<NextArrowIcon />}
          onChange={(date) => onStartDateChange(date as Moment)}
        />
      </MuiPickersUtilsProvider>

      {!window.cordova && (
        <button
          data-testid="open-pdf-button"
          type="button"
          className={classNames('btn btn-primary', classes.openPDFButton)}
          disabled={isLoading || periodStart === null}
          onClick={downloadFile}
        >
          {isLoading ? <CircularProgress color="primary" size={24} /> : translate('open-pdf')}
        </button>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onDisplayGlobalError: bindActionCreators(displayGlobalError, dispatch)
  };
}

export default compose<typeof React.Component>(
  connect<{}, ReduxDispatchProps, OwnProps>(null, mapDispatchToProps),
  withStyles(styles)
)(TravelDistancesReportForm);
