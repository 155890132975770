import SingleSelectCheckboxGroup from 'app/components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { AssociatedField } from '../requiredDetail.type';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const associatedFields: AssociatedField[] = [
  {
    getName: (index) => `codes[${index}].differentChronicPainProblem`,
    label: 'Problème différent ?',
    list: YES_NO_CHECKBOX_VALUES,
    props: {
      defaultValue: false
    },
    type: SingleSelectCheckboxGroup
  }
];

export default { associatedFields };
