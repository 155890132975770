import PropTypes from 'prop-types';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import RequiredDetails from '../../../components/RequiredDetails/RequiredDetails';
import UIOptimizations from '../UIOptimizations/UIOptimizations';
import ActCodeSelector from '../ActCodeSelector/ActCodeSelector';
import { selectReduxActFormObject } from '../selectors';

export class CodeListWithRequiredDetails extends React.PureComponent {
  render() {
    const { fields: reduxFormFields, meta, act } = this.props;

    return (
      <div id={reduxFormFields.name}>
        <ActCodeSelector fields={reduxFormFields} meta={meta} />
        <UIOptimizations fields={reduxFormFields} act={act} />
        <RequiredDetails fields={reduxFormFields} act={act} />
      </div>
    );
  }
}

CodeListWithRequiredDetails.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  act: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  act: selectReduxActFormObject
});

export default compose(connect(mapStateToProps))(CodeListWithRequiredDetails);
