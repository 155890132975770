import firebase from 'firebase/compat/app';
import axios from 'axios';
import API_BASE_URL from '../../../shared/server/apiConfig';

export default class DeferredPaymentAPI {
  public static async validateXml(xml: string): Promise<String> {
    const endpoint = `${API_BASE_URL}/deferredPayments/validate`;
    const userToken = await firebase.auth().currentUser!.getIdToken();
    return axios
      .post(endpoint, xml, {
        headers: { 'Content-Type': 'application/xml', 'Authorization': `Bearer ${userToken}` }
      })
      .then((response) => response.data);
  }
}
