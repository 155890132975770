import { call, select, spawn, takeEvery } from 'redux-saga/effects';
import { logDefault } from '../../../shared/utils/plog';
import actPictureRepository from '../Storage/cordova/actPictureRepository';
import { selectUserIdInContext } from '../User/selectors';
import { APP_PAUSED, APP_RESUMED, APP_STARTED } from './actions';
import removeDeprecatedPictures from './removeDeprecatedPictures';
import actAttachmentRepository from '../Storage/cordova/actAttachmentRepository';
import { SYNCHRONIZED_ATTACHMENT, SYNCHRONIZED_PICTURE } from '../Storage/cordova/constants';
import storageUploader from '../Storage/uploader/StorageUploader';
import StorageUploadContext from '../Storage/uploader/StorageUploadContext';
import { getStore } from '../../reduxStore/configureStore';

export function* uploadPendingActPictures() {
  if (window.cordova) {
    logDefault({
      type: 'saga',
      text: 'AppSagas/uploadPendingActPictures',
      array: ['start uploading pending act pictures']
    });
    const pendingLocalPictures = yield call([actPictureRepository, actPictureRepository.getAllActPictures]);
    logDefault({
      type: 'saga',
      text: 'AppSagas/uploadPendingActPictures',
      array: ['got this many pictures to upload: ', pendingLocalPictures.length]
    });

    const validPendingLocalPictures = yield call(removeDeprecatedPictures, pendingLocalPictures);

    yield* launchUpload(validPendingLocalPictures, SYNCHRONIZED_PICTURE);
  }
}

export function* uploadPendingActAttachments() {
  if (window.cordova) {
    logDefault({
      type: 'saga',
      text: 'AppSagas/uploadPendingActAttachments',
      array: ['start uploading pending act attachments']
    });

    const pendingLocalAttachments = yield call([actAttachmentRepository, actAttachmentRepository.getAllActAttachments]);
    logDefault({
      type: 'saga',
      text: 'AppSagas/uploadPendingActAttachments',
      array: ['got this many attachments to upload: ', pendingLocalAttachments.length]
    });

    yield* launchUpload(pendingLocalAttachments, SYNCHRONIZED_ATTACHMENT);
  }
}

function* launchUpload(pendingActFiles, uploadType, repository) {
  logDefault({
    type: 'saga',
    text: 'AppSagas/launchUpload',
    array: ['Effectively launching upload for', pendingActFiles.map((file) => file.fileName)]
  });

  // The "resume" event on android is triggered when we come back from the camera (which is not the case on iOS)
  // Explained here : https://cordova.apache.org/docs/en/latest/guide/platforms/android/index.html#example
  // So here the logic is to initiate the upload of the file whatever the case (so both the ActForm saga and the AppSaga will
  // do so), but only one will be able to acquire a lock on the file, so it will be processed only once. Then, on upload success,
  // the saga handling the update has to remove the local file and release the lock.

  const userId = yield select(selectUserIdInContext());

  pendingActFiles.forEach((actFile) => {
    const uploadContext = new StorageUploadContext(
      actFile.actId,
      actFile.fileEntry,
      false /* persistFileLocallyBeforeUpload */
    );
    uploadContext.fileWrapper = actFile;
    uploadContext.uploadType = uploadType;
    uploadContext.userId = userId;
    uploadContext.localFileRepository = repository;
    uploadContext.postLocalPersistFileHook = undefined; // The files are inherently already persisted locally
    uploadContext.storeDispatch = getStore().dispatch;

    storageUploader.initiateUpload(uploadContext);
  });
}

export const checkFirestoreState = async () => {};

export function* startAppStartedJobs() {
  logDefault({
    type: 'saga',
    text: 'AppSagas/startAppStartedJobs',
    array: ['Application started jobs launching']
  });

  yield* uploadPendingActPictures();
  yield* uploadPendingActAttachments();
}

export function* startAppResumedJobs() {
  logDefault({
    type: 'saga',
    text: 'AppSagas/startAppResumedJobs',
    array: ['Application resumed jobs launching']
  });

  yield* uploadPendingActPictures();
  yield* uploadPendingActAttachments();
}

export function* startAppPausedJobs() {
  logDefault({
    type: 'saga',
    text: 'AppSagas/startAppPausedJobs',
    array: ['Application paused']
  });
  yield spawn(checkFirestoreState);
}

export default function* appSaga() {
  yield takeEvery(APP_STARTED, startAppStartedJobs);
  yield takeEvery(APP_RESUMED, startAppResumedJobs);
  yield takeEvery(APP_PAUSED, startAppPausedJobs);
}
