import { uniq } from 'lodash';
import moment from 'moment-timezone';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { getStore } from '../../../../reduxStore/configureStore';
import Act from '../../../../../shared/domain/activity/act/model/Act';
import { selectActivitiesInContext } from '../../../../containers/Activities/selectors';

const NO_WARNINGS = [];
const LIMITED_CODES = ['15485', '15600', '15486', '15601', '15487', '15602'];
const LIMIT = 15;

const halfDayPreoperativeClinicLimitWarning = (form: Partial<Act>) => {
  const jsForm = form as Act;
  const currentStartTime = moment(jsForm.start);

  if (!isTimeMorning(currentStartTime) && !isTimeAfternoon(currentStartTime)) return NO_WARNINGS;

  const usedActCode = (jsForm.codes || []).find((actCode) => LIMITED_CODES.includes(actCode.code));
  if (!usedActCode) return NO_WARNINGS;

  const activities = (selectActivitiesInContext()(getStore().getState()) || []) as Act[];

  const matchingOtherCodes = activities.reduce((usedCodes: string[], act: Act) => {
    if (act.type !== ActivityType.ACT) return usedCodes;
    if (act.id === jsForm.id) return usedCodes;
    if (act.date !== jsForm.date) return usedCodes;
    if (isTimeMorning(currentStartTime) && !isTimeMorning(moment(act.start))) return usedCodes;
    if (isTimeAfternoon(currentStartTime) && !isTimeAfternoon(moment(act.start))) return usedCodes;

    const actCode = act.codes.find((actCode) => LIMITED_CODES.includes(actCode.code));

    if (!actCode) return usedCodes;

    return [...usedCodes, actCode.code];
  }, []);

  if (matchingOtherCodes.length < LIMIT) {
    return NO_WARNINGS;
  }

  const uniqueMatchingCodes = uniq([...matchingOtherCodes, usedActCode.code]);

  if (uniqueMatchingCodes.length === 1) {
    return [`Le code « ${uniqueMatchingCodes[0]} » est limité à ${LIMIT} par demi journée`];
  }

  return [`Les codes « ${uniqueMatchingCodes.join(', ')} » sont limités à ${LIMIT} par demi journée`];
};

function isTimeMorning(time: moment.Moment) {
  const lowerLimit = time.clone().set({ hour: 7, minute: 0, second: 0, millisecond: 0 });
  const upperLimit = time.clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

  return time.isBetween(lowerLimit, upperLimit, undefined, '[)');
}

function isTimeAfternoon(time: moment.Moment) {
  const lowerLimit = time.clone().set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  const upperLimit = time.clone().set({ hour: 16, minute: 0, second: 0, millisecond: 0 });

  return time.isBetween(lowerLimit, upperLimit, undefined, '[)');
}

export default halfDayPreoperativeClinicLimitWarning;
