import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = () => ({
  smallChip: {
    height: '18px',
    marginLeft: '1em',
    cursor: 'inherit'
  }
});

export function PrincipalPlaceChip({ place, classes }) {
  return place.priority === 1 ? <Chip label="P" className={classes.smallChip} /> : null;
}

PrincipalPlaceChip.propTypes = {
  place: PropTypes.object.isRequired
};

export default withStyles(styles)(PrincipalPlaceChip);
