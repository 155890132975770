import moment from 'moment-timezone';
import { chain } from 'lodash';
import { getDayOfMonth } from '../utils/dateTime/dateTimeUtils';
import {
  RamqExchange,
  RamqExchangeLine,
  RamqExchangeLineSuccess,
  RamqExchangeLineType,
  RamqExchangeType,
  RamqResponseOutcome
} from './constants';

export const padStringWithZeros = (string: string, length: number) => `0000000${string}`.slice(-length);

export const getRamqDayOfMonthFromTimestamp = (timestamp: number): string =>
  padStringWithZeros(getDayOfMonth(timestamp).toString(), 2);

export const getRamqNumberOfHoursWorkedFromTimestamps = (fromTimestamp: number, toTimestamp: number): string => {
  const from = moment(fromTimestamp);
  const to = fromTimestamp < toTimestamp ? moment(toTimestamp) : moment(toTimestamp).add(1, 'days');

  const minutesDifferences = (to.diff(from, 'minutes') / 60).toFixed(2).toString();
  return padStringWithZeros(minutesDifferences.replace('.', ''), 4);
};

export const parseHoursWorkedStringToNumber = (hours: string): number =>
  parseFloat(`${hours.substring(0, 2)}.${hours.substring(2, 4)}`);

export const parseHoursWorkedNumberToString = (hours: number): string =>
  padStringWithZeros(hours.toFixed(2).replace('.', ''), 4);

export const getWorkedHours = (fromTimestamp: string, toTimestamp: string) => {
  const from = moment(fromTimestamp);
  const to = fromTimestamp < toTimestamp ? moment(toTimestamp) : moment(toTimestamp).add(1, 'days');

  return to.diff(from).valueOf() / 1000 / 3600;
};

export const getHourFromTimestamp = (time: string): number => parseInt(moment(time).format('H'), 10);

export function isRamqExchangeLineSuccess(line: RamqExchangeLine): line is RamqExchangeLineSuccess {
  return line.outcome === 'success';
}

export function doesRamqExchangeLineHaveStatus(line: RamqExchangeLine, type: RamqExchangeLineType): boolean {
  return line.type === type;
}

export const getLatestRamqExchange = (exchanges: RamqExchange[]): RamqExchange =>
  chain(exchanges).sortBy('createdOn').last().value();

export const isRamqExchangeOutcomeSuccess = (exchange: RamqExchange): boolean =>
  exchange.outcome === RamqResponseOutcome.SUCCESS;

export const doesRamqExchangeHaveStatus = (exchange: RamqExchange, requiredStatus: RamqExchangeType): boolean =>
  exchange.type === requiredStatus;

export const getStatementAmountOfExchange = (exchange: RamqExchange): number | undefined => {
  if (exchange.type !== RamqExchangeType.PAYMENT_EXPLANATION_RESPONSE) return undefined;

  const statementLine = exchange.lines?.find(
    (line): line is RamqExchangeLineSuccess =>
      line.outcome === RamqResponseOutcome.SUCCESS && line.type === RamqExchangeLineType.STATEMENT_LINE_TYPE
  );

  return statementLine && parseFloat(statementLine.amount);
};
