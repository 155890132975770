import { get } from 'lodash';
import { TRAINING_FORM_NAME } from '../../ui/constants';

export const selectTrainingId = () => (state) => get(state, ['form', TRAINING_FORM_NAME, 'values', 'id'], undefined);

export const selectAttachments = () => (state) => {
  const trainingFormValues = get(state, ['form', TRAINING_FORM_NAME, 'values'], undefined);
  if (trainingFormValues) {
    return trainingFormValues.attachments ?? [];
  }

  return undefined;
};
