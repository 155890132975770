import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from 'app/components/Form/Fields';
import { RAMQContextElements } from 'shared/ramq/RamqContextElements';
import PATIENT_NO_SHOW from 'shared/ramq/requiredDetails/patientNoShow';

interface ListValue {
  label: string;
  value: boolean;
}

const ramqContextElement100 = RAMQContextElements.get('100');

const getList = (date: number) => {
  const list: ListValue[] = [];
  if (ramqContextElement100 && RAMQContextElements.isEffectiveOn(ramqContextElement100, date)) {
    list.push({ label: 'Patient non-présenté', value: true });
  }
  return list;
};

const getAssociatedFields = (date: number) => {
  if (!ramqContextElement100 || !RAMQContextElements.isEffectiveOn(ramqContextElement100, date)) {
    return [];
  }

  return {
    associatedFields: [
      {
        label: '',
        getName: (index) => `codes[${index}].${PATIENT_NO_SHOW}`,
        list: getList(date),
        notRequired: true,
        type: DeletableValueOnUnmountSingleSelectCheckboxGroup
      }
    ]
  };
};

export default getAssociatedFields;
