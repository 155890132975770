import { UPDATE_MAX_SEARCH_REACHED, UPDATE_RESULTS, UPDATE_RESULTS_FAILURE, UPDATE_SEARCH_VALUE } from './actions';

export const initialState = {
  searchValue: '',
  maxSearchReached: false,
  hits: [],
  totalHitsNumber: 0
};

export default function searchSuggestionsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.value
      };
    case UPDATE_MAX_SEARCH_REACHED:
      return {
        ...state,
        maxSearchReached: action.value
      };
    case UPDATE_RESULTS: {
      const { results } = action;

      if (results.query.trim() === '') {
        return {
          ...state,
          hits: [],
          totalHitsNumber: 0
        };
      }

      return {
        ...state,
        hits: results.hits,
        totalHitsNumber: results.nbHits
      };
    }
    case UPDATE_RESULTS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
