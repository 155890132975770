import React, { useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { WithStyles, withStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Moment } from 'moment-timezone';
import { useQuery } from '@tanstack/react-query';
import Spinner from 'application/components/spinner';
import HeaderPortal from '../../../../components/Portals/HeaderPortal/HeaderPortal';
import User from '../../../../../shared/domain/user/User';
import { selectUserProfileInContext } from '../../../User/selectors';
import ReceivableAccountsReportForm from '../ReceivableAccountsReportForm/ReceivableAccountsReportForm';
import UserBillingType from '../../../../../shared/domain/user/UserBillingType';
import ReportAPI from '../../../../infrastructure/api/ReportAPI';

export const styles = (theme) =>
  ({
    whiteBackground: {
      backgroundColor: 'white'
    },
    spinner: {
      marginTop: theme.spacing(5)
    },
    iframeWrapper: {
      padding: theme.spacing(2),
      overflowX: 'scroll',
      height: '100%',
      width: '100%'
    },
    iframe: {
      height: '100%',
      width: '100%',
      minWidth: '1200px'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  user: User | undefined;
}

export const ReceivableAccountsPage: React.FunctionComponent<Props> = ({ user, classes }: Props) => {
  const [selectedBillingAccount, setSelectedBillingAccount] = useState<string>(UserBillingType.PRIVATE);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(null);

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ['receivable-accounts', selectedDate, selectedBillingAccount],
    queryFn: () => {
      if (!user || !selectedDate) return;

      return ReportAPI.downloadReceivableAccounts({
        mode: 'html',
        date: selectedDate.valueOf(),
        userId: user?.id,
        ...(selectedBillingAccount !== UserBillingType.PRIVATE && { groupId: selectedBillingAccount })
      });
    },
    enabled: Boolean(user && selectedDate)
  });

  if (!user) return;

  return (
    <>
      <HeaderPortal>
        <Toolbar className={classes.whiteBackground}>
          <ReceivableAccountsReportForm
            user={user}
            selectedDate={selectedDate}
            onSelectedDateChange={setSelectedDate}
            selectedBillingAccount={selectedBillingAccount}
            onSelectedBillingAccountChange={setSelectedBillingAccount}
          />
        </Toolbar>
      </HeaderPortal>

      {isFetching && <Spinner className={classes.spinner} />}

      {data && isSuccess && !isFetching && (
        <div className={classes.iframeWrapper}>
          <iframe srcDoc={data} title="Report preview" className={classes.iframe} />
        </div>
      )}
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  user: selectUserProfileInContext()
});

export default compose<typeof React.Component>(withStyles(styles), connect(mapStateToProps))(ReceivableAccountsPage);
