import * as Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Act, { isAct } from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';
import { selectActivitiesInContext } from '../../../containers/Activities/selectors';
import { getStore } from '../../../reduxStore/configureStore';
import { codeIsInRole2 } from '../../../../shared/domain/activity/act/service/actCodeUtils';
import { ROLE3 } from '../../../../shared/ramq/contextElements/globalContextElements';
import { selectSpecialty } from '../../../containers/User/selectors';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';

const moment = extendMoment(Moment);

export const role2Role3ExclusiveCodesValidation = (act: Act) => {
  if (!moment(act.date).isAfter('2023-01-31') || !act.start || !act.end) {
    return {};
  }

  const userSpecialty = selectSpecialty()(getStore().getState());

  if (
    act.contextElements?.includes(ROLE3) &&
    activitiesAtSameTime(act).some((activity) =>
      activity.codes?.find((code) => codeIsInRole2(code, activity, userSpecialty))
    )
  ) {
    return {
      [actFormFields.contextElements]:
        'Il y a déjà un acte en R2 facturé aux mêmes heures. SVP modifier les heures du R3 ou modifier le rôle car non facturable'
    };
  }
  if (
    act.codes?.find((code) => codeIsInRole2(code, act, userSpecialty)) &&
    activitiesAtSameTime(act).some((activitiy) => activitiy.contextElements?.includes(ROLE3))
  ) {
    return {
      [actFormFields.codes]: {
        _error:
          'Il y a déjà un acte en R3 facturé aux mêmes heures. SVP modifier les heures du R2 ou modifier le rôle car non facturable'
      }
    };
  }
  return {};
};

const activitiesAtSameTime = (act: Act) => {
  const activities = selectActivitiesInContext()(getStore().getState());
  const actDurationRange = moment.range(moment(act.remp || act.start), moment(act.end || act.start));
  return activities.filter((activity) => {
    if (act.id === activity.id || !isAct(activity) || !activity.end || activity.status === ActivityStatus.CANCELED) {
      return false;
    }
    const activityDurationRange = moment.range(moment(activity.remp || activity.start), moment(activity.end));
    return activityDurationRange.overlaps(actDurationRange, { adjacent: false });
  });
};

export default role2Role3ExclusiveCodesValidation;
