/* eslint-disable react/no-array-index-key */
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { getWeekBasedStartDateFromDate } from '../../../../../shared/periods/periods';
import { defaultFormatMomentDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import PeriodAdminActions from '../../../../containers/PeriodsBilling/PeriodAdminActions/PeriodAdminActions';
import PeriodHeader from '../../../../containers/PeriodsBilling/PeriodHeader/PeriodHeader';
import ActivityCommentLists from '../../../../containers/UsersRoute/UserRoute/UserActivities/ActSummaryActivity/ActivityCommentLists/ActivityCommentLists';
import PlaceDetails from '../../../Places/PlaceDetails';
import ActivityOutcomeResponses from '../../../Ramq/RamqExchange/ActivityOutcomeResponses/ActivityOutcomeResponses';
import ActivitySummaryWrapper from '../../ActivitySummaryWrapper/ActivitySummaryWrapper';
import DetailsNote from '../../DetailsNote/DetailsNote';
import PeriodDaysSummary from '../PeriodDaysSummary/PeriodDaysSummary';

export const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3)
  },
  selectedPlaceContainer: {
    display: 'flex',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  mixteDate: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.common.white
  },
  mixteItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    padding: 0,
    marginBottom: theme.spacing(2)
  },
  daysContainer: {
    width: '100%'
  },
  dayContainer: {
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'paddingTop': theme.spacing(1),
    'paddingBottom': theme.spacing(1),
    'borderBottom': `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: theme.spacing(1)
    }
  },
  perdiems: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  code: {
    display: 'flex',
    justifyContent: 'center'
  },
  upperCased: {
    textTransform: 'uppercase'
  },
  billingType: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
});

export function PeriodsSummaryActivity({
  activity,
  onSelect,
  TimeSummaryComponent,
  shouldDisplayDay,
  isSelected,
  classes
}) {
  const { date, place, duration } = activity;
  return (
    <ActivitySummaryWrapper activity={activity} isSelected={isSelected}>
      <ListItem button onClick={onSelect} className={classNames(classes.mixteItem, 'selectable-area')}>
        <PeriodHeader activity={activity} displayBillingType>
          <Typography variant="button" noWrap className={classes.mixteDate}>
            {defaultFormatMomentDate(getWeekBasedStartDateFromDate(moment(date), duration))}
          </Typography>
          <div className={classes.selectedPlaceContainer}>
            <PlaceDetails displaySignatoryName place={place} theme="light" />
          </div>
        </PeriodHeader>
        <div className={classes.daysContainer}>
          <PeriodDaysSummary
            activity={activity}
            shouldDisplayDay={shouldDisplayDay}
            TimeSummaryComponent={TimeSummaryComponent}
          />
        </div>
        <DetailsNote activity={activity} />
      </ListItem>
      <Restrict atLeastRole={Role.Agent}>
        <ActivityCommentLists activity={activity} />
        <ActivityOutcomeResponses activity={activity} />
        <PeriodAdminActions period={activity} />
      </Restrict>
    </ActivitySummaryWrapper>
  );
}

PeriodsSummaryActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  shouldDisplayDay: PropTypes.func.isRequired,
  TimeSummaryComponent: PropTypes.object.isRequired,
  isSelected: PropTypes.bool
};

PeriodsSummaryActivity.defaultProps = {
  isSelected: false
};

export default compose(withStyles(styles))(PeriodsSummaryActivity);
