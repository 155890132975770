export const CNESST_EVENT = {
  id: '1',
  description: 'Service rendu dans le cadre des lois relatives à la santé et à la sécurité du travail'
};

export const PERIOD_EVENT = {
  id: '2',
  description: 'Dernières menstruations'
};

export const LABOR_EVENT = {
  id: '3',
  description: "Date prévue de l'accouchement"
};

export default [CNESST_EVENT, PERIOD_EVENT, LABOR_EVENT];
