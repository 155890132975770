import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { RAMQ_LOGS_ROUTE } from '../../../../../containers/Navigation/constants';
import DialogTrigger from '../../../../Dialogs/DialogTrigger/DialogTrigger';
import XMLViewerDialog from '../../../../Dialogs/XMLViewerDialog/XMLViewerDialog';
import InformationBox from '../../../../InformationBox/InformationBox';

const styles = (theme) => ({
  container: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
  }
});

const showRamqInfo = (ramqLogId) => {
  window.open(`#${RAMQ_LOGS_ROUTE}/${ramqLogId}`);
};

function DeferredPaymentResponseDetails({ ramqResponse, classes }) {
  return (
    <div className={classes.container}>
      <InformationBox
        label="Numéro d'attestation"
        content={<Typography>{ramqResponse.attestationNumber}</Typography>}
      />
      <InformationBox
        label="Numéro accusé de réception"
        content={<Typography>{ramqResponse.receptionNumber}</Typography>}
      />
      <InformationBox label="Numéro de transaction" content={<Typography>{ramqResponse.fileTradeNumber}</Typography>} />
      <InformationBox label="Nom du fichier envoyé" content={<Typography>{ramqResponse.fileName}</Typography>} />
      <InformationBox
        label="XML envoyés"
        content={
          <div>
            <DialogTrigger dialogProps={{ xml: ramqResponse.paymentRequestXml }} DialogComponent={XMLViewerDialog}>
              <Button>Demande de paiement</Button>
            </DialogTrigger>
            <DialogTrigger dialogProps={{ xml: ramqResponse.controlFileXml }} DialogComponent={XMLViewerDialog}>
              <Button>Fichier de contrôle</Button>
            </DialogTrigger>
          </div>
        }
      />
      <InformationBox
        label="Logs RAMQ"
        content={
          <div>
            <Button onClick={() => showRamqInfo(ramqResponse.ramqSuccessLogsId[0])}>Initier envoi</Button>
            <Button onClick={() => showRamqInfo(ramqResponse.ramqSuccessLogsId[1])}>Envoyer lot</Button>
            <Button onClick={() => showRamqInfo(ramqResponse.ramqSuccessLogsId[2])}>Correller envoi</Button>
          </div>
        }
      />
    </div>
  );
}

DeferredPaymentResponseDetails.propTypes = {
  ramqResponse: PropTypes.object.isRequired
};

export default withStyles(styles)(DeferredPaymentResponseDetails);
