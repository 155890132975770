import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import PropTypes from 'prop-types';
import React from 'react';
import Grow from '@material-ui/core/Grow';
import { ListItemSecondaryAction, withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { displayPlace } from '../../../Form/utils/fieldTransformations';
import DetailsItem from '../../Act/Details/DetailsItem/DetailsItem';
import MainInformationContainer from '../../MainInformationContainer/MainInformationContainer';
import PerdiemsCodeInfo from '../../Mixte/PerdiemsCodeInfo/PerdiemsCodeInfo';
import ActivityContainer from '../../ActivityContainer/ActivityContainer';
import { selectDetailedView } from '../../../../containers/Activities/selectors';
import CollapsableDetails from './Details/CollapsableDetails';
import { TRAINING_ROUTE } from '../../../../containers/Navigation/constants';
import DesktopActions from 'app/containers/ActivitiesRoute/DayRoute/DayActivities/Act/DesktopActions/DesktopActions';

export const styles = (theme) => ({
  extraText: {
    fontWeight: 'bold',
    position: 'absolute',
    right: '50%',
    left: '50%',
    textTransform: 'uppercase'
  },
  headerContainer: {
    height: 15
  },
  activityContainer: {
    borderBottom: `1px solid ${theme.palette.grey[600]}`
  }
});

export function TrainingActivity({ activity, classes, detailedView }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Grow in>
      <ActivityContainer
        className={classes.periodContainer}
        onClick={() => navigate({ pathname: `${TRAINING_ROUTE}/${activity.id}`, search: location.search })}
      >
        <MainInformationContainer activity={activity}>
          <div className={classes.headerContainer}>
            <Typography variant="subtitle1" className={classes.extraText}>
              <FormattedMessage id="activity.type.training" />
            </Typography>
          </div>
          <PerdiemsCodeInfo activity={activity} />
          <DetailsItem
            dense
            icon={<PlaceIcon />}
            text={<Typography noWrap>{displayPlace(activity.place)}</Typography>}
          />
        </MainInformationContainer>
        <ListItemSecondaryAction>
          <DesktopActions activity={activity} />
        </ListItemSecondaryAction>
        <CollapsableDetails open={detailedView} activity={activity} />
      </ActivityContainer>
    </Grow>
  );
}

TrainingActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  detailedView: PropTypes.bool.isRequired
};

export const mapStateToProps = createStructuredSelector({
  detailedView: selectDetailedView()
});

export default compose(withStyles(styles), connect(mapStateToProps))(TrainingActivity);
