import moment, { Moment } from 'moment-timezone';

import LEGACY_LIMITED_DIAGNOSTIC_LIST from './legacyLimitedDiagnosticList.json';
import LIMITED_DIAGNOSTIC_LIST from './limitedDiagnosticList.json';

class LimitedDiagnosticListSelector {
  private static readonly SWITCH_DATE = moment('01-10-2021', 'DD-MM-YYYY');

  static select(currentDate: Moment) {
    return currentDate.isBefore(LimitedDiagnosticListSelector.SWITCH_DATE)
      ? LEGACY_LIMITED_DIAGNOSTIC_LIST
      : LIMITED_DIAGNOSTIC_LIST;
  }
}

export default LimitedDiagnosticListSelector;
