import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EditActivity from '../../../components/Activities/EditActivity/EditActivity';
import SafeActivityByIdFetcher from '../../ActivitiesSearch/SafeActivityByIdFetcher';
import { ACTIVITY_ID_ROUTE_PARAM, ACTIVITY_TYPE_ROUTE_PARAM } from '../../Navigation/constants';
import SearchActivitiesWithFiltersRoute from './SearchActivitiesWithFiltersRoute';

function SearchActivitiesRoute() {
  return (
    <Routes>
      <Route index element={<SearchActivitiesWithFiltersRoute />} />

      <Route path={ACTIVITY_TYPE_ROUTE_PARAM}>
        <Route
          path={ACTIVITY_ID_ROUTE_PARAM}
          element={
            <SafeActivityByIdFetcher
              activities={[]}
              render={({ activity }) => (!activity ? null : <EditActivity activity={activity} />)}
            />
          }
        />
      </Route>
    </Routes>
  );
}

export default SearchActivitiesRoute;
