import React from 'react';
import { gql, useQuery } from '@apollo/client';
import useTranslate from 'application/hooks/use-translate';
import openUrl from 'application/utilities/open-url';
import classNames from 'classnames';

interface Props {
  userId: string;
}

interface DeviceModelWithUpdatedAt {
  model: string;
  updatedAt: number;
}

interface UserDevicesInfo {
  user: {
    id: string;
    deviceInfos: {
      iOS: DeviceModelWithUpdatedAt | null;
      Android: DeviceModelWithUpdatedAt | null;
      web: DeviceModelWithUpdatedAt | null;
    } | null;
  };
}

const USER_DEVICES_INFO_QUERY = gql`
  query UserDevicesInfo($id: String!) {
    user(id: $id) {
      id
      deviceInfos {
        iOS {
          model
          updatedAt
        }
        Android {
          model
          updatedAt
        }
        web {
          model
          updatedAt
        }
      }
    }
  }
`;

const DevicesInfo = ({ userId }: Props) => {
  const translate = useTranslate('pages.profile.devices-info');

  const { data, loading } = useQuery<UserDevicesInfo>(USER_DEVICES_INFO_QUERY, {
    variables: { id: userId }
  });

  if (loading || !data || !data.user || !data.user.deviceInfos) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="text-xl font-bold">{translate('title')}</div>

      {Object.entries(data.user.deviceInfos).map(([platform, deviceInfo]) => {
        if (!deviceInfo || platform === '__typename') return null;

        const isMobile = ['iOS', 'Android'].includes(platform);

        return (
          <div
            key={platform}
            className={classNames('flex p-2', { 'cursor-pointer': isMobile })}
            onClick={() => isMobile && openUrl(`https://www.gsmarena.com/res.php3?sSearch=${deviceInfo.model}`)}
          >
            <div className="basis-1/2 capitalize">{platform}</div>
            <div className="basis-1/2 capitalize">{deviceInfo.model}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DevicesInfo;
