import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import { StatementLine } from './StatementDoctorInfo';
import StatementSectionContainer from './StatementSectionContainer';

export function StatementCeilingCutLines({ generalInformation }) {
  const addUpAmounts = (sum, current) => sum + parseFloat(current.cutAmount);
  const ceilingCutAmountSum = generalInformation.ceilingCutLines.reduce(addUpAmounts, 0).toFixed(2);
  return generalInformation.ceilingCutLines.length > 0 ? (
    <StatementSectionContainer withDivider>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Coupure plafond: ', currencyFormat(ceilingCutAmountSum))}
          secondaryLines={generalInformation.ceilingCutLines.map((line) =>
            StatementLine(`${line.ceilingLabel}`, currencyFormat(line.cutAmount))
          )}
        />
      </Grid>
    </StatementSectionContainer>
  ) : null;
}

StatementCeilingCutLines.propTypes = {
  generalInformation: PropTypes.object.isRequired
};

export default StatementCeilingCutLines;
