import { onGuardCodes } from 'shared/ramq/onGuardCodes/constants';
import Act from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';

const NO_ERRORS = {};

export const guardCodeCannotBeBilledWithAPatientNamValidation = (act: Act) => {
  if (!act.nam) return NO_ERRORS;

  if ((act.codes || []).length === 0) return NO_ERRORS;

  const guardCodeInAct = act.codes.find(({ code }) => onGuardCodes.includes(code));

  if (!guardCodeInAct) return NO_ERRORS;

  return {
    [actFormFields.codes]: {
      _error: `Le code de garde ${guardCodeInAct.code} ne peut être facturé avec un numéro d'assurance maladie.`
    }
  };
};

export default guardCodeCannotBeBilledWithAPatientNamValidation;
