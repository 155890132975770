import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import { SingleLineField } from '../../../../components/Form/Fields';

export interface Props {}

export const Medavie: React.FunctionComponent<Props> = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
      <Typography gutterBottom variant="h6">
        Medavie
      </Typography>
    </Grid>

    <Grid item xs={12} sm={6}>
      <FormElement name="medavieUserName" label="Nom d'utilisateur" component={SingleLineField} />
    </Grid>

    <Grid item xs={12} sm={6}>
      <FormElement name="medaviePassword" label="Mot de passe" component={SingleLineField} />
    </Grid>
  </Grid>
);
