import useTranslate from 'application/hooks/use-translate';
import React, { useEffect, useMemo } from 'react';
import Chart from 'application/components/chart/chart';
import Period from 'shared/periods/common/Period';
import calculateORFromPeriod from 'shared/periods/common/calculateORFromActDate';
import { getBillingPeriodFromDate } from 'shared/periods/periods';
import moment from 'moment';
import Dropdown from 'application/components/dropdown';
import { ConfiguratorProps, RendererProps } from 'application/pages/administration/statistics';
import { useQuery } from '@tanstack/react-query';
import StatisticsService from 'application/services/statistics-service';

interface Params {
  selectedOr?: string;
}

interface BillingPeriodFromDate {
  act: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
}

const generatePeriodsFromDate = (date: Date, count: number) => {
  let currentDate = new Date(date.valueOf());
  const periodsWithOR: Array<{ period: Period; or: number }> = [];

  for (let i = 0; i < count; i++) {
    const billingPeriod = getBillingPeriodFromDate(currentDate) as BillingPeriodFromDate;
    const period = Period.fromMoment(billingPeriod.act.startDate, billingPeriod.act.endDate);
    const or = calculateORFromPeriod(period);

    const previousDate = moment(currentDate).subtract(2, 'weeks');
    currentDate = new Date(previousDate.valueOf());
    periodsWithOR.push({ period, or: or ?? 0 });
  }

  return periodsWithOR;
};

const Configurator = ({ params, onChange }: ConfiguratorProps<Params>) => {
  const translateReports = useTranslate('reports');
  const periodsWithOr = useMemo(() => generatePeriodsFromDate(new Date(), 6), []);

  useEffect(() => {
    if (params.selectedOr) return;

    onChange({
      selectedOr: periodsWithOr[0].or.toString()
    });
  }, [onChange, params.selectedOr, periodsWithOr]);

  if (!params.selectedOr) return null;

  return (
    <Dropdown
      className="flex"
      placeholder={translateReports('billing-period')}
      onChange={(selectedOr) => onChange({ selectedOr })}
      value={params.selectedOr}
      options={periodsWithOr.map((periodWithOr) => ({
        label: translateReports('billing-period-range', {
          start: moment(periodWithOr.period.getStart()).format('DD MMM. YYYY'),
          end: moment(periodWithOr.period.getEnd()).format('DD MMM. YYYY'),
          paymentNumber: `OR ${periodWithOr.or}`
        }),
        value: periodWithOr.or.toString()
      }))}
    />
  );
};

const Renderer = ({ params }: RendererProps<Params>) => {
  const translate = useTranslate('pages.administration.stats');
  const periodsWithOr = useMemo(() => generatePeriodsFromDate(new Date(), 6), []);

  const start = useMemo(
    () =>
      (
        periodsWithOr.find((periodWithOr) => periodWithOr.or.toString() === params.selectedOr) ?? periodsWithOr[0]
      )?.period
        .getStart()
        .toDate(),
    [params.selectedOr, periodsWithOr]
  );
  const end = useMemo(
    () =>
      (
        periodsWithOr.find((periodWithOr) => periodWithOr.or.toString() === params.selectedOr) ?? periodsWithOr[0]
      )?.period
        .getEnd()
        .toDate(),
    [params.selectedOr, periodsWithOr]
  );

  const { data, isFetching } = useQuery({
    queryKey: ['activities-by-user-statistics', start, end],
    queryFn: () => StatisticsService.getActivitiesByUser(start, end)
  });

  return (
    <Chart
      loading={isFetching}
      type="bar"
      title={translate('activities-by-status')}
      data={
        data?.map((row) => ({
          label: `${row.firstName} ${row.lastName}`,
          value: row.count
        })) ?? []
      }
    />
  );
};

export default {
  Configurator,
  Renderer
};
