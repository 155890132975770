import React, { useState } from 'react';
import { previewXml } from 'app/infrastructure/api/api';
import XmlEditorDialog from 'app/components/Dialogs/XMLEditorDialog/XMLEditorDialog';
import Activity from 'shared/core/activity/domain/Activity';
import LoadingButton from '../../../../../components/button/LoadingButton/LoadingButton';

interface Props {
  activity: Activity;
  onSave: (modifiedXml: string) => void;
}

export const ModifyXmlButton: React.FunctionComponent<Props> = ({ activity, onSave }) => {
  const { id: activityId, type } = activity;
  const [isLoading, setIsLoading] = useState(false);
  const [xml, setXml] = useState('');

  const handlePreviewXml = async () => {
    setIsLoading(true);
    const data = await previewXml(activityId, type);
    setXml(data.data);
    setIsLoading(false);
  };

  const handleTeardown = () => {
    setIsLoading(false);
    setXml('');
  };

  return (
    <>
      <XmlEditorDialog
        open={!!xml && !isLoading}
        onClose={handleTeardown}
        onSaveEditedXml={(modifiedXml) => {
          onSave(modifiedXml);
          handleTeardown();
        }}
        xml={xml}
      />
      <LoadingButton
        id="preview-xml"
        className=""
        label="Modifier le XML"
        disabled={false}
        loading={isLoading}
        onClick={handlePreviewXml}
      />
    </>
  );
};
