import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import SingleSelectCheckboxGroup from '../../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { GENDER_LIST } from '../../../../../components/Form/constants';
import { DateField, NamField } from '../../../../../components/Form/Fields';
import FormElement from '../../../../../components/Form/FormElement/FormElement';

export function NoNamBebeForm() {
  const translate = useTranslate();

  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormElement name="patientInformation.gender" component={SingleSelectCheckboxGroup} list={GENDER_LIST} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.birthDate"
            label={translate('no-nam.date-of-birth')}
            deletable
            component={DateField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name="patientInformation.respondent.nam"
            label={translate('no-nam.baby.mothers-hin')}
            fullWidth
            component={NamField}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default NoNamBebeForm;
