import ActivityType from '../shared/core/activity/domain/ActivityType';
import { LUMPSUM_CODE_LIST_NAME, MIXTE_CODE_LIST_NAME } from '../userCustomization/featureNames';
import { getFeatureConfig } from '../userCustomization/featureToggle';
import anestesiologistSpecialistMixteCodes from '../data/mixte/mixte-anesthesiologistes-codes.json';
import internistMixteCodes from '../data/mixte/mixte-internist-codes.json';
import generalSurgeonMixteCodes from '../data/mixte/mixte-general-surgeon-codes.json';
import anestesiologistSpecialistLumpSumCodes from '../data/lumpSum/lumpSum-specialist-codes.json';
import internistLumpSumCodes from '../data/lumpSum/lumpSum-internist-codes.json';
import pneumologistLumpSumCodes from '../data/lumpSum/lumpSum-pneumologist-codes.json';
import generalSurgeonLumpSumCodes from '../data/lumpSum/lumpSum-general-surgeon-codes.json';
import coordinatorLumpSumCodes from '../data/lumpSum/_lumpSum-coordinator-codes.json';
import LU238COVIDCodes from '../data/lumpSum/_lumpSum-lu238-covid-codes.json';
import LU241COVIDCodes from '../data/lumpSum/_lumpSum-lu241-covid-codes.json';
import UserUtils from './userUtils';

const codeListMapping = {
  'mixte-specialist-codes': anestesiologistSpecialistMixteCodes,
  'mixte-internist-codes': internistMixteCodes,
  'mixte-general-surgeon-codes': generalSurgeonMixteCodes,
  'lumpSum-specialist-codes': [...anestesiologistSpecialistLumpSumCodes, ...coordinatorLumpSumCodes],
  'lumpSum-internist-codes': [...internistLumpSumCodes, ...coordinatorLumpSumCodes],
  'lumpSum-pneumologist-codes': [...pneumologistLumpSumCodes, ...coordinatorLumpSumCodes],
  'lumpSum-general-surgeon-codes': [...generalSurgeonLumpSumCodes, ...coordinatorLumpSumCodes]
};

const getCodesAccordingToUserSpecialty = (activityType) => {
  const featureName = activityType === ActivityType.LUMP_SUM ? LUMPSUM_CODE_LIST_NAME : MIXTE_CODE_LIST_NAME;
  const fallback = `${activityType}-specialist-codes`;
  const userFeatureConfig = getFeatureConfig(featureName, fallback);

  if (activityType !== ActivityType.LUMP_SUM) {
    if (UserUtils.isAdminOrAgent()) {
      return codeListMapping[userFeatureConfig];
    }
    return getAllowedMixteCovidCodes(codeListMapping[userFeatureConfig]);
  }

  const codesAccordingToUserSpecialty = codeListMapping[userFeatureConfig];
  if (UserUtils.isAdminOrAgent()) {
    return [...LU241COVIDCodes, ...LU238COVIDCodes, ...codesAccordingToUserSpecialty];
  }
  const filteredCovidCodes = filterUnallowedCovidCodes([...codesAccordingToUserSpecialty]);
  return [...LU241COVIDCodes, ...filteredCovidCodes];
};

const allowedMixteCovidCodes = ['065356', '084356'];
const getAllowedMixteCovidCodes = (mixteCodes) =>
  mixteCodes.map((mixteCode) => {
    if (!["COVID (L/E 238 - jusqu'au 6 déc)"].includes(mixteCode.category)) {
      return mixteCode;
    }
    return {
      ...mixteCode,
      items: mixteCode.items.filter((code) => allowedMixteCovidCodes.includes(code.code))
    };
  });

const unallowedCovidCodes = ['xxx236', 'xxx241', 'xxx248', 'xxx253', 'xxx254'];
const filterUnallowedCovidCodes = (covidCodes) =>
  covidCodes.map((covidCode) => ({
    ...covidCode,
    items: covidCode.items.filter((code) => !unallowedCovidCodes.includes(code.code))
  }));

export default getCodesAccordingToUserSpecialty;
