import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { has, last, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  isPaymentExplanation,
  isPaymentOrModificationRequest,
  isSuccessExchange
} from '../../../../../shared/models/utils/activitiesUtils';
import {
  ACTIVITY_PAYMENT_EXPLANATION_LINE_TYPE,
  RAMQ_RESPONSE_OUTCOME_SUCCESS
} from '../../../../../shared/ramq/constants';
import RamqExplanationText from '../../../../components/Ramq/RamqExchange/ActivityResponse/ActResponseLine/RamqExplanationText';

export const NO_EXPLANATION_MESSAGE = 'Aucune explication disponible pour cet activité';

const getLatestRamqExchangeOfTypePaymentExplanation = (ramqExchanges) =>
  last(sortBy(ramqExchanges, 'statementProductionDate').filter(isPaymentExplanation));

const hasLines = (exchange) => has(exchange, 'lines');

const getLastRamqExchangeOfPaymentOrModificationType = (ramqExchanges) =>
  last(
    sortBy(ramqExchanges.filter(isSuccessExchange).filter(isPaymentOrModificationRequest).filter(hasLines), 'createdOn')
  );

const getPaymentExplanationLines = (ramqExchange) => {
  const lastRamqExplanation = getLatestRamqExchangeOfTypePaymentExplanation(ramqExchange);

  if (!lastRamqExplanation || !lastRamqExplanation.lines) {
    return [];
  }

  return lastRamqExplanation.lines.reduce((accumulatedExplanations, line) => {
    if (line.type === ACTIVITY_PAYMENT_EXPLANATION_LINE_TYPE && line.explanation !== undefined) {
      accumulatedExplanations.push(line.explanation);
    }
    return accumulatedExplanations;
  }, []);
};

const getLastPaymentExplanation = (ramqExchange = []) => {
  const noExplanationAvailable = [NO_EXPLANATION_MESSAGE];

  if (ramqExchange.length === 0) {
    return noExplanationAvailable;
  }

  const paymentExplanationLines = getPaymentExplanationLines(ramqExchange);

  if (paymentExplanationLines.length > 0) {
    return paymentExplanationLines;
  }

  const lastRamqPaymentOrModification = getLastRamqExchangeOfPaymentOrModificationType(ramqExchange);

  let requestExplanations = [];

  if (has(lastRamqPaymentOrModification, 'lines')) {
    requestExplanations = lastRamqPaymentOrModification.lines.reduce((accumulatedExplanations, line) => {
      if (line.outcome === RAMQ_RESPONSE_OUTCOME_SUCCESS && has(line, 'explanation')) {
        accumulatedExplanations.push(line.explanation);
      }
      return accumulatedExplanations;
    }, []);
  }

  if (requestExplanations.length === 0) {
    return noExplanationAvailable;
  }

  return requestExplanations;
};

export function RamqExchangeSummary({ act, className }) {
  const lastPaymentExplanations = getLastPaymentExplanation(act.ramqExchange);

  return (
    <List dense className={className}>
      {lastPaymentExplanations.map((explanation, index) => (
        <ListItem data-testid={`ramqExchangeSummary-item-${index}`} key={explanation}>
          <RamqExplanationText explanation={explanation} />
        </ListItem>
      ))}
    </List>
  );
}

RamqExchangeSummary.defaultProps = {
  className: undefined
};

RamqExchangeSummary.propTypes = {
  act: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default RamqExchangeSummary;
