import { StyledComponentProps, StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../../shared/periods/mixte/constants';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    textTransform: 'uppercase'
  },
  text: {
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('xs')]: {
    extraText: {
      fontSize: '12px'
    }
  },
  extraText: {
    fontWeight: 'bold',
    position: 'absolute',
    right: '50%',
    left: '50%',
    minWidth: '200px'
  },
  separator: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    fontWeight: 'bold'
  }
});

export interface Props extends StyledComponentProps {
  perdiems: string[];
  text?: string;
}

export const PerdiemsWithText: React.FunctionComponent<Props> = ({ perdiems, text, classes }: Props) => (
  <div className={classes!.root}>
    {perdiems.includes(AM_PERDIEM) && (
      <Typography data-testid="am-perdiem" className={classes!.text} variant="subtitle1">
        {AM_PERDIEM}
      </Typography>
    )}

    {perdiems.length > 1 && (
      <Typography data-testid="separator" variant="subtitle1" className={classes!.separator}>
        -
      </Typography>
    )}

    {perdiems.includes(PM_PERDIEM) && (
      <Typography data-testid="pm-perdiem" className={classes!.text} variant="subtitle1">
        {PM_PERDIEM}
      </Typography>
    )}

    {text && (
      <Typography data-testid="text" className={classes!.extraText} variant="subtitle1">
        {text}
      </Typography>
    )}
  </div>
);

export default withStyles(styles)(PerdiemsWithText);
