import { Chip, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { RAMQPlace } from 'shared/ramq/RamqPlaces';

interface OptionComponentProps {
  option: RAMQPlace;
  classes: {
    root: string;
    icon: string;
  };
}

const styles = (theme) => ({
  root: {
    padding: 0
  },
  icon: {
    marginRight: theme.spacing(1)
  }
});

const PlaceAutocompleteOption: React.FunctionComponent<OptionComponentProps> = ({
  option,
  classes
}: OptionComponentProps) => (
  <ListItemText
    primary={
      <ListItem className={classes.root}>
        <ListItemIcon className={classes.icon}>
          <Chip label={option.number} size="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1">{option.name}</Typography>
        </ListItemText>
      </ListItem>
    }
  />
);

export default withStyles(styles)(PlaceAutocompleteOption);
