import DayUnit from './LegacyDayUnit/DayUnit';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';
import NewStructureMixteConstraint from '../../../../../shared/domain/ramq/mixte/contraint/NewStructureMixteContraint';
import LatestMixteDayUnit from './LatestMixteDayUnit/LatestMixteDayUnit/LatestMixteDayUnit';

class DayUnitFactory {
  static create(activityType: ActivityType, date: number): React.ElementType {
    return NewStructureMixteConstraint.isSatisfiedBy(activityType, date) ? LatestMixteDayUnit : DayUnit;
  }
}

export default DayUnitFactory;
