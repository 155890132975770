import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { transformToCategorizableTree } from '../../../../shared/editable/domain/CategorizableCollectionTransformer';
import FavoriteItem from '../../../../containers/PlacesPreferencesDialog/FavoriteItem/FavoriteItem';
import CollapsableCategoryTree from '../../../../shared/category/ui/CollapsableCategoryTree/CollapsableCategoryTree';
import { editDiagnosticCodeFromUserPreferences, removeDiagnosticCodeFromUserPreferences } from '../../adapters/actions';
import FullScreenLoading from '../../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { selectDiagnosticCodesUpdatingFromUser } from '../../../../containers/User/selectors';

export const styles = (theme) => ({
  dialogContent: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  },
  dialogActions: {
    justifyContent: 'space-between'
  }
});

export function DiagnosticCodeFavoritesSelector({
  favoritesDiagnosticCodes,
  diagnosticCodesWithMarkup,
  onSelectDiagnostic,
  onRemoveDiagnostic,
  diagnosticCodesUpdatingFromUser,
  onClose,
  onAdvancedMode,
  classes
}) {
  return (
    <>
      <DialogContent className={classes.dialogContent}>
        {diagnosticCodesUpdatingFromUser ? (
          <FullScreenLoading />
        ) : (
          renderDiagnosticCodes(
            favoritesDiagnosticCodes,
            onSelectDiagnostic,
            onRemoveDiagnostic,
            diagnosticCodesWithMarkup
          )
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button id="advanced-mode-button" color="primary" onClick={onAdvancedMode}>
          <FormattedMessage id="editableCollectionWithCategories.advancedEditMode" />
        </Button>
        <Button id="close-Dialog" color="primary" onClick={onClose}>
          <FormattedMessage id="global.close" />
        </Button>
      </DialogActions>
    </>
  );
}

function renderDiagnosticCodes(
  favoritesDiagnosticCodes,
  onSelectDiagnostic,
  onRemoveDiagnostic,
  diagnosticCodesWithMarkup
) {
  const { itemsWithoutCategory, categories } = transformToCategorizableTree(favoritesDiagnosticCodes);

  return (
    <List dense>
      {itemsWithoutCategory
        .filter((item) => item !== undefined)
        .map(renderActCodeItem(onSelectDiagnostic, onRemoveDiagnostic, diagnosticCodesWithMarkup))}
      <CollapsableCategoryTree
        categories={categories}
        renderItem={renderActCodeItem(onSelectDiagnostic, onRemoveDiagnostic, diagnosticCodesWithMarkup)}
      />
    </List>
  );
}

function renderActCodeItem(onSelect, onRemove, diagnosticCodesWithMarkup) {
  return (item) => (
    <FavoriteItem
      key={item.code}
      chipNumber={item.code}
      withMarkup={diagnosticCodesWithMarkup.includes(item.code)}
      onItemSelect={() => onSelect(item)}
      onItemRemove={() => onRemove(item)}
      primaryText={<Typography>{item.description}</Typography>}
    />
  );
}

DiagnosticCodeFavoritesSelector.propTypes = {
  favoritesDiagnosticCodes: PropTypes.array.isRequired,
  diagnosticCodesWithMarkup: PropTypes.array.isRequired,
  diagnosticCodesUpdatingFromUser: PropTypes.bool.isRequired,
  onSelectDiagnostic: PropTypes.func.isRequired,
  onRemoveDiagnostic: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdvancedMode: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  diagnosticCodesUpdatingFromUser: selectDiagnosticCodesUpdatingFromUser()
});

export const mapDispatchToProps = (dispatch) => ({
  onRemoveDiagnostic: (code) => dispatch(removeDiagnosticCodeFromUserPreferences(code)),
  onEditDiagnostic: (code) => dispatch(editDiagnosticCodeFromUserPreferences(code))
});
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DiagnosticCodeFavoritesSelector);
