import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import pick from 'lodash/pick';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import useSearchRAMQPlaces from 'application/hooks/use-search-ramq-places';
import { RAMQPlace, SearchOptions } from 'shared/ramq/RamqPlaces';
import { useSelector } from 'react-redux';
import PlaceAutocompleteOption from './PlaceAutocompleteOption';
import { selectSelectedDate } from '../selectors';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },
  autocomplete: {
    flex: 1,
    marginRight: 20
  },
  button: {
    marginLeft: 10
  }
});

interface Props extends WrappedFieldProps {
  type?: 'physical' | 'geographical';
}

const PlaceAutocomplete = (props: Props) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const actDate = useSelector(selectSelectedDate());
  const searchOptions = useMemo(() => {
    if (!props.type) return;

    return { type: props.type, maxResults: 21 } as SearchOptions;
  }, [props.type]);
  const ramqPlaces = useSearchRAMQPlaces(inputValue.replace(',', ''), actDate, searchOptions);

  const { value } = props.input;

  useEffect(() => {
    if (!value) return;

    setInputValue(`${value.number} ${value.name}`);
  }, [value]);

  const onInputChange = (_event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };

  const onSelectedPlaceChange = (event, value: RAMQPlace | null) => {
    if (value) {
      props.input.onChange(pick(value, ['number', 'name']));

      onInputChange(event, `${value?.number} ${value?.name}`);
    } else {
      props.input.onChange(null);

      onInputChange(event, '');
    }
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        autoHighlight
        className={classes.autocomplete}
        clearOnBlur={false}
        defaultValue={value}
        filterOptions={(options) => options}
        getOptionLabel={(option: RAMQPlace) => option && `${option.number} ${option.name}`}
        id="place-autocomplete"
        inputValue={inputValue}
        ListboxProps={{ style: { overflowX: 'hidden' } }}
        loadingText={<FormattedMessage id="global.loading" />}
        noOptionsText={<FormattedMessage id="global.noResults" />}
        onChange={onSelectedPlaceChange}
        onInputChange={onInputChange}
        options={ramqPlaces}
        renderInput={(params) => (
          <TextField {...params} label={<FormattedMessage id="act-form.location" />} variant="standard" />
        )}
        renderOption={(option: RAMQPlace) => <PlaceAutocompleteOption option={option} />}
      />
    </div>
  );
};

export default PlaceAutocomplete;
