import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import styles from './styles';

export function CollapsableIcon({ onClick, selected, expanded, label, classes }) {
  return (
    <IconButton className={classes.iconContainer} classes={{ label: classes.iconButtonLabel }} onClick={onClick}>
      <div className={classes.indicatorContainer}>
        <Typography variant="caption" className={selected ? classes.checked : ''}>
          {label}
        </Typography>
        {selected && <CheckIcon className={classes.checked} />}
      </div>
      <div className={classNames(classes.expandIcon, { [classes.expandIconExpanded]: expanded })}>
        <ExpandMoreIcon />
      </div>
    </IconButton>
  );
}

CollapsableIcon.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  selected: PropTypes.bool
};

CollapsableIcon.defaultProps = {
  selected: false
};

export default compose(withStyles(styles))(CollapsableIcon);
