import { RAMQDiagnostics } from '../../../../shared/ramq/RamqDiagnostics';
import recursiveItemsCallback from '../../../../utils/recursive-items-callback';

export const GET_SPECIALTY_DIAGNOSTIC_CODES = 'getSpecialtyDiagnosticCodes';
export const getSpecialtyDiagnosticCodes = () => ({
  type: GET_SPECIALTY_DIAGNOSTIC_CODES
});

export const GET_SPECIALTY_DIAGNOSTIC_CODES_SUCCESS = 'getSpecialtyDiagnosticCodesSuccess';
export const getSpecialtyDiagnosticCodesSuccess = (codes) => ({
  type: GET_SPECIALTY_DIAGNOSTIC_CODES_SUCCESS,
  codes: recursiveItemsCallback(codes, (code) => ({
    ...code,
    ...(RAMQDiagnostics.get(code.code) || {}),
    ...(code.description && { description: code.description })
  }))
});

export const GET_SPECIALTY_DIAGNOSTIC_CODES_FAILURE = 'getSpecialtyDiagnosticCodesFailure';
export const getSpecialtyDiagnosticCodesFailure = (error) => ({
  type: GET_SPECIALTY_DIAGNOSTIC_CODES_FAILURE,
  error
});

export const ADD_DIAGNOSTIC_CODE = 'addDiagnosticCodeToUserPreferences';
export const addDiagnosticCodeToUserPreferences = (document) => ({
  type: ADD_DIAGNOSTIC_CODE,
  document
});

export const ADD_DIAGNOSTIC_CODE_FAILURE = 'addDiagnosticCodeFailure';
export const addDiagnosticCodeToUserPreferenceFailure = (error) => ({
  type: ADD_DIAGNOSTIC_CODE_FAILURE,
  error
});

export const REMOVE_DIAGNOSTIC_CODE = 'removeDiagnosticCodeFromUserPreferences';
export const removeDiagnosticCodeFromUserPreferences = (document) => ({
  type: REMOVE_DIAGNOSTIC_CODE,
  document
});

export const REMOVE_DIAGNOSTIC_CODE_FAILURE = 'removeDiagnosticCodeFailure';
export const removeDiagnosticCodeFromUserPreferenceFailure = (error) => ({
  type: REMOVE_DIAGNOSTIC_CODE_FAILURE,
  error
});

export const EDIT_DIAGNOSTIC_CODE = 'editDiagnosticCodeFromUserPreferences';
export const editDiagnosticCodeFromUserPreferences = (document) => ({
  type: EDIT_DIAGNOSTIC_CODE,
  document
});

export const EDIT_DIAGNOSTIC_CODE_FAILURE = 'editDiagnosticCodeFailure';
export const editDiagnosticCodeFromUserPreferenceFailure = (error) => ({
  type: EDIT_DIAGNOSTIC_CODE_FAILURE,
  error
});

export const UPDATE_DIAGNOSTIC_CODE_COLLECTION = 'updateDiagnosticCodesFromUserPreferences';
export const updateDiagnosticCodesFromUserPreferences = (documentMap) => ({
  type: UPDATE_DIAGNOSTIC_CODE_COLLECTION,
  documentMap
});

export const UPDATE_DIAGNOSTIC_CODE_COLLECTION_SUCCESS = 'updateDiagnosticCodesSuccess';
export const updateDiagnosticCodesFromUserPreferencesSuccess = () => ({
  type: UPDATE_DIAGNOSTIC_CODE_COLLECTION_SUCCESS
});

export const UPDATE_DIAGNOSTIC_CODE_COLLECTION_FAILURE = 'updateDiagnosticCodesFailure';
export const updateDiagnosticCodesFromUserPreferencesFailure = (error) => ({
  type: UPDATE_DIAGNOSTIC_CODE_COLLECTION_FAILURE,
  error
});

export const GET_DIAGNOSTIC_CODES_PREFERENCES = 'getDiagnosticCodesPreferences';
export const getDiagnosticCodesPreferences = () => ({
  type: GET_DIAGNOSTIC_CODES_PREFERENCES
});

export const GET_DIAGNOSTIC_CODES_PREFERENCES_SUCCESS = 'getDiagnosticCodesPreferencesSuccess';
export const getDiagnosticCodesPreferencesSuccess = (diagnosticCodes) => ({
  type: GET_DIAGNOSTIC_CODES_PREFERENCES_SUCCESS,
  diagnosticCodes: recursiveItemsCallback(diagnosticCodes, (diagnosticCode) => ({
    ...diagnosticCode,
    ...(RAMQDiagnostics.get(diagnosticCode.code) || {}),
    ...(diagnosticCode.description && { description: diagnosticCode.description })
  }))
});

export const GET_DIAGNOSTIC_CODES_PREFERENCES_FAILURE = 'getDiagnosticCodesPreferencesFailure';
export const getDiagnosticCodesPreferencesFailure = (error) => ({
  type: GET_DIAGNOSTIC_CODES_PREFERENCES_FAILURE,
  error
});
