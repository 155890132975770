import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Mixte from '../../../Mixte/Mixte';

function MixteRoute() {
  return (
    <Routes>
      <Route index element={<Mixte />} />
    </Routes>
  );
}

export default MixteRoute;
