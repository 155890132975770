import { get, uniq } from 'lodash';
import ActivityStatus from '../../core/activity/domain/ActivityStatus';
import {
  ACTIVITY_PAYMENT_EXPLANATION_TYPE,
  ACT_RESPONSE_CANCEL_REQUEST_TYPE,
  ACT_RESPONSE_MODIFICATION_REQUEST_TYPE,
  ACT_RESPONSE_PAYMENT_REQUEST_TYPE,
  RAMQ_RESPONSE_OUTCOME_ERROR,
  RAMQ_RESPONSE_OUTCOME_SUCCESS
} from '../../ramq/constants';

export const isBilled = (activity) => !!(activity.lastActiveBill && !isCanceledOrNa(activity));

export const isNotBilledNotCanceled = (activity) => !isBilled(activity) && !isCanceledOrNa(activity);

export const isCanceledOrNa = (activity) =>
  activity.status === ActivityStatus.CANCELED || activity.status === ActivityStatus.NO_STATUS;

export const getActiveBillsNumber = (ramqExchanges) => {
  if (!Array.isArray(ramqExchanges)) {
    return [];
  }

  const canceledBill = ramqExchanges.filter(isCancelExchange).map(getBillNumber);

  return uniq(
    ramqExchanges
      .filter(isSuccessExchange)
      .filter(hasABillNumber)
      .filter(isNotInCanceledBills(canceledBill))
      .map(getBillNumber)
  );
};

export const hasRamqExchange = (activity) => get(activity, 'ramqExchange', []).length > 0;
const isPaymentRequest = (ramqExchange) => ramqExchange.type === ACT_RESPONSE_PAYMENT_REQUEST_TYPE;
export const isModificationRequest = (ramqExchange) => ramqExchange.type === ACT_RESPONSE_MODIFICATION_REQUEST_TYPE;
const getBillNumber = (ramqExchange) => ramqExchange.billNumber;
const isNotInCanceledBills = (canceledBill) => (ramqExchange) => !canceledBill.includes(ramqExchange.billNumber);
const hasABillNumber = (ramqExchange) =>
  isPaymentOrModificationRequest(ramqExchange) || isPaymentExplanation(ramqExchange);
export const hasBillNumber = (billNumberToCompare) => (ramqExchange) => ramqExchange.billNumber === billNumberToCompare;
export const isPaymentExplanation = (ramqExchange) => ramqExchange.type === ACTIVITY_PAYMENT_EXPLANATION_TYPE;
export const isSuccessExchange = (ramqExchange) => ramqExchange.outcome === RAMQ_RESPONSE_OUTCOME_SUCCESS;
export const isCancelExchange = (ramqExchange) => ramqExchange.type === ACT_RESPONSE_CANCEL_REQUEST_TYPE;
export const isPaymentOrModificationRequest = (ramqExchange) =>
  isPaymentRequest(ramqExchange) || isModificationRequest(ramqExchange);
export const isNotErrorExchange = (ramqExchange) => ramqExchange.outcome !== RAMQ_RESPONSE_OUTCOME_ERROR;
