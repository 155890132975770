import { Chip, ListItem, ListItemIcon, ListItemText, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { DirectoryPhysician } from './DoctorAutocomplete';

interface OptionComponentProps {
  option: DirectoryPhysician;
  classes: {
    root: string;
    icon: string;
  };
}

const styles = (theme) => ({
  root: {
    padding: 0
  },
  icon: {
    marginRight: theme.spacing(1)
  }
});

const OptionComponent: React.FunctionComponent<OptionComponentProps> = ({ option, classes }: OptionComponentProps) => (
  <ListItemText
    primary={
      <ListItem className={classes.root}>
        <ListItemIcon className={classes.icon}>
          <Chip label={option.practiceNumber} size="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1">
            {option.lastName}, {option.firstName}
          </Typography>
        </ListItemText>
      </ListItem>
    }
    secondary={
      <>
        {option.facility && <Typography variant="body2">{option.facility}</Typography>}
        {option.specialties && (
          <Typography variant="caption">{option.specialties.map((specialty) => specialty.label).join(', ')}</Typography>
        )}
      </>
    }
  />
);

export default withStyles(styles)(OptionComponent);
