import moment from 'moment';
import { DefaultBillingType } from 'application/hooks/use-firestore-user-profile';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import BillingType from '../../../../shared/domain/billing/model/BillingType';
import { logDefault } from '../../../../shared/utils/plog';
import { DEFAULT_START_TIME } from '../../../../userCustomization/featureNames';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { activitiesCollectionRef } from '../../../firebase/collection/collectionReferences';
import { ACTIVITIES_ACT_TYPE } from '../../../../shared/collection/collectionConstants';
import {
  getActsInContextInTheSameDay,
  getLastBillingTypeUsedInContext,
  getLastPlaceUsedInContext,
  getLastPoolUsedInContext,
  getStartingPoint
} from '../../../codeSpecificLogic/ui/codeSpecificUILogicUtilities';

const getDefaultActBillingType = (userBillingType, lastBillingType, defaultBillingType) => {
  if (defaultBillingType && defaultBillingType !== DefaultBillingType.AUTO) {
    return defaultBillingType;
  }

  if (lastBillingType) {
    return lastBillingType;
  }
  return userBillingType === BillingType.POOL ? BillingType.POOL : BillingType.PRIV;
};

export default class CreateActUseCase {
  constructor(placePreferences, activitiesInContext, userBillingType, userDefaultBillingType) {
    this.placePreferences = placePreferences;
    this.activitiesInContext = activitiesInContext;
    this.userBillingType = userBillingType;
    this.defaultBillingType = userDefaultBillingType;
  }

  createWithDefaultProperties = (options) => {
    const { id } = activitiesCollectionRef().doc();
    const selectedDate = moment(options.date).startOf('day').valueOf() || moment().startOf('day').valueOf();
    const activitiesOnSameDay = getActsInContextInTheSameDay(this.activitiesInContext, selectedDate);
    const place = getLastPlaceUsedInContext(activitiesOnSameDay, this.placePreferences);
    const startingPoint = getStartingPoint(activitiesOnSameDay);
    const lastBillingType = getLastBillingTypeUsedInContext(activitiesOnSameDay);
    const billingType = getDefaultActBillingType(this.userBillingType, lastBillingType, this.defaultBillingType);
    const poolNumber = billingType === BillingType.POOL ? getLastPoolUsedInContext(activitiesOnSameDay) : undefined;

    let startTime = startingPoint ?? '';

    const defaultStartTimeForSpecialty = getFeatureConfig(DEFAULT_START_TIME, '');

    if (!startTime && defaultStartTimeForSpecialty) {
      startTime = moment(selectedDate).hours(defaultStartTimeForSpecialty).valueOf();
    }

    logDefault({
      type: 'newAct',
      text: 'Act/New',
      array: ['Creating new act with id', id]
    });

    return {
      id,
      billingType,
      ...(poolNumber && { poolNumber }),
      type: ACTIVITIES_ACT_TYPE,
      status: ActivityStatus.NO_STATUS,
      codes: [],
      nam: '',
      date: selectedDate,
      start: startTime,
      place,
      ...options
    };
  };
}
