import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ActivityStatus from '../../../../../shared/core/activity/domain/ActivityStatus';
import ActivityOutcomeResponsesGrouper from './ActivityOutcomeResponsesGrouper';
import getOutcomeResponseComponent from './Outcome/activityOutcomeComponentMapper';

export const styles = (theme) => ({
  actResponse: {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0
  },
  responseContainer: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(1),
    height: theme.spacing(1)
  }
});

const getComponentKey = (ramqResponse) =>
  ramqResponse.billNumber ? `${ramqResponse.billNumber}${ramqResponse.createdOn}` : `${ramqResponse.createdOn}`;

const renderActivityResponses = (ramqResponses, billLines, classes, collapseAll) => {
  const billingGroups = ActivityOutcomeResponsesGrouper.group(ramqResponses);

  return billingGroups.map((billingGroup, groupIndex) => (
    <Fragment key={billingGroup.billNumber}>
      {groupIndex > 0 && <Divider light className={classes.divider} />}
      {billingGroup.ramqResponses.map((ramqResponse, responseIndex) => {
        const ResponseComponent = getOutcomeResponseComponent(ramqResponse);

        return (
          <Paper className={classes.responseContainer} key={getComponentKey(ramqResponse)}>
            <ResponseComponent
              ramqResponse={ramqResponse}
              billLines={billLines}
              initiallyOpen={groupIndex === 0 && responseIndex === 0 && !collapseAll}
            />
          </Paper>
        );
      })}
    </Fragment>
  ));
};

function ActivityOutcomeResponses({ activity, classes }) {
  return (
    !isEmpty(activity.ramqExchange) && (
      <List className={classes.actResponse} dense>
        {renderActivityResponses(
          activity.ramqExchange,
          activity.computedBill?.billLines,
          classes,
          activity.status !== ActivityStatus.SENT
        )}
      </List>
    )
  );
}

ActivityOutcomeResponses.defaultProps = {
  activity: {
    ramqExchange: [],
    computedBill: { billLines: [] },
    status: ActivityStatus.NO_STATUS
  }
};

ActivityOutcomeResponses.propTypes = {
  activity: PropTypes.shape({
    ramqExchange: PropTypes.array,
    computedBill: PropTypes.object,
    status: PropTypes.string
  })
};

export default withStyles(styles)(ActivityOutcomeResponses);
