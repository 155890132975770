import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import TravelExpenseSectionHeader from './TravelExpenseSectionHeader';

const styles = (theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2)
  }
});

function FeeBox({ fields, fieldIndex, label, classes, children }) {
  return (
    <Paper className={classes.wrapper}>
      <TravelExpenseSectionHeader label={label} onDelete={() => fields.remove(fieldIndex)} />
      {children}
    </Paper>
  );
}

FeeBox.propTypes = {
  children: PropTypes.node.isRequired,
  fields: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(styles)(FeeBox);
