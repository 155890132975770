import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { closeMultiActDialog } from '../../../adapters/dialog/actions';
import { selectMultiActDialogState, selectMultiActProgressState } from '../../../adapters/dialog/selectors';
import { createActsFromPatients, createActsFromPatientsAndCreateMore } from '../../../adapters/acts/actions';
import DialogCenteredTitle from '../../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import MultiActForm from '../MultiActForm/MultiActForm';

export const styles = (theme) => ({
  primaryBackground: {
    backgroundColor: theme.palette.primary.main
  },
  dialogActionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(4)
  },
  flexColumnAlignCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  alignCamera: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4)
  },
  whiteText: {
    color: theme.palette.common.white
  },
  topLevel: {
    zIndex: 1299
  },
  noNamsMessage: {
    paddingTop: '40px'
  },
  dialogContent: {
    paddingLeft: '15px',
    paddingRight: '15px',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: '5px',
      paddingRight: 0
    }
  },
  dialogPaper: {
    [theme.breakpoints.up('md')]: {
      minHeight: '600px'
    }
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4),
    paddingTop: theme.spacing(4),
    flexDirection: 'column'
  },
  hidden: {
    display: 'none'
  }
});
export function MultiActDialog({
  fullScreen,
  open,
  onClose,
  classes,
  onCreateActs,
  onCreateActsAndCreateMore,
  progress
}) {
  return (
    <Dialog
      fullScreen={fullScreen}
      className={classes.topLevel}
      classes={{ paper: classes.dialogPaper }}
      fullWidth
      open={open}
      onClose={onClose}
    >
      <DialogCenteredTitle
        className={classes.primaryBackground}
        label={
          <Typography className={classes.whiteText}>
            <FormattedMessage id="multiNam.dialog.batchActCreation" />
          </Typography>
        }
      />
      <DialogContent className={classes.dialogContent}>
        {progress && (
          <div className={classes.progress}>
            <CircularProgress
              color="primary"
              size={80}
              variant="determinate"
              value={(progress.progress / progress.total) * 100}
            />
            <Typography variant="h5">
              {progress.progress} / {progress.total}
            </Typography>
            <Typography variant="subtitle1">Création de l'acte pour {progress.nam}&hellip;</Typography>
          </div>
        )}
        <div className={classNames({ [classes.hidden]: progress })}>
          <MultiActForm onSave={onCreateActs} onSaveAndCreateMore={onCreateActsAndCreateMore} onCancel={onClose} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

MultiActDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  progress: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onCreateActs: PropTypes.func.isRequired,
  onCreateActsAndCreateMore: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  open: selectMultiActDialogState(),
  progress: selectMultiActProgressState()
});

export const mapDispatchToProps = {
  onCreateActs: createActsFromPatients,
  onCreateActsAndCreateMore: createActsFromPatientsAndCreateMore,
  onClose: closeMultiActDialog
};

export default compose(
  withMobileDialog(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(MultiActDialog);
