import { put, select } from 'redux-saga/effects';
import VisionService from 'application/services/vision-service';
import { selectHasMultipleNAMDiscoveryEnabled, selectHasOCREnabled } from '../../../../../containers/User/selectors';
import { logDefault } from '../../../../../../shared/utils/plog';
import { addNAMsInMultiActForm, addExtractedNAMsInMultiActForm } from '../actions';

export default function* initiateMultipleNamOcr({ payload: attachment }) {
  const ocrEnabled = yield select(selectHasOCREnabled());
  const multipleNAMDiscoveryEnabled = yield select(selectHasMultipleNAMDiscoveryEnabled());
  if (!(ocrEnabled && multipleNAMDiscoveryEnabled)) {
    logDefault('OCR Disabled for user');
    return;
  }

  const base64Content = yield attachment.getDataAsBase64();
  const extractedNams = yield VisionService.fetchExtractedNams(base64Content);

  if (extractedNams && extractedNams.length > 0) {
    logDefault(`Displaying all extracted nams : ${extractedNams}`);

    yield put(addNAMsInMultiActForm(extractedNams));
    yield put(addExtractedNAMsInMultiActForm(extractedNams));
  }
}
