import { isUserAtLeast } from 'application/utilities/authentication';
import React from 'react';
import { useSelector } from 'react-redux';
import Role from 'shared/domain/authentication/Role';

interface Props {
  atLeastRole?: Role;
  isRole?: Role;
  isAllowed?: () => boolean;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface ReduxState {
  authentication: {
    user: {
      role: Role;
    };
  };
}

const Restrict = ({ atLeastRole, isRole, isAllowed, children, fallback = null }: Props) => {
  if (!atLeastRole && !isRole && !isAllowed) {
    throw new Error('You must provide at least one of the following props: atLeastRole, isRole, isAllowed');
  }

  const userRole = useSelector<ReduxState, Role>((state) => state.authentication.user.role);

  if (!userRole) return fallback;
  if (isRole && userRole !== isRole && userRole !== Role.Developer) return fallback;
  if (atLeastRole && !isUserAtLeast(userRole, atLeastRole)) return fallback;
  if (isAllowed && !isAllowed()) return fallback;

  if (userRole !== Role.Developer) return children;

  return <span className={isRole === Role.Doctor ? 'restricted-doctor' : 'restricted-dev'}>{children}</span>;
};

export default Restrict;
