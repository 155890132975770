import { get, set } from 'lodash';
import { GEOGRAPHICAL_LOCATION_TYPE, PHYSICAL_LOCATION_TYPE } from '../../../shared/ramq/location/constants';
import isDefined from '../../../shared/utils/isDefined';

const validateGeographicalLocation = (travelExpenses, locationPropertyName, errorMap) => {
  const location = get(travelExpenses, `travelInformation.${locationPropertyName}`, {});
  if (location.type === GEOGRAPHICAL_LOCATION_TYPE) {
    if (!isDefined(location.number) && !isDefined(location.zipCode)) {
      set(errorMap, `travelInformation.${locationPropertyName}`, 'Veuillez indiquer le code postal');
    }
  }
};

const validatePhysicalLocation = (travelExpenses, locationPropertyName, errorMap) => {
  const location = get(travelExpenses, `travelInformation.${locationPropertyName}`, {});
  if (location.type === PHYSICAL_LOCATION_TYPE) {
    if (!isDefined(location.number)) {
      set(errorMap, `travelInformation.${locationPropertyName}`, 'Veuillez sélectionner le lieu');
    }
  }
};

const validateNonEmptyLocation = (travelExpenses, locationPropertyName, errorMap) => {
  const location = get(travelExpenses, `travelInformation.${locationPropertyName}`, {});
  if (!isDefined(location.type)) {
    set(errorMap, `travelInformation.${locationPropertyName}`, 'Veuillez sélectionner le lieu');
  }
};

const travelInformationLocationValidation = (travelExpenses) => {
  const errorMap = {};
  validateGeographicalLocation(travelExpenses, 'startLocation', errorMap);
  validateGeographicalLocation(travelExpenses, 'endLocation', errorMap);
  validatePhysicalLocation(travelExpenses, 'startLocation', errorMap);
  validatePhysicalLocation(travelExpenses, 'endLocation', errorMap);
  validateNonEmptyLocation(travelExpenses, 'startLocation', errorMap);
  validateNonEmptyLocation(travelExpenses, 'endLocation', errorMap);
  return errorMap;
};

export default travelInformationLocationValidation;
