import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectMaxSearchReached } from './selectors';

export const styles = () => ({
  indicatorText: {
    textAlign: 'center'
  }
});

export function MaxSearchReachedIndicator({ maxSearchReached, hitsCount, maxSearchNumber, classes }) {
  const maxReached = hitsCount > maxSearchNumber || maxSearchReached;
  return maxReached ? (
    <Typography variant="caption" className={classes.indicatorText}>
      {`Affichage des ${maxSearchNumber} premiers résultats. Ajoutez des mots clés pour raffiner votre
      recherche.`}
    </Typography>
  ) : (
    <span />
  );
}

MaxSearchReachedIndicator.propTypes = {
  maxSearchReached: PropTypes.bool.isRequired,
  maxSearchNumber: PropTypes.number.isRequired,
  hitsCount: PropTypes.number
};

export const mapStateToProps = createStructuredSelector({
  maxSearchReached: selectMaxSearchReached()
});

export default compose(withStyles(styles), connect(mapStateToProps, null))(MaxSearchReachedIndicator);
