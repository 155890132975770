import get from 'lodash/get';
import { OUTSIDE_RAMQ_PATIENT_TYPE } from '../../../../shared/ramq/domainValues/situationTypes';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';
import { isSpecialtyAnesthesiologist } from '../../../../shared/domain/doctor/Doctor';
import { OutsideRamqPatientType } from '../../../../shared/domain/activity/act/model/Act';

class OutsideRamqFields {
  private static readonly PATIENT_TYPES_REQUIRING_DIAGNOSTIC = [
    OutsideRamqPatientType.OUTSIDE_RAMQ,
    OutsideRamqPatientType.PRIVATE_INSURANCE,
    OutsideRamqPatientType.ABROAD_STUDENT
  ];

  private static readonly PATIENT_TYPES_REQUIRING_DATE_HOSPITALISATION = [OutsideRamqPatientType.PRIVATE_INSURANCE];

  public static isDoctorRequired(patientInformation: object, userProfile: { specialty: SpecialtyName }): boolean {
    const patientIsOutsideRamq = get(patientInformation, 'type', undefined) === OUTSIDE_RAMQ_PATIENT_TYPE;
    return patientIsOutsideRamq && isSpecialtyAnesthesiologist(userProfile.specialty);
  }

  public static isFreeTextDiagnosticFieldRequired(userProfile: { specialty: SpecialtyName }): boolean {
    return isSpecialtyAnesthesiologist(userProfile.specialty);
  }

  public static isFreeTextDiagnosticFieldPrompted(patientInformation: object): boolean {
    if (get(patientInformation, 'type', undefined) !== OUTSIDE_RAMQ_PATIENT_TYPE) return false;

    const patientType = get(patientInformation, 'outsideRamq.patientType', undefined) as
      | OutsideRamqPatientType
      | undefined;
    if (!patientType) {
      return false;
    }

    return this.PATIENT_TYPES_REQUIRING_DIAGNOSTIC.includes(patientType);
  }

  public static isDateHospitalisationFieldRequired(patientInformation: object): boolean {
    if (get(patientInformation, 'type', undefined) !== OUTSIDE_RAMQ_PATIENT_TYPE) return false;

    const patientType = get(patientInformation, 'outsideRamq.patientType', undefined) as
      | OutsideRamqPatientType
      | undefined;
    if (!patientType) {
      return false;
    }

    return this.PATIENT_TYPES_REQUIRING_DATE_HOSPITALISATION.includes(patientType);
  }
}

export default OutsideRamqFields;
