import { take } from 'lodash';
import { codeIsAnHalfDayDuringCovid } from '../partialRate/partialRateUtils';
import DefaultTimeSlotCalculator from './timeSlotCalculators/DefaultTimeSlotCalculator';

export default class DayTimeSlotSplitter {
  constructor(strategy = new DefaultTimeSlotCalculator()) {
    this.splitStrategy = strategy;
  }

  setStrategy(strategy) {
    this.splitStrategy = strategy;
  }

  getStrategy() {
    return this.splitStrategy;
  }

  split(day) {
    let timeSlotSpan = this.getStrategy().calculate(day);

    // According to MED-764, a MD can only bill a
    // maximum of 2 slots per day for these codes
    if (codeIsAnHalfDayDuringCovid(day.code)) {
      timeSlotSpan = take(timeSlotSpan, 2);
    }

    return timeSlotSpan;
  }
}
