import { Deploy } from 'cordova-plugin-ionic';
import { ISnapshotInfo } from 'cordova-plugin-ionic/dist/IonicCordova';
import { useEffect, useState } from 'react';

export default function useIonicVersion(): [ISnapshotInfo | null, (channel) => void] {
  const [version, setVersion] = useState<ISnapshotInfo | null>(null);

  useEffect(() => {
    Deploy.getCurrentVersion().then((version) => {
      if (!version) return;
      setVersion(version);
    });
  }, []);

  const switchChannel = (channel: string | undefined) => {
    if (!channel) return;

    Deploy.configure({ channel }).then(() => {
      Deploy.sync({}).then((version) => {
        if (!version) return;

        Deploy.reloadApp().then((value) => {
          if (!value) return;
          setVersion(version);
        });
      });
    });
  };

  return [version, switchChannel];
}
