import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { StyleRulesCallback, Theme, WithStyles, withStyles } from '@material-ui/core';
import CodeSuggestion from '../../../../../components/List/Item/CodeSuggestion/CodeSuggestion';
import CollapsableCategoryTree from '../../../../../shared/category/ui/CollapsableCategoryTree/CollapsableCategoryTree';
import Category from '../../../domain/Category';
import CategoryItem from '../../../domain/CategoryItem';
import EditItemDialog from '../../../../diagnosticCode/ui/EditItemDialog/EditItemDialog';

export const styles: StyleRulesCallback<Theme, Props> = (theme: Theme) => ({
  dialogContent: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  }
});

export interface Props extends WithStyles {
  data: Category<CategoryItem>[];
  /* eslint-disable no-unused-vars */
  isInFavorites: (item: CategoryItem) => boolean;
  isSelected?: (item: CategoryItem) => boolean;
  onAddToFavorites: (item: CategoryItem) => void;
  onItemSelected: (item: CategoryItem) => void;
  /* eslint-enable */
  onConfirm?: () => void;
  onCancel: () => void;
}

interface State {
  selectedElement?: CategoryItem;
  editDialogOpen: boolean;
}

export class PresetsTab extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      editDialogOpen: false
    };

    this.handleOnFavoritesClick = this.handleOnFavoritesClick.bind(this);
    this.handleOnSuggestionClick = this.handleOnSuggestionClick.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.updateSelectedElementDescription = this.updateSelectedElementDescription.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  handleOnFavoritesClick(item: CategoryItem) {
    if (!this.props.isInFavorites(item)) {
      this.openEditDialog(item);
    }
  }

  handleOnSuggestionClick(item: CategoryItem) {
    const { onItemSelected } = this.props;
    onItemSelected(item);
  }

  openEditDialog(item: CategoryItem) {
    this.setState({
      editDialogOpen: true,
      selectedElement: item
    });
  }

  closeEditDialog() {
    this.setState({
      editDialogOpen: false,
      selectedElement: undefined
    });
  }

  updateSelectedElementDescription(newDescription: string) {
    this.props.onAddToFavorites({
      ...this.state.selectedElement!,
      description: newDescription
    });

    this.closeEditDialog();
  }

  renderItem(item: CategoryItem) {
    const { isSelected = () => false } = this.props;

    return (
      <CodeSuggestion
        key={item.code}
        suggestion={item}
        isSelected={isSelected(item)}
        onSuggestionClick={this.handleOnSuggestionClick}
        onFavoriteClick={this.handleOnFavoritesClick}
        isInFavorites={this.props.isInFavorites(item)}
      />
    );
  }

  render() {
    const { data, onConfirm, onCancel, classes } = this.props;
    const { selectedElement, editDialogOpen } = this.state;

    return (
      <>
        <DialogContent className={classes.dialogContent}>
          <CollapsableCategoryTree initiallyOpen={false} categories={data} renderItem={this.renderItem} />
          {selectedElement && (
            <EditItemDialog
              key={selectedElement!.code}
              open={editDialogOpen}
              mode="append"
              onSave={this.updateSelectedElementDescription}
              onCancel={this.closeEditDialog}
              diagnostic={selectedElement}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button id="close-Dialog" color="primary" onClick={onCancel}>
            <FormattedMessage id="global.cancel" />
          </Button>
          {onConfirm && (
            <Button id="confirm-Dialog" onClick={onConfirm} color="primary">
              <FormattedMessage id="global.ok" />
            </Button>
          )}
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(PresetsTab) as typeof React.Component;
