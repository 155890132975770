import Act from 'shared/domain/activity/act/model/Act';
import {
  isCodeThatAllowSyntheticPlateSupplementPresent,
  isSyntheticPlateSupplementCodePresent,
  SYNTHETIC_PLATE_SUPPLEMENT_CODE,
  CODES_THAT_ALLOW_SYNTHETIC_PLATE_SUPPLEMENT
} from 'app/act/recommendations/syntheticPlateSupplement/addSyntheticPlateSupplement';

const syntheticPlateSupplementValidation = (act: Act) => {
  if (isSyntheticPlateSupplementCodePresent(act.codes) && !isCodeThatAllowSyntheticPlateSupplementPresent(act.codes)) {
    return {
      codes: {
        _error: `Le code ${SYNTHETIC_PLATE_SUPPLEMENT_CODE} ne peut être facturé qu'avec l'un ou plusieurs des codes suivants :  ${CODES_THAT_ALLOW_SYNTHETIC_PLATE_SUPPLEMENT}.`
      }
    };
  }
  return {};
};

export default syntheticPlateSupplementValidation;
