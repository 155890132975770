import React from 'react';
import PropTypes from 'prop-types';
import CollapsableCategory from '../../../../shared/category/ui/CollapsableCategory/CollapsableCategory';
import DoctorFavoriteListItem from '../DoctorFavoriteListItem/DoctorFavoriteListItem';

export const ItemComponentBuilder = (onEdit, onDelete) => {
  function ItemComponent({ item, id }) {
    return <DoctorFavoriteListItem doctor={item} key={id} onEdit={() => onEdit(item)} onDelete={() => onDelete(id)} />;
  }

  ItemComponent.propTypes = {
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired
  };

  return ItemComponent;
};

export const ContainerWrapperComponentBuilder = (onEdit, onDelete) => {
  function ContainerWrapperComponent({ id, label, level, children }) {
    return (
      <CollapsableCategory
        onEdit={() => onEdit(id, { category: label })}
        onDelete={() => onDelete(id)}
        label={label}
        level={level}
      >
        {children}
      </CollapsableCategory>
    );
  }

  ContainerWrapperComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired
  };

  return ContainerWrapperComponent;
};
