import { assign } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../containers/ActForm/messages';

const getContextElementsTranslation = (contextElements) =>
  contextElements.map((contextElement) =>
    assign(contextElement, {
      label: <FormattedMessage {...messages[contextElement.key]} />
    })
  );

export default getContextElementsTranslation;
