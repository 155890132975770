import LUMPSUM_DISPENSARY_AREA_MAP from '../../../../shared/ramq/domainValues/lumpsumDispensaryAreas';

export const generalCareLumpsum = {
  label: 'HOSPIT',
  value: LUMPSUM_DISPENSARY_AREA_MAP.generalCare
};
export const externalClinicLumpsum = {
  label: 'EXTERNE',
  value: LUMPSUM_DISPENSARY_AREA_MAP.externalClinic
};
export const remoteLumpsum = {
  label: 'À Distance',
  value: LUMPSUM_DISPENSARY_AREA_MAP.remote,
  aliases: [LUMPSUM_DISPENSARY_AREA_MAP.remote, LUMPSUM_DISPENSARY_AREA_MAP.remoteOverride]
};
export const painLumpsum = {
  label: 'Douleur',
  value: LUMPSUM_DISPENSARY_AREA_MAP.pain
};

const dispensaryAreaMap: any = [externalClinicLumpsum, generalCareLumpsum];
export const adminDispensaryAreaMap: any = [externalClinicLumpsum, generalCareLumpsum, painLumpsum, remoteLumpsum];

export default dispensaryAreaMap;
