import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import BREAST_RECONSTRUCTION from '../../../../shared/ramq/requiredDetails/breastReconstruction';

const associatedFields: AssociatedField[] = [
  {
    label: 'Reconstr. mamm. même nature incision diff. ?',
    getName: (index) => `codes[${index}].${BREAST_RECONSTRUCTION}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    notRequired: true
  }
];

const breastReconstruction: RequiredDetail = {
  associatedFields
};

export default breastReconstruction;
