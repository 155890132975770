const EMAIL_VALIDATION_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const isEmailValid = (value: string | undefined): boolean => !!value && EMAIL_VALIDATION_REGEX.test(value);
const validateEmailFormat = (value: string | undefined) => {
  if (!isEmailValid(value)) {
    if (value?.includes(' ')) {
      return 'L’adresse courriel ne doit pas contenir d’espace';
    }
    return 'L’adresse courriel doit être valide';
  }
  return undefined;
};

export default validateEmailFormat;
