/* eslint-disable no-shadow, no-unused-vars */
export enum InterventionSide {
  BILATERAL = 'bilateral',
  LEFT = 'left',
  RIGHT = 'right'
}

export const isInterventionSide = (value: any): value is InterventionSide =>
  Object.values(InterventionSide).includes(value);

export default { InterventionSide };
