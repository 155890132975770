import { SEARCH_ACTIVITIES_LIMIT } from '../ActivitiesSearch/withSearchLimit';
import {
  GET_ACTIVITIES,
  GET_ACTIVITIES_ERROR,
  GET_ACTIVITIES_SUCCESS,
  TOGGLE_DETAILED_VIEW,
  MORE_ACTIVITIES,
  MORE_ACTIVITIES_SUCCESS,
  SAVE_DUPLICATION_INFO,
  GET_MORE_ACTIVITIES_SUCCES,
  GET_MORE_ACTIVITIES_ERROR,
  LOAD_MORE_ACTIVITIES
} from './actions';

export const initialState = {
  activities: [],
  filters: {},
  options: {},
  detailedView: false,
  loading: true,
  currentPage: 0,
  nbHits: 0
};

function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITIES:
      return {
        ...state,
        currentPage: 0,
        nbHits: 0,
        activities: [],
        filters: action.filters,
        options: action.options,
        loading: true,
        hasMore: false,
        error: false
      };
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasMore: action.activities.length === SEARCH_ACTIVITIES_LIMIT,
        activities: action.activities,
        nbHits: action.nbHits
      };
    case MORE_ACTIVITIES:
      return {
        ...state,
        moreLoading: true,
        error: false
      };
    case LOAD_MORE_ACTIVITIES:
      return {
        ...state,
        loadingMore: true,
        error: false,
        hasMore: false
      };
    case MORE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        moreLoading: false,
        currentPage: state.currentPage + 1,
        activities: state.activities.concat(action.activities)
      };
    case GET_ACTIVITIES_ERROR:
    case GET_MORE_ACTIVITIES_ERROR:
      return {
        ...state,
        loading: false,
        hasMore: false,
        error: action.error
      };
    case TOGGLE_DETAILED_VIEW:
      return {
        ...state,
        detailedView: !state.detailedView
      };
    case SAVE_DUPLICATION_INFO:
      return {
        ...state,
        duplicationData: {
          ...state.duplicationData,
          [action.activityType]: action.duplicationData
        }
      };
    case GET_MORE_ACTIVITIES_SUCCES:
      return {
        ...state,
        loading: false,
        loadingMore: false,
        hasMore: action.activities.length === SEARCH_ACTIVITIES_LIMIT,
        activities: state.activities.concat(action.activities)
      };
    default:
      return state;
  }
}

export default activitiesReducer;
