import PropTypes from 'prop-types';
import React from 'react';
import DialogTrigger from '../../../../../components/Dialogs/DialogTrigger/DialogTrigger';
import RadioListDialog from '../../../../../components/Dialogs/RadioListDialog/RadioListDialog';
import LoadingButton from '../../../../../components/button/LoadingButton/LoadingButton';

const renderButton = (disabled, loading, onClick) => (
  <LoadingButton label="Ramq Payer" disabled={disabled} loading={loading} onClick={onClick} />
);

function RamqBillButton({ disabled, loading, onBillRamq, activeBills }) {
  return activeBills.length > 1 ? (
    <DialogTrigger
      DialogComponent={RadioListDialog}
      dialogProps={{
        items: activeBills,
        title: 'RAMQ Payer - Sélectionnez une facture'
      }}
      onItemSelected={(selectedBillNumber) => onBillRamq({ billNumber: selectedBillNumber })}
    >
      {renderButton(disabled, loading, () => ({}))}
    </DialogTrigger>
  ) : (
    renderButton(disabled, loading, () => onBillRamq())
  );
}

RamqBillButton.propTypes = {
  activeBills: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onBillRamq: PropTypes.func.isRequired
};

RamqBillButton.defaultProps = {
  disabled: false
};

export default RamqBillButton;
