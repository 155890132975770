/* eslint-disable no-shadow, no-unused-vars */
export enum AbsenceReason {
  FELLOWSHIP = 'fellowship',
  MATERNITY = 'maternity',
  SICKNESS = 'sickness',
  HOLIDAYS = 'holidays',
  OTHER = 'other'
}

interface Absence {
  id: string;
  startDate: number;
  endDate: number;
  reason: AbsenceReason;
  comments: string;
}

export default Absence;
