enum AlertSeverity {
  DEFAULT = 'default',
  ERROR = 'error',
  INFO = 'info',
  NONE = 'none',
  SUCCESS = 'success',
  WARNING = 'warning',
  RECOMMENDATION = 'recommendation'
}

export default AlertSeverity;
