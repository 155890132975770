import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import PlacesDialog from '../../../containers/PlacesPreferencesDialog/PlacesDialog';
import DialogTrigger from '../../Dialogs/DialogTrigger/DialogTrigger';
import { selectPlacesPreferenceFromUser } from '../../../containers/User/selectors';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 16
  },
  text: {
    marginBottom: theme.spacing(2)
  }
});

function EmptyPlacePreferences({ text, placesPreferences, children, classes }) {
  const navigate = useNavigate();

  return placesPreferences.length === 0 ? (
    <div className={classes.container}>
      <Typography variant="body2" align="center" className={classes.text}>
        {text}
      </Typography>
      <DialogTrigger
        DialogComponent={PlacesDialog}
        onItemSelected={() => ({})}
        dialogProps={{
          createNewRouteFromPlace: (location, placeNumber) =>
            navigate({ pathname: `${location.pathname}/${placeNumber}`, search: location.search })
        }}
      >
        <Button variant="contained">
          <FormattedMessage id="emptyPlacePreferences.addLocation" />
        </Button>
      </DialogTrigger>
    </div>
  ) : (
    children
  );
}

export const EmptyPlacePreferencesWithStyle = withStyles(styles)(EmptyPlacePreferences);

EmptyPlacePreferences.propTypes = {
  text: PropTypes.node.isRequired,
  placesPreferences: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired
};

export const mapStateToProps = createStructuredSelector({
  placesPreferences: selectPlacesPreferenceFromUser()
});

export default compose(connect(mapStateToProps))(EmptyPlacePreferencesWithStyle);
