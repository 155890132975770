import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import TRANSPORT_TYPES from '../../../../shared/ramq/domainValues/transportTypes';
import TravelInformationBoxSelectValue from './TravelInformationBoxSelectValue';
import TravelInformationBoxSingleValue from './TravelInformationBoxSingleValue';

function TravelTimeFeeSummary({ fee }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography align="center" variant="button" style={{ marginBottom: 8 }}>
        Temps
      </Typography>
      <Grid container>
        <TravelInformationBoxSelectValue label="Type de transport" map={TRANSPORT_TYPES} value={fee.transportType} />
        <TravelInformationBoxSingleValue label="Nb. Kilomètres aller" value={fee.goingKilometers} />
        <TravelInformationBoxSingleValue label="Nb. Kilomètres retour" value={fee.returnKilometers} />
        <TravelInformationBoxSingleValue label="Nb. heures déplacement" value={fee.travelingHours} />
        <TravelInformationBoxSingleValue label="Nb. heure attente" value={fee.waitingHours} />
      </Grid>
    </Grid>
  );
}

TravelTimeFeeSummary.propTypes = {
  fee: PropTypes.object.isRequired
};

export default TravelTimeFeeSummary;
