import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { updateEndTime } from '../../actions';
import { selectEndTime, selectStartTime } from '../../selectors';

const FIFTEEN_MINUTES = 900000;
const TWENTY_MINUTES = 1200000;
const FEBRUARY_1ST_2023 = moment('2023-02-01', 'YYYY-MM-DD');

export class AdjustEndTime extends React.Component {
  constructor(props) {
    super(props);
    const { startTime, endTime } = props;
    if (startTime && !endTime) {
      if (moment(startTime).isBefore(FEBRUARY_1ST_2023)) {
        props.updateEndTime(startTime + TWENTY_MINUTES);
      } else {
        props.updateEndTime(startTime + FIFTEEN_MINUTES);
      }
    }
  }

  render() {
    return null;
  }
}

AdjustEndTime.defaultProps = {
  startTime: '',
  endTime: ''
};

AdjustEndTime.propTypes = {
  startTime: PropTypes.node,
  endTime: PropTypes.node,
  updateEndTime: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  startTime: selectStartTime(),
  endTime: selectEndTime()
});

export const mapDispatchToProps = (dispatch) => ({
  updateEndTime: (time) => {
    dispatch(updateEndTime(time));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdjustEndTime);
