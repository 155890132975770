import React from 'react';

import lobbyPlaceholderImage from 'images/lobby-placeholder.png';
import medyxLogoImage from 'images/lobby-logo.svg';
import useTranslate from 'application/hooks/use-translate';
import Icon from 'application/components/icon';
import LanguageSwitcher from 'application/components/language-switcher';

interface Props {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
}

const LobbyLayout = ({ title, subtitle, children }: Props) => {
  const translate = useTranslate('pages.lobby.signup');

  return (
    <div className="min-h-screen bg-gray-50 bg-fixed bg-right-top bg-no-repeat pb-20 xl:bg-lobby-background xl:pb-14">
      <div className="px-4 sm:px-14 xl:w-[calc(100vw-750px)] xl:pr-0">
        <img className="mx-auto py-6 sm:py-8 xl:py-16" src={medyxLogoImage} alt="Medyx" />

        <div className="flex flex-col gap-2 text-center font-bold">
          {title && <h1 className="text-2xl text-primary-900">{title}</h1>}
          {subtitle && <p className="text-sm text-neutral-500">{subtitle}</p>}
        </div>

        <div className="mt-6 xl:mt-12">{children}</div>

        <div className="mt-4 flex justify-center gap-2">
          <div className="flex items-center gap-1">
            <Icon icon="copyright" />
            <div>{translate('company')}</div>
            <div>{new Date().getFullYear()}</div>
          </div>
          <div>|</div>
          <LanguageSwitcher />
        </div>
      </div>

      <img
        className="fixed right-[60px] top-[60px] hidden h-[830px] rounded-3xl xl:block"
        src={lobbyPlaceholderImage}
        alt=""
      />
    </div>
  );
};

export default LobbyLayout;
