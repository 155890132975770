import React, { useCallback, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { Card, CardContent, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import withStyles, { StyleRulesCallback, WithStyles } from '@material-ui/core/styles/withStyles';
import InputLabel from '@material-ui/core/InputLabel';
import RamqContextElementsField from '../RamqContextElements/RamqContextElementsField';
import ContextElement from '../../../../favorite/contextElement/domain/ContextElement';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  contextElementCardContent: {
    padding: `${theme.spacing(1)}px`
  },
  contextElementLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block'
  },
  contextElementChip: {
    marginBottom: `${theme.spacing(1)}px`,
    width: '100%'
  }
});

export interface Props extends WithStyles<typeof styles>, WrappedFieldProps {
  label?: string;
}

export const ContextElementsRequiredDetail: React.FunctionComponent<Props> = ({ input, label, meta, classes }) => {
  const ramqContextElements = useMemo((): ContextElement[] => {
    const ramqContextElementsInputValue = input.value;

    if (ramqContextElementsInputValue === '') {
      return [];
    }

    return ramqContextElementsInputValue;
  }, [input]);

  const handleCodeDelete = useCallback(
    (index) => {
      const ramqContextElementsInputValue = [...ramqContextElements];

      ramqContextElementsInputValue.splice(index, 1);

      input.onChange(ramqContextElementsInputValue);
    },
    [ramqContextElements, input]
  );

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <RamqContextElementsField
        buttonRender={(onClick) => (
          <Button data-testid="add-more-context-elements-button" style={{ margin: 'auto' }} onClick={onClick}>
            <AddIcon />
          </Button>
        )}
        meta={meta}
        input={input}
      />

      {ramqContextElements.length > 0 && (
        <div data-testid="context-elements-list">
          <Card>
            <CardContent className={classes.contextElementCardContent}>
              {ramqContextElements.map(({ code, description }, index) => (
                <Chip
                  onDelete={() => handleCodeDelete(index)}
                  label={`(${code}) ${description}`}
                  key={code}
                  classes={{ label: classes.contextElementLabel }}
                  data-testid="context-element-chip"
                  className={classes.contextElementChip}
                />
              ))}
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(ContextElementsRequiredDetail);
