import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { withStyles, WithStyles } from '@material-ui/core';
import CategoryItem from '../../../domain/CategoryItem';
import ItemAndCategoriesMapping from '../../../../../shared/editable/domain/ItemAndCategoriesMapping';
import BasicEditor from './BasicEditor/BasicEditor';
import AdvancedEditor from './AdvancedEditor/AdvancedEditor';

export const styles = (theme) =>
  ({
    container: {
      'height': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    },
    dialogActions: {
      justifyContent: 'space-between'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  favorites: ItemAndCategoriesMapping;
  advancedEditionEnabled: boolean;
  /* eslint-disable no-unused-vars */
  isSelected?: (item: CategoryItem) => boolean;
  onItemSelected: (item: CategoryItem) => void;
  onUpdate: (favorites: ItemAndCategoriesMapping) => void;
  /* eslint-enable */
  onConfirm?: () => void;
  onCancel: () => void;
}

interface State {
  showAdvancedEditor: boolean;
}

export class FavoritesTab extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      showAdvancedEditor: false
    };
  }

  onCloseAdvancedEditor = () => {
    this.setState({ showAdvancedEditor: false });
  };

  render() {
    const { favorites, advancedEditionEnabled, isSelected, onItemSelected, onUpdate, onConfirm, onCancel, classes } =
      this.props;

    const { showAdvancedEditor } = this.state;

    if (showAdvancedEditor) {
      return (
        <>
          {/* @ts-ignore */}
          <AdvancedEditor
            data-testid="advanced-editor"
            onUpdateFavoriteItems={onUpdate}
            onClose={this.onCloseAdvancedEditor}
            favorites={favorites}
          />
        </>
      );
    }

    return (
      <>
        <DialogContent className={classes.container}>
          <BasicEditor
            data-testid="basic-editor"
            favorites={favorites}
            isItemSelected={isSelected}
            advancedModeEnabled={false}
            onSelectItem={onItemSelected}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <div>
            {advancedEditionEnabled && (
              <Button
                data-testid="advanced-edition-mode-button"
                onClick={() => this.setState({ showAdvancedEditor: true })}
                color="primary"
              >
                <FormattedMessage id="editableCollectionWithCategories.advancedEditMode" />
              </Button>
            )}
          </div>
          <div>
            <Button data-testid="close-Dialog" onClick={onCancel} color="primary">
              <FormattedMessage id="global.cancel" />
            </Button>
            {onConfirm && (
              <Button data-testid="confirm-Dialog" onClick={onConfirm} color="primary">
                <FormattedMessage id="global.ok" />
              </Button>
            )}
          </div>
        </DialogActions>
      </>
    );
  }
}

export default withStyles(styles)(FavoritesTab);
