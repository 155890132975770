import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { asyncCallDelete } from '../../../containers/AsyncTasks/actions';
import { selectAllAsyncCalls } from '../../../containers/AsyncTasks/selectors';

export const styles = (theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    marginTop: theme.spacing(1)
  }
});

const getErrorAsyncCalls = (asyncCalls) => asyncCalls.filter((relatedAsyncCall) => relatedAsyncCall.error);

export function ErrorSnackBars({ asyncCalls, onAsyncCallDelete, classes }) {
  const asyncCallsInError = getErrorAsyncCalls(asyncCalls);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={asyncCallsInError.size > 0}
    >
      <div className={classes.contentWrapper}>
        {asyncCallsInError.map((asyncCallError) => (
          <SnackbarContent
            className={classes.content}
            key={asyncCallError.id}
            message={<span>{asyncCallError.error.message}</span>}
            action={
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => onAsyncCallDelete(asyncCallError.id)}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        ))}
      </div>
    </Snackbar>
  );
}

export const mapDispatchToProps = {
  onAsyncCallDelete: asyncCallDelete
};

export const mapStateToProps = createStructuredSelector({
  asyncCalls: selectAllAsyncCalls()
});

ErrorSnackBars.propTypes = {
  asyncCalls: PropTypes.object.isRequired,
  onAsyncCallDelete: PropTypes.func.isRequired
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ErrorSnackBars);
