import { actFormFields } from 'app/containers/ActForm/constants';
import Act, { ActCode } from 'shared/domain/activity/act/model/Act';

export const NO_ERRORS = {};

const TEACHING_CODES_FOR_RESIDENTS = ['19700', '19701'];
const TEACHING_CODES_FOR_EXTERNAL_STUDENTS = ['19702', '19703'];
const TEACHING_CODES_FOR_INSTRUCTORS = ['19762', '19763'];

export default ({ codes }: Act) => {
  const errorsFound: string[] = [...validateMutuallyExclusiveTeachingCodes(codes), ...validateStudentNumbers(codes)];

  if (errorsFound.length === 0) return NO_ERRORS;

  return {
    [actFormFields.codes]: {
      _error: errorsFound.join(' ')
    }
  };
};

const validateMutuallyExclusiveTeachingCodes = (codes: ActCode[]) => {
  const teachingCodesForResidents: string[] = extractTeachingCodes(codes, TEACHING_CODES_FOR_RESIDENTS);
  const teachingCodesForExternalStudents: string[] = extractTeachingCodes(codes, TEACHING_CODES_FOR_EXTERNAL_STUDENTS);
  const teachingCodesForInstructors: string[] = extractTeachingCodes(codes, TEACHING_CODES_FOR_INSTRUCTORS);

  const mutuallyExclusiveCodes: Set<string> = new Set();

  [teachingCodesForResidents, teachingCodesForExternalStudents, teachingCodesForInstructors].forEach(
    (teachingCodes, index, allTeachingCodes) => {
      if (teachingCodes.length === 0) return;

      const otherTeachingCodes = allTeachingCodes.reduce((accumulator, values, subIndex) => {
        if (subIndex === index) return accumulator;

        return [...accumulator, ...values];
      }, []);

      if (otherTeachingCodes.length === 0) return;

      teachingCodes.forEach((teachingCode) => {
        mutuallyExclusiveCodes.add(teachingCode);

        otherTeachingCodes.forEach((otherTeachingCode) => mutuallyExclusiveCodes.add(otherTeachingCode));
      });
    }
  );

  if (mutuallyExclusiveCodes.size <= 1) return [];

  return [
    `Les codes d'enseignements ${Array.from(mutuallyExclusiveCodes).sort().join(', ')} ne peuvent pas être utilisés ensemble. Veuillez créer des cartons différents.`
  ];
};

const extractTeachingCodes = (codes: ActCode[], teachingCodes: string[]): string[] =>
  codes.reduce((accumulator: string[], { code }: ActCode): string[] => {
    if (!teachingCodes.includes(code)) return accumulator;

    return [...accumulator, code];
  }, []);

const validateStudentNumbers = (codes: ActCode[]) =>
  codes.reduce((errorsFound: string[], { code, noStudents = [] }) => {
    if (noStudents.length === 0) return errorsFound;

    if (TEACHING_CODES_FOR_RESIDENTS.includes(code) && !noStudents.every(isStudentNumberValid(/^R\d{5}$/i))) {
      errorsFound.push(`${code} doit seulement être utilisé avec des Résidents dont le numéro commence par R#####.`);
    }

    if (TEACHING_CODES_FOR_EXTERNAL_STUDENTS.includes(code) && !noStudents.some(isStudentNumberValid(/E\d{8}/i))) {
      errorsFound.push(`${code} doit être utilisé avec au moins un externe dont le numéro commence par E########.`);
    }

    if (TEACHING_CODES_FOR_INSTRUCTORS.includes(code) && !noStudents.every(isStudentNumberValid(/^[2|5]\d{5}$/i))) {
      errorsFound.push(
        `${code} doit seulement être utilisés avec des Moniteurs cliniques dont le numéro commence par 5##### ou 2#####.`
      );
    }

    return errorsFound;
  }, []);

const isStudentNumberValid =
  (validationPattern: RegExp) =>
  (studentNumber: string): boolean => {
    const trimmedStudentNumber = studentNumber.trim();

    return validationPattern.test(trimmedStudentNumber);
  };
