export default class BillingAgency {
  constructor(
    /* eslint-disable no-unused-vars */
    private readonly name: string,
    private readonly agencyNumber: string
  ) {}

  public getName = () => this.name;

  public getNumber = () => this.agencyNumber;
}
