import moment from 'moment';
import flatMap from 'lodash/flatMap';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import { activitiesCollectionRef } from '../../../firebase/collection/collectionReferences';
import { ACTIVITIES_TRAINING_TYPE } from '../../../../shared/collection/collectionConstants';

export default async function calculateHalfDayTrainingClaimingForYearToDay(userId, trainingDate) {
  const trainings = await getAllTrainingActivityByUser(userId);
  const trainingsNotCanceled = trainings.filter(isActivityNotCanceled);

  return getAllTrainingDaysFrom(trainingsNotCanceled)
    .filter(isActivityDayPerformedThisYear(trainingDate))
    .map(numberOfPerdiemsPerformedThisDay)
    .reduce(summarize, 0);
}

async function getAllTrainingActivityByUser(userId) {
  const { docs } = await activitiesCollectionRef()
    .where('type', '==', ACTIVITIES_TRAINING_TYPE)
    .where('userId', '==', userId)
    .get();

  return docs.map((doc) => doc.data());
}

function getAllTrainingDaysFrom(trainings) {
  return flatMap(trainings.map((doc) => doc.days));
}

const isActivityDayPerformedThisYear = (date) => (activityDay) => {
  const timestampFirstMillisecondOfTheYear = moment(date).startOf('year').valueOf();
  const timestampLastMillisecondOfTheYear = moment(date).endOf('year').valueOf();
  return (
    activityDay.date >= timestampFirstMillisecondOfTheYear && activityDay.date <= timestampLastMillisecondOfTheYear
  );
};

function isActivityNotCanceled(training) {
  return training.status !== ActivityStatus.CANCELED;
}

function numberOfPerdiemsPerformedThisDay(day) {
  return day.perdiems.length;
}

function summarize(accumulator, currentValue) {
  return accumulator + currentValue;
}
