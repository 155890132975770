import { createSelector } from '@reduxjs/toolkit';
import AuthUserDomain from '../../../shared/domain/authentication/AuthUser';

export const selectApp = (state) => state.authentication;

export const selectLoggedUser = () => createSelector(selectApp, (appState) => AuthUserDomain.fromDto(appState.user));

export const selectCurrentLoggedUser = () => createSelector(selectApp, (appState) => appState.user);

export const selectLoggedUserPermissions = () =>
  createSelector(selectApp, (appState) => {
    const { permissions = [] } = appState.user;
    return permissions;
  });

export const selectLoginInfo = () => createSelector(selectApp, (appState) => appState.login);

export const selectUpdatePasswordSuccess = () => createSelector(selectApp, (state) => state.updatePassword.success);
