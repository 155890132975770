import {
  doesRamqExchangeHaveStatus,
  getLatestRamqExchange,
  isRamqExchangeOutcomeSuccess
} from '../../../ramq/ramqCommonUtils';
import isDefined from '../../../utils/isDefined';
import ActivityStatus from '../domain/ActivityStatus';
import ActivityType from '../domain/ActivityType';
import BillingType from '../../../domain/billing/model/BillingType';
import { RamqExchangeType } from '../../../ramq/constants';
import AdminStatus from '../domain/AdminStatus';

export function isActivityBlocked(activity) {
  return !!activity.blocked;
}

export function isActivityProcessingOrPaid(activity) {
  return activity.status === ActivityStatus.PROCESSING || activity.status === ActivityStatus.PAID;
}

export function canActivityStatusBeSwitchedToProcessing(activity) {
  const { ramqExchange = [], adminStatus } = activity;
  const latestRAMQExchange = getLatestRamqExchange(ramqExchange);

  return (
    adminStatus === AdminStatus.REVISION_DEROGATION ||
    activity.type === ActivityType.TRAINING ||
    activity.billingType === BillingType.END ||
    isDefined(activity.manualCorrection) ||
    (isDefined(latestRAMQExchange) &&
      isRamqExchangeOutcomeSuccess(latestRAMQExchange) &&
      !doesRamqExchangeHaveStatus(latestRAMQExchange, RamqExchangeType.CANCEL_REQUEST_RESPONSE))
  );
}
