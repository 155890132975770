import ActCodes from '../../../domain/activity/act/model/ActCodes';
import RAMQPatient from '../../../domain/ramq/RAMQPatient';
import rules from './rules';
import { RecommendationMap } from './types';

class SwappableActCodesRecommendationService {
  public findRecommendationsForCodesAccordingToPatientsAge(
    codes: ActCodes,
    patient: RAMQPatient,
    actDate: number
  ): RecommendationMap {
    return codes.reduce<RecommendationMap>(
      (acc, actCode) => this.evaluateCode(patient, actDate, acc, actCode),
      new Map()
    );
  }

  protected evaluateCode(patient, actDate, accumulator, { code }): RecommendationMap {
    let recommendedCode;

    for (const rule of rules) {
      recommendedCode = rule.find(code, patient.ageAtDate(actDate));

      if (recommendedCode && recommendedCode !== code) {
        accumulator.set(code, recommendedCode);

        break;
      }
    }

    return accumulator;
  }
}

export default SwappableActCodesRecommendationService;
