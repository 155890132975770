import HOSPITAL_ACTIVITY_AREA_MAP from './activityAreas';
import { clinicalSupervisionForSpecialistInEmergencyClinicActivityArea } from '../teachingCodes/constants';
import { TELEMEDECINE_AILLEURS_COVID } from '../contextElements/legacyCovidContextElements';
import Act, { ActCode } from '../../domain/activity/act/model/Act';
import { atLeastOneContextElementFits } from '../../utils/codesUtils';
import isDefined from '../../utils/isDefined';
import { RAMQCodes, TimedAttributeKey } from '../RamqCodes';

const externalClinicCodes = [
  '9145',
  '9162',
  '9164',
  '9170',
  '15272',
  '15709',
  '15391',
  '15485',
  '15486',
  '15487',
  '15600',
  '15601',
  '15602'
];

const externalClinicContextElements = [TELEMEDECINE_AILLEURS_COVID];

const extendedCareCodes = ['9147', '9148', '9176', '9296', '48'];

const intensiveCareCodes = [
  '900',
  '929',
  '9095',
  '9096',
  '9097',
  '9098',
  '9295',
  '15388',
  '15389',
  '15390',
  '15482',
  '15483',
  '15484',
  '19523',
  '19524',
  '42158',
  '42159',
  '42160',
  '42161',
  '42162',
  '42163',
  '42164',
  '42165',
  '42166',
  '42167',
  '42168',
  '42169',
  '42170'
];

export const chronicPainCodes = ['15074', '15075', '19342'];

const generalCareCodes = ['9150', '9060', '9160', '15386', '15387', '9152', '9161', '78', '99', '9168'];

const isCodeInEmergencyClinicActivityArea = (act: Act, code: ActCode) =>
  clinicalSupervisionForSpecialistInEmergencyClinicActivityArea.includes(code.code) &&
  atLeastOneContextElementFits(externalClinicContextElements, act.contextElements) === false;

const isCodeInIntensiveCareActivityArea = (act: Act, code: ActCode) =>
  intensiveCareCodes.includes(code.code) &&
  atLeastOneContextElementFits(externalClinicContextElements, act.contextElements) === false;

const isCodeInChronicPainCenterActivityArea = (act: Act, { code }: ActCode) => {
  const ramqCode = RAMQCodes.get(code);

  if (!ramqCode) return false;

  return RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isPainDay, act.date);
};

const isCodeInExtendedCareUnitActivityArea = (act: Act, code: ActCode) =>
  extendedCareCodes.includes(code.code) &&
  atLeastOneContextElementFits(externalClinicContextElements, act.contextElements) === false;

const isCodeInExternalClinicActivityArea = (act: Act, code: ActCode) =>
  externalClinicCodes.includes(code.code) ||
  atLeastOneContextElementFits(externalClinicContextElements, act.contextElements) === true;

const isCodeInGeneralCareActivityArea = (act: Act, code: ActCode) => generalCareCodes.includes(code.code);

export const getDefaultActivityAreaForCode = (act: Act, codeIndex: number): string | undefined => {
  if (codeIndex >= act.codes.length) {
    throw new Error(`Code index ${codeIndex} is out of range (act has ${act.codes.length} codes).`);
  }

  const code = act.codes[codeIndex];
  if (isCodeInEmergencyClinicActivityArea(act, code)) {
    return HOSPITAL_ACTIVITY_AREA_MAP.emergencyClinic;
  }

  if (isCodeInIntensiveCareActivityArea(act, code)) {
    return HOSPITAL_ACTIVITY_AREA_MAP.intensiveCareUnit;
  }

  if (isCodeInChronicPainCenterActivityArea(act, code)) {
    return HOSPITAL_ACTIVITY_AREA_MAP.chronicPainCenter;
  }

  if (isCodeInExtendedCareUnitActivityArea(act, code)) {
    return HOSPITAL_ACTIVITY_AREA_MAP.extendedCareProlonges;
  }

  if (isCodeInExternalClinicActivityArea(act, code)) {
    return HOSPITAL_ACTIVITY_AREA_MAP.externalClinic;
  }

  if (isCodeInGeneralCareActivityArea(act, code)) {
    return HOSPITAL_ACTIVITY_AREA_MAP.generalCare;
  }

  return undefined;
};

export const listActivityAreasOfCodes = (act: Act): (string | undefined)[] =>
  act.codes.map((_, index) => getDefaultActivityAreaForCode(act, index));

export const getFirstDefaultActivityAreaFromCodes = (act: Act): string | undefined => {
  const activityAreas = listActivityAreasOfCodes(act).filter(isDefined);

  return activityAreas.length > 0 ? activityAreas[0] : undefined;
};
