import PropTypes from 'prop-types';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core';
import { currencyFormat } from '../../../../../utils/wordFormatUtilities';
import ExpandableTableRow from '../../../../components/Table/ExpandableTableRow/ExpandableTableRow';
import ExpandableTableRowHeader from '../../../../components/Table/ExpandableTableRow/ExpandableTableRowHeader';
import { getDateDenseWithWeekDay } from '../../../../../utils/dateTimeUtils';
import ExpandableTableRowContent from '../../../../components/Table/ExpandableTableRow/ExpandableTableRowContent';
import DoctorsPeriodDayDetails from './DoctorsPeriodDayDetails';

export const styles = () => ({
  '@media (max-width: 460px)': {
    denseTableCell: {
      'padding': '4px 10px',
      '&:first-child': {
        paddingLeft: '14px'
      },
      '&:last-child': {
        paddingRight: '14px'
      }
    }
  },
  'fixedCol': {
    width: '200px'
  },
  'noPadding': {
    '&:last-child': {
      padding: '0'
    }
  }
});

export function PeriodDayDetails({ classes, day, individual }) {
  return (
    <ExpandableTableRow>
      <ExpandableTableRowHeader>
        <TableCell classes={{ root: classes.denseTableCell }} size="small">
          {getDateDenseWithWeekDay(moment(day.date))}
        </TableCell>
        <TableCell classes={{ root: classes.denseTableCell }} className={classes.fixedCol} size="small" align="right">
          {day.numberOfPerdiems || day.hoursWorked}
        </TableCell>
        <TableCell classes={{ root: classes.denseTableCell }} className={classes.fixedCol} size="small" align="right">
          {currencyFormat(day.amount)}
        </TableCell>
      </ExpandableTableRowHeader>
      <ExpandableTableRowContent canOpen={individual === false}>
        <TableCell classes={{ root: classes.noPadding }} colSpan={3}>
          <DoctorsPeriodDayDetails doctors={day.doctors} />
        </TableCell>
      </ExpandableTableRowContent>
    </ExpandableTableRow>
  );
}

PeriodDayDetails.propTypes = {
  day: PropTypes.object.isRequired,
  individual: PropTypes.bool
};

PeriodDayDetails.defaultProps = {
  individual: false
};

export default withStyles(styles)(PeriodDayDetails);
