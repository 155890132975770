import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import useTranslate from 'application/hooks/use-translate';
import { UserRole } from 'application/types/users';
import TypeaheadInput from 'application/components/form/typeahead-input';
import Icon from 'application/components/icon';

interface Props {
  inputType?: 'text' | 'search';
  placeholder?: string;
  onUserSelect: (user: TypeaheadUser) => void;
  userRoles?: UserRole[];
  excludedIds?: string[];
  className?: string;
  showChoicesIfEmpty?: boolean;
  anchorRight?: boolean;
  autoFocus?: boolean;
  formatFunction?: (user: TypeaheadUser) => string;
}

export interface TypeaheadUser {
  id: string;
  fullName: string;
  role: UserRole;
  groups: Array<{
    id: string;
  }>;
}
interface SearchUsersQuery {
  usersResult: {
    users: TypeaheadUser[];
  };
}

const SEARCH_USERS_QUERY = gql`
  query SearchUsers($searchTerm: String!, $userRoles: [UserRole!]!, $excludedIds: [String!]!, $orphan: Boolean!) {
    usersResult(
      query: { searchTerm: $searchTerm, roles: $userRoles, excludedIds: $excludedIds, orphan: $orphan }
      limit: 10
    ) {
      users {
        id
        fullName
        role
        groups {
          id
        }
      }
    }
  }
`;

const UsersTypeahead = ({
  inputType = 'text',
  placeholder,
  onUserSelect,
  userRoles,
  excludedIds,
  className,
  showChoicesIfEmpty,
  anchorRight,
  autoFocus = true,
  formatFunction
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const translateUserRole = useTranslate('user-role');
  const { data, loading } = useQuery<SearchUsersQuery>(SEARCH_USERS_QUERY, {
    skip: !searchValue && !showChoicesIfEmpty,
    variables: {
      searchTerm: searchValue,
      userRoles,
      excludedIds: excludedIds || [],
      orphan: !searchValue && userRoles?.includes(UserRole.DOCTOR)
    }
  });

  return (
    <TypeaheadInput<TypeaheadUser>
      placeholder={placeholder}
      choices={data?.usersResult.users || []}
      loading={loading}
      onSearch={setSearchValue}
      onChoiceSelect={onUserSelect}
      getKey={(user) => user.id}
      renderChoice={(user) => (
        <div className="flex w-full justify-between">
          <div className="">{user.fullName}</div>
          <div className="flex flex-wrap content-end gap-x-2">
            {user.groups.length === 0 && <Icon icon="users-slash" className="text-primary" />}
            <div className="badge badge-ghost">{translateUserRole(user.role)}</div>
          </div>
        </div>
      )}
      showChoicesIfEmpty={showChoicesIfEmpty}
      className={className}
      anchorRight={anchorRight}
      autoFocus={autoFocus}
      inputType={inputType}
      formatFunction={formatFunction}
    />
  );
};

export default UsersTypeahead;
