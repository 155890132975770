import { Button, Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import moment from 'moment-timezone';
import classNames from 'classnames';
import Absence from '../../../../shared/domain/user/Absence/Absence';
import AbsenceDialog from './AbsenceDialog/AbsenceDialog';
import DialogTrigger from '../../Dialogs/DialogTrigger/DialogTrigger';
import { absenceReasonsTranslations } from './absenceTranslations';

const styles = (theme) =>
  ({
    fieldContainer: {
      display: 'flex',
      marginTop: theme.spacing(2)
    },
    rowContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row'
    },
    reason: {
      marginTop: -theme.spacing(1),
      fontWeight: 'bold'
    },
    comments: {
      margin: 0,
      fontWeight: 100
    },
    absenceContentContainer: {
      marginTop: '2px',
      paddingLeft: '16px'
    },
    absenceDateSection: {
      marginTop: -theme.spacing(1),
      marginBottom: theme.spacing(3)
    },
    absenceDate: {
      textDecoration: 'underline'
    },
    actionSection: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(4)
    },
    actionIcons: {
      background: 'none!important'
    }
  }) as const;

interface Props extends WithStyles<typeof styles>, WrappedFieldProps {
  onDelete: () => void;
}

const formatDate = (date: number) => moment(date).format('DD MMMM Y');

export const AbsenceField: React.FunctionComponent<Props> = ({ classes, input, onDelete }: Props) => {
  const editAbsence = (absence: Absence) => {
    input.onChange(absence);
  };

  const absence = input.value;

  return (
    <div className={classes.fieldContainer}>
      <Grid item xs={8}>
        <div className={classes.absenceContentContainer}>
          <div className={classNames(classes.rowContainer, classes.absenceDateSection)}>
            <Typography variant="body2" data-testid="startDate" className={classes.absenceDate}>
              {`${formatDate(absence.startDate)}`}
            </Typography>
            <Typography variant="body2">&nbsp;au&nbsp;</Typography>
            <Typography variant="body2" data-testid="endDate" className={classes.absenceDate}>
              {formatDate(absence.endDate)}
            </Typography>
          </div>
          <div className={classNames(classes.rowContainer, classes.reason)}>
            <Typography>{absenceReasonsTranslations[absence.reason]}</Typography>
          </div>
          <Typography className={classes.comments}>{absence.comments}</Typography>
        </div>
      </Grid>
      <Grid item xs={4} className={classes.actionSection}>
        <DialogTrigger
          DialogComponent={AbsenceDialog}
          dialogProps={{
            onSubmit: (editedAbsence) => editAbsence(editedAbsence),
            initialAbsence: absence
          }}
        >
          <Button onClick={onDelete} className={classes.actionIcons}>
            <EditIcon />
          </Button>
        </DialogTrigger>
        <Button onClick={onDelete} className={classes.actionIcons}>
          <DeleteIcon />
        </Button>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(AbsenceField);
