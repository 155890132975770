import { cloneDeep, concat, get, partition, remove, sortBy, isEqual } from 'lodash';
import AdminNote from 'shared/domain/user/admin/note/AdminNote';
import { chronicPainCodes } from 'shared/ramq/domainValues/codeActivityAreas';
import BillingType from 'shared/domain/billing/model/BillingType';
import { selectHasSkipMixteGenerationForTeachingCodesEnabled } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { logDefault } from 'shared/utils/plog';
import moment from 'moment';
import computePerdiemsAccordingToActs from '../optimization/mixtePerdiemCreation';
import createMixteDays from '../optimization/mixteSyncInformationCreation';
import { getUnixStartOfDay } from '../../../../shared/utils/dateTime/dateTimeUtils';
import MANUAL_EDITION_MODE from '../../../containers/Mixte/constants';
import { dateIsDuringPlaceHoliday, dateIsDuringWeekend } from '../../../../shared/utils/dateTime/dateValidations';
import MixteCreator from '../../../../shared/domain/activity/mixte/MixteCreator';
import { Mixte, MixteDay } from '../../../../shared/domain/activity/mixte/model/Mixte';
import Place, { Location } from '../../../../shared/domain/place/model/Place';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import Act from '../../../../shared/domain/activity/act/model/Act';
import getBlockedMixteMessage from './blockedMixteMessages';

const teachingCodes = ['19700', '19701', '19702', '19703'];
const preventGenerationCodes = [...chronicPainCodes, '15299'];

class MixteGenerator {
  private skipMixteGenerationForTeachingCodes: boolean = false;

  generate(targetedDate: number, actsInCurrentDay: Act[], savedMixtesInCurrentPeriod: Mixte[]) {
    const mixtesToUpdate: Mixte[] = [];
    const hasSkipMixteGenerationForTeachingCodes = selectHasSkipMixteGenerationForTeachingCodesEnabled()(
      getStore().getState()
    );

    this.skipMixteGenerationForTeachingCodes = hasSkipMixteGenerationForTeachingCodes;

    if (
      actsInCurrentDay.some(
        (act) =>
          act.status !== ActivityStatus.CANCELED && act.codes.some((code) => preventGenerationCodes.includes(code.code))
      )
    ) {
      logDefault(
        `Skipping mixte generation for ${moment(targetedDate).format('YYYY-MM-DD')} because of a code that prevents generation.`
      );
      return [];
    }

    const actsByPlaceAndBillingTypeAndPoolNumber = actsInCurrentDay.reduce((acc, act) => {
      const { place, billingType = BillingType.PRIV, poolNumber, codes = [] } = act;

      if (
        billingType === BillingType.END ||
        (this.skipMixteGenerationForTeachingCodes && codes.find((code) => teachingCodes.includes(code.code))) ||
        codes.length === 0
      ) {
        return acc;
      }

      const key = [place.number, billingType, poolNumber].filter(Boolean).join('-');

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(act);

      return acc;
    }, {});

    const mixtesInCurrentPeriodWithChanges = cloneDeep(savedMixtesInCurrentPeriod);

    Object.keys(actsByPlaceAndBillingTypeAndPoolNumber).forEach((key) => {
      const [placeNumber, billingType, poolNumber] = key.split('-');
      const acts = actsByPlaceAndBillingTypeAndPoolNumber[key];
      const currentMixte = mixtesInCurrentPeriodWithChanges.find(
        (mixte) =>
          mixte.place.number === placeNumber && billingType === mixte.billingType && poolNumber === mixte.poolNumber
      );

      const { date, place, userId } = acts[0];

      if (this.shouldPerformMixteOptimization(date, place, currentMixte)) {
        const perdiemsInformation = computePerdiemsAccordingToActs(this.getNotCanceledActs(acts));
        const computedDays = createMixteDays(perdiemsInformation);

        if (!currentMixte) {
          const newMixte = MixteCreator.create(computedDays, date, place, userId, billingType, poolNumber);
          mixtesToUpdate.push(newMixte);
        } else {
          const optimizedMixte = this.updateMixteDaysAtDate(currentMixte, date, computedDays);

          if (this.isMixteNeedToBeUpdated(optimizedMixte, currentMixte)) {
            const updatedMixte = this.updateExistingMixte(currentMixte, date, computedDays);
            const originalMixte = mixtesInCurrentPeriodWithChanges.find((mixte) => mixte.id === optimizedMixte.id);
            mixtesInCurrentPeriodWithChanges.splice(
              mixtesInCurrentPeriodWithChanges.indexOf(originalMixte),
              1,
              updatedMixte
            );
            mixtesToUpdate.push(updatedMixte);
          } else {
            mixtesToUpdate.push(currentMixte);
          }
        }
      }
    });

    const mixtesExcludedFromActs = this.findMixtesExcludedFromActs(
      targetedDate,
      actsInCurrentDay,
      savedMixtesInCurrentPeriod
    );

    const [mixtesToCancel, mixtesToBlock] = partition(mixtesExcludedFromActs, (mixte: Mixte) =>
      this.canCurrentMixteBeModified(mixte)
    );

    const canceledMixtes = this.cancelMixtesForDate(mixtesToCancel, targetedDate);

    const blockedMixtes = mixtesToBlock
      .filter(this.isMixteBilledSomePerdiemAtDate(targetedDate))
      .map((mixte) => this.blockMixte(mixte, targetedDate));

    mixtesToUpdate.forEach((mixte) => {
      if (this.isOverlapping(mixte, mixtesInCurrentPeriodWithChanges, canceledMixtes)) {
        if (this.canCurrentMixteBeModified(mixte)) {
          mixtesToUpdate.splice(mixtesToUpdate.indexOf(mixte), 1, this.applyAdminNote(mixte));
        } else {
          mixtesToUpdate.splice(mixtesToUpdate.indexOf(mixte), 1, this.blockMixte(mixte, targetedDate));
        }
      } else if (this.canCurrentMixteBeModified(mixte) && mixte.status === ActivityStatus.NEED_FIX) {
        mixtesToUpdate.splice(mixtesToUpdate.indexOf(mixte), 1, this.waiting(mixte));
      }
    });

    return [...mixtesToUpdate, ...canceledMixtes, ...blockedMixtes];
  }

  private isOverlapping(mixte: Mixte, allMixtes: Mixte[], canceledMixtes: Mixte[]) {
    return allMixtes.some(
      (existingMixte) =>
        (!mixte || existingMixte.id !== mixte.id) &&
        !canceledMixtes.find((canceledMixte) => canceledMixte.id === existingMixte.id) &&
        existingMixte.days.some((day) => {
          const mixteDay = mixte.days.find((currentDay) => currentDay.date === day.date);
          return mixteDay && mixteDay.perdiems?.some((perdiem) => day.perdiems?.includes(perdiem));
        })
    );
  }

  private updateExistingMixte(currentMixteForTargetPlace: Mixte, date: number, computedDays: any[]) {
    let updatedMixte: Mixte;

    if (this.canCurrentMixteBeModified(currentMixteForTargetPlace)) {
      updatedMixte = this.updateMixteDaysAtDate(currentMixteForTargetPlace, date, computedDays);
    } else {
      updatedMixte = this.blockMixte(currentMixteForTargetPlace, date);
    }

    return updatedMixte;
  }

  private isMixteNeedToBeUpdated(optimizedMixte: Mixte, currentMixteForTargetPlace: Mixte) {
    // We cannot juste compare days with isEqual(optimizedMixte.days, currentMixteForTargetPlace.days)
    // 1) Sometimes, days are not correctly sorted by date
    // 2) We need to compare only date, code and perdiems.
    // 3) About perdiems, we need to make sure that [] === undefined in order to not update mixte for no reason

    if (optimizedMixte.days.length !== currentMixteForTargetPlace.days.length) {
      return true;
    }

    const sortedOriginalDays = sortBy(optimizedMixte.days, 'date');
    const sortedUpdatedDays = sortBy(currentMixteForTargetPlace.days, 'date');

    for (let i = 0; i < sortedOriginalDays.length; i += 1) {
      const { perdiems: originalPerdiems = [], date: originalDate, code: originalCode } = sortedOriginalDays[i];
      const { perdiems: updatedPerdiems = [], date: updatedDate, code: updatedCode } = sortedUpdatedDays[i];

      if (
        !isEqual(originalDate, updatedDate) ||
        !isEqual(originalPerdiems, updatedPerdiems) ||
        !isEqual(originalCode, updatedCode)
      ) {
        return true;
      }
    }

    return false;
  }

  private isMixteBilledSomePerdiemAtDate(targetedDate: number) {
    return ({ days }: Mixte) => !!days.find(this.isSomeBilledPerdiemAtDate(targetedDate));
  }

  private isSomeBilledPerdiemAtDate(targetedDate: number) {
    return ({ date, perdiems = [] }: MixteDay) => date === getUnixStartOfDay(targetedDate) && perdiems.length > 0;
  }

  private updateMixteDaysAtDate(mixte: Mixte, date: number, computedDays) {
    const mixteCopy = cloneDeep(mixte);

    remove(mixteCopy.days, (day: any) => day.date === getUnixStartOfDay(date));
    mixteCopy.days = concat(mixteCopy.days, computedDays);

    return { ...mixteCopy, status: ActivityStatus.WAITING };
  }

  private cancelMixtesForDate(mixtes: Mixte[], date: number) {
    return mixtes.map((mixte) => {
      remove(mixte.days, (day: MixteDay) => day.date === getUnixStartOfDay(date));
      return mixte;
    });
  }

  private waiting(mixte: Mixte) {
    return { ...mixte, status: ActivityStatus.WAITING };
  }

  private applyAdminNote(mixte: Mixte) {
    return {
      ...mixte,
      adminNotes: [
        ...(mixte.adminNotes || []),
        AdminNote.createSystemAdminNote('Mixte à valider car il chevauche potentiellement un autre mixte').getProps()
      ]
    };
  }

  private blockMixte(mixte: Mixte, date: number) {
    const { corrections = [], days = [] } = mixte;

    const matchingDay = days.find((day: MixteDay) => day.date === getUnixStartOfDay(date));

    return {
      ...mixte,
      status: ActivityStatus.NEED_FIX,
      blocked: true,
      corrections: [
        ...corrections,
        {
          date: Date.now(),
          content: getBlockedMixteMessage(matchingDay || { date })
        }
      ]
    };
  }

  private findMixtesExcludedFromActs(date: number, acts: Act[], mixtes: Mixte[]) {
    return mixtes.filter(
      (mixte) =>
        !this.isInManualEditionModeForThisDay(mixte, date) && !this.isMixtePlaceAndPoolNumberIncludedInActs(mixte, acts)
    );
  }

  private isMixtePlaceAndPoolNumberIncludedInActs(mixte: Mixte, acts: Act[]) {
    return acts.some((act) => {
      const { place: actPlace, billingType: actBillingType, poolNumber: actPoolNumber = null } = act;
      const { place: mixtePlace, billingType: mixteBillingType, poolNumber: mixtePoolNumber = null } = mixte;

      return (
        act.status !== ActivityStatus.CANCELED &&
        actPlace?.number === mixtePlace?.number &&
        actBillingType === mixteBillingType &&
        actPoolNumber === mixtePoolNumber &&
        !(this.skipMixteGenerationForTeachingCodes && act.codes.some((code) => teachingCodes.includes(code.code)))
      );
    });
  }

  private isInManualEditionModeForThisDay(mixte: Mixte | undefined, date: number) {
    const currentDayMixteEntries = get(mixte, 'days', []).filter((day) => day.date === date);
    return currentDayMixteEntries.filter((mixteEntry) => mixteEntry[MANUAL_EDITION_MODE]).length > 0;
  }

  private shouldPerformMixteOptimization(date: number, place: Place, mixte?: Mixte) {
    return (
      this.placeIsPhysicalOne(place) &&
      !dateIsDuringPlaceHoliday(date, place) &&
      !dateIsDuringWeekend(date) &&
      !this.placeIsBilledByAct(place) &&
      !this.isInManualEditionModeForThisDay(mixte, date)
    );
  }

  private placeIsPhysicalOne(place: Place) {
    return place.type === Location.PHYSICAL;
  }

  private placeIsBilledByAct(place: Place) {
    return place.billByAct;
  }

  private canCurrentMixteBeModified(mixte?: Mixte) {
    if (mixte === undefined) {
      return false;
    }

    if ([ActivityStatus.PAID, ActivityStatus.PROCESSING].includes(mixte.status)) {
      return false;
    }

    return !mixte.blocked;
  }

  private getNotCanceledActs(acts) {
    return acts.filter((act) => act.status !== ActivityStatus.CANCELED);
  }
}

export default MixteGenerator;
