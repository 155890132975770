import { useIntercom } from 'react-use-intercom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { selectCurrentIntercomUserHash } from '../../containers/Intercom/selectors';
import { selectUserProfileInContext } from '../../containers/User/selectors';

function IntercomWidgetWrapper({ userProfile, hash }) {
  const { boot, update } = useIntercom();

  useEffect(() => {
    if (!userProfile?.id) return;
    if (!hash) return;

    boot({ userHash: hash, userId: userProfile.id });
    update({ hideDefaultLauncher: false });

    return () => {
      update({ hideDefaultLauncher: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, hash]);

  return null;
}

IntercomWidgetWrapper.propTypes = {
  userProfile: PropTypes.node.isRequired,
  hash: PropTypes.string.isRequired
};

export const mapStateToProps = createStructuredSelector({
  hash: selectCurrentIntercomUserHash(),
  userProfile: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps))(IntercomWidgetWrapper);
