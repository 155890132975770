import Act, { ActCode } from '../model/Act';
import ActCodes from '../model/ActCodes';

class ActPerformedAlonePredicate {
  static validate(act: Act) {
    return !!this.findMainCode(act);
  }

  static findMainCode(act: Act): ActCode | undefined {
    const actCodes = new ActCodes(act.codes);
    const mainCodes = actCodes.filter(({ isSupplement }: ActCode) => !isSupplement);

    if (mainCodes.size() !== 1) {
      return undefined;
    }

    return mainCodes.at(0)!;
  }
}

export default ActPerformedAlonePredicate;
