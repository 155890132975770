import classNames from 'classnames';
import React from 'react';

interface Props {
  label?: string;
  children: React.ReactNode;
  className?: string;
}

const FieldContainer = ({ label, className, children }: Props) => (
  <div className={classNames('field-container flex w-full flex-col', className)}>
    {label && <label className="mb-2">{label}</label>}
    {children}
  </div>
);

export default FieldContainer;
