/* eslint-disable no-shadow, no-unused-vars */
enum BillingType {
  POOL = 'pool',
  PRIV = 'priv',
  END = 'end'
}

export const ALL_BILLING_TYPES = [BillingType.POOL, BillingType.END, BillingType.PRIV];
export const POOL_BILLING_TYPES = [BillingType.POOL, BillingType.END];

export default BillingType;
