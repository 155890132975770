import flattenDeep from 'lodash/flattenDeep';
import Act from '../../../../shared/domain/activity/act/model/Act';
import actCode7000XCannotBeBilledWithNoPdtOrSurgeryPg28ActCodeValidator from './actCode7000XCannotBeBilledWithNoPdtOrSurgeryPg28ActCodeValidator/actCode7000XCannotBeBilledWithNoPdtOrSurgeryPg28ActCodeValidator';
import { actFormFields } from '../../../containers/ActForm/constants';
import dailyChronicPainPlanWarning from './dailyChronicPainPlanWarning/dailyChronicPainPlanWarning';
import switchCodeAccordingToPatientAgeWarning from './switchCodeAccordingToPatientAgeWarning';
import addSupplementAccordingToPatientAgeWarning from './addSupplementAccordingToPatientAgeWarning/addSupplementAccordingToPatientAgeWarning';
import multipleR3CodesWarning from './multipleR3CodesWarning';
import unitSupportPlanWarning from './unitSupportPlanWarning/unitSupportPlanWarning';
import halfDayPreoperativeClinicLimitWarning from './halfDayPreoperativeClinicLimitWarning/halfDayPreoperativeClinicLimitWarning';
import incompletePeriodWarning from '../hours/incompletePeriodWarning';
import contextElement1922Warning from '../hours/contextElement1922Warning';
import surgeryInReplacement from './surgeryInReplacement';
import chronicPainDayExclusiveCodesValidation from '../codes/chronicPainDayExclusiveCodesValidation';

const NO_WARNINGS = {};

const warningService = (form: Partial<Act>) => {
  const warningValidators = [
    actCode7000XCannotBeBilledWithNoPdtOrSurgeryPg28ActCodeValidator,
    switchCodeAccordingToPatientAgeWarning,
    addSupplementAccordingToPatientAgeWarning,
    dailyChronicPainPlanWarning,
    multipleR3CodesWarning,
    unitSupportPlanWarning,
    halfDayPreoperativeClinicLimitWarning,
    incompletePeriodWarning,
    contextElement1922Warning,
    surgeryInReplacement,
    chronicPainDayExclusiveCodesValidation
  ];

  const warnings = flattenDeep(warningValidators.map((validator) => validator(form)));

  if (warnings.length > 0) {
    return {
      [actFormFields.warningsDisplay]: {
        _warning: warnings
      }
    };
  }

  return NO_WARNINGS;
};

export default warningService;
