import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DirectoryPhysicianType } from 'app/containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import FloatingButton from '../../../../components/button/FloatingButton/FloatingButton';
import CollapsableCategoryTree from '../../../../shared/category/ui/CollapsableCategoryTree/CollapsableCategoryTree';
import { transformToCategorizableTree } from '../../../../shared/editable/domain/CategorizableCollectionTransformer';
import { addDoctorToUserPreferences, removeDoctorFromUserPreferences } from '../../../adapters/actions';
import { FavoritesDoctorsUtils } from '../../../utils/FavoritesDoctorsUtils';
import DoctorFavoriteListItem from '../../components/DoctorFavoriteListItem/DoctorFavoriteListItem';
import DoctorDialog from '../DoctorDialog/DoctorDialog';

export const styles = () => ({
  addDoctorButton: {
    bottom: 75,
    right: 25,
    position: 'absolute'
  }
});

export class DoctorFavoritesBasicEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = { doctorInEdit: undefined, doctorDialogOpen: false };

    this.openDoctorDialogInEditionMode = this.openDoctorDialogInEditionMode.bind(this);
    this.openDoctorDialogInCreationMode = this.openDoctorDialogInCreationMode.bind(this);
    this.closeDoctorDialog = this.closeDoctorDialog.bind(this);
  }

  openDoctorDialogInCreationMode() {
    this.setState({ doctorInEdit: undefined, doctorDialogOpen: true });
  }

  openDoctorDialogInEditionMode(doctor) {
    this.setState({
      doctorInEdit: doctor,
      doctorDialogOpen: true
    });
  }

  closeDoctorDialog() {
    this.setState({
      doctorDialogOpen: false,
      doctorInEdit: undefined
    });
  }

  render() {
    const { doctorsPreferences, onDeleteDoctor, onSelectDoctor, onSaveDoctor, type, classes } = this.props;
    const { doctorDialogOpen, doctorInEdit } = this.state;

    const { itemsWithoutCategory, categories } = transformToCategorizableTree(doctorsPreferences);

    const { itemsWithoutCategory: itemsWithoutCategorySorted, categories: categoriesSorted } =
      FavoritesDoctorsUtils.sortItemsWithoutCategoryAndCategories(itemsWithoutCategory, categories);

    return (
      <>
        <List dense>
          {itemsWithoutCategorySorted
            .filter((doctor) => doctor !== undefined)
            .map((doctor) => (
              <DoctorFavoriteListItem
                key={doctor.id}
                doctor={doctor}
                onClick={() => onSelectDoctor(doctor)}
                onEdit={() => this.openDoctorDialogInEditionMode(doctor)}
                onDelete={() => onDeleteDoctor(doctor)}
              />
            ))}
          <CollapsableCategoryTree
            categories={categoriesSorted}
            initiallyOpen={false}
            renderItem={(doctor) => (
              <DoctorFavoriteListItem
                key={doctor.id}
                doctor={doctor}
                onClick={() => onSelectDoctor(doctor)}
                onEdit={() => this.openDoctorDialogInEditionMode(doctor)}
                onDelete={() => onDeleteDoctor(doctor)}
              />
            )}
          />
        </List>
        {type !== DirectoryPhysicianType.RESIDENT && (
          <FloatingButton
            id="add-doctor-button"
            className={classes.addDoctorButton}
            color="primary"
            icon={<AddIcon />}
            onClick={this.openDoctorDialogInCreationMode}
          />
        )}
        <DoctorDialog
          open={doctorDialogOpen}
          onSave={onSaveDoctor}
          onClose={this.closeDoctorDialog}
          doctorsPreferences={doctorsPreferences}
          doctor={doctorInEdit}
        />
      </>
    );
  }
}

DoctorFavoritesBasicEditor.propTypes = {
  doctorsPreferences: PropTypes.array.isRequired,
  onDeleteDoctor: PropTypes.func.isRequired,
  onSaveDoctor: PropTypes.func.isRequired,
  onSelectDoctor: PropTypes.func.isRequired,
  type: PropTypes.string
};

export default compose(withStyles(styles))(DoctorFavoritesBasicEditor);
