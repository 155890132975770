import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import WeekAndWeekendActCodesMappingService from '../../../../shared/act/services/WeekAndWeekendActCodesMappingService';
import PlaceDomain from '../../../../shared/domain/place/model/PlaceDomain';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';
import { findCodeDetail } from '../../../../shared/data/actCodesData';
import AssistedMechanicalVentilationActCodesMappingService from '../../../../shared/act/services/AssistedMechanicalVentilationActCodesMappingService';

const switchWeekWeekendAndHolidayCodeRecommendation = (act: Partial<Act>): Recommendation<ActCode[] | undefined>[] => {
  const { place, date } = act;

  if (!(place && date)) {
    return NO_RECOMMENDATIONS;
  }

  const placeDomain = PlaceDomain.fromDto(place);
  const misusedCodes = [
    ...new WeekAndWeekendActCodesMappingService().listMisusedCodesInAct(act as Act),
    ...AssistedMechanicalVentilationActCodesMappingService.listMisusedCodesInAct(act as Act)
  ];

  if (misusedCodes.length > 0) {
    const warningMessageComposer = placeDomain.isWeekEndOrHoliday(date)
      ? composeWeekendAndHolidayRecommendationMessage
      : composeWeekRecommendationMessage;

    return misusedCodes.map(([invalidCode, validCode]) => ({
      message: warningMessageComposer([invalidCode, validCode]),
      apply: (partialAct) => {
        const oldValue = partialAct.codes;
        const newValue = partialAct.codes?.map((actCode) => {
          if (actCode.code === invalidCode) {
            return {
              ...actCode,
              ...findCodeDetail(validCode)
            };
          }

          return actCode;
        });

        return {
          fieldName: 'codes',
          newValue,
          oldValue
        };
      }
    }));
  }

  return NO_RECOMMENDATIONS;
};

const composeWeekRecommendationMessage = ([invalidCode, validCode]) =>
  `Modification du code ${invalidCode} par ${validCode} les jours de semaine`;

const composeWeekendAndHolidayRecommendationMessage = ([invalidCode, validCode]) =>
  `Modification du code ${invalidCode} par ${validCode} les weekends et jours fériés`;

export default switchWeekWeekendAndHolidayCodeRecommendation;
