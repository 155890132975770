import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { connect } from 'react-redux';
import FavoriteItem from '../../../../../containers/PlacesPreferencesDialog/FavoriteItem/FavoriteItem';
import { transformToCategorizableTree } from '../../../../../shared/editable/domain/CategorizableCollectionTransformer';
import { removeCodeFromUserPreferences } from '../../../../../containers/CodeSearchDialog/actions';
import CollapsableCategoryTree from '../../../../../shared/category/ui/CollapsableCategoryTree/CollapsableCategoryTree';
import { objectElementToLowerCase } from '../../../../../../shared/utils/objectManipulation';
import {
  buildUnitsString,
  findUserSpecialtyRelatedCodeUnits
} from '../../../../../act/shared/domain/roles/roles.utils';

export class FavoritesActCodes extends React.PureComponent {
  getItemsAndCategoriesInRightOrder(favoritesCode, hasEditableFavoritesEnabled) {
    const { itemsWithoutCategory, categories } = transformToCategorizableTree(favoritesCode);

    let orderedItemsWithoutCategories = itemsWithoutCategory.filter((item) => item !== undefined);

    if (!hasEditableFavoritesEnabled) {
      orderedItemsWithoutCategories = orderBy(
        orderedItemsWithoutCategories,
        [objectElementToLowerCase('description')],
        ['asc']
      );
    }

    return {
      categories,
      orderedItemsWithoutCategories
    };
  }

  renderActCodeItem(isCodeSelected, onSelectCode, onRemoveFromFavorite) {
    return (item) => (
      <FavoriteItem
        key={item.id}
        chipNumber={item.code}
        selected={isCodeSelected(item)}
        primaryText={<Typography>{item.description}</Typography>}
        secondaryText={buildUnitsString(findUserSpecialtyRelatedCodeUnits(item.code))}
        onItemSelect={() => onSelectCode(item)}
        onItemRemove={() => onRemoveFromFavorite(item)}
      />
    );
  }

  render() {
    const { favoritesCode, onSelectCode, onRemoveCode, isCodeSelected, hasEditableFavoritesEnabled } = this.props;

    const { categories, orderedItemsWithoutCategories } = this.getItemsAndCategoriesInRightOrder(
      favoritesCode,
      hasEditableFavoritesEnabled
    );

    return (
      <List dense>
        {orderedItemsWithoutCategories.map(this.renderActCodeItem(isCodeSelected, onSelectCode, onRemoveCode))}
        <CollapsableCategoryTree
          initiallyOpen={false}
          categories={categories}
          renderItem={this.renderActCodeItem(isCodeSelected, onSelectCode, onRemoveCode)}
        />
      </List>
    );
  }
}

FavoritesActCodes.propTypes = {
  favoritesCode: PropTypes.array.isRequired,
  hasEditableFavoritesEnabled: PropTypes.bool.isRequired,
  onSelectCode: PropTypes.func.isRequired,
  onRemoveCode: PropTypes.func.isRequired,
  isCodeSelected: PropTypes.func.isRequired
};

export const mapDispatchToProps = (dispatch) => ({
  onRemoveCode: (code) => dispatch(removeCodeFromUserPreferences(code))
});

export default connect(null, mapDispatchToProps)(FavoritesActCodes);
