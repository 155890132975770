import { ActOptimisationSpecification } from '../ActOptimisationSpecification';
import Range from '../../../../utils/Range/Range';
import ActBillingContext from '../../optimisation/ActBillingContext';
import RAMQPatient from '../../../ramq/RAMQPatient';
import NAM from '../../../ramq/NAM';

export class PatientAgeIs extends ActOptimisationSpecification {
  // eslint-disable-next-line no-unused-vars
  constructor(private ageRange: Range) {
    super();
  }

  isSatisfiedBy(context: ActBillingContext): boolean {
    const patient = new RAMQPatient(NAM.fromAct(context.act));

    return this.ageRange.includes(patient.ageAtDate(context.act?.date));
  }
}

const patientAgeIs = (ageRange: Range) => new PatientAgeIs(ageRange);

export default patientAgeIs;
