import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { previewXml } from 'app/infrastructure/api/api';
import XMLViewerDialog from 'app/components/Dialogs/XMLViewerDialog/XMLViewerDialog';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import LoadingButton from '../../../../../components/button/LoadingButton/LoadingButton';

interface Props {
  activityId: string;
  type: ActivityType;
}

export const PreviewXmlButton: React.FunctionComponent<Props> = ({ activityId, type }) => {
  const [open, setOpen] = React.useState(false);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['previewXml'],
    queryFn: () => previewXml(activityId, type),
    enabled: false
  });

  const handleClick = () => {
    refetch();
    setOpen(true);
  };

  return (
    <>
      <XMLViewerDialog open={open} onClose={() => setOpen(false)} xml={data?.data} loading={isFetching} />
      <LoadingButton
        id="preview-xml"
        className=""
        label="Prévisualiser XML"
        disabled={false}
        loading={isFetching}
        onClick={handleClick}
      />
    </>
  );
};
