import { get } from 'lodash';
import { call, put } from 'redux-saga/effects';
import { logDefault } from '../../../shared/utils/plog';
import { reduxSagaFireBase } from '../../../server/Firebase';
import { getAllDataFromSnapshotWithBoundId } from './snapshotDataFetcher';

export function* syncCollection(collectionRef, successActionCreator, failureActionCreator) {
  logDefault(
    `Syncing collection ${collectionRef.id} ${get(successActionCreator, 'name')} ${get(failureActionCreator, 'name')}`
  );
  yield reduxSagaFireBase.firestore.syncCollection(collectionRef, {
    successActionCreator,
    transform: getAllDataFromSnapshotWithBoundId,
    failureActionCreator
  });
}

export function* getCollection(collectionRef, successActionCreator, failureActionCreator) {
  try {
    const snapshot = yield call(reduxSagaFireBase.firestore.getCollection, collectionRef);
    const extractedData = getAllDataFromSnapshotWithBoundId(snapshot);
    yield put(successActionCreator(extractedData));
  } catch (e) {
    yield put(failureActionCreator(e));
  }
}
