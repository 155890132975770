import queryString from 'qs';
import API_BASE_URL from '../../../shared/server/apiConfig';
import request, { processResponse } from './apiUtils';

export interface RecommendationDetail {
  code: string;
  desc: string;
  prob: number;
  reason?: string;
  relatedCode?: string;
  type: number;
}

export interface MappedRecommendations {
  errors: RecommendationDetail[];
  infos: RecommendationDetail[];
  suggestions: RecommendationDetail[];
  warnings: RecommendationDetail[];
}

export default class RecommendationsAPI {
  public static get(codes: string[], specialty: string): Promise<MappedRecommendations> {
    const params = queryString.stringify({ codes, specialty }, { indices: true });

    const endpoint = `${API_BASE_URL}/recommendations?${params}`;

    return processResponse(request(endpoint, { method: 'GET' }));
  }
}
