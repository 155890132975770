/** @deprecated Use RamqResponseOutcome below */
export const RAMQ_RESPONSE_OUTCOME_SUCCESS = 'success';
/** @deprecated Use RamqResponseOutcome below */
export const RAMQ_RESPONSE_OUTCOME_RETRYABLE = 'retryable';
/** @deprecated Use RamqResponseOutcome below */
export const RAMQ_RESPONSE_OUTCOME_FAILURE = 'failure';
/** @deprecated Use RamqResponseOutcome below */
export const RAMQ_RESPONSE_OUTCOME_ERROR = 'error';

/* eslint-disable no-shadow, no-unused-vars */
export enum RamqResponseOutcome {
  SUCCESS = 'success',
  RETRYABLE = 'retryable',
  FAILURE = 'failure',
  ERROR = 'error'
}

/** @deprecated Use RamqExchangeType below */
export const ACT_RESPONSE_PAYMENT_REQUEST_TYPE = 'RAMQPaymentRequestResponse';
/** @deprecated Use RamqExchangeType below */
export const ACT_RESPONSE_MODIFICATION_REQUEST_TYPE = 'RAMQModificationRequestResponse';
/** @deprecated Use RamqExchangeType below */
export const ACT_RESPONSE_CANCEL_REQUEST_TYPE = 'RAMQCancelRequestResponse';
/** @deprecated Use RamqExchangeType below */
export const ACTIVITY_PAYMENT_EXPLANATION_TYPE = 'RAMQPaymentExplanationResponse';
/** @deprecated Use RamqExchangeType below */
export const DEFERRED_PAYMENT_REQUEST_RESPONSE_TYPE = 'RAMQDeferredPaymentRequestResponse';
/** @deprecated Use RamqExchangeType below */
export const DEFERRED_PAYMENT_CONFIRMATION_TYPE = 'RAMQDeferredPaymentConfirmation';
/** @deprecated Use RamqExchangeType below */
export const DEFERRED_PAYMENT_ERROR_TYPE = 'RAMQDeferredPaymentError';

export enum RamqExchangeType {
  PAYMENT_REQUEST_RESPONSE = 'RAMQPaymentRequestResponse',
  MODIFICATION_REQUEST_REPONSE = 'RAMQModificationRequestResponse',
  CANCEL_REQUEST_RESPONSE = 'RAMQCancelRequestResponse',
  PAYMENT_EXPLANATION_RESPONSE = 'RAMQPaymentExplanationResponse',
  DEFERRED_PAYMENT_REQUEST_RESPONSE = 'RAMQDeferredPaymentRequestResponse',
  DEFERRED_PAYMENT_CONFIRMATION = 'RAMQDeferredPaymentConfirmation',
  DEFERRED_PAYMENT_ERROR = 'RAMQDeferredPaymentError'
}

/** @deprecated Use RamqExchangeLineType below */
export const ACTIVITY_PAYMENT_EXPLANATION_LINE_TYPE = 'RamqExplanationLineType';
/** @deprecated Use RamqExchangeLineType below */
export const ACTIVITY_PAYMENT_STATEMENT_LINE_TYPE = 'RamqStatementLineType';

export enum RamqExchangeLineType {
  EXPLANATION_LINE_TYPE = 'RamqExplanationLineType',
  STATEMENT_LINE_TYPE = 'RamqStatementLineType'
}

export const RAMQ_CLOUDFUNCTIONS_BILL = 'server/billRamq';
export const RAMQ_CLOUDFUNCTIONS_CANCEL = 'server/cancelRamq';

export type RamqExchange = {
  statementProductionDate?: string;
  balance?: string;
  lines?: RamqExchangeLine[];
  type: RamqExchangeType;
  outcome?: RamqResponseOutcome;
  createdOn?: number;
  paymentRequestXml?: string;
};

interface ExchangeLine {
  outcome: string;
  createdOn: number;
  type?: RamqExchangeLineType;
}

export interface RamqExchangeLineSuccess extends ExchangeLine {
  actCode?: string;
  amount: string;
  number?: string;
  outcome: 'success';
}

export interface RamqExchangeLineFailure extends ExchangeLine {
  actCode?: string;
  outcome: 'failure';
}

export type RamqExchangeLine = RamqExchangeLineSuccess | RamqExchangeLineFailure;
