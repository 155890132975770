export const ADD_DOCTOR = 'addDoctorToUserPreferences';
export function addDoctorToUserPreferences(document) {
  return {
    type: ADD_DOCTOR,
    document
  };
}

export const ADD_DOCTOR_FAILURE = 'addDoctorFailure';
export function addDoctorToUserPreferenceFailure(error) {
  return {
    type: ADD_DOCTOR_FAILURE,
    error
  };
}

export const REMOVE_DOCTOR = 'removeDoctorFromUserPreferences';
export function removeDoctorFromUserPreferences(document) {
  return {
    type: REMOVE_DOCTOR,
    document
  };
}

export const REMOVE_DOCTOR_FAILURE = 'removeDoctorFailure';
export function removeDoctorFromUserPreferenceFailure(error) {
  return {
    type: REMOVE_DOCTOR_FAILURE,
    error
  };
}

export const GET_DOCTORS = 'getDoctors';
export function getDoctorPreferences() {
  return {
    type: GET_DOCTORS
  };
}

export const GET_DOCTORS_SUCCESS = 'getDoctorsSuccess';
export function getDoctorsPreferencesSuccess(doctors) {
  return {
    type: GET_DOCTORS_SUCCESS,
    doctors
  };
}

export const GET_DOCTORS_FAILURE = 'getDoctorsFailure';
export function getDoctorsPreferencesFailure(error) {
  return {
    type: GET_DOCTORS_FAILURE,
    error
  };
}

export const UPDATE_DOCTOR_COLLECTION = 'updateDoctorsFromUserPreferences';
export const updateDoctorsFromUserPreferences = (documentMap) => ({
  type: UPDATE_DOCTOR_COLLECTION,
  documentMap
});

export const UPDATE_DOCTOR_COLLECTION_SUCCESS = 'updateDoctorsSuccess';
export const updateDoctorsFromUserPreferencesSuccess = () => ({
  type: UPDATE_DOCTOR_COLLECTION_SUCCESS
});

export const UPDATE_DOCTOR_COLLECTION_FAILURE = 'updateDoctorsFailure';
export const updateDoctorsFromUserPreferencesFailure = (error) => ({
  type: UPDATE_DOCTOR_COLLECTION_FAILURE,
  error
});

export const GET_RESIDENTS = 'getResidents';
export function getResidentsPreferences() {
  return {
    type: GET_RESIDENTS
  };
}

export const GET_RESIDENTS_SUCCESS = 'getResidentsSuccess';
export function getResidentsPreferencesSuccess(residents) {
  return {
    type: GET_RESIDENTS_SUCCESS,
    residents
  };
}

export const GET_RESIDENTS_FAILURE = 'getResidentsFailure';
export function getResidentsPreferencesFailure(error) {
  return {
    type: GET_RESIDENTS_FAILURE,
    error
  };
}

export const UPDATE_RESIDENT_COLLECTION = 'updateResidentsFromUserPreferences';
export const updateResidentsFromUserPreferences = (documentMap) => ({
  type: UPDATE_RESIDENT_COLLECTION,
  documentMap
});

export const UPDATE_RESIDENT_COLLECTION_SUCCESS = 'updateResidentsSuccess';
export const updateResidentsFromUserPreferencesSuccess = () => ({
  type: UPDATE_RESIDENT_COLLECTION_SUCCESS
});

export const UPDATE_RESIDENT_COLLECTION_FAILURE = 'updateResidentsFailure';
export const updateResidentsFromUserPreferencesFailure = (error) => ({
  type: UPDATE_RESIDENT_COLLECTION_FAILURE,
  error
});

export const ADD_RESIDENT = 'addResidentToUserPreferences';
export function addResidentToUserPreferences(document) {
  return {
    type: ADD_RESIDENT,
    document
  };
}

export const ADD_RESIDENT_FAILURE = 'addResidentFailure';
export function addResidentToUserPreferenceFailure(error) {
  return {
    type: ADD_RESIDENT_FAILURE,
    error
  };
}

export const REMOVE_RESIDENT = 'removeResidentFromUserPreferences';
export function removeResidentFromUserPreferences(document) {
  return {
    type: REMOVE_RESIDENT,
    document
  };
}

export const REMOVE_RESIDENT_FAILURE = 'removeResidentFailure';
export function removeResidentFromUserPreferenceFailure(error) {
  return {
    type: REMOVE_RESIDENT_FAILURE,
    error
  };
}
