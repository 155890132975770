import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { isEmpty } from 'lodash';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { defaultFormatMomentDate } from '../../../../shared/utils/dateTime/dateTimeUtils';
import PrintHeader from '../PrintHeader/PrintHeader';
import IncomeStatementRenderer from './IncomeStatementRenderer/IncomeStatementRenderer';
import { selectUserProfileInContext } from '../../User/selectors';
import FullScreenLoading from '../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { fetchStatementsForDoctor } from '../../../infrastructure/api/ReportAPI';
import calculateORFromStatementDate from '../../../../shared/periods/common/calculateORFromActDate';
import Period from '../../../../shared/periods/common/Period';

export const styles = (theme) => ({
  root: {
    overflowX: 'auto',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  }
});

export class IncomeStatement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null,
      loading: false,
      statements: []
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    if (this.props.user && this.props.user.practiceNumber !== null) {
      this.fetchStatements();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user && this.props.user && prevProps.user.practiceNumber !== this.props.user.practiceNumber) {
      if (this.props.user.practiceNumber !== null) {
        this.fetchStatements();
      }
    }
  }

  changeSelectedDate = (value) => {
    this.setState({
      selectedDate: value
    });
  };

  assignComponentRef = (el) => {
    this.componentRef = el;
  };

  async fetchStatements() {
    const { user } = this.props;
    this.setState({ loading: true });

    try {
      const { data } = await fetchStatementsForDoctor({ practiceNumber: user.practiceNumber });
      if (!this._isMounted) return;

      this.setState({ statements: data });
    } finally {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  }

  renderData() {
    const { classes } = this.props;
    const { selectedDate, statements, loading } = this.state;

    if (loading) {
      return <FullScreenLoading />;
    }

    if (isEmpty(statements)) {
      return <Typography>Aucun état des revenus trouvé</Typography>;
    }

    return (
      <Paper className={classes.root}>
        <IncomeStatementRenderer
          ref={this.assignComponentRef}
          selectedDate={selectedDate ? defaultFormatMomentDate(selectedDate) : selectedDate}
          statements={statements}
        />
      </Paper>
    );
  }

  render() {
    const { selectedDate } = this.state;
    const { user } = this.props;

    const or = (selectedDate && `(OR ${calculateORFromStatementDate(Period.fromStatementDate(selectedDate))})`) || '';
    const formattedSelectedDate = (selectedDate && selectedDate.format('YYYY-MM-DD')) || '';
    const title = `États des revenus ${formattedSelectedDate} 
      ${or} - ${this.props.user?.title} ${user?.firstName} ${user?.lastName}.pdf`;

    return (
      <>
        <PrintHeader
          componentToPrintRef={() => this.componentRef}
          SelectorComponents={[
            () => (
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <DatePicker
                  emptyLabel="Choisir date début"
                  value={selectedDate}
                  disableFuture
                  format="YYYY-MM-DD"
                  leftArrowIcon={<PrevArrowIcon />}
                  rightArrowIcon={<NextArrowIcon />}
                  onChange={this.changeSelectedDate}
                />
              </MuiPickersUtilsProvider>
            )
          ]}
          title={title}
        />

        {this.renderData()}
      </>
    );
  }
}

IncomeStatement.propTypes = {
  user: PropTypes.object
};

export const mapStateToProps = createStructuredSelector({
  user: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps), withStyles(styles))(IncomeStatement);
