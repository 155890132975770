import OutsideRamqBillingManager from '../../../../../../shared/domain/activity/act/patientInformation/outsideRamq/OutsideRamqBillingManager';
import { OutsideRamqPatientType } from '../../../../../../shared/domain/activity/act/model/Act';

export const PATIENT_TYPES_LIST = [
  {
    label: 'Réfugié',
    value: OutsideRamqPatientType.REFUGEE
  },
  {
    label: 'Hors province',
    value: OutsideRamqPatientType.OUTSIDE_RAMQ
  },
  {
    label: 'Assurance privée',
    value: OutsideRamqPatientType.PRIVATE_INSURANCE
  },
  {
    label: 'Militaire',
    value: OutsideRamqPatientType.MILITARY
  },
  {
    label: 'Détenu',
    value: OutsideRamqPatientType.PRISONER
  },
  {
    label: 'Étudiant étranger',
    value: OutsideRamqPatientType.ABROAD_STUDENT
  },
  {
    label: 'Facturé au patient',
    value: OutsideRamqPatientType.BILLED
  }
];

export const BILLING_MANAGER_CHECKBOX_VALUES = [
  {
    label: 'Oui',
    value: OutsideRamqBillingManager.MEDYX
  },
  {
    label: 'Non',
    value: OutsideRamqBillingManager.DOCTOR
  }
];
