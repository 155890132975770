import { get } from 'lodash';
import { DirectoryPhysicianType } from 'app/containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import { PROFESSIONAL_REFERENCE } from '../../../../shared/ramq/requiredDetails/professionalReferenceConstants';
import {
  DoctorAutocompleteField,
  ProfessionalTypeSelectorField,
  SingleLineField
} from '../../../components/Form/Fields';
import { AssociatedField, RequiredDetail } from '../requiredDetail.type';

const DOCTOR_PROFESSIONAL_TYPES = ['1', '15', '16'];

const shouldDoctorFieldBeHidden = (code) => !DOCTOR_PROFESSIONAL_TYPES.includes(extractProfessionalTypeFromCode(code));

const shouldOtherProfessionalFieldsBeHidden = (code) =>
  DOCTOR_PROFESSIONAL_TYPES.includes(extractProfessionalTypeFromCode(code));

const extractProfessionalTypeFromCode = (code) => get(code, `${PROFESSIONAL_REFERENCE}.professionalType`, '1');

const associatedFields: AssociatedField[] = [
  {
    getName: (index) => `codes[${index}].${PROFESSIONAL_REFERENCE}.practiceNumber`,
    label: 'No. du prof.',
    notRequired: true,
    shouldFieldBeHidden: shouldOtherProfessionalFieldsBeHidden,
    type: SingleLineField
  },
  {
    getName: (index) => `codes[${index}].${PROFESSIONAL_REFERENCE}.lastName`,
    label: 'Nom du prof.',
    notRequired: true,
    shouldFieldBeHidden: shouldOtherProfessionalFieldsBeHidden,
    type: SingleLineField
  },
  {
    getName: (index) => `codes[${index}].${PROFESSIONAL_REFERENCE}.firstName`,
    label: 'Prénom du prof.',
    notRequired: true,
    shouldFieldBeHidden: shouldOtherProfessionalFieldsBeHidden,
    type: SingleLineField
  },
  {
    getName: (index) => `codes[${index}].${PROFESSIONAL_REFERENCE}.doctor`,
    label: 'Professionnel en référence',
    shouldFieldBeHidden: shouldDoctorFieldBeHidden,
    type: DoctorAutocompleteField,
    props: {
      type: DirectoryPhysicianType.PHYSICIAN
    }
  },
  {
    getName: (index) => `codes[${index}].${PROFESSIONAL_REFERENCE}.professionalType`,
    label: 'Profession',
    type: ProfessionalTypeSelectorField,
    props: {
      defaultValue: '1'
    }
  }
];

const referenceProfessional: RequiredDetail = { associatedFields };

export default referenceProfessional;
