import { intersection } from 'lodash';
import moment from 'moment';
import {
  additionalContextElements,
  additionalContextElementsForDoctorOnly,
  legacyCovidContextElements,
  lettreEntente241ContextElements
} from '../LegacyContextElementsSelector/constants';
import isDefined from '../../../../shared/utils/isDefined';
import {
  LETTRE_ENTENTE_241_EFFECTIVE_DATE,
  TELEMEDECINE_PAR_TELEPHONE,
  TELEMEDECINE_PAR_VISIOCONSULTATION
} from '../../../../shared/ramq/contextElements/lettreEntente241ContextElements';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { ACT_ADDITIONAL_CONTEXT_ELEMENTS } from '../../../../userCustomization/featureNames';
import AuthUserDomain from '../../../../shared/domain/authentication/AuthUser';
import Permission from '../../../../shared/domain/authorization/model/Permission';
import UserUtils from '../../../../utils/userUtils';

const allowedCovidKeys = [TELEMEDECINE_PAR_TELEPHONE, TELEMEDECINE_PAR_VISIOCONSULTATION];
export const getCovidContextElements = (date) => {
  if (moment(date).isBefore(LETTRE_ENTENTE_241_EFFECTIVE_DATE)) {
    return legacyCovidContextElements;
  }

  if (UserUtils.isAdminOrAgent()) {
    return lettreEntente241ContextElements;
  }

  return lettreEntente241ContextElements.filter((element) => allowedCovidKeys.includes(element.key));
};

export const getContextElementsAccordingToUserSecurity = (user: AuthUserDomain) => {
  if (user.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS)) {
    return additionalContextElements;
  }

  return additionalContextElementsForDoctorOnly;
};

export const getContextElementsKey = (contextElement) => contextElement.key;
export const atLeastOneAdditionalContextElementIsSelected = (reduxFormInput) => {
  const additionalContextElementsKeys = additionalContextElements.map(getContextElementsKey);
  return atLeastOneContextElementIsSelected(reduxFormInput, additionalContextElementsKeys);
};
export const atLeastOneCovidContextElementIsSelected = (reduxFormInput) => {
  const contextElements = legacyCovidContextElements.concat(lettreEntente241ContextElements);
  const covidContextElementsKeys = contextElements.map(getContextElementsKey);
  return atLeastOneContextElementIsSelected(reduxFormInput, covidContextElementsKeys);
};
export const atLeastOneContextElementIsSelected = (reduxFormInput, contextElements) =>
  isDefined(reduxFormInput.value) && intersection(reduxFormInput.value, contextElements).length > 0;

export const userCanEnterAdditionalContextElements = (user: AuthUserDomain) => {
  const featureIsEnabled = getFeatureConfig(ACT_ADDITIONAL_CONTEXT_ELEMENTS, true);

  return user.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS) || featureIsEnabled;
};
