import {
  getLaborDropActRelatedMixteSuffix,
  getLaborDropActRelatedMixteDispensaryArea,
  isLaborDropActCode,
  LABOR_DROP_ACT_CODES
} from './laborDrop';
import { getReassignmentActRelatedMixteSuffix, isReassignmentActCode, REASSIGNMENT_ACT_CODES } from './reassignment';
import {
  getUnusualPracticeActRelatedMixteSuffix,
  isUnusualPracticeActCode,
  UNUSUAL_PRACTICE_ACT_CODES
} from './unusualPractice';

const UNDERSTANDABLE_LETTER_241_CODES = [
  ...UNUSUAL_PRACTICE_ACT_CODES,
  ...REASSIGNMENT_ACT_CODES,
  ...LABOR_DROP_ACT_CODES
];

const isUnderstandableLetter241ActCodes = ({ code }: { code: string }) =>
  UNDERSTANDABLE_LETTER_241_CODES.includes(code);

const hasSomeUnderstandableLetter241Codes = ({ codes }: { codes: any[] }) =>
  codes.some(isUnderstandableLetter241ActCodes);

function getUnderstandableLetter241ActCodesRelated(acts: any[]) {
  return acts.reduce(
    (acc: any[], { codes = [] }: any) => acc.concat(codes.filter(isUnderstandableLetter241ActCodes)),
    []
  );
}

export function hasActivitiesContaining241UnderstandableLetterCode(acts: any[]): boolean {
  return getUnderstandableLetter241ActCodesRelated(acts).length > 0;
}

export function getDefaultMixtesuffixAccordingTo241UnderstandableLetterRules(acts: any[]) {
  const [{ codes = [], place }] = acts.filter(hasSomeUnderstandableLetter241Codes);

  const unusualPracticeCodesInAct = codes.filter(isUnusualPracticeActCode);
  const reassignmentCodesInAct = codes.filter(isReassignmentActCode);
  const laborDropCodesInAct = codes.filter(isLaborDropActCode);

  if (unusualPracticeCodesInAct.length > 0) {
    return {
      suffix: getUnusualPracticeActRelatedMixteSuffix(unusualPracticeCodesInAct[0])
    };
  }

  if (reassignmentCodesInAct.length > 0) {
    return {
      suffix: getReassignmentActRelatedMixteSuffix(reassignmentCodesInAct[0])
    };
  }

  if (laborDropCodesInAct.length > 0) {
    return {
      suffix: getLaborDropActRelatedMixteSuffix(laborDropCodesInAct[0], place),
      activityArea: getLaborDropActRelatedMixteDispensaryArea(laborDropCodesInAct[0])
    };
  }

  return {
    suffix: undefined
  };
}
