import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreActions from '../../../actions/MoreActions/MoreActions';

export const styles = () => ({
  bold: {
    fontWeight: 'bold'
  },
  level0: {
    paddingLeft: '0px'
  },
  level1: {
    paddingLeft: '10px'
  },
  level2: {
    paddingLeft: '20px'
  },
  level3: {
    paddingLeft: '30px'
  },
  level4: {
    paddingLeft: '40px'
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center'
  }
});

export class CollapsableCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.initiallyOpen
    };

    this.handleToggleCollapsable = this.handleToggleCollapsable.bind(this);
  }

  handleToggleCollapsable() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  renderActions(onEdit, onRemove) {
    const actions = [];

    if (onEdit) {
      actions.push({
        key: 'edit-action-key',
        labelKey: 'editableCollectionWithCategories.edit',
        clickEvent: onEdit,
        Icon: EditIcon
      });
    }

    if (onRemove) {
      actions.push({
        key: 'remove-action-key',
        labelKey: 'editableCollectionWithCategories.delete',
        clickEvent: onRemove,
        Icon: DeleteIcon
      });
    }
    return actions;
  }

  render() {
    const { label, level, onDelete, onEdit, classes, children } = this.props;

    const actions = this.renderActions(onEdit, onDelete);

    return (
      <List dense>
        <ListItem divider button onClick={this.handleToggleCollapsable}>
          <ListItemText classes={{ primary: `${classes.bold} ${classes[`level${level}`]}` }} primary={label} />
          <ListItemSecondaryAction classes={{ root: classes.actionsContainer }}>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
            <MoreActions actions={actions} />
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </List>
    );
  }
}

CollapsableCategory.defaultProps = {
  initiallyOpen: true,
  onDelete: undefined,
  onEdit: undefined,
  level: 0
};

CollapsableCategory.propTypes = {
  initiallyOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  level: PropTypes.number,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
};

export default withStyles(styles)(CollapsableCategory);
