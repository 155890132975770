import Collection from '../../../../collection/Collection';
import { ActCode, NatureType } from './Act';

class ActCodes extends Collection<ActCode> {
  public isContainsSome(...codes: string[]): boolean {
    return this.data.some(({ code }) => codes.includes(code));
  }

  public isContainsPdt(): boolean {
    return this.data.some(({ natureType }) => natureType === NatureType.PDT);
  }
}

export default ActCodes;
