import { StyleRules, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { formatUnixToTimeValue } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import SelectedActCodes from '../../../../containers/ActForm/SelectedActCodes/SelectedActCodes';
import { PatientActivity } from './ActsHistoryWithNam';

export const styles: StyleRules = {
  paddingBottom: {
    paddingBottom: 2
  },
  componentPadding: {
    paddingBottom: 10
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start'
  }
};

interface Props {
  act: PatientActivity;
  classes?: any;
}

const ActHistory: React.FunctionComponent<Props> = ({ act, classes }: Props) => {
  moment.relativeTimeRounding(Math.floor);

  return (
    <div className={classes.componentPadding}>
      <div className={classNames(classes.container, classes.paddingBottom)}>
        <div className={classes.container}>
          <Typography key={`dateAndTime-${act.id}`} variant="caption">
            {moment(act.startedAt).format('LLL')}
          </Typography>
          {act.endedAt && (
            <Typography key={`end-${act.id}`} variant="caption">
              -
              {moment(act.startedAt).isSame(act.endedAt, 'day')
                ? formatUnixToTimeValue(act.endedAt)
                : moment(act.endedAt).format('LLL')}
            </Typography>
          )}
        </div>
        <div className={classNames(classes.container, classes.paddingBottom)}>
          <Typography key={`date-${act.id}`} variant="caption">
            {moment(act.date).fromNow()}
          </Typography>
        </div>
      </div>
      <div className={classes.row}>
        <SelectedActCodes key={`codes-${act.id}`} actCodes={act.codes} toolTipPlacement="bottom-start" />
      </div>
    </div>
  );
};

export default withStyles(styles)(ActHistory);
