import React from 'react';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { StyledComponentProps, Theme } from '@material-ui/core';
import { WrappedFieldArrayProps } from 'redux-form';
import useTranslate from 'application/hooks/use-translate';
import FormHelperText from '@material-ui/core/FormHelperText';
import Portal from '@material-ui/core/Portal';
import DayUnitController from '../DayUnitController';
import MixteDayUnitDetailCode from '../MixteDayUnitDetailCode/MixteDayUnitDetailCode';

export const styles: StyleRulesCallback<Theme, Props> = ({ spacing }) => ({
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: `0 ${spacing(1)}px`
  },
  errorText: {
    textAlign: 'center',
    marginBottom: `${spacing(1)}px`
  }
});

export interface Props extends StyledComponentProps, WrappedFieldArrayProps<any> {
  canAddCode;
  errorContainer: any;
}

export const MixteDayUnitDetailCodes: React.FunctionComponent<Props> = ({
  fields: reduxFormFields,
  meta: { error },
  errorContainer,
  canAddCode,
  classes
}: Props) => {
  const translate = useTranslate();
  return (
    <>
      <div className={classes!.header}>
        <Button
          data-testid="add-button"
          variant="contained"
          size="small"
          color="secondary"
          disabled={!canAddCode}
          onClick={() => reduxFormFields.push(DayUnitController.createCodeLine(reduxFormFields.getAll() as any))}
        >
          <AddIcon />
          {translate('codeSelector.add')}
        </Button>
      </div>
      {reduxFormFields.map((fieldName, fieldIndex, fields) => (
        <MixteDayUnitDetailCode key={fieldName} name={fieldName} index={fieldIndex} fields={fields} />
      ))}

      {error && (
        <Portal container={errorContainer}>
          <FormHelperText error data-testid="error-message" style={{ textAlign: 'center', margin: '0 0 1em 0' }}>
            {error}
          </FormHelperText>
        </Portal>
      )}
    </>
  );
};

export default withStyles(styles)(MixteDayUnitDetailCodes);
