import { flatMap, intersection, keys } from 'lodash';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import concat from 'lodash/concat';
import reduce from 'lodash/reduce';
import moment from 'moment-timezone';
import { COVID_CONTEXT_ELEMENT_SUFFIX_MAPPING } from '../../../../shared/periods/common/constants';
import { clinicalSupervisionCodes } from '../../../../shared/ramq/teachingCodes/constants';
import { getStartOfDay, getStrictHour } from '../../../../shared/utils/dateTime/dateTimeUtils';
import RAMQCodes from '../../../../shared/ramq/RamqCodes';

const preventMixteGenerationCodes = [
  '9295',
  '19053',
  '19342',
  '19870',
  '19872',
  '19903',
  '42155',
  '42156',
  '42157',
  '42158',
  '42159',
  '42160',
  '42161',
  '42162',
  '42163',
  '42164',
  '42165',
  '42166',
  '42167',
  '42168',
  '42169',
  '42170',
  '42171',
  '42172',
  '42173'
];

const preventMixteGenerationWhenCodesBilledAlone = ['19904', '19908', '19910'];

export const hasActivitiesThatPreventsMixteGeneration = (acts) => {
  const activityCodes = flattenActivityCodesFromActs(acts);

  return activityCodes.some((code) => {
    if (preventMixteGenerationCodes.includes(code)) {
      return true;
    }

    if (activityCodes.length === 1 && preventMixteGenerationWhenCodesBilledAlone.includes(code)) {
      return true;
    }

    const ramqCode = RAMQCodes.get(code);
    if (!ramqCode) {
      return false;
    }

    if (
      RAMQCodes.getTimedAttribute(ramqCode, 'isPainDay', acts[0].date) ||
      RAMQCodes.getTimedAttribute(ramqCode, 'isPainDayExclusive', acts[0].date)
    ) {
      return true;
    }

    return false;
  });
};

export const flattenActivityCodesFromActs = (acts) =>
  reduce(acts, (accumulator, act) => concat(accumulator, flattenActivityCodesFromAct(act)), []);

export const flattenActivityCodesFromAct = (act) => map(act.codes, ({ code }) => code);

export const hasActivitiesInClinicalSupervision = (acts) =>
  findIndex(acts, (act) => containsClinicalSupervisionCodes(act)) !== -1;

const containsClinicalSupervisionCodes = (act) =>
  findIndex(act.codes, (code) => clinicalSupervisionCodes.includes(code.code)) !== -1;

export const isInTheMorning = (unixTime) =>
  unixTime ? moment(unixTime).isBetween(startAMmixteHour(unixTime), midMixteHour(unixTime), null, '[)') : false;

export const isInTheAfternoon = (unixTime) =>
  unixTime ? moment(unixTime).isBetween(midMixteHour(unixTime), endPMmixteHour(unixTime), null, '(]') : false;

const startAMmixteHour = (unixTime) => getStrictHour(moment(unixTime), 8);
const midMixteHour = (unixTime) => getStrictHour(moment(unixTime), 12);
const endPMmixteHour = (unixTime) => getStrictHour(moment(unixTime), 15);

const isBeforeRAMQAMHour = (unixTime) => (unixTime ? moment(unixTime).isBefore(startAMmixteHour(unixTime)) : false);

const isAfterRAMQAMHour = (unixTime) => (unixTime ? moment(unixTime).isAfter(startAMmixteHour(unixTime)) : false);

const isBeforeRAMQPMHour = (unixTime) => (unixTime ? moment(unixTime).isBefore(endPMmixteHour(unixTime)) : false);

const isAfterRAMQPMHour = (unixTime) => (unixTime ? moment(unixTime).isAfter(endPMmixteHour(unixTime)) : false);

export const overlapsMorningAndAfternoon = (startUnixTime, endUnixTime) =>
  (isBeforeRAMQAMHour(startUnixTime) && isInTheAfternoon(endUnixTime)) ||
  (isInTheMorning(startUnixTime) && isAfterRAMQPMHour(endUnixTime)) ||
  (isBeforeRAMQAMHour(startUnixTime) && isAfterRAMQPMHour(endUnixTime));

export const overlapsMorning = (startUnixTime, endUnixTime) =>
  isBeforeRAMQAMHour(startUnixTime) && isAfterRAMQAMHour(endUnixTime);

export const overlapsAfternoon = (startUnixTime, endUnixTime) =>
  isBeforeRAMQPMHour(startUnixTime) && isAfterRAMQPMHour(endUnixTime);

export const createMixteDayDate = (date) => getStartOfDay(date).valueOf();

const getIntersectionWithSupportedCovidContextElements = (contextElements) =>
  intersection(contextElements, keys(COVID_CONTEXT_ELEMENT_SUFFIX_MAPPING));

const flattenContextElementsFromActs = (acts) => flatMap(acts, ({ contextElements }) => contextElements);

export const hasActivitiesContainingLegacyCovidContextElement = (acts) =>
  getIntersectionWithSupportedCovidContextElements(flattenContextElementsFromActs(acts)).length > 0;

export const getDefaultMixteCodeAccordingToLegacyCovidContextElement = (acts) => {
  const foundContextElements = getIntersectionWithSupportedCovidContextElements(
    flattenContextElementsFromActs(acts)
  )[0];

  return COVID_CONTEXT_ELEMENT_SUFFIX_MAPPING[foundContextElements];
};
