import moment from 'moment-timezone';
import identity from 'lodash/identity';
import { CMS_MEDICAL_SERVICES_MIXTE_CODES } from '../../../../shared/periods/common/constants';
import isDefined from '../../../../shared/utils/isDefined';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import { RAMQPlaces } from '../../../../shared/ramq/RamqPlaces';

export const ERROR_MESSAGE_CANNOT_USE_IN_CMS_CLINICAL_SERVICE = (code) =>
  `Vous ne pouvez pas utiliser le code ${code} dans un établissement CMS`;
export const ERROR_MESSAGE_CANNOT_USE_IN_NON_CMS_CLINICAL_SERVICE = (code) =>
  `Le code ${code} est utilisable uniquement dans un établissement CMS`;
export const ERROR_MESSAGE_CODE_FROM_LU238_IS_EXPIRED = (code) =>
  `Le code ${code} fait partie de la L/E 238 et ne peut être facturé après le 6 décembre`;

const isCodeOfLU238AndUsedAfterExpiration = ({ code, date }) => {
  if (!isDefined(code)) {
    return false;
  }

  const LU238Codes = ['xxx320', 'xxx321', 'xxx328', 'xxx325', 'xxx326', 'xxx327', 'xxx335'];
  const genericCode = code.replace(/^.{3}/g, 'xxx');

  if (!LU238Codes.includes(genericCode)) {
    return false;
  }

  return moment(date).isAfter('2020-12-06');
};

function checkIfCodeCanBeBillThisDay(placeNumber, periodCode) {
  if (RAMQPlaces.isCms(placeNumber) && periodCode.code && !CMS_MEDICAL_SERVICES_MIXTE_CODES.includes(periodCode.code)) {
    return { code: ERROR_MESSAGE_CANNOT_USE_IN_CMS_CLINICAL_SERVICE(periodCode.code) };
  }

  if (!RAMQPlaces.isCms(placeNumber) && periodCode.code && CMS_MEDICAL_SERVICES_MIXTE_CODES.includes(periodCode.code)) {
    return { code: ERROR_MESSAGE_CANNOT_USE_IN_NON_CMS_CLINICAL_SERVICE(periodCode.code) };
  }

  if (isCodeOfLU238AndUsedAfterExpiration(periodCode)) {
    return { code: ERROR_MESSAGE_CODE_FROM_LU238_IS_EXPIRED(periodCode.code) };
  }

  return undefined;
}

function checkIfAnyPeriodCodeCanBeBillThisDay(day, placeNumber) {
  const dayCodeError = day.codes
    .map((periodCode) => checkIfCodeCanBeBillThisDay(placeNumber, periodCode))
    .filter((codeError) => identity(codeError));

  return dayCodeError.length > 0
    ? {
        codes: {
          _error: dayCodeError[0].code
        }
      }
    : undefined;
}

const validateMixte = (status) => (values) => {
  const errors = {};

  const daysError = values.days.map((day) => {
    const { perdiems = [] } = day;

    if (isDefined(day.codes) && status === ActivityStatus.SENT) {
      if (isDayDetailHasBeenPoluted(day)) {
        if (perdiems.length === 0) {
          return {
            codes: { _error: 'Vous devez selectionner au moins un demi-perdiem pour cette période' }
          };
        }

        if (day.codes.some(({ duration, code }) => !duration || !code)) {
          return {
            codes: { _error: 'Toutes les périodes selectionnés doivent avoir un code et une durée' }
          };
        }
      }

      if (!isDayDetailHasBeenPoluted(day) && perdiems.length > 0) {
        return {
          codes: { _error: 'Vous devez selectionner au moins un code pour cette période' }
        };
      }

      return checkIfAnyPeriodCodeCanBeBillThisDay(day, values.placeNumber);
    }

    if (isDefined(day.code)) {
      return checkIfCodeCanBeBillThisDay(values.placeNumber, day);
    }

    return undefined;
  });

  if (daysError.some((err) => err !== undefined)) {
    errors.days = daysError;
  }

  return errors;
};

function isDayDetailHasBeenPoluted(day) {
  const { codes = [] } = day;

  if (codes.length > 1) {
    return true;
  }

  if (codes.length === 1) {
    const { code, duration } = codes[0];

    if (isDefined(code) && isDefined(duration)) {
      return true;
    }
  }

  return false;
}

export default validateMixte;
