import { find, get } from 'lodash';
import { NumberField, SelectorField } from '../../../components/Form/Fields';
import { SPECIAL_SITUATION_CODES } from './constants';

const getSpecialSituationLabelByValue = (value) => find(SPECIAL_SITUATION_CODES, { value });

const shouldFieldBeHidden = ({ specialSituation }) => specialSituation !== 'other';

const associatedFields = [
  {
    label: 'Code référence situations spéciales',
    getName: (index) => `codes[${index}].specialSituation`,
    type: SelectorField,
    list: SPECIAL_SITUATION_CODES
  },
  {
    label: 'Veuillez spécifier si disponible',
    getName: (index) => `codes[${index}].specialSituationSpecificCode`,
    notRequired: true,
    shouldFieldBeHidden,
    type: NumberField
  }
];

const summaryRender = (code) => [
  {
    name: 'specialSituation',
    value: getSpecialSituationLabelByValue(get(code, 'specialSituation', 'none')).label
  }
];

export default { associatedFields, summaryRender };
