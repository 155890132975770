import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import english from './assets/english.png';
import french from './assets/french.png';

const PlayStoreBadge = () => {
  const intl = useIntl();

  const logo = useMemo(() => {
    switch (intl.locale) {
      case 'fr':
        return french;
      case 'en':
      default:
        return english;
    }
  }, [intl.locale]);

  const handleClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.ingeno.medyx', '_blank');
  };

  return (
    <div onClick={handleClick} className="cursor-pointer">
      <img src={logo} alt="App store" className="w-[180px]" />
    </div>
  );
};

export default PlayStoreBadge;
