import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import createManualAdminNote from 'app/user/admin/createManualAdminNote';
import { billActivity, cancelActivity } from '../../../../Ramq/actions';
import { updateTravelExpenses } from '../../../../TravelExpenses/actions';
import { duplicateActivity } from '../../../../Activities/actions';
import AdminActions from '../AdminActions/AdminActions';
import { USERS_ROUTE, ACTIVITIES_ROUTE, DUPLICATE_TRAVEL_EXPENSES_ROUTE } from '../../../../Navigation/constants';

export function TravelExpensesAdminActions({
  activity,
  activeBills,
  onUpdate,
  onAddAdminNote,
  onBillRamq,
  onCancelRamq,
  onDuplicate
}) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <AdminActions
      activeBills={activeBills}
      activity={activity}
      onUpdate={onUpdate}
      onAddAdminNote={onAddAdminNote}
      onBillRamq={onBillRamq}
      onCancelRamq={onCancelRamq}
      onDuplicate={() => {
        onDuplicate();
        navigate({
          pathname: `/${USERS_ROUTE}/${activity.userId}/${ACTIVITIES_ROUTE}/${DUPLICATE_TRAVEL_EXPENSES_ROUTE}`,
          search: location.search
        });
      }}
    />
  );
}

TravelExpensesAdminActions.propTypes = {
  activity: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAddAdminNote: PropTypes.func.isRequired,
  onBillRamq: PropTypes.func.isRequired,
  onCancelRamq: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  activeBills: PropTypes.array.isRequired
};

export const mapDispatchToProps = (dispatch, props) => ({
  onUpdate: (changes) => {
    dispatch(updateTravelExpenses(props.activity, changes));
  },
  onAddAdminNote: (adminNote) => {
    const adminNotes = props.activity.adminNotes || [];
    adminNotes.push(createManualAdminNote(adminNote));
    dispatch(updateTravelExpenses(props.activity, { adminNotes }));
  },
  onBillRamq: () => {
    dispatch(billActivity(props.activity.id));
  },
  onCancelRamq: () => {
    dispatch(cancelActivity(props.activity.id));
  },
  onDuplicate: () => {
    dispatch(duplicateActivity(props.activity));
  }
});

export default compose(connect(null, mapDispatchToProps))(TravelExpensesAdminActions);
