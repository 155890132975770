import Act from 'shared/domain/activity/act/model/Act';
import {
  ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS,
  ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS,
  GREATER_THAN_4_HOURS
} from 'shared/ramq/contextElements/globalContextElements';
import messages from 'app/containers/ActForm/messages';

const NO_ERRORS = {};

export default ({ contextElements = [], remp }: Act) => {
  if (contextElements.length === 0 || !remp) return NO_ERRORS;

  const durationGreaterThanContextElement = contextElements.find((contextElement) =>
    [
      GREATER_THAN_4_HOURS,
      ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS,
      ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS
    ].includes(contextElement)
  );

  if (durationGreaterThanContextElement) {
    return {
      contextElements: `SVP enlever l'élément de contexte ${messages[durationGreaterThanContextElement].defaultMessage} car vous êtes en remplacement et n'y avez pas droit`
    };
  }

  return NO_ERRORS;
};
