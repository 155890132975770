import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { CircularProgress, MenuItem, Select, withStyles, WithStyles } from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment, { Moment } from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { connect } from 'react-redux';
import UserBillingType from 'shared/domain/user/UserBillingType';
import useTranslate from 'application/hooks/use-translate';
import ReportAPI from 'app/infrastructure/api/ReportAPI';
import classNames from 'classnames';
import User from '../../../../../shared/domain/user/User';
import { displayGlobalError } from '../../../Act/actions';

export const styles = (theme) =>
  ({
    dateMessage: {
      marginRight: theme.spacing(1)
    },
    openPDFButton: {
      marginLeft: theme.spacing(4)
    },
    billingAccountSelector: {
      marginLeft: theme.spacing(4)
    }
  }) as const;

export interface OwnProps {
  user: User;
  selectedDate: Moment | null;
  onSelectedDateChange: (date: Moment | null) => void;
  selectedBillingAccount: string;
  onSelectedBillingAccountChange: (billingAccount: string) => void;
}

export interface ReduxDispatchProps {
  // eslint-disable-next-line no-unused-vars
  onDisplayGlobalError: (error: string) => any;
}

export interface Props extends OwnProps, WithStyles<typeof styles>, ReduxDispatchProps {}

export const ReceivableAccountsReportForm: React.FunctionComponent<Props> = (props: Props) => {
  const translate = useTranslate('billingType');
  const translateError = useTranslate('errors');
  const {
    user,
    onDisplayGlobalError,
    selectedDate,
    onSelectedDateChange,
    selectedBillingAccount,
    onSelectedBillingAccountChange,
    classes
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const downloadFile = async () => {
    if (!selectedDate) return;

    const options = {
      date: selectedDate.valueOf(),
      userId: user.id,
      groupId:
        selectedBillingAccount && selectedBillingAccount !== UserBillingType.PRIVATE
          ? selectedBillingAccount
          : undefined
    };

    setIsLoading(true);
    ReportAPI.downloadReceivableAccounts(options)
      .catch(() => {
        onDisplayGlobalError(translateError('generic'));
      })
      .finally(() => setIsLoading(false));
  };

  const userPools = user?.pools ?? [];

  return (
    <>
      <Typography className={classes.dateMessage}>Date:</Typography>

      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker
          emptyLabel="Choisir une date"
          value={selectedDate}
          disableFuture
          format="YYYY-MM-DD"
          leftArrowIcon={<PrevArrowIcon />}
          rightArrowIcon={<NextArrowIcon />}
          onChange={(date) => onSelectedDateChange(date as Moment)}
        />
      </MuiPickersUtilsProvider>

      {user.billingType === UserBillingType.POOL && userPools.length > 0 && (
        <Select
          className={classes.billingAccountSelector}
          autoWidth={false}
          value={selectedBillingAccount}
          onChange={({ target }) => onSelectedBillingAccountChange(target.value as string)}
        >
          <MenuItem key={UserBillingType.PRIVATE} value={UserBillingType.PRIVATE}>
            {translate('private')}
          </MenuItem>
          {userPools.map((pool) => (
            <MenuItem key={pool.id} value={pool.id}>
              ({pool.number}) {pool.name}
            </MenuItem>
          ))}
        </Select>
      )}

      {!window.cordova && (
        <button
          data-testid="open-pdf-button"
          type="button"
          className={classNames('btn btn-primary', classes.openPDFButton)}
          disabled={isLoading || selectedDate === null}
          onClick={downloadFile}
        >
          {isLoading ? <CircularProgress color="primary" size={24} /> : 'Ouvrir PDF'}
        </button>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onDisplayGlobalError: bindActionCreators(displayGlobalError, dispatch)
  };
}

export default compose<typeof React.Component>(
  connect<{}, ReduxDispatchProps, OwnProps>(null, mapDispatchToProps),
  withStyles(styles)
)(ReceivableAccountsReportForm);
