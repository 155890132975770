import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import shortid from 'shortid';
import { pushNewValue, updateValue } from '../../utils/reduxFormArrayField';
import { SingleLineField } from '../Fields';
import FormRow from '../FormRow/FormRow';

export const styles = () => ({
  iconButton: {
    position: 'absolute',
    right: 0
  },
  addStudent: {
    position: 'relative'
  },
  contentInput: {
    paddingRight: 50
  }
});

export class MultiLineField extends React.Component {
  constructor(props) {
    super(props);
    this.handleLineChange = this.handleLineChange.bind(this);
    this.handleAddNewLine = this.handleAddNewLine.bind(this);

    if (!props.input.value) {
      this.handleAddNewLine();
    }
  }

  handleLineChange(text, index) {
    updateValue(this.props.input, text.target.value, index);
  }

  handleAddNewLine() {
    pushNewValue(this.props.input, '');
  }

  render() {
    const { input, label, meta, classes } = this.props;
    return (
      <>
        {input.value
          ? input.value.map((text, index) => (
              <FormRow className={classes.addStudent} key={shortid.generate()}>
                <SingleLineField
                  className={classes.contentInput}
                  label={label}
                  meta={{}}
                  onBlur={(event) => this.handleLineChange(event, index)}
                  defaultValue={text}
                />
                {index + 1 === input.value.length && index < 3 ? (
                  <IconButton onClick={this.handleAddNewLine} className={classes.iconButton}>
                    <AddIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </FormRow>
            ))
          : ''}
        {!input.value.length ? (
          <IconButton onClick={this.handleAddNewLine}>
            <AddIcon />
          </IconButton>
        ) : (
          ''
        )}
        {meta.error && <FormHelperText error={!!meta.error}>{meta.error}</FormHelperText>}
      </>
    );
  }
}

MultiLineField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

export default compose(withStyles(styles))(MultiLineField);
