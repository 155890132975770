import BottomNavigationBar from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import PrintIcon from '@material-ui/icons/Print';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import findIndex from 'lodash/findIndex';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ACTIVITIES_ROUTE,
  ASSISTANT_ROUTE,
  REPORTS_ROUTE,
  SETTINGS_ROUTE,
  USERS_ROUTE
} from '../../containers/Navigation/constants';
import messages from '../AppHeader/messages';
import AssistantNavigationIcon from '../../containers/Assistant/AssistantNavigationIcon/AssistantNavigationIcon';

export const BOTTOM_NAVIGATION_HEIGHT = 75;
export const styles = () =>
  ({
    root: {
      minHeight: BOTTOM_NAVIGATION_HEIGHT,
      boxShadow: '0px -1px 0px 0px rgba(72, 47, 146, 0.25)',
      zIndex: 0
    }
  }) as const;

export const routeMapping = [
  `/${ACTIVITIES_ROUTE}`,
  `/${ASSISTANT_ROUTE}`,
  `/${REPORTS_ROUTE}`,
  `/${SETTINGS_ROUTE}`,
  `/${USERS_ROUTE}`
];

interface Props extends WithStyles<typeof styles> {}

export const BottomNavigation: React.FunctionComponent<Props> = ({ classes }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getMenuItemIndexFromRoute = useCallback(
    (route) => findIndex(routeMapping, (mapping) => route.startsWith(mapping)),
    []
  );

  const handleNavigationChange = useCallback(
    (event, routeIndexRequested) => {
      const route = routeMapping[routeIndexRequested];
      if (location.pathname !== route) {
        navigate({ pathname: route, search: location.search });
      }
    },
    [location, navigate]
  );

  return (
    <BottomNavigationBar
      showLabels
      classes={{ root: classes.root }}
      value={getMenuItemIndexFromRoute(location.pathname)}
      onChange={handleNavigationChange}
    >
      <BottomNavigationAction label={<FormattedMessage {...messages.home} />} icon={<ViewDayIcon />} />
      <BottomNavigationAction label={<FormattedMessage {...messages.assistant} />} icon={<AssistantNavigationIcon />} />
      <BottomNavigationAction label={<FormattedMessage {...messages.reports} />} icon={<PrintIcon />} />
      <BottomNavigationAction label={<FormattedMessage {...messages.settings} />} icon={<SettingsIcon />} />
    </BottomNavigationBar>
  );
};

export default withStyles(styles)(BottomNavigation);
