import SettingsReferralInvite from 'application/pages/settings/referral/referral-invite';
import React from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';
import useToastedApolloError from 'application/hooks/use-toast-apollo-error';
import SettingsReferralList from './referral/referral-list';

export interface Referral {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  expiredAt: string;
  claimedAt: string;
  referredUser: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

const REFERRAL_QUERY = gql`
  query ReferralList {
    referrals {
      id
      firstName
      lastName
      email
      expiredAt
      claimedAt
      referredUser {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

const SettingsReferralPage = () => {
  const toastApolloError = useToastedApolloError();

  const { data, loading, refetch } = useQuery<{ referrals: Referral[] }>(REFERRAL_QUERY, {
    onError: (error: ApolloError) => toastApolloError(error, 'shield-exclamation')
  });

  return (
    <div className="flex flex-col gap-4">
      <SettingsReferralInvite onReferralCreated={() => refetch()} />
      <SettingsReferralList referrals={data?.referrals ?? []} loading={loading} />
    </div>
  );
};

export default SettingsReferralPage;
