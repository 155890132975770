import moment from 'moment-timezone';
import { MixteDay } from '../../../../shared/domain/activity/mixte/model/Mixte';

const getBlockedMixteMessage = ({ date }: MixteDay) =>
  // eslint-disable-next-line max-len
  `Veuillez prendre note que la modification automatique du mixte pour la journée du _*${moment(date).format(
    'dddd D'
  )}*_ ne peut se faire.

*SVP communiquer avec Médyx*.`;

export default getBlockedMixteMessage;
