import React from 'react';
import { LatestMixteDayPeriodFormDto } from '../../../../../containers/PeriodsBilling/PeriodForm/type';
import { MixteDay } from '../../../../../../shared/domain/activity/mixte/model/Mixte';
import PerdiemsToHours from '../../PerdiemsToHours/PerdiemsToHours';
import PerdiemsCodeInfo from '../../PerdiemsCodeInfo/PerdiemsCodeInfo';

export interface Props {
  mixteDay: LatestMixteDayPeriodFormDto | MixteDay;
}

const LegacyMixtePeriodDetail: React.FunctionComponent<Props> = ({ mixteDay }) => (
  <>
    <PerdiemsToHours perdiems={mixteDay.perdiems} manualEditionMode={mixteDay.manualEditionMode} />
    <PerdiemsCodeInfo activity={mixteDay} />
  </>
);

export default LegacyMixtePeriodDetail;
