import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import { DateField, PhoneNumberField, SingleLineField } from '../../../../../components/Form/Fields';
import { parsePhoneNumber } from '../../../../../components/Form/utils/fieldTransformations';
import { formatDateStringToUnix, formatUnixToDate } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import SingleSelectCheckboxGroup from '../../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../../../ui/shared/form/constants';

const ForeignStudentForm: React.FunctionComponent = () => (
  <CardContent>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        Identification du patient
      </Typography>
      <Divider />
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.lastName" required label="Nom du patient" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.firstName"
          required
          label="Prénom du patient"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.birthDate"
          required
          label="Date de naissance"
          component={DateField}
          format={formatUnixToDate}
          parse={formatDateStringToUnix}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.address" label="Adresse" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.phone"
          label="Numéro de téléphone"
          component={PhoneNumberField}
          parse={parsePhoneNumber}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.email" label="Adresse courriel" component={SingleLineField} />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="h6">
          Assurance
        </Typography>
        <Divider />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.studentNumber"
          required
          label="Numéro d'étudiant"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.groupNumberOrUniversityName"
          required
          label="Numéro de groupe ou nom université"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12}>
        <FormElement
          name="patientInformation.communicationMedyxPatientAuthorisation"
          required
          label="J’autorise médyx à communiquer avec le patient en mon nom advenant tout suivi supplémentaire requis"
          list={YES_NO_CHECKBOX_VALUES}
          component={SingleSelectCheckboxGroup}
        />
      </Grid>
    </Grid>
  </CardContent>
);

export default ForeignStudentForm;
