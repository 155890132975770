import { selectSpecialty } from '../../../../containers/User/selectors';
import { getStore } from '../../../../reduxStore/configureStore';
import { selectLoggedUserPermissions } from '../../../../containers/Authentication/selectors';
import RAMQCodes, { Unit as RAMQUnit } from '../../../../../shared/ramq/RamqCodes';
import SpecialtyName from '../../../../../shared/core/doctor/domain/SpecialtyName';

const CABINET_ROLE_ID = '1_cabinet';
const ESTABLISHMENT_ROLE_ID = '1_establishment';

export const getRoleNumber = (roleId) => {
  switch (roleId) {
    case ESTABLISHMENT_ROLE_ID:
      return 1;
    case CABINET_ROLE_ID:
      return 1;
    default:
      return parseInt(roleId, 10);
  }
};

export const findUserSpecialtyRelatedCodeUnits = (code: string): RAMQUnit[] | undefined => {
  const userSpecialty: SpecialtyName = selectSpecialty()(getStore().getState());
  const unitsBySpecialty = RAMQCodes.getRoleUnitsForSpecialty(code, userSpecialty);

  if (unitsBySpecialty === undefined) {
    return undefined;
  }

  return unitsBySpecialty;
};

export const buildUnitsString = (units) => {
  if (!Array.isArray(units)) {
    return '';
  }

  let unitString = '';

  units.forEach((unit) => {
    if (unit.role !== CABINET_ROLE_ID) {
      // Eventually show the appropriate role depending on the current type of place
      const roleNumber = getRoleNumber(unit.role);
      if (roleNumber === 1) {
        unitString = unitString.concat(`R${roleNumber}: ${unit.units}\u00a0$\u2003`);
      } else {
        unitString = unitString.concat(`R${roleNumber}: ${unit.units}\u2003`);
      }
    }
  });

  return unitString;
};

export const can = (permission) => {
  const userPermissions = selectLoggedUserPermissions()(getStore().getState());

  return userPermissions.includes(permission);
};
