import React from 'react';
import Toast, { ToastProps } from 'application/components/toast/toast';

interface Props {
  toasts: Array<Omit<ToastProps, 'onDismiss'>>;
  onDismiss: (id: number) => void;
}

const Toasts = ({ toasts, onDismiss }: Props) => (
  <div className="toast toast-end toast-top z-[1111]">
    {toasts.map((toast) => (
      <Toast
        autoDismiss={toast.autoDismiss}
        icon={toast.icon}
        id={toast.id}
        key={toast.id}
        message={toast.message}
        onDismiss={() => onDismiss(toast.id)}
        type={toast.type}
      />
    ))}
  </div>
);

export default Toasts;
