import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { WithStyles } from '@material-ui/core';
import { selectPlacesTerritory } from '../../../containers/RamqPlaces/selectors';
import Place from '../../../../shared/domain/place/model/Place';

export const styles = () =>
  ({
    smallChip: {
      height: '18px',
      marginLeft: '1em',
      cursor: 'inherit'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  place: Place;
}

export const MixteBillingPlaceChip: React.FunctionComponent<Props> = ({ classes, place }) =>
  !place.billByAct ? <Chip className={classes.smallChip} label="M" /> : null;

export const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    territory: selectPlacesTerritory(ownProps.place.number)
  });

export default compose(connect(mapStateToProps), withStyles(styles))(MixteBillingPlaceChip);
