import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormRow from '../FormRow/FormRow';
import FormElement from '../FormElement/FormElement';
import { SingleLineField } from '../Fields';
import validateEmailFormat from '../utils/validator/emailValidator';

export interface Props {
  firstNameInputRef?: React.RefCallback<any>;
  name?: string;
  disabled?: boolean;
  validateEmail?: boolean;
}

const SignatoryField: React.FunctionComponent<Props> = ({ firstNameInputRef, name, disabled, validateEmail }) => {
  const namePrefix = name ? `${name}.` : '';

  return (
    <>
      <FormRow>
        <Typography variant="subtitle1">Signataire </Typography>
      </FormRow>

      <FormRow>
        <FormElement
          inputRef={firstNameInputRef}
          name={`${namePrefix}firstName`}
          label="Prénom"
          fullWidth
          disabled={!!disabled}
          component={SingleLineField}
        />
      </FormRow>

      <FormRow>
        <FormElement
          name={`${namePrefix}lastName`}
          label="Nom"
          fullWidth
          disabled={!!disabled}
          component={SingleLineField}
        />
      </FormRow>

      <FormRow>
        <FormElement
          name={`${namePrefix}email`}
          label="Courriel"
          placeholder="example@domain.com"
          type="email"
          fullWidth
          disabled={!!disabled}
          validate={validateEmail ? validateEmailFormat : undefined}
          component={SingleLineField}
        />
      </FormRow>
    </>
  );
};

export default SignatoryField;
