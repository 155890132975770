import { get } from 'lodash';
import CODE_NATURE_TYPES from '../../../../shared/ramq/domainValues/natureTypes';
import { RAMQCodes, TimedAttributeKey } from '../../../../shared/ramq/RamqCodes';
import { isSpecialtyAnesthesiologist } from '../../../../shared/domain/doctor/Doctor';

const buildErrorString = (result) =>
  `Les codes ${result[0].code} et ${result[1].code} sont mutuellement exclusifs. SVP enlever un des deux et envoyer.`;

const isSurgeryMainCode = (code, date) => {
  if (code.natureType !== CODE_NATURE_TYPES.chirurgie || !get(code, 'roles', []).includes(2) || code.isSupplement) {
    return false;
  }

  const ramqCode = RAMQCodes.get(code.code);
  if (!ramqCode) return false;

  return !RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isAddendum8, date);
};

export const exclusiveCodesInR2ForAnesthesiologistOnly =
  (user = { specialty: null }) =>
  (act) => {
    const { specialty } = user;
    const result = act.codes.filter((code) => isSurgeryMainCode(code, act.date));

    if (isSpecialtyAnesthesiologist(specialty) && result.length >= 2) {
      return {
        codes: {
          _error: buildErrorString(result)
        }
      };
    }
    return {};
  };

export default exclusiveCodesInR2ForAnesthesiologistOnly;
