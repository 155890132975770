import React from 'react';
import Alert from '../../../../../components/Alert/Alert';
import AlertSeverity from '../../../../../ui/shared/severity/AlertSeverity';

const MilitaryAdditionalSteps: React.FunctionComponent = () => (
  <Alert
    title="Étapes suivantes"
    severity={AlertSeverity.INFO}
    showIcon
    message={
      <ul>
        <li>SVP ajouter le numéro du militaire ou joindre photo de la carte du militaire</li>

        <li>N.B. Si le patient a un NAM admissible il doit être facturé à la RAMQ</li>
      </ul>
    }
  />
);

export default MilitaryAdditionalSteps;
