import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import { StatementLine } from './StatementDoctorInfo';
import StatementSectionContainer from './StatementSectionContainer';

export function StatementGrossAmount({ generalInformation }) {
  const addUpAmounts = (sum, current) => sum + parseFloat(current.grossAmount);
  const grossAmountSum = generalInformation.grossAmountInfoLines.reduce(addUpAmounts, 0).toFixed(2);
  return (
    <StatementSectionContainer withDivider>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Montants Bruts: ', currencyFormat(grossAmountSum))}
          secondaryLines={generalInformation.grossAmountInfoLines.map((line) =>
            StatementLine(`${line.transactionCode} - ${line.transactionLabel}`, currencyFormat(line.grossAmount))
          )}
        />
      </Grid>
    </StatementSectionContainer>
  );
}

StatementGrossAmount.propTypes = {
  generalInformation: PropTypes.object.isRequired
};

export default StatementGrossAmount;
