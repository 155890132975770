import Proptypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

const BLANK_SPACE = '\u2000';

export function PhoneMask({ className, value, onChange }) {
  return (
    <MaskedInput
      className={className}
      placeholder="(999) 999-9999"
      value={value}
      onChange={onChange}
      pattern="[0-9]*"
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={BLANK_SPACE}
    />
  );
}

PhoneMask.propTypes = {
  className: Proptypes.string.isRequired,
  value: Proptypes.string.isRequired,
  onChange: Proptypes.func.isRequired
};

export default PhoneMask;
