import React, { useEffect, useState } from 'react';
import useTranslate from 'application/hooks/use-translate';
import classNames from 'classnames';
import { useDebounce, useToggle } from 'react-use';
import Icon from 'application/components/icon';

interface Props<ChoiceType> {
  choices: ChoiceType[];
  inputType?: 'text' | 'search';
  loading: boolean;
  placeholder?: string;
  onSearch: (searchValue: string) => void;
  onChoiceSelect: (selectedChoice: ChoiceType) => void;
  renderChoice: (choice: ChoiceType) => React.ReactNode;
  getKey: (choice: ChoiceType) => string;
  showChoicesIfEmpty?: boolean;
  className?: string;
  anchorRight?: boolean;
  autoFocus?: boolean;
  formatFunction?: (choice: ChoiceType) => string;
}

const TypeaheadInput = <ChoiceType,>({
  choices,
  inputType = 'text',
  loading,
  placeholder,
  onSearch: onSearchValueChange,
  onChoiceSelect,
  getKey,
  renderChoice,
  showChoicesIfEmpty,
  className,
  anchorRight,
  autoFocus = true,
  formatFunction
}: Props<ChoiceType>) => {
  const translate = useTranslate('components.typeahead-input');
  const [searchValue, setSearchValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isFocus, setIsFocus] = useToggle(false);
  const [isOpened, setIsOpened] = useToggle(false);
  const [isHovering, setIsHovering] = useToggle(false);
  const [hasBeenOpened, setHasBeenOpened] = useToggle(false);

  useEffect(() => {
    if (isFocus && (loading || hasBeenOpened)) {
      setIsOpened(true);
      setHasBeenOpened(true);
    } else if (!isFocus) {
      setIsOpened(isHovering);
    }
  }, [isFocus, isHovering, loading, setIsOpened, setHasBeenOpened, hasBeenOpened]);

  useDebounce(
    () => {
      if (searchValue === '' && !showChoicesIfEmpty) {
        setIsOpened(false);
        setHasBeenOpened(false);
      }

      onSearchValueChange(searchValue);
    },
    500,
    [searchValue]
  );

  const handleChoiceSelect = (selectedChoice: ChoiceType) => {
    if (formatFunction) {
      setSearchValue(formatFunction(selectedChoice));
    } else {
      setSearchValue('');
    }
    setIsOpened(false);
    setSelectedIndex(-1);
    onChoiceSelect(selectedChoice);
  };

  return (
    <div
      className={classNames('dropdown dropdown-bottom dropdown-open', className, {
        'dropdown-end': anchorRight
      })}
    >
      <input
        type={inputType}
        placeholder={placeholder}
        value={searchValue}
        className="input-bordered input w-full"
        onInput={(event: React.FormEvent) => setSearchValue((event.target as HTMLInputElement).value)}
        onChange={(event: React.FormEvent) => setSearchValue((event.target as HTMLInputElement).value)}
        autoFocus={autoFocus}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if (choices && choices[selectedIndex]) {
              handleChoiceSelect(choices[selectedIndex]);
              setSelectedIndex(-1);
            }
          }
          if (event.key === 'ArrowDown') {
            setSelectedIndex(selectedIndex < (choices || []).length ? selectedIndex + 1 : selectedIndex);
          }
          if (event.key === 'ArrowUp') {
            setSelectedIndex(selectedIndex > 0 ? selectedIndex - 1 : selectedIndex);
          }
        }}
      />

      {isOpened && (
        <ul
          className="bg menu dropdown-content w-full rounded-box bg-base-100 p-2 shadow"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {choices.length === 0 && !loading ? (
            <li className="disabled">
              <div className="flex items-center">
                <Icon icon="empty-set" />
                {translate('no-results')}
              </div>
            </li>
          ) : (
            choices.map((choice, index) => (
              <li key={getKey(choice)}>
                <button
                  type="button"
                  className={classNames('flex', { 'bg-gray-100': index === selectedIndex })}
                  onClick={() => handleChoiceSelect(choice)}
                  onMouseMove={() => setSelectedIndex(-1)}
                >
                  {renderChoice(choice)}
                </button>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default TypeaheadInput;
