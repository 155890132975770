import React from 'react';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import Dialog from '@material-ui/core/Dialog';
import { compose } from 'redux';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import SearchIcon from '@material-ui/icons/Search';
import FavoriteIcon from '@material-ui/icons/Favorite';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import CategoryItem from '../../../shared/domain/CategoryItem';
import ItemAndCategoriesMapping from '../../../../shared/editable/domain/ItemAndCategoriesMapping';
import Category from '../../../shared/domain/Category';
import DialogCenteredTitle from '../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import PresetsTab from '../../../shared/ui/GenericSearchDialog/PresetsTab/PresetsTab';
import FavoritesTab from '../../../shared/ui/GenericSearchDialog/FavoritesTab/FavoritesTab';
import { isInCollection } from '../../../shared/domain/isInFavoritesFunction';
import SwipeableTabs from '../../../../components/SwipeableTabs/SwipeableTabs';
import RegistryTab from './RegistryTab';

const styles = (theme) =>
  ({
    paper: {
      minHeight: '90vh',
      height: '100%'
    },
    slideChildContainer: {
      'display': 'flex',
      'height': '100%',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    }
  }) as const;

interface Props extends WithStyles {
  open: boolean;
  fullScreen: boolean;
  advancedEditionEnabled: boolean;
  favorites: ItemAndCategoriesMapping;
  presets: Category<CategoryItem>[];
  onAddToFavorites: (item: CategoryItem) => void;
  onUpdateFavorites: (favorites: ItemAndCategoriesMapping) => void;
  onConfirm: (items: CategoryItem[]) => void;
  onClose: () => void;
}

const SearchDialog: React.FunctionComponent<Props> = (props: Props) => {
  const {
    open,
    fullScreen,
    advancedEditionEnabled,
    favorites,
    presets,
    onAddToFavorites,
    onUpdateFavorites,
    onConfirm,
    onClose,
    classes
  } = props;

  const [selectedItems, setSelectedItems] = React.useState<CategoryItem[]>([]);

  const isSelected = (item: CategoryItem) =>
    selectedItems.find((selectedItem) => selectedItem.code === item.code) !== undefined;

  const toggleItemToSelection = (item: CategoryItem) => {
    setSelectedItems((selectedItems) => {
      const indexOfMatchingElement = selectedItems.findIndex(({ code }) => code === item.code);

      if (indexOfMatchingElement !== -1) {
        const updatedSelectedCodes = [...selectedItems];
        updatedSelectedCodes.splice(indexOfMatchingElement, 1);

        return updatedSelectedCodes;
      }

      return [...selectedItems, item];
    });
  };

  const confirmSelection = () => {
    onConfirm(selectedItems);
  };

  const closeDialog = () => {
    setSelectedItems([]);
    onClose();
  };

  const tabs = [
    {
      key: 'favorites',
      icon: <FavoriteIcon />,
      component: (
        <div className={classes.slideChildContainer} key="presets-tab">
          <FavoritesTab
            favorites={favorites}
            isSelected={(item) => isInCollection(selectedItems, item)}
            onItemSelected={toggleItemToSelection}
            onUpdate={onUpdateFavorites}
            onConfirm={confirmSelection}
            onCancel={closeDialog}
            advancedEditionEnabled={advancedEditionEnabled}
          />
        </div>
      )
    },
    {
      key: 'presets',
      icon: <FolderSpecialIcon />,
      component: (
        <div className={classes.slideChildContainer} key="presets-tab">
          <PresetsTab
            data={presets}
            isInFavorites={(item) => isInCollection(favorites, item)}
            isSelected={(item) => isInCollection(selectedItems, item)}
            onAddToFavorites={(item) => onAddToFavorites(item)}
            onItemSelected={toggleItemToSelection}
            onConfirm={confirmSelection}
            onCancel={closeDialog}
          />
        </div>
      )
    },
    {
      key: 'search',
      icon: <SearchIcon />,
      component: (
        <div className={classes.slideChildContainer}>
          <RegistryTab
            onClose={confirmSelection}
            isSelected={(item) => isSelected(item)}
            isInFavorites={(item) => isInCollection(favorites, item)}
            onItemSelected={(item: CategoryItem) => toggleItemToSelection(item)}
            onAddToFavorites={(item: CategoryItem) => onAddToFavorites(item)}
          />
        </div>
      )
    }
  ];

  return (
    <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={closeDialog} classes={{ paper: classes.paper }}>
      <DialogCenteredTitle label="Recherche d'élément de contexte" />
      <SwipeableTabs
        swipeableViewsProps={{
          containerStyle: { height: '100%' },
          slideStyle: { overflow: 'hidden' }
        }}
        tabs={tabs}
      />
    </Dialog>
  );
};

export default compose<typeof SearchDialog>(withMobileDialog(), withStyles(styles))(SearchDialog);
