import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import getExecutionEnv, { getBuildNumber } from 'shared/utils/executionEnvironment';
import Section from 'application/components/form-controls/section';
import FieldContainer from 'application/components/form-controls/field-container';
import useIonicVersion from 'application/hooks/use-ionic-version';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import SelectInput from 'application/components/form-controls/select-input';

const SettingsApplicationPage = () => {
  const translate = useTranslate('pages.settings.deployment-info');
  const [ionicVersion, switchChannel] = useIonicVersion();

  return (
    <Section label={translate('title')}>
      <FieldContainer label={translate('build-number')}>{getBuildNumber()}</FieldContainer>
      <Restrict atLeastRole={Role.Developer}>
        {ionicVersion && (
          <FieldContainer label={translate('ionic-channel')}>
            <SelectInput
              value={ionicVersion?.channel}
              options={[
                { label: 'Production', value: 'Production' },
                { label: 'Staging', value: 'Staging' }
              ]}
              onChange={switchChannel}
            />
          </FieldContainer>
        )}
      </Restrict>
      <FieldContainer label={translate('environment')}>{getExecutionEnv()}</FieldContainer>
    </Section>
  );
};

export default SettingsApplicationPage;
