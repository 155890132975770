import ActFile from './ActFile';
import { logError } from '../../../../shared/utils/plog';

export const buildFromFileEntry = (fileEntry) => buildFromFileName(fileEntry.name, fileEntry);

export const buildFromFileName = (fileName, fileEntry) => {
  // filename is of the format <actid>_<timestamp>_<shortid>.<extension>
  // Example: 091u2eoijdsakojd0912e_1234566990000_an9Ldj2h-4_c5.pdf
  const splitUnderscore = fileName.split('_');
  if (splitUnderscore.length >= 2) {
    const actId = splitUnderscore[0];
    const timestamp = parseInt(splitUnderscore[1], 10);
    const shortId = fileName.match(/^[a-zA-Z0-9]+_[0-9]+_(.+)\.[a-zA-Z]+$/)[1];
    const extension = fileName.split('.')[1];
    return new ActFile(actId, fileEntry, undefined /* data */, timestamp, shortId, extension);
  }

  logError({
    type: 'actFileBuilder',
    text: 'actFileBuilder/buildFromFileName',
    array: ['Invalid act file name', fileName]
  });
  return undefined;
};
