import MIXTE_DISPENSARY_AREA_MAP from '../../../../shared/ramq/domainValues/mixteDispensaryAreas';

export const generalCareMixte = {
  label: 'HOSPIT',
  value: MIXTE_DISPENSARY_AREA_MAP.generalCare
};
export const externalClinicMixte = {
  label: 'EXTERNE',
  value: MIXTE_DISPENSARY_AREA_MAP.externalClinic
};
export const remoteMixte = {
  label: 'À Distance',
  value: MIXTE_DISPENSARY_AREA_MAP.remote
};

const dispensaryAreaMap: any = [externalClinicMixte, generalCareMixte, remoteMixte];

export const latestDispensaryAreaMap: any = [externalClinicMixte, generalCareMixte];

export default dispensaryAreaMap;
