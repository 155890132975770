import PropTypes from 'prop-types';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectHasEditableFavoriteActCodesEnabled } from '../../../../containers/User/selectors';
import DraggableFavoritesActCodesTab from './EditableFavoritesActCodesTab/EditableFavoritesActCodesTab';
import EditableOnlyFavoritesActCodesTab from './EditableOnlyFavoritesActCodesTab/EditableOnlyFavoritesActCodesTab';

export function FavoritesActCodesTabFactory({ hasEditableFavoritesEnabled, ...rest }) {
  return hasEditableFavoritesEnabled ? (
    <DraggableFavoritesActCodesTab {...rest} />
  ) : (
    <EditableOnlyFavoritesActCodesTab {...rest} />
  );
}

FavoritesActCodesTabFactory.propTypes = {
  hasEditableFavoritesEnabled: PropTypes.bool.isRequired
};

export const mapStateToProps = createStructuredSelector({
  hasEditableFavoritesEnabled: selectHasEditableFavoriteActCodesEnabled()
});

export default connect(mapStateToProps)(FavoritesActCodesTabFactory);
