import React, { useState } from 'react';
import capitalize from 'lodash/capitalize';
import { CircularProgress, withStyles, WithStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { getBillingPeriodFromDate } from '../../../../shared/periods/periods';
import { PeriodByActivities } from '../shared/type';
import { ACTIVITIES_ACT_TYPE } from '../../../../shared/collection/collectionConstants';
import * as ReportAPI from '../../../infrastructure/api/ReportAPI';
import EmailService from '../../../email/EmailService';
import PaymentRequestPeriodSelector from '../PaymentRequest/PaymentRequestPeriodSelector/PaymentRequestPeriodSelector';
import HeaderPortal from '../../../components/Portals/HeaderPortal/HeaderPortal';
import User from '../../../../shared/domain/user/User';
import buildBody from './bodyTemplate';
import { displayGlobalError } from '../../Act/actions';
import calculateORFromPeriod from '../../../../shared/periods/common/calculateORFromActDate';
import Period from '../../../../shared/periods/common/Period';

export const styles = (theme) =>
  ({
    dateMessage: {
      marginRight: theme.spacing(1)
    },
    whiteBackground: {
      backgroundColor: 'white'
    },
    dropdownLabel: {
      marginLeft: theme.spacing(8)
    },
    generateMailButton: {
      marginLeft: theme.spacing(4)
    }
  }) as const;

export interface ReduxDispatchProps {
  // eslint-disable-next-line no-unused-vars
  onDisplayGlobalError: (error: any) => void;
}

export interface Props extends ReduxDispatchProps, WithStyles<typeof styles> {
  doctor: User;
}

export const PeriodReportsForm: React.FunctionComponent<Props> = ({ doctor, onDisplayGlobalError, classes }: Props) => {
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onGenerate = async () => {
    setLoading(true);
    try {
      const period = (getBillingPeriodFromDate(selectedDate) as PeriodByActivities)[ACTIVITIES_ACT_TYPE];
      const statementDate = moment(period.endDate).add(11, 'days').startOf('day').valueOf();
      const nextPeriod = getBillingPeriodFromDate(statementDate)[ACTIVITIES_ACT_TYPE];

      const [{ url: paymentRequestReportUrl }, { url: statementReportUrl }] = await ReportAPI.generatePeriodReportLinks(
        {
          userId: doctor.id,
          statementDate,
          periodStart: nextPeriod.startDate.valueOf(),
          periodEnd: nextPeriod.endDate.valueOf()
        }
      );

      const billingPeriod = Period.actBillingPeriodOfDate(moment(selectedDate));

      const body = buildBody(doctor, billingPeriod, [
        {
          name: 'Demandes de paiement',
          url: paymentRequestReportUrl
        },
        {
          name: 'État de compte',
          url: statementReportUrl
        }
      ]);

      EmailService.openEmailDraft({
        to: doctor.email,
        cc: 'agencesl@medyx.com',
        subject: `Rapports et état de compte - OR${calculateORFromPeriod(billingPeriod)} ${capitalize(doctor.title)} ${
          doctor.firstName
        } ${doctor.lastName}`,
        body
      });
    } catch (error) {
      onDisplayGlobalError((error as any).toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <HeaderPortal>
      <Toolbar className={classes.whiteBackground}>
        <Typography className={classes.dateMessage}>Période:</Typography>
        <PaymentRequestPeriodSelector initialiseWithFirstOption value={selectedDate} onChange={setSelectedDate} />

        <button
          type="button"
          className={classNames('btn btn-primary', classes.generateMailButton)}
          disabled={loading}
          onClick={onGenerate}
        >
          {loading ? <CircularProgress color="primary" size={24} /> : 'Générer'}
        </button>
      </Toolbar>
    </HeaderPortal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onDisplayGlobalError: bindActionCreators(displayGlobalError, dispatch)
  };
}

export default compose<typeof React.Component>(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(PeriodReportsForm);
