import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { ReactElement } from 'react';
import { FormControl, Input, InputLabel } from '@material-ui/core';

interface Props {
  cancelLabel?: string;
  confirmationLabel?: string;
  manualConfirmWithString?: string;
  onClose: () => void;
  onItemSelected: () => void;
  open: boolean;
  text: string;
  title: string;
}

export default ({
  cancelLabel = 'Annuler',
  confirmationLabel = 'Confirmer',
  manualConfirmWithString,
  onClose,
  onItemSelected,
  open,
  text,
  title
}: Props): ReactElement => {
  const [manualConfirmation, setManualConfirmation] = React.useState<string | undefined>();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>

        {manualConfirmWithString && (
          <FormControl fullWidth>
            <InputLabel>Écrivez « {manualConfirmWithString} » pour confirmer.</InputLabel>
            <Input name="manual-confirmation" onChange={({ target }) => setManualConfirmation(target.value)} />
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancelLabel}
        </Button>
        <Button
          autoFocus
          onClick={() => {
            onItemSelected();
            onClose();
          }}
          color="primary"
          disabled={!!manualConfirmWithString && manualConfirmation !== manualConfirmWithString}
        >
          {confirmationLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
