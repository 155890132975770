import { firestore } from 'server/Firebase';

export interface SpecialtyPreset {
  id: string;
}

export interface CategoryPreset {
  id: string;
  category: string;
  items: Item[];
  order: number;
}

export interface Item {
  code: string;
  description: string;
}

/* eslint-disable -- firestore is not typed */
export default class PresetsService {
  public static fetchSpecialties(): Promise<SpecialtyPreset[]> {
    return firestore
      .collection('specialties')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => ({
          id: doc.id
        }))
      );
  }

  public static fetchCategories(specialtyId: string): Promise<CategoryPreset[]> {
    return firestore
      .collection('specialties')
      .doc(specialtyId)
      .collection('specificActCodes')
      .orderBy('order')
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          category: doc.data()?.category,
          order: doc.data()?.order,
          items: doc.data() ? (Object.values(doc.data().items) as Item[]) : []
        }))
      );
  }

  public static saveCategory(specialtyId: string, category: CategoryPreset): Promise<void> {
    return firestore
      .collection('specialties')
      .doc(specialtyId)
      .collection('specificActCodes')
      .doc(category.id)
      .set({
        ...category
      });
  }
}
