import { AM_PERDIEM, PM_PERDIEM } from '../../../../shared/periods/common/constants';
import { getUnixStartOfDay } from '../../../../shared/utils/dateTime/dateTimeUtils';
import MIXTE_DISPENSARY_AREA_MAP from '../../../../shared/ramq/domainValues/mixteDispensaryAreas';
import DayUnitController from '../../../containers/PeriodsBilling/PeriodContent/DayUnit/LatestMixteDayUnit/DayUnitController';

const isACompleteDayWithSameCode = (perdiemsInfo) =>
  perdiemsInfo.length === 2 && perdiemsInfo[0].code === perdiemsInfo[1].code;

const isACompleteDayWithDifferentCode = (perdiemsInfo) =>
  perdiemsInfo.length === 2 && perdiemsInfo[0].code !== perdiemsInfo[1].code;

const isHalfADay = (perdiemsInfo) => perdiemsInfo.length === 1;

const createBaseMixteDay = (date, perdiems, code) => ({
  date: getUnixStartOfDay(date),
  perdiems,
  code,
  activityArea: MIXTE_DISPENSARY_AREA_MAP.generalCare,
  duration: perdiems.length * DayUnitController.DEFAULT_HALF_PERDIEM_DURATION
});

const createMixteDays = (perdiemsInfo = []) => {
  if (isACompleteDayWithSameCode(perdiemsInfo)) {
    const { date, code } = perdiemsInfo[0];
    return [createBaseMixteDay(date, [AM_PERDIEM, PM_PERDIEM], code)];
  }

  if (isACompleteDayWithDifferentCode(perdiemsInfo)) {
    return [
      createBaseMixteDay(perdiemsInfo[0].date, [perdiemsInfo[0].perdiem], perdiemsInfo[0].code),
      createBaseMixteDay(perdiemsInfo[1].date, [perdiemsInfo[1].perdiem], perdiemsInfo[1].code)
    ];
  }

  if (isHalfADay(perdiemsInfo)) {
    const { date, code, perdiem } = perdiemsInfo[0];
    return [createBaseMixteDay(date, [perdiem], code)];
  }

  return [];
};

export default createMixteDays;
