/* eslint-disable */
import React, { FunctionComponent } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles, { StyledComponentProps } from '@material-ui/core/styles/withStyles';
import StatementRenderer from '../../../../../../../app/containers/ReportsRoute/Statement/StatementRenderer/StatementRenderer';
import StatementReport from '../../../../../../../app/containers/ReportsRoute/Statement/StatementReport/StatementReport';
import PoolStatementSummaryBuilder from './PoolStatementSummaryBuilder';

export const styles = (theme) => ({
  root: {
    height: 'max-content',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  }
});

export interface Props extends StyledComponentProps {
  statements: { practiceNumber: string }[];
}

function preparePoolStatement(statements) {
  const statementsWithArraySections = PoolStatementSummaryBuilder.transformSectionsToArray(statements);

  const builder = new PoolStatementSummaryBuilder();
  builder.withBasicInfo(statementsWithArraySections);
  builder.withHeaderInfo(statementsWithArraySections);
  builder.withFirstLineInfo(statementsWithArraySections);
  builder.withProfessionalIdInfo(statementsWithArraySections);
  builder.mergeGrossAmountInfoLines(statementsWithArraySections);
  builder.mergeCeilingCutLines(statementsWithArraySections);
  builder.mergeDeductionLines(statementsWithArraySections);
  builder.mergeFederationContributionsLines(statementsWithArraySections);
  builder.mergeInterestAndAdvancesLines(statementsWithArraySections);
  builder.mergeStatementBalanceLines(statementsWithArraySections);
  builder.mergeStatementBalanceTransferLines(statementsWithArraySections);
  builder.mergeNetAmountInfo(statementsWithArraySections);
  const summaryStatement = builder.build();

  return summaryStatement;
}

export const PoolStatementRenderer: FunctionComponent<Props> = ({ statements, classes }: Props) => (
  <Paper className={classes!.root}>
    <StatementReport statements={preparePoolStatement(statements)} isPool />
    {statements.map((entry) => (
      <StatementRenderer key={entry.practiceNumber} statements={[entry]} />
    ))}
  </Paper>
);

export default withStyles(styles)(PoolStatementRenderer);
