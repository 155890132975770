import React, { useEffect, useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useQuery } from '@tanstack/react-query';
import StripeService from 'application/services/stripe-service';
import Icon from 'application/components/icon';
import { SectionProps } from 'application/pages/lobby/lobby.signup';
import useTranslate from 'application/hooks/use-translate';
import Alert from 'application/components/alert';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY!;

const Payment = ({ userState, temporaryState, generateRecaptchaToken, onError, onForceSuccess }: SectionProps) => {
  const translate = useTranslate('pages.lobby.signup.section-payment');
  const [recaptchaToken, setRecaptchaToken] = useState<string>();

  useEffect(() => {
    if (generateRecaptchaToken) {
      generateRecaptchaToken().then(setRecaptchaToken).catch(onError);
    }
  }, [generateRecaptchaToken, onError]);

  const stripePromise = useMemo(() => loadStripe(STRIPE_PUBLIC_KEY), []);

  const { data: checkoutOptions, isFetching } = useQuery({
    queryKey: ['stripe-subscription-session'],
    queryFn: () => {
      if (!temporaryState.stripePriceId) {
        onError();
        throw new Error('No stripe price id');
      }

      return StripeService.createSubscriptionSession(userState.email, temporaryState.stripePriceId, recaptchaToken);
    },
    enabled: !!recaptchaToken
  });

  if (isFetching || !recaptchaToken) {
    return (
      <div className="w-full py-12 text-center text-4xl">
        <Icon loading />
      </div>
    );
  }

  return (
    <>
      <div className="w-full">
        {checkoutOptions && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={checkoutOptions}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <div className="m-4">
        <Alert message={translate('skip-payment-disclaimer')} type="info" />
        <button type="button" onClick={onForceSuccess} className="btn btn-neutral mt-2 w-full">
          {translate('skip-payment')}
          <Icon solid icon="forward-fast" className="ml-2" />
        </button>
      </div>
    </>
  );
};

export default Payment;
