export const MULTI_ACT_OPEN_DIALOG = 'openMultiActDialog';
export const openMultiActDialog = () => ({
  type: MULTI_ACT_OPEN_DIALOG
});

export const MULTI_ACT_CLOSE_DIALOG = 'closeMultiActDialog';
export const closeMultiActDialog = () => ({
  type: MULTI_ACT_CLOSE_DIALOG
});

export const MULTI_ACT_REINIT_DIALOG = 'reinitMultiActDialog';
export const reinitMultiActDialog = () => ({
  type: MULTI_ACT_REINIT_DIALOG
});

export const MULTI_ACT_PROGRESS_UPDATE = 'multiActProgressUpdate';
export const multiActProgressUpdate = (nam, progress, total) => ({
  type: MULTI_ACT_PROGRESS_UPDATE,
  nam,
  progress,
  total
});
