import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import isDefined from '../../../../../shared/utils/isDefined';
import { MIXTE_ROUTE } from '../../../../containers/Navigation/constants';
import PeriodsSummaryActivity from '../../PeriodsBilling/PeriodsSummaryActivity/PeriodsSummaryActivity';
import MixteTimeSummary from '../MixteTimeSummary/MixteTimeSummary';
import PeriodTransformerFactory from '../../../../containers/PeriodsBilling/PeriodForm/transformer/PeriodTransformerFactory';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';

const shouldDisplayMixteDay = (day) => (isDefined(day.perdiems) && day.perdiems.length > 0) || isDefined(day.code);

const MixteSummaryActivity = ({ activity, isSelected }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const activityGroupedByPerdiems = PeriodTransformerFactory.create(ActivityType.MIXTE).toPeriodFormDto(activity);

  return (
    <PeriodsSummaryActivity
      activity={activityGroupedByPerdiems}
      shouldDisplayDay={shouldDisplayMixteDay}
      onSelect={() => navigate({ pathname: `${MIXTE_ROUTE}/${activity.id}`, search: location.search })}
      TimeSummaryComponent={MixteTimeSummary}
      isSelected={isSelected}
    />
  );
};

MixteSummaryActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  isSelected: PropTypes.bool
};

MixteSummaryActivity.defaultProps = {
  isSelected: false
};

export default MixteSummaryActivity;
