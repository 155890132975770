import { defineMessages } from 'react-intl';

export default defineMessages({
  selectStatementDate: {
    id: 'AdminReports.select',
    defaultMessage: 'Choisir une date'
  },
  NoticeToSelect: {
    id: 'AdminReports.home',
    defaultMessage: 'Veuillez choisir une date'
  }
});
