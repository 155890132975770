import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import { ALLER, RETOUR } from '../../../shared/ramq/domainValues/travelTypes';
import SingleSelectCheckboxGroup from '../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import FormElement from '../../components/Form/FormElement/FormElement';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  }
});

function TravelDirectionSelector({ classes }) {
  const translate = useTranslate('travel-expenses');
  return (
    <div className={classes.wrapper}>
      <Typography>{translate('travel-direction')}</Typography>
      <FormElement
        name="travelInformation.travelType"
        component={SingleSelectCheckboxGroup}
        list={[
          { label: translate('direction-departure'), value: ALLER },
          { label: translate('direction-return'), value: RETOUR }
        ]}
      />
    </div>
  );
}

export default withStyles(styles)(TravelDirectionSelector);
