import moment from 'moment-timezone';
import { ACTIVITIES_ACT_TYPE } from '../../../../../shared/collection/collectionConstants';
import ActsDay from './ActsDay';

export default class PeriodActs {
  constructor(period, acts) {
    this.period = period;
    this.type = ACTIVITIES_ACT_TYPE;
    this.initializeActsDays();
    this.addAll(acts || []);
  }

  initializeActsDays() {
    if (this.period.startDate === undefined || this.period.endDate === undefined) {
      const message = 'Received invalid period, cannot build actDays';
      throw new Error(message);
    }

    const currentDay = moment(this.period.startDate);
    const endDay = moment(this.period.endDate);

    this.activityDays = new Map();

    while (currentDay.isSameOrBefore(endDay)) {
      this.activityDays[currentDay.valueOf()] = new ActsDay(currentDay.clone());
      currentDay.add(1, 'day');
    }
  }

  addAll(acts) {
    acts.forEach((act) => this.addActivity(act));
  }

  addActivity(activity) {
    if (activity.type !== this.type) {
      throw new Error('ACTIVITY_TYPE_MISMATCH: activity type does not match instance type', activity.type, this.type);
    }

    this.addAct(activity);
  }

  addAct(act) {
    const actDay = PeriodActs.getDayOfActivity(act);

    if (this.activityDays[actDay] === undefined) {
      const message = 'trying to add act not matching a valid day';
      throw new Error(message, act, this.activityDays);
    }

    this.activityDays[actDay].addActivity(act);
  }

  getActsDay(date) {
    return this.activityDays[PeriodActs.getStartOfDay(date).valueOf()];
  }

  get totalAmount() {
    return Object.keys(this.activityDays).reduce(
      (accumulator, key) => accumulator + parseFloat(this.activityDays[key].totalAmount),
      0
    );
  }

  static getDayOfActivity(activity) {
    return PeriodActs.getStartOfDay(activity.date);
  }

  static getStartOfDay(date) {
    return moment(date).startOf('day').valueOf();
  }
}
