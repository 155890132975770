import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import CollapsableCategoryTree from '../../../../shared/category/ui/CollapsableCategoryTree/CollapsableCategoryTree';
import CodeSuggestion from '../../../../components/List/Item/CodeSuggestion/CodeSuggestion';
import { selectDiagnosticCodePreferencesFromUser } from '../../../../containers/User/selectors';
import { addDiagnosticCodeToUserPreferences } from '../../adapters/actions';
import EditDiagnosticDialog from '../EditItemDialog/EditItemDialog';
import isInFavoritesFunction from '../../../shared/domain/isInFavoritesFunction';

const UNDEFINED_CODE = -1;

export const styles = (theme) => ({
  dialogContent: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  }
});

export class DiagnosticCodePresetsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editDiagnosticDialogOpen: false,
      selectedDiagnostic: {
        code: UNDEFINED_CODE
      }
    };

    this.isInFavorites = this.isInFavorites.bind(this);
    this.handleOnFavoritesClick = this.handleOnFavoritesClick.bind(this);
    this.handleOnSuggestionClick = this.handleOnSuggestionClick.bind(this);
    this.renderDiagnostic = this.renderDiagnostic.bind(this);
    this.openEditDiagnosticDialog = this.openEditDiagnosticDialog.bind(this);
    this.closeEditDiagnosticDialog = this.closeEditDiagnosticDialog.bind(this);
    this.updateSelectedDiagnosticDescription = this.updateSelectedDiagnosticDescription.bind(this);
  }

  isInFavorites(diagnostic) {
    return isInFavoritesFunction(this.props.favoritesDiagnosticCodes, diagnostic);
  }

  handleOnFavoritesClick(diagnostic) {
    if (!this.isInFavorites(diagnostic)) {
      this.openEditDiagnosticDialog(diagnostic);
    }
  }

  handleOnSuggestionClick(diagnostic) {
    const { onItemSelected, onClose } = this.props;
    onItemSelected(diagnostic);
    onClose();
  }

  openEditDiagnosticDialog(diagnostic) {
    this.setState({
      editDiagnosticDialogOpen: true,
      selectedDiagnostic: diagnostic
    });
  }

  closeEditDiagnosticDialog() {
    this.setState({
      editDiagnosticDialogOpen: false,
      selectedDiagnostic: {
        code: UNDEFINED_CODE
      }
    });
  }

  updateSelectedDiagnosticDescription(newDescription) {
    this.props.addToFavorites({
      ...this.state.selectedDiagnostic,
      description: newDescription
    });

    this.closeEditDiagnosticDialog();
  }

  renderDiagnostic(item) {
    return (
      <CodeSuggestion
        key={item.code}
        suggestion={item}
        onSuggestionClick={this.handleOnSuggestionClick}
        onFavoriteClick={this.props.canAddItemToFavorites ? this.handleOnFavoritesClick : undefined}
        withMarkup={this.props.diagnosticCodesWithMarkup.includes(item.code)}
        isInFavorites={this.isInFavorites(item)}
      />
    );
  }

  render() {
    const { diagnosticCategoryList, onClose, classes } = this.props;
    const { selectedDiagnostic, editDiagnosticDialogOpen } = this.state;

    return (
      <>
        <DialogContent className={classes.dialogContent}>
          <CollapsableCategoryTree
            initiallyOpen={false}
            categories={diagnosticCategoryList}
            renderItem={this.renderDiagnostic}
          />
          <EditDiagnosticDialog
            key={selectedDiagnostic.code}
            open={editDiagnosticDialogOpen}
            mode="append"
            onSave={this.updateSelectedDiagnosticDescription}
            onCancel={this.closeEditDiagnosticDialog}
            diagnostic={selectedDiagnostic}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button id="close-Dialog" color="primary" onClick={onClose}>
            <FormattedMessage id="global.close" />
          </Button>
        </DialogActions>
      </>
    );
  }
}

DiagnosticCodePresetsTab.propTypes = {
  diagnosticCategoryList: PropTypes.array.isRequired,
  favoritesDiagnosticCodes: PropTypes.array.isRequired,
  diagnosticCodesWithMarkup: PropTypes.array.isRequired,
  canAddItemToFavorites: PropTypes.bool.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  favoritesDiagnosticCodes: selectDiagnosticCodePreferencesFromUser()
});

export const mapDispatchToProps = (dispatch) => ({
  addToFavorites: (code) => dispatch(addDiagnosticCodeToUserPreferences(code))
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(DiagnosticCodePresetsTab);
