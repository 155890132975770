/* eslint-disable no-await-in-loop,no-restricted-syntax */
import { remove } from 'lodash';
import moment from 'moment-timezone';
import { plog, logDefault } from '../../../shared/utils/plog';
import isDefined from '../../../shared/utils/isDefined';
import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import actPictureRepository from '../Storage/cordova/actPictureRepository';

const isDeprecated = async (picture) => {
  try {
    if (!isDefined(picture.timestamp) || picture.timestamp < moment().subtract(10, 'days').valueOf()) {
      const relatedAct = await activitiesCollectionRef().doc(picture.actId).get();
      return relatedAct.exists ? '' : picture.actId;
    }
    return '';
  } catch (_e) {
    // We will often get an error with missing permission on non existing act
    return picture.actId;
  }
};

const removeDeprecatedPictures = async (pendingPictureToBeUploaded) => {
  plog('Looking if we need to remove picture files', pendingPictureToBeUploaded);

  const deprecatedPictureIds = await Promise.all(pendingPictureToBeUploaded.map(isDeprecated));
  const tooOldInvalidPendingPictures = remove(pendingPictureToBeUploaded, (picture) =>
    deprecatedPictureIds.includes(picture.actId)
  );

  if (tooOldInvalidPendingPictures.length > 0) {
    for (const invalidPicture of tooOldInvalidPendingPictures) {
      await actPictureRepository.removeFile(invalidPicture.fileName);
      logDefault({
        type: 'picture',
        text: 'Picture/Remove deprecated',
        array: ['Removed invalid picture', invalidPicture.fileName]
      });
    }
  }

  return pendingPictureToBeUploaded;
};

export default removeDeprecatedPictures;
