import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import PersonIcon from '@material-ui/icons/Person';
import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { allActivityAreas } from '../../../../activityArea/domain/act/data';
import { displayDoctor } from '../../../Form/utils/fieldTransformations';
import PlaceDetails from '../../../Places/PlaceDetails';
import CodeContextElementsSummary from './ContextElementsSummary/CodeContextElementsSummary';
import ContextElementsSummary from './ContextElementsSummary/ContextElementsSummary';
import DetailsItem from './DetailsItem/DetailsItem';
import TextIcon from './TextIcon/TextIcon';
import DetailsNote from '../../DetailsNote/DetailsNote';

export const styles = (theme) => ({
  detailedViewContent: {
    width: '100%'
  },
  detailedViewList: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0
  }
});

const findAreaObject = (activityAreaNumber) =>
  find(allActivityAreas, (activityArea) => activityArea.value === activityAreaNumber);

const activityAreaLabel = (activityAreaNumber) => findAreaObject(activityAreaNumber).label;

const isDetailableActivityArea = (activityAreaNumber) => findAreaObject(activityAreaNumber) !== undefined;

const renderActivityContextIfNeeded = (act) => {
  const contextElements = get(act, 'contextElements', []);
  const ramqContextElements = get(act, 'ramqContextElements', []).map(({ code }) => code);
  const elements = [].concat(contextElements, ramqContextElements);

  const codes = get(act, 'codes', []);

  return (
    <>
      {elements.length > 0 && (
        <DetailsItem icon={<TextIcon text="E.C." />} text={<ContextElementsSummary contextElements={elements} />} />
      )}

      {codes.map((code) => (
        <CodeContextElementsSummary code={code} key={code.code} />
      ))}
    </>
  );
};

export function Details({ open, act, classes }) {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit className={classes.detailedViewContent}>
      <List dense className={classes.detailedViewList}>
        {act.place && <PlaceDetails place={act.place} />}
        {isDetailableActivityArea(act.activityArea) && (
          <DetailsItem icon={<TextIcon text="S.A." />} text={activityAreaLabel(act.activityArea)} />
        )}
        {renderActivityContextIfNeeded(act)}
        {!isEmpty(act.doctor) && <DetailsItem icon={<PersonIcon />} text={displayDoctor(act.doctor)} />}
        <DetailsNote activity={act} />
      </List>
    </Collapse>
  );
}

Details.propTypes = {
  open: PropTypes.bool.isRequired,
  act: PropTypes.object.isRequired
};

export default withStyles(styles)(Details);
