import { remove } from 'lodash';
import { takeEvery } from 'redux-saga/effects';
import { ACTIVITIES_ACT_TYPE } from '../../../shared/collection/collectionConstants';
import { operateActivitiesWithUserFilter, setDocument, updateDocument } from '../../firebase/document/documentSagas';
import MANUAL_EDITION_MODE from '../Mixte/constants';
import { UPDATE_PERIOD, updatePeriodFailure } from './actions';

const isEmpty = (day) =>
  (!day.perdiems || day.perdiems.length === 0) && !day.code && !day.start && !day.end && !day[MANUAL_EDITION_MODE];

export function* savePeriod(failureAction, action) {
  const { document } = action;
  remove(document.days, (day) => isEmpty(day));
  return yield* operateActivitiesWithUserFilter(document.type, setDocument, failureAction, action);
}

export default function* periodsSaga() {
  yield takeEvery(
    UPDATE_PERIOD,
    operateActivitiesWithUserFilter,
    ACTIVITIES_ACT_TYPE,
    updateDocument,
    updatePeriodFailure
  );
}
