import {
  addAmount,
  computeExpectedAmountForDirectPaymentActivity,
  directPaymentActivityHasExpectedAmount,
  isAct,
  isLumpSum,
  isMixte,
  isNotCanceledStatus,
  isTraining,
  isTravelExpenses
} from './computeExpectedAmountUtil';
import computeExpectedAmountForLumpSum from './computeLumpSumExpectedAmount';
import computeExpectedAmountForMixte from './computeMixteExpectedAmount';
import computeExpectedAmountForTraining from './computeExpectedAmoutForTraining';

export const activityHasExpectedAmount = (activity) =>
  (isMixte(activity) && isNotCanceledStatus(activity)) ||
  (isLumpSum(activity) && isNotCanceledStatus(activity)) ||
  (isAct(activity) && directPaymentActivityHasExpectedAmount(activity)) ||
  (isTravelExpenses(activity) && directPaymentActivityHasExpectedAmount(activity)) ||
  (isTraining(activity) && isNotCanceledStatus(activity));

export const computeExpectedAmountForActivities = (activities) => activities.reduce(sumUpActivitiesAmount, '0.00');

const sumUpActivitiesAmount = (sum, currentActivity) =>
  addAmount(sum, computeExpectedAmountForActivity(currentActivity));

export const computeExpectedAmountForActivity = (activity) => {
  if (isAct(activity) || isTravelExpenses(activity)) {
    return computeExpectedAmountForDirectPaymentActivity(activity);
  }
  if (isMixte(activity) && isNotCanceledStatus(activity)) {
    return computeExpectedAmountForMixte(activity);
  }
  if (isLumpSum(activity) && isNotCanceledStatus(activity)) {
    return computeExpectedAmountForLumpSum(activity);
  }
  if (isTraining(activity) && isNotCanceledStatus(activity)) {
    return computeExpectedAmountForTraining(activity);
  }

  return '0.00';
};
