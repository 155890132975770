import MomentTimezone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { get, last, sortBy, sum } from 'lodash';
import { MIXTE_LOWER_RANGE_HOUR, MIXTE_MIDDLE_RANGE_HOUR, MIXTE_UPPER_RANGE_HOUR } from './constants';
import { AM_PERDIEM, PM_PERDIEM } from '../timeSlot/constants';
import { DEFERRED_PAYMENT_CONFIRMATION_TYPE } from '../../ramq/constants';

export const isPerdiemAdjustmentCompleted = (adjustmentLeft) => adjustmentLeft === 0;

export const canBalanceMore = (dayWithLeastMissingHours, totalExtraHours) =>
  dayWithLeastMissingHours &&
  totalExtraHours - dayWithLeastMissingHours.extraHours >= dayWithLeastMissingHours.missingHours;

export const hasEnoughHoursToBalance = (dayWithLeastMissingHours, dayWithLeastExtraHours, totalExtraHours) =>
  dayWithLeastExtraHours &&
  dayWithLeastMissingHours.missingHours > 0 &&
  totalExtraHours - dayWithLeastMissingHours.extraHours >= dayWithLeastMissingHours.missingHours;

export const getPerdiems = ({ date, start, end }) => {
  const moment = extendMoment(MomentTimezone);

  const perdiems = [];
  const mixteLowerRange = moment(date).hours(MIXTE_LOWER_RANGE_HOUR);
  const mixteMiddleRange = moment(date).hours(MIXTE_MIDDLE_RANGE_HOUR);
  const mixteUpperRange = moment(date).hours(MIXTE_UPPER_RANGE_HOUR);

  const actStart = moment(start);

  const actEnd = start <= end ? moment(end) : moment(end).add(1, 'day');

  const mixteAMRange = moment.range(mixteLowerRange, mixteMiddleRange);
  const mixtePMRange = moment.range(mixteMiddleRange, mixteUpperRange);

  const actRange = moment.range(actStart, actEnd);

  if (actRange.overlaps(mixteAMRange)) {
    perdiems.push(AM_PERDIEM);
  }

  if (actRange.overlaps(mixtePMRange)) {
    perdiems.push(PM_PERDIEM);
  }

  return perdiems;
};

export const extractLastSuccessDeferredPaymentFromMixte = (mixte) =>
  last(
    sortBy(
      get(mixte, 'ramqExchange', []).filter(({ type }) => type === DEFERRED_PAYMENT_CONFIRMATION_TYPE),
      'createdOn'
    )
  );

export const splitDurationInPerDiem = (duration, perDiems) =>
  perDiems.reduce((accumulator, perDiem, index) => {
    if (index + 1 === perDiems.length) {
      accumulator[perDiem] = duration - sum(Object.values(accumulator));
    } else {
      accumulator[perDiem] = duration > 7 ? 5 : 3.5;
    }

    return accumulator;
  }, {});
