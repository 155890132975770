import TextInput from 'application/components/form-controls/text-input';
import useTranslate from 'application/hooks/use-translate';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import React, { useCallback, useEffect, useState } from 'react';

interface Props extends ValidatedFieldProps {
  value: string;
  mask: string;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

const applyMask = (value: string, mask: string, placeholder: string) => {
  if (!value) return '';

  if (!validateMask(value, mask, placeholder, true)) {
    return value;
  }

  let valueCharIndex = 0;
  let maskedValue = '';

  for (const maskChar of mask.split('')) {
    if (maskChar === '_') {
      if (valueCharIndex === value.length) {
        break;
      }

      maskedValue += value[valueCharIndex++];
    } else {
      maskedValue += maskChar;
    }
  }

  return maskedValue;
};

const validateMask = (value: string, mask: string, placeholder: string, required: boolean): boolean => {
  if (!required && !value) return true;

  const maskChars = mask.split('');
  const placeholderChars = placeholder.split('');

  return (
    value.split('').every((valueChar) => {
      if (maskChars.length === 0) return false;

      while (maskChars[0] !== '_') {
        maskChars.shift();
        placeholderChars.shift();
      }

      if (/[0-9]/.test(placeholderChars[0]) && !/[0-9]/.test(valueChar)) return false;
      if (/[a-zA-Z]/.test(placeholderChars[0]) && !/[a-zA-Z]/.test(valueChar)) return false;

      maskChars.shift();
      placeholderChars.shift();

      return true;
    }) && maskChars.length === 0
  );
};

const MaskedInput = ({
  value,
  mask,
  placeholder,
  disabled = false,
  required = false,
  onChange,
  onValidate,
  ...textInputProps
}: Props) => {
  const translate = useTranslate('components.form-controls.masked-input');
  const [maskedValue, setMaskedValue] = useState<string | null>(applyMask(value, mask, placeholder));

  const isMaskValid = validateMask(value, mask, placeholder, required);
  const isRequiredValid = required ? !!value : true;
  const isValid = isRequiredValid && isMaskValid;

  useEffect(() => {
    if (!onValidate) return;
    onValidate(isValid);
  }, [isValid, onValidate]);

  const handleOnBlur = useCallback(() => {
    const strippedValue = value.replace(/[^0-9a-zA-Z]/g, '');
    if (strippedValue !== value) {
      onChange(strippedValue.toUpperCase());
    }

    setMaskedValue(applyMask(strippedValue, mask, placeholder));
  }, [mask, onChange, placeholder, value]);

  if (placeholder.length !== mask.length) {
    throw new Error(`Placeholder and mask must have the same length. Placeholder: "${placeholder}", mask: "${mask}"`);
  }

  return (
    <TextInput
      value={maskedValue ?? value}
      type="text"
      placeholder={placeholder}
      disabled={disabled}
      customValidation={
        isRequiredValid
          ? { isValid: isMaskValid, errorMessage: translate('format-error', { format: placeholder }) }
          : undefined
      }
      onChange={(value) => onChange(value.toUpperCase())}
      onFocus={() => setMaskedValue(null)}
      onBlur={handleOnBlur}
      required={required}
      {...textInputProps}
    />
  );
};

export default MaskedInput;
