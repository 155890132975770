import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserLanguage } from 'app/containers/User/selectors';
import { SelectorField } from '../../../../components/Form/Fields';
import frInternationals from '../../../../../translations/fr';
import enInternationals from '../../../../../translations/en';
import getSpecialtyOptions from '../../intl/specialtyDataOptionGenerator';

export function DoctorSpecialtySelectorField(props) {
  const { language, ...rest } = props;

  const internationals = language === 'fr' ? frInternationals : enInternationals;

  const specialtyOptions = getSpecialtyOptions(internationals);

  return <SelectorField {...rest} list={specialtyOptions} />;
}

DoctorSpecialtySelectorField.propTypes = {
  language: PropTypes.string.isRequired
};

export const mapStateToProps = createStructuredSelector({
  language: selectUserLanguage()
});

export default connect(mapStateToProps)(DoctorSpecialtySelectorField);
