import React, { useMemo, useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import classNames from 'classnames';
import Icon from 'application/components/icon';

interface Props {
  className?: string;
  placeholder?: string;
  options: DropdownOption[];
  onChange: (value: string) => void;
  value: string | null;
}

export interface DropdownOption {
  label: string;
  value: string;
}

const Dropdown = ({ className, placeholder, onChange, options, value }: Props) => {
  const [isDropdownOpened, toggleIsDropdownOpened] = useToggle(false);
  const { selectedOption } = useMemo(
    () => ({ selectedOption: options.find((option) => option.value === value) }),
    [options, value]
  );
  const ref = useRef(null);

  useClickAway(ref, () => toggleIsDropdownOpened(false));

  const handleOptionClick = (item: DropdownOption) => {
    toggleIsDropdownOpened(false);
    onChange(item.value);
  };

  return (
    <div ref={ref} className={classNames('dropdown-start dropdown dropdown-open', className)}>
      <button type="button" className="btn btn-link rounded-btn px-0 font-bold" onClick={toggleIsDropdownOpened}>
        {selectedOption?.label || placeholder} <Icon icon="circle-chevron-down" className="ml-2 text-primary" />
      </button>
      {isDropdownOpened && (
        <ul className="menu dropdown-content z-50 mt-4 min-w-max rounded-b-lg bg-base-100 shadow">
          {options.map((option) => (
            <li key={option.value}>
              <button
                type="button"
                className="flex justify-between text-left"
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
                {selectedOption?.value === option.value && <Icon icon="check" className="text-primary" />}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
