import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import isDefined from '../../../../shared/utils/isDefined';
import PrintHeader from '../../ReportsRoute/PrintHeader/PrintHeader';
import messages from '../messages';
import AvailableStatementSelector from '../AvailableStatementSelector/AvailableStatementSelector';
import DoctorSpecialtyMultiSelector from '../DoctorSpecialtyMultiSelector/DoctorSpecialtyMultiSelector';
import StatementReports from '../StatementsReport/StatementReports';
import StatementSectionsSelector from '../StatementSectionsSelector/StatementSectionsSelector';
import GroupMultiSelector from '../GroupMultiSelector/GroupMultiSelector';

export const styles = () => ({
  typography: {
    alignSelf: 'center'
  },
  select: {
    minWidth: '115px'
  },
  empty: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  }
});

export class AdminReportContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null,
      selectedSpecialtyGroup: [],
      selectedGroupIds: [],
      showSection20: true
    };
  }

  assignComponentRef = (el) => {
    this.componentRef = el;
  };

  renderAvailableStatementSelector = () => (
    <AvailableStatementSelector
      date={this.state.selectedDate}
      onChange={(date) => this.setState({ selectedDate: date })}
    />
  );

  renderDoctorSpecialtyMultiSelector = () => (
    <Restrict atLeastRole={Role.Admin}>
      <DoctorSpecialtyMultiSelector
        specialties={this.state.selectedSpecialtyGroup}
        onChange={(specialties) => this.setState({ selectedSpecialtyGroup: specialties })}
      />
    </Restrict>
  );

  renderGroupMutliSelector = () => (
    <div style={{ maxWidth: '350px' }}>
      <GroupMultiSelector
        selectedGroupIds={this.state.selectedGroupIds}
        onChange={(groupIds) => this.setState({ selectedGroupIds: groupIds })}
      />
    </div>
  );

  renderStatementSectionsMultiSelector = () => (
    <StatementSectionsSelector
      value={this.state.showSection20}
      onChange={(value) => this.setState({ showSection20: value })}
    />
  );

  render() {
    const { classes } = this.props;
    const { selectedDate, selectedSpecialtyGroup, showSection20, selectedGroupIds } = this.state;

    return (
      <>
        <PrintHeader
          componentToPrintRef={() => this.componentRef}
          SelectorComponents={[
            this.renderAvailableStatementSelector,
            this.renderDoctorSpecialtyMultiSelector,
            this.renderGroupMutliSelector,
            this.renderStatementSectionsMultiSelector
          ]}
        />
        {selectedDate !== null ? (
          <StatementReports
            parameters={{
              date: selectedDate,
              specialties: selectedSpecialtyGroup,
              groupIds: selectedGroupIds,
              showSection20
            }}
            printableAreaRef={this.assignComponentRef}
          />
        ) : (
          <Typography className={isDefined(classes) ? classes.empty : null}>
            <FormattedMessage {...messages.NoticeToSelect} />
          </Typography>
        )}
      </>
    );
  }
}

AdminReportContainer.propTypes = {
  intl: PropTypes.object.isRequired
};

export default compose(injectIntl, withStyles(styles))(AdminReportContainer);
