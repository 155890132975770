import useTranslate from 'application/hooks/use-translate';
import React, { useMemo } from 'react';
import Chart from 'application/components/chart/chart';
import StatisticsService from 'application/services/statistics-service';
import { useQuery } from '@tanstack/react-query';

const Configurator = () => null;

const Renderer = () => {
  const translate = useTranslate('pages.administration.stats');

  const { data, isFetching } = useQuery({
    queryKey: ['users-specialty-statistics'],
    queryFn: () => StatisticsService.getUsersSpecialty()
  });

  const sortedData = useMemo(() => {
    if (!data) return [];
    return [...(data ?? [])].sort((a, b) => b.count - a.count);
  }, [data]);

  return (
    <div className="flex items-center">
      <Chart
        loading={isFetching}
        type="doughnut"
        title={translate('users-by-specialty')}
        data={
          data?.map((row) => ({
            label: translate(row.specialty),
            value: row.count
          })) ?? []
        }
        showLegend
      />

      <div>
        <div className="stats stats-vertical shadow">
          {sortedData.map((row) => (
            <div className="stat">
              <div className="stat-title">{translate(row.specialty)}</div>
              <div className="stat-value">{row.count}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default {
  Configurator,
  Renderer
};
