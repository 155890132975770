import React, { useMemo, useState } from 'react';
import { omit, keys } from 'lodash';
import { injectIntl } from 'react-intl';
import { Badge, Button, Card, Chip, StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  frFR,
  GridState,
  GridDensityTypes,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { compose } from 'redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { GroupType } from 'application/types/groups';
import DialogCenteredTitle from '../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import User from '../../../../shared/domain/user/User';
import { USER_ACCOUNT_STATUS_COLORS, USER_ACCOUNT_STATUS_TEXT } from '../../../components/UserStatus/UserStatus';
import UserAccountStatus from '../../../../shared/domain/user/profile/UserAccountStatus';
import { DEFAULT_DATE_FORMAT } from '../../../../shared/utils/dateTime/dateTimeUtils';
import Absence from '../../../../shared/domain/user/Absence/Absence';
import DebouncedTextField from '../../../components/Form/DebouncedTextField';
import {
  ACTIVE_ABSENCE,
  FULL_NAME,
  GROUP_MANAGERS_NAMES,
  GROUP_NAME,
  PLACE,
  POOL_MANAGERS_NAMES,
  POOL_NAME,
  PRACTICE_NUMBER,
  ROLE,
  SPECIALTY,
  SUBSCRIPTION_PACKAGE,
  USER_STATUS
} from './ColumnFields';
import UserSearchScopes from './UserSearchScopes';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  datagrid: {
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none'
    },
    'flex': '1 1 auto',
    'minHeight': 300
  },
  row: {
    cursor: 'pointer'
  },
  practiceNumberSelected: {
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white
  },
  minWidth: {
    minWidth: theme.spacing(9)
  },
  searchInput: {
    padding: '0 24px 12px 24px'
  },
  searchInputContainer: {
    marginBottom: theme.spacing(2),
    flex: '0 0 auto'
  }
});

interface Props {
  title: string;
  users: never[];
  classes: any;
  currentUserScope: object;
  isSelectedFunction: (user: User) => boolean;
  onItemSelected: (string) => void;
  onPageChange: (number) => void;
  onScopeChange: (object) => void;
  label: string;
  loading: boolean;
  intl: any;
  searchTerm?: string;
  totalCount: number;
  pageSize: number;
  currentPage: number;
}

export const UserSearchResults: React.FunctionComponent<Props> = ({
  title,
  users,
  totalCount,
  pageSize,
  currentPage,
  classes,
  currentUserScope,
  isSelectedFunction,
  onItemSelected,
  onPageChange,
  onScopeChange,
  label,
  loading,
  intl,
  searchTerm
}: Props) => {
  const USERS_GRID_COLUMNS_VISIBILITY = 'USERS_GRID_COLUMNS_VISIBILITY';
  const USERS_GRID_DENSITY = 'USERS_GRID_DENSITY';

  const getVisibilityMapInLocalStorage = () => {
    const usersGridColumnsVisibility = localStorage.getItem(USERS_GRID_COLUMNS_VISIBILITY);
    const usersGridColumnsVisibilityMap = usersGridColumnsVisibility
      ? new Map<string, boolean>(JSON.parse(usersGridColumnsVisibility))
      : new Map<string, boolean>();
    return usersGridColumnsVisibilityMap;
  };

  const getColumnVisibility = (field) => {
    const map = getVisibilityMapInLocalStorage();
    const visibility = map.get(field) === undefined || map.get(field);
    return {
      hide: !visibility
    };
  };

  const getDensity = () =>
    (localStorage.getItem(USERS_GRID_DENSITY) || GridDensityTypes.Standard.valueOf()) as GridDensityTypes;

  const onStateChange = (gridState: GridState) => {
    localStorage.setItem(USERS_GRID_DENSITY, gridState.density.value);

    const map = getVisibilityMapInLocalStorage();
    Object.entries(gridState.columns.lookup).forEach(([field, values]) => {
      map.set(field, !values.hide);
    });
    localStorage.setItem(USERS_GRID_COLUMNS_VISIBILITY, JSON.stringify(Array.from(map.entries())));
  };

  const columnOverrides: GridColDef[] = useMemo(
    () => [
      {
        field: USER_STATUS,
        sortable: false,
        ...getColumnVisibility(USER_STATUS),
        headerName: 'Statut',
        minWidth: 115,
        flex: 0.5,
        valueGetter: (params) => USER_ACCOUNT_STATUS_TEXT[params.value as UserAccountStatus],
        renderCell: (params) =>
          params.value && (
            <Chip
              label={params.value?.toString()}
              style={{ backgroundColor: USER_ACCOUNT_STATUS_COLORS[params.row.status] }}
            />
          )
      },
      {
        field: PRACTICE_NUMBER,
        sortable: false,
        ...getColumnVisibility(PRACTICE_NUMBER),
        headerName: 'Numéro de pratique',
        minWidth: 205,
        flex: 0.5,
        renderCell: (params) => (
          <Chip
            label={params.value?.toString()}
            className={classNames(
              { [classes.practiceNumberSelected]: isSelectedFunction(params.row as User) },
              classes.minWidth
            )}
          />
        )
      },
      {
        field: FULL_NAME,
        sortable: false,
        ...getColumnVisibility(FULL_NAME),
        headerName: 'Nom',
        minWidth: 300,
        flex: 1
      },
      {
        field: SPECIALTY,
        sortable: false,
        ...getColumnVisibility(SPECIALTY),
        headerName: 'Spécialité',
        minWidth: 225,
        valueGetter: (params) => params.value && intl.formatMessage({ id: `global.specialty.${params.value}` })
      },
      {
        field: GROUP_NAME,
        sortable: false,
        ...getColumnVisibility(GROUP_NAME),
        headerName: 'Groupe',
        minWidth: 200,
        flex: 0.5,
        valueGetter: (params) =>
          params.row.groups
            .filter(({ type }) => type === GroupType.MANAGEMENT)
            .map(({ name }) => name)
            .join(', ')
      },
      {
        field: GROUP_MANAGERS_NAMES,
        sortable: false,
        ...getColumnVisibility(GROUP_MANAGERS_NAMES),
        headerName: 'Responsable(s) Groupe',
        minWidth: 300,
        flex: 1,
        valueGetter: (params) => {
          const set = new Set();
          params.row.groups
            .filter(({ type }) => type === GroupType.MANAGEMENT)
            .map(({ owners }) => owners.map(({ fullName }) => fullName))
            .forEach((fullNames) => fullNames.forEach((fullName) => set.add(fullName)));
          return Array.from(set).join(', ');
        }
      },
      {
        field: POOL_NAME,
        sortable: false,
        ...getColumnVisibility(POOL_NAME),
        headerName: 'Pool',
        minWidth: 200,
        flex: 0.5,
        valueGetter: (params) =>
          params.row.groups
            .filter(({ type }) => type === GroupType.POOL)
            .map(({ name }) => name)
            .join(', ')
      },
      {
        field: POOL_MANAGERS_NAMES,
        sortable: false,
        ...getColumnVisibility(POOL_MANAGERS_NAMES),
        headerName: 'Responsable(s) Pool',
        minWidth: 300,
        flex: 1,
        valueGetter: (params) => {
          const set = new Set();
          params.row.groups
            .filter(({ type }) => type === GroupType.POOL)
            .map(({ owners }) => owners.map(({ fullName }) => fullName))
            .forEach((fullNames) => fullNames.forEach((fullName) => set.add(fullName)));
          return Array.from(set).join(', ');
        }
      },
      {
        field: SUBSCRIPTION_PACKAGE,
        sortable: false,
        ...getColumnVisibility(SUBSCRIPTION_PACKAGE),
        headerName: 'Forfait',
        minWidth: 250,
        flex: 0.5,
        valueGetter: (params) => {
          if (!params.row.stripeProductName) return null;
          return params.row.stripeProductName;
        }
      },
      {
        field: PLACE,
        sortable: false,
        ...getColumnVisibility(PLACE),
        headerName: 'Établissement principal',
        minWidth: 300,
        flex: 1,
        valueGetter: (params) => {
          const place = [...params.row.facilities].sort((a, b) => a.priority - b.priority)[0];

          if (!place) {
            return 'N/A';
          }

          return `(${place.number}) ${place.name}`;
        }
      },
      {
        field: ACTIVE_ABSENCE,
        sortable: false,
        ...getColumnVisibility(ACTIVE_ABSENCE),
        headerName: 'Absence',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => {
          if (!params.row.activeAbsence) return null;

          const activeAbsence = params.row.activeAbsence as Absence;
          if (activeAbsence) {
            return (
              <div>
                {activeAbsence.reason} {moment(activeAbsence.startDate).format(DEFAULT_DATE_FORMAT)} au{' '}
                {moment(activeAbsence.endDate).format(DEFAULT_DATE_FORMAT)}
              </div>
            );
          }
          return null;
        }
      },
      {
        field: ROLE,
        sortable: false,
        ...getColumnVisibility(ROLE),
        headerName: 'Rôle',
        minWidth: 150,
        flex: 0.5,
        valueGetter: (params) =>
          params.value && intl.formatMessage({ id: `global.role.${params.value.toString().toLowerCase()}` })
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const rows: GridRowsProp = users;

  const [userSearchScopeIsOpened, setUserSearchScopeIsOpened] = useState(false);

  return (
    <div className={classes.root}>
      <Card className={classes.searchInputContainer}>
        <DialogCenteredTitle label={title} />
        <div className={classes.searchInput}>
          <DebouncedTextField
            label={label}
            fullWidth
            onSubmit={(searchTerm) => onScopeChange({ ...currentUserScope, searchTerm: searchTerm || undefined })}
            debounceInMS={300}
            initialValue={searchTerm ?? ''}
          />

          <UserSearchScopes
            isOpened={userSearchScopeIsOpened}
            onScopeClose={() => setUserSearchScopeIsOpened(false)}
            activeScopes={currentUserScope}
            onScopeChange={onScopeChange}
          />
        </div>
      </Card>
      <DataGrid
        className={classNames(classes.datagrid)}
        loading={loading}
        columns={columnOverrides}
        rows={rows}
        density={getDensity()}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <GridToolbarDensitySelector
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <GridToolbarExport
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />
              <Badge
                overlap="rectangular"
                color="secondary"
                badgeContent={keys(omit(currentUserScope, 'searchTerm')).length}
              >
                <Button
                  size="small"
                  color="primary"
                  startIcon={<FilterIcon />}
                  onClick={() => setUserSearchScopeIsOpened(!userSearchScopeIsOpened)}
                >
                  Filtres avancés
                </Button>
              </Badge>
            </GridToolbarContainer>
          )
        }}
        onStateChange={onStateChange}
        onRowClick={(params) => onItemSelected(params.id)}
        getRowClassName={() => classes.row}
        localeText={(frFR as any).props.MuiDataGrid.localeText}
        disableColumnFilter
        rowCount={totalCount}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        page={currentPage - 1}
        paginationMode="server"
        onPageChange={(newPage) => onPageChange(newPage + 1)}
      />
    </div>
  );
};

export default compose(injectIntl, withStyles(styles))(UserSearchResults);
