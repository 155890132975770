import moment from 'moment-timezone';
import Period from './Period';

function calculateORFromPeriod(period: Period): number | undefined {
  const DEFAULT_BILLING_PERIOD_END_DATE = moment('2016-01-04');
  const OR_INITIAL_OFFSET = 2;

  const difference = period.getEnd().diff(DEFAULT_BILLING_PERIOD_END_DATE, 'weeks');

  if (difference < 0) {
    return undefined;
  }

  return (difference % 52) + OR_INITIAL_OFFSET;
}

export default calculateORFromPeriod;
