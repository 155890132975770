import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down('xs')]: {
      placeItem: {
        paddingLeft: 0
      },
      placeItemText: {
        paddingLeft: 0
      }
    }
  };
};

export class PlaceSuggestion extends React.PureComponent {
  render() {
    const { onSuggestionClick, suggestion, classes } = this.props;
    return (
      <ListItem button onClick={() => onSuggestionClick(suggestion)} className={classes.placeItem}>
        <ListItemIcon>
          <Chip label={suggestion.number} />
        </ListItemIcon>
        <ListItemText primary={suggestion.name} className={classes.placeItemText} />
      </ListItem>
    );
  }
}

PlaceSuggestion.propTypes = {
  onSuggestionClick: PropTypes.func.isRequired,
  suggestion: PropTypes.object.isRequired
};

export default withStyles(styles)(PlaceSuggestion);
