import Collection from '../../../../../shared/collection/Collection';
import Act, { ActCode, NatureType } from '../../../../../shared/domain/activity/act/model/Act';
import ActCodes from '../../../../../shared/domain/activity/act/model/ActCodes';
import { RULE_28_ACT_CODES } from '../../../../../shared/ramq/requiredDetails/rule28';

interface ReturnType {
  rule28ActCodes: Collection<ActCode>;
  notPg28WithPDTorSurgeryNatureType: Collection<ActCode>;
}

export function extractRule28ActCodesAndNotPg28WithPdtOrSurgeryNatureTypeActCodes(act: Act): ReturnType {
  const [rule28ActCodes, notRule28ActCodes] = new ActCodes(act.codes).partition(byRule28ActCodes);
  const [, notPg28WithPDTorSurgeryNatureType] = notRule28ActCodes.partition(byPg28CodeWithSurgeonOrPdtNatureType);

  return { rule28ActCodes, notPg28WithPDTorSurgeryNatureType };
}

export function extractRule28ActCodesAndPg28WithPdtOrSurgeryNatureTypeActCodes(act: Act) {
  const [rule28ActCodes, notRule28ActCodes] = new ActCodes(act.codes).partition(byRule28ActCodes);
  const [pg28WithPDTorSurgeryNatureType] = notRule28ActCodes.partition(byPg28CodeWithSurgeonOrPdtNatureType);

  return { rule28ActCodes, pg28WithPDTorSurgeryNatureType };
}

const byRule28ActCodes = (actCode) => RULE_28_ACT_CODES.includes(actCode.code);

const byPg28CodeWithSurgeonOrPdtNatureType = (actCode) =>
  (actCode.description || '').includes('(PG-28)') && [NatureType.SURGERY, NatureType.PDT].includes(actCode.natureType!);
