import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonCheckbox from '../ButtonCheckbox/ButtonCheckbox';

export const styles = () => ({
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  }
});

export function CheckboxGroup({
  id,
  classes,
  checkboxes,
  labelProp,
  valueProp,
  onCheckboxSelected,
  isChecked,
  className,
  checkboxClasses,
  disabled = false
}) {
  const mergedClassName = classNames(classes.checkboxGroup, className);
  return (
    <div id={id} className={mergedClassName}>
      {checkboxes.map((checkbox) => (
        <ButtonCheckbox
          disabled={!!checkbox.disabled || disabled}
          classes={checkboxClasses}
          key={checkbox[valueProp]}
          label={checkbox[labelProp]}
          onClick={() => onCheckboxSelected(checkbox[valueProp])}
          checked={isChecked(checkbox)}
        />
      ))}
    </div>
  );
}

CheckboxGroup.propTypes = {
  checkboxes: PropTypes.array.isRequired,
  labelProp: PropTypes.string,
  valueProp: PropTypes.string,
  onCheckboxSelected: PropTypes.func.isRequired,
  isChecked: PropTypes.func.isRequired,
  checkboxClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool
};

CheckboxGroup.defaultProps = {
  labelProp: 'label',
  valueProp: 'value',
  checkboxClasses: undefined,
  className: undefined,
  id: ''
};

export default withStyles(styles)(CheckboxGroup);
