import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import React from 'react';

const styles = (theme) => ({
  loadingContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary[500],
    height: 'inherit',
    flex: 1
  },
  darkBackground: {
    backgroundColor: theme.palette.primary[500],
    color: 'white'
  },
  progress: {
    margin: `${theme.spacing(1)}px`
  },
  notFlex: {
    position: 'absolute',
    height: '100%'
  }
});

interface Props {
  classes: any;
  title?: string;
  dark?: boolean;
  parentNotFlex?: boolean;
}

function FullScreenLoading({ title, dark = false, parentNotFlex = false, classes }: Props) {
  const className = classNames(
    classes.loadingContainer,
    { [classes.darkBackground]: dark },
    { [classes.notFlex]: parentNotFlex }
  );
  return (
    <div className={className}>
      <Fade in style={{ transitionDelay: '700ms' }}>
        <CircularProgress title={title} color="inherit" className={classes.progress} />
      </Fade>
    </div>
  );
}

export default withStyles(styles as any)(FullScreenLoading);
