import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import isDefined from '../../../../shared/utils/isDefined';
import { LUMP_SUM_ROUTE } from '../../../containers/Navigation/constants';
import PeriodsSummaryActivity from '../PeriodsBilling/PeriodsSummaryActivity/PeriodsSummaryActivity';
import LumpSumTimeSummary from './LumpSumTimeSummary';

const shouldDisplayLumpSumDay = (day) => isDefined(day.code) || isDefined(day.start) || isDefined(day.end);

export const LumpSumSummaryActivity = ({ activity, isSelected }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PeriodsSummaryActivity
      activity={activity}
      shouldDisplayDay={shouldDisplayLumpSumDay}
      onSelect={() =>
        navigate({
          pathname: `${LUMP_SUM_ROUTE}/${activity.id}`,
          search: location.search
        })
      }
      TimeSummaryComponent={LumpSumTimeSummary}
      isSelected={isSelected}
    />
  );
};

LumpSumSummaryActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  isSelected: PropTypes.bool
};

LumpSumSummaryActivity.defaultProps = {
  isSelected: false
};

export default LumpSumSummaryActivity;
