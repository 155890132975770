import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';
import PropTypes from 'prop-types';
import React from 'react';

export function NativeSelector({ input, values, disableFirstOption }) {
  return (
    <NativeSelect value={input.value} onChange={input.onChange} input={<Input />}>
      {values.map((value, position) => (
        <option
          hidden={position === 0 && disableFirstOption}
          disabled={position === 0 && disableFirstOption}
          key={value.key || value.value || value}
          value={value.value || value}
        >
          {value.label || value}
        </option>
      ))}
    </NativeSelect>
  );
}

NativeSelector.defaultProps = {
  disableFirstOption: false
};

NativeSelector.propTypes = {
  input: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  disableFirstOption: PropTypes.bool
};

export default NativeSelector;
