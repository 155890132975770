import EvaqCodeTimeSlotCalculator, { EVAQ_CODE_FIRST_THIRTEEN_WORK_HOURS } from './EvaqTimeSlotCalculator';
import CAPQTimeSlotCalculator, { CAPQ_CODES } from './CAPQTimeSlotCalculator';
import DefaultTimeSlotCalculator from './DefaultTimeSlotCalculator';

export default class TimeSlotCalculatorFactory {
  static create(code) {
    if (code === EVAQ_CODE_FIRST_THIRTEEN_WORK_HOURS) {
      return new EvaqCodeTimeSlotCalculator();
    }

    if (CAPQ_CODES.includes(code)) {
      return new CAPQTimeSlotCalculator();
    }

    return new DefaultTimeSlotCalculator();
  }
}
