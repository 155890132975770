import Act from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';
import { getStore } from '../../../reduxStore/configureStore';
import { codeIsInRole2 } from '../../../../shared/domain/activity/act/service/actCodeUtils';
import { selectSpecialty } from '../../../containers/User/selectors';
import { NatureType } from '../../../../shared/core/activity/domain/Act';

export const role2PdtAndSurgeryExclusiveCodesValidation = (act: Act) => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (
    act.codes?.some((code) => codeIsInRole2(code, act, userSpecialty)) &&
    act.codes?.some((code) => code.natureType === NatureType.SURGERY) &&
    act.codes?.some((code) => code.natureType === NatureType.PDT)
  ) {
    const pdtCodes = act.codes.filter((code) => code.natureType === NatureType.PDT).map((code) => code.code);
    return {
      [actFormFields.codes]: {
        _error:
          pdtCodes.length > 1
            ? `Les codes PDT ${pdtCodes.join(
                ', '
              )} ne peuvent s’ajouter avec un code de chirurgie. SVP enlever et envoyer.`
            : `Le code PDT ${pdtCodes} ne peut s’ajouter avec un code de chirurgie. SVP enlever et envoyer.`
      }
    };
  }
};

export default role2PdtAndSurgeryExclusiveCodesValidation;
