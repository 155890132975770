import React from 'react';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import useTranslate from 'application/hooks/use-translate';
import { Period } from './reports/types';

interface Props {
  groupId?: string;
  onChange: (value: Period | null) => void;
  value: Period | null;
}

interface ReportBillingPeriodSelectorValue {
  start: number;
  end: number;
}

const GROUP_BILLING_PERIODS = gql`
  query FetchGroupBillingPeriods($groupId: String!) {
    group(groupId: $groupId) {
      billingPeriods {
        start
        end
        paymentNumber
      }
    }
  }
`;

interface Group {
  billingPeriods: Array<{
    start: string;
    end: string;
    paymentNumber: string;
  }>;
}

const ReportBillingPeriodSelector = ({ groupId, onChange, value }: Props) => {
  const translate = useTranslate('reports');
  const { data } = useQuery<{ group: Group }>(GROUP_BILLING_PERIODS, { variables: { groupId } });
  const billingPeriods = data?.group.billingPeriods || [];
  const selectedPeriod = value as ReportBillingPeriodSelectorValue | null;

  return (
    <select
      id="report-billing-period-selector"
      className="select-bordered select mr-4"
      onChange={(event) => onChange(JSON.parse(event.target.value) as ReportBillingPeriodSelectorValue)}
      disabled={billingPeriods.length === 0}
      defaultValue={selectedPeriod ? JSON.stringify(selectedPeriod) : ''}
    >
      <option value="" disabled>
        {translate('billing-period')}
      </option>
      {billingPeriods.map(({ start, end, paymentNumber }) => (
        <option
          key={`${start}, ${end}, ${paymentNumber}`}
          value={JSON.stringify({ start: moment(start).valueOf(), end: moment(end).valueOf() })}
        >
          {translate('billing-period-range', {
            start: moment(start).format('DD MMM. YYYY'),
            end: moment(end).format('DD MMM. YYYY'),
            paymentNumber
          })}
        </option>
      ))}
    </select>
  );
};

export default ReportBillingPeriodSelector;
