import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Theme, WithStyles } from '@material-ui/core';
import classNames from 'classnames';

import HourMinuteSelectorDialog from '../../../../containers/ActForm/HourMinuteSelectorDialog/HourMinuteSelectorDialog';
import HourMinute from '../../../../containers/ActForm/HourMinuteSelector/HourMinute/HourMinute';
import DialogTrigger from '../../../Dialogs/DialogTrigger/DialogTrigger';
import { formatDecimalHourToHourMinuteString } from '../../../../../shared/utils/dateTime/dateTimeUtils';

export const styles: StyleRulesCallback<Theme, Props> = () => ({
  timeInputContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  timeInputLabel: {
    top: -5,
    left: 'unset',
    transformOrigin: 'top',
    transform: 'scale(0.75)'
  },
  timeButton: {
    fontSize: '1rem'
  },
  errorTimeHelperText: {
    position: 'absolute',
    bottom: 0
  }
});

export interface Props extends WrappedFieldProps, WithStyles<typeof styles> {
  label?: string;
  minMinutes?: number;
  maxMinutes?: number;
  maxHour?: number;
  minutesOnly?: boolean;
  minutesPrecision?: number;
  numberRows?: number;
  className?: string;
}

export const HourMinuteInput: React.FunctionComponent<Props> = ({
  label,
  input,
  meta,
  maxHour,
  minMinutes,
  maxMinutes,
  minutesOnly,
  minutesPrecision,
  numberRows,
  classes,
  className
}) => {
  const { value, onChange } = input;
  const { error } = meta;

  return (
    <div className={classNames(classes.timeInputContainer, className)}>
      <FormControl className={classes.timeInputContainer}>
        {(value || error) && (
          <InputLabel shrink className={classes.timeInputLabel} error={!!error}>
            {label}
          </InputLabel>
        )}
        <DialogTrigger
          DialogComponent={HourMinuteSelectorDialog}
          onItemSelected={(hourMinute: HourMinute | null) => onChange(hourMinute ? hourMinute.toDecimal() : null)}
          dialogProps={{
            minMinutes,
            maxMinutes,
            maxHour,
            minutesOnly,
            minutesPrecision,
            numberRows,
            selectedHourMinute: HourMinute.fromDecimal(value)
          }}
        >
          <Button data-testid="dialog-trigger-button" className={classes.timeButton}>
            {value ? formatDecimalHourToHourMinuteString(value) : label}
          </Button>
        </DialogTrigger>
      </FormControl>
      {error && (
        <FormHelperText data-testid="input-error-message" error className={classes.errorTimeHelperText}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default withStyles(styles)(HourMinuteInput);
