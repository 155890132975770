import Input from '@material-ui/core/Input';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BasicField from '../../../Form/BasicField';
import DialogTrigger from '../DialogTrigger';

export const styles = () => ({
  triggerInput: {
    cursor: 'pointer'
  }
});

export function InputDialogTrigger({
  input: reduxFormInput,
  DialogComponent,
  inputClasses,
  classes,
  dialogProps,
  ...props
}) {
  const mergedInputClasses = classNames(classes.triggerInput, inputClasses);
  const dialogPropsWithValue = {
    ...dialogProps,
    value: reduxFormInput.value
  };
  return (
    <DialogTrigger
      disabled={props.readOnly}
      DialogComponent={DialogComponent}
      onItemSelected={(value) => reduxFormInput.onChange(value)}
      dialogProps={dialogPropsWithValue}
    >
      <BasicField label={props.label} meta={props.meta} fullWidth>
        <Input
          readOnly
          disabled={props.readOnly}
          classes={{ input: mergedInputClasses }}
          value={props.formatValueFunction(reduxFormInput.value)}
        />
      </BasicField>
    </DialogTrigger>
  );
}

InputDialogTrigger.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  DialogComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  formatValueFunction: PropTypes.func,
  inputClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogProps: PropTypes.object
};

InputDialogTrigger.defaultProps = {
  inputClasses: {},
  readOnly: false,
  formatValueFunction: undefined,
  dialogProps: {}
};

export default withStyles(styles)(InputDialogTrigger);
