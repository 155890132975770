import Portal from '@material-ui/core/Portal';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import isDefined from '../../../../shared/utils/isDefined';
import { HEADER_CONTAINER_PORTAL_ID } from '../../AppHeader/AppHeader';

const getHeaderContainer = () => document.getElementById(HEADER_CONTAINER_PORTAL_ID);

const HeaderPortal = ({ children, preventRenderOnAdminConsole }) => {
  const { userId } = useParams();
  const headerContainer = getHeaderContainer();

  const shouldHideHeader = (preventRenderOnAdminConsole) => preventRenderOnAdminConsole && isDefined(userId);

  return !shouldHideHeader(preventRenderOnAdminConsole) ? (
    <Portal container={headerContainer}>{children}</Portal>
  ) : null;
};

HeaderPortal.propTypes = {
  children: PropTypes.node.isRequired,
  preventRenderOnAdminConsole: PropTypes.bool
};

HeaderPortal.defaultProps = {
  preventRenderOnAdminConsole: false
};

export default HeaderPortal;
