import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import shortid from 'shortid';
import { FormattedMessage } from 'react-intl';
import { padStringWithZeros } from '../../../../../shared/ramq/ramqCommonUtils';
import DialogCenteredTitle from '../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import InputDialogTrigger from '../../../../components/Dialogs/DialogTrigger/InputDialogTrigger/InputDialogTrigger';
import { NumberField, SingleLineField } from '../../../../components/Form/Fields';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import FormRow from '../../../../components/Form/FormRow/FormRow';
import { displayPlace } from '../../../../components/Form/utils/fieldTransformations';
import LinkButton from '../../../../components/button/LinkButton/LinkButton';
import {
  createErrorMapWithEveryValidationFunction,
  requiredFieldValidations,
  throwSubmissionErrorIfNecessary
} from '../../../../validations/common/reduxFormValidation';
import DoctorSpecialtySelectorField from '../DoctorSpecialtySelectorField/DoctorSpecialtySelectorField';
import PlacesDialog from '../../../../containers/PlacesPreferencesDialog/PlacesDialog';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    dialog: {
      width: '100%',
      maxWidth: '400px',
      [breakpoints.down('sm')]: {
        maxWidth: '600px'
      }
    }
  };
};

export class DoctorDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleSaveDoctor = this.handleSaveDoctor.bind(this);
    this.checkDuplicatedPracticeNumber = this.checkDuplicatedPracticeNumber.bind(this);
    this.initializeForm = this.initializeForm.bind(this);
  }

  handleClose() {
    this.props.destroy();
    this.props.onClose();
  }

  isInEditMode() {
    return this.props.doctor !== undefined;
  }

  removeCurrentDoctorFromValidationCheck() {
    return this.props.doctorsPreferences.filter((d) => d.id !== this.props.doctor.id);
  }

  checkDuplicatedPracticeNumber(reduxFormValues) {
    const { practiceNumber } = reduxFormValues;
    if (practiceNumber) {
      let doctorsPreference = [];

      if (this.isInEditMode()) {
        doctorsPreference = this.removeCurrentDoctorFromValidationCheck();
      } else {
        doctorsPreference = this.props.doctorsPreferences;
      }

      if (findIndex(doctorsPreference, (d) => d.practiceNumber === practiceNumber) !== -1) {
        return { practiceNumber: 'Un médecin avec ce numéro de pratique a déjà été ajouté' };
      }
    }
    return {};
  }

  handleSaveDoctor(values) {
    const javascriptDoctorObject = values;
    const errorMap = createErrorMapWithEveryValidationFunction(
      javascriptDoctorObject,
      requiredFieldValidations(['firstName', 'lastName', 'practiceNumber']),
      this.checkDuplicatedPracticeNumber
    );

    if (javascriptDoctorObject.practiceNumber.length < 5) {
      javascriptDoctorObject.practiceNumber = padStringWithZeros(javascriptDoctorObject.practiceNumber, 5);
    }

    throwSubmissionErrorIfNecessary(errorMap);
    const doctorId = this.isInEditMode() ? this.props.doctor.id : shortid.generate();
    this.props.onSave({ id: doctorId, ...javascriptDoctorObject });
    this.handleClose();
  }

  initializeForm() {
    if (this.isInEditMode()) {
      this.props.initialize(this.props.doctor);
    } else {
      this.props.initialize({ firstName: '', lastName: '', practiceNumber: '' });
    }
  }

  render() {
    const { open, classes, handleSubmit: reduxFormHandleSubmit } = this.props;
    return (
      <Dialog
        open={open}
        onClose={() => this.handleClose()}
        TransitionProps={{ onEnter: this.initializeForm }}
        classes={{ paper: classes.dialog }}
      >
        <DialogCenteredTitle
          label={<FormattedMessage id={this.isInEditMode() ? 'doctors.editDoctor' : 'doctors.addDoctor'} />}
        />
        <form>
          <DialogContent>
            <FormRow>
              <FormElement
                name="firstName"
                label={<FormattedMessage id="doctors.firstName" />}
                fullWidth
                component={SingleLineField}
              />
            </FormRow>
            <FormRow>
              <FormElement
                name="lastName"
                label={<FormattedMessage id="doctors.lastName" />}
                fullWidth
                component={SingleLineField}
              />
            </FormRow>
            <FormRow>
              <FormElement
                name="practiceNumber"
                label={<FormattedMessage id="doctors.practiceNumber" />}
                fullWidth
                component={NumberField}
              />
            </FormRow>
            <FormRow>
              <FormElement
                name="specialtyId"
                label={<FormattedMessage id="doctors.specialty" />}
                fullWidth
                component={DoctorSpecialtySelectorField}
              />
            </FormRow>
            <FormRow>
              <FormElement
                name="place"
                label="Lieu"
                component={InputDialogTrigger}
                formatValueFunction={displayPlace}
                DialogComponent={PlacesDialog}
              />
            </FormRow>
          </DialogContent>
          <DialogActions>
            <LinkButton href="http://www.cmq.org/bottin" target="_blank">
              Bottin MD
            </LinkButton>
            <Button onClick={() => this.handleClose()} color="primary">
              Fermer
            </Button>
            <Button onClick={reduxFormHandleSubmit(this.handleSaveDoctor)} color="primary">
              {this.isInEditMode() ? 'Sauvegarder' : 'Ajouter'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

DoctorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  doctorsPreferences: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doctor: PropTypes.object,
  initialize: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired
};

DoctorDialog.defaultProps = {
  doctor: undefined
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'addDoctorForm'
  })
)(DoctorDialog);
