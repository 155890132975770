import moment from 'moment-timezone';
import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, FieldArray, Fields, reduxForm, change } from 'redux-form';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import { FormattedMessage } from 'react-intl';
import { selectLoggedUser } from '../../../containers/Authentication/selectors';
import isPdfReadOnly from '../../../validations/training/Pdf';
import PeriodFooter from '../../../containers/PeriodsBilling/PeriodFooter/PeriodFooter';
import {
  TRAINING_ATTACHMENT_TYPE,
  TRAINING_ATTACHMENTS_LIST_FIELD,
  TRAINING_FORM_NAME,
  TRAINING_UPLOAD_ATTACHMENT_FIELD
} from '../constants';
import HeaderPortal from '../../../components/Portals/HeaderPortal/HeaderPortal';
import PeriodHeader from '../../../containers/PeriodsBilling/PeriodHeader/PeriodHeader';
import CalendarRangePickerField from './fields/CalendarRangePickerField/CalendarRangePickerField';
import PeriodField from './fields/PeriodField/PeriodField';
import AttachmentsList from '../../../containers/Attachments/AttachmentsList/AttachmentsList';
import FormRow from '../../../components/Form/FormRow/FormRow';
import AttachFile from '../../../containers/Attachments/AttachFile/AttachFile';
import { NoteField } from '../../../components/Form/Fields';
import FormElement from '../../../components/Form/FormElement/FormElement';
import { trainingFileReadyForUpload } from '../../adapters/store/actions';
import CorrectionDisplay from '../../../containers/ActForm/CorrectionDisplay/CorrectionDisplay';
import setStatusToSendIfStatusIsUndefined from '../../../../shared/amountComputation/activityStatus';
import { selectUserIdInContext } from '../../../containers/User/selectors';
import Alert from '../../../components/Alert/Alert';
import HalfTrainingDayClaimingForYearToDay from './fields/HalfTrainingDaysClaimedForYearToDay/HalfTrainingDayClaimingForYearToDay';
import PeriodPlaceField from '../../../containers/PeriodsBilling/PeriodHeader/PeriodPlaceField/PeriodPlaceField';
import { Training } from '../../../../shared/domain/activity/training/model/Training';
import AuthUserDomain from '../../../../shared/domain/authentication/AuthUser';
import AlertSeverity from '../../../ui/shared/severity/AlertSeverity';

export const styles = (theme) => ({
  halfDayTrainingClaimingMessageContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3)
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  periodHeaderWrapper: {
    'display': 'flex',
    'justifyContent': 'center',
    '& div': {
      maxWidth: 700
    }
  },
  card: {
    height: 'max-content',
    maxWidth: 700,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  },
  notesAndAttachments: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  dateRangePicker: {
    height: 'max-content',
    maxWidth: 700,
    marginTop: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paddedField: {
    padding: theme.spacing(1)
  }
});

interface Props extends WithStyles<typeof styles> {
  trainingPeriod: Training;
  fileReadyForUpload: any;
  initialize: any;
  handleSubmit: any;
  updateDays: any;
  onPDFButtonClick: any;
  onSave: any;
  connectedUser: AuthUserDomain;
  trainingRelatedUserId: string;
}

export class TrainingForm extends React.Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    trainingPeriod: {}
  };

  constructor(props, context) {
    super(props, context);

    this.props.initialize(this.props.trainingPeriod);
    this.onClickPdfButton = this.onClickPdfButton.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    // We only re render everything if we get a new period object from the periodBilling component
    return this.props.trainingPeriod !== nextProps.trainingPeriod;
  }

  componentDidUpdate() {
    this.props.initialize(this.props.trainingPeriod);
  }

  onClickPdfButton(handleSubmit, onPDFButtonClick) {
    return handleSubmit((periodForm) => {
      const isReadOnly = isPdfReadOnly(this.props.trainingPeriod.status, this.props.connectedUser);
      const mutedPeriodForm = setStatusToSendIfStatusIsUndefined(periodForm);

      onPDFButtonClick(mutedPeriodForm, isReadOnly);
    });
  }

  render() {
    const {
      classes,
      handleSubmit,
      onSave,
      onPDFButtonClick,
      trainingPeriod,
      trainingRelatedUserId,
      fileReadyForUpload
    } = this.props;

    return (
      <>
        <HeaderPortal preventRenderOnAdminConsole>
          <div className={classes.periodHeaderWrapper}>
            {/* @ts-ignore */}
            <PeriodHeader activity={trainingPeriod}>
              <FormElement data-testid="period-place-form-element" name="place" component={PeriodPlaceField} />
            </PeriodHeader>
          </div>
        </HeaderPortal>
        <div className={classes.halfDayTrainingClaimingMessageContainer}>
          <Field
            id="info-message-half-training-day-claimed"
            name="startDate"
            component={({ input }) => (
              <HalfTrainingDayClaimingForYearToDay userId={trainingRelatedUserId} trainingDate={input.value} />
            )}
          />
        </div>
        <Fade in>
          <Paper className={classes.card}>
            <FormElement name="corrections" component={CorrectionDisplay} status={trainingPeriod.status} />
            <div className={classes.dateRangePicker}>
              <Fields
                names={['startDate', 'endDate', 'date', 'days']}
                updateDays={this.props.updateDays}
                component={CalendarRangePickerField}
              />
            </div>
          </Paper>
        </Fade>

        <Field
          id="info-message-display-first-day-only"
          name="startDate"
          component={({ input }) => (
            <Alert
              severity={AlertSeverity.INFO}
              noMargin
              showIcon
              title="Attention"
              message={
                <FormattedMessage
                  id="trainingActivity.displayFirstDayOnlyWarning"
                  values={{
                    startDate: moment(input.value).format('Do MMMM YYYY')
                  }}
                />
              }
            />
          )}
        />

        <Fade in>
          <Paper className={classes.card}>
            <CardContent>
              <FieldArray name="days" component={PeriodField as any} />
              <FormRow>
                <FormElement className={classes.paddedField} name="note" label="Note" fullWidth component={NoteField} />
                <AttachFile
                  attachmentType={TRAINING_ATTACHMENT_TYPE}
                  documentId={trainingPeriod.id || ''}
                  fieldName={TRAINING_UPLOAD_ATTACHMENT_FIELD}
                  fileReadyForUploadHandler={fileReadyForUpload}
                />
              </FormRow>
              <FormRow>
                <FieldArray name={TRAINING_ATTACHMENTS_LIST_FIELD} component={AttachmentsList as any} />
              </FormRow>
              <PeriodFooter
                onSaveButtonClick={(status) => handleSubmit(onSave(status))}
                onPDFButtonClick={this.onClickPdfButton(handleSubmit, onPDFButtonClick)}
                activityType={trainingPeriod.type}
                readOnly={isPdfReadOnly(this.props.trainingPeriod.status, this.props.connectedUser)}
              />
            </CardContent>
          </Paper>
        </Fade>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  connectedUser: selectLoggedUser(),
  trainingRelatedUserId: selectUserIdInContext()
});

export const mapDispatchToProps = (dispatch) => ({
  updateDays: (days) => {
    dispatch(change(TRAINING_FORM_NAME, 'days', days));
  },
  fileReadyForUpload: (...args) => dispatch(trainingFileReadyForUpload(...args))
});

export default compose(
  withStyles(styles),
  reduxForm({
    form: TRAINING_FORM_NAME
  }),
  // TS2589: Type instantiation is excessively deep and possibly infinite.
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)
)(TrainingForm);
