import queryString from 'qs';
import Backdrop from '@material-ui/core/Backdrop';
import Portal from '@material-ui/core/Portal';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import zIndex from '@material-ui/core/styles/zIndex';
import AssignmentIcon from '@material-ui/icons/AssignmentRounded';
import FlightIcon from '@material-ui/icons/FlightRounded';
import ListIcon from '@material-ui/icons/ListRounded';
import NoteAddIcon from '@material-ui/icons/NoteAddRounded';
import SchoolIcon from '@material-ui/icons/School';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { orderBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslate from 'application/hooks/use-translate';
import { DUPLICATE_ANOTHER_DAY_ACTS } from '../../../../../../userCustomization/featureNames';
import { getFeatureConfig } from '../../../../../../userCustomization/featureToggle';
import { openMultiActDialog } from '../../../../../act/multiAct/adapters/dialog/actions';
import DuplicateActsToAnotherDayButton from '../../../../../components/Activities/DuplicateActToAnotherDayButton/DuplicateActsOfAnotherDayButton';
import deviceDetector from '../../../../../components/utils/DeviceDetector';
import {
  LUMP_SUM_ROUTE,
  MIXTE_ROUTE,
  NEW_ACT_ROUTE,
  NEW_TRAINING_ROUTE,
  NEW_TRAVEL_EXPENSES_ROUTE
} from '../../../../Navigation/constants';
import {
  selectDefaultPlace,
  selectHasDuplicateAnotherDayActivitiesEnabled,
  selectHasMultipleNAMDiscoveryEnabled
} from '../../../../User/selectors';
import { usePostHog } from 'posthog-js/react';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    addActButton: {
      zIndex: zIndex.drawer + 2,
      position: 'fixed',
      bottom: theme.spacing(3),
      right: theme.spacing(3),
      [breakpoints.down('sm')]: {
        bottom: '100px',
        right: theme.spacing(2)
      }
    },
    tooltip: {
      whiteSpace: 'nowrap'
    },
    fabTooltip: {
      // Copied the styles from the SpeedDial static tooltip since they are not the MUI standard ones
      color: 'rgba(0, 0, 0, 0.54)',
      padding: '4px 16px',
      fontSize: '1rem',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      wordBreak: 'keep-all',
      fontWeight: '400',
      lineHeight: '1.5',
      borderRadius: '4px',
      letterSpacing: '0.00938em',
      backgroundColor: '#fff',
      marginBottom: 15
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.45)'
    },
    backdropOpen: {
      zIndex: zIndex.drawer + 1
    }
  };
};

const ActivitiesOptions = ({
  classes,
  goToMultiAct,
  multipleNAMDiscoveryEnabled,
  canDuplicateAnotherDay,
  defaultPlace,
  activities
}) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (deviceDetector.isMobile()) {
      if (!open) {
        setOpen((prevOpen) => !prevOpen);
      } else {
        navigate({ pathname: `${NEW_ACT_ROUTE}`, search: location.search });
      }
    } else {
      navigate({ pathname: `${NEW_ACT_ROUTE}`, search: location.search });
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBrowserOnly = (functionToCall) => {
    if (!deviceDetector.isMobile()) {
      functionToCall();
    }
  };

  const firstActOfTheDay =
    activities && activities.length > 0
      ? orderBy(activities, ['start', 'nam'], ['asc', 'asc']).find((act) => act.type === ActivityType.ACT)
      : null;

  const firstPlaceOfTheDay = firstActOfTheDay ? firstActOfTheDay.place : null;

  return (
    <Portal>
      <Backdrop
        onClick={() => handleClose()}
        open={open}
        classes={{ root: classNames(classes.backdrop, { [classes.backdropOpen]: open }) }}
      />
      <Tooltip
        title={translate('Navigation.act')}
        placement="left-end"
        classes={{ tooltip: classes.fabTooltip }}
        open={open}
      >
        <SpeedDial
          ariaLabel={translate('Navigation.act')}
          className={classNames(classes.addActButton, 'push-communication-banner')}
          icon={<SpeedDialIcon id="activities-options-button" openIcon={<NoteAddIcon />} />}
          FabProps={{
            onClick: handleClick
          }}
          onMouseEnter={() => handleBrowserOnly(handleOpen)}
          onMouseLeave={() => handleBrowserOnly(handleClose)}
          open={open}
        >
          {multipleNAMDiscoveryEnabled && (
            <SpeedDialAction
              id="create-multi-act-button"
              icon={<ViewModuleIcon />}
              tooltipTitle={translate('Navigation.multi-act')}
              tooltipOpen
              classes={{ staticTooltipLabel: classes.tooltip }}
              onClick={() => {
                posthog.capture('FAB clicked', { type: 'multi-act' });
                setOpen(false);
                goToMultiAct();
              }}
            />
          )}
          {(getFeatureConfig(DUPLICATE_ANOTHER_DAY_ACTS, false) || canDuplicateAnotherDay) && (
            <DuplicateActsToAnotherDayButton open closeActivitiesOptions={handleClose} />
          )}
          <SpeedDialAction
            id="create-mixte-button"
            icon={<AssignmentIcon />}
            tooltipTitle={translate('Navigation.mixte')}
            tooltipOpen
            classes={{ staticTooltipLabel: classes.tooltip }}
            onClick={() => {
              posthog.capture('FAB clicked', { type: 'mixte' });
              navigate({
                pathname: MIXTE_ROUTE,
                search: queryString.stringify(
                  {
                    place: firstPlaceOfTheDay?.number || defaultPlace?.number
                  },
                  { skipNulls: true }
                )
              });
            }}
          />
          <SpeedDialAction
            icon={<ListIcon />}
            tooltipOpen
            tooltipTitle={translate('activity.type.lumpSum')}
            classes={{ staticTooltipLabel: classes.tooltip }}
            onClick={() => {
              posthog.capture('FAB clicked', { type: 'lump-sum' });
              navigate({
                pathname: LUMP_SUM_ROUTE,
                search: queryString.stringify(
                  {
                    place: firstPlaceOfTheDay?.number || defaultPlace?.number
                  },
                  { skipNulls: true }
                )
              });
            }}
          />
          <SpeedDialAction
            icon={<SchoolIcon />}
            tooltipOpen
            tooltipTitle={translate('Navigation.training')}
            classes={{ staticTooltipLabel: classes.tooltip }}
            onClick={() => {
              posthog.capture('FAB clicked', { type: 'training' });
              navigate({ pathname: `${NEW_TRAINING_ROUTE}`, search: location.search });
            }}
          />
          <SpeedDialAction
            icon={<FlightIcon />}
            tooltipOpen
            tooltipTitle={translate('activity.type.travelExpenses')}
            classes={{ staticTooltipLabel: classes.tooltip }}
            onClick={() => {
              posthog.capture('FAB clicked', { type: 'travel-expenses' });
              navigate({ pathname: `${NEW_TRAVEL_EXPENSES_ROUTE}`, search: location.search });
            }}
          />
        </SpeedDial>
      </Tooltip>
    </Portal>
  );
};

ActivitiesOptions.propTypes = {
  goToMultiAct: PropTypes.func.isRequired,
  multipleNAMDiscoveryEnabled: PropTypes.bool,
  canDuplicateAnotherDay: PropTypes.bool.isRequired,
  defaultPlace: PropTypes.object,
  activities: PropTypes.array.isRequired
};

ActivitiesOptions.defaultProps = {
  multipleNAMDiscoveryEnabled: false
};

export const mapStateToProps = createStructuredSelector({
  multipleNAMDiscoveryEnabled: selectHasMultipleNAMDiscoveryEnabled(),
  canDuplicateAnotherDay: selectHasDuplicateAnotherDayActivitiesEnabled(),
  defaultPlace: selectDefaultPlace()
});

export const mapDispatchToProps = (dispatch) => ({
  goToMultiAct: () => dispatch(openMultiActDialog())
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ActivitiesOptions);
