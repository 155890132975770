import PropTypes from 'prop-types';
import React from 'react';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import ModifyAct from '../../../containers/ActForm/ModifyAct/ModifyAct';
import LumpSum from '../../../containers/LumpSum/LumpSum';
import Mixte from '../../../containers/Mixte/Mixte';
import TravelExpenses from '../../../containers/TravelExpenses/TravelExpenses';
import Trainings from '../../../training/ui/Trainings/Trainings';

function EditActivity({ activity, formClassesOverride }) {
  switch (activity.type) {
    case ActivityType.ACT:
      return <ModifyAct act={activity} formClasses={formClassesOverride} />;
    case ActivityType.TRAVEL_EXPENSES:
      return <TravelExpenses travelExpenses={activity} formClasses={formClassesOverride} />;
    case ActivityType.MIXTE:
      return <Mixte savedMixte={activity} previousUrl="../.." formClasses={formClassesOverride} />;
    case ActivityType.LUMP_SUM:
      return <LumpSum savedLumpSum={activity} previousUrl="../.." formClasses={formClassesOverride} />;
    case ActivityType.TRAINING:
      return <Trainings formClasses={formClassesOverride} />;
    default:
      return null;
  }
}

EditActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  formClassesOverride: PropTypes.object
};

EditActivity.defaultProps = {
  formClassesOverride: {}
};

export default EditActivity;
