import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useTranslate from 'application/hooks/use-translate';
import Icon from 'application/components/icon';
import { gql, useQuery } from '@apollo/client';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import PdfGenerationAPI from 'app/infrastructure/api/PdfGenerationAPI';
import useToast from 'application/hooks/use-toast';
import DataTable from 'application/components/data-table';
import openUrl from 'application/utilities/open-url';
import ReportBillingPeriodSelector from './components/billing-period-selector';
import { Period } from './components/reports/types';
import DownloadButton from '../../../components/download-button';

interface GroupResponse {
  group: {
    id: string;
    users: Array<{
      id: string;
    }>;
  };
}

interface MixteSummaryResponse {
  activitiesResult: {
    activities: MixteSummary[];
  };
}

interface MixteSummary {
  id: string;
  firebaseId: string;
  date: string;
  status: ActivityStatus;
  days: Array<{
    date: Date;
    duration: number;
    perdiems: string[];
  }>;
  place: {
    id: string;
    name: string;
    number: string;
  };
  user: {
    id: string;
    fullName: string;
  };
}

const GROUP_USER_IDS_QUERY = gql`
  query FetchGroupUserIds($groupId: String!) {
    group(groupId: $groupId) {
      id
      users {
        id
      }
    }
  }
`;

const MIXTE_SUMMARY_QUERY = gql`
  query SearchMixtesForPeriod($query: GetActivitiesInput!) {
    activitiesResult(query: $query) {
      activities {
        id
        firebaseId
        date
        status
        days {
          date
          duration
          perdiems
        }
        place {
          id
          name
          number
        }
        user {
          id
          fullName
        }
      }
    }
  }
`;

const GroupPdfGenerationPage = () => {
  const translate = useTranslate('pages.group.pdf-generation');
  const { addToast } = useToast();
  const { groupId } = useParams();
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<Period | null>(null);
  const [fetchingPdf, setFetchingPdf] = useState(false);

  const { data: groupUserIdsData, loading: groupUserIdsLoading } = useQuery<GroupResponse>(GROUP_USER_IDS_QUERY, {
    variables: { groupId }
  });

  const { data, loading } = useQuery<MixteSummaryResponse>(MIXTE_SUMMARY_QUERY, {
    skip: !selectedBillingPeriod || !groupUserIdsData || groupUserIdsLoading,
    variables: {
      query: {
        userIds: groupUserIdsData?.group.users.map((user: { id: string }) => user.id),
        startDate: selectedBillingPeriod?.start,
        endDate: selectedBillingPeriod?.end,
        types: [ActivityType.MIXTE],
        statuses: [
          ActivityStatus.NEED_FIX,
          ActivityStatus.PAID,
          ActivityStatus.PROCESSING,
          ActivityStatus.SENT,
          ActivityStatus.WAITING
        ]
      }
    }
  });

  const sortedActivties = useMemo(() => {
    if (!data) return [];

    return [...data.activitiesResult.activities].sort((a, b) => a.user.fullName.localeCompare(b.user.fullName));
  }, [data]);

  return (
    <div className="relative rounded-md bg-base-100 shadow">
      <div className="flex flex-row items-center justify-between border-b-2 p-4">
        {data ? (
          <span>
            <span className="font-bold">{translate('title')}</span>
            <div className="badge badge-ghost ml-2">{sortedActivties.length}</div>
          </span>
        ) : (
          <span />
        )}

        <div className="flex flex-row items-center justify-between">
          <ReportBillingPeriodSelector
            groupId={groupId}
            onChange={setSelectedBillingPeriod}
            value={selectedBillingPeriod}
          />

          <DownloadButton
            onClick={async () => {
              setFetchingPdf(true);
              PdfGenerationAPI.getPaymentRequestFormPdfUrl(sortedActivties.map((activity) => activity.firebaseId))
                .then((url) => {
                  openUrl(url);
                })
                .catch((_error) => {
                  addToast({
                    message: translate('download-error'),
                    type: 'error'
                  });
                })
                .finally(() => {
                  setFetchingPdf(false);
                });
            }}
            disabled={!selectedBillingPeriod || sortedActivties.length === 0}
            downloading={fetchingPdf}
          />
        </div>
      </div>

      {data && !loading && (
        <DataTable
          count={sortedActivties.length}
          columns={[
            { title: translate('full-name'), width: '*' },
            { title: translate('place'), width: '*' },
            { title: translate('perdiems-count'), width: '*' }
          ]}
        >
          {sortedActivties.map((activity) => (
            <tr key={activity.id} className="hover">
              <td>{activity.user.fullName}</td>
              <td>
                {activity.place.number} - {activity.place.name}
              </td>
              <td>{activity.days.reduce((total, day) => total + day.perdiems.length, 0)}</td>
            </tr>
          ))}
        </DataTable>
      )}

      {!data && !loading && (
        <div className="relative flex h-96 flex-col items-center justify-center gap-4 bg-base-200">
          <Icon icon="calendar-day" className="text-4xl text-gray-400" />
          <span className="w-64 text-center text-gray-400">{translate('select-statement-date')}</span>
        </div>
      )}
    </div>
  );
};

export default GroupPdfGenerationPage;
