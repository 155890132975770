import React from 'react';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import Dialog from '@material-ui/core/Dialog';
import SearchIcon from '@material-ui/icons/Search';
import FavoriteIcon from '@material-ui/icons/Favorite';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import useTranslate from 'application/hooks/use-translate';
import Role from 'shared/domain/authentication/Role';
import { isUserAtLeast } from 'application/utilities/authentication';
import { RootState } from 'application/types/redux-state';
import { useSelector } from 'react-redux';
import CategoryItem from '../../shared/domain/CategoryItem';
import ItemAndCategoriesMapping from '../../../shared/editable/domain/ItemAndCategoriesMapping';
import DialogCenteredTitle from '../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import PresetsTab from '../../shared/ui/GenericSearchDialog/PresetsTab/PresetsTab';
import FavoritesTab from '../../shared/ui/GenericSearchDialog/FavoritesTab/FavoritesTab';
import { isInCollection } from '../../shared/domain/isInFavoritesFunction';
import SwipeableTabs from '../../../components/SwipeableTabs/SwipeableTabs';
import RegistryTab from './RegistryTab';
import lumpSumPresets from '../data/lump-sum-presets.json';
import lu241CovidLumpSums from '../data/lu-241-covid-lump-sums.json';
import lu238CovidLumpSums from '../data/lu-238-covid-lump-sums.json';

const styles = (theme) =>
  ({
    paper: {
      minHeight: '90vh',
      height: '100%'
    },
    slideChildContainer: {
      'display': 'flex',
      'height': '100%',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  advancedEditionEnabled: boolean;
  favorites: ItemAndCategoriesMapping;
  onAddToFavorites: (item: CategoryItem) => void;
  onClose: () => void;
  onItemSelected: (item: CategoryItem) => void;
  onUpdateFavorites: (favorites: ItemAndCategoriesMapping) => void;
  open: boolean;
}

const LumpSumDialog: React.FunctionComponent<Props> = ({
  advancedEditionEnabled,
  favorites,
  onAddToFavorites,
  onClose,
  onItemSelected,
  onUpdateFavorites,
  open,
  classes
}: Props) => {
  const translate = useTranslate('lump-sum-favorites');
  const userRole = useSelector<RootState, Role>((state) => state.authentication.user.role);

  let enhancedLumpSumPresets;

  if (isUserAtLeast(userRole, Role.Agent)) {
    enhancedLumpSumPresets = [...lumpSumPresets, ...lu241CovidLumpSums, ...lu238CovidLumpSums];
  }

  const tabs = [
    {
      key: 'favorites',
      icon: <FavoriteIcon />,
      component: (
        <div className={classes.slideChildContainer} key="presets-tab">
          <FavoritesTab
            favorites={favorites}
            onItemSelected={onItemSelected}
            onUpdate={onUpdateFavorites}
            onCancel={onClose}
            advancedEditionEnabled={advancedEditionEnabled}
          />
        </div>
      )
    },
    {
      key: 'presets',
      icon: <FolderSpecialIcon />,
      component: (
        <div className={classes.slideChildContainer} key="presets-tab">
          <PresetsTab
            data={enhancedLumpSumPresets || lumpSumPresets}
            isInFavorites={(item) => isInCollection(favorites, item)}
            onAddToFavorites={onAddToFavorites}
            onItemSelected={onItemSelected}
            onCancel={onClose}
          />
        </div>
      )
    },
    {
      key: 'search',
      icon: <SearchIcon />,
      component: (
        <div className={classes.slideChildContainer}>
          <RegistryTab
            onClose={onClose}
            isInFavorites={(item) => isInCollection(favorites, item)}
            onItemSelected={onItemSelected}
            onAddToFavorites={onAddToFavorites}
          />
        </div>
      )
    }
  ];

  return (
    <Dialog fullWidth open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogCenteredTitle label={translate('title')} />
      <SwipeableTabs
        swipeableViewsProps={{
          containerStyle: { height: '100%' },
          slideStyle: { overflow: 'hidden' }
        }}
        tabs={tabs}
      />
    </Dialog>
  );
};

export default withStyles(styles)(LumpSumDialog);
