import intensiveCareExceptionalCircumstances from '../ExceptionalCircumstances/intensiveCareExceptionalCircumstances';
import { ActCode } from './ActCode';
import { RequiredDetails } from '../requiredDetail.type';
import referenceProfessional from '../ReferenceProfessional/referenceProfessional';

export default class ActCode15483 implements ActCode {
  // eslint-disable-next-line no-unused-vars
  constructor(private act: any) {}

  getRequiredDetails(): RequiredDetails {
    return [referenceProfessional, intensiveCareExceptionalCircumstances];
  }
}
