import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import DialogCenteredTitle from '../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import {
  selectDoctorsUpdatingFromUser,
  selectHasEditableFavoriteDoctorFeatureFlagEnabled
} from '../../../../containers/User/selectors';
import DisplayIf from '../../../../shared/global/DisplayIf/DisplayIf';
import DoctorFavoritesBasicEditor from '../DoctorFavoritesBasicEditor/DoctorFavoritesBasicEditor';
import FullScreenLoading from '../../../../components/Loadings/FullScreenLoading/FullScreenLoading';

export const styles = ({ breakpoints }) => ({
  addDoctorButton: {
    bottom: 75,
    right: 25,
    position: 'absolute'
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  noPaddingOnMobile: {
    [breakpoints.down('sm')]: {
      padding: 0
    }
  }
});

export class DoctorFavoritesSelector extends React.Component {
  constructor(props) {
    super(props);
    this.onDoctorSelected = this.onDoctorSelected.bind(this);
  }

  onDoctorSelected(doctor) {
    this.props.onItemSelected(doctor);
    this.props.onClose();
  }

  render() {
    const {
      onClose,
      classes,
      doctorsPreferences,
      hasEditableFavoritesEnabled,
      doctorsUpdatingFromUser,
      value,
      withTitle,
      onAdvancedMode,
      type,
      onDeleteDoctor,
      onSave
    } = this.props;

    if (doctorsUpdatingFromUser) return <FullScreenLoading />;
    return (
      <>
        {withTitle && <DialogCenteredTitle label="Médecins" />}
        <DialogContent className={classes.noPaddingOnMobile}>
          <DoctorFavoritesBasicEditor
            hasEditableFavoritesEnabled={hasEditableFavoritesEnabled}
            doctorsPreferences={doctorsPreferences}
            onSelectDoctor={this.onDoctorSelected}
            onSaveDoctor={onSave}
            type={type}
            onDeleteDoctor={onDeleteDoctor}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <DisplayIf condition={hasEditableFavoritesEnabled} DisplayElse={<div />}>
            <Button id="advanced-edition-mode-button" onClick={onAdvancedMode} color="primary">
              <FormattedMessage id="editableCollectionWithCategories.advancedEditMode" />
            </Button>
          </DisplayIf>
          <div>
            <DisplayIf condition={value !== ''}>
              <Button id="remove-selected-doctor-button" onClick={() => this.onDoctorSelected('')} color="primary">
                Effacer
              </Button>
            </DisplayIf>
            <Button id="cancel-button" onClick={() => onClose()} color="primary">
              Annuler
            </Button>
          </div>
        </DialogActions>
      </>
    );
  }
}

DoctorFavoritesSelector.propTypes = {
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdvancedMode: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  doctorsPreferences: PropTypes.array.isRequired,
  doctorsUpdatingFromUser: PropTypes.bool.isRequired,
  hasEditableFavoritesEnabled: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([''])]),
  withTitle: PropTypes.bool,
  type: PropTypes.string,
  onDeleteDoctor: PropTypes.func.isRequired
};

DoctorFavoritesSelector.defaultProps = {
  value: '',
  withTitle: false
};

export const mapStateToProps = createStructuredSelector({
  hasEditableFavoritesEnabled: selectHasEditableFavoriteDoctorFeatureFlagEnabled(),
  doctorsUpdatingFromUser: selectDoctorsUpdatingFromUser()
});

export default compose(connect(mapStateToProps), withStyles(styles))(DoctorFavoritesSelector);
