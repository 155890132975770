import { get, set } from 'lodash';
import { SingleLineField } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import isDefined from '../../../../shared/utils/isDefined';

const createError = (index, message) =>
  set({ elementIdToScrollTo: `codes[${index}].imc` }, `codes[${index}].imc`, message);

const codesIMCAreNumber = (act) => {
  const codesInError: any[] = [];

  act.codes.forEach(({ imc }, index) => {
    if (isDefined(imc) && Number.isNaN(parseInt(imc, 10))) {
      codesInError.push(createError(index, "L'IMC doit être un nombre entier"));
    }
  });

  return codesInError.reduce(combineErrors, {});
};

export const codeIMCToMinimumValueMapping = new Map([
  ['6087', 35],
  ['6094', 35],
  ['1025', 40],
  ['1026', 40],
  ['1027', 40],
  ['1028', 40],
  ['3047', 40],
  ['3048', 40],
  ['3049', 40],
  ['3050', 40],
  ['5510', 40],
  ['5511', 40],
  ['5512', 40],
  ['5513', 40],
  ['6058', 35],
  ['6946', 35],
  ['6945', 35],
  ['6931', 35]
]);

const codesIMCAreEqualOrGreaterThan = (act) => {
  const codesInError: any[] = [];
  const codes = get(act, 'codes', []);

  codes.forEach(({ code, imc }, index) => {
    const minimumIMC = codeIMCToMinimumValueMapping.get(code) || 0;

    if (imc < minimumIMC) {
      codesInError.push(createError(index, `L'IMC doit être égal ou plus grand que ${minimumIMC}`));
    }
  });

  return codesInError.reduce(combineErrors, {});
};

const imc = {
  associatedFields: [{ label: 'IMC', getName: (index) => `codes[${index}].imc`, type: SingleLineField }],
  associatedValidations: [codesIMCAreNumber, codesIMCAreEqualOrGreaterThan],
  summaryRender: (code) => [{ name: 'IMC', value: get(code, 'imc', '-') }]
};

export default imc;
