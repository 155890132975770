import React from 'react';
import { get } from 'lodash';
import ListItemText from '@material-ui/core/ListItemText';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import CodeChip from '../../../../CodeChip/CodeChip';
import TextIcon from '../TextIcon/TextIcon';
import ContextElement from '../../../../../favorite/contextElement/domain/ContextElement';

const stringifyContextElements = (contextElements: ContextElement[]) =>
  contextElements.map(({ code }) => code).join(', ');

const styles = (theme) =>
  ({
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    codeChip: {
      marginRight: theme.spacing(1)
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  code: {
    code: string;
    ramqContextElements?: ContextElement[];
  };
}

export const CodeContextElementsSummary: React.FunctionComponent<Props> = ({ code, classes }) => {
  const contextElements = get(code, 'ramqContextElements', []) || [];

  if (contextElements.length === 0) {
    return null;
  }

  return (
    <div className={classes.container}>
      <CodeChip code={code.code} className={classes.codeChip} />

      <TextIcon text="E.C." />

      <ListItemText
        data-testid="context-elements-text"
        style={{ paddingLeft: 0 }}
        primary={stringifyContextElements(contextElements)}
      />
    </div>
  );
};

export default withStyles(styles)(CodeContextElementsSummary);
