import { WithStyles } from '@material-ui/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { TextField, Paper } from '@material-ui/core';
import getCodeSuggestionsWithLimit from '../../containers/SearchSuggestions/utils/searchWithLimit';
import ExpandableActCodeSuggestion from '../ExpandableActCodeSuggestion/ExpandableActCodeSuggestion';
import { ActCode } from '../../../shared/core/activity/domain/Act';
import { usePostHog } from 'posthog-js/react';

export const styles = ({ breakpoints }) =>
  ({
    codeSuggestions: {
      position: 'absolute',
      width: '100%',
      zIndex: 2,
      maxHeight: 200,
      overflowY: 'auto',
      [breakpoints.down('xs')]: {
        width: '93vw'
      },
      scrollBehavior: 'smooth'
    },
    input: {
      width: '100%'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  disabled?: boolean;
  codesData: ActCode[];
  onHandleSelect: (array) => void;
  className: string;
}

export const CodeSelector: React.FunctionComponent<Props> = ({
  disabled,
  codesData,
  onHandleSelect,
  classes,
  className
}) => {
  const posthog = usePostHog();
  const [inputValue, setInputValue] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const codeSuggestions = getCodeSuggestionsWithLimit(inputValue, codesData, 'code', 20);

  const updateInputValue = (event) => {
    setInputValue(event.currentTarget.value);
    setSelectedIndex(0);
  };

  useEffect(() => {
    if (selectedIndex >= 0 && selectedIndex < codeSuggestions.length) {
      const suggestion = document.getElementById(codeSuggestions[selectedIndex]?.code);
      if (suggestion && suggestion.parentNode) {
        const parentNode = suggestion.parentNode as HTMLElement;
        parentNode.scrollTop = suggestion.offsetTop - parentNode.offsetTop;
      }
    }
  }, [codeSuggestions, selectedIndex]);

  const handleKeydown = (event) => {
    if (event.key === 'Enter' && codeSuggestions.length) {
      handleSelectItem(codeSuggestions[selectedIndex]);
    }

    if (event.key === 'Escape') {
      setInputValue('');
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (selectedIndex < codeSuggestions.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
  };

  const handleSelectItem = (selectedCode) => {
    onHandleSelect([selectedCode]);
    setInputValue('');
    setSelectedIndex(0);
    posthog.capture('act_code_selected', { code: selectedCode.code, mode: 'dropdown' });
  };

  return (
    <div role="presentation" className={className} onKeyDown={handleKeydown}>
      <TextField
        onChange={updateInputValue}
        value={inputValue}
        fullWidth
        disabled={disabled}
        label="Codes"
        inputProps={{ pattern: '[0-9]*', type: 'number', className: classes.input }}
      />
      <Paper square className={classes.codeSuggestions}>
        {codeSuggestions.map((suggestion, index) => (
          <ExpandableActCodeSuggestion
            isSelected={selectedIndex === index}
            key={suggestion.code}
            suggestion={suggestion}
            onClick={() => handleSelectItem(suggestion)}
          />
        ))}
      </Paper>
    </div>
  );
};

export default withStyles(styles)(CodeSelector);
