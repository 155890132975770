/* eslint-disable no-console */

export class Logger {
  private loggingEnabled: boolean = false;

  public enableLogging() {
    this.loggingEnabled = true;
  }

  public log(...messages: any) {
    if (this.loggingEnabled) {
      console.log(...messages);
    }
  }

  public warn(...messages: any) {
    if (this.loggingEnabled) {
      console.warn(...messages);
    }
  }

  public error(...messages: any) {
    if (this.loggingEnabled) {
      console.error(...messages);
    }
  }
}

export const LOGGER = new Logger();

/* @deprecated Use logDefault instead */
export function plog(...messages: any) {
  LOGGER.log(...messages);
}

/* @deprecated Use logError instead */
export function perror(...messages) {
  LOGGER.error(...messages);
}

export function logDefault(args: object | string) {
  LOGGER.log(args);
}

export function logWarning(args: object | string) {
  LOGGER.warn(args);
}

export function logError(args: object | string) {
  LOGGER.error(args);
}
