import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import { ACTIVITIES_MIXTE_TYPE } from '../../../../shared/collection/collectionConstants';
import FormRow from '../../../components/Form/FormRow/FormRow';
import OnTopBottomNavigationPortal from '../../../components/Portals/OnTopBottomNavigationPortal/OnTopBottomNavigationPortal';
import { selectPDFError, selectPDFLoading } from '../../PDF/selectors';
import SplitButton from '../../../components/button/SplitButton/SplitButton';
import { selectHasElectronicSignatureEnabled } from '../../User/selectors';

export const styles = (theme) => ({
  submitButtonGroup: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: 'space-evenly'
  },
  waitingButton: {
    backgroundColor: theme.palette.turquoise[500],
    color: theme.palette.common.white
  },
  pdfButton: {
    backgroundColor: theme.palette.grey[400]
  },
  pdfError: {
    justifyContent: 'center',
    marginTop: theme.spacing(1)
  },
  readOnlyMessage: {
    display: 'block',
    marginBottom: theme.spacing(4)
  },
  RAMQFormContainer: {
    margin: theme.spacing(3),
    marginTop: theme.spacing(8)
  },
  RAMQFormDivider: {
    marginBottom: theme.spacing(3)
  },
  RAMQFormButton: {
    marginRight: theme.spacing(1)
  }
});

export function PeriodFooter({
  onSaveButtonClick,
  onPDFButtonClick,
  onRequestElectronicSignature,
  electronicSignatureEnabled,
  activityType,
  periodStatus,
  pdfLoading,
  pdfError,
  classes,
  readOnly
}) {
  const translate = useTranslate();
  const canRequestSignature = [ActivityStatus.PROCESSING, ActivityStatus.PAID].includes(periodStatus);
  const secondaryActions = canRequestSignature
    ? [
        {
          disabled: !electronicSignatureEnabled,
          label: 'À signer',
          callback: onRequestElectronicSignature
        }
      ]
    : [];

  return (
    <OnTopBottomNavigationPortal>
      {pdfError && (
        <FormRow className={classes.pdfError}>
          <Typography color="error">{translate('pdf-creation-error')}</Typography>
        </FormRow>
      )}
      <FormRow className={classes.submitButtonGroup}>
        {readOnly && (
          <Typography variant="body2" align="center" className={classes.readOnlyMessage}>
            {`Vous ne pouvez modifier ce
            ${activityType === ACTIVITIES_MIXTE_TYPE ? 'mixte' : 'forfaitaire'}. 
            SVP communiquer avec Médyx`}
          </Typography>
        )}

        <>
          <Button
            id="wait-act-button"
            onClick={onSaveButtonClick(ActivityStatus.WAITING)}
            variant="contained"
            className={classes.waitingButton}
          >
            {translate('act-form.wait')}
          </Button>

          <SplitButton
            mainAction={{ label: 'PDF', callback: onPDFButtonClick, disabled: pdfLoading }}
            secondaryActions={secondaryActions}
            loading={pdfLoading}
          />

          <Button
            id="send-act-button"
            onClick={onSaveButtonClick(ActivityStatus.SENT)}
            variant="contained"
            color="primary"
          >
            {translate('act-form.send')}
          </Button>
        </>

        {periodStatus !== ActivityStatus.NO_STATUS && (
          <Restrict atLeastRole={Role.Agent}>
            <Button id="save-act-button" onClick={onSaveButtonClick()} variant="contained" color="default">
              {translate('global.save')}
            </Button>
          </Restrict>
        )}
      </FormRow>
    </OnTopBottomNavigationPortal>
  );
}

PeriodFooter.propTypes = {
  onSaveButtonClick: PropTypes.func.isRequired,
  onPDFButtonClick: PropTypes.func.isRequired,
  onRequestElectronicSignature: PropTypes.func.isRequired,
  activityType: PropTypes.string.isRequired,
  periodStatus: PropTypes.string.isRequired,
  pdfLoading: PropTypes.bool.isRequired,
  pdfError: PropTypes.object,
  readOnly: PropTypes.bool,
  electronicSignatureEnabled: PropTypes.bool.isRequired
};

PeriodFooter.defaultProps = {
  pdfError: undefined,
  readOnly: false
};

export const mapStateToProps = createStructuredSelector({
  pdfLoading: selectPDFLoading(),
  pdfError: selectPDFError(),
  electronicSignatureEnabled: selectHasElectronicSignatureEnabled()
});

export default compose(withStyles(styles), connect(mapStateToProps, null))(PeriodFooter);
