import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import ADRENAL_CONTRALATERAL from '../../../../shared/ramq/requiredDetails/ardenalContralateral';

const associatedFields: AssociatedField[] = [
  {
    label: 'Surrénale contralatérale même séance ?',
    getName: (index) => `codes[${index}].${ADRENAL_CONTRALATERAL}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    notRequired: true
  }
];

const adrenalContralateral: RequiredDetail = {
  associatedFields
};

export default adrenalContralateral;
