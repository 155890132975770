import { call, put, select } from 'redux-saga/effects';
import { selectUserProfileInContext } from '../../../containers/User/selectors';
import GetUserSpecialtyDiagnosticCodeCollectionUseCase from '../usecases/GetUserSpecialtyDiagnosticCodeCollectionUseCase';

export default function* fetchSpecialtyDiagnosticCodes(successAction, failureAction) {
  try {
    const currentUserProfile = yield select(selectUserProfileInContext());
    const useCase = new GetUserSpecialtyDiagnosticCodeCollectionUseCase(currentUserProfile);

    const collection = yield call([useCase, 'getCollection']);
    yield put(successAction(collection));
  } catch (e) {
    yield put(failureAction(e));
  }
}
