import React from 'react';
import BillingTypeGroupablePaymentRequestReport from '../PaymentRequestReport/BillingTypeGroupablePaymentRequestReport/BillingTypeGroupablePaymentRequestReport';
import PrintAct from '../../../Act/PrintAct/PrintActButton';
import {
  ACTIVITIES_ACT_TYPE,
  ACTIVITIES_LUMP_SUM_TYPE,
  ACTIVITIES_MIXTE_TYPE
} from '../../../../../shared/collection/collectionConstants';
import PaymentRequestReport from '../PaymentRequestReport/PaymentRequestReport/PaymentRequestReport';
import PeriodActs from '../classes/PeriodActs';
import { computeExpectedAmountForActivities } from '../../../../../shared/amountComputation/computeExpectedAmount';
import UserBillingType from '../../../../../shared/domain/user/UserBillingType';
import calculateORFromStatementDate from '../../../../../shared/periods/common/calculateORFromActDate';
import Period from '../../../../../shared/periods/common/Period';

export const calculateTotal = (preparedActivities) =>
  computeExpectedAmountForActivities(
    preparedActivities[ACTIVITIES_ACT_TYPE].concat(
      preparedActivities[ACTIVITIES_LUMP_SUM_TYPE],
      preparedActivities[ACTIVITIES_MIXTE_TYPE]
    )
  );

export interface Props {
  user: {
    billingType: string;
    title: string;
    firstName: string;
    lastName: string;
  };
  period: any;
  activities: any;
}

const IndividualPaymentRequestRenderer: React.FunctionComponent<Props> = ({ user, period, activities }) => {
  const or = `(OR ${
    (period?.act?.endDate && calculateORFromStatementDate(Period.actBillingPeriodOfDate(period.act.endDate))) || ''
  })`;
  const title = `Rapport de facturation 
    ${period?.act?.startDate?.format('YYYY-DD-MM')} au ${period?.act?.endDate?.format('YYYY-MM-DD')} 
    ${or} - ${user.title} ${user.firstName} ${user.lastName}.pdf`;

  if (user.billingType === UserBillingType.POOL) {
    return (
      <BillingTypeGroupablePaymentRequestReport
        individual
        period={period}
        preparedActivities={activities}
        PrintButton={<PrintAct acts={activities[ACTIVITIES_ACT_TYPE]} title={title} />}
      />
    );
  }

  return (
    <PaymentRequestReport
      acts={new PeriodActs(period[ACTIVITIES_ACT_TYPE], activities[ACTIVITIES_ACT_TYPE])}
      PrintButton={<PrintAct acts={activities[ACTIVITIES_ACT_TYPE]} title={title} />}
      mixte={activities[ACTIVITIES_MIXTE_TYPE]}
      lumpSum={activities[ACTIVITIES_LUMP_SUM_TYPE]}
      total={calculateTotal(activities)}
      pool={undefined}
      individual={undefined}
    />
  );
};

export default IndividualPaymentRequestRenderer;
