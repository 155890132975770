import { first, isEmpty, last, sortBy } from 'lodash';
import find from 'lodash/find';

const getLastPreferenceByPriority = (placeArray) => last(sortBy(placeArray, 'priority'));

const getNextPriority = (preferences) => {
  const lastPriority = !isEmpty(preferences) && getLastPreferenceByPriority(preferences).priority;
  return lastPriority ? lastPriority + 1 : 1;
};

export const getFirstPlaceByPriority = (placeArray) => first(sortBy(placeArray, 'priority'));

export const getActivityPlacePreferenceFromRoute = (placesPreferences, placeNumberInRoute) => {
  const placePreferenceFit = find(placesPreferences, (place) => place.number === placeNumberInRoute);
  return placePreferenceFit || getFirstPlaceByPriority(placesPreferences);
};

export default getNextPriority;
