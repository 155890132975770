import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { gql, useQuery } from '@apollo/client';
import { useLocation, useSearchParams } from 'react-router-dom';
import QueryString from 'qs';
import ActivitiesSearchDialog from '../../ActivitiesSearch/ActivitiesSearchDialog';
import FilteredActivitiesView from '../../../components/FilteredActivitiesView/FilteredActivitiesView';
import { activitiesCollectionRef } from '../../../firebase/collection/collectionReferences';
import { selectUserProfileInContext } from '../../User/selectors';
import User from '../../../../shared/domain/user/User';
import Activity, { ActivityId } from '../../../../shared/domain/activity/global/model/Activity';
import { ID_FIELD, USER_ID_FIELD } from '../../../../shared/collection/queryConstants';
import usePagination from '../../../ui/hooks/usePagination';
import PageSelector from '../../../components/PageSelector/PageSelector';
import isDefined from '../../../../shared/utils/isDefined';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import ActivityOtherFilter from '../../../../shared/core/activity/domain/ActivityOtherFilter';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import { OutsideRamqPatientType } from '../../../../shared/domain/activity/act/model/Act';
import AdminStatus from '../../../../shared/core/activity/domain/AdminStatus';

const PAGE_SIZE = 10;

interface QueryParams {
  other?: ActivityOtherFilter[];
  dateRange?: {
    startDate?: number;
    endDate?: number;
  };
  status?: ActivityStatus[];
  adminStatus?: AdminStatus[];
  type?: ActivityType[];
  outsideRamqPatientType?: OutsideRamqPatientType[];
  textFilter?: string;
  pageNumber?: number;
}

interface Props {
  user?: User;
}

interface SearchActivitiesVariables {
  limit: number;
  offset: number;
  query: {
    userIds?: string[];
    searchTerm?: string;
    types?: ActivityType[];
    statuses?: ActivityStatus[];
    adminStatuses?: AdminStatus[];
    outsideRamqPatientTypes?: OutsideRamqPatientType[];
    synchronized?: boolean;
    startDate?: number;
    endDate?: number;
  };
}

const SEARCH_ACTIVITIES_QUERY = gql`
  query SearchActivities($query: GetActivitiesInput!, $limit: Float!, $offset: Float!) {
    activitiesResult(query: $query, limit: $limit, offset: $offset) {
      count
      activities {
        id
        firebaseId
      }
    }
  }
`;

export const SearchActivitiesWithFiltersRoute: React.FunctionComponent<Props> = ({ user }) => {
  const location = useLocation();
  const [urlSearchParams, setSearchParams] = useSearchParams();

  const queryParams = useMemo<QueryParams>(
    () => QueryString.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );

  const [activities, setActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const userId = user?.id;

  const variables: SearchActivitiesVariables = {
    limit: PAGE_SIZE,
    offset: ((queryParams.pageNumber ?? 1) - 1) * PAGE_SIZE,
    query: {
      userIds: userId ? [userId] : [],
      ...(queryParams.textFilter && { searchTerm: queryParams.textFilter }),
      ...(queryParams.type && { types: queryParams.type }),
      ...(queryParams.status && { statuses: queryParams.status }),
      ...(queryParams.adminStatus && { adminStatuses: queryParams.adminStatus }),
      ...(queryParams.outsideRamqPatientType && {
        outsideRamqPatientTypes: queryParams.outsideRamqPatientType
      }),
      ...(queryParams.other &&
        queryParams.other.includes(ActivityOtherFilter.UNSYNCHRONIZED) && { synchronized: false }),
      ...(queryParams.dateRange?.startDate && { startDate: Number(queryParams.dateRange.startDate) }),
      ...(queryParams.dateRange?.endDate && { endDate: Number(queryParams.dateRange.endDate) })
    }
  };

  const { data, loading: isLoadingFromRalph } = useQuery<{
    activitiesResult: {
      count: number;
      activities: Array<{ firebaseId: string }>;
    };
  }>(SEARCH_ACTIVITIES_QUERY, {
    skip: !isDefined(user),
    variables
  });

  const pagination = usePagination({
    totalCount: data?.activitiesResult.count || 0,
    pageSize: PAGE_SIZE,
    pageNumber: queryParams.pageNumber ?? 1,
    onChangePage: (pageNumber: number) => {
      urlSearchParams.set('pageNumber', String(pageNumber));
      setSearchParams(urlSearchParams);
    }
  });

  useEffect(() => {
    if (!userId || isLoadingFromRalph) return undefined;

    if (data?.activitiesResult.activities.length === 0) {
      setIsLoading(false);
      setActivities([]);
      return undefined;
    }

    setIsLoading(true);

    const activityIds = data?.activitiesResult.activities.map(({ firebaseId }) => firebaseId) ?? [];

    const { unsubscribe } = subscribeToSnapshot({
      activityIds,
      userId,
      onSnapshot: (newActivities) => {
        setActivities(orderActivities(newActivities, activityIds));
        setIsLoading(false);
      }
    });

    return unsubscribe;
  }, [userId, isLoadingFromRalph, data?.activitiesResult.activities]);

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ActivitiesSearchDialog />

      <PageSelector style={{ marginTop: 25 }} pagination={pagination} />

      <FilteredActivitiesView
        nbHits={data?.activitiesResult.count || 0}
        activitiesLoading={isLoadingFromRalph || isLoading}
        activities={activities}
        query={variables.query}
      />

      <PageSelector style={{ marginBottom: 75 }} pagination={pagination} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectUserProfileInContext()
});

function subscribeToSnapshot({
  activityIds,
  userId,
  onSnapshot
}: // eslint-disable-next-line no-unused-vars
{
  activityIds: ActivityId[];
  userId: string;
  onSnapshot: (activities: Activity[]) => void;
}): { unsubscribe: () => void } {
  const unsubscribe = activitiesCollectionRef()
    .where(ID_FIELD, 'in', activityIds)
    .where(USER_ID_FIELD, '==', userId)
    .onSnapshot((snapshot) => {
      const activities: Activity[] = [];
      snapshot.docs.forEach((doc) => activities.push(doc.data()));

      onSnapshot(activities);
    });

  return { unsubscribe };
}

function orderActivities(activities: Activity[], order: ActivityId[]): Activity[] {
  const result: Activity[] = [];
  for (const activity of activities) {
    const index = order.indexOf(activity!.id as string);
    result[index] = activity;
  }

  return result;
}

export default connect(mapStateToProps)(SearchActivitiesWithFiltersRoute);
