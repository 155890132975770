import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import find from 'lodash/find';
import DialogCenteredTitle from '../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import SwipeableTabs from '../../../components/SwipeableTabs/SwipeableTabs';
import messages from '../../../containers/CodeSearchDialog/messages';
import isSameCodeItem from '../utils/isSameCodeItem';
import SearchActCodesTab from './SearchActCodesTab/SearchActCodesTab';
import FavoritesActCodesTabFactory from './FavoritesActCodesTabFactory/FavoritesActCodesTabFactory';
import ActCodePresetsTab from './ActCodePresetsTab/ActCodePresetsTab';
import { getActCodesDataWithSpecialtyOverrides } from '../../../../shared/data/actCodesData';
import { usePostHog } from 'posthog-js/react';

export const styles = (theme) => ({
  paper: {
    minHeight: '90vh',
    height: '100%'
  },
  slideChildContainer: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  }
});

export function ActCodesSearchDialog({ fullScreen, open, onClose, onConfirm, classes }) {
  const posthog = usePostHog();
  const [selectedCodes, setSelectedCodes] = React.useState([]);

  const handleCodeToggledWithPosthog = (code, mode = 'favorites' || 'presets' || 'search') => {
    posthog.capture('act_code_selected', { code: code.code, mode });
    codeToggled(code);
  };

  const codeToggled = (code) => {
    setSelectedCodes((previousSelectedCodes) => {
      const indexOfCurrentCodeIntoSelectedList = previousSelectedCodes.findIndex((selectedItem) =>
        isSameCodeItem(selectedItem, code)
      );
      const currentCodeIsAlreadySelected = indexOfCurrentCodeIntoSelectedList !== -1;
      if (currentCodeIsAlreadySelected) {
        const updatedSelectedCodes = [...previousSelectedCodes];
        updatedSelectedCodes.splice(indexOfCurrentCodeIntoSelectedList, 1);

        return updatedSelectedCodes;
      }
      return [...previousSelectedCodes, code];
    });
  };

  const fetchLatestCodeVersion = (code) =>
    find(getActCodesDataWithSpecialtyOverrides(), (actCode) => actCode.code === code.code);

  const handleCodeSelected = (code) => {
    posthog.capture('act_code_selected', { code: code.code, mode: 'search' });
    const latestCodeVersion = fetchLatestCodeVersion(code);
    const finalCodeVersion = selectedCodes.map(fetchLatestCodeVersion);
    onConfirm([...finalCodeVersion, latestCodeVersion]);
    setSelectedCodes([]);
  };

  const handleConfirm = () => {
    const finalCodeVersion = selectedCodes.map(fetchLatestCodeVersion);
    onConfirm(finalCodeVersion);
    setSelectedCodes([]);
  };

  const handleClose = () => {
    onClose();
    setSelectedCodes([]);
  };

  return (
    <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogCenteredTitle label={<FormattedMessage {...messages.codeSearchTitle} />} />
      <SwipeableTabs
        swipeableViewsProps={{
          containerStyle: { height: '100%' },
          slideStyle: { overflow: 'hidden' }
        }}
        tabs={[
          {
            key: 'favorites',
            icon: <FavoriteIcon />,
            component: (
              <div className={classes.slideChildContainer}>
                <FavoritesActCodesTabFactory
                  selectedCodes={selectedCodes}
                  onClickItem={(code) => handleCodeToggledWithPosthog(code, 'favorites')}
                  onConfirm={handleConfirm}
                  onCancel={handleClose}
                />
              </div>
            )
          },
          {
            key: 'presets',
            icon: <FolderSpecialIcon />,
            component: (
              <div className={classes.slideChildContainer} key="presets-tab">
                <ActCodePresetsTab
                  selectedCodes={selectedCodes}
                  onClickItem={(code) => handleCodeToggledWithPosthog(code, 'presets')}
                  onConfirm={handleConfirm}
                  onCancel={handleClose}
                />
              </div>
            )
          },
          {
            key: 'search',
            icon: <SearchIcon />,
            component: (
              <div className={classes.slideChildContainer}>
                <SearchActCodesTab onSuggestionClick={(code) => handleCodeSelected(code)} onClose={handleClose} />
              </div>
            )
          }
        ]}
      />
    </Dialog>
  );
}

ActCodesSearchDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default compose(withMobileDialog(), withStyles(styles))(ActCodesSearchDialog);
