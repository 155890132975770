import EXCEPTIONAL_CIRCUMSTANCES from '../../../../shared/ramq/requiredDetails/exceptionalCircumstances';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const summaryRender = (code) => [
  {
    name: 'circonstances exceptionnelles',
    value: code[EXCEPTIONAL_CIRCUMSTANCES] ? 'Oui' : 'Non'
  }
];

const associatedFields = [
  {
    label: 'Circonstances exceptionnelles ?',
    getName: (index) => `codes[${index}].${EXCEPTIONAL_CIRCUMSTANCES}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    props: {
      defaultValue: true
    }
  }
];

export default { associatedFields, summaryRender };
