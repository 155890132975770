import classNames from 'classnames';
import React from 'react';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const ToggleInput = ({ value, onChange, disabled = false, className }: Props) => (
  <input
    type="checkbox"
    className={classNames('toggle', {
      'border-primary-900 bg-base-100 [--tglbg:oklch(var(--p))] hover:bg-base-100': value,
      'border-neutral-300': !value,
      className
    })}
    checked={value}
    disabled={disabled}
    onChange={(e) => onChange(e.target.checked)}
  />
);

export default ToggleInput;
