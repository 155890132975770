import SURGERY_CONTEXT_ELEMENT from 'shared/ramq/requiredDetails/surgeryContextElement';
import { RAMQContextElements } from 'shared/ramq/RamqContextElements';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from 'app/components/Form/Fields';

interface ListValue {
  label: string;
  value: string;
}

const ramqContextElement1948 = RAMQContextElements.get('1948');
const ramqContextElement1949 = RAMQContextElements.get('1949');

const getList = (date: number) => {
  const list: ListValue[] = [];

  if (ramqContextElement1948 && RAMQContextElements.isEffectiveOn(ramqContextElement1948, date)) {
    list.push({ label: 'Chirurgie élective >1 an', value: ramqContextElement1948.code });
  }
  if (ramqContextElement1949 && RAMQContextElements.isEffectiveOn(ramqContextElement1949, date)) {
    list.push({ label: 'Chirurgie oncologique >56 jours', value: ramqContextElement1949.code });
  }

  return list;
};

const getAssociatedFields = (date: number) => {
  if (
    (!ramqContextElement1948 || !RAMQContextElements.isEffectiveOn(ramqContextElement1948, date)) &&
    (!ramqContextElement1949 || !RAMQContextElements.isEffectiveOn(ramqContextElement1949, date))
  ) {
    return [];
  }

  return [
    {
      label: 'En attente de chirurgie',
      getName: (index) => `codes[${index}].${SURGERY_CONTEXT_ELEMENT}`,
      type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
      list: getList(date),
      notRequired: true
    }
  ];
};

export default { getAssociatedFields };
