import { RAMQCodes } from '../../../../shared/ramq/RamqCodes';
import recursiveItemsCallback from '../../../../utils/recursive-items-callback';

export const GET_SPECIALTY_PRESET_CODES = 'getSpecialtyPresetActCodes';
export const getSpecialtyPresetActCodes = () => ({
  type: GET_SPECIALTY_PRESET_CODES
});

export const GET_SPECIALTY_PRESET_CODES_SUCCESS = 'getSpecialtyPresetActCodesSuccess';
export const getSpecialtyPresetActCodesSuccess = (codes) => ({
  type: GET_SPECIALTY_PRESET_CODES_SUCCESS,
  codes: recursiveItemsCallback(codes, (codePreference) => ({
    ...codePreference,
    ...(RAMQCodes.get(codePreference.code) || {}),
    ...(codePreference.description && { description: codePreference.description })
  }))
});

export const GET_SPECIALTY_PRESET_CODES_FAILURE = 'getSpecialtyPresetActCodesFailure';
export const getSpecialtyPresetActCodesFailure = (error) => ({
  type: GET_SPECIALTY_PRESET_CODES_FAILURE,
  error
});

export const GET_SPECIALTY_FAVORITES_CODES = 'getSpecialtyFavoritesActCodes';
export const getSpecialtyFavoritesActCodes = () => ({
  type: GET_SPECIALTY_FAVORITES_CODES
});

export const GET_SPECIALTY_FAVORITES_CODES_SUCCESS = 'getSpecialtyFavoritesActCodesSuccess';
export const getSpecialtyFavoritesActCodesSuccess = (codes) => ({
  type: GET_SPECIALTY_FAVORITES_CODES_SUCCESS,
  codes: recursiveItemsCallback(codes, (codePreference) => ({
    ...codePreference,
    ...(RAMQCodes.get(codePreference.code) || {}),
    ...(codePreference.description && { description: codePreference.description })
  }))
});

export const GET_SPECIALTY_FAVORITES_CODES_FAILURE = 'getSpecialtyFavoritesActCodesFailure';
export const getSpecialtyFavoritesActCodesFailure = (error) => ({
  type: GET_SPECIALTY_FAVORITES_CODES_FAILURE,
  error
});
