import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import SearchIcon from '@material-ui/icons/Search';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Dialog from '@material-ui/core/Dialog';
import { FormattedMessage } from 'react-intl';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import diagnosticCodesWithMarkupForInternists from './diagnosticWithMarkup.json';
import SwipeableTabs from '../../../../components/SwipeableTabs/SwipeableTabs';
import DiagnosticCodePresetsTab from '../DiagnosticCodePresetsTab/DiagnosticCodePresetsTab';
import DiagnosticCodeRegistryTab from '../DiagnosticCodesRegistryTab/DiagnosticCodeRegistryTab';
import DiagnosticCodeFavoritesTab from '../DiagnosticCodeFavoritesTab/DiagnosticCodeFavoritesTab';
import {
  selectHasEditableFavoriteDiagnosticCodesEnabled,
  selectUserProfileInContext
} from '../../../../containers/User/selectors';
import DialogCenteredTitle from '../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import messages from '../../../../containers/CodeSearchDialog/messages';
import { INTERNIST } from '../../../../specialty/domain/specialties';

export const styles = (theme) => ({
  paper: {
    minHeight: '90vh',
    height: '100%'
  },
  slideChildContainer: {
    'display': 'flex',
    'height': '100%',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  }
});

export class DiagnosticCodeSearchDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleItemSelected = this.handleItemSelected.bind(this);
  }

  handleItemSelected = (item) => {
    this.props.onItemSelected(item);
    this.props.onClose();
  };

  render() {
    const { open, userProfile, diagnosticList, displayFavoritesTab, onClose, fullScreen, classes } = this.props;

    const diagnosticCodesWithMarkup = userProfile.specialty === INTERNIST ? diagnosticCodesWithMarkupForInternists : [];

    const tabs = [
      {
        key: 'presets',
        icon: <FolderSpecialIcon />,
        component: (
          <div className={classes.slideChildContainer} key="presets-tab">
            <DiagnosticCodePresetsTab
              diagnosticCategoryList={diagnosticList}
              diagnosticCodesWithMarkup={diagnosticCodesWithMarkup}
              canAddItemToFavorites={displayFavoritesTab}
              onItemSelected={this.handleItemSelected}
              onClose={onClose}
            />
          </div>
        )
      },
      {
        key: 'search',
        icon: <SearchIcon />,
        component: (
          <div className={classes.slideChildContainer} key="search-tab">
            <DiagnosticCodeRegistryTab
              diagnosticCodesWithMarkup={diagnosticCodesWithMarkup}
              canAddItemToFavorites={displayFavoritesTab}
              onItemSelected={this.handleItemSelected}
              onClose={onClose}
            />
          </div>
        )
      }
    ];

    if (displayFavoritesTab) {
      tabs.unshift({
        key: 'favorites',
        icon: <FavoriteIcon />,
        component: (
          <div className={classes.slideChildContainer} key="favorites-tab">
            <DiagnosticCodeFavoritesTab
              diagnosticCodesWithMarkup={diagnosticCodesWithMarkup}
              onItemSelected={this.handleItemSelected}
              onClose={onClose}
            />
          </div>
        )
      });
    }

    return (
      <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogCenteredTitle label={<FormattedMessage {...messages.codeSearchTitle} />} />
        <SwipeableTabs
          swipeableViewsProps={{
            containerStyle: { height: '100%' },
            slideStyle: { overflow: 'hidden' }
          }}
          tabs={tabs}
        />
      </Dialog>
    );
  }
}

DiagnosticCodeSearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  userProfile: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  displayFavoritesTab: PropTypes.bool.isRequired,
  diagnosticList: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfileInContext(),
  displayFavoritesTab: selectHasEditableFavoriteDiagnosticCodesEnabled()
});

export default compose(withMobileDialog(), connect(mapStateToProps), withStyles(styles))(DiagnosticCodeSearchDialog);
