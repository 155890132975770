import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocation } from 'react-router-dom';
import QueryString from 'qs';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import isDefined from '../../../shared/utils/isDefined';
import { getActivities, loadMoreActivities } from '../Activities/actions';

export const SEARCH_ACTIVITIES_LIMIT = 100;

const statusWouldReturnTooMuchData = (filters) => {
  const currentStatus = filters.status;
  return (
    !isDefined(currentStatus) ||
    currentStatus === ActivityStatus.PAID ||
    currentStatus === ActivityStatus.CANCELED ||
    currentStatus === ActivityStatus.SENT ||
    currentStatus === ActivityStatus.PROCESSING
  );
};

const searchActivitiesWithLimit = (searchActivitiesFilters, onGetActivities, loadMoreActivities) => () => {
  if (statusWouldReturnTooMuchData(searchActivitiesFilters)) {
    if (searchActivitiesFilters.startAfter) {
      loadMoreActivities({ ...searchActivitiesFilters, limit: SEARCH_ACTIVITIES_LIMIT });
    } else {
      onGetActivities({ ...searchActivitiesFilters, limit: SEARCH_ACTIVITIES_LIMIT });
    }
  } else {
    onGetActivities(searchActivitiesFilters);
  }
};

export const SearchLimitWrapper = (WrappedComponent) => {
  function SearchLimitComponent({ onGetActivities, loadMoreActivities, ...rest }) {
    const location = useLocation();
    const searchActivitiesFilters = useMemo(
      () => QueryString.parse(location.search, { ignoreQueryPrefix: true }),
      [location.search]
    );

    useEffect(() => {
      searchActivitiesWithLimit(searchActivitiesFilters, onGetActivities, loadMoreActivities)();
    }, [loadMoreActivities, onGetActivities, searchActivitiesFilters]);

    return <WrappedComponent {...rest} />;
  }

  SearchLimitComponent.propTypes = {
    onGetActivities: PropTypes.func.isRequired,
    loadMoreActivities: PropTypes.func.isRequired
  };

  return SearchLimitComponent;
};

const mapDispatchToProps = {
  onGetActivities: getActivities,
  loadMoreActivities
};

const withSearchLimit = compose(connect(null, mapDispatchToProps), SearchLimitWrapper);

export default withSearchLimit;
