import { ActCode } from 'shared/core/activity/domain/Act';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import RalphApi from './ralph-api';

interface UsersGrowthStatistic {
  count: number;
  month: Date;
  total: number;
}

interface UsersSpecialtyStatistic {
  count: number;
  specialty: string;
}

interface ActivitiesByUserStatistic {
  count: number;
  userId: string;
  firstName: string;
  lastName: string;
  specialty: string;
}

interface ActivitiesByStatusStatistic {
  count: number;
  status: ActivityStatus;
}

interface ActivityCodesStatistic {
  total: number;
  activityCodesCount: Array<{
    count: number;
    ratio: number;
    codes: string[];
  }>;
}

interface DeviceModelWithTotal {
  model: string;
  total: number;
}

export interface UsersDevicesStatistic {
  iOS: {
    modelsWithTotal: DeviceModelWithTotal[];
    total: number;
  };
  android: {
    modelsWithTotal: DeviceModelWithTotal[];
    total: number;
  };
  web: {
    modelsWithTotal: DeviceModelWithTotal[];
    total: number;
  };
}

export default class StatisticsService {
  public static async getUsersGrowth(start: Date | null, end: Date | null): Promise<UsersGrowthStatistic[]> {
    const queryParams = new URLSearchParams();

    if (start) {
      queryParams.append('start', start.valueOf().toString());
    }
    if (end) {
      queryParams.append('end', end.valueOf().toString());
    }

    const response = await RalphApi.sendRequest(`statistics/users-growth?${queryParams}`, 'GET');

    return response.json() as Promise<UsersGrowthStatistic[]>;
  }

  public static async getUsersSpecialty(): Promise<UsersSpecialtyStatistic[]> {
    const response = await RalphApi.sendRequest('statistics/users-specialty', 'GET');

    return response.json() as Promise<UsersSpecialtyStatistic[]>;
  }

  public static async getActivitiesByUser(start: Date, end: Date): Promise<ActivitiesByUserStatistic[]> {
    const queryParams = new URLSearchParams();

    queryParams.append('start', start.valueOf().toString());
    queryParams.append('end', end.valueOf().toString());

    const response = await RalphApi.sendRequest(`statistics/activities-by-user?${queryParams}`, 'GET');

    return response.json() as Promise<ActivitiesByUserStatistic[]>;
  }

  public static async getActivitiesStatus(
    start: Date,
    end: Date,
    statuses: string[],
    userIds?: string[],
    managerIds?: string[]
  ): Promise<ActivitiesByStatusStatistic[]> {
    const queryParams = new URLSearchParams();

    queryParams.append('start', start.valueOf().toString());
    queryParams.append('end', end.valueOf().toString());

    statuses.forEach((status) => {
      queryParams.append('statuses', status);
    });

    userIds?.forEach((userId) => {
      queryParams.append('userIds', userId);
    });

    managerIds?.forEach((managerId) => {
      queryParams.append('managerIds', managerId);
    });

    const response = await RalphApi.sendRequest(`statistics/activities-status?${queryParams}`, 'GET');

    return response.json() as Promise<ActivitiesByStatusStatistic[]>;
  }

  public static async getActivitiesCodes(codes?: ActCode[]): Promise<ActivityCodesStatistic> {
    const queryParams = new URLSearchParams();

    codes?.forEach(({ code }) => {
      queryParams.append('codes', code);
    });

    const response = await RalphApi.sendRequest(`statistics/activity-codes?${queryParams}`, 'GET');

    return response.json() as Promise<ActivityCodesStatistic>;
  }

  public static async getUsersDevices(): Promise<UsersDevicesStatistic> {
    const response = await RalphApi.sendRequest('statistics/users-devices', 'GET');

    return response.json() as Promise<UsersDevicesStatistic>;
  }
}
