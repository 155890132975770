import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import MaxSearchReachedIndicator from '../../../containers/SearchSuggestions/MaxSearchReachedIndicator';

const styles = () => ({});

function MaxSearchReachedDialogActions({ children, maxSearchNumber, hitsCount, classes }) {
  return (
    <DialogActions>
      <MaxSearchReachedIndicator maxSearchNumber={maxSearchNumber} hitsCount={hitsCount} />
      <div className={classes.actions}>{children}</div>
    </DialogActions>
  );
}

MaxSearchReachedDialogActions.propTypes = {
  maxSearchNumber: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  hitsCount: PropTypes.number
};

export default withStyles(styles)(MaxSearchReachedDialogActions);
