import { logError } from '../../../../../../shared/utils/plog';
import {
  RAMQ_RESPONSE_OUTCOME_ERROR,
  RAMQ_RESPONSE_OUTCOME_FAILURE,
  RAMQ_RESPONSE_OUTCOME_SUCCESS
} from '../../../../../../shared/ramq/constants';
import ActivityResponseSuccess from './Success/ActivityResponseSuccess';
import ActResponseFailure from './Failure/ActResponseFailure';
import ActivityResponseError from './Error/ActivityResponseError';

const activityOutcomeResponseComponentMap = {
  [RAMQ_RESPONSE_OUTCOME_SUCCESS]: ActivityResponseSuccess,
  [RAMQ_RESPONSE_OUTCOME_FAILURE]: ActResponseFailure,
  [RAMQ_RESPONSE_OUTCOME_ERROR]: ActivityResponseError
};

const getOutcomeResponseComponent = (ramqResponse) => {
  let component = activityOutcomeResponseComponentMap[ramqResponse.outcome];
  if (component === undefined) {
    logError({
      type: 'dataFormat',
      text: 'invalid ramqExchange.outcome',
      array: ['Outcome missing or invalid, cannot render corresponding component', ramqResponse]
    });
    component = activityOutcomeResponseComponentMap[RAMQ_RESPONSE_OUTCOME_ERROR];
  }
  return component;
};

export default getOutcomeResponseComponent;
