import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import { selectCurrentCodes, selectPatientDiagnostic } from '../../../../containers/ActForm/selectors';
import InputDialogTrigger from '../../../Dialogs/DialogTrigger/InputDialogTrigger/InputDialogTrigger';
import { displayCode } from '../../utils/fieldTransformations';
import LimitedDiagnosticDialog from '../../../../favorite/diagnosticCode/ui/LimitedDiagnosticDialog/LimitedDiagnosticDialog';
import { ActCode, PatientDiagnostic } from '../../../../../shared/domain/activity/act/model/Act';
import { updateInputValue } from './utils/DefaultDiagnosticUtils';

interface OwnProps {
  input: WrappedFieldInputProps;
  diagnosticList: any[];
}

interface ReduxStateProps {
  patientDiagnostic: PatientDiagnostic[] | PatientDiagnostic;
  actCodes: ActCode[];
}

export interface Props extends OwnProps, ReduxStateProps {}

export const LimitedDiagnosticWithPatientContextField: React.FunctionComponent<Props> = (props) => {
  const { patientDiagnostic, actCodes, input, diagnosticList } = props;
  const handleOnChange = input.onChange;
  const inputValue = input.value;

  const isInputAlreadyFilled = input.value !== '' && input.value !== null;

  useEffect(() => {
    updateInputValue(patientDiagnostic, actCodes, isInputAlreadyFilled, handleOnChange, inputValue);
  }, [patientDiagnostic, actCodes, isInputAlreadyFilled, handleOnChange, inputValue]);

  return (
    <InputDialogTrigger
      {...props}
      formatValueFunction={displayCode}
      DialogComponent={LimitedDiagnosticDialog}
      dialogProps={{ diagnosticList }}
    />
  );
};

export const mapStateToProps = createStructuredSelector({
  patientDiagnostic: selectPatientDiagnostic(),
  actCodes: selectCurrentCodes()
});

export default connect<ReduxStateProps, {}, OwnProps, {}>(mapStateToProps)(LimitedDiagnosticWithPatientContextField);
