import { AM_PERDIEM, PM_PERDIEM } from '../../../timeSlot/constants';
import { computeTimeSlotSpanFromTwoHoursInTheSameDay } from '../../../timeSlot/timeSlotUtils';
import {
  getRamqDayOfMonthFromTimestamp,
  getRamqNumberOfHoursWorkedFromTimestamps
} from '../../../../ramq/ramqCommonUtils';
import {
  makeFullRateDetail,
  makeHalfRateDetailIfNotBilledByAct,
  pmHoursWorkedInFullRate,
  pmHoursWorkedInHalfRate
} from '../../partialRate/partialRateUtils';
import { formatToDeferredPaymentRequestDate } from '../../../../ramq/ramqDateUtil';
import { codeIsMissingAPrefix } from '../../codePrefixor/codePrefixors.utils';

export default class DefaultTimeSlotCalculator {
  calculate(day) {
    return computeTimeSlotSpanFromTwoHoursInTheSameDay(day.start, day.end).map((timeslot) =>
      this.calculatePeriodWorkedHours(day, timeslot)
    );
  }

  splitPmTimeSlotIntoMultipleRate(timeSlotStartTime, timeSlotEndTime, lumpSum) {
    const fullRateHours = pmHoursWorkedInFullRate(timeSlotStartTime, timeSlotEndTime);
    const halfRateHours = pmHoursWorkedInHalfRate(timeSlotStartTime, timeSlotEndTime);

    const details = [];
    if (fullRateHours !== '0000') {
      details.push(makeFullRateDetail(lumpSum, fullRateHours));
    }
    if (halfRateHours !== '0000') {
      details.push(makeHalfRateDetailIfNotBilledByAct(lumpSum, halfRateHours));
    }

    return details;
  }

  calculatePeriodWorkedHours(day, period) {
    const hoursWorked = getRamqNumberOfHoursWorkedFromTimestamps(period.start, period.end);

    let details = [];

    if (period.timeslot === PM_PERDIEM && codeIsMissingAPrefix(day.code)) {
      details = this.splitPmTimeSlotIntoMultipleRate(period.start, period.end, day);
    } else if (period.timeslot === AM_PERDIEM) {
      details = [makeHalfRateDetailIfNotBilledByAct(day, hoursWorked)];
    } else {
      details = [makeFullRateDetail(day, hoursWorked)];
    }

    return {
      details,
      timePeriod: period,
      day: getRamqDayOfMonthFromTimestamp(day.date),
      date: formatToDeferredPaymentRequestDate(day.date)
    };
  }
}
