import { WithStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import { useSelector } from 'react-redux';
import { selectPatientInformation } from 'app/containers/ActForm/selectors';
import Alert from 'app/components/Alert/Alert';
import { compose } from '@reduxjs/toolkit';
import AlertSeverity from 'app/ui/shared/severity/AlertSeverity';
import YES_NO_CHECKBOX_VALUES from 'app/ui/shared/form/constants';
import Role from 'shared/domain/authentication/Role';
import { isUserAtLeast } from 'application/utilities/authentication';
import { selectCurrentLoggedUser } from 'app/containers/Authentication/selectors';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import { PhoneNumberField, SingleLineField } from '../../../../../components/Form/Fields';
import SingleSelectCheckboxGroup from '../../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import CashAmountField from '../../../../../components/Form/custom/CashAmountField';
import { actFormAdminFields } from '../../../constants';

export const styles = ({ spacing }) =>
  ({
    alertBoxWrapper: {
      marginTop: spacing(2)
    },
    alertBoxChildrenWrapper: {
      paddingTop: spacing(2)
    }
  }) as const;

export const PAYMENT_TYPE_VALUES = [
  { label: 'Via hôpital', value: 'hospital' },
  { label: 'Carte de crédit', value: 'credit' },
  { label: 'Comptant', value: 'cash' }
];

export interface Props extends WithStyles<typeof styles> {}

export const BilledForm: React.FunctionComponent<Props> = ({ classes }: Props) => {
  const { outsideRamq } = useSelector(selectPatientInformation());
  const loggedUser = useSelector(selectCurrentLoggedUser());

  return (
    <>
      {!!outsideRamq &&
        outsideRamq.billingManager === 'doctor' &&
        outsideRamq.allowMedyxToContactWithPatient === false && (
          <div className={classes.alertBoxWrapper}>
            <Alert
              message="Cette demande sera transférée automatiquement au statut payé 6 mois après date de service au montant indiqué sur la demande."
              severity={AlertSeverity.WARNING}
              showIcon
              title="Attention"
            >
              <div className={classes.alertBoxChildrenWrapper}>
                <FormElement
                  name="patientInformation.outsideRamq.billedDisclaimerAccepted"
                  required
                  disabled={isUserAtLeast(loggedUser.role, Role.Agent)}
                  label="Je comprends et j'accepte"
                  list={YES_NO_CHECKBOX_VALUES}
                  component={SingleSelectCheckboxGroup}
                />
              </div>
            </Alert>
          </div>
        )}

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6">
              Identification du patient
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormElement
              name="patientInformation.lastName"
              required
              label="Nom du patient"
              component={SingleLineField}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormElement
              name="patientInformation.firstName"
              required
              label="Prénom du patient"
              component={SingleLineField}
            />
          </Grid>

          <Grid item xs={12}>
            <FormElement name="patientInformation.address" required label="Adresse" component={SingleLineField} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormElement name="patientInformation.phone" required label="Téléphone" component={PhoneNumberField} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormElement name="patientInformation.email" label="Adresse courriel" component={SingleLineField} />
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom variant="h6">
              Paiement
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FormElement
              name="patientInformation.paymentType"
              required
              label="Type de paiement"
              list={PAYMENT_TYPE_VALUES}
              component={SingleSelectCheckboxGroup}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormElement
              name={actFormAdminFields.manualCorrection}
              required
              label="Montant"
              component={CashAmountField}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default compose(withStyles(styles))(BilledForm);
