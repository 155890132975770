import ListItem from '@material-ui/core/ListItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import PropTypes from 'prop-types';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withStyles } from '@material-ui/core';
import DoctorFavoritesListItemText from '../DoctorFavoritesListItemText/DoctorFavoritesListItemText';
import MoreActions from '../../../../shared/actions/MoreActions/MoreActions';

export const styles = ({ breakpoints }) => ({
  minimalPaddingOnXs: {
    cursor: 'pointer',
    [breakpoints.down('xs')]: {
      paddingLeft: '5px',
      paddingRight: '5px'
    }
  }
});

export function DoctorFavoriteListItem({ doctor, onClick, onEdit, onDelete, classes }) {
  const actions = [
    {
      key: 'edit-action-key',
      labelKey: 'editableCollectionWithCategories.edit',
      clickEvent: onEdit,
      Icon: EditIcon
    },
    {
      key: 'delete-action-key',
      labelKey: 'editableCollectionWithCategories.delete',
      clickEvent: onDelete,
      Icon: DeleteIcon
    }
  ];

  return (
    <ListItem key={doctor.id} onClick={onClick} ContainerComponent="div" className={classes.minimalPaddingOnXs}>
      <DoctorFavoritesListItemText doctor={doctor} />
      <ListItemSecondaryAction>
        <MoreActions actions={actions} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

DoctorFavoriteListItem.defaultProps = {
  onClick: () => {}
};

DoctorFavoriteListItem.propTypes = {
  doctor: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default withStyles(styles)(DoctorFavoriteListItem);
