import React from 'react';
import { MixteDay } from '../../../../../shared/domain/activity/mixte/model/Mixte';
import NewStructureMixteConstraint from '../../../../../shared/domain/ramq/mixte/contraint/NewStructureMixteContraint';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';
import LatestMixtePeriodDetail from './LatestMixtePeriodDetail/LatestMixtePeriodDetail';
import LegacyMixtePeriodDetail from './LegacyMixtePeriodDetail/LegacyMixtePeriodDetail';

export interface Props {
  mixteDay: MixteDay;
}

const MixtePeriodDetail: React.FunctionComponent<Props> = ({ mixteDay }: Props) =>
  NewStructureMixteConstraint.isSatisfiedBy(ActivityType.MIXTE, mixteDay.date) ? (
    <LatestMixtePeriodDetail mixteDay={mixteDay} />
  ) : (
    <LegacyMixtePeriodDetail mixteDay={mixteDay} />
  );

export default MixtePeriodDetail;
