import { createSelector } from '@reduxjs/toolkit';

const selectSearchSuggestions = (state) => state.searchSuggestions;

export const selectSearchValue = () => createSelector(selectSearchSuggestions, (state) => state.searchValue);

export const selectMaxSearchReached = () => createSelector(selectSearchSuggestions, (state) => state.maxSearchReached);

export const selectHits = () => createSelector(selectSearchSuggestions, (state) => state.hits);

export const selectTotalHistNumber = () => createSelector(selectSearchSuggestions, (state) => state.totalHitsNumber);
