import React from 'react';
import Icon from 'application/components/icon';
import { Referral } from 'application/pages/settings/settings.referral';
import useTranslate from 'application/hooks/use-translate';

const isReferralExpired = (referral: Referral): boolean => new Date(referral.expiredAt) < new Date();

interface Props {
  referral: Referral;
}

const SettingsReferralStatus = ({ referral }: Props) => {
  const translate = useTranslate('pages.settings.referral.referral-status');

  if (referral.claimedAt) {
    return (
      <div>
        <Icon fixedWidth icon="circle-check" className="ml-0 mr-2 text-success" />
        {translate('claimed')}
      </div>
    );
  }

  if (isReferralExpired(referral)) {
    return (
      <div>
        <Icon fixedWidth icon="circle-xmark" className="ml-0 mr-2 text-error" />
        {translate('expired')}
      </div>
    );
  }

  if (referral.referredUser) {
    return (
      <div>
        <Icon fixedWidth icon="circle-check" className="ml-0 mr-2 text-success-content" />
        {translate('signed-up')}
      </div>
    );
  }

  return (
    <div>
      <Icon fixedWidth icon="hourglass-half" className="ml-0 mr-2 text-info" />
      {translate('pending')}
    </div>
  );
};

export default SettingsReferralStatus;
