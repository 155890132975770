import { Button, IconButton, WithStyles, withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { selectMultiActInterval, selectMultiActVisitCode } from 'app/act/multiAct/adapters/selectors';
import DialogTrigger from 'app/components/Dialogs/DialogTrigger/DialogTrigger';
import NamIcon from 'app/components/Form/NamInput/NamIcon/NamIcon';
import NamInput from 'app/components/Form/NamInput/NamInput';
import { VisitCodeDialog } from 'app/components/Form/VisitCode/VisitCodeDialog';
import HourMinute from 'app/containers/ActForm/HourMinuteSelector/HourMinute/HourMinute';
import HourMinuteSelectorDialog from 'app/containers/ActForm/HourMinuteSelectorDialog/HourMinuteSelectorDialog';
import React from 'react';
import { useSelector } from 'react-redux';
import { VisitCodeOption } from 'shared/act/services/VisitCodes';
import { formatDecimalHourToHourMinuteString } from 'shared/utils/dateTime/dateTimeUtils';

export const styles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  controls: {
    marginLeft: 'auto'
  }
});
interface MultiActPatient {
  nam?: string;
  interval?: number;
  visitCode?: string;
}

interface Props extends WithStyles<typeof styles> {
  value: MultiActPatient;
  visitCodeOptions: VisitCodeOption[];
  onChange: (value: MultiActPatient) => void;
  onRemove: () => void;
}

const formatIntervalButton = (interval: number | undefined | null, globalInterval: number | undefined | null) => {
  if (interval !== undefined && interval !== null) {
    return formatDecimalHourToHourMinuteString(interval);
  }

  if (globalInterval !== undefined && globalInterval !== null) {
    return formatDecimalHourToHourMinuteString(globalInterval);
  }

  return 'Int.';
};

const formatSelectedVisitCodeOption = (visitCodeOption: VisitCodeOption | null | undefined) => {
  if (visitCodeOption === null) {
    return 'Aucun';
  }

  if (visitCodeOption === undefined) {
    return 'Visite';
  }

  return visitCodeOption.shortLabel;
};

const MultiActFormPatient = ({ value, visitCodeOptions, onChange, onRemove, classes }: Props) => {
  const { nam = '', interval, visitCode } = value;

  const globalInterval = useSelector(selectMultiActInterval());
  const globalVisitCode = useSelector(selectMultiActVisitCode());

  const selectedVisitCodeOption =
    (visitCode ?? globalVisitCode)
      ? (visitCodeOptions.find((option) => option.value === (visitCode ?? globalVisitCode)) ?? null)
      : undefined;

  return (
    <div className={classes.root}>
      <DialogTrigger
        DialogComponent={HourMinuteSelectorDialog}
        onItemSelected={(hourMinute: HourMinute | null) =>
          onChange({ ...value, interval: hourMinute === null ? undefined : hourMinute.toDecimal() })
        }
        dialogProps={{
          minutesOnly: true,
          minutesPrecision: 5,
          minMinutes: 5,
          maxMinutes: 60,
          selectedHourMinute: interval !== null && interval !== undefined ? HourMinute.fromDecimal(interval) : undefined
        }}
      >
        <Button>{formatIntervalButton(interval, globalInterval)}</Button>
      </DialogTrigger>

      <NamInput selectedNam={nam} onChange={(nam) => onChange({ ...value, nam: nam.toUpperCase() })} />
      <NamIcon nam={nam} />

      {visitCodeOptions.length > 0 && (
        <DialogTrigger
          DialogComponent={VisitCodeDialog}
          onItemSelected={(visitCode) => onChange({ ...value, visitCode: visitCode?.value })}
          dialogProps={{
            options: [{ value: 'NULL', label: 'Aucun' }, ...visitCodeOptions],
            value: visitCode
          }}
        >
          <Button>{formatSelectedVisitCodeOption(selectedVisitCodeOption)}</Button>
        </DialogTrigger>
      )}

      <div className={classes.controls}>
        <IconButton size="small" onClick={onRemove}>
          <ClearIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(MultiActFormPatient);
