import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SectionProps } from 'application/pages/lobby/lobby.signup';
import OptionTiles from 'application/components/option-tiles';
import useTranslate from 'application/hooks/use-translate';
import { gql, useQuery } from '@apollo/client';
import Icon from 'application/components/icon';
import { useLocation } from 'react-router-dom';

interface StripePlan {
  id: string;
  priceId: string;
  name: string;
  description: string;
  bullets: string[];
  monthlyPrice: number;
}

const STRIPE_PLANS_QUERY = gql`
  query SignupPlans {
    plans {
      id
      priceId
      name
      description
      bullets
      monthlyPrice
    }
  }
`;

export const DEMO_FAKE_PRICE_ID = 'demo';

const PaidPlan = ({
  temporaryState,
  onTemporaryStateChange,
  userState,
  onUserChange,
  onValidate,
  generateRecaptchaToken,
  onError
}: SectionProps) => {
  const { search } = useLocation();
  const translate = useTranslate('pages.lobby.signup.section-paid-plan');
  const [recaptchaToken, setRecaptchaToken] = useState<string>();

  useEffect(() => onValidate(!!temporaryState.stripePriceId), [temporaryState.stripePriceId, onValidate]);

  const { data, loading } = useQuery<{ plans: StripePlan[] }>(STRIPE_PLANS_QUERY, {
    context: {
      headers: {
        'recaptcha-token': recaptchaToken
      }
    },
    skip: !recaptchaToken
  });

  const plansMap = useMemo(() => {
    if (!data) {
      return new Map<string, StripePlan>();
    }

    return data.plans.reduce((acc, plan) => {
      acc.set(plan.priceId, plan);
      return acc;
    }, new Map<string, StripePlan>());
  }, [data]);

  useEffect(() => {
    if (temporaryState.stripePriceId || plansMap.size === 0) return;

    const params = new URLSearchParams(search);
    const planId = params.get('planId');
    if (!planId) return;

    let stripePlan: StripePlan | undefined;
    for (const plan of plansMap.values()) {
      if (plan.id === planId) {
        stripePlan = plan;
        break;
      }
    }
    if (!stripePlan) return;

    onTemporaryStateChange({ ...temporaryState, stripePriceId: stripePlan.priceId });
    onUserChange({ ...userState, productId: stripePlan.id });
  }, [onTemporaryStateChange, plansMap, search, temporaryState, userState, onUserChange]);

  useEffect(() => {
    if (plansMap.size > 0) return;

    if (generateRecaptchaToken) {
      generateRecaptchaToken().then(setRecaptchaToken).catch(onError);
    }
  }, [generateRecaptchaToken, onError, plansMap]);

  const onPlanSelected = useCallback(
    (stripePriceId: string) => {
      onTemporaryStateChange({ ...temporaryState, stripePriceId });

      const plan = plansMap.get(stripePriceId);
      if (!plan) return;
      onUserChange({ ...userState, productId: plan.id });
    },
    [onTemporaryStateChange, temporaryState, plansMap, onUserChange, userState]
  );

  if (loading || !recaptchaToken) {
    return (
      <div className="w-full py-12 text-center text-4xl">
        <Icon loading />
      </div>
    );
  }

  return (
    <OptionTiles
      className="w-full flex-col gap-4 p-4"
      value={temporaryState.stripePriceId}
      options={[DEMO_FAKE_PRICE_ID, ...Array.from(plansMap.keys())]}
      onChange={onPlanSelected}
      renderOption={(stripePriceId) => {
        const plan = plansMap.get(stripePriceId);

        return (
          <>
            <div className="text-sm font-bold text-primary-900">{plan?.name ?? translate('demo-option.name')}</div>
            {plan && (
              <div className="font-bold text-primary-900">
                <span className="text-2xl">{plan.monthlyPrice / 100}</span>
                <sup className="relative top-[-10px]">{translate('price-suffix')}</sup>
              </div>
            )}
            <div className="text-sm leading-snug text-neutral-500">
              {plan?.description ?? translate('demo-option.description')}
            </div>
            {plan && (
              <ul className="list-inside list-disc text-justify text-xs text-neutral-500">
                {plan.bullets.map((bullet) => (
                  <li key={bullet}>{bullet}</li>
                ))}
              </ul>
            )}
          </>
        );
      }}
    />
  );
};

export default PaidPlan;
