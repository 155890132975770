import React from 'react';
import { ActCode } from 'shared/core/activity/domain/Act';
import { ConfiguratorProps, RendererProps } from 'application/pages/administration/statistics';
import useTranslate from 'application/hooks/use-translate';
import Chart from 'application/components/chart/chart';
import { getActCodesDataWithSpecialtyOverrides } from 'shared/data/actCodesData';
import CodeSelector from 'app/components/CodeSelector/CodeSelector';
import SelectedActCodes from 'app/containers/ActForm/SelectedActCodes/SelectedActCodes';
import { useQuery } from '@tanstack/react-query';
import StatisticsService from 'application/services/statistics-service';

interface Params {
  selectedCodes?: ActCode[];
}

const Configurator = ({ params, onChange }: ConfiguratorProps<Params>) => (
  <div className="relative flex w-1/3 flex-col items-start gap-2">
    <CodeSelector
      codesData={getActCodesDataWithSpecialtyOverrides()}
      onHandleSelect={(actCode: ActCode[]) =>
        onChange({ selectedCodes: [...(params.selectedCodes ?? []), ...actCode] })
      }
      className="w-full"
    />
    <SelectedActCodes
      actCodes={params.selectedCodes ?? []}
      onDelete={(code: ActCode) => {
        const newCodes = params.selectedCodes?.filter(({ code: selectedCode }) => selectedCode !== code.code);
        onChange({ selectedCodes: newCodes });
      }}
    />
  </div>
);

const Renderer = ({ params }: RendererProps<Params>) => {
  const translate = useTranslate('pages.administration.stats');

  const { data, isFetching } = useQuery({
    queryKey: ['activity-codes-count', params.selectedCodes?.map(({ code }) => code)],
    queryFn: () => StatisticsService.getActivitiesCodes(params.selectedCodes)
  });

  return (
    <Chart
      loading={isFetching}
      type="bar"
      title={translate('activity-codes-count')}
      data={
        data?.activityCodesCount.map(({ count, codes }) => ({
          label: codes.join(', '),
          value: count
        })) ?? []
      }
    />
  );
};

export default {
  Configurator,
  Renderer
};
