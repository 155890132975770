/* eslint-disable no-shadow, no-unused-vars */
enum Role {
  Doctor = 'doctor',
  Agent = 'agent',
  Admin = 'admin',
  Auditor = 'auditor',
  Developer = 'developer'
}

export default Role;
