import React, { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import DataTable from 'application/components/data-table';
import useTranslate from 'application/hooks/use-translate';
import { useNavigate } from 'react-router-dom';
import { GROUPS_ROUTE, GROUP_USERS, USERS_ROUTE, USER_PROFILE } from 'application/routes';
import moment from 'moment';
import { GroupType } from 'application/types/groups';
import UserAccountStatus from 'shared/domain/user/profile/UserAccountStatus';
import UserStatusBadge from 'application/components/user-status-badge';
import DownloadButton from 'application/components/download-button';
import ReportService from 'application/services/report-service';

const RECENT_USERS_QUERY = gql`
  query RecentUsers {
    usersResult(limit: 10, sortKey: "createdAt", sortDirection: DESC) {
      count
      users {
        id
        fullName
        practiceNumber
        createdAt
        groups {
          id
          name
          number
          type
        }
        status
        facilities {
          id
          priority
          number
          name
        }
      }
    }
  }
`;

interface Facility {
  id: string;
  priority: number;
  number: string;
  name: string;
}

interface RecentUser {
  id: string;
  fullName: string;
  practiceNumber: string;
  createdAt: string;
  groups: Array<{
    id: string;
    name: string;
    number: string;
    type: GroupType;
  }>;
  status: UserAccountStatus;
  facilities: Facility[];
}

interface RecentUsers {
  usersResult: {
    count: number;
    users: RecentUser[];
  };
}

const RecentUsers = () => {
  const translate = useTranslate('pages.administration.stats.recent-users');
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);

  const { data: recentUsersData, loading } = useQuery<RecentUsers>(RECENT_USERS_QUERY);

  const usersFavoriteFacilityMap = useMemo(() => {
    const userFavoriteFacilityMap = new Map<string, Facility>();

    recentUsersData?.usersResult.users.forEach((user) => {
      user.facilities.forEach((facility) => {
        if (
          !userFavoriteFacilityMap.has(user.id) ||
          userFavoriteFacilityMap.get(user.id)!.priority > facility.priority
        ) {
          userFavoriteFacilityMap.set(user.id, facility);
        }
      });
    });
    return userFavoriteFacilityMap;
  }, [recentUsersData]);

  if (loading) {
    return null;
  }

  const fetchFile = () => {
    setIsDownloading(true);

    return ReportService.getRecentUsersReport('pdf').finally(() => setIsDownloading(false));
  };

  const exportFile = () => {
    setIsDownloading(true);

    return ReportService.getRecentUsersReport('csv').finally(() => setIsDownloading(false));
  };

  return (
    <DataTable
      title={translate('title')}
      count={recentUsersData?.usersResult.count ?? 0}
      headerComponent={
        <DownloadButton
          downloading={isDownloading}
          modes={['pdf', 'csv']}
          onClick={(mode) => {
            if (mode === 'pdf') {
              return fetchFile();
            }

            if (mode === 'csv') {
              return exportFile();
            }
          }}
        />
      }
      columns={[
        { title: translate('name'), width: 'auto' },
        { title: translate('groups'), width: 'auto' },
        { title: translate('principal-facility'), width: 'auto' },
        { title: translate('status'), width: 'auto' },
        { title: translate('registration-date'), width: 'auto' }
      ]}
    >
      {recentUsersData?.usersResult.users.map((user) => {
        const favoriteFacility = usersFavoriteFacilityMap.get(user.id);

        return (
          <tr key={user.id} className="hover:bg-base-300">
            <td className="link-hover link" onClick={() => navigate(`/${USERS_ROUTE}/${user.id}/${USER_PROFILE}`)}>
              <div className="flex flex-row gap-2">
                <div className="badge badge-ghost ml-2">{user.practiceNumber}</div>
                {user.fullName}
              </div>
            </td>
            <td>
              <div className="flex flex-col gap-2">
                {user.groups.map((group) => (
                  <div
                    className="link-hover link flex flex-row"
                    onClick={() => navigate(`/${GROUPS_ROUTE}/${group.id}/${GROUP_USERS}`)}
                  >
                    <div key={group.name}>{group.name}</div>
                    {group.type === GroupType.POOL && (
                      <div key={group.number} className="badge badge-ghost ml-2">
                        {group.number}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </td>
            <td>
              {favoriteFacility && (
                <div className="flex flex-row gap-2">
                  <div key={favoriteFacility.number} className="badge badge-ghost ml-2">
                    {favoriteFacility.number}
                  </div>
                  <div key={favoriteFacility.name}>{favoriteFacility.name}</div>
                </div>
              )}
            </td>
            <td>
              <UserStatusBadge status={user.status} />
            </td>
            <td>{moment(user.createdAt).format('YYYY-MM-DD')}</td>
          </tr>
        );
      })}
    </DataTable>
  );
};

export default RecentUsers;
