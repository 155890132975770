import classNames from 'classnames';
import React from 'react';

interface SectionProps {
  helper?: string;
  label?: string;
  children: React.ReactNode;
}

interface FooterProps {
  justify?: 'left' | 'center' | 'right' | 'between';
  children: React.ReactNode;
}

const Section = ({ helper, label, children }: SectionProps) => (
  <div className="inline-flex w-full flex-col items-start justify-start overflow-hidden rounded border border-gray-200 bg-white shadow">
    {(helper || label) && (
      <div className="w-full border-b border-gray-200 bg-neutral-100 p-4 text-neutral-900">
        {label}

        {helper && <span className="block text-xs font-normal text-gray-500">{helper}</span>}
      </div>
    )}

    {children}
  </div>
);

const Footer = ({ justify = 'right', children }: FooterProps) => (
  <div
    className={classNames('flex w-full gap-4 p-4', {
      'justify-center': justify === 'center',
      'justify-end': justify === 'right',
      'justify-between': justify === 'between'
    })}
  >
    {children}
  </div>
);

export default Object.assign(Section, { Footer });
