import { set } from 'lodash';
import TimeSyncedWithActStartTime from 'app/components/Form/ActSpecificField/TimeSyncedWithActStartTime/TimeSyncedWithActStartTime';
import { formatUnixToTimeValue } from '../../../../shared/utils/dateTime/dateTimeUtils';
import {
  END_TIME_WHEN_DURATION_OVER_60_MINUTES,
  isLessThan60MinsDifferenceWithActStartDate
} from '../../../../shared/ramq/requiredDetails/optionalEndTime';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';

const LESS_THAN_60_MINUTES_ERROR = 'Min 60 minutes de diff. avec début';
const lessThan60MinutesError = (index) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${END_TIME_WHEN_DURATION_OVER_60_MINUTES}` },
    `codes[${index}].${END_TIME_WHEN_DURATION_OVER_60_MINUTES}`,
    LESS_THAN_60_MINUTES_ERROR
  );

const validateAtLeast60MinutesWithActStartTime = (act) => {
  const codesInError = act.codes.map((code, index) => {
    if (isLessThan60MinsDifferenceWithActStartDate(act.start, code)) {
      return lessThan60MinutesError(index);
    }

    return {};
  });

  return codesInError.reduce(combineErrors, {});
};

const summaryRender = (code) =>
  code[END_TIME_WHEN_DURATION_OVER_60_MINUTES]
    ? [
        {
          name: 'Heure fin si durée > 60 min',
          value: formatUnixToTimeValue(code[END_TIME_WHEN_DURATION_OVER_60_MINUTES])
        }
      ]
    : [];

const associatedFields = [
  {
    label: 'Heure fin si durée > 60 min',
    getName: (index) => `codes[${index}].${END_TIME_WHEN_DURATION_OVER_60_MINUTES}`,
    type: TimeSyncedWithActStartTime,
    notRequired: true
  }
];

const associatedValidations = [validateAtLeast60MinutesWithActStartTime];

const endTimeIfMoreThan60Min = { associatedFields, associatedValidations, summaryRender };
export default endTimeIfMoreThan60Min;
