export interface Props {
  totalCount: number;
  pageSize: number;
  pageNumber: number;
  // eslint-disable-next-line no-unused-vars
  onChangePage: (page: number) => void;
}

export interface PaginationData {
  goToNextPage?: () => void;
  goToPreviousPage?: () => void;
  // eslint-disable-next-line no-unused-vars
  goToPage: (page: number) => void;
  pageNumber: number;
  numberOfPages: number;
}

const usePagination = ({ totalCount, pageSize, pageNumber, onChangePage }: Props): PaginationData => {
  const numberOfPages = Math.ceil(totalCount / pageSize);

  const currentPage = parseInt(pageNumber.toString(), 10);

  const goToNextPage = () => {
    if (currentPage < numberOfPages) onChangePage(currentPage + 1);
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) onChangePage(currentPage - 1);
  };
  const goToPage = (page: number) => {
    if (page >= 1 && page <= numberOfPages) {
      onChangePage(page);
    }
  };

  return {
    goToNextPage: pageNumber !== numberOfPages ? goToNextPage : undefined,
    goToPreviousPage: pageNumber !== 1 ? goToPreviousPage : undefined,
    goToPage,
    pageNumber,
    numberOfPages
  };
};

export default usePagination;
