import moment, { Moment } from 'moment-timezone';
import { Mixte } from '../activity/mixte/model/Mixte';
import LumpSum from '../activity/lumpSum/model/LumpSum';
import { getWeekBasedEndDateFromDate } from '../../periods/periods';
import ISO_WEEKDAY from '../../utils/weekDay';

function canPeriodBeBilled(period: Mixte | LumpSum, billingDate: Moment = moment()): boolean {
  const endOfPeriod = getWeekBasedEndDateFromDate(period.startDate, period.duration);
  const lastFriday = moment(endOfPeriod).isoWeekday(ISO_WEEKDAY.FRIDAY).startOf('day');

  return billingDate.isSameOrAfter(lastFriday);
}

export default canPeriodBeBilled;
