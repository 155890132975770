import { DaisyButtonVariant } from 'application/types/daisy';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useClickAway } from 'react-use';

export interface ModalAction {
  label: string;
  onClick: () => void;
  variant: DaisyButtonVariant;
}

interface Props {
  actions?: ModalAction[];
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  open?: boolean;
  title: string;
}

const Modal = ({ actions, children, className, onClose, open = false, title }: Props) => {
  const ref = useRef(null);

  useClickAway(ref, () => {
    if (!onClose) {
      return;
    }
    onClose();
  });

  return (
    <div className={classNames('modal z-[1203]', className, { 'modal-open': open })}>
      <div ref={ref} className="modal-box">
        <h3 className="text-lg font-bold">{title}</h3>
        <p className="py-4">{children}</p>
        <div className="modal-action flex justify-end">
          {actions?.map((action) => (
            <button
              key={action.label}
              type="button"
              className={classNames('btn', `btn-${action.variant}`)}
              onClick={action.onClick}
            >
              {action.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
