import React from 'react';
import PageContainer from 'application/components/page-container';
import useTranslate from 'application/hooks/use-translate';
import { Navigate, Route, Routes } from 'react-router-dom';
import { COMMUNICATIONS_ROUTE, PRESETS_ROUTE, STATS_ROUTE } from 'application/routes';
import PresetsPage from 'application/pages/administration/presets';
import CommunicationsPage from 'application/pages/administration/communications';
import StatisticsPage from 'application/pages/administration/statistics';
import Tabs from 'application/components/tabs';

const AdministrationIndex: React.FunctionComponent = () => {
  const translate = useTranslate('pages.administration');

  return (
    <PageContainer>
      <Tabs
        tabs={[
          { destination: STATS_ROUTE, label: translate('tab.stats') },
          { destination: COMMUNICATIONS_ROUTE, label: translate('tab.communications') },
          { destination: PRESETS_ROUTE, label: translate('tab.presets') }
        ]}
      />

      <Routes>
        <Route path={STATS_ROUTE} element={<StatisticsPage />} />
        <Route path={COMMUNICATIONS_ROUTE} element={<CommunicationsPage />} />
        <Route path={PRESETS_ROUTE} element={<PresetsPage />} />
        <Route index element={<Navigate to={STATS_ROUTE} />} />
      </Routes>
    </PageContainer>
  );
};

export default AdministrationIndex;
