import moment from 'moment-timezone';
import { RAMQPlaces } from '../../../ramq/RamqPlaces';
import Place from './Place';

/* eslint-disable no-shadow, no-unused-vars */
export enum WeekDay {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
}

class PlaceDomain {
  private placeModel: Place;

  static fromDto(placeModel: Place) {
    return new PlaceDomain(placeModel);
  }

  private constructor(placeModel: Place) {
    this.placeModel = placeModel;
  }

  getNumber(): string {
    return this.placeModel.number;
  }

  isCms(): boolean {
    return RAMQPlaces.isCms(this.getNumber());
  }

  isWeekEndOrHoliday(date: number): boolean {
    return this.isHoliday(date) || this.isWeekEnd(date);
  }

  isBilledByAct(): boolean {
    return !!this.placeModel.billByAct;
  }

  isSameAnnexe19Percentage(percentage: number | undefined): boolean {
    if (this.placeModel.annexe19 === undefined || this.placeModel.annexe19 === 1) {
      return percentage === undefined || percentage === 100;
    }

    return Math.round(this.placeModel.annexe19 * 100) === percentage;
  }

  private isHoliday = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return this.placeModel.daysOff && this.placeModel.daysOff.includes(formattedDate);
  };

  private isWeekEnd(date) {
    return [WeekDay.SATURDAY, WeekDay.SUNDAY].includes(moment(date).day());
  }
}

export default PlaceDomain;
