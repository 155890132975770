import Act from '../../../shared/domain/activity/act/model/Act';

export const NO_RECOMMENDATIONS = [];

type Recommendation<T> = {
  message: string;
  details?: string[];
  // eslint-disable-next-line no-unused-vars
  apply: (act: Partial<Act>) => FormChanges<T>;
};

export type FormChanges<T> = {
  fieldName: string;
  oldValue: T;
  newValue: T;
};

export default Recommendation;
