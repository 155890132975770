import { Mixte, MixteDay } from '../../../../shared/domain/activity/mixte/model/Mixte';

export type LegacyMixtePeriodFormDto = Mixte;

export type LatestMixtePeriodFormDto = Mixte & {
  days: LatestMixteDayPeriodFormDto[] | MixteDay[];
};

export type LatestMixteDayPeriodFormDto = {
  date: number;
  manualCorrection?: string;
  manualEditionMode?: boolean;
  perdiems?: string[];
  codes: MixteDayCode[];
};

export type MixteDayCode = {
  code?: string;
  activityArea: string;
  duration: number;
};

export function isLatestMixteDay(
  mixteDay: LatestMixteDayPeriodFormDto | MixteDay
): mixteDay is LatestMixteDayPeriodFormDto {
  return 'codes' in mixteDay;
}
