import axios, { HttpStatusCode } from 'axios';
import firebase from 'firebase/compat/app';

interface Request {
  endpoint: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  authenticated?: boolean;
  body?: object;
  headers?: Record<string, string>;
}

export default class RalphApi {
  public static async sendRequest(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    authenticateRequest: boolean = true,
    payload?: unknown,
    recaptchaToken?: string
  ): Promise<Response> {
    const url = `${process.env.REACT_APP_RALPH_API_BASE_URL}/${endpoint}`;
    const requestConfig = { method, headers: {} };

    if (authenticateRequest) {
      const userIdToken = await firebase.auth().currentUser!.getIdToken();
      const headers = {
        'Authorization': `Bearer ${userIdToken}`,
        'Content-Type': 'application/json'
      };

      Object.assign(requestConfig, { headers });
    }

    if (payload) {
      Object.assign(requestConfig, { data: payload });
    }

    if (recaptchaToken) {
      Object.assign(requestConfig, { headers: { ...requestConfig.headers, ...{ 'recaptcha-token': recaptchaToken } } });
    }

    const response = await fetch(url, requestConfig);

    if (response.status >= HttpStatusCode.BadRequest) {
      throw new Error(response.statusText);
    }

    return response;
  }

  public static async request<T>({ endpoint, method = 'GET', authenticated = true, headers = {}, body }: Request) {
    let userIdToken;

    if (authenticated) {
      userIdToken = await firebase.auth().currentUser!.getIdToken();
    }

    const response = await axios<T>({
      url: `${process.env.REACT_APP_RALPH_API_BASE_URL}/${endpoint}`,
      method,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...(userIdToken ? { Authorization: `Bearer ${userIdToken}` } : {}),
        ...headers
      },
      data: body
    });

    return response;
  }
}
