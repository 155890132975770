import PropTypes from 'prop-types';
import React from 'react';
import Portal from '@material-ui/core/Portal';
import FormHelperText from '@material-ui/core/FormHelperText';
import useTranslate from 'application/hooks/use-translate';
import ActivityType from '../../../../../../../shared/core/activity/domain/ActivityType';
import LumpSumCodeList from './PeriodCodeSelector/LumpSumCodeList';
import MixteCodeList from './PeriodCodeSelector/MixteCodeList';
import PeriodCodeSelector from './PeriodCodeSelector/PeriodCodeSelector';
import LumpSumSelector from '../../../../../../favorite/lumpSumCode/ui/LumpSumSelector';

function DynamicPeriodCodesSelection({ input, meta, activityType, errorContainer }) {
  const translate = useTranslate('lump-sum');

  if (input.value === 'xxx171') {
    Object.assign(meta, {
      warning: translate('xxx171-warning')
    });
  }

  return (
    <>
      {activityType === ActivityType.LUMP_SUM && (
        <PeriodCodeSelector
          input={input}
          dialogComponent={LumpSumSelector}
          dialogProps={{
            title: 'Codes Forfaitaire',
            children: LumpSumCodeList
          }}
        />
      )}

      {activityType === ActivityType.MIXTE && (
        <PeriodCodeSelector
          input={input}
          dialogProps={{
            title: 'Codes Mixte',
            children: MixteCodeList
          }}
        />
      )}
      {(meta.error || meta.warning) && (
        <Portal container={errorContainer}>
          <FormHelperText error style={{ textAlign: 'center', margin: '0 0 1em 0' }}>
            {meta.error || meta.warning}
          </FormHelperText>
        </Portal>
      )}
    </>
  );
}

DynamicPeriodCodesSelection.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  errorContainer: PropTypes.object,
  activityType: PropTypes.oneOf([ActivityType.MIXTE, ActivityType.LUMP_SUM]).isRequired
};

DynamicPeriodCodesSelection.defaultProps = {
  errorContainer: null
};

export default DynamicPeriodCodesSelection;
