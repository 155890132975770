import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { DEFERRED_PAYMENT_ERROR_TYPE } from '../../../../../../../shared/ramq/constants';
import Collapser from '../../../../../Collapser/Collapser';
import CollapserContent from '../../../../../Collapser/CollapserContent';
import CollapserHeader from '../../../../../Collapser/CollapserHeader';
import DeferredPaymentErrorDetails from '../../../ActivityResponse/DeferredPaymentErrorDetails/DeferredPaymentErrorDetails';
import DefaultFailureComponent from '../../../ActivityResponse/DefaultFailureComponent/DefaultFailureComponent';
import DateSummary from '../../../ActivityResponse/DateSummary/DateSummary';
import RamqInfoIcon from '../../../RamqInfoIcon/RamqInfoIcon';

const styles = (theme) => ({
  header: {
    backgroundColor: '#FADC65',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  messages: {
    padding: theme.spacing(1)
  },
  billInfo: {
    alignSelf: 'flex-start',
    padding: 0,
    paddingLeft: theme.spacing(2)
  },
  absoluteThis: {
    position: 'absolute'
  }
});

const responseTypeComponents = {
  [DEFERRED_PAYMENT_ERROR_TYPE]: DeferredPaymentErrorDetails
};

const getBillInformationAccordingToResponseType = (ramqResponse, classes) => {
  if (ramqResponse.type === DEFERRED_PAYMENT_ERROR_TYPE) {
    return (
      <ListItemText
        className={`${classes.billInfo} ${classes.absoluteThis}`}
        primary={`# Attestation ${ramqResponse.attestationNumber}`}
      />
    );
  }
  return null;
};

function ActResponseFailure({ ramqResponse, initiallyOpen, classes }) {
  const ResponseContentComponent = responseTypeComponents[ramqResponse.type] || DefaultFailureComponent;

  return (
    <Collapser initiallyOpen={initiallyOpen}>
      <CollapserHeader>
        <RamqInfoIcon ramqLogId={ramqResponse.ramqLogId} />
        <ListItem button className={classes.header}>
          {getBillInformationAccordingToResponseType(ramqResponse, classes)}
          <DateSummary date={ramqResponse.createdOn} />
          <ListItemSecondaryAction>
            <Typography>Échec</Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </CollapserHeader>
      <CollapserContent>
        <ResponseContentComponent ramqResponse={ramqResponse} classes={classes} />
      </CollapserContent>
    </Collapser>
  );
}

ActResponseFailure.propTypes = {
  ramqResponse: PropTypes.object.isRequired,
  initiallyOpen: PropTypes.bool.isRequired
};

export default withStyles(styles)(ActResponseFailure);
