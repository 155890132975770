import MomentUtils from '@date-io/moment';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import FormHelperText from '@material-ui/core/FormHelperText';
import enResources from '../../../translations/en.json';

export const styles = () => ({
  calendarContainer: {
    margin: '30px 0'
  },
  startDatePicker: {
    marginRight: '10px'
  },
  endDatePicker: {
    marginLeft: '10px'
  }
});

const START_DATE_POSTERIOR_TO_END_DATE_MESSAGE_KEY = 'CalendarRangePicker.startDatePosteriorToEndDate';
const END_DATE_ANTERIOR_TO_START_DATE_MESSAGE_KEY = 'CalendarRangePicker.endDateAnteriorToStartDate';

export class CalendarRangePicker extends React.PureComponent {
  constructor(props, context) {
    super(props, context);

    const { startDate, endDate } = this.props;

    if (moment(startDate).isAfter(moment(endDate)))
      throw new Error(enResources[START_DATE_POSTERIOR_TO_END_DATE_MESSAGE_KEY]);

    this.state = {
      errorKey: null
    };
  }

  handleOnChangeStartDate = (value) => {
    if (value.isAfter(moment(this.props.endDate))) {
      this.setState({ errorKey: START_DATE_POSTERIOR_TO_END_DATE_MESSAGE_KEY });
    } else {
      this.props.onChangeRange(value.toDate(), this.props.endDate);
    }
  };

  handleOnChangeEndDate = (value) => {
    if (value.isBefore(moment(this.props.startDate))) {
      this.setState({ errorKey: END_DATE_ANTERIOR_TO_START_DATE_MESSAGE_KEY });
    } else {
      this.props.onChangeRange(this.props.startDate, value.toDate());
    }
  };

  render() {
    return (
      <div className={this.props.classes.calendarContainer}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <DatePicker
            label={<FormattedMessage id="CalendarRangePicker.startDate" />}
            className={this.props.classes.startDatePicker}
            type="date"
            value={this.props.startDate}
            onChange={(value) => this.handleOnChangeStartDate(value)}
            format="YYYY-MM-DD"
            leftArrowIcon={<PrevArrowIcon />}
            rightArrowIcon={<NextArrowIcon />}
          />
          <DatePicker
            label={<FormattedMessage id="CalendarRangePicker.endDate" />}
            className={this.props.classes.endDatePicker}
            type="date"
            value={this.props.endDate}
            onChange={(value) => this.handleOnChangeEndDate(value)}
            format="YYYY-MM-DD"
            leftArrowIcon={<PrevArrowIcon />}
            rightArrowIcon={<NextArrowIcon />}
          />
        </MuiPickersUtilsProvider>
        {this.state.errorKey && (
          <FormHelperText className="error-message" error>
            <FormattedMessage id={this.state.errorKey} />
          </FormHelperText>
        )}
      </div>
    );
  }
}

CalendarRangePicker.propTypes = {
  onChangeRange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
};

export default withStyles(styles)(CalendarRangePicker);
