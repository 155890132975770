import { MULTI_ACT_CREATE_ACTS, MULTI_ACT_CREATE_ACTS_ERROR, MULTI_ACT_CREATE_ACTS_START } from './constants';

export function createActsFromPatients({ patients, ...actProps }) {
  return {
    type: MULTI_ACT_CREATE_ACTS,
    patients,
    actProps,
    closeAfter: true
  };
}

export function createActsFromPatientsAndCreateMore({ patients, ...actProps }) {
  return {
    type: MULTI_ACT_CREATE_ACTS,
    patients,
    actProps,
    closeAfter: false
  };
}

export function createActsFromNamsStart() {
  return {
    type: MULTI_ACT_CREATE_ACTS_START
  };
}

export function createActsFromNamsError() {
  return {
    type: MULTI_ACT_CREATE_ACTS_ERROR
  };
}
