import React from 'react';

interface Props {
  children: React.ReactNode;
}

const PageContainer = ({ children }: Props) => (
  <div className="absolute block min-h-screen w-full bg-base-200 p-8">{children}</div>
);

export default PageContainer;
