import { clone, difference, keyBy, keys, omit, values, groupBy } from 'lodash';

export function transformToCategorizableCollection(favoritesActCodes) {
  const { items, categoriesObject } = splitCollectionByType(favoritesActCodes);
  const itemMap = keyBy(items, (item) => item.id);

  return {
    items: itemMap,
    ...categoriesObject,
    itemsWithoutCategory: getItemsWithoutCategory(itemMap, categoriesObject)
  };
}

export function transformToCategorizableTree(favoritesActCodes) {
  const { items, categoriesObject } = splitCollectionByType(favoritesActCodes);
  const itemMap = keyBy(items, (item) => item.id);

  const itemsWithoutCategory = getItemsWithoutCategory(itemMap, categoriesObject)
    .itemIds.filter((item) => itemMap[item])
    .map((itemId) => itemMap[itemId]);

  const categories = keys(categoriesObject.categories)
    .sort()
    .map((categoryKey) => injectItemInCategories(categoriesObject.categories[categoryKey], itemMap))
    .filter((category) => category);

  return {
    itemsWithoutCategory,
    categories
  };
}

export function transformToFirebaseDocumentsMap(categorizableCollection) {
  const firebaseDocumentMap = categorizableCollection.items;

  firebaseDocumentMap[categorizableCollection.id] = omit(categorizableCollection, ['items', 'id']);

  return firebaseDocumentMap;
}

export function transformToFirebaseDocumentsCollection(categorizableCollection) {
  return values(transformToFirebaseDocumentsMap(categorizableCollection));
}

function splitCollectionByType(favoritesActCodes) {
  const groups = groupBy(favoritesActCodes, (favoritesActCode) =>
    favoritesActCode.type === 'categories' ? 'categoryNodesGroup' : 'elementNodesGroup'
  );

  const items = groups.elementNodesGroup || [];
  const categoriesObject = groups.categoryNodesGroup
    ? groups.categoryNodesGroup[0]
    : { categories: {}, itemsWithoutCategory: { itemIds: [] }, type: 'categories', id: 'categories' };

  return {
    items,
    categoriesObject
  };
}

function injectItemInCategories(category, itemMap) {
  const categoryClone = clone(category);

  const categoryHasItems = categoryClone.itemIds && categoryClone.itemIds.length > 0;

  categoryClone.items = categoryClone.itemIds.filter((item) => itemMap[item]).map((itemId) => itemMap[itemId]);
  delete categoryClone.itemIds;

  const categoryStillHasItems = categoryClone.items.length > 0;

  if (categoryHasItems !== categoryStillHasItems) {
    return null;
  }

  if (categoryClone.subCategories) {
    categoryClone.subCategories = values(categoryClone.subCategories).map((subCategory) =>
      injectItemInCategories(subCategory, itemMap)
    );
  }

  return categoryClone;
}

function getItemsWithoutCategory(itemMap, categoriesObject) {
  const itemIds = keys(itemMap);

  let itemIdsOnCategories = [];
  values(categoriesObject.categories).forEach((category) => {
    itemIdsOnCategories = itemIdsOnCategories.concat(getNestedCategoryItemIds(category));
  });

  return {
    itemIds: categoriesObject.itemsWithoutCategory.itemIds.concat(
      difference(itemIds, itemIdsOnCategories, categoriesObject.itemsWithoutCategory.itemIds)
    )
  };
}

function getNestedCategoryItemIds(category) {
  let itemIds = [];

  if (category.itemIds) {
    itemIds = itemIds.concat(category.itemIds);
  }

  if (category.subCategories) {
    values(category.subCategories).forEach((subCategory) => {
      itemIds = itemIds.concat(getNestedCategoryItemIds(subCategory));
    });
  }

  return itemIds;
}
