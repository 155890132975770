import { SubmissionError } from 'redux-form';
import isDefined from '../../../../../../shared/utils/isDefined';
import { isValidNam } from '../../../../../validations/act/nam/namValidation';

function validatePatients(patients) {
  const errors = {};

  if (patients.every(({ nam = '' }) => nam.trim() === '')) {
    errors.patients = {
      _error: 'Vous devez entrer au moins un NAM'
    };
  } else {
    patients.forEach(({ nam = '' }, index) => {
      if (nam.trim() !== '' && !isValidNam(nam)) {
        errors.patients = errors.patients || {};

        errors.patients = {
          [index]: 'Ce NAM est invalide'
        };
      }
    });
  }

  return errors;
}

const validateStartAndInterval = (form) => {
  if (!isDefined(form.start)) {
    return { start: 'Ce champ est requis' };
  }

  return {};
};

const validateForm = (successCallback) => (values) => {
  const jsonFormValues = values;

  const errors = {
    ...validatePatients(jsonFormValues.patients),
    ...validateStartAndInterval(jsonFormValues)
  };

  if (Object.keys(errors).length > 0) {
    throw new SubmissionError(errors);
  }

  successCallback({
    ...jsonFormValues,
    patients: jsonFormValues.patients.filter(({ nam = '' }) => isValidNam(nam))
  });
};

export default validateForm;
