/* eslint-disable react/no-array-index-key */

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

export function StatementSection({ primaryLine, secondaryLines }) {
  return (
    <ListItem>
      <ListItemText
        primary={primaryLine}
        secondary={
          <>
            {secondaryLines.map((line, index) => (
              <Typography key={index} variant="caption">
                {line}
              </Typography>
            ))}
          </>
        }
      />
    </ListItem>
  );
}

StatementSection.propTypes = {
  primaryLine: PropTypes.node.isRequired,
  secondaryLines: PropTypes.array.isRequired
};

export default StatementSection;
