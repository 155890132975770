import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import DiagnosticCodeFavoritesSelector from '../DiagnosticCodeFavoritesSelector/DiagnosticCodeFavoritesSelector';
import DiagnosticCodeFavoritesEditor from '../DiagnosticCodeFavoritesEditor/DiagnosticCodeFavoritesEditor';
import { selectDiagnosticCodePreferencesFromUser } from '../../../../containers/User/selectors';

export class DiagnosticCodeFavoritesTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      advancedEditingMode: false
    };
  }

  render() {
    const { favoritesDiagnosticCodes, diagnosticCodesWithMarkup, onItemSelected, onClose } = this.props;
    const { advancedEditingMode } = this.state;

    if (advancedEditingMode) {
      return (
        <DiagnosticCodeFavoritesEditor
          favoritesDiagnosticCodes={favoritesDiagnosticCodes}
          onCancel={() => this.setState({ advancedEditingMode: false })}
        />
      );
    }

    return (
      <DiagnosticCodeFavoritesSelector
        favoritesDiagnosticCodes={favoritesDiagnosticCodes}
        diagnosticCodesWithMarkup={diagnosticCodesWithMarkup}
        onClose={onClose}
        onAdvancedMode={() => this.setState({ advancedEditingMode: true })}
        onSelectDiagnostic={onItemSelected}
      />
    );
  }
}

DiagnosticCodeFavoritesTab.propTypes = {
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  diagnosticCodesWithMarkup: PropTypes.array.isRequired,
  favoritesDiagnosticCodes: PropTypes.array.isRequired
};

export const mapStateToProps = createStructuredSelector({
  favoritesDiagnosticCodes: selectDiagnosticCodePreferencesFromUser()
});

export default connect(mapStateToProps)(DiagnosticCodeFavoritesTab);
