import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { tryLogin, mfaLogin, mfaCancel } from '../../../containers/Authentication/actions';
import { selectLoginInfo } from '../../../containers/Authentication/selectors';
import { FORGOT_PASSWORD_ROUTE } from '../../../containers/Navigation/constants';
import LoadingButton from '../../button/LoadingButton/LoadingButton';
import LoginFormWrapper from '../LoginFormWrapper/LoginFormWrapper';

export const LOGIN_CONTAINER_ID = 'medyx-login';

export const styles = (theme) => ({
  spacedBottom: {
    marginBottom: theme.spacing(2)
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.red[500],
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

export class FirebaseLoginUI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameError: undefined,
      passwordError: undefined,
      mfaError: undefined,
      showPassword: false
    };
  }

  login = (event) => {
    event.preventDefault();
    const usernameError = !this.userNameInput.value ? "Veuillez entrer votre nom d'utilisateur" : undefined;
    const passwordError = !this.passwordInput.value ? 'Veuillez entrer votre mot de passe' : undefined;
    if (!usernameError && !passwordError) {
      this.props.tryLogin(this.userNameInput.value, this.passwordInput.value);
    }
    this.setState({ usernameError, passwordError });
  };

  mfaLogin = (event) => {
    event.preventDefault();
    const token = this.mfaTokenInput.value;
    if (token) {
      this.props.mfaLogin(token);
    }
  };

  togglePasswordVisibility = (showPassword) => {
    this.setState({ showPassword: !showPassword });
  };

  render() {
    const { classes, loginInfo } = this.props;
    const { usernameError, passwordError, mfaError, showPassword } = this.state;

    const submitAction = loginInfo.pendingMfa ? this.mfaLogin : this.login;

    return (
      <LoginFormWrapper id={LOGIN_CONTAINER_ID} onSubmit={submitAction}>
        {loginInfo.error && (
          <div className={classes.errorBox}>
            <Typography color="inherit">Authentification impossible</Typography>
          </div>
        )}

        {loginInfo.pendingMfa ? (
          <TextField
            id="login-mfa"
            error={mfaError !== undefined}
            label="Code de vérification"
            className={classes.spacedBottom}
            inputRef={(ref) => {
              this.mfaTokenInput = ref;
            }}
            helperText={mfaError}
          />
        ) : (
          <>
            <TextField
              id="login-username"
              autoComplete="username"
              error={usernameError !== undefined}
              label="Courriel"
              className={classes.spacedBottom}
              inputRef={(ref) => {
                this.userNameInput = ref;
              }}
              type="email"
              helperText={usernameError}
            />
            <TextField
              id="login-password"
              autoComplete="current-password"
              error={passwordError !== undefined}
              label="Mot de passe"
              className={classes.spacedBottom}
              type={showPassword ? 'text' : 'password'}
              inputRef={(ref) => {
                this.passwordInput = ref;
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.togglePasswordVisibility(showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              helperText={passwordError}
            />
          </>
        )}

        <Grid container className={classes.spacedBottom} spacing={2}>
          {loginInfo.pendingMfa && (
            <Grid item sm={6}>
              <Button variant="contained" fullWidth onClick={this.props.mfaCancel}>
                Retour
              </Button>
            </Grid>
          )}

          <Grid item sm={loginInfo.pendingMfa ? 6 : 12}>
            <LoadingButton
              fullWidth
              id="login-button"
              type="submit"
              label="Se connecter"
              loading={loginInfo.isLoginIn}
              variant="contained"
              color="primary"
              onClick={submitAction}
            />
          </Grid>
        </Grid>

        {!loginInfo.pendingMfa && (
          <Link className={classes.spacedBottom} to={`./${FORGOT_PASSWORD_ROUTE}`}>
            <Typography>Mot de passe oublié?</Typography>
          </Link>
        )}
      </LoginFormWrapper>
    );
  }
}

FirebaseLoginUI.propTypes = {
  tryLogin: PropTypes.func.isRequired,
  mfaLogin: PropTypes.func.isRequired,
  mfaCancel: PropTypes.func.isRequired,
  loginInfo: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  loginInfo: selectLoginInfo()
});

const mapDispatchToProps = {
  tryLogin,
  mfaLogin,
  mfaCancel
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(FirebaseLoginUI);
