export interface AdminNoteProps {
  date: number;
  author: string;
  content: string;
  type?: AdminNoteType;
}

/* eslint-disable no-shadow, no-unused-vars */
export enum AdminNoteType {
  DEFAULT = 'default',
  ZERO_DOLLARS_WARNING = 'zeroDollarsWarning'
}

export default AdminNoteProps;
