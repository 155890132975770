import some from 'lodash/some';
import { NatureType } from '../../../../shared/core/activity/domain/Act';
import { codeIsInRole2 } from '../../../../shared/domain/activity/act/service/actCodeUtils';
import CODE_NATURE_TYPES from '../../../../shared/ramq/domainValues/natureTypes';
import { RAMQCodes, TimedAttributeKey } from '../../../../shared/ramq/RamqCodes';
import { RAMQContextElements } from '../../../../shared/ramq/RamqContextElements';
import { selectSpecialty } from '../../../containers/User/selectors';
import { getStore } from '../../../reduxStore/configureStore';
import preSurgeryIntervention from '../../requiredDetails/PreSurgeryIntervention/preSurgeryIntervention';

const addPreSurgeryFieldSelectIfNeeded = (currentCode, codeList, act) => {
  const ramqCode = RAMQCodes.get(currentCode.code);
  const userSpecialty = selectSpecialty()(getStore().getState());
  const contextElement27 = RAMQContextElements.get('27');

  if (
    RAMQContextElements.isEffectiveOn(contextElement27, act.date) &&
    !ramqCode?.isSupplement &&
    ramqCode?.natureType === NatureType.PDT &&
    codeIsInRole2(currentCode, act, userSpecialty) &&
    some(codeList, (code) => surgeryCodeNotInA81(code, act.date))
  ) {
    return preSurgeryIntervention.associatedFields;
  }

  return [];
};

const surgeryCodeNotInA81 = ({ code, natureType }, date) => {
  const ramqCode = RAMQCodes.get(code);

  if (!ramqCode) {
    return false;
  }

  return (
    natureType === CODE_NATURE_TYPES.chirurgie &&
    !RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isAddendum8, date)
  );
};

export default addPreSurgeryFieldSelectIfNeeded;
