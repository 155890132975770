import { orderBy, values } from 'lodash';

export default class UserSpecialtyActCodeListService {
  constructor(repository) {
    this.repository = repository;
  }

  async getPresetSpecialtyList(userProfile) {
    let actCodesCollection;

    const { specialty = null } = userProfile;

    if (specialty) {
      const promise = this.repository.fetchSpecialtyList(specialty);
      actCodesCollection = await promise;
    } else {
      actCodesCollection = [];
    }

    return orderBy(values(actCodesCollection), ['order'], ['asc']);
  }

  async getFavoritesSpecialtyList(userProfile) {
    let actCodesCollection;

    const { actCodeSpecialtyListIncluded = false, actCodeSpecialtyListChoice = userProfile.specialty } = userProfile;

    if (actCodeSpecialtyListIncluded && actCodeSpecialtyListChoice) {
      actCodesCollection = await this.repository.fetchSpecialtyList(actCodeSpecialtyListChoice);
    } else {
      actCodesCollection = [];
    }

    return orderBy(values(actCodesCollection), ['order'], ['asc']);
  }
}
