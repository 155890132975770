import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@material-ui/core';
import DialogCenteredTitle from '../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import ActivityHistoryVersionWithDelta from '../ActivityHistoryVersionWithDelta/ActivityHistoryVersionWithDelta';
import useFetchActivityHistory from '../../../hooks/useFetchActivityHistory/useFetchActivityHistory';
import FullScreenLoading from '../../../../components/Loadings/FullScreenLoading/FullScreenLoading';

const recursiveFilter = (node, filter, type) =>
  Object.entries(node).some(([key, value]) => {
    if (type === 'key' && key.toLocaleLowerCase().includes(filter)) {
      return true;
    }

    if (!value) {
      return false;
    }

    if (typeof value === 'object') {
      return recursiveFilter(value, filter, type);
    }

    if (type === 'value' && String(value).toLocaleLowerCase().includes(filter)) {
      return true;
    }

    return false;
  });

function ActivityHistoryDialog({ activityId, open: dialogOpen, onClose }) {
  const { versionsWithDeltas, fetchActivityHistory, loading } = useFetchActivityHistory(activityId);

  const [keyFilter, setKeyFilter] = useState('');
  const [valueFilter, setValueFilter] = useState('');

  const filteredVersionsWithDeltas = useMemo(() => {
    if (!keyFilter && !valueFilter) {
      return versionsWithDeltas;
    }

    return versionsWithDeltas.filter((versionWithDelta) => {
      const keyMatch = keyFilter ? recursiveFilter(versionWithDelta.delta, keyFilter.toLocaleLowerCase(), 'key') : true;

      const valueMatch = valueFilter
        ? recursiveFilter(versionWithDelta.delta, valueFilter.toLocaleLowerCase(), 'value')
        : true;

      return keyMatch && valueMatch;
    });
  }, [keyFilter, valueFilter, versionsWithDeltas]);

  useEffect(() => {
    if (dialogOpen) {
      fetchActivityHistory();
    }
  }, [fetchActivityHistory, dialogOpen]);

  return (
    <Dialog open={dialogOpen} onClose={() => onClose()} maxWidth="lg" fullScreen>
      <DialogCenteredTitle label="Historique" />
      <DialogContent>
        {loading ? (
          <FullScreenLoading data-testid="loading-spinner" />
        ) : (
          <>
            <Grid container>
              <Grid item xs={6}>
                <Input placeholder="Key" fullWidth value={keyFilter} onChange={(e) => setKeyFilter(e.target.value)} />
              </Grid>
              <Grid item xs={6}>
                <Input
                  fullWidth
                  placeholder="Value"
                  value={valueFilter}
                  onChange={(e) => setValueFilter(e.target.value)}
                />
              </Grid>
            </Grid>

            {filteredVersionsWithDeltas.map(({ version, delta }) => (
              <ActivityHistoryVersionWithDelta
                key={version.auditInformation.modifiedOn}
                keyFilter={keyFilter}
                valueFilter={valueFilter}
                version={version}
                delta={delta}
              />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button data-testid="close-button" onClick={() => onClose()} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActivityHistoryDialog.propTypes = {
  activityId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ActivityHistoryDialog;
