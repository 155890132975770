import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { has } from 'lodash';
import { DEFAULT_DATE_FORMAT } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import IncomeStatementReport from '../IncomeStatementReport/IncomeStatementReport';

const statementDateIsWithinAYearAfter = (selectedDate) => (currentStatement) =>
  moment(currentStatement.statementDate, DEFAULT_DATE_FORMAT).isSameOrAfter(
    moment(selectedDate, DEFAULT_DATE_FORMAT)
  ) &&
  moment(currentStatement.statementDate, DEFAULT_DATE_FORMAT).isBefore(
    moment(selectedDate, DEFAULT_DATE_FORMAT).add(1, 'years')
  );

const groupStatementsByGroupNumber = (statements) => {
  const sectionGroups = new Map();
  // Set groupNumber 0 first, this will render personal section first since Map.forEach respects entry order
  sectionGroups.set(0, []);
  statements.forEach((stmt) => {
    // support legacy statement format without sections array
    // when migration is completed we can delete that condition
    let sectionsToDispatch = [];
    if (!has(stmt, 'sections')) {
      if (has(stmt, 'personal')) {
        const personalSection = stmt.personal;
        personalSection.type = 'personal';
        sectionsToDispatch.push(personalSection);
      }
      if (has(stmt, 'group')) {
        const groupSection = stmt.group;
        groupSection.type = 'group';
        sectionsToDispatch.push(groupSection);
      }
    } else {
      sectionsToDispatch = Object.values(stmt.sections);
    }

    dispatchStatementSectionsByGroup(sectionsToDispatch, sectionGroups);
  });

  if (sectionGroups.get(0).length === 0) {
    sectionGroups.delete(0);
  }

  return sectionGroups;
};

const dispatchStatementSectionsByGroup = (sections, sectionGroups) => {
  sections.forEach((currentSection) => {
    let parsedGroupNumber = parseInt(currentSection.generalInformation.professionalIdInfo.groupNumber, 0);
    if (Number.isNaN(parsedGroupNumber)) {
      parsedGroupNumber = currentSection.generalInformation.professionalIdInfo.groupNumber;
    }

    if (!sectionGroups.has(parsedGroupNumber)) {
      sectionGroups.set(parsedGroupNumber, []);
    }
    sectionGroups.get(parsedGroupNumber).push(currentSection);
  });
};

export class IncomeStatementRenderer extends React.Component {
  getStatementsInTheYearFollowingSelectedDate = () => {
    if (!this.props.selectedDate) {
      return { fullName: '', groupedSections: new Map() };
    }
    const selectedStatements = this.props.statements.filter(statementDateIsWithinAYearAfter(this.props.selectedDate));

    const groupedStatementsSections = groupStatementsByGroupNumber(selectedStatements);
    return {
      fullName: selectedStatements[0] && selectedStatements[0].fullName,
      groupedSections: groupedStatementsSections
    };
  };

  render() {
    return <IncomeStatementReport statements={this.getStatementsInTheYearFollowingSelectedDate()} />;
  }
}

IncomeStatementRenderer.defaultProps = {
  selectedDate: null
};

IncomeStatementRenderer.propTypes = {
  statements: PropTypes.array.isRequired,
  selectedDate: PropTypes.string
};

export default IncomeStatementRenderer;
