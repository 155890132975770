import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import DialogTrigger from '../../../Dialogs/DialogTrigger/DialogTrigger';
import { deleteValue, pushNewValue, updateValue } from '../../../utils/reduxFormArrayField';
import FormRow from '../../FormRow/FormRow';
import { IntensiveCareDiagnosticSelectField } from '../../Fields';
import DiagnosticCodesSearchDialog from '../../../../favorite/diagnosticCode/ui/DiagnosticCodesSearchDialog/DiagnosticCodeSearchDialog';

window.moment = moment;

export const styles = () => ({
  rowWithDeleteButton: {
    flexWrap: 'unset'
  }
});

export class MultiDiagnostic extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.input.value !== nextProps.input.value;
  }

  handleChange = (value, index) => {
    updateValue(this.props.input, value, index);
  };

  handleNewValue = (value) => {
    if (typeof value === 'object') {
      pushNewValue(this.props.input, value);
    }
  };

  handleRemoveDiagnostic = (index) => {
    if (index >= 0 && index < this.props.input.value.length) {
      deleteValue(this.props.input, index);
    }
  };

  render() {
    const { classes, input, dialogProps } = this.props;

    const diagnostics = Array.isArray(input.value) ? input.value : [input.value];

    return (
      <>
        {input.value &&
          diagnostics.map((diagnostic, index) => (
            <FormRow className={classes.rowWithDeleteButton} key={shortid.generate()}>
              <IntensiveCareDiagnosticSelectField
                label=""
                meta={{}}
                input={{
                  value: diagnostic,
                  onChange: (event) => this.handleChange(event, index)
                }}
              />
              <Button onClick={() => this.handleRemoveDiagnostic(index)}>
                <DeleteIcon />
              </Button>
            </FormRow>
          ))}
        <FormRow>
          {!input.value || !input.value.length || input.value.length < 3 ? (
            <DialogTrigger
              DialogComponent={DiagnosticCodesSearchDialog}
              onItemSelected={this.handleNewValue}
              dialogProps={dialogProps}
            >
              <Button style={{ margin: 'auto' }}>
                <AddIcon />
              </Button>
            </DialogTrigger>
          ) : null}
        </FormRow>
      </>
    );
  }
}

MultiDiagnostic.propTypes = {
  input: PropTypes.object.isRequired,
  dialogProps: PropTypes.object
};

MultiDiagnostic.defaultProps = {
  dialogProps: {}
};

export default withStyles(styles)(MultiDiagnostic);
