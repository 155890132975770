import Viewer from 'viewerjs';
import { IMAGE_VIEWER_ID } from './PlaceHolderImagePreview';

const createViewer = (options = {}) =>
  new Viewer(document.getElementById(IMAGE_VIEWER_ID), {
    navbar: false,
    title: false,
    inline: true,
    button: false,
    toolbar: {
      rotateLeft: {
        show: true,
        size: 'large'
      },
      rotateRight: {
        show: true,
        size: 'large'
      },
      zoomIn: {
        show: true,
        size: 'large'
      },
      zoomOut: {
        show: true,
        size: 'large'
      },
      oneToOne: {
        show: true,
        size: 'large'
      }
    },
    ...options
  });

export default createViewer;
