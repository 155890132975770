import CardActions from '@material-ui/core/CardActions';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import { PreviewXmlButton } from 'app/containers/UsersRoute/UserRoute/UserActivities/AdminActions/PreviewXmlButton';
import AdminAdminStatusSelector from '../../../components/Activities/AdminAdminStatusSelector/AdminAdminStatusSelector';
import AdminStatusSelector from '../../../components/Activities/AdminStatusSelector/AdminStatusSelector';
import { selectRelatedAsyncCall } from '../../AsyncTasks/selectors';
import { billPeriod } from '../../Ramq/actions';
import AdminNotePopup from '../../UsersRoute/UserRoute/UserActivities/AdminNotePopup/AdminNotePopup';
import { updatePeriod } from '../actions';
import canBillToRamq from '../../../validations/ramq/canBillToRamq';
import ButtonCheckbox from '../../../components/Form/Checkbox/ButtonCheckbox/ButtonCheckbox';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import DialogTrigger from '../../../components/Dialogs/DialogTrigger/DialogTrigger';
import ActivityHistoryDialog from '../../../adminConsole/ui/components/ActivityHistoryDialog/ActivityHistoryDialog';
import BillToRamqButton from '../BillToRamqButton/BillToRamqButton';
import createManualAdminNote from '../../../user/admin/createManualAdminNote';
import useCopyActivityUrl from 'application/hooks/use-copy-activity-url';

const isCallingRamq = (relatedAsyncCalls) =>
  relatedAsyncCalls.find((relatedAsyncCall) => relatedAsyncCall.loading) !== undefined;

export interface Props {
  period: any;
  // eslint-disable-next-line no-unused-vars
  onPeriodUpdate: (period: any) => void;
  onAddAdminNote: (adminNote: any) => void;
  onBillRamq: () => void;
  relatedAsyncCalls: any;
}

export function PeriodAdminActions({ period, onPeriodUpdate, onAddAdminNote, relatedAsyncCalls = [], onBillRamq }) {
  const copyActivityUrl = useCopyActivityUrl();

  return (
    <CardActions>
      <AdminStatusSelector onChange={onPeriodUpdate} entity={period} />
      <AdminAdminStatusSelector
        value={period.adminStatus}
        onChange={({ target }) => onPeriodUpdate({ adminStatus: target.value })}
      />
      <AdminNotePopup onSent={onAddAdminNote} />
      {canBillToRamq(period.type) && (
        <>
          <PreviewXmlButton activityId={period.id} type={period.type} />
          <BillToRamqButton period={period} isLoading={isCallingRamq(relatedAsyncCalls)} onClick={onBillRamq} />
        </>
      )}
      {[ActivityType.MIXTE, ActivityType.LUMP_SUM].includes(period.type) && (
        // @ts-ignore
        <ButtonCheckbox
          data-testid="block-status-button-checkbox"
          label="bloquer"
          checked={!!period.blocked}
          onClick={() => onPeriodUpdate({ blocked: !period.blocked })}
        />
      )}

      <DialogTrigger dialogProps={{ activityId: period.id }} DialogComponent={ActivityHistoryDialog}>
        <Button data-testid="period-history-button">Historique</Button>
      </DialogTrigger>

      <Button onClick={() => copyActivityUrl(period)}>Copier URL</Button>
    </CardActions>
  );
}

export const mapDispatchToProps = (dispatch, props) => ({
  onPeriodUpdate: (changes) => {
    dispatch(updatePeriod(props.period, changes));
  },
  onAddAdminNote: (adminNote) => {
    const adminNotes = props.period.adminNotes || [];
    adminNotes.push(createManualAdminNote(adminNote));
    dispatch(updatePeriod(props.period, { adminNotes }));
  },
  onBillRamq: () => {
    dispatch(billPeriod(props.period.id));
  }
});

export const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    relatedAsyncCalls: selectRelatedAsyncCall(ownProps.period.id)
  });

export default connect(mapStateToProps, mapDispatchToProps)(PeriodAdminActions);
