import React, { useEffect, useState } from 'react';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import classNames from 'classnames';
import useTranslate from 'application/hooks/use-translate';

interface Props extends ValidatedFieldProps {
  value: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const TextInput = ({
  value,
  type = 'text',
  placeholder,
  required = false,
  disabled = false,
  touched = false,
  customValidation,
  onChange,
  onBlur,
  onFocus,
  onValidate,
  onTouch
}: Props) => {
  const translate = useTranslate('components.form-controls.text-input');
  const [isFocused, setIsFocused] = useState(false);

  const isValid = customValidation !== undefined ? customValidation.isValid : !required || (required && !!value);

  useEffect(() => {
    if (!onValidate) return;
    onValidate(isValid);
  }, [isValid, onValidate]);

  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (onTouch) onTouch();
    onChange(value);
  };

  const handleOnBlur = () => {
    if (onTouch) onTouch();
    if (onBlur) onBlur();

    setIsFocused(false);
  };

  const handleOnFocus = () => {
    if (onFocus) onFocus();

    setIsFocused(true);
  };

  const errorIsVisible = !isFocused && !isValid && touched;

  return (
    <>
      <input
        type={type}
        className={classNames(
          'w-full rounded-lg border border-neutral-300 p-4 text-sm text-neutral-900 focus:border-primary-900 focus:outline-none',
          {
            'border-red-500 focus:border-red-500': errorIsVisible,
            'bg-slate-100 text-slate-400': disabled
          }
        )}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
      />

      {errorIsVisible && (
        <div className="mt-1 text-sm text-red-500" role="alert">
          {customValidation?.errorMessage ?? translate('required-error')}
        </div>
      )}
    </>
  );
};

export default TextInput;
