import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectPlacesTerritory } from '../../../containers/RamqPlaces/selectors';

export const styles = () => ({
  smallChip: {
    height: '18px',
    marginLeft: '1em',
    cursor: 'inherit'
  }
});

const isTerritoryGreaterThanAreaNo2 = (territory) => territory.replace(/\D/g, '') > 2;

export function TerritoryPlaceChip({ classes, territory }) {
  return territory ? (
    <Chip
      className={classes.smallChip}
      color={isTerritoryGreaterThanAreaNo2(territory) ? 'secondary' : 'default'}
      label={territory}
    />
  ) : null;
}

TerritoryPlaceChip.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  place: PropTypes.object.isRequired,
  territory: PropTypes.string
};

TerritoryPlaceChip.defaultProps = {
  territory: undefined
};

export const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    territory: selectPlacesTerritory(ownProps.place.number)
  });

export default compose(connect(mapStateToProps), withStyles(styles))(TerritoryPlaceChip);
