import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useTranslate from 'application/hooks/use-translate';
import Icon from 'application/components/icon';
import YearSelector from 'application/pages/groups/group/components/year-selector';
import LoadingState from 'application/pages/groups/group/components/reports/loading-state';
import NoDataAvailableState from 'application/pages/groups/group/components/reports/no-data-available-state';
import ReportService from 'application/services/report-service';
import HTMLReport from 'application/pages/groups/group/components/reports/html-report';
import DownloadButton from 'application/components/download-button';
import useToast from 'application/hooks/use-toast';
import downloadFile from 'application/utilities/download-file';

interface Props {
  groupId: string;
}

const PaymentRequestsByCodeTypeReport = ({ groupId }: Props) => {
  const translate = useTranslate('reports');
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ['group-statement-report', selectedYear],
    queryFn: () => {
      if (!selectedYear) {
        return Promise.reject();
      }

      return ReportService.fetchPaymentRequestsByCodeTypeReport(groupId, selectedYear);
    },
    enabled: !!selectedYear
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const { addToast } = useToast();
  const fetchFile = () => {
    if (!selectedYear) return;

    setIsDownloading(true);

    return ReportService.downloadPaymentRequestsByCodeTypeReport(groupId, selectedYear)
      .then((response) => {
        if (response.status === 404) {
          addToast({
            message: translate('no-data-available'),
            type: 'warning'
          });

          return;
        }

        if (!response.ok) {
          addToast({
            message: translate('error-downloading-report'),
            type: 'error'
          });

          return;
        }

        downloadFile(response);
      })
      .catch(() => {
        addToast({
          message: translate('error-downloading-report'),
          type: 'error'
        });
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <>
      <div className="absolute right-4 top-4 flex items-center print:hidden">
        <YearSelector onChange={setSelectedYear} value={selectedYear} />
        <DownloadButton disabled={!data || !isSuccess || isFetching} downloading={isDownloading} onClick={fetchFile} />
      </div>

      {isFetching && <LoadingState />}

      {data && isSuccess && !isFetching && <HTMLReport report={data} />}

      {!isFetching && !data && isSuccess && <NoDataAvailableState />}

      {!isFetching && !data && !isSuccess && (
        <div className="flex h-96 flex-col items-center justify-center gap-4 bg-base-200">
          <Icon icon="calendars" className="text-4xl text-gray-400" />

          <span className="w-64 text-center text-gray-400">{translate('select-year')}</span>
        </div>
      )}
    </>
  );
};

export default PaymentRequestsByCodeTypeReport;
