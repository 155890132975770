import React, { Ref } from 'react';
import { random } from 'lodash';
import moment from 'moment-timezone';
import * as queryString from 'qs';
import RalphApi from 'application/services/ralph-api';
import StatementsRenderer from '../../ReportsRoute/Statement/StatementRenderer/StatementRenderer';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';
import { perror } from '../../../../shared/utils/plog';
import FullScreenLoading from '../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import LoadableDataCollectionState from '../../../ui/shared/global/LoadableDataCollectionState';

interface StatementReportParameters {
  date: string;
  specialties: SpecialtyName[];
  groupIds?: string[];
  showSection20: boolean;
}

export interface Props {
  parameters: StatementReportParameters;
  printableAreaRef: Ref<any>;
}

interface State extends LoadableDataCollectionState<any> {}

class StatementReports extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      data: null,
      loading: false
    };

    this.isStatementRelatedToUserMatchingAnySpecialty = this.isStatementRelatedToUserMatchingAnySpecialty.bind(this);
  }

  componentDidMount() {
    this.fetchStatements();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { parameters: currentParameters } = this.props;
    const { parameters: previousParameters } = prevProps;

    if (previousParameters !== currentParameters) {
      this.fetchStatements();
    }
  }

  orderList(statements) {
    return statements.sort((a, b) =>
      a.fullName.replace(/Dre?[.\s]+/, '').localeCompare(b.fullName.replace(/Dre?[.\s]+/, ''))
    );
  }

  async fetchStatements() {
    const { parameters } = this.props;
    const { date, specialties, groupIds } = parameters;

    this.setState({ loading: true });

    try {
      const queryParams = queryString.stringify({
        statementDate: moment(date, 'YYYY-MM-DD').valueOf(),
        filters: {
          specialties,
          groupIds
        }
      });
      const response = await RalphApi.sendRequest(`statement-temporary/administrative?${queryParams}`, 'GET');
      const data = await response.json();

      this.setState({ data });
    } catch (_error) {
      perror(_error);
    } finally {
      this.setState({ loading: false });
    }
  }

  private isStatementRelatedToUserMatchingAnySpecialty({ specialty }) {
    const { parameters } = this.props;
    const { specialties = [] } = parameters;

    if (specialties.length > 0) {
      return specialties.includes(specialty);
    }

    return true;
  }

  render() {
    const { printableAreaRef, parameters } = this.props;
    const { showSection20, date } = parameters;
    const { data, loading } = this.state;

    if (loading || data === null) {
      return <FullScreenLoading dark={false} parentNotFlex={false} />;
    }

    return (
      <div ref={printableAreaRef}>
        {this.orderList(data).map((value) => (
          <StatementsRenderer
            key={value.practiceNumber + random()}
            statements={[value]}
            showSection20={showSection20}
            selectedDate={date}
          />
        ))}
      </div>
    );
  }
}

export default StatementReports;
