import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import User from '../../../../../shared/domain/user/User';
import { displayGlobalMessage } from '../../../Act/actions';
import { saveUserProfile, setUserProfileInContext } from '../../../User/actions';
import Profile from '../../../User/Profile/Profile';
import { selectPlacesPreferenceFromUser, selectUserProfileInContext } from '../../../User/selectors';

interface Props {
  saveUser: (updatedUser: User) => void;
  currentUserProfile: User;
  updateUserProfile: (updatedUserProfile: User) => void;
  placesPreferences: any[];
  onDisplayGlobalMessage: (message: string, type: string) => void;
}

export const UserProfile: React.FunctionComponent<Props> = ({
  currentUserProfile,
  placesPreferences,
  saveUser,
  updateUserProfile,
  onDisplayGlobalMessage
}) => (
  <Profile
    user={currentUserProfile}
    placesPreferences={placesPreferences}
    onSave={(updatedUser) => {
      saveUser(updatedUser);
      updateUserProfile(updatedUser);
      onDisplayGlobalMessage('Enregistré avec succès!', 'success');
    }}
  />
);

export const mapStateToProps = createStructuredSelector({
  currentUserProfile: selectUserProfileInContext(),
  placesPreferences: selectPlacesPreferenceFromUser()
});

export const mapDispatchToProps = {
  saveUser: saveUserProfile,
  updateUserProfile: setUserProfileInContext,
  onDisplayGlobalMessage: displayGlobalMessage
};

export default compose<typeof React.Component>(connect(mapStateToProps, mapDispatchToProps))(UserProfile);
