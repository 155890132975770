import moment from 'moment';
import { pickBy } from 'lodash';
import {
  CLEAR_GLOBAL_MESSAGE,
  DISPLAY_GLOBAL_ERROR,
  DISPLAY_GLOBAL_MESSAGE,
  DUPLICATE_ACT,
  FILTER_WATCHED_ACTS,
  SAVE_ACT,
  SAVE_ACT_FAILURE,
  SAVE_MIXTE_FAILURE,
  UPDATE_ACT,
  UPDATE_ACT_FAILURE,
  UPDATE_WATCHED_ACTIVITIES,
  WATCH_SENT_ACTIVITY
} from './actions';

const WATCHED_ACT_TTL = 1 * 60 * 1000; // 1 min in ms
const UNIT = 'ms';

export const initialState = {
  duplicatedInfo: {},
  saveActError: undefined,
  updateActError: undefined,
  globalMessage: {
    display: false,
    message: '',
    severity: ''
  },
  watchedActs: {}
};

const isNotExpired = (timestamp) => moment(timestamp).add(WATCHED_ACT_TTL, UNIT).isAfter(moment().valueOf());

function actReducer(state = initialState, action) {
  switch (action.type) {
    case DUPLICATE_ACT:
      return {
        ...state,
        duplicatedInfo: action.duplicateActInfo
      };
    case SAVE_ACT:
      return {
        ...state,
        saveActError: undefined
      };
    case SAVE_ACT_FAILURE:
    case SAVE_MIXTE_FAILURE:
      return {
        ...state,
        saveActError: action.error
      };
    case UPDATE_ACT:
      return {
        ...state,
        updateActError: undefined
      };
    case UPDATE_ACT_FAILURE:
      return {
        ...state,
        updateActError: action.error
      };
    case WATCH_SENT_ACTIVITY:
      return {
        ...state,
        watchedActs: {
          ...state.watchedActs,
          [action.activity.id]: moment().valueOf()
        }
      };
    case FILTER_WATCHED_ACTS: {
      const clonedWatchedActs = { ...state.watchedActs };
      action.erroredActs.forEach((erroredDoc) => delete clonedWatchedActs[erroredDoc.watchingData.id]);
      return {
        ...state,
        watchedActs: clonedWatchedActs
      };
    }
    case UPDATE_WATCHED_ACTIVITIES:
      return {
        ...state,
        watchedActs: pickBy(state.watchedActs, isNotExpired)
      };
    case DISPLAY_GLOBAL_ERROR:
    case DISPLAY_GLOBAL_MESSAGE:
      return {
        ...state,
        globalMessage: {
          display: true,
          message: action.message,
          severity: action.severity
        }
      };
    case CLEAR_GLOBAL_MESSAGE:
      return {
        ...state,
        globalMessage: {
          display: false,
          message: '',
          severity: ''
        }
      };
    default:
      return state;
  }
}

export default actReducer;
