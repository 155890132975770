import { defineMessages } from 'react-intl';

export default defineMessages({
  codeSearchTitle: {
    id: 'codeSelector.title',
    defaultMessage: 'Search codes'
  },
  codeSearchLabel: {
    id: 'codeSelector.inputLabel',
    defaultMessage: 'Key words'
  },
  close: {
    id: 'codeSelector.close',
    defaultMessage: 'Fermer'
  }
});
