import firebase from 'firebase/compat/app';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import _ from 'lodash';
import { selectUserLanguage } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import RALPH_API_BASE_URL from '../app/infrastructure/api/ralphApi';

const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: `${RALPH_API_BASE_URL}/graphql`
  });
  const authLink = setContext(async (_, { headers }) => {
    const userToken = await firebase.auth().currentUser?.getIdToken();
    const language = selectUserLanguage()(getStore().getState());
    return {
      headers: {
        ...headers,
        authorization: userToken ? `Bearer ${userToken}` : '',
        ...(language && { 'Accept-Language': language })
      }
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      }
    }
  });
};

export const removeTypenames = <T>(value: T | T[]) => omitByRecursively(value, (v, k) => k.includes('__typename'));

const omitByRecursively = <T>(value: T | T[], iteratee) => {
  const cb = (v) => omitByRecursively(v, iteratee);
  if (_.isObject(value)) {
    if (_.isArray(value)) {
      return _.map(value, cb) as T[];
    }
    return _(value).omitBy(iteratee).mapValues(cb).value() as T | T[];
  }
  return value as T;
};

const apolloClient = createApolloClient();
export default apolloClient;
