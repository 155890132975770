// JSON.stringify updates all "undefined" values to "null" which are supported by Firestore
const removeAllUndefinedProperties = (data) => JSON.parse(JSON.stringify(data));
export const add = (collectionReference, data) => {
  const filteredData = removeAllUndefinedProperties(data);
  return collectionReference.add(filteredData);
};

export const set = (collectionReference, data, setOptions = {}) => {
  const filteredData = removeAllUndefinedProperties(data);
  return collectionReference.set(filteredData, setOptions);
};

export const update = (documentReference, data, updateOptions = {}) => {
  const filteredData = removeAllUndefinedProperties(data);
  return documentReference.update(filteredData, updateOptions);
};

export const deepMerge = (collectionReference, data) => set(collectionReference, data, { merge: true });
