import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  transformToCategorizableCollection,
  transformToFirebaseDocumentsMap
} from '../../../../shared/editable/domain/CategorizableCollectionTransformer';
import EditableDoctorCollectionWithCategories from '../../components/EditableDoctorCollectionWithCategories/EditableDoctorCollectionWithCategories';
import { updateDoctorsFromUserPreferences } from '../../../adapters/actions';
import CreateNewCategoryDialog from '../../../../shared/editable/ui/CreateNewCategoryDialog/CreateNewCategoryDialog';
import CategorizableCollection from '../../../../shared/editable/domain/CategorizableCollection';
import EditCategoryNameDialog from '../../../../shared/editable/ui/EditCategoryNameDialog/EditCategoryNameDialog';
import DeleteCategoryValidationDialog from '../../../../shared/editable/ui/DeleteCategoryValidationDialog/DeleteCategoryValidationDialog';
import { ContainerWrapperComponentBuilder } from '../../components/EditableDoctorCollectionWithCategories/ComponentBuilders';

export const styles = (theme) => ({
  dialogContainer: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  },
  addCategoryButtonContainer: {
    margin: `${theme.spacing(2)}px 0`,
    display: 'flex',
    justifyContent: 'center'
  },
  dialogActions: {
    justifyContent: 'space-between'
  }
});

export class DoctorFavoritesAdvancedEditor extends React.Component {
  constructor(props) {
    super(props);

    const categorizableCollection = transformToCategorizableCollection(props.favoritesDoctors);

    this.state = {
      deleteCategoryValidatorOpen: false,
      createCategoryDialogOpen: false,
      categoryNameEditorOpen: false,
      tempSelectedCategory: {
        id: null,
        value: undefined
      },
      favoritesDoctors: categorizableCollection
    };

    this.handleOnChangeCollection = this.handleOnChangeCollection.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.openDeleteCategoryValidator = this.openDeleteCategoryValidator.bind(this);
    this.closeDeleteCategoryValidator = this.closeDeleteCategoryValidator.bind(this);
    this.openNewCategoryDialog = this.openNewCategoryDialog.bind(this);
    this.closeNewCategoryDialog = this.closeNewCategoryDialog.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.editCategoryName = this.editCategoryName.bind(this);
    this.openCategoryNameEditor = this.openCategoryNameEditor.bind(this);
    this.closeCategoryNameEditor = this.closeCategoryNameEditor.bind(this);
  }

  handleOnChangeCollection(newCollectionWithCategory) {
    this.setState({ favoritesDoctors: newCollectionWithCategory });
  }

  handleSave() {
    const { onSave, onSaveAction, onClose } = this.props;
    const transformedData = transformToFirebaseDocumentsMap(this.state.favoritesDoctors);

    onSave(onSaveAction(transformedData));

    onClose();
  }

  openDeleteCategoryValidator(id) {
    this.setState((state) => ({
      deleteCategoryValidatorOpen: true,
      tempSelectedCategory: {
        id,
        value: new CategorizableCollection(state.favoritesDoctors).getFromPath(id)
      }
    }));
  }

  deleteCategory() {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favoritesDoctors);

      return {
        ...state,
        favoritesDoctors: categorizableCollection.removeCategory(state.tempSelectedCategory.id)
      };
    }, this.closeDeleteCategoryValidator);
  }

  closeDeleteCategoryValidator() {
    this.setState({
      deleteCategoryValidatorOpen: false,
      tempSelectedCategory: {
        id: null,
        value: undefined
      }
    });
  }

  openNewCategoryDialog() {
    this.setState({
      createCategoryDialogOpen: true
    });
  }

  closeNewCategoryDialog() {
    this.setState({
      createCategoryDialogOpen: false
    });
  }

  createCategory({ id, name, path }) {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favoritesDoctors);
      return {
        ...state,
        favoritesDoctors: categorizableCollection.addCategory({ id, name }, path)
      };
    }, this.closeNewCategoryDialog);
  }

  openCategoryNameEditor(id, category) {
    this.setState({
      categoryNameEditorOpen: true,
      tempSelectedCategory: {
        id,
        value: category
      }
    });
  }

  closeCategoryNameEditor() {
    this.setState({
      categoryNameEditorOpen: false,
      tempSelectedCategory: {
        id: null,
        value: undefined
      }
    });
  }

  editCategoryName(newCategoryName) {
    this.setState((state) => {
      const { id } = state.tempSelectedCategory;

      const categorizableCollection = new CategorizableCollection(state.favoritesDoctors);
      return {
        ...state,
        favoritesDoctors: categorizableCollection.editCategoryName(id, newCategoryName)
      };
    }, this.closeCategoryNameEditor);
  }

  render() {
    const { classes, onClose } = this.props;
    const {
      favoritesDoctors,
      createCategoryDialogOpen,
      categoryNameEditorOpen,
      tempSelectedCategory,
      deleteCategoryValidatorOpen
    } = this.state;

    return (
      <>
        <DialogContent className={classes.dialogContainer}>
          <EditableDoctorCollectionWithCategories
            favoritesDoctors={favoritesDoctors}
            containerWrapperComponent={ContainerWrapperComponentBuilder(
              this.openCategoryNameEditor,
              this.openDeleteCategoryValidator
            )}
            onChange={this.handleOnChangeCollection}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <div>
            <Button id="add-category" color="primary" onClick={this.openNewCategoryDialog}>
              <FormattedMessage id="editableCollectionWithCategories.addCategory" />
            </Button>
          </div>
          <div>
            <Button id="close-Dialog" color="primary" onClick={onClose}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button id="accept-Dialog" color="primary" onClick={this.handleSave}>
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        </DialogActions>
        <DeleteCategoryValidationDialog
          category={tempSelectedCategory.value}
          open={deleteCategoryValidatorOpen}
          onCancel={this.closeDeleteCategoryValidator}
          onConfirm={this.deleteCategory}
        />

        <CreateNewCategoryDialog
          open={createCategoryDialogOpen}
          categoryPaths={new CategorizableCollection(favoritesDoctors).getCategoryPathsFlatMap()}
          onCancel={this.closeNewCategoryDialog}
          onConfirm={this.createCategory}
        />

        <EditCategoryNameDialog
          open={categoryNameEditorOpen}
          category={tempSelectedCategory.value}
          onCancel={this.closeCategoryNameEditor}
          onSave={this.editCategoryName}
        />
      </>
    );
  }
}

DoctorFavoritesAdvancedEditor.propTypes = {
  favoritesDoctors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaveAction: PropTypes.func.isRequired
};

export const mapDispatchToProps = (dispatch) => ({
  onSave: (action) => dispatch(action)
});

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(DoctorFavoritesAdvancedEditor);
