import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { WithStyles, withStyles } from '@material-ui/core';
import { AlertOptions } from '../api/AlertApi';
import theme from '../../../../utils/muiTheme';

export interface Props extends AlertOptions, WithStyles<typeof styles> {
  onClose: () => void;
}

export const styles = () =>
  ({
    title: {
      textAlign: 'center'
    },
    actionButtons: {
      justifyContent: 'space-between',
      flexDirection: 'row-reverse'
    },
    cancelButton: {
      color: 'red'
    }
  }) as const;

export const ConfirmTemplate: React.FunctionComponent<Props> = ({
  onClose,
  onConfirm,
  onCancel = () => {},
  displayCancel = true,
  title,
  content,
  confirmActionLabel = 'Confirmer',
  cancelActionLabel = 'Annuler',
  classes
}) => (
  <MuiThemeProvider theme={theme}>
    <Dialog open onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle data-testid="alert-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText data-testid="alert-dialog-content">{content}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          data-testid="confirm-dialog"
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
          autoFocus
        >
          {confirmActionLabel}
        </Button>
        {displayCancel && (
          <Button
            data-testid="cancel-dialog"
            onClick={() => {
              onCancel();
              onClose();
            }}
            className={classes.cancelButton}
          >
            {cancelActionLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  </MuiThemeProvider>
);

export default withStyles(styles)(ConfirmTemplate);
