import React from 'react';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import useTranslate from 'application/hooks/use-translate';

const GROUP_STATEMENT_PERIODS = gql`
  query FetchGroupStatementPeriods($groupId: String!) {
    group(groupId: $groupId) {
      statementPeriods {
        date
        paymentNumber
      }
    }
  }
`;

interface Props {
  groupId?: string;
  onChange: (value: number | null) => void;
  value: number | null;
}

interface Group {
  statementPeriods: GroupStatementPeriod[];
}

interface GroupStatementPeriod {
  date: string;
  paymentNumber: string;
}

const StatementDateSelector = ({ groupId, onChange, value }: Props) => {
  const translate = useTranslate('reports');
  const { data } = useQuery<{ group: Group }>(GROUP_STATEMENT_PERIODS, { variables: { groupId } });
  const statementPeriods = data?.group.statementPeriods || [];

  return (
    <select
      id="report-statement-date-selector"
      className="select-bordered select mr-4"
      onChange={(event) => onChange(Number(event.target.value))}
      disabled={statementPeriods.length === 0}
      defaultValue={value || ''}
    >
      <option value="" disabled>
        {translate('statement-date')}
      </option>
      {statementPeriods.map(({ date, paymentNumber }) => (
        <option key={paymentNumber} value={moment(date).valueOf()}>
          ({paymentNumber}) {`${moment(new Date(date).toUTCString()).format('DD MMM. YYYY')}`}
        </option>
      ))}
    </select>
  );
};

export default StatementDateSelector;
