import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { getActivities } from '../../../containers/Activities/actions';
import { selectActivitiesLoading } from '../../../containers/Activities/selectors';
import { getCodesPreferences } from '../../../containers/CodeSearchDialog/actions';
import { ACTIVITIES_ROUTE, USERS_ROUTE } from '../../../containers/Navigation/constants';
import { getPlacesPreferences } from '../../../containers/PlacesPreferencesDialog/actions';
import { getLumpSumsPreferences, setUserInContext, setUserProfileInContext } from '../../../containers/User/actions';
import { getDoctorPreferences, getResidentsPreferences } from '../../../doctorsRegistry/adapters/actions';
import { getSpecialtyFavoritesActCodes, getSpecialtyPresetActCodes } from '../../../favorite/actCode/adapters/actions';
import {
  getDiagnosticCodesPreferences,
  getSpecialtyDiagnosticCodes
} from '../../../favorite/diagnosticCode/adapters/actions';
import NavigationHeaderButton from '../../button/NavigationHeaderButton/NavigationHeaderButton';
import {
  getContextElementsPreferences,
  getSpecialtyContextElements
} from '../../../favorite/contextElement/adapters/actions';
import User from '../../../../shared/domain/user/User';
import UserAPI from '../../../infrastructure/api/UserAPI';

const styles = (theme) =>
  ({
    navigationButton: {
      minHeight: theme.spacing(6)
    }
  }) as const;

const getUserUrl = (userId) => `/${USERS_ROUTE}/${userId}/${ACTIVITIES_ROUTE}`;

interface Props extends WithStyles<typeof styles> {
  userIds: string[];
  setUserIdInContext: (userId: string) => void;
  setUserProfileInContext: (user: User) => void;
  currentUserId: string | undefined;
  navigateByAction: boolean;
  loadingActivities: boolean;
}

interface State {
  isLoading: boolean;
}

export class UserNavigationButtons extends React.Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    navigateByAction: false,
    userIds: []
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  initializeUserImpersonification = async (userId) => {
    this.setState({ isLoading: true });
    this.props.setUserIdInContext(userId);
    const nextUser = await UserAPI.fetchProfile(userId);
    if (!nextUser) return;

    this.props.setUserProfileInContext(nextUser);
    this.setState({ isLoading: false });
  };

  render() {
    const { loadingActivities, userIds, currentUserId, classes, navigateByAction } = this.props;

    if (userIds.length === 0 || loadingActivities || this.state.isLoading) {
      return null;
    }

    const currentUserIndex = userIds.indexOf(currentUserId || '');
    const previousUserIndex = currentUserIndex - 1;
    const nextUserIndex = currentUserIndex + 1;
    const previousUserId = userIds[previousUserIndex];
    const nextUserId = userIds[nextUserIndex];

    return (
      <>
        {previousUserIndex > -1 && (
          <NavigationHeaderButton
            className={classes.navigationButton}
            position="left"
            actionToMakeOnClick={
              navigateByAction ? () => this.initializeUserImpersonification(previousUserId) : undefined
            }
            urlToGoOnClick={getUserUrl(previousUserId)}
            label={`[${previousUserIndex + 1}/${userIds.length}] Précédent`}
          />
        )}

        {nextUserIndex < userIds.length && (
          <NavigationHeaderButton
            className={classes.navigationButton}
            position="right"
            actionToMakeOnClick={navigateByAction ? () => this.initializeUserImpersonification(nextUserId) : undefined}
            urlToGoOnClick={getUserUrl(nextUserId)}
            label={`[${nextUserIndex + 1}/${userIds.length}] Suivant`}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setUserIdInContext: (userId) => {
    dispatch(setUserInContext(userId));
  },
  setUserProfileInContext: (user) => {
    dispatch(setUserProfileInContext(user));
    dispatch(getActivities(null));
    dispatch(getPlacesPreferences());
    dispatch(getDoctorPreferences());
    dispatch(getResidentsPreferences());
    dispatch(getCodesPreferences());
    dispatch(getSpecialtyPresetActCodes());
    dispatch(getSpecialtyFavoritesActCodes());
    dispatch(getSpecialtyDiagnosticCodes());
    dispatch(getSpecialtyContextElements());
    dispatch(getDiagnosticCodesPreferences());
    dispatch(getContextElementsPreferences());
    dispatch(getLumpSumsPreferences());
  }
});

const mapsStateToProps = createStructuredSelector({
  loadingActivities: selectActivitiesLoading()
});

export default compose(connect(mapsStateToProps, mapDispatchToProps), withStyles(styles))(UserNavigationButtons);
