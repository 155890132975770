import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import { NumberField } from '../../../components/Form/Fields';

const associatedFields: AssociatedField[] = [
  {
    label: '"Nombre d\'unités correspondant à une valeur monétaire"',
    getName: (index) => `codes[${index}].unitNumberMeasurementElement`,
    type: NumberField
  }
];

const unitNumberMeasurementElement: RequiredDetail = {
  associatedFields
};

export default unitNumberMeasurementElement;
