/* eslint-disable react/no-array-index-key */
import Divider from '@material-ui/core/Divider';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import StatementBox from '../../../../components/Table/Statement/StatementBox/StatementBox';
import StatementTable from '../../../../components/Table/Statement/StatementTable/StatementTable';
import selectPrinting from '../selectors';
import StatementIncentive from './Incentive/StatementIncentive';
import StatementLiabilityInsurance from './LiabilityInsurance/StatementLiabilityInsurance';
import StatementPeriod from './Period/StatementPeriod';
import StatementBalances from './sections/StatementBalances';
import StatementCeilingCutLines from './sections/StatementCeilingCutLines';
import StatementDoctorInfo from './sections/StatementDoctorInfo';
import StatementFederationSum from './sections/StatementFederationSum';
import StatementGrossAmount from './sections/StatementGrossAmount';
import StatementHeader from './sections/StatementHeader';
import StatementInterestAndAdvances from './sections/StatementInterestAndAdvances';
import StatementNetAmount from './sections/StatementNetAmount';
import StatementVariousDeductions from './sections/StatementVariousDeductions';
import StatementSpecial from './Special/StatementSpecial';
import {
  actRequestNumberTransactionCode,
  actTIPIAmountCodeColumn,
  actTIPIDateAttestationColumn,
  actTIPINamTypeColumn,
  amountAndExplicationColumn,
  amountColumn,
  amountPreviousAndCurrentColumn,
  decorateWithReferenceNumber,
  ceilingCutAmount,
  ceilingCutStartDateColumn,
  ceilingCutTypeColumn,
  dateAndBillNumberColumn,
  deductionAmountColumn,
  deductionCodeColumn,
  deductionLabelColumn,
  keepLineWithTransactionCode,
  namAndIdentificationValueColumn,
  namColumn,
  replaceExplanationMessageWithDetailedVersion
} from './StatementReportUtils';

export const styles = () => ({
  printableHeader: {
    width: '100%',
    zoom: '80%',
    pageBreakBefore: 'always',
    pageBreakAfter: 'avoid'
  },
  pageContent: {
    width: '100%',
    zoom: '80%',
    pageBreakBefore: 'always',
    pageBreakAfter: 'avoid'
  }
});

export class StatementReport extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  shouldDoubleTable = () => this.props.printing || isWidthUp('md', this.props.width);

  getStatementReportType = (section, isPool) => {
    if (isPool) {
      return 'Pool';
    }
    return section.generalInformation.professionalIdInfo.groupClass === '0' ? 'Personnel' : 'Groupe';
  };

  render() {
    const { statements, isPool, showSection20, classes } = this.props;
    const { fullName, sections } = statements;

    return (
      <div className="pageBreakHeader">
        {sections.map((section, index) => (
          <Fragment key={index}>
            <div className={classes.printableHeader}>
              <StatementHeader title="ÉTAT DE COMPTE" subtitle={this.getStatementReportType(section, isPool)} />
              <Divider />
              <StatementDoctorInfo
                fullName={fullName}
                generalInformation={section.generalInformation}
                isPool={isPool}
              />
              <StatementGrossAmount generalInformation={section.generalInformation} />
              <StatementCeilingCutLines generalInformation={section.generalInformation} />
              <StatementVariousDeductions generalInformation={section.generalInformation} />
              <StatementFederationSum generalInformation={section.generalInformation} />
              <StatementInterestAndAdvances generalInformation={section.generalInformation} />
              <StatementBalances generalInformation={section.generalInformation} />
              <StatementNetAmount generalInformation={section.generalInformation} />
            </div>

            {!isPool && (
              <div className={classes.pageContent}>
                <StatementTable
                  title="001 - Paiement partiel ou facture révisée à votre demande"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namColumn, amountAndExplicationColumn]}
                  data={sortBy(
                    section.syraLines.anomalyPaidSyraBill.filter(keepLineWithTransactionCode('001')),
                    'dateRFP'
                  )}
                />

                <StatementTable
                  title="002/004 - Paiement modifié"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namColumn, amountAndExplicationColumn]}
                  data={section.syraLines.anomalyPaidSyraBill
                    .reduce(decorateWithReferenceNumber, [])
                    .filter(keepLineWithTransactionCode('002', '004'))
                    .sort((a, b) => a.dateRFP - b.dateRFP)}
                />

                <StatementTable
                  title="005 - Coupure plafond"
                  doubled={this.shouldDoubleTable()}
                  columns={[ceilingCutStartDateColumn, ceilingCutTypeColumn, ceilingCutAmount]}
                  data={section.generalInformation.ceilingCutLines}
                />

                <StatementTable
                  title="007 - Déductions diverses"
                  doubled={this.shouldDoubleTable()}
                  columns={[deductionCodeColumn, deductionLabelColumn, deductionAmountColumn]}
                  data={section.generalInformation.deductionLines}
                />

                {showSection20 && (
                  <StatementTable
                    title=" 020 - Demande de paiement payée au montant demandé"
                    doubled={this.shouldDoubleTable()}
                    columns={[dateAndBillNumberColumn, namColumn, amountColumn]}
                    data={sortBy(section.syraLines.regularPaidSyraBill, 'dateRFP')}
                  />
                )}

                <StatementTable
                  title="021 - Paiement suite à demande de révision"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namAndIdentificationValueColumn, amountAndExplicationColumn]}
                  data={sortBy(
                    section.syraLines.modifiedPaidSyraBill.filter(keepLineWithTransactionCode('021')),
                    'dateRFP'
                  )}
                />

                <StatementTable
                  title="022 - Factures révisées par la régie"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namAndIdentificationValueColumn, amountAndExplicationColumn]}
                  data={section.syraLines.modifiedPaidSyraBill
                    .reduce(decorateWithReferenceNumber, [])
                    .filter(keepLineWithTransactionCode('022'))
                    .sort((a, b) => a.dateRFP - b.dateRFP)}
                />

                <StatementTable
                  title="023 - Révision nulle suite à demande de révision"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namAndIdentificationValueColumn, amountAndExplicationColumn]}
                  data={sortBy(
                    section.syraLines.modifiedPaidSyraBill.filter(keepLineWithTransactionCode('023')),
                    'dateRFP'
                  )}
                />

                <StatementTable
                  title="024 - Demandes en attente de révision"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namAndIdentificationValueColumn, amountAndExplicationColumn]}
                  data={sortBy(
                    section.syraLines.modifiedPaidSyraBill.filter(keepLineWithTransactionCode('024')),
                    'dateRFP'
                  )}
                />

                <StatementTable
                  title="035 - Révision négative suite à refacturation"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namAndIdentificationValueColumn, amountAndExplicationColumn]}
                  data={section.syraLines.modifiedPaidSyraBill
                    .reduce(decorateWithReferenceNumber, [])
                    .filter(keepLineWithTransactionCode('035'))
                    .sort((a, b) => a.dateRFP - b.dateRFP)}
                />

                <StatementTable
                  title="036 - Demande de modification sans ajustement"
                  doubled={this.shouldDoubleTable()}
                  columns={[dateAndBillNumberColumn, namAndIdentificationValueColumn, amountAndExplicationColumn]}
                  data={sortBy(
                    section.syraLines.modifiedPaidSyraBill.filter(keepLineWithTransactionCode('036')),
                    'dateRFP'
                  )}
                />

                <StatementTable
                  title="Relevé d’honoraire"
                  doubled={false}
                  columns={[
                    dateAndBillNumberColumn,
                    namAndIdentificationValueColumn,
                    amountAndExplicationColumn,
                    amountPreviousAndCurrentColumn
                  ]}
                  data={sortBy(section.syraLines.refundSyraBill, 'dateRFP')}
                />

                <StatementTable
                  doubled={this.shouldDoubleTable()}
                  title="Factures en cours de révision"
                  columns={[dateAndBillNumberColumn, namAndIdentificationValueColumn]}
                  data={section.syraLines.waitingForRevisionBill}
                />

                <StatementBox title="Mixte" contentArray={section.oldLines.mixteX} ContentComponent={StatementPeriod} />

                <StatementBox
                  title="Forfaitaire"
                  contentArray={section.oldLines.sessionAndFixedFeesV}
                  ContentComponent={StatementPeriod}
                />

                <StatementBox
                  title="Assurance responsabilité"
                  contentArray={section.oldLines.liabilityInsuranceE}
                  ContentComponent={StatementLiabilityInsurance}
                />

                <StatementBox
                  title="Mesures incitatives"
                  contentArray={section.oldLines.incentiveM}
                  ContentComponent={StatementIncentive}
                />

                <StatementBox
                  title="Mesures spéciales"
                  contentArray={section.oldLines.specialMesureR}
                  ContentComponent={StatementSpecial}
                />

                <StatementTable
                  title="Détails / Acte TIP-I"
                  columns={[
                    actTIPIDateAttestationColumn,
                    actRequestNumberTransactionCode,
                    actTIPINamTypeColumn,
                    actTIPIAmountCodeColumn
                  ]}
                  data={section.detailsLines}
                />

                <StatementTable
                  columns={[
                    { name: 'Code', primaryText: (line) => line.explanationCodeNumber },
                    { name: 'Message', primaryText: (line) => line.explanationText }
                  ]}
                  data={section.transactionLines
                    .concat(section.explanationLines)
                    .concat(section.syraLines.codeExplanationText.map(replaceExplanationMessageWithDetailedVersion))}
                  title="Messages Explicatifs"
                />
              </div>
            )}
          </Fragment>
        ))}
      </div>
    );
  }
}

StatementReport.defaultProps = {
  isPool: false,
  showSection20: true
};

StatementReport.propTypes = {
  statements: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  printing: PropTypes.bool.isRequired,
  isPool: PropTypes.bool,
  showSection20: PropTypes.bool
};

export const mapStateToProps = createStructuredSelector({
  printing: selectPrinting()
});

export default compose(withStyles(styles), withWidth(), connect(mapStateToProps))(StatementReport);
