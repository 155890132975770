import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'redux';
import Button from 'application/components/button';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Menu, MenuItem } from '@material-ui/core';
import BillingType from 'shared/domain/billing/model/BillingType';
import useTranslate from 'application/hooks/use-translate';
import { usePostHog } from 'posthog-js/react';

export const styles = () => ({
  activityTypeFilter: {
    position: 'absolute',
    left: 10
  }
});



export function TotalAmountLine({ classes, onActivityFilterChange, currentFilter }) {
  const [filterMenuDown, setFilterMenuDown] = useState(false);
  const translate = useTranslate('act-filter');
  const posthog = usePostHog();

  const getFilterName = () => {
    if (!currentFilter) return translate('all');
    
    return currentFilter === BillingType.PRIV ? translate('private') : translate('pool');
  };

  return (        
          <div className={classes.activityTypeFilter}>
            <Button
              id="filterActivitiesDropdown"
              className={classes.activityTypeFilterButton}
              onClick={() => setFilterMenuDown(true)}
            >
              {getFilterName()}
              <KeyboardArrowDown />
            </Button>
            <Menu
              anchorEl={document.getElementById('filterActivitiesDropdown')}
              keepMounted
              open={filterMenuDown}
              onClose={() => setFilterMenuDown(false)}
            >
              <MenuItem
                onClick={() => {
                    onActivityFilterChange(null);
                  setFilterMenuDown(false);
                  posthog.capture('Filter activities', { filter: 'all' });
                }}
              >
                {translate('all')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                    onActivityFilterChange(BillingType.PRIV);
                  setFilterMenuDown(false);
                  posthog.capture('Filter activities', { filter: 'private' });
                }}
              >
                {translate('private')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                    onActivityFilterChange(BillingType.POOL);
                  setFilterMenuDown(false);
                  posthog.capture('Filter activities', { filter: 'pool' });
                }}
              >
                {translate('pool')}
              </MenuItem>
            </Menu>
          </div>        
  );
}

TotalAmountLine.propTypes = {
  onActivityFilterChange: PropTypes.func.isRequired,
  currentFilter: PropTypes.string
};

export default compose(withStyles(styles))(TotalAmountLine);
