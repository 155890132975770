import React, { useEffect, useState } from 'react';
import Icon from 'application/components/icon';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import useTranslate from 'application/hooks/use-translate';
import classNames from 'classnames';

interface SelectOption<T> {
  value: T;
  label: string;
}

interface Props<T> extends ValidatedFieldProps {
  value: T;
  options: SelectOption<T>[];
  placeholder?: string | true;
  required?: boolean;
  disabled?: boolean;
  onChange: (value: T) => void;
}

const SelectInput = <T,>({
  value,
  options,
  placeholder,
  required = false,
  disabled = false,
  touched = false,
  customValidation,
  onChange,
  onTouch,
  onValidate
}: Props<T>) => {
  const translate = useTranslate('components.form-controls.select-input');
  const [isFocused, setIsFocused] = useState(false);

  const isValid = !required || (required && !!value);

  useEffect(() => {
    if (!onValidate) return;
    onValidate(isValid);
  }, [onValidate, isValid]);

  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    const castedValue = value as unknown as T;
    if (onTouch) onTouch();
    onChange(castedValue);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleOnBlur = () => {
    if (onTouch) onTouch();

    setIsFocused(false);
  };

  const errorIsVisible = !isFocused && !isValid && touched;

  return (
    <div className="relative">
      <select
        className={classNames(
          'w-full appearance-none rounded-lg border border-neutral-300 bg-white p-4 text-sm text-neutral-900 focus:border-primary-900 focus:outline-none',
          {
            'border-red-500': errorIsVisible
          }
        )}
        disabled={disabled}
        onChange={handleOnChange}
        defaultValue={value as string}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        value={value as string}
      >
        {placeholder && (
          <option value="">{placeholder === true ? translate('default-placeholder') : placeholder}</option>
        )}

        {options.map((option) => (
          <option key={option.value as string} value={option.value as string}>
            {option.label}
          </option>
        ))}
      </select>

      {errorIsVisible && (
        <div className="mt-1 text-sm text-red-500" role="alert">
          {customValidation?.errorMessage ?? translate('required-error')}
        </div>
      )}

      <div className="pointer-events-none absolute right-4 top-0 flex h-[54px] items-center text-neutral-900">
        <Icon icon="chevron-down" />
      </div>
    </div>
  );
};

export default SelectInput;
