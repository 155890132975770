import get from 'lodash/get';
import ActsDay from '../../classes/ActsDay';

function extractDoctorUserIds(acts: any) {
  const doctorUserIds = new Set([
    ...acts.billedActivities.map(({ userId }) => userId),
    ...acts.notBilledActivities.map(({ userId }) => userId),
    ...acts.ignoredActivities.map(({ userId }) => userId)
  ]);
  return doctorUserIds;
}

function extractUserData(actsDay: ActsDay) {
  const { firstName, lastName, practiceNumber } =
    get(actsDay, 'billedActivities[0]', null) ||
    get(actsDay, 'notBilledActivities[0]', null) ||
    get(actsDay, 'ignoredActivities[0]', null);
  return { firstName, lastName, practiceNumber };
}

function createActDaysForDoctor(acts: any, userId) {
  const actsDay = new ActsDay(acts.day);

  actsDay.addActivities([
    ...acts.billedActivities.filter((activity) => activity.userId === userId),
    ...acts.notBilledActivities.filter((activity) => activity.userId === userId),
    ...acts.ignoredActivities.filter((activity) => activity.userId === userId)
  ]);
  const { firstName, lastName, practiceNumber } = extractUserData(actsDay);

  const actByDaysForSpecificDoctor = {
    doctor: { firstName, lastName, practiceNumber },
    actsDay
  };
  return actByDaysForSpecificDoctor;
}

const groupByUserId = (acts: any) => {
  const doctorUserIds = extractDoctorUserIds(acts);

  const actByDaysGroupedByDoctor = {};

  for (const userId of doctorUserIds) {
    const actByDaysForSpecificDoctor = createActDaysForDoctor(acts, userId);
    actByDaysGroupedByDoctor[userId] = actByDaysForSpecificDoctor;
  }

  return actByDaysGroupedByDoctor;
};

export default groupByUserId;
