import React, { useEffect } from 'react';
import Icon from 'application/components/icon';
import classnames from 'classnames';

export type ToastType = 'success' | 'error' | 'info' | 'warning';

export interface ToastProps {
  autoDismiss?: boolean;
  icon?: string;
  id: number;
  message: string;
  onDismiss: () => void;
  type: ToastType;
}

const AUTO_DISMISS_TIMEOUT = 5000; // 5 seconds

const Toast = ({ autoDismiss, icon, id, message, onDismiss, type }: ToastProps) => {
  useEffect(() => {
    if (!autoDismiss) return;

    const timeout = setTimeout(() => {
      onDismiss();
    }, AUTO_DISMISS_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [autoDismiss, onDismiss]);

  return (
    <div
      className={classnames(
        'relative max-w-[100vw] overflow-hidden text-wrap rounded-lg border bg-white p-4 shadow-md',
        {
          'border-green-700 text-green-800': type === 'success',
          'border-red-500 text-red-800': type === 'error',
          'border-pelorous-500 text-pelorous-800': type === 'info',
          'border-yellow-500 text-yellow-800': type === 'warning'
        }
      )}
      key={`toast-${id}`}
    >
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          {icon && <Icon className="text-2xl" icon={icon} />}

          <span>{message}</span>
        </div>

        <button type="button" className="btn btn-square btn-ghost btn-sm" onClick={onDismiss}>
          <Icon icon="times" solid />
        </button>
      </div>

      {autoDismiss && (
        <div
          className={classnames('absolute bottom-0 left-0 z-0 h-2 w-full', {
            'bg-green-700': type === 'success',
            'bg-red-500': type === 'error',
            'bg-pelorous-500': type === 'info',
            'bg-yellow-500': type === 'warning'
          })}
          style={{
            animation: `toast-progress ${AUTO_DISMISS_TIMEOUT / 1000}s linear forwards`
          }}
        />
      )}
    </div>
  );
};

export default Toast;
