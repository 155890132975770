import React, { useRef } from 'react';
import useTranslate from 'application/hooks/use-translate';
import Icon from 'application/components/icon';
import classNames from 'classnames';
import { useClickAway, useToggle } from 'react-use';

type DownloadMode = 'pdf' | 'csv';

interface Props {
  disabled?: boolean;
  downloading?: boolean;
  onClick: (selectedMode: DownloadMode) => void;
  modes?: DownloadMode[];
  className?: string;
}

const DownloadButton = ({ disabled, downloading, modes, className, onClick }: Props) => {
  const translate = useTranslate('download-button');
  const effectiveModes = modes ?? ['pdf'];

  const [isDropdownOpened, toggleIsDropdownOpened] = useToggle(false);
  const ref = useRef(null);

  useClickAway(ref, () => toggleIsDropdownOpened(false));

  const downloadButtonAction = effectiveModes.length > 1 ? toggleIsDropdownOpened : () => onClick(effectiveModes[0]);

  return (
    <div ref={ref} className={classNames('dropdown dropdown-end dropdown-open', className)}>
      <button
        type="button"
        className="btn btn-primary flex-nowrap gap-2"
        onClick={downloadButtonAction}
        disabled={disabled || downloading}
      >
        {downloading ? (
          <>
            <span className="loading loading-spinner" />
            {translate('downloading')}
          </>
        ) : (
          <>
            <Icon icon="arrow-down-to-line" />
            {translate('download')}
          </>
        )}
      </button>

      {effectiveModes.length > 1 && isDropdownOpened && (
        <ul className="menu dropdown-content z-50 min-w-max rounded-b-lg bg-base-100 shadow">
          {effectiveModes.map((mode) => (
            <li key={mode}>
              <button
                type="button"
                className="flex justify-between text-left"
                onClick={() => {
                  onClick(mode);
                  toggleIsDropdownOpened(false);
                }}
              >
                <Icon icon={`file-${mode}`} />
                {translate(mode)}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DownloadButton;
