/** *********************************************************************************************************************
 ***********************************************************************************************************************
 ***********************************************************************************************************************
 ****************************************** Legacy business logic down below *******************************************
 ***********************************************************************************************************************
 ***********************************************************************************************************************
 ***********************************************************************************************************************
 */

import moment from 'moment-timezone';
import { maxBy, minBy } from 'lodash';
import { AM_PERDIEM, HALF_PERDIEM, PERDIEM, PM_PERDIEM } from './constants';
import { getRamqDayOfMonthFromTimestamp, padStringWithZeros } from '../../ramq/ramqCommonUtils';
import { formatToDeferredPaymentRequestDate } from '../../ramq/ramqDateUtil';
import { perror, plog } from '../../utils/plog';
import { canBalanceMore, hasEnoughHoursToBalance, isPerdiemAdjustmentCompleted } from './makeMixteSummaryUtils';

export const isAmPeriod = (start, end) => moment((start + end) / 2).hour() < 12;

export const isPmPeriod = (start, end) => moment((start + end) / 2).hour() >= 12;

export const makeDayLineFromMixte = (day) => {
  let am;
  let pm;
  let duration;
  let perdiem;

  if (day.perdiems) {
    am = day.perdiems && day.perdiems.includes(AM_PERDIEM);
    pm = day.perdiems && day.perdiems.includes(PM_PERDIEM);
    duration = am && pm ? '0700' : '0350';
    perdiem = am && pm ? PERDIEM : HALF_PERDIEM;
  } else {
    duration = getPaddedHoursWorked(day.start, day.end);
    perdiem = calculatePerdiem(duration);
    am = isAmPeriod(day.start, day.end);
    pm = isPmPeriod(day.start, day.end);
  }

  if (perdiem === PERDIEM) {
    // eslint-disable-next-line no-multi-assign
    am = pm = true;
  }

  return {
    am,
    pm,
    day: getRamqDayOfMonthFromTimestamp(day.date),
    date: formatToDeferredPaymentRequestDate(day.date),
    dispensatoryArea: day.activityArea,
    code: day.code,
    duration,
    perdiem
  };
};

export const balancePerdiems = (days) => {
  const daysToBalance = prepareMetadata(days);
  const daysWithBalancedHours = balanceHoursAcrossDays(daysToBalance).map((day) => {
    // eslint-disable-next-line no-param-reassign
    day.perdiem = calculatePerdiemForDay(day);
    return day;
  });

  return cleanUpMetadata(ensure1PerdiemPerDayLimit(daysWithBalancedHours));
};

export const calculatePerdiem = (duration, adjustedPerdiem = 0) =>
  padStringWithZeros((Math.floor(parseInt(duration, 10) / 100 / 3.5) / 2 + adjustedPerdiem) * 10, 2);

const getMissingHours = (duration) => (duration / 100 < 7 ? 3.5 - ((duration / 100) % 3.5) : 0);

const getExtraHours = (duration) => (duration / 100) % 3.5;

const prepareMetadata = (days) =>
  days.map((day) => ({
    ...day,
    extraHours: getExtraHours(day.duration),
    missingHours: getMissingHours(day.duration),
    adjustedPerdiem: 0
    // perdiem: '0'
  }));

const cleanUpMetadata = (days) =>
  days.map((day) => {
    // eslint-disable-next-line no-param-reassign
    delete day.adjustedPerdiem;
    // eslint-disable-next-line no-param-reassign
    delete day.extraHours;
    // eslint-disable-next-line no-param-reassign
    delete day.missingHours;
    return day;
  });

const ensure1PerdiemPerDayLimit = (daysWithBalancedHours) => {
  const totalPerdiemsPerDay = aggregatePerdiemsPerDay(daysWithBalancedHours);
  const perdiemAdjustmentMap = new Map();
  const dayKeys = [...totalPerdiemsPerDay.keys()];

  totalPerdiemsPerDay.forEach((perdiem, date) => {
    const perdiemValue = parseInt(perdiem, 10);
    if (perdiemValue > 10) {
      let extraPerdiem = perdiemValue - 10;
      perdiemAdjustmentMap.set(date, 0 - extraPerdiem);
      totalPerdiemsPerDay.set(date, PERDIEM);

      // TODO add while loop to handle the case where we have to spread the extraPerdiem across multiple days
      while (extraPerdiem > 0) {
        const dayThatCanTakeExtraPerdiem = minBy(dayKeys, (day) => parseInt(totalPerdiemsPerDay.get(day), 10));
        const dayThatCanTakeExtraPerdiemValue = parseInt(totalPerdiemsPerDay.get(dayThatCanTakeExtraPerdiem), 10);
        if (dayThatCanTakeExtraPerdiemValue >= 10) {
          plog(`makeMixteSummary NO DAY CAN TAKE EXTRA PERDIEM, wasting ${extraPerdiem / 10} perdiem`);
          extraPerdiem = 0;
        } else {
          let perdiemAdjustment = extraPerdiem;
          let newPerdiem = dayThatCanTakeExtraPerdiemValue + extraPerdiem;

          if (newPerdiem > 10) {
            extraPerdiem = newPerdiem - 10;
            perdiemAdjustment -= extraPerdiem;
            newPerdiem = 10;
          } else {
            extraPerdiem = 0;
          }

          totalPerdiemsPerDay.set(dayThatCanTakeExtraPerdiem, padStringWithZeros(newPerdiem, 2));
          perdiemAdjustmentMap.set(
            dayThatCanTakeExtraPerdiem,
            (perdiemAdjustmentMap.get(dayThatCanTakeExtraPerdiem) || 0) + perdiemAdjustment
          );
        }
      }
    }
  });

  return applyPerdiemAdjustment(perdiemAdjustmentMap, daysWithBalancedHours);
};

const applyPerdiemAdjustment = (perdiemAdjustmentMap, days) => {
  perdiemAdjustmentMap.forEach((adjustment, day) => {
    let adjustmentLeft = adjustment;
    let loopCount = 0;
    while (!isPerdiemAdjustmentCompleted(adjustmentLeft) && loopCount < 100) {
      loopCount += 1;

      if (adjustmentLeft > 0) {
        const dayToAdjust = minBy(
          days.filter((d) => d.date === day && parseInt(d.perdiem, 10) < 10),
          (d) => parseInt(d.perdiem, 10)
        );
        if (dayToAdjust === undefined) {
          break;
        }

        if (parseInt(dayToAdjust.perdiem, 10) + adjustmentLeft <= 10) {
          dayToAdjust.perdiem = padStringWithZeros(parseInt(dayToAdjust.perdiem, 10) + adjustmentLeft, 2);
          adjustmentLeft = 0;
        } else {
          adjustmentLeft -= 10 - parseInt(dayToAdjust.perdiem, 10);
          dayToAdjust.perdiem = PERDIEM;
        }
      } else {
        const daysOfCurrentDate = days.filter((d) => d.date === day && parseInt(d.perdiem, 10) > 0);
        const dayToAdjust = maxBy(daysOfCurrentDate, (d) => parseInt(d.perdiem, 10));
        if (dayToAdjust === undefined) {
          break;
        }

        if (parseInt(dayToAdjust.perdiem, 10) >= Math.abs(adjustmentLeft)) {
          dayToAdjust.perdiem = padStringWithZeros(parseInt(dayToAdjust.perdiem, 10) + adjustmentLeft, 2);
          adjustmentLeft = 0;
        } else {
          adjustmentLeft += parseInt(dayToAdjust.perdiem, 10);
          dayToAdjust.perdiem = '00';
        }
      }
    }

    // Yes this is cheap and should not happen but I'd rather have an error than a cloud function timing out
    // I think the tests cover all actual use cases, I don't see a way that would lead to an infinite loop
    // so this is just a backup check, and quite useful when actually developping and running the tests to avoid
    // having to kill the test server each time we create a loop
    if (loopCount === 100) {
      perror('Too much looping', adjustment, day, JSON.stringify(days).slice(0, 5000));
      throw Error('Too much looping', adjustment, day);
    }
  });

  return days;
};

const aggregatePerdiemsPerDay = (daysWithBalancedHours) =>
  daysWithBalancedHours.reduce((perdiemsPerDay, day) => {
    if (perdiemsPerDay.get(day.date) === undefined) {
      // eslint-disable-next-line no-param-reassign
      perdiemsPerDay.set(day.date, parseInt(day.perdiem, 10));
    } else {
      // eslint-disable-next-line no-param-reassign
      perdiemsPerDay.set(day.date, parseInt(day.perdiem, 10) + parseInt(perdiemsPerDay.get(day.date), 10), 2);
    }
    return perdiemsPerDay;
  }, new Map());

const calculatePerdiemForDay = (day) => calculatePerdiem(day.duration, day.adjustedPerdiem);

const getTotalExtraHours = (days) => days.reduce((extraHours, day) => extraHours + day.extraHours, 0);

const getDayWithLeastExtraHours = (days, dayWithLeastMissingHours) =>
  minBy(days, (day) => (day === dayWithLeastMissingHours || day.extraHours === 0 ? Number.MAX_VALUE : day.extraHours));

const balanceHoursAcrossDays = (daysToBalance) => {
  let dayWithLeastMissingHours;
  let dayWithLeastExtraHours;
  let totalExtraHours;
  let loopCount = 0;
  do {
    loopCount += 1;
    dayWithLeastMissingHours = minBy(daysToBalance, (day) =>
      day.missingHours === 0 ? Number.MAX_VALUE : day.missingHours
    );
    dayWithLeastExtraHours = getDayWithLeastExtraHours(daysToBalance, dayWithLeastMissingHours);

    totalExtraHours = getTotalExtraHours(daysToBalance);
    if (
      dayWithLeastMissingHours === undefined ||
      dayWithLeastMissingHours.missingHours === 0 ||
      dayWithLeastMissingHours.missingHours > totalExtraHours - dayWithLeastMissingHours.extraHours ||
      totalExtraHours === 0
    ) {
      break;
    }

    fillUpMissingHours(daysToBalance, dayWithLeastMissingHours, dayWithLeastExtraHours);
  } while (canBalanceMore(dayWithLeastMissingHours, totalExtraHours) && loopCount < 100);

  if (loopCount === 100) {
    perror('Looped too much in balanceHoursAcrossDays', loopCount, JSON.stringify(daysToBalance).slice(0, 5000));
    throw Error('Looped too much in balanceHoursAcrossDays');
  }
  return daysToBalance;
};

const fillUpMissingHours = (daysToBalance, dayWithLeastMissingHours, dayWithLeastExtraHours) => {
  let loopCount = 0;
  let totalExtraHours;
  let dayLeastExtra = dayWithLeastExtraHours;
  do {
    totalExtraHours = getTotalExtraHours(daysToBalance);
    loopCount += 1;
    const hoursToAdjust = Math.min(dayWithLeastMissingHours.missingHours, dayLeastExtra.extraHours);
    // eslint-disable-next-line no-param-reassign
    dayWithLeastMissingHours.missingHours -= hoursToAdjust;
    // eslint-disable-next-line no-param-reassign
    dayWithLeastMissingHours.extraHours = (dayWithLeastMissingHours.extraHours + hoursToAdjust) % 3.5;

    adjustDayWithLeastExtraHours(dayLeastExtra, hoursToAdjust);

    if (dayWithLeastMissingHours.missingHours === 0) {
      addHalfPerdiemToDay(dayWithLeastMissingHours);
    }

    if (dayLeastExtra.extraHours === 0) {
      dayLeastExtra = getDayWithLeastExtraHours(daysToBalance, dayWithLeastMissingHours);
    }
  } while (
    hasEnoughHoursToBalance(dayWithLeastMissingHours, dayWithLeastExtraHours, totalExtraHours) &&
    loopCount < 100
  );
  if (loopCount === 100) {
    perror('Looped too much in fillUpMissingHours', loopCount, JSON.stringify(daysToBalance).slice(0, 5000));
    throw Error('Looped too much in fillUpMissingHours');
  }
};

const adjustDayWithLeastExtraHours = (day, hoursToAdjust) => {
  // eslint-disable-next-line no-param-reassign
  day.extraHours -= hoursToAdjust;
  if (getTotalPerdiemForDay(day) <= 0.5) {
    // eslint-disable-next-line no-param-reassign
    day.missingHours = (day.missingHours + hoursToAdjust) % 3.5;
    // eslint-disable-next-line no-param-reassign
    day.missingHours = day.missingHours === 0 ? 3.5 : day.missingHours;
  }
};

const addHalfPerdiemToDay = (day) => {
  // eslint-disable-next-line no-param-reassign
  day.adjustedPerdiem = day.adjustedPerdiem ? day.adjustedPerdiem + 0.5 : 0.5;
  if (getTotalPerdiemForDay(day) < 1) {
    // eslint-disable-next-line no-param-reassign
    day.missingHours = 3.5;
  }
};

const getTotalPerdiemForDay = (day) => parseInt(day.perdiem, 10) / 10 + (day.adjustedPerdiem || 0);

export const getPaddedHoursWorked = (start, end) => {
  const minutes = moment(end).diff(start, 'minutes');

  return padStringWithZeros(((minutes / 60) * 100).toFixed(0), 4);
};
