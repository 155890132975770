import List from '@material-ui/core/List';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = () => ({
  activitiesContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: 800
  },
  activityList: {
    flex: 1,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: 0
  }
});

export function ActivitiesContainer({ classes, className, children }) {
  const mergedClassName = classNames(classes.activitiesContainer, className);
  return (
    <div id="activities-container" className={mergedClassName}>
      <List className={classes.activityList}>{children}</List>
    </div>
  );
}

ActivitiesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

ActivitiesContainer.defaultProps = {
  className: ''
};

export default withStyles(styles)(ActivitiesContainer);
