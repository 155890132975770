import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import FlightLandIcon from '@material-ui/icons/FlightLandRounded';
import FlightTakeOffIcon from '@material-ui/icons/FlightTakeoffRounded';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ALLER } from '../../../../shared/ramq/domainValues/travelTypes';
import isDefined from '../../../../shared/utils/isDefined';
import { displayPlace } from '../../Form/utils/fieldTransformations';
import DetailsItem from '../Act/Details/DetailsItem/DetailsItem';

const styles = () => ({
  detailsOverride: {
    alignItems: 'center'
  },
  detailsContainer: {
    minWidth: 0
  }
});

const getLocationValue = (location) => {
  const locationName = get(location, 'number', '');
  return isDefined(locationName) ? displayPlace(location) : get(location, 'zipCode', '');
};

function TravelDepartureArrival({ activity, classes }) {
  const {
    travelInformation: { startLocation }
  } = activity;
  const {
    travelInformation: { endLocation }
  } = activity;
  const {
    travelInformation: { travelType }
  } = activity;

  return (
    <DetailsItem
      className={classes.detailsOverride}
      dense
      icon={travelType === ALLER ? <FlightTakeOffIcon /> : <FlightLandIcon />}
      text={
        <div className={classes.detailsContainer}>
          <Typography noWrap>{getLocationValue(startLocation)}</Typography>
          <Typography noWrap>{getLocationValue(endLocation)}</Typography>
        </div>
      }
    />
  );
}

TravelDepartureArrival.propTypes = {
  activity: PropTypes.object.isRequired
};

export default withStyles(styles)(TravelDepartureArrival);
