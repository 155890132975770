import { get, has, set } from 'lodash';
import BIOPSY_NOT_RELATED_TO_ACT from '../../../../shared/ramq/requiredDetails/biopsy';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

export const BIOPSY_NOT_RELATED_TO_ACT_NAME = 'biopsie non reliée acte';
export const BIOPSY_ERROR = 'Vous ne pouvez facturer le code de biopsie car il est inclus dans le code principal';
const biopsyCannotBeBillError = (index) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${BIOPSY_NOT_RELATED_TO_ACT}` },
    `codes[${index}].${BIOPSY_NOT_RELATED_TO_ACT}`,
    BIOPSY_ERROR
  );

export const makeSureBiopsyCanBeBill = (act) => {
  const codesInError = act.codes.map((code, index) =>
    code[BIOPSY_NOT_RELATED_TO_ACT] === false ? biopsyCannotBeBillError(index) : {}
  );
  return codesInError.reduce(combineErrors, {});
};

const summaryRender = (code) =>
  has(code, BIOPSY_NOT_RELATED_TO_ACT)
    ? [
        {
          name: BIOPSY_NOT_RELATED_TO_ACT_NAME,
          value: get(code, BIOPSY_NOT_RELATED_TO_ACT) ? 'Oui' : 'Non'
        }
      ]
    : [];

const associatedFields = [
  {
    label: "Biopsie non reliée à l'Acte opératoire?",
    getName: (index) => `codes[${index}].${BIOPSY_NOT_RELATED_TO_ACT}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  }
];

const associatedValidations = [makeSureBiopsyCanBeBill];

export default { associatedFields, associatedValidations, summaryRender };
