export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}

export default class FirebaseConfigProvider {
  private static singleton: FirebaseConfigProvider;

  private firebaseConfig: FirebaseConfig | undefined;

  static getInstance() {
    if (!this.singleton) {
      this.singleton = new FirebaseConfigProvider();
    }

    return this.singleton;
  }

  configure(firebaseConfig: FirebaseConfig) {
    this.firebaseConfig = firebaseConfig;
  }

  getConfig() {
    if (!this.firebaseConfig) {
      // eslint-disable-next-line no-console
      console.warn('Warning: Firebase config is being used before it has been configured.');
    }

    return this.firebaseConfig || {};
  }
}
