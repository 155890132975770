import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import isDefined from '../../../shared/utils/isDefined';

const styles = (theme) =>
  ({
    sectionTitle: {
      fontWeight: 'bold'
    },
    textField: {
      marginTop: theme.spacing(1),
      width: '100%'
    }
  }) as const;

export type BillingRates = Partial<{
  act: number;
  mixte: number;
  forfaitaire: number;
  training: number;
  transport: number;
  monthlyRate: number;

  refugee: number;
  military: number;
  prisonner: number;
  outOfProvince: number;
  abroadStudies: number;
  privateInsurance: number;
}>;

const BILLING_RATE_LABELS = {
  act: 'Acte',
  mixte: 'Mixte',
  forfaitaire: 'Forfaitaire',
  training: 'Formation',
  transport: 'Transport',
  monthlyRate: 'Forfait mensuel',
  refugee: 'Réfugié',
  military: 'Militaire',
  prisonner: 'Prisonnier',
  outOfProvince: 'Hors province',
  abroadStudies: 'Étudiant étranger',
  privateInsurance: 'Assurances privées'
} as const;

const RAMQ_BILLING_RATE_FIELDS: (keyof BillingRates)[] = [
  'act',
  'mixte',
  'forfaitaire',
  'training',
  'transport',
  'monthlyRate'
];
const OUTSIDE_RAMQ_BILLING_RATE_FIELDS: (keyof BillingRates)[] = [
  'refugee',
  'military',
  'prisonner',
  'outOfProvince',
  'abroadStudies',
  'privateInsurance'
];

export interface Props extends WithStyles<typeof styles> {
  value: BillingRates | undefined;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: BillingRates) => void;
  readonly: boolean;
}

export const BillingRateEditor: React.FunctionComponent<Props> = ({ classes, value, onChange, readonly }) => {
  const onRateChanged = (name: keyof BillingRates) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    const newRate = isDefined(newValue) ? Number(newValue) : undefined;
    const newRates = { ...value, [name]: newRate };

    onChange(newRates);
  };

  const renderField = (name: keyof BillingRates) => (
    <Grid item xs={12} key={name}>
      <TextField
        date-testid={`billing-rate-field-${name}`}
        disabled={readonly}
        key={name}
        className={classes.textField}
        label={BILLING_RATE_LABELS[name]}
        value={value?.[name] || ''}
        type="number"
        onChange={onRateChanged(name)}
      />
    </Grid>
  );

  return (
    <Grid item container spacing={3}>
      <Grid item xs={6}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          RAMQ
        </Typography>

        {RAMQ_BILLING_RATE_FIELDS.map(renderField)}
      </Grid>

      <Grid item xs={6}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Hors RAMQ
        </Typography>

        {OUTSIDE_RAMQ_BILLING_RATE_FIELDS.map(renderField)}
      </Grid>
    </Grid>
  );
};

type ReduxBillingRateEditorProps = Omit<Props, 'value' | 'onChange'> & WrappedFieldProps;
const ReduxBillingRateEditor: React.FunctionComponent<ReduxBillingRateEditorProps> = ({ input, ...rest }) => (
  <BillingRateEditor value={input.value && input.value} onChange={(value) => input.onChange(value)} {...rest} />
);

export default withStyles(styles)(ReduxBillingRateEditor);
