import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { useState } from 'react';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import AdminStatus from 'shared/core/activity/domain/AdminStatus';
import { OutsideRamqPatientType } from 'shared/domain/activity/act/model/Act';
import ReportAPI from 'app/infrastructure/api/ReportAPI';
import useTranslate from 'application/hooks/use-translate';
import Spinner from 'application/components/spinner';
import useToast from 'application/hooks/use-toast';
import ActivitiesSearchDialog from '../../containers/ActivitiesSearch/ActivitiesSearchDialog';
import Activity from '../../../shared/domain/activity/global/model/Activity';
import EmptyActivitiesIndicator from '../Activities/EmptyActivitiesIndicator/EmptyActivitiesIndicator';
import { summaryActivitiesMapping } from '../Activities/componentsMappings';

export const styles = (theme) =>
  ({
    activitiesContainer: {
      width: '100%',
      maxWidth: 800,
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    headerCard: {
      width: '98%',
      marginBottom: theme.spacing(3)
    },
    footerWrapper: {
      textAlign: 'center',
      marginBottom: '1em'
    },
    limitExceededTitle: {
      fontWeight: 'bold'
    },
    activityLimitMessage: {
      marginTop: theme.spacing(1)
    },
    circularProgressWrapper: {
      minHeight: 700,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    activityWrapper: {
      'width': '100%',
      'display': 'flex',
      'justifyContent': 'center',
      '& > *': {
        width: '100%'
      }
    }
  }) as const;

interface QueryParams {
  userIds?: string[];
  searchTerm?: string;
  types?: ActivityType[];
  statuses?: ActivityStatus[];
  adminStatuses?: AdminStatus[];
  outsideRamqPatientTypes?: OutsideRamqPatientType[];
  synchronized?: boolean;
  startDate?: number;
  endDate?: number;
}

export interface Props extends WithStyles<typeof styles> {
  activities: Activity[];
  activitiesLoading: boolean;
  nbHits: number;
  query: QueryParams;
}

export const FilteredActivitiesView: React.FunctionComponent<Props> = ({
  activities,
  activitiesLoading,
  nbHits,
  query,
  classes
}) => {
  const translate = useTranslate('reports');
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  return (
    <div className={classes.activitiesContainer}>
      {!activitiesLoading && (
        <Card className={classes.headerCard} data-testid="number-of-hits-card">
          <CardContent style={{ paddingBottom: 16 }} className="flex items-center justify-between">
            <Typography variant="h5" component="h2" className={classes.limitExceededTitle}>
              {nbHits} {nbHits === 1 ? 'activité a été trouvée' : 'activités ont été trouvées'}
            </Typography>
            <button
              type="button"
              className="btn btn-primary"
              disabled={loading || nbHits === 0}
              onClick={async () => {
                setLoading(true);
                ReportAPI.downloadActivitiesSearch(query)
                  .catch((_error) => {
                    addToast({
                      message: translate('error-downloading-report'),
                      type: 'error'
                    });
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              {loading ? <Spinner /> : translate('download')}
            </button>

            {nbHits > 1000 && (
              <Typography
                variant="body1"
                component="h3"
                data-testid="activities-limit-message"
                className={classes.activityLimitMessage}
              >
                Plus de 1000 activités ont été trouvées, veuillez raffiner votre recherche pour plus précision.
              </Typography>
            )}
          </CardContent>
        </Card>
      )}

      {activitiesLoading && (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      )}

      {!activitiesLoading && activities.length === 0 && <EmptyActivitiesIndicator />}

      {!activitiesLoading &&
        activities.length > 0 &&
        activities.map((activity) => {
          const ActivityComponent = summaryActivitiesMapping[activity.type];
          return (
            <div key={activity.id} className={classes.activityWrapper}>
              <ActivityComponent activity={activity} />
            </div>
          );
        })}

      <ActivitiesSearchDialog />
    </div>
  );
};

export default compose(withStyles(styles))(FilteredActivitiesView);
