import React from 'react';
import moment from 'moment-timezone';
import { gql, useMutation, useQuery } from '@apollo/client';
import useTranslate from 'application/hooks/use-translate';
import OptionsMenu from 'application/components/options-menu';
import DataTable from 'application/components/data-table';
import { useToggle } from 'react-use';
import Icon from 'application/components/icon';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { isUserAtLeast } from 'application/utilities/authentication';
import classNames from 'classnames';
import useToastedApolloError from 'application/hooks/use-toast-apollo-error';
import { UserRole } from 'application/types/users';
import { GroupRole } from 'application/types/groups';
import { useSelector } from 'react-redux';
import { RootState } from 'application/types/redux-state';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { USERS_ROUTE, USER_PROFILE } from 'application/routes';
import UsersTypeahead from 'application/components/users-typeahead';

interface Owner {
  id: string;
  fullName: string;
  role: GroupRole;
  userRole: UserRole;
  deletedAt: Date | null;
}

interface Manager {
  id: string;
  fullName: string;
  role: GroupRole;
  userRole: UserRole;
  deletedAt: Date | null;
}

interface Member {
  id: string;
  fullName: string;
  role: GroupRole;
  practiceNumber: string;
  userRole: UserRole;
  deletedAt: Date | null;
}

interface Group {
  id: string;
  usersCount: number;
  owners: Owner[];
  managers: Manager[];
  members: Member[];
}

const GROUP_FRAGMENT = gql`
  fragment GroupFragmentWithRoles on GroupSchema {
    id
    usersCount

    owners {
      id
      fullName
      role
      userRole
      deletedAt
    }

    managers {
      id
      fullName
      role
      userRole
      deletedAt
    }

    members {
      id
      fullName
      role
      practiceNumber
      userRole
      deletedAt
    }
  }
`;

const GROUP_USERS_QUERY = gql`
  query FetchGroupUsers($groupId: String!) {
    group(groupId: $groupId) {
      ...GroupFragmentWithRoles
    }
  }

  ${GROUP_FRAGMENT}
`;

interface AddUserInGroup {
  addUserInGroup: {
    id: string;
  };
}

const ADD_USER_IN_GROUP_MUTATION = gql`
  mutation AddUserInGroup($groupId: String!, $groupUser: GroupUserInput!) {
    addUserInGroup(groupId: $groupId, groupUser: $groupUser) {
      ...GroupFragmentWithRoles
    }
  }

  ${GROUP_FRAGMENT}
`;

interface RemoveUserFromGroup {
  removeUserFromGroup: {
    id: string;
  };
}

const REMOVE_USER_FROM_GROUP_MUTATION = gql`
  mutation RemoveUserFromGroup($groupId: String!, $userId: String!, $deletedAt: DateTime) {
    removeUserFromGroup(groupId: $groupId, userId: $userId, deletedAt: $deletedAt) {
      ...GroupFragmentWithRoles
    }
  }

  ${GROUP_FRAGMENT}
`;

const GroupUsersPage = () => {
  const translate = useTranslate('pages.group.users');
  const translateUserRole = useTranslate('user-role');
  const userRole = useSelector<RootState, Role>((state) => state.authentication.user.role);

  const [isAddingOwner, setIsAddingOwner] = useToggle(false);
  const [isAddingManager, setIsAddingManager] = useToggle(false);
  const [isAddingMember, setIsAddingMember] = useToggle(false);

  const { groupId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { data } = useQuery<{ group: Group }>(GROUP_USERS_QUERY, { variables: { groupId } });
  const toastApolloError = useToastedApolloError();
  const [addUserInGroup] = useMutation<AddUserInGroup>(ADD_USER_IN_GROUP_MUTATION);
  const [removeUserFromGroup] = useMutation<RemoveUserFromGroup>(REMOVE_USER_FROM_GROUP_MUTATION);

  return (
    <>
      <DataTable
        title={translate('owners')}
        count={data?.group.owners.length || 0}
        headerButton={
          isUserAtLeast(userRole, Role.Admin)
            ? {
                label: translate('add-owner'),
                hide: isAddingOwner,
                onClick: () => setIsAddingOwner(true),
                onCancel: () => setIsAddingOwner(false),
                cancelLabel: translate('cancel')
              }
            : undefined
        }
        emptyPlaceholder={translate('no-owners')}
        columns={[
          { title: translate('name'), width: '*' },
          { title: translate('user-role'), width: '*' },
          { title: '', width: '150px' }
        ]}
      >
        {isAddingOwner && (
          <tr>
            <td colSpan={3} className="text-center">
              <div className="flex">
                <UsersTypeahead
                  className="flex-grow"
                  placeholder={translate('add-owner')}
                  userRoles={[UserRole.ADMINISTRATOR, UserRole.DEVELOPER, UserRole.AGENT]}
                  excludedIds={data?.group.owners.map((owner) => owner.id)}
                  onUserSelect={(user: { id: string }) =>
                    addUserInGroup({
                      variables: {
                        groupId,
                        groupUser: {
                          userId: user.id,
                          role: GroupRole.OWNER
                        }
                      }
                    })
                      .then(() => setIsAddingOwner(false))
                      .catch(toastApolloError)
                  }
                />
                <button type="button" className="btn btn-square btn-ghost ml-2" onClick={() => setIsAddingOwner(false)}>
                  <Icon icon="close" />
                </button>
              </div>
            </td>
          </tr>
        )}
        {data?.group.owners.map((owner) => (
          <tr key={owner.id}>
            <td
              className={classNames({ 'link-hover link': isUserAtLeast(userRole, Role.Agent) })}
              onClick={() =>
                isUserAtLeast(userRole, Role.Agent) &&
                navigate({ pathname: `/${USERS_ROUTE}/${owner.id}/${USER_PROFILE}`, search: location.search })
              }
            >
              {owner.fullName}
              {owner.deletedAt && (
                <span className="badge badge-warning ml-2">
                  <Icon icon="warning" className="mr-1" />
                  {translate('deleted-at', { timeFromNow: moment(owner.deletedAt).fromNow() })}
                </span>
              )}
            </td>
            <td>{translateUserRole(owner.userRole)}</td>
            <td className="text-right">
              <Restrict atLeastRole={Role.Agent}>
                <OptionsMenu>
                  <OptionsMenu.Item label={translate('open')} route={`/${USERS_ROUTE}/${owner.id}/${USER_PROFILE}`} />
                  <Restrict atLeastRole={Role.Admin}>
                    <OptionsMenu.Item
                      label={translate('remove')}
                      onClick={() =>
                        removeUserFromGroup({
                          variables: {
                            groupId,
                            userId: owner.id
                          }
                        }).catch(toastApolloError)
                      }
                    />
                  </Restrict>
                </OptionsMenu>
              </Restrict>
            </td>
          </tr>
        ))}
      </DataTable>

      <DataTable
        title={translate('managers')}
        count={data?.group.managers.length || 0}
        headerButton={
          isUserAtLeast(userRole, Role.Admin)
            ? {
                label: translate('add-manager'),
                hide: isAddingManager,
                onClick: () => setIsAddingManager(true),
                onCancel: () => setIsAddingManager(false),
                cancelLabel: translate('cancel')
              }
            : undefined
        }
        emptyPlaceholder={translate('no-managers')}
        columns={[
          { title: translate('name'), width: '*' },
          { title: translate('user-role'), width: '*' },
          { title: '', width: '150px' }
        ]}
      >
        {isAddingManager && (
          <tr>
            <td colSpan={3} className="text-center">
              <div className="flex">
                <UsersTypeahead
                  className="flex-grow"
                  placeholder={translate('add-manager')}
                  excludedIds={data?.group.managers.map((manager) => manager.id)}
                  userRoles={[
                    UserRole.DEVELOPER,
                    UserRole.ADMINISTRATOR,
                    UserRole.AGENT,
                    UserRole.AUDITOR,
                    UserRole.DOCTOR
                  ]}
                  onUserSelect={(user: { id: string }) =>
                    addUserInGroup({
                      variables: {
                        groupId,
                        groupUser: {
                          userId: user.id,
                          role: GroupRole.MANAGER
                        }
                      }
                    })
                      .then(() => setIsAddingManager(false))
                      .catch(toastApolloError)
                  }
                />
                <button
                  type="button"
                  className="btn btn-square btn-ghost ml-2"
                  onClick={() => setIsAddingManager(false)}
                >
                  <Icon icon="close" />
                </button>
              </div>
            </td>
          </tr>
        )}
        {data?.group.managers.map((manager) => (
          <tr key={manager.id}>
            <td
              className={classNames({ 'link-hover link': isUserAtLeast(userRole, Role.Agent) })}
              onClick={() =>
                isUserAtLeast(userRole, Role.Agent) &&
                navigate({ pathname: `/${USERS_ROUTE}/${manager.id}/${USER_PROFILE}`, search: location.search })
              }
            >
              {manager.fullName}
              {manager.deletedAt && (
                <span className="badge badge-warning ml-2">
                  <Icon icon="warning" className="mr-1" />
                  {translate('deleted-at', { timeFromNow: moment(manager.deletedAt).fromNow() })}
                </span>
              )}
            </td>
            <td>{translateUserRole(manager.userRole)}</td>
            <td className="text-right">
              <Restrict atLeastRole={Role.Agent}>
                <OptionsMenu>
                  <OptionsMenu.Item label={translate('open')} route={`/${USERS_ROUTE}/${manager.id}/${USER_PROFILE}`} />
                  <Restrict atLeastRole={Role.Admin}>
                    <OptionsMenu.Item
                      label={translate('remove')}
                      onClick={() =>
                        removeUserFromGroup({
                          variables: {
                            groupId,
                            userId: manager.id
                          }
                        })
                          .then(() => setIsAddingMember(false))
                          .catch(toastApolloError)
                      }
                    />
                    <OptionsMenu.Item
                      label={translate('remove-in-one-day')}
                      onClick={() =>
                        removeUserFromGroup({
                          variables: {
                            groupId,
                            userId: manager.id,
                            deletedAt: moment().add(1, 'day').toISOString()
                          }
                        }).catch(toastApolloError)
                      }
                    />
                    <OptionsMenu.Item
                      label={translate('remove-in-one-week')}
                      onClick={() =>
                        removeUserFromGroup({
                          variables: {
                            groupId,
                            userId: manager.id,
                            deletedAt: moment().add(7, 'days').toISOString()
                          }
                        }).catch(toastApolloError)
                      }
                    />
                  </Restrict>
                </OptionsMenu>
              </Restrict>
            </td>
          </tr>
        ))}
      </DataTable>

      <DataTable
        title={translate('members')}
        count={data?.group.members.length || 0}
        headerButton={
          isUserAtLeast(userRole, Role.Admin)
            ? {
                label: translate('add-member'),
                hide: isAddingMember,
                onClick: () => setIsAddingMember(true),
                onCancel: () => setIsAddingMember(false),
                cancelLabel: translate('cancel')
              }
            : undefined
        }
        emptyPlaceholder={translate('no-members')}
        columns={[
          { title: translate('name'), width: '*' },
          { title: translate('practice-number'), width: '*' },
          { title: translate('user-role'), width: '*' },
          { title: '', width: '150px' }
        ]}
      >
        {isAddingMember && (
          <tr>
            <td colSpan={4} className="text-center">
              <div className="flex">
                <UsersTypeahead
                  className="flex-grow"
                  placeholder={translate('add-member')}
                  excludedIds={data?.group.members.map((member) => member.id)}
                  userRoles={[UserRole.DOCTOR]}
                  showChoicesIfEmpty
                  onUserSelect={(user: { id: string }) =>
                    addUserInGroup({
                      variables: {
                        groupId,
                        groupUser: {
                          userId: user.id,
                          role: GroupRole.MEMBER
                        }
                      }
                    }).catch(toastApolloError)
                  }
                />
                <button
                  type="button"
                  className="btn btn-square btn-ghost ml-2"
                  onClick={() => setIsAddingMember(false)}
                >
                  <Icon icon="close" />
                </button>
              </div>
            </td>
          </tr>
        )}
        {data?.group.members.map((member) => (
          <tr key={member.id}>
            <td
              className={classNames({ 'link-hover link': isUserAtLeast(userRole, Role.Agent) })}
              onClick={() =>
                isUserAtLeast(userRole, Role.Agent) &&
                navigate({ pathname: `/${USERS_ROUTE}/${member.id}/${USER_PROFILE}`, search: location.search })
              }
            >
              {member.fullName}
              {member.deletedAt && (
                <span className="badge badge-warning ml-2">
                  <Icon icon="warning" className="mr-1" />
                  {translate('deleted-at', { timeFromNow: moment(member.deletedAt).fromNow() })}
                </span>
              )}
            </td>
            <td>{member.practiceNumber}</td>
            <td>{translateUserRole(member.userRole)}</td>
            <td className="text-right">
              <Restrict atLeastRole={Role.Agent}>
                <OptionsMenu>
                  <OptionsMenu.Item label={translate('open')} route={`/${USERS_ROUTE}/${member.id}/${USER_PROFILE}`} />
                  <Restrict atLeastRole={Role.Admin}>
                    <OptionsMenu.Item
                      label={translate('remove')}
                      onClick={() =>
                        removeUserFromGroup({
                          variables: {
                            groupId,
                            userId: member.id
                          }
                        }).catch(toastApolloError)
                      }
                    />
                    <OptionsMenu.Item
                      label={translate('remove-in-one-day')}
                      onClick={() =>
                        removeUserFromGroup({
                          variables: {
                            groupId,
                            userId: member.id,
                            deletedAt: moment().add(1, 'day').toISOString()
                          }
                        }).catch(toastApolloError)
                      }
                    />
                    <OptionsMenu.Item
                      label={translate('remove-in-one-week')}
                      onClick={() =>
                        removeUserFromGroup({
                          variables: {
                            groupId,
                            userId: member.id,
                            deletedAt: moment().add(7, 'days').toISOString()
                          }
                        }).catch(toastApolloError)
                      }
                    />
                  </Restrict>
                </OptionsMenu>
              </Restrict>
            </td>
          </tr>
        ))}
      </DataTable>
    </>
  );
};

export default GroupUsersPage;
