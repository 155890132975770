import { logDefault } from '../shared/utils/plog';

const lockedOnMap = {};

export const acquireLock = (lockName) => {
  if (lockedOnMap[lockName] !== undefined) {
    logDefault({
      type: 'StorageUploaderLock',
      text: 'acquireLock',
      array: ['Upload lock could not be acquired', lockName]
    });

    return false;
  }

  lockedOnMap[lockName] = Date.now();
  logDefault({
    type: 'StorageUploaderLock',
    text: 'acquireLock',
    array: ['Upload lock acquired', lockName, 'at', lockedOnMap[lockName]]
  });

  return true;
};

export const isLocked = (lockName) => lockedOnMap[lockName] !== undefined;

export const releaseLock = (lockName) => {
  lockedOnMap[lockName] = undefined;
  logDefault({
    type: 'StorageUploaderLock',
    text: 'releaseLock',
    array: ['Upload lock released', lockName]
  });
};
