import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { getDateDayAbbreviation, getDateDayNumber } from '../../../../../../utils/dateTimeUtils';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import AddPeriodDayUnitButton from '../../../PeriodHeader/AddPeriodDayUnitButton/AddPeriodDayUnitButton';
import DynamicPeriodCodesSelection from './DynamicPeriodCodesSelector/DynamicPeriodCodesSelector';
import DynamicPeriodTimeSelection from './DynamicPeriodTimeSelection/DynamicPeriodTimeSelection';
import ActivityAreaSelector from '../../../../../activityArea/component/ActivityAreaSelector/ActivityAreaSelector';
import ActivityType from '../../../../../../shared/core/activity/domain/ActivityType';
import ActivityAreaChoiceContextFactory from '../../../../../activityArea/domain/ActivityAreaChoiceContextFactory';
import DayAmountField from './DayAmountField/DayAmountField';

const styles = ({ spacing, palette, breakpoints }) => ({
  unitContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: spacing.unit,
    paddingRight: spacing(3),
    [breakpoints.down('xs')]: {
      justifyContent: 'start',
      paddingLeft: 0
    }
  },
  manualEditionMode: {
    backgroundColor: '#fff4e5'
  },
  dayInfo: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'flex': 1,
    '& > *': {
      padding: spacing.unit
    }
  },
  periodContainer: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      flex: 3
    }
  },
  codeSelection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 2,
    [breakpoints.down('xs')]: {
      flex: 3
    }
  },
  periodAmount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100px',
    flex: 2,
    paddingRight: spacing(3),
    [breakpoints.down('xs')]: {
      flex: 3
    }
  },
  noFullFlex: {
    flex: '0 1 auto',
    minWidth: 92
  },
  holidayChip: {
    flex: '0 1 auto',
    minWidth: 92,
    backgroundColor: palette.secondary.main,
    color: palette.common.white,
    zIndex: 3
  },
  addPeriodButtonContainer: {
    height: '38px',
    display: 'flex',
    alignItems: 'center'
  }
});

class DayUnit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorContainer: null
    };
  }

  errorContainerRef = (ref) => {
    this.setState({ errorContainer: ref });
  };

  render() {
    const { fieldName, fieldIndex, fields, onChange, disabled, activityType, place, classes } = this.props;
    const { date, start, end, manualEditionMode } = fields.get(fieldIndex);

    return (
      <>
        <div className={classnames(classes.unitContainer, { [classes.manualEditionMode]: manualEditionMode })}>
          <div className={classes.dayInfo}>
            <Typography variant="caption">{getDateDayAbbreviation(date)}</Typography>
            <Typography variant="body2">{getDateDayNumber(date)}</Typography>
          </div>
          <div className={classes.periodContainer}>
            <DynamicPeriodTimeSelection
              date={date}
              fieldName={fieldName}
              onChange={onChange}
              activityType={activityType}
              startDateTime={start}
              endDateTime={end}
            />
          </div>
          <div className={classes.codeSelection}>
            {disabled ? (
              <Chip label={<Typography variant="button">Férié</Typography>} className={classes.holidayChip} />
            ) : (
              <FormElement
                id="day-code-selector"
                errorContainer={this.state.errorContainer}
                name={`${fieldName}.code`}
                onChange={onChange}
                component={DynamicPeriodCodesSelection}
                className={classes.noFullFlex}
                activityType={activityType}
              />
            )}
            <span className={classes.addPeriodButtonContainer}>
              <AddPeriodDayUnitButton date={date} fieldIndex={fieldIndex} fields={fields} activityType={activityType} />
            </span>
          </div>
          <Restrict atLeastRole={Role.Agent}>
            <div className={classes.periodAmount}>
              <DayAmountField activityType={activityType} fieldName={fieldName} place={place} />
            </div>
          </Restrict>
        </div>
        {[ActivityType.MIXTE, ActivityType.LUMP_SUM].includes(activityType) && (
          <ActivityAreaSelector
            formField={`${fieldName}.activityArea`}
            onChange={onChange}
            activityAreas={ActivityAreaChoiceContextFactory.create(activityType)}
          />
        )}
        <div ref={this.errorContainerRef} />
      </>
    );
  }
}

DayUnit.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  fields: PropTypes.object.isRequired,
  place: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  activityType: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

DayUnit.defaultProps = {
  disabled: false,
  onChange: () => {}
};

export default withStyles(styles)(DayUnit);
