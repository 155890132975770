import { ActCode } from './ActCode';
import { RequiredDetails } from '../requiredDetail.type';
import dateHospitalisation from '../DateHospitalisation/dateHospitalisation';
import intensiveCareExceptionalCircumstances from '../ExceptionalCircumstances/intensiveCareExceptionalCircumstances';
import HOSPITAL_ACTIVITY_AREA_MAP from '../../../../shared/ramq/domainValues/activityAreas';

export default class ActCode52 implements ActCode {
  // eslint-disable-next-line no-unused-vars
  constructor(private act: any) {}

  getRequiredDetails(): RequiredDetails {
    const requiredDetails = [dateHospitalisation];

    if (this.act.activityArea === HOSPITAL_ACTIVITY_AREA_MAP.intensiveCareUnit) {
      requiredDetails.push(intensiveCareExceptionalCircumstances);
    }

    return requiredDetails;
  }
}
