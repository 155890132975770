import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { WithStyles } from '@material-ui/core';
import TimeItem from './TimeItem';

const styles = () => ({
  timeInfo: {
    display: 'flex',
    justifyContent: 'center'
  }
});

interface Props extends WithStyles<typeof styles> {
  title: string;
  itemSize: number;
  /* eslint-disable no-unused-vars */
  isSelectedFunction: (value: string) => boolean;
  onItemClick: (value: string) => void;
  /* eslint-enable */
  values: string[];
}

const TimeItems: React.FunctionComponent<Props> = ({
  title,
  itemSize,
  isSelectedFunction,
  onItemClick,
  values,
  classes
}) => (
  <>
    <Typography variant="caption" className={classes.timeInfo}>
      {title}
    </Typography>
    <Grid container spacing={0}>
      {values.map((value) => (
        <TimeItem
          key={value}
          size={itemSize}
          value={value}
          isSelected={isSelectedFunction(value)}
          onClick={onItemClick}
        />
      ))}
    </Grid>
  </>
);

TimeItems.propTypes = {
  title: PropTypes.string.isRequired,
  itemSize: PropTypes.number.isRequired,
  isSelectedFunction: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired
};

export default withStyles(styles)(TimeItems);
