import { createSelector } from '@reduxjs/toolkit';
import { selectMultiActFormSelector } from '../selectors';

export const isCreatingActs = () => createSelector(selectMultiActFormSelector, (state) => state.creatingActs);

export const isErrorWhileCreatingActs = () =>
  createSelector(selectMultiActFormSelector, (state) => state.errorWhileCreatingActs);

export const selectExtractedNams = () =>
  createSelector(selectMultiActFormSelector, (state) => state.extractedNams ?? []);
