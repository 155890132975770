export default class MixteSummaryDayLineActivity {
  private _code: string;

  private _dispensatoryArea: string;

  private _duration: string;

  private _reference: number;

  constructor(code: string, dispensatoryArea: string, duration: string, reference: number) {
    this._code = code;
    this._dispensatoryArea = dispensatoryArea;
    this._duration = duration;
    this._reference = reference;
  }

  public get code(): string {
    return this._code;
  }

  public get dispensatoryArea(): string {
    return this._dispensatoryArea;
  }

  public get duration(): string {
    return this._duration;
  }

  public get reference(): number {
    return this._reference;
  }
}
