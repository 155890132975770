import findIndex from 'lodash/findIndex';

function isSamePracticeNumber(aDoctor, anotherDoctor) {
  return aDoctor.practiceNumber === anotherDoctor.practiceNumber;
}

function isDoctorInDoctorsPreferences(doctor, doctorsPreferences) {
  return (
    findIndex(doctorsPreferences, (doctorInPreferences) => isSamePracticeNumber(doctorInPreferences, doctor)) !== -1
  );
}

export default isDoctorInDoctorsPreferences;
