import isDefined from '../../../../../shared/utils/isDefined';

export const REASSIGNMENT_ACT_CODES = ['42188', '42191'];

export const isReassignmentActCode = ({ code }: { code: string }) => REASSIGNMENT_ACT_CODES.includes(code);

export const getReassignmentActRelatedMixteSuffix = (actCode: any) => {
  let mixteSuffix = '362';

  if (actCode.remote) {
    mixteSuffix = '372';
  }

  if (actCode.canceledActivities) {
    mixteSuffix = '366';
  }

  if (isDefined(actCode.particularSituation)) {
    if (actCode.particularSituation === 'infected') {
      if (actCode.remote) {
        mixteSuffix = '369';
      }
      if (actCode.telemedicine) {
        mixteSuffix = '374';
      }
    } else if (actCode.particularSituation === 'quarantine') {
      mixteSuffix = '375';
    } else if (actCode.telemedicine || actCode.remote) {
      mixteSuffix = '371';
    } else {
      mixteSuffix = '379';
    }
  }

  return mixteSuffix;
};
