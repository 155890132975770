import { formatUnixToDate } from '../../../../shared/utils/dateTime/dateTimeUtils';
import DefaultActValueDateField from '../../../components/Form/ActSpecificField/DefaultActValueDateField/DefaultActValueDateField';

const summaryRender = (code) => [
  {
    name: 'admission',
    value: code.dateHospitalisation ? formatUnixToDate(code.dateHospitalisation) : '-'
  }
];

const associatedFields = [
  {
    label: "Date d'admission",
    getName: (index) => `codes[${index}].dateHospitalisation`,
    type: DefaultActValueDateField
  }
];

const dateHospitalisation = { associatedFields, summaryRender };

export default dateHospitalisation;
