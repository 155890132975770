import shortid from 'shortid';
import { put, select } from 'redux-saga/effects';
import { pickBy, identity } from 'lodash';
import moment from 'moment-timezone';
import CreateActUseCase from 'app/act/shared/usecases/CreateActUseCase';
import { SECONDS_IN_MINUTE } from 'app/containers/ActForm/HourMinuteSelector/HourMinute/HourMinute';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import { clearMultiActFormPatients, setMultiActFormStart } from 'app/containers/ReduxForm/actions';
import RAMQCodes from 'shared/ramq/RamqCodes';
import { reinitMultiActDialog, closeMultiActDialog, multiActProgressUpdate } from '../../dialog/actions';
import { selectPlacesPreferenceFromUser } from '../../../../../containers/User/selectors';
import { selectActivitiesInContext } from '../../../../../containers/Activities/selectors';
import { selectMultiActPicture } from '../../fileUpload/selectors';
import { createActsFromNamsError, createActsFromNamsStart } from '../actions';
import { selectCurrentLoggedUser } from '../../../../../containers/Authentication/selectors';
import { logError } from '../../../../../../shared/utils/plog';
import { trackErrorInFirestore } from '../../../../../firebase/document/documentSagas';
import uploadFileAndSaveTasksForAct from './uploadFileAndSaveTasksForAct';
import { selectExtractedNams } from '../../nams/selectors';

const PATIENT_CARE_CODES = ['9097', '9098'];

export default function* createActsFromNamsProcess(action) {
  yield put(createActsFromNamsStart());

  try {
    const placesPreference = yield select(selectPlacesPreferenceFromUser());
    const activitiesInContext = yield select(selectActivitiesInContext());
    const extractedNams = yield select(selectExtractedNams());
    const user = yield select(selectCurrentLoggedUser());
    const picture = yield select(selectMultiActPicture());

    const createActUseCase = new CreateActUseCase(placesPreference, activitiesInContext);

    const {
      start,
      codes: globalCodes = [],
      interval: globalInterval = null,
      visitCode: globalVisitCode = null,
      ...remainingActProps
    } = action.actProps;
    const sanitizedRemainingActProps = pickBy(remainingActProps, identity);

    let actStart = moment(start);
    let actEnd;

    let shouldSkipSupportPlanOptimization = false;

    for (let actIndex = 0; actIndex < action.patients.length; actIndex++) {
      const { nam, interval = null, visitCode = null } = action.patients[actIndex];

      const effectiveInterval = interval ?? globalInterval ?? null;

      actEnd = effectiveInterval ? actStart.clone().add(effectiveInterval * SECONDS_IN_MINUTE, 'minutes') : null;

      yield put(multiActProgressUpdate(nam, actIndex + 1, action.patients.length));

      const effectiveVisitCode = visitCode ?? globalVisitCode ?? null;
      const effectiveVisitRamqCode = effectiveVisitCode ? RAMQCodes.get(effectiveVisitCode) : undefined;

      const effectiveCodes = [...globalCodes];

      if (effectiveVisitRamqCode && effectiveCodes.every((code) => code.code !== effectiveVisitRamqCode.code)) {
        effectiveCodes.push({
          id: shortid.generate(),
          ...effectiveVisitRamqCode
        });
      }

      const hasPatientCareCode = effectiveCodes.some((code) => PATIENT_CARE_CODES.includes(code.code));

      const act = createActUseCase.createWithDefaultProperties({
        nam,
        status: ActivityStatus.WAITING,
        start: actStart.valueOf(),
        onlineSave: navigator.onLine,
        codes: effectiveCodes,
        ...(actEnd && { end: actEnd.valueOf() }),
        ...(extractedNams.length > 0 && { extractedNams }),
        ...(hasPatientCareCode && { skipSupportPlanOptimization: shouldSkipSupportPlanOptimization }),
        ...sanitizedRemainingActProps
      });

      shouldSkipSupportPlanOptimization ||= hasPatientCareCode;
      actStart = actEnd ?? actStart.add(1, 'millisecond');

      yield* uploadFileAndSaveTasksForAct(act, user.uid, picture);
    }

    if (action.closeAfter) {
      yield put(closeMultiActDialog());
    } else {
      yield put(reinitMultiActDialog());
      yield put(clearMultiActFormPatients());
      yield put(setMultiActFormStart(actStart.valueOf()));
    }
  } catch (error) {
    logError({
      type: 'saga',
      text: 'MultiActDialogSagas/createActsFromNamsStart',
      array: ['createActsFromNamsStart ERROR', action, error]
    });
    yield put(createActsFromNamsError());
    yield* trackErrorInFirestore(error, { type: 'createActsFromNamsError' });
  }
}
