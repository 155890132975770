import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { formatUnixToDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import fetchActivityHistory from '../../../../adminConsole/usesCases/fetchActivityHistory';
import UserName from '../../../../adminConsole/ui/components/UserName/UserName';

export const styles = (theme) => ({
  dateContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white,
    top: 0,
    boxShadow: theme.shadows[1],
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  text: {
    marginLeft: theme.spacing(1)
  },
  createdOn: {
    marginRight: theme.spacing(1)
  }
});

export function ActSummaryActivityHeader({ activity, classes, intl }) {
  const [createdBy, setCreatedBy] = React.useState('');

  useEffect(() => {
    const getCreatedBy = async () => {
      const activityHistory = await fetchActivityHistory(activity.id);
      if (activityHistory.length > 0) {
        return activityHistory[0].auditInformation?.modifiedBy;
      }
    };

    getCreatedBy().then((userId) => setCreatedBy(userId));
  }, [activity]);

  return (
    <div className={classes.dateContainer}>
      <Typography color="inherit" className={classes.text}>
        {formatUnixToDate(activity.date)}
      </Typography>
      {createdBy && (
        <Typography color="inherit" className={classes.createdOn}>
          {intl.formatMessage({ id: 'global.createdOn' })} {formatUnixToDate(activity.createdOn)}{' '}
          {intl.formatMessage({ id: 'global.by' })} <UserName userId={createdBy} />
        </Typography>
      )}
    </div>
  );
}

ActSummaryActivityHeader.propTypes = {
  activity: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
};

export default compose(injectIntl, withStyles(styles))(ActSummaryActivityHeader);
