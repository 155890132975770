import moment, { Moment } from 'moment-timezone';
import { deburr } from 'lodash';
import specialistActCodes from '../data/specialist-act-codes.json';
import { NatureType } from '../core/activity/domain/Act';
import SpecialtyName, { getUserSpecialtyId } from '../core/doctor/domain/SpecialtyName';

interface MeasurementElement {
  code: string;
  name: string;
  unitType: string;
  effectiveStartDate: Date;
  effectiveEndDate?: Date;
}

export interface Unit {
  role: string;
  units: string;
}

interface TimedAttribute {
  key: string;
  value: unknown;
  effectiveStartDate: string | null;
  effectiveEndDate: string | null;
}

export enum TimedAttributeKey {
  isAddendum8 = 'isAddendum8',
  isPainDay = 'isPainDay',
  isPainDayExclusive = 'isPainDayExclusive',
  isPainDayCompatible = 'isPainDayCompatible'
}

export interface RAMQCode {
  code: string;
  description: string;
  natureType: NatureType;
  effectiveStartDate: string;
  effectiveEndDate?: string;
  relatedCodes: string[];
  roles: number[];
  measurementElements: MeasurementElement[];
  isPlaceOnly: boolean;
  isSupplement: boolean;
  units: Unit[];
  unitsBySpecialty: {
    [specialtyKey: string]: Unit[];
  };
  roleMetadata: ActCodeRole[];
  timedAttributes: TimedAttribute[];
}

interface ActCodeRole {
  role: string;
  effectiveEndDate: string;
  effectiveStartDate: string;
  roleName: string;
  specialty: string;
  specialtyName: string;
  units: number;
}

export class RAMQCodes {
  private static ramqCodes: Map<string, RAMQCode>;

  static get codesMap() {
    if (!this.ramqCodes) {
      this.ramqCodes = this.buildRamqDict();
    }

    return this.ramqCodes;
  }

  private static buildRamqDict(): Map<string, RAMQCode> {
    const codesMap = new Map<string, RAMQCode>();
    (specialistActCodes as RAMQCode[]).forEach((entry) => codesMap.set(entry.code, entry));
    return codesMap;
  }

  public static search(
    searchTerm: string,
    serviceDate: Moment | number | undefined = undefined,
    maxResult: number = 21
  ): RAMQCode[] {
    const searchTokens = deburr(searchTerm).toLowerCase().split(' ');
    const date = serviceDate ? moment(serviceDate) : null;

    const foundCodes: RAMQCode[] = [];
    for (const code of Array.from(this.codesMap.values())) {
      const descriptionLower = deburr(code.description).toLowerCase();
      if (
        (!date || this.isCodeEffectiveOn(code, date)) &&
        searchTokens.every((searchToken) => code.code === searchToken || descriptionLower.includes(searchToken))
      ) {
        foundCodes.push(code);
      }
      if (foundCodes.length === maxResult) {
        break;
      }
    }

    return foundCodes;
  }

  public static get(code: string): RAMQCode | undefined {
    return this.codesMap.get(code);
  }

  public static getTimedAttribute<T>(ramqCode: RAMQCode, attributeKey: TimedAttributeKey, date: Moment | number) {
    const attribute = ramqCode.timedAttributes.find(({ key }) => attributeKey === key);

    if (!attribute) {
      return undefined;
    }

    if (attribute.effectiveStartDate && moment(date).isBefore(attribute.effectiveStartDate)) {
      return undefined;
    }

    if (attribute.effectiveEndDate && moment(date).isAfter(attribute.effectiveEndDate)) {
      return undefined;
    }

    return attribute.value as T;
  }

  public static isCodeEffectiveOn(ramqCode: RAMQCode, serviceDate: Moment | number | undefined): boolean {
    const date = moment(serviceDate); // When serviceDate is undefined, fallback on current date

    if (!ramqCode.effectiveEndDate) {
      return date.isSameOrAfter(ramqCode.effectiveStartDate);
    }

    return date.isBetween(
      moment(ramqCode.effectiveStartDate),
      moment(ramqCode.effectiveEndDate),
      undefined, // Granularity
      '[]' // Inclusivity
    );
  }

  public static getRolesForCodeEffectiveOnAndForSpecialty(
    code: string,
    serviceDate: Moment | number,
    specialtyName?: SpecialtyName
  ): string[] {
    const FALLBACK_ROLE_LIST = ['1'];
    const ramqCode = this.get(code);

    if (ramqCode === undefined) {
      return FALLBACK_ROLE_LIST;
    }

    const isForUserSpecialty = ({ specialty }) =>
      specialtyName === undefined || specialty === null || specialty === getUserSpecialtyId(specialtyName);
    const isEffective = ({ effectiveEndDate, effectiveStartDate }) => {
      if (!effectiveEndDate) {
        return moment(serviceDate).isSameOrAfter(effectiveStartDate);
      }

      return moment(serviceDate).isBetween(
        moment(effectiveStartDate),
        moment(effectiveEndDate),
        undefined, // Granularity
        '(]' // Inclusivity
      );
    };
    const roleMetadata = ramqCode.roleMetadata || [];
    const roles = roleMetadata
      .filter(isForUserSpecialty)
      .filter(isEffective)
      .map(({ role }) => role)
      .sort();

    return roles.length === 0 ? FALLBACK_ROLE_LIST : roles;
  }

  public static getRoleUnitsForSpecialty(code: string, specialtyName: SpecialtyName): Unit[] {
    const ramqCode = this.get(code);

    if (ramqCode === undefined) {
      return [];
    }

    const { unitsBySpecialty } = ramqCode;

    return unitsBySpecialty[getUserSpecialtyId(specialtyName)] || unitsBySpecialty.default;
  }
}

export default RAMQCodes;
