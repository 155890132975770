import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { AssociatedField, RequiredDetail } from '../requiredDetail.type';

export const PLACE_TYPES = [
  { label: 'Site non traditionnel non ambulatoire (SNT)', value: 'SNT' },
  { label: 'Site ambulatoire ou Clinique désignée de dépistage (CDD)', value: 'CDD' },
  { label: "Clinique désignée d'évaluation (CDÉ)", value: 'CDE' }
];

const associatedFields: AssociatedField[] = [
  {
    label: 'Choix du lieu',
    getName: (index) => `codes[${index}].placeSelection`,
    type: SingleSelectCheckboxGroup,
    list: PLACE_TYPES
  }
];

const placeSelection: RequiredDetail = {
  associatedFields
};

export default placeSelection;
