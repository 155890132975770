import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import find from 'lodash/find';
import { createStructuredSelector } from 'reselect';
import { useDebounce } from 'react-use';
import { TextField } from '@material-ui/core';
import ListSelector from '../../../components/Form/ListSelector/ListSelector';
import CodeSuggestion from '../../../components/List/Item/CodeSuggestion/CodeSuggestion';
import { updateSearchValue } from '../../../containers/SearchSuggestions/actions';
import { SearchSuggestions } from '../../../containers/SearchSuggestions/SearchSuggestions';
import messages from '../../../containers/CodeSearchDialog/messages';
import medicalClassificationSystemList from '../../../../data/medical-classification-system.json';
import EditDiagnosticDialog from './EditItemDialog/EditItemDialog';
import { selectDiagnosticCodePreferencesFromUser } from '../../../containers/User/selectors';
import { addDiagnosticCodeToUserPreferences } from '../adapters/actions';
import { isInCollection } from '../../shared/domain/isInFavoritesFunction';
import { RAMQDiagnostic, RAMQDiagnostics } from '../../../../shared/ramq/RamqDiagnostics';
import { selectSelectedDate } from '../../../containers/ActForm/selectors';

export const styles = (theme) =>
  ({
    grid: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3)
    },
    searchInput: {
      width: `calc(100% - ${theme.spacing(2)}px)`
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  onClose: () => void;
  canAddItemToFavorites: boolean;
  diagnosticCodesWithMarkup: string[];
  onItemSelected: (diagnostic: RAMQDiagnostic) => void;
  favoritesDiagnosticCodes: unknown;
  addToFavorites: (diagnostic: RAMQDiagnostic) => void;
  currentActDate: number;
}

export const SingleCodeSearch: React.FC<Props> = ({
  canAddItemToFavorites,
  onItemSelected,
  favoritesDiagnosticCodes,
  diagnosticCodesWithMarkup,
  addToFavorites,
  currentActDate,
  classes
}: Props) => {
  const [cimFilter, setCimFilter] = useState<string>(
    find(medicalClassificationSystemList, ['defaultValue', true]).value
  );
  const [editDiagnosticDialogOpen, setEditDiagnosticDialogOpen] = useState(false);
  const [selectedDiagnostic, setSelectedDiagnostic] = useState<RAMQDiagnostic | undefined>();
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [options, setOptions] = useState<RAMQDiagnostic[]>([]);

  useDebounce(() => setDebouncedInputValue(inputValue), 300, [inputValue]);

  const handleItemSelected = (value) => {
    onItemSelected(value);
    updateSearchValue('');
  };

  const isInFavorites = (diagnostic) => isInCollection(favoritesDiagnosticCodes, diagnostic);
  const isWithMarkup = (diagnostic) => diagnosticCodesWithMarkup.includes(diagnostic.code);

  const closeEditDiagnosticDialog = () => {
    setEditDiagnosticDialogOpen(false);
    setSelectedDiagnostic(undefined);
  };

  const handleFavoriteClick = (diagnostic) => {
    if (isInFavorites(diagnostic)) {
      return;
    }

    setEditDiagnosticDialogOpen(true);
    setSelectedDiagnostic(diagnostic);
  };

  const updateSelectedDiagnosticDescription = (newDescription) => {
    if (!selectedDiagnostic) return;

    addToFavorites({
      ...selectedDiagnostic,
      description: newDescription
    });

    closeEditDiagnosticDialog();
  };

  const handleCIMFilterSelected = (event) => {
    setCimFilter(event.target.value);
  };

  useEffect(() => {
    if (!debouncedInputValue) {
      setOptions([]);
    } else {
      setOptions(RAMQDiagnostics.search(debouncedInputValue, cimFilter, currentActDate));
    }
  }, [debouncedInputValue, cimFilter, currentActDate]);

  return (
    <>
      <Grid container className={classes.grid}>
        <Grid item xs={8} sm={9}>
          <TextField
            className={classes.searchInput}
            onChange={(e) => setInputValue(e.target.value)}
            label={<FormattedMessage {...messages.codeSearchLabel} />}
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <ListSelector
            label="Version CIM"
            values={medicalClassificationSystemList}
            input={{ value: cimFilter, onChange: handleCIMFilterSelected }}
            formControlProps={{ fullWidth: true }}
          />
        </Grid>
      </Grid>
      <DialogContent>
        <SearchSuggestions
          hits={options}
          onSuggestionClick={handleItemSelected}
          clearSearch={() => setInputValue('')}
          isInFavoritesFunction={isInFavorites}
          isWithMarkupFunction={isWithMarkup}
          SuggestionItemComponent={CodeSuggestion}
          onFavoriteClick={canAddItemToFavorites ? handleFavoriteClick : undefined}
          getKeyFunction={(suggestion) => suggestion.code}
        />
      </DialogContent>

      <EditDiagnosticDialog
        open={editDiagnosticDialogOpen}
        key={selectedDiagnostic && selectedDiagnostic.code}
        mode="append"
        diagnostic={selectedDiagnostic}
        onSave={updateSelectedDiagnosticDescription}
        onCancel={closeEditDiagnosticDialog}
      />
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  favoritesDiagnosticCodes: selectDiagnosticCodePreferencesFromUser(),
  currentActDate: selectSelectedDate()
});

export const mapDispatchToProps = (dispatch) => ({
  addToFavorites: (code) => dispatch(addDiagnosticCodeToUserPreferences(code))
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SingleCodeSearch);
