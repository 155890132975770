import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectActCodeSpecialtyFavoritesFromUser,
  selectActCodeSpecialtyPresetFromUser
} from '../../adapters/selectors';

export const withActCodePresetSpecialtyList = (Component) => {
  const mapStateToProps = createStructuredSelector({
    specialtyList: selectActCodeSpecialtyPresetFromUser()
  });

  return connect(mapStateToProps)(Component);
};

export const withActCodeFavoritesSpecialtyList = (Component) => {
  const mapStateToProps = createStructuredSelector({
    specialtyList: selectActCodeSpecialtyFavoritesFromUser()
  });

  return connect(mapStateToProps)(Component);
};
