import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocalStorage } from 'react-use';
import { useParams } from 'react-router-dom';
import ActivitiesContainer from '../../../../components/Activities/ActivitiesContainer/ActivitiesContainer';
import { dayActivitiesMapping } from '../../../../components/Activities/componentsMappings';
import EmptyDayIndicator from '../../../../components/Activities/EmptyDayIndicator/EmptyDayIndicator';
import GroupedByTimeActivities from '../../../../components/Activities/GroupedByTimeActivities/GroupedByTimeActivities';
import HeaderPortal from '../../../../components/Portals/HeaderPortal/HeaderPortal';
import { selectActivitiesByDayInContext, selectActivitiesLoading } from '../../../Activities/selectors';
import ActivitiesSearchDialog from '../../../ActivitiesSearch/ActivitiesSearchDialog';
import ActivitiesOptions from './ActivitiesOptions/ActivitiesOptions';
import DaySwitcher from './DaySwitcher/DaySwitcher';
import TotalAmountLine from './TotalAmountLine/TotalAmountLine';
import MultiActDialog from '../../../../act/multiAct/ui/component/MultiActDialog/MultiActDialog';
import { LocalStorageKeys } from '../../../../../userCustomization/LocalStorageKeys';
import ClearImpersonnification from './ClearImpersonnification/ClearImpersonnification';
import CopyUserInfos from './CopyUserInfos/CopyUserInfos';
import ExportImportActivities from './ExportImportActivities/ExportImportActivities';

export function DayActivities({ activitiesLoading }) {
  const [hideCanceled] = useLocalStorage(LocalStorageKeys.HIDE_CANCELED_ACTIVITIES, false);
  const { date } = useParams();
  const activities = useSelector(selectActivitiesByDayInContext(date));
  const [activityFilterOnBillingType, setActivityFilterOnBillingType] = useState(null);

  const displayedActivites = useMemo(() => {
    if (!activityFilterOnBillingType) return activities;

    return activities.filter((activity) => activity.billingType === activityFilterOnBillingType);
  }, [activities, activityFilterOnBillingType]);

  return (
    <>
      <HeaderPortal>
        <DaySwitcher />
        <ExportImportActivities />
        <CopyUserInfos />
        <ClearImpersonnification />
        <TotalAmountLine
          activities={displayedActivites}
          onActivityFilterChange={setActivityFilterOnBillingType}
          currentFilter={activityFilterOnBillingType}
        />
      </HeaderPortal>

      <ActivitiesContainer>
        <GroupedByTimeActivities
          mixteOnTop
          componentsMapping={dayActivitiesMapping}
          activitiesLoading={activitiesLoading}
          activities={displayedActivites}
          EmptyIndicatorComponent={() => <EmptyDayIndicator />}
          hideCanceled={hideCanceled}
        />
      </ActivitiesContainer>

      <ActivitiesSearchDialog />
      <MultiActDialog />

      <ActivitiesOptions id="activities-options" color="primary" icon={<AddIcon />} activities={activities} />
    </>
  );
}

DayActivities.propTypes = {
  activitiesLoading: PropTypes.bool.isRequired
};

export const mapStateToProps = createStructuredSelector({
  activitiesLoading: selectActivitiesLoading()
});

export default connect(mapStateToProps)(DayActivities);
