import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import classnames from 'classnames';
import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import { getDateDayAbbreviation, getFullDate } from '../../../../../../utils/dateTimeUtils';
import RemoteActivityChip from '../../../RemoteActivityChip/RemoteActivityChip';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import { DayWithComputedAmount } from '../../../../../../shared/amountComputation/amount/PeriodAmountService';
import { MixteDay } from '../../../../../../shared/domain/activity/mixte/model/Mixte';
import { LumpSumDay } from '../../../../../../shared/domain/activity/lumpSum/model/LumpSum';
import PeriodCodeRow from '../PeriodCodeRow/PeriodCodeRow';
import { LatestMixteDayPeriodFormDto } from '../../../../../containers/PeriodsBilling/PeriodForm/type';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  dayContainer: {
    'paddingLeft': theme.spacing(2),
    'paddingRight': theme.spacing(2),
    'paddingTop': theme.spacing(1),
    'paddingBottom': theme.spacing(1),
    'borderBottom': `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: theme.spacing(1)
    }
  },
  manualEditionMode: {
    backgroundColor: '#fff4e5'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  upperCased: {
    textTransform: 'uppercase'
  },
  manualCorrection: {
    // @ts-ignore
    color: theme.palette.red[300]
  }
});

export interface Props {
  dayWithComputedAmount: DayWithComputedAmount;
  classes?: any;
  TimeSummaryComponent: any;
}

export const PeriodDayRow: React.FunctionComponent<Props> = ({
  dayWithComputedAmount,
  TimeSummaryComponent,
  classes
}) => (
  <Grid
    container
    className={classnames(classes.dayContainer, {
      [classes.manualEditionMode]: (dayWithComputedAmount as MixteDay | LumpSumDay).manualEditionMode!
    })}
    spacing={0}
  >
    <Grid item xs={1}>
      <Typography variant="body2" className="week-day">
        {getDateDayAbbreviation(dayWithComputedAmount.date)}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="subtitle1" className="day-date">
        {getFullDate(dayWithComputedAmount.date)}
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <TimeSummaryComponent day={dayWithComputedAmount} />
    </Grid>
    <Grid item xs={2}>
      <RemoteActivityChip dispensaryArea={(dayWithComputedAmount as MixteDay | LumpSumDay).activityArea} />
    </Grid>
    <Grid item xs={4} className={classes.alignCenter}>
      <PeriodCodeRow periodDay={dayWithComputedAmount as MixteDay | LumpSumDay | LatestMixteDayPeriodFormDto} />
    </Grid>
    <Grid item xs={1}>
      <Typography
        variant="body1"
        className={classnames('day-amount', {
          [classes.manualCorrection]: !!(dayWithComputedAmount as MixteDay | LumpSumDay).manualCorrection
        })}
      >
        {currencyFormat(dayWithComputedAmount.amount)}
      </Typography>
    </Grid>
  </Grid>
);

export default withStyles(styles)(PeriodDayRow);
