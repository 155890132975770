import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ActCode, PatientContext, PatientDiagnostic } from '../../../../../shared/domain/activity/act/model/Act';
import { selectCurrentCodes, selectPatientDiagnostic } from '../../../../containers/ActForm/selectors';
import { IntensiveCareDiagnosticSelectField } from '../../Fields';
import { updateInputValue } from './utils/DefaultDiagnosticUtils';

interface Props {
  patientContext: PatientContext;
  patientDiagnostic: PatientDiagnostic[] | PatientDiagnostic;
  actCodes: ActCode[];
  input: any;
}

export const IntensiveCareDiagnosticWithPatientContextField: React.FunctionComponent<Props> = (props) => {
  const { patientDiagnostic, actCodes, input } = props;
  const handleOnChange = input.onChange;
  const inputValue = input.value;

  const isInputAlreadyFilled = input.value !== '' && input.value !== null;

  useEffect(() => {
    updateInputValue(patientDiagnostic, actCodes, isInputAlreadyFilled, handleOnChange, inputValue);
    /* IMPORTANT NOTE
     * We have disabled the rule, in order to not execute the effect when actCodes changes, because it
     * causes the component to re-render. This should do the job for now, however we must at all costs apply
     * a refactoring on this component to re-enable the validation rule according to the dependency list.
     * Otherwise it could eventually bite us in the ass. Ideally the interpreter logic should be part of
     * the ActForm, but right now that's not possible because it's a class component that doesn't
     * support React hooks.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDiagnostic]);

  return <IntensiveCareDiagnosticSelectField {...props} />;
};

export const mapStateToProps = createStructuredSelector({
  patientDiagnostic: selectPatientDiagnostic(),
  actCodes: selectCurrentCodes()
});

export default connect(mapStateToProps)(IntensiveCareDiagnosticWithPatientContextField);
