import React from 'react';
import { Typography, withStyles, WithStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

export const styles = () =>
  ({
    namContainer: {
      display: 'flex',
      flex: 1
    },
    namText: {
      fontWeight: 'bold'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  type?: string;
  firstName: string;
  lastName: string;
}

export const NoNamIdentification: React.FunctionComponent<Props> = ({
  classes,
  firstName,
  lastName,
  type = 'undefined'
}: Props) => (
  <div className={classes.namContainer}>
    <Typography variant="caption" className={classes.namText}>
      <FormattedMessage id={type} />
      {` - ${firstName} ${lastName}`.toUpperCase()}
    </Typography>
  </div>
);

export default withStyles(styles)(NoNamIdentification);
