import { flatten, groupBy, uniq } from 'lodash';
import DayUnitController from './DayUnitController';
import { isLatestMixteDay, LatestMixteDayPeriodFormDto, LatestMixtePeriodFormDto } from '../../../PeriodForm/type';
import { MixteDay } from '../../../../../../shared/domain/activity/mixte/model/Mixte';
import { getFeatureConfig } from '../../../../../../userCustomization/featureToggle';
import {
  MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM,
  MINIMUM_DURATION_BY_MIXTE_PERDIEMS
} from '../../../../../../userCustomization/featureNames';

type CodeError = { _error?: string };
type CodesError = { codes?: CodeError };
type Day = LatestMixteDayPeriodFormDto | MixteDay;

class DayUnitValidator {
  static validate(mixte: LatestMixtePeriodFormDto, errors: any) {
    const mutation = { ...errors };

    let daysErrors: (CodesError | null)[] = [];

    if (mixte.days && mixte.days.length > 0) {
      const daysByDate = groupBy(mixte.days, 'date');
      daysErrors = flatten(Object.values(daysByDate).map((days) => this.validateDaysOfSameDay(days as Day[])));
    }

    if (daysErrors.length) {
      mutation.days = daysErrors;
    }

    return mutation;
  }

  static validateDaysOfSameDay(days: Day[]): (CodesError | null)[] {
    const errors = days.map((day): CodesError | null => this.validateMixteDay(day));

    const daysError = this.validateMixteDays(days);
    if (daysError) {
      errors[errors.length - 1] = daysError;
    }

    return errors;
  }

  private static validateMixteDays(days: Day[]): CodesError | null {
    const latestDays = days.filter((day): day is LatestMixteDayPeriodFormDto => isLatestMixteDay(day));

    const minimumDuration = (getFeatureConfig(MINIMUM_DURATION_BY_MIXTE_PERDIEMS, 7) as number) || 7;
    if (this.hasBothPerdiems(latestDays) && this.calculateDayDuration(latestDays) < minimumDuration) {
      return this.createCodesError(`Un minimum de ${minimumDuration}h est requis par journée pour facturer un perdiem`);
    }

    return null;
  }

  private static validateMixteDay(day: LatestMixteDayPeriodFormDto | MixteDay): CodesError | null {
    if (!isLatestMixteDay(day)) return null;
    const { codes = [], perdiems = [] } = day;

    if (!perdiems.length || !codes.length) return null;

    const totalDuration = codes.reduce((acc, { duration }) => acc + (duration || 0), 0);

    if (totalDuration > DayUnitController.MAXIMUM_DURATION_BY_PERDIEMS * perdiems.length) {
      if (perdiems.length === 1) {
        return this.createCodesError('Un maximum de 5h est permis par demi-journée pour facturer un demi perdiem');
      }

      return this.createCodesError('Un maximum de 10h est permis par journée pour facturer un perdiem');
    }

    const minimumDuration = (getFeatureConfig(MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM, 3.5) as number) || 3.5;
    if (perdiems.length === 1 && totalDuration < minimumDuration) {
      return this.createCodesError(
        `Un minimum de ${minimumDuration}h est requis par demi-journée pour facturer un demi perdiem`
      );
    }

    return null;
  }

  static calculateDayDuration(days: LatestMixteDayPeriodFormDto[]): number {
    return days.reduce((total, day) => total + day.codes.reduce((codeTotal, code) => codeTotal + code.duration, 0), 0);
  }

  static hasBothPerdiems(days: LatestMixteDayPeriodFormDto[]): boolean {
    const perdiemsUsed = flatten(days.map((day) => day.perdiems));
    return uniq(perdiemsUsed).length === 2;
  }

  private static createCodesError(message: string): CodesError {
    return { codes: { _error: message } };
  }
}

export default DayUnitValidator;
