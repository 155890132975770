import React from 'react';
import classNames from 'classnames';
import FieldContainer from 'application/components/form/field-container';

interface Props {
  label?: string;
  disabled?: boolean;
  value: string;
  placeholder?: string;
  onChange: (updatedValue: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  type?: React.HTMLInputTypeAttribute;
  autofocus?: boolean;
}

const Input = ({ label, type, disabled, value, autofocus, placeholder, onChange, onKeyDown, className }: Props) => (
  <FieldContainer label={label}>
    <input
      type={type || 'text'}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      autoFocus={autofocus}
      className={classNames('input-bordered input w-full', { 'input-disabled': disabled }, className)}
      onInput={(event: React.FormEvent) => onChange((event.target as HTMLInputElement).value)}
      onChange={(event: React.FormEvent) => onChange((event.target as HTMLInputElement).value)}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(event)}
    />
  </FieldContainer>
);

export default Input;
