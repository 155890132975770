import ALCOHOL_PHENOL_INJECTION from '../../../../shared/ramq/requiredDetails/alcoholPhenolInjection';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const summaryRender = (code) => [
  {
    name: 'inj. alcool/phénol',
    value: code[ALCOHOL_PHENOL_INJECTION] ? 'Oui' : 'Non'
  }
];

const associatedFields = [
  {
    label: 'Injection d’alcool et Phénol ?',
    getName: (index) => `codes[${index}].${ALCOHOL_PHENOL_INJECTION}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  }
];

export default { associatedFields, summaryRender };
