import { ActCode } from './ActCode';
import endTimeRequired from '../EndTimeRequired/endTimeRequired';
import broadcastDelay from '../BroadcastDelay';
import { RequiredDetail, RequiredDetails } from '../requiredDetail.type';

export default class ActCode42195 implements ActCode {
  // eslint-disable-next-line no-unused-vars
  constructor(private act: any) {}

  getRequiredDetails(): RequiredDetails {
    return [endTimeRequired as RequiredDetail, broadcastDelay];
  }
}
