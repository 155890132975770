import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';

const MUTUALLY_EXCLUSIVE_PAIN_CODES = ['41006', '41039', '41044', '41045', '41046', '41049'];
const LAST_COMMA_IN_STRING = /, ([^, ]*)$/;

export const mutuallyExclusivePainCodes = (act: Act) => {
  const painCodes = act.codes.filter(isPainCode).map(({ code }) => code);

  if (painCodes.length > 1) {
    const painCodesEnumeration = painCodes.join(', ').replace(LAST_COMMA_IN_STRING, ' et $1');

    return {
      [actFormFields.codes]: {
        _error: `Les codes ${painCodesEnumeration} sont mutuellement exclusifs, svp en garder qu'un seul`
      }
    };
  }

  return {};
};

const isPainCode = ({ code }: ActCode) => MUTUALLY_EXCLUSIVE_PAIN_CODES.includes(code);

export default mutuallyExclusivePainCodes;
