import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { DateField } from '../Fields';

export class DefaultDateValueField extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.input.onChange(this.props.dateToSyncWith);
  }

  render() {
    const { label, meta, input } = this.props;
    return <DateField label={label} meta={meta} input={input} />;
  }
}

export const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    dateToSyncWith: ownProps.dateSelector()
  });

DefaultDateValueField.propTypes = {
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dateSelector: PropTypes.func.isRequired,
  dateToSyncWith: PropTypes.node.isRequired,
  input: PropTypes.object.isRequired
};

export default compose(connect(mapStateToProps))(DefaultDateValueField);
