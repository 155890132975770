import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DATE_RANGE_FILTER,
  STATUS_FIELD,
  ADMIN_STATUS_FIELD,
  TYPE_FIELD,
  OUTSIDE_RAMQ_PATIENT_TYPE_FILTER,
  OTHER_FILTER
} from '../../../shared/collection/queryConstants';
import { defaultFormatMomentDate } from '../../../shared/utils/dateTime/dateTimeUtils';
import messages from '../../containers/Activities/messages';

export const styles = (theme) => ({
  filtersContainer: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    color: theme.palette.common.black,
    paddingTop: 10,
    paddingBottom: 10,
    textTransform: 'uppercase'
  },
  filter: {
    marginRight: 10
  }
});

const bothDateArePresent = (dateFilters) => dateFilters && !!dateFilters.startDate && !!dateFilters.endDate;
const onlyOneDateIsPresent = (dateFilters) => dateFilters && !dateFilters.startDate !== !dateFilters.endDate;

export function FiltersDisplayer({ filters, classes }) {
  const dateFilters = filters[DATE_RANGE_FILTER];
  const startDate = dateFilters?.startDate && moment(Number(dateFilters.startDate));
  const endDate = dateFilters?.endDate && moment(Number(dateFilters.endDate));

  return (
    <div className={classes.filtersContainer}>
      {Array.isArray(filters[TYPE_FIELD]) &&
        filters[TYPE_FIELD].map((type) => (
          <Typography key={type} type="caption" className={classnames(classes.filter, 'filter')}>
            <FormattedMessage {...messages[type]} />
          </Typography>
        ))}

      {filters[TYPE_FIELD] && !Array.isArray(filters[TYPE_FIELD]) && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          <FormattedMessage {...messages[filters[TYPE_FIELD]]} />
        </Typography>
      )}

      {!filters[TYPE_FIELD] && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          Activités
        </Typography>
      )}

      {Array.isArray(filters[STATUS_FIELD]) &&
        filters[STATUS_FIELD].map((status) => (
          <Typography key={status} type="caption" className={classnames(classes.filter, 'filter')}>
            <FormattedMessage {...messages[status]} />
          </Typography>
        ))}

      {filters[STATUS_FIELD] && !Array.isArray(filters[STATUS_FIELD]) && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          <FormattedMessage {...messages[filters[STATUS_FIELD]]} />
        </Typography>
      )}

      {Array.isArray(filters[ADMIN_STATUS_FIELD]) &&
        filters[ADMIN_STATUS_FIELD].map((status) => (
          <Typography key={status} type="caption" className={classnames(classes.filter, 'filter')}>
            <FormattedMessage {...messages[status]} />
          </Typography>
        ))}

      {filters[ADMIN_STATUS_FIELD] && !Array.isArray(filters[ADMIN_STATUS_FIELD]) && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          <FormattedMessage {...messages[filters[ADMIN_STATUS_FIELD]]} />
        </Typography>
      )}

      {Array.isArray(filters[OUTSIDE_RAMQ_PATIENT_TYPE_FILTER]) &&
        filters[OUTSIDE_RAMQ_PATIENT_TYPE_FILTER].map((status) => (
          <Typography key={status} type="caption" className={classnames(classes.filter, 'filter')}>
            <FormattedMessage {...messages[status]} />
          </Typography>
        ))}

      {filters[OUTSIDE_RAMQ_PATIENT_TYPE_FILTER] && !Array.isArray(filters[OUTSIDE_RAMQ_PATIENT_TYPE_FILTER]) && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          <FormattedMessage {...messages[filters[OUTSIDE_RAMQ_PATIENT_TYPE_FILTER]]} />
        </Typography>
      )}

      {Array.isArray(filters[OTHER_FILTER]) &&
        filters[OTHER_FILTER].map((status) => (
          <Typography key={status} type="caption" className={classnames(classes.filter, 'filter')}>
            <FormattedMessage {...messages[status]} />
          </Typography>
        ))}

      {filters[OTHER_FILTER] && !Array.isArray(filters[OTHER_FILTER]) && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          <FormattedMessage {...messages[filters[OTHER_FILTER]]} />
        </Typography>
      )}

      {bothDateArePresent(dateFilters) && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          {`Du ${defaultFormatMomentDate(startDate)}
        au ${defaultFormatMomentDate(endDate)}`}
        </Typography>
      )}
      {onlyOneDateIsPresent(dateFilters) && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          {`Du ${defaultFormatMomentDate(startDate || endDate)}`}
        </Typography>
      )}

      {!!filters.textFilter && (
        <Typography type="caption" className={classnames(classes.filter, 'filter')}>
          &quot;
          {filters.textFilter}
          &quot;
        </Typography>
      )}
    </div>
  );
}

FiltersDisplayer.propTypes = {
  filters: PropTypes.object.isRequired
};

export default withStyles(styles)(FiltersDisplayer);
