import moment from 'moment-timezone';
import React from 'react';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import isValidNumber from '../../../../shared/utils/isValidNumber';
import TimePickerSyncedWithDate from '../TimePickerSyncedWithDate/TimePickerSyncedWithDate';

export interface Props {
  givenTime: number;
  input: WrappedFieldInputProps;
}

export default class TimeSyncedWithGivenTime extends React.Component<Props> {
  componentDidMount() {
    if (this.currentValueAndActStartTimeAreValidNumbers()) {
      this.props.input.onChange(adjustDateToAlwaysBeAfterGivenTime(this.props.givenTime, this.props.input.value));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.currentValueAndActStartTimeAreValidNumbers() && this.currentValueOrActStartTimeHasChanged(prevProps)) {
      this.props.input.onChange(adjustDateToAlwaysBeAfterGivenTime(this.props.givenTime, this.props.input.value));
    }
  }

  currentValueAndActStartTimeAreValidNumbers = (): boolean =>
    isValidNumber(this.props.input.value) && isValidNumber(this.props.givenTime);

  currentValueOrActStartTimeHasChanged = (prevProps) =>
    areDifferentNumbers(prevProps.input.value, this.props.input.value) ||
    areDifferentNumbers(prevProps.givenTime, this.props.givenTime);

  render() {
    const { input, ...props } = this.props;

    return <TimePickerSyncedWithDate input={input} {...props} />;
  }
}

const adjustDateToAlwaysBeAfterGivenTime = (givenTime, time): number => {
  const givenTimeMoment = moment(givenTime);
  const timeMoment = moment(time);

  // Make sure we use the same date as the given time to adjust for the right date
  timeMoment.set({
    year: givenTimeMoment.year(),
    month: givenTimeMoment.month(),
    date: givenTimeMoment.date()
  });

  if (givenTimeMoment > timeMoment) {
    const adjustedTime = timeMoment.add(1, 'days');
    return adjustedTime.valueOf();
  }

  if (timeMoment.diff(givenTimeMoment, 'hour') >= 24) {
    const adjustedTime = timeMoment.subtract(1, 'days');
    return adjustedTime.valueOf();
  }

  return timeMoment.valueOf();
};

const areDifferentNumbers = (number1, number2): boolean => number1 !== number2;
