import React from 'react';
import HybridListSelector from '../../../../components/Form/ListSelector/HybridListSelector/HybridListSelector';
import { getBillingEndDates } from '../../../../../shared/periods/periods';
import { BILLING_PERIOD_DURATION } from '../../../../../shared/periods/constants';
import calculateORFromStatementDate from '../../../../../shared/periods/common/calculateORFromActDate';
import Period from '../../../../../shared/periods/common/Period';

const DEFAULT_DATE_MESSAGE = 'Période';

const buildDateChoices = () => {
  const dateChoices: { label: string; value?: string }[] = [{ label: DEFAULT_DATE_MESSAGE }];

  // For now let them select through two years of reports
  // Eventually be smarter and give access to all reports since subscription
  getBillingEndDates(52).map((momentDate) => {
    const or = calculateORFromStatementDate(Period.actBillingPeriodOfDate(momentDate));
    dateChoices.push({
      value: momentDate.format('YYYY-MM-DD'),
      label: `Du ${momentDate
        .clone()
        .subtract(BILLING_PERIOD_DURATION - 1, 'days')
        .format('YYYY-MM-DD')}
        au ${momentDate.format('YYYY-MM-DD')} (OR ${or})`
    });
    return dateChoices;
  });

  return dateChoices;
};

export interface Props {
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (date: string) => void;
  initialiseWithFirstOption?: boolean;
}

class PaymentRequestPeriodSelector extends React.Component<Props, {}> {
  private dateChoices: any[];

  constructor(props: Props, context: any) {
    super(props, context);

    this.dateChoices = buildDateChoices();
  }

  componentDidMount() {
    const { initialiseWithFirstOption, onChange } = this.props;

    if (initialiseWithFirstOption) {
      onChange(this.dateChoices[1].value);
    }
  }

  render() {
    const { value, onChange } = this.props;

    return (
      <HybridListSelector
        data-testid="selector"
        disableFirstOption
        input={{ onChange: (evt) => onChange(evt.target.value), value }}
        values={this.dateChoices}
      />
    );
  }
}

export default PaymentRequestPeriodSelector;
