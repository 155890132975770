import { isUserAtLeast } from 'application/utilities/authentication';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Role from 'shared/domain/authentication/Role';

interface Props {
  atLeastRole?: Role;
}

interface PotentiallyLoggedOutState {
  authentication?: {
    user?: {
      role?: Role;
    };
  };
}

const RestrictedOutlet = ({ atLeastRole }: Props) => {
  const userRole = useSelector<PotentiallyLoggedOutState, Role | undefined>(
    (state) => state.authentication?.user?.role
  );

  if (!userRole) {
    return <Navigate to="/login" />;
  }

  if (atLeastRole && !isUserAtLeast(userRole, atLeastRole)) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default RestrictedOutlet;
