import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import AlertSeverity from '../../../ui/shared/severity/AlertSeverity';
import Alert from '../../Alert/Alert';

const WarningsDisplay: React.FunctionComponent<WrappedFieldProps> = ({ meta }) => {
  if (!meta.warning || !Array.isArray(meta.warning)) {
    return null;
  }

  return (
    <>
      {meta.warning.map((warningMessage) => (
        <Alert key={warningMessage} severity={AlertSeverity.WARNING}>
          {warningMessage}
        </Alert>
      ))}
    </>
  );
};

export default WarningsDisplay;
