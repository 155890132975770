import ActBilledWithValidRamqPatientAndAtLeastOneCode from '../../../../../shared/domain/global/specification/ActBilledWithValidRamqPatientAndAtLeastOneCode/ActBilledWithValidRamqPatientAndAtLeastOneCode';
import addingSupplementAccordingToPatientsAgeScenarios from '../../../../../shared/act/services/SupplementActCodesAccordingToPatientsAgeRecommendationService/rules/rules';
import { selectUserProfileInContext } from '../../../../containers/User/selectors';
import { getStore } from '../../../../reduxStore/configureStore';
import Activity from '../../../../../shared/domain/activity/global/model/Activity';
import Act from '../../../../../shared/domain/activity/act/model/Act';
import { AddSupplementCodeToActPayload } from '../../../../../shared/act/services/SupplementActCodesAccordingToPatientsAgeRecommendationService/AddSupplementCodeToMainCodeInAct';
import OptimizationResponse from '../../../../../shared/domain/global/optimisation/OptimizationResponse';

const NO_WARNINGS = [];

const addSupplementAccordingToPatientAgeWarning = (form: Partial<Act>) => {
  const jsForm = form as Activity;

  const user = selectUserProfileInContext()(getStore().getState());

  if (new ActBilledWithValidRamqPatientAndAtLeastOneCode().isSatisfiedBy(jsForm)) {
    const _warning: string[] = [];

    for (const scenario of addingSupplementAccordingToPatientsAgeScenarios) {
      const action: OptimizationResponse<AddSupplementCodeToActPayload> | undefined = scenario.run({
        act: jsForm as Act,
        doctor: user
      });

      if (action) {
        _warning.push(
          // eslint-disable-next-line max-len
          `Ajout automatique du supplément ${action.payload.supplementCode} au ${action.payload.mainCode} à cause de l'âge du patient`
        );
      }
    }

    return _warning;
  }

  return NO_WARNINGS;
};

export default addSupplementAccordingToPatientAgeWarning;
