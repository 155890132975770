import { get, has } from 'lodash';
import { actFormFields } from 'app/containers/ActForm/constants';
import Act from 'shared/core/activity/domain/Act';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup, PlaceSelectField } from '../../../components/Form/Fields';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';
import CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS from '../../../../shared/ramq/requiredDetails/consultationCarriedOutElsewhereThanCRDS';
import { AssociatedField, AssociatedValidation, RequiredDetailSummary } from '../requiredDetail.type';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';

const CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS_LABEL = 'Consultation réalisée ailleurs que dans le CRDS ?';

const associatedFields: AssociatedField[] = [
  {
    getName: (index) => `codes[${index}].place`,
    label: 'Numéro de CRDS',
    shouldFieldBeHidden: (code) => code[CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS] !== true,
    type: PlaceSelectField
  },
  {
    label: CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS_LABEL,
    getName: (index) => `codes[${index}].${CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    props: {
      defaultValue: false
    }
  }
];

const associatedValidations: AssociatedValidation[] = [
  (act: Act) => {
    const errors: Array<{ elementIdToScrollTo: string; [key: string]: string }> = [];
    const isActMadeElsewhereThanCRDS = act.codes.some(
      (actCode) => actCode.code === '15299' && actCode[CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS] === false
    );

    if (isActMadeElsewhereThanCRDS && act[actFormFields.place]?.isCRDS === false) {
      errors.push({
        elementIdToScrollTo: actFormFields.place,
        [actFormFields.place]: "SVP modifier l'établissement utilisé pour un CRDS"
      });
    }

    return errors.reduce(combineErrors, {});
  }
];

const summaryRender = (code) => {
  const summary: RequiredDetailSummary[] = [];

  if (has(code, CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS)) {
    summary.push({
      name: CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS_LABEL,
      value: get(code, CONSULTATION_CARRIED_OUT_ELSEWHERE_THAN_CRDS) ? 'Oui' : 'Non'
    });
  }

  return summary;
};

export default { associatedFields, associatedValidations, summaryRender };
