import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/WarningRounded';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(0.5)
  },
  warningIcon: {
    color: theme.palette.primary[500]
  }
});

function MultipleActiveBillWarning({ activeBills, classes }) {
  return activeBills.length > 1 ? (
    <Typography className={classes.textWrapper} variant="button">
      <WarningIcon className={classes.warningIcon} />
      {`${activeBills.length} Factures`}
    </Typography>
  ) : null;
}

MultipleActiveBillWarning.propTypes = {
  activeBills: PropTypes.array.isRequired
};

export default withStyles(styles)(MultipleActiveBillWarning);
