import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  namContainer: {
    display: 'flex',
    flex: 1
  },
  namText: {
    fontWeight: 'bold'
  },
  ramqPatient: {
    marginLeft: theme.spacing(0.75)
  }
});

export function NAM({ act, classes }) {
  const namToDisplay =
    act.nam || get(act, ['patientInformation', 'respondent', 'nam']) || get(act, ['patientInformation', 'expiredNam']);

  return (
    <div className={classes.namContainer}>
      {namToDisplay && (
        <Typography variant="caption" className={classes.namText}>
          {namToDisplay}
          {act.ramqPatient && act.nam && (
            <span className={classes.ramqPatient}>
              - {act.ramqPatient.fullName} ({act.ramqPatient.age} ans)
            </span>
          )}
        </Typography>
      )}
    </div>
  );
}

NAM.propTypes = {
  act: PropTypes.object.isRequired
};

export default withStyles(styles)(NAM);
