import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import FullScreenIcon from '@material-ui/icons/Fullscreen';
import FullScreenExitIcon from '@material-ui/icons/FullscreenExit';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import queryString from 'qs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMomentDateFromUnix } from '../../../../shared/utils/dateTime/dateTimeUtils';
import { toggleDetailedView } from '../../../containers/Activities/actions';
import { selectDetailedView } from '../../../containers/Activities/selectors';
import ActivitiesSearchIcon from '../../../containers/ActivitiesSearch/ActivitiesSearchIcon';
import { ACTIVITIES_ROUTE, USERS_ROUTE } from '../../../containers/Navigation/constants';
import { selectUserProfileInContext } from '../../../containers/User/selectors';
import { selectSearchedUserIds } from '../../../containers/UsersRoute/selectors';
import AppHeaderContainer from '../AppHeaderContainer/AppHeaderContainer';
import UserNavigationButtons from '../UserNameHeader/UserNavigationButtons';
import MonthButton from './MonthButton';
import { usePostHog } from 'posthog-js/react';

export const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.common.white,
    maxWidth: 600,
    paddingTop: theme.spacing(0.5)
  },
  headerIcons: {
    width: 40,
    height: 40
  },
  datePicker: {
    maxWidth: 80
  },
  userButton: {
    color: '#fff',
    fontWeight: 'normal',
    textTransform: 'inherit'
  }
});

export function MonthHeader({
  dispatchToggleDetailedView,
  detailedView,
  currentUserProfile,
  classes,
  searchedUserIds
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { date } = useParams();
  const posthog = usePostHog();

  const currentMomentDate = getMomentDateFromUnix(date);

  const handleDetailedViewClicked = () => {
    posthog.capture('Toggle detailed activity', { detailedView: !detailedView });
    dispatchToggleDetailedView();
  };

  return (
    <AppHeaderContainer>
      <div className={classes.headerContainer}>
        <div className={classes.datePicker}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DatePicker
              TextFieldComponent={({ onClick }) => (
                <MonthButton white momentDate={currentMomentDate} onClick={onClick} />
              )}
              value={currentMomentDate}
              leftArrowIcon={<PrevArrowIcon />}
              rightArrowIcon={<NextArrowIcon />}
              onChange={(date) =>
                navigate({ pathname: `/${ACTIVITIES_ROUTE}/${date.valueOf()}`, search: location.search })
              }
            />
          </MuiPickersUtilsProvider>
        </div>
        <Restrict atLeastRole={Role.Agent}>
          <Button
            data-testid="current-user"
            className={classes.userButton}
            onClick={() => {
              navigate({
                pathname: `/${USERS_ROUTE}/${currentUserProfile.id}/${ACTIVITIES_ROUTE}`,
                search: queryString.stringify({ dateRange: { startDate: currentMomentDate.valueOf() } })
              });
            }}
          >
            {currentUserProfile.firstName && `${currentUserProfile.firstName} ${currentUserProfile.lastName}`}
            &nbsp;-&nbsp;
            {currentUserProfile.specialty && (
              <FormattedMessage id={`global.specialty.${currentUserProfile.specialty}`} defaultMessage="Inconnu" />
            )}
          </Button>

          <UserNavigationButtons usersIds={searchedUserIds} currentUserId={currentUserProfile.id} navigateByAction />
        </Restrict>
        <div>
          <IconButton color="inherit" className={classes.headerIcons} onClick={handleDetailedViewClicked}>
            {detailedView ? <FullScreenExitIcon /> : <FullScreenIcon />}
          </IconButton>
          <ActivitiesSearchIcon className={classes.headerIcons} />
        </div>
      </div>
    </AppHeaderContainer>
  );
}

MonthHeader.propTypes = {
  dispatchToggleDetailedView: PropTypes.func.isRequired,
  detailedView: PropTypes.bool.isRequired,
  currentUserProfile: PropTypes.object,
  searchedUserIds: PropTypes.array.isRequired
};

MonthHeader.defaultProps = {
  currentUserProfile: {},
  searchedUserIds: []
};

export const mapStateToProps = createStructuredSelector({
  detailedView: selectDetailedView(),
  currentUserProfile: selectUserProfileInContext(),
  searchedUserIds: selectSearchedUserIds()
});

export const mapDispatchToProps = (dispatch) => ({
  dispatchToggleDetailedView: () => {
    dispatch(toggleDetailedView());
  }
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(MonthHeader);
