import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const associatedFields: AssociatedField[] = [
  {
    label: 'À distance ?',
    getName: (index) => `codes[${index}].remote`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES
  }
];

const remote: RequiredDetail = {
  associatedFields
};

export default remote;
