export const UPDATE_PERIOD = 'updatePeriod';
export const UPDATE_PERIOD_FAILURE = 'updatePeriodFailure';

export function updatePeriod(document, changes) {
  return {
    type: UPDATE_PERIOD,
    document,
    changes
  };
}

export function updatePeriodFailure(error) {
  return {
    type: UPDATE_PERIOD_FAILURE,
    error
  };
}
