import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import withImageData from './withImageData';

const styles = () => ({
  hiddenPreviewBox: {
    display: 'none'
  }
});

export const IMAGE_VIEWER_ID = 'image-preview';

function PlaceHolderImagePreview({ imageData, classes }) {
  return (
    <div id={IMAGE_VIEWER_ID} className={classes.hiddenPreviewBox}>
      <img src={imageData} alt="preview" />
    </div>
  );
}

PlaceHolderImagePreview.propTypes = {
  imageData: PropTypes.string.isRequired
};

export default compose(withImageData, withStyles(styles))(PlaceHolderImagePreview);
