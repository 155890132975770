export default [
  '24',
  '28',
  '29',
  '41',
  '53',
  '54',
  '81',
  '574',
  '763',
  '767',
  '927',
  '929',
  '984',
  '991',
  '8918',
  '8919',
  '8925',
  '9062',
  '9095',
  '9096',
  '9097',
  '9098',
  '9128',
  '9135',
  '9139',
  '9145',
  '9158',
  '9159',
  '9161',
  '9177',
  '9178',
  '9179',
  '9197',
  '9198',
  '9199',
  '9200',
  '9203',
  '9204',
  '9213',
  '9221',
  '9291',
  '9294',
  '9295',
  '9298',
  '9350',
  '9377',
  '9378',
  '9379',
  '9380',
  '9381',
  '9403',
  '9404',
  '9405',
  '9489',
  '9490',
  '9491',
  '9708',
  '9832',
  '9833',
  '9834',
  '9835',
  '9843',
  '9999',
  '15014',
  '15015',
  '15016',
  '15017',
  '15035',
  '15036',
  '15041',
  '15042',
  '15043',
  '15044',
  '15045',
  '15046',
  '15047',
  '15048',
  '15238',
  '15485',
  '15486',
  '15487',
  '15545',
  '15600',
  '15601',
  '15602',
  '15671',
  '15672',
  '15674',
  '15703',
  '15722',
  '15723',
  '17000',
  '17001',
  '17002',
  '17003',
  '17004',
  '17005',
  '17006',
  '18185',
  '18186',
  '18187',
  '18188',
  '18189',
  '18190',
  '18191',
  '18192',
  '18193',
  '19052',
  '19053',
  '19129',
  '19130',
  '19131',
  '19132',
  '19133',
  '19134',
  '19135',
  '19136',
  '19137',
  '19138',
  '19163',
  '19164',
  '19165',
  '19166',
  '19167',
  '19168',
  '19169',
  '19170',
  '19171',
  '19172',
  '19173',
  '19174',
  '19175',
  '19176',
  '19177',
  '19178',
  '19207',
  '19208',
  '19261',
  '19262',
  '19263',
  '19264',
  '19265',
  '19266',
  '19267',
  '19268',
  '19295',
  '19296',
  '19297',
  '19298',
  '19299',
  '19300',
  '19301',
  '19302',
  '19303',
  '19304',
  '19305',
  '19306',
  '19307',
  '19308',
  '19309',
  '19310',
  '19311',
  '19312',
  '19313',
  '19314',
  '19315',
  '19316',
  '19317',
  '19318',
  '19349',
  '19350',
  '19353',
  '19354',
  '19359',
  '19360',
  '19363',
  '19364',
  '19377',
  '19378',
  '19379',
  '19380',
  '19405',
  '19406',
  '19407',
  '19408',
  '19409',
  '19410',
  '19411',
  '19412',
  '19427',
  '19428',
  '19429',
  '19430',
  '19431',
  '19432',
  '19439',
  '19440',
  '19483',
  '19484',
  '19507',
  '19508',
  '19509',
  '19510',
  '19511',
  '19512',
  '19513',
  '19514',
  '19515',
  '19516',
  '19517',
  '19518',
  '19519',
  '19520',
  '19521',
  '19522',
  '19523',
  '19524',
  '19525',
  '19526',
  '19527',
  '19528',
  '19540',
  '19541',
  '19542',
  '19543',
  '19544',
  '19545',
  '19556',
  '19557',
  '19558',
  '19559',
  '19560',
  '19561',
  '19566',
  '19567',
  '19572',
  '19573',
  '19582',
  '19583',
  '19588',
  '19589',
  '19658',
  '19659',
  '19750',
  '19751',
  '19752',
  '19753',
  '19754',
  '19755',
  '19756',
  '19757',
  '19758',
  '19759',
  '19760',
  '19761',
  '19762',
  '19763',
  '19764',
  '19765',
  '19766',
  '19767',
  '19768',
  '19769',
  '19777',
  '19778',
  '19779',
  '19780',
  '19800',
  '19801',
  '19802',
  '19803',
  '19804',
  '19805',
  '19806',
  '19807',
  '19825',
  '19826',
  '19827',
  '19828',
  '19829',
  '19830',
  '19863',
  '19864',
  '19865',
  '19866',
  '19867',
  '19868',
  '19889',
  '19890',
  '19900',
  '19901',
  '19902',
  '19903',
  '19904',
  '19905',
  '19906',
  '19907',
  '19908',
  '19909',
  '19910',
  '19911',
  '19912',
  '19913',
  '19940',
  '19941',
  '19944',
  '19945',
  '19946',
  '19947',
  '20039',
  '20213',
  '20268',
  '20269',
  '20270',
  '20271',
  '20273',
  '20274',
  '20275',
  '41023',
  '41024',
  '41025',
  '41028',
  '41029',
  '41030',
  '41040',
  '41041',
  '41042',
  '41043',
  '41051',
  '41052',
  '41053',
  '41054',
  '41055',
  '41056',
  '41057',
  '41058',
  '41059',
  '98101',
  '98102',
  '98103',
  '98104',
  '17000',
  '17001',
  '17002',
  '17003',
  '17004',
  '17005',
  '17006'
];
