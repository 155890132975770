import { computeTimeSlotSpanFromTwoHoursInTheSameDay } from '../../../timeSlot/timeSlotUtils';
import {
  getRamqDayOfMonthFromTimestamp,
  getRamqNumberOfHoursWorkedFromTimestamps,
  getWorkedHours
} from '../../../../ramq/ramqCommonUtils';
import { makeFullRateDetail } from '../../partialRate/partialRateUtils';
import { formatToDeferredPaymentRequestDate } from '../../../../ramq/ramqDateUtil';

export const MAXIMUM_CONSECUTIVE_WORKABLE_HOURS = 13;

export const EVAQ_CODE_FIRST_THIRTEEN_WORK_HOURS = '078127';
export const EVAQ_CODE_SUBSEQUENT_WORK_HOURS = '078128';

export default class EvaqTimeSlotCalculator {
  calculate(day) {
    const timeSlots = computeTimeSlotSpanFromTwoHoursInTheSameDay(day.start, day.end);
    const startTimeSlot = timeSlots[0];

    const period = day;

    const workedHours = getWorkedHours(day.start, day.end);
    if (workedHours > MAXIMUM_CONSECUTIVE_WORKABLE_HOURS) {
      period.code = EVAQ_CODE_SUBSEQUENT_WORK_HOURS;
    }

    return [
      {
        details: [makeFullRateDetail(period, getRamqNumberOfHoursWorkedFromTimestamps(period.start, period.end))],
        timePeriod: startTimeSlot,
        day: getRamqDayOfMonthFromTimestamp(period.date),
        date: formatToDeferredPaymentRequestDate(period.date)
      }
    ];
  }
}
