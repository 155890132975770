/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';

export const styles = (theme) => ({
  oneOfThreeColumns: {
    width: '33%',
    overflowX: 'scroll',
    padding: theme.spacing(2)
  },
  redBackground: {
    backgroundColor: theme.palette.red[300]
  },
  blueBackground: {
    backgroundColor: theme.palette.turquoise[500]
  },
  greenBackground: {
    backgroundColor: theme.palette.green[300]
  },
  deltaPreview: {
    whiteSpace: 'pre',
    fontFamily: 'monospace'
  }
});

const prettyPrintAndHighlight = (rawObject, keyFilter, valueFilter) => {
  let highlightedJsonString = JSON.stringify(
    rawObject,
    (_key, value) => {
      if (Array.isArray(value)) {
        return value.reduce((acc, item, index) => {
          acc[index] = item;
          return acc;
        }, {});
      }
      return value;
    },
    2
  );

  if (keyFilter) {
    highlightedJsonString = highlightedJsonString.replace(
      new RegExp(`"(.*)(${keyFilter})(.*)":`, 'gi'),
      '"$1<span style="background-color: yellow;">$2</span>$3":'
    );
  }

  if (valueFilter) {
    highlightedJsonString = highlightedJsonString.replace(
      new RegExp(`": (.*)(${valueFilter})(.*)\n`, 'gi'),
      '": $1<span style="background-color: yellow;">$2</span>$3\n'
    );
  }

  return highlightedJsonString;
};

export function ActivityHistoryDelta({ delta, keyFilter, valueFilter, classes }) {
  const { added, updated, deleted } = delta;

  return (
    <Grid container>
      <Grid item xs className={classnames(classes.oneOfThreeColumns, classes.redBackground)}>
        <Typography>Supprimé</Typography>
        <div data-testid="deleted-section">
          <div
            className={classes.deltaPreview}
            dangerouslySetInnerHTML={{ __html: prettyPrintAndHighlight(deleted, keyFilter, valueFilter) }}
          />
        </div>
      </Grid>
      <Grid item xs className={classnames(classes.oneOfThreeColumns, classes.blueBackground)}>
        <Typography>Mis à jour</Typography>
        <div data-testid="updated-section">
          <div
            className={classes.deltaPreview}
            dangerouslySetInnerHTML={{ __html: prettyPrintAndHighlight(updated, keyFilter, valueFilter) }}
          />
        </div>
      </Grid>
      <Grid item xs className={classnames(classes.oneOfThreeColumns, classes.greenBackground)}>
        <Typography>Ajouté</Typography>
        <div data-testid="added-section">
          <div
            className={classes.deltaPreview}
            dangerouslySetInnerHTML={{ __html: prettyPrintAndHighlight(added, keyFilter, valueFilter) }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

ActivityHistoryDelta.propTypes = {
  delta: PropTypes.object.isRequired,
  keyFilter: PropTypes.string,
  valueFilter: PropTypes.string
};

export default withStyles(styles)(ActivityHistoryDelta);
