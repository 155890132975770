import React from 'react';
import FieldContainer from 'application/components/form/field-container';

interface TranslatedValue {
  fr: string;
  en: string;
}

interface Props {
  label?: string;
  value: TranslatedValue;
  onChange: (updatedValue: TranslatedValue) => void;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
}

const TranslatedInput = ({ label, type, value, onChange, required }: Props) => (
  <FieldContainer label={label}>
    <div className="flex w-full gap-4">
      {['fr', 'en'].map((locale: string) =>
        type === 'textarea' ? (
          <textarea
            key={locale}
            required={required}
            placeholder={locale}
            value={value[locale] as string}
            className="input-bordered input min-h-[150px] flex-auto"
            onChange={(event: React.FormEvent) =>
              onChange({ ...value, [locale]: (event.target as HTMLInputElement).value })
            }
          />
        ) : (
          <input
            key={locale}
            required={required}
            placeholder={locale}
            type={type || 'text'}
            value={value[locale] as string}
            className="input-bordered input flex-auto"
            onChange={(event: React.FormEvent) =>
              onChange({ ...value, [locale]: (event.target as HTMLInputElement).value })
            }
          />
        )
      )}
    </div>
  </FieldContainer>
);

export default TranslatedInput;
