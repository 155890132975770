import React, { FunctionComponent, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, MenuItem, Select, WithStyles, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReportService from 'application/services/report-service';
import downloadFile from 'application/utilities/download-file';
import User from 'shared/domain/user/User';
import { selectLoggedUser } from 'app/containers/Authentication/selectors';
import PaymentRequestPeriodSelector from '../PaymentRequestPeriodSelector/PaymentRequestPeriodSelector';
import HeaderPortal from '../../../../components/Portals/HeaderPortal/HeaderPortal';
import { selectHasPaymentRequestsSentInPeriodEnabled, selectUserProfileInContext } from '../../../User/selectors';
import { displayGlobalError } from '../../../Act/actions';
import AuthUserDomain from '../../../../../shared/domain/authentication/AuthUser';
import Permission from '../../../../../shared/domain/authorization/model/Permission';
import { PeriodByActivities } from '../../shared/type';

export const styles = (theme) =>
  ({
    toolbar: {
      boxSizing: 'border-box',
      padding: theme.spacing(1),
      display: 'flex',
      backgroundColor: 'white',
      gap: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
      flexWrap: 'wrap'
    },
    toolbarLabel: {
      marginRight: theme.spacing(1)
    },
    toolbarItem: {
      display: 'flex',
      alignItems: 'center'
    }
  }) as const;

export interface OwnProps {
  undocked?: boolean;
  blockedPaymentType?: boolean;
  selectedDate: string;
  selectedPeriod?: PeriodByActivities;
  /* eslint-disable no-unused-vars */
  onChangeSelectedDate: (date: string) => void;
  onDisplayGlobalError: (error: string) => any;
  /* eslint-enable */
}

export interface ReduxStateProps {
  userInContext: User;
  paymentRequestsSentInPeriodEnabled: boolean;
  loggedUser: AuthUserDomain;
}

export interface Props extends OwnProps, WithStyles<typeof styles>, ReduxStateProps {
  practiceNumber?: string;
}

export enum PaymentRequestsType {
  BILLED = 'Par date de service',
  SENT_IN_PERIOD = "Par date d'envoi"
}

export const PaymentRequestHeader: FunctionComponent<Props> = ({
  selectedDate,
  selectedPeriod,
  onChangeSelectedDate,
  classes,
  paymentRequestsSentInPeriodEnabled,
  userInContext,
  onDisplayGlobalError,
  practiceNumber,
  undocked,
  blockedPaymentType,
  loggedUser
}) => {
  const [selectedPaymentRequestsType, setSelectedPaymentRequestsType] = useState<PaymentRequestsType>(
    PaymentRequestsType.BILLED
  );

  const [isLoading, setIsLoading] = useState(false);

  const onOpenPDF = async () => {
    try {
      if (practiceNumber) {
        setIsLoading(true);
        ReportService.downloadDoctorPaymentRequestsBySentDate(userInContext.id, {
          periodStart: selectedPeriod!.act.startDate.valueOf(),
          periodEnd: selectedPeriod!.act.endDate.valueOf()
        })
          .then((downloadLink) => {
            downloadFile(downloadLink);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    } catch {
      onDisplayGlobalError("Aucun rapport n'existe pour cette période");
    }
  };

  const Wrapper = undocked ? React.Fragment : HeaderPortal;

  return (
    <Wrapper>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarItem}>
          <Typography className={classes.toolbarLabel}>Période:</Typography>
          <PaymentRequestPeriodSelector
            initialiseWithFirstOption
            value={selectedDate}
            onChange={onChangeSelectedDate}
          />
        </div>

        {!blockedPaymentType &&
          (paymentRequestsSentInPeriodEnabled ||
            loggedUser.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS)) && (
            <>
              <div className={classes.toolbarItem}>
                <Typography className={classNames(classes.toolbarLabel)}>Type de date:</Typography>
                <Select
                  id="payment-requests-type"
                  data-testid="payment-requests-type"
                  value={selectedPaymentRequestsType}
                  onChange={(event) => setSelectedPaymentRequestsType(event.target.value as PaymentRequestsType)}
                >
                  {Object.values(PaymentRequestsType).map((paymentRequestType) => (
                    <MenuItem key={paymentRequestType} value={paymentRequestType}>
                      {paymentRequestType}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              {selectedPaymentRequestsType === PaymentRequestsType.SENT_IN_PERIOD && (
                <div className={classes.toolbarItem}>
                  <button type="button" className="btn btn-primary" disabled={isLoading} onClick={onOpenPDF}>
                    {isLoading ? <CircularProgress color="primary" size={24} /> : 'Ouvrir PDF'}
                  </button>
                </div>
              )}
            </>
          )}
      </Toolbar>
    </Wrapper>
  );
};

export const mapStateToProps = createStructuredSelector({
  paymentRequestsSentInPeriodEnabled: selectHasPaymentRequestsSentInPeriodEnabled(),
  userInContext: selectUserProfileInContext(),
  loggedUser: selectLoggedUser()
});

function mapDispatchToProps(dispatch) {
  return {
    onDisplayGlobalError: bindActionCreators(displayGlobalError, dispatch)
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PaymentRequestHeader));
