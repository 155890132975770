/* eslint-disable no-shadow, no-unused-vars */
enum ISO_WEEKDAY {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7
}

export default ISO_WEEKDAY;
