import React, { useEffect, useState } from 'react';
import useTranslate from 'application/hooks/use-translate';
import { gql, useMutation, useQuery } from '@apollo/client';
import useToastApolloError from 'application/hooks/use-toast-apollo-error';
import useToast from 'application/hooks/use-toast';
import Card from 'application/components/card';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { isUserAtLeast } from 'application/utilities/authentication';
import HoldButton from 'application/components/hold-button';
import { useSelector } from 'react-redux';
import { RootState } from 'application/types/redux-state';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useValidatedForm from 'application/hooks/use-validated-form';
import DetailsForm from 'application/pages/groups/group/components/details-form';

interface Group {
  id: string;
  name: string;
  number: string;
  type: string;
}

const GROUP_FRAGMENT = gql`
  fragment GroupFragment on GroupSchema {
    id
    name
    number
    type
  }
`;

const GROUP_DETAILS_QUERY = gql`
  query FetchGroupDetails($groupId: String!) {
    group(groupId: $groupId) {
      ...GroupFragment
    }
  }

  ${GROUP_FRAGMENT}
`;

interface UpdateGroup {
  updateGroup: Group;
}

const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroup($groupId: String!, $data: GroupInput!) {
    updateGroup(groupId: $groupId, data: $data) {
      ...GroupFragment
    }
  }

  ${GROUP_FRAGMENT}
`;

const DELETE_GROUP_MUTATION = gql`
  mutation DeleteGroup($groupId: String!) {
    deleteGroup(groupId: $groupId) {
      id
    }
  }
`;

const GroupDetailsPage = () => {
  const translate = useTranslate('pages.group.details');
  const toastApolloError = useToastApolloError();
  const { addToast } = useToast();
  const userRole = useSelector<RootState, Role>((state) => state.authentication.user.role);

  const { groupId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [stagedGroup, setStagedGroup] = useState<Omit<Group, 'id'> | null>(null);

  const { data, loading, refetch } = useQuery<{ group: Group }>(GROUP_DETAILS_QUERY, { variables: { groupId } });
  const [updateGroup] = useMutation<UpdateGroup>(UPDATE_GROUP_MUTATION);
  const [deleteGroup] = useMutation(DELETE_GROUP_MUTATION);

  const { isValid, registerField } = useValidatedForm();

  useEffect(() => {
    if (loading || !data?.group) return;

    setStagedGroup({
      name: data.group.name,
      number: data.group.number,
      type: data.group.type
    });
  }, [data, loading]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isUserAtLeast(userRole, Role.Admin) || !isValid || !stagedGroup) return;

    updateGroup({
      variables: {
        groupId,
        data: stagedGroup
      }
    })
      .then(() =>
        addToast({
          autoDismiss: true,
          message: translate('informations.save-success'),
          type: 'success'
        })
      )
      .catch(toastApolloError);
  };

  if (!data?.group || !stagedGroup) return null;

  return (
    <div className="flex flex-col gap-4">
      <DetailsForm
        values={stagedGroup}
        isFormValid={isValid}
        onCancel={() => refetch()}
        onChange={(values) => setStagedGroup({ ...stagedGroup, ...values })}
        onSubmit={handleSubmit}
        registerField={registerField}
      />

      <Restrict atLeastRole={Role.Admin}>
        <Card icon="hexagon-exclamation" theme="error" title={translate('danger-zone.title')}>
          <p>{translate('danger-zone.description')}</p>

          <Card.Actions>
            <HoldButton
              className="btn btn-error"
              onClick={() => {
                deleteGroup({ variables: { groupId } })
                  .then(() => {
                    addToast({
                      message: translate('danger-zone.delete-success', { name: data.group.name }),
                      type: 'success'
                    });
                    navigate({ pathname: '../..', search: location.search });
                  })
                  .catch(toastApolloError);
              }}
            >
              {translate('danger-zone.delete')}
            </HoldButton>
          </Card.Actions>
        </Card>
      </Restrict>
    </div>
  );
};

export default GroupDetailsPage;
