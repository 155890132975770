import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import {
  infectedParticularSituationNeedToBeRemoteOrTelemedicine,
  infectedParticularSituationAnd42189,
  quarantineParticularSituationAndTelemedicineAnd42189
} from './validations';
import { PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY, PARTICULAR_SITUATION_TYPES } from './constants';

const associatedFields: AssociatedField[] = [
  {
    label: 'Situation particulière',
    notRequired: true,
    getName: (index) => `codes[${index}].${PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY}`,
    type: SingleSelectCheckboxGroup,
    list: PARTICULAR_SITUATION_TYPES
  }
];

const particularSituation: RequiredDetail = {
  associatedFields,
  associatedValidations: [
    infectedParticularSituationAnd42189,
    quarantineParticularSituationAndTelemedicineAnd42189,
    infectedParticularSituationNeedToBeRemoteOrTelemedicine
  ]
};

export default particularSituation;
