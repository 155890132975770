/* eslint-disable */
import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import flatMap from 'lodash/flatMap';
import { FederationContributionLine, PartialStatement } from '../../../../../../../shared/statement/types';

export default class PoolStatementSummaryBuilder {
  private statementSummary: PartialStatement = {
    sections: [
      {
        generalInformation: {}
      }
    ]
  };

  static transformSectionsToArray(statements) {
    return statements.map((statement) => Object.assign(statement, { sections: Object.values(statement.sections) }));
  }

  withBasicInfo(statements: PartialStatement[]) {
    this.statementSummary.firstName = statements[0].firstName;
    this.statementSummary.lastName = statements[0].lastName;
    this.statementSummary.fullName = statements[0].fullName;
    this.statementSummary.practiceNumber = statements[0].practiceNumber;
    this.statementSummary.specialty = statements[0].specialty;
    this.statementSummary.statementDate = statements[0].statementDate;
    return this;
  }

  withProfessionalIdInfo(statements: PartialStatement[]) {
    this.statementSummary.sections![0].generalInformation!.professionalIdInfo =
      statements[0].sections![0].generalInformation!.professionalIdInfo!;
    return this;
  }

  withHeaderInfo(statements: PartialStatement[]) {
    this.statementSummary.sections![0].generalInformation!.headerInfo =
      statements[0].sections![0].generalInformation!.headerInfo;
    return this;
  }

  withFirstLineInfo(statements: PartialStatement[]) {
    this.statementSummary.sections![0].generalInformation!.firstLineInfo =
      statements[0].sections![0].generalInformation!.firstLineInfo;
    return this;
  }

  mergeGrossAmountInfoLines(statements: PartialStatement[]) {
    const grossAmountInfoLines = flatMap(statements, (statement) =>
      flatMap(statement.sections!, (section) => section.generalInformation!.grossAmountInfoLines!)
    );
    const grossAmountInfoLinesByCodes = Object.values(groupBy(grossAmountInfoLines, 'transactionCode'));

    this.statementSummary.sections![0].generalInformation!.grossAmountInfoLines = grossAmountInfoLinesByCodes.map(
      (linesPerCode: any) => ({
        transactionCode: linesPerCode[0]!.transactionCode,
        transactionLabel: linesPerCode[0]!.transactionLabel,
        grossAmount: sumBy(linesPerCode, (x) => Number.parseFloat(x!.grossAmount)).toString()
      })
    );

    return this;
  }

  mergeNetAmountInfo(statements: PartialStatement[]) {
    // We assume that the transfer number will be the same for the entire group for a given statement
    const netAmounts = flatMap(this.mergeSections(statements), (section) =>
      Number.parseFloat(section.generalInformation!.netAmountInfo!.netAmount!)
    );

    const netAmount = sum(netAmounts.filter((amount) => !Number.isNaN(amount))).toString();

    this.statementSummary.sections![0].generalInformation!.netAmountInfo = { netAmount, transferNumber: '' };

    return this;
  }

  mergeCeilingCutLines(statements: PartialStatement[]) {
    const ceilingCutLines = flatMap(
      this.mergeSections(statements),
      (section) => section.generalInformation!.ceilingCutLines!
    );

    this.statementSummary.sections![0].generalInformation!.ceilingCutLines = ceilingCutLines as [];

    return this;
  }

  mergeDeductionLines(statements: PartialStatement[]) {
    const deductionLines = flatMap(
      this.mergeSections(statements),
      (section) => section.generalInformation!.deductionLines!
    );

    this.statementSummary.sections![0].generalInformation!.deductionLines = deductionLines as [];

    return this;
  }

  mergeFederationContributionsLines(statements: PartialStatement[]) {
    const federationContributionsLines = flatMap(
      this.mergeSections(statements),
      (section) => section.generalInformation!.federationContributionsLines!
    );

    this.statementSummary.sections![0].generalInformation!.federationContributionsLines =
      this.groupByFederations(federationContributionsLines);

    this.statementSummary.sections![0].generalInformation!.federationContributionSumInfo = {
      federationName: statements[0].sections![0].generalInformation!.federationContributionSumInfo!.federationName,
      federationTotalAmount: this.sumFederationCumulativeAmounts(statements).toString()
    };

    return this;
  }

  mergeInterestAndAdvancesLines(statements: PartialStatement[]) {
    const interestAndAdvancesLines = flatMap(
      this.mergeSections(statements),
      (section) => section.generalInformation!.interestAndAdvancesLines
    );

    this.statementSummary.sections![0].generalInformation!.interestAndAdvancesLines = interestAndAdvancesLines as [];
    return this;
  }

  mergeStatementBalanceLines(statements: PartialStatement[]) {
    const balanceLines = flatMap(
      this.mergeSections(statements),
      (section) => section.generalInformation!.balanceLines!
    );

    this.statementSummary.sections![0].generalInformation!.balanceLines = balanceLines as [];

    return this;
  }

  mergeStatementBalanceTransferLines(statements: PartialStatement[]) {
    const balanceTransferLines = flatMap(
      this.mergeSections(statements),
      (section) => section.generalInformation!.balanceTransferLines!
    );

    this.statementSummary.sections![0].generalInformation!.balanceTransferLines = balanceTransferLines as [];

    return this;
  }

  private groupByFederations(federationContributionsLines: FederationContributionLine[]): FederationContributionLine[] {
    return Object.values(groupBy(federationContributionsLines, 'federationName')).map((entries: any) => ({
      federationName: entries[0].federationName,
      federationAmount: entries.reduce((acc, { federationAmount }) => acc + parseFloat(federationAmount), 0).toString()
    }));
  }

  private sumFederationCumulativeAmounts(statements: PartialStatement[]) {
    const federationTotalAmounts = flatMap(this.mergeSections(statements), (section) =>
      Number.parseFloat(section.generalInformation!.federationContributionSumInfo!.federationTotalAmount)
    );

    return federationTotalAmounts.reduce((acc, current) => acc + current, 0);
  }

  private mergeSections(statements: PartialStatement[]) {
    return flatMap(statements, (statement) => Object.values(statement.sections!)[0]);
  }

  build(): PartialStatement {
    return this.statementSummary;
  }
}
