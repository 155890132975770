import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'qs';
import { LUMP_SUM_ROUTE } from '../../../containers/Navigation/constants';
import { displayPlace } from '../../Form/utils/fieldTransformations';
import DetailsItem from '../Act/Details/DetailsItem/DetailsItem';
import ActivityContainer from '../ActivityContainer/ActivityContainer';
import FormattedUnixHours from '../FormattedUnixHours/FormattedUnixHours';
import MainInformationContainer from '../MainInformationContainer/MainInformationContainer';
import PerdiemsCodeInfo from '../Mixte/PerdiemsCodeInfo/PerdiemsCodeInfo';
import { ListItemSecondaryAction } from '@material-ui/core';
import DesktopActions from 'app/containers/ActivitiesRoute/DayRoute/DayActivities/Act/DesktopActions/DesktopActions';

export function LumpSumActivity({ activity }) {
  const navigate = useNavigate();

  return (
    <Grow in>
      <ActivityContainer
        divider
        onClick={() =>
          navigate({
            pathname: LUMP_SUM_ROUTE,
            search: queryString.stringify(
              { place: activity.place.number, billingType: activity.billingType, pool: activity.poolNumber },
              { skipNulls: true }
            )
          })
        }
        highlight
      >
        <MainInformationContainer activity={activity}>
          <FormattedUnixHours activity={activity} text="Forfaitaire" />
          <PerdiemsCodeInfo activity={activity} />
          <DetailsItem
            dense
            icon={<PlaceIcon />}
            text={<Typography noWrap>{displayPlace(activity.place)}</Typography>}
          />
        </MainInformationContainer>
        <ListItemSecondaryAction>
          <DesktopActions activity={activity} />
        </ListItemSecondaryAction>
      </ActivityContainer>
    </Grow>
  );
}

LumpSumActivity.propTypes = {
  activity: PropTypes.object.isRequired
};

export default LumpSumActivity;
