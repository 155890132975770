export default class MixteSummaryDayLineSchedule {
  private _am: boolean;

  private _date: string;

  private _pm: boolean;

  constructor(am: boolean, date: string, pm: boolean) {
    this._am = am;
    this._date = date;
    this._pm = pm;
  }

  public get am(): boolean {
    return this._am;
  }

  public get isAm(): boolean {
    return this._am && !this._pm;
  }

  public get date(): string {
    return this._date;
  }

  public get pm(): boolean {
    return this._pm;
  }

  public get isPm(): boolean {
    return this._pm && !this._am;
  }

  public get isAllDay(): boolean {
    return this.am && this.pm;
  }

  public get isHalfDay(): boolean {
    return this.isAm || this.isPm;
  }
}
