import PeriodAmountService, { DayWithComputedAmount, TotalAmountByCode } from '../PeriodAmountService';
import computeExpectedAmountForMixte from '../../computeMixteExpectedAmount';

class MixteAmountService extends PeriodAmountService {
  getTotalAmount(): number {
    return this.getAmountByDay().reduce((acc, { amount }) => acc + amount, 0);
  }

  getTotalAmountByCode(): TotalAmountByCode[] {
    throw new Error('This method is not implemented for mixte type');
  }

  getAmountByDay(): DayWithComputedAmount[] {
    return this.mapDaysWithPopulatedPlace().map((day) => ({
      ...day,
      amount: day.manualCorrection
        ? Number.parseFloat(day.manualCorrection!)
        : Number.parseFloat(computeExpectedAmountForMixte(day))
    }));
  }
}

export default MixteAmountService;
