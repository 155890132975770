import PropTypes from 'prop-types';

const DisplayIf = ({ condition, DisplayElse, children }) => (condition ? children : DisplayElse);

DisplayIf.propTypes = {
  condition: PropTypes.bool.isRequired,
  DisplayElse: PropTypes.node
};

DisplayIf.defaultProps = {
  DisplayElse: null
};

export default DisplayIf;
