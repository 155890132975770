import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import { NamField } from '../../../../../components/Form/Fields';
import FormElement from '../../../../../components/Form/FormElement/FormElement';

export function NoNamReleveForm() {
  const translate = useTranslate();

  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormElement
            label={translate('no-nam.statement.expired-hin')}
            name="patientInformation.expiredNam"
            component={NamField}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
}

export default NoNamReleveForm;
