export enum GroupRole {
  MANAGER = 'manager',
  MEMBER = 'member',
  OWNER = 'owner'
}

export enum GroupType {
  MANAGEMENT = 'management',
  POOL = 'pool'
}
