import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { dateIsDuringPlaceHoliday, dateIsDuringWeekend } from '../../../../../../shared/utils/dateTime/dateValidations';

const styles = (theme) => ({
  specialDayChip: {
    marginBottom: theme.spacing(0.5),
    height: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  mainActInformation: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});

function SpecialDaysIndicator({ date, mainActInformation, place, classes }) {
  if (dateIsDuringPlaceHoliday(date, place) || dateIsDuringWeekend(date)) {
    return (
      <Chip
        label="W/E - Férié"
        className={classNames(classes.specialDayChip, {
          [classes.mainActInformation]: mainActInformation
        })}
      />
    );
  }

  return null;
}

SpecialDaysIndicator.defaultProps = {
  mainActInformation: true
};

SpecialDaysIndicator.propTypes = {
  date: PropTypes.node.isRequired,
  place: PropTypes.object.isRequired,
  mainActInformation: PropTypes.bool
};

export default compose(withStyles(styles))(SpecialDaysIndicator);
