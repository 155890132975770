import get from 'lodash/get';
import moment from 'moment-timezone';
import intersection from 'lodash/intersection';
import fetchExplanationMatchingCode from '../../../../../shared/ramq/codeExplanation/codeExplanationUtils';
import { formatUnixToDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import { currencyFormat } from '../../../../../utils/wordFormatUtilities';
import {
  amountFormat,
  ramqStringAmountToFloat
} from '../../IncomeStatement/IncomeStatementReport/IncomeStatementReportUtils';
import RAMQExplanatoryMessagesForCodeInReference from '../../../../../data/ramq-explanatory-messages-for-code-in-reference.json';

export const dateAndBillNumberColumn = {
  name: 'Date / Numéro facture RFP',
  primaryText: (line) => formatUnixToDate(line.dateRFP),
  secondaryText: (line) => `${line.billRFPNumber} ${line.lineNumberRFP ? `#${line.lineNumberRFP}` : ''}`
};

export const namColumn = {
  name: 'Nam / Codes',
  primaryText: (line) => line.patientName,
  secondaryText: (line) => (line.codes ? line.codes.join(', ') : '')
};

export const amountColumn = {
  name: 'Montant',
  align: 'right',
  primaryText: (line) => currencyFormat(line.paidAmountRFP)
};

export const amountPreviousAndCurrentColumn = {
  name: 'Montant actuel / Montant précédent',
  align: 'right',
  primaryText: (line) => currencyFormat(line.currentAmount),
  secondaryText: (line) => currencyFormat(line.previousAmount)
};
export const amountAndExplicationColumn = {
  name: 'Montant / Codes explicatifs',
  align: 'right',
  primaryText: (line) => `${currencyFormat(line.paidAmountRFP)}`,
  secondaryText: (line) =>
    `${line.explanationCode1} ${line.explanationCode2} ${line.explanationCode3} ${line.explanationCode4} ${line.explanationCode5}`
};

export const namAndIdentificationValueColumn = {
  name: 'Nam / Code',
  primaryText: (line) => line.patientName,
  secondaryText: (line) => (line.codes ? line.codes.join(', ') : '')
};

export const keepLineWithTransactionCode = (transactionCode, altTransactionCode) => (line) =>
  line.transactionCode === transactionCode || (altTransactionCode && line.transactionCode === altTransactionCode);

export const replaceExplanationMessageWithDetailedVersion = (line) => ({
  ...line,
  explanationText: fetchExplanationMatchingCode(line.explanationCodeNumber) || line.explanationText
});

export const actTIPIDateAttestationColumn = {
  name: 'Date / N. Attestation',
  primaryText: (line) =>
    `${line.dateReceived.substr(0, 1)}/${
      moment(line.dateReceived.substr(1), 'MMDD').isValid()
        ? moment(line.dateReceived.substr(1), 'MMDD').format('MM/DD')
        : ''
    }`,
  secondaryText: (line) => line.attestationNumber
};

export const actTIPINamTypeColumn = {
  name: 'Nam / Type',
  primaryText: (line) => line.beneficiary,
  secondaryText: (line) => line.actOrIncentiveType
};

export const actTIPIAmountCodeColumn = {
  name: 'Montant / Code explicatif',
  align: 'right',
  primaryText: (line) => currencyFormat(amountFormat(line.paidAmountOrLineNumberReference)),
  secondaryText: (line) => line.explanationCode
};

export const actRequestNumberTransactionCode = {
  name: 'N. requête / Code transaction',
  primaryText: (line) => line.requestNumber,
  secondaryText: (line) => line.transactionCode
};

export const ceilingCutStartDateColumn = {
  name: 'Date début période plafond',
  primaryText: (line) => formatUnixToDate(line.ceilingDateStart)
};

export const ceilingCutTypeColumn = {
  name: 'Type de plafond',
  primaryText: (line) => line.ceilingLabel
};

export const ceilingCutAmount = {
  name: 'Montant',
  align: 'right',
  primaryText: (line) => currencyFormat(line.cutAmount)
};

export const deductionCodeColumn = {
  name: 'Code',
  primaryText: (line) => line.deductionCode
};

export const deductionLabelColumn = {
  name: 'Type',
  primaryText: (line) => line.deductionLabel
};

export const deductionAmountColumn = {
  name: 'Montant',
  align: 'right',
  primaryText: (line) => currencyFormat(line.deductionAmount)
};

// This function is useful for a reduce function where we would want to extract specific or all values
// from a Ramq statement section object. For example, we would want to extract balancesChanges and postponedBalance in :
// balanceLines: [
//       {
//         AmountForMessage: '00000',
//         balanceChanges: '+00000000',
//         codeMessage: ' ',
//         paidAmount: '000000000',
//         postponedBalance: '-00003946',
//         startDateInterestOnNegativeBalance: '000000',
//         toBePostponedBalance: '+00000000'
//       }
//     ],
export const sumObjectValues = (propertiesToBeSum) => (initialSum, object) => {
  let values = [];
  if (Array.isArray(propertiesToBeSum)) {
    values = propertiesToBeSum.map((property) => object[property]);
  } else {
    values = Object.values(object);
  }
  values.forEach((value) => {
    // eslint-disable-next-line no-param-reassign
    initialSum += ramqStringAmountToFloat(value);
  });
  return initialSum;
};

export const calculateBalanceTotalAmount = (generalInformation) => {
  const propertiesToBeSum = ['balanceChanges', 'paidAmount'];

  const balance = generalInformation.balanceLines
    .reduce((currentAmount, object) => {
      const values = propertiesToBeSum.map((property) => object[property]);

      if (object.toBePostponedBalance) {
        values.push(object.toBePostponedBalance);
      } else {
        values.push(object.postponedBalance);
      }

      return currentAmount + values.reduce((acc, value) => acc + ramqStringAmountToFloat(value), 0);
    }, 0)
    .toFixed(2);

  return get(generalInformation, 'balanceTransferLines', []).reduce(
    (accumulator, { transferAmount }) => parseFloat(accumulator) + parseFloat(transferAmount),
    balance
  );
};

export const decorateWithReferenceNumber = (accumulator, currentBillLine, index, billLines) => {
  const { codes = [], dateRFP, idValue } = currentBillLine;

  if (!dateRFP) return accumulator;

  const codesToDisplay = idValue && idValue.trim() !== '' ? [idValue.trim()] : codes.slice();
  const { explanationCode1, explanationCode2, explanationCode3, explanationCode4, explanationCode5 } = currentBillLine;
  const billExplanationCodes = [
    explanationCode1,
    explanationCode2,
    explanationCode3,
    explanationCode4,
    explanationCode5
  ].filter(Boolean);

  accumulator.push({ ...currentBillLine, codes: codesToDisplay });

  if (intersection(RAMQExplanatoryMessagesForCodeInReference, billExplanationCodes).length === 0) return accumulator;

  const nextBillLine = billLines[index + 1];

  if (!nextBillLine || nextBillLine.idValue.trim() === '') return accumulator;

  const { idValue: referenceCode } = nextBillLine;

  if (referenceCode.trim() === '') return accumulator;

  codesToDisplay.push(`(${referenceCode.trim()})`);

  return accumulator;
};
