import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { savePeriod } from '../../../containers/PeriodsBilling/sagas';
import {
  SAVE_TRAINING,
  saveTrainingFailure,
  TRAINING_FILE_READY_FOR_UPLOAD,
  updateSavedAttachmentInTrainingForm
} from './actions';
import { logDefault, logError } from '../../../../shared/utils/plog';
import ActFile from '../../../containers/Storage/cordova/ActFile';
import StorageUploadContext from '../../../containers/Storage/uploader/StorageUploadContext';
import { selectUserIdInContext } from '../../../containers/User/selectors';
import actAttachmentRepository from '../../../containers/Storage/cordova/actAttachmentRepository';
import { getStore } from '../../../reduxStore/configureStore';
import storageUploader from '../../../containers/Storage/uploader/StorageUploader';
import { trackErrorInFirestore } from '../../../firebase/document/documentSagas';
import { CARD_ATTACHMENT_TYPE } from '../../../containers/Storage/constants';
import {
  getUpdatedAttachments,
  removeTemporaryFileFromDeviceLocalStorage,
  updateAttachmentInPersistedAct
} from '../../../containers/ActForm/sagas';
import { SYNCHRONIZED_ATTACHMENT } from '../../../containers/Storage/cordova/constants';
import { UPLOAD_ERROR, UPLOAD_SUCCESS } from '../../../containers/Storage/actions';
import { TRAINING_ATTACHMENT_TYPE } from '../../ui/constants';
import { selectAttachments, selectTrainingId } from './selectors';

export function* trainingFileReadyForUpload(action) {
  logDefault({
    type: 'saga',
    text: 'TrainingSagas/trainingFileReadyForUpload',
    array: ['Starting trainingFileReadyForUpload. action is', action]
  });

  try {
    const uploadContext = yield* getUploadContext(action);

    storageUploader.initiateUpload(uploadContext);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'TrainingSagas/trainingFileReadyForUpload',
      array: ['trainingFileReadyForUpload ERROR', action, e]
    });
    yield* trackErrorInFirestore(e, { type: 'trainingFileReadyForUploadError' });
  }
}

function* getUploadContext(action) {
  const { attachmentType, documentId, fileEntry, persistFileLocallyBeforeUpload } = action;
  const actAttachment = new ActFile(documentId, fileEntry);

  const uploadContext = new StorageUploadContext(documentId, fileEntry, persistFileLocallyBeforeUpload);
  uploadContext.fileWrapper = actAttachment;
  uploadContext.uploadType = attachmentType;
  uploadContext.userId = yield select(selectUserIdInContext());
  uploadContext.localFileRepository = actAttachmentRepository;
  uploadContext.postLocalPersistFileHook = postLocalPersistAttachment;
  uploadContext.storeDispatch = getStore().dispatch;
  return uploadContext;
}

export function postLocalPersistAttachment(actId, addedAttachment, localPath) {
  const currentAttachments = selectAttachments()(getStore().getState());
  const updatedAttachments = [
    ...currentAttachments,
    {
      type: CARD_ATTACHMENT_TYPE,
      location: localPath,
      fileName: addedAttachment.fileName,
      originalFileName: addedAttachment.originalFileName,
      temporary: true
    }
  ];

  logDefault({
    type: 'saga',
    text: 'TrainingSagas/postLocalPersistAttachment',
    array: ['Dispatching updateSavedAttachmentInTrainingForm action with attachment', updatedAttachments]
  });
  getStore().dispatch(updateSavedAttachmentInTrainingForm(updatedAttachments));
}

export function* trainingFileSuccessfullyUploaded(action) {
  const { downloadUrl, uploadTask } = action;
  const currentFormTrainingId = yield select(selectTrainingId());

  logDefault({
    type: 'saga',
    text: 'TrainingSagas/trainingFileSuccessfullyUploaded',
    array: [
      'Starting trainingFileSuccessfullyUploaded with action',
      action,
      'and currentFormTrainingId',
      currentFormTrainingId
    ]
  });

  try {
    yield* uploadAttachmentsInForm(currentFormTrainingId, uploadTask, downloadUrl);
    yield* uploadAttachmentsInPersistence(uploadTask, downloadUrl);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'TrainingSagas/trainingFileSuccessfullyUploaded',
      array: ['trainingFileSuccessfullyUploaded ERROR', uploadTask, downloadUrl, e]
    });
    yield* trackErrorInFirestore(e, { type: 'TrainingFileSuccessfullyUploadedError' });
  } finally {
    completeUploadProcess(uploadTask);
  }
}

function* uploadAttachmentsInForm(currentFormTrainingId, uploadTask, downloadUrl) {
  if (currentFormTrainingId === uploadTask.actId) {
    const attachments = yield select(selectAttachments());
    const updatedAttachments = getUpdatedAttachments(attachments, uploadTask, downloadUrl);

    logDefault({
      type: 'saga',
      text: 'TrainingSagas/trainingFileSuccessfullyUploaded',
      array: ['Updating attachment in training form']
    });
    yield put(updateSavedAttachmentInTrainingForm(updatedAttachments));
  }
}

function* uploadAttachmentsInPersistence(uploadTask, downloadUrl) {
  if (uploadTask.type === TRAINING_ATTACHMENT_TYPE) {
    logDefault({
      type: 'saga',
      text: 'TrainingSagas/trainingFileSuccessfullyUploaded',
      array: ['Updating attachment in persisted training']
    });
    yield* updateAttachmentInPersistedAct(uploadTask.actId, downloadUrl);
    yield* removeTemporaryFileFromDeviceLocalStorage(uploadTask.fileName, SYNCHRONIZED_ATTACHMENT);
  } else {
    logDefault({
      type: 'saga',
      text: 'TrainingSagas/trainingFileSuccessfullyUploaded',
      array: ['Upload task type not known to this saga. Skipping any processing.', uploadTask.type]
    });
  }
}

function completeUploadProcess(uploadTask) {
  if (uploadTask.type === TRAINING_ATTACHMENT_TYPE) {
    storageUploader.completeUploadProcessForFile(uploadTask.fileName);
  }
}

export function* trainingFileUploadError(action) {
  const { uploadTask } = action;

  yield call(storageUploader.completeUploadProcessForFile, uploadTask.fileName);
}

export default function* trainingsSaga() {
  yield takeEvery(TRAINING_FILE_READY_FOR_UPLOAD, trainingFileReadyForUpload);
  yield takeEvery(UPLOAD_ERROR, trainingFileUploadError);
  yield takeEvery(isActionUploadSuccessForTrainingAttachment, trainingFileSuccessfullyUploaded);
  yield takeLatest(SAVE_TRAINING, savePeriod, saveTrainingFailure);
}

export const isActionUploadSuccessForTrainingAttachment = (action) =>
  action.type === UPLOAD_SUCCESS && action.uploadTask.type === TRAINING_ATTACHMENT_TYPE;
