import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  selectFavoriteContextElementsPreferencesFromUser,
  selectHasEditableFavoriteContextElementsEnabled
} from '../../../../containers/User/selectors';
import {
  addContextElementToUserPreferences,
  removeContextElementFromUserPreferences,
  updateContextElementFromUserPreferences
} from '../../adapters/actions';
import ContextElement from '../../domain/ContextElement';
import Category from '../../../shared/domain/Category';
import { selectSpecialtyContextElementsFromUser } from '../../adapters/selectors';
import ItemAndCategoriesMapping from '../../../../shared/editable/domain/ItemAndCategoriesMapping';
import SearchDialog from './SearchDialog';

export interface OwnProps {
  open: boolean;
  // eslint-disable-next-line no-unused-vars
  onConfirm: (items: ContextElement[]) => void;
  onClose: () => void;
}

export interface ReduxStateProps {
  favorites: ItemAndCategoriesMapping;
  presets: Category<ContextElement>[];
  advancedEditionEnabled: boolean;
}

export interface ReduxDispatchProps {
  /* eslint-disable no-unused-vars */
  onAddToFavorites: (contextElement: ContextElement) => void;
  onUpdateFavorites: (favorites: ItemAndCategoriesMapping) => void;
  /* eslint-enable */
}

export interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

export const ContextElementSearchDialog: React.FunctionComponent<Props> = (props: Props) => {
  const { open, favorites, presets, advancedEditionEnabled, onAddToFavorites, onUpdateFavorites, onConfirm, onClose } =
    props;

  return (
    // @ts-ignore
    <SearchDialog
      open={open}
      favorites={favorites}
      presets={presets}
      advancedEditionEnabled={advancedEditionEnabled}
      onAddToFavorites={onAddToFavorites}
      onUpdateFavorites={onUpdateFavorites}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

export const mapStateToProps = createStructuredSelector({
  favorites: selectFavoriteContextElementsPreferencesFromUser(),
  presets: selectSpecialtyContextElementsFromUser(),
  advancedEditionEnabled: selectHasEditableFavoriteContextElementsEnabled()
});

export const mapDispatchToProps = (dispatch) => ({
  onAddToFavorites: (contextElement: ContextElement) => dispatch(addContextElementToUserPreferences(contextElement)),
  onRemoveFromFavorites: (contextElement: ContextElement) =>
    dispatch(removeContextElementFromUserPreferences(contextElement)),
  onUpdateFavorites: bindActionCreators(updateContextElementFromUserPreferences, dispatch)
});

export default connect<ReduxStateProps, ReduxDispatchProps, OwnProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(ContextElementSearchDialog);
