import useToast from 'application/hooks/use-toast';
import useTranslate from 'application/hooks/use-translate';
import { useCallback } from 'react';
import Activity from 'shared/core/activity/domain/Activity';

export default function useCopyActivityUrl() {
  const translate = useTranslate('hooks.copy-activity-url');
  const { addToast } = useToast();

  const handleCopySuccess = useCallback(
    (url) => {
      addToast({
        autoDismiss: true,
        icon: 'clipboard',
        message: translate('success', { url }),
        type: 'success'
      });
    },
    [addToast, translate]
  );

  const handleCopyError = useCallback(
    (error: Error) => {
      addToast({
        autoDismiss: true,
        icon: 'clipboard-exclamation',
        message: translate('error', { error: error.message }),
        type: 'error'
      });
    },
    [addToast, translate]
  );

  return useCallback(
    (activity: Activity) => {
      const queryParams = window.location.href.split('?')[1];
      let url = `${window.location.origin}/#/users/${activity.userId}/activities/${activity.type}/${activity.id}`;
      if (queryParams) {
        url += `?${queryParams}`;
      }
      navigator.clipboard
        .writeText(url)
        .then(() => handleCopySuccess(url))
        .catch(handleCopyError);
    },
    [handleCopyError, handleCopySuccess]
  );
}
