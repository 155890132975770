import PropTypes from 'prop-types';

export function ExpandableTableRowHeader({ children }) {
  return children;
}

ExpandableTableRowHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default ExpandableTableRowHeader;
