import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import {
  selectCodesUpdatingFromUser,
  selectHasActCodeSpecialtyListIncluded,
  selectHasEditableFavoriteActCodesEnabled
} from '../../../../../containers/User/selectors';
import isSameCodeItem from '../../../utils/isSameCodeItem';
import FavoritesActCodes from '../FavoritesActCodes/FavoritesActCodes';
import { ActCodeFavoriteSpecialtyList } from '../../../../shared/ui/SpecialtyList/SpecialtyList';
import FullScreenLoading from '../../../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import CodeSuggestion from '../../../../../components/List/Item/CodeSuggestion/CodeSuggestion';

export const styles = (theme) => ({
  container: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  },
  dialogActions: {
    justifyContent: 'space-between'
  }
});

export class FavoritesActCodesSelector extends React.Component {
  renderFavorites() {
    const {
      favoritesActCodes,
      hasEditableFavoritesEnabled,
      hasActCodeSpecialtyListIncluded,
      onClickItem,
      selectedCodes
    } = this.props;

    const canDisplaySpecialtyList = hasActCodeSpecialtyListIncluded && !hasEditableFavoritesEnabled;

    const isSelected = (item) => selectedCodes.some((selectedItem) => isSameCodeItem(selectedItem, item));

    const renderActCode = (item) => (
      <CodeSuggestion key={item.code} suggestion={item} onSuggestionClick={onClickItem} isSelected={isSelected(item)} />
    );

    return (
      <>
        <FavoritesActCodes
          favoritesCode={favoritesActCodes}
          hasEditableFavoritesEnabled={hasEditableFavoritesEnabled}
          isCodeSelected={isSelected}
          onSelectCode={onClickItem}
        />
        {canDisplaySpecialtyList && <ActCodeFavoriteSpecialtyList initiallyOpen={false} renderItem={renderActCode} />}
      </>
    );
  }

  render() {
    const { codesUpdatingFromPreference, onConfirm, onCancel, onEdit, classes } = this.props;

    return (
      <>
        <DialogContent className={classes.container}>
          {codesUpdatingFromPreference ? <FullScreenLoading /> : this.renderFavorites()}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <div>
            {onEdit && (
              <Button id="advanced-edit-mode" onClick={onEdit} color="primary">
                <FormattedMessage id="editableCollectionWithCategories.advancedEditMode" />
              </Button>
            )}
          </div>
          <div>
            <Button id="close-Dialog" onClick={onCancel} color="primary">
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button id="confirm-Dialog" onClick={onConfirm} color="primary">
              <FormattedMessage id="global.ok" />
            </Button>
          </div>
        </DialogActions>
      </>
    );
  }
}

FavoritesActCodesSelector.defaultProps = {
  onEdit: undefined
};

FavoritesActCodesSelector.propTypes = {
  favoritesActCodes: PropTypes.array.isRequired,
  selectedCodes: PropTypes.array.isRequired,
  hasEditableFavoritesEnabled: PropTypes.bool.isRequired,
  hasActCodeSpecialtyListIncluded: PropTypes.bool.isRequired,
  codesUpdatingFromPreference: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  onClickItem: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  hasEditableFavoritesEnabled: selectHasEditableFavoriteActCodesEnabled(),
  codesUpdatingFromPreference: selectCodesUpdatingFromUser(),
  hasActCodeSpecialtyListIncluded: selectHasActCodeSpecialtyListIncluded()
});

export default compose(connect(mapStateToProps), withStyles(styles))(FavoritesActCodesSelector);
