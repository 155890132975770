import { Snackbar, SnackbarContent } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import CloseIcon from '@material-ui/icons/Close';
import { clearGlobalMessage } from '../../../containers/Act/actions';
import { selectGlobalMessage } from '../../../containers/Act/selectors';
import SEVERITY_COLORS from '../../../ui/shared/severity/SeverityColors';
import SEVERITY_ICONS from '../../../ui/shared/severity/SeverityIcons';

const SIX_SECONDS = 6000;

export function GlobalMessageSnackBar(props) {
  const { severity, display, message } = props.globalMessage;
  const SeverityIcon = SEVERITY_ICONS[severity];

  return (
    <Snackbar
      autoHideDuration={SIX_SECONDS}
      open={display}
      onClose={props.clearMessage}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
    >
      <SnackbarContent
        style={{ flexWrap: 'nowrap', backgroundColor: SEVERITY_COLORS[severity] }}
        message={
          <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
            {SeverityIcon && <SeverityIcon style={{ marginRight: '10px' }} />}
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={props.clearMessage}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
}

export const mapDispatchToProps = {
  clearMessage: clearGlobalMessage
};

export const mapStateToProps = createStructuredSelector({
  globalMessage: selectGlobalMessage()
});

GlobalMessageSnackBar.propTypes = {
  clearMessage: PropTypes.func.isRequired,
  globalMessage: PropTypes.object.isRequired
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(GlobalMessageSnackBar);
