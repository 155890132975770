import moment from 'moment';
import calculateORFromStatementDate from '../../../../shared/periods/common/calculateORFromActDate';
import Period from '../../../../shared/periods/common/Period';

export const mapToStatementSelector = (statementDate) => {
  const or = calculateORFromStatementDate(Period.fromStatementDate(moment(statementDate)));
  return {
    label: `${statementDate} (OR ${or})`,
    value: statementDate
  };
};
