interface NamMetadata {
  namePreview: string;
  birthYear: string;
  birthMonth: string;
  birthDay: string;
  twinNumberCharacter: string;
  validationNumberCharacter: string;
}

const BIRTH_YEAR_PREFIXES = ['19', '20'];

const VALIDATION_CHARACTERS_MAP: Record<string, number> = {
  'A': 193,
  'B': 194,
  'C': 195,
  'D': 196,
  'E': 197,
  'F': 198,
  'G': 199,
  'H': 200,
  'I': 201,
  'J': 209,
  'K': 210,
  'L': 211,
  'M': 212,
  'N': 213,
  'O': 214,
  'P': 215,
  'Q': 216,
  'R': 217,
  'S': 226,
  'T': 227,
  'U': 228,
  'V': 229,
  'W': 230,
  'X': 231,
  'Y': 232,
  'Z': 233,
  '0': 240,
  '1': 241,
  '2': 242,
  '3': 243,
  '4': 244,
  '5': 245,
  '6': 246,
  '7': 247,
  '8': 248,
  '9': 249,
};

const VALIDATION_CHARACTER_POSITION_MULTIPLIERS = [1, 3, 7, 9, 1, 7, 1, 3, 4, 5, 7, 6, 9, 1];

export const parseNam = (nam: string): NamMetadata => {
  const match = nam.match(/^([A-Z]{4})(\d{2})(\d{2})(\d{2})(\d)(\d)$/);
  if (!match) {
    throw new Error(`Invalid NAM: ${nam}`);
  }

  const [
    _match,
    namePreview,
    rawBirthYear,
    rawBirthMonth,
    rawBirthDay,
    twinNumberCharacter,
    validationNumberCharacter,
  ] = match;

  // We use modulo 50 to handle birth month having +50 for women
  const parsedBirthMonth = parseInt(rawBirthMonth, 10);
  const birthMonth = parsedBirthMonth % 50;
  if (birthMonth < 1 || birthMonth > 12) {
    throw new Error(`Invalid NAM birth month: ${nam} (${birthMonth})`);
  }
  const paddedBirthMonth = birthMonth.toString().padStart(2, '0');

  const birthDay = parseInt(rawBirthDay, 10);
  if (birthDay < 1 || birthDay > 31) {
    throw new Error(`Invalid NAM birth day: ${nam} (${birthDay})`);
  }

  const parsedValidationNumberCharacter = parseInt(validationNumberCharacter, 10);
  const birthYearPrefix = BIRTH_YEAR_PREFIXES.find((centuryPrefix) => {
    const sexLetter = parsedBirthMonth >= 50 ? 'F' : 'M';
    const formula = `${namePreview}${centuryPrefix}${rawBirthYear}${sexLetter}${paddedBirthMonth}${rawBirthDay}${twinNumberCharacter}`;

    const computedValidationNumber =
      VALIDATION_CHARACTER_POSITION_MULTIPLIERS.reduce((acc, multiplier, index) => {
        const characterValue = VALIDATION_CHARACTERS_MAP[formula.charAt(index)] ?? 0;
        return acc + characterValue * multiplier;
      }, 0) % 10;

    return computedValidationNumber === parsedValidationNumberCharacter;
  });

  if (!birthYearPrefix) {
    throw new Error(`Invalid NAM validation number: ${nam} (${validationNumberCharacter})`);
  }

  const birthYear = `${birthYearPrefix}${rawBirthYear}`;
  const currentYear = new Date().getFullYear();

  if (parseInt(birthYear, 10) > currentYear) {
    throw new Error(`Invalid NAM birth year: ${nam} (${birthYear})`);
  }

  return {
    namePreview,
    birthYear,
    birthMonth: paddedBirthMonth,
    birthDay: rawBirthDay,
    twinNumberCharacter,
    validationNumberCharacter,
  };
};

export const validateNam = (nam: string): boolean => {
  try {
    parseNam(nam);
    return true;
  } catch (e) {
    return false;
  }
};
