import React, { useEffect, useState } from 'react';
import Alert from 'app/components/Alert/Alert';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Activity from 'shared/core/activity/domain/Activity';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { Mixte } from 'shared/domain/activity/mixte/model/Mixte';
import AlertSeverity from 'app/ui/shared/severity/AlertSeverity';
import moment from 'moment-timezone';
import { MixteDay } from 'shared/domain/collection/activity/Mixte.types';
import { selectDays } from 'app/containers/PeriodsBilling/selectors';
import { FormattedMessage } from 'react-intl';
import capitalize from 'lodash/capitalize';

interface OwnProps {
  currentMixte: Mixte;
}

interface ReduxProps {
  activities: Activity[];
  days: MixteDay[];
}

interface DayPerdiem {
  date: number;
  perdiem: string;
  placeNumber: string;
  poolNumber?: string;
}

interface OverlapMessage {
  perdiem: string;
  date: number;
  placeNumber: string;
  poolNumber?: string;
}

interface Props extends OwnProps, ReduxProps {}

const PerdiemOverlapOnSameDayWarning = ({ currentMixte, days, activities }: Props) => {
  const [overlappingPerdiems, setOverlappingPerdiems] = useState<DayPerdiem[]>([]);

  useEffect(() => {
    const otherMixtes = activities.filter(
      (activity) => activity.type === ActivityType.MIXTE && activity.id !== currentMixte.id
    ) as unknown as Mixte[];

    const overlappingDaysPerdiems = otherMixtes.reduce((acc: Array<DayPerdiem>, otherMixte) => {
      if (!currentMixte || otherMixte.id === currentMixte.id || !otherMixte.days) {
        return acc;
      }

      otherMixte.days.forEach((day) => {
        const mixteDay = days.find((currentDay) => currentDay.date === day.date);

        if (mixteDay && mixteDay.perdiems) {
          mixteDay.perdiems.forEach((perdiem) => {
            if (day.perdiems?.includes(perdiem)) {
              return acc.push({
                date: day.date,
                perdiem,
                placeNumber: otherMixte.place?.number,
                poolNumber: otherMixte.poolNumber
              });
            }
          });
        }
      });

      return acc;
    }, []);

    setOverlappingPerdiems(overlappingDaysPerdiems);
  }, [currentMixte, days, activities]);

  if (!overlappingPerdiems.length) {
    return null;
  }

  const overlapMessages = overlappingPerdiems.reduce((acc: OverlapMessage[], overlappingPerdiem) => {
    const { date, perdiem, placeNumber, poolNumber } = overlappingPerdiem;

    if (!acc.find((message) => message.date === date && message.perdiem === perdiem)) {
      acc.push({ date, perdiem, placeNumber, poolNumber });
    }

    return acc;
  }, []);

  return (
    <Alert
      severity={AlertSeverity.WARNING}
      noMargin
      showIcon
      title="Attention"
      message={
        <ul>
          {overlapMessages.map((overlapMessage) => (
            <li>
              <FormattedMessage
                id="period.perdiem.overlap"
                values={{
                  perdiem: <b>{overlapMessage.perdiem.toUpperCase()}</b>,
                  date: <b>{capitalize(moment(overlapMessage.date).format('dddd D'))}</b>,
                  place: <b>{overlapMessage.placeNumber}</b>,
                  pool: overlapMessage.poolNumber,
                  poolNumber: overlapMessage.poolNumber && <b>{overlapMessage.poolNumber}</b>
                }}
              />
            </li>
          ))}
        </ul>
      }
    />
  );
};

const mapStateToProps = createStructuredSelector({
  activities: selectActivitiesInContext(),
  days: selectDays()
});

export default connect<ReduxProps, {}, OwnProps>(mapStateToProps)(PerdiemOverlapOnSameDayWarning);
