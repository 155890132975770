import { Button, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import NamIcon from '../../../../components/Form/NamInput/NamIcon/NamIcon';
import { isValidNam } from '../../../../validations/act/nam/namValidation';
import { useState } from 'react';
import { usePostHog } from 'posthog-js/react';

export const ClickableDetectedNam = ({ nam, onSelectNam, selectedNam }) => {
  const posthog = usePostHog();
  const [namState, setNamState] = useState(nam);
  const [capturedPosthogEvent, setCapturedPosthogEvent] = useState(false);

  const handleChange = (event) => {
    if (!capturedPosthogEvent) {
      posthog.capture('Multiple NAMs Dialog - Edit NAM');
      setCapturedPosthogEvent(true);
    }
    setNamState(event.target.value ? event.target.value.toUpperCase() : '');
  };

  const onNamSelected = () => {
    posthog.capture('Multiple NAMs Dialog - Selected NAM');
    onSelectNam(namState.replace(/\s/g, ''));
  };

  let formattedMessageId = 'nam.dialog.choose';
  if (selectedNam) {
    formattedMessageId = selectedNam === nam ? 'nam.dialog.keep' : 'nam.dialog.replace';
  }

  return (
    <>
      <TextField defaultValue={namState} onChange={handleChange} />
      <NamIcon nam={namState} />
      <Button
        onClick={onNamSelected}
        style={{ marginLeft: '10px', width: '120px' }}
        variant="contained"
        color="primary"
        disabled={!isValidNam(namState)}
      >
        <FormattedMessage id={formattedMessageId} />
      </Button>
    </>
  );
};

ClickableDetectedNam.propTypes = {
  nam: PropTypes.string.isRequired,
  onSelectNam: PropTypes.func.isRequired,
  selectedNam: PropTypes.bool.isRequired
};

export default ClickableDetectedNam;
