import { PatientContext } from '../../../../shared/domain/activity/act/model/Act';
import PatientContextExtractor from '../../../../shared/domain/user/patientContext/PatientContextExtractor';
import queryBuilder from '../../../../shared/collection/QueryBuilder';
import { activitiesCollectionRef } from '../../../firebase/collection/collectionReferences';
import { ACTIVITIES_ACT_TYPE } from '../../../../shared/collection/collectionConstants';
import { getAllDataFromSnapshotWithBoundId } from '../../../firebase/collection/snapshotDataFetcher';

export default class FetchPatientContextFromNamUseCase {
  async execute(nam: string, userId: string): Promise<PatientContext | undefined> {
    const query = queryBuilder
      .withBaseQuery(activitiesCollectionRef())
      .withUserId(userId)
      .withNam(nam)
      .withType(ACTIVITIES_ACT_TYPE)
      .build();

    const result = await query.get();
    const acts = getAllDataFromSnapshotWithBoundId(result);

    return PatientContextExtractor.extractFromActs(acts);
  }
}
