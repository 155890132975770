import FavoriteIcon from '@material-ui/icons/Favorite';
import React from 'react';
import HOSPITAL_ACTIVITY_AREA_MAP from '../../../../shared/ramq/domainValues/activityAreas';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';

export const intensiveCareUnit = {
  label: 'S.I.',
  value: HOSPITAL_ACTIVITY_AREA_MAP.intensiveCareUnit
};
export const coronaryUnit = {
  label: <FavoriteIcon />,
  value: HOSPITAL_ACTIVITY_AREA_MAP.coronaryUnit
};
export const emergencyClinic = {
  label: 'URGENCE',
  value: HOSPITAL_ACTIVITY_AREA_MAP.emergencyClinic
};
export const generalCare = {
  label: 'HOSPIT',
  value: HOSPITAL_ACTIVITY_AREA_MAP.generalCare
};
export const externalClinic = {
  label: 'EXTERNE',
  value: HOSPITAL_ACTIVITY_AREA_MAP.externalClinic
};

export const allActivityAreas = [intensiveCareUnit, coronaryUnit, emergencyClinic, generalCare, externalClinic];

const activityAreaMap: any = {
  [SpecialtyName.ANESTHESIOLOGIST]: [intensiveCareUnit, externalClinic, coronaryUnit],
  [SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST]: [
    intensiveCareUnit,
    coronaryUnit,
    emergencyClinic,
    externalClinic,
    generalCare
  ],
  [SpecialtyName.INTERNIST]: [intensiveCareUnit, emergencyClinic, externalClinic, generalCare, coronaryUnit],
  [SpecialtyName.CARDIOLOGIST]: [intensiveCareUnit, emergencyClinic, externalClinic, generalCare, coronaryUnit],
  [SpecialtyName.ENDOCRINOLOGIST]: [intensiveCareUnit, emergencyClinic, externalClinic, generalCare, coronaryUnit],
  [SpecialtyName.PNEUMOLOGIST]: [intensiveCareUnit, emergencyClinic, externalClinic, generalCare, coronaryUnit],
  [SpecialtyName.GENERAL_SURGEON]: [intensiveCareUnit, emergencyClinic, externalClinic, generalCare],
  [SpecialtyName.ORTHOPEDIC_SURGEON]: [intensiveCareUnit, emergencyClinic, externalClinic, generalCare],
  [SpecialtyName.OTOLARYNGOLOGIST]: [intensiveCareUnit, emergencyClinic, externalClinic, generalCare]
};

export default activityAreaMap;
