import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import AssistantIcon from '@material-ui/icons/Assistant';
import useTranslate from 'application/hooks/use-translate';
import React from 'react';

const styles = () => ({
  avatarContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    flexDirection: 'column',
    height: 330,
    width: 330,
    boxShadow: 'inset 0 0 2px rgba(0,0,0,.12), inset 0 2px 4px rgba(0,0,0,.24)' // Thanks to wavebox style
  },
  avatarSvg: {
    height: '4em',
    width: '4em'
  }
});
function EmptyAdvicesIndicator({ classes }) {
  const translate = useTranslate();

  return (
    <div className={classes.avatarContainer}>
      <Avatar className={classes.avatar}>
        <AssistantIcon className={classes.avatarSvg} />
        <Typography variant="subtitle1" color="textSecondary">
          {translate('assistant.empty-assistant')}
        </Typography>
      </Avatar>
    </div>
  );
}

export default withStyles(styles)(EmptyAdvicesIndicator);
