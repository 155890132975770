import React from 'react';
import SelectInput from 'application/components/form-controls/select-input';
import useTranslate from 'application/hooks/use-translate';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import { DefaultBillingType } from 'application/hooks/use-firestore-user-profile';

interface Props extends ValidatedFieldProps {
  value: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

const DefaultBillingTypeInput = (props: Props) => {
  const translate = useTranslate('components.form-controls.default-billing-type-input');

  const options: Array<{ value: string; label: string }> = [];
  Object.values(DefaultBillingType).forEach((value) => {
    options.push({ value, label: translate(value) });
  });

  return <SelectInput options={options} {...props} />;
};

export default DefaultBillingTypeInput;
