import {
  INITIALIZE_ASSISTANT,
  INITIALIZE_ASSISTANT_FAILURE,
  REMOVE_ADVICE,
  UPDATE_ADVICE,
  UPDATE_UNREAD_MESSAGES_COUNT
} from './actions';

export const initialState = {
  advices: [],
  unreadMessagesCount: 0,
  loading: true
};

function assistantReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_ASSISTANT:
      return {
        ...state,
        loading: true,
        advices: []
      };
    case INITIALIZE_ASSISTANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case REMOVE_ADVICE:
      return {
        ...state,
        advices: state.advices.filter((advice) => advice.id !== action.id),
        loading: false
      };
    case UPDATE_ADVICE: {
      const existingIndex = state.advices.findIndex((advice) => advice.id === action.advice.id);

      if (existingIndex === -1) {
        return {
          ...state,
          advices: [...state.advices, action.advice],
          loading: false
        };
      }
      return {
        ...state,
        advices: state.advices.map((advice, index) => {
          if (index === existingIndex) {
            return action.advice;
          }
          return advice;
        }),
        loading: false
      };
    }
    case UPDATE_UNREAD_MESSAGES_COUNT:
      return {
        ...state,
        unreadMessagesCount: action.unreadMessagesCount
      };
    default:
      return state;
  }
}

export default assistantReducer;
