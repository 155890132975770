import { CheckboxField, SingleLineField } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import { isNotAValidValue } from '../../../validations/common/reduxFormValidation';
import { noValidAuthorizationFieldError } from '../ReductionMammaire150g/reductionMammaire150g';

export const PCMA_CODES = [
  '1029',
  '1386',
  '1388',
  '1395',
  '1401',
  '1402',
  '1407',
  '1409',
  '1410',
  '1419',
  '1463',
  '1464',
  '1465'
];

const pcmaCodeHasOnlyOneValueSelected = (code) =>
  PCMA_CODES.includes(code.code) &&
  ((isNotAValidValue(code.authorizationNumber) && isNotAValidValue(code.pcma)) ||
    (isNotAValidValue(code.authorizationNumber) && !code.pcma) ||
    (!isNotAValidValue(code.authorizationNumber) && code.pcma));

const OnlyOneFieldMustBeFilled = (act) => {
  const codesInError = act.codes.map((code, index) =>
    pcmaCodeHasOnlyOneValueSelected(code) ? noValidAuthorizationFieldError(index) : {}
  );
  return codesInError.reduce(combineErrors, {});
};

const summaryRender = (code) => [
  {
    name: code.pcma ? 'pcma' : 'autorisation',
    value: code.pcma ? 'Oui' : code.authorizationNumber
  }
];

const associatedValidations = [OnlyOneFieldMustBeFilled];

const associatedFields = [
  {
    label: 'No Autorisation',
    getName: (index) => `codes[${index}].authorizationNumber`,
    notRequired: true,
    type: SingleLineField
  },
  {
    label: 'Post-chirurgie mammaire assurée',
    getName: (index) => `codes[${index}].pcma`,
    notRequired: true,
    type: CheckboxField
  }
];

const noAutorisationPcma = { associatedFields, associatedValidations, summaryRender };

export default noAutorisationPcma;
