import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { StyledComponentProps } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import HourMinuteSelector from '../HourMinuteSelector/HourMinuteSelector';
import HourMinute from '../HourMinuteSelector/HourMinute/HourMinute';

export const styles = (theme) => ({
  timeContainer: {
    display: 'flex',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3)
  }
});

export interface Props extends StyledComponentProps {
  maxHour?: number;
  minMinutes?: number;
  maxMinutes?: number;
  minutesOnly?: boolean;
  minutesPrecision?: number;
  numberRows?: number;
  // eslint-disable-next-line no-unused-vars
  onItemSelected: (hourMinute: HourMinute | null) => void;
  onClose: () => void;
  open: boolean;
  selectedHourMinute?: HourMinute;
}

export class HourMinuteSelectorDialog extends React.Component<Props> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.eraseSelection = this.eraseSelection.bind(this);
  }

  handleOnChange(hourMinute: HourMinute) {
    this.props.onItemSelected(hourMinute);
    this.props.onClose();
  }

  closeDialog() {
    this.props.onClose();
  }

  eraseSelection() {
    this.props.onItemSelected(null);
    this.props.onClose();
  }

  render() {
    const {
      open,
      selectedHourMinute,
      maxHour = 23,
      minMinutes,
      maxMinutes,
      minutesOnly,
      minutesPrecision = 5,
      numberRows = 6,
      classes
    } = this.props;

    return (
      <Dialog open={open}>
        <DialogContent classes={{ root: classes!.timeContainer }}>
          <HourMinuteSelector
            maxHour={maxHour}
            minMinutes={minMinutes}
            maxMinutes={maxMinutes}
            minutesOnly={minutesOnly}
            minutesPrecision={minutesPrecision}
            numberRows={numberRows}
            onChange={this.handleOnChange}
          />
        </DialogContent>
        <DialogActions>
          {selectedHourMinute && (
            <Button data-testid="erase-button" onClick={this.eraseSelection} color="primary">
              <FormattedMessage id="time-selector-dialog.erase" />
            </Button>
          )}
          <Button data-testid="close-button" onClick={this.closeDialog} color="primary">
            <FormattedMessage id="time-selector-dialog.cancel" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(HourMinuteSelectorDialog);
