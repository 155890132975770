import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import { StatementLine } from './StatementDoctorInfo';
import StatementSectionContainer from './StatementSectionContainer';

export function StatementFederationSum({ generalInformation }) {
  const addUpAmounts = (sum, current) => sum + parseFloat(current.federationAmount);
  const cotisationAmountSum = generalInformation.federationContributionsLines.reduce(addUpAmounts, 0).toString();
  return (
    <StatementSectionContainer withDivider>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Cotisations:', currencyFormat(cotisationAmountSum))}
          secondaryLines={generalInformation.federationContributionsLines.map((federationContribution) =>
            StatementLine(
              federationContribution.federationName,
              currencyFormat(federationContribution.federationAmount)
            )
          )}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine="Cumulatif cotisation de fédération"
          secondaryLines={[
            StatementLine(
              generalInformation.federationContributionSumInfo.federationName,
              currencyFormat(generalInformation.federationContributionSumInfo.federationTotalAmount)
            )
          ]}
        />
      </Grid>
    </StatementSectionContainer>
  );
}

StatementFederationSum.propTypes = {
  generalInformation: PropTypes.object.isRequired
};

export default StatementFederationSum;
