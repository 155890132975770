import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'places.title',
    defaultMessage: 'Lieux'
  },
  close: {
    id: 'places.close',
    defaultMessage: 'Fermer'
  },
  searchLabel: {
    id: 'places.searchLabel',
    defaultMessage: 'Rechercher lieux'
  }
});
