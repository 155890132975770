import { createSelector } from '@reduxjs/toolkit';

const selectUsersState = (state) => state.users;

const selectUsers = () => createSelector(selectUsersState, (usersState) => usersState.users);

const selectFilteredUserIds = () => createSelector(selectUsersState, (usersState) => usersState.filteredUserIds);

export const selectFilteredUsers = () =>
  createSelector(selectUsers(), selectFilteredUserIds(), (users = [], userIds) => {
    if (userIds === null) {
      return users;
    }

    return users.filter(({ id }) => userIds.includes(id));
  });

export const isUsersLoading = () => createSelector(selectUsersState, (usersState) => usersState.loading);

export const isUsersSearching = () => createSelector(selectUsersState, (usersState) => usersState.searching);

export const selectUsersMeta = () => createSelector(selectUsersState, (usersState) => usersState.usersMeta);

export const selectSearchedUserIds = () => createSelector(selectUsersState, (usersState) => usersState.searchedUserIds);

export default selectUsers;
