import { ReactNode } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import AlertSeverity from './AlertSeverity';

type SeverityIcons = {
  [key in AlertSeverity]: ReactNode | Function;
};

const SEVERITY_ICONS: SeverityIcons = {
  default: undefined,
  error: ErrorOutlineIcon,
  info: InfoOutlinedIcon,
  success: CheckCircleOutlineIcon,
  none: undefined,
  warning: ReportProblemOutlinedIcon,
  recommendation: FlashOnIcon
};

export default SEVERITY_ICONS;
