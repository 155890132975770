const FOOTER_HEIGHT = 68;

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  codeSelectionSection: {
    minHeight: '120px'
  },
  form: {
    maxHeight: `calc(100% - ${FOOTER_HEIGHT}px)`,
    marginBottom: `${FOOTER_HEIGHT}px`
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    height: `${FOOTER_HEIGHT}px`
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  intervalInput: {
    flex: 1
  },
  startInput: {
    width: '100%'
  },
  patientManager: {
    marginTop: theme.spacing(6)
  },
  billingTypeSelector: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2) * -1,
    marginRight: theme.spacing(2) * -1,
    marginTop: theme.spacing(3) * -1
  }
});

export default styles;
