import { createSelector } from '@reduxjs/toolkit';
import { getUser, selectPreferences } from '../../../containers/User/selectors';

const selectSpecialtyPresetActCodes = (state) => state.specialtyPresetActCodes;
const selectSpecialtyPresetActCodesLoaded = (state) => state.specialtyPresetActCodesLoaded;

const selectSpecialtyFavoritesActCodes = (state) => state.specialtyFavoritesActCodes;
const selectSpecialtyFavoritesActCodesLoaded = (state) => state.specialtyFavoritesActCodesLoaded;

export const selectPreferenceFromUser = () => createSelector(getUser, selectPreferences);

export const selectActCodeSpecialtyPresetFromUser = () =>
  createSelector(selectPreferenceFromUser(), selectSpecialtyPresetActCodes);

export const selectActCodesSpecialtyPresetLoadedFromUser = () =>
  createSelector(selectPreferenceFromUser(), selectSpecialtyPresetActCodesLoaded);

export const selectActCodeSpecialtyFavoritesFromUser = () =>
  createSelector(selectPreferenceFromUser(), selectSpecialtyFavoritesActCodes);

export const selectActCodesSpecialtyFavoritesLoadedFromUser = () =>
  createSelector(selectPreferenceFromUser(), selectSpecialtyFavoritesActCodesLoaded);
