import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import { DateField } from '../../components/Form/Fields';
import FormElement from '../../components/Form/FormElement/FormElement';
import FormRow from '../../components/Form/FormRow/FormRow';
import TimePickerSyncedWithDate from '../../components/Form/TimePickerSyncedWithDate/TimePickerSyncedWithDate';
import TravelExpenseSectionHeader from './TravelExpenseSectionHeader';
import TravelPlaceInformation from './TravelPlaceInformation';

const styles = (theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2)
  }
});

function TravelInformationBox({
  label,
  reduxFormDateName,
  reduxFormTimeName,
  reduxFormPlaceName,
  dateSelector,
  travelExpenses,
  classes
}) {
  const translate = useTranslate();
  return (
    <Paper className={classes.wrapper}>
      <TravelExpenseSectionHeader label={label} />
      <div className={classes.content}>
        <FormRow>
          <FormElement
            name={`travelInformation.${reduxFormDateName}`}
            label={translate('act.date')}
            component={DateField}
          />
          <FormElement
            name={`travelInformation.${reduxFormTimeName}`}
            label={translate('travel-expenses.time')}
            component={TimePickerSyncedWithDate}
            dateSelector={dateSelector}
          />
        </FormRow>
        <FormElement
          name={`travelInformation.${reduxFormPlaceName}`}
          component={TravelPlaceInformation}
          defaultPlace={travelExpenses.travelInformation[reduxFormPlaceName]}
        />
      </div>
    </Paper>
  );
}

TravelInformationBox.propTypes = {
  label: PropTypes.string.isRequired,
  reduxFormDateName: PropTypes.string.isRequired,
  reduxFormTimeName: PropTypes.string.isRequired,
  reduxFormPlaceName: PropTypes.string.isRequired,
  dateSelector: PropTypes.func.isRequired,
  travelExpenses: PropTypes.object.isRequired
};

export default withStyles(styles)(TravelInformationBox);
