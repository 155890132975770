export const PRINT = 'print';
export const PRINT_END = 'printEnd';

export function print() {
  return {
    type: PRINT
  };
}

export function printEnd() {
  return {
    type: PRINT_END
  };
}
