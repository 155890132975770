import { addUploadTask } from '../actions';
import { acquireLock, isLocked, releaseLock } from '../../../../utils/lock';
import { getUserActStorageReference } from '../../../firebase/storage/storageRepository';

import { logDefault, logError } from '../../../../shared/utils/plog';
import { trackErrorInFirestore } from '../../../firebase/document/documentSagas';

class StorageUploader {
  async initiateUpload(storageUploadContext) {
    logDefault({
      type: 'StorageUploader',
      text: 'StorageUploader/initiateUpload',
      array: ['Starting initiateUpload for file', storageUploadContext.fileWrapper.fileName]
    });

    try {
      if (storageUploadContext.persistFileLocallyBeforeUpload) {
        const fileData = await this.readFileData(storageUploadContext.fileWrapper);
        const localPath = await storageUploadContext.localFileRepository.writeFile(
          storageUploadContext.fileWrapper.fileName,
          fileData
        );

        if (storageUploadContext.postLocalPersistFileHook) {
          storageUploadContext.postLocalPersistFileHook(
            storageUploadContext.actId,
            storageUploadContext.fileWrapper,
            localPath
          );
        }
      }

      this.addUploadFileTaskWithLock(storageUploadContext);
    } catch (e) {
      logError({
        type: 'StorageUploader',
        text: 'StorageUploader/initiateUpload',
        array: ['initiateUpload error for file', storageUploadContext.fileWrapper.fileName, 'ERROR is', e]
      });
      trackErrorInFirestore(e, { type: 'initiateUploadError' });
    }
  }

  addUploadFileTaskWithLock(storageUploadContext) {
    if (acquireLock(storageUploadContext.fileWrapper.fileName)) {
      this.addNewUploadTask(storageUploadContext);
    }
  }

  async addNewUploadTask(storageUploadContext) {
    if (storageUploadContext.storeDispatch) {
      const userActStorageReference = getUserActStorageReference(
        storageUploadContext.userId,
        storageUploadContext.actId,
        storageUploadContext.fileWrapper.fileName
      );

      const fileData = await this.readFileData(storageUploadContext.fileWrapper);

      logDefault({
        type: 'StorageUploader',
        text: 'StorageUploader/addNewUploadTask',
        array: ['Adding new upload task for file', storageUploadContext.fileWrapper.fileName]
      });
      // Once the upload is completed, it is the caller's reposibility to listen on the Storage's UPLOAD_SUCCESS saga and
      // determine whether the uploaded file is of his concern. For example, when uploading an attachment file, the act form and
      // the travel expense form will both listen on the saga, but only one will take action, depending on the active form
      storageUploadContext.storeDispatch(
        addUploadTask({
          actId: storageUploadContext.fileWrapper.actId,
          id: storageUploadContext.fileWrapper.fileName,
          type: storageUploadContext.uploadType,
          fileName: storageUploadContext.fileWrapper.fileName,
          originalFileName: storageUploadContext.fileWrapper.originalFileName,
          metadata: storageUploadContext.fileWrapper.metadata,
          storageReference: userActStorageReference,
          data: fileData
        })
      );
    } else {
      logDefault({
        type: 'StorageUploader',
        text: 'StorageUploader/addNewUploadTask',
        array: [
          'storeDispatch not set. Cannot initiate file upload',
          storageUploadContext && storageUploadContext.fileWrapper
        ]
      });
    }
  }

  async readFileData(actFile) {
    return actFile.data;
  }

  isFileBeingUploaded(fileName) {
    return isLocked(fileName);
  }

  completeUploadProcessForFile(fileName) {
    releaseLock(fileName);
  }
}

const storageUploader = new StorageUploader();
export default storageUploader;
