export const SAVE_LUMP_SUM = 'saveLumpSum';
export const SAVE_LUMP_SUM_FAILURE = 'saveLumpSumFailure';

export function saveLumpSum(document) {
  return {
    type: SAVE_LUMP_SUM,
    document
  };
}

export function saveLumpSumFailure(error) {
  return {
    type: SAVE_LUMP_SUM_FAILURE,
    error
  };
}
