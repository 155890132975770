import moment from 'moment-timezone';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import RAMQCodes, { TimedAttributeKey } from 'shared/ramq/RamqCodes';
import Act, { isAct } from '../../../../shared/domain/activity/act/model/Act';
import Period, { Inclusivity } from '../../../../shared/periods/common/Period';
import { selectActivitiesInContext } from '../../../containers/Activities/selectors';
import { getStore } from '../../../reduxStore/configureStore';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { DAILY_CHRONIC_PAIN_PLAN } from '../../../../userCustomization/featureNames';

const NO_WARNINGS = [];

export const chronicPainDayExclusiveCodesValidation = (act: Partial<Act>) => {
  if (!getFeatureConfig(DAILY_CHRONIC_PAIN_PLAN, false)) return NO_WARNINGS;

  if ((act.codes ?? []).length === 0) return NO_WARNINGS;

  const isPainDay = (selectActivitiesInContext()(getStore().getState()) ?? []).some((dayActivity) => {
    if (!act.date) return false;

    return activityIsChronicPainDayForDate(dayActivity, act.date);
  });

  if (!isPainDay) {
    return NO_WARNINGS;
  }

  const nonExclusiveCode = findNonExlusiveCode(act);

  if (nonExclusiveCode && isActWithinTimeFrame(act)) {
    return [
      `Le code ${nonExclusiveCode} ne fait pas partie de la liste des codes autorisés pendant une période de douleur. Vous pouvez l'ajouter si vous êtes à l'extérieur de cette période. N'oubliez pas d'ajouter un mixte si applicable`
    ];
  }

  return NO_WARNINGS;
};

const activityIsChronicPainDayForDate = (activity: Act, date: number): boolean =>
  isAct(activity) &&
  activity.status !== ActivityStatus.CANCELED &&
  activity.date === date &&
  isChronicPainDayAct(activity) &&
  isActWithinTimeFrame(activity);

const isChronicPainDayAct = (act: Act): boolean =>
  act.codes.some(({ code }) => {
    const ramqCode = RAMQCodes.get(code);

    if (!ramqCode || !RAMQCodes.isCodeEffectiveOn(ramqCode, act.date)) return false;

    return RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isPainDay, act.date);
  });

const isActWithinTimeFrame = (act: Partial<Act>): boolean => {
  const startTime = act.remp ?? act.start;

  if (!startTime) return false;

  const start = moment(act.remp ?? act.start);
  const hourStart = start.clone().set({ hour: 7, minute: 0, second: 0 });
  const hourEnd = start.clone().set({ hour: 17, minute: 0, second: 0 });

  return Period.fromMoment(hourStart, hourEnd).contains(start, { end: Inclusivity.INCLUSIVE });
};

const findNonExlusiveCode = (act: Partial<Act>): string | undefined => {
  const nonExclusiveActCode = (act.codes ?? []).find(({ code }) => {
    if (!act.date) return;

    const ramqCode = RAMQCodes.get(code);

    if (!ramqCode) return false;

    return (
      !RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isPainDay, act.date) &&
      !RAMQCodes.getTimedAttribute(ramqCode, TimedAttributeKey.isPainDayCompatible, act.date)
    );
  });
  return nonExclusiveActCode?.code;
};

export default chronicPainDayExclusiveCodesValidation;
