import shortid from 'shortid';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  chip: {
    margin: 2
  },
  dense: {
    'marginTop': 1,
    'marginBottom': 1,
    'height': 15,
    'marginRight': 2,
    'marginLeft': 2,
    '&:first-child': {
      marginLeft: 0
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  denseLabel: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  }
});

export function ChipList({
  list,
  objectPropertyToBeDisplayed,
  objectPropertyToUseAsKey,
  onClick,
  onDelete,
  classes,
  className,
  dense
}) {
  const mergedClassName = classNames(classes.row, className);

  const useAsKey = objectPropertyToUseAsKey !== undefined ? objectPropertyToUseAsKey : objectPropertyToBeDisplayed;

  return (
    <div className={mergedClassName}>
      {list.map((value, index) => (
        <Chip
          onClick={onClick ? () => onClick(value) : undefined}
          onDelete={onDelete ? () => onDelete(value, index) : undefined}
          label={value[objectPropertyToBeDisplayed]}
          key={value[useAsKey] || shortid.generate()}
          className={classNames(classes.chip, { [classes.dense]: dense })}
          classes={dense ? { label: classes.denseLabel } : {}}
        />
      ))}
    </div>
  );
}

ChipList.propTypes = {
  list: PropTypes.array,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  objectPropertyToBeDisplayed: PropTypes.string.isRequired,
  objectPropertyToUseAsKey: PropTypes.string,
  className: PropTypes.string,
  dense: PropTypes.bool
};

ChipList.defaultProps = {
  className: '',
  list: [],
  objectPropertyToUseAsKey: undefined,
  onClick: undefined,
  onDelete: undefined,
  dense: undefined
};

export default withStyles(styles)(ChipList);
