import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToFullDateAndTime } from '../../../../../../shared/utils/dateTime/dateTimeUtils';

const styles = (theme) => ({
  date: {
    'alignSelf': 'center',
    '&:first-child': {
      paddingLeft: theme.spacing(3)
    }
  }
});

function DateSummary({ date, className, classes }) {
  return (
    <ListItemText primary={formatUnixToFullDateAndTime(date)} className={className} classes={{ root: classes.date }} />
  );
}

DateSummary.propTypes = {
  date: PropTypes.number.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

DateSummary.defaultProps = {
  className: ''
};

export default withStyles(styles)(DateSummary);
