import { DIFFERENT_SITE, LEFT, RIGHT } from '../../../../shared/ramq/requiredDetails/differentSiteConstants';
import { DeletableValueOnUnmountSingleSelectCheckboxGroup } from '../../../components/Form/Fields';

export const LEFT_RIGHT_CHECKBOXES = [
  { label: 'Gauche', value: LEFT },
  { label: 'Droite', value: RIGHT }
];

export const CODE_WHOM_FIELD_SHOULD_BE_ALWAYS_SHOWN = ['1463'];

const shouldFieldBeHidden = (currentCode, codeList) =>
  !CODE_WHOM_FIELD_SHOULD_BE_ALWAYS_SHOWN.includes(currentCode.code) && codeOnlyEnteredOnce(currentCode, codeList);

const codeOnlyEnteredOnce = (currentCode, codeList) =>
  codeList.filter((code) => code.code === currentCode.code).length === 1;

const summaryRender = (code) =>
  code[DIFFERENT_SITE] ? [{ name: 'site diff.', value: code[DIFFERENT_SITE] === LEFT ? 'Gauche' : 'Droite' }] : [];

const associatedFields = [
  {
    getName: (index) => `codes[${index}].${DIFFERENT_SITE}`,
    type: DeletableValueOnUnmountSingleSelectCheckboxGroup,
    shouldFieldBeHidden,
    list: LEFT_RIGHT_CHECKBOXES
  }
];

const siteDiff = { associatedFields, summaryRender };

export default siteDiff;
