import React from 'react';
import Typography from '@material-ui/core/Typography';
import { LatestMixteDayPeriodFormDto, MixteDayCode } from '../../../../../containers/PeriodsBilling/PeriodForm/type';
import { MixteDay } from '../../../../../../shared/domain/activity/mixte/model/Mixte';
import PerdiemsWithText from '../../PerdiemsWithText/PerdiemsWithText';
import isDefined from '../../../../../../shared/utils/isDefined';

export interface Props {
  mixteDay: LatestMixteDayPeriodFormDto | MixteDay;
}

function formatPeriodCode(codes: MixteDayCode[]): string {
  return codes
    .filter((mixteDayCode: MixteDayCode) => isDefined(mixteDayCode.code) && isDefined(mixteDayCode.duration))
    .map((mixteDayCode: MixteDayCode) => `${mixteDayCode.code} (${mixteDayCode.duration} H)`)
    .join(' - ');
}

const LatestMixtePeriodDetail: React.FunctionComponent<Props> = ({ mixteDay }) => (
  <>
    <PerdiemsWithText
      perdiems={mixteDay.perdiems || []}
      text={`Mixte${mixteDay.manualEditionMode ? ' - manuel ' : ''}`}
    />
    {'codes' in mixteDay && <Typography variant="caption">{formatPeriodCode(mixteDay.codes)}</Typography>}
  </>
);

export default LatestMixtePeriodDetail;
