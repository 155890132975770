import { ActCode } from './ActCode';
import { RequiredDetails } from '../requiredDetail.type';
import amPm from '../AmPm/amPm';
import studentNumber from '../StudentNumber/studentNumber';

export default class ActCode19746 implements ActCode {
  getRequiredDetails(): RequiredDetails {
    return [studentNumber('8XXXXX'), amPm];
  }
}
