import { selectUserLanguage } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import firebase from 'firebase/compat/app';

export default async function request(url, options) {
  const userToken = await firebase.auth().currentUser!.getIdToken();
  const language = selectUserLanguage()(getStore().getState());
  const optionsWithAuthorizationToken = Object.assign(options, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userToken}`,
      ...(language && { 'Accept-Language': language })
    }
  });

  return fetch(url, optionsWithAuthorizationToken).then(checkStatus).catch(handleResponseError);
}

export const downloadFile = async (response: Response): Promise<void> => {
  const contentDispositionHeader = response.headers.get('Content-Disposition');

  if (!contentDispositionHeader) {
    throw new Error('Content-Disposition header missing');
  }

  const filename = contentDispositionHeader
    .split(';')
    .find((part) => part.includes('filename='))!
    .replace('filename=', '')
    .trim();

  const fileData = await response.blob();

  const a = document.createElement('a');
  const url = window.URL.createObjectURL(fileData);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();

  a.remove();
  window.URL.revokeObjectURL(url);
};

const checkStatus = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const text = await response.text();
  throw new Error(text);
};

const handleResponseError = (error) => {
  throw error;
};

export const processResponse = async (sentRequest: Promise<any>): Promise<any> => {
  const response = await sentRequest;
  const json = await response.json();

  return json.data;
};
