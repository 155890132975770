import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import { ACTIVITIES_ACT_TYPE } from '../../../shared/collection/collectionConstants';
import { getEndOfDay, getStartOfDay } from '../../../shared/utils/dateTime/dateTimeUtils';

const queryActsByDateForCurrentUser = async (date, userId) => {
  const beginningOfTheDay = getStartOfDay(date);
  const endOfTheDay = getEndOfDay(date);

  const { docs } = await activitiesCollectionRef()
    .where('type', '==', ACTIVITIES_ACT_TYPE)
    .where('userId', '==', userId)
    .where('date', '>=', beginningOfTheDay.valueOf())
    .where('date', '<=', endOfTheDay.valueOf())
    .get();

  return docs.map((doc) => doc.data());
};

export default {
  queryActsByDateForCurrentUser
};
