import firebase from 'firebase/compat/app';
import { User } from 'firebase/auth';
import axios from 'axios';
import RALPH_API_BASE_URL from 'app/infrastructure/api/ralphApi';
import { auth, firestore } from '../../../../server/Firebase';
import { UserModel } from '../../../../shared/domain/authentication/AuthUser';
import AuthenticationService from '../../../../shared/domain/authentication/service/AuthenticationService';
import FirebaseUserToDomainUserObjectMapper from '../factory/FirebaseUserToDomainUserObjectMapper';
import Role from '../../../../shared/domain/authentication/Role';

const MFA_ERROR_CODE = 'auth/multi-factor-auth-required';

class FirebaseAuthenticationService implements AuthenticationService {
  async signIn(email: string, password: string): Promise<void> {
    return auth.signInWithEmailAndPassword(email, password).catch(async (error) => {
      if (error.code === MFA_ERROR_CODE) {
        const { resolver } = error;

        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session
        };

        // eslint-disable-next-line new-cap
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('login-button', {
          size: 'invisible'
        });

        return phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
          .then((verificationId) => ({
            resolver,
            verificationId
          }))
          .catch((error) => {
            throw error;
          })
          .finally(() => recaptchaVerifier.clear());
      }

      throw error;
    });
  }

  async mfaSignIn(mfaToken: string, mfaChallenge): Promise<void> {
    const credential = firebase.auth.PhoneAuthProvider.credential(mfaChallenge.verificationId, mfaToken);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(credential);

    return mfaChallenge.resolver.resolveSignIn(multiFactorAssertion);
  }

  async signOut(): Promise<void> {
    return auth.signOut();
  }

  // eslint-disable-next-line no-unused-vars
  onAuthStateChanged(callback: (user: UserModel | null) => void): void {
    auth.onAuthStateChanged(async (user: User | null) => {
      if (user) {
        const { claims } = await auth.currentUser.getIdTokenResult();
        const role = claims.role as Role;
        const permissions = await this.retrievePermissionsFromRole(role);

        callback(FirebaseUserToDomainUserObjectMapper.map(user, role, permissions));
      } else {
        callback(null);
      }
    });
  }

  private async retrievePermissionsFromRole(role: Role) {
    const snapshot = await firestore.collection('roles').doc(role).get();

    let permissions = [];
    if (snapshot.exists) {
      ({ permissions } = snapshot.data());
    }
    return permissions;
  }

  async updatePassword(email: string, newPassword: string): Promise<void> {
    return auth.currentUser.updatePassword(newPassword);
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    return auth.sendPasswordResetEmail(email);
  }

  async logoutFromAllDevices(): Promise<void> {
    await axios.delete(`${RALPH_API_BASE_URL}/users/logout-all-devices`, {
      headers: {
        'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`,
        'Content-Type': 'application/json'
      }
    });
    this.signOut();
  }
}

export default FirebaseAuthenticationService;
