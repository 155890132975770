export const BILL_PERIOD = 'billPeriod';
export const billPeriod = (periodId) => ({
  type: BILL_PERIOD,
  id: periodId
});

export const BILL_ACTIVITY = 'billActivity';
export const billActivity = (activityId, options) => ({
  type: BILL_ACTIVITY,
  id: activityId,
  options
});

export const CANCEL_ACTIVITY = 'cancelActivity';
export const cancelActivity = (actId, options) => ({
  type: CANCEL_ACTIVITY,
  id: actId,
  options
});
