import React from 'react';
import { Button, Grid, withStyles, WithStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { WrappedFieldArrayProps } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import AbsenceDialog from './AbsenceDialog/AbsenceDialog';
import DialogTrigger from '../../Dialogs/DialogTrigger/DialogTrigger';
import AlertApi from '../../../shared/confirm/api/AlertApi';
import AbsenceField from './AbsenceField';
import FormElement from '../FormElement/FormElement';
import Absence from '../../../../shared/domain/user/Absence/Absence';

const styles = (theme) =>
  ({
    ul: {
      borderLeft: '1px #CCCCCC dashed',
      margin: '0 0 0 5px',
      padding: 0
    },
    li: {
      'listStyle': 'none',
      'position': 'relative',
      '&::before': {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        content: "' '",
        display: 'block',
        height: '9px',
        left: '-5px',
        position: 'absolute',
        top: 0,
        width: '9px'
      }
    },
    absenceTitle: {
      marginTop: theme.spacing(2.65)
    },
    headerContainer: {
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '30px'
    },
    divider: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2)
    },
    addAbsenceBtn: {
      '&:focus': {
        background: 'none!important'
      },
      'marginTop': theme.spacing(2),
      'background': 'none!important'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles>, WrappedFieldArrayProps<any> {}

export class AbsenceFields extends React.Component<Props> {
  addAbsence = (absence: Absence) => {
    this.props.fields.push(absence);
  };

  confirmDelete = (index: number) => {
    AlertApi.showConfirm({
      title: 'Supprimer une absence',
      content: 'Voulez-vous vraiment supprimer cette absence?',
      onConfirm: () => this.deleteAbsence(index),
      onCancel: () => {}
    });
  };

  deleteAbsence = (index: number) => {
    this.props.fields.remove(index);
  };

  render() {
    const { classes, fields } = this.props;

    return (
      <Grid item xs={12}>
        <div className={classes.headerContainer}>
          <Typography gutterBottom variant="h6" className={classes.absenceTitle}>
            Absences
          </Typography>
          <DialogTrigger
            DialogComponent={AbsenceDialog}
            dialogProps={{
              onSubmit: this.addAbsence
            }}
          >
            <Button className={classes.addAbsenceBtn}>
              <AddCircleIcon color="primary" />
            </Button>
          </DialogTrigger>
        </div>

        <ul className={classes.ul}>
          {fields.map((fieldName, fieldIndex) => (
            <li data-testid="absence" className={classes.li} key={fieldName}>
              <FormElement
                data-testid="formElement"
                name={fieldName}
                component={AbsenceField}
                onDelete={() => this.confirmDelete(fieldIndex)}
              />
            </li>
          ))}
        </ul>
      </Grid>
    );
  }
}

export default withStyles(styles)(AbsenceFields);
