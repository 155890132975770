import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';

export const styles = (theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary[500]
  },
  tableHeadCell: {
    color: theme.palette.common.white
  }
});

export function ReportSummary({ total }) {
  return (
    <Typography variant="subtitle1" align="right">
      Total pour la periode:
      {` ${currencyFormat(total)}`}
    </Typography>
  );
}

ReportSummary.propTypes = {
  total: PropTypes.string.isRequired
};

export default withStyles(styles)(ReportSummary);
