import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import React from 'react';
import getLumpSumCodesAccordingToUserSpecialty from '../../../../../../../../utils/dataFetchers';
import CategoriesList from '../../../../../../../components/List/CategoriesList/CategoriesList';
import { ACTIVITIES_MIXTE_TYPE } from '../../../../../../../../shared/collection/collectionConstants';

function MixteCodeList({ onItemSelected, onClose }) {
  return (
    <List dense>
      <CategoriesList
        list={getLumpSumCodesAccordingToUserSpecialty(ACTIVITIES_MIXTE_TYPE)}
        onItemSelected={onItemSelected}
        onClose={onClose}
      />
    </List>
  );
}

MixteCodeList.propTypes = {
  onClose: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired
};

export default MixteCodeList;
