import './index.css';

import './server/configureFirebase';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase/compat/app';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApolloProvider } from '@apollo/client';
import apolloClient from 'utils/apolloClient';
import { HashRouter } from 'react-router-dom';
import { USERS_COLLECTION } from 'shared/collection/collectionConstants';
import { getRamqPlaces } from './app/containers/RamqPlaces/actions';
import { LOGGER } from './shared/utils/plog';
import { appPaused, appResumed, appStarted } from './app/containers/App/actions';
import App from './app/containers/App/App';
import { initializeAssistant } from './app/containers/Assistant/actions';
import { loggedInAction, loggedOutAction } from './app/containers/Authentication/actions';
import { getCodesPreferences } from './app/containers/CodeSearchDialog/actions';
import { getDoctorPreferences, getResidentsPreferences } from './app/doctorsRegistry/adapters/actions';
import { getPlacesPreferences } from './app/containers/PlacesPreferencesDialog/actions';
import LanguageProvider from './app/containers/Translation/LanguageProvider/LanguageProvider';
import { getUserProfile, setUserInContext, getLumpSumsPreferences } from './app/containers/User/actions';
import { translationMessages } from './app/intl/i18n';
import configureStore from './app/reduxStore/configureStore';
import { firestore, initializeFirebase } from './server/Firebase';
import { setMomentInEastern } from './shared/utils/dateTime/dateTimeUtils';
import theme from './utils/muiTheme';
import { getDiagnosticCodesPreferences } from './app/favorite/diagnosticCode/adapters/actions';
import AuthenticationServiceProvider from './app/infrastructure/authentication/service/AuthenticationServiceProvider';
import {
  getContextElementsPreferences,
  getSpecialtyContextElements
} from './app/favorite/contextElement/adapters/actions';
import reactQueryConfig from './react-query.config';
import { setup as setupSentry } from './utils/sentry';
import { setup as setupIonicChannel } from './utils/ionic';
import ToastProvider from './application/providers/toast.provider';
import { PostHogProvider } from 'posthog-js/react';

const watchNativeMobileResumeEvent = (store) => {
  document.addEventListener('resume', () => store.dispatch(appResumed()));
};

const watchNativeMobilePauseEvent = (store) => {
  document.addEventListener('pause', () => store.dispatch(appPaused()));
};

const updateDeviceInfos = async (user) => {
  try {
    const documentReference = firestore.collection(USERS_COLLECTION).doc(user.uid);
    const document = await documentReference.get();
    const { deviceInfos } = document.data();

    const timestamp = new Date().valueOf();
    const newDeviceInfos = {
      ...deviceInfos,
      updatedAt: timestamp
    };
    if (window.cordova) {
      // eslint-disable-next-line no-undef
      const { platform, model } = device;
      newDeviceInfos[platform] = {
        model,
        updatedAt: timestamp
      };
    } else {
      newDeviceInfos.web = {
        model: navigator.userAgent,
        updatedAt: timestamp
      };
    }

    documentReference.update({ deviceInfos: newDeviceInfos });
  } catch (_e) {
    // Do nothing
  }
};

const startApp = () => {
  if (process.env.REACT_APP_LOGGING_ENABLED === 'true') {
    firebase.firestore.setLogLevel('debug');
    LOGGER.enableLogging();
  }

  setMomentInEastern();
  const store = configureStore({});
  initializeFirebase(store).then(() => {
    const logUser = (user) => {
      store.dispatch(setUserInContext(user.uid));
      store.dispatch(getUserProfile(user.uid));
      store.dispatch(loggedInAction(user));
      store.dispatch(getRamqPlaces());
      store.dispatch(getPlacesPreferences());
      store.dispatch(getDoctorPreferences());
      store.dispatch(getResidentsPreferences());
      store.dispatch(getCodesPreferences());
      store.dispatch(getDiagnosticCodesPreferences());
      store.dispatch(getContextElementsPreferences());
      store.dispatch(getLumpSumsPreferences());
      store.dispatch(getSpecialtyContextElements());
      store.dispatch(initializeAssistant());
      store.dispatch(appStarted());

      updateDeviceInfos(user);
    };

    AuthenticationServiceProvider.getInstance().onAuthStateChanged((user) => {
      if (user) {
        logUser(user);
      } else {
        store.dispatch(loggedOutAction());
      }
    });
  });

  watchNativeMobileResumeEvent(store);
  watchNativeMobilePauseEvent(store);

  setupSentry();
  setupIonicChannel();

  const queryClient = new QueryClient(reactQueryConfig);

  const root = createRoot(document.getElementById('root'));

  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
          <LanguageProvider messages={translationMessages}>
            <HashRouter>
              <MuiThemeProvider theme={theme}>
                <ToastProvider>
                  <PostHogProvider
                    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
                    options={{ persistence: 'localStorage', autocapture: false }}
                  >
                    <App />
                  </PostHogProvider>
                </ToastProvider>
              </MuiThemeProvider>
            </HashRouter>
          </LanguageProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </Provider>
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp);
} else {
  startApp();
}
