import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../FormRow/FormRow';
import { getFeatureConfig } from '../../../../../userCustomization/featureToggle';
import { SHOW_DIAGNOSTIC_IN_ACT_FORM } from '../../../../../userCustomization/featureNames';
import OutsideRamqFields from '../../../../validations/act/outsideRamq/OutsideRamqFields';
import FormElement from '../../FormElement/FormElement';
import IntensiveCareDiagnosticWithPatientContextField from './IntensiveCareDiagnosticWithPatientContextField';
import { actFormFields } from '../../../../containers/ActForm/constants';
import SpecialtyName from '../../../../../shared/core/doctor/domain/SpecialtyName';
import { SingleLineField } from '../../Fields';

interface Props {
  patientInformation: object;
  userProfile: { specialty: SpecialtyName };
}

const DiagnosticField: React.FunctionComponent<Props> = ({ patientInformation, userProfile }) => {
  const showFreeTextDiagnostic = OutsideRamqFields.isFreeTextDiagnosticFieldPrompted(patientInformation);
  const freeTextDiagnosticRequired = OutsideRamqFields.isFreeTextDiagnosticFieldRequired(userProfile);
  const showDiagnosticField = getFeatureConfig(SHOW_DIAGNOSTIC_IN_ACT_FORM, false);

  return (
    <>
      {showDiagnosticField && !showFreeTextDiagnostic && (
        <FormRow>
          <FormElement
            label={<FormattedMessage id="act.patientDiagnostic" />}
            component={IntensiveCareDiagnosticWithPatientContextField}
            name={actFormFields.patientDiagnostic}
          />
        </FormRow>
      )}

      {showFreeTextDiagnostic && (
        <FormRow>
          <FormElement
            required={freeTextDiagnosticRequired}
            label={<FormattedMessage id="act.patientDiagnostic" />}
            component={SingleLineField}
            name={actFormFields.freeTextPatientDiagnostic}
          />
        </FormRow>
      )}
    </>
  );
};

export default DiagnosticField;
