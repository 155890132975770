import moment from 'moment-timezone';
import find from 'lodash/find';
import clone from 'lodash/clone';

const createTrainingPeriodDays = (trainingPeriod) => {
  const days = [];
  const startDateMoment = moment(trainingPeriod.startDate);
  const endDateMoment = moment(trainingPeriod.endDate);

  const dayIterator = moment(startDateMoment);

  while (!dayIterator.isAfter(endDateMoment)) {
    let relatedDay = find(trainingPeriod.days, (day) => day.date === dayIterator.valueOf());

    if (relatedDay === undefined) {
      relatedDay = { date: dayIterator.valueOf(), perdiems: [] };
    }

    days.push(relatedDay);

    dayIterator.add(1, 'day');
  }

  const newTrainingPeriod = clone(trainingPeriod);
  newTrainingPeriod.days = days;

  return newTrainingPeriod;
};

export default createTrainingPeriodDays;
