import classNames from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Container = ({ children, className }: Props) => (
  <div
    className={classNames(
      'flex w-full flex-col gap-3 border-b border-neutral-200 p-4 text-sm text-neutral-900',
      className
    )}
  >
    {children}
  </div>
);

export default Container;
