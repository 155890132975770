import { get } from 'lodash';
import actCodesData from '../../../../shared/data/specialist-act-codes.json';
import { getAssociatedFields } from '../../requiredDetails/RamqContextElements';
import { NatureType } from '../../../../shared/domain/activity/act/model/Act';
import { selectSpecialty } from '../../../containers/User/selectors';
import { getStore } from '../../../reduxStore/configureStore';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR_FOR_SURGERY_CODES } from '../../../../userCustomization/featureNames';

export default ({ code }) => {
  const actCodeObject = getActCodeObject(code);

  if (!actCodeObject) {
    return [];
  }

  const canAddContextElements = isSurgeonCode(actCodeObject) && isBehaviorEnabled();

  return canAddContextElements ? [getAssociatedFields()] : [];
};

const isBehaviorEnabled = () => getFeatureConfig(CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR_FOR_SURGERY_CODES, false);

export const isUserSpecialtyGeneralSurgeon = () => {
  const userSpecialty = selectSpecialty()(getStore().getState());
  return userSpecialty === SpecialtyName.GENERAL_SURGEON;
};

export const isSurgeonCode = (codeObject) => {
  const natureType = get(codeObject, 'natureType', {});
  return natureType === NatureType.SURGERY;
};

export const getActCodeObject = (code) =>
  code && actCodesData.find((actCodeData) => actCodeData.code === code.toString());
