import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import AuthUserDomain from '../../../shared/domain/authentication/AuthUser';
import Permission from '../../../shared/domain/authorization/model/Permission';

export default function isReadOnly(status: ActivityStatus, user: AuthUserDomain) {
  return (
    !user.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS) &&
    (status === ActivityStatus.PROCESSING || status === ActivityStatus.PAID)
  );
}
