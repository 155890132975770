import { CONTEXT_ELEMENT_LIST_NAME } from '../../../../userCustomization/featureNames';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import {
  COEUR_POUMON,
  GREATER_THAN_4_HOURS,
  INTERRUPT,
  NONURGENT,
  ROLE3,
  ROLE4,
  SDIFF,
  URGENT,
  ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS,
  ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS
} from '../../../../shared/ramq/contextElements/globalContextElements';
import {
  SNT_NON_AMBULATOIRE_COVID,
  SNT_AMBULATOIRE_COVID,
  MD_REAFFECTE_COVID_ETABLISS,
  ENCEINTE_COVID,
  TELEMEDECINE_CABINET_COVID,
  TELEMEDECINE_AILLEURS_COVID,
  TELEMEDECINE_PAR_TELEPHONE as LEGACY_TELEMEDECINE_PAR_TELEPHONE,
  TELEMEDECINE_PAR_VISIOCONSULTATION as LEGACY_TELEMEDECINE_PAR_VISIOCONSULTATION,
  MD_INFECTE_COVID,
  ISOLEMENT_COVID,
  MD_REAFFECTE_AILLEURS_COVID,
  SERVICE_PATIENT_COVID as LEGACY_SERVICE_PATIENT_COVID
} from '../../../../shared/ramq/contextElements/legacyCovidContextElements';
import {
  MD_ENCEINTE,
  MD_INFECTE,
  MD_QUARANTAINE,
  MD_REAFFECTE,
  TELEMEDECINE_PAR_TELEPHONE,
  TELEMEDECINE_PAR_VISIOCONSULTATION,
  MD_REAFFECTE_AUTRE_LIEU,
  SERVICE_PATIENT_COVID,
  SNT_AMBULATOIRE_CLINIQUE_DESIGNE_EVALUATION,
  SNT_NON_AMBULATOIRE,
  WEBINAIRE_DIRECT,
  WEBINAIRE_DIFFERE,
  SERVICE_DISCONTINU_TELEMEDECINE,
  ACTIVITES_ANNULEES,
  AUCUN_PER_DIEM,
  DEMI_PER_DIEM,
  PER_DIEM
} from '../../../../shared/ramq/contextElements/lettreEntente241ContextElements';

export const getMainContextElements = () =>
  contextElementLists[getFeatureConfig(CONTEXT_ELEMENT_LIST_NAME, 'anesthesiologist')];

export const contextElementLists = {
  anesthesiologist: [{ key: ROLE3 }, { key: URGENT }, { key: INTERRUPT }, { key: SDIFF }],
  internist: [{ key: SDIFF }, { key: URGENT }, { key: NONURGENT }],
  generalSurgeon: [{ key: ROLE4 }, { key: URGENT }, { key: SDIFF }, { key: NONURGENT }],
  onlySDIFF: [{ key: SDIFF }]
};

export const additionalContextElements = [
  { key: COEUR_POUMON },
  {
    key: NONURGENT
  },
  { key: GREATER_THAN_4_HOURS },
  { key: ANESTHETIC_COMPLEXITY_GREATER_THAN_4_HOURS },
  { key: ANESTHETIC_COMPLEXITY_GREATER_THAN_8_HOURS }
];

export const additionalContextElementsForDoctorOnly = additionalContextElements.filter(({ adminOnly }) => !adminOnly);

export const legacyCovidContextElements = [
  { key: SNT_NON_AMBULATOIRE_COVID },
  { key: SNT_AMBULATOIRE_COVID },
  { key: MD_REAFFECTE_COVID_ETABLISS },
  { key: ENCEINTE_COVID },
  { key: TELEMEDECINE_CABINET_COVID },
  { key: TELEMEDECINE_AILLEURS_COVID },
  { key: LEGACY_TELEMEDECINE_PAR_TELEPHONE },
  { key: LEGACY_TELEMEDECINE_PAR_VISIOCONSULTATION },
  { key: MD_INFECTE_COVID },
  { key: ISOLEMENT_COVID },
  { key: MD_REAFFECTE_AILLEURS_COVID },
  { key: LEGACY_SERVICE_PATIENT_COVID }
];

export const lettreEntente241ContextElements = [
  { key: MD_ENCEINTE },
  { key: MD_INFECTE },
  { key: MD_QUARANTAINE },
  { key: MD_REAFFECTE },
  { key: MD_REAFFECTE_AUTRE_LIEU },
  { key: ACTIVITES_ANNULEES },
  { key: TELEMEDECINE_PAR_TELEPHONE },
  { key: TELEMEDECINE_PAR_VISIOCONSULTATION },
  { key: SERVICE_DISCONTINU_TELEMEDECINE },
  { key: SERVICE_PATIENT_COVID },
  { key: WEBINAIRE_DIRECT },
  { key: WEBINAIRE_DIFFERE },
  { key: SNT_AMBULATOIRE_CLINIQUE_DESIGNE_EVALUATION },
  { key: SNT_NON_AMBULATOIRE },
  { key: AUCUN_PER_DIEM },
  { key: DEMI_PER_DIEM },
  { key: PER_DIEM }
];
