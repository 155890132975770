import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'application/components/icon';

interface Props {
  title: string;
  badge?: string | number;
  backButton?: {
    label: string;
    route: string;
  };
}

const PageHeader = ({ title, badge, backButton }: Props) => (
  <div className="mb-6">
    {backButton && (
      <Link to={backButton.route} className="btn btn-link btn-sm mb-6 px-0">
        <Icon icon="arrow-left mr-2" />
        {backButton.label}
      </Link>
    )}

    <h1 className="text-left font-bold uppercase">
      {title}
      {badge && <div className="badge badge-ghost ml-2">{badge}</div>}
    </h1>
  </div>
);

export default PageHeader;
