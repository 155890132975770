import { call, put, takeLatest } from 'redux-saga/effects';
import getActivityById from '../../firebase/collection/repository';
import { GET_ACTIVITY_BY_ID, getActivityByIdError, getActivityByIdSuccess } from './actions';

export function* getSpecificActivity({ activityId }) {
  try {
    const activity = yield call(getActivityById, activityId);
    yield put(getActivityByIdSuccess(activity));
  } catch (e) {
    yield put(getActivityByIdError(e));
  }
}

export default function* searchActivitiesSaga() {
  yield takeLatest(GET_ACTIVITY_BY_ID, getSpecificActivity);
}
