import actions from 'redux-form/lib/actions';
import { PERIOD_ATTACHMENTS_LIST_FIELD, PERIOD_FORM_NAME } from './constants';

export const PERIOD_FILE_READY_FOR_UPLOAD = 'periodFileReadyForUpload';
export const periodFileReadyForUpload = (documentId, attachmentType, fileEntry, persistFileLocallyBeforeUpload) => ({
  type: PERIOD_FILE_READY_FOR_UPLOAD,
  documentId,
  attachmentType,
  fileEntry,
  persistFileLocallyBeforeUpload
});

export const updateSavedAttachmentInPeriodForm = (attachments) =>
  actions.change(PERIOD_FORM_NAME, PERIOD_ATTACHMENTS_LIST_FIELD, attachments);
