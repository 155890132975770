import Icon from 'application/components/icon';
import classNames from 'classnames';
import React from 'react';

type Type = 'warning' | 'error' | 'success' | 'info';

interface Props {
  title?: string;
  message: string;
  type: Type;
  className?: string;
}

const resolveIcon = (type: Type) => {
  switch (type) {
    case 'warning':
      return 'triangle-exclamation';
    case 'error':
      return 'hexagon-exclamation';
    case 'success':
      return 'circle-check';
    case 'info':
      return 'circle-info';
    default:
      throw new Error(`Unknown alert type: ${type}`);
  }
};

const Alert = ({ title, message, type, className }: Props) => (
  <div
    className={classNames('flex gap-4 rounded-lg p-4', className, {
      'bg-yellow-50': type === 'warning',
      'bg-red-100': type === 'error',
      'bg-green-50': type === 'success',
      'bg-blue-100': type === 'info'
    })}
  >
    <Icon
      icon={resolveIcon(type)}
      className={classNames('text-3xl', {
        'text-yellow-500': type === 'warning',
        'text-red-500': type === 'error',
        'text-green-500': type === 'success',
        'text-blue-500': type === 'info'
      })}
    />

    <div className="flex flex-col gap-2">
      {title && (
        <div
          className={classNames('text-sm font-bold', {
            'text-yellow-900': type === 'warning',
            'text-red-900': type === 'error',
            'text-green-900': type === 'success',
            'text-blue-800': type === 'info'
          })}
        >
          {title}
        </div>
      )}

      <div
        className={classNames('text-sm font-normal leading-normal', {
          'text-yellow-800': type === 'warning',
          'text-red-800': type === 'error',
          'text-green-800': type === 'success',
          'text-blue-800': type === 'info'
        })}
      >
        {message}
      </div>
    </div>
  </div>
);

export default Alert;
