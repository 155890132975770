import { ACT_CODES_COLLECTION, SPECIALTIES_COLLECTION } from '../../collection/collectionConstants';

export default class SpecialtyActCodeListRepository {
  constructor(database) {
    this.database = database;
  }

  async fetchSpecialtyList(specialty) {
    const snapshot = await this.database
      .collection(SPECIALTIES_COLLECTION)
      .doc(specialty)
      .collection(ACT_CODES_COLLECTION)
      .get();

    const specialtyListMapObject = {};

    snapshot.docs.forEach((documentSnapshot) => {
      specialtyListMapObject[documentSnapshot.id] = documentSnapshot.data();
    });

    return specialtyListMapObject;
  }
}
