import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  infoContainer: {
    display: 'flex',
    flex: 1,
    minWidth: theme.spacing(17),
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  upperCased: {
    textTransform: 'uppercase'
  }
});

function PerdiemsCodeInfo({ activity, classes }) {
  return (
    <div className={classes.infoContainer}>
      {activity.code && (
        <Typography key={activity.code} variant="caption" className={classes.upperCased}>
          {activity.code}
        </Typography>
      )}
      {activity.perdiems &&
        activity.perdiems.map((perdiem) => (
          <Typography key={perdiem} variant="caption" className={classes.upperCased}>
            {perdiem}
          </Typography>
        ))}
    </div>
  );
}

PerdiemsCodeInfo.propTypes = {
  activity: PropTypes.object.isRequired
};

export default withStyles(styles)(PerdiemsCodeInfo);
