import { RAMQDiagnostics } from 'shared/ramq/RamqDiagnostics';
import { get } from 'lodash';
import CODE_NATURE_TYPES from '../../../../../../shared/ramq/domainValues/natureTypes';

const V999 = RAMQDiagnostics.get('V999');
const excludedCodesFromDiagnostic = ['6946'];

const formatDiagnostic = (diagnostic) => {
  if (Array.isArray(diagnostic)) {
    return diagnostic[0];
  }

  return diagnostic;
};

export const updateInputValue = (patientDiagnostic, actCodes, isInputAlreadyFilled, handleOnChange, inputValue) => {
  if (!isInputAlreadyFilled || isDefaultDiagnostic(inputValue)) {
    if (patientDiagnostic) {
      handleOnChange(formatDiagnostic(patientDiagnostic));
    } else if (!isDefaultDiagnostic(inputValue)) {
      if (actCodes.length >= 1 && areAllCodesWithDiagnostic(actCodes) && areAllCodesAllowed(actCodes)) {
        handleOnChange(V999);
      }
    }
  }
  if (isInputAlreadyFilled) {
    if (isDefaultDiagnostic(inputValue) && (!areAllCodesWithDiagnostic(actCodes) || !areAllCodesAllowed(actCodes))) {
      handleOnChange(null);
    } else if (isDefaultDiagnostic(inputValue) && actCodes.length === 0) {
      handleOnChange(null);
    }
  }
};

const isDefaultDiagnostic = (inputValue) => get(inputValue, 'code') === V999?.code;

const areAllCodesWithDiagnostic = (actCodes) =>
  !actCodes.some(
    (code) => code.natureType && [CODE_NATURE_TYPES.administratif, CODE_NATURE_TYPES.forfait].includes(code.natureType)
  );

const areAllCodesAllowed = (actCodes) => !actCodes.some((code) => excludedCodesFromDiagnostic.includes(code.code));
