import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import Details from '../../../../components/Activities/Act/Details/Details';
import FormattedUnixHours from '../../../../components/Activities/FormattedUnixHours/FormattedUnixHours';
import MainInformationContainer from '../../../../components/Activities/MainInformationContainer/MainInformationContainer';
import RequiredDetailsSummary from './RequiredDetailsSummary';
import PatientIdentification from '../../../../components/Activities/Act/PatientIdentification/PatientIdentification';

export const styles = (theme) => ({
  actContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: theme.palette.common.white
  }
});

export function PrintActComponent({ act, classes }) {
  return (
    <ListItem className={classes.actContainer}>
      <MainInformationContainer noBoxShadow activity={act}>
        <FormattedUnixHours activity={act} />
        <PatientIdentification act={act} />
      </MainInformationContainer>

      <RequiredDetailsSummary act={act} />
      <Details open act={act} />
    </ListItem>
  );
}

PrintActComponent.propTypes = {
  act: PropTypes.object.isRequired
};

export default withStyles(styles)(PrintActComponent);
