import queryString from 'qs';

export type emailAddress = string;

export interface EmailDraftParameters {
  to: emailAddress[] | emailAddress;
  cc?: emailAddress[] | emailAddress;
  bcc?: emailAddress[] | emailAddress;
  subject: string;
  body: string;
}

class EmailService {
  static openEmailDraft(params: EmailDraftParameters) {
    const url = this.buildMailToUrl(params);

    window.open(url);
  }

  private static buildMailToUrl({ to, ...rest }: EmailDraftParameters): string {
    return `mailto:${to}?${queryString.stringify(rest, { indices: false })}`;
  }
}

export default EmailService;
