import momentTimezone, { Moment, Duration } from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { getBillingPeriodFromDate } from '../periods';
import {
  ACTIVITIES_ACT_TYPE,
  ACTIVITIES_LUMP_SUM_TYPE,
  ACTIVITIES_MIXTE_TYPE
} from '../../collection/collectionConstants';

const moment = extendMoment(momentTimezone as any);

/* eslint-disable no-shadow, no-unused-vars */
export enum Inclusivity {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE'
}

export default class Period {
  public static fromMoment(startDateTime: Moment, endDateTime: Moment): Period {
    return new Period(startDateTime.valueOf(), endDateTime.valueOf());
  }

  public static actBillingPeriodOfDate(date: Moment): Period {
    const billingPeriods = getBillingPeriodFromDate(date);
    const { startDate, endDate } = billingPeriods[ACTIVITIES_ACT_TYPE];

    return new Period(startDate.valueOf(), endDate.valueOf());
  }

  public static mixteBillingPeriodOfDate(date: Moment): Period {
    const billingPeriods = getBillingPeriodFromDate(date);
    const { startDate, endDate } = billingPeriods[ACTIVITIES_MIXTE_TYPE];

    return new Period(startDate.valueOf(), endDate.valueOf());
  }

  public static lumpSumBillingPeriodOfDate(date: Moment): Period {
    const billingPeriods = getBillingPeriodFromDate(date);
    const { startDate, endDate } = billingPeriods[ACTIVITIES_LUMP_SUM_TYPE];

    return new Period(startDate.valueOf(), endDate.valueOf());
  }

  static fromStatementDate(date: Moment) {
    const billingPeriods = getBillingPeriodFromDate(date.clone().subtract(2, 'weeks'));
    const { startDate, endDate } = billingPeriods[ACTIVITIES_ACT_TYPE];

    return new Period(startDate.valueOf(), endDate.valueOf());
  }

  static getPreviousPeriodFromPeriod(period: Period): Period {
    const nextPeriodEndDate = moment(period.getStart()).subtract('1', 'day');
    const nextPeriodStartDate = moment(nextPeriodEndDate).subtract('13', 'days');

    return new Period(nextPeriodStartDate.valueOf(), nextPeriodEndDate.valueOf());
  }

  static getNextPeriodFromPeriod(period: Period): Period {
    const nextPeriodStartDate = moment(period.getEnd()).add('1', 'day');
    const nextPeriodEndDate = moment(nextPeriodStartDate).add('13', 'days');

    return new Period(nextPeriodStartDate.valueOf(), nextPeriodEndDate.valueOf());
  }

  private constructor(
    private readonly startDateTime: number,
    private readonly endDateTime: number
  ) {}

  public overlaps(startDate: Moment, endDate: Moment): boolean {
    const periodRange = moment.range(this.getStart(), this.getEnd());
    const range = moment.range(startDate, endDate);

    return periodRange.overlaps(range);
  }

  public contains(otherDate: Moment, settings: Partial<{ end: Inclusivity }> = {}): boolean {
    const periodRange = moment.range(this.getStart(), this.getEnd());

    return periodRange.contains(otherDate, { excludeEnd: settings.end === Inclusivity.EXCLUSIVE });
  }

  public overlapWith(startDate: Moment, endDate: Moment): Period | undefined {
    const periodRange = moment.range(this.getStart(), this.getEnd());
    const range = moment.range(startDate, endDate);

    if (!periodRange.overlaps(range)) {
      return undefined;
    }

    const newStart = moment.max(startDate, this.getStart());
    const newEnd = moment.min(endDate, this.getEnd());
    return Period.fromMoment(newStart, newEnd);
  }

  public duration(): Duration {
    return moment.duration(this.getEnd().diff(this.getStart()));
  }

  public getStart(): Moment {
    return moment(this.startDateTime);
  }

  public getEnd(): Moment {
    return moment(this.endDateTime);
  }

  public getStatementDate(): Moment {
    const DAYS_BETWEEN_END_OF_PERIOD_AND_STATEMENT_DAY = 11;

    return this.getEnd().clone().add(DAYS_BETWEEN_END_OF_PERIOD_AND_STATEMENT_DAY, 'days');
  }

  public static isDayOfStatement(date?: Moment): boolean {
    const today = moment(date).startOf('day');
    const endOfPeriod = today.clone().add(3, 'days').endOf('day');
    const period = Period.actBillingPeriodOfDate(today);

    return endOfPeriod.isSame(period.getEnd(), 'day');
  }
}
