import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import React from 'react';
import filter from 'lodash/filter';
import { connect } from 'react-redux';
import { updateResults } from './actions';

export class SearchSuggestions extends React.Component {
  componentDidMount() {
    this.props.clearSearch();
  }

  render() {
    const {
      hits,
      filters,
      onSuggestionClick,
      isSelectedFunction,
      isInFavoritesFunction,
      isWithMarkupFunction,
      SuggestionItemComponent,
      getKeyFunction,
      className,
      ...otherProps
    } = this.props;

    return (
      <List dense className={className}>
        {(filters ? filter(hits, filters) : hits).map((suggestion) => (
          <SuggestionItemComponent
            key={getKeyFunction(suggestion)}
            suggestion={suggestion}
            isSelected={isSelectedFunction(suggestion)}
            isInFavorites={isInFavoritesFunction(suggestion)}
            withMarkup={isWithMarkupFunction(suggestion)}
            onSuggestionClick={onSuggestionClick}
            {...otherProps}
          />
        ))}
      </List>
    );
  }
}

SearchSuggestions.defaultProps = {
  className: '',
  filters: undefined,
  isSelectedFunction: () => false,
  isWithMarkupFunction: () => false,
  isInFavoritesFunction: () => false
};

SearchSuggestions.propTypes = {
  hits: PropTypes.array.isRequired,
  onSuggestionClick: PropTypes.func.isRequired,
  SuggestionItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  getKeyFunction: PropTypes.func.isRequired,
  filters: PropTypes.object,
  isSelectedFunction: PropTypes.func,
  isInFavoritesFunction: PropTypes.func,
  isWithMarkupFunction: PropTypes.func,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  clearSearch: PropTypes.func.isRequired,
  onFavoriteClick: PropTypes.func
};

export const mapDispatchToProps = {
  clearSearch: () => updateResults({ query: '' })
};

export default connect(null, mapDispatchToProps)(SearchSuggestions);
