export const GET_ACTIVITIES = 'getActivities';
export const getActivities = (filters, options = { makeGetRequest: false }) => ({
  type: GET_ACTIVITIES,
  filters,
  options
});

export const LOAD_MORE_ACTIVITIES = 'loadMoreActivities';
export const loadMoreActivities = (filters) => ({
  type: LOAD_MORE_ACTIVITIES,
  filters
});

export const GET_ACTIVITIES_SUCCESS = 'getActivitiesSuccess';
export const getActivitiesSuccess = (activities, nbHits = 0) => ({
  type: GET_ACTIVITIES_SUCCESS,
  activities,
  nbHits
});

export const GET_MORE_ACTIVITIES_SUCCES = 'getMoreActivitiesSucces';
export const getMoreActivitiesSuccess = (activities) => ({
  type: GET_MORE_ACTIVITIES_SUCCES,
  activities
});

export const MORE_ACTIVITIES = 'moreActivities';
export const getMoreActivities = () => ({
  type: MORE_ACTIVITIES
});

export const MORE_ACTIVITIES_SUCCESS = 'moreActivitiesSuccess';
export const moreActivitiesSuccess = (activities) => ({
  type: MORE_ACTIVITIES_SUCCESS,
  activities
});

export const GET_ACTIVITIES_ERROR = 'getActivitiesError';
export const getActivitiesError = (error) => ({
  type: GET_ACTIVITIES_ERROR,
  error
});

export const GET_MORE_ACTIVITIES_ERROR = 'getMoreActivitiesError';
export const getMoreActivitiesError = (error) => ({
  type: GET_MORE_ACTIVITIES_ERROR,
  error
});

export const TOGGLE_DETAILED_VIEW = 'toggleDetailedView';
export const toggleDetailedView = (toggleValue) => ({
  type: TOGGLE_DETAILED_VIEW,
  toggleValue
});

export const DUPLICATE_ACTIVITY = 'duplicateActivity';
export const duplicateActivity = (activity) => ({
  type: DUPLICATE_ACTIVITY,
  activity
});

export const SAVE_DUPLICATION_INFO = 'saveDuplicationInfo';
export const saveDuplicationInfo = (activityType, duplicationData) => ({
  type: SAVE_DUPLICATION_INFO,
  activityType,
  duplicationData
});
