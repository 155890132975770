import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { POSSIBLE_STATUS } from '../../../containers/ActForm/constants';
import messages from '../../../containers/Activities/messages';
import CommentEditorDialog from '../../../containers/UsersRoute/UserRoute/UserActivities/EditCommentDialog/CommentEditorDialog';
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog/ConfirmationDialog';
import ListSelector from '../../Form/ListSelector/ListSelector';
import { displayGlobalMessage } from '../../../containers/Act/actions';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import { canActivityStatusBeSwitchedToProcessing } from '../../../../shared/core/activity/shared/activityStatus.utils';
import createManualCorrection from '../../../user/admin/createManualCorrection';
import actSendValidations from '../../../validations/act/sendValidation/sendValidation';
import { isEmpty } from '../../../../shared/utils/objectManipulation';
import { selectUserProfileInContext } from '../../../containers/User/selectors';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';

export const makeI18nStatusItem = (status) => ({
  key: status,
  value: status,
  label: <FormattedMessage {...messages[status]} />
});

export const styles = (theme) => ({
  statusSelector: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    borderRadius: '4px',
    backgroundColor: theme.palette.red[300]
  }
});

export class AdminStatusSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commentDialogOpen: false,
      pendingStatusConfirmation: undefined
    };
  }

  updateEntityWithComment = (comment, isAcknowledgeable) => {
    const corrections = this.props.entity.corrections || [];
    corrections.push(createManualCorrection(comment, isAcknowledgeable));
    this.props.onChange({ status: ActivityStatus.NEED_FIX, corrections });
  };

  sendAct = () => {
    const { entity, userProfile } = this.props;
    if (!isEmpty(actSendValidations({ act: entity, user: userProfile }))) {
      this.props.triggerDisplayGlobalMessage("L'acte ne peut être envoyé car il n'est pas valide", 'warning');
    } else {
      this.props.onChange({ status: ActivityStatus.SENT });
    }
  };

  handleStatusSelected = (event) => {
    const newStatus = event.target.value;

    if (ActivityStatus.WAITING === this.props.entity?.status && ActivityStatus.PROCESSING === newStatus) {
      this.props.triggerDisplayGlobalMessage(
        'Vous ne pouvez transférer à En traitement car le statut actuel est En attente',
        'warning'
      );
    } else if (newStatus === ActivityStatus.PROCESSING && !canActivityStatusBeSwitchedToProcessing(this.props.entity)) {
      this.props.triggerDisplayGlobalMessage(
        'Vous ne pouvez transférer à en traitement car aucune facture RAMQ ou montant $ inscrit',
        'warning'
      );
    } else if (newStatus === ActivityStatus.NEED_FIX) {
      this.setState({ commentDialogOpen: true });
    } else if (newStatus === ActivityStatus.CANCELED || newStatus === ActivityStatus.PAID) {
      this.setState({ pendingStatusConfirmation: newStatus });
    } else if (newStatus === ActivityStatus.SENT && this.props.entity?.type === ActivityType.ACT) {
      this.sendAct();
    } else {
      this.props.onChange({ status: newStatus });
    }
  };

  render() {
    const { entity, intl, classes, onChange } = this.props;
    const { commentDialogOpen, pendingStatusConfirmation } = this.state;
    const haveToManualConfirmOnCancel =
      pendingStatusConfirmation === ActivityStatus.CANCELED &&
      !!this.props.entity.billNumberMap &&
      Object.keys(this.props.entity.billNumberMap).length > 0
        ? 'ANNULER'
        : undefined;

    return (
      <>
        <CommentEditorDialog
          act={entity}
          title="Demander une correction"
          open={commentDialogOpen}
          onClose={() => this.setState({ commentDialogOpen: false })}
          onItemSelected={this.updateEntityWithComment}
        />
        <ConfirmationDialog
          title="Changement de status"
          text={`Voulez vous vraiment changer le status à :
          ${intl.formatMessage(messages[pendingStatusConfirmation || ActivityStatus.NO_STATUS])} ?`}
          onClose={() => this.setState({ pendingStatusConfirmation: undefined })}
          open={!!pendingStatusConfirmation}
          manualConfirmWithString={haveToManualConfirmOnCancel}
          onItemSelected={() => onChange({ status: pendingStatusConfirmation })}
        />
        <ListSelector
          label="Status"
          className={classes.statusSelector}
          values={POSSIBLE_STATUS.map(makeI18nStatusItem)}
          input={{ value: entity.status, onChange: this.handleStatusSelected }}
        />
      </>
    );
  }
}

AdminStatusSelector.propTypes = {
  entity: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  triggerDisplayGlobalMessage: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfileInContext()
});

const mapDispatchToProps = (dispatch) => ({
  triggerDisplayGlobalMessage: bindActionCreators(displayGlobalMessage, dispatch)
});

export default compose(
  injectIntl,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AdminStatusSelector);
