import Act from 'shared/domain/activity/act/model/Act';
import { URGENT } from 'shared/ramq/contextElements/globalContextElements';

const NO_ERRORS = {};

export default ({ contextElements = [], urgentCallDetails }: Act) => {
  if (contextElements.length === 0 || !contextElements.includes(URGENT) || !urgentCallDetails) {
    return NO_ERRORS;
  }

  if (urgentCallDetails.guard === false && (!urgentCallDetails.time || !urgentCallDetails.date)) {
    return {
      contextElements: "Veuilllez préciser l'heure et la date de l'appel urgent."
    };
  }

  return NO_ERRORS;
};
