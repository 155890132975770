import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import XmlEditor from '../../../xml/XmlEditor/XmlEditor';
import DialogCenteredTitle from '../DialogCenteredTitle/DialogCenteredTitle';

export interface Props {
  xml: string;
  open: boolean;
  onClose: () => void;
  onSaveEditedXml: (editedXml: string) => void;
}

export const XmlEditorDialog: React.FunctionComponent<Props> = ({ xml, open, onSaveEditedXml, onClose }: Props) => (
  <Dialog open={open} onClose={onClose} fullScreen>
    <DialogCenteredTitle label="XML" />
    <DialogContent>
      <XmlEditor xml={xml} onSave={(editedXml: string) => onSaveEditedXml(editedXml)} onCancel={onClose} />
    </DialogContent>
    <DialogActions>
      <Button data-testid="close-button" onClick={onClose} color="primary">
        Fermer
      </Button>
    </DialogActions>
  </Dialog>
);

export default XmlEditorDialog;
