import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { compose } from 'redux';
import CustomCodeDescriptionDialog from '../../../../../../components/Dialogs/CustomCodeDescriptionDialog/CustomCodeDescriptionDialog';
import FavoriteItemWithActionMenu from '../../../../../../containers/PlacesPreferencesDialog/FavoriteItemWithActionMenu/FavoriteItemWithActionMenu';
import CollapsableCategory from '../../../../../../shared/category/ui/CollapsableCategory/CollapsableCategory';
import CategorizableCollection from '../../../../../../shared/editable/domain/CategorizableCollection';
import {
  transformToCategorizableCollection,
  transformToFirebaseDocumentsMap
} from '../../../../../../shared/editable/domain/CategorizableCollectionTransformer';
import CreateNewCategoryDialog from '../../../../../../shared/editable/ui/CreateNewCategoryDialog/CreateNewCategoryDialog';
import DeleteCategoryValidationDialog from '../../../../../../shared/editable/ui/DeleteCategoryValidationDialog/DeleteCategoryValidationDialog';
import EditableCollectionWithCategories from '../../../../../../shared/editable/ui/EditableCollectionWithCategories/EditableCollectionWithCategories';
import EditCategoryNameDialog from '../../../../../../shared/editable/ui/EditCategoryNameDialog/EditCategoryNameDialog';
import ItemAndCategoriesMapping from '../../../../../../shared/editable/domain/ItemAndCategoriesMapping';
import AlertApi from '../../../../../../shared/confirm/api/AlertApi';

export const styles = (theme) =>
  ({
    searchContainer: {
      'height': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    },
    addCategoryButtonContainer: {
      margin: `${theme.spacing(2)}px 0`,
      display: 'flex',
      justifyContent: 'center'
    },
    dialogActions: {
      justifyContent: 'space-between'
    },
    removeAllButton: {
      float: 'right'
    },
    controlSection: {
      padding: `${theme.spacing(1)}px 0`
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  intl: { messages: { [key: string]: string } };
  // eslint-disable-next-line no-unused-vars
  onUpdateFavoriteItems: (favoriteItems: any) => void;
  onClose: () => void;
  favorites: ItemAndCategoriesMapping;
  enableDeleteAll: boolean;
}

export interface State {
  itemDescriptionEditorOpen: boolean;
  deleteCategoryValidatorOpen: boolean;
  createCategoryDialogOpen: boolean;
  categoryNameEditorOpen: boolean;
  tempSelectedCode: {
    id: string | null;
    value: any | undefined;
  };
  tempSelectedCategory: {
    id: string | null;
    value: any | undefined;
  };
  favorites: any;
}

export class AdvancedEditor extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const categorizableCollection = transformToCategorizableCollection(props.favorites);

    this.state = {
      itemDescriptionEditorOpen: false,
      deleteCategoryValidatorOpen: false,
      createCategoryDialogOpen: false,
      categoryNameEditorOpen: false,
      tempSelectedCode: {
        id: null,
        value: undefined
      },
      tempSelectedCategory: {
        id: null,
        value: undefined
      },
      favorites: categorizableCollection
    };

    this.getItemComponentBuilder = this.getItemComponentBuilder.bind(this);
    this.getContainerWrapperComponentBuilder = this.getContainerWrapperComponentBuilder.bind(this);
    this.handleOnChangeOrder = this.handleOnChangeOrder.bind(this);
    this.handleOnRemoveItem = this.handleOnRemoveItem.bind(this);
    this.openCodeDescriptionEditor = this.openCodeDescriptionEditor.bind(this);
    this.closeCodeDescriptionEditor = this.closeCodeDescriptionEditor.bind(this);
    this.updateItemDescription = this.updateItemDescription.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.openDeleteCategoryValidator = this.openDeleteCategoryValidator.bind(this);
    this.closeDeleteCategoryValidator = this.closeDeleteCategoryValidator.bind(this);
    this.openNewCategoryDialog = this.openNewCategoryDialog.bind(this);
    this.closeNewCategoryDialog = this.closeNewCategoryDialog.bind(this);
    this.createCategory = this.createCategory.bind(this);
    this.openCategoryNameEditor = this.openCategoryNameEditor.bind(this);
    this.closeCategoryNameEditor = this.closeCategoryNameEditor.bind(this);
    this.editCategoryName = this.editCategoryName.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDeleteAll = this.handleDeleteAll.bind(this);
  }

  getItemComponentBuilder({ item, id }) {
    return (
      <FavoriteItemWithActionMenu
        chipNumber={item.code}
        onItemRemove={() => this.handleOnRemoveItem(id)}
        onItemEdit={() => this.openCodeDescriptionEditor(id)}
        primaryText={item.description}
      />
    );
  }

  getContainerWrapperComponentBuilder({ id, label, level, children }) {
    return (
      <CollapsableCategory
        label={label}
        level={level}
        initiallyOpen={false}
        onDelete={() => this.openDeleteCategoryValidator(id)}
        onEdit={() => this.openCategoryNameEditor(id, { category: label })}
      >
        {children}
      </CollapsableCategory>
    );
  }

  handleOnRemoveItem(id) {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favorites);

      return {
        ...state,
        favorites: categorizableCollection.removeItem(id)
      };
    });
  }

  openCodeDescriptionEditor(id) {
    this.setState((state) => ({
      itemDescriptionEditorOpen: true,
      tempSelectedCode: {
        id,
        value: state.favorites.items[id]
      }
    }));
  }

  closeCodeDescriptionEditor() {
    this.setState({
      itemDescriptionEditorOpen: false,
      tempSelectedCode: {
        id: null,
        value: undefined
      }
    });
  }

  updateItemDescription(description) {
    this.setState((state) => {
      if (!state.tempSelectedCode.id) return state;

      const updatedItem = {
        ...state.favorites.items[state.tempSelectedCode.id],
        description
      };

      return {
        ...state,
        favorites: new CategorizableCollection(state.favorites).updateItem(state.tempSelectedCode.id, updatedItem)
      };
    }, this.closeCodeDescriptionEditor);
  }

  handleOnChangeOrder(newCollectionWithCategory) {
    this.setState({ favorites: newCollectionWithCategory });
  }

  openDeleteCategoryValidator(id) {
    this.setState((state) => ({
      deleteCategoryValidatorOpen: true,
      tempSelectedCategory: {
        id,
        value: new CategorizableCollection(state.favorites).getFromPath(id)
      }
    }));
  }

  deleteCategory() {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favorites);

      return {
        ...state,
        favorites: categorizableCollection.removeCategory(state.tempSelectedCategory.id)
      };
    }, this.closeDeleteCategoryValidator);
  }

  closeDeleteCategoryValidator() {
    this.setState({
      deleteCategoryValidatorOpen: false,
      tempSelectedCategory: {
        id: null,
        value: undefined
      }
    });
  }

  openNewCategoryDialog() {
    this.setState({
      createCategoryDialogOpen: true
    });
  }

  closeNewCategoryDialog() {
    this.setState({
      createCategoryDialogOpen: false
    });
  }

  createCategory({ id, name, path }) {
    this.setState((state) => {
      const categorizableCollection = new CategorizableCollection(state.favorites);
      return {
        ...state,
        favorites: categorizableCollection.addCategory({ id, name }, path),
        createCategoryDialogOpen: false
      };
    });
  }

  openCategoryNameEditor(id, category) {
    this.setState({
      categoryNameEditorOpen: true,
      tempSelectedCategory: {
        id,
        value: category
      }
    });
  }

  closeCategoryNameEditor() {
    this.setState({
      categoryNameEditorOpen: false,
      tempSelectedCategory: {
        id: null,
        value: undefined
      }
    });
  }

  editCategoryName(newCategoryName) {
    this.setState((state) => {
      const { id } = state.tempSelectedCategory;

      const categorizableCollection = new CategorizableCollection(state.favorites);
      return {
        ...state,
        favorites: categorizableCollection.editCategoryName(id, newCategoryName)
      };
    }, this.closeCategoryNameEditor);
  }

  handleSave() {
    const firebaseDocumentMap = transformToFirebaseDocumentsMap(this.state.favorites);

    this.props.onUpdateFavoriteItems(firebaseDocumentMap);
    this.props.onClose();
  }

  handleDeleteAll() {
    AlertApi.showConfirm({
      title: this.props.intl.messages['editableCollectionWithCategories.removeCollection'],
      content: this.props.intl.messages['editableCollectionWithCategories.confirmRemoveAllContent'],
      onConfirm: () => {
        this.setState({
          favorites: {
            items: {},
            itemsWithoutCategory: { itemIds: [] },
            type: 'categories',
            categories: {},
            id: 'categories'
          }
        });
      }
    });
  }

  render() {
    const { classes, onClose, enableDeleteAll = false } = this.props;

    return (
      <>
        <DialogContent className={classes.searchContainer}>
          <div className={classes.controlSection}>
            {enableDeleteAll && !new CategorizableCollection(this.state.favorites).isEmpty() && (
              <Button
                data-testid="remove-collection-button"
                variant="contained"
                size="small"
                className={classes.removeAllButton}
                onClick={this.handleDeleteAll}
              >
                <DeleteIcon />
                <FormattedMessage id="editableCollectionWithCategories.removeCollection" />
              </Button>
            )}
          </div>
          <EditableCollectionWithCategories
            collectionWithCategories={this.state.favorites}
            itemComponent={this.getItemComponentBuilder}
            containerWrapperComponent={this.getContainerWrapperComponentBuilder}
            onChange={this.handleOnChangeOrder}
          />
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <div>
            <Button id="add-category" color="primary" onClick={this.openNewCategoryDialog}>
              <FormattedMessage id="editableCollectionWithCategories.addCategory" />
            </Button>
          </div>
          <div>
            <Button id="close-Dialog" color="primary" onClick={onClose}>
              <FormattedMessage id="global.cancel" />
            </Button>
            <Button id="accept-Dialog" color="primary" onClick={this.handleSave}>
              <FormattedMessage id="global.save" />
            </Button>
          </div>
        </DialogActions>

        <CustomCodeDescriptionDialog
          selectedCode={this.state.tempSelectedCode.value}
          mode="edit"
          key={this.state.tempSelectedCode.value}
          open={this.state.itemDescriptionEditorOpen}
          onCancel={this.closeCodeDescriptionEditor}
          onSave={this.updateItemDescription}
        />

        <DeleteCategoryValidationDialog
          category={this.state.tempSelectedCategory.value}
          open={this.state.deleteCategoryValidatorOpen}
          onCancel={this.closeDeleteCategoryValidator}
          onConfirm={this.deleteCategory}
        />

        <CreateNewCategoryDialog
          open={this.state.createCategoryDialogOpen}
          categoryPaths={new CategorizableCollection(this.state.favorites).getCategoryPathsFlatMap()}
          onCancel={this.closeNewCategoryDialog}
          onConfirm={this.createCategory}
        />

        <EditCategoryNameDialog
          key={this.state.tempSelectedCategory.value}
          open={this.state.categoryNameEditorOpen}
          category={this.state.tempSelectedCategory.value}
          onCancel={this.closeCategoryNameEditor}
          onSave={this.editCategoryName}
        />
      </>
    );
  }
}

export default compose(
  withStyles(styles),
  injectIntl
  // @ts-ignore
)(AdvancedEditor);
