import { withStyles, WithStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ActivityType from '../../../../../../../shared/core/activity/domain/ActivityType';
import { DETAILED_MIXTE_HOURS } from '../../../../../../../userCustomization/featureNames';
import { getFeatureConfig } from '../../../../../../../userCustomization/featureToggle';
import CustomTimePicker from '../../../../../../components/Form/custom/CustomTimePicker';
import FormElement from '../../../../../../components/Form/FormElement/FormElement';
import messages from '../../../../../ActForm/messages';
import PerdiemSelector from '../../shared/PerdiemSelector/PerdiemSelector';

const styles = () =>
  ({
    timeSelectionContainer: {
      flex: '0 1 auto',
      minWidth: 92,
      display: 'flex'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  activityType: ActivityType;
  startDateTime?: number;
  date: number;
  fieldName: string;
  onChange: () => void;
}

const DynamicPeriodTimeSelection = ({
  activityType,
  classes,
  date,
  fieldName,
  onChange,
  startDateTime: defaultStartDateTime
}: Props) => {
  const [startDateTime, setStartDateTime] = useState(defaultStartDateTime);

  const handleStartTimeChange = (newValue) => {
    setStartDateTime(newValue);
    onChange();
  };

  if (activityType === ActivityType.MIXTE && getFeatureConfig(DETAILED_MIXTE_HOURS, false)) {
    return <PerdiemSelector fieldName={fieldName} onChange={onChange} />;
  }

  return (
    <div className={classes.timeSelectionContainer}>
      <FormElement
        className="startTimePicker"
        name={`${fieldName}.start`}
        label={<FormattedMessage {...messages.start} />}
        component={CustomTimePicker}
        onChange={handleStartTimeChange}
        unixDateToSyncWith={date}
      />
      <FormElement
        className="endTimePicker"
        name={`${fieldName}.end`}
        label={<FormattedMessage {...messages.end} />}
        component={CustomTimePicker}
        onChange={onChange}
        unixDateToSyncWith={date}
        currentStartTime={startDateTime}
      />
    </div>
  );
};

export default withStyles(styles)(DynamicPeriodTimeSelection);
