import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import medyxLogo from '../../../../../../images/medyx-logo-purple.svg';

export const styles = (theme) => ({
  logoContainer: {
    alignItems: 'center',
    display: 'flex'
  },
  logo: {
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    maxWidth: '240px',
    minWidth: '160px',
    width: '100%'
  },
  headerContainer: {
    paddingTop: '16px',
    fontSize: '3.5rem'
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  }
});

export function StatementHeader({ title, subtitle, subtitle2, width, classes }) {
  return (
    <Grid container spacing={2} className={classes.headerContainer}>
      <Grid item xs={2} className={classes.logoContainer}>
        <img className={classes.logo} src={medyxLogo} alt="Medyx" />
      </Grid>
      <Grid className={classes.titleContainer} item xs={10} sm={10}>
        <Typography variant={isWidthUp('sm', width) ? 'h5' : 'body1'}>
          {title}
          <br />
          {subtitle}
          <br />
          {subtitle2}
        </Typography>
      </Grid>
    </Grid>
  );
}

StatementHeader.defaultProps = {
  subtitle: '',
  subtitle2: ''
};

StatementHeader.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string
};

export default compose(withWidth(), withStyles(styles))(StatementHeader);
