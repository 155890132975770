import { defineMessages } from 'react-intl';

export default defineMessages({
  patientEventDate: {
    id: 'act.patientEventDate',
    defaultMessage: 'Date événement'
  },
  patientEventType: {
    id: 'act.patientEventType',
    defaultMessage: 'Événement patient'
  }
});
