/* eslint-disable react/no-array-index-key */
import withStyles from '@material-ui/core/styles/withStyles';
import { last } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import IncomeStatementTable from '../../../../components/Table/Statement/VeryDenseStatementTable/IncomeStatementTable';
import StatementHeader from '../../Statement/StatementReport/sections/StatementHeader';
import {
  addUpBalanceChanges,
  addUpBalancePaidAmount,
  addUpCeilingCutLines,
  addUpContributionAmount,
  addUpDeductionAmount,
  addUpFederationAmount,
  addUpGrossAmount,
  addUpInsurancePaidAmount,
  addUpInterestOnAdvance,
  addUpInterestOnNegativeBalance,
  addUpInterestOnRecovery,
  addUpIssuedAdvances,
  addUpNetAmount,
  addUpPostponedBalance,
  addUpRecoveredAdvances,
  addUpSeizedAmount,
  addUpToBePostponedBalance,
  addUpTransferAmount,
  advances,
  balanceChangeAndPaid,
  balances,
  balanceTransferAndInsurance,
  cotisations,
  cutAndDeduction,
  dateAndPaymentNumber,
  formatCurrencyOrDash,
  grossAndNetAmount,
  interests,
  seizureAndNegativeInterest
} from './IncomeStatementReportUtils';

export const styles = () => ({
  printableHeader: {
    width: '100%',
    zoom: '80%'
  },
  pageContent: {
    width: '100%',
    zoom: '80%',
    pageBreakAfter: 'always'
  }
});

const addUpAmounts = (sum, current) => sum + (parseFloat(current) || 0);

export const computeTotalSum = (statements) => {
  // Used for all "balance type" amount
  const lastStatement = last(statements);

  const totalGrossAmount = formatCurrencyOrDash(statements.map(addUpGrossAmount).reduce(addUpAmounts, 0).toFixed(2));
  const totalNetAmount = formatCurrencyOrDash(statements.map(addUpNetAmount).reduce(addUpAmounts, 0).toFixed(2));

  const totalCeilingCut = formatCurrencyOrDash(statements.map(addUpCeilingCutLines).reduce(addUpAmounts, 0).toFixed(2));
  const totalDeductionAmount = formatCurrencyOrDash(
    statements.map(addUpDeductionAmount).reduce(addUpAmounts, 0).toFixed(2)
  );

  const totalFederationAmount = formatCurrencyOrDash(
    statements.map(addUpFederationAmount).reduce(addUpAmounts, 0).toFixed(2)
  );
  const totalContributionAmount = formatCurrencyOrDash(addUpContributionAmount(lastStatement));

  const totalSeizedAmount = formatCurrencyOrDash(statements.map(addUpSeizedAmount).reduce(addUpAmounts, 0).toFixed(2));
  const totalInterestOnNegativeBalance = formatCurrencyOrDash(
    statements.map(addUpInterestOnNegativeBalance).reduce(addUpAmounts, 0).toFixed(2)
  );

  const totalTransferAmount = formatCurrencyOrDash(
    statements.map(addUpTransferAmount).reduce(addUpAmounts, 0).toFixed(2)
  );
  const totalInsuranceAmount = formatCurrencyOrDash(
    statements.map(addUpInsurancePaidAmount).reduce(addUpAmounts, 0).toFixed(2)
  );

  const totalInterestOnRecovery = formatCurrencyOrDash(
    statements.map(addUpInterestOnRecovery).reduce(addUpAmounts, 0).toFixed(2)
  );
  const totalInterestOnAdvance = formatCurrencyOrDash(
    statements.map(addUpInterestOnAdvance).reduce(addUpAmounts, 0).toFixed(2)
  );

  const totalPostponedBalance = formatCurrencyOrDash(addUpPostponedBalance(lastStatement));
  const totalToBePostponedBalance = formatCurrencyOrDash(addUpToBePostponedBalance(lastStatement));

  const totalRecoveredAdvances = formatCurrencyOrDash(
    statements.map(addUpRecoveredAdvances).reduce(addUpAmounts, 0).toFixed(2)
  );
  const totalIssuedAdvances = formatCurrencyOrDash(
    statements.map(addUpIssuedAdvances).reduce(addUpAmounts, 0).toFixed(2)
  );

  const totalBalanceChanges = formatCurrencyOrDash(
    statements.map(addUpBalanceChanges).reduce(addUpAmounts, 0).toFixed(2)
  );
  const totalBalancePaidAmount = formatCurrencyOrDash(
    statements.map(addUpBalancePaidAmount).reduce(addUpAmounts, 0).toFixed(2)
  );

  return {
    totalGrossAmount,
    totalNetAmount,
    totalCeilingCut,
    totalDeductionAmount,
    totalFederationAmount,
    totalContributionAmount,
    totalSeizedAmount,
    totalInterestOnNegativeBalance,
    totalTransferAmount,
    totalInsuranceAmount,
    totalInterestOnRecovery,
    totalInterestOnAdvance,
    totalPostponedBalance,
    totalToBePostponedBalance,
    totalRecoveredAdvances,
    totalIssuedAdvances,
    totalBalanceChanges,
    totalBalancePaidAmount
  };
};

const prepareSections = (groupedSections, fullName, classes) => {
  const sections = [];
  groupedSections.forEach((currentGroup, currentGroupNumber) => {
    sections.push(
      <div key={currentGroupNumber} className={classes.pageContent}>
        {parseInt(currentGroupNumber, 0) === 0 ? (
          <StatementHeader
            title="État des revenus"
            subtitle="Personnel"
            subtitle2={`${fullName} : ${currentGroup[0].generalInformation.professionalIdInfo.practiceNumber}`}
          />
        ) : (
          <StatementHeader
            title="ÉTAT DES REVENUS"
            subtitle={`Groupe : ${currentGroup[0].generalInformation.professionalIdInfo.groupNumber}`}
            subtitle2={`${fullName} : ${currentGroup[0].generalInformation.professionalIdInfo.practiceNumber}`}
          />
        )}
        <IncomeStatementTable
          columns={[
            dateAndPaymentNumber,
            cutAndDeduction,
            grossAndNetAmount,
            cotisations,
            seizureAndNegativeInterest,
            balanceTransferAndInsurance,
            interests,
            balances,
            advances,
            balanceChangeAndPaid
          ]}
          data={currentGroup}
          total={computeTotalSum(currentGroup)}
        />
      </div>
    );
  });
  return sections;
};

export function IncomeStatementReport({ statements, classes }) {
  const { groupedSections, fullName } = statements;
  return <div>{prepareSections(groupedSections, fullName, classes).map((section) => section)}</div>;
}

IncomeStatementReport.propTypes = {
  statements: PropTypes.object.isRequired
};

export default compose(withStyles(styles))(IncomeStatementReport);
