import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMomentDateFromUnix } from 'shared/utils/dateTime/dateTimeUtils';
import { getActivityPlacePreferenceFromRoute } from 'app/containers/User/preferencesUtils';
import { selectPlacesPreferenceFromUser } from 'app/containers/User/selectors';
import { selectActivitiesInContext, selectActivitiesLoading } from 'app/containers/Activities/selectors';
import { selectActivities } from 'app/containers/Assistant/selectors';
import AdminStatus from 'shared/core/activity/domain/AdminStatus';
import { activitiesCollectionRef } from 'app/firebase/collection/collectionReferences';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import { ACTIVITIES_TRAINING_TYPE } from 'shared/collection/collectionConstants';
import { ASSISTANT_ROUTE } from 'app/containers/Navigation/constants';
import LoadPreferences from '../../../containers/LoadPreferences/LoadPreferences';
import BackButton from '../../../components/button/NavigationHeaderButton/NavigationHeaderButton';
import EmptyPlacePreferences from '../../../components/Activities/EmptyPlacePreferences/EmptyPlacePreferences';
import TrainingForm from '../TrainingForm/TrainingForm';
import { extractPDF } from '../../../containers/PDF/actions';
import { saveTraining } from '../../adapters/store/actions';
import displayAttachmentsOnWeb from '../../../containers/Attachments/displayAttachments';
import createAllTrainingPeriodDays from '../../usecases/createTrainingPeriodDays';

const Trainings = ({ formClasses, saveTrainingDocument, extractPDF, activities, advices, placesPreferences }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { activityId, date, placeNumber } = useParams();

  const doesTrainingExist = () => activityId !== 'new';

  const generateTrainingPeriod = () => {
    const currentPeriodStartDate = getMomentDateFromUnix(date);
    const currentPeriodEndDate = currentPeriodStartDate;
    const place = getActivityPlacePreferenceFromRoute(placesPreferences, placeNumber);
    const days = [{ date: currentPeriodStartDate.valueOf(), perdiems: [] }];
    const status = ActivityStatus.NO_STATUS;
    const adminStatus = AdminStatus.NORMAL;
    return {
      id: activitiesCollectionRef().doc().id,
      place,
      placeNumber: place.number,
      date: currentPeriodStartDate.valueOf(),
      startDate: currentPeriodStartDate.valueOf(),
      endDate: currentPeriodEndDate.valueOf(),
      days,
      status,
      adminStatus,
      type: ACTIVITIES_TRAINING_TYPE,
      onlineCreation: navigator.onLine
    };
  };

  const fetchExistingTrainingPeriod = () => {
    const trainingCollection = location.pathname.startsWith(`/${ASSISTANT_ROUTE}`) ? advices : activities;

    const trainingPeriod = trainingCollection.find((activity) => activity.id === activityId);

    return createAllTrainingPeriodDays(trainingPeriod);
  };

  const trainingPeriodFunction = () => (doesTrainingExist() ? fetchExistingTrainingPeriod() : generateTrainingPeriod());

  const trainingPeriod = trainingPeriodFunction();

  const saveTraining = (periodForm, status = undefined) => {
    const documentToSave = {
      ...periodForm,
      onlineSave: navigator.onLine,
      ...(status && { status })
    };

    documentToSave.onlineSave = navigator.onLine;

    saveTrainingDocument(documentToSave);
    navigate({ pathname: '../..', search: location.search });
  };

  const downloadPdfAndDisplayAttachments = (periodForm, isReadOnly) => {
    const { attachments } = periodForm;

    extractPDF(periodForm, isReadOnly);
    displayAttachmentsOnWeb(attachments);
  };

  return (
    <LoadPreferences>
      <>
        <BackButton urlToGoOnClick="../.." position="left" preventRenderOnAdminConsole />
        <EmptyPlacePreferences text={<FormattedMessage id="emptyPlacePreferences.training" />}>
          <TrainingForm
            trainingPeriod={trainingPeriod}
            classes={formClasses}
            onSave={(status) => (periodForm) => saveTraining(periodForm, status)}
            onPDFButtonClick={(periodForm, isReadOnly) => downloadPdfAndDisplayAttachments(periodForm, isReadOnly)}
          />
        </EmptyPlacePreferences>
      </>
    </LoadPreferences>
  );
};

Trainings.propTypes = {
  saveTrainingDocument: PropTypes.func.isRequired,
  extractPDF: PropTypes.func.isRequired,
  formClasses: PropTypes.object,
  placesPreferences: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  advices: PropTypes.array
};

Trainings.defaultProps = {
  formClasses: {}
};

export const mapStateToProps = createStructuredSelector({
  placesPreferences: selectPlacesPreferenceFromUser(),
  activitiesFetching: selectActivitiesLoading(),
  activities: selectActivitiesInContext(),
  advices: selectActivities()
});

export const mapDispatchToProps = {
  saveTrainingDocument: saveTraining,
  extractPDF
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Trainings);
