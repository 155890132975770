import PropTypes from 'prop-types';
import React from 'react';
import {
  withActCodeFavoritesSpecialtyList,
  withActCodePresetSpecialtyList
} from '../../../actCode/ui/withUserActCodeSpecialtyList/withActCodePresetSpecialtyList';
import CollapsableCategoryTree from '../../../../shared/category/ui/CollapsableCategoryTree/CollapsableCategoryTree';

export function SpecialtyList({ initiallyOpen, specialtyList, renderItem }) {
  return <CollapsableCategoryTree initiallyOpen={initiallyOpen} categories={specialtyList} renderItem={renderItem} />;
}

SpecialtyList.propTypes = {
  initiallyOpen: PropTypes.bool.isRequired,
  specialtyList: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired
};

export const ActCodePresetSpecialtyList = withActCodePresetSpecialtyList(SpecialtyList);
export const ActCodeFavoriteSpecialtyList = withActCodeFavoritesSpecialtyList(SpecialtyList);
