import PropTypes from 'prop-types';
import React from 'react';
import { measurementElementsUnits } from '../../../../../shared/ramq/requiredDetails/measurementElements';
import { SingleLineField } from '../../Fields';
import FormElement from '../../FormElement/FormElement';
import FormRow from '../../FormRow/FormRow';
import { isMeasurementElementsEffective } from '../../../../../shared/domain/activity/act/MeasurementElement';

const renderMeasurementElementIfNotExpired = (act, code, input) => (measurementElement, index) => {
  if (
    isMeasurementElementsEffective(act.date, measurementElement.effectiveStartDate, measurementElement.effectiveEndDate)
  ) {
    return (
      <FormRow key={`${input.name}.${measurementElement.code}`}>
        <FormElement
          name={`${input.name}.[${index}].value`}
          label={`${measurementElement.name} (${measurementElementsUnits[measurementElement.unitType]})`}
          fullWidth
          component={SingleLineField}
        />
      </FormRow>
    );
  }

  return null;
};

function MeasurementElements({ act, code, input }) {
  return code.measurementElements.map(renderMeasurementElementIfNotExpired(act, code, input));
}

MeasurementElements.propTypes = {
  act: PropTypes.object.isRequired,
  code: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default MeasurementElements;
