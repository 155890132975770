import Act from 'shared/domain/activity/act/model/Act';

export const NO_ERRORS = {};

const supplement15400CanOnlyBeBilledWithAPrincipalVisitCodeValidation = (act: Act) => {
  if (!act.codes || act.codes.length === 0 || !act.codes.some(({ code }) => code === '15400')) {
    return NO_ERRORS;
  }

  if (act.codes.length === 1) {
    return {
      codes: {
        _error: 'Le code 15400 ne peut être facturé seul, il doit être accompagné de la visite principale (42)'
      }
    };
  }

  if (!act.codes.map(({ code }) => code).includes('42')) {
    return {
      codes: {
        _error: 'Le supplément 15400 peut seulement être facturé avec la visite principale (42)'
      }
    };
  }

  return NO_ERRORS;
};

export default supplement15400CanOnlyBeBilledWithAPrincipalVisitCodeValidation;
