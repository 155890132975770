import React from 'react';
import { hasPendingPaymentValidation } from '../../../../shared/periods/common/deferredPaymentConfirmation';
import LoadingButton from '../../../components/button/LoadingButton/LoadingButton';

import { Mixte } from '../../../../shared/domain/activity/mixte/model/Mixte';
import LumpSum from '../../../../shared/domain/activity/lumpSum/model/LumpSum';
import AlertApi from '../../../shared/confirm/api/AlertApi';
import canPeriodBeBilled from '../../../../shared/domain/billing/canPeriodBeBilled';

export interface Props {
  period: Mixte | LumpSum;
  isLoading: boolean;
  onClick: () => void;
}

export const BillToRamqButton: React.FunctionComponent<Props> = ({ period, isLoading, onClick }: Props) => (
  <LoadingButton
    role="button"
    label="Ramq Payer"
    disabled={false}
    id="ramq-payer-loading-button"
    className=""
    loading={isLoading}
    onClick={() => {
      if (hasPendingPaymentValidation(period)) {
        AlertApi.showConfirm({
          title: 'Confirmation manquante',
          content: "La confirmation de la dernière demande de paiement n'est pas disponible. Procéder quand même?",
          onConfirm: onClick,
          confirmActionLabel: 'Oui',
          cancelActionLabel: 'Non'
        });
      } else if (!canPeriodBeBilled(period)) {
        AlertApi.showConfirm({
          title: 'Impossible de facturer',
          content: 'La demande ne peut pas être transmise car la période est en cours',
          onConfirm: () => {},
          displayCancel: false,
          confirmActionLabel: "J'ai compris"
        });
      } else {
        onClick();
      }
    }}
  />
);

export default BillToRamqButton;
