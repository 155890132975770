import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

function ActivityResponseExplanationDivider({ label }) {
  return (
    <div style={{ paddingLeft: 24, paddingRight: 32 }}>
      <Typography style={{ marginBottom: 0 }} gutterBottom variant="subtitle1">
        {label}
      </Typography>
      <Divider />
    </div>
  );
}

ActivityResponseExplanationDivider.propTypes = {
  label: PropTypes.string.isRequired
};

export default ActivityResponseExplanationDivider;
