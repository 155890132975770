import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import {
  BILLING_FOR_SEDATION_ANALGESIA_ONLY_REQUIRED_DETAIL,
  PG_28_PDT_OR_SURGERY_MADE_BY_ANOTHER_DOCTOR,
  PG_28_PDT_OR_SURGERY_NOT_BILLABLE
} from '../../../../shared/ramq/requiredDetails/rule28';

const VALUES = [
  { label: 'ACTE PG-28 par autre MD', value: PG_28_PDT_OR_SURGERY_MADE_BY_ANOTHER_DOCTOR },
  { label: 'ACTE PG-28 non facturable', value: PG_28_PDT_OR_SURGERY_NOT_BILLABLE }
];

const associatedFields: AssociatedField[] = [
  {
    label: 'Facturation sédation/analgésie seule',
    getName: (index) => `codes[${index}].${BILLING_FOR_SEDATION_ANALGESIA_ONLY_REQUIRED_DETAIL}`,
    type: SingleSelectCheckboxGroup,
    list: VALUES
  }
];

const billingForSedationAnalgesiaOnly: RequiredDetail = {
  associatedFields
};

export default billingForSedationAnalgesiaOnly;
