import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import useFetchUserInfos from '../../../hooks/useFetchUserInfos/useFetchUserInfos';

export const styles = () => ({
  inline: {
    display: 'inline'
  }
});

export function UserName({ userId, classes }) {
  const { userInfos } = useFetchUserInfos(userId);

  return (
    <Typography className={classes.inline}>
      {userInfos ? `${userInfos.firstName} ${userInfos.lastName}` : userId}
    </Typography>
  );
}

UserName.propTypes = {
  userId: PropTypes.string.isRequired
};

export default withStyles(styles)(UserName);
