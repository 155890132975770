import { defineMessages } from 'react-intl';
import ActivityOtherFilter from '../../../shared/core/activity/domain/ActivityOtherFilter';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import AdminStatus from '../../../shared/core/activity/domain/AdminStatus';
import { OutsideRamqPatientType } from '../../../shared/domain/activity/act/model/Act';

export default defineMessages({
  [ActivityType.ACT]: {
    id: 'activity.type.act',
    defaultMessage: 'Acte'
  },
  [ActivityType.MIXTE]: {
    id: 'activity.type.mixte',
    defaultMessage: 'Mixte'
  },
  [ActivityType.LUMP_SUM]: {
    id: 'activity.type.lumpSum',
    defaultMessage: 'Forfaitaire'
  },
  [ActivityType.TRAVEL_EXPENSES]: {
    id: 'activity.type.travelExpenses',
    defaultMessage: 'Frais de transport'
  },
  [ActivityType.TRAINING]: {
    id: 'activity.type.training',
    defaultMessage: 'Formation'
  },
  [ActivityStatus.NO_STATUS]: {
    id: 'activity.status.na',
    defaultMessage: 'N/A'
  },
  [ActivityStatus.SENT]: {
    id: 'activity.status.sent',
    defaultMessage: 'Sent'
  },
  [ActivityStatus.WAITING]: {
    id: 'activity.status.waiting',
    defaultMessage: 'Waiting'
  },
  [ActivityStatus.NEED_FIX]: {
    id: 'activity.status.needFix',
    defaultMessage: 'Need Fix'
  },
  [ActivityStatus.PROCESSING]: {
    id: 'activity.status.processing',
    defaultMessage: 'Processing'
  },
  [ActivityStatus.CANCELED]: {
    id: 'activity.status.canceled',
    defaultMessage: 'Canceled'
  },
  [ActivityStatus.PAID]: {
    id: 'activity.status.paid',
    defaultMessage: 'Paid'
  },
  [AdminStatus.NORMAL]: {
    id: 'activity.adminStatus.normal',
    defaultMessage: 'Normal'
  },
  [AdminStatus.OUTSIDE_RAMQ]: {
    id: 'activity.adminStatus.outsideRamq',
    defaultMessage: 'Hors RAMQ'
  },
  [AdminStatus.MIXTE_DESIGNATION]: {
    id: 'activity.adminStatus.mixteDesignation',
    defaultMessage: 'Désignation mixte'
  },
  [AdminStatus.TERRITORY_MAJORATION]: {
    id: 'activity.adminStatus.territoryMajoration',
    defaultMessage: 'Majoration territoire'
  },
  [AdminStatus.URGENT_TIME_LIMIT]: {
    id: 'activity.adminStatus.urgentTimeLimit',
    defaultMessage: 'Urgent / Hors délai'
  },
  [AdminStatus.REVISION_DEROGATION]: {
    id: 'activity.adminStatus.revisionDerogation',
    defaultMessage: 'Révision / Dérogation'
  },
  [AdminStatus.ERROR_AMOUNT]: {
    id: 'activity.adminStatus.errorAmount',
    defaultMessage: 'Erreur $'
  },
  [AdminStatus.AWAITING_INCORPORATION]: {
    id: 'activity.adminStatus.awaitingIncorporation',
    defaultMessage: 'Incorporation en attente'
  },
  [AdminStatus.OTHER_FOLLOWUPS]: {
    id: 'activity.adminStatus.otherFollowups',
    defaultMessage: 'Autres suivis'
  },
  [AdminStatus.MANAGED_BY_DOCTOR]: {
    id: 'activity.adminStatus.managedByDoctor',
    defaultMessage: 'Géré par le MD'
  },
  [OutsideRamqPatientType.REFUGEE]: {
    id: 'activity.outsideRamqPatientType.refugee',
    defaultMessage: 'Réfugié'
  },
  [OutsideRamqPatientType.OUTSIDE_RAMQ]: {
    id: 'activity.outsideRamqPatientType.outsideRamq',
    defaultMessage: 'Hors RAMQ'
  },
  [OutsideRamqPatientType.PRIVATE_INSURANCE]: {
    id: 'activity.outsideRamqPatientType.privateInsurance',
    defaultMessage: 'Assurance privée'
  },
  [OutsideRamqPatientType.MILITARY]: {
    id: 'activity.outsideRamqPatientType.military',
    defaultMessage: 'Militaire'
  },
  [OutsideRamqPatientType.PRISONER]: {
    id: 'activity.outsideRamqPatientType.prisoner',
    defaultMessage: 'Détenu'
  },
  [OutsideRamqPatientType.OTHER]: {
    id: 'activity.outsideRamqPatientType.other',
    defaultMessage: 'Autre'
  },
  [OutsideRamqPatientType.ABROAD_STUDENT]: {
    id: 'activity.outsideRamqPatientType.abroadStudent',
    defaultMessage: 'Étudiant étrangé'
  },
  [OutsideRamqPatientType.BILLED]: {
    id: 'activity.outsideRamqPatientType.billed',
    defaultMessage: 'Facturé au patient'
  },
  [ActivityOtherFilter.UNSYNCHRONIZED]: {
    id: 'activity.other.unsynchronized',
    defaultMessage: 'Bloqué'
  }
});
