import { formatUnixToDate } from '../../../../shared/utils/dateTime/dateTimeUtils';
import DefaultActEndDateField from '../../../components/Form/ActSpecificField/DefaultActEndDateField/DefaultActEndDateField';

const summaryRender = (code) => [
  {
    name: 'exit',
    value: code.exitDate ? formatUnixToDate(code.exitDate) : '-'
  }
];

const associatedFields = [
  {
    label: 'Date de sortie',
    getName: (index) => `codes[${index}].exitDate`,
    type: DefaultActEndDateField
  }
];

const exitDate = { associatedFields, summaryRender };

export default exitDate;
