import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import OptionsMenu from 'application/components/options-menu';

interface Tab {
  badge?: string | number;
  destination: string;
  label: string;
}

interface Props {
  tabs: Tab[];
}

const GAP_BETWEEN_TABS = 30;
const OPTION_MENU_WIDTH = 34;

const Tabs = ({ tabs }: Props) => {
  const tabsContainerRef = useRef<HTMLDivElement>(null);

  const [optionMenuItems, setOptionMenuItems] = useState<Tab[]>([]);
  const [tabIndexVisibilityThreshold, setTabIndexVisibilityThreshold] = useState<number>(Infinity);

  const applyVisibility = useCallback(
    (actionElementsWidth: number[], containerVisibleWidth: number) => {
      let visibleElementsWidth = 0;
      let isVisible = true;

      const elementsToHide: Tab[] = [];

      for (let index = 0; index < actionElementsWidth.length; index++) {
        const gapWidth = index === actionElementsWidth.length - 1 ? 0 : GAP_BETWEEN_TABS;

        visibleElementsWidth += actionElementsWidth[index] + gapWidth;

        const visibleSpaceWidth =
          index !== actionElementsWidth.length - 1 ? visibleElementsWidth + OPTION_MENU_WIDTH : visibleElementsWidth;

        if (visibleSpaceWidth > containerVisibleWidth || !isVisible) {
          if (isVisible) {
            setTabIndexVisibilityThreshold(index);
            isVisible = false;
          }

          if (tabs[index]) {
            elementsToHide.push(tabs[index]);
          }
        }
      }

      if (isVisible) {
        setTabIndexVisibilityThreshold(Infinity);
      }

      setOptionMenuItems([...elementsToHide]);
    },
    [tabs]
  );

  useEffect(() => {
    if (!tabsContainerRef.current) {
      return;
    }

    const tabsContainerElements = Array.from(tabsContainerRef.current.children) as HTMLAnchorElement[];
    const actionsListWidth: number[] = tabsContainerElements.map((tab) => tab.offsetWidth);

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          applyVisibility(actionsListWidth, Math.ceil(entry.contentBoxSize[0].inlineSize));
        }
      }
    });

    resizeObserver.observe(tabsContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [applyVisibility, tabs]);

  return (
    <div className="xs:min-w-full tabs tabs-bordered relative mb-8 flex flex-nowrap" ref={tabsContainerRef}>
      {tabs.map(({ badge, destination, label }, index) => (
        <NavLink
          key={label}
          to={destination}
          className={({ isActive }) =>
            classNames('tab', 'text-nowrap', {
              'tab-active': isActive,
              'invisible': index >= tabIndexVisibilityThreshold
            })
          }
        >
          {label}
          {badge !== undefined && <div className="badge badge-ghost ml-1">{badge}</div>}
        </NavLink>
      ))}

      <div className={classNames('absolute', 'right-0', { invisible: optionMenuItems.length === 0 })}>
        <OptionsMenu>
          {optionMenuItems.map(({ destination, label }) => (
            <OptionsMenu.Item key={label} label={label} route={destination} />
          ))}
        </OptionsMenu>
      </div>
    </div>
  );
};

export default Tabs;
