import { concat } from 'lodash';

export const clinicalSupervisionInDesignatedTeachingUnitCodes = [
  '19704',
  '19705',
  '19706',
  '19707',
  '19708',
  '19710',
  '19711',
  '19712',
  '19713',
  '19714',
  '19715',
  '19716',
  '19717',
  '19718',
  '19719',
  '19720',
  '19721',
  '19722',
  '19723',
  '19724',
  '19725',
  '19726',
  '19727',
  '19873',
  '19746',
  '19748'
];

export const clinicalSupervisionForSpecialistInEmergencyAM = ['19750', '19756', '19764'];
export const clinicalSupervisionForSpecialistInEmergencyPM = ['19751', '19757', '19765'];

export const clinicalSupervisionForSpecialistInEmergencyLATEPM = ['19752', '19758', '19766'];
export const clinicalSupervisionForSpecialistInEmergencyEVENING = ['19753', '19759', '19767'];
export const clinicalSupervisionForSpecialistInEmergencyNIGHT = ['19754', '19760', '19768'];
export const clinicalSupervisionForSpecialistInEmergencyEARLYMORNING = ['19755', '19761', '19769'];

export const clinicalSupervisionForSpecialistInEmergencyClinicActivityArea = concat(
  clinicalSupervisionForSpecialistInEmergencyAM,
  clinicalSupervisionForSpecialistInEmergencyPM,
  clinicalSupervisionForSpecialistInEmergencyLATEPM,
  clinicalSupervisionForSpecialistInEmergencyEVENING,
  clinicalSupervisionForSpecialistInEmergencyNIGHT,
  clinicalSupervisionForSpecialistInEmergencyEARLYMORNING
);

export const clinicalSupervisionInOtherTeachingUnitCodesAM = ['19700', '19702', '19762'];

export const clinicalSupervisionInOtherTeachingUnitCodesPM = ['19701', '19703', '19763'];

export const allClinicalSupervisionAMMandatory = concat(
  clinicalSupervisionInOtherTeachingUnitCodesAM,
  clinicalSupervisionForSpecialistInEmergencyAM
);

export const allClinicalSupervisionPMMandatory = concat(
  clinicalSupervisionInOtherTeachingUnitCodesPM,
  clinicalSupervisionForSpecialistInEmergencyPM
);

export const clinicalSupervisionCodes = concat(
  clinicalSupervisionInOtherTeachingUnitCodesAM,
  clinicalSupervisionInOtherTeachingUnitCodesPM,
  clinicalSupervisionForSpecialistInEmergencyClinicActivityArea,
  clinicalSupervisionInDesignatedTeachingUnitCodes
);

export const otherCodesValidAsEmergency = [
  '9150',
  '9160',
  '15386',
  '9152',
  '78',
  '99',
  '9162',
  '9170',
  '15709',
  '9164'
];
