import { createSelector } from '@reduxjs/toolkit';
import { formValueSelector } from 'redux-form';
import isDefined from '../../../shared/utils/isDefined';
import { getFirstPlaceByPriority } from './preferencesUtils';

export const getUser = (state) => state.user;
export const selectPreferences = (state) => state.preferences;
export const selectPlaces = (state) => state.places;
export const selectPlacesLoaded = (state) => state.placesLoaded;
export const selectCodes = (state) => state.codes;
export const selectCodesLoaded = (state) => state.codesLoaded;
export const selectDiagnosticCodes = (state) => state.diagnosticCodes;
export const selectDiagnosticCodesLoaded = (state) => state.diagnosticCodesLoaded;
export const selectFavoriteContextElements = (state) => state.contextElements;
export const selectFavoriteLumpSums = (state) => state.lumpSums;
export const selectFavoriteContextElementsLoaded = (state) => state.contextElementsLoaded;
export const selectCodesUpdating = (state) => state.codesUpdating;
export const selectDiagnosticCodesUpdating = (state) => state.diagnosticCodesUpdating;
export const selectDoctorsUpdating = (state) => state.doctorsUpdating;
export const selectFavoriteContextElementsUpdating = (state) => state.contextElementsUpdating;
export const selectStripeSubscriptionMetaData = (state) => state.stripeSubscriptionMetaData;

export const selectUserIdInContext = () => createSelector(getUser, (userState) => userState.id);

export const selectIsUserProfileReady = () => createSelector(getUser, (userState) => !!userState.isProfileReady);

export const selectUserProfileInContext = () => createSelector(getUser, (userState) => userState.profile);

export const selectUserLanguage = () => createSelector(getUser, (userState) => userState.language ?? 'fr');

export const selectUserDefaultGroupNumber = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile.groupNumber);

export const selectUserDefaultGroupName = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile.groupName);

export const selectUserSpecificBillingGroupNumber = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile.specificBillingGroups[0]?.number);

export const selectUserOriginalPoolNumber = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile.originalPoolNumber);

export const selectUserSpecificBillingGroupName = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile.specificBillingGroups[0]?.name);

export const selectIsCardiologyInstituteMember = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile && profile.institutCardio);

export const selectUserBillingType = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile && !!profile && profile.billingType);

export const selectHasOCREnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile && !!profile.ocrEnabled);

export const selectHasElectronicSignatureEnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile && !!profile.electronicSignatureEnabled);

export const selectHasPaymentRequestsSentInPeriodEnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile && !!profile.paymentRequestsSentInPeriod);

export const selectHasMultipleNAMDiscoveryEnabled = () =>
  createSelector(
    selectUserProfileInContext(),
    (profile) => profile && !!profile && !!profile.multipleNAMDiscoveryEnabled
  );

export const selectHasEditableFavoriteActCodesEnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => !!profile && !!profile.editableFavoritesEnabled);

export const selectHasEditableFavoriteDiagnosticCodesEnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => !!profile && !!profile.editableFavoritesDiagnosticEnabled);

export const selectHasEditableFavoriteContextElementsEnabled = () =>
  createSelector(
    selectUserProfileInContext(),
    (profile) => !!profile && !!profile.editableFavoritesContextElementsEnabled
  );

export const selectHasEditableFavoriteDoctorFeatureFlagEnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => !!profile && !!profile.editableFavoritesDoctorEnabled);

export const selectHasActCodeSpecialtyListIncluded = () =>
  createSelector(selectUserProfileInContext(), (profile) => !!profile && !!profile.actCodeSpecialtyListIncluded);

export const selectHasEditableFavoritesContextElementsFeatureFlagEnabled = () =>
  createSelector(
    selectUserProfileInContext(),
    (profile) => !!profile && !!profile.editableFavoritesContextElementsEnabled
  );

export const selectSpecialty = () =>
  createSelector(selectUserProfileInContext(), (profile) => (profile !== undefined ? profile.specialty : undefined));

export const selectHasDuplicateAnotherDayActivitiesEnabled = () =>
  createSelector(
    selectUserProfileInContext(),
    (profile) => isDefined(profile) && !!profile.duplicateAnotherDayActivities
  );

export const selectHasMixteGenerationEnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile && !!profile.mixteGenerationEnabled);

export const selectHasSkipMixteGenerationForTeachingCodesEnabled = () =>
  createSelector(selectUserProfileInContext(), (profile) => profile && !!profile.skipMixteGenerationForTeachingCodes);

export const selectPlacesPreferenceFromPreferences = createSelector(selectPreferences, selectPlaces);

export const selectPlacesLoadedFromPreference = createSelector(selectPreferences, selectPlacesLoaded);

const selectCodesPreferenceFromPreferences = createSelector(selectPreferences, selectCodes);

export const selectCodesLoadedFromPreference = createSelector(selectPreferences, selectCodesLoaded);

const selectDiagnosticCodesFromPreferences = createSelector(selectPreferences, selectDiagnosticCodes);

export const selectDiagnosticCodesLoadedFromPreferences = createSelector(
  selectPreferences,
  selectDiagnosticCodesLoaded
);

const selectFavoriteContextElementsFromPreferences = createSelector(selectPreferences, selectFavoriteContextElements);

const selectFavoriteLumpSumFromPreferences = createSelector(selectPreferences, selectFavoriteLumpSums);

export const selectFavoriteContextElementsLoadedFromPreferences = createSelector(
  selectPreferences,
  selectFavoriteContextElementsLoaded
);

export const selectCodesUpdatingFromPreference = createSelector(selectPreferences, selectCodesUpdating);

export const selectDiagnosticCodesUpdatingFromPreferences = createSelector(
  selectPreferences,
  selectDiagnosticCodesUpdating
);

export const selectFavoriteContextElementsUpdatingFromPreferences = createSelector(
  selectPreferences,
  selectFavoriteContextElementsUpdating
);

export const selectDoctorsUpdatingFromPreferences = createSelector(selectPreferences, selectDoctorsUpdating);

export const selectPlacesPreferenceFromUser = () => createSelector(getUser, selectPlacesPreferenceFromPreferences);

export const selectDefaultPlace = () => createSelector(selectPlacesPreferenceFromUser(), getFirstPlaceByPriority);

export const selectPlacesLoadedFromUser = () => createSelector(getUser, selectPlacesLoadedFromPreference);

export const selectCodesPreferencesFromUser = () => createSelector(getUser, selectCodesPreferenceFromPreferences);

export const selectCodesLoadedFromUser = () => createSelector(getUser, selectCodesLoadedFromPreference);

export const selectCodesUpdatingFromUser = () => createSelector(getUser, selectCodesUpdatingFromPreference);

export const selectDiagnosticCodesUpdatingFromUser = () =>
  createSelector(getUser, selectDiagnosticCodesUpdatingFromPreferences);

export const selectFavoriteContextElementsUpdatingFromUser = () =>
  createSelector(getUser, selectFavoriteContextElementsUpdatingFromPreferences);

export const selectDoctorsUpdatingFromUser = () => createSelector(getUser, selectDoctorsUpdatingFromPreferences);

export const selectDiagnosticCodePreferencesFromUser = () =>
  createSelector(getUser, selectDiagnosticCodesFromPreferences);

export const selectDiagnosticCodeLoadedFromUser = () =>
  createSelector(getUser, selectDiagnosticCodesLoadedFromPreferences);

export const selectFavoriteContextElementsPreferencesFromUser = () =>
  createSelector(getUser, selectFavoriteContextElementsFromPreferences);

export const selectFavoriteLumpSumPreferencesFromUser = () =>
  createSelector(getUser, selectFavoriteLumpSumFromPreferences);

export const selectFavoriteContextElementsLoadedFromUser = () =>
  createSelector(getUser, selectFavoriteContextElementsLoadedFromPreferences);

export const userInfoFormSelector = formValueSelector('userInfoForm');
export const billingTypeFormSelector = (state) => userInfoFormSelector(state, 'billingType');

export const selectUserStripeCustomerId = (state) => formValueSelector('userInfoForm')(state, 'stripeCustomerId');

export const selectUserStripeSubscriptionMetaData = (state) =>
  formValueSelector('userInfoForm')(state, 'stripeSubscriptionMetadata');
