import PropTypes from 'prop-types';
import React from 'react';
import FormattedUnixHours from '../FormattedUnixHours/FormattedUnixHours';

function LumpSumTimeSummary({ day }) {
  return <FormattedUnixHours activity={{ start: day.start, end: day.end }} />;
}

LumpSumTimeSummary.propTypes = {
  day: PropTypes.object.isRequired
};

export default LumpSumTimeSummary;
