import { ActOptimisationSpecification } from '../ActOptimisationSpecification';
import ActBillingContext from '../../optimisation/ActBillingContext';
import ActCodes from '../../../activity/act/model/ActCodes';

export class ActContainsMaleGenitaliaCode extends ActOptimisationSpecification {
  private readonly MALE_GENITALIA_CODES = [
    '6094',
    '6077',
    '6078',
    '6079',
    '6127',
    '6119',
    '6218',
    '6131',
    '6116',
    '6117',
    '6354',
    '6224',
    '6226',
    '6227',
    '6234',
    '6236',
    '6237',
    '6291',
    '6292',
    '6293',
    '6360',
    '6379',
    '6228',
    '6229',
    '6238',
    '6380',
    '6095',
    '6432',
    '6433',
    '6382',
    '6396',
    '6364',
    '6314',
    '6317',
    '6122',
    '6125',
    '6257',
    '6285',
    '6245',
    '6391',
    '6309',
    '6388',
    '6389',
    '6394',
    '6390',
    '6106',
    '6107',
    '6232',
    '6155',
    '6392',
    '6418',
    '6082',
    '6395',
    '6444',
    '6083',
    '6397',
    '6235',
    '6054',
    '6051',
    '6240',
    '6241',
    '6242',
    '6243',
    '6212',
    '6244',
    '6247',
    '6096',
    '6248',
    '6060',
    '6061',
    '6053'
  ];

  isSatisfiedBy(context: ActBillingContext): boolean {
    const actCodes = new ActCodes(context.act.codes || []);

    return actCodes.isContainsSome(...this.MALE_GENITALIA_CODES);
  }
}

export const actContainsMaleGenitaliaCode = () => new ActContainsMaleGenitaliaCode();
