import { flatten, get, groupBy, has, mapValues, orderBy, parseInt } from 'lodash';
import { addAmount } from '../../../../../../shared/amountComputation/computeExpectedAmountUtil';
import computeExpectedAmountForMixte from '../../../../../../shared/amountComputation/computeMixteExpectedAmount';
import { getPerdiems } from '../../../../../../shared/periods/mixte/makeMixteSummaryUtils';
import PeriodHoursService from '../../../../PeriodsBilling/PeriodForm/service/PeriodHoursService';

export const computeMixteDaysIncome = (mixtesInPeriod) => {
  const relevantDaysGrouped = getRelevantPeriodDaysGrouped(mixtesInPeriod);
  return Object.values(mapValues(relevantDaysGrouped, computeExpectedAmountAndPerdiems));
};

export const getRelevantPeriodDaysGrouped = (mixtesInPeriod) => {
  const relevantMixtes = mixtesInPeriod.filter((mixte) => Array.isArray(mixte.days));
  const daysOfMixte = orderBy(flatten(relevantMixtes.map(splitPeriodIntoDays)), 'date');
  return groupBy(daysOfMixte, 'date');
};

export const splitPeriodIntoDays = (period) =>
  period.days.map((day) => ({
    ...period,
    date: day.date,
    days: [day]
  }));

export const computeExpectedAmountAndPerdiems = (sameDayMixteArray, dateKey) =>
  sameDayMixteArray.reduce(addUpDay, {
    date: dateKey,
    amount: '0.00',
    numberOfPerdiems: 0,
    hoursWorked: 0
  });

const addUpDay = (final, periodDay) => {
  const nbPerdiems = getNumberOfPerdiems(periodDay);

  return {
    date: parseInt(final.date),
    amount: addAmount(final.amount, computeExpectedAmountForMixte(periodDay)),
    numberOfPerdiems: final.numberOfPerdiems + nbPerdiems * 0.5,
    hoursWorked: final.hoursWorked + PeriodHoursService.getMixteHours(periodDay)
  };
};

function getNumberOfPerdiems(periodDay) {
  const { days } = periodDay;

  return days.reduce((acc, curr) => {
    let perdiems = get(curr, 'perdiems', []);

    if (has(curr, 'start') && has(curr, 'end')) {
      perdiems = getPerdiems(curr);
    }
    return acc + perdiems.length;
  }, 0);
}

export default computeMixteDaysIncome;
