import React from 'react';
import Button from '@material-ui/core/Button';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { LETTRE_ENTENTE_241_EFFECTIVE_DATE } from 'shared/ramq/contextElements/lettreEntente241ContextElements';
import UserUtils from 'utils/userUtils';
import MultiSelectCheckboxGroup from '../../Form/Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';
import FormRow from '../../Form/FormRow/FormRow';
import RamqContextElementsField from '../../Form/ActSpecificField/RamqContextElements/RamqContextElementsField';
import { getMainContextElements } from '../LegacyContextElementsSelector/constants';
import getContextElementsTranslation from '../shared/translation';
import { selectSelectedDate } from '../../../containers/ActForm/selectors';
import ContextElementShortcutPanel from '../ContextElementShortcutPanel/ContextElementShortcutPanel';
import { atLeastOneCovidContextElementIsSelected, getCovidContextElements } from '../shared/domain';

export const styles = ({ spacing, breakpoints }) =>
  ({
    root: {
      display: 'flex',
      padding: `${spacing.unit}px 0`,
      flexDirection: 'row',
      justifyContent: 'center',
      position: 'relative',
      paddingRight: '40px'
    },
    contextElementShortcuts: {
      flexGrow: 4
    },
    addContextElementButtonContainer: {
      minWidth: '100px',
      paddingRight: '20px',

      [breakpoints.down('xs')]: {
        marginTop: `${spacing.unit}px`,
        width: '100%'
      }
    }
  }) as const;

interface OwnProps {
  contextElements: WrappedFieldProps;
  ramqContextElements: WrappedFieldProps;
}

interface ReduxStateProps {
  selectedDate: number;
}

export interface Props extends OwnProps, WithStyles<typeof styles>, ReduxStateProps {}

export function NewContextElementsSelector(props: Props) {
  const { contextElements, selectedDate, classes, ramqContextElements } = props;

  return (
    <FormRow className={classes.root}>
      <div className={classes.contextElementShortcuts}>
        <MultiSelectCheckboxGroup
          data-testid="context-element-shortcuts"
          input={contextElements.input}
          list={getContextElementsTranslation(getMainContextElements())}
          labelProp="label"
          valueProp="key"
        />
      </div>
      <RamqContextElementsField
        {...ramqContextElements}
        buttonRender={(onClick) => (
          <div className={classes.addContextElementButtonContainer}>
            <Button fullWidth variant="contained" onClick={onClick}>
              <FormattedMessage id="codeSelector.add" />
            </Button>
          </div>
        )}
        data-testid="ramq-context-elements-field"
      />
      {(moment(selectedDate).isBefore(LETTRE_ENTENTE_241_EFFECTIVE_DATE) || UserUtils.isAdminOrAgent()) && (
        <ContextElementShortcutPanel
          data-testid="covid-context-elements-shortcut-panel"
          label="COVID"
          input={contextElements.input}
          list={getContextElementsTranslation(getCovidContextElements(selectedDate))}
          selected={atLeastOneCovidContextElementIsSelected(contextElements.input)}
        />
      )}
    </FormRow>
  );
}

export const mapStateToProps = createStructuredSelector({
  selectedDate: selectSelectedDate()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(NewContextElementsSelector);
