import { logDefault } from '../../../../shared/utils/plog';
import { buildFromFileEntry } from './actFileBuilder';
import { getRootDirectory, getSubDirectory } from './cordovaLocalFile';
import MobileFileRepository from './mobileFileRepository';

const MEDYX_ASSETS_DIRECTORY = 'medyxAssets';
const MEDYX_CARD_ATTACHMENTS = 'medyxCardAttachments';

const getActAttachmentsDirectory = async () => {
  const rootDirectory = await getRootDirectory();
  const medyxDirectory = await getSubDirectory(rootDirectory, MEDYX_ASSETS_DIRECTORY);
  return getSubDirectory(medyxDirectory, MEDYX_CARD_ATTACHMENTS);
};

class ActAttachmentRepository extends MobileFileRepository {
  constructor() {
    super(getActAttachmentsDirectory);
  }

  getAllActAttachments = async () => {
    const localAttachments = await this.listAllFiles();
    logDefault(['All act attachments found in the filesystem: ', localAttachments.map((fileEntry) => fileEntry.name)]);
    return localAttachments.map(buildFromFileEntry);
  };

  removeAttachmentForAct = async (actAttachment) => this.removeFile(actAttachment.fileName);
}

const actAttachmentRepository = new ActAttachmentRepository();
export default actAttachmentRepository;
