import React from 'react';
import HourMinute from './HourMinute/HourMinute';
import TimeItems from '../TimeSelectorDialog/TimeItems';
import TimeSeparators from '../TimeSelectorDialog/TimeSeparators';
import isDefined from '../../../../shared/utils/isDefined';

const formatDoubleDigitNumber = (number) => (number < 10 ? `0${number}` : `${number}`);

const getMinutesList = (minutesPrecision, minMinutes, maxMinutes) =>
  getStringNumberList(minMinutes, maxMinutes, minutesPrecision);
const getHoursList = (maxHour) => getStringNumberList(0, maxHour, 1);

export const getStringNumberList = (minNumber: number, maxNumber: number, step: number): string[] => {
  const numberList: string[] = [];
  let currentNumber = minNumber;
  while (currentNumber <= maxNumber) {
    numberList.push(formatDoubleDigitNumber(currentNumber));
    currentNumber += step;
  }

  return numberList;
};

export interface Props {
  maxHour?: number;
  minMinutes?: number;
  maxMinutes?: number;
  minutesOnly?: boolean;
  minutesPrecision?: number;
  numberRows?: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (hourMinute: HourMinute) => void;
}

interface State {
  hour?: number;
  minute?: number;
}

class HourMinuteSelector extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};

    this.resetState = this.resetState.bind(this);
    this.isHourSelected = this.isHourSelected.bind(this);
    this.isMinuteSelected = this.isMinuteSelected.bind(this);
    this.handleHourClicked = this.handleHourClicked.bind(this);
    this.handleMinuteClicked = this.handleMinuteClicked.bind(this);
    this.updateStateAndSendChangeIfComplete = this.updateStateAndSendChangeIfComplete.bind(this);
    this.isHourAndMinutesAreSet = this.isHourAndMinutesAreSet.bind(this);
  }

  resetState() {
    this.setState({});
  }

  handleHourClicked(value) {
    this.updateStateAndSendChangeIfComplete({ hour: Number.parseInt(value, 10) });
  }

  handleMinuteClicked(value) {
    this.updateStateAndSendChangeIfComplete({ minute: Number.parseInt(value, 10) });
  }

  updateStateAndSendChangeIfComplete(newState) {
    this.setState({ ...newState }, () => {
      const canSendMinutes = this.props.minutesOnly && isDefined(this.state.minute);
      if (this.isHourAndMinutesAreSet() || canSendMinutes) {
        this.props.onChange(new HourMinute(this.state.hour || 0, this.state.minute!));

        this.resetState();
      }
    });
  }

  isHourSelected(hour) {
    return this.state.hour === Number.parseInt(hour, 10);
  }

  isMinuteSelected(minute) {
    return this.state.minute === Number.parseInt(minute, 10);
  }

  isHourAndMinutesAreSet() {
    return isDefined(this.state.hour) && isDefined(this.state.minute);
  }

  render() {
    const { maxHour, minMinutes = 0, maxMinutes, minutesOnly, numberRows, minutesPrecision } = this.props;

    return (
      <>
        {!minutesOnly && (
          <>
            <div id="hours-container">
              <TimeItems
                title="Heures"
                data-testid="hour-selector"
                itemSize={3}
                values={getHoursList(maxHour)}
                isSelectedFunction={this.isHourSelected}
                onItemClick={this.handleHourClicked}
              />
            </div>

            <TimeSeparators numberRows={numberRows} />
          </>
        )}

        <div id="minutes-container">
          <TimeItems
            title="Minutes"
            data-testid="minute-selector"
            itemSize={6}
            values={getMinutesList(minutesPrecision, minMinutes, maxMinutes || 59)}
            isSelectedFunction={this.isMinuteSelected}
            onItemClick={this.handleMinuteClicked}
          />
        </div>
      </>
    );
  }
}

export default HourMinuteSelector;
