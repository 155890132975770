import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class CreateNewCategoryDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      name: '',
      selectedPath: this.props.categoryPaths[0] ? this.props.categoryPaths[0].key : undefined,
      addToSubCategory: false
    };

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeSelectedPath = this.handleChangeSelectedPath.bind(this);
    this.handleChangeAddToSubCategory = this.handleChangeAddToSubCategory.bind(this);
  }

  resetState() {
    this.setState({
      name: '',
      selectedPath: this.props.categoryPaths[0] ? this.props.categoryPaths[0].key : undefined,
      addToSubCategory: false
    });
  }

  handleConfirm() {
    const { name, selectedPath, addToSubCategory } = this.state;
    this.props.onConfirm({ id: uuidv4(), name, path: addToSubCategory ? selectedPath : undefined });
    this.resetState();
  }

  handleChangeSelectedPath(event) {
    this.setState({
      selectedPath: event.target.value
    });
  }

  handleClose() {
    this.props.onCancel();
    this.resetState();
  }

  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }

  handleChangeAddToSubCategory() {
    this.setState((state) => ({ addToSubCategory: !state.addToSubCategory }));
  }

  renderCategoryPathMenuItems() {
    return this.props.categoryPaths.map((path) => (
      <MenuItem value={path.key} key={path.key}>
        {path.label}
      </MenuItem>
    ));
  }

  render() {
    const { open, categoryPaths } = this.props;
    const { name, selectedPath, addToSubCategory } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="editableCollectionWithCategories.createCategoryTitle" />
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              id="input-category-name"
              label={<FormattedMessage id="editableCollectionWithCategories.categoryName" />}
              value={name}
              onChange={this.handleChangeName}
            />
          </FormControl>
          {categoryPaths.length > 0 && (
            <FormControl margin="dense" fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    id="checkbox-addToSubCategory"
                    checked={addToSubCategory}
                    onChange={this.handleChangeAddToSubCategory}
                    name="addToSubCategory"
                  />
                }
                label={<FormattedMessage id="editableCollectionWithCategories.addToSubcategory" />}
              />
              <Select
                id="select-category-path"
                label="select-category-path-label"
                disabled={!addToSubCategory}
                value={selectedPath}
                onChange={this.handleChangeSelectedPath}
              >
                {this.renderCategoryPathMenuItems()}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button id="cancel-dialog" onClick={this.handleClose} color="primary">
            <FormattedMessage id="global.cancel" />
          </Button>
          <Button id="confirm-dialog" onClick={this.handleConfirm} color="primary" autoFocus>
            <FormattedMessage id="global.submit" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateNewCategoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  categoryPaths: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default CreateNewCategoryDialog;
