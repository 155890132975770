import React, { useMemo } from 'react';
import { toPairs } from 'lodash';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  classes?: any;
  options: {
    [value: string]: string;
  };
  label: string;
  values?: string[];
  onChange: (values: string[]) => void;
}

export const UserSearchScopesSelect: React.FunctionComponent<Props> = ({ options, label, values, onChange }: Props) => {
  const { optionList, selectedOptionList } = useMemo(() => {
    const optionList: Array<[string, string]> = toPairs(options);

    return {
      optionList,
      selectedOptionList: optionList.filter(([value]) => values?.includes(value))
    };
  }, [options, values]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          fullWidth
          id="tags-standard"
          options={optionList}
          value={selectedOptionList}
          getOptionLabel={([, label]: [string, string]) => label}
          onChange={(_event, selectedOptions: Array<[string, string]>) =>
            onChange(selectedOptions.map(([value]) => value))
          }
          renderInput={(params) => <TextField {...params} variant="standard" label={label} placeholder="" />}
        />
      </Grid>
    </Grid>
  );
};

export default UserSearchScopesSelect;
