import PropTypes from 'prop-types';
import React from 'react';
import DescriptionHeaderItem from '../../../../List/Item/DescriptionHeaderItem/DescriptionHeaderItem';
import ActResponseLine from '../ActResponseLine/ActResponseLine';

export function ActResponsePaymentDetails({ ramqResponse }) {
  const hasBilledActCode = ramqResponse.lines.some(({ actCode }) => !!actCode);
  return (
    <>
      <DescriptionHeaderItem
        iconDescription="# ligne"
        codeDescription={hasBilledActCode && 'Code'}
        textDescription="Explication"
        actionDescription="Montant calculé"
      />
      {ramqResponse.lines.map((line) => (
        <ActResponseLine key={line.number} actResponseLine={line} />
      ))}
    </>
  );
}

ActResponsePaymentDetails.propTypes = {
  ramqResponse: PropTypes.object.isRequired
};

export default ActResponsePaymentDetails;
