import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import EditActivity from '../../components/Activities/EditActivity/EditActivity';
import FullScreenLoading from '../../components/Loadings/FullScreenLoading/FullScreenLoading';
import SafeActivityByIdFetcher from '../ActivitiesSearch/SafeActivityByIdFetcher';
import AssistantAdvices from '../Assistant/AssistantAdvices/AssistantAdvices';
import ADVICE_ACTIVITIES_CORRECTION from '../Assistant/constants';
import { selectAdvices, selectLoading } from '../Assistant/selectors';
import { ACTIVITY_ID_ROUTE_PARAM, ACTIVITY_TYPE_ROUTE_PARAM } from '../Navigation/constants';
import IntercomWidgetWrapper from '../../components/Intercom/IntercomWidgetWrapper';

export function AssistantRoute(props) {
  const { advices, advicesLoading } = props;

  return (
    <>
      {advicesLoading ? (
        <FullScreenLoading />
      ) : (
        <Routes>
          <Route path={ACTIVITY_TYPE_ROUTE_PARAM}>
            <Route
              path={ACTIVITY_ID_ROUTE_PARAM}
              element={
                <SafeActivityByIdFetcher
                  activities={get(
                    find(advices, (advice) => advice.type === ADVICE_ACTIVITIES_CORRECTION),
                    'activities',
                    []
                  )}
                  render={({ activity }) => (!activity ? null : <EditActivity activity={activity} />)}
                />
              }
            />
          </Route>
          <Route index element={<AssistantAdvices advices={advices} />} />
        </Routes>
      )}
      <IntercomWidgetWrapper />
    </>
  );
}

AssistantRoute.propTypes = {
  advices: PropTypes.array.isRequired,
  advicesLoading: PropTypes.bool.isRequired
};

export const mapStateToProps = createStructuredSelector({
  advices: selectAdvices(),
  advicesLoading: selectLoading()
});

export default connect(mapStateToProps, null)(AssistantRoute);
