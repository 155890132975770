import React from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DoctorsDialogWithoutDoctorRegistry from '../DoctorsDialogWithoutDoctorRegistry/DoctorsDialogWithoutDoctorRegistry';
import DoctorDialogWithDoctorRegistry from '../DoctorDialogWithDoctorRegistry/DoctorDialogWithDoctorRegistry';
import { selectUserProfileInContext } from '../../../../containers/User/selectors';

export function DoctorsDialogFactory({ profile, ...rest }) {
  return profile.medyxRegistryEnabled ? (
    <DoctorDialogWithDoctorRegistry {...rest} />
  ) : (
    <DoctorsDialogWithoutDoctorRegistry {...rest} />
  );
}

DoctorsDialogFactory.propTypes = {
  profile: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  profile: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps))(DoctorsDialogFactory);
