import ConfigRepository, { SpecialtyConfigMap } from '../ConfigRepository';
import SpecialtyName, { FamilyName } from '../../../../../core/doctor/domain/SpecialtyName';

/* eslint-disable no-unused-vars */
export type SpecialtyJSONConfig = {
  families: { [key in FamilyName]?: any };
  specialties: { [key in SpecialtyName]?: any };
};

class JSONConfigRepository implements ConfigRepository {
  constructor(private readonly jsonConfig: SpecialtyJSONConfig) {}

  findFamilyConfig(familyName: FamilyName): SpecialtyConfigMap {
    return new Map(Object.entries(this.jsonConfig.families?.[familyName] || {}));
  }

  findSpecialtyConfig(specialtyName: SpecialtyName): SpecialtyConfigMap {
    return new Map(Object.entries(this.jsonConfig.specialties?.[specialtyName] || {}));
  }
}

export default JSONConfigRepository;
