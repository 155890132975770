import {
  ADD_CODE_FAILURE,
  GET_CODES,
  GET_CODES_FAILURE,
  GET_CODES_SUCCESS,
  REMOVE_CODE_FAILURE,
  UPDATE_CODE_COLLECTION,
  UPDATE_CODE_COLLECTION_FAILURE,
  UPDATE_CODE_COLLECTION_SUCCESS
} from '../CodeSearchDialog/actions';
import {
  ADD_DOCTOR_FAILURE,
  GET_DOCTORS,
  GET_DOCTORS_FAILURE,
  GET_DOCTORS_SUCCESS,
  REMOVE_DOCTOR_FAILURE,
  UPDATE_DOCTOR_COLLECTION,
  UPDATE_DOCTOR_COLLECTION_FAILURE,
  UPDATE_DOCTOR_COLLECTION_SUCCESS,
  ADD_RESIDENT_FAILURE,
  GET_RESIDENTS,
  GET_RESIDENTS_SUCCESS,
  GET_RESIDENTS_FAILURE,
  REMOVE_RESIDENT_FAILURE,
  UPDATE_RESIDENT_COLLECTION,
  UPDATE_RESIDENT_COLLECTION_SUCCESS,
  UPDATE_RESIDENT_COLLECTION_FAILURE
} from '../../doctorsRegistry/adapters/actions';
import {
  GET_PLACES,
  GET_PLACES_FAILURE,
  GET_PLACES_SUCCESS,
  REMOVE_PLACE_FAILURE,
  SAVE_PLACE_FAILURE
} from '../PlacesPreferencesDialog/actions';
import {
  SAVE_USER_PROFILE,
  SAVE_USER_PROFILE_FAILURE,
  SET_USER_IN_CONTEXT,
  SET_USER_PROFILE_IN_CONTEXT,
  SET_IS_USER_PROFILE_READY,
  SET_USER_PROFILE_IN_CONTEXT_FAILURE,
  GET_LUMP_SUMS_SUCCESS,
  GET_LUMP_SUMS_FAILURE,
  UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES,
  UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_SUCCESS,
  UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_FAILURE,
  CHANGE_USER_LANGUAGE
} from './actions';
import {
  GET_SPECIALTY_FAVORITES_CODES,
  GET_SPECIALTY_FAVORITES_CODES_FAILURE,
  GET_SPECIALTY_FAVORITES_CODES_SUCCESS,
  GET_SPECIALTY_PRESET_CODES,
  GET_SPECIALTY_PRESET_CODES_FAILURE,
  GET_SPECIALTY_PRESET_CODES_SUCCESS
} from '../../favorite/actCode/adapters/actions';
import {
  ADD_DIAGNOSTIC_CODE_FAILURE,
  GET_DIAGNOSTIC_CODES_PREFERENCES,
  GET_DIAGNOSTIC_CODES_PREFERENCES_FAILURE,
  GET_DIAGNOSTIC_CODES_PREFERENCES_SUCCESS,
  GET_SPECIALTY_DIAGNOSTIC_CODES,
  GET_SPECIALTY_DIAGNOSTIC_CODES_FAILURE,
  GET_SPECIALTY_DIAGNOSTIC_CODES_SUCCESS,
  REMOVE_DIAGNOSTIC_CODE_FAILURE,
  UPDATE_DIAGNOSTIC_CODE_COLLECTION,
  UPDATE_DIAGNOSTIC_CODE_COLLECTION_FAILURE,
  UPDATE_DIAGNOSTIC_CODE_COLLECTION_SUCCESS
} from '../../favorite/diagnosticCode/adapters/actions';
import {
  ADD_CONTEXT_ELEMENT_FAILURE,
  GET_CONTEXT_ELEMENTS_PREFERENCES,
  GET_CONTEXT_ELEMENTS_PREFERENCES_FAILURE,
  GET_CONTEXT_ELEMENTS_PREFERENCES_SUCCESS,
  GET_SPECIALTY_CONTEXT_ELEMENTS,
  GET_SPECIALTY_CONTEXT_ELEMENTS_FAILURE,
  GET_SPECIALTY_CONTEXT_ELEMENTS_SUCCESS,
  REMOVE_CONTEXT_ELEMENT_FAILURE,
  UPDATE_CONTEXT_ELEMENT_COLLECTION,
  UPDATE_CONTEXT_ELEMENT_COLLECTION_FAILURE,
  UPDATE_CONTEXT_ELEMENT_COLLECTION_SUCCESS
} from '../../favorite/contextElement/adapters/actions';

export const initialState = {
  id: undefined,
  profile: undefined,
  error: undefined,
  preferences: {
    places: [],
    placesLoaded: false,
    placesSyncError: undefined,
    addPlaceError: undefined,
    removePlaceError: undefined,
    doctors: [],
    doctorsLoaded: false,
    doctorsSyncError: undefined,
    addDoctorError: undefined,
    removeDoctorError: undefined,
    doctorsUpdating: false,
    doctorsUpdateError: undefined,
    codes: [],
    codesLoaded: false,
    codesSyncError: undefined,
    codesUpdating: false,
    codesUpdateError: undefined,
    addCodeError: undefined,
    removeCodeError: undefined,
    diagnosticCodes: [],
    diagnosticCodesLoaded: false,
    diagnosticCodesSyncError: undefined,
    diagnosticCodesUpdating: false,
    diagnosticCodesUpdateError: undefined,
    addDiagnosticCodeError: undefined,
    removeDiagnosticCodeError: undefined,
    contextElements: [],
    contextElementsLoaded: false,
    contextElementsSyncError: undefined,
    contextElementsUpdating: false,
    contextElementsUpdateError: undefined,
    addContextElementError: undefined,
    removeContextElementError: undefined,
    specialtyPresetActCodes: [],
    specialtyPresetActCodesLoaded: false,
    specialtyPresetActCodesLoadingError: undefined,
    specialtyFavoritesActCodes: [],
    specialtyFavoritesActCodesLoaded: false,
    specialtyFavoritesActCodesLoadingError: undefined,
    specialtyDiagnosticCodes: [],
    specialtyDiagnosticCodesLoaded: false,
    specialtyDiagnosticCodesLoadingError: undefined,
    specialtyContextElements: [],
    specialtyContextElementsLoaded: false,
    specialtyContextElementsError: undefined,

    lumpSums: [],
    lumpSumsLoaded: false,
    lumpSumsLoadingError: undefined,
    lumpSumsUpdating: false,
    lumpSumsUpdateError: undefined,

    residentsLoaded: false,
    residentsSyncError: undefined,
    residents: [],
    residentsUpdating: false,
    residentsUpdateError: undefined
  }
};

// TODO use combine reducers here... it's always the same
function userReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_PROFILE:
      return { ...state, error: undefined };
    case SAVE_USER_PROFILE_FAILURE:
    case SET_USER_PROFILE_IN_CONTEXT_FAILURE:
      return { ...state, error: true };
    case SET_USER_PROFILE_IN_CONTEXT:
      return { ...state, profile: action.profile };
    case SET_USER_IN_CONTEXT:
      return { ...state, id: action.userId };
    case SET_IS_USER_PROFILE_READY:
      return { ...state, isProfileReady: true };
    case GET_PLACES:
      return { ...state, preferences: { ...state.preferences, placesLoaded: false, placesSyncError: undefined } };
    case GET_PLACES_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, places: action.places, placesLoaded: true }
      };
    case GET_PLACES_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, placesSyncError: action.error, placesLoaded: true }
      };
    case SAVE_PLACE_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, addPlaceError: action.error }
      };
    case REMOVE_PLACE_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, removePlaceError: action.error }
      };
    case GET_DOCTORS:
      return {
        ...state,
        preferences: { ...state.preferences, doctorsLoaded: false, doctorsSyncError: undefined }
      };
    case GET_DOCTORS_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, doctors: action.doctors, doctorsLoaded: true }
      };
    case GET_DOCTORS_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, doctorsSyncError: action.error, doctorsLoaded: true }
      };
    case ADD_DOCTOR_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, addDoctorError: action.error }
      };
    case REMOVE_DOCTOR_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, removeDoctorError: action.error }
      };
    case UPDATE_DOCTOR_COLLECTION:
      return {
        ...state,
        preferences: { ...state.preferences, doctorsUpdating: true, doctorsUpdateError: undefined }
      };
    case UPDATE_DOCTOR_COLLECTION_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, doctorsUpdating: false }
      };
    case UPDATE_DOCTOR_COLLECTION_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, doctorsUpdateError: action.error, doctorsUpdating: false }
      };
    case GET_CODES:
      return {
        ...state,
        preferences: { ...state.preferences, codesLoaded: false, codesSyncError: undefined }
      };
    case GET_CODES_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, codes: action.codes, codesLoaded: true }
      };
    case GET_CODES_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, codesSyncError: action.error, codesLoaded: true }
      };
    case UPDATE_CODE_COLLECTION:
      return {
        ...state,
        preferences: { ...state.preferences, codesUpdating: true, codesUpdateError: undefined }
      };
    case UPDATE_CODE_COLLECTION_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, codesUpdating: false }
      };
    case UPDATE_CODE_COLLECTION_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, codesUpdateError: action.error, codesUpdating: false }
      };
    case ADD_CODE_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, addCodeError: action.error }
      };
    case REMOVE_CODE_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, removeCodeError: action.error }
      };
    case GET_DIAGNOSTIC_CODES_PREFERENCES:
      return {
        ...state,
        preferences: { ...state.preferences, diagnosticCodesLoaded: false, diagnosticCodesSyncError: undefined }
      };
    case GET_DIAGNOSTIC_CODES_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, diagnosticCodes: action.diagnosticCodes, diagnosticCodesLoaded: true }
      };
    case GET_DIAGNOSTIC_CODES_PREFERENCES_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, diagnosticCodesSyncError: action.error, diagnosticCodesLoaded: true }
      };
    case UPDATE_DIAGNOSTIC_CODE_COLLECTION:
      return {
        ...state,
        preferences: { ...state.preferences, diagnosticCodesUpdating: true, diagnosticCodesUpdateError: undefined }
      };
    case UPDATE_DIAGNOSTIC_CODE_COLLECTION_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, diagnosticCodesUpdating: false }
      };
    case UPDATE_DIAGNOSTIC_CODE_COLLECTION_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, diagnosticCodesUpdateError: action.error, diagnosticCodesUpdating: false }
      };
    case ADD_DIAGNOSTIC_CODE_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, addDiagnosticCodeError: action.error }
      };
    case REMOVE_DIAGNOSTIC_CODE_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, removeDiagnosticCodeError: action.error }
      };
    case GET_CONTEXT_ELEMENTS_PREFERENCES:
      return {
        ...state,
        preferences: { ...state.preferences, contextElementsLoaded: false, contextElementsSyncError: undefined }
      };
    case GET_CONTEXT_ELEMENTS_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, contextElements: action.contextElements, contextElementsLoaded: true }
      };
    case GET_CONTEXT_ELEMENTS_PREFERENCES_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, contextElementsSyncError: action.error, contextElementsLoaded: true }
      };
    case UPDATE_CONTEXT_ELEMENT_COLLECTION:
      return {
        ...state,
        preferences: { ...state.preferences, contextElementsUpdating: true, contextElementsUpdateError: undefined }
      };
    case UPDATE_CONTEXT_ELEMENT_COLLECTION_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, contextElementsUpdating: false }
      };
    case UPDATE_CONTEXT_ELEMENT_COLLECTION_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, contextElementsUpdateError: action.error, contextElementsUpdating: false }
      };
    case ADD_CONTEXT_ELEMENT_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, addContextElementError: action.error }
      };
    case REMOVE_CONTEXT_ELEMENT_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, removeContextElementError: action.error }
      };
    case GET_SPECIALTY_CONTEXT_ELEMENTS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyContextElementsLoaded: false,
          specialtyContextElementsError: undefined
        }
      };
    case GET_SPECIALTY_CONTEXT_ELEMENTS_SUCCESS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyContextElements: action.specialtyContextElements,
          specialtyContextElementsLoaded: true
        }
      };
    case GET_SPECIALTY_CONTEXT_ELEMENTS_FAILURE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyContextElementsError: action.specialtyContextElementsError,
          specialtyContextElementsLoaded: true
        }
      };
    case GET_SPECIALTY_PRESET_CODES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyPresetActCodesLoaded: false,
          specialtyPresetActCodesLoadingError: undefined
        }
      };
    case GET_SPECIALTY_PRESET_CODES_SUCCESS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyPresetActCodes: action.codes,
          specialtyPresetActCodesLoaded: true
        }
      };
    case GET_SPECIALTY_PRESET_CODES_FAILURE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyPresetActCodesLoadingError: action.error,
          specialtyPresetActCodesLoaded: true
        }
      };
    case GET_SPECIALTY_FAVORITES_CODES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyFavoritesActCodesLoaded: false,
          specialtyFavoritesActCodesLoadingError: undefined
        }
      };
    case GET_SPECIALTY_FAVORITES_CODES_SUCCESS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyFavoritesActCodes: action.codes,
          specialtyFavoritesActCodesLoaded: true
        }
      };
    case GET_SPECIALTY_FAVORITES_CODES_FAILURE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyFavoritesActCodesLoadingError: action.error,
          specialtyFavoritesActCodesLoaded: true
        }
      };
    case GET_SPECIALTY_DIAGNOSTIC_CODES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyDiagnosticCodesLoaded: false,
          specialtyDiagnosticCodesLoadingError: undefined
        }
      };
    case GET_SPECIALTY_DIAGNOSTIC_CODES_SUCCESS:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyDiagnosticCodes: action.codes,
          specialtyDiagnosticCodesLoaded: true
        }
      };
    case GET_SPECIALTY_DIAGNOSTIC_CODES_FAILURE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          specialtyDiagnosticCodesLoadingError: action.error,
          specialtyDiagnosticCodesLoaded: true
        }
      };
    case GET_LUMP_SUMS_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, lumpSums: action.lumpSums, lumpSumsLoaded: true }
      };
    case GET_LUMP_SUMS_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, lumpSumsLoadingError: action.error, lumpSumsLoaded: true }
      };
    case UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES:
      return {
        ...state,
        preferences: { ...state.preferences, lumpSumsUpdating: true, lumpSumsUpdateError: undefined }
      };
    case UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, lumpSumsUpdating: false }
      };
    case UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, lumpSumsUpdateError: action.error, lumpSumsUpdating: false }
      };
    case CHANGE_USER_LANGUAGE:
      return {
        ...state,
        language: action.language
      };
    case GET_RESIDENTS:
      return {
        ...state,
        preferences: { ...state.preferences, residentsLoaded: false, residentsSyncError: undefined }
      };
    case GET_RESIDENTS_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, residents: action.residents, residentsLoaded: true }
      };
    case GET_RESIDENTS_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, residentsSyncError: action.error, residentsLoaded: true }
      };
    case ADD_RESIDENT_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, addResidentError: action.error }
      };
    case REMOVE_RESIDENT_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, removeResidentError: action.error }
      };
    case UPDATE_RESIDENT_COLLECTION:
      return {
        ...state,
        preferences: { ...state.preferences, residentsUpdating: true, residentsUpdateError: undefined }
      };
    case UPDATE_RESIDENT_COLLECTION_SUCCESS:
      return {
        ...state,
        preferences: { ...state.preferences, residentsUpdating: false }
      };
    case UPDATE_RESIDENT_COLLECTION_FAILURE:
      return {
        ...state,
        preferences: { ...state.preferences, residentsUpdateError: action.error, residentsUpdating: false }
      };
    default:
      return state;
  }
}

export default userReducer;
