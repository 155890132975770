import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import Section from 'application/components/form-controls/section';
import FieldContainer from 'application/components/form-controls/field-container';
import TextInput from 'application/components/form-controls/text-input';
import Button from 'application/components/button';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import PhoneInput from 'application/components/form-controls/phone-input';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';

interface Props {
  user: FirestoreUser;
  formIsValid: boolean;
  registerField: (field: string) => ValidatedFieldProps;
  onChange: (user: FirestoreUser) => void;
}

const SettingsCommunications = ({ user, formIsValid: isValid, registerField, onChange }: Props) => {
  const translate = useTranslate('pages.settings.details.communications');

  return (
    <Section label={translate('title')}>
      <FieldContainer label={translate('user.email.label')} helper={translate('user.email.helper')} required>
        <TextInput
          {...registerField('email')}
          value={user.email}
          onChange={(email) => onChange({ ...user, email })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.phone')} required>
        <PhoneInput
          {...registerField('phone')}
          value={user.phone}
          onChange={(phone) => onChange({ ...user, phone })}
          required
        />
      </FieldContainer>

      <Section.Footer>
        <Button type="submit" disabled={!isValid} className="btn btn-primary">
          {translate('save')}
        </Button>
      </Section.Footer>
    </Section>
  );
};

export default SettingsCommunications;
