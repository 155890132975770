import { EXTRACT_PDF, EXTRACT_PDF_FAILURE, EXTRACT_PDF_SUCCESS } from './actions';

export const initialPDFState = {
  loading: false,
  error: undefined
};

function pdfExtractionReducer(state = initialPDFState, action) {
  switch (action.type) {
    case EXTRACT_PDF:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case EXTRACT_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined
      };
    case EXTRACT_PDF_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}

export default pdfExtractionReducer;
