import React, { useState } from 'react';
import { CircularProgress, MenuItem, Select, withStyles, WithStyles } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import UserBillingType from 'shared/domain/user/UserBillingType';
import useTranslate from 'application/hooks/use-translate';
import classNames from 'classnames';
import ReportAPI from '../../../infrastructure/api/ReportAPI';
import HeaderPortal from '../../../components/Portals/HeaderPortal/HeaderPortal';
import User from '../../../../shared/domain/user/User';
import { displayGlobalError } from '../../Act/actions';

export const styles = (theme) =>
  ({
    dateMessage: {
      color: 'black',
      marginRight: theme.spacing(1)
    },
    whiteBackground: {
      backgroundColor: 'white'
    },
    dropdownLabel: {
      marginLeft: theme.spacing(8)
    },
    downloadButton: {
      marginLeft: theme.spacing(4)
    },
    groupNumberSelect: {
      marginLeft: theme.spacing(4)
    }
  }) as const;

export interface ReduxDispatchProps {
  // eslint-disable-next-line no-unused-vars
  onDisplayGlobalError: (error: any) => void;
}

export interface Props extends ReduxDispatchProps, WithStyles<typeof styles> {
  selectedYear: string;
  onSelectedYearChange: (year: string) => void;
  selectedBillingIdentifier: string;
  onSelectedBillingIdentifierChange: (billingIdentifier: string) => void;
  doctor: User;
}

export const OutsideRAMQForm: React.FunctionComponent<Props> = ({
  doctor,
  onDisplayGlobalError,
  selectedYear,
  onSelectedYearChange,
  selectedBillingIdentifier,
  onSelectedBillingIdentifierChange,
  classes
}: Props) => {
  const translate = useTranslate('billingType');
  const { billingType, pools = [] } = doctor;
  const [loading, setLoading] = useState<boolean>(false);

  const onDownload = async () => {
    setLoading(true);
    try {
      const params = { userId: doctor.id, year: selectedYear };

      if (!!selectedBillingIdentifier && selectedBillingIdentifier !== UserBillingType.PRIVATE) {
        Object.assign(params, { groupId: selectedBillingIdentifier });
      }

      await ReportAPI.downloadDoctorOutsideRAMQReport(params);
    } catch (error) {
      onDisplayGlobalError((error as any).toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <HeaderPortal>
      <Toolbar className={classes.whiteBackground}>
        <Typography className={classes.dateMessage}>Année :</Typography>

        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <DatePicker
            disableFuture
            onChange={(event) => event && onSelectedYearChange(event.format('YYYY'))}
            value={selectedYear}
            views={['year']}
          />
        </MuiPickersUtilsProvider>

        {billingType === UserBillingType.POOL && pools.length > 0 && (
          <Select
            className={classes.groupNumberSelect}
            autoWidth={false}
            value={selectedBillingIdentifier}
            onChange={({ target }) => onSelectedBillingIdentifierChange(target.value as string)}
          >
            <MenuItem key={UserBillingType.PRIVATE} value={UserBillingType.PRIVATE}>
              {translate('private')}
            </MenuItem>
            {pools.map((pool) => (
              <MenuItem key={pool.id} value={pool.id}>
                ({pool.number}) {pool.name}
              </MenuItem>
            ))}
          </Select>
        )}

        {!window.cordova && (
          <button
            type="button"
            className={classNames('btn btn-primary', classes.downloadButton)}
            disabled={loading}
            onClick={onDownload}
          >
            {loading ? <CircularProgress color="primary" size={24} /> : 'Télécharger'}
          </button>
        )}
      </Toolbar>
    </HeaderPortal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    onDisplayGlobalError: bindActionCreators(displayGlobalError, dispatch)
  };
}

export default compose<typeof React.Component>(withStyles(styles), connect(null, mapDispatchToProps))(OutsideRAMQForm);
