import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControlLabel, Switch } from '@material-ui/core';
import ActivityType from '../../../../../../shared/core/activity/domain/ActivityType';
import ActMainInformation from '../../../../../components/Activities/Act/ActMainInformation/ActMainInformation';
import ActSummaryActivityHeader from '../../../../../components/Activities/Act/ActSummaryHeader/ActSummaryHeader';
import Details from '../../../../../components/Activities/Act/Details/Details';
import DialogCenteredTitle from '../../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';

export class CommentEditorDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      checkedValue: false
    };
    this.updateInputValue = this.updateInputValue.bind(this);
    this.updateCheckedValue = this.updateCheckedValue.bind(this);
    this.addComment = this.addComment.bind(this);
    this.resetDialog = this.resetDialog.bind(this);
  }

  updateInputValue(event) {
    this.setState({ inputValue: event.target.value });
  }

  updateCheckedValue(event) {
    this.setState({ checkedValue: event.target.checked });
  }

  resetDialog() {
    this.setState({ inputValue: '', checkedValue: false });
  }

  addComment() {
    this.props.onItemSelected(this.state.inputValue, this.state.checkedValue);
    this.props.onClose();
  }

  render() {
    const { open, onClose, title, act, comment } = this.props;

    return (
      <Dialog fullWidth open={open} onClose={onClose} TransitionProps={{ onExited: this.resetDialog }}>
        <DialogCenteredTitle label={title} />
        <DialogContent>
          {act && act.type === ActivityType.ACT && (
            <div
              data-testid="act-info-section"
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                paddingTop: 8 * 4
              }}
            >
              <ActSummaryActivityHeader activity={act} />
              <ActMainInformation activity={act} />
              <Details open act={act} />
            </div>
          )}
          <TextField fullWidth multiline autoFocus label="" defaultValue={comment} onChange={this.updateInputValue} />
          <FormControlLabel
            control={<Switch defaultChecked={false} color="primary" onChange={this.updateCheckedValue} />}
            label="Aucune action du médecin n'est requise."
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="cancel-button" onClick={onClose}>
            Annuler
          </Button>
          <Button data-testid="submit-button" onClick={this.addComment} color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CommentEditorDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  comment: PropTypes.string,
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  act: PropTypes.object
};

CommentEditorDialog.defaultProps = {
  act: undefined,
  comment: ''
};

export default CommentEditorDialog;
