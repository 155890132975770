import React, { useEffect } from 'react';
import { useLocalStorage, useNetworkState, useToggle } from 'react-use';
import classnames from 'classnames';
import Icon from 'application/components/icon';
import Markdown from 'application/components/markdown';
import useTranslate from 'application/hooks/use-translate';
import useToast from 'application/hooks/use-toast';
import { LocalStorageKeys } from 'userCustomization/LocalStorageKeys';

const OfflineNetworkWarning = () => {
  const translate = useTranslate('offline-network-warning');

  const { online = true, previous } = useNetworkState();

  const [detailsHidden, toggleDetailsVisibility] = useToggle(true);

  const [detailedOfflineNetworkWarningDismissed, setDetailedOfflineNetworkWarningDismissed] = useLocalStorage<boolean>(
    LocalStorageKeys.DETAILED_OFFLINE_NETWORK_WARNING_DISMISSED,
    false
  );

  const { addToast } = useToast();

  useEffect(() => {
    if (!detailedOfflineNetworkWarningDismissed) return;

    if (previous === false && online === true) {
      addToast({
        autoDismiss: true,
        icon: 'wifi',
        message: translate('you-are-back-online'),
        type: 'success'
      });
    }

    if (previous === true && online === false) {
      addToast({
        autoDismiss: true,
        icon: 'wifi-exclamation',
        message: translate('you-are-now-offline'),
        type: 'warning'
      });
    }
  }, [addToast, detailedOfflineNetworkWarningDismissed, online, previous, translate]);

  return (
    <div
      className={classnames(
        'linear fixed left-4 right-4 z-[9999] flex flex-col gap-2 overflow-hidden rounded-lg border border-yellow-500 bg-white shadow-md transition-all duration-700 md:left-auto md:max-w-[420px]',
        {
          '-top-[100%]': online || detailedOfflineNetworkWarningDismissed,
          'top-4': !online && !detailedOfflineNetworkWarningDismissed
        }
      )}
    >
      <button
        className="inline-flex items-center justify-start gap-4 px-4 pb-2 pt-4 text-yellow-800"
        onClick={toggleDetailsVisibility}
        type="button"
      >
        <Icon className="text-2xl" icon="wifi-exclamation" />

        <span className="flex w-full items-center justify-between gap-2 text-base font-bold">
          {translate('title')}

          <Icon
            className={classnames('linear transition-all duration-500', { 'rotate-180': !detailsHidden })}
            icon="chevron-down"
          />
        </span>
      </button>

      <div
        className={classnames('linear max-h-0 overflow-hidden px-4 duration-700', 'transition-all', {
          'max-h-96': !detailsHidden
        })}
      >
        <Markdown slug="offline-network-warning" />
      </div>

      <button
        className="flex w-full items-center justify-center bg-gray-100 p-4 text-sm font-bold"
        onClick={() => setDetailedOfflineNetworkWarningDismissed(true)}
        type="button"
      >
        {translate('dismiss-button')}
      </button>
    </div>
  );
};

export default OfflineNetworkWarning;
