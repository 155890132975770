import { firestore } from 'server/Firebase';
import { USERS_COLLECTION } from 'shared/collection/collectionConstants';

export default function resolveLanguage(userProfile) {
  const { language } = userProfile;

  if (language) return language;

  const storageLocale = localStorage.getItem('locale');
  const location = window.location.href.split('?');
  const queryParam = location.length > 0 ? new URLSearchParams(location[1]) : new URLSearchParams();
  const queryParamLocale = queryParam.get('locale');
  const navigatorLocale = navigator.language.split('-')[0];
  let locale = storageLocale || queryParamLocale || navigatorLocale;

  if (!['fr', 'en'].includes(locale)) {
    locale = 'fr';
  }

  firestore.collection(USERS_COLLECTION).doc(userProfile.id).update({ language: locale });
  localStorage.removeItem('locale');

  return locale;
}
