import moment from 'moment-timezone';
import {
  ACTIVITIES_ACT_TYPE,
  ACTIVITIES_LUMP_SUM_TYPE,
  ACTIVITIES_MIXTE_TYPE
} from '../../../../shared/collection/collectionConstants';

export function splitActivitiesPerPeriod(activities, period) {
  const splitActivities = {};

  activities.sort((activity1, activity2) => moment(activity1.date).valueOf() - moment(activity2.date).valueOf());

  Object.keys(period).forEach((activityType) => {
    splitActivities[activityType] = activities.filter(isInPeriod(period[activityType])).filter(isOfType(activityType));
  });

  return splitActivities;
}

// We are assuming that acts are already sorted
export function prepareActivitiesData(activities, period) {
  const splitActivities = splitActivitiesPerPeriod(activities, period);

  return {
    [ACTIVITIES_ACT_TYPE]: splitActivities[ACTIVITIES_ACT_TYPE],
    [ACTIVITIES_MIXTE_TYPE]: splitActivities[ACTIVITIES_MIXTE_TYPE],
    [ACTIVITIES_LUMP_SUM_TYPE]: splitActivities[ACTIVITIES_LUMP_SUM_TYPE]
  };
}

const isInPeriod = (period) => (activity) => {
  const activityMoment = moment(activity.date);

  return (
    activityMoment.isSameOrAfter(moment(period.startDate)) && activityMoment.isSameOrBefore(moment(period.endDate))
  );
};

const isOfType = (type) => (activity) => activity.type === type;
