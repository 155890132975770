import React from 'react';
import HybridListSelector from '../../../components/Form/ListSelector/HybridListSelector/HybridListSelector';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';

export interface Props {
  specialties: SpecialtyName[];
  // eslint-disable-next-line no-unused-vars
  onChange: (specialties: SpecialtyName[]) => void;
}

const SPECIALTY_CHOICES = [
  {
    label: 'Anesthésiologie',
    value: SpecialtyName.ANESTHESIOLOGIST
  },
  {
    label: 'Anesthésiologie intensive',
    value: SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST
  },
  {
    label: 'Cardiologie',
    value: SpecialtyName.CARDIOLOGIST
  },
  {
    label: 'Chirurgie générale',
    value: SpecialtyName.GENERAL_SURGEON
  },
  {
    label: 'Chirurgie orthopédique',
    value: SpecialtyName.ORTHOPEDIC_SURGEON
  },
  {
    label: 'Endocrinologie',
    value: SpecialtyName.ENDOCRINOLOGIST
  },
  {
    label: 'Médecine interne',
    value: SpecialtyName.INTERNIST
  },
  {
    label: 'Oto-rhino-laryngologie',
    value: SpecialtyName.OTOLARYNGOLOGIST
  },
  {
    label: 'Pneumologie',
    value: SpecialtyName.PNEUMOLOGIST
  }
];

const DoctorSpecialtyMultiSelector: React.FunctionComponent<Props> = (props: Props) => {
  const { specialties, onChange } = props;

  return (
    <HybridListSelector
      multiple
      placeholder="Choisir une/des spécialité(s)"
      input={{
        onChange: (evt) => onChange(evt.target.value),
        value: specialties
      }}
      values={SPECIALTY_CHOICES}
    />
  );
};

export default DoctorSpecialtyMultiSelector;
