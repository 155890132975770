import PropTypes from 'prop-types';
import React from 'react';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../../shared/periods/common/constants';
import Hours from '../../Hours/Hours';

const perdiemsHoursMapping = {
  [AM_PERDIEM]: { start: '8h00', end: '12h00' },
  [PM_PERDIEM]: { start: '12h00', end: '15h00' },
  allDay: { start: '8h00', end: '15h00' },
  noPerdiems: { start: '', end: '' }
};

function renderDefinedPerdiems(perdiems) {
  return perdiems.length === 2 ? perdiemsHoursMapping.allDay : perdiemsHoursMapping[perdiems[0]];
}

function PerdiemsToHours({ perdiems, manualEditionMode }) {
  const text = `Mixte${manualEditionMode ? ' - manuel ' : ''}`;
  const activity = perdiemsHoursMapping.noPerdiems;

  if (perdiems.length > 0) {
    Object.assign(activity, renderDefinedPerdiems(perdiems));
  }

  return <Hours activity={activity} text={text} />;
}

PerdiemsToHours.defaultProps = {
  perdiems: [],
  manualEditionMode: false
};
PerdiemsToHours.propTypes = {
  perdiems: PropTypes.array,
  manualEditionMode: PropTypes.bool
};

export default PerdiemsToHours;
