import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { change } from 'redux-form';
import FormElement from '../Form/FormElement/FormElement';
import { selectSelectedActPlace } from '../../containers/ActForm/selectors';
import { ACT_FORM_NAME } from '../../containers/ActForm/constants';
import isDefined from '../../../shared/utils/isDefined';

export const styles = (theme) => ({
  rootCard: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  deleteButton: {
    position: 'absolute',
    height: '40px',
    width: '40px',
    right: '0px',
    top: '0px'
  },
  headerRow: {
    height: '40px',
    position: 'relative'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    height: '40px'
  }
});

export class RequiredDetail extends React.Component {
  componentDidUpdate(prevProps) {
    const { requiredDetail: previousDetailFields } = prevProps;
    const { requiredDetail: currentDetailFields, resetDetailField } = this.props;

    const previousDetailKeys = previousDetailFields.fields.map((el) => el.getName(currentDetailFields.index));
    const currentDetailKeys = currentDetailFields.fields.map((el) => el.getName(currentDetailFields.index));

    if (isDefined(resetDetailField)) {
      previousDetailKeys.filter((key) => !currentDetailKeys.includes(key)).forEach(resetDetailField);
    }
  }

  render() {
    const { classes, requiredDetail, currentSelectedPlace, ...rest } = this.props;
    const requiredDetailFieldsSortedByMandatory = sortRequiredDetailFieldSortedByMandatory(requiredDetail);

    return (
      <Paper className={classes.rootCard}>
        <CardContent>
          {requiredDetail.title && (
            <div className={classes.headerRow}>
              <Typography variant="h6" className={classes.title}>
                {requiredDetail.title}
              </Typography>
            </div>
          )}
          <Grid container key={requiredDetail.key} spacing={2}>
            {requiredDetailFieldsSortedByMandatory.map((field) => (
              <Grid key={field.getName(requiredDetail.index)} item xs={12} sm={6}>
                <FormElement
                  {...rest}
                  {...field.props}
                  defaultPlace={currentSelectedPlace}
                  name={field.getName(requiredDetail.index)}
                  component={field.type}
                  label={field.label}
                  list={field.list}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Paper>
    );
  }
}

function sortRequiredDetailFieldSortedByMandatory(requiredDetail) {
  return requiredDetail.fields
    .sort((firstField, secondField) => {
      const isFirstFieldMandatory = !firstField.notRequired;
      const isSecondFieldMandatory = !secondField.notRequired;

      if (isFirstFieldMandatory && !isSecondFieldMandatory) {
        return 1;
      }

      if (!isFirstFieldMandatory && isSecondFieldMandatory) {
        return -1;
      }

      return 0;
    })
    .reverse();
}

RequiredDetail.propTypes = {
  requiredDetail: PropTypes.object.isRequired,
  currentSelectedPlace: PropTypes.object.isRequired,
  resetDetailField: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  currentSelectedPlace: selectSelectedActPlace()
});

export const mapDispatchToProps = {
  resetDetailField: (path) => change(ACT_FORM_NAME, path, null)
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(RequiredDetail);
