import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import PoolStatementRenderer from 'application/pages/groups/group/components/reports/PoolStatementRenderer/PoolStatementRenderer';
import useTranslate from 'application/hooks/use-translate';
import Icon from 'application/components/icon';
import ReportService from 'application/services/report-service';
import StatementDateSelector from 'application/pages/groups/group/components/statement-date-selector';
import LoadingState from 'application/pages/groups/group/components/reports/loading-state';
import NoDataAvailableState from 'application/pages/groups/group/components/reports/no-data-available-state';
import ReactToPrint from 'react-to-print';

interface Props {
  groupId: string;
}

const StatementReport = ({ groupId }: Props) => {
  const translate = useTranslate('reports');
  const [selectedStatementDate, setSelectedStatementDate] = useState<number | null>(null);
  const { data, isFetching, isSuccess } = useQuery<[]>({
    queryKey: ['group-statement-report', selectedStatementDate],
    queryFn: () => ReportService.fetchPoolStatement(groupId, selectedStatementDate as number),
    enabled: !!selectedStatementDate
  });

  return (
    <>
      <div className="absolute right-4 top-4 z-50 print:hidden">
        <StatementDateSelector groupId={groupId} onChange={setSelectedStatementDate} value={selectedStatementDate} />
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-primary gap-2" type="button" disabled={!data || !isSuccess}>
              <Icon icon="print" /> {translate('print')}
            </button>
          )}
          content={() => document.getElementById('report-area') as HTMLElement}
        />
      </div>

      {isFetching && <LoadingState />}

      {data && isSuccess && !isFetching && (
        <div id="report-area">
          <div className="h-[calc(100vh-325px)] overflow-y-auto overflow-x-hidden print:h-full print:overflow-hidden">
            <PoolStatementRenderer statements={data} />
          </div>
        </div>
      )}

      {!data && isSuccess && <NoDataAvailableState />}

      {!data && !isFetching && !isSuccess && (
        <div className="relative flex h-96 flex-col items-center justify-center gap-4 bg-base-200">
          <Icon icon="calendar-day" className="text-4xl text-gray-400" />

          <span className="w-64 text-center text-gray-400">{translate('select-statement-date')}</span>
        </div>
      )}
    </>
  );
};

export default StatementReport;
