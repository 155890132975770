import { USER_ACCOUNT_STATUS_COLORS, USER_ACCOUNT_STATUS_TEXT } from 'app/components/UserStatus/UserStatus';
import React from 'react';
import UserAccountStatus from 'shared/domain/user/profile/UserAccountStatus';

interface Props {
  status: UserAccountStatus;
}

const UserStatusBadge = ({ status }: Props) => {
  const color = USER_ACCOUNT_STATUS_COLORS[status];
  const label = USER_ACCOUNT_STATUS_TEXT[status];

  return (
    <div style={{ backgroundColor: color }} className="inline-flex items-center rounded-full px-2 py-1 text-xs">
      {label}
    </div>
  );
};

export default UserStatusBadge;
