import Icon from 'application/components/icon';
import useTranslate from 'application/hooks/use-translate';
import React from 'react';
import classNames from 'classnames';
import { useToggle } from 'react-use';
import Spinner from './spinner';

interface Props {
  children: React.ReactNode;
  title?: string;
  count: number;
  collapsable?: boolean;
  emptyPlaceholder?: string;
  columns: Array<{
    title: string;
    width: string;
  }>;
  headerComponent?: React.ReactNode;
  headerButton?: {
    label?: string;
    onClick: () => void;
    hide?: boolean;
    onCancel?: () => void;
    cancelLabel?: string;
    loading?: boolean;
  };
}

const DataTable = ({
  title,
  count,
  collapsable,
  headerComponent,
  headerButton,
  emptyPlaceholder,
  columns,
  children
}: Props) => {
  const translate = useTranslate('components.data-table');

  const [expanded, setExpanded] = useToggle(!collapsable);

  const disableDetailsIfNotCollapsable = (event: React.MouseEvent<HTMLDetailsElement, MouseEvent>) => {
    if (!collapsable) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleExpand = (event: React.SyntheticEvent<HTMLDetailsElement, Event>) => {
    const newState = event.currentTarget.open;
    setExpanded(newState);
    if (headerButton && !newState && headerButton.onCancel) {
      headerButton.onCancel();
    }
  };

  return (
    <div className="data-table rounded-md bg-white shadow">
      <details
        className={classNames({ 'collapse collapse-arrow': collapsable })}
        open={expanded}
        onClick={disableDetailsIfNotCollapsable}
        onToggle={handleExpand}
      >
        <summary className={classNames('border-b-2', { 'collapse-title': collapsable, 'block': !collapsable })}>
          <div className="flex items-center justify-between p-5">
            {title && (
              <span>
                <span className="font-bold">{title}</span>
                <div className="badge badge-ghost ml-2">{count}</div>
              </span>
            )}
            {headerComponent}
            {headerButton &&
              expanded &&
              (!headerButton.hide ? (
                <button
                  className="btn btn-primary ml-2"
                  type="button"
                  disabled={headerButton.loading}
                  onClick={headerButton.onClick}
                >
                  {headerButton.loading ? <Spinner /> : headerButton.label}
                </button>
              ) : (
                <button className="btn btn-error ml-2" type="button" onClick={headerButton.onCancel}>
                  {headerButton.cancelLabel ? headerButton.cancelLabel : <Icon icon="close" />}
                </button>
              ))}
          </div>
        </summary>

        <table className={classNames('table w-full', { 'collapse-content': collapsable })}>
          <colgroup>
            {columns.map((column) => (
              <col key={column.title} width={column.width} />
            ))}
          </colgroup>
          {count > 0 && (
            <thead className="bg-gray-100 uppercase text-gray-800">
              <tr>
                {columns.map((column) => (
                  <th key={column.title}>{column.title}</th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {count === 0 && (
              <tr>
                <td colSpan={columns.length} className="rounded-b-md bg-base-200 py-5 text-center">
                  <Icon icon="empty-set" className="size text-xl text-primary" />

                  <div className="text-gray-500">{emptyPlaceholder || translate('generic-empty-placeholder')}</div>
                </td>
              </tr>
            )}

            {children}
          </tbody>
        </table>
      </details>
    </div>
  );
};

export default DataTable;
