import { get } from 'lodash';
import { formatUnixToFullDateAndTime } from '../../../../shared/utils/dateTime/dateTimeUtils';
import TimeSyncedWithActStartDate from '../../../components/Form/ActSpecificField/TimeSyncedWithActStartTime/TimeSyncedWithActStartTime';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { GENDER_LIST } from '../../../components/Form/constants';

const summaryRender = (code) => [
  {
    name: 'sexe',
    value: get(
      GENDER_LIST.find((gender) => gender.value === code.gender),
      'label',
      '-'
    )
  },
  {
    name: 'naissance',
    value: code.birthTime && formatUnixToFullDateAndTime(code.birthTime)
  }
];

const associatedFields = [
  {
    getName: (index) => `codes[${index}].gender`,
    type: SingleSelectCheckboxGroup,
    list: GENDER_LIST
  },
  {
    label: 'Heure de naissance',
    getName: (index) => `codes[${index}].birthTime`,
    type: TimeSyncedWithActStartDate
  }
];

const sexeBebe = { associatedFields, summaryRender };

export default sexeBebe;
