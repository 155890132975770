import ActCodeAccordingToAgeCollection from '../../../domain/activity/act/ActCodeAccordingToAgeCollection';
import Range from '../../../utils/Range/Range';

export default [
  new ActCodeAccordingToAgeCollection([
    { code: '20127', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '9305', ageRange: new Range.Builder().greaterThanOrEqual(2).lessThanOrEqual(16).build() },
    { code: '9306', ageRange: new Range.Builder().greaterThan(16).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '789', ageRange: new Range.Builder().lessThan(16).build() },
    { code: '790', ageRange: new Range.Builder().greaterThanOrEqual(16).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '902', ageRange: new Range.Builder().lessThan(16).build() },
    { code: '903', ageRange: new Range.Builder().greaterThanOrEqual(16).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '993', ageRange: new Range.Builder().lessThan(16).build() },
    { code: '994', ageRange: new Range.Builder().greaterThanOrEqual(16).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '233', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '129', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '886', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '133', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '888', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '135', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '621', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '141', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '408', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '142', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '619', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '139', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '409', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '143', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '883', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '130', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '884', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '131', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '885', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '132', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '887', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '134', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '488', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '294', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '219', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '126', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '191', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '102', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '690', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '685', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '705', ageRange: new Range.Builder().lessThan(2).build() },
    { code: '693', ageRange: new Range.Builder().greaterThanOrEqual(2).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '15411', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '15412', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '15414', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '15415', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '15422', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '15423', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '15426', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '15427', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '15710', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '15711', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '249', ageRange: new Range.Builder().lessThan(14).build() },
    { code: '234', ageRange: new Range.Builder().greaterThanOrEqual(14).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '282', ageRange: new Range.Builder().lessThan(14).build() },
    { code: '281', ageRange: new Range.Builder().greaterThanOrEqual(14).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '20140', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '9382', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '20141', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '9383', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '595', ageRange: new Range.Builder().lessThan(14).build() },
    { code: '593', ageRange: new Range.Builder().greaterThanOrEqual(14).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '789', ageRange: new Range.Builder().lessThan(16).build() },
    { code: '790', ageRange: new Range.Builder().greaterThanOrEqual(16).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '20165', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '771', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '20166', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '773', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '20167', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '774', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '20168', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '775', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '20169', ageRange: new Range.Builder().lessThan(18).build() },
    { code: '20172', ageRange: new Range.Builder().greaterThanOrEqual(18).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '7106', ageRange: new Range.Builder().lessThan(6).build() },
    { code: '7331', ageRange: new Range.Builder().greaterThanOrEqual(6).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '7228', ageRange: new Range.Builder().lessThan(16).build() },
    { code: '7227', ageRange: new Range.Builder().greaterThan(16).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '15486', ageRange: new Range.Builder().greaterThanOrEqual(14).build() },
    { code: '15487', ageRange: new Range.Builder().lessThan(14).build() }
  ]),

  new ActCodeAccordingToAgeCollection([
    { code: '15601', ageRange: new Range.Builder().greaterThanOrEqual(14).build() },
    { code: '15602', ageRange: new Range.Builder().lessThan(14).build() }
  ])
];
