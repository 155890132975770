import actions from 'redux-form/lib/actions';
import PERIOD_FORM_NAME from '../PeriodsBilling/constants';
import MANUAL_EDITION_MODE from './constants';

export const SAVE_MIXTE = 'saveMixte';
export const saveMixte = (document) => ({
  type: SAVE_MIXTE,
  document
});

export const SAVE_MIXTE_FAILURE = 'saveMixteFailure';
export const saveMixteFailure = (error) => ({
  type: SAVE_MIXTE_FAILURE,
  error
});

export const toggleManualMixteEditionForSpecificDay = (fieldName) =>
  actions.change(PERIOD_FORM_NAME, `${fieldName}.${MANUAL_EDITION_MODE}`, true);
