import React from 'react';
import Icon from 'application/components/icon';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  title: string;
  className?: string;
  icon?: string;
  theme?: 'primary' | 'error' | 'success' | 'warning';
}

const Card = ({ children, title, icon, theme, className }: Props) => (
  <div
    className={classNames(
      'card bg-base-100 shadow',
      {
        'border-primary': theme === 'primary',
        'border-error': theme === 'error',
        'border-success': theme === 'success',
        'border-warning': theme === 'warning',
        'border': !!theme
      },
      className
    )}
  >
    <div className="card-body">
      <h2
        className={classNames('card-title', {
          'text-primary': theme === 'primary',
          'text-error': theme === 'error',
          'text-success': theme === 'success',
          'text-warning': theme === 'warning'
        })}
      >
        {icon && <Icon icon={icon} className="mr-2" />}
        {title}
      </h2>

      {children}
    </div>
  </div>
);

const CardActions = ({ children }: { children: React.ReactNode }) => (
  <div className="card-actions justify-end border-t-2 bg-base-200">{children}</div>
);

export default Object.assign(Card, { Actions: CardActions });
