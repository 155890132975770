import { configureStore as createStore, combineReducers, Tuple } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import actReducer from '../containers/Act/reducer';
import actSaga from '../containers/Act/sagas';
import actFormReducer from '../containers/ActForm/reducer';
import actFormSagas from '../containers/ActForm/sagas';
import activitiesReducer from '../containers/Activities/reducer';
import activitiesSaga from '../containers/Activities/sagas';
import activitiesSearchReducer from '../containers/ActivitiesSearch/reducer';
import searchActivitiesSaga from '../containers/ActivitiesSearch/sagas';
import appSaga from '../containers/App/sagas';
import assistantReducer from '../containers/Assistant/reducer';
import assistantSaga from '../containers/Assistant/sagas';
import asyncTasksReducer from '../containers/AsyncTasks/reducer';
import authenticationReducer from '../containers/Authentication/reducer';
import authenticationSaga from '../containers/Authentication/sagas';
import lumpSumReducer from '../containers/LumpSum/reducer';
import lumpSumsaga from '../containers/LumpSum/sagas';
import mixtesReducer from '../containers/Mixte/reducer';
import mixtesSaga from '../containers/Mixte/sagas';
import pdfExtractionReducer from '../containers/PDF/reducer';
import pdfSaga from '../containers/PDF/sagas';
import periodsSaga from '../containers/PeriodsBilling/sagas';
import ramqBillSaga from '../containers/Ramq/sagas';
import ramqCallLogsReducer from '../containers/RamqCallLog/reducer';
import ramqSaga from '../containers/RamqCallLog/sagas';
import ramqPlacesReducer from '../containers/RamqPlaces/reducer';
import ramqPlacesSagas from '../containers/RamqPlaces/sagas';
import reduxFormReducer from '../containers/ReduxForm/reducer';
import statementReducer from '../containers/ReportsRoute/Statement/reducer';
import searchSuggestionsReducer from '../containers/SearchSuggestions/reducer';
import searchSuggestionsSaga from '../containers/SearchSuggestions/sagas';
import storageReducer from '../containers/Storage/reducer';
import uploadSaga from '../containers/Storage/sagas';
import travelExpensesReducer from '../containers/TravelExpenses/reducer';
import travelExpensesSaga from '../containers/TravelExpenses/sagas';
import userReducer from '../containers/User/reducer';
import userSaga from '../containers/User/sagas';
import usersReducer from '../containers/UsersRoute/reducer';
import usersSaga from '../containers/UsersRoute/sagas';
import trainingsReducer from '../training/adapters/store/reducer';
import trainingsSaga from '../training/adapters/store/sagas';
import doctorsPreferencesSagas from '../doctorsRegistry/adapters/sagas';
import multiActFormReducer from '../act/multiAct/adapters/reducer';
import multiActUploadFileSagas from '../act/multiAct/adapters/fileUpload/sagas/fileUpload';
import multiActCreateActsFromNamsSagas from '../act/multiAct/adapters/acts/sagas/createActsFromNams';
import periodsFileUploadSaga from '../containers/PeriodsBilling/PeriodForm/store/sagas';
import intercomSaga from '../containers/Intercom/sagas';
import intercomReducer from '../containers/Intercom/reducer';
import { watchRequestsForMixteCreation } from '../mixte/generation/watcher/sagas';

const sagaMiddleware = createSagaMiddleware();

export type Store = any;

let store: Store;

export const getStore = () => store;

export default function configureStore(initialState) {
  const reducers = combineReducers({
    authentication: authenticationReducer,
    actForm: actFormReducer,
    act: actReducer,
    users: usersReducer,
    form: reduxFormReducer,
    user: userReducer,
    statement: statementReducer,
    activitiesSearch: activitiesSearchReducer,
    storage: storageReducer,
    searchSuggestions: searchSuggestionsReducer,
    mixtes: mixtesReducer,
    lumpSum: lumpSumReducer,
    activities: activitiesReducer,
    trainings: trainingsReducer,
    assistant: assistantReducer,
    pdfExtraction: pdfExtractionReducer,
    ramqCallLogs: ramqCallLogsReducer,
    asyncTasks: asyncTasksReducer,
    travelExpenses: travelExpensesReducer,
    multiActForm: multiActFormReducer,
    ramqPlaces: ramqPlacesReducer,
    intercom: intercomReducer
  });

  store = createStore({
    reducer: reducers,
    preloadedState: initialState,
    middleware: () => new Tuple(sagaMiddleware)
  });

  store.runSaga = sagaMiddleware.run;
  store.runSaga(appSaga);
  store.runSaga(authenticationSaga);
  store.runSaga(actFormSagas);
  store.runSaga(actSaga);
  store.runSaga(usersSaga);
  store.runSaga(userSaga);
  store.runSaga(doctorsPreferencesSagas);
  store.runSaga(uploadSaga);
  store.runSaga(searchSuggestionsSaga);
  store.runSaga(mixtesSaga);
  store.runSaga(lumpSumsaga);
  store.runSaga(activitiesSaga);
  store.runSaga(assistantSaga);
  store.runSaga(pdfSaga);
  store.runSaga(ramqSaga);
  store.runSaga(periodsSaga);
  store.runSaga(periodsFileUploadSaga);
  store.runSaga(travelExpensesSaga);
  store.runSaga(trainingsSaga);
  store.runSaga(ramqBillSaga);
  store.runSaga(searchActivitiesSaga);
  store.runSaga(multiActUploadFileSagas);
  store.runSaga(multiActCreateActsFromNamsSagas);
  store.runSaga(ramqPlacesSagas);
  store.runSaga(intercomSaga);
  store.runSaga(watchRequestsForMixteCreation);

  return store;
}
