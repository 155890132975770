import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { FormattedMessage } from 'react-intl';
import AttachFile from '../Attachments/AttachFile/AttachFile';
import AttachmentsList from '../Attachments/AttachmentsList/AttachmentsList';
import { NoteField } from '../../components/Form/Fields';
import FormElement from '../../components/Form/FormElement/FormElement';
import FormRow from '../../components/Form/FormRow/FormRow';
import OnTopBottomNavigationPortal from '../../components/Portals/OnTopBottomNavigationPortal/OnTopBottomNavigationPortal';
import { scrollToInvalidField } from '../../validations/common/reduxFormValidation';
import CorrectionDisplay from '../ActForm/CorrectionDisplay/CorrectionDisplay';
import {
  GLOBAL_POSSIBLE_CONTEXT_ELEMENTS,
  TRAVEL_EXPENSE_ATTACHMENT,
  TRAVEL_EXPENSES_ATTACHMENTS_LIST_FIELD,
  TRAVEL_EXPENSES_FORM_NAME,
  TRAVEL_EXPENSES_UPLOAD_ATTACHMENT_FIELD
} from './constants';
import RestrictedAdditionalTransportContextElement from './RestrictedAdditionalTransportContextElement';
import { selectEndDate, selectStartDate } from './selectors';
import TravelDirectionSelector from './TravelDirectionSelector';
import TravelExpenseSectionHeader from './TravelExpenseSectionHeader';
import TravelExpensesFees from './TravelExpensesFees';
import TravelInformationBox from './TravelInformationBox';
import { travelExpenseFileReadyForUpload } from './actions';
import { actFormAdminFields } from '../ActForm/constants';
import CashAmountField from '../../components/Form/custom/CashAmountField';

export const styles = (theme) => ({
  card: {
    padding: theme.spacing(1),
    width: '100%',
    maxWidth: 600,
    boxSizing: 'border-box'
  },
  wrapper: {
    marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(1)
  },
  submitButtonGroup: {
    display: 'flex',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  paddedField: {
    padding: theme.spacing(1)
  }
});

export class TravelExpensesForm extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.travelExpenses.id !== this.props.travelExpenses.id;
  }

  componentDidUpdate() {
    this.props.initialize(this.props.travelExpenses);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.initialize(this.props.travelExpenses);
  }

  render() {
    const { travelExpenses, handleSubmit, onSave, onSent, onWait, classes } = this.props;

    return (
      <Fade in>
        <div className={classes.card}>
          <FormElement name="corrections" component={CorrectionDisplay} status={travelExpenses.status} />

          <TravelDirectionSelector />

          <RestrictedAdditionalTransportContextElement
            label="E. Contexte (ensemble de la facture)"
            list={GLOBAL_POSSIBLE_CONTEXT_ELEMENTS}
          />

          <TravelInformationBox
            label={<FormattedMessage id="travel-expenses.departure" />}
            reduxFormDateName="startDate"
            reduxFormTimeName="startTime"
            reduxFormPlaceName="startLocation"
            dateSelector={selectStartDate}
            travelExpenses={travelExpenses}
          />

          <TravelInformationBox
            label={<FormattedMessage id="travel-expenses.arrival" />}
            reduxFormDateName="endDate"
            reduxFormTimeName="endTime"
            reduxFormPlaceName="endLocation"
            dateSelector={selectEndDate}
            travelExpenses={travelExpenses}
          />

          <Paper className={classes.wrapper}>
            <TravelExpenseSectionHeader label={<FormattedMessage id="travel-expenses.list-of-fees" />} />
            <div className={classes.content}>
              <FieldArray name="fees" component={TravelExpensesFees} />
            </div>
          </Paper>

          <Paper className={classes.wrapper}>
            <FormRow>
              <FormElement className={classes.paddedField} name="note" label="Note" fullWidth component={NoteField} />
              <AttachFile
                attachmentType={TRAVEL_EXPENSE_ATTACHMENT}
                documentId={travelExpenses.id}
                fieldName={TRAVEL_EXPENSES_UPLOAD_ATTACHMENT_FIELD}
                fileReadyForUploadHandler={this.props.travelExpenseFileReadyForUpload}
              />
            </FormRow>
            <FormRow className={classes.content}>
              <FieldArray name={TRAVEL_EXPENSES_ATTACHMENTS_LIST_FIELD} component={AttachmentsList} />
            </FormRow>
          </Paper>

          <Paper className={classes.wrapper}>
            <Restrict atLeastRole={Role.Agent}>
              <FormRow className={classes.content}>
                <FormElement
                  label={<FormattedMessage id="travel-expenses.amount-billed" />}
                  name={actFormAdminFields.manualCorrection}
                  component={CashAmountField}
                />
              </FormRow>
            </Restrict>
          </Paper>

          <OnTopBottomNavigationPortal>
            <div className={classes.submitButtonGroup}>
              <Button id="wait-travel-expenses-button" onClick={handleSubmit(onWait)} variant="contained">
                <FormattedMessage id="act-form.wait" />
              </Button>
              <Button
                id="send-travel-expenses-button"
                onClick={handleSubmit(onSent)}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="act-form.send" />
              </Button>
              <Restrict atLeastRole={Role.Agent}>
                <Button id="save-act-button" onClick={handleSubmit(onSave)}>
                  <FormattedMessage id="global.save" />
                </Button>
              </Restrict>
            </div>
          </OnTopBottomNavigationPortal>
        </div>
      </Fade>
    );
  }
}

TravelExpensesForm.propTypes = {
  travelExpenseFileReadyForUpload: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSent: PropTypes.func.isRequired,
  onWait: PropTypes.func.isRequired,
  travelExpenses: PropTypes.object.isRequired
};

export const mapDispatchToProps = {
  travelExpenseFileReadyForUpload
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: TRAVEL_EXPENSES_FORM_NAME,
    onSubmitFail: scrollToInvalidField
  }),
  connect(null, mapDispatchToProps)
)(TravelExpensesForm);
