import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { selectUserBillingType } from 'app/containers/User/selectors';
import { useSelector } from 'react-redux';
import BillingType from 'shared/domain/billing/model/BillingType';
import ActivityType from '../../../../../../shared/core/activity/domain/ActivityType';
import ActivityStatus from '../../../../../../shared/core/activity/domain/ActivityStatus';
import { computeExpectedAmountForActivities } from '../../../../../../shared/amountComputation/computeExpectedAmount';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import messages from './messages';
import FilterActsOnBillingType from './FilterActsOnBillingType';

export const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 0,
    height: 20,
    display: 'flex'
  },
  subContainer: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    maxWidth: 800,
    justifyContent: 'center',
    alignItems: 'center'
  },
  totalAmountLine: {},
  activityCount: {
    position: 'absolute',
    right: 10
  }
});

export const style = {};
export function TotalAmountLine({ activities, classes, onActivityFilterChange, currentFilter }) {
  const userProfileBillingType = useSelector(selectUserBillingType());

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        {userProfileBillingType === BillingType.POOL && (
          <FilterActsOnBillingType onActivityFilterChange={onActivityFilterChange} currentFilter={currentFilter} />
        )}
        <div className={classes.totalAmountLine}>
          <Typography key="expectedAmountForActivites">
            {currencyFormat(computeExpectedAmountForActivities(activities))}
          </Typography>
        </div>
        <div className={classes.activityCount}>
          <Typography key="activitiesCount">
            <FormattedMessage
              {...messages.activityCount}
              values={{
                number: activities.filter(
                  (act) => act.status !== ActivityStatus.CANCELED && act.type === ActivityType.ACT
                ).length
              }}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
}

TotalAmountLine.propTypes = {
  activities: PropTypes.array.isRequired,
  onActivityFilterChange: PropTypes.func.isRequired,
  currentFilter: PropTypes.string
};

export default compose(withStyles(styles))(TotalAmountLine);
