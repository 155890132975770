import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FieldArray } from 'redux-form';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import { NoteField, SingleLineField } from '../../../../components/Form/Fields';
import AbsenceFields from '../../../../components/Form/AbsenceFields/AbsenceFields';

export interface Props {}

export const AdditionalInformations: React.FunctionComponent<Props> = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
      <Typography gutterBottom variant="h6">
        Informations supplémentaires
      </Typography>
    </Grid>

    <Grid item xs={12} sm={12}>
      <FormElement name="referrer" label="Référé(e) par" component={SingleLineField} />
    </Grid>

    <Grid item xs={12} sm={12}>
      {/* @ts-ignore */}
      <FieldArray name="absences" label="absences" component={AbsenceFields} />
    </Grid>

    <Grid item xs={12} sm={12}>
      <FormElement name="note" label="Note" component={NoteField} />
    </Grid>
  </Grid>
);
