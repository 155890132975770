import Collapse from '@material-ui/core/Collapse';
import withStyles, { StyledComponentProps, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import React, { useState } from 'react';
import { FieldArray } from 'redux-form';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import useTranslate from 'application/hooks/use-translate';
import CollapsableIcon from '../../../../../../components/CollapsableIcon/CollapsableIcon';
import MixteDayUnitDetailCodes from '../MixteDayUnitDetailCodes/MixteDayUnitDetailCodes';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../../../../shared/periods/mixte/constants';

export const styles: StyleRulesCallback<Theme, Props> = ({ spacing }) => ({
  collapseWrapper: {
    width: '100%'
  },
  splitPerdiemsMessage: {
    textAlign: 'center',
    fontSize: '0.75rem',
    marginTop: spacing(1)
  }
});

export interface Props extends StyledComponentProps {
  dayFieldName: string;
  canAddCode: boolean;
  errorContainer: any;
  splitPerdiemHours: number | undefined;
}

export const MixteDayUnitDetail: React.FunctionComponent<Props> = ({
  dayFieldName,
  canAddCode,
  errorContainer,
  splitPerdiemHours,
  classes
}) => {
  const translate = useTranslate();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <CollapsableIcon
        onClick={() => setOpen(!open)}
        selected={false}
        label={translate('Navigation.pool.details')}
        expanded={open}
      />
      <Collapse in={open} className={classes!.collapseWrapper}>
        <FieldArray
          rerenderOnEveryChange
          name={`${dayFieldName}.codes`}
          canAddCode={canAddCode}
          errorContainer={errorContainer}
          component={MixteDayUnitDetailCodes}
        />

        {!!splitPerdiemHours && (
          <Typography data-testid="split-perdiems-message" color="primary" className={classes!.splitPerdiemsMessage}>
            {splitPerdiemHours[AM_PERDIEM]}
            {translate('mixed-billing-form.hours-billed-am')} &nbsp;
            {splitPerdiemHours[PM_PERDIEM]}
            {translate('mixed-billing-form.hours-billed-pm')}
          </Typography>
        )}
      </Collapse>
    </>
  );
};

export default withStyles(styles)(MixteDayUnitDetail);
