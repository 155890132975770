import { trim } from 'lodash';

const add2DecimalToStringNumber = (string) => {
  if (string.includes('.')) {
    return string;
  }
  return `${string.substring(0, string.length - 2)}.${string.substring(string.length - 2)}`;
};

export const amountFormat = (string) => {
  if (string === '') {
    return '';
  }

  return parseFloat(add2DecimalToStringNumber(string)).toFixed(2);
};

export const trimStringForSpacesAndLeadingZeroes = (string) => trim(string).replace(/^0+/, '');
