import { find, get, isArray, set } from 'lodash';
import { isSpecialtySurgeon } from 'shared/domain/doctor/Doctor';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import MEASUREMENT_ELEMENTS from '../../../../shared/ramq/requiredDetails/measurementElements';
import isDefined from '../../../../shared/utils/isDefined';
import { MeasurementElementsField } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import { measurementElementsShouldBeHandled } from '../../ui/rules/measurementElements';
import { isMeasurementElementsEffective } from '../../../../shared/domain/activity/act/MeasurementElement';

export const MEASUREMENT_ELEMENTS_ERROR = 'Veuillez remplir tous les éléments de mesure';
const missingMeasurementElementError = (index) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${MEASUREMENT_ELEMENTS}` },
    `codes[${index}].${MEASUREMENT_ELEMENTS}`,
    MEASUREMENT_ELEMENTS_ERROR
  );

export const makeSureEveryMeasurementElementsIsFilled = (act) => {
  const userSpecialty = selectSpecialty()(getStore().getState());
  if (!isSpecialtySurgeon(userSpecialty)) {
    return {};
  }

  const isMeasurementElementUndefinedAndEffective = ({ effectiveStartDate, effectiveEndDate, value }) =>
    !isDefined(value) && isMeasurementElementsEffective(act.date, effectiveStartDate, effectiveEndDate);

  const codesInError = act.codes.map((code, index) => {
    if (measurementElementsShouldBeHandled(code)) {
      if (find(code.measurementElements, isMeasurementElementUndefinedAndEffective)) {
        return missingMeasurementElementError(index);
      }
    }
    return {};
  });
  return codesInError.reduce(combineErrors, {});
};

const displayMeasurement = (measurmentElement) => ({
  name: get(measurmentElement, 'name', '-'),
  value: get(measurmentElement, 'value', '-')
});

const summaryRender = (code) =>
  isArray(code[MEASUREMENT_ELEMENTS]) ? code[MEASUREMENT_ELEMENTS].map(displayMeasurement) : [];

const associatedFields = (act) => [
  {
    getName: (index) => `codes[${index}].${MEASUREMENT_ELEMENTS}`,
    label: 'Éléments de mesure',
    type: MeasurementElementsField,
    props: {
      act
    }
  }
];

const associatedValidations = [makeSureEveryMeasurementElementsIsFilled];

export default { associatedFields, associatedValidations, summaryRender };
