import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { DirectoryPhysicianType } from 'app/containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import DialogCenteredTitle from '../../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import SwipeableTabs from '../../../../components/SwipeableTabs/SwipeableTabs';
import DoctorFavoritesTab from '../../components/DoctorFavoritesTab/DoctorFavoritesTab';
import DoctorsRegistrySearchSuggestions from '../../components/DoctorsRegistrySearchSuggestions/DoctorsRegistrySearchSuggestions';

export const styles = (theme) => ({
  paper: {
    minHeight: '90vh'
  },
  childContainer: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  },
  slideChildContainer: {
    'minHeight': '85vh',
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  },
  suggestionList: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch'
  }
});

export function DoctorDialogWithDoctorRegistry({
  fullScreen,
  open,
  classes,
  onItemSelected,
  onClose,
  value,
  addToFavorites,
  onAdvancedSave,
  type,
  preferences,
  ...rest
}) {
  const swipeableViewsProps = { containerStyle: { height: '100%' }, slideStyle: { overflow: 'hidden' } };

  return (
    <Dialog
      id="doctors-dialog"
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <DialogCenteredTitle
        label={<FormattedMessage id={type === DirectoryPhysicianType.RESIDENT ? 'act.resident' : 'act.doctor'} />}
      />

      <SwipeableTabs
        swipeableViewsProps={swipeableViewsProps}
        tabs={[
          {
            key: 'favorites',
            icon: <FavoriteIcon />,
            component: (
              <div className={classes.slideChildContainer}>
                <DoctorFavoritesTab
                  onItemSelected={onItemSelected}
                  onClose={onClose}
                  value={value}
                  doctorsPreferences={preferences}
                  onAdvancedSave={onAdvancedSave}
                  type={type}
                  {...rest}
                />
              </div>
            )
          },
          {
            key: 'search',
            icon: <SearchIcon />,
            component: (
              <div className={classes.slideChildContainer}>
                <DoctorsRegistrySearchSuggestions
                  doctorsPreferences={preferences}
                  onItemSelected={onItemSelected}
                  onClickSuggestion={(doctor) => {
                    onItemSelected(doctor);
                    onClose();
                  }}
                  onClickFavorite={addToFavorites}
                  onClose={onClose}
                  type={type}
                  {...rest}
                />
              </div>
            )
          }
        ]}
      />
    </Dialog>
  );
}

DoctorDialogWithDoctorRegistry.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([''])]),
  addToFavorites: PropTypes.func.isRequired,
  type: PropTypes.string,
  preferences: PropTypes.array.isRequired,
  onAdvancedSave: PropTypes.func.isRequired
};

DoctorDialogWithDoctorRegistry.defaultProps = {
  value: ''
};

export default compose(withMobileDialog(), withStyles(styles))(DoctorDialogWithDoctorRegistry);
