import React, { useCallback, useEffect, useState } from 'react';
import useTranslate from 'application/hooks/use-translate';
import Section from 'application/components/form-controls/section';
import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import PasswordInput from 'application/components/form-controls/password-input';
import DangerZone from 'application/components/danger-zone';
import AuthenticationServiceProvider from 'app/infrastructure/authentication/service/AuthenticationServiceProvider';
import { useSelector } from 'react-redux';
import useValidatedForm from 'application/hooks/use-validated-form';
import useToast from 'application/hooks/use-toast';

interface UserReduxState {
  user?: {
    profile?: { email?: string };
  };
}

const SettingsSecurityPage = () => {
  const translate = useTranslate('pages.settings.security');
  const userEmail = useSelector<UserReduxState, string>((state) => state.user?.profile?.email ?? '');

  const { isValid: formIsValid, registerField, reset: resetValidations } = useValidatedForm();

  const [currentPasswordIsInvalid, setCurrentPasswordIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { addToast } = useToast();

  useEffect(() => setCurrentPasswordIsInvalid(false), [currentPassword]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (newPassword !== confirmPassword) {
      return;
    }

    try {
      setIsLoading(true);
      const authService = AuthenticationServiceProvider.getInstance();
      await authService.signIn(userEmail, currentPassword);
      await authService.updatePassword(userEmail, newPassword);

      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      resetValidations();

      addToast({
        autoDismiss: true,
        message: translate('saved-success'),
        type: 'success'
      });
    } catch (_error) {
      setCurrentPasswordIsInvalid(true);

      addToast({
        autoDismiss: true,
        message: translate('saved-error'),
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogoutAllDevices = useCallback(() => {
    AuthenticationServiceProvider.getInstance()
      .logoutFromAllDevices()
      .catch(() => {
        AuthenticationServiceProvider.getInstance().signOut();
      });
  }, []);

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <Section label={translate('title')}>
        <FieldContainer label={translate('current-password')} required>
          <PasswordInput
            {...registerField('currentPassword')}
            customValidation={
              currentPasswordIsInvalid
                ? { isValid: false, errorMessage: translate('invalid-current-password') }
                : undefined
            }
            value={currentPassword}
            onChange={setCurrentPassword}
            required
          />
        </FieldContainer>
        <FieldContainer label={translate('new-password')} required>
          <PasswordInput
            {...registerField('newPassword')}
            value={newPassword}
            onChange={setNewPassword}
            validateStrenght
            required
          />
        </FieldContainer>
        <FieldContainer label={translate('confirm-password')} required>
          <PasswordInput
            {...registerField('confirmPassword')}
            customValidation={
              confirmPassword
                ? {
                    isValid: newPassword === confirmPassword,
                    errorMessage: translate('invalid-confirm-password')
                  }
                : undefined
            }
            value={confirmPassword}
            onChange={setConfirmPassword}
            required
          />
        </FieldContainer>
        <Section.Footer>
          <Button type="submit" disabled={!formIsValid} loading={isLoading} className="btn btn-primary">
            {translate('save')}
          </Button>
        </Section.Footer>
      </Section>
      <DangerZone
        label={translate('logout-all-devices.label')}
        helper={translate('logout-all-devices.helper')}
        buttonLabel={translate('logout-all-devices.logout')}
        onClick={handleLogoutAllDevices}
        icon="shield-check"
      />
    </form>
  );
};

export default SettingsSecurityPage;
