import { selectCurrentLoggedUser } from 'app/containers/Authentication/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { isUserAtLeast } from 'application/utilities/authentication';
import Role from 'shared/domain/authentication/Role';
import PATIENT_WITHOUT_ID_TYPES from 'shared/ramq/domainValues/situationTypes';

const NO_ERRORS = {};

interface Activity {
  patientInformation?: {
    type?: string;
    urgent?: {
      disclaimerAccepted?: boolean;
    };
  };
}

export const patientNeedingUrgentCareDisclaimerIsAccepted = (activity: Activity) => {
  if (activity.patientInformation?.type !== PATIENT_WITHOUT_ID_TYPES.patientNeedingUrgentCare) {
    return NO_ERRORS;
  }

  if (activity.patientInformation?.urgent?.disclaimerAccepted) {
    return NO_ERRORS;
  }

  const loggedUser = selectCurrentLoggedUser()(getStore().getState());

  if (isUserAtLeast(loggedUser.role, Role.Agent)) {
    return NO_ERRORS;
  }

  return {
    patientInformation: {
      urgent: {
        disclaimerAccepted: "Vous devez accepter l'avertissement pour continuer."
      }
    }
  };
};
