import {
  SAVE_TRAVEL_EXPENSES,
  SAVE_TRAVEL_EXPENSES_FAILURE,
  UPDATE_TRAVEL_EXPENSES,
  UPDATE_TRAVEL_EXPENSES_FAILURE
} from './actions';

export const initialState = {
  saveTravelExpensesError: undefined,
  updateTravelExpensesError: undefined
};

function travelExpensesReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_TRAVEL_EXPENSES:
      return {
        ...state,
        saveTravelExpensesError: undefined
      };
    case SAVE_TRAVEL_EXPENSES_FAILURE:
      return {
        ...state,
        saveTravelExpensesError: action.error
      };
    case UPDATE_TRAVEL_EXPENSES:
      return {
        ...state,
        updateTravelExpensesError: undefined
      };
    case UPDATE_TRAVEL_EXPENSES_FAILURE:
      return {
        ...state,
        updateTravelExpensesError: action.error
      };
    default:
      return state;
  }
}

export default travelExpensesReducer;
