import isDefined from '../../../../../shared/utils/isDefined';
import MIXTE_DISPENSARY_AREA_MAP from '../../../../../shared/ramq/domainValues/mixteDispensaryAreas';
import { RAMQPlaces } from '../../../../../shared/ramq/RamqPlaces';

export const LABOR_DROP_ACT_CODES = ['42189', '42192'];

export const isLaborDropActCode = ({ code }: { code: string }) => LABOR_DROP_ACT_CODES.includes(code);

export const getLaborDropActRelatedMixteSuffix = (actCode: any, place: any) => {
  let mixteSuffix;

  if (actCode.telemedicineOrRemote && RAMQPlaces.isCms(place?.number)) {
    mixteSuffix = '378';
  }

  if (actCode.canceledActivities) {
    mixteSuffix = '368';
  }

  if (isDefined(actCode.particularSituation)) {
    if (actCode.particularSituation === 'infected') {
      mixteSuffix = '374';
    } else if (actCode.particularSituation === 'quarantine') {
      mixteSuffix = '376';
    } else if (actCode.telemedicineOrRemote) {
      mixteSuffix = '373';
    } else {
      mixteSuffix = '379';
    }
  }

  return mixteSuffix;
};

export const getLaborDropActRelatedMixteDispensaryArea = (actCode: any) => {
  let dispensaryArea;

  if (actCode.telemedicineOrRemote && !isDefined(actCode.particularSituation)) {
    dispensaryArea = MIXTE_DISPENSARY_AREA_MAP.externalClinic;
  }

  return dispensaryArea;
};
