import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import useTranslate from 'application/hooks/use-translate';
import CashAmountField from '../../components/Form/custom/CashAmountField';
import { SelectorField } from '../../components/Form/Fields';
import SpacedFormElement from '../../components/Form/SpacedFormElement/SpacedFormElement';
import AddAdditionalTransportFeeButton from './AddAdditionalTransportFeeButton';
import { TRANSPORT_FEES_LIST } from './constants';

const styles = (theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2)
  },
  item: {
    position: 'relative'
  },
  removeIcon: {
    position: 'absolute',
    right: -theme.spacing(4),
    top: theme.spacing(1)
  }
});

function AdditionalTransportFees({ fields: reduxFormFields, meta, classes }) {
  const translate = useTranslate();
  return (
    <div id={reduxFormFields.name} className={classes.wrapper}>
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
      {reduxFormFields.length > 0 && (
        <>
          <Typography align="center">{translate('travel-expenses.additional-fee')}</Typography>
          <Divider />
          <Grid container className={classes.container}>
            {reduxFormFields.map((fieldName, fieldIndex) => (
              <Fragment key={fieldName}>
                <Grid item xs={6} sm={6}>
                  <SpacedFormElement
                    name={`${fieldName}.type`}
                    label={translate('travel-expenses.type-of-fee')}
                    component={SelectorField}
                    formControlProps={{ fullWidth: true }}
                    list={TRANSPORT_FEES_LIST}
                  />
                </Grid>
                <Grid item xs={6} sm={6} className={classes.item}>
                  <SpacedFormElement
                    name={`${fieldName}.amount`}
                    label={translate('travel-expenses.amount')}
                    component={CashAmountField}
                  />
                  <IconButton className={classes.removeIcon} onClick={() => reduxFormFields.remove(fieldIndex)}>
                    <RemoveIcon />
                  </IconButton>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </>
      )}
      <AddAdditionalTransportFeeButton reduxFormFields={reduxFormFields} />
    </div>
  );
}

AdditionalTransportFees.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default withStyles(styles)(AdditionalTransportFees);
