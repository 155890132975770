import { get } from 'lodash';
import { PERIOD_FORM_NAME } from './constants';

export const selectActivityPeriodId = () => (state) =>
  get(state, ['form', PERIOD_FORM_NAME, 'values', 'id'], undefined);

export const selectAttachments = () => (state) => {
  const periodFormValues = get(state, ['form', PERIOD_FORM_NAME, 'values'], undefined);
  if (periodFormValues) {
    return periodFormValues.attachments ?? [];
  }

  return [];
};

export const selectSelectedPeriodPlace = () => (state) => {
  const periodFormValues = get(state, ['form', PERIOD_FORM_NAME, 'values'], undefined);
  if (periodFormValues) {
    return periodFormValues.place ?? {};
  }

  return undefined;
};
