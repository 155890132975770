import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToFullDateAndTime } from '../../../../../shared/utils/dateTime/dateTimeUtils';

export function Signature({ author, date }) {
  return (
    <div>
      <Typography style={{ display: 'inline' }} color="textSecondary">
        {`${formatUnixToFullDateAndTime(date)} - `}
      </Typography>

      <Typography style={{ display: 'inline', fontWeight: 'bold' }}>{author}</Typography>
    </div>
  );
}

Signature.propTypes = {
  date: PropTypes.number.isRequired,
  author: PropTypes.string.isRequired
};

export default Signature;
