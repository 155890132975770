import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'application/hooks/use-translate';
import { DefaultBillingType } from 'application/hooks/use-firestore-user-profile';
import { useDispatch } from 'react-redux';
import { removeKey } from 'app/containers/ReduxForm/actions';
import { MenuItem, Select } from '@material-ui/core';
import BasicField from 'app/components/Form/BasicField';
import { gql, useMutation } from '@apollo/client';
import useToastApolloError from 'application/hooks/use-toast-apollo-error';
import useToast from 'application/hooks/use-toast';
import { SelectorField, SingleLineField } from '../../../../components/Form/Fields';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import ramqEnvironment from '../../../../../shared/ramq/environments';
import UserBillingType, { isPoolBillingType } from '../../../../../shared/domain/user/UserBillingType';
import Role from '../../../../../shared/domain/authentication/Role';

const roleList = map(Role, (value) => ({ label: <FormattedMessage id={`role.${value}`} />, value }));

export const billingTypeList = [
  { value: UserBillingType.PRIVATE, label: 'Privée' },
  { value: UserBillingType.POOL, label: 'Pool' }
];

export const ramqEnvironmentList = [
  {
    value: ramqEnvironment.production,
    key: ramqEnvironment.production,
    label: 'Production'
  },
  {
    value: ramqEnvironment.partenaire,
    key: ramqEnvironment.partenaire,
    label: 'Partenaire'
  }
];

const UPDATE_ROLE_MUTATION = gql`
  mutation UpdateUserRole($id: String!, $role: UserRole!) {
    updateUserRole(id: $id, role: $role) {
      id
    }
  }
`;

export interface Props {
  readonly: boolean;
  userId: string;
  userRole: Role;
  // eslint-disable-next-line no-unused-vars
  change: (field: string, value: any) => void;
  billingType: UserBillingType;
}

export const Configuration: React.FunctionComponent<Props> = ({ userRole, userId, readonly, change, billingType }) => {
  const translate = useTranslate('components.form-controls.default-billing-type-input');
  const dispatch = useDispatch();
  const toastApolloError = useToastApolloError();
  const { addToast } = useToast();

  const [updateUserRole] = useMutation(UPDATE_ROLE_MUTATION);

  const handleBillingTypeChanged = (event, newValue, previousValue) => {
    if (isPoolBillingType(previousValue) && !isPoolBillingType(newValue)) {
      change('specificBillingGroups', []);
      dispatch(removeKey('defaultBillingType'));
    } else if (isPoolBillingType(newValue) && !isPoolBillingType(previousValue)) {
      change('defaultBillingType', DefaultBillingType.AUTO);
    }
  };

  const handleRoleChanged = (updatedRole: Role) => {
    updateUserRole({
      variables: {
        id: userId,
        role: updatedRole
      }
    })
      .then(() => {
        change('role', updatedRole);
        addToast({
          autoDismiss: true,
          message: "Le rôle de l'utilisateur a été mis à jour",
          type: 'success'
        });
      })
      .catch(toastApolloError);
  };

  if (!userRole) return;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography gutterBottom variant="h6">
          Configuration
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <BasicField label="Rôle" InputLabelProps={{}} meta={{}}>
          <Select
            disabled={readonly}
            value={userRole}
            onChange={(event) => handleRoleChanged(event.target.value as Role)}
          >
            {roleList.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </BasicField>
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormElement
          name="billingType"
          label="Type de facturation"
          list={billingTypeList}
          disabled={readonly}
          component={SelectorField}
          onChange={handleBillingTypeChanged}
        />
      </Grid>

      {billingType === UserBillingType.POOL && (
        <Grid item xs={12} sm={12}>
          <FormElement
            name="defaultBillingType"
            label={translate('title')}
            component={SelectorField}
            list={[
              {
                value: DefaultBillingType.AUTO,
                label: translate(DefaultBillingType.AUTO)
              },
              {
                value: DefaultBillingType.POOL,
                label: translate(DefaultBillingType.POOL)
              },
              {
                value: DefaultBillingType.PRIVATE,
                label: translate(DefaultBillingType.PRIVATE)
              },
              {
                value: DefaultBillingType.END,
                label: translate(DefaultBillingType.END)
              }
            ]}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={12}>
        <FormElement
          name="ramqEnvironment"
          label="Environnement facturation"
          disabled={readonly}
          component={SelectorField}
          list={ramqEnvironmentList}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <FormElement name="intercomId" label="Identifiant Intercom" disabled={readonly} component={SingleLineField} />
      </Grid>
    </Grid>
  );
};
