import React from 'react';
import TextField from '@material-ui/core/TextField';
import Place from '../../../shared/domain/place/model/Place';

export interface Props {
  place: Place;
}

export const PlaceDistance: React.FunctionComponent<Props> = ({ place }) => (
  <TextField
    type="number"
    fullWidth
    placeholder="km"
    id={`${place.number}-distanceKM`}
    value={place.distanceKM || ''}
    disabled
  />
);

export default PlaceDistance;
