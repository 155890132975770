import React from 'react';
import classNames from 'classnames';

interface Props<T> {
  onChange: (value: T) => void;
  className?: string;
  value: T | null;
  options: T[];
  renderOption: (value: T) => React.ReactNode;
  evenTiles?: boolean;
}

const OptionTiles = <T,>({ value, onChange, evenTiles, options, renderOption, className }: Props<T>) => (
  <div className={classNames('flex gap-2', className)}>
    {options.map((option) => (
      <label
        className={classNames(
          'flex cursor-pointer select-none gap-3 rounded-lg border bg-white px-4 py-6 transition-colors',
          {
            'border-neutral-200': option !== value,
            'border-primary-900': option === value,
            'flex-1': !!evenTiles
          }
        )}
        key={`${option}`}
        onClick={() => onChange(option)}
      >
        <input type="radio" className="radio-primary radio" readOnly checked={option === value} />
        <div className="flex flex-col gap-2">{renderOption(option)}</div>
      </label>
    ))}
  </div>
);

export default OptionTiles;
