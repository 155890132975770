import partition from 'lodash/partition';

/* eslint-disable no-unused-vars */
class Collection<T> {
  constructor(protected data: T[] = []) {}

  at(index: number): T | undefined {
    return this.data[index];
  }

  filter(predicate: (entry: T) => boolean): Collection<T> {
    const filteredItems = this.data.filter(predicate);

    return new Collection<T>(filteredItems);
  }

  find(predicate: (entry: T) => boolean): { index: number; data: T } | undefined {
    const index = this.data.findIndex(predicate);

    if (index === -1) {
      return undefined;
    }

    return { index, data: this.data[index] };
  }

  flatten(): T[] {
    return this.data.map((item) => item);
  }

  getAll(): T[] {
    return this.data;
  }

  map<U>(predicate: (entry: T) => U): Collection<U> {
    const mappedItems = this.data.map(predicate);

    return new Collection<U>(mappedItems);
  }

  partition(predicate: (entry: T) => boolean): [Collection<T>, Collection<T>] {
    const [matchedActCodes, unmatchedActCodes] = partition(this.data, predicate);

    return [new Collection<T>(matchedActCodes), new Collection<T>(unmatchedActCodes)];
  }

  reduce<U>(reducer: (accumulator: U, entry: T) => U, accumulator: U): U {
    return this.data.reduce(reducer, accumulator);
  }

  remove(predicate: (entry: T) => T): void {
    this.data = this.data.filter(predicate);
  }

  size(): number {
    return this.data.length;
  }

  some(predicate: (entry: T) => boolean): boolean {
    return this.data.some(predicate);
  }

  updateAt(index: number, newValue: T): void {
    this.data[index] = newValue;
  }
}

export default Collection;
