import React, { Component } from 'react';
import { logError } from 'shared/utils/plog';
import { captureError } from 'utils/sentry';
import ErrorPopup from '../../../components/Errors/ErrorPopup/ErrorPopup';

export interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

export interface State {
  appIsCrashed: boolean;
}

export class CrashHandler extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = { appIsCrashed: false };
  }

  componentDidCatch(error) {
    logError(error.stack);
    this.setState({ appIsCrashed: true });
    captureError(error, { error });
  }

  render() {
    if (this.state.appIsCrashed) {
      return (
        <ErrorPopup
          onClose={() => {
            this.setState({ appIsCrashed: false });
            this.props.onClose();
          }}
          isOpen={this.state.appIsCrashed}
          message="Quelque chose d'imprévu est arrivé vous serez redirigé à l'accueil"
        />
      );
    }

    return this.props.children;
  }
}

export default CrashHandler;
