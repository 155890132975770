import { set } from 'lodash';
import { AssociatedValidation } from '../requiredDetail.type';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import {
  PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY,
  REMOTE_REQUIRED_DETAIL_KEY,
  TELEMEDICINE_REQUIRED_DETAIL_KEY,
  TELEMEDICINE_OR_REMOTE_REQUIRED_DETAIL_KEY
} from './constants';

export const INFECTED_WITHOUT_TELEMEDICINE_OR_REMOTE = 'Télémédecine ou à distance doit être coché oui si infecté';
export const INFECTED_WITH_42189 = 'La mesure de maintien 42189 ne s’applique pas au MD infecté';
export const QUARANTINE_WITHOUT_TELEMEDICINE_OR_REMOTE =
  'Télémédecine ou à distance doit être coché oui si en quarantaine';

const addInfectedWithoutTelemedicineOrRemoteError = (index: number) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY}` },
    `codes[${index}].${PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY}`,
    INFECTED_WITHOUT_TELEMEDICINE_OR_REMOTE
  );

const addInfectedWith42189Error = (index: number) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY}` },
    `codes[${index}].${PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY}`,
    INFECTED_WITH_42189
  );

const addQuarantineWithoutTelemedicineWith42189Error = (index: number) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY}` },
    `codes[${index}].${PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY}`,
    QUARANTINE_WITHOUT_TELEMEDICINE_OR_REMOTE
  );

function isInfectedWithoutTelemedicineOrRemote(code: any) {
  return (
    code[PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY] === 'infected' &&
    code[REMOTE_REQUIRED_DETAIL_KEY] === false &&
    code[TELEMEDICINE_REQUIRED_DETAIL_KEY] === false
  );
}

function isInfectedWith42189(code: any) {
  return code[PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY] === 'infected' && code.code === '42189';
}

function isQuarantineWithoutTelemedicineWith42189(code: any) {
  return (
    code[PARTICULAR_SITUATION_REQUIRED_DETAIL_KEY] === 'quarantine' &&
    code[TELEMEDICINE_OR_REMOTE_REQUIRED_DETAIL_KEY] === false &&
    code.code === '42189'
  );
}

export const infectedParticularSituationNeedToBeRemoteOrTelemedicine: AssociatedValidation = (act: any) => {
  const codesInError = act.codes.map((code: any, index: number) =>
    isInfectedWithoutTelemedicineOrRemote(code) ? addInfectedWithoutTelemedicineOrRemoteError(index) : {}
  );

  return codesInError.reduce(combineErrors, {});
};

export const infectedParticularSituationAnd42189: AssociatedValidation = (act: any) => {
  const codesInError = act.codes.map((code: any, index: number) =>
    isInfectedWith42189(code) ? addInfectedWith42189Error(index) : {}
  );

  return codesInError.reduce(combineErrors, {});
};

export const quarantineParticularSituationAndTelemedicineAnd42189: AssociatedValidation = (act: any) => {
  const codesInError = act.codes.map((code: any, index: number) =>
    isQuarantineWithoutTelemedicineWith42189(code) ? addQuarantineWithoutTelemedicineWith42189Error(index) : {}
  );

  return codesInError.reduce(combineErrors, {});
};
