import Badge from '@material-ui/core/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import AssistantIcon from '@material-ui/icons/Assistant';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectIntercomUnreadCount } from '../../Intercom/selectors';
import { selectAdvices, selectLoading } from '../selectors';

export const styles = () => ({
  badge: {
    top: -10,
    right: -10,
    width: 20,
    height: 20
  }
});

const countNumberAdvicesToNotify = (advices) =>
  advices.reduce((totalAdviceCount, currentAdvice) => {
    if (currentAdvice.activities) {
      return totalAdviceCount + currentAdvice.activities.length;
    }
    return totalAdviceCount + 1;
  }, 0);

const renderIconAccordingToAdvices = (advices, unreadMessagesCount, className, classes) => {
  const advicesNotificationsCount = countNumberAdvicesToNotify(advices);
  const totalNotificationsCount = advicesNotificationsCount + unreadMessagesCount;

  return totalNotificationsCount > 0 ? (
    <Badge badgeContent={totalNotificationsCount} color="secondary" classes={{ badge: classes.badge }}>
      <AssistantIcon className={className} />
    </Badge>
  ) : (
    <AssistantIcon className={className} />
  );
};

export function AssistantNavigationIcon({ advices, unreadMessagesCount, className, loading, classes }) {
  return loading ? <AssistantIcon /> : renderIconAccordingToAdvices(advices, unreadMessagesCount, className, classes);
}

AssistantNavigationIcon.propTypes = {
  advices: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  unreadMessagesCount: PropTypes.number
};

AssistantNavigationIcon.defaultProps = {
  className: '',
  unreadMessagesCount: 0
};

export const mapStateToProps = createStructuredSelector({
  advices: selectAdvices(),
  unreadMessagesCount: selectIntercomUnreadCount(),
  loading: selectLoading()
});

export default compose(withStyles(styles), connect(mapStateToProps, null))(AssistantNavigationIcon);
