import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

export const styles = () => ({
  formElement: {
    flex: 1
  }
});

export const FieldValueComponent = (props) => props.input.value;

// eslint-disable-next-line react/prop-types
export function RequiredElementLabel({ label }) {
  return (
    <>
      {label} <span style={{ color: 'red' }}>*</span>
    </>
  );
}

export function FormElement({ classes, className, name, required, component, label, ...props }) {
  const mergedClassName = classNames(classes.formElement, className);

  return (
    <div id={name} className={mergedClassName}>
      <Field
        name={name}
        component={component}
        label={required ? <RequiredElementLabel label={label} /> : label}
        {...props}
      />
    </div>
  );
}

FormElement.propTypes = {
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired
};

FormElement.defaultProps = {
  className: '',
  component: FieldValueComponent,
  label: '',
  disabled: false,
  required: false
};

export default withStyles(styles)(FormElement);
