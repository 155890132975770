import intersection from 'lodash/intersection';
import { selectSpecialty } from '../../../containers/User/selectors';
import { getStore } from '../../../reduxStore/configureStore';
import roleSelect from '../../requiredDetails/RoleSelect/roleSelect';
import RAMQCodes from '../../../../shared/ramq/RamqCodes';
import CODE_NATURE_TYPES from '../../../../shared/ramq/domainValues/natureTypes';
import SpecialtyName from '../../../../shared/core/doctor/domain/SpecialtyName';

export const addRoleSelectFieldIfNeeded = ({ code }, { date }) => {
  if (!code) {
    return [];
  }

  // For more details about this business logic, please refer to: https://medyx.atlassian.net/browse/MED-569
  const userSpeciality = selectSpecialty()(getStore().getState());
  const actCode = RAMQCodes.get(code);
  const roles = RAMQCodes.getRolesForCodeEffectiveOnAndForSpecialty(code, date, userSpeciality);

  const isCodeNatureTypeWhiteListed = [CODE_NATURE_TYPES.pdt, CODE_NATURE_TYPES.anesthesiologie].includes(
    actCode.natureType
  );

  const isUserSpecialtyWhiteListed = [
    SpecialtyName.ANESTHESIOLOGIST,
    SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST
  ].includes(userSpeciality);

  if (isUserSpecialtyWhiteListed && isCodeNatureTypeWhiteListed && intersection(roles, ['1', '2']).length === 2) {
    return roleSelect.associatedFields;
  }

  return [];
};

export default addRoleSelectFieldIfNeeded;
