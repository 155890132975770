import partition from 'lodash/partition';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import {
  ACTIVITY_PAYMENT_EXPLANATION_TYPE,
  ACT_RESPONSE_CANCEL_REQUEST_TYPE,
  ACT_RESPONSE_MODIFICATION_REQUEST_TYPE,
  ACT_RESPONSE_PAYMENT_REQUEST_TYPE
} from '../../../../../shared/ramq/constants';
import { getPreviousBillingPeriodEndDateFromDate } from '../../../../../shared/periods/periods';

export default class ActivityOutcomeResponsesGrouper {
  static group(ramqResponses: any) {
    const [responsesWithBillNumber, responsesWithoutBillNumber] = partition(
      ramqResponses,
      (ramqResponse) => ramqResponse.billNumber || ramqResponse.attestationNumber
    );

    const billNumberGroups = this.groupByBillNumber(responsesWithBillNumber);
    const responsesWithoutBillNumberInUniqGroup =
      this.createGroupForEachResponsesWithoutBillNumber(responsesWithoutBillNumber);

    const groups = [...billNumberGroups, ...responsesWithoutBillNumberInUniqGroup];

    return orderBy(groups, ['orderingDate'], ['desc']);
  }

  static createGroupForEachResponsesWithoutBillNumber(responsesWithoutBillNumber: any) {
    return responsesWithoutBillNumber.map((response: any) => ({
      ramqResponses: [response],
      orderingDate: response.createdOn
    }));
  }

  static groupByBillNumber(responsesWithBillNumber: any) {
    const responseGroups = groupBy(responsesWithBillNumber, (ramqResponse) =>
      ramqResponse.billNumber ? ramqResponse.billNumber : ramqResponse.attestationNumber
    );

    return Object.values(responseGroups).map((responses) => {
      const mostRecentResponse = orderBy(responses, ['createdOn'], ['desc'])[0];
      const mostRecentBillingResponse = this.findMostRecentBillingResponse(responses as any[]);

      return {
        orderingDate: mostRecentBillingResponse ? mostRecentBillingResponse.createdOn : mostRecentResponse.createdOn,
        ramqResponses: orderBy(responses, this.orderByDateDescendingWithEndingBillingPeriod, 'desc')
      };
    });
  }

  static findMostRecentBillingResponse(responses: any[]) {
    const BILLING_RESPONSES_TYPE = [
      ACT_RESPONSE_PAYMENT_REQUEST_TYPE,
      ACT_RESPONSE_MODIFICATION_REQUEST_TYPE,
      ACT_RESPONSE_CANCEL_REQUEST_TYPE
    ];

    const paymentRequestResponses = responses.filter((response) => BILLING_RESPONSES_TYPE.includes(response.type));

    if (paymentRequestResponses.length === 0) {
      return undefined;
    }

    return orderBy(paymentRequestResponses, ['createdOn'], ['desc'])[0];
  }

  static orderByDateDescendingWithEndingBillingPeriod(response: any) {
    if (response.type === ACTIVITY_PAYMENT_EXPLANATION_TYPE && !response.attestationNumber) {
      return getPreviousBillingPeriodEndDateFromDate(response.createdOn).valueOf();
    }

    return response.createdOn;
  }
}
