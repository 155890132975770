import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { forceDeleteValue } from '../../../containers/ReduxForm/actions';

/*
Redux form doesn't clear values on field/unregistered action. This is a
problem since we absolutely need to reset required details when they disapear (take example with
600, 6001 code with Pre Surgery Intervention detail. If you remove this wrapper component, select a value
(yes or no) then delete 6001 code, and re add 6001, you will see the value is still present in the store).
It can be a real problem because our backend optimization may have values that should not have been passed.
Related issues :
- https://github.com/erikras/redux-form/issues/2178
- https://github.com/erikras/redux-form/issues/2032
*/
export class DeletableValueOnUnmountField extends React.Component {
  componentWillUnmount() {
    this.props.forceDeleteValue(this.props.input.name);
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = {
  forceDeleteValue
};

DeletableValueOnUnmountField.propTypes = {
  forceDeleteValue: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  input: PropTypes.object.isRequired
};

export default connect(null, mapDispatchToProps)(DeletableValueOnUnmountField);
