import axios from 'axios';
import firebase from 'firebase/compat/app';
import RALPH_API_BASE_URL from './ralphApi';

export default class ElectronicSignatureAPI {
  static async requestSignature(activityId: string): Promise<void> {
    const bearerToken = await firebase.auth().currentUser?.getIdToken();

    if (!bearerToken) {
      throw new Error('User is not authenticated');
    }

    await axios({
      baseURL: RALPH_API_BASE_URL,
      headers: {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json'
      },
      method: 'post',
      url: `activities/${activityId}/request-signature`
    });
  }
}
