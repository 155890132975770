import React from 'react';
import Collapse from '@material-ui/core/Collapse';
import { WrappedFieldInputProps } from 'redux-form/lib/Field';
import CollapsableIcon from '../../CollapsableIcon/CollapsableIcon';
import MultiSelectCheckboxGroup from '../../Form/Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';

export interface Props {
  label: string;
  selected: boolean;
  input: WrappedFieldInputProps;
  list: { key: string; label: string }[];
  panelTriggerClassName?: string;
}

interface State {
  panelExpanded: boolean;
}

export class ContextElementShortcutPanel extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      panelExpanded: false
    };
  }

  render() {
    const { input, list, label, selected, panelTriggerClassName } = this.props;
    const { panelExpanded } = this.state;

    return (
      <>
        <div className={panelTriggerClassName || ''}>
          <CollapsableIcon
            data-testid="button-trigger"
            expanded={panelExpanded}
            onClick={() => this.setState((prevState) => ({ panelExpanded: !prevState.panelExpanded }))}
            selected={selected}
            label={label}
          />
        </div>
        <Collapse in={panelExpanded}>
          <MultiSelectCheckboxGroup
            data-testid="checkbox-list"
            input={input}
            list={list}
            labelProp="label"
            valueProp="key"
          />
        </Collapse>
      </>
    );
  }
}

export default ContextElementShortcutPanel;
