/*
Domaine de valeurs
1 : Situation normale
10 : Délai de carence, services nécessaires aux victimes de violence conjugale ou familiale ou d'une agression
11 : Délai de carence, services liés à la grossesse, à l'accouchement ou à l'interruption de grossesse
12 : Délai de carence, services nécessaires aux personnes aux prises avec problèmes de santé de nature
     infectieuse ayant une incidence sur la santé publique
16 : Patient requérant des soins urgents, détenteur de carnet de réclamation dont la
     durée de présence à l’aide financière de dernier recours est insuffisante
*/

export const WAITING_PERIOD_SITUATIONS = {
  normalSituation: '1',
  requiredForDomesticViolenceOrAggression: '10',
  pregnancyBirthAbortion: '11',
  publicHealthThreat: '12',
  insufficientLastResortHelp: '16'
};

export default WAITING_PERIOD_SITUATIONS;
