import * as PropTypes from 'prop-types';
import React from 'react';
import BasicField from '../BasicField';
import CashAmountInput from './CashAmountInput';

function CashAmountField({ input: { value, onChange }, ...props }) {
  return (
    <BasicField
      {...props}
      InputLabelProps={{
        shrink: true
      }}
    >
      <CashAmountInput value={value} onChange={onChange} />
    </BasicField>
  );
}

CashAmountField.propTypes = {
  input: PropTypes.object.isRequired
};

export default CashAmountField;
