import REFUND_AMOUNT from '../../../../shared/ramq/requiredDetails/refundAmount';
import { currencyFormat } from '../../../../utils/wordFormatUtilities';
import CashAmountField from '../../../components/Form/custom/CashAmountField';

const summaryRender = (code) => [
  { name: 'Relevé', value: code.refundAmount ? currencyFormat(code.refundAmount) : '-' }
];

const associatedFields = [
  {
    label: 'Montant perçu',
    getName: (index) => `codes[${index}][${REFUND_AMOUNT}]`,
    type: CashAmountField
  }
];

export default { associatedFields, summaryRender };
