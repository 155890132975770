import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ACTIVITIES_ROUTE, ACTS_ROUTE } from '../../../../containers/Navigation/constants';

const ActLinkButton = ({ act }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();

  const buildLink = (act) => {
    if (location.pathname.startsWith('/users') && userId) {
      return `/users/${userId}${ACTIVITIES_ROUTE}${ACTS_ROUTE}/${act.id}`;
    }

    return `${ACTIVITIES_ROUTE}/${act.date}${ACTS_ROUTE}/${act.id}`;
  };

  const actLink = buildLink(act);
  return (
    <Button href={actLink} onClick={() => navigate({ pathname: actLink, search: location.search })}>
      Voir
    </Button>
  );
};

ActLinkButton.propTypes = {
  act: PropTypes.object.isRequired
};

export default ActLinkButton;
