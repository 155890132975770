export const DEFAULT_MIXTE_START_DATE = '2018-01-07';
export const LUMP_SUM_PERIOD_DAYS_DURATION = 7;
export const MIXTE_PERIOD_DAYS_DURATION = 14;
export const BILLING_PERIOD_DURATION = 14;
export const DEFAULT_BILLING_START_DATE = '2018-08-28';
export const DEFAULT_STATEMENT_DATE = '2017-10-06';

// Mixte periods always start and end 9 days before billing periods
// This comes from the fact that billing periods start on Tuesdays
// but mixte periods start on Sundays the week before
export const BILLING_MIXTE_PERIOD_GAP = 9;
// Lump sum periods matching billing periods
// start and end 2 days before billing periods
export const BILLING_LUMPSUM_PERIOD_GAP = 2;
