import MomentTimezone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import PlaceDomain from '../../domain/place/model/PlaceDomain';
import Act from '../../domain/activity/act/model/Act';
import ActCodes from '../../domain/activity/act/model/ActCodes';

type MisusedCode = string;
type CorrecCode = string;

class AssistedMechanicalVentilationActCodesMappingService {
  public static listMisusedCodesInAct({ date, place, codes, start, end, remp }: Act): [MisusedCode, CorrecCode][] {
    const placeDomain = PlaceDomain.fromDto(place);
    const actCodes = new ActCodes(codes);
    const moment = extendMoment(MomentTimezone as any);
    const actCode929RuleRange = moment.range(
      moment(date).set({ hour: 7, minute: 0, second: 0, millisecond: 0 }),
      moment(date).set({ hour: 18, minute: 59, second: 59, millisecond: 59 })
    );
    const actDurationRange = moment.range(moment(remp || start || end), moment(end || remp || start));

    if (
      actCodes.isContainsSome('900') &&
      placeDomain.isWeekEndOrHoliday(date) &&
      actCode929RuleRange.overlaps(actDurationRange, { adjacent: true })
    ) {
      return [['900', '929']];
    }

    if (
      (actCodes.isContainsSome('929') && !placeDomain.isWeekEndOrHoliday(date)) ||
      (actCodes.isContainsSome('929') &&
        placeDomain.isWeekEndOrHoliday(date) &&
        !actCode929RuleRange.overlaps(actDurationRange, { adjacent: true }))
    ) {
      return [['929', '900']];
    }

    return [];
  }
}

export default AssistedMechanicalVentilationActCodesMappingService;
