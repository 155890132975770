import { useCallback, useMemo, useState } from 'react';

interface CustomValidation {
  isValid: boolean;
  errorMessage: string;
}

export interface ValidatedFieldProps {
  onValidate?: (isValid: boolean) => void;
  onTouch?: () => void;
  customValidation?: CustomValidation;
  touched?: boolean;
}

export default function useValidatedForm() {
  const [fieldValidations, setFieldValidations] = useState<Record<string, boolean>>({});
  const [touchedFields, setTouchedFields] = useState<string[]>([]);

  const erroredFields = useMemo(
    () =>
      Object.entries(fieldValidations).reduce((erroredFields: string[], [key, isValid]) => {
        if (!isValid) return [...erroredFields, key];
        return erroredFields;
      }, []),
    [fieldValidations]
  );

  const reset = useCallback(() => {
    setFieldValidations({});
    setTouchedFields([]);
  }, []);

  const useValidatedField = (key: string) => ({
    touched: touchedFields.includes(key),
    onValidate: useCallback(
      (isValid: boolean) => {
        setFieldValidations((previousValidations) => {
          if (previousValidations[key] === isValid) return previousValidations;

          return {
            ...previousValidations,
            [key]: isValid
          };
        });
      },
      [key]
    ),
    onTouch: useCallback(() => {
      setTouchedFields((previousTouchedFields) => {
        if (previousTouchedFields.includes(key)) return previousTouchedFields;

        return [...previousTouchedFields, key];
      });
    }, [key])
  });

  return {
    isValid: erroredFields.length === 0,
    erroredFields,
    touchedFields,
    registerField: useValidatedField,
    reset
  };
}
