import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DoctorFavoritesListItemText from '../DoctorFavoritesListItemText/DoctorFavoritesListItemText';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down('xs')]: {
      doctorItem: {
        paddingLeft: 0
      },
      doctorItemText: {
        paddingLeft: 0
      }
    },
    hidden: {
      display: 'none'
    },
    visible: {
      display: 'block'
    },
    icon: {
      color: theme.palette.grey[500]
    },
    selected: {
      color: theme.palette.primary[500]
    }
  };
};

export class DoctorSuggestion extends React.PureComponent {
  render() {
    const { suggestion, classes, isInFavorites, onClick, onClickFavorite } = this.props;

    return (
      <ListItem button className={classes.doctorItem} onClick={() => onClick(suggestion)}>
        <DoctorFavoritesListItemText doctor={suggestion} />
        <ListItemSecondaryAction>
          <IconButton onClick={() => !isInFavorites && onClickFavorite(suggestion)}>
            <FavoriteIcon className={classNames(classes.icon, { [classes.selected]: isInFavorites })} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

DoctorSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isInFavorites: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickFavorite: PropTypes.func.isRequired
};

export default withStyles(styles)(DoctorSuggestion);
