export const GET_RAMQ_PLACES = 'getRamqPlaces';
export const GET_RAMQ_PLACES_SUCCESS = 'getRamqPlacesSuccess';
export const GET_RAMQ_PLACES_FAILURE = 'getRamqPlacesFailure';

export const getRamqPlaces = () => ({
  type: GET_RAMQ_PLACES
});

export const getRamqPlacesSuccess = (ramqPlaces) => ({
  type: GET_RAMQ_PLACES_SUCCESS,
  ramqPlaces
});

export const getRamqPlacesFailure = (error) => ({
  type: GET_RAMQ_PLACES_FAILURE,
  error
});
