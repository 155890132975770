import { withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import {
  AdministrativeNote,
  AdministrativeNoteType
} from '../../../../../shared/core/activity/domain/AdministrativeNote';
import ConfirmationDialog from '../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import CommentList from '../../../../components/List/CommentList/CommentList';
import CommentEditorDialog from '../../../../containers/UsersRoute/UserRoute/UserActivities/EditCommentDialog/CommentEditorDialog';
import MoreActions from '../../../../shared/actions/MoreActions/MoreActions';
import Comment from '../Comment/Comment';

export const styles = () => ({
  default: {
    '& > * *': {
      color: '#0011cc'
    }
  },
  followUp: {
    '& > * *': {
      color: '#0011cc'
    }
  },
  needFix: {
    '& > * *': {
      color: '#e53935bf',
      wordBreak: 'break-word'
    }
  }
});

interface OwnProps {
  notes: AdministrativeNote[];
  activity: Object;
  type: AdministrativeNoteType;
  // eslint-disable-next-line no-unused-vars
  onUpdate: (notes: AdministrativeNote[]) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  noteDeletionValidationModalOpen: boolean;
  administrativeNoteToDelete: AdministrativeNote | null;
  noteEditorModalOpen: boolean;
  administrativeNoteToEdit: AdministrativeNote | null;
}

export class AdministrativeNotes extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      noteDeletionValidationModalOpen: false,
      administrativeNoteToDelete: null,
      noteEditorModalOpen: false,
      administrativeNoteToEdit: null
    };

    this.closeNoteDeletionValidationModal = this.closeNoteDeletionValidationModal.bind(this);
    this.deleteSelectedCommentFromAdminNotes = this.deleteSelectedCommentFromAdminNotes.bind(this);
    this.closeCommentEditor = this.closeCommentEditor.bind(this);
    this.updateSelectedCommentFromAdminNotes = this.updateSelectedCommentFromAdminNotes.bind(this);
    this.displayDeletingNoteConfirmation = this.displayDeletingNoteConfirmation.bind(this);
    this.openCommentEditor = this.openCommentEditor.bind(this);
  }

  closeNoteDeletionValidationModal() {
    this.setState({ noteDeletionValidationModalOpen: false, administrativeNoteToDelete: null });
  }

  deleteSelectedCommentFromAdminNotes() {
    const { notes, onUpdate } = this.props;
    const { administrativeNoteToDelete } = this.state;

    const adminNotes = notes.filter((note) => note !== administrativeNoteToDelete);

    onUpdate(adminNotes);
  }

  updateSelectedCommentFromAdminNotes(newComment: string) {
    const { notes, onUpdate } = this.props;
    const { administrativeNoteToEdit } = this.state;

    const adminNotes = notes.map((note) => {
      if (note.date === administrativeNoteToEdit?.date) {
        return { ...note, content: newComment };
      }

      return note;
    });

    onUpdate(adminNotes);
  }

  closeCommentEditor() {
    this.setState({
      noteEditorModalOpen: false,
      administrativeNoteToEdit: null
    });
  }

  displayDeletingNoteConfirmation(comment: AdministrativeNote) {
    this.setState({
      noteDeletionValidationModalOpen: true,
      administrativeNoteToDelete: comment
    });
  }

  openCommentEditor(comment: AdministrativeNote) {
    this.setState({
      noteEditorModalOpen: true,
      administrativeNoteToEdit: comment
    });
  }

  render() {
    const { classes, notes, activity, type } = this.props;
    const { noteDeletionValidationModalOpen, administrativeNoteToEdit, noteEditorModalOpen } = this.state;

    return (
      <>
        <CommentList
          className={classes[type]}
          title={
            <Typography variant="button">
              {type === AdministrativeNoteType.NEED_FIX ? 'À Corriger' : 'Suivi'}
            </Typography>
          }
          comments={notes}
        >
          {(administrativeNotes: AdministrativeNote[]) =>
            administrativeNotes.map((administrativeNote) => (
              <Comment
                key={administrativeNote.date.toString()}
                actions={
                  <MoreActions
                    actions={[
                      {
                        key: 'edit-action-key',
                        labelKey: 'editableCollectionWithCategories.edit',
                        clickEvent: () => this.openCommentEditor(administrativeNote),
                        Icon: EditIcon
                      },
                      {
                        key: 'delete-action-key',
                        labelKey: 'editableCollectionWithCategories.delete',
                        clickEvent: () => this.displayDeletingNoteConfirmation(administrativeNote),
                        Icon: DeleteIcon
                      }
                    ]}
                  />
                }
                comment={administrativeNote}
              />
            ))
          }
        </CommentList>
        <ConfirmationDialog
          data-testid="confirmation-dialog"
          title="Supprimer une note"
          text="Etes vous sûr de vouloir supprimer cette note de suivi ?"
          open={noteDeletionValidationModalOpen}
          onClose={this.closeNoteDeletionValidationModal}
          confirmationLabel="oui"
          cancelLabel="non"
          onItemSelected={this.deleteSelectedCommentFromAdminNotes}
        />
        <CommentEditorDialog
          title="Modifier une note"
          open={noteEditorModalOpen}
          act={activity}
          comment={administrativeNoteToEdit?.content}
          onClose={this.closeCommentEditor}
          onItemSelected={this.updateSelectedCommentFromAdminNotes}
        />
      </>
    );
  }
}

export default withStyles(styles)(AdministrativeNotes);
