import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import useTranslate from 'application/hooks/use-translate';
import SettingsSubscriptionSignupPayment from 'application/pages/settings/details/subscription-signup/subscription-signup-payment';
import SettingsSubscriptionSignupPlans from 'application/pages/settings/details/subscription-signup/subscription-signup-plans';
import React, { useState } from 'react';

interface Props {
  user: FirestoreUser;
}

const SettingsSubscriptionSignup = ({ user }: Props) => {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null);
  const translate = useTranslate('pages.settings.details.subscription.signup');

  if (!isSubscribing) {
    return (
      <FieldContainer label={translate('intro')}>
        <div className="flex justify-end">
          <Button theme="primary" onClick={() => setIsSubscribing(true)}>
            {translate('subscribe')}
          </Button>
        </div>
      </FieldContainer>
    );
  }

  if (!selectedPriceId) {
    return <SettingsSubscriptionSignupPlans onPriceIdSelected={setSelectedPriceId} />;
  }

  return <SettingsSubscriptionSignupPayment email={user.email} priceId={selectedPriceId} />;
};

export default SettingsSubscriptionSignup;
