import React from 'react';
import classnames from 'classnames';
import getExecutionEnv, { isPROD } from 'shared/utils/executionEnvironment';
import medyxMarkWhite from 'images/medyx-mark-white.svg';
import medyxWordmarkWhite from 'images/medyx-wordmark-white.svg';

interface Props {
  displayEnvironmentBadge?: boolean;
  type?: 'mark' | 'wordmark';
  width?: number;
}

const Logo = ({ displayEnvironmentBadge = false, type = 'wordmark', width = 160 }: Props) => (
  <div
    className={classnames('flex items-center gap-1', {
      'flex-col': type === 'mark',
      'justify-center': type === 'mark'
    })}
  >
    {type === 'mark' && <img alt="Medyx" src={medyxMarkWhite} width={(60 * 160) / width} />}

    {type === 'wordmark' && <img alt="Medyx" src={medyxWordmarkWhite} width={width} />}

    {displayEnvironmentBadge && !isPROD() && (
      <span
        className={classnames('badge badge-sm rounded-sm', {
          '-translate-y-5': type === 'wordmark'
        })}
      >
        {getExecutionEnv()}
      </span>
    )}
  </div>
);

export default Logo;
