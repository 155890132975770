import FirebaseConfigProvider from './FirebaseConfigProvider';
import { plog } from '../utils/plog';

const PROJECT_ID = FirebaseConfigProvider.getInstance().getConfig().projectId;
const FUNCTIONS_URL = FirebaseConfigProvider.getInstance().getConfig().functionsUrl;
const DEPLOYED_REGION = 'northamerica-northeast1';
const CLOUD_URL = 'cloudfunctions.net';
const LOCAL_URL = `http://${FUNCTIONS_URL}/${PROJECT_ID}/${DEPLOYED_REGION}`;
const REMOTE_URL = `https://${DEPLOYED_REGION}-${PROJECT_ID}.${CLOUD_URL}`;

const API_BASE_URL = process.env.NODE_ENV === 'development' || !process.env.NODE_ENV ? LOCAL_URL : REMOTE_URL;
plog('Client API base URL: ', API_BASE_URL);

export default API_BASE_URL;
