import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectStartTime } from '../../../../containers/ActForm/selectors';
import TimeSyncedWithGivenTime from '../../TimeSyncedWithGivenTime/TimeSyncedWithGivenTime';

export function TimeSyncedWithActStartTime(componentProps) {
  const { input, ...props } = componentProps;

  return <TimeSyncedWithGivenTime givenTime={props.currentStartTime} input={input} {...props} />;
}

TimeSyncedWithActStartTime.defaultProps = {
  currentStartTime: null
};

TimeSyncedWithActStartTime.propTypes = {
  currentStartTime: PropTypes.number,
  input: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  currentStartTime: selectStartTime()
});

export default compose(connect(mapStateToProps))(TimeSyncedWithActStartTime);
