import { padStart } from 'lodash';
import { LatestMixtePeriodFormDto } from '../type';
import MixteTransformer from '../transformer/mixte/MixtePeriodTransformer';
import MixteSummaryMaker from '../../../../../shared/periods/mixte/MixteSummaryMaker';
import { parseHoursWorkedStringToNumber } from '../../../../../shared/ramq/ramqCommonUtils';
import { Mixte } from '../../../../../shared/domain/activity/mixte/model/Mixte';

const PeriodHoursService = {
  getMixteHours: (period: LatestMixtePeriodFormDto) => {
    const domainModel: Mixte = new MixteTransformer().toDomainObject(period);
    const summary = MixteSummaryMaker.make(domainModel as any);
    return parseHoursWorkedStringToNumber(padStart(summary.totalDuration, 4, '0'));
  },

  getHalfPerdiemsCount: (period: LatestMixtePeriodFormDto) =>
    period.days.reduce((acc, day) => acc + (day?.perdiems?.length ?? 0), 0)
};

export default PeriodHoursService;
