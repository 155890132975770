import { defineMessages } from 'react-intl';

export default defineMessages({
  hideCanceled: {
    id: 'activity.header.dp.hide.canceled'
  },
  activityCount: {
    id: 'activity.header.dp.count',
    defaultMessage: ''
  }
});
