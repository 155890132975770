import Grow from '@material-ui/core/Grow';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import useTranslate from 'application/hooks/use-translate';
import ActivityStatus from '../../../../../../shared/core/activity/domain/ActivityStatus';
import ActMainInformation from '../../../../../components/Activities/Act/ActMainInformation/ActMainInformation';
import Details from '../../../../../components/Activities/Act/Details/Details';
import ActivityContainer from '../../../../../components/Activities/ActivityContainer/ActivityContainer';
import ErrorPopup from '../../../../../components/Errors/ErrorPopup/ErrorPopup';
import { actSendValidations } from '../../../../../validations/act/sendValidation/sendValidation';
import { duplicateAct, updateAct } from '../../../../Act/actions';
import ActDuplicationService from '../../../../ActForm/DuplicateAct/ActDuplicationService';
import { selectDetailedView } from '../../../../Activities/selectors';
import { ACTS_ROUTE, ACTIVITIES_ROUTE, DUPLICATE_ACT_ROUTE } from '../../../../Navigation/constants';
import DesktopActions from './DesktopActions/DesktopActions';
import { selectUserProfileInContext } from '../../../../User/selectors';
import { getStore } from '../../../../../reduxStore/configureStore';
import SwipeableActions from '../../../../../../application/components/swipeable-actions';
import Modal from '../../../../../../application/components/modal';

const ActActivity = ({ userProfile, activity, onChangeStatus, onDuplicate, detailedView }) => {
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false);
  const translate = useTranslate();

  const sendAct = () => {
    if (!isEmpty(actSendValidations({ act: activity, user: userProfile }))) {
      setValidationErrors(true);
    } else {
      setValidationErrors(false);
      onChangeStatus(activity, ActivityStatus.SENT);
    }
  };

  const handleChangeStatus = (activity, newStatus) => {
    if (
      newStatus === ActivityStatus.CANCELED &&
      activity.billNumberMap &&
      Object.keys(activity.billNumberMap).length > 0
    ) {
      setIsCancelConfirmationOpen(true);
    } else {
      onChangeStatus(activity, newStatus);
    }
  };

  const cancelActivity = (activity) => {
    setIsCancelConfirmationOpen(false);
    onChangeStatus(activity, ActivityStatus.CANCELED);
  };

  const swipeableActions = [
    {
      label: translate('act-form.send'),
      onClick: sendAct,
      theme: 'primary',
      icon: 'send'
    },
    {
      label: translate('act-form.wait'),
      onClick: () => onChangeStatus(activity, ActivityStatus.WAITING),
      theme: 'secondary',
      icon: 'clock'
    },
    {
      label: translate('act-form.duplicate'),
      onClick: () => onDuplicate(activity, navigate),
      icon: 'copy'
    }
  ];

  if (!activity.blocked) {
    swipeableActions.push({
      label: translate('act-form.cancel'),
      onClick: () => handleChangeStatus(activity, ActivityStatus.CANCELED),
      theme: 'error',
      icon: 'ban'
    });
  }

  return (
    <Grow in>
      {/* Cannot be a fragment if we want Grow animation to work */}
      <div>
        <ErrorPopup
          message="L'acte ne peut être envoyé car il n'est pas valide"
          isOpen={validationErrors}
          onClose={() => setValidationErrors(false)}
        />

        <Modal
          actions={[
            {
              label: translate('activity-cancellation-contirmation-dialog.cancel'),
              onClick: () => setIsCancelConfirmationOpen(false),
              variant: 'ghost'
            },
            {
              label: translate('activity-cancellation-contirmation-dialog.confirm'),
              onClick: () => cancelActivity(activity),
              variant: 'primary'
            }
          ]}
          open={isCancelConfirmationOpen}
          title={translate('activity-cancellation-contirmation-dialog.title')}
        >
          <p>{translate('activity-cancellation-contirmation-dialog.description')}</p>
          <p className="mt-4">{translate('activity-cancellation-contirmation-dialog.confirmation-message')}</p>
        </Modal>
        <SwipeableActions disabled={activity.synchronized === false} actions={swipeableActions}>
          <ActivityContainer
            id="activities-list-item"
            divider
            disabled={activity.synchronized === false}
            ContainerComponent="div"
            activityCodes={activity.codes}
            isGuard={activity.guard}
            onClick={() => navigate(`${ACTS_ROUTE}/${activity.id}`)}
          >
            <ActMainInformation activity={activity} />
            <Details open={detailedView} act={activity} />
            <ListItemSecondaryAction>
              <DesktopActions
                activity={activity}
                onCancel={activity.blocked ? undefined : () => handleChangeStatus(activity, ActivityStatus.CANCELED)}
                onDuplicate={() => onDuplicate(activity, navigate)}
                onExport={() => {
                  const redactedAct = { ...activity, nam: undefined, assets: undefined, attachments: undefined };

                  const fileName = `${userProfile.firstName} ${userProfile.lastName} ${moment(redactedAct.date).format(
                    'YYYY-MM-DD'
                  )}`;

                  const element = document.createElement('a');
                  const file = new Blob([JSON.stringify([redactedAct], null, 2)], { type: 'text/plain' });
                  element.href = URL.createObjectURL(file);
                  element.download = `${fileName}.json`;
                  document.body.appendChild(element); // Required for this to work in FireFox
                  element.click();
                }}
                onSend={sendAct}
                disabled={activity.synchronized === false}
              />
            </ListItemSecondaryAction>
          </ActivityContainer>
        </SwipeableActions>
      </div>
    </Grow>
  );
};

ActActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  detailedView: PropTypes.bool.isRequired,
  userProfile: PropTypes.object
};

export const mapStateToProps = createStructuredSelector({
  detailedView: selectDetailedView(),
  userProfile: selectUserProfileInContext()
});

export const mapDispatchToProps = (dispatch) => ({
  onChangeStatus: (act, status) => dispatch(updateAct(act, { status })),
  onDuplicate: (act, navigate) => {
    const duplicatedInfo = ActDuplicationService.duplicateForDoctor(
      act,
      selectUserProfileInContext()(getStore().getState())
    );
    dispatch(duplicateAct(duplicatedInfo));
    navigate(`/${ACTIVITIES_ROUTE}/${act.date}/${DUPLICATE_ACT_ROUTE}`);
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ActActivity);
