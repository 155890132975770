export default async (response: Response): Promise<void> => {
  const contentDispositionHeader = response.headers.get('content-disposition');

  if (!contentDispositionHeader) {
    throw new Error('Content-Disposition header missing');
  }

  const filename = contentDispositionHeader
    .split(';')
    .find((part) => part.includes('filename='))!
    .replace('filename=', '')
    .replaceAll('"', '')
    .trim();

  const fileBlob = await response.blob();

  if (window.cordova) {
    mobileDownload(fileBlob);

    return;
  }

  browserDownload(filename, fileBlob);
};

const mobileDownload = (blob: Blob): void => {
  const reader = new window.FileReader();

  reader.readAsDataURL(blob);

  reader.onloadend = () => {
    // @ts-ignore
    // eslint-disable-next-line
    window.cordova.InAppBrowser.open(reader.result, '_blank', 'toolbarposition=bottom,toolbarcolor=#FFFFFF');
  };
};

const browserDownload = (filename: string, blob: Blob): void => {
  const fileURL = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.href = fileURL;
  a.download = filename;

  document.body.appendChild(a);

  a.click();
  a.remove();

  window.URL.revokeObjectURL(fileURL);
};
