import { flattenDeep } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import { getUIOptimizations } from '../../../codeSpecificLogic/ui/codeSpecificUILogic';

export const UIOptimizations = ({ fields, act }) => {
  const selectedActCodes = fields.length > 0 ? fields.getAll() : [];
  const optimizationsFunctionFromCodes = flattenDeep(
    selectedActCodes.map((actCode) => getUIOptimizations(actCode, act))
  );

  return optimizationsFunctionFromCodes.map((Optimization) => <Optimization key={shortid.generate()} />);
};

UIOptimizations.propTypes = {
  fields: PropTypes.object.isRequired,
  act: PropTypes.object.isRequired
};

export default UIOptimizations;
