import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { USER_ID_ROUTE_PARAM } from '../Navigation/constants';
import UserRoute from './UserRoute/UserRoute';
import UsersSearch from './UsersSearch/UsersSearch';

export const UsersRoute = () => (
  <Routes>
    <Route index element={<UsersSearch />} />
    <Route path={`${USER_ID_ROUTE_PARAM}/*`} element={<UserRoute />} />
  </Routes>
);

export default UsersRoute;
