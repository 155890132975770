import { Button, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { useCopyToClipboard } from 'react-use';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { Alert } from '@material-ui/lab';
import User from 'shared/domain/user/User';
import deviceDetector from '../../../../../components/utils/DeviceDetector';
import { selectUserProfileInContext } from '../../../../User/selectors';

const styles = () =>
  ({
    container: {
      display: 'flex',
      justifyContent: 'end',
      position: 'absolute',
      right: 0,
      top: 30
    },
    copyUserInfosButton: {
      color: '#fff'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  currentUserProfile: User;
}

function CopyUserInfos({ classes, currentUserProfile }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'error' | 'warning' | 'info'>('success');
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>('');
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [state, copyToClipboard] = useCopyToClipboard();

  if (!deviceDetector.isBrowser() || !currentUserProfile) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copyTextToClipboard = (text: string) => {
    copyToClipboard(text);
    if (state.error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(`Une erreur est survenue lors de la copie. ${state.error.message}`);
    } else {
      setSnackbarSeverity('success');
      setSnackbarMessage(`Copié ${text} avec succès!`);
    }
    setShowSnackbar(true);
    handleClose();
  };

  return (
    <Restrict atLeastRole={Role.Developer}>
      <div className={classes.container}>
        <Button className={classes.copyUserInfosButton} onClick={handleClick}>
          Copier informations
          <KeyboardArrowDown />
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => copyTextToClipboard(currentUserProfile.id)}>ID</MenuItem>
          <MenuItem onClick={() => copyTextToClipboard(currentUserProfile.practiceNumber)}>Numéro de pratique</MenuItem>
          <MenuItem onClick={() => copyTextToClipboard(currentUserProfile.email)}>Email</MenuItem>
        </Menu>
      </div>
      <Snackbar open={showSnackbar} onClose={() => setShowSnackbar(false)} autoHideDuration={3000}>
        <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>
    </Restrict>
  );
}

export const mapStateToProps = createStructuredSelector({
  currentUserProfile: selectUserProfileInContext()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(CopyUserInfos);
