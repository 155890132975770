import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import has from 'lodash/has';
import messages from '../../../../../containers/ActForm/messages';

const styles = (theme) => ({
  spacedText: {
    marginRight: theme.spacing(1)
  }
});

function ContextElementsSummary({ contextElements, classes }) {
  return (
    <>
      {contextElements.map((contextElement) => (
        <div key={contextElement} className={classes.spacedText}>
          {has(messages, contextElement) ? <FormattedMessage {...messages[contextElement]} /> : contextElement}
        </div>
      ))}
    </>
  );
}

ContextElementsSummary.propTypes = {
  contextElements: PropTypes.array.isRequired
};
export default withStyles(styles)(ContextElementsSummary);
