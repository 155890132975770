import SpecialtyName, { SPECIALTY_TO_FAMILY } from '../../../../core/doctor/domain/SpecialtyName';
import ConfigRepository from '../repository/ConfigRepository';
import Specialty from '../model/Specialty';

class SpecialtyFactory {
  private configRepository: ConfigRepository;

  constructor(configRepository: ConfigRepository) {
    this.configRepository = configRepository;
  }

  create(specialtyName: SpecialtyName): Specialty {
    const specialtyConfig = this.configRepository.findSpecialtyConfig(specialtyName);
    const familyConfig = this.configRepository.findFamilyConfig(SPECIALTY_TO_FAMILY[specialtyName]);

    return new Specialty(new Map([...familyConfig, ...specialtyConfig]));
  }
}

export default SpecialtyFactory;
