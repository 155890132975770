import Icon from 'application/components/icon';
import Markdown from 'application/components/markdown';
import AppStoreBadge from 'application/components/mobile-store/apple/app-store-badge';
import PlayStoreBadge from 'application/components/mobile-store/google/play-store-badge';
import useTranslate from 'application/hooks/use-translate';
import React from 'react';

const Error = () => {
  const translate = useTranslate('pages.lobby.signup');

  return (
    <div className="inline-flex w-full flex-col items-start justify-start overflow-hidden rounded border border-gray-200 bg-white shadow">
      <div className="inline-flex w-full flex-col items-center justify-center gap-4 border-b border-neutral-200 px-4 py-8">
        <div className="relative h-24 w-24">
          <div className="h-24 w-24 rounded-full bg-error opacity-25" />
          <Icon icon="circle-xmark" solid className="fa-5x absolute left-0 top-0 p-2 text-error" />
        </div>
        <div className="text-center text-xl font-bold text-error">{translate('error')}</div>

        <Markdown slug="signup-error-1" />

        <div className="flex w-full items-center gap-4">
          <AppStoreBadge theme="dark" />
          <PlayStoreBadge />
        </div>

        <Markdown slug="signup-error-2" />
      </div>
    </div>
  );
};

export default Error;
