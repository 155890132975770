import { set } from 'lodash';
import TimeSyncedWithActStartTime from 'app/components/Form/ActSpecificField/TimeSyncedWithActStartTime/TimeSyncedWithActStartTime';
import { formatUnixToTimeValue } from '../../../../shared/utils/dateTime/dateTimeUtils';
import {
  END_TIME_WHEN_DURATION_OVER_30_MINUTES,
  isLessThan30MinsDifferenceWithActStartDate
} from '../../../../shared/ramq/requiredDetails/optionalEndTime';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';

const LESS_THAN_30_MINUTES_ERROR = 'Min 30 minutes de diff. avec début';
const lessThan30MinutesError = (index) =>
  set(
    { elementIdToScrollTo: `codes[${index}].${END_TIME_WHEN_DURATION_OVER_30_MINUTES}` },
    `codes[${index}].${END_TIME_WHEN_DURATION_OVER_30_MINUTES}`,
    LESS_THAN_30_MINUTES_ERROR
  );

const validateAtLeast30MinutesWithActStartTime = (act) => {
  const codesInError = act.codes.map((code, index) => {
    if (isLessThan30MinsDifferenceWithActStartDate(act.start, code)) {
      return lessThan30MinutesError(index);
    }

    return {};
  });

  return codesInError.reduce(combineErrors, {});
};

const summaryRender = (code) =>
  code[END_TIME_WHEN_DURATION_OVER_30_MINUTES]
    ? [
        {
          name: 'Heure fin si durée > 30 min',
          value: formatUnixToTimeValue(code[END_TIME_WHEN_DURATION_OVER_30_MINUTES])
        }
      ]
    : [];

const associatedFields = [
  {
    label: 'Heure fin si durée > 30 min',
    getName: (index) => `codes[${index}].${END_TIME_WHEN_DURATION_OVER_30_MINUTES}`,
    type: TimeSyncedWithActStartTime,
    notRequired: true
  }
];

const associatedValidations = [validateAtLeast30MinutesWithActStartTime];

const endTimeIfMoreThan30Min = { associatedFields, associatedValidations, summaryRender };
export default endTimeIfMoreThan30Min;
