import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  WithStyles,
  withStyles
} from '@material-ui/core';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { v4 as uuidV4 } from 'uuid';
import DialogCenteredTitle from '../../../Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import Absence, { AbsenceReason } from '../../../../../shared/domain/user/Absence/Absence';
import { absenceReasonsTranslations } from '../absenceTranslations';

const generateRandomId = () => uuidV4();

export const styles = (theme) =>
  ({
    dialogContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2)
    },
    dialogContentElement: {
      marginBottom: theme.spacing(2),
      marinTop: theme.spacing(2)
    },
    cancelBtn: {
      color: 'red'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (abscence: Absence) => void;
  initialAbsence?: Absence;
  open: boolean;
  onClose: () => void;
}

interface State {
  absence: Absence;
}

export class AbsenceDialog extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    const { initialAbsence } = props;
    this.state = { absence: initialAbsence || this.createNewAbsence() };
  }

  createNewAbsence = (): Absence => ({
    id: generateRandomId(),
    startDate: moment().add(7, 'days').valueOf(),
    endDate: moment().add(14, 'days').valueOf(),
    reason: AbsenceReason.FELLOWSHIP,
    comments: ''
  });

  handleDateChange = (field: 'startDate' | 'endDate', date: number | null) => {
    const { absence } = this.state;
    const updatedAbsence = { ...absence, [field]: date };

    this.setState({ absence: updatedAbsence });
  };

  handleChange = (field: 'reason' | 'comments', event: React.ChangeEvent<any>) => {
    const { absence } = this.state;
    const updatedAbsence = { ...absence, [field]: event.target.value };

    this.setState({ absence: updatedAbsence });
  };

  saveAbsence = () => {
    this.props.onSubmit(this.state.absence);
    this.props.onClose();
    this.setState({
      absence: this.createNewAbsence()
    });
  };

  render() {
    const { startDate, endDate, reason, comments } = this.state.absence;
    const { open, onClose, classes } = this.props;

    return (
      <Dialog fullWidth open={open}>
        {/* @ts-ignore */}
        <DialogCenteredTitle label="Ajouter une absence" />
        <DialogContent className={classes.dialogContentContainer}>
          <div className={classes.dialogContentElement}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker
                label="Date de départ"
                data-testid="startDate"
                value={moment(startDate)}
                format="YYYY/MM/DD"
                leftArrowIcon={<PrevArrowIcon />}
                rightArrowIcon={<NextArrowIcon />}
                onChange={(newDate) => this.handleDateChange('startDate', newDate?.valueOf() || null)}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div className={classes.dialogContentElement}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <DatePicker
                label="Date de retour"
                data-testid="endDate"
                value={moment(endDate)}
                format="YYYY/MM/DD"
                leftArrowIcon={<PrevArrowIcon />}
                rightArrowIcon={<NextArrowIcon />}
                onChange={(newDate) => this.handleDateChange('endDate', newDate?.valueOf() || null)}
              />
            </MuiPickersUtilsProvider>
          </div>

          <FormControl>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
              Raison
            </InputLabel>
            <Select
              id="reason"
              value={reason}
              data-testid="reason"
              onChange={(event) => this.handleChange('reason', event)}
            >
              {Object.values(AbsenceReason).map((currentReason) => (
                <MenuItem value={currentReason} key={currentReason}>
                  {absenceReasonsTranslations[currentReason]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="comments"
            data-testid="comments"
            onChange={(event) => this.handleChange('comments', event)}
            label="Commentaires"
            multiline
            value={comments}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} data-testid="close-dialog-btn" className={classes.cancelBtn}>
            Fermer
          </Button>
          <Button onClick={this.saveAbsence} data-testid="save-btn" color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AbsenceDialog);
