import moment from 'moment-timezone';
import {
  ACTIVITIES_ACT_TYPE,
  ACTIVITIES_LUMP_SUM_TYPE,
  ACTIVITIES_MIXTE_TYPE
} from '../collection/collectionConstants';
import { getEndOfDay, getStartOfDay, isWeekEnd } from '../utils/dateTime/dateTimeUtils';
import {
  BILLING_LUMPSUM_PERIOD_GAP,
  BILLING_MIXTE_PERIOD_GAP,
  BILLING_PERIOD_DURATION,
  DEFAULT_BILLING_START_DATE,
  DEFAULT_MIXTE_START_DATE,
  DEFAULT_STATEMENT_DATE
} from './constants';
import LUMPSUM_DISPENSARY_AREA_MAP from '../ramq/domainValues/lumpsumDispensaryAreas';
import MIXTE_DISPENSARY_AREA_MAP from '../ramq/domainValues/mixteDispensaryAreas';

export const getPreviousBillingPeriodEndDateFromDate = (date) =>
  getEndOfDay(getBillingStartDateFromDate(date).subtract(1, 'day'));

export const getWeekBasedStartDateFromDate = (date, periodDuration) =>
  getPeriodStartDateFromDate(DEFAULT_MIXTE_START_DATE, date, periodDuration);

export const getBillingStartDateFromDate = (date) =>
  getPeriodStartDateFromDate(DEFAULT_BILLING_START_DATE, date, BILLING_PERIOD_DURATION);

const getPeriodStartDateFromDate = (validPeriodStartDate, date, periodDuration) => {
  const momentDate = getStartOfDay(moment(date));
  const defaultPeriodStartDate = moment(validPeriodStartDate);
  const daysBetweenDateAndValidPeriodStart = momentDate.diff(defaultPeriodStartDate, 'days');

  let daysFromNearestStartDate = getNumberOfDaysFromNearestStartDate(
    daysBetweenDateAndValidPeriodStart,
    periodDuration
  );
  if (daysAreNegative(daysBetweenDateAndValidPeriodStart) && daysFromNearestStartDate !== 0) {
    daysFromNearestStartDate = adjustForNegativeValue(daysFromNearestStartDate);
  }

  const currentPeriodStartDate = momentDate.clone().subtract(daysFromNearestStartDate, 'day');
  return getStartOfDay(currentPeriodStartDate);
};

export const getNumberOfDaysFromNearestStartDate = (daysBetweenDateAndValidPeriodStart, periodDuration) =>
  daysBetweenDateAndValidPeriodStart % periodDuration;

const daysAreNegative = (daysBetweenDateAndValidPeriodStart) => daysBetweenDateAndValidPeriodStart < 0;

const adjustForNegativeValue = (negativeDaysFromStartDate) => negativeDaysFromStartDate + 14;

export const getWeekBasedEndDateFromDate = (date, periodDuration) => {
  const periodStartDate = getWeekBasedStartDateFromDate(date, periodDuration);
  return getEndOfDay(periodStartDate.clone().add(periodDuration - 1, 'day'));
};

export const getBillingEndDateFromDate = (date) => {
  const periodStartDate = getBillingStartDateFromDate(date);
  return getEndOfDay(periodStartDate.clone().add(BILLING_PERIOD_DURATION - 1, 'day'));
};

export const createEmptyDayElement = (date) => ({
  date: createPeriodDayDate(date)
});

const getAlreadySavedDays = (dateToProcess, savedDays) =>
  savedDays.filter((day) => day.date === dateToProcess.valueOf());

export const createEmptyDayElementWithDefaultDispensaryArea = (date, activityType) => ({
  date: createPeriodDayDate(date),
  activityArea:
    activityType === ACTIVITIES_LUMP_SUM_TYPE
      ? LUMPSUM_DISPENSARY_AREA_MAP.generalCare
      : MIXTE_DISPENSARY_AREA_MAP.generalCare
});

function addDay(activityType, alreadySavedDays, days, dateToProcess) {
  if (alreadySavedDays.length > 0) {
    days.push(...alreadySavedDays);
  } else {
    days.push(createEmptyDayElementWithDefaultDispensaryArea(dateToProcess, activityType));
  }
}

export const createPeriodDays = (
  activityType,
  currentPeriodStartDate,
  periodDuration,
  savedDaysToBeMerged,
  allowPeriodsOnWeekEnd = false
) => {
  const days = [];
  const dateToProcess = currentPeriodStartDate.clone();

  for (let i = 0; i < periodDuration; i += 1) {
    if (!isWeekEnd(dateToProcess) || allowPeriodsOnWeekEnd) {
      const alreadySavedDays = getAlreadySavedDays(dateToProcess, savedDaysToBeMerged);
      addDay(activityType, alreadySavedDays, days, dateToProcess);
    }
    dateToProcess.add(1, 'day');
  }

  return days;
};

export const getPreviousWeekPeriods = (date, periodsCount, periodDuration) =>
  getPreviousPeriodsFromValidStartDate(DEFAULT_MIXTE_START_DATE, date, periodsCount, periodDuration);

export const getPreviousBillingPeriods = (date, periodsCount) =>
  getPreviousPeriodsFromValidStartDate(DEFAULT_BILLING_START_DATE, date, periodsCount, BILLING_PERIOD_DURATION);

const getPreviousPeriodsFromValidStartDate = (validPeriodStartDate, date, periodsCount, periodDuration) => {
  const periods = [];
  const currentPeriodStartDate = getPeriodStartDateFromDate(validPeriodStartDate, date, periodDuration);
  for (let i = 0; i < periodsCount; i += 1) {
    periods.push({
      startDate: currentPeriodStartDate.clone(),
      endDate: getEndOfDay(currentPeriodStartDate.clone().add(periodDuration - 1, 'day'))
    });
    currentPeriodStartDate.subtract(periodDuration, 'day');
  }
  return periods;
};

export const getBillingEndDates = (endDatesCount) => {
  const previousPeriods = getPreviousBillingPeriods(moment(), endDatesCount);

  return previousPeriods.map((period) => period.endDate);
};

export const getBillingPeriodFromDate = (date) => {
  const periodDescription = {};
  const billingStartDate = getBillingStartDateFromDate(date);
  const billingEndDate = getBillingEndDateFromDate(date);

  periodDescription[ACTIVITIES_ACT_TYPE] = {
    startDate: billingStartDate,
    endDate: billingEndDate
  };

  periodDescription[ACTIVITIES_MIXTE_TYPE] = {
    startDate: billingStartDate.clone().subtract(BILLING_MIXTE_PERIOD_GAP, 'days'),
    endDate: billingEndDate.clone().subtract(BILLING_MIXTE_PERIOD_GAP, 'days')
  };

  periodDescription[ACTIVITIES_LUMP_SUM_TYPE] = {
    startDate: billingStartDate.clone().subtract(BILLING_LUMPSUM_PERIOD_GAP, 'days'),
    endDate: billingEndDate.clone().subtract(BILLING_LUMPSUM_PERIOD_GAP, 'days')
  };

  return periodDescription;
};

export const createPeriodDayDate = (date) => getStartOfDay(date).valueOf();

export const getStatementDatesSince = (date) => {
  const statementDatesSince = [];
  const momentDate = getStartOfDay(date);
  const daysBetweenDefaultAndDate = moment(date).diff(moment(DEFAULT_STATEMENT_DATE), 'days');
  const differenceBetweenDateAndLastStatement = daysBetweenDefaultAndDate % 14;
  let nextStatementDate;
  if (differenceBetweenDateAndLastStatement >= 0) {
    nextStatementDate = momentDate.add(14 - differenceBetweenDateAndLastStatement, 'days');
  } else {
    nextStatementDate = momentDate.add(Math.abs(differenceBetweenDateAndLastStatement), 'days');
  }

  if (nextStatementDate.valueOf() < moment().valueOf()) {
    statementDatesSince.push(nextStatementDate.valueOf());
  }

  let newStatementDate = nextStatementDate;
  while (newStatementDate.valueOf() < moment().subtract(14, 'days').valueOf()) {
    newStatementDate = newStatementDate.add(14, 'days');
    statementDatesSince.push(newStatementDate.valueOf());
  }

  return statementDatesSince;
};
