import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useParams } from 'react-router-dom';
import ActivityStatus from '../../../../../shared/core/activity/domain/ActivityStatus';
import {
  selectActs24HoursBefore,
  selectCurrentCodes,
  selectRempTime,
  selectSelectedNam,
  selectStartTime
} from '../../../../containers/ActForm/selectors';
import FormRow from '../../FormRow/FormRow';
import SameCodeWithoutNamWarning from './SameCodeWithoutNamWarning';
import SameNamWithin24HoursWarning from './SameNamWithin24HoursWarning';
import ActWithoutPreviousOnGuardActWarning from './ActWithoutPreviousOnGuardActWarning/ActWithoutPreviousOnGuardActWarning';

export const styles = () => ({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 0
  }
});

const ActsWarnings = ({ previous24HourActs, currentNam, currentCodes, startTime, rempTime, classes }) => {
  const { activityId } = useParams();

  const getPrevious24HoursAct = () =>
    previous24HourActs.filter((act) => act.id !== activityId && act.status !== ActivityStatus.CANCELED);

  const previous24HoursActs = getPrevious24HoursAct();

  return (
    <FormRow className={classes.container}>
      <SameNamWithin24HoursWarning
        currentNam={currentNam}
        currentCodes={currentCodes}
        previous24HoursActs={previous24HoursActs}
      />
      <SameCodeWithoutNamWarning
        currentNam={currentNam}
        previous24HoursActs={previous24HoursActs}
        currentCodes={currentCodes}
      />
      <ActWithoutPreviousOnGuardActWarning
        startTime={rempTime || startTime}
        currentCodes={currentCodes}
        previous24HoursActs={previous24HoursActs}
      />
    </FormRow>
  );
};

ActsWarnings.propTypes = {
  currentNam: PropTypes.string,
  previous24HourActs: PropTypes.array.isRequired,
  currentCodes: PropTypes.array.isRequired,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rempTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ActsWarnings.defaultProps = {
  currentNam: '',
  startTime: undefined,
  rempTime: undefined,
  user: undefined
};

export const mapStateToProps = createStructuredSelector({
  startTime: selectStartTime(),
  rempTime: selectRempTime(),
  currentNam: selectSelectedNam(),
  previous24HourActs: selectActs24HoursBefore(),
  currentCodes: selectCurrentCodes()
});

export default compose(connect(mapStateToProps), withStyles(styles))(ActsWarnings);
