import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { useLocation, useNavigate } from 'react-router-dom';
import { getActiveBillsNumber } from '../../../../../../shared/models/utils/activitiesUtils';
import ActMainInformation from '../../../../../components/Activities/Act/ActMainInformation/ActMainInformation';
import ActSummaryActivityHeader from '../../../../../components/Activities/Act/ActSummaryHeader/ActSummaryHeader';
import Details from '../../../../../components/Activities/Act/Details/Details';
import ActivityContainer from '../../../../../components/Activities/ActivityContainer/ActivityContainer';
import ActivitySummaryWrapper from '../../../../../components/Activities/ActivitySummaryWrapper/ActivitySummaryWrapper';
import ActivityOutcomeResponses from '../../../../../components/Ramq/RamqExchange/ActivityOutcomeResponses/ActivityOutcomeResponses';
import MultipleActiveBillWarning from '../../../../../components/Ramq/RamqExchange/MultipleActiveBillWarning/MultipleActiveBillWarning';
import { ACTS_ROUTE } from '../../../../Navigation/constants';
import { selectUserIdInContext } from '../../../../User/selectors';
import ActAdminActions from '../ActAdminActions/ActAdminActions';
import ActivityCommentLists from './ActivityCommentLists/ActivityCommentLists';

export const styles = (theme) => ({
  listItemContainer: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5)
  },
  billingType: {
    position: 'absolute',
    top: theme.spacing(4)
  }
});

const ActSummaryActivity = ({ activity, userId, isSelected, classes }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeBills = getActiveBillsNumber(activity.ramqExchange);

  return (
    <ActivitySummaryWrapper activity={activity} isSelected={isSelected}>
      <ActivityContainer
        button
        onClick={() =>
          navigate({
            pathname: `${ACTS_ROUTE}/${activity.id}`,
            search: location.search
          })
        }
        className={classes.listItemContainer}
        isGuard={activity.guard}
      >
        <ActSummaryActivityHeader activity={activity} />
        <ActMainInformation activity={activity} />
        <Details open act={activity} />
      </ActivityContainer>
      <Restrict atLeastRole={Role.Agent}>
        <ActivityCommentLists activity={activity} />
        <MultipleActiveBillWarning activeBills={activeBills} />
        <ActivityOutcomeResponses activity={activity} />
        <ActAdminActions activity={activity} userId={userId} activeBills={activeBills} />
      </Restrict>
    </ActivitySummaryWrapper>
  );
};

ActSummaryActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool
};

ActSummaryActivity.defaultProps = {
  isSelected: false
};

export const mapStateToProps = createStructuredSelector({
  userId: selectUserIdInContext()
});

export default compose(withStyles(styles), connect(mapStateToProps))(ActSummaryActivity);
