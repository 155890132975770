import Act, { isAct } from '../../../../shared/domain/activity/act/model/Act';
import Activity from '../../../../shared/domain/activity/global/model/Activity';
import { ROLE3 } from '../../../../shared/ramq/contextElements/globalContextElements';
import { isEmpty } from '../../../../shared/utils/objectManipulation';

export const multipleR3CodesWarning = (form: Partial<Activity>) => {
  const jsForm = form as Activity;

  if (isEmpty(jsForm) || !isAct(jsForm)) {
    return [];
  }

  const act = jsForm as Act;

  if (act.contextElements?.includes(ROLE3) && act.codes.length > 1) {
    return ['Seul le code principal peut être facturé lors d’un R3'];
  }

  return [];
};

export default multipleR3CodesWarning;
