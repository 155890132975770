import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import { SingleLineField } from '../../../../../components/Form/Fields';
import BasicField from '../../../../../components/Form/BasicField';
import Alert from '../../../../../components/Alert/Alert';
import AlertSeverity from '../../../../../ui/shared/severity/AlertSeverity';

const OutsideRamqRefugeeForm: React.FunctionComponent = () => (
  <CardContent>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        Identification du patient
      </Typography>
      <Divider />
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.lastName" label="Nom du patient" required component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.firstName"
          label="Prénom du patient"
          required
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.iuc" label="IUC" component={BasicField} />
        <Alert
          message="SVP ajouter IUC patient ou joindre photo du formulaire immigration"
          severity={AlertSeverity.INFO}
          noMargin
        />
      </Grid>
    </Grid>
  </CardContent>
);

export default OutsideRamqRefugeeForm;
