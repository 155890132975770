import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router-dom';
import queryBuilder from 'shared/collection/QueryBuilder';
import { activitiesCollectionRef } from 'app/firebase/collection/collectionReferences';
import { selectUserIdInContext } from 'app/containers/User/selectors';
import { getAllDataFromSnapshotWithBoundId } from 'app/firebase/collection/snapshotDataFetcher';
import { DATE_RANGE_FILTER } from '../../../../shared/collection/queryConstants';
import { MIXTE_PERIOD_DAYS_DURATION } from '../../../../shared/periods/constants';
import { getWeekBasedEndDateFromDate, getWeekBasedStartDateFromDate } from '../../../../shared/periods/periods';
import { getActivities, getActivitiesSuccess } from '../../Activities/actions';
import {
  ACTS_ROUTE,
  TRAINING_ROUTE,
  LUMP_SUM_ROUTE,
  MIXTE_ROUTE,
  TRAVEL_EXPENSES_ROUTE
} from '../../Navigation/constants';
import ActRoute from './ActRoute/ActRoute';
import DayActivities from './DayActivities/DayActivities';
import LumpSumRoute from './LumpSumRoute/LumpSumRoute';
import MixteRoute from './MixteRoute/MixteRoute';
import TravelExpensesRoute from './TravelExpensesRoute/TravelExpensesRoute';
import TrainingRoute from './TrainingRoute/TrainingRoute';

const DayRoute = ({ getActivities }) => {
  const { date } = useParams();
  const dispatch = useDispatch();
  const userId = useSelector(selectUserIdInContext());
  const isExperimental = useSelector((state) => state.user?.profile?.isExperimentalMemoryOptimization ?? false);
  const [currentPeriodRange, setCurrentPeriodRange] = useState(null);

  useEffect(() => {
    const dateNumber = date && Number(date);
    const startDate = getWeekBasedStartDateFromDate(dateNumber, MIXTE_PERIOD_DAYS_DURATION).subtract(1, 'day');
    const endDate = getWeekBasedEndDateFromDate(dateNumber, MIXTE_PERIOD_DAYS_DURATION);

    if (
      currentPeriodRange &&
      currentPeriodRange.startDate.isSame(startDate) &&
      currentPeriodRange.endDate.isSame(endDate)
    ) {
      return;
    }

    setCurrentPeriodRange({ startDate, endDate });
  }, [currentPeriodRange, date]);

  useEffect(() => {
    if (!currentPeriodRange || !userId) return;

    if (isExperimental) {
      const query = queryBuilder
        .withBaseQuery(activitiesCollectionRef())
        .withUserId(userId)
        .sortOnDateField()
        .buildFromFilters({ [DATE_RANGE_FILTER]: currentPeriodRange });

      const unsubscribe = query.onSnapshot((snapshot) => {
        dispatch(getActivitiesSuccess(getAllDataFromSnapshotWithBoundId(snapshot)));
      });

      return () => unsubscribe();
    }

    getActivities({ [DATE_RANGE_FILTER]: currentPeriodRange });
  }, [dispatch, getActivities, currentPeriodRange, userId, isExperimental]);

  return (
    <Routes>
      <Route path={`${ACTS_ROUTE}/*`} element={<ActRoute />} />
      <Route path={`${MIXTE_ROUTE}/*`} element={<MixteRoute />} />
      <Route path={`${LUMP_SUM_ROUTE}/*`} element={<LumpSumRoute />} />
      <Route path={`${TRAVEL_EXPENSES_ROUTE}/*`} element={<TravelExpensesRoute />} />
      <Route path={`${TRAINING_ROUTE}/*`} element={<TrainingRoute />} />
      <Route index element={<DayActivities />} />
    </Routes>
  );
};

DayRoute.propTypes = {
  getActivities: PropTypes.func.isRequired
};

DayRoute.defaultProps = {
  parentRoute: ''
};

export const mapDispatchToProps = {
  getActivities
};

export default connect(null, mapDispatchToProps)(DayRoute);
