import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import sortBy from 'lodash/sortBy';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import type MedyxTheme from '../../../../../../utils/muiTheme';
import { TotalAmountByCode } from '../../../../../../shared/amountComputation/amount/PeriodAmountService';

export const styles = (theme: typeof MedyxTheme) => ({
  totalAmountContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTop: `2px solid ${theme.palette.divider}`
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  }
});

export interface Props {
  totalAmountByCodes: TotalAmountByCode[];
  classes?: any;
}

export const PeriodSubTotalRow: React.FunctionComponent<Props> = ({ totalAmountByCodes, classes }) => (
  <Grid container className={classnames(classes.totalAmountContainer, 'sub-total-amount')} spacing={0}>
    <Grid item xs={9}>
      <Typography variant="body2">
        <b>Sous Total</b>
      </Typography>
    </Grid>
    <Grid item xs={3}>
      <Grid container>
        {sortBy(totalAmountByCodes, ['code']).map(({ code, amount }) => (
          <Fragment key={code}>
            <Grid item xs={8}>
              <Typography variant="body2" className={classes.alignCenter}>
                {code}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" className="total-amount">
                {currencyFormat(amount)}
              </Typography>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

export default withStyles(styles)(PeriodSubTotalRow);
