import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { WithStyles } from '@material-ui/core';
import { SubmitHandler } from 'redux-form/lib/reduxForm';
import {
  createErrorMapWithEveryValidationFunction,
  requiredFieldValidations,
  throwSubmissionErrorIfNecessary
} from '../../../../validations/common/reduxFormValidation';
import DialogCenteredTitle from '../../../Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import MultiSelectCheckboxGroup from '../../Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';
import CodesSelectorField from '../../custom/CodesSelectorField';
import { NumberField, SingleLineField } from '../../Fields';
import FormElement from '../../FormElement/FormElement';
import FormRow from '../../FormRow/FormRow';
import { BillingGroup } from '../SpecificBillingGroupField/SpecificBillingGroupField';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';

export const styles = (theme) =>
  ({
    dialog: {
      width: '100%',
      maxWidth: 600,
      minHeight: 500
    },
    groupNameTitle: {
      paddingBottom: theme.spacing(2)
    }
  }) as const;

const ACTIVITIES_TYPES_OPTIONS = [
  { value: ActivityType.MIXTE, label: 'Mixte' },
  { value: ActivityType.LUMP_SUM, label: 'Forfaitaire' }
];

export interface Props extends WithStyles<typeof styles> {
  initialValue?: BillingGroup;
  onClose: () => void;
  open: boolean;
  handleSubmit: SubmitHandler<FormData, any>;
  /* eslint-disable no-unused-vars */
  initialize(data: any): void;
  onItemSelected: (billingGroup: BillingGroup) => void;
  /* eslint-enable */
}

export class BillingGroupDialog extends React.PureComponent<Props> {
  private nameInput: React.RefObject<HTMLInputElement> | null;

  constructor(props: Props) {
    super(props);

    this.nameInput = null;
  }

  handleDialogEnter = () => {
    const { initialValue, initialize } = this.props;

    initialize({
      codes: [],
      types: [],
      ...initialValue
    });

    this.nameInput?.current?.focus();
  };

  public handleSave = (values) => {
    const { onItemSelected, onClose } = this.props;

    const errorMap = createErrorMapWithEveryValidationFunction(values, requiredFieldValidations(['name', 'number']));

    throwSubmissionErrorIfNecessary(errorMap);

    onItemSelected({
      name: values.name,
      number: values.number,
      codes: values.codes,
      types: values.types
    } as BillingGroup);

    onClose();
  };

  render() {
    const { onClose, open, handleSubmit, classes } = this.props;

    return (
      <Dialog
        id="billing-group-dialog"
        open={open}
        TransitionProps={{ onEnter: this.handleDialogEnter }}
        classes={{ paper: classes.dialog }}
      >
        <DialogCenteredTitle label="Groupe de facturation spécifique" className={classes.groupNameTitle} />
        <DialogContent>
          <FormRow>
            <FormElement
              inputRef={(inputRef) => {
                this.nameInput = inputRef;
              }}
              name="name"
              label="Nom"
              component={SingleLineField}
            />
          </FormRow>
          <FormRow>
            <FormElement name="number" label="Numéro" component={NumberField} />
          </FormRow>
          <FormRow>
            <FormElement name="codes" label="Acte" component={CodesSelectorField} />
          </FormRow>
          <FormRow>
            <FormElement
              name="types"
              label="Types Activités"
              component={MultiSelectCheckboxGroup}
              list={ACTIVITIES_TYPES_OPTIONS}
            />
          </FormRow>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Annuler
          </Button>
          <Button id="saveBtn" onClick={handleSubmit(this.handleSave)} color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'billingGroupForm'
  })
)(BillingGroupDialog);
