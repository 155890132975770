/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import PATIENT_EVENTS from 'shared/domain/activity/act/PatientEvent';
import DialogCenteredTitle from '../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import messages from './messages';

export class PatientEventsDialog extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  onItemSelect = (event) => {
    if (event) {
      this.props.onItemSelected({
        id: event.id,
        description: event.description
      });
    } else {
      this.props.onItemSelected(null);
    }

    this.props.onClose();
  };

  render() {
    const { open, value } = this.props;

    return (
      <Dialog id="patient-event-dialog" fullWidth open={open} onClose={this.handleClose}>
        <DialogCenteredTitle label={<FormattedMessage {...messages.title} />} />
        <DialogContent>
          <List>
            {PATIENT_EVENTS.map((event) => (
              <ListItem button onClick={() => this.onItemSelect(event)} key={event.id}>
                <ListItemText primary={`${event.id} - ${event.description}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          {value && (
            <Button onClick={() => this.onItemSelect()} color="primary">
              <FormattedMessage {...messages.erase} />
            </Button>
          )}
          <Button id="close-Dialog" onClick={() => this.handleClose()} color="primary">
            <FormattedMessage {...messages.close} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

PatientEventsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired
};

export default compose(withMobileDialog())(PatientEventsDialog);
