import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import InputDialogTrigger from '../../../components/Dialogs/DialogTrigger/InputDialogTrigger/InputDialogTrigger';
import FormRow from '../../../components/Form/FormRow/FormRow';
import { selectSelectedActPlace } from '../selectors';

export const styles = (theme) => ({
  input: {
    paddingRight: theme.spacing(6)
  },
  collapseWrapper: {
    width: '100%'
  },
  collapseContainer: {
    paddingTop: theme.spacing(1)
  },
  placeSelectorContainer: {
    marginBottom: theme.spacing(1)
  }
});

export function PlaceSelector({
  classes,
  currentSelectedPlace,
  input,
  label,
  meta,
  formatValueFunction,
  DialogComponent
}) {
  return (
    <FormRow className={classes.placeSelectorContainer}>
      <InputDialogTrigger
        inputClasses={classes.input}
        input={input}
        label={label}
        meta={meta}
        formatValueFunction={formatValueFunction}
        DialogComponent={DialogComponent}
        dialogProps={{
          currentSelectedPlace
        }}
      />
    </FormRow>
  );
}

PlaceSelector.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  formatValueFunction: PropTypes.func.isRequired,
  DialogComponent: PropTypes.object.isRequired,
  currentSelectedPlace: PropTypes.object
};

PlaceSelector.defaultProps = {
  currentSelectedPlace: undefined
};

export const mapStateToProps = createStructuredSelector({
  currentSelectedPlace: selectSelectedActPlace()
});

export default compose(connect(mapStateToProps, null), withStyles(styles))(PlaceSelector);
