import { get, set } from 'lodash';
import LimitedDiagnosticWithPatientContextField from '../../../components/Form/ActSpecificField/Diagnostics/LimitedDiagnosticWithPatientContextField';
import { SingleLineField } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import { isNotAValidValue } from '../../../validations/common/reduxFormValidation';

const diagnosticIs6699AndImcIsInvalid = (code) =>
  code.code === '6946' && code.diagnostic && code.diagnostic.code === '6699' && isNotAValidValue(code.imc);

const imcIsRequiredError = (index) =>
  set({ elementIdToScrollTo: `codes[${index}].imc` }, `codes[${index}].imc`, 'IMC est requis pour le diagnostic 6699');

export const imcRequiredIfDiagnosticIs6699 = (act) => {
  const codesInError = act.codes.map((code, index) =>
    diagnosticIs6699AndImcIsInvalid(code) ? imcIsRequiredError(index) : {}
  );
  return codesInError.reduce(combineErrors, {});
};

const summaryRender = (code) => [
  {
    name: 'diagnostic',
    value: get(code, 'diagnostic.code', '-')
  },
  {
    name: 'IMC',
    value: get(code, 'imc', '-')
  }
];

const createAssociatedFields = (diagnosticList) => [
  {
    label: 'Diagnostic',
    props: { diagnosticList },
    getName: (index) => `codes[${index}].diagnostic`,
    type: LimitedDiagnosticWithPatientContextField
  },
  {
    label: 'IMC',
    getName: (index) => `codes[${index}].imc`,
    notRequired: true,
    type: SingleLineField
  }
];

const associatedValidations = [imcRequiredIfDiagnosticIs6699];

const limitedDiagnostic = (diagnosticList) => ({
  associatedFields: createAssociatedFields(diagnosticList),
  associatedValidations,
  summaryRender
});

export default limitedDiagnostic;
