interface DataStructure {
  items?: DataStructure[];
  subCategories?: DataStructure[];
}

export default function recursiveItemsCallback(
  dataStructures: DataStructure[],
  callback: (item: DataStructure) => DataStructure
) {
  return dataStructures.map((dataStructure) => {
    if (dataStructure.items || dataStructure.subCategories) {
      return {
        ...dataStructure,
        ...(dataStructure.items ? { items: recursiveItemsCallback(dataStructure.items, callback) } : {}),
        ...(dataStructure.subCategories
          ? { subCategories: recursiveItemsCallback(dataStructure.subCategories, callback) }
          : {})
      };
    }

    return callback(dataStructure);
  });
}
