/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useEffect, useState } from 'react';
import { firestore } from 'server/Firebase';

export enum FeatureFlags {
  MAINTENANCE = 'maintenance'
}

export default function useFeatureFlags(featureFlagKey: FeatureFlags): boolean {
  const [isFeatureFlagEnabled, setIsFeatureFlagEnabled] = useState<boolean>(false);

  useEffect(
    () =>
      firestore
        .collection('medyxGlobalInfos')
        .doc('feature-flags')
        .onSnapshot((snapshot) => {
          const featureFlags = snapshot.data() ?? {};
          setIsFeatureFlagEnabled(!!featureFlags[featureFlagKey]);
        }),
    [featureFlagKey, setIsFeatureFlagEnabled]
  );

  return isFeatureFlagEnabled;
}
