import Divider from '@material-ui/core/Divider';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import withStyles, { StyleRulesCallback, WithStyles } from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import AccordionActions from '@material-ui/core/AccordionActions';
import { Theme } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'qs';
import FiltersDisplayer from '../../../../../components/FiltersDisplayer/FiltersDisplayer';
import ActivitiesSearchOptions from '../../../../ActivitiesSearch/ActivitiesSearchOptions';
import SearchUrlParameterController from '../../../../ActivitiesSearch/SearchUrlParameter/SearchUrlParameterController';
import ActivityFilterApplier from '../../../../ActivitiesSearch/FilterApplier/ActivityFilterApplier';
import ActivitiesSearchBar from '../../../../ActivitiesSearch/SearchBar/ActivitiesSearchBar';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  AccordionContainer: {
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  AccordionDetails: {
    display: 'flex',
    flexDirection: 'column'
  }
});

interface Props extends WithStyles<typeof styles> {}

const UsersSearchBox = ({ classes }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo<any>(
    () => QueryString.parse(location.search, { ignoreQueryPrefix: true }),
    [location.search]
  );
  const [expanded, setExpanded] = useState(false);
  const [searchActivitiesFiltersState, setSearchActivitiesFiltersState] = useState(queryParams);
  const [activitiesTextFilter, setActivitiesTextFilter] = useState(queryParams.textFilter ?? '');

  const updateFilters = (filter) => {
    const filterApplier = new ActivityFilterApplier();
    setSearchActivitiesFiltersState(filterApplier.apply(searchActivitiesFiltersState, filter));
  };

  return (
    <Accordion className={classes.AccordionContainer} expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded((prevState) => !prevState)}>
        <FiltersDisplayer filters={searchActivitiesFiltersState} />
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionDetails}>
        <ActivitiesSearchOptions
          searchActivitiesFilters={searchActivitiesFiltersState}
          onChangeSearchFilter={updateFilters}
        />
        <ActivitiesSearchBar value={activitiesTextFilter} changeHandler={setActivitiesTextFilter} />
      </AccordionDetails>
      <AccordionActions>
        <Button
          color="primary"
          onClick={() => {
            const search = new SearchUrlParameterController(new ActivityFilterApplier()).newSearchQuery(
              {
                ...searchActivitiesFiltersState,
                ...(activitiesTextFilter && { textFilter: activitiesTextFilter }),
                ...(queryParams.user && { user: queryParams.user })
              },
              null
            );
            setExpanded(false);
            navigate({ search });
          }}
        >
          Rechercher
        </Button>
      </AccordionActions>
      <Divider />
    </Accordion>
  );
};

export default withStyles(styles)(UsersSearchBox);
