import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import { sumObjectValues } from '../StatementReportUtils';
import { StatementLine, StatementLineOnAboveZeroAmount } from './StatementDoctorInfo';
import StatementSectionContainer from './StatementSectionContainer';

export function StatementInterestAndAdvances({ generalInformation }) {
  const interestAndAdvancesTotalAmount = generalInformation.interestAndAdvancesLines
    .reduce(sumObjectValues(), 0)
    .toFixed(2);
  return (
    <StatementSectionContainer withDivider>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Intérêts et avances: ', currencyFormat(interestAndAdvancesTotalAmount))}
          secondaryLines={generalInformation.interestAndAdvancesLines.map((line) => (
            <>
              {StatementLineOnAboveZeroAmount('Intérêts solde négatif', line.interestOnNegativeBalance)}
              {StatementLineOnAboveZeroAmount('Intérêts recouvrement', line.interestOnRecovery)}
              {StatementLineOnAboveZeroAmount('Intérêts avance', line.interestOnAdvance)}
              {StatementLineOnAboveZeroAmount('Avances recouvrés', line.recoveredAdvances)}
              {StatementLineOnAboveZeroAmount('Avances émises', line.issuedAdvances)}
            </>
          ))}
        />
      </Grid>
    </StatementSectionContainer>
  );
}

StatementInterestAndAdvances.propTypes = {
  generalInformation: PropTypes.object.isRequired
};

export default StatementInterestAndAdvances;
