import React, { FC, useMemo, useState } from 'react';
import Dropdown from 'application/components/dropdown';
import useTranslate from 'application/hooks/use-translate';
import UsersDevices from 'application/pages/administration/stats/users-devices';
import UsersGrowth from 'application/pages/administration/stats/users-growth';
import UsersSpecialties from 'application/pages/administration//stats/users-specialties';
import ActivitiesUsage from 'application/pages/administration//stats/activities-usage';
import ActivitiesByStatus from 'application/pages/administration//stats/activities-by-status';
import ActivityCodesCount from 'application/pages/administration//stats/activity-codes-count';

export interface ConfiguratorProps<T> {
  params: T;
  onChange: (updatedParams: T) => void;
}

export interface RendererProps<T> {
  params: T;
}

type StatComponent<T> = {
  Configurator: FC<{ params: T; onChange: (updatedParams: T) => void }>;
  Renderer: FC<{ params: T }>;
};

const getComponentFromValue = (value: string): StatComponent<object> => {
  switch (value) {
    case 'UsersGrowth':
      return UsersGrowth;
    case 'UsersSpecialties':
      return UsersSpecialties;
    case 'ActivitiesUsage':
      return ActivitiesUsage;
    case 'ActivitiesByStatus':
      return ActivitiesByStatus;
    case 'ActivityCodesCount':
      return ActivityCodesCount;
    case 'UsersDevices':
      return UsersDevices;
    default:
      throw new Error('Invalid value');
  }
};

const StatisticsPage = () => {
  const translate = useTranslate('pages.administration.stats');
  const [params, setParams] = useState({});

  const options = useMemo(
    () => [
      { label: translate('users-growth'), value: 'UsersGrowth' },
      { label: translate('users-by-specialty'), value: 'UsersSpecialties' },
      { label: translate('activities-by-user'), value: 'ActivitiesUsage' },
      { label: translate('activities-by-status'), value: 'ActivitiesByStatus' },
      { label: translate('activity-codes-count'), value: 'ActivityCodesCount' },
      { label: translate('users-devices'), value: 'UsersDevices' }
    ],
    [translate]
  );

  const [selectedStatistic, setSelectedStatistic] = useState<string | null>(options[0]?.value);

  const StatComponent = selectedStatistic && getComponentFromValue(selectedStatistic);

  return (
    <div className="rounded-md bg-base-100 p-4 shadow">
      <div className="flex justify-between">
        <Dropdown className="flex" onChange={setSelectedStatistic} value={selectedStatistic} options={options} />

        {StatComponent && <StatComponent.Configurator params={params} onChange={setParams} />}
      </div>

      <div className="flex flex-col items-center justify-between">
        {StatComponent && <StatComponent.Renderer params={params} />}
      </div>
    </div>
  );
};

export default StatisticsPage;
