import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { isEmpty, reverse, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import HybridListSelector from '../../../components/Form/ListSelector/HybridListSelector/HybridListSelector';
import PrintHeader from '../PrintHeader/PrintHeader';
import StatementsRenderer from './StatementRenderer/StatementRenderer';
import { selectUserProfileInContext } from '../../User/selectors';
import FullScreenLoading from '../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { fetchStatementsForDoctor } from '../../../infrastructure/api/ReportAPI';
import { mapToStatementSelector } from './StatementUtils';

export const styles = (theme) => ({
  root: {
    height: 'max-content',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: 0
    }
  }
});

const DEFAULT_DATE_MESSAGE = 'Choisir une date';

export class Statement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: DEFAULT_DATE_MESSAGE,
      statements: [],
      loading: false,
      selectedDateLabel: undefined,
      dateChoices: []
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    if (this.props.user && this.props.user.practiceNumber !== null) {
      this.fetchStatements();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user && this.props.user && prevProps.user.practiceNumber !== this.props.user.practiceNumber) {
      if (this.props.user.practiceNumber !== null) {
        this.fetchStatements();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeSelectedDate = (evt) => {
    const selectedDate = evt.target.value;
    const { dateChoices } = this.state;
    const selectedDateChoice = dateChoices.find((dateChoice) => dateChoice.value === selectedDate);
    if (selectedDateChoice) this.setState({ selectedDateLabel: selectedDateChoice.label });

    this.setState({
      selectedDate
    });
  };

  assignComponentRef = (el) => {
    this.componentRef = el;
  };

  async fetchStatements() {
    const { user } = this.props;
    this.setState({ loading: true });

    try {
      const { data } = await fetchStatementsForDoctor({ practiceNumber: user.practiceNumber });
      if (!this._isMounted) return;

      const statementsDates = reverse(sortBy(data, 'statementDate').map((statement) => statement.statementDate)).map(
        mapToStatementSelector
      );
      this.setState({ statements: data, dateChoices: [DEFAULT_DATE_MESSAGE, ...statementsDates] });
    } finally {
      if (this._isMounted) {
        this.setState({ loading: false });
      }
    }
  }

  renderData() {
    const { classes } = this.props;
    const { selectedDate, statements, loading } = this.state;

    if (loading) {
      return <FullScreenLoading />;
    }

    if (isEmpty(statements)) {
      return <Typography>Aucun état de compte trouvé</Typography>;
    }

    return (
      <Paper className={classes.root}>
        <StatementsRenderer statements={statements} selectedDate={selectedDate} ref={this.assignComponentRef} />
      </Paper>
    );
  }

  render() {
    const { selectedDate, dateChoices, selectedDateLabel } = this.state;
    const formattedDateLabel = (selectedDateLabel && `- ${selectedDateLabel}`) || '';
    const { user } = this.props;

    return (
      <>
        <PrintHeader
          componentToPrintRef={() => this.componentRef}
          SelectorComponents={[
            () => (
              <HybridListSelector
                disableFirstOption
                input={{ onChange: this.changeSelectedDate, value: selectedDate }}
                values={dateChoices}
              />
            )
          ]}
          title={`EC ${formattedDateLabel} - ${user?.title} ${user?.firstName} ${user?.lastName}.pdf`}
        />

        {this.renderData()}
      </>
    );
  }
}

Statement.propTypes = {
  user: PropTypes.object
};

export const mapStateToProps = createStructuredSelector({
  user: selectUserProfileInContext()
});

export default compose(connect(mapStateToProps), withStyles(styles))(Statement);
