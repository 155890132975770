import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import CopyFavorites from 'app/containers/User/Profile/Partials/CopyFavorites';
import SpecialtyName from '../../../../../shared/core/doctor/domain/SpecialtyName';
import { SelectorField, SwitchField } from '../../../../components/Form/Fields';
import FormElement from '../../../../components/Form/FormElement/FormElement';

export const actCodePresetListOptions = [
  {
    value: SpecialtyName.ANESTHESIOLOGIST,
    label: 'Anesthésiologie'
  },
  {
    value: 'cardiology',
    label: 'Anesthésiologie - IUCPQ'
  },
  {
    value: SpecialtyName.GENERAL_SURGEON,
    label: 'Chirurgie générale'
  },
  {
    value: SpecialtyName.ORTHOPEDIC_SURGEON,
    label: 'Chirurgie orthopédique'
  },
  {
    value: SpecialtyName.CARDIOLOGIST,
    label: 'Cardiologie'
  },
  {
    value: SpecialtyName.ENDOCRINOLOGIST,
    label: 'Endocrinologie'
  },
  {
    value: SpecialtyName.INTERNIST,
    label: 'Médecine interne'
  },
  {
    value: SpecialtyName.OTOLARYNGOLOGIST,
    label: 'Oto-rhino-laryngologie'
  },
  {
    value: SpecialtyName.PNEUMOLOGIST,
    label: 'Pneumologie'
  },
  {
    value: 'poolChildren',
    label: 'Pool Anesthésiogie - Children'
  }
];

function FeaturesConfiguration({ ocrEnabled, change, readonly }) {
  const handleChangeOcrEnabledFlag = (event, isOcrEnabled) => {
    if (!isOcrEnabled) {
      change('multipleNAMDiscoveryEnabled', false);
    }
  };

  const handleChangeMixteGenerationEnabled = (event, isMixteGenerationEnabled) => {
    if (isMixteGenerationEnabled) {
      change('backendMixteEnabled', false);
    }
  };

  const handleChangeBackendMixteEnabled = (event, isBackendMixteEnabled) => {
    if (isBackendMixteEnabled) {
      change('mixteGenerationEnabled', false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormElement
          label="Intégration des états de compte dans les actes"
          name="statementIntegration"
          defaultTrue
          disabled={readonly}
          component={SwitchField}
        />

        <FormElement
          label="ROC (OCR) - Reconnaissance optique de caractères"
          name="ocrEnabled"
          disabled={readonly}
          component={SwitchField}
          onChange={handleChangeOcrEnabledFlag}
        />

        <FormElement
          label="Reconnaissance de NAM multiple"
          name="multipleNAMDiscoveryEnabled"
          disabled={ocrEnabled === false || readonly}
          component={SwitchField}
        />

        <FormElement label="Bottin Medyx" name="medyxRegistryEnabled" disabled={readonly} component={SwitchField} />

        <FormElement
          label="Dupliquer une journée"
          name="duplicateAnotherDayActivities"
          disabled={readonly}
          component={SwitchField}
        />

        <FormElement
          label="Signature électronique"
          name="electronicSignatureEnabled"
          disabled={readonly}
          component={SwitchField}
        />

        <FormElement
          label="DP facturée par date d'envoi"
          name="paymentRequestsSentInPeriod"
          disabled={readonly}
          component={SwitchField}
        />

        <FormElement
          label="Transfert automatique - SYSTEM"
          name="automaticProcessingActEnabled"
          component={SwitchField}
        />

        <FormElement label="Suggestions intelligentes" name="activityRecommendationsEnabled" component={SwitchField} />

        <FormElement
          label="Génération de mixte (Frontend)"
          name="mixteGenerationEnabled"
          component={SwitchField}
          onChange={handleChangeMixteGenerationEnabled}
        />

        <FormElement
          label="Mixte Backend"
          name="backendMixteEnabled"
          component={SwitchField}
          onChange={handleChangeBackendMixteEnabled}
        />

        <FormElement
          label="Empêcher la génération de mixte pour les codes d'enseignement"
          name="skipMixteGenerationForTeachingCodes"
          component={SwitchField}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="h6">
          Favoris Modifiables
        </Typography>

        <Typography gutterBottom variant="subtitle1" style={{ marginTop: '24px' }}>
          Codes d'actes
        </Typography>

        <FormElement
          label="Codes d'actes favoris modifiables"
          name="editableFavoritesEnabled"
          disabled={readonly}
          component={SwitchField}
        />

        <FormElement
          label="Inclure la liste de code d'actes spécifique à la spécialité"
          name="actCodeSpecialtyListIncluded"
          disabled={readonly}
          component={SwitchField}
        />

        <Grid item xs={4}>
          <FormElement
            name="actCodeSpecialtyListChoice"
            disabled={readonly}
            component={SelectorField}
            list={actCodePresetListOptions}
          />
        </Grid>

        <Restrict atLeastRole={Role.Admin}>
          <div style={{ marginTop: '24px' }}>
            <CopyFavorites />
          </div>
        </Restrict>

        <Typography gutterBottom variant="subtitle1" style={{ marginTop: '48px' }}>
          Codes de diagnostiques
        </Typography>

        <FormElement
          label="Codes de diagnostiques favoris modifiables"
          name="editableFavoritesDiagnosticEnabled"
          disabled={readonly}
          component={SwitchField}
        />

        <Typography gutterBottom variant="subtitle1" style={{ marginTop: '34px' }}>
          Médecins
        </Typography>

        <FormElement
          label="Médecins favoris modifiables"
          name="editableFavoritesDoctorEnabled"
          disabled={readonly}
          component={SwitchField}
        />

        <Typography gutterBottom variant="subtitle1" style={{ marginTop: '34px' }}>
          Éléments de contexte
        </Typography>

        <FormElement
          label="Éléments de contexte favoris modifiables"
          name="editableFavoritesContextElementsEnabled"
          disabled={readonly}
          component={SwitchField}
        />
      </Grid>

      <Restrict atLeastRole={Role.Admin}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            Fonctionnalités expérimentales
          </Typography>

          <FormElement
            label="Mémoire - Chargement des activités et page d'accueil assitant"
            name="isExperimentalMemoryOptimization"
            disabled={readonly}
            component={SwitchField}
          />
        </Grid>
      </Restrict>
    </Grid>
  );
}

FeaturesConfiguration.propTypes = {
  ocrEnabled: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  readonly: PropTypes.bool
};

FeaturesConfiguration.defaultProps = {
  readonly: false
};

export default FeaturesConfiguration;
