import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import queryString from 'qs';
import { MenuItem, Paper, Select } from '@material-ui/core';
import BillingType from 'shared/domain/billing/model/BillingType';
import { createStructuredSelector } from 'reselect';
import { selectActType } from 'app/containers/PeriodsBilling/selectors';
import { connect } from 'react-redux';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { useLocation, useNavigate } from 'react-router-dom';
import BillingTypeSelector from '../../BillingTypeSelector';
import FormElement from '../FormElement/FormElement';

const styles = (theme) => ({
  root: {
    flex: 'none',
    maxWidth: 700,
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  poolSelect: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

interface Props {
  classes?: any;
  billingTypeFieldName: string;
  poolNumberFieldName: string;
  currentBillingType: BillingType;
  actType: ActivityType;
  user: {
    pools?: Array<{
      id: string;
      name: string;
      number: string;
    }>;
  };
  onChange?: (billingType: string) => void;
}

const billingTypeComponent = ({ input: { onChange, value } }) => (
  <BillingTypeSelector onChange={onChange} value={value} />
);

const poolNumberComponent = ({ user, selectClassName, input: { onChange, value } }) => (
  <Select className={selectClassName} autoWidth={false} value={value} onChange={onChange}>
    {user.pools.map((pool) => (
      <MenuItem key={pool.id} value={pool.number}>
        ({pool.number}) {pool.name}
      </MenuItem>
    ))}
  </Select>
);

const BillingTypeField: React.FunctionComponent<Props> = ({
  classes,
  user,
  billingTypeFieldName,
  poolNumberFieldName,
  currentBillingType,
  actType,
  onChange
}): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Paper square className={classes.root}>
      <FormElement
        name={billingTypeFieldName}
        component={billingTypeComponent}
        className={classes.root}
        onChange={(newBillingType) => {
          if ([ActivityType.MIXTE, ActivityType.LUMP_SUM].includes(actType)) {
            const currentParams = queryString.parse(location.search, { ignoreQueryPrefix: true });

            return navigate(
              `${location.pathname}?${queryString.stringify({
                ...currentParams,
                billingType: newBillingType,
                ...(newBillingType === BillingType.PRIV && { pool: undefined })
              })}`
            );
          }

          if (onChange) onChange(newBillingType);
        }}
      />

      {user.pools && user.pools.length > 1 && [BillingType.POOL, BillingType.END].includes(currentBillingType) && (
        <FormElement
          name={poolNumberFieldName}
          component={poolNumberComponent}
          user={user}
          className={classes.root}
          selectClassName={classes.poolSelect}
          onChange={(_event, newPoolNumber) => {
            if ([ActivityType.MIXTE, ActivityType.LUMP_SUM].includes(actType)) {
              const currentParams = queryString.parse(location.search, { ignoreQueryPrefix: true });

              return navigate(
                `${location.pathname}?${queryString.stringify({
                  ...currentParams,
                  pool: newPoolNumber
                })}`
              );
            }
          }}
        />
      )}
    </Paper>
  );
};

const mapStateToProps = createStructuredSelector({
  actType: selectActType()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps, null))(BillingTypeField) as React.FunctionComponent<
  Omit<Props, 'actType' | 'history' | 'match'>
>;
