import { actFormFields } from 'app/containers/ActForm/constants';
import Act from 'shared/domain/activity/act/model/Act';
import { GREATER_THAN_4_HOURS } from 'shared/ramq/contextElements/globalContextElements';

const invalidCodes = ['911'];

const NO_ERRORS = {};

const greaterThan4HoursValidation = (act: Act) => {
  const { contextElements = [], codes = [] } = act;

  const invalidCode = codes.find((code) => invalidCodes.includes(code.code));

  if (invalidCode && contextElements.includes(GREATER_THAN_4_HOURS)) {
    return {
      [actFormFields.codes]: {
        _error: `Le code ${invalidCode.code} est incompatible avec l'élément de contexte 1922 (>4H, règle 16). Veuillez le retirer.`
      }
    };
  }

  return NO_ERRORS;
};

export default greaterThan4HoursValidation;
