import get from 'lodash/get';
import { namFormula } from '../../../../shared/ramq/namUtils/namUtils';

export const NAM_PATIENT_TYPE = 'NAM';

export const isValidNam = (nam) => {
  if (!nam) return false;

  const noWhiteSpaceNam = nam.replace(/ /g, '').toUpperCase();
  if (noWhiteSpaceNam.length === 12 || noWhiteSpaceNam.length === 16) {
    const noExpirationDateNam = noWhiteSpaceNam.slice(0, 12);
    return noExpirationDateNam && validateNamStructure(noExpirationDateNam) && checkNamValidity(noExpirationDateNam);
  }
  return false;
};

const checkNamValidity = (nam) => validateNamWithCentury(nam, '19') || validateNamWithCentury(nam, '20');

const validateNamWithCentury = (nam, century) => {
  const NAMFormula = namFormula(nam, century);
  const reducedValue = reduceDecomposedString(NAMFormula) % 10;
  const validator = nam.slice(11, 13);

  return String(reducedValue) === validator;
};

const reduceDecomposedString = (decomposedNAMString) => {
  const reducer = (total, currentValue, index) =>
    total + ramqCharacterMap.get(decomposedNAMString.charAt(index)) * currentValue;
  return ramqPositionMultiplicator.reduce(reducer, 0);
};

const validateNamStructure = (nam) => {
  const match = nam.match(/^([A-Z]{4})(\d{2})(\d{2})(\d{2})(\d)(\d)$/);
  if (!match) return false;

  const [_match, _namePart, _birthYear, rawBirthMonth, rawBirthDate, _twinNumber, _validationNumber] = match;

  // We use modulo 50 to handle birth month having +50 for women
  const birthMonth = parseInt(rawBirthMonth, 10) % 50;
  if (birthMonth < 1 || birthMonth > 12) return false;

  const birthDate = parseInt(rawBirthDate, 10);
  if (birthDate < 1 || birthDate > 31) return false;

  return true;
};

export default isValidNam;

const ramqCharacterMap = new Map([
  ['A', 193],
  ['B', 194],
  ['C', 195],
  ['D', 196],
  ['E', 197],
  ['F', 198],
  ['G', 199],
  ['H', 200],
  ['I', 201],
  ['J', 209],
  ['K', 210],
  ['L', 211],
  ['M', 212],
  ['N', 213],
  ['O', 214],
  ['P', 215],
  ['Q', 216],
  ['R', 217],
  ['S', 226],
  ['T', 227],
  ['U', 228],
  ['V', 229],
  ['W', 230],
  ['X', 231],
  ['Y', 232],
  ['Z', 233],
  ['0', 240],
  ['1', 241],
  ['2', 242],
  ['3', 243],
  ['4', 244],
  ['5', 245],
  ['6', 246],
  ['7', 247],
  ['8', 248],
  ['9', 249]
]);

const ramqPositionMultiplicator = [1, 3, 7, 9, 1, 7, 1, 3, 4, 5, 7, 6, 9, 1];

export const isPatientTypeNam = (act) => get(act, 'patientInformation.type', NAM_PATIENT_TYPE) === NAM_PATIENT_TYPE;
