import moment from 'moment';

export const END_TIME_WHEN_DURATION_OVER_30_MINUTES = 'endTimeWhenDurationIsOver30Minutes';
export const END_TIME_WHEN_DURATION_OVER_60_MINUTES = 'endTimeWhenDurationIsOver60Minutes';

export const isLessThan30MinsDifferenceWithActStartDate = (actStart, code) => {
  if (!actStart || !code[END_TIME_WHEN_DURATION_OVER_30_MINUTES]) {
    return false;
  }

  const endMoment = moment(code[END_TIME_WHEN_DURATION_OVER_30_MINUTES]);
  return endMoment.diff(actStart, 'minutes') < 31;
};

export const isLessThan60MinsDifferenceWithActStartDate = (actStart, code) => {
  if (!actStart || !code[END_TIME_WHEN_DURATION_OVER_60_MINUTES]) {
    return false;
  }

  const endMoment = moment(code[END_TIME_WHEN_DURATION_OVER_60_MINUTES]);
  return endMoment.diff(actStart, 'minutes') < 61;
};
