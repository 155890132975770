import classNames from 'classnames';
import React from 'react';

interface Props {
  className?: string;
  size?: number;
}

const Spinner = ({ className, size = 32 }: Props) => (
  <div className={classNames('spinner', className)} style={{ height: size, width: size }}>
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
    <div className="spinner-dot" />
  </div>
);

export default Spinner;
