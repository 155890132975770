import { Moment } from 'moment-timezone';
import Activity from './Activity';

export interface Act extends Activity {
  codes: ActCode[];
  start: Moment;
}

export interface ActCode {
  [key: string]: any;
  code: string;
  natureType?: NatureType;
}

export enum NatureType {
  ANESTHESIOLOGY = 'Anesthésiologie',
  PDT = 'PDT',
  SURGERY = 'Chirurgie',
  VISIT = 'Visite',
  FORFAIT = 'Forfait'
}

export default Act;
