export const GET_USERS = 'getUsers';
export const GET_USERS_SUCCESS = 'getUsersSuccess';
export const GET_USERS_ERROR = 'getUsersError';
export const SEARCH_USERS = 'searchUsers';
export const GET_USERS_SEARCH_SUCCESS = 'getUsersSearchSuccess';
export const USERS_SEARCH = 'usersSearch';
export const SEARCH_USERS_SUCCESS = 'searchUsersSuccess';
export const SEARCH_USERS_ERROR = 'searchUsersError';
export const SEARCHED_USER_IDS_CHANGED = 'searchedUserIdsChanged';

export function searchedUserIdsChanged(userIds) {
  return {
    type: SEARCHED_USER_IDS_CHANGED,
    userIds
  };
}

export function getUsers() {
  return {
    type: GET_USERS
  };
}

export function getUsersSuccess(users) {
  return {
    type: GET_USERS_SUCCESS,
    users
  };
}

export function getUsersError() {
  return {
    type: GET_USERS_ERROR
  };
}

export function searchUsers(filters) {
  return {
    type: SEARCH_USERS,
    filters
  };
}

export function searchUsersSuccess(searchResult) {
  return {
    type: GET_USERS_SEARCH_SUCCESS,
    searchResult
  };
}

export function getUsersByFiltersSuccess(userIds) {
  return {
    type: SEARCH_USERS_SUCCESS,
    userIds
  };
}

export function getUsersByFiltersError() {
  return {
    type: SEARCH_USERS_ERROR
  };
}
