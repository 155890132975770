import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import DefaultDateValueField from '../../../components/Form/DefaultDateValueField/DefaultDateValueField';
import { NumberField, SelectorField, SingleLineField } from '../../../components/Form/Fields';
import SpacedFormElement from '../../../components/Form/SpacedFormElement/SpacedFormElement';
import { LINE_SPECIFIC_POSSIBLE_CONTEXT_ELEMENTS, TRANSPORT_TYPES_LIST } from '../constants';
import FeeBox from '../FeeBox';
import RestrictedAdditionalTransportContextElement from '../RestrictedAdditionalTransportContextElement';
import { selectStartDate } from '../selectors';

function TravelTimeFee({ fieldName, fieldIndex, fields }) {
  const translate = useTranslate();
  return (
    <FeeBox fieldIndex={fieldIndex} fields={fields} label={translate('travel-expenses.travel-time')}>
      <Grid container style={{ padding: 16 }}>
        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.date`}
            label={translate('act.date')}
            component={DefaultDateValueField}
            dateSelector={selectStartDate}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.transportType`}
            label={translate('travel-expenses.transportation-type')}
            component={SelectorField}
            formControlProps={{ fullWidth: true }}
            values={TRANSPORT_TYPES_LIST}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.goingKilometers`}
            label={translate('travel-expenses.nb-km-departure')}
            component={NumberField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.returnKilometers`}
            label={translate('travel-expenses.nb-km-return')}
            component={NumberField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.travelingHours`}
            label={translate('travel-expenses.nb-hours-traveling')}
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SpacedFormElement
            name={`${fieldName}.waitingHours`}
            label={translate('travel-expenses.nb-hours-waiting')}
            component={SingleLineField}
          />
        </Grid>
      </Grid>
      <RestrictedAdditionalTransportContextElement
        fieldName={fieldName}
        label="E. Contexte (ligne spécifique)"
        list={LINE_SPECIFIC_POSSIBLE_CONTEXT_ELEMENTS}
      />
    </FeeBox>
  );
}

TravelTimeFee.propTypes = {
  fieldIndex: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired
};

export default TravelTimeFee;
