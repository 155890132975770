import PropTypes from 'prop-types';
import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';

export const styles = () => ({
  menuIcon: {
    marginRight: '15px'
  }
});

export class MoreActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose(actionEvent) {
    return () => {
      this.setState({ anchorEl: null }, () => {
        if (actionEvent) {
          actionEvent();
        }
      });
    };
  }

  render() {
    const { anchorEl } = this.state;
    const { actions, classes } = this.props;

    return (
      actions.length > 0 && (
        <>
          <IconButton id="menu-button" aria-controls="menu" aria-haspopup="true" onClick={this.handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleClose()}>
            {actions.map(({ key, labelKey, Icon, clickEvent }) => (
              <MenuItem id={key} key={key} dense onClick={this.handleClose(clickEvent)}>
                {Icon && <Icon className={classes.menuIcon} />}
                <FormattedMessage id={labelKey} />
              </MenuItem>
            ))}
          </Menu>
        </>
      )
    );
  }
}

MoreActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      labelKey: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      Icon: PropTypes.func,
      clickEvent: PropTypes.func.isRequired
    })
  ).isRequired
};

export default withStyles(styles)(MoreActions);
