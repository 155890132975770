import { Mixte } from '../../../../shared/domain/activity/mixte/model/Mixte';

const MIXTE_CREATION_REQUEST = 'MIXTE_CREATION_REQUEST';

export type MixteCreationRequestPayload = {
  document: Mixte;
  userProfile: any;
};

export default MIXTE_CREATION_REQUEST;
