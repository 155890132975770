import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';

const openInAppBrowser = (href) => {
  window.cordova.InAppBrowser.open(href, '_system');
};

function LinkButton({ href, target, children, ...props }) {
  if (window.cordova) {
    return (
      <Button onClick={() => openInAppBrowser(href)} {...props}>
        {children}
      </Button>
    );
  }

  return (
    <Button href={href} target={target} {...props}>
      {children}
    </Button>
  );
}

LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default LinkButton;
