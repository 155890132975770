import React, { useCallback, useState } from 'react';
import { MenuItem, withStyles, WithStyles } from '@material-ui/core';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { ActCode } from '../../../shared/domain/activity/act/model/Act';
import { buildUnitsString, findUserSpecialtyRelatedCodeUnits } from '../../act/shared/domain/roles/roles.utils';
import CollapsableIcon from '../CollapsableIcon/CollapsableIcon';

export const styles = ({ breakpoints }) =>
  ({
    root: {
      [breakpoints.down('xs')]: {
        paddingLeft: '10px'
      }
    },
    collapsedRoot: {
      [breakpoints.down('xs')]: {
        height: '15px'
      }
    },
    expandedRoot: {
      height: 'fit-content'
    },
    text: {
      width: '100%',
      overflowX: 'hidden'
    },
    collapsedText: {
      textOverflow: 'ellipsis',
      marginRight: '26px'
    },
    expandedText: {
      whiteSpace: 'break-spaces'
    },
    collapseButton: {
      padding: '0px 8px',
      minWidth: '3px',
      minHeight: '1px',
      lineHeight: '1rem'
    },
    hidden: {
      display: 'none'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  suggestion: ActCode;
  isSelected: boolean;
  onClick: () => void;
}

export const ExpandableActCodeSuggestion: React.FunctionComponent<Props> = ({
  classes,
  suggestion,
  isSelected,
  onClick
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const additionalClasses = useCallback(
    (global: any, whenExpanded: any, whenCollapsed: any) =>
      classnames(global, { [whenExpanded]: expanded, [whenCollapsed]: !expanded }),
    [expanded]
  );

  return (
    <MenuItem
      selected={isSelected}
      classes={{ root: additionalClasses(classes.root, classes.expandedRoot, classes.collapsedRoot) }}
      onClick={onClick}
      id={suggestion.code}
    >
      <div className={additionalClasses(classes.text, classes.expandedText, classes.collapsedText)}>
        <span data-testid="code-description">{`${suggestion.code} - ${suggestion.description}`}</span>
        <Typography
          data-testid="units"
          className={classnames({ [classes.hidden]: !expanded })}
          variant="body2"
          color="textSecondary"
        >
          {buildUnitsString(findUserSpecialtyRelatedCodeUnits(suggestion.code))}
        </Typography>
      </div>
      <CollapsableIcon
        expanded={expanded}
        selected={false}
        label=""
        onClick={(event) => {
          event.stopPropagation();
          setExpanded(!expanded);
        }}
      />
    </MenuItem>
  );
};

export default withStyles(styles)(ExpandableActCodeSuggestion);
