import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { get, omit } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'qs';
import useTranslate from 'application/hooks/use-translate';
import DialogCenteredTitle from '../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import { closeActivitiesSearch } from './actions';
import ActivitiesSearchOptions from './ActivitiesSearchOptions';
import { selectSearchingActivities } from './selectors';
import ActivitiesSearchBar from './SearchBar/ActivitiesSearchBar';
import SearchUrlParameterController from './SearchUrlParameter/SearchUrlParameterController';
import ActivityFilterApplier from './FilterApplier/ActivityFilterApplier';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import { ACTIVITIES_SEARCH_ROUTE } from '../Navigation/constants';

interface Props {
  onCloseSearch: () => any;
  open: boolean;
  fullScreen?: boolean;
}

interface Filters {
  type: ActivityType[];
  status: ActivityStatus[];
  dateRange: Partial<{
    startDate: number;
    endDate: number;
  }>;
}

const activityFilterApplierStrategy = new ActivityFilterApplier();
const NO_NEW_FILTER = null;

const ActivitiesSearchDialog = ({ onCloseSearch, open, fullScreen }: Props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = useMemo(() => QueryString.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const [textFilter, setTextFilter] = useState(get(queryParams ?? {}, 'textFilter', ''));
  const [filtersState, setFiltersState] = useState<Partial<Filters>>(omit(queryParams ?? {}, 'textFilter'));

  const launchSearch = () => {
    onCloseSearch();

    let finalFilters: any = { ...filtersState };
    if (textFilter) {
      finalFilters = { ...finalFilters, textFilter };
    }

    const newSearchQuery = new SearchUrlParameterController(activityFilterApplierStrategy).newSearchQuery(
      finalFilters,
      NO_NEW_FILTER
    );

    navigate({ pathname: ACTIVITIES_SEARCH_ROUTE, search: newSearchQuery });
  };

  const onFilterChanged = (newFilter: object) => {
    const newFilters = activityFilterApplierStrategy.apply(filtersState, newFilter);
    setFiltersState(newFilters);
  };

  return (
    <Dialog fullScreen={fullScreen} fullWidth maxWidth="md" open={open} onClose={onCloseSearch}>
      <DialogCenteredTitle className="" label="Rechercher" />
      <DialogContent>
        <div style={{ position: 'relative' }}>
          <ActivitiesSearchOptions searchActivitiesFilters={filtersState} onChangeSearchFilter={onFilterChanged} />
        </div>
        <div style={{ position: 'relative' }}>
          <ActivitiesSearchBar value={textFilter} changeHandler={setTextFilter} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button data-testid="cancel-search" onClick={onCloseSearch} color="primary">
          {translate('global.cancel')}
        </Button>
        <Button data-testid="launch-search" color="primary" onClick={launchSearch}>
          {translate('global.search')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = createStructuredSelector({
  open: selectSearchingActivities()
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCloseSearch: closeActivitiesSearch
    },
    dispatch
  );

export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(ActivitiesSearchDialog as any));
