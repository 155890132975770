import { UserInfo as FirebaseUser } from 'firebase/auth';
import { UserModel } from '../../../../shared/domain/authentication/AuthUser';
import Role from '../../../../shared/domain/authentication/Role';
import Permission from '../../../../shared/domain/authorization/model/Permission';

class FirebaseUserToDomainUserObjectMapper {
  static map(firebaseUser: FirebaseUser, role: Role, permissions: Permission[]): UserModel {
    return {
      uid: firebaseUser.uid!,
      displayName: firebaseUser.displayName!,
      email: firebaseUser.email!,
      role,
      permissions
    };
  }
}

export default FirebaseUserToDomainUserObjectMapper;
