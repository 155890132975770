import FollowupType from 'shared/ramq/requiredDetails/FollowupType';
import { getStore } from 'app/reduxStore/configureStore';
import { selectPatientDiagnostic } from 'app/containers/ActForm/selectors';
import Act, { ActCode } from 'shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from 'app/act/recommendations/types';
import { isPotentiallyCancerDiagnostic } from 'shared/diagnostic/domain/isCancerDiagnostic';

const addCancerAsFollowupType = (act: Partial<Act>): Recommendation<ActCode[] | undefined>[] => {
  const reduxStore = getStore().getState();
  let patientDiagnostics = selectPatientDiagnostic()(reduxStore) ?? [];

  if (!Array.isArray(patientDiagnostics)) {
    patientDiagnostics = [patientDiagnostics];
  }

  if (!act.codes) {
    return NO_RECOMMENDATIONS;
  }

  if (
    !hasThePatientCancerDiagnostic([...patientDiagnostics, act.patientDiagnostic]) ||
    isThePatientFollowUpDefinedForAllCodes(act.codes)
  ) {
    return NO_RECOMMENDATIONS;
  }
  return addCancerAsFollowupTypeToAllCodesInAct();
};

function addCancerAsFollowupTypeToAllCodesInAct(): Recommendation<ActCode[] | undefined>[] {
  return [
    {
      message: `Ajout du type de suivi ${FollowupType.CANCER} pour les codes sans type de suivi patient`,
      apply: (partialAct) => {
        const oldValue = partialAct.codes;
        const newValue = (partialAct.codes || []).map((actCode) => {
          if (isFollowupRequiredDetailPresentForCode(actCode) && !isFollowupDetailSelected(actCode)) {
            return {
              ...actCode,
              followupType: FollowupType.CANCER
            };
          }
          return actCode;
        });
        return {
          fieldName: 'codes',
          newValue,
          oldValue
        };
      }
    }
  ];
}
const hasThePatientCancerDiagnostic = (patientDiagnostics: any[]) =>
  patientDiagnostics.filter(Boolean).some((diagnostic) => isPotentiallyCancerDiagnostic(diagnostic));

const isThePatientFollowUpDefinedForAllCodes = (actCodes: ActCode[] | undefined) =>
  actCodes?.every((code) => !isFollowupRequiredDetailPresentForCode(code) || isFollowupDetailSelected(code));

const isFollowupRequiredDetailPresentForCode = (actCode: ActCode): boolean => actCode.followupType !== undefined;

const isFollowupDetailSelected = (actCode: ActCode): boolean => actCode.followupType !== null;

export default addCancerAsFollowupType;
