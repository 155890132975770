import { get } from 'lodash';
import { SingleLineField } from '../../../components/Form/Fields';

const summaryRender = (code) => [{ name: 'apgar', value: get(code, 'apgar', '-') }];

const associatedFields = [
  {
    label: 'Apgar',
    getName: (index) => `codes[${index}].apgar`,
    type: SingleLineField,
    notRequired: true
  }
];

const apgar = { associatedFields, summaryRender };

export default apgar;
