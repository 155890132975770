import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import isDefined from '../../../../../shared/utils/isDefined';
import PeriodsSummaryActivity from '../../PeriodsBilling/PeriodsSummaryActivity/PeriodsSummaryActivity';
import { TRAINING_ROUTE } from '../../../../containers/Navigation/constants';
import TrainingTimeSummary from '../TrainingTimeSummary/TrainingTimeSummary';

const shouldDisplayTrainingDay = (day) => (isDefined(day.perdiems) && day.perdiems.length > 0) || isDefined(day.code);

const TrainingSummaryActivity = ({ activity, isSelected }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PeriodsSummaryActivity
      activity={activity}
      shouldDisplayDay={shouldDisplayTrainingDay}
      onSelect={() =>
        navigate({
          pathname: `${TRAINING_ROUTE}/${activity.id}`,
          search: location.search
        })
      }
      TimeSummaryComponent={TrainingTimeSummary}
      isSelected={isSelected}
    />
  );
};

TrainingSummaryActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  isSelected: PropTypes.bool
};

TrainingSummaryActivity.defaultProps = {
  isSelected: false
};

export default TrainingSummaryActivity;
