import PATIENT_EVENTS from 'shared/domain/activity/act/PatientEvent';

export const parsePhoneNumber = (value) => (value ? value.replace(/\D/g, '') : null);

export const displayPatientEvent = (value) => {
  if (value) {
    const eventId = value.id;
    const eventDescription = PATIENT_EVENTS.find((event) => event.id === eventId)?.description;

    if (eventId && eventDescription) {
      return `${eventId} - ${eventDescription}`;
    }
  }

  return '';
};

export const displayPlace = (value) => {
  if (value?.number && value?.name) {
    return `(${value.number}) ${value.name}`;
  }
  return '';
};

const formatDoctor = (doctor) => {
  const practiceNumber = doctor.practiceNumber ? `(${doctor.practiceNumber}) ` : '';
  return `${practiceNumber}${doctor.firstName || ''} ${doctor.lastName || ''}`;
};

export const displayDoctor = (value) => (value ? formatDoctor(value) : '');

export const displayCode = (value) => (value ? `(${value.code}) ${value.description}` : '');

export const displayGroup = (value) => (value ? `(${value.number}) ${value.name}` : '');
