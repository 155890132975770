import { logDefault, logError } from '../../../shared/utils/plog';
import { sleep } from '../../../utils/timeUtils';

const docsHaveAuditInformation = (doc, otherDoc) =>
  !!doc && !!doc.auditInformation && !!otherDoc && !!otherDoc.auditInformation;

export const isAuditInfoIdentical = (doc, otherDoc) =>
  docsHaveAuditInformation(doc, otherDoc) &&
  doc.auditInformation.modifiedBy === otherDoc.auditInformation.modifiedBy &&
  doc.auditInformation.modifiedOn === otherDoc.auditInformation.modifiedOn;

export const isModifiedOnAfter = (savedDoc, docFromCache) =>
  docsHaveAuditInformation(savedDoc, docFromCache) &&
  savedDoc.auditInformation.modifiedOn <= docFromCache.auditInformation.modifiedOn;

export const verifyDocumentSavedProperly = async (documentRef, auditedDocument, methodName) => {
  logDefault({
    type: 'verification',
    text: 'verifyDocumentSavedProperly/start',
    array: [`verifyDocumentSavedProperly ${documentRef.id}`]
  });
  await sleep(3000);

  const verificationPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(
        new Error(
          `${methodName} Document did not save properly, could not get it from cache within 1000ms ${documentRef.id}`
        )
      );
    }, 1000);

    documentRef
      .get({ source: 'cache' })
      .then((doc) => {
        if (!doc.exists) {
          reject(
            new Error(`${methodName} Document did not save properly, it does not exist in cache ${documentRef.id}`)
          );
        } else if (isAuditInfoIdentical(auditedDocument, doc.data())) {
          resolve(`${methodName} Document saved properly to cache ${doc.id}`);
        } else if (isModifiedOnAfter(auditedDocument, doc.data())) {
          resolve(
            `${methodName} WARN modifiedOn is newer in cache than what we just saved` +
              `${JSON.stringify(doc.data().auditInformation)}  DIFFERENT ${JSON.stringify(
                auditedDocument.auditInformation
              )}`
          );
        } else {
          reject(
            new Error(
              `${methodName} Document did not save properly, auditInformation is not identical ${documentRef.id},` +
                `${JSON.stringify(doc.data().auditInformation)}  DIFFERENT ${JSON.stringify(
                  auditedDocument.auditInformation
                )}`
            )
          );
        }
      })
      .catch((e) => reject(e));
  });

  return verificationPromise
    .then((message) =>
      logDefault({
        type: 'verification',
        text: 'verifyDocumentSavedProperly/success',
        array: [message]
      })
    )
    .catch((e) =>
      logError({
        type: 'verification',
        text: 'verifyDocumentSavedProperly/FAILURE',
        array: [`FAILURE on document ${documentRef.id}`, e]
      })
    );
};
