export const APP_RESUMED = 'appResumed';
export const appResumed = () => ({
  type: APP_RESUMED
});

export const APP_STARTED = 'appStarted';
export const appStarted = () => ({
  type: APP_STARTED
});

export const APP_PAUSED = 'appPaused';
export const appPaused = () => ({
  type: APP_PAUSED
});
