import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  isCancelExchange,
  isModificationRequest,
  isPaymentExplanation,
  isPaymentOrModificationRequest
} from '../../../../../../shared/models/utils/activitiesUtils';
import {
  isPaymentConfirmed,
  isPaymentExchange
} from '../../../../../../shared/periods/common/deferredPaymentConfirmation';
import {
  ACTIVITY_PAYMENT_EXPLANATION_LINE_TYPE,
  ACTIVITY_PAYMENT_EXPLANATION_TYPE,
  ACTIVITY_PAYMENT_STATEMENT_LINE_TYPE
} from '../../../../../../shared/ramq/constants';
import DateSummary from '../DateSummary/DateSummary';

export const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary[15],
    flexDirection: 'column',
    justifyContent: 'center'
  },
  paymentResponse: {
    backgroundColor: theme.palette.green[300]
  },
  secondaryAction: {
    right: theme.spacing(1)
  },
  date: {
    'alignSelf': 'center',
    '&:first-child': {
      paddingLeft: theme.spacing(3)
    }
  },
  billInfo: {
    alignSelf: 'flex-start',
    padding: 0,
    paddingLeft: theme.spacing(2)
  },
  absoluteThis: {
    position: 'absolute'
  }
});

const getTotalAmount = (billLines) => sumBy(billLines, (line) => parseFloat(line.amount)).toFixed(2);

const getLinesTotalAmount = (ramqResponse, lineType) =>
  ramqResponse.lines
    .filter((line) => line.type === lineType)
    .reduce((accumulator, currentValue) => parseFloat(currentValue.amount) + accumulator, 0)
    .toFixed(2);

const getSummaryTextAccordingToRamqResponseType = (ramqResponse) => {
  if (isPaymentOrModificationRequest(ramqResponse)) {
    return `${getTotalAmount(ramqResponse.lines)}$`;
  }
  if (isCancelExchange(ramqResponse)) {
    return 'Annulée';
  }
  if (isPaymentExplanation(ramqResponse)) {
    const explanationTotalAmount = getLinesTotalAmount(ramqResponse, ACTIVITY_PAYMENT_EXPLANATION_LINE_TYPE);
    const statementTotalAmount = getLinesTotalAmount(ramqResponse, ACTIVITY_PAYMENT_STATEMENT_LINE_TYPE);

    if (ramqResponse.attestationNumber) {
      return `Solde: ${ramqResponse.balance || '-'}`;
    }

    return `EC: ${statementTotalAmount} | EX: ${explanationTotalAmount} | Solde: ${ramqResponse.balance || '-'}`;
  }
  if (isPaymentConfirmed(ramqResponse)) {
    return 'Confirmée';
  }
  return 'Envoyée';
};

const getBillInformationNumberAccordingToResponseType = (ramqResponse) => {
  if (isPaymentExchange(ramqResponse)) {
    return `# Attestation: ${ramqResponse.attestationNumber}`;
  }
  if (isModificationRequest(ramqResponse)) {
    return `Facture modifiée: ${ramqResponse.billNumber}`;
  }
  if (isPaymentExplanation(ramqResponse)) {
    return `Paiement: ${ramqResponse.billNumber || ramqResponse.attestationNumber}`;
  }
  return `Facture: ${ramqResponse.billNumber}`;
};

const getResponseColor = (ramqResponse, classes) =>
  ramqResponse.type === ACTIVITY_PAYMENT_EXPLANATION_TYPE ? classes.paymentResponse : '';

export function ActivityResponseSummary({ ramqResponse, classes }) {
  const summaryText = getSummaryTextAccordingToRamqResponseType(ramqResponse);
  const billInformationNumber = getBillInformationNumberAccordingToResponseType(ramqResponse);
  const containerClassName = classNames(classes.container, getResponseColor(ramqResponse, classes));
  return (
    <ListItem button className={containerClassName}>
      <DateSummary date={ramqResponse.createdOn} className={classes.absoluteThis} />
      <ListItemText className={classes.billInfo} primary={billInformationNumber} />
      <ListItemSecondaryAction className={classes.secondaryAction}>
        <Typography>{summaryText}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

ActivityResponseSummary.propTypes = {
  ramqResponse: PropTypes.object.isRequired
};

export default withStyles(styles)(ActivityResponseSummary);
