import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { isValidNam } from '../../../validations/act/nam/namValidation';
import NamInputSection from './NamInputSection/NamInputSection';
import { logDefault } from '../../../../shared/utils/plog';

export const styles = (theme) => ({
  namValidationIcon: {
    position: 'absolute',
    width: 32,
    height: 32
  },
  checkIcon: {
    color: theme.palette.green[500]
  }
});

export class NamInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialNam: props.selectedNam || '',
      invalidNam: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedNam !== prevProps.selectedNam && this.state.lastNam !== this.props.selectedNam) {
      logDefault(`NamInput new nam ${prevProps.selectedNam} ${this.props.selectedNam} ${this.state.lastNam}`);
      this.updateNamInputSections(this.props.selectedNam);
    }
  }

  updateNamValue = () => {
    const fullNam = this.buildFullNam();
    const invalidNam = !isValidNam(fullNam);

    if (fullNam.length === 12) {
      this.props.onNamFull?.(fullNam);
    }

    this.setState({ lastNam: fullNam, invalidNam }, () => {
      this.props.onChange(fullNam);
    });
  };

  updateNamInputSections = (newNam) => {
    if (typeof newNam !== 'string') {
      return;
    }

    this.firstSection.value = newNam.substring(0, 4);
    this.secondSection.value = newNam.substring(4, 8);
    this.thirdSection.value = newNam.substring(8, 12);
    this.thirdSection.focus();
  };

  setFocus = (section) => () =>
    new Promise((resolve) => {
      setTimeout(() => {
        section.focus();
        resolve();
      }, 100);
    });

  buildFullNam() {
    return `${this.firstSection.value}${this.secondSection.value}${this.thirdSection.value}`.toUpperCase();
  }

  render() {
    const { initialNam, invalidNam } = this.state;

    return (
      <span>
        <NamInputSection
          error={invalidNam}
          onChange={this.updateNamValue}
          initialValue={initialNam.substring(0, 4)}
          onInputFull={this.setFocus(this.secondSection)}
          onInputEmpty={() => false}
          updateNamInputs={this.updateNamInputSections}
          placeholder="MEDY"
          reference={(refInput) => {
            this.firstSection = refInput;
          }}
        />
        <NamInputSection
          error={invalidNam}
          onChange={this.updateNamValue}
          initialValue={initialNam.substring(4, 8)}
          onInputFull={this.setFocus(this.thirdSection)}
          onInputEmpty={this.setFocus(this.firstSection)}
          updateNamInputs={this.updateNamInputSections}
          placeholder="0441"
          numeric
          reference={(refInput) => {
            this.secondSection = refInput;
          }}
        />
        <NamInputSection
          error={invalidNam}
          onChange={this.updateNamValue}
          initialValue={initialNam.substring(8, 12)}
          onInputFull={() => {}}
          onInputEmpty={this.setFocus(this.secondSection)}
          updateNamInputs={this.updateNamInputSections}
          placeholder="3399"
          numeric
          reference={(refInput) => {
            this.thirdSection = refInput;
          }}
        />
      </span>
    );
  }
}

NamInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedNam: PropTypes.string,
  onNamFull: PropTypes.func
};

NamInput.defaultProps = {
  selectedNam: ''
};

export default compose(withStyles(styles))(NamInput);
