import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import classnames from 'classnames';
import { StyledComponentProps, Theme } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { getDateDayAbbreviation, getDateDayNumber } from '../../../../../../../utils/dateTimeUtils';
import ActivityType from '../../../../../../../shared/core/activity/domain/ActivityType';
import DayAmountField from '../../LegacyDayUnit/DayAmountField/DayAmountField';
import PerdiemSelector from '../../shared/PerdiemSelector/PerdiemSelector';
import { LatestMixteDayPeriodFormDto } from '../../../../PeriodForm/type';
import MixteDayUnitDetail from '../MixteDayUnitDetail/MixteDayUnitDetail';
import PERIOD_FORM_NAME from '../../../../constants';
import DayUnitController from '../DayUnitController';
import DayCodeSummary from '../DayCodeSummary/DayCodeSummary';
import MANUAL_EDITION_MODE from '../../../../../Mixte/constants';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../../../../shared/periods/mixte/constants';
import { splitDurationInPerDiem } from '../../../../../../../shared/periods/mixte/makeMixteSummaryUtils';

const BOTH_PERDIEMS = [AM_PERDIEM, PM_PERDIEM];

export const styles: StyleRulesCallback<Theme, Props> = ({ spacing, breakpoints }) => ({
  unitContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: spacing(1),
    paddingRight: spacing(3),
    [breakpoints.down('xs')]: {
      justifyContent: 'start',
      paddingLeft: 0
    }
  },
  manualEditionMode: {
    backgroundColor: '#fff4e5'
  },
  dayInfo: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'flex': 1,
    '& > *': {
      padding: spacing(1)
    }
  },
  periodContainer: {
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      flex: 3
    }
  },
  codeSelection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 2,
    [breakpoints.down('xs')]: {
      flex: 3
    }
  },
  periodAmount: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100px',
    flex: 2,
    paddingRight: spacing(3),
    [breakpoints.down('xs')]: {
      flex: 3
    }
  },
  buttonContainer: {
    height: '38px',
    display: 'flex',
    alignItems: 'center'
  }
});

export interface Props extends StyledComponentProps {
  fieldName: string;
  fieldIndex: number;
  days: any;
  fields: any;
  place: any;
  /* eslint-disable react/no-unused-prop-types, no-unused-vars */
  onChange?: (...args: any[]) => void;
  updateField: (fieldName: string, value: any) => void;
  /* eslint-enable */
  disabled?: boolean;
}

interface State {
  errorContainer: any;
}

export class LatestMixteDayUnit extends React.Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.handlePerdiemSelectionChange = this.handlePerdiemSelectionChange.bind(this);
    this.handleAddPeriod = this.handleAddPeriod.bind(this);

    this.state = {
      errorContainer: null
    };
  }

  errorContainerRef = (ref) => {
    this.setState({ errorContainer: ref });
  };

  private handlePerdiemSelectionChange(perdiems: string[]) {
    const { days, fieldName, fieldIndex, updateField } = this.props;

    const { codes } = days[fieldIndex] as LatestMixteDayPeriodFormDto;

    if (codes.length === 1) {
      const minimumDurationRequired = DayUnitController.DEFAULT_HALF_PERDIEM_DURATION * perdiems.length;
      updateField(`${fieldName}.codes[0].duration`, minimumDurationRequired);
    }
  }

  private handleAddPeriod() {
    const { updateField, days, fields, fieldName, fieldIndex } = this.props;
    const period = DayUnitController.createComplementaryPeriod(days, fieldIndex);
    const insertIndex = period.perdiems?.includes(AM_PERDIEM) ? fieldIndex : fieldIndex + 1;

    updateField(`${fieldName}.${MANUAL_EDITION_MODE}`, true);

    fields.insert(insertIndex, DayUnitController.createComplementaryPeriod(days, fieldIndex));
  }

  render() {
    const { fieldName, fieldIndex, disabled = false, days, fields, place, classes } = this.props;
    const mutableDays = days;
    const { date, manualEditionMode } = mutableDays[fieldIndex] as LatestMixteDayPeriodFormDto;
    const perdiemList = DayUnitController.generatePerdiemList(mutableDays, fieldIndex);
    const isFullDay = mutableDays[fieldIndex]?.perdiems?.length === 2;
    const splitPerdiemHours = isFullDay
      ? splitDurationInPerDiem(mutableDays[fieldIndex]?.codes[0].duration, BOTH_PERDIEMS)
      : undefined;

    return (
      <>
        <div
          className={classnames(classes!.unitContainer, {
            [classes!.manualEditionMode!.toString()]: manualEditionMode
          })}
        >
          <div className={classes!.dayInfo}>
            <Typography variant="caption" data-testid="day-abbreviation">
              {getDateDayAbbreviation(date)}
            </Typography>
            <Typography variant="body2" data-testid="date">
              {getDateDayNumber(date)}
            </Typography>
          </div>
          <div className={classes!.periodContainer}>
            <PerdiemSelector
              fieldName={fieldName}
              list={perdiemList}
              onChange={(event, newValue) => this.handlePerdiemSelectionChange(newValue)}
            />
          </div>
          <div className={classes!.codeSelection}>
            <DayCodeSummary
              name={`${fieldName}.codes[0].code`}
              onChange={() => {}}
              errorContainer={this.state.errorContainer}
              codes={mutableDays[fieldIndex].codes}
              holiday={disabled}
            />
            <span className={classes!.buttonContainer}>
              {DayUnitController.canAddAnotherPeriodOnTheSameDay(disabled, mutableDays, fieldIndex) && (
                <IconButton data-testid="add-button" onClick={this.handleAddPeriod}>
                  <AddIcon />
                </IconButton>
              )}
              {DayUnitController.isALeastTwoPeriodOnTheSameDay(mutableDays, date) && (
                <IconButton data-testid="remove-button" onClick={() => fields.remove(fieldIndex)}>
                  <ClearIcon />
                </IconButton>
              )}
            </span>
          </div>
          <Restrict atLeastRole={Role.Agent}>
            <div className={classes!.periodAmount}>
              <DayAmountField activityType={ActivityType.MIXTE} fieldName={fieldName} place={place} />
            </div>
          </Restrict>
        </div>
        {!disabled && (
          <MixteDayUnitDetail
            data-testid="day-unit-detail"
            errorContainer={this.state.errorContainer}
            canAddCode={DayUnitController.canAddCode(mutableDays, fieldIndex)}
            splitPerdiemHours={splitPerdiemHours}
            dayFieldName={fieldName}
          />
        )}

        <div ref={this.errorContainerRef} />
      </>
    );
  }
}

const selector = formValueSelector(PERIOD_FORM_NAME);

export default compose(
  withStyles(styles),
  // Since Typescript 5.4 the connect function raise the following error:
  // TS2589: Type instantiation is excessively deep and possibly infinite.
  // @ts-ignore
  connect(
    (state: object) => ({
      days: selector(state, 'days')
    }),
    (dispatch) => ({
      updateField: (field: string, value: any) => dispatch(change(PERIOD_FORM_NAME, field, value))
    })
  )
)(LatestMixteDayUnit) as React.ElementType;
