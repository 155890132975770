import { newUnixDate } from '../../../../utils/dateTime/dateTimeUtils';
import AdminNoteProps, { AdminNoteType } from '../../../../core/admin/domain/AdminNoteProps';

class AdminNote {
  static readonly SYSTEM_AUTHOR_NAME = 'System';

  private constructor(
    // eslint-disable-next-line no-unused-vars
    private props: AdminNoteProps
  ) {}

  static createSystemAdminNote(adminNote: string, options?: { type: AdminNoteType }): AdminNote {
    return AdminNote.create(adminNote, AdminNote.SYSTEM_AUTHOR_NAME, options?.type);
  }

  static create(adminNote: string, author: string, type: AdminNoteType = AdminNoteType.DEFAULT): AdminNote {
    return new AdminNote({ author, type, date: newUnixDate(), content: adminNote });
  }

  public getProps(): AdminNoteProps {
    return Object.freeze(this.props);
  }
}

export default AdminNote;
