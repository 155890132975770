import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';

export function BasicField({ InputLabelProps, label, children, ...rest }) {
  const child = children || <Input />;
  const childWithPropsAppended = React.cloneElement(child, { ...rest, ...rest.input });
  return (
    <FormControl fullWidth error={!!rest.meta.error}>
      {label && <InputLabel {...InputLabelProps}>{label}</InputLabel>}
      {childWithPropsAppended}
      {rest.meta.error && <FormHelperText>{rest.meta.error}</FormHelperText>}
      {!rest.meta.error && rest.meta.warning && <FormHelperText>{rest.meta.warning}</FormHelperText>}
    </FormControl>
  );
}

BasicField.defaultProps = {
  children: '',
  InputLabelProps: {},
  label: ''
};

BasicField.propTypes = {
  label: PropTypes.node,
  meta: PropTypes.object.isRequired,
  InputLabelProps: PropTypes.object,
  children: PropTypes.node
};

export default BasicField;
