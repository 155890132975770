import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import SelectedActCode from './SelectedActCode';

export const styles = () =>
  ({
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  actCodes: ActCode[];
  // eslint-disable-next-line no-unused-vars
  onDelete?: (actCode: ActCode, index: number) => void;
  toolTipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const SelectedActCodes: React.FunctionComponent<Props> = ({
  classes,
  actCodes,
  onDelete,
  toolTipPlacement
}: Props) => (
  <div className={classes.row}>
    {actCodes.map((actCode, index) => {
      if (!actCode?.code) return null;
      return (
        <SelectedActCode
          key={actCode.id ?? actCode.code}
          actCode={actCode}
          onDelete={onDelete && (() => onDelete(actCode, index))}
          toolTipPlacement={toolTipPlacement}
        />
      );
    })}
  </div>
);

export default withStyles(styles)(SelectedActCodes);
