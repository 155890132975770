/**
 * Reference: https://medyx.atlassian.net/browse/MED-1773
 *
 * Infolettre no.011 – 8 avril, 2021
 * Mesures de l'Institut de la pertinence des actes médicaux : modifications à votre entente
 * https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/infolettres/2021/info011-21.pdf
 *
 * À noter que cette règle métier est en vigueur à compter du 1er juin 2021
 * https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/manuels/158-brochure-5-specialistes/
 * Specialistes_Brochure_no5-IPAM-li7.html#103959
 */

import { flatten, get } from 'lodash';
import MixteDaysComputator from './MixteDaysComputator';
import { AM_PERDIEM, PM_PERDIEM } from './constants';
import { getRamqDayOfMonthFromTimestamp, padStringWithZeros } from '../../ramq/ramqCommonUtils';
import { formatToDeferredPaymentRequestDate } from '../../ramq/ramqDateUtil';
import { ComputedMixteDay } from './MixteDaysComputator.types';
import { MixteDay } from '../../domain/collection/activity/Mixte.types';
import { splitDurationInPerDiem } from './makeMixteSummaryUtils';

export default class LatestMixteDaysComputator extends MixteDaysComputator {
  public compute(): ComputedMixteDay[] {
    return flatten(this.mixteDays.map(this.computeMixteDay));
  }

  private computeMixteDay(mixteDay: MixteDay): ComputedMixteDay[] {
    const { activityArea: dispensatoryArea, code, date } = mixteDay;
    const perDiems = get(mixteDay, 'perdiems', []) as string[];
    const day = getRamqDayOfMonthFromTimestamp(date);
    const deferredPaymentRequestDate = formatToDeferredPaymentRequestDate(date);
    const duration = get(mixteDay, 'duration', 0);
    const durationByPerDiem = splitDurationInPerDiem(duration, perDiems);

    return perDiems.map((perDiem) => {
      const am = perDiem === AM_PERDIEM;
      const pm = perDiem === PM_PERDIEM;

      return {
        am,
        code,
        date: deferredPaymentRequestDate,
        day,
        dispensatoryArea,
        duration: padStringWithZeros((durationByPerDiem[perDiem] * 100).toString(), 4),
        pm
      };
    });
  }
}
