import { find, findIndex, get, intersection } from 'lodash';
import { ACTIVITIES_ACT_TYPE } from '../../../../../shared/collection/collectionConstants';
import queryBuilder from '../../../../../shared/collection/QueryBuilder';
import ActivityStatus from '../../../../../shared/core/activity/domain/ActivityStatus';
import {
  INTENSIVE_CARE_ON_GUARD_DAY,
  INTENSIVE_CARE_UNIT_SUPPORT_DAY
} from '../../../../../shared/ramq/contextElements/globalContextElements';
import { onGuardCodes } from '../../../../../shared/ramq/onGuardCodes/constants';
import { clinicalSupervisionCodes } from '../../../../../shared/ramq/teachingCodes/constants';
import { isWeekEnd } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import { dateIsDuringPlaceHoliday } from '../../../../../shared/utils/dateTime/dateValidations';
import isDefined from '../../../../../shared/utils/isDefined';
import { activitiesCollectionRef } from '../../../../firebase/collection/collectionReferences';
import { getAllDataFromSnapshotWithBoundId } from '../../../../firebase/collection/snapshotDataFetcher';
import WeekAndWeekendActCodesMappingService, {
  Mapping
} from '../../../../../shared/act/services/WeekAndWeekendActCodesMappingService';

export const INTENSIVE_CARE_OPTIMIZATIONS = 'intensiveCareOptimizations';

export const hasIntensiveCareUnitSupportDayContextElement = (act) =>
  isDefined(act.contextElements) && act.contextElements.includes(INTENSIVE_CARE_UNIT_SUPPORT_DAY);

export const shouldAddIntensiveCareUnitActivityArea = (act) => !act.activityArea;

export const hasIntensiveCareOnGuardDayContextElement = (act) =>
  isDefined(act.contextElements) && act.contextElements.includes(INTENSIVE_CARE_ON_GUARD_DAY);

export const getActsForSpecificDate = async (date, userId) => {
  const collectionRef = activitiesCollectionRef();
  const actsActivitiesInCurrentDayQuery = queryBuilder
    .withBaseQuery(collectionRef)
    .withType(ACTIVITIES_ACT_TYPE)
    .withUserId(userId)
    .withDayRange(date)
    .build();

  // From cache is used here to prevent 4 to 6 seconds lag when user is offline or is on a bad network
  // We are aware that on specific edge case, this optimization might not be run : https://github.com/medyxa/medyx/issues/533
  // We decided to go with the speed instead of being 100% bulletproof to satisfy most of our users use cases
  const snapshot = await actsActivitiesInCurrentDayQuery.get({ source: 'cache' });
  return getAllDataFromSnapshotWithBoundId(snapshot);
};

export const hasIntensiveCareUnitSupportCodeAndIsNotCanceled = (act) =>
  (act.codes || []).map((code) => code.code).includes('9295') && act.status !== ActivityStatus.CANCELED;

export const doctorIsOnIntensiveCareUnitSupportOnThisDay = (acts) =>
  findIndex(acts, hasIntensiveCareUnitSupportCodeAndIsNotCanceled) !== -1;

export const hasIntensiveCareOnGuardCodeAndIsNotCanceled = (act) =>
  intersection(
    get(act, 'codes', []).map((code) => code.code),
    onGuardCodes
  ).length >= 1 && act.status !== ActivityStatus.CANCELED;

export const doctorIsOnIntensiveCareOnGuardOnThisDay = (acts) =>
  findIndex(acts, hasIntensiveCareOnGuardCodeAndIsNotCanceled) !== -1;

export const addPatientSupportCode = (yesterdaysCode, codesToDuplicate) => {
  // 9097 : Prise en charge du patient --> Premier jour
  // 9098 : Prise en charge du patient --> Jour subséquent
  const patientSupportCode = find(yesterdaysCode, (code) => code.code === '9097' || code.code === '9098');
  if (patientSupportCode) {
    codesToDuplicate.push({ ...patientSupportCode, code: '9098' }); // Keep all associated required details

    const patientVentilation = find(yesterdaysCode, (code) => ['900', '929'].includes(code.code));

    if (patientVentilation) {
      codesToDuplicate.push(patientVentilation);
    }
  }
};

const isWeekendOrHoliday = (currentDate, place) =>
  isWeekEnd(currentDate) || dateIsDuringPlaceHoliday(currentDate, place);

const isStudentSupervisionCode = (code) => clinicalSupervisionCodes.includes(code.code);

export const addStudentSupervisionCodes = (currentDate, yesterdaysCode, codesToDuplicate, place) => {
  if (!isWeekendOrHoliday(currentDate, place)) {
    yesterdaysCode.filter(isStudentSupervisionCode).forEach((code) => {
      codesToDuplicate.push(code);
    });
  }
};

const isOnGuardCode = (code) => onGuardCodes.includes(code.code);

export const addOnGuardCodes = (currentDate, yesterdaysCode, codesToDuplicate, place) => {
  const weekAndWeekendActCodesMappingService = new WeekAndWeekendActCodesMappingService().useMapping(Mapping.GUARD);

  yesterdaysCode.filter(isOnGuardCode).forEach((code) => {
    const [weekCode, weekendCode] = weekAndWeekendActCodesMappingService.findTupleByCode(code.code);

    if (isWeekendOrHoliday(currentDate, place)) {
      codesToDuplicate.push({ ...code, code: weekendCode });
    } else {
      codesToDuplicate.push({ ...code, code: weekCode });
    }
  });
};
