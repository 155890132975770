import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import FormattedUnixHours from '../../FormattedUnixHours/FormattedUnixHours';

const styles = () => ({
  perdiems: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  upperCasedBold: {
    paddingRight: '10px',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  }
});

function MixteTimeSummary({ day, classes }) {
  return (
    <Grid item xs={3} className={classes.perdiems}>
      {isArray(day.perdiems) ? (
        day.perdiems.map((perdiem) => (
          <Typography key={perdiem} data-testid="perdiem" variant="body1" className={classes.upperCasedBold}>
            {perdiem}
          </Typography>
        ))
      ) : (
        <FormattedUnixHours data-testid="hours" activity={{ start: day.start, end: day.end }} />
      )}
    </Grid>
  );
}

MixteTimeSummary.propTypes = {
  day: PropTypes.object.isRequired
};

export default withStyles(styles)(MixteTimeSummary);
