import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectPatientContext } from '../../../../containers/ActForm/selectors';
import { MultiDiagnosticSelectField } from '../../Fields';

export class MultiDiagnosticWithPatientContextField extends React.Component {
  componentDidMount() {
    this.updateInputValue();
  }

  updateInputValue() {
    const { diagnostic } = this.props.patientContext;

    if (diagnostic && !this.isInputAlreadyFilled()) {
      this.props.input.onChange(diagnostic);
    }
  }

  isInputAlreadyFilled() {
    return this.props.input.value !== '';
  }

  render() {
    return <MultiDiagnosticSelectField {...this.props} />;
  }
}

MultiDiagnosticWithPatientContextField.propTypes = {
  input: PropTypes.object.isRequired,
  patientContext: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  patientContext: selectPatientContext()
});

export default compose(connect(mapStateToProps, null))(MultiDiagnosticWithPatientContextField);
