const CODE_NATURE_TYPES = {
  visite: 'Visite',
  administratif: 'Administratif',
  pdt: 'PDT',
  anesthesiologie: 'Anesthésiologie',
  chirurgie: 'Chirurgie',
  radiologie: 'Radiologie diagnostique',
  ultrasonographie: 'Ultrasonographie',
  respiratoire: 'Épreuves de fonction respiratoire',
  nucleaire: 'Médecine nucléaire',
  forfait: 'Forfait',
  genetique: 'Génétique médicale',
  biochimie: 'Biochimie médicale',
  microbiologie: 'Microbiologie-Infectiologie',
  tarificationNonPrevu: 'Tarification non prévue',
  deplacement: 'Frais de déplacement',
  hematologie: 'Hématologie'
};

export default CODE_NATURE_TYPES;
