import { useEffect, useState } from 'react';
import { storage } from '../../../../../server/Firebase';

const useGetDownloadUrl = (path: string) => {
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setLoading(true);
    fetchUrl();

    async function fetchUrl() {
      try {
        const _url = await storage.ref().child(path).getDownloadURL();
        setUrl(_url);
      } catch (catchedError: any) {
        setError(catchedError);
      } finally {
        setLoading(false);
      }
    }
  }, [path]);

  return { url, loading, error };
};

export default useGetDownloadUrl;
