import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import MaxSearchReachedDialogActions from '../../components/Dialogs/DialogActionsWithMessage/MaxSearchReachedDialogActions';
import DialogCenteredTitle from '../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';
import { updateSearchValue } from '../SearchSuggestions/actions';
import messages from './messages';

export const styles = () => ({
  paper: {
    minHeight: '90vh',
    height: '100%'
  },
  title: {
    paddingBottom: 5
  },
  searchInputContainer: {
    padding: '0 24px 12px 24px'
  }
});

export class CodeSearchDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.resetSelector = this.resetSelector.bind(this);
  }

  resetSelector() {
    this.props.updateSearchValue('');
  }

  render() {
    const { onClose, open, fullScreen, children, classes } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={open}
        onClose={onClose}
        TransitionProps={{ onExited: this.resetSelector }}
        classes={{ paper: classes.paper }}
      >
        <DialogCenteredTitle label={<FormattedMessage {...messages.codeSearchTitle} />} />
        {children}
        <MaxSearchReachedDialogActions maxSearchNumber={20}>
          <Button onClick={onClose} color="primary">
            <FormattedMessage {...messages.close} />
          </Button>
        </MaxSearchReachedDialogActions>
      </Dialog>
    );
  }
}

CodeSearchDialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  updateSearchValue: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  updateSearchValue
};

export default compose(connect(null, mapDispatchToProps), withMobileDialog(), withStyles(styles))(CodeSearchDialog);
