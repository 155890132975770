import { gql, useMutation } from '@apollo/client';
import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import Section from 'application/components/form-controls/section';
import TextInput from 'application/components/form-controls/text-input';
import useToast from 'application/hooks/use-toast';
import useToastApolloError from 'application/hooks/use-toast-apollo-error';
import useTranslate from 'application/hooks/use-translate';
import useValidatedForm from 'application/hooks/use-validated-form';
import React, { useState } from 'react';

interface ReferralInvite {
  firstName: string;
  lastName: string;
  email: string;
}

const INITIAL_REFERRAL_INVITE: ReferralInvite = {
  firstName: '',
  lastName: '',
  email: ''
};

const CREATE_MUTATION = gql`
  mutation CreateReferral($referral: CreateReferralInput!) {
    createReferral(referral: $referral) {
      id
    }
  }
`;

interface Props {
  onReferralCreated: () => void;
}

const SettingsReferralInvite = ({ onReferralCreated }: Props) => {
  const translate = useTranslate('pages.settings.referral.invite');
  const [referralInvite, setReferralInvite] = useState<ReferralInvite>(INITIAL_REFERRAL_INVITE);
  const { registerField, isValid, reset } = useValidatedForm();
  const [createReferral, { loading }] = useMutation(CREATE_MUTATION);
  const { addToast } = useToast();
  const toastApolloError = useToastApolloError();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValid) return;

    createReferral({
      variables: {
        referral: {
          firstName: referralInvite.firstName,
          lastName: referralInvite.lastName,
          email: referralInvite.email
        }
      }
    })
      .then(() => {
        addToast({
          autoDismiss: true,
          message: translate('send-success'),
          type: 'success'
        });

        reset();
        setReferralInvite(INITIAL_REFERRAL_INVITE);
        onReferralCreated();
      })
      .catch(toastApolloError);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Section label={translate('title')}>
        <FieldContainer required label={translate('first-name')}>
          <TextInput
            {...registerField('firstName')}
            required
            value={referralInvite.firstName}
            onChange={(firstName) => setReferralInvite({ ...referralInvite, firstName })}
          />
        </FieldContainer>

        <FieldContainer required label={translate('last-name')}>
          <TextInput
            {...registerField('lastName')}
            required
            value={referralInvite.lastName}
            onChange={(lastName) => setReferralInvite({ ...referralInvite, lastName })}
          />
        </FieldContainer>

        <FieldContainer required label={translate('email')}>
          <TextInput
            {...registerField('email')}
            required
            type="email"
            value={referralInvite.email}
            onChange={(email) => setReferralInvite({ ...referralInvite, email })}
          />
        </FieldContainer>

        <Section.Footer>
          <Button type="submit" className="btn btn-primary" disabled={!isValid} loading={loading}>
            {translate('send')}
          </Button>
        </Section.Footer>
      </Section>
    </form>
  );
};

export default SettingsReferralInvite;
