import moment from 'moment-timezone';
import { padStringWithZeros } from './ramqCommonUtils';

// AAAA-MM-JJT99:99:99
const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
const dateFormat = 'YYYY-MM-DD';
export const PERIOD_PDF_DATE_FORMAT = 'YYMMDD';
export const DEFERRED_PAYMENT_REQUEST_DATE_FORMAT = 'YYYYMMDD';
export const ramqDateTimeFormat = (date) => moment(date).format(dateTimeFormat);
export const ramqDateFormat = (date) => moment(date).format(dateFormat);
export const ramqPeriodPDFFormat = (date) => moment(date).format(PERIOD_PDF_DATE_FORMAT);

export const getSendBatchFileNameDate = () => {
  const currentDate = moment();
  const currentYear = currentDate.format('YY');
  const yearLastNumber = currentYear.charAt(currentYear.length - 1);
  return `${yearLastNumber}${padStringWithZeros(currentDate.format('DDD'), 3)}`;
};

export const periodPdfDateToRMXPaymentRequestDate = (pdfDate) =>
  moment(pdfDate, PERIOD_PDF_DATE_FORMAT).format(DEFERRED_PAYMENT_REQUEST_DATE_FORMAT);

export const formatToDeferredPaymentRequestDate = (date) => moment(date).format(DEFERRED_PAYMENT_REQUEST_DATE_FORMAT);

// Didn't find a good way to mock moment to return always the same value ONLY when we get TODAY'S date
// By wrapping the "new date" in a function, we can mock this function to always return the same date to
// keep our test consistent.
export const newDeferredPaymentRequestDate = () => formatToDeferredPaymentRequestDate(moment());
