import { get } from 'lodash';
import { findUserSpecialtyRelatedCodeUnits } from '../../../act/shared/domain/roles/roles.utils';
import Act, { ActCode, NatureType } from '../../../../shared/domain/activity/act/model/Act';

import { MAXIMUM_AMOUNT_FOR_MAIN_CODE_ELIGIBLE_FOR_INCREASE } from '../../../../userCustomization/featureNames';
import ActPerformedAlonePredicate from '../../../../shared/domain/activity/act/service/ActPerformedAlonePredicate';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';

const UNSUPPORTED_CODES_BY_SPECIALTY = {
  [SpecialtyName.OTOLARYNGOLOGIST]: ['7077']
};

class ActPerformedAloneWithAmountLowerThanMaximumAmountPredicate {
  private static readonly SUPPORTED_NATURE_TYPES = [NatureType.SURGERY, NatureType.PDT, NatureType.VISIT];

  static validate(act: Act) {
    return !!this.getMainCodeMatchingPredicate(act);
  }

  static getMainCodeMatchingPredicate(act: Act): ActCode | undefined {
    const mainCode: ActCode | undefined = ActPerformedAlonePredicate.findMainCode(act);

    if (mainCode === undefined) {
      return undefined;
    }

    const codeUnits = parseFloat(get(findUserSpecialtyRelatedCodeUnits(mainCode.code), '[0].units', 0));

    if (this.isCodeApplicable(mainCode, codeUnits)) {
      return mainCode;
    }

    return undefined;
  }

  private static isCodeApplicable(mainCode: ActCode, codeUnits: number) {
    const userSpecialty = selectSpecialty()(getStore().getState());

    return (
      !(UNSUPPORTED_CODES_BY_SPECIALTY[userSpecialty] ?? []).includes(mainCode.code) &&
      this.SUPPORTED_NATURE_TYPES.includes(mainCode.natureType as NatureType) &&
      codeUnits < ((getFeatureConfig(MAXIMUM_AMOUNT_FOR_MAIN_CODE_ELIGIBLE_FOR_INCREASE, 0) as number) || 0)
    );
  }
}

export default ActPerformedAloneWithAmountLowerThanMaximumAmountPredicate;
