import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToTimeWithHourPrefixValue } from '../../../../shared/utils/dateTime/dateTimeUtils';
import isDefined from '../../../../shared/utils/isDefined';
import Hours from '../Hours/Hours';

function TravelTimeToHour({ activity }) {
  const start = isDefined(activity.travelInformation.startTime)
    ? formatUnixToTimeWithHourPrefixValue(activity.travelInformation.startTime)
    : '-';

  const end = isDefined(activity.travelInformation.endTime)
    ? formatUnixToTimeWithHourPrefixValue(activity.travelInformation.endTime)
    : '-';

  return <Hours activity={{ start, end }} text="Transport" />;
}

TravelTimeToHour.propTypes = {
  activity: PropTypes.object.isRequired
};

export default TravelTimeToHour;
