import get from 'lodash/get';
import Act, { isAct } from '../../../../shared/domain/activity/act/model/Act';
import { isEmpty } from '../../../../shared/utils/objectManipulation';
import Activity from '../../../../shared/domain/activity/global/model/Activity';
import NAM from '../../../../shared/domain/ramq/NAM';
import isDefined from '../../../../shared/utils/isDefined';
import RAMQPatient from '../../../../shared/domain/ramq/RAMQPatient';
import SwappableActCodesRecommendationService from '../../../../shared/act/services/SwappableActCodesRecommendationService/SwappableActCodesRecommendationService';
import ActCodes from '../../../../shared/domain/activity/act/model/ActCodes';

const NO_WARNINGS = [];

const switchCodeAccordingToPatientAgeWarning = (form: Partial<Act>) => {
  const jsForm = form as Activity;

  if (isEmpty(jsForm) || !isAct(jsForm) || isRecommendationServiceCanBeExecuted(jsForm)) {
    return NO_WARNINGS;
  }

  const nam = NAM.fromV1(jsForm.nam as string);
  const patient = new RAMQPatient(nam);
  const actCodes = new ActCodes(jsForm.codes);
  const recommendations =
    new SwappableActCodesRecommendationService().findRecommendationsForCodesAccordingToPatientsAge(
      actCodes,
      patient,
      jsForm.date
    );

  if (recommendations.size > 0) {
    const _warning: string[] = [];

    for (const [misusedCode, codeToUse] of recommendations.entries()) {
      _warning.push(`Modification automatique du ${misusedCode} au ${codeToUse} à cause de l'âge du patient`);
    }

    return _warning;
  }

  return NO_WARNINGS;
};

const isRecommendationServiceCanBeExecuted = (jsForm) =>
  !isDefined(jsForm.nam) ||
  !NAM.isValidForV1(jsForm.nam || '') ||
  !isDefined(jsForm.codes) ||
  jsForm.codes.length === 0;

export default switchCodeAccordingToPatientAgeWarning;
