import { useCallback } from 'react';
import { useIntl } from 'react-intl';

interface TranslationParams {
  [key: string]: string | number;
}

export default function useTranslate(translationContext?: string) {
  const intl = useIntl();

  return useCallback(
    (key: string, params?: TranslationParams) =>
      translationContext
        ? intl.formatMessage({ id: `${translationContext}.${key}` }, params)
        : intl.formatMessage({ id: key }, params),
    [intl, translationContext]
  );
}
