import actions from 'redux-form/lib/actions';
import { MULTI_ACT_INITIATE_NAM_OCR, MULTI_ACT_UPDATE_EXTRACTED_NAMS } from './constants';
import MULTI_ACT_FORM_NAME from '../../ui/component/MultiActForm/constants';

export function addNAMsInMultiActForm(extractedNams) {
  return actions.change(
    MULTI_ACT_FORM_NAME,
    'patients',
    extractedNams.map(({ nam }) => ({ nam }))
  );
}

export const addExtractedNAMsInMultiActForm = (extractedNams) => ({
  type: MULTI_ACT_UPDATE_EXTRACTED_NAMS,
  extractedNams
});

export const initiateNamOcr = (attachment) => ({
  type: MULTI_ACT_INITIATE_NAM_OCR,
  attachment
});
