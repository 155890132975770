import { call, put, takeEvery } from 'redux-saga/effects';
import { billRAMQ, cancelRAMQ, deferredBillRAMQ } from '../../infrastructure/api/api';
import { addAsyncCall, asyncCallFailure, asyncCallSuccess } from '../AsyncTasks/actions';
import { BILL_ACTIVITY, BILL_PERIOD, CANCEL_ACTIVITY } from './actions';

export function* ramqCall(ramqCallFunction, { id, options }) {
  try {
    yield put(addAsyncCall(id));
    yield call(ramqCallFunction, id, options);
    yield put(asyncCallSuccess(id));
  } catch (error) {
    yield put(asyncCallFailure(id, error));
  }
}

export default function* ramqBillSaga() {
  yield takeEvery(BILL_PERIOD, ramqCall, deferredBillRAMQ);
  yield takeEvery(BILL_ACTIVITY, ramqCall, billRAMQ);
  yield takeEvery(CANCEL_ACTIVITY, ramqCall, cancelRAMQ);
}
