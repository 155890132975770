import { get, isArray } from 'lodash';
import { AM_PERDIEM, PERDIEM, PM_PERDIEM } from '../periods/mixte/constants';
import {
  calculatePerdiem,
  getPaddedHoursWorked,
  isAmPeriod,
  isPmPeriod
} from '../periods/mixte/MixteDaysComputator.legacyUtils';
import isDefined from '../utils/isDefined';
import { isMultipleDays } from './computeExpectedAmountUtil';

export const BASE_MIXTE_HALF_PERDIEM_FLOAT = 372.0;

export const computeExpectedAmountForMixte = (mixte) => {
  const amountPerHalfPerdiem = getAmountForHalfPerdiem(mixte);
  if (isASingleDay(mixte)) {
    return getExpectedAmountForSingleDay(amountPerHalfPerdiem, mixte).toFixed(2);
  }
  if (isMultipleDays(mixte)) {
    return getExpectedAmountForMultipleDays(amountPerHalfPerdiem, mixte.days).toFixed(2);
  }
  return '0.00';
};

const getAmountForHalfPerdiem = (mixte) => computeAdjustedAmount(get(mixte, 'place.annexe19', 1.0));

const computeAdjustedAmount = (annexe19Rate) => BASE_MIXTE_HALF_PERDIEM_FLOAT * annexe19Rate;

const addUpAmountForSingleDay = (amountForHalfPerdiem) => (total, current) =>
  total + getExpectedAmountForSingleDay(amountForHalfPerdiem, current);

const getExpectedAmountForMultipleDays = (amountForHalfPerdiem, days) =>
  days.reduce(addUpAmountForSingleDay(amountForHalfPerdiem), 0.0);

export const getExpectedAmountForSingleDay = (amountForHalfPerdiem, day) => {
  if (isDefined(day.manualCorrection)) {
    return Number.parseFloat(day.manualCorrection.replace(/\s/g, ''));
  }
  return amountForHalfPerdiem * getPerdiemForDay(day).length;
};

const isASingleDay = (mixte) =>
  (isDefined(mixte.perdiems) && isArray(mixte.perdiems)) || (isDefined(mixte.start) && isDefined(mixte.end));

const getPerdiemForDay = (day) => {
  if (isDefined(day.perdiems)) {
    return day.perdiems;
  }

  const hoursWorked = getPaddedHoursWorked(day.start, day.end);
  const calculatedPerdiem = calculatePerdiem(hoursWorked);

  if (calculatedPerdiem === PERDIEM) {
    return [AM_PERDIEM, PM_PERDIEM];
  }

  if (isAmPeriod(day.start, day.end)) {
    return [AM_PERDIEM];
  }

  if (isPmPeriod(day.start, day.end)) {
    return [PM_PERDIEM];
  }

  return [];
};

export default computeExpectedAmountForMixte;
