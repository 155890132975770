import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import {
  createErrorMapWithEveryValidationFunction,
  requiredFieldValidations,
  throwSubmissionErrorIfNecessary
} from '../../../validations/common/reduxFormValidation';
import { NoteField } from '../../Form/Fields';
import FormElement from '../../Form/FormElement/FormElement';
import FormRow from '../../Form/FormRow/FormRow';
import DialogCenteredTitle from '../DialogCenteredTitle/DialogCenteredTitle';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    dialog: {
      width: '100%',
      maxWidth: '400px',
      [breakpoints.down('sm')]: {
        maxWidth: '600px'
      }
    },
    dialogContent: {
      paddingBottom: theme.spacing(1)
    },
    customCodeDescriptionTitle: {
      paddingBottom: theme.spacing(2)
    },
    keepSameDescriptionButtonContainer: {
      justifyContent: 'center',
      margin: 0
    }
  };
};

export class CustomCodeDescriptionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.handleDialogEnter = this.handleDialogEnter.bind(this);
  }

  handleDialogEnter() {
    const { initialize, selectedCode, mode } = this.props;

    initialize({
      description: mode === 'edit' ? selectedCode.description : ''
    });
    this.inputRef.focus();
  }

  handleSave(values) {
    const errorMap = createErrorMapWithEveryValidationFunction(values, requiredFieldValidations(['description']));
    throwSubmissionErrorIfNecessary(errorMap);
    this.props.onSave(values.description);
  }

  render() {
    const { open, selectedCode, onSave, onCancel, handleSubmit: reduxFormHandleSubmit, classes } = this.props;

    return (
      <Dialog
        id="custom-description-code-dialog"
        open={open}
        onClose={() => onCancel()}
        TransitionProps={{ onEnter: () => this.handleDialogEnter() }}
        classes={{ paper: classes.dialog }}
      >
        <DialogCenteredTitle label="Ajouter votre description" className={classes.customCodeDescriptionTitle} />
        <DialogContent className={classes.dialogContent}>
          <FormRow>
            <FormElement
              inputRef={(inputRef) => {
                this.inputRef = inputRef;
              }}
              name="description"
              label="Votre propre description"
              fullWidth
              component={NoteField}
            />
          </FormRow>
          <FormRow className={classes.keepSameDescriptionButtonContainer}>
            <Button onClick={() => onSave(selectedCode.originalDescription || selectedCode.description)}>
              Garder la description originale
            </Button>
          </FormRow>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()} color="primary">
            Annuler
          </Button>
          <Button onClick={reduxFormHandleSubmit(this.handleSave)} color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CustomCodeDescriptionDialog.propTypes = {
  selectedCode: PropTypes.object,
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['edit', 'append']).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired
};

CustomCodeDescriptionDialog.defaultProps = {
  selectedCode: { description: '' }
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'addCustomCodeDescriptionForm'
  })
)(CustomCodeDescriptionDialog);
