import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { useLocation } from 'react-router-dom';
import { throwSubmissionErrorIfNecessary } from '../../../validations/common/reduxFormValidation';
import placeSignatoryValidation from '../../../validations/placesSignatory/placeSignatoryValidation';
import SingleCheckbox from '../../Form/Checkbox/SingleCheckbox/SingleCheckbox';
import Annexe19Field from '../../Form/custom/Annexe19Field';
import FormElement from '../../Form/FormElement/FormElement';
import FormRow from '../../Form/FormRow/FormRow';
import SignatoryField from '../../Form/SignatoryField/SignatoryField';
import DialogCenteredTitle from '../DialogCenteredTitle/DialogCenteredTitle';
import isDefined from '../../../../shared/utils/isDefined';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    dialog: {
      width: '100%',
      maxWidth: '400px',
      [breakpoints.down('sm')]: {
        maxWidth: '600px'
      }
    },
    signatoryNameTitle: {
      paddingBottom: theme.spacing(2)
    },
    signatoryCard: {
      marginTop: '4px',
      marginBottom: '1em'
    },
    isMainHospital: {
      textAlign: 'center'
    }
  };
};

const PlaceSettingsDialog = ({
  initialize,
  place,
  onSave,
  createNewRouteFromPlace,
  open,
  onCancel,
  handleSubmit: reduxFormHandleSubmit,
  classes
}) => {
  const location = useLocation();
  let firstNameInput = null;

  const handleDialogEnter = () => {
    if (isInEditMode()) {
      initialize({
        annexe19: place.annexe19 || 1,
        ...place.signatory,
        billByAct: place.billByAct,
        mainHospital: place.priority === 1
      });
    } else {
      initialize({ annexe19: 1 });
    }
    firstNameInput.focus();
  };

  const handleSave = (values) => {
    const signatoryInformation = values;
    const errorMap = placeSignatoryValidation(signatoryInformation);
    const signatory = pick(signatoryInformation, ['firstName', 'lastName', 'email']);
    throwSubmissionErrorIfNecessary(errorMap);

    if (!!signatory.email && signatory.email.length > 0) {
      signatory.email = signatory.email.replace(/\s/g, '');
    }

    onSave(signatory, signatoryInformation.annexe19, signatoryInformation.billByAct, signatoryInformation.mainHospital);

    if (isDefined(createNewRouteFromPlace) && isDefined(place)) {
      createNewRouteFromPlace(location, place.number);
    }
  };

  const isInEditMode = () => place !== undefined;

  return (
    <Dialog
      id="signatory-name-dialog"
      open={open}
      onClose={onCancel}
      TransitionProps={{ onEnter: handleDialogEnter }}
      classes={{ paper: classes.dialog }}
    >
      <DialogCenteredTitle
        label={isInEditMode() ? 'Modifier un établissement' : 'Ajouter un établissement'}
        className={classes.signatoryNameTitle}
      />
      <DialogContent>
        <Card className={classes.signatoryCard}>
          <CardContent>
            <SignatoryField
              firstNameInputRef={(inputRef) => {
                firstNameInput = inputRef;
              }}
            />
          </CardContent>
        </Card>
        <FormRow>
          <FormElement
            name="annexe19"
            label="Rémunération différente (Annexe 19)"
            fullWidth
            component={Annexe19Field}
          />
        </FormRow>
        <FormRow>
          <FormElement
            name="billByAct"
            label="Facturation à l'acte (Sans Mixte)"
            fullWidth
            component={SingleCheckbox}
          />
        </FormRow>
        {place?.priority === 1 ? (
          <Typography className={classes.isMainHospital}>Cet hôpital est votre hôpital principal</Typography>
        ) : (
          <FormRow>
            <FormElement name="mainHospital" label="Hôpital principal" fullWidth component={SingleCheckbox} />
          </FormRow>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Annuler
        </Button>
        <Button onClick={reduxFormHandleSubmit(handleSave)} color="primary">
          Sauvegarder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PlaceSettingsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  place: PropTypes.object,
  createNewRouteFromPlace: PropTypes.func.isRequired
};

PlaceSettingsDialog.defaultProps = {
  place: undefined
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'addSignatoryForm'
  })
)(PlaceSettingsDialog);
