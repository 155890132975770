import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';
import { WithStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { DialogActions, TextField } from '@material-ui/core';
import { useDebounce } from 'react-use';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import messages from '../../../../containers/CodeSearchDialog/messages';
import CustomCodeDescriptionDialog from '../../../../components/Dialogs/CustomCodeDescriptionDialog/CustomCodeDescriptionDialog';
import CodeSuggestion from '../../../../components/List/Item/CodeSuggestion/CodeSuggestion';
import { SearchSuggestions } from '../../../../containers/SearchSuggestions/SearchSuggestions';
import CategoryItem from '../../../shared/domain/CategoryItem';
import { RAMQContextElements } from '../../../../../shared/ramq/RamqContextElements';
import { selectSelectedDate } from '../../../../containers/ActForm/selectors';

export const styles = (theme) =>
  ({
    searchContainer: {
      'height': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    },
    suggestionList: {
      flex: 1,
      overflowY: 'scroll',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  onItemSelected: (item: CategoryItem) => void;
  isSelected?: (item: CategoryItem) => boolean;
  isInFavorites: (item: CategoryItem) => boolean;
  onAddToFavorites: (item: CategoryItem) => void;
  currentActDate: number;
  onClose: () => void;
}

export const RegistryTab: React.FC<Props> = (props: Props) => {
  const { classes, isInFavorites, isSelected, onItemSelected, onAddToFavorites, onClose, currentActDate } = props;
  const [tempSelectedItem, setTempSelectedItem] = React.useState<CategoryItem | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const [options, setOptions] = useState<CategoryItem[]>([]);

  const handleFavoriteClick = (item: CategoryItem) => {
    if (isInFavorites(item)) {
      return;
    }

    if (item.description) {
      setTempSelectedItem(item);
    } else {
      onAddToFavorites(item);
    }
  };

  const handleAddFavorite = (description) => {
    if (!tempSelectedItem) return;

    onAddToFavorites({
      ...tempSelectedItem,
      description
    });

    setTempSelectedItem(undefined);
  };

  const resetState = () => {
    setTempSelectedItem(undefined);
  };

  useDebounce(() => setDebouncedInputValue(inputValue), 300, [inputValue]);

  useEffect(() => {
    if (!debouncedInputValue) {
      setOptions([]);
    } else {
      setOptions(RAMQContextElements.search(debouncedInputValue, currentActDate));
    }
  }, [debouncedInputValue, currentActDate]);

  return (
    <>
      <DialogContent className={classes.searchContainer}>
        <TextField
          onChange={(e) => setInputValue(e.target.value)}
          label={<FormattedMessage {...messages.codeSearchLabel} />}
        />
        <SearchSuggestions
          className={classes.suggestionList}
          hits={options}
          clearSearch={() => setInputValue('')}
          onSuggestionClick={onItemSelected}
          onFavoriteClick={handleFavoriteClick}
          isSelectedFunction={isSelected}
          isInFavoritesFunction={isInFavorites}
          SuggestionItemComponent={CodeSuggestion}
          getKeyFunction={(suggestion) => suggestion.code}
        />
      </DialogContent>
      <DialogActions>
        <Button id="close-Dialog" onClick={onClose} color="primary">
          <FormattedMessage {...messages.close} />
        </Button>
      </DialogActions>

      <CustomCodeDescriptionDialog
        mode="append"
        selectedCode={tempSelectedItem}
        open={!!tempSelectedItem}
        onCancel={resetState}
        onSave={handleAddFavorite}
      />
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  currentActDate: selectSelectedDate()
});

// Since Typescript 5.4 the connect function raise the following error:
// TS2589: Type instantiation is excessively deep and possibly infinite.
// @ts-ignore
export default compose(withStyles(styles), connect(mapStateToProps))(RegistryTab) as React.ComponentType<
  Omit<Props, 'classes' | 'currentActDate'>
>;
