import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@material-ui/core';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import AlertApi from '../../../shared/confirm/api/AlertApi';
import MoreActions from '../../../shared/actions/MoreActions/MoreActions';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    placeItemText: {
      paddingRight: '95px'
    },
    codeItemSelected: {
      backgroundColor: theme.palette.primary[500],
      color: theme.palette.common.white
    },
    markup: {
      backgroundColor: theme.palette.primary.main,
      top: '-11px',
      color: 'white',
      border: 'solid',
      right: '10px',
      position: 'relative',
      borderRadius: '100%',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    [breakpoints.down('xs')]: {
      placeItem: {
        paddingLeft: 0
      },
      placeItemText: {
        paddingLeft: 0,
        paddingRight: '25px'
      },
      actions: {
        '& button': {
          width: 'initial'
        }
      }
    }
  };
};

export function FavoriteItem({
  chipNumber,
  primaryText,
  secondaryText,
  selected,
  withMarkup,
  onItemSelect,
  onItemEdit,
  onItemRemove,
  intl,
  classes
}) {
  const onDeleteClicked = () => {
    AlertApi.showConfirm({
      onConfirm: onItemRemove,
      title: intl.formatMessage({ id: 'itemDeletionConfirmation' }),
      content: intl.formatMessage({ id: 'irreversibleOperation' })
    });
  };

  return (
    <ListItem button onClick={onItemSelect} className={classes.placeItem}>
      <ListItemIcon>
        <>
          <Chip label={chipNumber} className={selected ? classes.codeItemSelected : ''} />
          {withMarkup && <Typography className={classes.markup}>%</Typography>}
        </>
      </ListItemIcon>
      <ListItemText primary={primaryText} secondary={secondaryText} className={classes.placeItemText} />
      <ListItemSecondaryAction className={classes.actions}>
        {onItemEdit && onItemRemove && (
          <MoreActions
            actions={[
              {
                key: 'remove-action-key',
                labelKey: 'editableCollectionWithCategories.edit',
                clickEvent: onItemEdit,
                Icon: EditIcon
              },
              {
                key: 'remove-action-key',
                labelKey: 'editableCollectionWithCategories.delete',
                clickEvent: onDeleteClicked,
                Icon: DeleteIcon
              }
            ]}
          />
        )}

        {onItemEdit && !onItemRemove && (
          <IconButton onClick={onItemEdit}>
            <EditIcon />
          </IconButton>
        )}

        {onItemRemove && !onItemEdit && (
          <IconButton onClick={onDeleteClicked}>
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

FavoriteItem.defaultProps = {
  secondaryText: '',
  selected: false,
  withMarkup: false,
  onItemEdit: undefined,
  onItemSelect: undefined,
  onItemRemove: undefined,
  intl: undefined
};

FavoriteItem.propTypes = {
  selected: PropTypes.bool,
  withMarkup: PropTypes.bool,
  primaryText: PropTypes.node.isRequired,
  secondaryText: PropTypes.node,
  chipNumber: PropTypes.string.isRequired,
  onItemSelect: PropTypes.func,
  onItemEdit: PropTypes.func,
  onItemRemove: PropTypes.func,
  intl: PropTypes.object
};

export default compose(injectIntl, withStyles(styles))(FavoriteItem);
