/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { sortBy } from 'lodash';
import React from 'react';
import { compose } from 'redux';
import { currencyFormat } from '../../../../../utils/wordFormatUtilities';
import ActivityType from '../../../../../shared/core/activity/domain/ActivityType';
import type MedyxTheme from '../../../../../utils/muiTheme';
import PeriodDayRow from './PeriodDayRow/PeriodDayRow';
import PeriodSubTotalRow from './PeriodSubTotalRow/PeriodSubTotalRow';
import PeriodAmountServiceFactory from '../../../../../shared/amountComputation/amount/PeriodAmountServiceFactory';
import LumpSum from '../../../../../shared/domain/activity/lumpSum/model/LumpSum';
import { Mixte } from '../../../../../shared/domain/activity/mixte/model/Mixte';
import { LatestMixtePeriodFormDto } from '../../../../containers/PeriodsBilling/PeriodForm/type';
import PeriodHoursService from '../../../../containers/PeriodsBilling/PeriodForm/service/PeriodHoursService';

export const styles = (theme: typeof MedyxTheme) => ({
  totalAmountContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderTop: `2px solid ${theme.palette.divider}`
  }
});

export interface Props {
  /* eslint-disable no-unused-vars */
  shouldDisplayDay?: (day: any) => boolean;
  activity: Mixte | LumpSum;
  TimeSummaryComponent: (day: any) => JSX.Element;
  /* eslint-enable */
  classes?: any;
}

export const PeriodDaysSummary: React.FunctionComponent<Props> = ({
  shouldDisplayDay = () => true,
  activity,
  classes,
  TimeSummaryComponent
}) => {
  const periodAmountService = PeriodAmountServiceFactory.create(activity);

  const displayDayRows = () => (
    <>
      {sortBy(periodAmountService.getAmountByDay(), (dayWithComputedAmount) => dayWithComputedAmount.date)
        .filter((day) => shouldDisplayDay(day))
        .map((dayWithComputedAmount, index) => (
          <PeriodDayRow
            // eslint-disable-next-line react/no-array-index-key
            key={`${activity.id}${dayWithComputedAmount.date}${index}`}
            dayWithComputedAmount={dayWithComputedAmount}
            TimeSummaryComponent={TimeSummaryComponent}
          />
        ))}
    </>
  );

  const displaySubTotalRowForLumpSumOnly = () =>
    activity.type === ActivityType.LUMP_SUM &&
    activity.days.length > 0 && <PeriodSubTotalRow totalAmountByCodes={periodAmountService.getTotalAmountByCode()} />;

  const displayTotalAmountRow = () => (
    <Grid container className={classes.totalAmountContainer} spacing={0}>
      <Grid item xs={11}>
        <Typography variant="body2">
          <b>Total</b>
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2" className="total-amount">
          <b>{currencyFormat(periodAmountService.getTotalAmount())}</b>
        </Typography>
      </Grid>
    </Grid>
  );

  const displayTotalHoursRowForMixteOnly = () =>
    activity.type === ActivityType.MIXTE && (
      <Grid container className={classes.totalAmountContainer} spacing={0}>
        <Grid item xs={9}>
          <Typography variant="body2">
            <b>Total Heures</b>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" className="total-hours">
            <b>{PeriodHoursService.getMixteHours(activity as LatestMixtePeriodFormDto)}</b>
          </Typography>
        </Grid>
      </Grid>
    );

  return (
    <>
      {displayDayRows()}
      {displaySubTotalRowForLumpSumOnly()}
      {displayTotalAmountRow()}
      {displayTotalHoursRowForMixteOnly()}
    </>
  );
};

export default compose(withStyles(styles))(PeriodDaysSummary);
