import get from 'lodash/get';
import { isUserAtLeast } from 'application/utilities/authentication';
import { selectCurrentLoggedUser } from 'app/containers/Authentication/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import Role from 'shared/domain/authentication/Role';
import Act, { OutsideRamqPatientType } from '../../../../shared/domain/activity/act/model/Act';
import User from '../../../../shared/domain/user/User';
import { actFormAdminFields, actFormFields } from '../../../containers/ActForm/constants';
import { createErrorMapWithEveryValidationFunction, requiredFieldValidations } from '../../common/reduxFormValidation';
import { OUTSIDE_RAMQ_PATIENT_TYPE } from '../../../../shared/ramq/domainValues/situationTypes';
import OutsideRamqFields from './OutsideRamqFields';
import { isSpecialtyAnesthesiologist } from '../../../../shared/domain/doctor/Doctor';
import OutsideRamqBillingManager from 'shared/domain/activity/act/patientInformation/outsideRamq/OutsideRamqBillingManager';

const NO_ERRORS = {};

function outsideRamqValidations(user: User) {
  return (act: Act) => {
    if (!isOutsideRamq(act)) return {};

    return createErrorMapWithEveryValidationFunction(act, [
      validateDateHospitalisation,
      validatePatient(user),
      validateDoctorDisclaimerAccepted
    ]);
  };
}

function validateDateHospitalisation(act: Act) {
  if (!OutsideRamqFields.isDateHospitalisationFieldRequired(act.patientInformation as object)) return {};

  return createErrorMapWithEveryValidationFunction(act, [
    requiredFieldValidations([actFormFields.dateHospitalisation])
  ]);
}

function validatePatient(user: User) {
  return (act: Act) =>
    createErrorMapWithEveryValidationFunction(act, [
      requiredFieldValidations([patientInformationFieldName('firstName')]),
      requiredFieldValidations([patientInformationFieldName('lastName')]),
      requiredFieldValidations(getOutsideRamqSubFormulaSpecificRequiredFields(act, user))
    ]);
}

function validateDoctorDisclaimerAccepted(act: Act) {
  if (act?.patientInformation?.outsideRamq?.patientType !== OutsideRamqPatientType.BILLED) {
    return NO_ERRORS;
  }

  if (
    act?.patientInformation?.outsideRamq?.billingManager === OutsideRamqBillingManager.MEDYX &&
    act?.patientInformation?.outsideRamq?.allowMedyxToContactWithPatient
  ) {
    return NO_ERRORS;
  }

  if (act?.patientInformation?.outsideRamq?.billedDisclaimerAccepted) {
    return NO_ERRORS;
  }

  const loggedUser = selectCurrentLoggedUser()(getStore().getState());

  if (isUserAtLeast(loggedUser.role, Role.Agent)) {
    return NO_ERRORS;
  }

  return {
    patientInformation: {
      outsideRamq: {
        billedDisclaimerAccepted: "Vous devez accepter l'avertissement pour continuer."
      }
    }
  };
}

function getOutsideRamqSubFormulaSpecificRequiredFields(act: Act, user: User) {
  const { patientInformation } = act;

  switch (patientInformation?.outsideRamq?.patientType) {
    case OutsideRamqPatientType.ABROAD_STUDENT:
      return [
        patientInformationFieldName('outsideRamq.billingManager'),
        patientInformationFieldName('birthDate'),
        patientInformationFieldName('studentNumber'),
        patientInformationFieldName('groupNumberOrUniversityName')
      ];
    case OutsideRamqPatientType.PRIVATE_INSURANCE: {
      const requiredFields = [
        patientInformationFieldName('outsideRamq.billingManager'),
        patientInformationFieldName('birthDate'),
        patientInformationFieldName('insurance.name'),
        patientInformationFieldName('insurance.policyCertificateNumber'),
        patientInformationFieldName('outsideRamq.allowMedyxToContactWithPatient')
      ];

      if (isSpecialtyAnesthesiologist(user.specialty)) {
        requiredFields.push(actFormFields.freeTextPatientDiagnostic);
        requiredFields.push(actFormFields.doctor);
      }

      return requiredFields;
    }
    case OutsideRamqPatientType.OUTSIDE_RAMQ: {
      const requiredFields = [
        patientInformationFieldName('outsideRamq.billingManager'),
        patientInformationFieldName('province'),
        patientInformationFieldName('outsideRamq.allowMedyxToContactWithPatient')
      ];

      if (isSpecialtyAnesthesiologist(user.specialty)) {
        requiredFields.push(actFormFields.freeTextPatientDiagnostic);
        requiredFields.push(actFormFields.doctor);
      }

      return requiredFields;
    }
    case OutsideRamqPatientType.BILLED:
      return [
        patientInformationFieldName('address'),
        patientInformationFieldName('phone'),
        patientInformationFieldName('paymentType'),
        actFormFields.end,
        actFormAdminFields.manualCorrection
      ];
    case OutsideRamqPatientType.PRISONER:
    case OutsideRamqPatientType.MILITARY:
    case OutsideRamqPatientType.REFUGEE:
    default:
      return [patientInformationFieldName('outsideRamq.billingManager')];
  }
}

function isOutsideRamq(act: Act) {
  return get(act, `${actFormFields.patientInformation}.type`, undefined) === OUTSIDE_RAMQ_PATIENT_TYPE;
}

function patientInformationFieldName(name: string): string {
  return `${actFormFields.patientInformation}.${name}`;
}

export default outsideRamqValidations;
