import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import Section from 'application/components/form-controls/section';
import useTranslate from 'application/hooks/use-translate';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import React from 'react';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import PasswordInput from 'application/components/form-controls/password-input';
import MaskedInput from 'application/components/form-controls/masked-input';

interface Props {
  user: FirestoreUser;
  registerField: (field: string) => ValidatedFieldProps;
  onChange: (user: FirestoreUser) => void;
}

const Ramq = ({ user, registerField, onChange }: Props) => {
  const translate = useTranslate('pages.settings.partners.ramq');

  return (
    <Section label={translate('title')}>
      <FieldContainer helper={translate('username-helper')} label={translate('username')}>
        <MaskedInput
          {...registerField('ramqUsername')}
          mask="________"
          onChange={(ramqUsername) => onChange({ ...user, ramqUsername })}
          placeholder="ip123456"
          value={user.ramqUsername}
        />
      </FieldContainer>
      <FieldContainer label={translate('password')}>
        <PasswordInput
          {...registerField('ramqPassword')}
          value={user.ramqPassword}
          onChange={(ramqPassword) => onChange({ ...user, ramqPassword })}
        />
      </FieldContainer>
      <Section.Footer>
        <Button type="submit" className="btn btn-primary">
          {translate('save')}
        </Button>
      </Section.Footer>
    </Section>
  );
};

export default Ramq;
