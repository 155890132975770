import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { compose } from '@reduxjs/toolkit';
import { WithStyles, withStyles } from '@material-ui/styles';
import PERIOD_FORM_NAME from '../constants';
import PeriodHoursService from '../PeriodForm/service/PeriodHoursService';
import { LatestMixtePeriodFormDto } from '../PeriodForm/type';

export interface Props extends WithStyles<typeof styles> {
  mixte?: LatestMixtePeriodFormDto;
}

const halfPerdiemDurationInHours = 3.5;

export const styles = (theme) => ({
  billedHours: {
    margin: '30px 20px 0px'
  },
  billedPerdiems: {
    margin: '0px 20px'
  },
  warningMessage: {
    fontSize: '0.9rem',
    fontStyle: 'italic',
    marginLeft: 20,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.yellow[5],
    color: theme.palette.yellow[900]
  }
});

export const MixteBilledHours: React.FunctionComponent<Props> = ({ mixte, classes }: Props) => {
  if (!mixte) return null;

  const mixteHours = PeriodHoursService.getMixteHours(mixte);
  const halfPerdiemsCount = PeriodHoursService.getHalfPerdiemsCount(mixte);
  const allowedPerdiems = mixteHours / halfPerdiemDurationInHours;
  const allowedPerdiemsRoundedDown = Math.floor(allowedPerdiems);
  const overDuration = Math.round((mixteHours % halfPerdiemDurationInHours) * 100) / 100;
  const missingDuration = Math.round((halfPerdiemsCount - allowedPerdiems) * halfPerdiemDurationInHours * 100) / 100;

  return (
    <>
      <Typography className={classes.billedHours}>
        <FormattedMessage data-testid="billed-hours-message" id="period.billedHours" values={{ hours: mixteHours }} />
      </Typography>
      <Typography className={classes.billedPerdiems}>
        <FormattedMessage
          id="period.billedPerdiems"
          values={{ perdiems: halfPerdiemsCount, allowedPerdiems: allowedPerdiemsRoundedDown }}
        />
        {allowedPerdiems !== halfPerdiemsCount && (
          <span className={classes.warningMessage}>
            {allowedPerdiems > halfPerdiemsCount && (
              <FormattedMessage
                id="period.billedPerdiems.overAllowed"
                values={{
                  perdiems: halfPerdiemsCount,
                  allowedPerdiems: allowedPerdiemsRoundedDown,
                  duration: overDuration
                }}
              />
            )}
            {allowedPerdiems < halfPerdiemsCount && (
              <FormattedMessage
                id="period.billedPerdiems.underAllowed"
                values={{
                  perdiems: halfPerdiemsCount,
                  allowedPerdiems: allowedPerdiemsRoundedDown,
                  duration: missingDuration
                }}
              />
            )}
          </span>
        )}
      </Typography>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  mixte: (state: any) => {
    const { form } = state;
    if (!form) return null;
    return form[PERIOD_FORM_NAME]?.values;
  }
});

export default compose(connect(mapStateToProps), withStyles(styles))(MixteBilledHours) as React.FunctionComponent;
