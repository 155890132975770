import SingleSelectCheckboxGroup from 'app/components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import DISCONTINUED_SERVICE from 'shared/ramq/requiredDetails/discontinuedService';
import YES_NO_CHECKBOX_VALUES from '../../../ui/shared/form/constants';

const summaryRender = (code) =>
  code[DISCONTINUED_SERVICE] !== undefined
    ? [
        {
          name: 'Service discontinué',
          value: code[DISCONTINUED_SERVICE] ? 'Oui' : 'Non'
        }
      ]
    : [];

const associatedFields = [
  {
    label: 'Service discontinué',
    getName: (index) => `codes[${index}].${DISCONTINUED_SERVICE}`,
    type: SingleSelectCheckboxGroup,
    list: YES_NO_CHECKBOX_VALUES,
    props: {
      defaultValue: false
    }
  }
];

export default { associatedFields, summaryRender };
