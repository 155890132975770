import PropTypes from 'prop-types';
import React from 'react';
import orderBy from 'lodash/orderBy';
import Category from '../Category/Category';
import CodeSuggestion from '../Item/CodeSuggestion/CodeSuggestion';

// appears with code 6946
export function CategoriesList({ list, onItemSelected, onClose, initiallyOpen, toggleSelected, isItemSelected }) {
  return (
    <>
      {orderBy(list, ['order'], ['asc']).map((category) => (
        <Category
          ItemComponent={CodeSuggestion}
          category={category}
          key={category.category}
          initiallyOpen={initiallyOpen}
          onSuggestionClick={(value) => onItemSelected(value) || onClose()}
          isItemSelected={isItemSelected}
          toggleSelected={toggleSelected}
        />
      ))}
    </>
  );
}

CategoriesList.propTypes = {
  initiallyOpen: PropTypes.bool,
  list: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  isItemSelected: PropTypes.func,
  toggleSelected: PropTypes.bool,
  onClose: PropTypes.func
};

CategoriesList.defaultProps = {
  initiallyOpen: false,
  toggleSelected: false,
  isItemSelected: () => false,
  onClose: () => {}
};

export default CategoriesList;
