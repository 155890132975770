import React, { useEffect, useState } from 'react';
import FieldContainer from './field-container';

interface Props {
  type?: 'date' | 'datetime-local' | 'month' | 'week';
  label?: string;
  value: Date | null;
  maxDate?: Date | string;
  onChange: (value: Date | null) => void;
  className?: string;
}

const DatePicker = ({ type = 'date', label, value, maxDate, onChange, className }: Props) => {
  const [stringValue, setStringValue] = useState(toStringWithoutTime(value));

  useEffect(() => {
    if (!value) {
      setStringValue('');
    } else if (type === 'month') {
      setStringValue(toStringWithoutTime(value).slice(0, 7));
    } else {
      setStringValue(toStringWithoutTime(value));
    }
  }, [value, type]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = event.target.value;
    setStringValue(dateString);
    const parsedDate = Date.parse(`${dateString} 00:00:00`);
    const date = parsedDate ? new Date(parsedDate) : null;
    onChange(date);
  };

  return (
    <FieldContainer label={label} className={className}>
      <input
        className="input-bordered input flex-auto text-sm font-semibold"
        max={toStringWithoutTime(maxDate)}
        onChange={handleDateChange}
        type={type}
        value={stringValue}
      />
    </FieldContainer>
  );
};

const toStringWithoutTime = (date?: Date | string | null) => {
  if (typeof date === 'string') {
    const parsedDate = Date.parse(date);
    return parsedDate ? new Date(parsedDate).toISOString().split('T')[0] : '';
  }
  return date ? date?.toISOString().split('T')[0] : '';
};

export default DatePicker;
