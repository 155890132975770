import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import FullScreenLoading from '../../../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { selectActivitiesLoading } from '../../../Activities/selectors';
import { ACTIVITY_ID_ROUTE_PARAM } from '../../../Navigation/constants';
import TravelExpenses from '../../../TravelExpenses/TravelExpenses';

function TravelExpensesRoute({ loading }) {
  if (loading) {
    return <FullScreenLoading />;
  }

  return (
    <Routes>
      <Route path="new" element={<TravelExpenses />} />
      <Route path={`${ACTIVITY_ID_ROUTE_PARAM}`} element={<TravelExpenses />} />
    </Routes>
  );
}

TravelExpensesRoute.propTypes = {
  loading: PropTypes.bool.isRequired
};

export const mapStateToProps = createStructuredSelector({
  loading: selectActivitiesLoading()
});

export default compose(connect(mapStateToProps, null))(TravelExpensesRoute);
