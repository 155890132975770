import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import isDefined from '../../../../shared/utils/isDefined';
import { selectPatientCarence, selectPatientType } from '../../../containers/ActForm/selectors';
import CollapsableIcon from '../CollapsableIcon';

export function NoNamCollapsableIcon({ toggleNoNamForm, noNamFormVisible, selectedPatientType, selectedCarence }) {
  return (
    <CollapsableIcon
      onClick={toggleNoNamForm}
      expanded={noNamFormVisible}
      selected={isDefined(selectedPatientType) || isDefined(selectedCarence)}
      label="N.D."
    />
  );
}

NoNamCollapsableIcon.propTypes = {
  toggleNoNamForm: PropTypes.func.isRequired,
  noNamFormVisible: PropTypes.bool.isRequired,
  selectedPatientType: PropTypes.string,
  selectedCarence: PropTypes.string
};

NoNamCollapsableIcon.defaultProps = {
  selectedPatientType: undefined,
  selectedCarence: undefined
};

export const mapStateToProps = createStructuredSelector({
  selectedPatientType: selectPatientType(),
  selectedCarence: selectPatientCarence()
});

export default compose(connect(mapStateToProps, null))(NoNamCollapsableIcon);
