import TRANSPORT_TYPES from '../../../shared/ramq/domainValues/transportTypes';
import TRAVEL_FEES_TYPES from '../../../shared/ramq/domainValues/travelFeesTypes';
import { validateValueIsDefined } from '../common/reduxFormValidation';
import validateDateAndTransportType from './common/commonFeeValidation';

const isTravelTimeFee = (fee) => fee.type === TRAVEL_FEES_TYPES.travelTimeFee;

// According to page 31
// http://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/manuels/syra/medecins-specialistes/GuideSpec.pdf
const transportTypeToRequiredFieldNamesMap = {
  [TRANSPORT_TYPES.autobus]: ['travelingHours'],
  [TRANSPORT_TYPES.covoiturage]: ['goingKilometers'],
  [TRANSPORT_TYPES.taxi]: ['travelingHours'],
  [TRANSPORT_TYPES.train]: ['travelingHours'],
  [TRANSPORT_TYPES.traversier]: ['travelingHours', 'waitingHours'],
  [TRANSPORT_TYPES.vehiculeLoue]: ['goingKilometers'],
  [TRANSPORT_TYPES.vehiculePerso]: ['goingKilometers'],
  [TRANSPORT_TYPES.volCommercial]: ['travelingHours', 'waitingHours'],
  [TRANSPORT_TYPES.volPersonnel]: ['travelingHours'],
  [TRANSPORT_TYPES.volNolise]: ['travelingHours']
};

const validateRequiredFieldAccordingToTransportType = (fee, errorMap, feeFieldName) => {
  if (fee.transportType) {
    transportTypeToRequiredFieldNamesMap[fee.transportType].forEach((requiredFieldName) => {
      validateValueIsDefined(fee, requiredFieldName, feeFieldName, errorMap);
    });
  }
};

const travelTimeFeeValidation = (fees) => {
  const errorMap = {};
  fees.forEach((fee, index) => {
    if (isTravelTimeFee(fee)) {
      const feeFieldName = `fees[${index}]`;
      validateDateAndTransportType(fee, errorMap, feeFieldName);
      validateRequiredFieldAccordingToTransportType(fee, errorMap, feeFieldName);
    }
  });
  return errorMap;
};

export default travelTimeFeeValidation;
