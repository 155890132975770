import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormElement from 'app/components/Form/FormElement/FormElement';
import { DateField, SingleLineField } from 'app/components/Form/Fields';
import { useSelector } from 'react-redux';
import { selectUserStripeCustomerId, selectUserStripeSubscriptionMetaData } from 'app/containers/User/selectors';
import useTranslate from 'application/hooks/use-translate';
import { StripeStatus } from 'application/types/stripe-status';
import Button from 'application/components/button';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import SubscriptionPeriodEnd from './SubscriptionPeriodEnd';

export const Subscription: React.FunctionComponent = () => {
  const translate = useTranslate('pages.profile.stripe-subscription');
  const userStripeCustomerId = useSelector(selectUserStripeCustomerId);
  const userStripeSubscriptionMetaData = useSelector(selectUserStripeSubscriptionMetaData);
  const subscriptionStatus = userStripeSubscriptionMetaData?.status;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Typography gutterBottom variant="h6">
            {translate('subscription')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormElement
            name={
              subscriptionStatus === StripeStatus.TRIALING
                ? 'stripeSubscriptionMetadata.trialStart'
                : 'stripeSubscriptionMetadata.currentPeriodStart'
            }
            label={translate(subscriptionStatus === StripeStatus.TRIALING ? 'trial-begin' : 'period-begin')}
            disabled
            component={DateField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SubscriptionPeriodEnd subscriptionStatus={subscriptionStatus} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormElement
            name="stripeSubscriptionMetadata.status"
            label={translate('status')}
            disabled
            component={SingleLineField}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormElement
            name="stripeSubscriptionMetadata.productName"
            label={translate('product-name')}
            disabled
            component={SingleLineField}
          />
        </Grid>
      </Grid>

      <Restrict atLeastRole={Role.Admin}>
        <div>
          <Button
            theme="primary"
            className="mt-4"
            onClick={() => window.open(`https://dashboard.stripe.com/customers/${userStripeCustomerId}`, '_blank')}
          >
            {translate('view-customer')}
          </Button>
        </div>
      </Restrict>
    </>
  );
};
