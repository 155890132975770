import { useQuery } from '@tanstack/react-query';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import StatisticsService from 'application/services/statistics-service';
import Chart from 'application/components/chart/chart';
import Icon from 'application/components/icon';
import openUrl from 'application/utilities/open-url';

const Configurator = () => null;

const Renderer = () => {
  const translate = useTranslate('pages.administration.stats');

  const { data, isFetching } = useQuery({
    queryKey: ['users-devices-statistic'],
    queryFn: () => StatisticsService.getUsersDevices()
  });

  if (!data) {
    return <Icon loading />;
  }

  const platformTotals = {
    iOS: data.iOS.total,
    Android: data.android.total,
    Web: data.web.total
  };

  return (
    <div className="flex w-full flex-col items-center gap-2 divide-y-2">
      <div className="flex w-full items-center">
        <div className="max-w-screen-md">
          <Chart
            loading={isFetching}
            type="doughnut"
            title={translate('users-devices')}
            data={Object.entries(platformTotals).map(([platform, total]) => ({ label: platform, value: total })) ?? []}
            showLegend
          />
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {Object.entries(platformTotals).map(([platform, total]) => (
            <div key={platform} className="flex flex-col justify-between border p-2">
              <div className="text-neutral-500">{platform}</div>
              <div className="text-4xl font-extrabold text-neutral-800">{total}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex w-full items-center">
        <div className="max-w-screen-md">
          <Chart
            loading={isFetching}
            type="doughnut"
            title="iOS"
            data={
              data.iOS.modelsWithTotal.map((row) => ({
                label: row.model,
                value: row.total
              })) ?? []
            }
            onClick={(label, _value) => openUrl(`https://www.gsmarena.com/res.php3?sSearch=${label}`)}
          />
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {data.iOS.modelsWithTotal.map((row) => (
            <div
              key={row.model}
              className="flex cursor-pointer flex-col justify-between border p-2"
              onClick={() => openUrl(`https://www.gsmarena.com/res.php3?sSearch=${row.model}`)}
            >
              <div className="text-neutral-500">{row.model}</div>
              <div className="text-4xl font-extrabold text-neutral-800">{row.total}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex w-full items-center">
        <div className="max-w-screen-md">
          <Chart
            loading={isFetching}
            type="doughnut"
            title="Android"
            data={
              data.android.modelsWithTotal.map((row) => ({
                label: row.model,
                value: row.total
              })) ?? []
            }
            onClick={(label, _value) => openUrl(`https://www.gsmarena.com/res.php3?sSearch=${label}`)}
          />
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {data.android.modelsWithTotal.map((row) => (
            <div
              key={row.model}
              className="flex cursor-pointer flex-col justify-between border p-2"
              onClick={() => openUrl(`https://www.gsmarena.com/res.php3?sSearch=${row.model}`)}
            >
              <div className="text-neutral-500">{row.model}</div>
              <div className="text-4xl font-extrabold text-neutral-800">{row.total}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex w-full items-center">
        <div className="max-w-screen-md">
          <Chart
            loading={isFetching}
            type="doughnut"
            title="Web"
            data={
              data.web.modelsWithTotal.map((row) => ({
                label: row.model,
                value: row.total
              })) ?? []
            }
          />
        </div>

        <div className="grid">
          {data.web.modelsWithTotal.map((row) => (
            <div key={row.model} className="flex flex-col justify-between border p-2">
              <div className="text-neutral-500">{row.model}</div>
              <div className="text-4xl font-extrabold text-neutral-800">{row.total}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default {
  Configurator,
  Renderer
};
