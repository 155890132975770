import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import DialogTrigger from '../../../../../components/Dialogs/DialogTrigger/DialogTrigger';
import CommentEditorDialog from '../EditCommentDialog/CommentEditorDialog';

export function AdminNotePopup({ onSent, act }) {
  return (
    <DialogTrigger
      DialogComponent={CommentEditorDialog}
      onItemSelected={onSent}
      dialogProps={{ title: 'Ajouter une note', act }}
    >
      <Button>Suivi</Button>
    </DialogTrigger>
  );
}

AdminNotePopup.propTypes = {
  onSent: PropTypes.func.isRequired,
  act: PropTypes.object
};

AdminNotePopup.defaultProps = {
  act: undefined
};

export default AdminNotePopup;
