import * as queryString from 'qs';
import RalphApi from 'application/services/ralph-api';
import { downloadFile } from 'app/infrastructure/api/apiUtils';

export enum ReportAPIResponseType {
  PDF = 'pdf',
  HTML = 'html',
  JSON = 'json',
  CSV = 'csv'
}

export default class ReportService {
  // Temporary endpoint to fulfill the payment requests page in Medyx's group section
  public static async fetchPoolStatement(poolId: string, statementDate: number): Promise<[]> {
    const queryParams = queryString.stringify({ poolId, statementDate });
    const response = await RalphApi.sendRequest(`statement-temporary/pool?${queryParams}`, 'GET');

    return response.json() as Promise<[]>;
  }

  public static async fetchPoolPaymentRequestsBySentDate(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ) {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`payment-requests-by-sent-date?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadPoolPaymentRequestsBySentDate(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ) {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`payment-requests-by-sent-date?${queryParams}`, 'GET');
  }

  public static async downloadDoctorPaymentRequestsBySentDate(
    userId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ) {
    const queryParams = queryString.stringify({ userId, ...billingPeriod, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`payment-requests-by-sent-date?${queryParams}`, 'GET');
  }

  public static async fetchPoolPaymentRequestsByCategory(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ): Promise<string> {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`group-payment-requests-by-category?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadPoolPaymentRequestsByCategory(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`group-payment-requests-by-category?${queryParams}`, 'GET');
  }

  public static async fetchPaymentRequestsByCodeTypeReport(groupId: string, year: number): Promise<string> {
    const queryParams = queryString.stringify({ groupId, year, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`group-payment-requests-by-code-type?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadPaymentRequestsByCodeTypeReport(groupId: string, year: number): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, year, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`group-payment-requests-by-code-type?${queryParams}`, 'GET') as Promise<Response>;
  }

  public static async fetchPoolOutsideRAMQPaymentRequests(groupId: string, year: number): Promise<string> {
    const queryParams = queryString.stringify({ groupId, year, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`payment-requests-outside-ramq?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadPoolOutsideRAMQPaymentRequests(groupId: string, year: number): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, year, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`payment-requests-outside-ramq?${queryParams}`, 'GET') as Promise<Response>;
  }

  public static async exportPoolOutsideRAMQPaymentRequests(groupId: string, year: number): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, year, mode: ReportAPIResponseType.CSV });

    return RalphApi.sendRequest(`payment-requests-outside-ramq?${queryParams}`, 'GET') as Promise<Response>;
  }

  public static async fetchAnnualGuardReport(groupId: string, year: number): Promise<string> {
    const queryParams = queryString.stringify({ groupId, year, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`group-guard-value-by-year?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadAnnualGuardReport(groupId: string, year: number): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, year, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`group-guard-value-by-year?${queryParams}`, 'GET') as Promise<Response>;
  }

  public static async fetchPeriodSummaryReport(groupId: string, date: number): Promise<string> {
    const queryParams = queryString.stringify({ groupId, date, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`period-summary?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadPeriodSummaryReport(groupId: string, date: number): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, date, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`period-summary?${queryParams}`, 'GET') as Promise<Response>;
  }

  public static async exportPeriodSummaryReport(groupId: string, date: number): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, date, mode: ReportAPIResponseType.CSV });

    return RalphApi.sendRequest(`period-summary?${queryParams}`, 'GET') as Promise<Response>;
  }

  public static async fetchReceivableAccountsForPoolReport(groupId: string, date: Date): Promise<string> {
    const queryParams = queryString.stringify({ groupId, date: date.valueOf(), mode: ReportAPIResponseType.HTML });

    const response = await RalphApi.sendRequest(`receivable-accounts?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadReceivableAccountsForPoolReport(groupId: string, date: Date): Promise<Response> {
    const queryParams = queryString.stringify({ groupId, date: date.valueOf(), mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`receivable-accounts?${queryParams}`, 'GET') as Promise<Response>;
  }

  public static async fetchPoolGuardValueBySentDate(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ) {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`group-guard-value-by-sent-date?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async fetchPoolNonGuardDailyValue(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ) {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.HTML });
    const response = await RalphApi.sendRequest(`group-non-guard-daily-value?${queryParams}`, 'GET');

    return response.text() as Promise<string>;
  }

  public static async downloadPoolGuardValueBySentDate(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ) {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`group-guard-value-by-sent-date?${queryParams}`, 'GET');
  }

  public static async downloadPoolNonGuardDailyValue(
    groupId: string,
    billingPeriod: { periodStart: number; periodEnd: number }
  ) {
    const queryParams = queryString.stringify({ groupId, ...billingPeriod, mode: ReportAPIResponseType.PDF });

    return RalphApi.sendRequest(`group-non-guard-daily-value?${queryParams}`, 'GET');
  }

  public static async getRecentUsersReport(mode?: 'html' | 'pdf' | 'csv') {
    const queryParams = queryString.stringify({ mode });
    const response = await RalphApi.sendRequest(`recent-users?${queryParams}`, 'GET');

    return downloadFile(response);
  }
}
