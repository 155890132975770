import ActCodes from '../../../../shared/domain/activity/act/model/ActCodes';
import { actFormFields } from '../../../containers/ActForm/constants';
import Act from '../../../../shared/domain/activity/act/model/Act';

const codeSupplementValidation = ({ codes }: Act) => {
  const actCodes = new ActCodes(codes);
  const [supplementCodes, mainCodes] = actCodes.partition(({ isSupplement }) => isSupplement === true);

  if (supplementCodes.size() > 0 && mainCodes.size() === 0) {
    const fieldName = actFormFields.codes;
    const message = 'Un supplément ne peut être facturé seul. SVP ajouter le code principal.';

    return {
      [fieldName]: {
        _error: message
      }
    };
  }

  return {};
};

export default codeSupplementValidation;
