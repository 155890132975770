export const MULTI_PLACE_GUARD_ACT_CODES = [
  '19568',
  '19569',
  '19405',
  '19406',
  '19427',
  '19428',
  '19808',
  '19809',
  '19570',
  '19571',
  '42032',
  '42033',
  '19637',
  '19638',
  '42245',
  '42246',
  '42247',
  '42248',
  '19407',
  '19408',
  '19429',
  '19430',
  '19777',
  '19778',
  '19779',
  '19780',
  '19572',
  '19573',
  '19588',
  '19589',
  '19409',
  '19410',
  '19658',
  '19659',
  '42034',
  '42035',
  '19602',
  '19603',
  '19574',
  '19575',
  '19604',
  '19605',
  '42036',
  '42037',
  '19781',
  '19782',
  '42040',
  '42041',
  '19606',
  '19607',
  '19576',
  '19577',
  '19639',
  '19640',
  '19810',
  '19811',
  '19411',
  '19412',
  '19431',
  '19432',
  '19578',
  '19579',
  '19413',
  '19414',
  '19580',
  '19581',
  '42046',
  '42047',
  '19415',
  '19416',
  '19417',
  '19418',
  '19433',
  '19434',
  '19419',
  '19420',
  '19608',
  '19609',
  '19421',
  '19422',
  '42042',
  '42043',
  '19812',
  '19813',
  '19814',
  '19815',
  '19582',
  '19583',
  '19818',
  '19819',
  '19820',
  '19821',
  '19610',
  '19611',
  '19612',
  '19613',
  '19584',
  '19585',
  '19590',
  '19591',
  '19586',
  '19587',
  '19831',
  '19832',
  '19425',
  '19426'
];
