import { Item } from 'application/services/presets-service';
import Input from 'application/components/form/input';
import Icon from 'application/components/icon';
import React from 'react';

interface Props {
  newItem: Item;
  onSubmit: (item: Item) => void;
  onCancel: () => void;
  onChange: (item: Item) => void;
  getDescription: (code: string) => string;
}

const NewItemForm: React.FunctionComponent<Props> = ({ newItem, onChange, onSubmit, onCancel, getDescription }) => (
  <tr className="hover">
    <td>
      <Input
        type="search"
        autofocus
        value={newItem.code}
        onChange={(updatedValue) => {
          onChange({ ...newItem, code: updatedValue });
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSubmit(newItem);
          } else if (event.key === 'Escape') {
            onCancel();
          }
        }}
      />
    </td>
    <td>{getDescription(newItem.code)}</td>
    <td>
      <Input
        type="search"
        value={newItem.description}
        onChange={(updatedValue: string) => {
          onChange({ ...newItem, description: updatedValue });
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSubmit(newItem);
          } else if (event.key === 'Escape') {
            onCancel();
          }
        }}
      />
    </td>
    <td>
      <div className="flex gap-2">
        <button className="btn btn-square btn-primary" type="button" onClick={() => onSubmit(newItem)}>
          <Icon icon="check" />
        </button>
        <button className="btn btn-square btn-outline" type="button" onClick={() => onCancel()}>
          <Icon icon="times" />
        </button>
      </div>
    </td>
  </tr>
);

export default NewItemForm;
