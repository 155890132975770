import React, { useEffect, useState } from 'react';
import useTranslate from 'application/hooks/use-translate';
import { useLocalStorage } from 'react-use';
import { LocalStorageKeys } from 'userCustomization/LocalStorageKeys';
import { useSelector, useDispatch } from 'react-redux';
import Section from 'application/components/form-controls/section';
import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import SelectInput from 'application/components/form-controls/select-input';
import ToggleInput from 'application/components/form-controls/toggle-input';
import useToast from 'application/hooks/use-toast';
import useFirestoreUserProfile, {
  DefaultBillingType,
  FirestoreUser
} from 'application/hooks/use-firestore-user-profile';
import DefaultBillingTypeInput from 'application/components/form-controls/default-billing-type-input';
import UserBillingType from 'shared/domain/user/UserBillingType';
import { changeUserLanguage, setUserProfileInContext } from 'app/containers/User/actions';
import { selectUserLanguage } from 'app/containers/User/selectors';

type locale = 'en' | 'fr';

interface UserReduxState {
  authentication: {
    user: {
      uid: string;
    };
  };
}

const SettingsPreferencesPage = () => {
  const userId = useSelector<UserReduxState, string>((state) => state.authentication.user.uid);
  const [user, updateUser] = useFirestoreUserProfile(userId);
  const [stagedUser, setStagedUser] = useState<FirestoreUser | null>(null);
  const translate = useTranslate('pages.settings.preferences');
  const { addToast } = useToast();

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const language = useSelector(selectUserLanguage()) as locale;

  const [hideCanceled, setHideCanceled] = useLocalStorage<boolean>(LocalStorageKeys.HIDE_CANCELED_ACTIVITIES);

  const [languageState, setLanguageState] = useState<locale>(language);
  const [hideCanceledState, setHideCanceledState] = useState<boolean>(hideCanceled || false);

  useEffect(() => {
    setStagedUser(user);
  }, [user]);

  const handleSave = () => {
    dispatch(changeUserLanguage(languageState));
    setHideCanceled(hideCanceledState);

    if (!stagedUser) return;

    updateUser(stagedUser)
      .then((updatedUser) => {
        dispatch(setUserProfileInContext(updatedUser));
        addToast({
          autoDismiss: true,
          message: translate('saved-success'),
          type: 'success'
        });
      })
      .catch(() => {
        addToast({
          autoDismiss: true,
          message: translate('saved-error'),
          type: 'error'
        });
      });
  };

  if (!user || !stagedUser) return;

  return (
    <Section label={translate('title')}>
      <FieldContainer label={translate('locale')}>
        <SelectInput
          value={languageState}
          options={[
            { value: 'en', label: translate('english') },
            { value: 'fr', label: translate('french') }
          ]}
          onChange={(value) => setLanguageState(value as locale)}
        />
      </FieldContainer>
      <FieldContainer label={translate('hide-canceled.label')} helper={translate('hide-canceled.helper')}>
        <ToggleInput value={hideCanceledState} onChange={setHideCanceledState} className="toggle toggle-primary" />
      </FieldContainer>
      {user.billingType === UserBillingType.POOL && (
        <FieldContainer label={translate('default-billing-type')} infoMarkdownSlug="default-billing-type">
          <DefaultBillingTypeInput
            value={stagedUser.defaultBillingType ?? DefaultBillingType.AUTO}
            onChange={(defaultBillingType) => setStagedUser({ ...stagedUser, defaultBillingType })}
          />
        </FieldContainer>
      )}
      <Section.Footer>
        <Button type="submit" className="btn btn-primary" onClick={handleSave}>
          {translate('save')}
        </Button>
      </Section.Footer>
    </Section>
  );
};

export default SettingsPreferencesPage;
