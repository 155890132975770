import { ActOptimisationSpecification } from '../ActOptimisationSpecification';
import ActBillingContext from '../../optimisation/ActBillingContext';

export class ActIsReplacement extends ActOptimisationSpecification {
  isSatisfiedBy(context: ActBillingContext): boolean {
    return !!context.act.remp;
  }
}

export const actIsReplacement = () => new ActIsReplacement();
export const actIsNotReplacement = () => new ActIsReplacement().not();
