/* eslint-disable react/no-array-index-key */

import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  root: {
    pageBreakInside: 'avoid',
    paddingTop: 16,
    marginBottom: 16
  },
  headerRow: {
    height: 30
  },
  row: {
    'height': 30,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  },
  denseListItem: {
    paddingRight: 0
  },
  headerCell: {
    padding: '3px',
    fontSize: '0.7rem',
    color: 'rgba(0, 0, 0, 0.87)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem'
    }
  },
  secondaryHeaderCell: {
    padding: '3px',
    fontSize: '0.7rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
    textAlign: 'right',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem'
    }
  },
  contentCell: {
    padding: '3px',
    textAlign: 'right',
    color: 'black',
    borderBottom: '1px solid rgba(0, 0, 0, 0.87)'
  },
  subtitleToolbar: {
    paddingLeft: 50,
    minHeight: 48,
    backgroundColor: theme.palette.primary[500],
    color: theme.palette.common.white
  },
  verySmallFont: {
    fontSize: '0.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.0rem'
    }
  }
});

function TableHeader(columns, classes) {
  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        {columns.map((column, index) => (
          <TableCell key={index} className={classes.headerCell}>
            {column.primaryTitle}
          </TableCell>
        ))}
      </TableRow>
      <TableRow className={classes.headerRow}>
        {columns.map((column, index) => (
          <TableCell key={index} className={classes.secondaryHeaderCell}>
            {column.secondaryTitle}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TableContent(data, columns, classes) {
  return (
    <TableBody>
      {data.map((line, index) => (
        <TableRow key={index} className={classes.row}>
          {columns.map((column, columnIndex) => (
            <TableCell key={columnIndex} className={classes.contentCell}>
              <ListItemText
                className={classes.denseListItem}
                primary={<Typography className={classes.verySmallFont}>{column.primaryText(line)}</Typography>}
                secondary={
                  <Typography className={classes.verySmallFont} variant="caption">
                    {column.secondaryText && column.secondaryText(line)}
                  </Typography>
                }
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}

function TableFooterContent(data, classes) {
  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>TOTAL</Typography>}
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalCeilingCut}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalDeductionAmount}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalGrossAmount}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalNetAmount}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalFederationAmount}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalContributionAmount}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalSeizedAmount}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalInterestOnNegativeBalance}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalTransferAmount}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalInsuranceAmount}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalInterestOnRecovery}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalInterestOnAdvance}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalPostponedBalance}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalToBePostponedBalance}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalRecoveredAdvances}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalIssuedAdvances}
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.contentCell}>
          <ListItemText
            className={classes.denseListItem}
            primary={<Typography className={classes.verySmallFont}>{data.totalBalanceChanges}</Typography>}
            secondary={
              <Typography className={classes.verySmallFont} variant="caption">
                {data.totalBalancePaidAmount}
              </Typography>
            }
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export function IncomeStatementTable({ columns, data, total, classes }) {
  return (
    !isEmpty(data) && (
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} sm={12}>
          <Table size="small">
            {TableHeader(columns, classes)}
            {TableContent(data, columns, classes)}
            {TableFooterContent(total, classes)}
          </Table>
        </Grid>
      </Grid>
    )
  );
}

IncomeStatementTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  total: PropTypes.object.isRequired
};

export default withStyles(styles)(IncomeStatementTable);
