/* eslint-disable react/no-array-index-key */
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import TRAVEL_FEES_TYPES from '../../../../shared/ramq/domainValues/travelFeesTypes';
import CostOfStayFeeSummary from './CostOfStayFeeSummary';
import TransportFeeSummary from './TransportFeeSummary';
import TravelTimeFeeSummary from './TravelTimeFeeSummary';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  container: {
    marginTop: theme.spacing(2)
  }
});

const feeComponentsMapping = {
  [TRAVEL_FEES_TYPES.transportFee]: TransportFeeSummary,
  [TRAVEL_FEES_TYPES.travelTimeFee]: TravelTimeFeeSummary,
  [TRAVEL_FEES_TYPES.costOfStayFee]: CostOfStayFeeSummary
};

function TravelFeesSummary({ activity, classes }) {
  return (
    <div className={classes.wrapper}>
      <Typography align="center" variant="button">
        Liste des frais
      </Typography>
      <Divider />
      <Grid container className={classes.container}>
        {activity.fees.map((fee, index) => {
          const FeeComponent = feeComponentsMapping[fee.type];
          return <FeeComponent fee={fee} key={index} />;
        })}
      </Grid>
    </div>
  );
}

TravelFeesSummary.propTypes = {
  activity: PropTypes.object.isRequired
};

export default withStyles(styles)(TravelFeesSummary);
