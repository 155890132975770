import useFetchUserHistory from 'app/adminConsole/hooks/useFetchActivityHistory/useFetchUserHistory';
import Card from 'application/components/card';
import Collapse from 'application/components/collapse';
import useTranslate from 'application/hooks/use-translate';
import React from 'react';

interface Props {
  userId: string;
}

const UserHistory = ({ userId }: Props) => {
  const translate = useTranslate('pages.profile.history');
  const { versionsWithDeltas } = useFetchUserHistory(userId);
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Card title="Historique">
      <button type="button" className="btn btn-primary" onClick={() => setIsOpen(true)}>
        {translate('open')}
      </button>

      {isOpen && (
        <div className="modal modal-open z-[1111]">
          <div className="modal-box h-screen w-screen max-w-none">
            <div className="modal-content flex h-full grow flex-col">
              <div className="modal-header">
                <h1 className="flex justify-center text-2xl font-bold">{translate('title')}</h1>
              </div>
              <div className="modal-body grow overflow-auto">
                <div className="row">
                  {versionsWithDeltas.map((versionWithDelta) => (
                    <div key={versionWithDelta.userVersion.timestamp}>
                      <Collapse className="w-full">
                        <div className="collapse-title flex justify-between">
                          <div>{new Date(versionWithDelta.userVersion.timestamp).toLocaleString('en-CA')}</div>
                          <div>{versionWithDelta.userVersion.user?.auditInformation?.modifiedBy}</div>
                        </div>
                        <div className="collapse-content flex justify-stretch">
                          <div className="mockup-code flex-auto bg-red-500 text-black before:content-[display-none]">
                            <div>{translate('deleted')}</div>
                            {JSON.stringify(versionWithDelta.delta.deleted, null, 2)
                              .split('\n')
                              .map((line, index) => (
                                <pre data-prefix={index + 1}>
                                  <code>{line}</code>
                                </pre>
                              ))}
                          </div>
                          <div className="mockup-code flex-auto bg-blue-500 text-black before:content-[display-none]">
                            <div>{translate('updated')}</div>
                            {JSON.stringify(versionWithDelta.delta.updated, null, 2)
                              .split('\n')
                              .map((line, index) => (
                                <pre data-prefix={index + 1}>
                                  <code>{line}</code>
                                </pre>
                              ))}
                          </div>
                          <div className="mockup-code flex-auto bg-green-500 text-black before:content-[display-none]">
                            <div>{translate('added')}</div>
                            {JSON.stringify(versionWithDelta.delta.added, null, 2)
                              .split('\n')
                              .map((line, index) => (
                                <pre data-prefix={index + 1}>
                                  <code>{line}</code>
                                </pre>
                              ))}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                </div>
              </div>

              <div className="modal-action">
                <button type="button" className="btn btn-primary" onClick={() => setIsOpen(false)}>
                  {translate('close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default UserHistory;
