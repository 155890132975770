import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import useTranslate from 'application/hooks/use-translate';
import { ACT_FORM_NAME, actFormFields } from 'app/containers/ActForm/constants';
import { TELEMEDICINE_OR_REMOTE_ACTIVITY_TYPES } from 'app/codeSpecificLogic/requiredDetails/Telemedicine/telemedicine';
import {
  TELEMEDECINE_PAR_TELEPHONE,
  TELEMEDECINE_PAR_VISIOCONSULTATION
} from 'shared/ramq/contextElements/legacyCovidContextElements';
import { chronicPainCodes } from 'shared/ramq/domainValues/codeActivityAreas';
import { isUserAtLeast } from 'application/utilities/authentication';
import Role from 'shared/domain/authentication/Role';
import { NatureType } from 'shared/core/activity/domain/Act';
import PATIENT_WITHOUT_ID_TYPE from '../../../shared/ramq/domainValues/situationTypes';
import refundAmount from '../../codeSpecificLogic/requiredDetails/RefundAmount/refundAmount';
import { getAdditionalFieldsRequiredForCode } from '../../codeSpecificLogic/ui/codeSpecificUILogic';
import { selectContextElements, selectPatientType } from '../../containers/ActForm/selectors';
import FormRow from '../Form/FormRow/FormRow';
import RequiredDetail from './RequiredDetail';

const createRequiredDetailObject = (selectedActCodes, act) => (actCode, index) => ({
  title: actCode.code,
  key: actCode.id,
  actCode,
  index,
  fields: getAdditionalFieldsRequiredForCode(actCode, selectedActCodes, act)
});

const hasFields = (requiredDetail) => !isEmpty(requiredDetail.fields);

const addExtraFieldIfPatientTypeIsRefundRequest = (patientType) => (requiredDetail) => {
  if (patientType === PATIENT_WITHOUT_ID_TYPE.refundRequest) {
    requiredDetail.fields.push(refundAmount.associatedFields[0]);
  }
  return requiredDetail;
};

export function RequiredDetails({ fields, patientType, act }) {
  const translate = useTranslate('act-form.required-details');
  const dispatch = useDispatch();
  const reduxContextElements = useSelector(selectContextElements());
  const userRole = useSelector((state) => state.authentication.user.role);
  const selectedActCodes = fields.length > 0 ? fields.getAll() : [];
  const requiredDetailsFromCodes = selectedActCodes
    .map(createRequiredDetailObject(selectedActCodes, act))
    .map(addExtraFieldIfPatientTypeIsRefundRequest(patientType))
    .filter(hasFields);

  useEffect(() => {
    let newContextElements = [...reduxContextElements];
    if (
      !requiredDetailsFromCodes.some(
        (requiredDetail) =>
          requiredDetail.actCode.natureType === NatureType.VISIT ||
          chronicPainCodes.includes(requiredDetail.actCode.code)
      )
    ) {
      if (isUserAtLeast(userRole, Role.Agent)) {
        return;
      }
      newContextElements = newContextElements.filter(
        (contextElement) =>
          contextElement !== TELEMEDECINE_PAR_TELEPHONE && contextElement !== TELEMEDECINE_PAR_VISIOCONSULTATION
      );
    }
    const isPhoneTelemedicine = requiredDetailsFromCodes.some(
      (requiredDetail) =>
        requiredDetail.actCode.telemedicineOrRemoteType === TELEMEDICINE_OR_REMOTE_ACTIVITY_TYPES[0].value
    );
    const isVisioTelemedicine = requiredDetailsFromCodes.some(
      (requiredDetail) =>
        requiredDetail.actCode.telemedicineOrRemoteType === TELEMEDICINE_OR_REMOTE_ACTIVITY_TYPES[1].value
    );

    if (isPhoneTelemedicine && !newContextElements.includes(TELEMEDECINE_PAR_TELEPHONE)) {
      newContextElements.push(TELEMEDECINE_PAR_TELEPHONE);
    } else if (!isPhoneTelemedicine && newContextElements.includes(TELEMEDECINE_PAR_TELEPHONE)) {
      newContextElements = newContextElements.filter((contextElement) => contextElement !== TELEMEDECINE_PAR_TELEPHONE);
    } else if (isVisioTelemedicine && !newContextElements.includes(TELEMEDECINE_PAR_VISIOCONSULTATION)) {
      newContextElements.push(TELEMEDECINE_PAR_VISIOCONSULTATION);
    } else if (!isVisioTelemedicine && newContextElements.includes(TELEMEDECINE_PAR_VISIOCONSULTATION)) {
      newContextElements = newContextElements.filter(
        (contextElement) => contextElement !== TELEMEDECINE_PAR_VISIOCONSULTATION
      );
    } else if (!isPhoneTelemedicine && !isVisioTelemedicine) {
      newContextElements = newContextElements.filter(
        (contextElement) =>
          contextElement !== TELEMEDECINE_PAR_TELEPHONE && contextElement !== TELEMEDECINE_PAR_VISIOCONSULTATION
      );
    }

    if (newContextElements.length === reduxContextElements.length) {
      return;
    }
    dispatch(change(ACT_FORM_NAME, `${actFormFields.contextElements}`, newContextElements));
  }, [act.codes, dispatch, reduxContextElements, requiredDetailsFromCodes, userRole]);

  useEffect(() => {
    let requiredDetailEndTime = null;
    for (const requiredDetail of requiredDetailsFromCodes) {
      if (requiredDetail.actCode.endTimeWhenDurationIsOver30Minutes)
        requiredDetailEndTime = requiredDetail.actCode.endTimeWhenDurationIsOver30Minutes;
      else if (requiredDetail.actCode.endTimeWhenDurationIsOver60Minutes)
        requiredDetailEndTime = requiredDetail.actCode.endTimeWhenDurationIsOver60Minutes;
    }

    if (requiredDetailEndTime && act.end !== requiredDetailEndTime) {
      dispatch(change(ACT_FORM_NAME, `${actFormFields.end}`, requiredDetailEndTime));
    }
  }, [act.end, dispatch, requiredDetailsFromCodes]);

  return requiredDetailsFromCodes.length > 0 ? (
    <div>
      <Typography gutterBottom variant="h6">
        {translate('required-details')}
      </Typography>
      <Divider />
      <FormRow>
        {requiredDetailsFromCodes.map((requiredDetail) => (
          <RequiredDetail key={requiredDetail.key} requiredDetail={requiredDetail} code={requiredDetail.actCode} />
        ))}
      </FormRow>
    </div>
  ) : null;
}

RequiredDetails.defaultProps = {
  patientType: ''
};

RequiredDetails.propTypes = {
  fields: PropTypes.object.isRequired,
  patientType: PropTypes.string,
  act: PropTypes.object.isRequired
};

export const mapStateToProps = createStructuredSelector({
  patientType: selectPatientType()
});

export default compose(connect(mapStateToProps))(RequiredDetails);
