/* eslint-disable react/no-array-index-key */

import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToDate } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import StatementTable from '../../../../../components/Table/Statement/StatementTable/StatementTable';
import { StatementLine } from '../sections/StatementDoctorInfo';

const natureDuServiceColumn = {
  name: 'Nature du service / Emploi du temps',
  primaryText: (line) => (line.serviceCode ? `${line.serviceCode} - ${line.serviceCodeLabel}` : 'Perdiem :'),
  secondaryText: (line) => line.timetableCode && `${line.timetableCode} - ${line.timetableCodeLabel}`
};

const uniteColumn = {
  name: 'Unités / no ref',
  primaryText: (line) =>
    line.unitAmount
      ? `${line.unitAmount}${String.fromCharCode(160)}${line.unitType}`
      : line.hoursPaid && `${line.hoursPaid} H`,
  secondaryText: (line) => line.reference !== '00' && `${line.reference}`
};

const montantCodeExplicatifColumn = {
  name: 'Montant / Codes Explicatifs',
  align: 'right',
  primaryText: (line) => line.paidAmount !== '     S/O ' && line.paidAmount && currencyFormat(line.paidAmount),
  secondaryText: (line) =>
    line.explanationCode1 !== '   ' &&
    line.explanationCode1 !== '000' &&
    `${line.explanationCode1} ${line.explanationCode2} ${line.explanationCode3}`
};

export function StatementPeriod({ content }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <StatementSection
          primaryLine={`${content.modTransactionCode} - ${content.modLineTransactionLabel}`}
          secondaryLines={[
            StatementLine(
              'Période:',
              `${formatUnixToDate(content.periodStart)}
                  au ${formatUnixToDate(content.periodEnd)}`
            ),
            StatementLine('N. attestation:', content.attestationNumber),
            StatementLine('N. requête:', content.requestNumber),
            StatementLine('Réception:', formatUnixToDate(content.dateReceived)),
            content.explanationCode1 !== '000' &&
              `codes explicatifs: ${content.explanationCode1} ${content.explanationCode2} ${content.explanationCode3}`
          ]}
        />
      </Grid>
      {!isEmpty(content.extraLines) && (
        <Grid item xs={12} sm={8}>
          <StatementTable
            title=""
            columns={[natureDuServiceColumn, uniteColumn, montantCodeExplicatifColumn]}
            data={content.extraLines}
          />
        </Grid>
      )}
    </Grid>
  );
}

StatementPeriod.propTypes = {
  content: PropTypes.object.isRequired
};
export default StatementPeriod;
