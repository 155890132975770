import addSyntheticPlateSupplement from 'app/act/recommendations/syntheticPlateSupplement/addSyntheticPlateSupplement';
import addEmergencyContextElement from 'app/act/recommendations/contextElement/addEmergencyContextElement';
import switchCodeForTelemedicine from 'app/act/recommendations/switchCodeForTelemedicine/switchCodeForTelemedicine';
import addCancerAsFollowupType from 'app/act/recommendations/addCancerAsFollowupType/addCancerAsFollowupType';
import removeCodeForInjectableMedication from 'app/act/recommendations/removeCodeForInjectableMedication/removeCodeForInjectableMedication';
import addDifferentChronicPainProblemRequiredDetailRecommendation from 'app/act/recommendations/addDifferentChronicPainProblemRequiredDetailRecommendation/addDifferentChronicPainProblemRequiredDetailRecommendation';
import switchChronicPainVisitCodes from 'app/act/recommendations/chronicPainVisitsRecommendations/switchChronicPainVisitCodes';
import Act from '../../../shared/domain/activity/act/model/Act';
import switchWeekWeekendAndHolidayCodeRecommendation from '../../act/recommendations/switchCodeWeekVsWeekEndAndHoliday/switchWeekWeekendAndHolidayCodeRecommendation';
import addMinorCareSupplementRecommendation from '../../act/recommendations/minorCareSupplement/addMinorCareSupplementRecommendation';
import addHalfDayPreoperativeClinicRecommendation from '../../act/recommendations/halfDayPreoperativeClinic/addHalfDayPreoperativeClinicRecommendation';
import addContextElement1922Recommendation from '../../act/recommendations/contextElement/addContextElement1922';
import switchNonIntensiveCare900Recommendation, {
  IntensiveCare900RecommendationActs
} from '../../act/recommendations/switchNonIntensiveCare900/switchNonIntensiveCare900Recommendation';
import addEmergencyContextElementWhenActIsMadeSimultaneouslyInRoleTwo from '../../act/recommendations/contextElement/addEmergencyContextElementWhenActIsMadeSimultaneouslyInRoleTwo';

const getRecommendations = (
  actForm: Partial<Act>,
  intensiveCare900RecommendationActs: IntensiveCare900RecommendationActs[],
  numberOfActivitiesWithTreatmentCodeDuringCivilYear: number,
  numberOfChronicPainInitialConsultationActs: number
) => [
  ...switchWeekWeekendAndHolidayCodeRecommendation(actForm),
  ...addMinorCareSupplementRecommendation(actForm),
  ...addHalfDayPreoperativeClinicRecommendation(actForm),
  ...switchNonIntensiveCare900Recommendation(actForm, intensiveCare900RecommendationActs),
  ...addContextElement1922Recommendation(actForm),
  ...addSyntheticPlateSupplement(actForm),
  ...addEmergencyContextElementWhenActIsMadeSimultaneouslyInRoleTwo(actForm),
  ...addEmergencyContextElement(actForm),
  ...switchCodeForTelemedicine(actForm),
  ...addCancerAsFollowupType(actForm),
  ...removeCodeForInjectableMedication(actForm, numberOfActivitiesWithTreatmentCodeDuringCivilYear),
  ...addDifferentChronicPainProblemRequiredDetailRecommendation(actForm, numberOfChronicPainInitialConsultationActs),
  ...switchChronicPainVisitCodes(actForm)
];

export default getRecommendations;
