import { set } from 'lodash';
import moment from 'moment';
import {
  getStartTimeForCode,
  getStartTimeMappingCodes
} from '../../../containers/ActForm/UIOptimizations/PrefillStartTimeAccordingToCode/utils';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';

const startTimeForGivenCodeError = (code, expectedTime) =>
  set({ elementIdToScrollTo: 'start' }, 'start', `L’heure pour ${code} doit être ${expectedTime}h00.`);

const validateStartTimeForGivenCodes = (act) => {
  const codesInError = act.codes.map(({ code }) => {
    if (getStartTimeMappingCodes().includes(code)) {
      const expectedTime = getStartTimeForCode(code, act.date);

      if (!moment(act.start).isSame(expectedTime)) {
        return startTimeForGivenCodeError(code, moment(expectedTime).format('HH'));
      }
    }

    return {};
  });

  return codesInError.reduce(combineErrors, {});
};

const associatedValidations = [validateStartTimeForGivenCodes];

export default { associatedValidations };
