import { confirmAlert } from 'react-confirm-alert';
import React, { ReactNode } from 'react';
import ConfirmTemplate from '../template/ConfirmTemplate';

export interface AlertOptions {
  title: string | ReactNode;
  content: string | ReactNode;
  displayCancel?: boolean;
  onCancel?: () => void;
  onConfirm: () => void;
  confirmActionLabel?: string;
  cancelActionLabel?: string;
}

class AlertApi {
  static showConfirm(options: AlertOptions) {
    return confirmAlert({
      customUI: ({ onClose }) => <ConfirmTemplate onClose={onClose} {...options} />
    });
  }
}

export default AlertApi;
