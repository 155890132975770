import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { IntercomProvider } from 'react-use-intercom';
import { isPROD } from '../../../shared/utils/executionEnvironment';
import { fetchIntercomUserHash, setIntercomUnreadMessageCount } from '../../containers/Intercom/actions';

const TEST_APP_ID = 'dzw2yqwv';
const PROD_APP_ID = 'gqx25pm8';
const PUBLIC_APP_ID = isPROD() ? PROD_APP_ID : TEST_APP_ID;

function IntercomWidget({ children, onFetchIntercomUserHash, onIntercomUnreadCountChange }) {
  React.useEffect(() => {
    onFetchIntercomUserHash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntercomProvider appId={PUBLIC_APP_ID} onUnreadCountChange={onIntercomUnreadCountChange}>
      {children}
    </IntercomProvider>
  );
}

IntercomWidget.propTypes = {
  children: PropTypes.node.isRequired,
  onFetchIntercomUserHash: PropTypes.func.isRequired,
  onIntercomUnreadCountChange: PropTypes.func.isRequired
};

export const mapDispatchToProps = {
  onFetchIntercomUserHash: fetchIntercomUserHash,
  onIntercomUnreadCountChange: setIntercomUnreadMessageCount
};

export default compose(connect(undefined, mapDispatchToProps))(IntercomWidget);
