export const UNUSUAL_PRACTICE_ACT_CODES = ['42187', '42190'];

export const isUnusualPracticeActCode = ({ code }: { code: string }) => UNUSUAL_PRACTICE_ACT_CODES.includes(code);

export const getUnusualPracticeActRelatedMixteSuffix = (actCode: any) => {
  let mixteSuffix = '361';

  if (actCode.telemedicineOrRemote) {
    mixteSuffix = '377';
  }

  if (actCode.canceledActivities) {
    mixteSuffix = '367';
  }

  return mixteSuffix;
};
