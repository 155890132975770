import {
    ACT_ADDITIONAL_CONTEXT_ELEMENTS,
    ACTIVITY_AREA_LIST,
    ACTIVITY_SECTOR_DEFAULT_OPEN,
    CODES_DATA_SPECIALTY_OVERRIDE,
    CODES_DATA_SPECIALTY_OVERRIDE_INTERNIST,
    CONTEXT_ELEMENT_LIST_NAME,
    DEFAULT_START_TIME,
    DETAILED_MIXTE_HOURS,
    DUPLICATE_ANOTHER_DAY_ACTS,
    FAVORITE_ACT_CODES_LIST_NAME,
    LIMITED_INTENSIVE_CARE_DIAGNOSTIC_LIST_NAME,
    LUMPSUM_CODE_LIST_NAME,
    LUMPSUM_MAX_CODES_PER_DAY,
    MIXTE_CODE_LIST_NAME,
    MIXTE_MAX_CODES_PER_DAY,
    REQUIRED_DETAILS_FOR_INTERNISTS,
    REQUIRED_DETAILS_FOR_PNEUMOLOGISTS,
    REQUIRED_DETAILS_FOR_SURGERY,
    SHOW_DIAGNOSTIC_IN_ACT_FORM,
    CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR,
    REQUIRE_END_TIME_FOR_SURGERY_CODES,
    MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM,
    MINIMUM_DURATION_BY_MIXTE_PERDIEMS,
    PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS,
    DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL,
    MAXIMUM_AMOUNT_FOR_MAIN_CODE_ELIGIBLE_FOR_INCREASE,
    CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR_FOR_SURGERY_CODES,
    ACT_HISTORY_WITH_NAM
} from '../featureNames';
import {INTERNIST_ACTIVITY_AREA_SELECTION} from '../constants'
import {FAVORITE_ACT_CODES_INTERNIST} from '../specialtyFeaturesConfiguration';
import SpecialtyName, {FamilyName} from '../../shared/core/doctor/domain/SpecialtyName';
import {SpecialtyJSONConfig} from '../../shared/domain/user/specialty/repository/JSON/JSONConfigRepository';

const specialtiesConfig : SpecialtyJSONConfig = {
    families: {
        [FamilyName.SURGERY]: {
            [DETAILED_MIXTE_HOURS]: true,
            [FAVORITE_ACT_CODES_LIST_NAME]: FAVORITE_ACT_CODES_INTERNIST,
            [MIXTE_MAX_CODES_PER_DAY]: 6,
            [LUMPSUM_MAX_CODES_PER_DAY]: 6,
            [MIXTE_CODE_LIST_NAME]: 'mixte-general-surgeon-codes',
            [ACT_ADDITIONAL_CONTEXT_ELEMENTS]: false,
            [ACTIVITY_AREA_LIST]: INTERNIST_ACTIVITY_AREA_SELECTION,
            [ACTIVITY_SECTOR_DEFAULT_OPEN]: true,
            [CODES_DATA_SPECIALTY_OVERRIDE]: CODES_DATA_SPECIALTY_OVERRIDE_INTERNIST,
            [CONTEXT_ELEMENT_LIST_NAME]: 'generalSurgeon',
            [LIMITED_INTENSIVE_CARE_DIAGNOSTIC_LIST_NAME]: 'limited-intensive-care-diagnostic-internist-list',
            [DUPLICATE_ANOTHER_DAY_ACTS]: true,
            [DEFAULT_START_TIME]: 8,
            [SHOW_DIAGNOSTIC_IN_ACT_FORM]: true,
            [CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR] : true,
            [CAN_USE_RAMQ_CONTEXT_ELEMENTS_SELECTOR_FOR_SURGERY_CODES] : true,
            // TODO pas rapport !!!
            [REQUIRED_DETAILS_FOR_PNEUMOLOGISTS]: true,
            [REQUIRED_DETAILS_FOR_INTERNISTS]: false,
            [REQUIRED_DETAILS_FOR_SURGERY]: true,
            [LUMPSUM_CODE_LIST_NAME]: 'lumpSum-general-surgeon-codes',
            [REQUIRE_END_TIME_FOR_SURGERY_CODES]: true,
            [MINIMUM_DURATION_BY_MIXTE_HALF_PERDIEM]: 1,
            [MINIMUM_DURATION_BY_MIXTE_PERDIEMS]: 4.5,
            [PM_PERDIEM_END_HOUR_FOR_OTHER_ACTS]: 17,
            [MAXIMUM_AMOUNT_FOR_MAIN_CODE_ELIGIBLE_FOR_INCREASE]: 211,
            [ACT_HISTORY_WITH_NAM]: true
        }
    },
    specialties: {
        [SpecialtyName.ORTHOPEDIC_SURGEON]: {
            [DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL]: true,
            [ACT_HISTORY_WITH_NAM]: true
        },
        [SpecialtyName.OTOLARYNGOLOGIST]: {
          [DISPLAY_SURGERY_ROOM_REQUIRED_DETAIL]: true,
          [MAXIMUM_AMOUNT_FOR_MAIN_CODE_ELIGIBLE_FOR_INCREASE]: 150
        }
    }
};

export default specialtiesConfig;
