import PresetsService, { CategoryPreset, SpecialtyPreset } from 'application/services/presets-service';
import Dropdown from 'application/components/dropdown';
import useTranslate from 'application/hooks/use-translate';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import HoldButton from 'application/components/hold-button';
import useToast from 'application/hooks/use-toast';
import CategoryPresetEditor from './category-preset/category-preset-editor';

const PresetsPage: React.FunctionComponent = () => {
  const translate = useTranslate('presets');
  const { addToast } = useToast();
  const [specialtyPresets, setSpecialtyPresets] = useState<SpecialtyPreset[]>([]);
  const [selectedCollectionId, setSelectedCollection] = useState<string>('');
  const [categories, setCategories] = useState<CategoryPreset[]>([]);
  const [stagedCategories, setStagedCategories] = useState<{ [id: string]: CategoryPreset }>({});

  const query = useQuery<SpecialtyPreset[]>({
    queryKey: ['fetchPresetsSpecialties'],
    queryFn: PresetsService.fetchSpecialties
  });

  useEffect(() => {
    if (query.data) {
      setSpecialtyPresets(query.data);
      if (!selectedCollectionId && query.data.length > 0) {
        setSelectedCollection(query.data[0].id);
      }
    }
  }, [query.data, selectedCollectionId]);

  const { data: categoriesData, refetch: refetchPreset } = useQuery<CategoryPreset[]>({
    queryKey: ['fetchPresetsCategories', selectedCollectionId],
    queryFn: () => PresetsService.fetchCategories(selectedCollectionId),
    enabled: !!selectedCollectionId
  });

  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData);
    }
  }, [categoriesData, selectedCollectionId]);

  const handleCategoryChange = async (updatingCategory: CategoryPreset) => {
    setStagedCategories({ ...stagedCategories, [updatingCategory.id]: updatingCategory });
  };

  const handleSave = async () => {
    for (const category of Object.values(stagedCategories)) {
      await PresetsService.saveCategory(selectedCollectionId, category);
    }

    addToast({
      type: 'success',
      message: translate('categories-saved', { count: Object.keys(stagedCategories).length })
    });
    setStagedCategories({});
    await refetchPreset();
  };

  const hasStagedCategories = Object.keys(stagedCategories).length > 0;

  return (
    <div>
      <div className="mb-4 flex justify-between">
        <Dropdown
          onChange={setSelectedCollection}
          options={specialtyPresets.map((specialtyPreset) => ({
            label: translate(specialtyPreset.id),
            value: specialtyPreset.id
          }))}
          value={selectedCollectionId}
        />
        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => setStagedCategories({})}
            className="btn btn-error"
            disabled={!hasStagedCategories}
          >
            {translate('cancel')}
          </button>
          <HoldButton onClick={handleSave} className="btn btn-primary" disabled={!hasStagedCategories}>
            {translate('save')}
          </HoldButton>
        </div>
      </div>
      {categories.map((category) => (
        <CategoryPresetEditor
          key={category.id}
          category={stagedCategories[category.id] ?? category}
          onChange={handleCategoryChange}
        />
      ))}
    </div>
  );
};

export default PresetsPage;
