import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import { selectDuplicationData } from '../Activities/selectors';
import LoadPreferences from '../LoadPreferences/LoadPreferences';
import { ACTIVITIES_ROUTE } from '../Navigation/constants';
import TravelExpenses from './TravelExpenses';

// We are preventing the fact that the user refresh the page on duplicate route
// which would cause an empty act here (and a crash)
export function DuplicateTravelExpenses({ duplicationData }) {
  return (
    <LoadPreferences>
      {duplicationData ? <TravelExpenses travelExpenses={duplicationData} /> : <Navigate to={ACTIVITIES_ROUTE} />}
    </LoadPreferences>
  );
}

DuplicateTravelExpenses.propTypes = {
  duplicationData: PropTypes.object
};

DuplicateTravelExpenses.defaultProps = {
  duplicationData: null
};

export const mapStateToProps = createStructuredSelector({
  duplicationData: selectDuplicationData(ActivityType.TRAVEL_EXPENSES)
});

export default connect(mapStateToProps, null)(DuplicateTravelExpenses);
