import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ExpandableTableRowHeader from './ExpandableTableRowHeader';
import ExpandableTableRowContent from './ExpandableTableRowContent';

export const styles = () => ({
  collapsed: {
    visibility: 'collapse',
    display: 'none' // until visibility: collapse is supported properly in all browsers
  },
  canOpen: {
    cursor: 'pointer'
  }
});

export class ExpandableTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: this.props.expanded
    };
  }

  toggleExpanded = () => this.setState((prevState) => ({ expanded: !prevState.expanded }));

  render() {
    const { classes } = this.props;

    const headerChildren = React.Children.toArray(this.props.children).find(
      (children) => children.type === ExpandableTableRowHeader
    );
    const contentChildren = React.Children.toArray(this.props.children).find(
      (children) => children.type === ExpandableTableRowContent
    );

    const { canOpen, ...childrenProps } = contentChildren.props;
    const childrenIsOpen = this.state.expanded && canOpen;
    const { className: headerClassName } = headerChildren.props;

    return (
      <>
        <TableRow
          onClick={() => this.toggleExpanded()}
          {...headerChildren.props}
          className={classNames(headerClassName, { [classes.canOpen]: canOpen })}
        >
          {headerChildren}
        </TableRow>
        <TableRow className={classNames({ [classes.collapsed]: !childrenIsOpen })} {...childrenProps}>
          {contentChildren}
        </TableRow>
      </>
    );
  }
}

ExpandableTableRow.defaultProps = {
  expanded: false
};

ExpandableTableRow.propTypes = {
  children: PropTypes.array.isRequired,
  expanded: PropTypes.bool
};

export default withStyles(styles)(ExpandableTableRow);
