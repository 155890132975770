import React from 'react';
import classnames from 'classnames';
import Icon from 'application/components/icon';
import useTranslate from 'application/hooks/use-translate';
import { RAMQAccreditationStatus } from 'application/hooks/use-firestore-user-profile';

interface Props {
  className?: string;
  status: RAMQAccreditationStatus;
}

const RAMQAccreditationStatusIndicator = ({ className = '', status }: Props) => {
  const translate = useTranslate('ramq-accreditation-status');

  return (
    <div
      className={classnames('inline-flex items-center gap-2 rounded-full border bg-white p-1 pl-2 pr-3', className, {
        'border-green-600 text-green-600': status === RAMQAccreditationStatus.APPROVED,
        'border-gray-600 text-gray-600': status === RAMQAccreditationStatus.MISSING,
        'border-yellow-600 text-yellow-600': [
          RAMQAccreditationStatus.PENDING_APPROVAL,
          RAMQAccreditationStatus.PENDING_SIGNATURE
        ].includes(status),
        'border-pelorous-600 text-pelorous-600': status === RAMQAccreditationStatus.SIGNED
      })}
    >
      {status === RAMQAccreditationStatus.APPROVED && <Icon icon="badge-check" />}
      {status === RAMQAccreditationStatus.MISSING && <Icon icon="ban" />}
      {status === RAMQAccreditationStatus.PENDING_APPROVAL && <Icon icon="clock" />}
      {status === RAMQAccreditationStatus.PENDING_SIGNATURE && <Icon icon="clock" solid />}
      {status === RAMQAccreditationStatus.SIGNED && <Icon icon="signature" />}

      <span className="text-sm">{translate(status)}</span>
    </div>
  );
};

export default RAMQAccreditationStatusIndicator;
