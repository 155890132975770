import { get } from 'lodash';
import { UNKNOWN_PROFESSIONAL_REFERENCE } from '../../../../shared/ramq/requiredDetails/professionalReferenceConstants';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { PROFESSIONAL_TYPE_LIST } from '../../../components/Form/constants';
import { ProfessionalTypeSelectorField, SingleLineField } from '../../../components/Form/Fields';

const getProfessionalTypeLabel = (code) => (type) =>
  type.value === get(code[UNKNOWN_PROFESSIONAL_REFERENCE], 'professionalType', '1');
const summaryRender = (code) => [
  {
    name: 'Prof.',
    value: `${get(code[UNKNOWN_PROFESSIONAL_REFERENCE], 'firstName', '-')} ${get(
      code[UNKNOWN_PROFESSIONAL_REFERENCE],
      'lastName',
      '-'
    )}`
  },
  {
    name: 'Profession',
    value: get(PROFESSIONAL_TYPE_LIST.find(getProfessionalTypeLabel(code)), 'label', '-')
  },
  {
    name: get(code[UNKNOWN_PROFESSIONAL_REFERENCE], 'territory', 1) === 1 ? 'Québec' : 'Hors Québec'
  }
];

const associatedFields = [
  {
    label: 'Nom du prof.',
    getName: (index) => `codes[${index}].${UNKNOWN_PROFESSIONAL_REFERENCE}.lastName`,
    type: SingleLineField
  },
  {
    label: 'Prénom du prof.',
    getName: (index) => `codes[${index}].${UNKNOWN_PROFESSIONAL_REFERENCE}.firstName`,
    type: SingleLineField
  },
  {
    label: 'Profession',
    getName: (index) => `codes[${index}].${UNKNOWN_PROFESSIONAL_REFERENCE}.professionalType`,
    type: ProfessionalTypeSelectorField
  },
  {
    label: '',
    getName: (index) => `codes[${index}].${UNKNOWN_PROFESSIONAL_REFERENCE}.territory`,
    type: SingleSelectCheckboxGroup,
    list: [
      { label: 'Québec', value: 1 },
      { label: 'Hors Québec', value: 2 }
    ]
  }
];

const profAutre = { associatedFields, summaryRender };

export default profAutre;
