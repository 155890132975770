import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToDate, formatUnixToTimeValue } from '../../../../../../shared/utils/dateTime/dateTimeUtils';

function ActsWarningWithCodesAndStartTime({ acts }) {
  return (
    <>
      {acts.map((act) => {
        const formattedCodes = act.codes.map((code) => code.code).join(', ');
        return (
          <Typography key={act.id} variant="caption">
            {`- ${formatUnixToDate(act.date)}   ${formatUnixToTimeValue(act.start)}   (${formattedCodes})`}
          </Typography>
        );
      })}
    </>
  );
}

ActsWarningWithCodesAndStartTime.propTypes = {
  acts: PropTypes.array.isRequired
};

export default ActsWarningWithCodesAndStartTime;
