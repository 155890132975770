import { useEffect, useState } from 'react';
import getUserInfos from '../../usesCases/fetchUserInfos';

export default function useFetchUserInfos(userId) {
  const [userInfos, setUserInfos] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    getUserInfos(userId)
      .then(setUserInfos)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [userId, setLoading, setError, setUserInfos]);

  return { userInfos, loading, error };
}
