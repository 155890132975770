import { find } from 'lodash';
import CODE_NATURE_TYPES from '../../../../shared/ramq/domainValues/natureTypes';
import biopsyNotRelatedToSurgicalProcedure from '../../requiredDetails/BiopsyNotRelatedToSurgicalAct/biopsyNotRelatedToSurgicalAct';

const biopsyCodes = [
  '103',
  '165',
  '166',
  '167',
  '168',
  '169',
  '171',
  '172',
  '173',
  '174',
  '175',
  '177',
  '179',
  '181',
  '183',
  '184',
  '185',
  '186',
  '187',
  '188',
  '190',
  '192',
  '194',
  '195',
  '202',
  '204',
  '206',
  '207',
  '213',
  '214',
  '215',
  '220',
  '221',
  '222',
  '223',
  '226',
  '227',
  '230',
  '231',
  '232',
  '234',
  '236',
  '237',
  '238',
  '240',
  '241',
  '244',
  '245',
  '246',
  '249',
  '273',
  '281',
  '282',
  '308',
  '782',
  '797',
  '798',
  '9480',
  '20021',
  '20117',
  '20118',
  '20119',
  '20507'
];

const addBiopsyFieldSelectIfNeeded = (currentCode, codeList) => {
  if (biopsyCodes.includes(currentCode.code)) {
    if (find(codeList, (code) => code.natureType === CODE_NATURE_TYPES.chirurgie)) {
      return biopsyNotRelatedToSurgicalProcedure.associatedFields;
    }
  }
  return [];
};

export default addBiopsyFieldSelectIfNeeded;
