import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import FullScreenLoading from '../../components/Loadings/FullScreenLoading/FullScreenLoading';
import { selectSpecialtyContextElementsLoadedFromUser } from '../../favorite/contextElement/adapters/selectors';
import { selectRamqPlacesLoaded } from '../RamqPlaces/selectors';
import {
  selectCodesLoadedFromUser,
  selectDiagnosticCodeLoadedFromUser,
  selectPlacesLoadedFromUser,
  selectUserProfileInContext
} from '../User/selectors';
import { selectDoctorsLoadedFromUser } from '../../doctorsRegistry/adapters/selectors';
import {
  selectActCodesSpecialtyFavoritesLoadedFromUser,
  selectActCodesSpecialtyPresetLoadedFromUser
} from '../../favorite/actCode/adapters/selectors';

export interface OwnProps {
  children: ReactNode;
  extraIsLoading: boolean;
}

export interface ReduxStateProps {
  placesLoaded: boolean;
  doctorsLoaded: boolean;
  codesLoaded: boolean;
  diagnosticCodesLoaded: boolean;
  specialtyPresetActCodesLoaded: boolean;
  specialtyFavoritesActCodesLoaded: boolean;
  specialtyContextElementsLoaded: boolean;
  ramqPlacesLoaded: boolean;
  userProfile: any;
}

export interface Props extends OwnProps, ReduxStateProps {}

export class LoadPreferences extends Component<Props> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    userProfile: undefined,
    extraIsLoading: false
  };

  isLoading = () =>
    !this.props.placesLoaded ||
    !this.props.doctorsLoaded ||
    !this.props.codesLoaded ||
    !this.props.diagnosticCodesLoaded ||
    !this.props.specialtyPresetActCodesLoaded ||
    !this.props.specialtyFavoritesActCodesLoaded ||
    !this.props.specialtyContextElementsLoaded ||
    !this.props.ramqPlacesLoaded ||
    this.props.userProfile === undefined ||
    this.props.extraIsLoading;

  render() {
    return this.isLoading() ? (
      // @ts-ignore
      <FullScreenLoading />
    ) : (
      this.props.children
    );
  }
}

export const mapStateToProps = createStructuredSelector({
  placesLoaded: selectPlacesLoadedFromUser(),
  doctorsLoaded: selectDoctorsLoadedFromUser(),
  codesLoaded: selectCodesLoadedFromUser(),
  diagnosticCodesLoaded: selectDiagnosticCodeLoadedFromUser(),
  specialtyPresetActCodesLoaded: selectActCodesSpecialtyPresetLoadedFromUser(),
  specialtyFavoritesActCodesLoaded: selectActCodesSpecialtyFavoritesLoadedFromUser(),
  specialtyContextElementsLoaded: selectSpecialtyContextElementsLoadedFromUser(),
  ramqPlacesLoaded: selectRamqPlacesLoaded(),
  userProfile: selectUserProfileInContext()
});

export default compose(connect<ReduxStateProps, {}, OwnProps, {}>(mapStateToProps as any))(LoadPreferences);
