import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PrevArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import NextArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMomentDateFromUnix, newUnixDateAtStartOfDay } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import { getDateDayAbbreviation } from '../../../../../../utils/dateTimeUtils';
import { usePostHog } from 'posthog-js/react';

export const styles = (theme) => ({
  daySwitcherContainer: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
    width: '100%',
    backgroundColor: theme.palette.primary[500],
    paddingBottom: theme.spacing(0.5)
  },
  switcher: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flex: '0 0 auto',
    width: '100%',
    maxWidth: 600
  },
  dateLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50
  },
  dateButton: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.common.white
  },
  dateNumber: {
    color: theme.palette.common.white,
    fontSize: '1.45rem',
    height: theme.spacing(4),
    width: theme.spacing(4),
    borderRadius: '50%',
    padding: theme.spacing(0.25),
    lineHeight: 1.4
  },
  today: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary[500]
  },
  currentDayLabel: {
    fontSize: '0.5rem'
  },
  switchButton: {
    color: theme.palette.common.white
  }
});

export function DaySwitcher({ classes }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { date: unixDate } = useParams();
  const posthog = usePostHog();

  const currentMomentDate = getMomentDateFromUnix(unixDate);
  const currentDayNumber = currentMomentDate.date();
  const currentDayName = getDateDayAbbreviation(currentMomentDate);
  const isToday = currentMomentDate.isSame(moment(), 'day');

  const goToToday = () => navigate({ pathname: `../${newUnixDateAtStartOfDay()}`, search: location.search });
  return (
    <div className={classes.daySwitcherContainer}>
      <div className={classes.switcher}>
        <Button
          className={classes.switchButton}
          onClick={() => {
            posthog.capture('Day switcher clicked', { direction: 'previous' });
            navigate({ pathname: `../${currentMomentDate.subtract(1, 'days').valueOf()}`, search: location.search });
          }}
        >
          <PrevArrowIcon />
        </Button>
        <Button className={classes.dateButton} classes={{ label: classes.dateLabel }} onClick={goToToday}>
          <Typography className={classNames(classes.dateNumber, { [classes.today]: isToday })} color="primary">
            {currentDayNumber}
          </Typography>
          <Typography variant="caption" color="inherit" className={classes.currentDayLabel}>
            {currentDayName}
          </Typography>
        </Button>
        <Button
          className={classes.switchButton}
          onClick={() => {
            posthog.capture('Day switcher clicked', { direction: 'next' });
            navigate({ pathname: `../${currentMomentDate.add(1, 'days').valueOf()}`, search: location.search });
          }}
        >
          <NextArrowIcon />
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(DaySwitcher);
