export const SET_USER_IN_CONTEXT = 'setUserInContext';
export const setUserInContext = (userId) => ({
  type: SET_USER_IN_CONTEXT,
  userId
});

export const SET_USER_PROFILE_IN_CONTEXT = 'setUserProfileInContext';
export const setUserProfileInContext = (profile) => ({
  type: SET_USER_PROFILE_IN_CONTEXT,
  profile
});

export const SET_USER_PROFILE_IN_CONTEXT_FAILURE = 'setUserProfileInContextFailure';
export const setUserProfileInContextFailure = (error) => ({
  type: SET_USER_PROFILE_IN_CONTEXT_FAILURE,
  error
});

export const SET_IS_USER_PROFILE_READY = 'setIsUserProfileReady';
export const setIsUserProfileReady = () => ({
  type: SET_IS_USER_PROFILE_READY
});

export const SAVE_USER_PROFILE = 'saveUserProfile';
export const saveUserProfile = (document) => ({
  type: SAVE_USER_PROFILE,
  document
});

export const SAVE_USER_PROFILE_FAILURE = 'saveUserProfileFailure';
export const saveUserProfileFailure = (error) => ({
  type: SAVE_USER_PROFILE_FAILURE,
  error
});

export const GET_USER_PROFILE = 'getUserProfile';
export const getUserProfile = (userId) => ({
  type: GET_USER_PROFILE,
  userId
});

export const GET_LUMP_SUMS = 'getLumpSums';
export const getLumpSumsPreferences = () => ({
  type: GET_LUMP_SUMS
});

export const GET_LUMP_SUMS_SUCCESS = 'getLumpSumsSuccess';
export const getLumpSumsPreferencesSuccess = (lumpSums) => ({
  type: GET_LUMP_SUMS_SUCCESS,
  lumpSums
});

export const GET_LUMP_SUMS_FAILURE = 'getLumpSumsFailure';
export const getLumpSumsPreferencesFailure = (error) => ({
  type: GET_LUMP_SUMS_FAILURE,
  error
});

export const ADD_LUMP_SUM_TO_USER_PREFERENCES = 'addLumpSumToUserPreferences';
export const addLumpSumToUserPreferences = (document) => ({
  type: ADD_LUMP_SUM_TO_USER_PREFERENCES,
  document
});

export const UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES = 'updateLumpSumsFromUserPreferences';
export const updateLumpSumsFromUserPreferences = (documentMap) => ({
  type: UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES,
  documentMap
});

export const UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_SUCCESS = 'updateLumpSumsFromUserPreferencesSuccess';
export const updateLumpSumsFromUserPreferencesSuccess = () => ({
  type: UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_SUCCESS
});

export const UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_FAILURE = 'updateLumpSumsFromUserPreferencesFailure';
export const updateLumpSumsFromUserPreferencesFailure = (error) => ({
  type: UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES_FAILURE,
  error
});

export const REMOVE_LUMP_SUM_FROM_USER_PREFERENCES = 'removeLumpSumFromUserPreferences';
export const removeLumpSumFromUserPreferences = (document) => ({
  type: REMOVE_LUMP_SUM_FROM_USER_PREFERENCES,
  document
});

export const CHANGE_USER_LANGUAGE = 'changeUserLanguage';
export const changeUserLanguage = (language) => ({
  type: CHANGE_USER_LANGUAGE,
  language
});
