import { deburr } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import ramqPlacesJson from '../data/ramq-places.json';

interface RAMQFacility {
  address: string;
  category: string;
  city: string;
  isCMS: boolean;
  name: string;
  number: string;
  numbers: Array<{
    effectiveEndDate: string | null;
    effectiveStartDate: string;
    number: string;
  }>;
  rssCode: number;
  type: 'physical';
  zipCode: string;
  daysOff: string[];
}

interface RAMQGeographicLocation {
  category: undefined;
  effectiveStartDate: string;
  effectiveEndDate: string | null;
  name: string;
  number: string;
  regionName: string;
  regionNumber: string;
  type: 'geographical';
}

export type RAMQPlace = RAMQFacility | RAMQGeographicLocation;

export interface SearchOptions {
  type?: 'physical' | 'geographical';
  maxResults: number;
}

export class RAMQPlaces {
  private static ramqPlaces: Map<string, RAMQPlace>;

  static get placesMap() {
    if (!this.ramqPlaces) {
      this.ramqPlaces = this.buildRamqDict();
    }

    return this.ramqPlaces;
  }

  private static buildRamqDict(): Map<string, RAMQPlace> {
    const ramqPlaces = new Map<string, RAMQPlace>();
    (ramqPlacesJson as RAMQPlace[]).forEach((entry) => ramqPlaces.set(entry.number, entry));
    return ramqPlaces;
  }

  public static get(number: string): RAMQPlace | undefined {
    return this.placesMap.get(number);
  }

  public static isEffectiveOn(ramqPlace: RAMQPlace, serviceDate: Moment | number | undefined): boolean {
    const date = moment(serviceDate); // When serviceDate is undefined, fallback on current date

    if (ramqPlace.type === 'physical') {
      return this.isFacilityEffectiveOn(ramqPlace, date);
    }

    if (ramqPlace.type === 'geographical') {
      return this.isGeographicLocationEffectiveOn(ramqPlace, date);
    }

    return false;
  }

  private static isFacilityEffectiveOn(ramqPlace: RAMQFacility, serviceDate: Moment): boolean {
    const { effectiveStartDate, effectiveEndDate } = ramqPlace.numbers.find(
      ({ number }) => number === ramqPlace.number
    )!;

    if (!effectiveEndDate) {
      return serviceDate.isSameOrAfter(moment(effectiveStartDate));
    }

    return serviceDate.isBetween(
      moment(effectiveStartDate),
      effectiveEndDate ? moment(effectiveEndDate) : undefined,
      undefined, // Granularity
      '[]' // Inclusivity
    );
  }

  private static isGeographicLocationEffectiveOn(ramqPlace: RAMQGeographicLocation, serviceDate: Moment): boolean {
    if (!ramqPlace.effectiveEndDate) {
      return serviceDate.isSameOrAfter(moment(ramqPlace.effectiveStartDate));
    }

    return serviceDate.isBetween(
      moment(ramqPlace.effectiveStartDate),
      moment(ramqPlace.effectiveEndDate),
      undefined, // Granularity
      '[]' // Inclusivity
    );
  }

  public static search(
    searchTerm: string,
    serviceDate: Moment | number | undefined = undefined,
    searchOptions: SearchOptions = { maxResults: 21 }
  ): RAMQPlace[] {
    const searchTokens = deburr(searchTerm).toLowerCase().split(' ');

    const foundPlaces: RAMQPlace[] = [];
    for (const place of Array.from(this.placesMap.values())) {
      const nameLower = deburr(place.name).toLowerCase();
      if (
        (!serviceDate || this.isEffectiveOn(place, serviceDate)) &&
        (!searchOptions.type || place.type === searchOptions.type) &&
        searchTokens.every((searchToken) => place.number === searchToken || nameLower.includes(searchToken))
      ) {
        foundPlaces.push(place);
      }
      if (foundPlaces.length === searchOptions.maxResults) {
        break;
      }
    }

    return foundPlaces;
  }

  public static isCms(number: string): boolean {
    const place = this.get(number);
    return place?.type === 'physical' && place.isCMS;
  }

  public static isUniversityOrGMF(number: string): boolean {
    return ['5', '6'].includes(number[0]);
  }
}
