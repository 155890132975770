import PeriodAmountService, { DayWithComputedAmount, TotalAmountByCode } from '../PeriodAmountService';
import { computeTrainingAmountForDay } from '../../computeExpectedAmoutForTraining';

class TrainingAmountService extends PeriodAmountService {
  getAmountByDay(): DayWithComputedAmount[] {
    return this.activity.days.map((day) => ({
      ...day,
      amount: computeTrainingAmountForDay(day)
    }));
  }

  getTotalAmount(): number {
    return this.getAmountByDay().reduce((acc, { amount }) => acc + amount, 0);
  }

  getTotalAmountByCode(): TotalAmountByCode[] {
    throw new Error('This method is not implemented for training type');
  }
}

export default TrainingAmountService;
