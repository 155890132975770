import { get, isArray } from 'lodash';
import moment from 'moment-timezone';
import ActivityStatus from '../core/activity/domain/ActivityStatus';
import ActivityType from '../core/activity/domain/ActivityType';
import isDefined from '../utils/isDefined';
import ActAmountComputatorFactory from './ActAmountComputator/ActAmountComputator.factory';
import { hasRamqExchange } from '../models/utils/activitiesUtils';

export const addAmount = (amount1, amount2) => (parseFloat(amount1) + parseFloat(amount2)).toFixed(2);

/**
 * MED-1552
 * Console admin needs to be able to manually overwrite RAMQ expected amount.
 * So this is why we use manualCorrection field
 */
export const directPaymentActivityHasExpectedAmount = (activity) =>
  hasRamqExchange(activity) || isManualCorrectionDefinedInActivity(activity);

export const computeExpectedAmountForDirectPaymentActivity = (activity) => {
  const actAmountComputator = new ActAmountComputatorFactory().create(activity);

  return actAmountComputator.compute();
};

export const compareMostRecentRamqExchange = (a, b) => b.createdOn - a.createdOn;
export const compareMostRecentStatementProductionDate = (a, b) =>
  moment(b.statementProductionDate).valueOf() - moment(a.statementProductionDate).valueOf();

export const isManualCorrectionDefinedInActivity = (activity) =>
  isDefined(activity.manualCorrection) && get(activity, 'manualCorrection', '0.00') !== '0.00';

export const isAct = (activity) => activity.type === ActivityType.ACT;
export const isMixte = (activity) => activity.type === ActivityType.MIXTE;
export const isLumpSum = (activity) => activity.type === ActivityType.LUMP_SUM;
export const isTraining = (activity) => activity.type === ActivityType.TRAINING;
export const isTravelExpenses = (activity) => activity.type === ActivityType.TRAVEL_EXPENSES;
export const isMultipleDays = (activity) => isArray(activity.days);
export const isNotCanceledStatus = (activity) => activity.status !== ActivityStatus.CANCELED;
