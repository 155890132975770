import { Button, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DialogTrigger from 'app/components/Dialogs/DialogTrigger/DialogTrigger';
import { VisitCodeDialog } from 'app/components/Form/VisitCode/VisitCodeDialog';
import React, { useEffect } from 'react';
import { WrappedFieldInputProps } from 'redux-form';
import { VisitCodeOption } from 'shared/act/services/VisitCodes';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  inputLabel: {
    top: -5,
    left: 'unset',
    transformOrigin: 'top',
    transform: 'scale(0.75)'
  },
  triggerButton: {
    fontSize: '1rem'
  }
});

interface Props {
  visitCodeOptions: VisitCodeOption[];
  disabled?: boolean;
  label?: string;
  input: WrappedFieldInputProps;
}

export function VisitCodeTrigger({ visitCodeOptions, disabled, label, input }: Props) {
  const classes = useStyles();
  const { value, onChange } = input;

  const selectedOption = visitCodeOptions.find((option) => option.value === value);

  useEffect(() => {
    onChange(undefined);
  }, [visitCodeOptions, onChange]);

  if (!visitCodeOptions.length) {
    return null;
  }

  return (
    <FormControl className={classes.container}>
      {value && (
        <InputLabel shrink className={classes.inputLabel}>
          {label}
        </InputLabel>
      )}
      <DialogTrigger
        DialogComponent={VisitCodeDialog}
        onItemSelected={(item) => onChange(item?.value)}
        dialogProps={{
          options: visitCodeOptions,
          value
        }}
      >
        <Button disabled={disabled} className={classes.triggerButton}>
          {selectedOption?.shortLabel || label}
        </Button>
      </DialogTrigger>
    </FormControl>
  );
}
