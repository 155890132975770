export const GET_RAMQ_CALL_LOG = 'getRamqCallLog';
export const GET_RAMQ_CALL_LOG_SUCCESS = 'getRamqCallLogSuccess';
export const GET_RAMQ_CALL_LOG_ERROR = 'getRamqCallLogFailure';

export function getRamqCallLog(logId) {
  return {
    type: GET_RAMQ_CALL_LOG,
    logId
  };
}

export function getRamqCallLogSuccess(ramqLog) {
  return {
    type: GET_RAMQ_CALL_LOG_SUCCESS,
    ramqLog
  };
}

export function getRamqCallLogFailure(error) {
  return {
    type: GET_RAMQ_CALL_LOG_ERROR,
    error
  };
}
