import Role from '../../../shared/domain/authentication/Role';
import { firestore } from '../../../server/Firebase';
import { USERS, USERS_COLLECTION, USERS_HISTORY } from '../../../shared/collection/collectionConstants';
import User from '../../../shared/domain/user/User';

interface UserRepository {
  // eslint-disable-next-line no-unused-vars
  getByRoles(roles: Role[]): Promise<User[]>;
  getHistory(userId: string): Promise<UserVersion[]>;
}

export interface UserVersion {
  user: User & {
    auditInformation: {
      modifiedOn: Date;
      modifiedBy: string;
    };
  };
  timestamp: number;
}

class FirebaseUserRepository implements UserRepository {
  async getByRoles(roles: Role[]): Promise<User[]> {
    const { docs } = await firestore.collection(USERS_COLLECTION).where('role', 'in', roles).get();

    return docs.map((doc) => doc.data() as User);
  }

  async getHistory(userId): Promise<UserVersion[]> {
    const { docs } = await firestore
      .collection(USERS_HISTORY)
      .where('id', '==', userId)
      .orderBy('auditInformation.modifiedOn', 'desc')
      .get();

    const userVersions: UserVersion[] = [];
    const userMap = new Map<string, User>();

    for (const doc of docs) {
      const modifiedBy = doc.data().auditInformation?.modifiedBy;
      if (modifiedBy && !userMap.has(modifiedBy)) {
        const { docs: modifiedByDocs } = await firestore.collection(USERS).where('id', '==', modifiedBy).get();
        if (modifiedByDocs.length > 0) {
          userMap.set(modifiedBy, modifiedByDocs[0].data() as User);
        }
      }

      const modifiedOn = doc.data().auditInformation?.modifiedOn;
      const modifiedByUser = userMap.get(modifiedBy)
        ? `${userMap.get(modifiedBy)?.firstName} ${userMap.get(modifiedBy)?.lastName}`
        : modifiedBy;

      userVersions.push({
        timestamp: modifiedOn,
        user: {
          ...doc.data(),
          ...{
            auditInformation: {
              modifiedOn,
              modifiedBy: modifiedByUser
            }
          }
        }
      });
    }

    return userVersions;
  }
}

export default FirebaseUserRepository;
