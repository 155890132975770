import SelectInput from 'application/components/form-controls/select-input';
import useTranslate from 'application/hooks/use-translate';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import React from 'react';

interface Props extends ValidatedFieldProps {
  value: string;
  disabled?: boolean;
  required?: boolean;
  onChange: (value: string) => void;
}

const ProvinceSelectInput = (props: Props) => {
  const translate = useTranslate();

  const options = [
    { value: 'AB', label: translate('AB') },
    { value: 'BC', label: translate('BC') },
    { value: 'MB', label: translate('MB') },
    { value: 'NB', label: translate('NB') },
    { value: 'NL', label: translate('NL') },
    { value: 'NS', label: translate('NS') },
    { value: 'NT', label: translate('NT') },
    { value: 'NU', label: translate('NU') },
    { value: 'ON', label: translate('ON') },
    { value: 'PE', label: translate('PE') },
    { value: 'QC', label: translate('QC') },
    { value: 'SK', label: translate('SK') },
    { value: 'YT', label: translate('YT') }
  ];

  options.sort((a, b) => a.label.localeCompare(b.label));

  return <SelectInput options={options} {...props} />;
};

export default ProvinceSelectInput;
