import Range from '../../../utils/Range/Range';

type ActCodeAccordingToAgeCollectionRule = {
  code: string;
  ageRange: Range;
};

class ActCodeAccordingToAgeCollection {
  constructor(
    // eslint-disable-next-line no-unused-vars
    private rules: ActCodeAccordingToAgeCollectionRule[] = []
  ) {}

  public find(code: string, age: number): string | undefined {
    if (!this.isCodeIncludedInRules(code)) {
      return undefined;
    }

    const swappableCodesFound = this.rules.find(({ ageRange }) => ageRange.includes(age));

    return swappableCodesFound?.code;
  }

  private isCodeIncludedInRules(codeToBeIncluded: string): boolean {
    return this.rules.map(({ code }) => code).includes(codeToBeIncluded);
  }
}

export default ActCodeAccordingToAgeCollection;
