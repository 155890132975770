import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import { StatementLine } from './StatementDoctorInfo';
import StatementSectionContainer from './StatementSectionContainer';

export function StatementVariousDeductions({ generalInformation }) {
  const addUpAmounts = (sum, current) => sum + parseFloat(current.deductionAmount);
  const deductionLinesSumAmount = generalInformation.deductionLines.reduce(addUpAmounts, 0).toFixed(2);
  return generalInformation.deductionLines.length > 0 ? (
    <StatementSectionContainer withDivider>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Déductions diverses: ', currencyFormat(deductionLinesSumAmount))}
          secondaryLines={generalInformation.deductionLines.map((line) =>
            StatementLine(`${line.deductionCode} - ${line.deductionLabel}`, currencyFormat(line.deductionAmount))
          )}
        />
      </Grid>
    </StatementSectionContainer>
  ) : null;
}

StatementVariousDeductions.propTypes = {
  generalInformation: PropTypes.object.isRequired
};

export default StatementVariousDeductions;
