import React from 'react';
import withStyles, { StyledComponentProps } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import FormElement from '../../../../../../components/Form/FormElement/FormElement';
import MultiSelectCheckboxGroup from '../../../../../../components/Form/Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../../../../shared/periods/common/constants';

export const styles = (theme) => ({
  timeSelectionContainer: {
    flex: '0 1 auto',
    minWidth: 92,
    display: 'flex'
  },
  checkboxButton: {
    'minWidth': 0,
    'width': theme.spacing(7),
    'margin': 0,
    'marginRight': theme.spacing(1),
    '&:last-child': {
      marginRight: 0
    }
  }
});

export interface Props extends StyledComponentProps {
  fieldName: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (...arg: any[]) => void;
  list?: { value: string; disabled?: boolean }[];
}

export const PerdiemSelector: React.FunctionComponent<Props> = ({
  fieldName,
  onChange,
  list = [{ value: AM_PERDIEM }, { value: PM_PERDIEM }],
  classes
}) => (
  <FormElement
    label=""
    disabled
    name={`${fieldName}.perdiems`}
    className={classNames('perdiemsSelector', classes!.timeSelectionContainer)}
    component={MultiSelectCheckboxGroup}
    labelProp="value"
    valueProp="value"
    onChange={onChange}
    checkboxClasses={{ root: classes!.checkboxButton }}
    list={list}
  />
);

export default withStyles(styles)(PerdiemSelector);
