import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { reverse, sortBy } from 'lodash';
import HybridListSelector from '../../../components/Form/ListSelector/HybridListSelector/HybridListSelector';
import { perror } from '../../../../shared/utils/plog';
import { formatUnixToDate } from '../../../../shared/utils/dateTime/dateTimeUtils';
import LoadableDataCollectionState from '../../../ui/shared/global/LoadableDataCollectionState';
import { fetchAvailableStatements } from '../../../infrastructure/api/ReportAPI';
import { mapToStatementSelector } from '../../ReportsRoute/Statement/StatementUtils';

export interface Props {
  filters?: any;
  date: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (data: number) => void;
}

const DEFAULT_DATE_MESSAGE = 'Choisir une date';

interface State extends LoadableDataCollectionState<any> {}

class AvailableStatementSelector extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      loading: false,
      data: []
    };
  }

  async componentDidMount() {
    const { filters } = this.props;
    this.setState({ loading: true });

    try {
      const { data } = await fetchAvailableStatements(filters);
      const statementsDates = reverse(sortBy(data.map(formatUnixToDate))).map(mapToStatementSelector);
      this.setState({ data: statementsDates });
    } catch (error) {
      perror(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { date, onChange } = this.props;
    const { data, loading } = this.state;

    const notReady = loading || data === null;

    if (notReady) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress size={20} color="primary" />
        </div>
      );
    }

    return (
      <HybridListSelector
        width={400}
        placeholder={DEFAULT_DATE_MESSAGE}
        input={{ onChange: (evt) => onChange(evt.target.value), value: date || DEFAULT_DATE_MESSAGE }}
        values={data}
      />
    );
  }
}

export default AvailableStatementSelector;
