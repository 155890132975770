import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  container: {
    paddingRight: 0,
    flex: 1
  },
  iconDescription: {
    flex: '0 0 auto'
  },
  codeDescription: {
    flex: '0 0 auto',
    paddingLeft: theme.spacing(1)
  },
  textDescription: {
    justifyContent: 'center',
    paddingRight: 0,
    paddingLeft: theme.spacing(4)
  },
  actionDescription: {
    flex: '0 0 auto',
    paddingRight: theme.spacing(1)
  }
});
function DescriptionHeaderItem({ iconDescription, codeDescription, textDescription, actionDescription, classes }) {
  return (
    <ListItem className={classes.container}>
      <ListItemText className={classes.iconDescription}>
        <Typography variant="caption">{iconDescription}</Typography>
      </ListItemText>
      {codeDescription && (
        <ListItemText className={classes.codeDescription}>
          <Typography variant="caption">{codeDescription}</Typography>
        </ListItemText>
      )}
      <ListItemText className={classes.textDescription}>
        <Typography variant="caption">{textDescription}</Typography>
      </ListItemText>
      <ListItemText className={classes.actionDescription}>
        <Typography variant="caption">{actionDescription}</Typography>
      </ListItemText>
    </ListItem>
  );
}

DescriptionHeaderItem.propTypes = {
  iconDescription: PropTypes.string.isRequired,
  codeDescription: PropTypes.string,
  textDescription: PropTypes.string.isRequired,
  actionDescription: PropTypes.string.isRequired
};

export default withStyles(styles)(DescriptionHeaderItem);
