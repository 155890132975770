import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { defaultFormatMomentDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';

export const styles = () => ({
  item: {
    justifyContent: 'center'
  },
  itemText: {
    flex: '0 1 auto'
  }
});

const formatPeriod = (period) =>
  `${defaultFormatMomentDate(period.startDate)} - ${defaultFormatMomentDate(period.endDate)}`;

function PeriodList({ periods, onItemSelected, onClose, onMoreItems, classes }) {
  return (
    <>
      <List>
        {periods.map((period) => (
          <ListItem
            divider
            key={period.startDate.format()}
            button
            onClick={() => {
              onItemSelected(period);
              onClose();
            }}
            className={classes.item}
          >
            <ListItemText primary={formatPeriod(period)} className={classes.itemText} />
          </ListItem>
        ))}
      </List>
      {onMoreItems && (
        <Button onClick={() => onMoreItems()} color="default" fullWidth>
          <FormattedMessage id="global.loadMorePeriods" />
        </Button>
      )}
    </>
  );
}

PeriodList.propTypes = {
  periods: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onMoreItems: PropTypes.func
};

PeriodList.defaultProps = {
  onMoreItems: undefined
};

export default withStyles(styles)(PeriodList);
