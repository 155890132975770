import { ActCode } from '../../../domain/activity/act/model/Act';

class ComplexSurgeryIdentifier {
  private SUPPORTED_COMPLEX_SURGERY_CODES = [
    '5937',
    '5938',
    '5939',
    '5940',
    '5941',
    '5942',
    '5943',
    '5944',
    '5945',
    '5946',
    '5947',
    '5948',
    '5949'
  ];

  public isComplexSurgery(codes: ActCode[]) {
    return this.hasComplexSurgeryCode(codes);
  }

  private hasComplexSurgeryCode(codes: ActCode[]): boolean {
    return codes.some(this.isComplexSurgeryCode, this);
  }

  private isComplexSurgeryCode({ code }: ActCode): boolean {
    return this.SUPPORTED_COMPLEX_SURGERY_CODES.includes(code);
  }
}

export default new ComplexSurgeryIdentifier();
