import { PlaceSelectField } from '../../../components/Form/Fields';
import { AssociatedField, RequiredDetail } from '../requiredDetail.type';

const associatedFields: AssociatedField[] = [
  {
    getName: (index) => `codes[${index}].placeReference`,
    label: 'Établissement supplémentaire',
    type: PlaceSelectField
  }
];

const additionalPlace: RequiredDetail = { associatedFields };

export default additionalPlace;
