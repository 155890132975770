import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import React from 'react';
import DialogCenteredTitle from '../../../components/Dialogs/DialogCenteredTitle/DialogCenteredTitle';

export function SingleSelectDialog({
  title,
  children: ListChildren,
  open,
  onClose,
  onItemSelected,
  fullScreen,
  ...props
}) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth>
      <DialogCenteredTitle label={title} />
      <DialogContent>
        <ListChildren onItemSelected={onItemSelected} onClose={onClose} {...props} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SingleSelectDialog.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(SingleSelectDialog);
