import { filter, findIndex, intersection } from 'lodash';
import isDefined from './isDefined';
import { ActCode } from '../domain/activity/act/model/Act';

export const atLeastOneCodeFits = (searchedCodes: string[], values: ActCode[]) =>
  findIndex(values, (code) => searchedCodes.includes(code.code)) !== -1;

export const atLeastOneContextElementFits = (searchedCodes, contextElements) =>
  isDefined(contextElements) && intersection(searchedCodes, contextElements).length > 0;

export const getCodesThatHaveThisProperty = (searchedCodes, propertyName) =>
  filter(searchedCodes, (code) => code[propertyName]);
