import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import Section from 'application/components/form-controls/section';
import TextInput from 'application/components/form-controls/text-input';
import useTranslate from 'application/hooks/use-translate';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import React from 'react';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import PasswordInput from 'application/components/form-controls/password-input';

interface Props {
  user: FirestoreUser;
  registerField: (field: string) => ValidatedFieldProps;
  onChange: (user: FirestoreUser) => void;
}

const Medavie = ({ user, registerField, onChange }: Props) => {
  const translate = useTranslate('pages.settings.partners.medavie');

  return (
    <Section label={translate('title')}>
      <FieldContainer label={translate('username')}>
        <TextInput
          {...registerField('medavieUserName')}
          value={user.medavieUserName}
          onChange={(medavieUserName) => onChange({ ...user, medavieUserName })}
        />
      </FieldContainer>
      <FieldContainer label={translate('password')}>
        <PasswordInput
          {...registerField('medaviePassword')}
          value={user.medaviePassword}
          onChange={(medaviePassword) => onChange({ ...user, medaviePassword })}
        />
      </FieldContainer>
      <Section.Footer>
        <Button type="submit" className="btn btn-primary">
          {translate('save')}
        </Button>
      </Section.Footer>
    </Section>
  );
};

export default Medavie;
