import {
  createPeriodDays,
  getWeekBasedEndDateFromDate,
  getWeekBasedStartDateFromDate
} from '../../../shared/periods/periods';
import { Mixte } from '../../../shared/domain/activity/mixte/model/Mixte';
import LumpSum from '../../../shared/domain/activity/lumpSum/model/LumpSum';
import { getMomentDateFromUnix } from '../../../shared/utils/dateTime/dateTimeUtils';
import { getActivityPlacePreferenceFromRoute, getFirstPlaceByPriority } from '../User/preferencesUtils';
import { activitiesCollectionRef } from '../../firebase/collection/collectionReferences';
import Place from '../../../shared/domain/place/model/Place';
import Activity from '../../../shared/domain/activity/global/model/Activity';
import ActivityType from '../../../shared/core/activity/domain/ActivityType';
import User from '../../../shared/domain/user/User';
import { LUMP_SUM_PERIOD_DAYS_DURATION, MIXTE_PERIOD_DAYS_DURATION } from '../../../shared/periods/constants';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import BillingType from '../../../shared/domain/billing/model/BillingType';
import UserBillingType, { userBillingTypeToBillingType } from '../../../shared/domain/user/UserBillingType';

export type Context = {
  placesPreferences: Place[];
  activities: Activity[];
  user: User;
};

class PeriodsBillingGenerator {
  constructor(
    // eslint-disable-next-line no-unused-vars
    private readonly context: Context
  ) {}

  private isActivityType =
    (type: ActivityType.MIXTE | ActivityType.LUMP_SUM) =>
    (activity: Activity): activity is LumpSum | Mixte =>
      activity.type === type;

  private isStartDate = (startDate: number) => (activity: Mixte | LumpSum) => activity.startDate === startDate;

  private isNotCanceled = (activity: Mixte | LumpSum) => activity.status !== ActivityStatus.CANCELED;

  getPeriod(
    activityType: ActivityType.MIXTE | ActivityType.LUMP_SUM,
    savedPeriod: Mixte | LumpSum | undefined,
    date: string,
    placeNumber?: string,
    billingType?: BillingType,
    poolNumber?: string
  ): Mixte | LumpSum {
    if (savedPeriod) {
      return this.generatePeriod(activityType, date, savedPeriod, placeNumber, billingType, poolNumber);
    }

    const momentDate = getMomentDateFromUnix(date);

    const effectivePlaceNumber =
      placeNumber ||
      this.getPlaceNumberFromContextActivities(activityType, momentDate) ||
      getFirstPlaceByPriority(this.context.placesPreferences).number;
    const effectiveBillingType = billingType || userBillingTypeToBillingType(this.context.user.billingType);
    const effectivePoolNumber = poolNumber || undefined;

    const matchingPeriod = this.getMatchingPeriod(
      activityType,
      momentDate,
      effectivePlaceNumber,
      effectiveBillingType,
      effectivePoolNumber
    );

    return this.generatePeriod(
      activityType,
      date,
      matchingPeriod,
      effectivePlaceNumber,
      effectiveBillingType,
      effectivePoolNumber
    );
  }

  private getMatchingPeriod = (
    activityType: ActivityType.MIXTE | ActivityType.LUMP_SUM,
    date,
    placeNumber?: string,
    billingType?: BillingType,
    poolNumber?: string
  ) => {
    const periodDuration = this.getPeriodDayDuration(activityType);
    const startDate = getWeekBasedStartDateFromDate(date, periodDuration).valueOf();

    return this.context.activities
      .filter(this.isActivityType(activityType))
      .filter(this.isStartDate(startDate))
      .filter(this.isNotCanceled.bind(this))
      .find((activity: Activity) => {
        if (placeNumber && activity.place.number !== placeNumber) return false;
        if (billingType && activity.billingType !== billingType) return false;

        if (poolNumber) {
          if (activity.poolNumber && activity.poolNumber !== poolNumber) return false;
          if (!activity.poolNumber && this.context.user.originalPoolNumber !== poolNumber) return false;
        }

        return true;
      }) as Mixte | LumpSum;
  };

  private getPlaceNumberFromContextActivities = (activityType: ActivityType.MIXTE | ActivityType.LUMP_SUM, date) => {
    const periodDuration = this.getPeriodDayDuration(activityType);
    const startDate = getWeekBasedStartDateFromDate(date, periodDuration).valueOf();

    const potentialActivities = this.context.activities
      .filter(this.isActivityType(activityType))
      .filter(this.isStartDate(startDate));

    return potentialActivities.length > 0 ? potentialActivities[0].place.number : undefined;
  };

  private getPeriodDayDuration(activityType: ActivityType.MIXTE | ActivityType.LUMP_SUM) {
    return activityType === ActivityType.LUMP_SUM ? LUMP_SUM_PERIOD_DAYS_DURATION : MIXTE_PERIOD_DAYS_DURATION;
  }

  private generatePeriod(
    activityType: ActivityType.MIXTE | ActivityType.LUMP_SUM,
    date: string,
    activity?: Mixte | LumpSum,
    placeNumber?: string,
    billingType?: BillingType,
    poolNumber?: string
  ): Mixte | LumpSum {
    const allowPeriodsOnWeekEnd = activityType === ActivityType.LUMP_SUM;

    if (activity) {
      return {
        ...activity,
        days: createPeriodDays(
          activityType,
          getMomentDateFromUnix(activity.startDate),
          activity.duration,
          activity.days,
          allowPeriodsOnWeekEnd
        )
      };
    }

    const periodDuration = this.getPeriodDayDuration(activityType);
    const currentDate = getMomentDateFromUnix(date);
    const currentPeriodStartDate = getWeekBasedStartDateFromDate(currentDate, periodDuration);
    const currentPeriodEndDate = getWeekBasedEndDateFromDate(currentPeriodStartDate, periodDuration);
    const place = getActivityPlacePreferenceFromRoute(this.context.placesPreferences, placeNumber);
    const days = createPeriodDays(activityType, currentPeriodStartDate, periodDuration, [], allowPeriodsOnWeekEnd);
    const status = ActivityStatus.NO_STATUS;

    return {
      id: activitiesCollectionRef().doc().id,
      userId: this.context.user.id,
      createdOn: Date.now(),
      billingType:
        billingType || (this.context.user.billingType === UserBillingType.POOL ? BillingType.POOL : BillingType.PRIV),
      place,
      placeNumber: place.number,
      date: currentPeriodStartDate.valueOf(),
      startDate: currentPeriodStartDate.valueOf(),
      endDate: currentPeriodEndDate.valueOf(),
      days,
      status,
      type: activityType,
      duration: periodDuration,
      ...(poolNumber ? { poolNumber } : {})
    };
  }
}

export default PeriodsBillingGenerator;
