import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import { getActiveBillsNumber } from '../../../../shared/models/utils/activitiesUtils';
import { TRAVEL_EXPENSES_ROUTE } from '../../../containers/Navigation/constants';
import { selectUserIdInContext } from '../../../containers/User/selectors';
import ActivityCommentLists from '../../../containers/UsersRoute/UserRoute/UserActivities/ActSummaryActivity/ActivityCommentLists/ActivityCommentLists';
import TravelExpensesAdminActions from '../../../containers/UsersRoute/UserRoute/UserActivities/TravelExpensesAdminActions/TravelExpensesAdminActions';
import ActivityOutcomeResponses from '../../Ramq/RamqExchange/ActivityOutcomeResponses/ActivityOutcomeResponses';
import MultipleActiveBillWarning from '../../Ramq/RamqExchange/MultipleActiveBillWarning/MultipleActiveBillWarning';
import ActSummaryActivityHeader from '../Act/ActSummaryHeader/ActSummaryHeader';
import ActivityContainer from '../ActivityContainer/ActivityContainer';
import ActivitySummaryWrapper from '../ActivitySummaryWrapper/ActivitySummaryWrapper';
import DetailsNote from '../DetailsNote/DetailsNote';
import MainInformationContainer from '../MainInformationContainer/MainInformationContainer';
import TravelDepartureArrival from './TravelDepartureArrival';
import TravelFeesSummary from './TravelFeesSummary';
import TravelTimeToHour from './TravelTimeToHours';

export const styles = (theme) => ({
  listItemContainer: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5)
  },
  contentOverride: {
    height: 'auto'
  }
});

const TravelExpensesSummaryActivity = ({ activity, userId, isSelected, classes }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeBills = getActiveBillsNumber(activity.ramqExchange);

  return (
    <ActivitySummaryWrapper activity={activity} isSelected={isSelected}>
      <ActivityContainer
        button
        onClick={() => navigate({ pathname: `${TRAVEL_EXPENSES_ROUTE}/${activity.id}`, search: location.search })}
        className={classes.listItemContainer}
      >
        <ActSummaryActivityHeader activity={activity} />
        <MainInformationContainer activity={activity} classes={{ simplifiedViewContent: classes.contentOverride }}>
          <TravelTimeToHour activity={activity} />
          <TravelDepartureArrival activity={activity} />
          <TravelFeesSummary activity={activity} />
        </MainInformationContainer>
        <DetailsNote activity={activity} />
      </ActivityContainer>
      <Restrict atLeastRole={Role.Agent}>
        <ActivityCommentLists activity={activity} />
        <MultipleActiveBillWarning activeBills={activeBills} />
        <ActivityOutcomeResponses activity={activity} />
        <TravelExpensesAdminActions activity={activity} userId={userId} activeBills={activeBills} />
      </Restrict>
    </ActivitySummaryWrapper>
  );
};

TravelExpensesSummaryActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool
};

TravelExpensesSummaryActivity.defaultProps = {
  isSelected: false
};

export const mapStateToProps = createStructuredSelector({
  userId: selectUserIdInContext()
});

export default compose(withStyles(styles), connect(mapStateToProps))(TravelExpensesSummaryActivity);
