import MULTI_ACT_FORM_NAME from 'app/act/multiAct/ui/component/MultiActForm/constants';
import actions from 'redux-form/lib/actions';

export const FORCE_DELETE_VALUE = 'forceDeleteValue';
export const REMOVE_KEY = 'removeKey';

export function forceDeleteValue(valuePath) {
  return {
    type: FORCE_DELETE_VALUE,
    valuePath
  };
}

export function removeKey(key) {
  return {
    type: REMOVE_KEY,
    key
  };
}

export const CLEAR_MUTLI_ACT_PATIENTS_VISIT_CODE = 'CLEAR_MUTLI_ACT_PATIENTS_VISIT_CODE';
export function clearMultiActFormPatientsVisitCode() {
  return {
    type: 'CLEAR_MUTLI_ACT_PATIENTS_VISIT_CODE'
  };
}

export function clearMultiActFormActivityArea() {
  return actions.change(MULTI_ACT_FORM_NAME, 'activityArea', null);
}

export function clearMultiActFormPatients() {
  return actions.change(MULTI_ACT_FORM_NAME, 'patients', [{}]);
}

export function setMultiActFormStart(start) {
  return actions.change(MULTI_ACT_FORM_NAME, 'start', start);
}
