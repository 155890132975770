import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import medyxLogoWhite from '../../../../images/medyx-logo-login-white.svg';

const styles = (theme) => ({
  paperContainer: {
    marginTop: '50px',
    width: '70%',
    maxWidth: '550px',
    padding: theme.spacing(5)
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  loginFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary[500],
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  medyxLoginLogo: {
    width: '100%',
    maxHeight: '200px',
    height: '40vw'
  }
});

function LoginFormWrapper({ id, onSubmit, classes, children }) {
  return (
    <div id="login-page" className={classes.loginFrame}>
      <div className={classes.loginContainer}>
        <img className={classes.medyxLoginLogo} src={medyxLogoWhite} alt="Medyx Logo" />
        <Paper id={id} className={classes.paperContainer}>
          <form onSubmit={onSubmit} className={classes.formWrapper}>
            {children}
          </form>
        </Paper>
      </div>
    </div>
  );
}

LoginFormWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default withStyles(styles)(LoginFormWrapper);
