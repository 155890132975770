class StorageUploadContext {
  constructor(actId, fileEntry, persistFileLocallyBeforeUpload) {
    this.actId = actId;
    this.fileEntry = fileEntry;
    this.persistFileLocallyBeforeUpload = persistFileLocallyBeforeUpload;

    this.fileWrapper = undefined;
    this.localFileRepository = undefined;
    this.postLocalPersistFileHook = undefined;
    this.storeDispatch = undefined;
    this.userId = undefined;
  }
}

export default StorageUploadContext;
