import withStyles from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: theme.spacing(4)
  }
});

export function AppHeaderContainer({ classes, className, children }) {
  return <Toolbar className={classNames(classes.toolBar, className)}>{children}</Toolbar>;
}

AppHeaderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

AppHeaderContainer.defaultProps = {
  className: {}
};

export default withStyles(styles)(AppHeaderContainer);
