import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectDisclaimerAccepted, selectPatientType } from 'app/containers/ActForm/selectors';
import { formatDateStringToUnix, formatUnixToDate } from '../../../../../shared/utils/dateTime/dateTimeUtils';
import SingleSelectCheckboxGroup from '../../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { GENDER_LIST } from '../../../../components/Form/constants';
import { DateField, NamField, NumberField, SelectorField, SingleLineField } from '../../../../components/Form/Fields';
import FormElement from '../../../../components/Form/FormElement/FormElement';
import { actFormFields, carenceList, PATIENT_TYPE_SELECTION } from '../../constants';
import PATIENT_WITHOUT_ID_TYPES from '../../../../../shared/ramq/domainValues/situationTypes';

const formatUrgentDisclaimerAccepted = (value?: boolean) => {
  if (value === true) return 'Oui';
  if (value === false) return 'Non';
  return "N'a pas répondu";
};

export const DefaultNoNamFormAdmin = () => {
  const patientType = useSelector(selectPatientType());
  const urgentDisclaimerAccepted = useSelector(selectDisclaimerAccepted());

  return (
    <CardContent>
      <Grid container spacing={2}>
        {patientType === PATIENT_WITHOUT_ID_TYPES.patientNeedingUrgentCare && (
          <Grid item xs={12} sm={12}>
            <Typography gutterBottom variant="body1">
              MD a accepté l'avertissement: {formatUrgentDisclaimerAccepted(urgentDisclaimerAccepted)}
            </Typography>
            <Divider />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Typography gutterBottom variant="h6">
            Identification du patient
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormElement
            name={`${actFormFields.patientInformation}.type`}
            label="Type de patient"
            component={SelectorField}
            list={PATIENT_TYPE_SELECTION}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.lastName`}
            label="Nom du patient"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.firstName`}
            label="Prénom du patient"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.gender`}
            component={SingleSelectCheckboxGroup}
            list={GENDER_LIST}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.birthDate`}
            label="Date de naissance"
            deletable
            component={DateField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.nas`}
            label="NAS du patient"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.birthOrder`}
            label="Ordre de naissance"
            component={NumberField}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormElement
            name={`${actFormFields.patientInformation}.carence`}
            label="Délai de carence"
            component={SelectorField}
            list={carenceList}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            label="Nam expiré ou carence"
            name={`${actFormFields.patientInformation}.expiredNam`}
            component={NamField}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography gutterBottom variant="h6">
            Identification du répondant
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.respondent.nam`}
            label="NAM du répondant"
            fullWidth
            component={NamField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.respondent.lastName`}
            label="Nom du répondant"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.respondent.firstName`}
            label="Prénom du répondant"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.respondent.gender`}
            component={SingleSelectCheckboxGroup}
            list={GENDER_LIST}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.respondent.birthDate`}
            label="Date de naissance"
            component={DateField}
            deletable
            format={formatUnixToDate}
            parse={formatDateStringToUnix}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography gutterBottom variant="h6">
            Adresse du patient et du répondant
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.address`}
            label="Adresse"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement name={`${actFormFields.patientInformation}.city`} label="Ville" component={SingleLineField} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.zipCode`}
            label="Code Postal"
            component={SingleLineField}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormElement
            name={`${actFormFields.patientInformation}.province`}
            label="Province"
            component={SingleLineField}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default DefaultNoNamFormAdmin;
