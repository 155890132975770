import shortid from 'shortid';
import { getFileObject, READ_AS, readFileEntry } from './cordovaLocalFile';

class ActFile {
  constructor(actId, fileEntry, data, timestamp, shortId, extension) {
    this.actId = actId;
    this.privateData = data;
    this.fileEntry = fileEntry;
    this.fileObject = undefined;
    this.timestamp = timestamp || Date.now();
    this.shortid = shortId || shortid.generate();
    this.extension = extension;

    this.setJavascriptFileObject(fileEntry);
  }

  setJavascriptFileObject(fileEntry) {
    if (fileEntry && fileEntry.file) {
      getFileObject(fileEntry).then((result) => {
        this.fileObject = result;
      });
    } else {
      this.fileObject = fileEntry;
    }
  }

  get data() {
    if (this.privateData) {
      return this.privateData;
    }

    if (!this.fileEntry) {
      throw new Error('ActFile has no data nor fileEntry, cannot get data', ActFile);
    }

    return readFileEntry(this.fileEntry, READ_AS.ARRAY_BUFFER);
  }

  set data(data) {
    this.privateData = data;
  }

  async getDataAsBase64() {
    // https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string
    let binary = '';
    const bytes = new Uint8Array(await this.data);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  get fileName() {
    return `${this.actId}_${this.timestamp}_${this.shortid}.${this.format}`;
  }

  get format() {
    if (this.extension) {
      return this.extension;
    }

    if (this.fileEntry && this.fileEntry.name) {
      const lastIndexOfDot = this.fileEntry.name.lastIndexOf('.');
      if (lastIndexOfDot > -1) {
        return this.fileEntry.name.substring(lastIndexOfDot + 1);
      }
    }

    if (this.fileEntry?.type?.type) {
      return this.fileEntry.type.type.split('/').pop();
    }

    if (this.fileObject && this.fileObject.type) {
      return this.fileObject.type.split('/').pop();
    }

    return '';
  }

  get originalFileName() {
    if (this.fileEntry === undefined) {
      throw new Error('ActFile has no fileEntry, cannot get the original file name', ActFile);
    }

    return this.fileEntry.name ?? this.fileName;
  }

  get metadata() {
    if (this.fileObject && this.fileObject.type) {
      return { contentType: this.fileObject.type };
    }

    return undefined;
  }
}

export default ActFile;
