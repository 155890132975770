import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import { selectActivitiesByDayInContext, selectActivitiesLoading } from '../../../containers/Activities/selectors';
import { ACTIVITIES_ACT_TYPE, ACTIVITIES_MIXTE_TYPE } from '../../../../shared/collection/collectionConstants';
import isDefined from '../../../../shared/utils/isDefined';
import Alert from '../../Alert/Alert';
import { selectSelectedPeriodPlace } from '../../../containers/PeriodsBilling/PeriodForm/store/selectors';
import { selectSelectedActPlace } from '../../../containers/ActForm/selectors';

export const MultiplePlacesOnSameDayWarning = ({ currentActivity, activitiesLoading, selectedPlace }) => {
  const { date } = useParams();
  const activities = useSelector(selectActivitiesByDayInContext(date));

  const getOtherActiveActOrMixtePlacesOnTheSameDay = (activities, currentActivity) => {
    const otherActivities = activities.filter(
      (activity) =>
        [ACTIVITIES_ACT_TYPE, ACTIVITIES_MIXTE_TYPE].includes(activity.type) &&
        activity.status !== ActivityStatus.CANCELED &&
        activity.id !== currentActivity.id &&
        activity.place
    );

    return otherActivities.map((activity) => activity.place);
  };

  const isCurrentActPlaceIsDifferentFromOtherActiveActsOrMixtesOnTheSameDay = () => {
    if (!isDefined(selectedPlace)) {
      return false;
    }
    const otherActivitiesPlaces = getOtherActiveActOrMixtePlacesOnTheSameDay(activities, currentActivity);

    if (otherActivitiesPlaces.length === 0) {
      return false;
    }

    return !otherActivitiesPlaces.map((place) => place.number).includes(selectedPlace.number);
  };

  if (!activitiesLoading && isCurrentActPlaceIsDifferentFromOtherActiveActsOrMixtesOnTheSameDay()) {
    const otherActivitiesPlaces = getOtherActiveActOrMixtePlacesOnTheSameDay(activities, currentActivity);

    const uniqPlacesNames = Array.from(new Set(otherActivitiesPlaces.map((place) => place.name)));

    return (
      <Alert
        severity="warning"
        noMargin
        showIcon
        title="Attention"
        message={
          <>
            <FormattedMessage id="act.warning.differentPlacesSameDay" />:
            <ul>
              <li>{selectedPlace.name}</li>
              {uniqPlacesNames.map((placeName) => (
                <li key={placeName}>{placeName}</li>
              ))}
            </ul>
          </>
        }
      />
    );
  }

  return null;
};

MultiplePlacesOnSameDayWarning.propTypes = {
  currentActivity: PropTypes.object.isRequired,
  selectedPlace: PropTypes.object,
  activitiesLoading: PropTypes.bool.isRequired
};

MultiplePlacesOnSameDayWarning.defaultProps = {
  selectedPlace: null
};

export const mapStateToProps = (state, { currentActivity }) =>
  createStructuredSelector({
    activitiesLoading: selectActivitiesLoading(),
    selectedPlace: currentActivity.type === ACTIVITIES_ACT_TYPE ? selectSelectedActPlace() : selectSelectedPeriodPlace()
  });

export default connect(mapStateToProps)(MultiplePlacesOnSameDayWarning);
