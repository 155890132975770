export const USER_ID_ROUTE_PARAM = ':userId';
export const ACTIVITY_ID_ROUTE_PARAM = ':activityId';
export const ACTIVITY_TYPE_ROUTE_PARAM = ':activityType';
export const DATE_ROUTE_PARAM = ':date';

export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = 'login';
export const FORGOT_PASSWORD_ROUTE = 'resetPassword';
export const ACTIVITIES_ROUTE = 'activities';
export const ACTIVITIES_DATE_ROUTE = `${ACTIVITIES_ROUTE}/${DATE_ROUTE_PARAM}`;
export const ACTIVITIES_SEARCH_ROUTE = '/search/activities';
export const SEARCH_ROUTE = 'search';
export const ACTS_ROUTE = 'acts';
export const SETTINGS_ROUTE = 'settings';
export const USERS_ROUTE = 'users';
export const GROUPS_ROUTE = 'groups';
export const PROFILE_ROUTE = 'profile';
export const MIXTE_ROUTE = 'mixte';
export const LUMP_SUM_ROUTE = 'lumpsum';
export const ASSISTANT_ROUTE = 'assistant';
export const REPORTS_ROUTE = 'reports';
export const RAMQ_LOGS_ROUTE = 'ramqLogs';
export const RAMQ_LOG_ROUTE = `${RAMQ_LOGS_ROUTE}/:id`;
export const STATEMENT_ROUTE = 'statement';
export const PERIOD_REPORTS_ROUTE = 'period-reports';
export const STATEMENT_INCOME_ROUTE = 'income-statement';
export const RECEIVABLE_ACCOUNTS_ROUTE = 'receivable-accounts';
export const TRAVEL_DISTANCES_ROUTE = 'travel-distances';
export const PAYMENT_REQUEST_ROUTE = 'payment-request';
export const OUTSIDE_RAMQ = 'outside-ramq';
export const GUARD = 'guard';
export const ADMIN_REPORT_ROUTE = 'admin-report';
export const TRAVEL_EXPENSES_ROUTE = 'travelExpenses';
export const NEW_TRAVEL_EXPENSES_ROUTE = `${TRAVEL_EXPENSES_ROUTE}/new`;
export const DUPLICATE_TRAVEL_EXPENSES_ROUTE = `${TRAVEL_EXPENSES_ROUTE}/new/duplicate`;
export const EDIT_TRAVEL_EXPENSES_ROUTE = `${TRAVEL_EXPENSES_ROUTE}/${ACTIVITY_ID_ROUTE_PARAM}`;
export const TRAINING_ROUTE = 'training';
export const NEW_TRAINING_ROUTE = `${TRAINING_ROUTE}/new`;

export const NEW_ACT_ROUTE = `${ACTS_ROUTE}/new`;
export const DUPLICATE_ACT_ROUTE = `${NEW_ACT_ROUTE}/duplicate`;
export const EDIT_ACT_ROUTE = `${ACTS_ROUTE}/${ACTIVITY_ID_ROUTE_PARAM}`;
export const USER_ROUTE = `${USER_ID_ROUTE_PARAM}`;
