import { LUMPSUM_TEACHING_CODES } from '../../../shared/periods/lumpSum/codePrefixor/ratePrefixors';
import LUMPSUM_DISPENSARY_AREA_MAP from '../../../shared/ramq/domainValues/lumpsumDispensaryAreas';

function overrideRemoteDispensaryAreaForExceptionCode(documentToSave: any) {
  const { days = [] } = documentToSave;

  if (days.length > 0) {
    return {
      ...documentToSave,
      days: days.map((day: any) => {
        if (LUMPSUM_TEACHING_CODES.includes(day.code) && day.activityArea === LUMPSUM_DISPENSARY_AREA_MAP.remote) {
          return {
            ...day,
            activityArea: LUMPSUM_DISPENSARY_AREA_MAP.remoteOverride
          };
        }
        if (
          !LUMPSUM_TEACHING_CODES.includes(day.code) &&
          day.activityArea === LUMPSUM_DISPENSARY_AREA_MAP.remoteOverride
        ) {
          return {
            ...day,
            activityArea: LUMPSUM_DISPENSARY_AREA_MAP.remote
          };
        }

        return day;
      })
    };
  }

  return documentToSave;
}

export default overrideRemoteDispensaryAreaForExceptionCode;
