import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import ActsDayRow from '../ActsDayRow/ActsDayRow';

export const styles = (theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary[500]
  },
  tableHeadCell: {
    color: theme.palette.common.white
  }
});

function TableHeader(acts, PrintButton, classes) {
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell className={classes.tableHeadCell} classes={{ root: classes.denseTableCell }}>
          <Typography variant="subtitle1" color="inherit">
            Actes
          </Typography>
        </TableCell>
        <TableCell colSpan="2" classes={{ root: classes.denseTableCell }}>
          {PrintButton}
        </TableCell>
        <TableCell className={classes.tableHeadCell} classes={{ root: classes.denseTableCell }} align="right">
          <Typography variant="subtitle1" color="inherit">
            {currencyFormat(acts.totalAmount)}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const columnNames = (classes) => (
  <TableHead>
    <TableRow>
      <TableCell classes={{ root: classes.denseTableCell }}>Jour</TableCell>
      <TableCell classes={{ root: classes.denseTableCell }} align="right">
        Non fact.
      </TableCell>
      <TableCell classes={{ root: classes.denseTableCell }} align="right">
        Fact.
      </TableCell>
      <TableCell classes={{ root: classes.denseTableCell }} align="right">
        Montant préliminaire
      </TableCell>
    </TableRow>
  </TableHead>
);

function TableContent(activityDays, pool, individual, classes) {
  return (
    <>
      {columnNames(classes)}

      <TableBody>
        {Object.keys(activityDays).map((key) => (
          <ActsDayRow
            key={key}
            actsDay={activityDays[key]}
            pool={pool}
            individual={individual}
            tableCellClass={classes.denseTableCell}
          />
        ))}
      </TableBody>
    </>
  );
}

export function PeriodActsTable({ acts, pool, individual, PrintButton, classes }) {
  if (!acts.activityDays) return null;

  return (
    <Table size="small">
      {TableHeader(acts, PrintButton, classes)}
      {TableContent(acts.activityDays, pool, individual, classes)}
    </Table>
  );
}

PeriodActsTable.defaultProps = {
  PrintButton: '',
  individual: false
};

PeriodActsTable.propTypes = {
  acts: PropTypes.object.isRequired,
  pool: PropTypes.bool.isRequired,
  individual: PropTypes.bool,
  PrintButton: PropTypes.node
};

export default withStyles(styles)(PeriodActsTable);
