import { compose } from 'redux';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { injectIntl } from 'react-intl';
import MoreActions from '../../../shared/actions/MoreActions/MoreActions';
import AlertApi from '../../../shared/confirm/api/AlertApi';

export const styles = (theme) => {
  const { breakpoints } = theme;
  return {
    [breakpoints.down('xs')]: {
      item: {
        paddingLeft: 0
      },
      itemText: {
        paddingLeft: 0
      }
    },
    itemTextRoot: {
      width: '2px'
    },
    itemTextTypography: {
      height: '1.5em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%'
    }
  };
};

export function FavoriteItemWithActionMenu({ onItemRemove, onItemEdit, chipNumber, primaryText, classes, intl }) {
  const actions = [];

  if (onItemEdit) {
    actions.push({
      key: 'edit-action-key',
      labelKey: 'editableCollectionWithCategories.edit',
      clickEvent: onItemEdit,
      Icon: EditIcon
    });
  }

  if (onItemRemove) {
    const onDeleteClicked = () => {
      AlertApi.showConfirm({
        onConfirm: onItemRemove,
        title: intl.formatMessage({ id: 'itemDeletionConfirmation' }),
        content: intl.formatMessage({ id: 'irreversibleOperation' })
      });
    };

    actions.push({
      key: 'remove-action-key',
      labelKey: 'editableCollectionWithCategories.delete',
      clickEvent: onDeleteClicked,
      Icon: DeleteIcon
    });
  }

  return (
    <ListItem className={classNames(classes.item)} ContainerComponent="div">
      <ListItemIcon>
        <Chip label={chipNumber} />
      </ListItemIcon>
      <ListItemText
        primary={primaryText}
        className={classes.itemText}
        classes={{ root: classes.itemTextRoot, primary: classes.itemTextTypography }}
      />
      <ListItemSecondaryAction>
        <MoreActions actions={actions} />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

FavoriteItemWithActionMenu.defaultProps = {
  onItemEdit: undefined,
  onItemRemove: undefined,
  intl: undefined
};

FavoriteItemWithActionMenu.propTypes = {
  primaryText: PropTypes.node.isRequired,
  chipNumber: PropTypes.node.isRequired,
  onItemRemove: PropTypes.func,
  onItemEdit: PropTypes.func,
  intl: PropTypes.object
};

export default compose(injectIntl, withStyles(styles))(FavoriteItemWithActionMenu);
