import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1)
  },
  container: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0.5) + 2,
    paddingBottom: 0
  },
  isSelected: {
    backgroundColor: theme.palette.grey[400]
  }
});

class ActivitySummaryWrapper extends React.PureComponent {
  componentDidUpdate() {
    if (!this.props.isSelected) return;

    document.getElementById(this.props.activity.id).scrollIntoView();
  }

  render() {
    const { activity, children, classes, isSelected } = this.props;
    return (
      <div className={classes.wrapper}>
        <Paper id={activity.id} className={classNames(classes.container, { [classes.isSelected]: isSelected })}>
          {children}
        </Paper>
      </div>
    );
  }
}

ActivitySummaryWrapper.propTypes = {
  activity: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isSelected: PropTypes.bool
};

ActivitySummaryWrapper.defaultProps = {
  isSelected: false
};

export default withStyles(styles)(ActivitySummaryWrapper);
