/* eslint-disable no-shadow, no-unused-vars */
enum UserAccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DEMO = 'demo',
  INTERNAL = 'internal',
  PAUSED = 'paused'
}

export default UserAccountStatus;
