import { Button, Fab, Grid, List, ListItem } from '@material-ui/core';
import React, { useMemo } from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import { createStructuredSelector } from 'reselect';
import DoneIcon from '@material-ui/icons/Done';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { selectUserIdInContext } from 'app/containers/User/selectors';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import useTranslate from 'application/hooks/use-translate';
import Act from '../../../shared/domain/activity/act/model/Act';
import { ACT_FORM_NAME } from '../../containers/ActForm/constants';
import Recommendation from '../../act/recommendations/types';
import getRecommendations from './getRecommendations';
import Alert from '../Alert/Alert';
import AlertSeverity from '../../ui/shared/severity/AlertSeverity';

interface OwnProps {
  act: Partial<Act>;
  nam?: string;
}

export interface ReduxStateProps {
  activitiesInContext: Act[];
  userId: string;
}

export interface ReduxDispatchProps {
  // eslint-disable-next-line no-unused-vars
  changeInActForm: (field, value) => void;
}

export interface Props extends OwnProps, ReduxStateProps, ReduxDispatchProps {}

interface RecommendationsContextActivity {
  id: string;
  date: Date;
  codes: Array<{
    code: string;
  }>;
}

interface ACTIVITIES_FOR_RECOMMENDATIONS {
  lastDayActivitiesResult: {
    activities: RecommendationsContextActivity[];
  };
  civilYearActivitiesResultCountForInjectableMedication?: {
    count: number;
  };
  last24MonthsActivitiesForChronicPainInitialConsultation?: {
    count: number;
  };
}

const FETCH_ACTIVITIES_FOR_RECOMMENDATIONS_QUERY = gql`
  query SearchActivitiesForRecommendations(
    $lastDayQuery: GetActivitiesInput!
    $civilYearQueryForInjectableMedicationOnNam: GetActivitiesInput!
    $last24MonthsActivitiesForChronicPainInitialConsultation: GetActivitiesInput!
    $hasNam: Boolean!
  ) {
    civilYearActivitiesResultCountForInjectableMedication: activitiesResult(
      query: $civilYearQueryForInjectableMedicationOnNam
      limit: null
    ) @include(if: $hasNam) {
      count
    }
    lastDayActivitiesResult: activitiesResult(query: $lastDayQuery, limit: null) {
      activities {
        id
        date
        codes {
          code
        }
      }
    }
    last24MonthsActivitiesForChronicPainInitialConsultation: activitiesResult(
      query: $last24MonthsActivitiesForChronicPainInitialConsultation
      limit: null
    ) @include(if: $hasNam) {
      count
    }
  }
`;

const injectableMedicationCode = '20176';
const chronicPainInitialConsultationCodes = ['9257', '9258'];

export const RecommendationBox: React.FunctionComponent<Props> = ({
  changeInActForm,
  act,
  activitiesInContext,
  userId,
  nam
}: Props): any => {
  const translate = useTranslate('act-form.recommendations');
  const { data } = useQuery<ACTIVITIES_FOR_RECOMMENDATIONS>(FETCH_ACTIVITIES_FOR_RECOMMENDATIONS_QUERY, {
    variables: {
      lastDayQuery: {
        startDate: moment(act.date).subtract(1, 'day'),
        endDate: moment(act.date),
        userIds: [userId],
        types: [ActivityType.ACT],
        statuses: [
          ActivityStatus.NEED_FIX,
          ActivityStatus.PAID,
          ActivityStatus.PROCESSING,
          ActivityStatus.SENT,
          ActivityStatus.WAITING
        ]
      },
      civilYearQueryForInjectableMedicationOnNam: {
        startDate: moment(act.date).startOf('year'),
        endDate: moment(act.date).endOf('year'),
        userIds: [userId],
        nams: [nam],
        codes: [injectableMedicationCode],
        excludedFirebaseIds: [act.id],
        types: [ActivityType.ACT],
        statuses: [
          ActivityStatus.NEED_FIX,
          ActivityStatus.PAID,
          ActivityStatus.PROCESSING,
          ActivityStatus.SENT,
          ActivityStatus.WAITING
        ]
      },
      last24MonthsActivitiesForChronicPainInitialConsultation: {
        startDate: moment(act.date).subtract(24, 'months'),
        endDate: moment(act.date),
        userIds: [userId],
        codes: chronicPainInitialConsultationCodes,
        excludedFirebaseIds: [act.id],
        types: [ActivityType.ACT],
        nams: [nam],
        placeNumbers: [act.place?.number],
        statuses: [
          ActivityStatus.NEED_FIX,
          ActivityStatus.PAID,
          ActivityStatus.PROCESSING,
          ActivityStatus.SENT,
          ActivityStatus.WAITING
        ]
      },
      hasNam: !!nam
    }
  });

  const intensiveCare900RecommendationActs = useMemo(
    () =>
      data?.lastDayActivitiesResult.activities ??
      activitiesInContext.filter(({ status }) => status !== ActivityStatus.CANCELED),
    [data?.lastDayActivitiesResult, activitiesInContext]
  );
  const numberOfActivitiesWithTreatmentCodeDuringCivilYear =
    data?.civilYearActivitiesResultCountForInjectableMedication?.count ?? 0;

  const numberOfChronicPainInitialConsultationActs =
    data?.last24MonthsActivitiesForChronicPainInitialConsultation?.count ?? 0;

  const recommendations = useMemo(
    () =>
      getRecommendations(
        act,
        intensiveCare900RecommendationActs,
        numberOfActivitiesWithTreatmentCodeDuringCivilYear,
        numberOfChronicPainInitialConsultationActs
      ),
    [
      act,
      intensiveCare900RecommendationActs,
      numberOfActivitiesWithTreatmentCodeDuringCivilYear,
      numberOfChronicPainInitialConsultationActs
    ]
  );

  function applyRecommendation(recommendation: Recommendation<unknown>, partialAct: Partial<Act> = act): Partial<Act> {
    const { fieldName, newValue } = recommendation.apply(partialAct);

    changeInActForm(fieldName, newValue);

    return {
      ...partialAct,
      [fieldName]: newValue
    };
  }

  function applyRecommendations(recs: Recommendation<unknown>[]): void {
    let actFormDraft = cloneDeep(act);

    for (const rec of recs) {
      actFormDraft = applyRecommendation(rec, actFormDraft);
    }
  }

  if (recommendations.length === 0) {
    return '';
  }

  return (
    <Alert severity={AlertSeverity.RECOMMENDATION} showIcon title={translate('recommendations')}>
      <Grid container justify="center" spacing={5}>
        <Grid item container spacing={2}>
          {recommendations.map((recommendation, index) => (
            <Grid item container alignItems="center" justify="space-between" wrap="nowrap">
              <Grid item>
                {recommendation.message}

                {recommendation.details && recommendation.details.length > 0 && (
                  <List>
                    {recommendation.details.map((detail) => (
                      <ListItem>
                        <small>- {detail}</small>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>

              <Grid item>
                <Fab
                  onClick={() => applyRecommendation(recommendation)}
                  id={`apply-recommendation-${index}`}
                  color="primary"
                  size="small"
                >
                  <DoneIcon />
                </Fab>
              </Grid>
            </Grid>
          ))}
        </Grid>

        {recommendations.length > 1 && (
          <Grid item>
            <Button
              onClick={() => applyRecommendations(recommendations)}
              id="apply-all-recommendations"
              variant="contained"
              color="primary"
            >
              {translate('apply-all-recommendations')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Alert>
  );
};

export const mapStateToProps = createStructuredSelector({
  activitiesInContext: selectActivitiesInContext(),
  userId: selectUserIdInContext()
});

export const mapDispatchToProps = {
  changeInActForm: (field, value) => change(ACT_FORM_NAME, field, value)
};

export default connect<ReduxStateProps, ReduxDispatchProps, OwnProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(RecommendationBox);
