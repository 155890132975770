const TRANSPORT_FEES_TYPES = {
  rentalCar: '1',
  gaz: '2',
  parking: '3',
  general: '4'
};

// Type de frais de transport : Type indiquant le
// genre de frais de transport facturé.
//
// Domaine de valeurs
// 1 : Frais de location de véhicule
// 2 : Frais d'essence
// 3 : Frais de stationnement
// 4 : Frais général

export default TRANSPORT_FEES_TYPES;
