export const SECONDS_IN_MINUTE = 60;

export class HourMinute {
  private _hour: number;

  private _minute: number;

  constructor(hour: number, minute: number) {
    this._hour = hour;
    this._minute = minute;
  }

  get hour(): number {
    return this._hour;
  }

  get minute(): number {
    return this._minute;
  }

  static fromDecimal(decimalHour: number): HourMinute {
    const hour = Math.floor(decimalHour);
    const minute = Math.round((decimalHour - hour) * SECONDS_IN_MINUTE);

    return new HourMinute(hour, minute);
  }

  toDecimal(): number {
    return this._hour + this._minute / SECONDS_IN_MINUTE;
  }
}

export default HourMinute;
