import moment from 'moment-timezone';
import { flatten, get } from 'lodash';
import {
  codeIsAnHalfDayDuringCovid,
  codeIsAReunionsOrReallocationDuringDay,
  codeIsCovidRelated,
  codeIsIncreasableDuringNight,
  codeIsRelatedToChronicLungDiseaseRehabilitation,
  codeIsRelatedToCovid,
  isClinicalServicesCode,
  isEvaqCode,
  isEvaqManagementCode,
  isEvaqOvertimeCode,
  isLaboratoryServicesCode,
  isSSSCode,
  isSSSPresidentCode,
  isMedicoAdministrativeActivitiesCode
} from '../periods/lumpSum/partialRate/partialRateUtils';
import { isMultipleDays } from './computeExpectedAmountUtil';
import TimeSlotCalculatorFactory from '../periods/lumpSum/timeSlot/timeSlotCalculators/TimeSlotCalculatorFactory';
import DayTimeSlotSplitter from '../periods/lumpSum/timeSlot/DayTimeSlotSplitter';
import { codeHasFullRatePrefix, codeHasHalfRatePrefix } from '../periods/lumpSum/codePrefixor/ratePrefixors';
import isDefined from '../utils/isDefined';

export const HOURLY_LUMPSUM_FULL_RATE_FLOAT = 211.0;
const HOURLY_LUMPSUM_FULL_RATE_RESERVED_FOR_CHRONIC_LUNG_DISEASE_REHABILITATION_CODES_FLOAT = 130.0;
const HOURLY_LUMPSUM_HALF_RATE_FLOAT = 105.5;
const HOURLY_LUMPSUM_FULL_RATE_RESERVED_FOR_CLINICAL_SERVICES_FLOAT = 130.0;
const HOURLY_LUMPSUM_FULL_RATE_RESERVED_FOR_LABORATORY_SERVICES_FLOAT = 130.0;

const HOURLY_LUMPSUM_MEDICO_ADMINISTRATIVE_ACTIVITIES_CODES_FLOAT = 106.0;

export const HOURLY_LUMPSUM_EVAQ_MANAGEMENT_CODE = 130.0;
export const HOURLY_LUMPSUM_EVAQ_CODE = 125.0;
export const HOURLY_LUMPSUM_EVAQ_OVERTIME_CODE = 119.08;

// Lumpsum during COVID-19 crisis
const HOURLY_LUMPSUM_DEFAULT_COVID_FLOAT = 211.0;
const HOURLY_LUMPSUM_DEFAULT_COVID_INCREASED_FLOAT = 253.2;
const HOURLY_LUMPSUM_REUNIONS_OR_REALLOCATION_DURING_DAY_FLOAT = 105.5;
const FLAT_LUMPSUM_HALF_DAY_FLOAT = 372.0;

// Lump sum SSS
const HOURLY_LUMPSUM_SSS = 211.0;
const HOURLY_LUMPSUM_SSS_PRESIDENT = 264.0;

// Lump sum CAPQ
const CAPQ_8H_CODES = ['078300', '078309'];
const CAPQ_12H_CODES = ['078124', '078129'];
const HOURLY_RATE_LUMPSUM_CAPQ = 130.0;
const FULL_FLAT_RATE_8H_PICKUP_LUMPSUM_CAPQ = 416.0;
const HALF_FLAT_RATE_8H_PICKUP_LUMPSUM_CAPQ = 208.0;
const FULL_FLAT_RATE_12H_PICKUP_LUMPSUM_CAPQ = 624.0;
const HALF_FLAT_RATE_12H_PICKUP_LUMPSUM_CAPQ = 312.0;

export const applyAnnexe19ForNonCovidRelatedCode = (lumpSum, regularRateAmount) => {
  if (codeIsCovidRelated(lumpSum.code)) {
    return regularRateAmount.toFixed(2);
  }

  return applyAnnexe19(lumpSum, regularRateAmount).toFixed(2);
};

const computeExpectedAmountForLumpSum = (lumpSum) => {
  let regularRateAmount;
  if (isMultipleDays(lumpSum)) {
    regularRateAmount = lumpSum.days.reduce(
      (accumulator, day) => accumulator + computeExpectedAmountForSingleDayLumpSum({ ...day, place: lumpSum.place }),
      0
    );
  } else {
    regularRateAmount = computeExpectedAmountForSingleDayLumpSum(lumpSum);
  }
  return applyAnnexe19ForNonCovidRelatedCode(lumpSum, regularRateAmount);
};

const applyAnnexe19 = (lumpSum, regularRateAmount) => {
  const annexe19Rate = get(lumpSum, 'place.annexe19', 1);
  return regularRateAmount * annexe19Rate;
};

export const getDetailsOfLumpSumAccordingToCode = (lumpSum) => {
  if (CAPQ_8H_CODES.includes(lumpSum.code)) return splitIntoBlocks(8, lumpSum);
  if (CAPQ_12H_CODES.includes(lumpSum.code)) return splitIntoBlocks(12, lumpSum);

  return getDetailsOfLumpSum(lumpSum);
};

export const getDetailsOfLumpSum = (lumpSum) => {
  const dayTimeSlotSplitter = new DayTimeSlotSplitter(TimeSlotCalculatorFactory.create(lumpSum.code));

  return dayTimeSlotSplitter.split(lumpSum).reduce(
    (accumulator, { date, details }) => [
      ...accumulator,
      ...details.map((detail) => ({
        ...detail,
        date,
        amount: computeAmountForCode(detail)
      }))
    ],
    []
  );
};

export const computeExpectedAmountForSingleDayLumpSum = (lumpSum) => {
  if (isDefined(lumpSum.manualCorrection)) {
    return Number.parseFloat(lumpSum.manualCorrection.replace(/\s/g, ''));
  }

  if ((!lumpSum.code || !lumpSum.start || !lumpSum.end) && !lumpSum.hoursWorked) {
    return 0;
  }

  let lumpSumLines;

  if (CAPQ_8H_CODES.includes(lumpSum.code)) {
    lumpSumLines = splitIntoBlocks(8, lumpSum);
  } else if (CAPQ_12H_CODES.includes(lumpSum.code)) {
    lumpSumLines = splitIntoBlocks(12, lumpSum);
  } else {
    lumpSumLines = getDetailsOfLumpSum(lumpSum);
  }

  return lumpSumLines.reduce(addUpLumpSumDetailsAmount, 0);
};

export const addUpLumpSumDetailsAmount = (total, current) => total + computeAmountForCode(current);

export const computeAmountForCode = (current) => {
  if (current.code === CAPQ_8H_CODES[0]) {
    return FULL_FLAT_RATE_8H_PICKUP_LUMPSUM_CAPQ;
  }

  if (current.code === CAPQ_8H_CODES[1]) {
    return HALF_FLAT_RATE_8H_PICKUP_LUMPSUM_CAPQ;
  }

  if (current.code === CAPQ_12H_CODES[0]) {
    return FULL_FLAT_RATE_12H_PICKUP_LUMPSUM_CAPQ;
  }

  if (current.code === CAPQ_12H_CODES[1]) {
    return HALF_FLAT_RATE_12H_PICKUP_LUMPSUM_CAPQ;
  }

  if (codeIsAnHalfDayDuringCovid(current.code)) {
    return FLAT_LUMPSUM_HALF_DAY_FLOAT;
  }

  return getPerHourAmountForCode(current.code) * convertRamqHoursWorkedToFloatHours(current.hoursWorked);
};

const getPerHourAmountForCode = (code) => {
  if (['078122', '078123'].includes(code)) {
    return HOURLY_RATE_LUMPSUM_CAPQ;
  }

  if (isClinicalServicesCode(code)) {
    return HOURLY_LUMPSUM_FULL_RATE_RESERVED_FOR_CLINICAL_SERVICES_FLOAT;
  }

  if (isLaboratoryServicesCode(code)) {
    return HOURLY_LUMPSUM_FULL_RATE_RESERVED_FOR_LABORATORY_SERVICES_FLOAT;
  }

  if (isMedicoAdministrativeActivitiesCode(code)) {
    return HOURLY_LUMPSUM_MEDICO_ADMINISTRATIVE_ACTIVITIES_CODES_FLOAT;
  }

  if (codeIsIncreasableDuringNight(code)) {
    return HOURLY_LUMPSUM_DEFAULT_COVID_INCREASED_FLOAT;
  }

  if (codeIsAReunionsOrReallocationDuringDay(code)) {
    return HOURLY_LUMPSUM_REUNIONS_OR_REALLOCATION_DURING_DAY_FLOAT;
  }

  if (codeIsRelatedToCovid(code)) {
    return HOURLY_LUMPSUM_DEFAULT_COVID_FLOAT;
  }

  if (codeHasFullRatePrefix(code)) {
    return HOURLY_LUMPSUM_FULL_RATE_FLOAT;
  }

  if (codeHasHalfRatePrefix(code)) {
    return HOURLY_LUMPSUM_HALF_RATE_FLOAT;
  }

  if (codeIsRelatedToChronicLungDiseaseRehabilitation(code)) {
    return HOURLY_LUMPSUM_FULL_RATE_RESERVED_FOR_CHRONIC_LUNG_DISEASE_REHABILITATION_CODES_FLOAT;
  }

  if (isEvaqManagementCode(code)) {
    return HOURLY_LUMPSUM_EVAQ_MANAGEMENT_CODE;
  }

  if (isEvaqCode(code)) {
    return HOURLY_LUMPSUM_EVAQ_CODE;
  }

  if (isEvaqOvertimeCode(code)) {
    return HOURLY_LUMPSUM_EVAQ_OVERTIME_CODE;
  }

  if (isSSSCode(code)) {
    return HOURLY_LUMPSUM_SSS;
  }

  if (isSSSPresidentCode(code)) {
    return HOURLY_LUMPSUM_SSS_PRESIDENT;
  }

  return HOURLY_LUMPSUM_HALF_RATE_FLOAT;
};

export const convertRamqHoursWorkedToFloatHours = (hoursWorked) => parseFloat(addDecimalToString(hoursWorked));

const addDecimalToString = (string) =>
  `${string.substring(0, string.length - 2)}.${string.substring(string.length - 2)}`;

const splitIntoBlocks = (blockDuration, lumpSum) => {
  const startTime = moment(lumpSum.start);
  const endTime = moment(lumpSum.end);

  if (endTime.isSameOrBefore(startTime)) {
    endTime.add(1, 'day');
  }

  const numberOfLines = Math.ceil(endTime.diff(startTime, 'hours') / blockDuration);

  return new Array(numberOfLines).fill({
    code: lumpSum.code,
    hoursWorked: blockDuration.toString().padEnd(3, '0').padStart(4, '0'),
    amount: computeAmountForCode({ code: lumpSum.code, hoursWorked: blockDuration }),
    date: lumpSum.date
  });
};

export default computeExpectedAmountForLumpSum;
