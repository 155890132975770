import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import { dateIsDuringPlaceHoliday } from '../../../../shared/utils/dateTime/dateValidations';
import DayUnitFactory from './DayUnit/DayUnitFactory';

const styles = (theme) => ({
  dayContainer: {
    'position': 'relative',
    'display': 'flex',
    'flexDirection': 'column',
    'borderBottom': `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      border: 'none'
    }
  },
  disabledOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: 2
  }
});

function PeriodContent({ fields: reduxFormFields, place, activityType, onChange, readOnly, classes }) {
  return (
    <>
      {reduxFormFields.map((fieldName, fieldIndex, fields) => {
        const dayDate = fields.get(fieldIndex).date;
        const disabled = activityType !== ActivityType.LUMP_SUM && dateIsDuringPlaceHoliday(dayDate, place);

        const DayUnitComponent = DayUnitFactory.create(activityType, dayDate);

        return (
          <div key={fieldName} className={classes.dayContainer}>
            <DayUnitComponent
              disabled={disabled}
              fieldName={fieldName}
              fieldIndex={fieldIndex}
              onChange={() => onChange(`${fieldName}`)}
              fields={fields}
              place={place}
              activityType={activityType}
            />
            {(disabled || readOnly) && <span className={classes.disabledOverlay} />}
          </div>
        );
      })}
    </>
  );
}

PeriodContent.defaultProps = {
  readOnly: false,
  onChange: () => {}
};

PeriodContent.propTypes = {
  fields: PropTypes.object.isRequired,
  place: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  activityType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};

export default withStyles(styles)(PeriodContent);
