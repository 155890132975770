import { actFormFields } from 'app/containers/ActForm/constants';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import moment from 'moment';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import Act from 'shared/domain/activity/act/model/Act';
import { URGENT } from 'shared/ramq/contextElements/globalContextElements';

const invalidCodes = [
  '148',
  '149',
  '276',
  '277',
  '301',
  '302',
  '307',
  '336',
  '336',
  '337',
  '337',
  '343',
  '350',
  '432',
  '489',
  '530',
  '573',
  '574',
  '582',
  '585',
  '597',
  '611',
  '615',
  '616',
  '639',
  '647',
  '654',
  '751',
  '777',
  '901',
  '901',
  '902',
  '903',
  '993',
  '994',
  '8458',
  '9304',
  '9304',
  '9305',
  '9306',
  '9334',
  '9335',
  '9418'
];

const NO_ERRORS = {};

const dailyUnitCarePackageValidation = (act: Act) => {
  const momentStart = moment(act.start);
  const momentDate = moment(act.date);

  const GRANULARITY = undefined;

  if (
    !momentStart.isBetween(momentDate.clone().hour(7), momentDate.clone().hour(19), GRANULARITY, '[)') ||
    (act.contextElements ?? []).includes(URGENT)
  ) {
    return NO_ERRORS;
  }

  const activitiesInContext = selectActivitiesInContext()(getStore().getState());
  const otherActsInDay = activitiesInContext.filter(
    (otherAct) =>
      otherAct.id !== act.id &&
      otherAct.date === momentDate.valueOf() &&
      otherAct.type === ActivityType.ACT &&
      otherAct.status !== ActivityStatus.CANCELED
  );

  const otherActInDay = otherActsInDay.find((otherAct) => otherAct.codes.some((code) => code.code === '9295'));

  if (otherActInDay) {
    const codeAlreadyIncludedInUnitCarePackage = act.codes.find((code) => invalidCodes.includes(code.code));

    if (codeAlreadyIncludedInUnitCarePackage) {
      return {
        [actFormFields.codes]: {
          _error: `Le code ${
            codeAlreadyIncludedInUnitCarePackage.code
          } est déjà inclus dans la prise en charge de l'unité (code 9295 présent dans la journée du ${momentDate.format(
            'YYYY-MM-DD'
          )})`
        }
      };
    }
  }

  return NO_ERRORS;
};

export default dailyUnitCarePackageValidation;
