import moment from 'moment-timezone';
import isDefined from '../../../../../shared/utils/isDefined';

export const codeHasEffectiveEndDate = (code) => isDefined(code.effectiveEndDate);
export const codeIsExpired = (relativeDate, code) => moment(code.effectiveEndDate).isBefore(relativeDate);
export const expirationValidation = ({ date: actDate, codes }) => {
  const codesInAct = codes || [];
  const expiredCodes = codesInAct.filter(codeHasEffectiveEndDate).filter(codeIsExpired.bind(this, actDate));

  if (expiredCodes.length > 0) {
    const { code, effectiveEndDate } = expiredCodes[0];
    const codeFormattedEffectiveEndDate = moment(effectiveEndDate).format('D MMMM YYYY');

    return {
      codes: { _error: `Le code ${code} est expiré depuis le ${codeFormattedEffectiveEndDate}` }
    };
  }

  return {};
};

export default expirationValidation;
