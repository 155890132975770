import { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { firestore } from 'server/Firebase';

export interface UserAccountant {
  id: string;
  fullName: string;
  email: string;
}

export const useFirestoreUserAccountants = (userId: string) => {
  const [accountants, setAccountants] = useState<UserAccountant[] | null>(null);

  useEffect(() => {
    if (!userId) return;

    const unsubscribe = (firestore as firebase.firestore.Firestore)
      .collection('users')
      .doc(userId)
      .collection('accountants')
      .onSnapshot((snapshot) => {
        const accountants = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        })) as UserAccountant[];

        setAccountants(accountants);
      });

    return () => unsubscribe();
  }, [userId]);

  const addAccountant = useCallback(
    async (accountant: Omit<UserAccountant, 'id'>) => {
      if (!userId) return;

      const newDocRef = await (firestore as firebase.firestore.Firestore)
        .collection('users')
        .doc(userId)
        .collection('accountants')
        .doc();

      await newDocRef.set({
        id: newDocRef.id,
        ...accountant
      });
    },
    [userId]
  );

  const deleteAccountant = useCallback(
    async (accountantId: string) => {
      if (!userId) return;

      await (firestore as firebase.firestore.Firestore)
        .collection('users')
        .doc(userId)
        .collection('accountants')
        .doc(accountantId)
        .delete();
    },
    [userId]
  );

  return { accountants, addAccountant, deleteAccountant };
};

export default useFirestoreUserAccountants;
