import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { newUnixDateAtStartOfDay } from '../../../shared/utils/dateTime/dateTimeUtils';
import { DATE_ROUTE_PARAM } from '../Navigation/constants';
import DayRoute from './DayRoute/DayRoute';

export function ActivitiesRoute() {
  return (
    <Routes>
      <Route path={`${DATE_ROUTE_PARAM}/*`} element={<DayRoute />} />
      <Route index element={<Navigate to={newUnixDateAtStartOfDay().toString()} />} />
    </Routes>
  );
}

export default ActivitiesRoute;
