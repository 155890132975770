import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import React from 'react';
import { formatUnixToDate } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import {
  amountFormat,
  ramqStringAmountToFloat
} from '../../../IncomeStatement/IncomeStatementReport/IncomeStatementReportUtils';
import StatementSectionContainer from './StatementSectionContainer';

export function StatementLine(line1, line2) {
  return (
    <Grid container component="span">
      <Grid component="span" item xs={6} sm={6}>
        {line1}
      </Grid>
      <Grid component="span" item xs={6} sm={6}>
        {line2}
      </Grid>
    </Grid>
  );
}

export function StatementLineOnAboveZeroAmount(label, ramqStringAmount) {
  return ramqStringAmountToFloat(ramqStringAmount) !== 0 ? StatementLine(label, amountFormat(ramqStringAmount)) : null;
}

export function StatementDoctorInfo({ fullName, generalInformation, isPool }) {
  return (
    <StatementSectionContainer withDivider>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={createPrimaryLine(generalInformation, fullName, isPool)}
          secondaryLines={createSecondaryLines(generalInformation, isPool)}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Date émission:', formatUnixToDate(generalInformation.headerInfo.date))}
          secondaryLines={[
            StatementLine('Numéro paiement:', generalInformation.headerInfo.paymentNumber),
            StatementLine('Date coupure ruban:', formatUnixToDate(generalInformation.headerInfo.cutOffDateNumerized)),
            StatementLine('Date accumulation:', formatUnixToDate(generalInformation.firstLineInfo.dateAccumulation))
          ]}
        />
      </Grid>
    </StatementSectionContainer>
  );
}

function createSecondaryLines(generalInformation, isPool) {
  const secondaryLines = [];

  if (!isPool) {
    secondaryLines.push(StatementLine('Numéro license:', generalInformation.professionalIdInfo.practiceNumber));
  }

  if (generalInformation.professionalIdInfo.groupClass === '0') {
    secondaryLines.push(StatementLine('Numéro groupe:', 'finance personnelle'));
  } else {
    secondaryLines.push(StatementLine('Numéro groupe:', generalInformation.professionalIdInfo.groupNumber));
  }

  secondaryLines.push(StatementLine('Numéro agence:', generalInformation.headerInfo.agencyNumber));
  return secondaryLines;
}

function createPrimaryLine(generalInformation, fullName, isPool) {
  return isPool ? generalInformation.professionalIdInfo.groupName : fullName;
}

StatementDoctorInfo.defaultProps = {
  isPool: false
};

StatementDoctorInfo.propTypes = {
  fullName: PropTypes.string.isRequired,
  generalInformation: PropTypes.object.isRequired,
  isPool: PropTypes.bool
};

export default StatementDoctorInfo;
