import React from 'react';
import Chip from '@material-ui/core/Chip';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Card, CardContent } from '@material-ui/core';
import ContextElement from '../../../../favorite/contextElement/domain/ContextElement';

export const styles = ({ spacing }) =>
  ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    contextElementsContainerCard: {
      width: '100%',
      borderStyle: 'dashed',
      borderWidth: 'thin',
      borderColor: 'gray',
      boxShadow: 'inherit'
    },
    contextElementsContainerContent: {
      paddingTop: `${spacing.unit}px`,
      paddingBottom: `${spacing.unit}px !important`
    },
    contextElementsChip: {
      margin: `${spacing.unit / 2}px`
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  contextElements: ContextElement[];
  // eslint-disable-next-line no-unused-vars
  onDelete: (index: number) => void;
}

export const SelectedContextElements: React.FunctionComponent<Props> = ({
  classes,
  contextElements,
  onDelete
}: Props) => (
  <div className={classes.root}>
    <Card className={classes.contextElementsContainerCard}>
      <CardContent className={classes.contextElementsContainerContent}>
        {contextElements.map(({ code, description }, index) => (
          <Chip
            className={classes.contextElementsChip}
            onDelete={() => onDelete(index)}
            label={`(${code}) ${description}`}
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${code}-${index}`}
          />
        ))}
      </CardContent>
    </Card>
  </div>
);

export default withStyles(styles)(SelectedContextElements);
