import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import CategorizableCollection from '../../domain/CategorizableCollection';
import DroppableContainer from '../DroppableContainer/DroppableContainer';

class EditableCollectionWithCategories extends React.Component {
  onDragEnd = (result) => {
    const { draggableId, source, destination } = result;

    this.setState({ sourceDroppableId: null });

    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourcePath = source.droppableId;
    const destinationPath = destination.droppableId;

    // eslint-disable-next-line react/no-access-state-in-setstate
    const categorizableCollection = new CategorizableCollection(this.props.collectionWithCategories);

    this.props.onChange(categorizableCollection.moveItem(sourcePath, draggableId, destinationPath, destination.index));
  };

  onDragStart = (result) => {
    this.setState({ sourceDroppableId: result.source.droppableId });
  };

  isDropDisabled = (id) => {
    if (!this.props.blockDropInSelf) return false;

    if (this.state) return id === this.state.sourceDroppableId;

    return false;
  };

  render() {
    const { collectionWithCategories, itemComponent, containerWrapperComponent } = this.props;
    const categorizableCollection = new CategorizableCollection(collectionWithCategories);

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <DroppableContainer
          id="itemsWithoutCategory"
          category={collectionWithCategories.itemsWithoutCategory}
          items={collectionWithCategories.items}
          itemComponentBuilder={itemComponent}
          isDropDisabled={this.isDropDisabled('itemsWithoutCategory')}
          containerWrapperComponentBuilder={({ children }) => <div>{children}</div>}
        />
        {categorizableCollection.getCategoryPathsFlatMap().map((categoryPath) => {
          const currentCategory = categorizableCollection.getFromPath(categoryPath.key);

          return (
            <DroppableContainer
              id={categoryPath.key}
              key={categoryPath.key}
              label={currentCategory.category}
              level={categoryPath.level}
              category={currentCategory}
              items={collectionWithCategories.items}
              itemComponentBuilder={itemComponent}
              isDropDisabled={this.isDropDisabled(categoryPath.key)}
              containerWrapperComponentBuilder={containerWrapperComponent}
            />
          );
        })}
      </DragDropContext>
    );
  }
}

EditableCollectionWithCategories.propTypes = {
  collectionWithCategories: PropTypes.object.isRequired,
  itemComponent: PropTypes.func.isRequired,
  containerWrapperComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  blockDropInSelf: PropTypes.bool
};

export default EditableCollectionWithCategories;
