import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { getActCodesDataWithSpecialtyOverrides } from '../../../../shared/data/actCodesData';
import CodeSelector from '../../CodeSelector/CodeSelector';
import ChipList from '../../List/ChipList/ChipList';
import { deleteValue, pushNewValue } from '../../utils/reduxFormArrayField';

export const styles = () => ({
  chipSelector: {
    flex: 1,
    marginRight: 20,
    position: 'relative'
  }
});

export function CodesSelectorField({ input, classes }) {
  return (
    <>
      <CodeSelector
        className={classes.chipSelector}
        codesData={getActCodesDataWithSpecialtyOverrides()}
        onHandleSelect={(value) => pushNewValue(input, value[0])}
      />
      <ChipList
        list={input.value ? input.value : []}
        onDelete={(map, index) => deleteValue(input, index)}
        objectPropertyToBeDisplayed="code"
        objectPropertyToUseAsKey="code"
      />
    </>
  );
}

CodesSelectorField.propTypes = {
  input: PropTypes.object.isRequired
};

export default withStyles(styles)(CodesSelectorField);
