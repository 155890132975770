import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FavoritesActCodesSelector from '../FavoritesActCodesSelector/FavoritesActCodesSelector';
import { selectCodesPreferencesFromUser } from '../../../../../containers/User/selectors';

export function EditableOnlyFavoritesActCodesTab({
  favoritesActCodes,
  selectedCodes,
  onConfirm,
  onCancel,
  onClickItem
}) {
  return (
    <FavoritesActCodesSelector
      favoritesActCodes={favoritesActCodes}
      onClickItem={onClickItem}
      selectedCodes={selectedCodes}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
}

EditableOnlyFavoritesActCodesTab.propTypes = {
  favoritesActCodes: PropTypes.array.isRequired,
  selectedCodes: PropTypes.array.isRequired,
  onClickItem: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export const mapStateToProps = createStructuredSelector({
  favoritesActCodes: selectCodesPreferencesFromUser()
});

export default connect(mapStateToProps)(EditableOnlyFavoritesActCodesTab);
