import Dropdown from 'application/components/dropdown';
import Icon from 'application/components/icon';
import useTranslate from 'application/hooks/use-translate';
import UsersTypeahead from 'application/components/users-typeahead';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import Period from 'shared/periods/common/Period';
import calculateORFromPeriod from 'shared/periods/common/calculateORFromActDate';
import { getBillingPeriodFromDate } from 'shared/periods/periods';
import { ConfiguratorProps, RendererProps } from 'application/pages/administration/statistics';
import { UserRole } from 'application/types/users';
import { useQuery } from '@tanstack/react-query';
import StatisticsService from 'application/services/statistics-service';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';

interface Params {
  selectedOr?: string;
  selectedUser?: User;
}

interface User {
  id: string;
  fullName: string;
  role: UserRole;
  groups: Array<{
    id: string;
  }>;
}

interface BillingPeriodFromDate {
  act: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
}

const generatePeriodsFromDate = (date: Date, count: number) => {
  let currentDate = new Date(date.valueOf());
  const periodsWithOR: Array<{ period: Period; or: number }> = [];

  for (let i = 0; i < count; i++) {
    const billingPeriod = getBillingPeriodFromDate(currentDate) as BillingPeriodFromDate;
    const period = Period.fromMoment(billingPeriod.act.startDate, billingPeriod.act.endDate);
    const or = calculateORFromPeriod(period);

    const previousDate = moment(currentDate).subtract(2, 'weeks');
    currentDate = new Date(previousDate.valueOf());
    periodsWithOR.push({ period, or: or ?? 0 });
  }

  return periodsWithOR;
};

const colorFromStatus = (status: ActivityStatus): string => {
  switch (status) {
    case ActivityStatus.CANCELED:
      return 'ban';
    case ActivityStatus.SENT:
      return 'info';
    case ActivityStatus.WAITING:
      return 'neutral';
    case ActivityStatus.NEED_FIX:
      return 'error';
    case ActivityStatus.PROCESSING:
      return 'warning';
    case ActivityStatus.PAID:
      return 'success';
    default:
      throw new Error(`Unknown status ${status}`);
  }
};

const iconFromStatus = (status: ActivityStatus): string => {
  switch (status) {
    case ActivityStatus.CANCELED:
      return 'ban';
    case ActivityStatus.SENT:
      return 'circle-check';
    case ActivityStatus.WAITING:
      return 'circle-dashed';
    case ActivityStatus.NEED_FIX:
      return 'circle-exclamation';
    case ActivityStatus.PROCESSING:
      return 'clock';
    case ActivityStatus.PAID:
      return 'circle-dollar';
    default:
      throw new Error(`Unknown status ${status}`);
  }
};

const activeRalphStatuses = [ActivityStatus.SENT, ActivityStatus.WAITING, ActivityStatus.NEED_FIX];
const inactiveRalphStatuses = [ActivityStatus.PROCESSING, ActivityStatus.PAID];

const Configurator = ({ params, onChange }: ConfiguratorProps<Params>) => {
  const translate = useTranslate('pages.administration.stats');
  const translateReports = useTranslate('reports');
  const periodsWithOr = useMemo(() => generatePeriodsFromDate(new Date(), 6), []);

  useEffect(() => {
    if (params.selectedOr) return;

    onChange({
      selectedOr: periodsWithOr[0].or.toString()
    });
  }, [onChange, params.selectedOr, periodsWithOr]);

  if (!params.selectedOr) return null;

  return (
    <div className="flex gap-2">
      <Dropdown
        placeholder={translateReports('billing-period')}
        onChange={(selectedOr) => onChange({ selectedOr, selectedUser: params.selectedUser })}
        value={params.selectedOr}
        options={periodsWithOr.map((periodWithOr) => ({
          label: translateReports('billing-period-range', {
            start: moment(periodWithOr.period.getStart()).format('DD MMM. YYYY'),
            end: moment(periodWithOr.period.getEnd()).format('DD MMM. YYYY'),
            paymentNumber: `OR ${periodWithOr.or}`
          }),
          value: periodWithOr.or.toString()
        }))}
      />
      <UsersTypeahead
        className="min-w-[250px] flex-grow"
        placeholder={translate('users-search')}
        userRoles={[UserRole.DEVELOPER, UserRole.ADMINISTRATOR, UserRole.AGENT, UserRole.AUDITOR, UserRole.DOCTOR]}
        onUserSelect={(selectedUser) => onChange({ selectedUser, selectedOr: params.selectedOr })}
        anchorRight
      />
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => onChange({ selectedUser: undefined, selectedOr: params.selectedOr })}
        disabled={!params.selectedUser}
      >
        {translate('reset')}
      </button>
    </div>
  );
};

const Renderer = ({ params }: RendererProps<Params>) => {
  const translate = useTranslate('pages.administration.stats');
  const periodsWithOr = useMemo(() => generatePeriodsFromDate(new Date(), 6), []);

  const [start, end] = useMemo(() => {
    const period = (
      periodsWithOr.find((periodWithOr) => periodWithOr.or.toString() === params.selectedOr) ?? periodsWithOr[0]
    )?.period;

    return [period.getStart().toDate(), period.getEnd().toDate()];
  }, [params.selectedOr, periodsWithOr]);

  const selectedUserIds = useMemo(() => {
    if (!params.selectedUser || params.selectedUser.role !== UserRole.DOCTOR) return undefined;
    return [params.selectedUser.id];
  }, [params.selectedUser]);

  const selectedManagerIds = useMemo(() => {
    if (!params.selectedUser || params.selectedUser.role === UserRole.DOCTOR) return undefined;
    return [params.selectedUser.id];
  }, [params.selectedUser]);

  const { data } = useQuery({
    queryKey: ['activities-by-status-statistics', start, end, selectedUserIds, selectedManagerIds],
    queryFn: () =>
      StatisticsService.getActivitiesStatus(
        start,
        end,
        [...activeRalphStatuses, ...inactiveRalphStatuses],
        selectedUserIds,
        selectedManagerIds
      )
  });

  const totalActivities = useMemo(() => {
    if (!data) return 0;
    return data.reduce((acc, row) => acc + row.count, 0);
  }, [data]);

  const [activeStatuses, inactiveStatuses] = useMemo(() => {
    if (!data) return [[], []];
    return [
      data.filter((row) => activeRalphStatuses.includes(row.status)),
      data.filter((row) => inactiveRalphStatuses.includes(row.status))
    ];
  }, [data]);

  const getPercentage = (count: number) => {
    if (!totalActivities) return 0;
    return Math.round((count / totalActivities) * 10000) / 100;
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between pb-2">
        <div>{params.selectedUser?.fullName || translate('all-users')}</div>
      </div>
      <div className="stats stats-vertical shadow">
        <div className="stats shadow">
          {activeStatuses.map((row) => (
            <div className="stat">
              <div className="stat-figure text-secondary">
                <Icon icon={iconFromStatus(row.status)} className={`fa-2xl text-${colorFromStatus(row.status)}`} />
              </div>
              <div className="stat-title">{translate(row.status)}</div>
              <div className="stat-value">{row.count}</div>
              <div className="stat-desc">{`${getPercentage(row.count)}%`}</div>
            </div>
          ))}
        </div>
        <div className="stats shadow">
          {inactiveStatuses.map((row) => (
            <div className="stat">
              <div className="stat-figure text-secondary">
                <Icon icon={iconFromStatus(row.status)} className={`fa-2xl text-${colorFromStatus(row.status)}`} />
              </div>
              <div className="stat-title">{translate(row.status)}</div>
              <div className="stat-value">{row.count}</div>
              <div className="stat-desc">{`${getPercentage(row.count)}%`}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default {
  Configurator,
  Renderer
};
