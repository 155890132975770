import Role from './Role';
import Permission from '../authorization/model/Permission';
import { UserId } from '../user/User';

export interface UserModel {
  uid: string;
  displayName?: string;
  email: string;
  role: Role;
  permissions: Permission[];
  pendingAuth?: boolean;
}

class AuthUserDomain {
  // eslint-disable-next-line no-unused-vars
  private constructor(private data: UserModel) {}

  static fromDto(data: UserModel): AuthUserDomain {
    return new AuthUserDomain(data);
  }

  isPending(): boolean {
    return !!this.data.pendingAuth;
  }

  toDto(): UserModel {
    return this.data;
  }

  can(permission: Permission): boolean {
    return this.data.permissions.includes(permission);
  }

  canSome(...permissions: Permission[]): boolean {
    return permissions.some(this.can, this);
  }

  get displayName(): string {
    return this.data?.displayName || '';
  }

  getId(): UserId {
    return this.data.uid;
  }

  getEmail(): string {
    return this.data.email;
  }
}

export default AuthUserDomain;
