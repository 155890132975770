import {
  CLOSE_SEARCH_ACTIVITIES,
  GET_ACTIVITY_BY_ID,
  GET_ACTIVITY_BY_ID_ERROR,
  GET_ACTIVITY_BY_ID_SUCCESS,
  OPEN_SEARCH_ACTIVITIES
} from './actions';

export const initialState = {
  searchingActivities: false,
  searchingSpecificActivity: false,
  selectedActivity: undefined,
  error: undefined
};

export default function activitiesSearchReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SEARCH_ACTIVITIES:
      return {
        ...state,
        searchingActivities: true
      };
    case CLOSE_SEARCH_ACTIVITIES:
      return {
        ...state,
        searchingActivities: false
      };
    case GET_ACTIVITY_BY_ID:
      return {
        ...state,
        selectedActivity: undefined,
        searchingSpecificActivity: true,
        error: undefined
      };
    case GET_ACTIVITY_BY_ID_SUCCESS:
      return {
        ...state,
        selectedActivity: action.activity,
        searchingSpecificActivity: false,
        error: undefined
      };
    case GET_ACTIVITY_BY_ID_ERROR:
      return {
        ...state,
        selectedActivity: undefined,
        searchingSpecificActivity: false,
        error: action.error.message
      };
    default:
      return state;
  }
}
