import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import React from 'react';
import { RAMQ_LOGS_ROUTE } from '../../../../containers/Navigation/constants';

const styles = () => ({
  ramqInfoIcon: {
    position: 'absolute',
    left: 0,
    zIndex: 2
  }
});

const showRamqInfo = (event, ramqLogId) => {
  event.stopPropagation(); // Prevent collapse from closing / opening
  window.open(`#${RAMQ_LOGS_ROUTE}/${ramqLogId}`);
};

function RamqInfoIcon({ ramqLogId, classes }) {
  return ramqLogId ? (
    <IconButton className={classes.ramqInfoIcon} onClick={(event) => showRamqInfo(event, ramqLogId)}>
      <InfoIcon />
    </IconButton>
  ) : null;
}

RamqInfoIcon.propTypes = {
  ramqLogId: PropTypes.string
};

RamqInfoIcon.defaultProps = {
  ramqLogId: undefined
};

export default withStyles(styles)(RamqInfoIcon);
