import PropTypes from 'prop-types';
import React from 'react';
import AdviceActivitiesCorrection from '../AdviceActivitiesCorrection/AdviceActivitiesCorrection';
import ADVICE_ACTIVITIES_CORRECTION from '../constants';
import EmptyAdvicesIndicator from './EmptyAdvicesIndicator/EmptyAdvicesIndicator';

const advicesComponentMapping = {
  [ADVICE_ACTIVITIES_CORRECTION]: AdviceActivitiesCorrection
};

function AssistantAdvices({ advices }) {
  if (advices.length === 0) {
    return <EmptyAdvicesIndicator />;
  }

  return advices.map((advice) => {
    const AdviceComponent = advicesComponentMapping[advice.type];
    return <AdviceComponent key={advice.id} advice={advice} />;
  });
}

AssistantAdvices.propTypes = {
  advices: PropTypes.array.isRequired
};

export default AssistantAdvices;
