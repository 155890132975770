import { get } from 'lodash';
import CODE_NATURE_TYPES from '../../../../shared/ramq/domainValues/natureTypes';
import { getFeatureConfig } from '../../../../userCustomization/featureToggle';
import { VALIDATE_R1_SURGERY_SUPPLEMENTS_CODE } from '../../../../userCustomization/featureNames';

export const excludeR1SurgerySupplements = (act) => {
  if (!getFeatureConfig(VALIDATE_R1_SURGERY_SUPPLEMENTS_CODE, false)) {
    return {};
  }

  const result = act.codes.filter(
    (code) =>
      code.natureType === CODE_NATURE_TYPES.chirurgie &&
      code.isSupplement &&
      // Added for backward compatibility. Codes in local devices didn't have roles property before
      // https://github.com/medyxa/medyx/pull/815/files#diff-2e8c4e01b0db870d8b2d6721f03d0a29
      // the "get" from lodash can be safely removed after all clients have updated to this version
      !get(code, 'roles', []).includes(2)
  );

  if (result.length >= 1) {
    return {
      codes: { _error: `Le code ${result[0].code} ne peut être facturé par votre spécialité. SVP valider et envoyer.` }
    };
  }

  return {};
};

export default excludeR1SurgerySupplements;
