import { Signatory } from './Signatory';

type Place = {
  id: string;
  number: string;
  name: string;
  type: Location;
  billByAct?: boolean;
  daysOff?: string[];
  annexe19?: number;
  signatory: Signatory;
  distanceKM?: number;
};

/* eslint-disable no-shadow, no-unused-vars */
export enum Location {
  PHYSICAL = 'physical',
  GEOGRAPHICAL = 'geographical'
}

export default Place;
