import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ON_GUARD_ACT_CODES from '../../../../../../shared/core/activity/repository/act/onGuardActCodes';
import Alert from '../../../../Alert/Alert';

function ActWithoutPreviousOnGuardActWarning({ startTime, currentCodes, previous24HoursActs }) {
  if (startTime && !doesActContainsSomeCode({ codes: currentCodes }, ON_GUARD_ACT_CODES)) {
    if (
      (isActStartAfter9Pm(startTime) && !isSomePreviousActOnTheSameDayIsOnGuardAct(startTime, previous24HoursActs)) ||
      (isActStartBefore7Am(startTime) && !isSomeActOnPreviousDayIsOnGuardAct(startTime, previous24HoursActs))
    ) {
      return (
        <Alert
          id="act-after-9pm-without-on-guard-act-warning-message"
          severity="warning"
          showIcon
          title="Attention"
          message={<FormattedMessage id="activity.doNotForgetToBillGuardOrTeaching" />}
        />
      );
    }
  }

  return null;
}

const isActStartAfter9Pm = (startTime) => {
  const startOfCurrentDay = moment(startTime).startOf('day');
  const ninePmTheSameDay = moment(startOfCurrentDay).add(21, 'hours');

  return moment(startTime).isSameOrAfter(ninePmTheSameDay);
};

const isSomePreviousActOnTheSameDayIsOnGuardAct = (startTime, previous24HoursActs) => {
  const startOfCurrentDay = moment(startTime).startOf('day');

  const previousActsBeginningOnTheSameDayWithOnGuardActCode = previous24HoursActs
    .filter((act) => moment(act.start).isSameOrAfter(startOfCurrentDay))
    .filter((act) => doesActContainsSomeCode(act, ON_GUARD_ACT_CODES));

  return previousActsBeginningOnTheSameDayWithOnGuardActCode.length > 0;
};

const isActStartBefore7Am = (startTime) => {
  const startOfCurrentDay = moment(startTime).startOf('day');
  const sevenAmTheSameDay = moment(startOfCurrentDay).add(7, 'hours');

  return moment(startTime).isBefore(sevenAmTheSameDay);
};

const isSomeActOnPreviousDayIsOnGuardAct = (startTime, previous24HoursActs) => {
  const startOfCurrentDay = moment(startTime).startOf('day');
  const startOfPreviousDay = moment(startOfCurrentDay).subtract(1, 'day');

  const previousActsBeginningOnTheSameDayWithOnGuardActCode = previous24HoursActs
    .filter((act) => moment(act.start).isSameOrAfter(startOfPreviousDay))
    .filter((act) => doesActContainsSomeCode(act, ON_GUARD_ACT_CODES));

  return previousActsBeginningOnTheSameDayWithOnGuardActCode.length > 0;
};

const doesActContainsSomeCode = (act, codes) => act.codes.some((actCode) => codes.includes(actCode.code));

ActWithoutPreviousOnGuardActWarning.propTypes = {
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentCodes: PropTypes.array.isRequired,
  previous24HoursActs: PropTypes.array.isRequired
};

ActWithoutPreviousOnGuardActWarning.defaultProps = {
  startTime: undefined
};

export default ActWithoutPreviousOnGuardActWarning;
