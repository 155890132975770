import React, { useEffect, useState } from 'react';
import FieldContainer from 'application/components/form-controls/field-container';
import PasswordInput from 'application/components/form-controls/password-input';
import useTranslate from 'application/hooks/use-translate';
import useValidatedForm from 'application/hooks/use-validated-form';
import { SectionProps } from 'application/pages/lobby/lobby.signup';
import Container from 'application/components/form-controls/container';

const Security = ({ userState, onUserChange, onValidate }: SectionProps) => {
  const translate = useTranslate('pages.lobby.signup.section-security');
  const { registerField, isValid } = useValidatedForm();
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => onValidate(isValid), [isValid, onValidate]);

  return (
    <>
      <Container className="py-0 sm:py-4">
        <div className="scroll-container-wrapper">
          <div className="scroll-container flex flex-col gap-3">
            <p>{translate('intro')}</p>
            <ol className="flex list-inside list-decimal flex-col gap-1">
              {['length', 'complexity', 'obvious', 'unique', 'discreet'].map((tipKey) => (
                <li key={tipKey}>
                  <strong>{translate(`tips.${tipKey}.title`)} : </strong>
                  {translate(`tips.${tipKey}.description`)}
                </li>
              ))}
            </ol>
            <p>{translate('outro')}</p>
          </div>
        </div>
      </Container>
      <FieldContainer label={translate('password')} required>
        <PasswordInput
          {...registerField('password')}
          value={userState.password ?? ''}
          onChange={(password) => onUserChange({ ...userState, password })}
          validateStrenght
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('confirm-password')} required>
        <PasswordInput
          {...registerField('confirmPassword')}
          customValidation={
            confirmPassword
              ? {
                  isValid: userState.password === confirmPassword,
                  errorMessage: translate('invalid-confirm-password')
                }
              : undefined
          }
          value={confirmPassword}
          onChange={setConfirmPassword}
          required
        />
      </FieldContainer>
    </>
  );
};

export default Security;
