import partition from 'lodash/partition';
import PeriodFormTransformer from '../PeriodFormTransformer';
import { Mixte, MixteDay } from '../../../../../../shared/domain/activity/mixte/model/Mixte';
import { LatestMixteDayPeriodFormDto, LatestMixtePeriodFormDto } from '../../type';
import FirstJuneMixteContraint from '../../../../../../shared/domain/ramq/mixte/contraint/NewStructureMixteContraint';
import ActivityType from '../../../../../../shared/core/activity/domain/ActivityType';
import LegacyMixteDayTransformer from './days/LegacyMixteDayTransformer';
import LatestMixteDayTransformer from './days/LatestMixteDayTransformer';

class MixtePeriodTransformer implements PeriodFormTransformer<LatestMixtePeriodFormDto, Mixte> {
  private legacyTransformer = new LegacyMixteDayTransformer();

  private latestTransformer = new LatestMixteDayTransformer();

  toDomainObject(dto: LatestMixtePeriodFormDto): Mixte {
    const [atOrAfterJuneFirst2021, beforeJuneFirst2021] = partition(dto.days, ({ date }) =>
      FirstJuneMixteContraint.isSatisfiedBy(ActivityType.MIXTE, date)
    );

    return {
      ...dto,
      days: [
        ...this.legacyTransformer.toDomainDays(beforeJuneFirst2021 as MixteDay[]),
        ...this.latestTransformer.toDomainDays(atOrAfterJuneFirst2021 as LatestMixteDayPeriodFormDto[])
      ]
    };
  }

  toPeriodFormDto(mixte: Mixte): LatestMixtePeriodFormDto {
    const [atOrAfterJuneFirst2021, beforeJuneFirst2021] = partition(mixte.days, ({ date }) =>
      FirstJuneMixteContraint.isSatisfiedBy(ActivityType.MIXTE, date)
    );

    return {
      ...mixte,
      days: [
        ...this.legacyTransformer.toDaysPeriodFormDto(beforeJuneFirst2021 as MixteDay[]),
        ...this.latestTransformer.toDaysPeriodFormDto(atOrAfterJuneFirst2021 as MixteDay[])
      ]
    };
  }
}

export default MixtePeriodTransformer;
