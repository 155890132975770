import { withStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import React from 'react';
import { isValidNam } from '../../../../validations/act/nam/namValidation';

export const styles = (theme) => ({
  checkIcon: {
    color: theme.palette.green[500],
    lineHeight: 0
  },
  cancelIcon: {
    lineHeight: 0
  }
});

export function NamIcon(props) {
  return !isValidNam(props.nam) || !props.isAdmissible ? (
    <Icon color="error" className={props.classes.cancelIcon}>
      <CancelIcon className={classnames(props.className)} />
    </Icon>
  ) : (
    <Icon className={props.classes.checkIcon}>
      <CheckCircleIcon className={classnames(props.className)} />
    </Icon>
  );
}

NamIcon.defaultProps = {
  className: {},
  isAdmissible: true
};

NamIcon.propTypes = {
  nam: PropTypes.string.isRequired,
  isAdmissible: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default withStyles(styles)(NamIcon);
