import get from 'lodash/get';
import { AssociatedField, RequiredDetail } from '../requiredDetail.type';
import MultiLineField from '../../../components/Form/MultiLineField/MultiLineField';

const summaryRender = (code) => [
  {
    name: 'étudiant',
    value: get(code, 'noStudents', '-')
  }
];

const associatedFields = (pattern: string): AssociatedField[] => [
  {
    label: `No D'étudiant (${pattern})`,
    getName: (index) => `codes[${index}].noStudents`,
    type: MultiLineField
  }
];

const studentNumber = (pattern: string): RequiredDetail => ({
  summaryRender,
  associatedFields: associatedFields(pattern)
});

export default studentNumber;
