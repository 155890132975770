import { formatUnixToDate } from '../../../../shared/utils/dateTime/dateTimeUtils';
import { DateField } from '../../../components/Form/Fields';

const summaryRender = (code) => [
  {
    name: 'csst',
    value: code.csstDate ? formatUnixToDate(code.csstDate) : '-'
  }
];

const associatedFields = [
  {
    label: 'Date CSST',
    getName: () => 'patientEvent.eventDate',
    type: DateField
  }
];

const dateCSST = { associatedFields, summaryRender };

export default dateCSST;
