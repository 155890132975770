import { get } from 'lodash';
import {
  INTENSIVE_CARE_ON_GUARD_DAY,
  INTENSIVE_CARE_UNIT_SUPPORT_DAY
} from '../../../../../shared/ramq/contextElements/globalContextElements';
import applyAuditInformation from '../../../../../utils/auditInformation';
import { activitiesCollectionRef } from '../../../../firebase/collection/collectionReferences';
import {
  hasIntensiveCareOnGuardDayContextElement,
  hasIntensiveCareUnitSupportDayContextElement,
  INTENSIVE_CARE_OPTIMIZATIONS
} from './utils';

const withoutIntensiveCareUnitSupportDayContextElement = (act) => !hasIntensiveCareUnitSupportDayContextElement(act);
const withoutIntensiveCareOnGuardDayContextElement = (act) => !hasIntensiveCareOnGuardDayContextElement(act);

const isNotIntensiveCareUnitSupport = (contextElement) => contextElement !== INTENSIVE_CARE_UNIT_SUPPORT_DAY;
const isNotIntensiveCareOnGuard = (contextElement) => contextElement !== INTENSIVE_CARE_ON_GUARD_DAY;

export const updateDocumentPromise = (act, contextElements, activityArea) =>
  activitiesCollectionRef()
    .doc(act.id)
    .update({
      ...(activityArea && { activityArea }),
      contextElements,
      ...applyAuditInformation({}, INTENSIVE_CARE_OPTIMIZATIONS)
    });

export const removeIntensiveCareUnitSupportContextElementOnEachActIfNeeded = (acts) => {
  const actsWithIntensiveCareUnitSupportContextElement = acts.filter(hasIntensiveCareUnitSupportDayContextElement);
  const documentUpdates = actsWithIntensiveCareUnitSupportContextElement.map((act) => {
    const contextElements = act.contextElements.filter(isNotIntensiveCareUnitSupport);
    return updateDocumentPromise(act, contextElements);
  });
  return Promise.all(documentUpdates);
};

export const removeIntensiveCareOnGuardContextElementOnEachActIfNeeded = (acts) => {
  const actsWithIntensiveCareOnGuardContextElement = acts.filter(hasIntensiveCareOnGuardDayContextElement);
  const documentUpdates = actsWithIntensiveCareOnGuardContextElement.map((act) => {
    const contextElements = act.contextElements.filter(isNotIntensiveCareOnGuard);
    return updateDocumentPromise(act, contextElements);
  });
  return Promise.all(documentUpdates);
};

export const addIntensiveCareUnitSupportContextElementOnEachActIfNeeded = (acts) => {
  const actsWithoutIntensiveCareUnitSupportContextElement = acts.filter(
    withoutIntensiveCareUnitSupportDayContextElement
  );
  const documentUpdates = actsWithoutIntensiveCareUnitSupportContextElement.map((act) => {
    const contextElements = get(act, 'contextElements', []).concat(INTENSIVE_CARE_UNIT_SUPPORT_DAY);
    return updateDocumentPromise(act, contextElements);
  });
  return Promise.all(documentUpdates);
};

export const addIntensiveCareOnGuardContextElementOnEachActIfNeeded = (acts) => {
  const actsWithoutIntensiveCareOnGuardContextElement = acts.filter(withoutIntensiveCareOnGuardDayContextElement);
  const documentUpdates = actsWithoutIntensiveCareOnGuardContextElement.map((act) => {
    const contextElements = get(act, 'contextElements', []).concat(INTENSIVE_CARE_ON_GUARD_DAY);
    return updateDocumentPromise(act, contextElements);
  });
  return Promise.all(documentUpdates);
};
