import moment from 'moment-timezone';
import { ActCode } from './ActCode';
import { RequiredDetails } from '../requiredDetail.type';
import LimitedDiagnosticListSelector from '../../../favorite/diagnosticCode/ui/LimitedDiagnosticDialog/LimitedDiagnosticListSelector';
import limitedDiagnostic from '../LimitedDiagnostic/limitedDiagnostic';
import Act from '../../../../shared/domain/activity/act/model/Act';

export default class ActCode6946 implements ActCode {
  // eslint-disable-next-line no-unused-vars
  constructor(private act: Act) {}

  getRequiredDetails(): RequiredDetails {
    const diagnosticList = LimitedDiagnosticListSelector.select(moment(this.act.date));

    return [limitedDiagnostic(diagnosticList)];
  }
}
