import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';

function EmptyActivitiesIndicator() {
  return (
    <Card style={{ margin: 8 }} data-testid="emptyActivitiesIndicator">
      <CardContent>
        <Typography variant="h5" component="h2">
          Aucune activité
        </Typography>
        <Typography component="p">Aucune activité trouvée pour ces critères de recherche</Typography>
      </CardContent>
    </Card>
  );
}

export default EmptyActivitiesIndicator;
