import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { activitiesCollectionRef } from 'app/firebase/collection/collectionReferences';
import ActivityStatus from '../../../shared/core/activity/domain/ActivityStatus';
import { logDefault, logError } from '../../../shared/utils/plog';
import { ACTIVITIES_ACT_TYPE } from '../../../shared/collection/collectionConstants';
import { operateActivitiesWithUserFilter, setDocument, updateDocument } from '../../firebase/document/documentSagas';
import { selectUserProfileInContext } from '../User/selectors';
import {
  SAVE_ACT,
  SAVE_ACT_WITHOUT_POST_PROCESSING,
  saveActFailure,
  UPDATE_ACT,
  UPDATE_ACT_WITHOUT_POST_PROCESSING,
  updateActFailure,
  watchSentActivity
} from './actions';
import runIntensiveCareOptimizations from './SaveAct/intensiveCareOptimizations/runIntensiveCareOptimizations';
import optimizeDocumentBeforeSave from './SaveAct/optimizeDocumentBeforeSave';
import requestMixteAutomaticGeneration from '../../mixte/generation/watcher/actions';

export function* processAct(saveFunction, saveFailureAction, action) {
  logDefault({ type: 'saga', text: 'processAct', array: [JSON.stringify(action)] });
  try {
    const userProfile = yield select(selectUserProfileInContext());
    const optimizedDocument = yield call(optimizeDocumentBeforeSave, { ...action, userProfile });
    yield fork(operateActivitiesWithUserFilter, ACTIVITIES_ACT_TYPE, saveFunction, saveFailureAction, {
      document: optimizedDocument,
      changes: action.changes
    });
    yield put(requestMixteAutomaticGeneration({ ...action, userProfile }));
    if (action.changes?.date && action.changes.date !== action.document.date) {
      yield put(requestMixteAutomaticGeneration({ document: action.changes, userProfile }));
    }
    yield fork(runIntensiveCareOptimizations, { ...action, userProfile });
    if (optimizedDocument.status === ActivityStatus.SENT) {
      yield put(watchSentActivity(optimizedDocument));
    }
  } catch (e) {
    logError({ type: 'saga', text: 'processAct', array: ['error processing act', JSON.stringify(action), e] });
    yield put(saveFailureAction(e));
  }
}

export function* saveActWithoutPostProcessing(action) {
  yield* setDocument(activitiesCollectionRef(), saveActFailure, action);
}

export default function* actSaga() {
  yield takeEvery(SAVE_ACT, processAct, setDocument, saveActFailure);
  yield takeEvery(UPDATE_ACT, processAct, updateDocument, updateActFailure);
  yield takeEvery(
    UPDATE_ACT_WITHOUT_POST_PROCESSING,
    operateActivitiesWithUserFilter,
    ACTIVITIES_ACT_TYPE,
    updateDocument,
    updateActFailure
  );
  yield takeEvery(SAVE_ACT_WITHOUT_POST_PROCESSING, saveActWithoutPostProcessing);
}
