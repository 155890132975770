import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'Navigation.home',
    defaultMessage: 'Activities'
  },
  assistant: {
    id: 'Navigation.assistant',
    defaultMessage: 'Assistant'
  },
  settings: {
    id: 'Navigation.settings',
    defaultMessage: 'Setting'
  },
  administration: {
    id: 'Navigation.administration',
    defaultMessage: 'Administration'
  },
  reports: {
    id: 'Navigation.reports',
    defaultMessage: 'Reports'
  },
  act: {
    id: 'Navigation.act',
    defaultMessage: 'Act'
  },
  acts: {
    id: 'Navigation.acts',
    defaultMessage: 'Acts'
  },
  newAct: {
    id: 'Navigation.acts.new',
    defaultMessage: 'New ActActivity'
  },
  editAct: {
    id: 'Navigation.act.edit',
    defaultMessage: 'Edit ActActivity'
  },
  adminReport: {
    id: 'Navigation.adminReport',
    defaultMessage: 'Rapport'
  },
  users: {
    id: 'Navigation.users',
    defaultMessage: 'Users'
  },
  newUser: {
    id: 'Navigation.users.new',
    defaultMessage: 'New User'
  },
  editUser: {
    id: 'Navigation.user.edit',
    defaultMessage: 'Edit User'
  },
  groups: {
    id: 'Navigation.groups',
    defaultMessage: 'Groupes et Pools'
  },
  duplicate: {
    id: 'Navigation.act.duplicate',
    defaultMessage: 'Acte'
  },
  mixte: {
    id: 'Navigation.mixte',
    defaultMessage: 'Mixte'
  },
  lumpsum: {
    id: 'Navigation.lumpsum',
    defaultMessage: 'Forfaitaire'
  },
  ramqLog: {
    id: 'Navigation.ramqLog',
    defaultMessage: 'Ramq Log'
  },
  back: {
    id: 'Navigation.back',
    defaultMessage: 'Retour'
  },
  next: {
    id: 'Navigation.next',
    defaultMessage: 'Suivant'
  },
  newTravelExpenses: {
    id: 'Navigation.travelExpenses.new',
    defaultMessage: 'New travel expenses'
  },
  editTravelExpenses: {
    id: 'Navigation.travelExpenses.edit',
    defaultMessage: 'Edit travel expenses'
  },
  newTraining: {
    id: 'Navigation.training.new',
    defaultMessage: 'New training'
  },
  pools: {
    id: 'Navigation.pools',
    defaultMessage: 'Pools'
  },
  pool: {
    id: 'Navigation.pool',
    defaultMessage: 'Pool'
  },
  poolDetails: {
    id: 'Navigation.pool.details',
    defaultMessage: 'Détails'
  },
  poolMembers: {
    id: 'Navigation.pool.users',
    defaultMessage: 'Membres'
  },
  poolReports: {
    id: 'Navigation.pool.reports',
    defaultMessage: 'Rapport Pool'
  }
});
