import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';

interface Props extends Omit<TextFieldProps, 'onSubmit' | 'onChange' | 'value'> {
  debounceInMS: number;
  initialValue: string;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (value: string) => void;
}

const DebouncedTextField: React.FunctionComponent<Props> = ({ initialValue, onSubmit, debounceInMS, ...rest }) => {
  const [value, setValue] = useState(initialValue);

  const onSubmitDebounced = useMemo(() => debounce(onSubmit, debounceInMS), [onSubmit, debounceInMS]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    onSubmitDebounced(newValue);
  };

  return <TextField {...rest} value={value} onChange={onChange} />;
};

export default DebouncedTextField;
