import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import ExpandableTableRow from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRow';
import ExpandableTableRowHeader from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRowHeader';
import ExpandableTableRowContent from '../../../../../components/Table/ExpandableTableRow/ExpandableTableRowContent';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import { getDateDenseWithWeekDay } from '../../../../../../utils/dateTimeUtils';
import ActsDayDetailTable from '../ActsDayDetailTable/ActsDayDetailTable';
import PoolActsDayDetailTable from '../PoolActsDayDetailTable/PoolActsDayDetailTable';

export const styles = () => ({
  noPadding: {
    '&:last-child': {
      padding: '0'
    }
  },
  noWrap: {
    whiteSpace: 'nowrap'
  }
});

export function ActsDayRow({ actsDay, classes, pool, individual, tableCellClass }) {
  return (
    <ExpandableTableRow>
      <ExpandableTableRowHeader>
        <TableCell classes={{ root: tableCellClass }} className={classes.noWrap} size="small">
          {getDateDenseWithWeekDay(actsDay.day)}
        </TableCell>
        <TableCell classes={{ root: tableCellClass }} align="right" size="small">
          {actsDay.notBilledCount}
        </TableCell>
        <TableCell classes={{ root: tableCellClass }} align="right" size="small">
          {actsDay.billedCount}
        </TableCell>
        <TableCell classes={{ root: tableCellClass }} align="right" size="small">
          {currencyFormat(actsDay.totalAmount)}
        </TableCell>
      </ExpandableTableRowHeader>
      <ExpandableTableRowContent canOpen={actsDay.billedCount + actsDay.notBilledCount > 0}>
        <TableCell classes={{ root: classes.noPadding }} colSpan="100">
          {pool && individual === false ? (
            <PoolActsDayDetailTable acts={actsDay} tableCellClass={tableCellClass} />
          ) : (
            <ActsDayDetailTable acts={actsDay} pool={pool} />
          )}
        </TableCell>
      </ExpandableTableRowContent>
    </ExpandableTableRow>
  );
}

ActsDayRow.propTypes = {
  actsDay: PropTypes.object.isRequired,
  pool: PropTypes.bool.isRequired,
  individual: PropTypes.bool,
  tableCellClass: PropTypes.string.isRequired
};

ActsDayRow.defaultProps = {
  individual: false
};

export default withStyles(styles)(ActsDayRow);
