import moment, { Moment } from 'moment-timezone';
import Act from '../activity/act/model/Act';
import { parseNam, validateNam } from '../../medyx-core/utilities/nam';

class NAM {
  constructor(
    /* eslint-disable no-unused-vars */
    public readonly code: string,
    public readonly birth: Moment
  ) {}

  public static fromAct(act: Act): NAM {
    return NAM.fromV1(act.nam!);
  }

  public static fromV1(nam: string): NAM {
    if (!NAM.isValidForV1(nam)) {
      throw new Error('Invalid NAM');
    }

    const { birthYear, birthMonth, birthDay } = parseNam(nam);

    const birth = moment(`${birthYear}-${birthMonth}-${birthDay}`, 'YYYY-MM-DD');

    return new NAM(nam, birth);
  }

  public static isValidForV1(nam: string): boolean {
    return validateNam(nam);
  }
}

export default NAM;
