import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import Section from 'application/components/form-controls/section';
import useTranslate from 'application/hooks/use-translate';
import { FirestoreUser, RAMQAccreditationStatus } from 'application/hooks/use-firestore-user-profile';
import React, { useState } from 'react';
import RAMQAccreditationStatusIndicator from 'application/components/ramq-accreditation-status';
import RalphApi from 'application/services/ralph-api';
import downloadFile from 'application/utilities/download-file';
import useToast from 'application/hooks/use-toast';

interface Props {
  user: FirestoreUser;
}

const RAMQAccreditation = ({ user }: Props) => {
  const translate = useTranslate('pages.settings.details.ramq-accreditation');

  const [isFetchingRAMQAccreditationForm, setIsFetchingRAMQAccreditationForm] = useState(false);

  const { addToast } = useToast();

  const fetchRAMQAccreditationForm = () => {
    setIsFetchingRAMQAccreditationForm(true);

    return RalphApi.sendRequest(`users/${user.id}/ramq-accreditation-request`, 'GET')
      .then((response) => {
        if (response.status === 404) {
          addToast({
            message: translate('no-data-available'),
            type: 'warning'
          });

          return;
        }

        if (!response.ok) {
          addToast({
            message: translate('error-downloading-report'),
            type: 'error'
          });

          return;
        }

        downloadFile(response);
      })
      .catch(() => {
        addToast({
          message: translate('error-downloading-report'),
          type: 'error'
        });
      })
      .finally(() => {
        setIsFetchingRAMQAccreditationForm(false);
      });
  };

  return (
    <Section label={translate('title')}>
      <FieldContainer label={translate('accreditation-status')}>
        <RAMQAccreditationStatusIndicator status={user.ramqAccreditationStatus ?? RAMQAccreditationStatus.MISSING} />
      </FieldContainer>
      <FieldContainer label={translate('accreditation-form')}>
        <Button
          className="btn btn-primary"
          icon="arrow-down-to-line"
          iconPosition="left"
          loading={isFetchingRAMQAccreditationForm}
          onClick={fetchRAMQAccreditationForm}
          type="button"
        >
          {translate('download-form')}
        </Button>
      </FieldContainer>
    </Section>
  );
};

export default RAMQAccreditation;
