import React from 'react';
import find from 'lodash/find';
import HybridListSelector from '../../../components/Form/ListSelector/HybridListSelector/HybridListSelector';

export interface Props {
  // eslint-disable-next-line no-unused-vars
  onChange: (data: number) => void;
  value: boolean;
}

function StatementSectionsSelector(props: Props) {
  const { onChange, value } = props;
  const data = [
    { label: 'Section 20 affichée', value: true },
    { label: 'Section 20 cachée', value: false }
  ];

  return (
    <HybridListSelector
      input={{
        onChange: (evt) => onChange(evt.target.value.value),
        value: find(data, ['value', value || false])?.label
      }}
      values={data}
    />
  );
}

export default StatementSectionsSelector;
