import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import MultiSelectCheckboxGroup from '../../components/Form/Checkbox/MultiSelectCheckboxGroup/MultiSelectCheckboxGroup';

function AdditionalTransportContextElement({ input: reduxFormInput, list, label }) {
  return (
    <div style={{ marginBottom: 16 }}>
      <Typography align="center">{label}</Typography>
      <MultiSelectCheckboxGroup input={reduxFormInput} list={list} labelProp="label" valueProp="value" />
    </div>
  );
}

AdditionalTransportContextElement.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired
};

export default AdditionalTransportContextElement;
