import API_BASE_URL from '../../../shared/server/apiConfig';
import request, { processResponse } from './apiUtils';

export default class VersionAPI {
  public static backendVersion(): Promise<{ version }> {
    const endpoint = `${API_BASE_URL}/version`;

    return processResponse(request(endpoint, { method: 'GET' }));
  }
}
