// Rule disabled because firestore initialization is async
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import ReduxSagaFirebase from 'redux-saga-firebase';
import { logError } from '../shared/utils/plog';
import FirebaseConfigProvider from '../shared/server/FirebaseConfigProvider';

const firebaseConfig = FirebaseConfigProvider.getInstance().getConfig();

firebase.initializeApp(firebaseConfig);

if (process.env.NODE_ENV === 'development') {
  firebase.functions().useEmulator(firebaseConfig.functionsUrl);
  firebase.firestore().settings({ host: firebaseConfig.databaseURL, ssl: false });
  firebase.auth().useEmulator(`http://${firebaseConfig.authDomain}`);
}

export let firestore = {};
export let storage = {};
export let reduxSagaFireBase = {};
export let auth = {};

export async function initializeFirebase() {
  try {
    storage = firebase.storage();
    auth = firebase.auth();
    firestore = firebase.firestore();
    reduxSagaFireBase = new ReduxSagaFirebase(firebase);
  } catch (e) {
    logError({
      type: 'configuration',
      text: 'firebaseSetup',
      array: ['Failed to setup Firebase utilities', e]
    });
  }
}
