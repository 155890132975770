import enTranslationMessages from '../../translations/en.json';
import frTranslationMessages from '../../translations/fr.json';
import './momentFR';

interface RawTranslationsMap {
  [key: string]: string | RawTranslationsMap;
}

interface FlattenedTranslationMap {
  [key: string]: string;
}

interface Translations {
  fr: FlattenedTranslationMap;
  en: FlattenedTranslationMap;
}

export const appLocales = ['en', 'fr'];

export const flattenTranslations = (
  map: RawTranslationsMap,
  globalTranslations: RawTranslationsMap = {},
  pathPrefix = ''
) => {
  const mapKeys = Object.keys(map);

  return mapKeys.reduce((acc, key) => {
    if (typeof map[key] === 'object') {
      return flattenTranslations(map[key] as RawTranslationsMap, acc, `${pathPrefix}${key}.`);
    }

    return {
      ...acc,
      [`${pathPrefix}${key}`]: map[key]
    };
  }, globalTranslations);
};

export const translationMessages: Translations = {
  en: flattenTranslations(enTranslationMessages),
  fr: flattenTranslations(frTranslationMessages)
};
