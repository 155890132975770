import { WithStyles, withStyles } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import Toolbar from '@material-ui/core/Toolbar';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { selectUserProfileInContext } from 'app/containers/User/selectors';
import User from 'shared/domain/user/User';
import React, { useState } from 'react';
import { Moment } from 'moment';
import { useQuery } from '@tanstack/react-query';
import ReportAPI from 'app/infrastructure/api/ReportAPI';
import HeaderPortal from 'app/components/Portals/HeaderPortal/HeaderPortal';
import ReceivableAccountsReportForm from '../ReceivableAccounts/ReceivableAccountsReportForm/ReceivableAccountsReportForm';
import Spinner from 'application/components/spinner';
import TravelDistancesReportForm from './TravelDistancesReportForm';

export const styles = (theme) =>
  ({
    whiteBackground: {
      backgroundColor: 'white'
    },
    spinner: {
      marginTop: theme.spacing(5)
    },
    iframeWrapper: {
      padding: theme.spacing(2),
      overflowX: 'scroll',
      height: '100%',
      width: '100%'
    },
    iframe: {
      height: '100%',
      width: '100%',
      minWidth: '1200px'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  user: User | undefined;
}

export const TravelDistancesPage: React.FunctionComponent<Props> = ({ user, classes }: Props) => {
  const [selectedPeriodStart, setSelectedPeriodStart] = useState<Moment | null>(null);
  const [selectedPeriodEnd, setSelectedPeriodEnd] = useState<Moment | null>(null);

  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ['travel-distance-summary-by-period', selectedPeriodStart, selectedPeriodEnd],
    queryFn: () => {
      if (!user || !selectedPeriodStart || !selectedPeriodEnd) return;

      return ReportAPI.downloadTravelDistancesReport({
        mode: 'html',
        userId: user?.id,
        periodStart: selectedPeriodStart.valueOf(),
        periodEnd: selectedPeriodEnd.valueOf()
      });
    },
    enabled: Boolean(user && selectedPeriodStart && selectedPeriodEnd)
  });

  const handlePeriodStartChange = (date: Moment | null) => {
    if (!date) return;

    setSelectedPeriodStart(date.startOf('day'));
    setSelectedPeriodEnd(date.clone().add(12, 'months').subtract(1, 'millisecond'));
  };

  if (!user) return;

  return (
    <>
      <HeaderPortal>
        <Toolbar className={classes.whiteBackground}>
          <TravelDistancesReportForm
            user={user}
            periodStart={selectedPeriodStart}
            periodend={selectedPeriodEnd}
            onStartDateChange={handlePeriodStartChange}
          />
        </Toolbar>
      </HeaderPortal>

      {isFetching && <Spinner className={classes.spinner} />}

      {data && isSuccess && !isFetching && (
        <div className={classes.iframeWrapper}>
          <iframe srcDoc={data} title="Report preview" className={classes.iframe} />
        </div>
      )}
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  user: selectUserProfileInContext()
});

export default compose<typeof React.Component>(withStyles(styles), connect(mapStateToProps))(TravelDistancesPage);
