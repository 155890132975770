import { changeUserLanguage } from 'app/containers/User/actions';
import { selectUserLanguage } from 'app/containers/User/selectors';
import useTranslate from 'application/hooks/use-translate';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

type language = 'en' | 'fr';

const LanguageSwitcher = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const language = useSelector(selectUserLanguage()) as language;
  const dispatch = useDispatch();
  const translate = useTranslate('pages.lobby.signup');

  const otherLanguage = language === 'en' ? 'fr' : 'en';

  const toggleLocale = () => {
    dispatch(changeUserLanguage(otherLanguage));
  };

  return (
    <div>
      <button type="button" className="link" onClick={toggleLocale}>
        {translate(otherLanguage)}
      </button>
    </div>
  );
};

export default LanguageSwitcher;
