import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { formatUnixToDate } from '../../../../../../shared/utils/dateTime/dateTimeUtils';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import StatementTable from '../../../../../components/Table/Statement/StatementTable/StatementTable';
import { StatementLine } from '../sections/StatementDoctorInfo';

const measuringElementColumn = {
  name: 'Élément de mesure',
  primaryText: (line) => `${line.specialMesureCodeLabel}`,
  secondaryText: (line) => line.specialMesure
};

const montantCodeExplicatifColumn = {
  name: 'Montant',
  align: 'right',
  primaryText: (line) => line.paidAmount !== '     S/O ' && line.paidAmount && currencyFormat(line.paidAmount)
};

export function StatementSpecial({ content }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <StatementSection
          primaryLine={`${content.modTransactionCode} - ${content.modLineTransactionLabel}`}
          secondaryLines={[
            StatementLine(
              'Période:',
              `${formatUnixToDate(content.periodStart)}
                  au ${formatUnixToDate(content.periodEnd)}`
            ),
            StatementLine('N. requête:', content.requestNumber),
            StatementLine('Réception:', formatUnixToDate(content.dateReceived)),
            content.explanationCode1 !== '000' &&
              `Codes explicatifs: ${content.explanationCode1} ${content.explanationCode2}`
          ]}
        />
      </Grid>
      {!isEmpty(content.extraLines) && (
        <Grid item xs={12} sm={8}>
          <StatementTable
            title=""
            columns={[measuringElementColumn, montantCodeExplicatifColumn]}
            data={content.extraLines}
          />
        </Grid>
      )}
    </Grid>
  );
}

StatementSpecial.propTypes = {
  content: PropTypes.object.isRequired
};

export default StatementSpecial;
