import useTranslate from 'application/hooks/use-translate';
import useFirestoreUserProfile, { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useToast from 'application/hooks/use-toast';
import { setUserProfileInContext } from 'app/containers/User/actions';
import useValidatedForm from 'application/hooks/use-validated-form';
import Medavie from 'application/pages/settings/partners/medavie';
import Ramq from 'application/pages/settings/partners/ramq';
import Accountants from 'application/pages/settings/partners/accountants';

interface UserReduxState {
  authentication: {
    user: {
      uid: string;
    };
  };
}

const SettingsPartnersPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector<UserReduxState, string>((state) => state.authentication.user.uid);
  const [user, updateUser] = useFirestoreUserProfile(userId);
  const [stagedUser, setStagedUser] = useState<FirestoreUser | null>(null);
  const translate = useTranslate('pages.settings.partners');
  const { addToast } = useToast();

  const { registerField } = useValidatedForm();

  useEffect(() => {
    setStagedUser(user);
  }, [user]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stagedUser) return;

    updateUser(stagedUser)
      .then((updatedUser) => {
        dispatch(setUserProfileInContext(updatedUser));
        addToast({
          autoDismiss: true,
          message: translate('saved-success'),
          type: 'success'
        });
      })
      .catch(() => {
        addToast({
          autoDismiss: true,
          message: translate('saved-error'),
          type: 'error'
        });
      });
  };

  if (!stagedUser) return;

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
      <Medavie
        user={stagedUser}
        registerField={registerField}
        onChange={(section) => setStagedUser({ ...stagedUser, ...section })}
      />

      <Ramq
        user={stagedUser}
        registerField={registerField}
        onChange={(section) => setStagedUser({ ...stagedUser, ...section })}
      />

      <Accountants />
    </form>
  );
};

export default SettingsPartnersPage;
