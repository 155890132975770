export const ADD_ASYNC_TASK = 'addAsyncCall';
export const ASYNC_CALL_SUCCESS = 'asyncCallSuccess';
export const ASYNC_CALL_FAILURE = 'asyncCallFailure';
export const ASYNC_CALL_DELETE = 'asyncCallDelete';

export function addAsyncCall(asyncCallId) {
  return {
    type: ADD_ASYNC_TASK,
    asyncCallId
  };
}

export function asyncCallSuccess(asyncCallId) {
  return {
    type: ASYNC_CALL_SUCCESS,
    asyncCallId
  };
}

export function asyncCallFailure(asyncCallId, error) {
  return {
    type: ASYNC_CALL_FAILURE,
    asyncCallId,
    error
  };
}

export function asyncCallDelete(asyncCallId) {
  return {
    type: ASYNC_CALL_DELETE,
    asyncCallId
  };
}
