export const AM_PERDIEM = 'am';
export const PM_PERDIEM = 'pm';
export const DEFAULT_CLINICAL_ACTIVITIES_SUFFIX = '030';
export const CLINICAL_ACTIVITIES_WITH_SUPERVISION_SUFFIX = '056';
export const NO_PERDIEM = '00';
export const PERDIEM = '10';
export const HALF_PERDIEM = '05';

export const MIXTE_LOWER_RANGE_HOUR = 8;
export const MIXTE_MIDDLE_RANGE_HOUR = 12;
export const MIXTE_UPPER_RANGE_HOUR = 15;
