import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { requiredFieldValidations } from '../../../validations/common/reduxFormValidation';

const summaryRender = (code) => (code.role ? [{ name: 'Rôle', value: code.role }] : []);

const associatedFields = [
  {
    getName: (index) => `codes[${index}].role`,
    type: SingleSelectCheckboxGroup,
    list: [
      { label: 'Rôle 1', value: 1 },
      { label: 'Rôle 2', value: 2 }
    ],
    props: {
      defaultValue: 2
    }
  }
];

const associatedValidations = [requiredFieldValidations(['end'])];

export default { associatedFields, associatedValidations, summaryRender };
