export enum DaisyButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ACCENT = 'accent',
  NEUTRAL = 'neutral',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  GHOST = 'ghost',
  LINK = 'link',
  OUTLINE = 'outline'
}

export enum DaisyAlertVariant {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}
