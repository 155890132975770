import moment from 'moment';
import { findCodeDetail } from 'shared/data/actCodesData';
import Act, { ActCode } from '../../../../shared/domain/activity/act/model/Act';
import Recommendation, { NO_RECOMMENDATIONS } from '../types';

const switchChronicPainVisitCodes = (act: Partial<Act>): Recommendation<ActCode[] | undefined>[] => {
  const startTime = moment(act.start);
  const endTime = moment(act.end);
  const duration = moment.duration(endTime.diff(startTime)).asMinutes();

  let recommendationMessage = '';
  let codeToRemove = '';
  let codeToAdd = '';

  if (act.codes?.some((code) => code.code === '9257') && duration > 90) {
    recommendationMessage = 'La durée maximale du 9257 est de 90 min, svp cliquer pour remplacer par 9258.';
    codeToRemove = '9257';
    codeToAdd = '9258';
  } else if (act.codes?.some((code) => code.code === '9258') && duration <= 90) {
    recommendationMessage = 'La durée du 9258 doit être supérieure à 90 minutes, svp cliquer pour remplacer par 9257.';
    codeToRemove = '9258';
    codeToAdd = '9257';
  } else if (act.codes?.some((code) => code.code === '9259') && duration > 45) {
    recommendationMessage = 'La durée maximale du 9259 est de 45 min, svp cliquer pour remplacer par 9260.';
    codeToRemove = '9259';
    codeToAdd = '9260';
  } else if (act.codes?.some((code) => code.code === '9260') && duration <= 45) {
    recommendationMessage = 'La durée du 9260 doit être supérieure à 45 minutes, svp cliquer pour remplacer par 9259.';
    codeToRemove = '9260';
    codeToAdd = '9259';
  }

  if (codeToRemove === '' && codeToAdd === '') {
    return NO_RECOMMENDATIONS;
  }

  return [
    {
      message: recommendationMessage,
      apply: (partialAct) => {
        const oldValue = partialAct.codes;
        const newValue = partialAct.codes?.map((actCode) => {
          if (actCode.code === codeToRemove) {
            return {
              ...actCode,
              ...findCodeDetail(codeToAdd)
            };
          }
          return actCode;
        });
        return {
          fieldName: 'codes',
          newValue,
          oldValue
        };
      }
    }
  ];
};

export default switchChronicPainVisitCodes;
