import {
  CLOSE_NAM_SELECTOR_DIALOG,
  CLOSE_PICTURE_PREVIEW,
  GET_ACTS_24_HOURS_BEFORE_DATE,
  GET_ACTS_24_HOURS_BEFORE_DATE_FAILURE,
  GET_ACTS_24_HOURS_BEFORE_DATE_SUCCESS,
  OPEN_NAM_SELECTOR_DIALOG,
  TOGGLE_PICTURE_PREVIEW
} from './actions';

export const initialState = {
  picturePreviewDisplayed: false,
  acts24HoursLoaded: false,
  acts24HoursBefore: [],
  namSelectorDialog: {
    open: false,
    detectedNams: []
  }
};

function actFormReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_NAM_SELECTOR_DIALOG:
      return {
        ...state,
        namSelectorDialog: {
          open: true,
          detectedNams: action.detectedNams || []
        }
      };
    case CLOSE_NAM_SELECTOR_DIALOG:
      return {
        ...state,
        namSelectorDialog: {
          open: false,
          detectedNams: []
        }
      };
    case CLOSE_PICTURE_PREVIEW:
      return {
        ...state,
        picturePreviewDisplayed: false
      };
    case GET_ACTS_24_HOURS_BEFORE_DATE:
      return {
        ...state,
        acts24HoursLoaded: false
      };
    case GET_ACTS_24_HOURS_BEFORE_DATE_SUCCESS:
      return {
        ...state,
        acts24HoursLoaded: true,
        acts24HoursBefore: action.acts
      };
    case GET_ACTS_24_HOURS_BEFORE_DATE_FAILURE:
      return {
        ...state,
        acts24HoursLoaded: false,
        acts24HoursBefore: [],
        error: true
      };
    case TOGGLE_PICTURE_PREVIEW:
      return {
        ...state,
        picturePreviewDisplayed: !state.picturePreviewDisplayed
      };
    default:
      return state;
  }
}

export default actFormReducer;
