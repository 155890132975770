import { detailedDiff } from 'deep-object-diff';

function calculateDeltaBetweenTwoActivityVersions(activityVersions) {
  const versionsWithDelta = [
    {
      delta: {
        added: activityVersions[0],
        updated: {},
        deleted: {}
      },
      version: activityVersions[0]
    }
  ];

  for (let i = 1; i < activityVersions.length; i += 1) {
    versionsWithDelta.push({
      delta: detailedDiff(activityVersions[i - 1], activityVersions[i]),
      version: activityVersions[i]
    });
  }

  return versionsWithDelta;
}

export default calculateDeltaBetweenTwoActivityVersions;
