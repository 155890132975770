import AuthenticationServiceProvider from 'app/infrastructure/authentication/service/AuthenticationServiceProvider';
import firebase from 'firebase/compat/app';
import { useCallback, useEffect, useRef, useState } from 'react';
import { firestore } from 'server/Firebase';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import UserBillingType from 'shared/domain/user/UserBillingType';

export enum DefaultBillingType {
  AUTO = 'auto',
  POOL = 'pool',
  PRIVATE = 'priv',
  END = 'end'
}

interface StripeSubscriptionMetadata {
  id: string;
  priceId: string;
  productId: string;
  start: number;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  trialStart?: number;
  trialEnd?: number;
  cancelEnd?: number;
  productName: string;
  status: string;
  latestEventId: string;
  isExpert: boolean;
}

export enum RAMQAccreditationStatus {
  APPROVED = 'approved',
  MISSING = 'missing',
  PENDING_APPROVAL = 'pendingApproval',
  PENDING_SIGNATURE = 'pendingSignature',
  SIGNED = 'signed'
}

export interface FirestoreUser {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  specialty: SpecialtyName;
  practiceNumber: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  zipCode: string;
  province: string;
  fiscalYearStart: string;
  groupName: string;
  groupNumber: string;
  agencyName: string;
  agencyNumber: string;
  agencySwitchDate: string;
  stripeCustomerId: string;
  stripeSubscriptionMetadata: StripeSubscriptionMetadata | null;
  medavieUserName: string;
  medaviePassword: string;
  billingType: UserBillingType;
  defaultBillingType?: string;
  ramqUsername: string;
  ramqPassword: string;
  ramqAccreditationStatus?: RAMQAccreditationStatus;
}

const converter = {
  toFirestore: (user: FirestoreUser) => {
    const firestoreUser = {
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
      specialty: user.specialty,
      practiceNumber: user.practiceNumber,
      email: user.email,
      phone: user.phone,
      address: user.address,
      city: user.city,
      zipCode: user.zipCode,
      province: user.province,
      fiscalYearStart: user.fiscalYearStart,
      groupName: user.groupName,
      groupNumber: user.groupNumber,
      agencyName: user.agencyName,
      agencyNumber: user.agencyNumber,
      agencySwitchDate: user.agencySwitchDate ?? '',
      medavieUserName: user.medavieUserName,
      medaviePassword: user.medaviePassword,
      ramqUsername: user.ramqUsername,
      ramqPassword: user.ramqPassword,
      defaultBillingType: user.billingType === UserBillingType.POOL ? user.defaultBillingType : undefined
    };
    return JSON.parse(JSON.stringify(firestoreUser)) as FirestoreUser;
  },
  fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot) => {
    const data = snapshot.data();
    const stripeSubscriptionMetadata = data.stripeSubscriptionMetadata as StripeSubscriptionMetadata | undefined;

    return {
      ...data,
      id: data.id as string,
      title: (data.title ?? '') as string,
      firstName: (data.firstName ?? '') as string,
      lastName: (data.lastName ?? '') as string,
      specialty: data.specialty as SpecialtyName,
      practiceNumber: (data.practiceNumber ?? '') as string,
      email: (data.email ?? '') as string,
      phone: (data.phone ?? '') as string,
      address: (data.address ?? '') as string,
      city: (data.city ?? '') as string,
      zipCode: (data.zipCode ?? '') as string,
      province: (data.province ?? '') as string,
      fiscalYearStart: (data.fiscalYearStart ?? '') as string,
      groupName: (data.groupName ?? '') as string,
      groupNumber: (data.groupNumber ?? '') as string,
      agencyName: (data.agencyName ?? '') as string,
      agencyNumber: (data.agencyNumber ?? '') as string,
      agencySwitchDate: (data.agencySwitchDate ?? '') as string,
      medavieUserName: (data.medavieUserName ?? '') as string,
      medaviePassword: (data.medaviePassword ?? '') as string,
      ramqUsername: (data.ramqUsername ?? '') as string,
      ramqPassword: (data.ramqPassword ?? '') as string,
      billingType: (data.billingType ?? UserBillingType.PRIVATE) as string,
      defaultBillingType:
        data.billingType === UserBillingType.POOL
          ? ((data.defaultBillingType ?? DefaultBillingType.AUTO) as string)
          : undefined,
      stripeCustomerId: (data.stripeCustomerId ?? '') as string,
      stripeSubscriptionMetadata: stripeSubscriptionMetadata
        ? {
            id: stripeSubscriptionMetadata.id as string,
            priceId: stripeSubscriptionMetadata.priceId as string,
            productId: stripeSubscriptionMetadata.productId as string,
            start: stripeSubscriptionMetadata.start as number,
            currentPeriodEnd: stripeSubscriptionMetadata.currentPeriodEnd as number,
            trialEnd: stripeSubscriptionMetadata.trialEnd as number,
            trialStart: stripeSubscriptionMetadata.trialStart as number,
            cancelEnd: stripeSubscriptionMetadata.cancelEnd as number,
            productName: stripeSubscriptionMetadata.productName as string,
            status: stripeSubscriptionMetadata.status as string,
            latestEventId: stripeSubscriptionMetadata.latestEventId as string,
            isExpert: stripeSubscriptionMetadata.isExpert as boolean
          }
        : null
    } as FirestoreUser;
  }
};

const useFirestoreUserProfile = (
  userId: string
): [FirestoreUser | null, (user: FirestoreUser) => Promise<FirestoreUser | undefined>] => {
  const [user, setUser] = useState<FirestoreUser | null>(null);
  const documentRef = useRef<firebase.firestore.DocumentReference<FirestoreUser>>();

  useEffect(() => {
    if (!userId) return;

    documentRef.current = (firestore as firebase.firestore.Firestore)
      .collection('users')
      .withConverter(converter)
      .doc(userId);

    const unsubscribe = documentRef.current.withConverter(converter).onSnapshot((user) => {
      if (!user.exists) setUser(null);
      setUser(user.data() as FirestoreUser);
    });

    return () => unsubscribe();
  }, [userId]);

  const update = useCallback(
    async (stagedUser: FirestoreUser) => {
      if (!user) return;
      if (!documentRef.current) return;

      await documentRef.current.withConverter(converter).set(stagedUser, { merge: true });

      if (user.email !== stagedUser.email) {
        AuthenticationServiceProvider.getInstance().signOut();
      }

      const document = await documentRef.current.get();
      return document.data();
    },
    [user]
  );

  return [user, update];
};

export default useFirestoreUserProfile;
