import Button from 'application/components/button';
import FieldContainer from 'application/components/form-controls/field-container';
import Section from 'application/components/form-controls/section';
import TextInput from 'application/components/form-controls/text-input';
import useTranslate from 'application/hooks/use-translate';
import { FirestoreUser } from 'application/hooks/use-firestore-user-profile';
import React from 'react';
import ZipCodeInput from 'application/components/form-controls/zip-code-input';
import { ValidatedFieldProps } from 'application/hooks/use-validated-form';
import ProvinceSelectInput from 'application/components/form-controls/province-select-input';

interface Props {
  user: FirestoreUser;
  formIsValid: boolean;
  registerField: (field: string) => ValidatedFieldProps;
  onChange: (user: FirestoreUser) => void;
}

const SettingsAddress = ({ user, formIsValid: isValid, registerField, onChange }: Props) => {
  const translate = useTranslate('pages.settings.details.address');

  return (
    <Section label={translate('title')}>
      <FieldContainer label={translate('user.address.label')} helper={translate('user.address.helper')} required>
        <TextInput
          {...registerField('address')}
          value={user.address}
          onChange={(address) => onChange({ ...user, address })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.city')} required>
        <TextInput
          {...registerField('city')}
          value={user.city}
          onChange={(city) => onChange({ ...user, city })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.zip-code')} required>
        <ZipCodeInput
          {...registerField('zipCode')}
          value={user.zipCode}
          onChange={(zipCode) => onChange({ ...user, zipCode })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.province')} required>
        <ProvinceSelectInput
          {...registerField('province')}
          value={user.province}
          onChange={(province) => onChange({ ...user, province })}
          required
        />
      </FieldContainer>
      <FieldContainer label={translate('user.country')}>Canada</FieldContainer>

      <Section.Footer>
        <Button type="submit" disabled={!isValid} className="btn btn-primary">
          {translate('save')}
        </Button>
      </Section.Footer>
    </Section>
  );
};

export default SettingsAddress;
