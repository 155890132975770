import { ActOptimisationSpecification } from '../ActOptimisationSpecification';
import ActBillingContext from '../../optimisation/ActBillingContext';
import ActCodes from '../../../activity/act/model/ActCodes';

export class ActContainsUrinarySystemCode extends ActOptimisationSpecification {
  private readonly URINARY_SYSTEM_CODES = [
    '6087',
    '6088',
    '6000',
    '6003',
    '6039',
    '6012',
    '6008',
    '6089',
    '6011',
    '6005',
    '6090',
    '6006',
    '6091',
    '6029',
    '6007',
    '6100',
    '6101',
    '6200',
    '6084',
    '6199',
    '6217',
    '6204',
    '6221',
    '6222',
    '6223',
    '6092',
    '6213',
    '6080',
    '6081',
    '6214',
    '6320',
    '6322',
    '6804',
    '6327',
    '6325',
    '6161',
    '6162',
    '6019',
    '6015',
    '6016',
    '6044',
    '6024',
    '6110',
    '6111',
    '6330',
    '6331',
    '6332',
    '6334',
    '6337',
    '6335',
    '6341',
    '6333',
    '6305',
    '6315',
    '6324',
    '6340',
    '6328',
    '6329',
    '6318',
    '6323',
    '6339',
    '6348',
    '6350',
    '6010',
    '6013',
    '6020',
    '6023',
    '6025',
    '6017',
    '6018',
    '6049',
    '6022',
    '6001',
    '6159',
    '6113',
    '6114',
    '6205',
    '6002',
    '6038',
    '6041',
    '6042',
    '6052',
    '6220',
    '6168',
    '6301',
    '6352',
    '6311',
    '6312',
    '6336',
    '6277',
    '6343',
    '6344',
    '6383',
    '6357',
    '6358',
    '6402',
    '6403',
    '6423',
    '6030',
    '6031',
    '6032',
    '6033',
    '6034',
    '6190',
    '6210',
    '6211',
    '6035',
    '6215',
    '6219',
    '6362',
    '6021',
    '6093',
    '6037',
    '6014',
    '6136',
    '6446',
    '6447',
    '6448',
    '6449',
    '6450',
    '6373',
    '6417',
    '6374',
    '6454',
    '6384',
    '6347',
    '6366',
    '6367',
    '6368',
    '6369',
    '6302',
    '6370',
    '6371',
    '6372',
    '6295',
    '6296',
    '6297',
    '6298',
    '6306',
    '6307',
    '6308'
  ];

  isSatisfiedBy(context: ActBillingContext): boolean {
    const actCodes = new ActCodes(context.act.codes || []);

    return actCodes.isContainsSome(...this.URINARY_SYSTEM_CODES);
  }
}

export const actContainsUrinarySystemCode = () => new ActContainsUrinarySystemCode();
