import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React from 'react';
import CollapsableCategory from '../CollapsableCategory/CollapsableCategory';

function CollapsableCategoryTree({ initiallyOpen, categories, renderItem, level }) {
  return (
    <>
      {orderBy(categories, ['order'], ['asc']).map((category) => (
        <CollapsableCategory
          initiallyOpen={initiallyOpen}
          label={category.category}
          level={level}
          key={category.category}
        >
          {category.items && category.items.filter((item) => item !== undefined).map(renderItem)}
          {category.subCategories && (
            <CollapsableCategoryTree
              initiallyOpen={initiallyOpen}
              categories={category.subCategories}
              level={level + 1}
              renderItem={renderItem}
            />
          )}
        </CollapsableCategory>
      ))}
    </>
  );
}

CollapsableCategoryTree.defaultProps = {
  level: 0,
  initiallyOpen: false
};

CollapsableCategoryTree.propTypes = {
  initiallyOpen: PropTypes.bool,
  categories: PropTypes.array.isRequired,
  level: PropTypes.number,
  renderItem: PropTypes.func.isRequired
};

export default CollapsableCategoryTree;
