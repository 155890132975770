import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import FullScreenLoading from 'app/components/Loadings/FullScreenLoading/FullScreenLoading';
import DialogCenteredTitle from '../DialogCenteredTitle/DialogCenteredTitle';

export interface Props {
  xml: string;
  open: boolean;
  onClose: () => void;
  loading?: boolean;
}

export function XMLViewerDialog({ xml, open, onClose, loading }: Props) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogCenteredTitle label="XML" />
      <DialogContent>{loading ? <FullScreenLoading /> : <pre>{xml}</pre>}</DialogContent>
      <DialogActions>
        <Button data-testid="close-button" onClick={onClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default XMLViewerDialog;
