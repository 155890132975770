import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'patientEvent.title',
    defaultMessage: 'Événement patient'
  },
  close: {
    id: 'patientEvent.close',
    defaultMessage: 'Fermer'
  },
  erase: {
    id: 'patientEvent.erase',
    defaultMessage: 'Effacer'
  }
});
