import { pick, values as _values } from 'lodash';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import BackButton from '../../../components/button/NavigationHeaderButton/NavigationHeaderButton';
import { throwSubmissionErrorIfNecessary } from '../../../validations/common/reduxFormValidation';
import { updateAct } from '../../Act/actions';
import { selectLoggedUser } from '../../Authentication/selectors';
import LoadPreferences from '../../LoadPreferences/LoadPreferences';
import ActForm from '../ActForm';
import { actFormAdminFields, actFormFields } from '../constants';
import Act from '../../../../shared/domain/activity/act/model/Act';
import AuthUserDomain from '../../../../shared/domain/authentication/AuthUser';
import Permission from '../../../../shared/domain/authorization/model/Permission';
import { selectUserProfileInContext } from '../../User/selectors';
import User from '../../../../shared/domain/user/User';
import { validator } from '../../../validations/act/actValidation';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';

export interface OwnProps {
  act?: Act;
  formClasses: any;
}

export interface ReduxStateProps {
  loggedUser: AuthUserDomain;
  impersonatedUser: User;
  activities: any[];
}

export interface Props extends OwnProps, ReduxStateProps {}

const ModifyAct = ({ act: actProp, activities, loggedUser, formClasses, impersonatedUser }: Props) => {
  const location = useLocation();
  const { activityId } = useParams();
  const act = useMemo(
    () => actProp ?? activities.find((activity) => activity.id === activityId),
    [actProp, activities, activityId]
  );

  const actWithOnlineStatus = { ...act, onlineCreation: navigator?.onLine };

  const navigate = useNavigate();

  const onSent = (originalAct: Act) => (values, dispatch) => {
    const updatedValues = {
      ...values,
      status: ActivityStatus.SENT
    };
    validateAndSaveAct(originalAct, updatedValues, impersonatedUser, navigator.onLine, dispatch);
  };

  const onWait = (originalAct: Act) => (values, dispatch) => {
    const updatedValues = {
      ...values,
      status: ActivityStatus.WAITING
    };
    validateAndSaveAct(originalAct, updatedValues, impersonatedUser, navigator.onLine, dispatch);
  };

  const onSave = (originalAct: Act) => (values, dispatch) => {
    validateAndSaveAct(originalAct, values, impersonatedUser, navigator.onLine, dispatch);
  };

  const onCancel = (originalAct: Act) => (values, dispatch) => {
    const updatedValues = {
      ...values,
      status: ActivityStatus.CANCELED
    };
    validateAndSaveAct(originalAct, updatedValues, impersonatedUser, navigator.onLine, dispatch);
  };

  const isBillNumberMapEmpty = (): boolean => !act.billNumberMap || Object.keys(act.billNumberMap).length === 0;

  const getDisplayableFieldOnly = (act, loggedUser: AuthUserDomain) => {
    if (loggedUser.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS)) {
      return pick(act, _values(actFormAdminFields));
    }
    return pick(act, _values(actFormFields));
  };

  const validateAndSaveAct = (
    originalAct: Act,
    updatedValues: Partial<Act>,
    impersonatedUser: User,
    isOnline: boolean,
    dispatch: any
  ) => {
    const updatedValuesWithOnlineStatus = { ...updatedValues, onlineSave: isOnline };
    throwSubmissionErrorIfNecessary(
      validator.validateAct({ act: updatedValuesWithOnlineStatus, user: impersonatedUser })
    );
    dispatch(updateAct(originalAct, updatedValuesWithOnlineStatus));
    navigate({ pathname: '../..', search: location.search });
  };
  return (
    <LoadPreferences extraIsLoading={!actWithOnlineStatus}>
      <>
        <BackButton urlToGoOnClick="../.." position="left" preventRenderOnAdminConsole />
        <ActForm
          onSave={
            loggedUser.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS)
              ? onSave(actWithOnlineStatus)
              : undefined
          }
          onCancel={
            loggedUser.canSome(Permission.DO_DOCTOR_ACTIONS) && act.status === ActivityStatus.NEED_FIX
              ? onCancel(actWithOnlineStatus)
              : undefined
          }
          isBillNumberMapEmpty={isBillNumberMapEmpty}
          onSent={onSent(actWithOnlineStatus)}
          onWait={onWait(actWithOnlineStatus)}
          act={getDisplayableFieldOnly(actWithOnlineStatus, loggedUser)}
          classes={formClasses}
        />
      </>
    </LoadPreferences>
  );
};

export const mapStateToProps = createStructuredSelector({
  loggedUser: selectLoggedUser(),
  impersonatedUser: selectUserProfileInContext(),
  activities: selectActivitiesInContext()
});

export default connect(mapStateToProps)(ModifyAct);
