import { ActCode } from 'shared/core/activity/domain/Act';
import { AssociatedField } from 'app/codeSpecificLogic/requiredDetails/requiredDetail.type';
import dateCSST from 'app/codeSpecificLogic/requiredDetails/DateSST/dateCSST';

const CNESST_CODES = [
  '9900',
  '9901',
  '9903',
  '9904',
  '9905',
  '9907',
  '9908',
  '9909',
  '9910',
  '9911',
  '9912',
  '9913',
  '9914',
  '9915',
  '9916',
  '9919',
  '9921',
  '9922',
  '9926',
  '9927',
  '9928',
  '9929',
  '9930',
  '9933',
  '9935',
  '9936',
  '9938',
  '9939',
  '9942',
  '9944',
  '9946',
  '9947',
  '9948',
  '9949',
  '9954',
  '9970',
  '9971',
  '9976',
  '9977',
  '9978',
  '9979',
  '9981',
  '9982'
];

export const isCNESSTCode = ({ code }: ActCode): boolean => CNESST_CODES.includes(code);

const askEventDateForCNESSTCodes = (code: ActCode): AssociatedField[][] => {
  if (!isCNESSTCode(code)) return [];

  return [dateCSST.associatedFields];
};

export default askEventDateForCNESSTCodes;
