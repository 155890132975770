import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { makeStyles } from '@material-ui/styles';
import { DirectoryPhysicianType } from 'app/containers/ActForm/DoctorAutocomplete/DoctorAutocomplete';
import { Button, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { pushNewValue, updateValue } from '../../utils/reduxFormArrayField';
import { SingleLineField } from '../Fields';
import FormRow from '../FormRow/FormRow';
import { useDispatch, useSelector } from 'react-redux';
import { selectResidentsPreferencesFromUser } from 'app/doctorsRegistry/adapters/selectors';
import {
  addResidentToUserPreferences,
  removeResidentFromUserPreferences,
  updateResidentsFromUserPreferences
} from 'app/doctorsRegistry/adapters/actions';
import DoctorDialogWithDoctorRegistry from 'app/doctorsRegistry/ui/containers/DoctorDialogWithDoctorRegistry/DoctorDialogWithDoctorRegistry';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  flex: {
    flex: 1
  },
  flexColumn: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '20px'
  },
  flexRow: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center'
  },
  button: {
    minWidth: '40px',
    padding: 0
  },
  buttonContainer: {
    height: 38,
    paddingTop: 10
  }
});

interface Props extends WrappedFieldProps {
  label: string;
}

export const MultiLineWithResidentsSearchField = ({ input, label, meta }: Props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [nameList, setNameList] = useState<(string | null)[]>(['']);
  const residentsPreferences = useSelector(selectResidentsPreferencesFromUser());
  const dispatch = useDispatch();

  useEffect(() => {
    if (input.value) return;

    pushNewValue(input, '');
    // input is defined by redux form and will change every render so we have to ignore it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResidentChange = (resident, index) => {
    updateValue(input, resident.practiceNumber, index);
    const newNameList = [...nameList];

    newNameList[index] = !resident ? '' : `${resident.firstName} ${resident.lastName}`;

    setNameList(newNameList);
  };

  const handleLineChange = (text, index) => {
    updateValue(input, text.target.value, index);
    const newNameList = [...nameList];
    newNameList[index] = '';
    setNameList(newNameList);
  };

  const handleAddNewLine = () => {
    pushNewValue(input, '');
    const newNameList = [...nameList];
    newNameList.push('');
    setNameList(newNameList);
  };

  const handleSaveResident = (doctor) => {
    dispatch(addResidentToUserPreferences(doctor));
  };

  const handleOnDeleteFromFavorites = (doctor) => {
    dispatch(removeResidentFromUserPreferences(doctor));
  };

  const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'close') => {
    if (reason !== 'backdropClick') {
      setOpenDialog(false);
    }
  };

  return (
    <div className={classes.container}>
      {input.value
        ? input.value.map((text, index) => (
            <FormRow className={classes.flexColumn} key={index}>
              <div className={classes.flexRow}>
                <div className={classes.flex}>
                  <SingleLineField
                    label={label}
                    meta={{}}
                    onChange={(event) => handleLineChange(event, index)}
                    value={text}
                  />
                  <Typography>{nameList[index]}</Typography>
                </div>

                <div className={classes.buttonContainer}>
                  <Button variant="contained" onClick={() => setOpenDialog(true)} className={classes.button}>
                    <SearchIcon />
                  </Button>
                </div>

                <DoctorDialogWithDoctorRegistry
                  open={openDialog}
                  addToFavorites={handleSaveResident}
                  onClose={(event) => handleClose(event, 'close')}
                  onItemSelected={(value) => handleResidentChange(value, index)}
                  onSave={handleSaveResident}
                  type={DirectoryPhysicianType.RESIDENT}
                  preferences={residentsPreferences}
                  onAdvancedSave={updateResidentsFromUserPreferences}
                  openDialog={openDialog}
                  closeDialog={() => setOpenDialog(false)}
                  onDeleteDoctor={handleOnDeleteFromFavorites}
                  removeFromFavorites={handleOnDeleteFromFavorites}
                  onDialogShouldOpen={() => setOpenDialog(true)}
                />
              </div>
              {index + 1 === input.value.length && index < 3 ? (
                <IconButton onClick={handleAddNewLine}>
                  <AddIcon />
                </IconButton>
              ) : (
                ''
              )}
            </FormRow>
          ))
        : ''}
      {!input.value.length ? (
        <IconButton onClick={handleAddNewLine}>
          <AddIcon />
        </IconButton>
      ) : (
        ''
      )}
      {meta.error && <FormHelperText error={!!meta.error}>{meta.error}</FormHelperText>}
    </div>
  );
};

export default MultiLineWithResidentsSearchField;
