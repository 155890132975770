import User, { UserId } from '../../../shared/domain/user/User';
import API_BASE_URL from '../../../shared/server/apiConfig';
import request, { processResponse } from './apiUtils';
import RALPH_API_BASE_URL from './ralphApi';

export default class UserAPI {
  public static fetchProfile(userId: UserId): Promise<User> {
    const endpoint = `${API_BASE_URL}/users/${userId}/profile`;

    return processResponse(request(endpoint, { method: 'GET' }));
  }

  public static fetchStripeCustomerPortalLink(): Promise<{ url: string }> {
    const endpoint = `${RALPH_API_BASE_URL}/stripe/customer-portal`;

    return request(endpoint, { method: 'GET' })
      .then((result) => result.json())
      .catch((error) => error);
  }
}
