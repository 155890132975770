import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { updateActCodesFromUserPreferences } from '../../../../../containers/CodeSearchDialog/actions';
import AdvancedEditor from '../../../../shared/ui/GenericSearchDialog/FavoritesTab/AdvancedEditor/AdvancedEditor';
import FavoritesActCodesSelector from '../FavoritesActCodesSelector/FavoritesActCodesSelector';
import { selectCodesPreferencesFromUser } from '../../../../../containers/User/selectors';

export class EditableFavoritesActCodesTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false
    };

    this.toggleEditMode = this.toggleEditMode.bind(this);
  }

  toggleEditMode() {
    this.setState((state) => ({ editMode: !state.editMode }));
  }

  render() {
    const { favoritesActCodes, onConfirm, onClickItem, selectedCodes, onCancel, updateUserActCodePreferences } =
      this.props;
    const { editMode } = this.state;

    if (editMode) {
      return (
        <AdvancedEditor
          favorites={favoritesActCodes}
          onUpdateFavoriteItems={updateUserActCodePreferences}
          onClose={this.toggleEditMode}
        />
      );
    }

    return (
      <FavoritesActCodesSelector
        onEdit={this.toggleEditMode}
        onClickItem={onClickItem}
        selectedCodes={selectedCodes}
        favoritesActCodes={favoritesActCodes}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  }
}

EditableFavoritesActCodesTab.propTypes = {
  favoritesActCodes: PropTypes.array.isRequired,
  onClickItem: PropTypes.func.isRequired,
  selectedCodes: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  updateUserActCodePreferences: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  favoritesActCodes: selectCodesPreferencesFromUser()
});

const mapDispatchToProps = (dispatch) => ({
  updateUserActCodePreferences: bindActionCreators(updateActCodesFromUserPreferences, dispatch)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditableFavoritesActCodesTab);
