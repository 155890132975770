export const UPDATE_SEARCH_VALUE = 'updateSearchValue';
export const UPDATE_MAX_SEARCH_REACHED = 'updateMaxSearchReached';
export const UPDATE_INPUT_VALUE = 'updateInputValue';
export const UPDATE_RESULTS = 'updateResults';
export const UPDATE_RESULTS_FAILURE = 'updateResultsFailure';

export function updateSearchValue(value) {
  return {
    type: UPDATE_SEARCH_VALUE,
    value
  };
}

export function updateMaxSearchReached(value) {
  return {
    type: UPDATE_MAX_SEARCH_REACHED,
    value
  };
}

export function updateInputValue(value) {
  return {
    type: UPDATE_INPUT_VALUE,
    value
  };
}

export function updateResults(results) {
  return {
    type: UPDATE_RESULTS,
    results
  };
}

export function updateResultsFailure(error) {
  return {
    type: UPDATE_RESULTS_FAILURE,
    error
  };
}
