import classNames from 'classnames';
import React from 'react';
import { useDebounce, useToggle } from 'react-use';

interface Props {
  children: React.ReactNode;
  className: string;
  onClick: () => void;
  disabled?: boolean;
}

const HoldButton = ({ children, className, onClick, disabled }: Props) => {
  const [isHolding, setIsHolding] = useToggle(false);

  const [, cancelDebounce] = useDebounce(
    () => {
      if (!isHolding) return;

      setIsHolding(false);
      onClick();
    },
    2000,
    [isHolding]
  );

  const handleCancelDebounce = () => {
    setIsHolding(false);
    cancelDebounce();
  };

  return (
    <button
      type="button"
      className={classNames('relative', className)}
      onMouseDown={() => setIsHolding(true)}
      onMouseUp={handleCancelDebounce}
      onMouseLeave={handleCancelDebounce}
      disabled={disabled}
    >
      {isHolding && (
        <div
          className="absolute left-0 top-0 z-0 h-full bg-black opacity-20"
          style={{
            animation: 'hold-button-progress 2s linear infinite'
          }}
        />
      )}

      <div className="z-10">{children}</div>
    </button>
  );
};

export default HoldButton;
