import classNames from 'classnames';
import React from 'react';
import { useToggle } from 'react-use';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Collapse = ({ children, className }: Props) => {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <div
      className={classNames(
        'collapse collapse-arrow cursor-pointer border',
        {
          'collapse-open': expanded,
          'collapse-close': !expanded
        },
        className
      )}
      onClick={toggleExpanded}
    >
      {children}
    </div>
  );
};

export default Collapse;
