import some from 'lodash/some';
import { selectActivitiesInContext } from '../../../../containers/Activities/selectors';
import { getStore } from '../../../../reduxStore/configureStore';
import Act, { isAct } from '../../../../../shared/domain/activity/act/model/Act';
import { getFeatureConfig } from '../../../../../userCustomization/featureToggle';
import { DAILY_CHRONIC_PAIN_PLAN } from '../../../../../userCustomization/featureNames';
import ActivityStatus from '../../../../../shared/core/activity/domain/ActivityStatus';

const DAILY_CHRONIC_PAIN_PLAN_CODE = '19342';
const PATIENT_PAIN_PLAN_CODES = ['15074', '15075'];
const NO_WARNINGS = [];

const dailyChronicPainPlanWarning = (form: Partial<Act>): string[] => {
  const jsForm = form as Act;

  if (!getFeatureConfig(DAILY_CHRONIC_PAIN_PLAN, false)) {
    return NO_WARNINGS;
  }

  if (!patientPainPlanIsBilled(jsForm.codes)) {
    return NO_WARNINGS;
  }

  if (doctorHasBilledDailyChronicPainPlanOnDate(jsForm.date)) {
    return NO_WARNINGS;
  }

  return [composeWarningAccordingToNetworkStatus()];
};

const patientPainPlanIsBilled = (codes) => some(codes, ({ code }): boolean => PATIENT_PAIN_PLAN_CODES.includes(code));

const doctorHasBilledDailyChronicPainPlanOnDate = (date): boolean => {
  const activitiesInContext = selectActivitiesInContext()(getStore().getState()) || [];
  const actsOfTheDay = activitiesInContext.filter(notCanceledActsOfTheDay(date));
  const dailyChronicPainPlan = actsOfTheDay.find(actHasADailyChronicPainPlan);

  return !!dailyChronicPainPlan;
};

const notCanceledActsOfTheDay = (date) => (activity) =>
  isAct(activity) && activity.status !== ActivityStatus.CANCELED && activity.date === date;

const actHasADailyChronicPainPlan = ({ codes }) => codes.find(({ code }) => code === DAILY_CHRONIC_PAIN_PLAN_CODE);

const composeWarningAccordingToNetworkStatus = () => {
  const offlineWarning =
    "Lorsque vous serez en ligne, le forfait quotidien de douleur chronique (19342) sera automatiquement ajouté s'il est inexistant.";
  const onlineWarning =
    "Le forfait quotidien de douleur chronique (19342) sera automatiquement ajouté lors de l'envoi de ce carton.";

  if (!window.navigator.onLine) {
    return offlineWarning;
  }

  return onlineWarning;
};

export default dailyChronicPainPlanWarning;
