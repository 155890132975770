import Act, { ActCode, isAct } from '../../../../../shared/domain/activity/act/model/Act';
import { isEmpty } from '../../../../../shared/utils/objectManipulation';
import Activity from '../../../../../shared/domain/activity/global/model/Activity';
import isDefined from '../../../../../shared/utils/isDefined';
import { extractRule28ActCodesAndNotPg28WithPdtOrSurgeryNatureTypeActCodes } from '../../../../codeSpecificLogic/ui/rules/add7000XCodesBilledOnlyWithPg28CodesRule/extractRule28ActCodesAndNotPg28WithPdtOrSurgeryNatureTypeActCodes';

const NO_WARNINGS = [];

function actCode7000XCannotBeBilledWithNoPdtOrSurgeryPg28ActCodeValidator(form: Partial<Act>): string[] {
  const jsForm = form as Activity;

  if (isEmpty(jsForm) || !isAct(jsForm) || !isDefined(jsForm.place)) {
    return NO_WARNINGS;
  }

  const { rule28ActCodes, notPg28WithPDTorSurgeryNatureType } =
    extractRule28ActCodesAndNotPg28WithPdtOrSurgeryNatureTypeActCodes(jsForm);

  if (rule28ActCodes.size() > 0 && notPg28WithPDTorSurgeryNatureType.size() > 0) {
    return notPg28WithPDTorSurgeryNatureType.map<string>(composeWeekWarningMessage).getAll();
  }

  return NO_WARNINGS;
}

const composeWeekWarningMessage = (code: ActCode) =>
  `Le code ${code.code} ne permet pas la facturation du
        code 7000X à moins que celui-ci était pour un autre acte PG-28 facturé par un autre médecin.`;

export default actCode7000XCannotBeBilledWithNoPdtOrSurgeryPg28ActCodeValidator;
