import PropTypes from 'prop-types';

export function ExpandableTableRowContent({ children }) {
  return children;
}

ExpandableTableRowContent.defaultProps = {
  canOpen: true
};

ExpandableTableRowContent.propTypes = {
  // canOpen is used indirectly in ExpandableTableRow
  canOpen: PropTypes.bool, // eslint-disable-line
  children: PropTypes.node.isRequired
};

export default ExpandableTableRowContent;
