import groupBy from 'lodash/groupBy';
import { parseHoursWorkedNumberToString, parseHoursWorkedStringToNumber } from '../../../../ramq/ramqCommonUtils';

export type LumpSumDetail = {
  date: number;
  code: string;
  sector: string;
  hoursWorked: string;
  amount: number;
};

export type HoursWorkedString = string;

class LumpSumDetailMergeService {
  static mergeByCodeAndActivityArea(details: LumpSumDetail[]): LumpSumDetail[] {
    const detailsByCodeSector = groupBy(details, (detail) => `${detail.code}-${detail.sector}`);

    return Object.values(detailsByCodeSector).map((group: any) => ({
      ...group[0],
      hoursWorked: LumpSumDetailMergeService.sumHoursWorkedString(group),
      amount: group.reduce((acc, { amount }) => acc + amount, 0)
    }));
  }

  private static sumHoursWorkedString(group: LumpSumDetail[]): HoursWorkedString {
    return parseHoursWorkedNumberToString(
      group.reduce((acc, { hoursWorked }) => acc + parseHoursWorkedStringToNumber(hoursWorked), 0)
    );
  }
}

export default LumpSumDetailMergeService;
