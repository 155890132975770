import React from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import PeriodPlaceSelector from '../PeriodPlaceSelector/PeriodPlaceSelector';

export interface Props extends WrappedFieldProps {}

const PeriodPlaceField: React.FunctionComponent<Props> = ({ input }) => (
  <PeriodPlaceSelector onChangePlaceContent={(place) => input.onChange(place)} selectedPlace={input.value} />
);

export default PeriodPlaceField;
