import { selectLoggedUser } from '../../containers/Authentication/selectors';
import { getStore } from '../../reduxStore/configureStore';
import { CorrectionProps } from '../../../shared/core/admin/domain/CorrectionProps';
import Correction from '../../../shared/domain/user/admin/correction/Correction';

export function createManualCorrection(correction: string, isAcknowledgeable: boolean = false): CorrectionProps {
  return Correction.create(
    correction,
    selectLoggedUser()(getStore().getState()).getEmail(),
    isAcknowledgeable
  ).getProps();
}

export default createManualCorrection;
