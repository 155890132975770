class Specialty {
  private readonly configs: Map<string, any>;

  constructor(configs: Map<string, any>) {
    this.configs = configs;
  }

  getConfig(name: string) {
    if (this.configs.has(name)) {
      return this.configs.get(name);
    }

    return undefined;
  }
}

export default Specialty;
