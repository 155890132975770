import { find } from 'lodash';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import {
  AM_PERDIEM,
  ANEST_MIXTE_PREFIX,
  CLINICAL_ACTIVITIES_WITH_SUPERVISION_SUFFIX,
  CMS_MEDICAL_ACTIVITIES_SUFFIX,
  CMS_MEDICAL_ACTIVITIES_WITH_TELEMEDICINE_SUFFIX,
  DEFAULT_CLINICAL_ACTIVITIES_SUFFIX,
  DEFAULT_MIXTE_PREFIX,
  PM_PERDIEM
} from '../../../../shared/periods/common/constants';
import {
  allClinicalSupervisionAMMandatory,
  allClinicalSupervisionPMMandatory
} from '../../../../shared/ramq/teachingCodes/constants';
import isDefined from '../../../../shared/utils/isDefined';
import {
  createMixteDayDate,
  getDefaultMixteCodeAccordingToLegacyCovidContextElement,
  hasActivitiesContainingLegacyCovidContextElement,
  hasActivitiesInClinicalSupervision,
  hasActivitiesThatPreventsMixteGeneration,
  isInTheAfternoon,
  isInTheMorning,
  overlapsAfternoon,
  overlapsMorning
} from './mixteUtils';
import {
  getDefaultMixtesuffixAccordingTo241UnderstandableLetterRules,
  hasActivitiesContaining241UnderstandableLetterCode
} from './lu241/understandableLetter241SpecificRules';
import {
  TELEMEDECINE_PAR_TELEPHONE,
  TELEMEDECINE_PAR_VISIOCONSULTATION
} from '../../../../shared/ramq/contextElements/lettreEntente241ContextElements';
import { RAMQPlaces } from '../../../../shared/ramq/RamqPlaces';

const hasAMClinicalSupervisionCode = (codes) =>
  isDefined(
    find(
      codes,
      (code) => allClinicalSupervisionAMMandatory.includes(code.code) || (code.amPm && code.amPm.includes(AM_PERDIEM))
    )
  );

const hasPMClinicalSupervisionCode = (codes) =>
  isDefined(
    find(
      codes,
      (code) => allClinicalSupervisionPMMandatory.includes(code.code) || (code.amPm && code.amPm.includes(PM_PERDIEM))
    )
  );

const getActStartTime = (act) => act.remp || act.start;
const computePerdiemsAccordingToActs = (acts) => {
  const perdiemInformation = [];
  if (!hasActivitiesThatPreventsMixteGeneration(acts)) {
    const actsMadeInAM = acts.filter((act) => {
      const startTime = getActStartTime(act);
      return (
        isInTheMorning(startTime) ||
        isInTheMorning(act.end) ||
        overlapsMorning(startTime, act.end) ||
        hasAMClinicalSupervisionCode(act.codes)
      );
    });

    const actsMadeInPM = acts.filter((act) => {
      const startTime = getActStartTime(act);
      return (
        isInTheAfternoon(startTime) ||
        isInTheAfternoon(act.end) ||
        overlapsAfternoon(startTime, act.end) ||
        hasPMClinicalSupervisionCode(act.codes)
      );
    });

    syncMixtePerdiem(actsMadeInAM, AM_PERDIEM, perdiemInformation);
    syncMixtePerdiem(actsMadeInPM, PM_PERDIEM, perdiemInformation);
  }
  return perdiemInformation;
};

const actPerformedInTelemedicineOrRemote = ({ contextElements = [] }) =>
  contextElements.includes(TELEMEDECINE_PAR_TELEPHONE) || contextElements.includes(TELEMEDECINE_PAR_VISIOCONSULTATION);

function isSomeActsPerformedInTelemedicineRemote(actsPerformedInCmsPlaces) {
  return (
    actsPerformedInCmsPlaces.some(actPerformedInTelemedicineOrRemote) ||
    actsPerformedInCmsPlaces.some(({ codes = [] }) => codes.some((code) => code.telemedicineOrRemote === true))
  );
}

const syncMixtePerdiem = (acts, perdiem, perdiemInformation) => {
  if (acts.length > 0) {
    const date = createMixteDayDate(acts[0].date);
    const userSpecialty = selectSpecialty()(getStore().getState());

    const prefix = [SpecialtyName.ANESTHESIOLOGIST, SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST].includes(userSpecialty)
      ? ANEST_MIXTE_PREFIX
      : DEFAULT_MIXTE_PREFIX;
    let suffix = DEFAULT_CLINICAL_ACTIVITIES_SUFFIX;
    let activityArea;

    const actsPerformedInCmsPlaces = acts.filter((act) => RAMQPlaces.isCms(act.place?.number));

    if (actsPerformedInCmsPlaces.length > 0) {
      if (isSomeActsPerformedInTelemedicineRemote(actsPerformedInCmsPlaces)) {
        suffix = CMS_MEDICAL_ACTIVITIES_WITH_TELEMEDICINE_SUFFIX;
      } else {
        suffix = CMS_MEDICAL_ACTIVITIES_SUFFIX;
      }
    } else if (hasActivitiesInClinicalSupervision(acts)) {
      suffix = CLINICAL_ACTIVITIES_WITH_SUPERVISION_SUFFIX;
    } else if (hasActivitiesContainingLegacyCovidContextElement(acts)) {
      suffix = getDefaultMixteCodeAccordingToLegacyCovidContextElement(acts);
    } else if (hasActivitiesContaining241UnderstandableLetterCode(acts)) {
      const { suffix: mixteSuffix, activityArea: mixteActivityArea } =
        getDefaultMixtesuffixAccordingTo241UnderstandableLetterRules(acts);

      suffix = mixteSuffix || suffix;
      activityArea = mixteActivityArea;
    }

    perdiemInformation.push({ perdiem, date, code: prefix + suffix, activityArea });
  }
};

export default computePerdiemsAccordingToActs;
