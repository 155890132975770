import queryString from 'qs';
import isDefined from '../../../../shared/utils/isDefined';
import ActivityFilterApplier from '../FilterApplier/ActivityFilterApplier';

class SearchUrlParameterController {
  private filterApplier: ActivityFilterApplier;

  constructor(filterApplier) {
    this.filterApplier = filterApplier;
  }

  newSearchQuery(filters, newFilter: any = null) {
    const updatedFilters = newFilter ? this.filterApplier.apply(filters, newFilter) : filters;
    const search = queryString.stringify(updatedFilters, { skipNulls: true, indices: true });

    return isDefined(search) ? search : undefined;
  }
}

export default SearchUrlParameterController;
