import Hidden from '@material-ui/core/Hidden';
import Portal from '@material-ui/core/Portal';
import PropTypes from 'prop-types';
import React from 'react';

export const ON_BOTTOM_MENU_CONTAINER_ID = 'on-bottom-menu-container';
const getContainerOnTopOfBottomNavigation = () => document.getElementById(ON_BOTTOM_MENU_CONTAINER_ID);
function OnTopBottomNavigationPortal({ children }) {
  return (
    <>
      <Hidden xsDown>{children}</Hidden>
      <Hidden smUp>
        <Portal container={getContainerOnTopOfBottomNavigation()}>{children}</Portal>
      </Hidden>
    </>
  );
}

OnTopBottomNavigationPortal.propTypes = {
  children: PropTypes.node.isRequired
};

export default OnTopBottomNavigationPortal;
