import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import TRAVEL_FEES_TYPES from '../../../shared/ramq/domainValues/travelFeesTypes';
import AddFeeButton from './AddFeeButton';
import CostOfStayFee from './Fees/CostOfStayFee';
import TransportFee from './Fees/TransportFee';
import TravelTimeFee from './Fees/TravelTimeFee';

const styles = (theme) => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
});

const feesTypeToComponents = {
  [TRAVEL_FEES_TYPES.transportFee]: TransportFee,
  [TRAVEL_FEES_TYPES.travelTimeFee]: TravelTimeFee,
  [TRAVEL_FEES_TYPES.costOfStayFee]: CostOfStayFee
};

function TravelExpensesFees({ fields: reduxFormFields, meta, classes }) {
  const translate = useTranslate();
  return (
    <div id={reduxFormFields.name}>
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
      {reduxFormFields.map((fieldName, fieldIndex, fields) => {
        const FeeComponent = feesTypeToComponents[reduxFormFields.get(fieldIndex).type];
        return <FeeComponent key={fieldName} fieldName={fieldName} fieldIndex={fieldIndex} fields={fields} />;
      })}
      {reduxFormFields.length < 10 && (
        <div className={classes.buttonsWrapper}>
          <AddFeeButton
            reduxFormFields={reduxFormFields}
            expensesType={TRAVEL_FEES_TYPES.transportFee}
            label={translate('travel-expenses.transportation')}
          />
          <AddFeeButton
            reduxFormFields={reduxFormFields}
            expensesType={TRAVEL_FEES_TYPES.travelTimeFee}
            label={translate('travel-expenses.time-fees')}
          />
          <AddFeeButton
            reduxFormFields={reduxFormFields}
            expensesType={TRAVEL_FEES_TYPES.costOfStayFee}
            label={translate('travel-expenses.stay')}
          />
        </div>
      )}
    </div>
  );
}

TravelExpensesFees.propTypes = {
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default withStyles(styles)(TravelExpensesFees);
