import { deburr } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import diagnosticsJson from '../data/diagnostics.json';

export interface RAMQDiagnostic {
  code: string;
  description: string;
  effectiveEndDate: string | null;
  effectiveStartDate: string;
  medicalClassificationSystemId: string;
}

export class RAMQDiagnostics {
  private static ramqDiagnostics: Map<string, RAMQDiagnostic>;

  static get diagnosticsMap() {
    if (!this.ramqDiagnostics) {
      this.ramqDiagnostics = this.buildRamqDict();
    }

    return this.ramqDiagnostics;
  }

  private static buildRamqDict(): Map<string, RAMQDiagnostic> {
    const diagnostics = new Map<string, RAMQDiagnostic>();
    (diagnosticsJson as RAMQDiagnostic[]).forEach((entry) => diagnostics.set(entry.code, entry));
    return diagnostics;
  }

  public static get(code: string): RAMQDiagnostic | undefined {
    return this.diagnosticsMap.get(code);
  }

  public static isEffectiveOn(ramqDiagnostic: RAMQDiagnostic, serviceDate: Moment | number | undefined): boolean {
    const date = moment(serviceDate); // When serviceDate is undefined, fallback on current date

    if (!ramqDiagnostic.effectiveEndDate) {
      return date.isSameOrAfter(moment(ramqDiagnostic.effectiveStartDate));
    }

    return date.isBetween(
      moment(ramqDiagnostic.effectiveStartDate),
      moment(ramqDiagnostic.effectiveEndDate),
      undefined, // Granularity
      '[]' // Inclusivity
    );
  }

  public static search(
    searchTerm: string,
    medicalClassificationSystemId: string,
    serviceDate: Moment | number | undefined = undefined,
    maxResult: number = 21
  ): RAMQDiagnostic[] {
    const searchTokens = deburr(searchTerm).toLowerCase().split(' ');
    const date = serviceDate ? moment(serviceDate) : null;

    const foundDiagnostics: RAMQDiagnostic[] = [];
    for (const diagnostic of Array.from(this.diagnosticsMap.values())) {
      const descriptionLower = deburr(diagnostic.description).toLowerCase();
      if (
        (!date || this.isEffectiveOn(diagnostic, date)) &&
        diagnostic.medicalClassificationSystemId === medicalClassificationSystemId &&
        searchTokens.every(
          (searchToken) => diagnostic.code.toLowerCase() === searchToken || descriptionLower.includes(searchToken)
        )
      ) {
        foundDiagnostics.push(diagnostic);
      }
      if (foundDiagnostics.length === maxResult) {
        break;
      }
    }

    return foundDiagnostics;
  }
}
