import { SAVE_TRAINING, SAVE_TRAINING_FAILURE } from './actions';

export const initialState = {
  error: undefined
};

function trainingsReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_TRAINING:
      return {
        ...state,
        error: undefined
      };
    case SAVE_TRAINING_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}

export default trainingsReducer;
