import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { currencyFormat } from '../../../../../../utils/wordFormatUtilities';
import StatementSection from '../../../../../components/Table/Statement/StatementSection/StatementSection';
import { StatementLine } from './StatementDoctorInfo';
import StatementSectionContainer from './StatementSectionContainer';
import isDefined from '../../../../../../shared/utils/isDefined';

export function StatementNetAmount({ generalInformation }) {
  return (
    <StatementSectionContainer>
      <Grid item xs={6} sm={6}>
        <StatementSection
          primaryLine={StatementLine('Montant net:', currencyFormat(generalInformation.netAmountInfo.netAmount))}
          secondaryLines={[
            isDefined(generalInformation.netAmountInfo.transferNumber)
              ? StatementLine('Numéro de virement', generalInformation.netAmountInfo.transferNumber)
              : null
          ]}
        />
      </Grid>
    </StatementSectionContainer>
  );
}

StatementNetAmount.propTypes = {
  generalInformation: PropTypes.object.isRequired
};

export default StatementNetAmount;
