import FavoriteIcon from '@material-ui/icons/Favorite';
import PropTypes from 'prop-types';
import React from 'react';
import CategoriesList from '../../../../components/List/CategoriesList/CategoriesList';
import SwipeableTabs from '../../../../components/SwipeableTabs/SwipeableTabs';
import CodeSearchDialog from '../../../../containers/CodeSearchDialog/CodeSearchDialog';

export function LimitedDiagnosticDialog({ onItemSelected, open, onClose, diagnosticList, initiallyOpen }) {
  return (
    <CodeSearchDialog open={open} onClose={onClose}>
      <SwipeableTabs
        tabs={[
          {
            key: 'favorites',
            icon: <FavoriteIcon />,
            component: (
              <CategoriesList
                list={diagnosticList}
                onItemSelected={onItemSelected}
                onClose={onClose}
                initiallyOpen={initiallyOpen}
              />
            )
          }
        ]}
      />
    </CodeSearchDialog>
  );
}

LimitedDiagnosticDialog.propTypes = {
  initiallyOpen: PropTypes.bool,
  diagnosticList: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

LimitedDiagnosticDialog.defaultProps = {
  initiallyOpen: false
};

export default LimitedDiagnosticDialog;
