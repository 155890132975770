import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { print, printEnd } from '../../../../containers/ReportsRoute/Statement/actions';
import selectPrinting from '../../../../containers/ReportsRoute/Statement/selectors';
import getHtmlToPrint from '../../../libs/CordovaReactToPrint/CordovaReactToPrint';

export class MobilePrintButton extends React.Component {
  componentDidUpdate(prevProps) {
    // Because we need to make sure any components (like StatementReport) that watch for the printing value in redux store
    // is re rendered before we print its html.
    if (!prevProps.printing && this.props.printing) {
      this.printMobile();
      this.props.onPrintEnd();
    }
  }

  printMobile = async () => {
    const htmlToConvertToPdf = await getHtmlToPrint(this.props.componentToPrintRef);
    window.pdf.fromData(htmlToConvertToPdf, { type: 'share' });
  };

  render() {
    const { onPrint, ButtonComponent } = this.props;
    return React.cloneElement(ButtonComponent(), { onClick: onPrint });
  }
}

MobilePrintButton.propTypes = {
  printing: PropTypes.bool.isRequired,
  onPrint: PropTypes.func.isRequired,
  onPrintEnd: PropTypes.func.isRequired,
  ButtonComponent: PropTypes.func.isRequired,
  componentToPrintRef: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  onPrint: print,
  onPrintEnd: printEnd
};

export const mapStateToProps = createStructuredSelector({
  printing: selectPrinting()
});

export default connect(mapStateToProps, mapDispatchToProps)(MobilePrintButton);
