import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, { useRef } from 'react';
import withStyles, { StyledComponentProps, StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import { currencyFormatWithoutSymbol } from '../../../../utils/wordFormatUtilities';
import { trimStringForSpacesAndLeadingZeroes } from '../../../../shared/ramq/ramqAmountUtils';
import { padStringWithZeros } from '../../../../shared/ramq/ramqCommonUtils';

interface Props extends StyledComponentProps {
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const CashAmountInput: React.FunctionComponent<Props> = ({ value, onChange, onKeyDown, classes }) => {
  const cashAmountInput = useRef<HTMLInputElement>();

  const putCursorAtTheEnd = () => {
    cashAmountInput.current!.selectionStart = 10;
  };

  return (
    <Input
      classes={{
        input: classes!.inputAlignRight
      }}
      autoFocus
      inputRef={cashAmountInput}
      endAdornment={<InputAdornment position="end">$</InputAdornment>}
      inputProps={{ type: 'tel', pattern: '[0-9]*', maxLength: 10, style: { width: '100%' } }}
      value={currencyFormatWithoutSymbol(value)}
      onKeyDown={onKeyDown}
      onClick={putCursorAtTheEnd}
      onChange={(evt) => onChange(convertFormattedToRegularAmount(evt.target.value))}
    />
  );
};

const convertFormattedToRegularAmount = (value) => {
  const cleanedValue = trimStringForSpacesAndLeadingZeroes(value.replace(',', ''));
  const paddedValue = cleanedValue.length < 3 ? padStringWithZeros(cleanedValue, 3) : cleanedValue;

  return `${paddedValue.substring(0, paddedValue.length - 2)}.${paddedValue.substring(paddedValue.length - 2)}`;
};

export const styles: StyleRulesCallback<Theme, Props> = () => ({
  inputAlignRight: {
    textAlign: 'right',
    cursor: 'pointer'
  }
});

export default withStyles(styles)(CashAmountInput);
