import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { updateUserEmail } from 'app/infrastructure/api/api';
import { logError } from 'shared/utils/plog';
import resolveLanguage from 'utils/resolveLanguage';
import moment from 'moment';
import {
  CODES_PREFERENCES,
  CONTEXT_ELEMENT_PREFERENCES,
  DIAGNOSTIC_PREFERENCES,
  PLACES_PREFERENCES,
  LUMP_SUMS_PREFERENCES
} from '../../../shared/collection/collectionConstants';
import fetchSpecialtyContextElements from '../../favorite/contextElement/adapters/sagas';
import {
  codesPreferencesCollectionRef,
  contextElementsPreferencesCollectionRef,
  diagnosticCodesPreferencesCollectionRef,
  lumpSumPreferencesCollectionRef,
  placesPreferencesCollectionRef,
  usersCollectionRef
} from '../../firebase/collection/collectionReferences';
import { syncCollection } from '../../firebase/collection/collectionSync';
import {
  removeDocumentInUserContext,
  setDocument,
  setDocumentInUserContext,
  updateCollectionInUserContext
} from '../../firebase/document/documentSagas';
import {
  ADD_CODE,
  addCodeToUserPreferenceFailure,
  EDIT_CODE,
  editCodeFromUserPreferenceFailure,
  GET_CODES,
  getCodesPreferencesFailure,
  getCodesPreferencesSuccess,
  REMOVE_CODE,
  removeCodeFromUserPreferenceFailure,
  UPDATE_CODE_COLLECTION,
  updateActCodesFromUserPreferencesFailure,
  updateActCodesFromUserPreferencesSuccess
} from '../CodeSearchDialog/actions';
import {
  addPlaceToUserPreferenceFailure,
  GET_PLACES,
  getPlacesPreferencesFailure,
  getPlacesPreferencesSuccess,
  REMOVE_PLACE,
  removePlaceFromUserPreferenceFailure,
  SAVE_PLACE,
  UPDATE_PLACE_SIGNATORY
} from '../PlacesPreferencesDialog/actions';
import { updatePlaceSignatorySaga } from '../PlacesPreferencesDialog/sagas';
import {
  GET_USER_PROFILE,
  SAVE_USER_PROFILE,
  saveUserProfileFailure,
  setUserProfileInContext,
  setIsUserProfileReady,
  updateLumpSumsFromUserPreferencesSuccess,
  updateLumpSumsFromUserPreferencesFailure,
  GET_LUMP_SUMS,
  getLumpSumsPreferencesSuccess,
  getLumpSumsPreferencesFailure,
  ADD_LUMP_SUM_TO_USER_PREFERENCES,
  UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES,
  REMOVE_LUMP_SUM_FROM_USER_PREFERENCES,
  changeUserLanguage,
  CHANGE_USER_LANGUAGE
} from './actions';
import { selectUserIdInContext } from './selectors';
import { fetchSpecialtyFavoritesActCodes, fetchSpecialtyPresetActCodes } from '../../favorite/actCode/adapters/sagas';
import {
  GET_SPECIALTY_FAVORITES_CODES,
  GET_SPECIALTY_PRESET_CODES,
  getSpecialtyFavoritesActCodes,
  getSpecialtyFavoritesActCodesFailure,
  getSpecialtyFavoritesActCodesSuccess,
  getSpecialtyPresetActCodes,
  getSpecialtyPresetActCodesFailure,
  getSpecialtyPresetActCodesSuccess
} from '../../favorite/actCode/adapters/actions';
import {
  ADD_DIAGNOSTIC_CODE,
  addDiagnosticCodeToUserPreferenceFailure,
  EDIT_DIAGNOSTIC_CODE,
  editDiagnosticCodeFromUserPreferenceFailure,
  GET_DIAGNOSTIC_CODES_PREFERENCES,
  GET_SPECIALTY_DIAGNOSTIC_CODES,
  getDiagnosticCodesPreferencesFailure,
  getDiagnosticCodesPreferencesSuccess,
  getSpecialtyDiagnosticCodes,
  getSpecialtyDiagnosticCodesFailure,
  getSpecialtyDiagnosticCodesSuccess,
  REMOVE_DIAGNOSTIC_CODE,
  removeDiagnosticCodeFromUserPreferenceFailure,
  UPDATE_DIAGNOSTIC_CODE_COLLECTION,
  updateDiagnosticCodesFromUserPreferencesFailure,
  updateDiagnosticCodesFromUserPreferencesSuccess
} from '../../favorite/diagnosticCode/adapters/actions';
import fetchSpecialtyDiagnosticCodes from '../../favorite/diagnosticCode/adapters/sagas';
import {
  ADD_CONTEXT_ELEMENT,
  addContextElementToUserPreferencesFailure,
  EDIT_CONTEXT_ELEMENT,
  editContextElementFromUserPreferencesFailure,
  GET_CONTEXT_ELEMENTS_PREFERENCES,
  GET_SPECIALTY_CONTEXT_ELEMENTS,
  getContextElementsPreferencesFailure,
  getContextElementsPreferencesSuccess,
  getSpecialtyContextElementsFailure,
  getSpecialtyContextElementsSuccess,
  REMOVE_CONTEXT_ELEMENT,
  removeContextElementFromUserPreferencesFailure,
  UPDATE_CONTEXT_ELEMENT_COLLECTION,
  updateContextElementFromUserPreferencesFailure,
  updateContextElementFromUserPreferencesSuccess
} from '../../favorite/contextElement/adapters/actions';
import { selectLoggedUser } from '../Authentication/selectors';

export default function* userSagas() {
  yield takeEvery(SAVE_PLACE, setDocumentInUserContext, PLACES_PREFERENCES, addPlaceToUserPreferenceFailure);
  yield takeEvery(ADD_CODE, setDocumentInUserContext, CODES_PREFERENCES, addCodeToUserPreferenceFailure);
  yield takeEvery(EDIT_CODE, setDocumentInUserContext, CODES_PREFERENCES, editCodeFromUserPreferenceFailure);
  yield takeEvery(
    UPDATE_CODE_COLLECTION,
    updateCollectionInUserContext,
    CODES_PREFERENCES,
    updateActCodesFromUserPreferencesSuccess,
    updateActCodesFromUserPreferencesFailure
  );
  yield takeLatest(REMOVE_PLACE, removeDocumentInUserContext, PLACES_PREFERENCES, removePlaceFromUserPreferenceFailure);
  yield takeLatest(REMOVE_CODE, removeDocumentInUserContext, CODES_PREFERENCES, removeCodeFromUserPreferenceFailure);

  yield takeLatest(
    GET_PLACES,
    getPreferences,
    placesPreferencesCollectionRef,
    getPlacesPreferencesSuccess,
    getPlacesPreferencesFailure
  );
  yield takeLatest(
    GET_CODES,
    getPreferences,
    codesPreferencesCollectionRef,
    getCodesPreferencesSuccess,
    getCodesPreferencesFailure
  );
  yield takeLatest(
    GET_DIAGNOSTIC_CODES_PREFERENCES,
    getPreferences,
    diagnosticCodesPreferencesCollectionRef,
    getDiagnosticCodesPreferencesSuccess,
    getDiagnosticCodesPreferencesFailure
  );
  yield takeLatest(
    GET_LUMP_SUMS,
    getPreferences,
    lumpSumPreferencesCollectionRef,
    getLumpSumsPreferencesSuccess,
    getLumpSumsPreferencesFailure
  );
  yield takeEvery(
    UPDATE_DIAGNOSTIC_CODE_COLLECTION,
    updateCollectionInUserContext,
    DIAGNOSTIC_PREFERENCES,
    updateDiagnosticCodesFromUserPreferencesSuccess,
    updateDiagnosticCodesFromUserPreferencesFailure
  );
  yield takeEvery(
    ADD_DIAGNOSTIC_CODE,
    setDocumentInUserContext,
    DIAGNOSTIC_PREFERENCES,
    addDiagnosticCodeToUserPreferenceFailure
  );
  yield takeEvery(
    EDIT_DIAGNOSTIC_CODE,
    setDocumentInUserContext,
    DIAGNOSTIC_PREFERENCES,
    editDiagnosticCodeFromUserPreferenceFailure
  );
  yield takeLatest(
    REMOVE_DIAGNOSTIC_CODE,
    removeDocumentInUserContext,
    DIAGNOSTIC_PREFERENCES,
    removeDiagnosticCodeFromUserPreferenceFailure
  );
  yield takeLatest(
    GET_SPECIALTY_PRESET_CODES,
    fetchSpecialtyPresetActCodes,
    getSpecialtyPresetActCodesSuccess,
    getSpecialtyPresetActCodesFailure
  );
  yield takeLatest(
    GET_SPECIALTY_FAVORITES_CODES,
    fetchSpecialtyFavoritesActCodes,
    getSpecialtyFavoritesActCodesSuccess,
    getSpecialtyFavoritesActCodesFailure
  );
  yield takeLatest(
    GET_SPECIALTY_DIAGNOSTIC_CODES,
    fetchSpecialtyDiagnosticCodes,
    getSpecialtyDiagnosticCodesSuccess,
    getSpecialtyDiagnosticCodesFailure
  );
  yield takeLatest(GET_USER_PROFILE, getUserProfile);

  yield takeLatest(SAVE_USER_PROFILE, saveUser);

  yield takeEvery(UPDATE_PLACE_SIGNATORY, updatePlaceSignatorySaga);

  yield takeLatest(CHANGE_USER_LANGUAGE, changeLanguage);

  yield takeLatest(
    GET_CONTEXT_ELEMENTS_PREFERENCES,
    getPreferences,
    contextElementsPreferencesCollectionRef,
    getContextElementsPreferencesSuccess,
    getContextElementsPreferencesFailure
  );

  yield takeEvery(
    ADD_CONTEXT_ELEMENT,
    setDocumentInUserContext,
    CONTEXT_ELEMENT_PREFERENCES,
    addContextElementToUserPreferencesFailure
  );

  yield takeLatest(
    REMOVE_CONTEXT_ELEMENT,
    removeDocumentInUserContext,
    CONTEXT_ELEMENT_PREFERENCES,
    removeContextElementFromUserPreferencesFailure
  );

  yield takeEvery(
    EDIT_CONTEXT_ELEMENT,
    setDocumentInUserContext,
    CONTEXT_ELEMENT_PREFERENCES,
    editContextElementFromUserPreferencesFailure
  );

  yield takeEvery(
    UPDATE_CONTEXT_ELEMENT_COLLECTION,
    updateCollectionInUserContext,
    CONTEXT_ELEMENT_PREFERENCES,
    updateContextElementFromUserPreferencesSuccess,
    updateContextElementFromUserPreferencesFailure
  );

  yield takeLatest(
    GET_SPECIALTY_CONTEXT_ELEMENTS,
    fetchSpecialtyContextElements,
    getSpecialtyContextElementsSuccess,
    getSpecialtyContextElementsFailure
  );

  yield takeEvery(ADD_LUMP_SUM_TO_USER_PREFERENCES, setDocumentInUserContext, LUMP_SUMS_PREFERENCES, (error) =>
    logError('User » sagas', ADD_LUMP_SUM_TO_USER_PREFERENCES, 'failure', error)
  );

  yield takeEvery(
    UPDATE_LUMP_SUMS_FROM_USER_PREFERENCES,
    updateCollectionInUserContext,
    LUMP_SUMS_PREFERENCES,
    updateLumpSumsFromUserPreferencesSuccess,
    updateLumpSumsFromUserPreferencesFailure
  );

  yield takeEvery(REMOVE_LUMP_SUM_FROM_USER_PREFERENCES, removeDocumentInUserContext, LUMP_SUMS_PREFERENCES, (error) =>
    logError('User » sagas', REMOVE_LUMP_SUM_FROM_USER_PREFERENCES, 'failure', error)
  );
}

export function* getUserProfile(action) {
  const userDocumentRef = usersCollectionRef().doc(action.userId);
  const documentSnapshot = yield call([userDocumentRef, userDocumentRef.get]);
  const userProfile = documentSnapshot.data();
  const language = resolveLanguage(userProfile);
  yield put(changeUserLanguage(language));
  yield put(setUserProfileInContext(userProfile));
  yield put(getSpecialtyPresetActCodes());
  yield put(getSpecialtyFavoritesActCodes());
  yield put(getSpecialtyDiagnosticCodes());

  yield put(setIsUserProfileReady());
}

export function* saveUser(action) {
  yield* setDocument(usersCollectionRef(), saveUserProfileFailure, action);
  yield put(getSpecialtyPresetActCodes());
  yield put(getSpecialtyFavoritesActCodes());
  yield put(getSpecialtyDiagnosticCodes());
  const loggedInUser = yield select(selectLoggedUser());
  yield call(updateUserEmail, action.document);
  if (loggedInUser.data.uid === action.document.id && loggedInUser.data.email !== action.document.email) {
    // If current user's email changed, we need to reload to force a token check and re-authenticate
    window.location.reload();
  }
}

export function* getPreferences(collectionRef, syncSuccessAction, syncFailureAction) {
  try {
    const currentUserId = yield select(selectUserIdInContext());
    const collectionReference = collectionRef(currentUserId);
    yield syncCollection(collectionReference, syncSuccessAction, syncFailureAction);
  } catch (e) {
    yield put(getPlacesPreferencesFailure(e));
  }
}

export function* changeLanguage(action) {
  moment.locale(action.language);
  const loggedInUser = yield select(selectLoggedUser());
  yield usersCollectionRef().doc(loggedInUser.getId()).update({ language: action.language });
  localStorage.removeItem('locale');
}
