import React, { useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { gql, useQuery } from '@apollo/client';
import HybridListSelector from '../../../components/Form/ListSelector/HybridListSelector/HybridListSelector';

export interface Props {
  selectedGroupIds: string[];
  onChange: (groupIds: string[]) => void;
}

const GROUPS_QUERY = gql`
  query FetchGroups {
    groups {
      id
      name
      owners {
        id
        fullName
      }
    }
  }
`;

interface Group {
  id: string;
  name: string;
  owners: Array<{
    id: string;
    fullName: string;
  }>;
}

interface FetchGroups {
  groups: Group[];
}

const GroupMultiSelector: React.FunctionComponent<Props> = ({ selectedGroupIds, onChange }: Props) => {
  const { data, loading: isFetching } = useQuery<FetchGroups>(GROUPS_QUERY);
  const selectorOptions = useMemo(() => {
    if (!data) return [];

    return data.groups.map((group) => ({
      label: `${group.name} - ${group.owners.map((owner) => owner.fullName).join(', ')}`,
      value: group.id
    }));
  }, [data]);

  if (isFetching) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CircularProgress size={20} color="primary" />
      </div>
    );
  }

  return (
    <HybridListSelector
      multiple
      placeholder="Choisir un/des groupe(s)"
      input={{
        onChange: (evt) => onChange(evt.target.value),
        value: selectedGroupIds
      }}
      values={selectorOptions}
    />
  );
};

export default GroupMultiSelector;
