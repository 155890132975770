import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useTranslate from 'application/hooks/use-translate';
import Icon from 'application/components/icon';
import LoadingState from 'application/pages/groups/group/components/reports/loading-state';
import NoDataAvailableState from 'application/pages/groups/group/components/reports/no-data-available-state';
import ReportService from 'application/services/report-service';
import HTMLReport from 'application/pages/groups/group/components/reports/html-report';
import DownloadButton from 'application/components/download-button';
import useToast from 'application/hooks/use-toast';
import downloadFile from 'application/utilities/download-file';
import StatementDateSelector from '../statement-date-selector';

interface Props {
  groupId: string;
}

const PeriodSummaryReport = ({ groupId }: Props) => {
  const translate = useTranslate('reports');
  const [selectedStatementDate, setSelectedStatementDate] = useState<number | null>(null);
  const { data, isFetching, isSuccess } = useQuery({
    queryKey: ['period-summary-report', selectedStatementDate],
    queryFn: () => {
      if (!selectedStatementDate) {
        return Promise.reject();
      }

      return ReportService.fetchPeriodSummaryReport(groupId, selectedStatementDate);
    },
    enabled: !!selectedStatementDate
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const { addToast } = useToast();

  const handleDownloadPromise = (response: Promise<Response>) => {
    response
      .then((response) => {
        if (response.status === 404) {
          addToast({
            message: translate('no-data-available'),
            type: 'warning'
          });

          return;
        }

        if (!response.ok) {
          addToast({
            message: translate('error-downloading-report'),
            type: 'error'
          });

          return;
        }

        downloadFile(response);
      })
      .catch(() => {
        addToast({
          message: translate('error-downloading-report'),
          type: 'error'
        });
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const fetchFile = () => {
    if (!selectedStatementDate) return;

    setIsDownloading(true);

    return handleDownloadPromise(ReportService.downloadPeriodSummaryReport(groupId, selectedStatementDate));
  };

  const exportFile = () => {
    if (!selectedStatementDate) return;

    setIsDownloading(true);

    return handleDownloadPromise(ReportService.exportPeriodSummaryReport(groupId, selectedStatementDate));
  };

  return (
    <>
      <div className="absolute right-4 top-4 flex items-center print:hidden">
        <StatementDateSelector groupId={groupId} onChange={setSelectedStatementDate} value={selectedStatementDate} />

        <DownloadButton
          disabled={!data || !isSuccess || isFetching}
          downloading={isDownloading}
          modes={['pdf', 'csv']}
          onClick={(mode) => {
            if (mode === 'pdf') {
              return fetchFile();
            }

            if (mode === 'csv') {
              return exportFile();
            }
          }}
        />
      </div>

      {isFetching && <LoadingState />}

      {data && isSuccess && !isFetching && <HTMLReport report={data} />}

      {!isFetching && !data && isSuccess && <NoDataAvailableState />}

      {!isFetching && !data && !isSuccess && (
        <div className="flex h-96 flex-col items-center justify-center gap-4 bg-base-200">
          <Icon icon="calendars" className="text-4xl text-gray-400" />

          <span className="w-64 text-center text-gray-400">{translate('select-year')}</span>
        </div>
      )}
    </>
  );
};

export default PeriodSummaryReport;
