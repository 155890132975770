import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import FormElement from '../FormElement/FormElement';

const styles = (theme) => ({
  spaced: {
    padding: theme.spacing(1)
  }
});

function SpacedFormElement({ classes, ...props }) {
  return <FormElement className={classes.spaced} {...props} />;
}

export default withStyles(styles)(SpacedFormElement);
