import { formatUnixToDate, getMomentDateFromUnix, newFormattedDate } from './dateTimeUtils';

export const dateIsToday = (date) => {
  const actDate = formatUnixToDate(date);
  const today = newFormattedDate();
  return actDate === today;
};

export const dateIsDuringPlaceHoliday = (date, place) => {
  const actDate = formatUnixToDate(date);
  return place && place.daysOff && place.daysOff.includes(actDate);
};

export const dateIsDuringWeekend = (date) => {
  const dayOfTheWeek = getMomentDateFromUnix(date).day();
  return dayIsSaturdayOrSunday(dayOfTheWeek);
};

export const dateIsDuringPlaceHolidayOrWeekEnd = (date, place) =>
  dateIsDuringPlaceHoliday(date, place) || dateIsDuringWeekend(date);

const dayIsSaturdayOrSunday = (dayNumber) => dayNumber === 6 || dayNumber === 0;
