import LumpSum from '../../../../../../shared/domain/activity/lumpSum/model/LumpSum';
import PeriodFormTransformer from '../PeriodFormTransformer';

class LumpSumPeriodTransformer implements PeriodFormTransformer<LumpSum, LumpSum> {
  toDomainObject(dto: LumpSum): LumpSum {
    return dto;
  }

  toPeriodFormDto(lumpSum: LumpSum): LumpSum {
    return lumpSum;
  }
}

export default LumpSumPeriodTransformer;
