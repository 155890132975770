import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../../Alert/Alert';

export const styles = (theme) => ({
  emptyDayIndicator: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  noActivityMessage: {
    marginBottom: theme.spacing(3)
  }
});

export class EmptyDayIndicator extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.emptyDayIndicator}>
        <Alert
          severity="warning"
          showIcon
          title="Attention"
          message={<FormattedMessage id="activity.doNotForgetToBillGuardOrTeaching" />}
        />
        <Alert severity="none" message={<FormattedMessage id="activity.noActivityForThisDay" />} />
      </div>
    );
  }
}

export default withStyles(styles)(EmptyDayIndicator);
