import queryString from 'qs';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MIXTE_ROUTE } from '../../../../containers/Navigation/constants';
import { displayPlace } from '../../../Form/utils/fieldTransformations';
import DetailsItem from '../../Act/Details/DetailsItem/DetailsItem';
import MainInformationContainer from '../../MainInformationContainer/MainInformationContainer';
import PeriodsActivityContainer from '../../PeriodsBilling/PeriodsActivityContainer/PeriodsActivityContainer';
import MixtePeriodDetail from '../MixtePeriodDetail/MixtePeriodDetail';
import { ListItemSecondaryAction } from '@material-ui/core';
import DesktopActions from 'app/containers/ActivitiesRoute/DayRoute/DayActivities/Act/DesktopActions/DesktopActions';

export function MixteActivity({ activity }) {
  const navigate = useNavigate();

  return (
    <PeriodsActivityContainer
      onClick={() =>
        navigate({
          pathname: MIXTE_ROUTE,
          search: queryString.stringify(
            { place: activity.place.number, billingType: activity.billingType, pool: activity.poolNumber },
            { skipNulls: true }
          )
        })
      }
    >
      <MainInformationContainer activity={activity}>
        <MixtePeriodDetail mixteDay={activity} />
        <DetailsItem dense icon={<PlaceIcon />} text={<Typography noWrap>{displayPlace(activity.place)}</Typography>} />
      </MainInformationContainer>
      <ListItemSecondaryAction>
        <DesktopActions activity={activity} />
      </ListItemSecondaryAction>
    </PeriodsActivityContainer>
  );
}

MixteActivity.propTypes = {
  activity: PropTypes.object.isRequired
};

export default MixteActivity;
