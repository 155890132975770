import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import MixteBillingPlaceChip from '../../../components/Activities/MixteBillingPlaceChip/MixteBillingPlaceChip';
import PrincipalPlaceChip from '../../../components/Activities/PrincipalPlaceChip/PrincipalPlaceChip';
import TerritoryPlaceChip from '../../../components/Activities/TerritoryPlaceChip/TerritoryPlaceChip';
import PlaceDistance from '../../../components/PlaceDistance/PlaceDistance';
import { findIndexInFieldValue, toggleFieldValue } from '../../../components/utils/reduxFormArrayField';

const styles = (theme) => ({
  centerJustified: {
    display: 'flex',
    justifyContent: 'center'
  },
  centerAligned: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    marginRight: theme.spacing(2)
  }
});

const isDirectRamqCallEnabled = (input, place) => findIndexInFieldValue(input, place.number) !== -1;
function DirectRamqCallEnabledConfig({ input, placesPreferences, classes }) {
  return (
    <Grid container spacing={3}>
      {placesPreferences.map((place) => (
        <Fragment key={place.number}>
          <Grid item xs={9} className={classes.centerAligned}>
            <Chip label={place.number} className={classes.chip} />
            <Typography>{place.name}</Typography>
            <PrincipalPlaceChip place={place} />
            <TerritoryPlaceChip place={place} />
            <MixteBillingPlaceChip place={place} />
          </Grid>

          <Grid item xs={2}>
            <PlaceDistance place={place} />
          </Grid>

          <Grid item xs={1} className={classes.centerJustified}>
            <Switch
              onClick={() => toggleFieldValue(input, place.number)}
              checked={isDirectRamqCallEnabled(input, place)}
            />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}

DirectRamqCallEnabledConfig.propTypes = {
  input: PropTypes.object.isRequired,
  placesPreferences: PropTypes.array.isRequired
};

export default withStyles(styles)(DirectRamqCallEnabledConfig);
