import { getWeekBasedEndDateFromDate, getWeekBasedStartDateFromDate } from '../../../periods/periods';
import { MIXTE_PERIOD_DAYS_DURATION } from '../../../periods/constants';
import ActivityStatus from '../../../core/activity/domain/ActivityStatus';
import ActivityType from '../../../core/activity/domain/ActivityType';
import { Mixte } from './model/Mixte';

class MixteCreator {
  static create(computedDays, date, place, userId, billingType, poolNumber): Mixte {
    const startDate = getWeekBasedStartDateFromDate(date, MIXTE_PERIOD_DAYS_DURATION).valueOf();
    const endDate = getWeekBasedEndDateFromDate(date, MIXTE_PERIOD_DAYS_DURATION).valueOf();

    return {
      place,
      placeNumber: place.number,
      date: startDate,
      startDate,
      endDate,
      createdOn: date,
      days: computedDays,
      status: ActivityStatus.WAITING,
      type: ActivityType.MIXTE,
      duration: MIXTE_PERIOD_DAYS_DURATION,
      userId,
      billingType,
      ...(poolNumber && { poolNumber })
    };
  }
}

export default MixteCreator;
