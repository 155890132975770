import { RAMQCodes } from '../../../shared/ramq/RamqCodes';
import recursiveItemsCallback from '../../../utils/recursive-items-callback';

export const ADD_CODE = 'addCodeToUserPreferences';
export const addCodeToUserPreferences = (document) => ({
  type: ADD_CODE,
  document
});

export const ADD_CODE_FAILURE = 'addCodeFailure';
export const addCodeToUserPreferenceFailure = (error) => ({
  type: ADD_CODE_FAILURE,
  error
});

export const REMOVE_CODE = 'removeCodeFromUserPreferences';
export const removeCodeFromUserPreferences = (document) => ({
  type: REMOVE_CODE,
  document
});

export const REMOVE_CODE_FAILURE = 'removeCodeFailure';
export const removeCodeFromUserPreferenceFailure = (error) => ({
  type: REMOVE_CODE_FAILURE,
  error
});

export const EDIT_CODE = 'editCodeFromUserPreferences';
export const editCodeFromUserPreferences = (document) => ({
  type: EDIT_CODE,
  document
});

export const EDIT_CODE_FAILURE = 'editCodeFailure';
export const editCodeFromUserPreferenceFailure = (error) => ({
  type: EDIT_CODE_FAILURE,
  error
});

export const UPDATE_CODE_COLLECTION = 'updateActCodesFromUserPreferences';
export const updateActCodesFromUserPreferences = (documentMap) => ({
  type: UPDATE_CODE_COLLECTION,
  documentMap
});

export const UPDATE_CODE_COLLECTION_SUCCESS = 'updateActCodesFromUserPreferencesSuccess';
export const updateActCodesFromUserPreferencesSuccess = () => ({
  type: UPDATE_CODE_COLLECTION_SUCCESS
});

export const UPDATE_CODE_COLLECTION_FAILURE = 'updateActCodesFromUserPreferencesFailure';
export const updateActCodesFromUserPreferencesFailure = (error) => ({
  type: UPDATE_CODE_COLLECTION_FAILURE,
  error
});

export const GET_CODES = 'getCodes';
export const getCodesPreferences = () => ({
  type: GET_CODES
});

export const GET_CODES_SUCCESS = 'getCodesSuccess';
export const getCodesPreferencesSuccess = (codes) => ({
  type: GET_CODES_SUCCESS,
  codes: recursiveItemsCallback(codes, (codePreference) => ({
    ...codePreference,
    ...(RAMQCodes.get(codePreference.code) || {}),
    ...(codePreference.description && { description: codePreference.description })
  }))
});

export const GET_CODES_FAILURE = 'getCodesFailure';
export const getCodesPreferencesFailure = (error) => ({
  type: GET_CODES_FAILURE,
  error
});
