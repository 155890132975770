import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import ActivitiesContainer from '../../../../components/Activities/ActivitiesContainer/ActivitiesContainer';
import { summaryActivitiesMapping } from '../../../../components/Activities/componentsMappings';
import EmptyActivitiesIndicator from '../../../../components/Activities/EmptyActivitiesIndicator/EmptyActivitiesIndicator';
import GroupedByTimeActivities from '../../../../components/Activities/GroupedByTimeActivities/GroupedByTimeActivities';
import ErrorSnackBars from '../../../../components/Errors/ErrorSnackBars/ErrorSnackBars';
import DuplicateAct from '../../../ActForm/DuplicateAct/DuplicateAct';
import DuplicateTravelExpenses from '../../../TravelExpenses/DuplicateTravelExpenses';
import { selectActivitiesInContext, selectActivitiesLoading } from '../../../Activities/selectors';
import {
  ACTIVITY_ID_ROUTE_PARAM,
  ACTIVITY_TYPE_ROUTE_PARAM,
  DUPLICATE_TRAVEL_EXPENSES_ROUTE,
  DUPLICATE_ACT_ROUTE
} from '../../../Navigation/constants';
import AdminActivityDetail from './AdminActivityDetail/AdminActivityDetail';
import AdminSearchBox from './ActivitiesSearchBox/ActivitiesSearchBox';

export const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    flex: 1
  },
  userActivities: {
    maxWidth: 980,
    width: 'unset',
    height: 'calc(100vh - 160px)'
  },
  duplicateActWrapper: {
    overflow: 'auto',
    flex: 1
  },
  card: {
    marginTop: 0,
    maxWidth: 'unset'
  }
});

export function UserActivities({ activities, activitiesLoading, classes }) {
  return (
    <>
      <AdminSearchBox />
      <div className={classes.wrapper}>
        <ActivitiesContainer className={classes.userActivities}>
          <GroupedByTimeActivities
            activitiesLoading={activitiesLoading}
            highlightSelected
            activities={activities}
            componentsMapping={summaryActivitiesMapping}
            EmptyIndicatorComponent={EmptyActivitiesIndicator}
          />
        </ActivitiesContainer>
        <Routes>
          <Route
            path={DUPLICATE_ACT_ROUTE}
            element={
              <div className={classes.duplicateActWrapper}>
                <DuplicateAct />
              </div>
            }
          />
          <Route
            path={DUPLICATE_TRAVEL_EXPENSES_ROUTE}
            element={
              <div className={classes.duplicateActWrapper}>
                <DuplicateTravelExpenses />
              </div>
            }
          />
          <Route path={ACTIVITY_TYPE_ROUTE_PARAM}>
            <Route
              path={ACTIVITY_ID_ROUTE_PARAM}
              element={activitiesLoading ? null : <AdminActivityDetail activities={activities} />}
            />
          </Route>
        </Routes>
      </div>
      <ErrorSnackBars />
    </>
  );
}

UserActivities.propTypes = {
  activities: PropTypes.array.isRequired,
  activitiesLoading: PropTypes.bool.isRequired
};

export const mapStateToProps = createStructuredSelector({
  activities: selectActivitiesInContext(),
  activitiesLoading: selectActivitiesLoading()
});

export default compose(withStyles(styles), connect(mapStateToProps))(UserActivities);
