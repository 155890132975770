import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import RamqExplanationText from './RamqExplanationText';

export const styles = (theme) => ({
  container: {
    flex: 1
  },
  denseChip: {
    'marginTop': 1,
    'marginBottom': 1,
    'height': 15,
    'marginRight': 2,
    'marginLeft': 2,
    '&:first-child': {
      marginLeft: 0
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  isRed: {
    backgroundColor: theme.palette.red[500],
    color: theme.palette.common.white
  },
  listItemIcon: {
    flex: '0 0 auto'
  },
  codeChip: {
    flex: '0 0 auto',
    width: 65
  },
  explanation: {
    flex: 1
  },
  secondaryAction: {
    right: theme.spacing(1),
    flex: '0 0 auto'
  }
});

export function ActResponseLine({ actResponseLine, classes }) {
  return (
    <ListItem>
      <ListItemIcon className={classes.listItemIcon}>
        <Chip
          className={classNames({ [classes.isRed]: actResponseLine.outcome === 'failure' }, classes.denseChip)}
          label={actResponseLine.number}
        />
      </ListItemIcon>
      {!!actResponseLine?.actCode && (
        <Chip className={classNames(classes.denseChip, classes.codeChip)} label={actResponseLine.actCode} />
      )}
      <RamqExplanationText className={classes.explanation} explanation={actResponseLine.explanation} />
      <ListItemSecondaryAction className={classes.secondaryAction}>
        <Typography variant="caption">{actResponseLine.amount}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

ActResponseLine.propTypes = {
  actResponseLine: PropTypes.object.isRequired
};

export default withStyles(styles)(ActResponseLine);
