import EXPIRED_LUMPSUM_CODES from '../../../../data/lumpSum/lumpsum-expired-codes.json';

interface LumpsumCodeWithExpirationDate {
  code: string;
  expirationDate: number;
}

const expiredLumpsumCodes: LumpsumCodeWithExpirationDate[] = EXPIRED_LUMPSUM_CODES;

const findExpiredCode = (lumpsumCode: string) =>
  expiredLumpsumCodes.find(({ code }: LumpsumCodeWithExpirationDate) => code === lumpsumCode);

export default findExpiredCode;
