import moment from 'moment-timezone';

export const ONE_DAY_IN_MILLISECONDS = 86400000;

export const roundDateNearest5Min = (date) => {
  const roundedUp = Math.ceil(moment().minute() / 5) * 5;
  return date.minute(roundedUp).second(0);
};

export const parseTimeValueToUnix = (value) => (value ? moment(value).valueOf() : '');

export const getDateDayAbbreviation = (date) => moment(date).format('ddd');
export const getDateDayNumber = (date) => moment(date).format('D');
export const getDateMonthName = (date) => moment(date).format('MMMM');
export const getFullDate = (date) => moment(date).format('LL');
export const getDateYear = (date) => moment(date).format('YYYY');

export const getDateDenseWithWeekDay = (momentDate) => momentDate.format('dd\u00a0MM-DD');

export const getTimeObjectFromUnix = (unixTimestamp) => {
  const momentDate = moment(unixTimestamp);
  return {
    hour: momentDate.hours(),
    minutes: momentDate.minutes()
  };
};
