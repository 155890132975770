import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useToggle } from 'react-use';
import Status from '../../../../components/Status/Status';
import ActivityHistoryDelta from '../AcitivityHistoryDelta/ActivityHistoryDelta';
import UserName from '../UserName/UserName';

export const styles = () => ({
  oneOfThreeColumns: {
    width: '33%',
    textOverflow: ''
  },
  AccordionSummary: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  }
});

export function ActivityHistoryVersionWithDelta({ version, delta, keyFilter, valueFilter, classes }) {
  const [expanded, toggleExpanded] = useToggle(false);

  useEffect(() => {
    toggleExpanded(Boolean(keyFilter || valueFilter));
  }, [toggleExpanded, keyFilter, valueFilter]);

  return (
    <Accordion expanded={expanded} onChange={toggleExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.AccordionSummary}>
          <Typography data-testid="version-date">
            {moment(version.auditInformation.modifiedOn).format('dddd, MMMM Do YYYY, HH:mm:ss')}
          </Typography>
          <Typography data-testid="version-author">
            {'Modifié Par : '}
            <UserName userId={version.auditInformation.modifiedBy} />
          </Typography>
          <Status status={version.status} dense data-testid="version-status" />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <ActivityHistoryDelta delta={delta} keyFilter={keyFilter} valueFilter={valueFilter} />
      </AccordionDetails>
    </Accordion>
  );
}

ActivityHistoryVersionWithDelta.propTypes = {
  version: PropTypes.object.isRequired,
  delta: PropTypes.object.isRequired,
  keyFilter: PropTypes.string,
  valueFilter: PropTypes.string
};

export default withStyles(styles)(ActivityHistoryVersionWithDelta);
