import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  hoursContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    textTransform: 'uppercase'
  },
  text: {
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('xs')]: {
    extraText: {
      fontSize: '12px'
    }
  },
  extraText: {
    fontWeight: 'bold',
    position: 'absolute',
    right: '50%',
    left: '50%',
    minWidth: '200px'
  },
  rempHour: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  separator: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    fontWeight: 'bold'
  }
});

function Hours({ activity, text, classes }) {
  return (
    <div className={classes.hoursContainer}>
      {activity.start && (
        <Typography className={classes.text} variant="subtitle1">
          {activity.start}
        </Typography>
      )}

      {(activity.start || activity.end) && (
        <Typography variant="subtitle1" className={classes.separator}>
          -
        </Typography>
      )}

      {activity.end && (
        <Typography className={classes.text} variant="subtitle1">
          {activity.end}
        </Typography>
      )}

      {activity.remp && (
        <Typography className={classNames(classes.text, classes.rempHour)} variant="subtitle1">
          {`(${activity.remp})`}
        </Typography>
      )}

      {text && (
        <Typography className={classes.extraText} variant="subtitle1">
          {text}
        </Typography>
      )}
    </div>
  );
}

Hours.propTypes = {
  activity: PropTypes.object.isRequired,
  text: PropTypes.node
};

Hours.defaultProps = {
  text: null
};

export default withStyles(styles)(Hours);
