import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
import SingleCodeSearch from '../SingleCodeSearch';
import MaxSearchReachedDialogActions from '../../../../components/Dialogs/DialogActionsWithMessage/MaxSearchReachedDialogActions';

export const styles = (theme) => ({
  searchContainer: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'paddingBottom': 0,
    'paddingTop': theme.spacing(0.5),
    '&:first-child': {
      paddingTop: theme.spacing(0.5)
    }
  }
});

export function DiagnosticCodeRegistryTab({
  onItemSelected,
  onClose,
  diagnosticCodesWithMarkup,
  canAddItemToFavorites,
  classes
}) {
  return (
    <>
      <SingleCodeSearch
        onItemSelected={onItemSelected}
        diagnosticCodesWithMarkup={diagnosticCodesWithMarkup}
        canAddItemToFavorites={canAddItemToFavorites}
      />
      <DialogActions className={classes.dialogActions}>
        <MaxSearchReachedDialogActions maxSearchNumber={20}>
          <Button id="close-Dialog" color="primary" onClick={onClose}>
            <FormattedMessage id="global.close" />
          </Button>
        </MaxSearchReachedDialogActions>
      </DialogActions>
    </>
  );
}

DiagnosticCodeRegistryTab.propTypes = {
  diagnosticCodesWithMarkup: PropTypes.array.isRequired,
  canAddItemToFavorites: PropTypes.bool.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(DiagnosticCodeRegistryTab);
