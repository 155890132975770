import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import useTranslate from 'application/hooks/use-translate';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  addFeeButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    width: '100%'
  },
  addIcon: {
    fontSize: 20,
    marginLeft: theme.spacing(1)
  }
});

function AddAdditionalTransportFeeButton({ reduxFormFields, classes }) {
  const translate = useTranslate();
  if (reduxFormFields.length >= 10) return null;

  return (
    <div className={classes.addFeeButtonWrapper}>
      <Button size="small" variant="contained" onClick={() => reduxFormFields.push({})}>
        {translate('travel-expenses.additional-fee')}
        <AddIcon className={classes.addIcon} />
      </Button>
    </div>
  );
}

AddAdditionalTransportFeeButton.propTypes = {
  reduxFormFields: PropTypes.object.isRequired
};

export default withStyles(styles)(AddAdditionalTransportFeeButton);
