import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import OutsideProvinceForm from '../OutsideProvinceForm/OutsideProvinceForm';
import MilitaryForm from '../MilitaryForm/MilitaryForm';
import DetainedForm from '../DetainedForm/DetainedForm';
import ForeignStudentForm from '../ForeignStudentForm/ForeignStudentForm';
import OutsideRamqRefugeeForm from '../OutsideRamqRefugeeForm/OutsideRamqRefugeeForm';
import BilledForm from '../BilledForm/BilledForm';
import { selectOutsideRamqPatientType } from '../../../selectors';
import PrivateInsuranceForm from '../PrivateInsuranceForm/PrivateInsuranceForm';
import { OutsideRamqPatientType } from '../../../../../../shared/domain/activity/act/model/Act';

export interface Props {
  outsideRamqPatientType?: OutsideRamqPatientType;
}

export const OutsideRamqPatientTypeSwitch: React.FunctionComponent<Props> = ({ outsideRamqPatientType }: Props) => {
  switch (outsideRamqPatientType) {
    case OutsideRamqPatientType.ABROAD_STUDENT:
      return <ForeignStudentForm />;
    case OutsideRamqPatientType.OUTSIDE_RAMQ:
      return <OutsideProvinceForm />;
    case OutsideRamqPatientType.MILITARY:
      return <MilitaryForm />;
    case OutsideRamqPatientType.PRISONER:
      return <DetainedForm />;
    case OutsideRamqPatientType.REFUGEE:
      return <OutsideRamqRefugeeForm />;
    case OutsideRamqPatientType.PRIVATE_INSURANCE:
      return <PrivateInsuranceForm />;
    case OutsideRamqPatientType.BILLED:
      return <BilledForm />;
    default:
      return null;
  }
};

export const mapStateToProps = createStructuredSelector({
  outsideRamqPatientType: selectOutsideRamqPatientType()
});

export default connect(mapStateToProps)(OutsideRamqPatientTypeSwitch);
