import { Moment } from 'moment-timezone';
import Act, { ActCode } from '../model/Act';
import RAMQCodes, { TimedAttributeKey } from '../../../../ramq/RamqCodes';
import SpecialtyName from '../../../../core/doctor/domain/SpecialtyName';
import { NatureType } from '../../../../core/activity/domain/Act';
import { ROLE3, ROLE4 } from '../../../../ramq/contextElements/globalContextElements';

export const codeIsInRole2 = ({ code, role }: ActCode, act: Act, userSpecialty: SpecialtyName) => {
  const isUserAnesthesiologist = [SpecialtyName.ANESTHESIOLOGIST, SpecialtyName.ANESTHESIOLOGIST_INTENSIVIST].includes(
    userSpecialty
  );
  const ramqCode = RAMQCodes.get(code);

  if (!ramqCode || !RAMQCodes.isCodeEffectiveOn(ramqCode, act.date)) {
    return false;
  }

  if (
    isUserAnesthesiologist &&
    act.contextElements?.includes(ROLE3) &&
    RAMQCodes.getTimedAttribute<boolean>(ramqCode, TimedAttributeKey.isAddendum8, act.date)
  ) {
    return true;
  }

  if (act.contextElements?.includes(ROLE3) || act.contextElements?.includes(ROLE4)) {
    return false;
  }

  if (role) {
    return role === 2;
  }

  const rolesForSpecialty = RAMQCodes.getRolesForCodeEffectiveOnAndForSpecialty(code, act.date, userSpecialty);

  if (NatureType.SURGERY === ramqCode?.natureType && isUserAnesthesiologist) {
    return rolesForSpecialty.some((role) => [2, '2'].includes(role));
  }

  return [2, '2'].includes(rolesForSpecialty[0]);
};

export const codeIsInRole1 = (actCode: ActCode, act: Act, userSpecialty: SpecialtyName) => {
  if (act.contextElements?.includes(ROLE3) || act.contextElements?.includes(ROLE4)) {
    return false;
  }

  return !codeIsInRole2(actCode, act, userSpecialty);
};

export const codeIsInRole = (
  { code, role: actCodeRole }: ActCode,
  role: number,
  serviceDate: number | Moment,
  userSpecialty: SpecialtyName
) => {
  if (actCodeRole) {
    return actCodeRole === role;
  }

  return [role, String(role)].includes(
    RAMQCodes.getRolesForCodeEffectiveOnAndForSpecialty(code, serviceDate, userSpecialty)[0]
  );
};
