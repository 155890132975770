import React from 'react';
import { SelectorField } from '../Fields';

export const ANNEXE_19_LIST = [
  { label: '100 %', value: 1.0, key: '0' },
  { label: '107 %', value: 1.07, key: '1' },
  { label: '115 %', value: 1.15, key: '2' },
  { label: '120 %', value: 1.2, key: '3' },
  { label: '125 %', value: 1.25, key: '4' },
  { label: '130 %', value: 1.3, key: '5' },
  { label: '145 %', value: 1.45, key: '6' }
];

function Annexe19Field(props) {
  return <SelectorField {...props} list={ANNEXE_19_LIST} />;
}

export default Annexe19Field;
