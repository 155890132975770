import React, { useEffect } from 'react';
import { change } from 'redux-form';
import isValidNam from 'app/validations/act/nam/namValidation';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIdInContext } from 'app/containers/User/selectors';
import useTranslate from 'application/hooks/use-translate';
import useToastedApolloError from 'application/hooks/use-toast-apollo-error';
import { UnknownAction } from '@reduxjs/toolkit';
import { makeStyles } from '@material-ui/styles';
import { selectRamqPatient } from './selectors';
import { ACT_FORM_NAME, actFormFields } from './constants';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '4px'
  }
});

interface Props {
  actDate: number;
  nam: string;
}

const PATIENT_QUERY = gql`
  query FetchRamqPatient($nam: String!, $userId: String!, $onDate: DateTime!) {
    patient(nam: $nam, userId: $userId) {
      nam
      ramqIdentity(onDate: $onDate) {
        firstName
        fullName
        lastName
        dateOfBirth
        age
        isAdmissible
      }
    }
  }
`;

const RamqPatient: React.FC<Props> = ({ actDate, nam }): React.ReactNode => {
  const translate = useTranslate('act-form');
  const userId = useSelector(selectUserIdInContext());
  const ramqPatient = useSelector(selectRamqPatient());
  const dispatch = useDispatch();
  const toastApolloError = useToastedApolloError();
  const classes = useStyles();

  const { data } = useQuery(PATIENT_QUERY, {
    variables: {
      nam,
      userId,
      onDate: actDate
    },
    skip: !navigator.onLine || !isValidNam(nam) || ramqPatient?.nam === nam,
    onError: (error: ApolloError) => toastApolloError(error, 'shield-exclamation')
  });

  useEffect(() => {
    let newRamqPatient;
    if (data?.patient.ramqIdentity) {
      newRamqPatient = {
        age: data.patient.ramqIdentity.age,
        dateOfBirth: data.patient.ramqIdentity.dateOfBirth,
        firstName: data.patient.ramqIdentity.firstName,
        fullName: data.patient.ramqIdentity.fullName,
        lastName: data.patient.ramqIdentity.lastName,
        nam: data.patient.nam,
        isAdmissible: data.patient.ramqIdentity.isAdmissible
      };
      dispatch(change(ACT_FORM_NAME, `${actFormFields.ramqPatient}`, newRamqPatient || null) as UnknownAction);
    } else if (!isValidNam(nam)) {
      dispatch(change(ACT_FORM_NAME, `${actFormFields.ramqPatient}`, null) as UnknownAction);
    }
  }, [data, nam, dispatch]);

  if (!ramqPatient) {
    return null;
  }

  return (
    <div className={classes.container}>
      {ramqPatient.fullName && <div>{ramqPatient.fullName}</div>}
      {ramqPatient.age !== undefined && ramqPatient.age !== null && (
        <div>
          ({ramqPatient.age} {translate('years-old')})
        </div>
      )}
    </div>
  );
};

export default RamqPatient;
