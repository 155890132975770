import { selectLoggedUser } from '../app/containers/Authentication/selectors';
import { getStore } from '../app/reduxStore/configureStore';
import Permission from '../shared/domain/authorization/model/Permission';

export default class UserUtils {
  static isAdminOrAgent = () => {
    const loggedUser = selectLoggedUser()(getStore().getState());
    return loggedUser.canSome(Permission.DO_ADMIN_ACTIONS, Permission.DO_AGENT_ACTIONS);
  };
}
