import { WithStyles, withStyles } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Alert from '../../../../../components/Alert/Alert';
import { DateField, SingleLineField } from '../../../../../components/Form/Fields';
import FormElement from '../../../../../components/Form/FormElement/FormElement';
import AlertSeverity from '../../../../../ui/shared/severity/AlertSeverity';

const styles = ({ spacing }) =>
  ({
    sectionTitle: {
      marginTop: spacing(2)
    }
  }) as const;

export const PrivateInsuranceForm: React.FunctionComponent<WithStyles<typeof styles>> = ({ classes }) => (
  <CardContent>
    <Alert severity={AlertSeverity.INFO}>
      Veuillez noter que Médyx ne pourra procéder à la facturation si les informations du patient et/ou de l’assureur
      sont insuffisantes.
      <div>(champ avec * obligatoire)</div>
    </Alert>
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        Information du patient
      </Typography>
      <Divider />
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.lastName" required label="Nom du patient" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.firstName"
          required
          label="Prénom du patient"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.birthDate" required label="Date de naissance" component={DateField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.address" label="Adresse" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.phoneNumber" label="Numéro de téléphone" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.email" label="Adresse courriel" component={SingleLineField} />
      </Grid>
    </Grid>

    <Grid item xs={12} className={classes.sectionTitle}>
      <Typography gutterBottom variant="h6">
        Assurance
      </Typography>
      <Divider />
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.insurance.name" required label="Nom" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.insurance.phoneNumber"
          label="Numéro de téléphone"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.insurance.faxNumber" label="Numéro de fax" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.insurance.email" label="Adresse courriel" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement name="patientInformation.insurance.address" label="Adresse" component={SingleLineField} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.insurance.contactPerson"
          label="Personne contact"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.insurance.policyCertificateNumber"
          required
          label="Numéro de police et/ou certificat"
          component={SingleLineField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormElement
          name="patientInformation.insurance.fileNumber"
          label="Numéro de dossier"
          component={SingleLineField}
        />
      </Grid>
    </Grid>
  </CardContent>
);

export default withStyles(styles)(PrivateInsuranceForm);
