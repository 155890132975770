import { put, takeEvery } from 'redux-saga/effects';
import { placesCollectionRef } from '../../firebase/collection/collectionReferences';
import { syncCollection } from '../../firebase/collection/collectionSync';
import { getPlacesPreferencesFailure } from '../PlacesPreferencesDialog/actions';
import { GET_RAMQ_PLACES, getRamqPlacesFailure, getRamqPlacesSuccess } from './actions';

function* getRamqPlaces(collectionRef, successAction, failureAction) {
  try {
    const collectionReference = collectionRef();

    yield syncCollection(collectionReference, successAction, failureAction);
  } catch (e) {
    yield put(getPlacesPreferencesFailure(e));
  }
}

export default function* ramqPlacesSagas() {
  yield takeEvery(GET_RAMQ_PLACES, getRamqPlaces, placesCollectionRef, getRamqPlacesSuccess, getRamqPlacesFailure);
}
