import { LOCK_UPLOAD_TASK, UPDATE_UPLOAD_TASK, UPLOAD_ERROR, UNLOCK_UPLOAD_TASK } from './actions';
import { ERROR_TASKS, PENDING_TASKS } from './constants';

const initialState = {
  [PENDING_TASKS]: [],
  [ERROR_TASKS]: []
};

export default function storageReducer(state = initialState, action) {
  switch (action.type) {
    case LOCK_UPLOAD_TASK:
      return {
        ...state,
        [PENDING_TASKS]: [
          ...state[PENDING_TASKS],
          {
            id: action.id,
            progress: 0
          }
        ],
        [ERROR_TASKS]: state[ERROR_TASKS].filter((uploadError) => uploadError.task.id !== action.id)
      };
    case UPDATE_UPLOAD_TASK: {
      return {
        ...state,
        [PENDING_TASKS]: state[PENDING_TASKS].map((uploadTask) => {
          if (uploadTask.id === action.uploadTask.id) {
            return action.uploadTask;
          }
          return uploadTask;
        })
      };
    }
    case UNLOCK_UPLOAD_TASK: {
      return {
        ...state,
        [PENDING_TASKS]: state[PENDING_TASKS].filter((uploadTask) => uploadTask.id !== action.uploadTask.id)
      };
    }
    case UPLOAD_ERROR:
      return {
        ...state,
        [PENDING_TASKS]: state[PENDING_TASKS].filter((uploadTask) => uploadTask.id !== action.uploadTask.id),
        [ERROR_TASKS]: [...state[ERROR_TASKS], { task: action.uploadTask, error: action.error }]
      };
    default:
      return state;
  }
}
