import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { duplicateAct, updateActWithoutPostProcessing } from '../../../../Act/actions';
import ActDuplicationService from '../../../../ActForm/DuplicateAct/ActDuplicationService';
import { ACTIVITIES_ROUTE, USERS_ROUTE, DUPLICATE_ACT_ROUTE } from '../../../../Navigation/constants';
import { billActivity, cancelActivity } from '../../../../Ramq/actions';
import AdminActions from '../AdminActions/AdminActions';
import createManualAdminNote from '../../../../../user/admin/createManualAdminNote';

export function ActAdminActions({
  activity,
  activeBills,
  onUpdate,
  onDuplicate,
  onAddAdminNote,
  onBillRamq,
  onCancelRamq,
  onValidateXml
}) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AdminActions
      activity={activity}
      activeBills={activeBills}
      onUpdate={onUpdate}
      onDuplicate={() => {
        onDuplicate();
        navigate({
          pathname: `/${USERS_ROUTE}/${activity.userId}/${ACTIVITIES_ROUTE}/${DUPLICATE_ACT_ROUTE}`,
          search: location.search
        });
      }}
      onAddAdminNote={onAddAdminNote}
      onBillRamq={onBillRamq}
      onCancelRamq={onCancelRamq}
      onValidateXml={onValidateXml}
    />
  );
}

ActAdminActions.propTypes = {
  activity: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onAddAdminNote: PropTypes.func.isRequired,
  onBillRamq: PropTypes.func.isRequired,
  onCancelRamq: PropTypes.func.isRequired,
  activeBills: PropTypes.array.isRequired,
  onValidateXml: PropTypes.func.isRequired
};

export const mapDispatchToProps = (dispatch, props) => ({
  onUpdate: (changes) => {
    dispatch(updateActWithoutPostProcessing(props.activity, changes));
  },
  onAddAdminNote: (adminNote) => {
    const adminNotes = props.activity.adminNotes || [];
    adminNotes.push(createManualAdminNote(adminNote));
    dispatch(updateActWithoutPostProcessing(props.activity, { adminNotes }));
  },
  onDuplicate: () => {
    const duplicatedInfo = ActDuplicationService.duplicateForAdmin(props.activity);
    dispatch(duplicateAct(duplicatedInfo));
  },
  onBillRamq: (options) => {
    dispatch(billActivity(props.activity.id, options));
  },
  onCancelRamq: (billNumber) => {
    dispatch(cancelActivity(props.activity.id, billNumber));
  }
});

export default compose(connect(null, mapDispatchToProps))(ActAdminActions);
