/* eslint-disable no-shadow, no-unused-vars */
enum AdminStatus {
  NORMAL = 'normal',
  OUTSIDE_RAMQ = 'outsideRamq',
  MIXTE_DESIGNATION = 'designationMixte',
  TERRITORY_MAJORATION = 'territoryMajoration',
  URGENT_TIME_LIMIT = 'urgentTimeLimit',
  REVISION_DEROGATION = 'revisionDerogation',
  ERROR_AMOUNT = 'errorAmount',
  AWAITING_INCORPORATION = 'awaitingIncorporation',
  OTHER_FOLLOWUPS = 'otherFollowups',
  MANAGED_BY_DOCTOR = 'managedByDoctor'
}

export default AdminStatus;
