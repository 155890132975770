import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { FormattedMessage } from 'react-intl';
import { WithStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { DialogActions, TextField } from '@material-ui/core';
import { useDebounce } from 'react-use';
import { compose } from 'redux';
import lodash, { deburr } from 'lodash';
import messages from '../../../containers/CodeSearchDialog/messages';
import CustomCodeDescriptionDialog from '../../../components/Dialogs/CustomCodeDescriptionDialog/CustomCodeDescriptionDialog';
import CodeSuggestion from '../../../components/List/Item/CodeSuggestion/CodeSuggestion';
import { SearchSuggestions } from '../../../containers/SearchSuggestions/SearchSuggestions';
import CategoryItem from '../../shared/domain/CategoryItem';
import lumpSumPresets from '../data/lump-sum-presets.json';

const items = lodash.chain(lumpSumPresets).map('items').flatten().sortBy('code').value();

export const styles = (theme) =>
  ({
    searchContainer: {
      'height': '100%',
      'display': 'flex',
      'flexDirection': 'column',
      'paddingBottom': 0,
      'paddingTop': theme.spacing(0.5),
      '&:first-child': {
        paddingTop: theme.spacing(0.5)
      }
    },
    suggestionList: {
      flex: 1,
      overflowY: 'scroll',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch'
    }
  }) as const;

export interface Props extends WithStyles<typeof styles> {
  isInFavorites: (item: CategoryItem) => boolean;
  onAddToFavorites: (item: CategoryItem) => void;
  onClose: () => void;
  onItemSelected: (item: CategoryItem) => void;
}

export const RegistryTab: React.FC<Props> = ({
  isInFavorites,
  onAddToFavorites,
  onClose,
  onItemSelected,
  classes
}: Props) => {
  const [tempSelectedItem, setTempSelectedItem] = React.useState<CategoryItem>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>('');
  const [options, setOptions] = useState<CategoryItem[]>(items);

  const handleFavoriteClick = (item: CategoryItem) => {
    if (isInFavorites(item)) return;

    if (item.description) {
      setTempSelectedItem(item);
    } else {
      onAddToFavorites(item);
    }
  };

  const handleAddFavorite = (description) => {
    if (!tempSelectedItem) return;

    onAddToFavorites({
      ...tempSelectedItem,
      description
    });

    setTempSelectedItem(undefined);
  };

  const resetState = () => {
    setTempSelectedItem(undefined);
  };

  useDebounce(() => setDebouncedSearchValue(searchValue), 300, [searchValue]);

  useEffect(() => {
    if (!debouncedSearchValue) {
      setOptions(items);
    } else {
      const searchTokens = deburr(debouncedSearchValue).toLowerCase().split(' ');
      const foundLumpSums: CategoryItem[] = [];

      for (const item of items) {
        const searchString = deburr(item.searchString).toLowerCase();

        if (searchTokens.every((searchToken) => searchString.includes(searchToken))) {
          foundLumpSums.push(item);
        }
      }

      setOptions(foundLumpSums);
    }
  }, [debouncedSearchValue]);

  return (
    <>
      <DialogContent className={classes.searchContainer}>
        <TextField
          onChange={(e) => setSearchValue(e.target.value)}
          label={<FormattedMessage {...messages.codeSearchLabel} />}
        />
        <SearchSuggestions
          className={classes.suggestionList}
          hits={options}
          clearSearch={() => setSearchValue('')}
          onSuggestionClick={onItemSelected}
          onFavoriteClick={handleFavoriteClick}
          isInFavoritesFunction={isInFavorites}
          SuggestionItemComponent={CodeSuggestion}
          getKeyFunction={(suggestion) => suggestion.code}
        />
      </DialogContent>
      <DialogActions>
        <Button id="close-Dialog" onClick={onClose} color="primary">
          <FormattedMessage {...messages.close} />
        </Button>
      </DialogActions>

      <CustomCodeDescriptionDialog
        mode="append"
        selectedCode={tempSelectedItem}
        open={!!tempSelectedItem}
        onCancel={resetState}
        onSave={handleAddFavorite}
      />
    </>
  );
};

export default compose(withStyles(styles))(RegistryTab) as React.ComponentType<Omit<Props, 'classes'>>;
