import { storage } from 'server/Firebase';
import { logDefault } from '../../../../shared/utils/plog';
import medyxNotSyncPicture from '../../../../images/medyx-not-sync-picture.png';
import actPictureRepository from '../../Storage/cordova/actPictureRepository';

const getPictureDataUrl = async (savedPicture) => {
  if (savedPicture.temporary) {
    if (window.cordova) {
      logDefault({
        type: 'PicturePreview',
        array: ['Trying to load picture', savedPicture.fileName]
      });
      return actPictureRepository.getFileContentAsDataUrl(`${savedPicture.fileName}`);
    }
    return medyxNotSyncPicture;
  }

  const relativePath = savedPicture.location
    .replace(/http.+appspot.com\/o\//, '')
    .replace(/\?.+$/, '')
    .replaceAll('%2F', '/');

  return storage.ref().child(relativePath).getDownloadURL();
};

export default getPictureDataUrl;
