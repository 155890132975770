import { cloneDeep, omit, orderBy, transform, unset, values, keys } from 'lodash';
import isDefined from '../../../../shared/utils/isDefined';

export default class CategorizableCollection {
  constructor(state) {
    this.state = cloneDeep(state);
  }

  moveItem(sourcePath, itemId, destinationPath, destinationIndex) {
    this.deleteFromSource(sourcePath, itemId);
    this.insertAtDestination(destinationPath, itemId, destinationIndex);

    return this.state;
  }

  removeItem(itemId) {
    this.state.items = omit(this.state.items, [itemId]);

    this.deleteFromSource('itemsWithoutCategory', itemId);
    this.removeItemIdInCategories(this.state.categories, itemId);

    return this.state;
  }

  removeItemIdInCategories(categories, itemId, parentPath) {
    const categoryKeys = keys(categories);

    for (let index = 0; index < categoryKeys.length; index += 1) {
      let path;

      if (parentPath) {
        path = `${parentPath}.subCategories.${categoryKeys[index]}`;
      } else {
        path = `categories.${categoryKeys[index]}`;
      }

      this.deleteFromSource(path, itemId);
      const category = categories[categoryKeys[index]];
      if (category.subCategories) {
        this.removeItemIdInCategories(category.subCategories, itemId, path);
      }
    }
  }

  updateItem(itemId, item) {
    if (this.state.items[itemId] === undefined) {
      this.state.itemsWithoutCategory.itemIds.push(itemId);
    }

    this.state.items[itemId] = item;

    return this.state;
  }

  getFromPath(path) {
    return path.split('.').reduce((a, b) => a && a[b], this.state);
  }

  deleteFromSource(path, key) {
    this.getFromPath(path).itemIds = this.getFromPath(path).itemIds.filter((itemId) => itemId !== key);
  }

  insertAtDestination(path, key, index) {
    this.getFromPath(path).itemIds.splice(index, 0, key);
  }

  removeCategory(categoryPath) {
    this.deleteAllItemsInCategoryAndSubCategories(categoryPath);
    unset(this.state, categoryPath);

    return this.state;
  }

  deleteAllItemsInCategoryAndSubCategories(categoryPath) {
    this.getFromPath(categoryPath).itemIds.forEach((itemId) => unset(this.state.items, itemId));

    const subCategoryKeys = keys(this.getFromPath(categoryPath).subCategories);

    for (let index = 0; index < subCategoryKeys.length; index += 1) {
      const path = `${categoryPath}.subCategories.${subCategoryKeys[index]}`;

      this.deleteAllItemsInCategoryAndSubCategories(path);
    }
  }

  addCategory({ id, name }, path) {
    if (path) {
      if (!this.getFromPath(path).subCategories) {
        this.getFromPath(path).subCategories = {};
      }

      this.getFromPath(path).subCategories[id] = {
        category: name,
        itemIds: [],
        order: this.getLatestOrder(this.getFromPath(path).subCategories)
      };
    } else {
      this.state.categories[id] = {
        category: name,
        itemIds: [],
        order: this.getLatestOrder(this.state.categories)
      };
    }

    return this.state;
  }

  getLatestOrder(categories) {
    if (keys(categories).length === 0) {
      return 0;
    }

    const orders = values(categories).map((category) => category.order);

    return Math.max(...orders) + 1;
  }

  getCategoryPathsFlatMap() {
    const { categories } = this.state;
    const paths = [];

    const categoriesOrdered = this.orderByOrderProperty(this.transformObjectMapIntoArray(categories));

    categoriesOrdered.forEach((category) => {
      this.digCategoryPaths(category, '', '', paths, 0);
    });

    return paths;
  }

  digCategoryPaths(category, parentPathIds, parentPathString, paths, level) {
    const currentCategoryPathString = parentPathString
      ? `${parentPathString} > ${category.category}`
      : `${category.category}`;

    const currentCategoryPathId = parentPathIds
      ? `${parentPathIds}.subCategories.${category.id}`
      : `categories.${category.id}`;

    paths.push({
      key: currentCategoryPathId,
      label: currentCategoryPathString,
      level
    });

    const subCategoriesOrdered = this.orderByOrderProperty(this.transformObjectMapIntoArray(category.subCategories));

    subCategoriesOrdered.forEach((subCategory) => {
      this.digCategoryPaths(subCategory, currentCategoryPathId, currentCategoryPathString, paths, level + 1);
    });
  }

  editCategoryName(categoryPath, categoryName) {
    this.getFromPath(categoryPath).category = categoryName;

    return this.state;
  }

  transformObjectMapIntoArray(objectMap) {
    return transform(
      objectMap,
      (result, value, key) => {
        result.push({ id: key, ...value });
      },
      []
    );
  }

  orderByOrderProperty(list) {
    return orderBy(list, ['order'], ['asc']);
  }

  isEmpty() {
    return (
      isDefined(this.state.items) &&
      Object.keys(this.state.items).length === 0 &&
      isDefined(this.state.categories) &&
      Object.keys(this.state.categories).length === 0
    );
  }
}
