import { get, set } from 'lodash';
import { reducer as formReducer } from 'redux-form';
import MULTI_ACT_FORM_NAME from 'app/act/multiAct/ui/component/MultiActForm/constants';
import isDefined from '../../../shared/utils/isDefined';
import { FORCE_DELETE_VALUE, REMOVE_KEY, CLEAR_MUTLI_ACT_PATIENTS_VISIT_CODE } from './actions';

export const reduxFormReducer = (state, action) => {
  switch (action.type) {
    case FORCE_DELETE_VALUE: {
      if (state) {
        // Because when we submit act form, the actForm state becomes undefined...
        const reduxForm = state;
        const propertyToDelete = get(reduxForm.values, action.valuePath);
        if (isDefined(propertyToDelete)) {
          set(reduxForm.values, action.valuePath, null);
        }
        return reduxForm;
      }
      return state;
    }
    case REMOVE_KEY: {
      if (state) {
        const reduxForm = { ...state };
        delete reduxForm.values[action.key];
        return reduxForm;
      }
      return state;
    }
    default:
      return state;
  }
};

export const multiActFormReducer = (state, action) => {
  switch (action.type) {
    case CLEAR_MUTLI_ACT_PATIENTS_VISIT_CODE: {
      if (state) {
        return {
          ...state,
          values: {
            ...state.values,
            patients: state.values.patients.map((patient) => ({
              ...patient,
              visitCode: undefined
            }))
          }
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default formReducer.plugin({
  actForm: reduxFormReducer,
  userInfoForm: reduxFormReducer,
  [MULTI_ACT_FORM_NAME]: multiActFormReducer
});
