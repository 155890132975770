import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchIntercomUserHash } from '../../infrastructure/api/api';
import { fetchIntercomUserHashSucceeded, FETCH_INTERCOM_USER_REQUESTED, fetchIntercomUserHashFailed } from './actions';

function* fetchUser() {
  try {
    const { user_hash: hash } = yield call(fetchIntercomUserHash);
    yield put(fetchIntercomUserHashSucceeded(hash));
  } catch (e) {
    yield put(fetchIntercomUserHashFailed(e));
  }
}

function* intercomSaga() {
  yield takeEvery(FETCH_INTERCOM_USER_REQUESTED, fetchUser);
}

export default intercomSaga;
