import actions from 'redux-form/lib/actions';
import { TRAVEL_EXPENSES_ATTACHMENTS_LIST_FIELD, TRAVEL_EXPENSES_FORM_NAME } from './constants';

export const TRAVEL_EXPENSE_FILE_READY_FOR_UPLOAD = 'travelExpenseFileReadyForUpload';
export const travelExpenseFileReadyForUpload = (
  documentId,
  attachmentType,
  fileEntry,
  persistFileLocallyBeforeUpload
) => ({
  type: TRAVEL_EXPENSE_FILE_READY_FOR_UPLOAD,
  documentId,
  attachmentType,
  fileEntry,
  persistFileLocallyBeforeUpload
});

export const updateSavedAttachmentInTravelExpensesForm = (attachments) =>
  actions.change(TRAVEL_EXPENSES_FORM_NAME, TRAVEL_EXPENSES_ATTACHMENTS_LIST_FIELD, attachments);

export const SAVE_TRAVEL_EXPENSES = 'saveTravelExpenses';
export function saveTravelExpenses(document) {
  return {
    type: SAVE_TRAVEL_EXPENSES,
    document
  };
}

export const SAVE_TRAVEL_EXPENSES_FAILURE = 'saveTravelExpensesFailure';
export function saveTravelExpensesFailure(error) {
  return {
    type: SAVE_TRAVEL_EXPENSES_FAILURE,
    error
  };
}

export const UPDATE_TRAVEL_EXPENSES = 'updateTravelExpenses';
export function updateTravelExpenses(document, changes) {
  return {
    type: UPDATE_TRAVEL_EXPENSES,
    document,
    changes
  };
}

export const UPDATE_TRAVEL_EXPENSES_FAILURE = 'updateTravelExpensesFailure';
export function updateTravelExpensesFailure(error) {
  return {
    type: UPDATE_TRAVEL_EXPENSES_FAILURE,
    error
  };
}
