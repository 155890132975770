import AdminNoteProps from '../../../shared/core/admin/domain/AdminNoteProps';
import AdminNote from '../../../shared/domain/user/admin/note/AdminNote';
import { selectLoggedUser } from '../../containers/Authentication/selectors';
import { getStore } from '../../reduxStore/configureStore';

export function createManualAdminNote(adminNote: string): AdminNoteProps {
  return AdminNote.create(adminNote, selectLoggedUser()(getStore().getState()).getEmail()).getProps();
}

export default createManualAdminNote;
