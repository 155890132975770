import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  checked: {
    'color': theme.palette.common.white,
    'backgroundColor': theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.light
    }
  },
  checkedIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
});

function ButtonCheckbox({ label, onClick, checked, disabled, classes, buttonClasses }) {
  const computedClasses = classNames(classes.root, { [classes.checked]: checked }, buttonClasses);
  return (
    <Button classes={{ root: computedClasses }} disabled={disabled} onClick={onClick}>
      {label}
      {checked && <CheckIcon className={classes.checkedIcon} />}
    </Button>
  );
}

ButtonCheckbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  buttonClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ButtonCheckbox.defaultProps = {
  buttonClasses: undefined,
  disabled: false
};

export default withStyles(styles)(ButtonCheckbox);
