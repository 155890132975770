import moment from 'moment-timezone';
import DateProvider from '../../utils/DateProvider';
import NAM from './NAM';

class RAMQPatient {
  constructor(public nam: NAM) {}

  get age(): number {
    return DateProvider.today().diff(this.nam.birth, 'years');
  }

  public ageAtDate(date: number): number {
    return moment(date).diff(this.nam.birth, 'years');
  }

  public isMinor(): boolean {
    return this.age < 18;
  }

  public isMinorAtDate(date: number) {
    return this.ageAtDate(date) < 18;
  }
}

export default RAMQPatient;
