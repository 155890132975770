import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import PlaceIcon from '@material-ui/icons/Place';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import isDefined from '../../../shared/utils/isDefined';
import DetailsItem from '../Activities/Act/Details/DetailsItem/DetailsItem';
import PrincipalPlaceChip from '../Activities/PrincipalPlaceChip/PrincipalPlaceChip';
import TerritoryPlaceChip from '../Activities/TerritoryPlaceChip/TerritoryPlaceChip';
import { displayPlace } from '../Form/utils/fieldTransformations';
import MixteBillingPlaceChip from '../Activities/MixteBillingPlaceChip/MixteBillingPlaceChip';

export const styles = (theme) => ({
  dark: {
    color: theme.palette.common.black
  },
  light: {
    color: theme.palette.common.white
  },
  place: {
    display: 'inline'
  },
  smallChip: {
    height: '18px',
    marginLeft: '1em',
    cursor: 'inherit'
  },
  supervisorName: {
    fontSize: '0.5rem',
    display: 'block',
    textTransform: 'uppercase',
    textAlign: 'left'
  }
});

export function PlaceDetails({ classes, displaySignatoryName, place, theme }) {
  return (
    <DetailsItem
      icon={<PlaceIcon className={classes[`${theme}`]} />}
      text={
        <div className={classes.container}>
          <Typography className={classnames(classes.place, classes[`${theme}`])}>{displayPlace(place)}</Typography>

          <Restrict atLeastRole={Role.Agent}>
            <PrincipalPlaceChip place={place} />
            <TerritoryPlaceChip place={place} />
            <MixteBillingPlaceChip place={place} />
          </Restrict>

          {displaySignatoryName && isDefined(place.signatory) && (
            <Typography variant="caption" className={classnames(classes.supervisorName, classes[`${theme}`])}>
              {`${place.signatory.firstName} ${place.signatory.lastName}`}
            </Typography>
          )}
        </div>
      }
    />
  );
}

PlaceDetails.propTypes = {
  displaySignatoryName: PropTypes.bool,
  place: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(['dark', 'light'])
};

PlaceDetails.defaultProps = {
  displaySignatoryName: false,
  theme: 'dark'
};

export default withStyles(styles)(PlaceDetails);
