import { orderBy } from 'lodash';
import {
  Avatar,
  Button,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  WithStyles
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useQuery } from '@tanstack/react-query';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import Restrict from 'application/components/restrict';
import Role from 'shared/domain/authentication/Role';
import RecommendationsAPI, { MappedRecommendations } from '../../../infrastructure/api/RecommendationsAPI';
import { selectUserProfileInContext } from '../../User/selectors';
import FleurDeLysIcon from './FleurDeLysIcon.svg';
import PsychologyIcon from './PsychologyIcon.svg';
import TipsIcon from './TipsIcon.svg';
import VerifiedIcon from './VerifiedIcon.svg';
import { ActionSource } from '../../../../shared/core/recommendation/domain/ActionSource';

const StyledChip = withStyles({
  root: {
    '&&:focus': {
      backgroundColor: 'white'
    }
  }
})(Chip);

const styles = (theme) =>
  ({
    container: {
      padding: theme.spacing(2)
    },
    badges: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.spacing(3),
      padding: 5,
      width: 'fit-content'
    },
    activeTab: {
      backgroundColor: 'white'
    },
    suggestionsBadge: {
      fontWeight: 600,
      backgroundColor: theme.palette.primary[15],
      color: `${theme.palette.primary[800]} !important`
    },
    warningsBadge: {
      fontWeight: 600,
      backgroundColor: theme.palette.yellow[300],
      color: `${theme.palette.yellow[900]} !important`
    },
    errorsBadge: {
      fontWeight: 600,
      backgroundColor: theme.palette.red[200],
      marginRight: theme.spacing(1),
      color: 'white !important'
    },
    tipsIcon: {
      height: 24,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    verifiedIcon: {
      height: 24
    },
    listItem: {
      'backgroundColor': `${theme.palette.grey[100]}`,
      'borderBottom': `1px solid ${theme.palette.grey[300]}`,
      '&:last-child': {
        borderBottom: 0
      }
    },
    suggestions: {
      borderLeft: `3px solid ${theme.palette.primary[15]}`
    },
    warnings: {
      borderLeft: `3px solid ${theme.palette.yellow[300]}`
    },
    errors: {
      borderLeft: `3px solid ${theme.palette.red[200]}`
    },
    codeChip: {
      marginRight: 10
    },
    chip: {
      minWidth: theme.spacing(7)
    },
    secondary: {
      display: 'flex',
      alignItems: 'center'
    },
    sourceTypeIcon: {
      height: theme.spacing(3)
    },
    justify: {
      textAlign: 'justify',
      textJustify: 'inter-word',
      marginRight: 10
    },
    actions: {
      display: 'flex',
      justifyContent: 'end'
    }
  }) as const;

interface Props extends WithStyles<typeof styles> {
  codes: string[];
  userProfile: {
    specialty: string;
    role: string;
  };
}

const FALLBACK_LABELS = {
  errors: 'Aucune erreur déctectée',
  infos: 'Aucune information détectée',
  suggestions: 'Aucune recommendation détectée',
  warnings: 'Aucun avertissement détecté'
};

const RecommendationsPrompt: React.FunctionComponent<Props> = ({ classes, codes, userProfile }) => {
  const [openedSection, setOpenedSection] = useState<keyof MappedRecommendations | null>(null);
  const isOnline = navigator.onLine;

  const { data } = useQuery({
    queryKey: ['recommendations', codes],
    queryFn: () => {
      if (isOnline && codes.length > 0) {
        return RecommendationsAPI.get(codes, userProfile.specialty);
      }
    },
    enabled: codes.length > 0
  });

  const recommendations = openedSection && data ? data[openedSection] : [];

  return (
    <>
      <div className={classes.badges}>
        <img src={TipsIcon} alt="Trucs et astuces" className={classes.tipsIcon} />
        <StyledChip
          avatar={
            openedSection ? (
              <Avatar className={classes.suggestionsBadge}>{data?.suggestions.length || 0}</Avatar>
            ) : undefined
          }
          label={openedSection ? 'Recommendations' : data?.suggestions.length || 0}
          className={classNames({
            [classes.suggestionsBadge]: !openedSection,
            [classes.activeTab]: openedSection === 'suggestions'
          })}
          onClick={() => setOpenedSection('suggestions')}
        />
        <StyledChip
          avatar={
            openedSection ? <Avatar className={classes.warningsBadge}>{data?.warnings.length || 0}</Avatar> : undefined
          }
          label={openedSection ? 'Avertissements' : data?.warnings.length || 0}
          className={classNames({
            [classes.warningsBadge]: !openedSection,
            [classes.activeTab]: openedSection === 'warnings'
          })}
          onClick={() => setOpenedSection('warnings')}
        />
        <StyledChip
          avatar={
            openedSection ? <Avatar className={classes.errorsBadge}>{data?.errors.length || 0}</Avatar> : undefined
          }
          label={openedSection ? 'Erreurs' : data?.errors.length || 0}
          className={classNames({
            [classes.errorsBadge]: !openedSection,
            [classes.activeTab]: openedSection === 'errors'
          })}
          onClick={() => setOpenedSection('errors')}
        />
      </div>

      {openedSection && (
        <>
          <List>
            {recommendations.length === 0 ? (
              <ListItem key="noRecommendation" className={classNames(classes.listItem, classes[`${openedSection}`])}>
                <ListItemIcon>
                  <img src={VerifiedIcon} alt="Vérifié" className={classes.verifiedIcon} />
                </ListItemIcon>
                <ListItemText primary={FALLBACK_LABELS[openedSection]} />
              </ListItem>
            ) : (
              orderBy(recommendations, ['source', 'prob', 'code'], ['asc', 'desc', 'asc']).map((recommendation) => (
                <ListItem
                  key={recommendation.code}
                  className={classNames(classes.listItem, classes[`${openedSection}`])}
                >
                  <ListItemIcon className={classes.codeChip}>
                    <Chip key={recommendation.code} label={recommendation.code} className={classes.chip} />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.justify}
                    primary={recommendation.desc}
                    secondary={
                      <div className={classes.secondary}>
                        <Tooltip title={recommendation.source}>
                          <ListItemIcon>
                            <img
                              src={recommendation.source === ActionSource.RAMQ ? FleurDeLysIcon : PsychologyIcon}
                              alt={recommendation.source}
                              className={classes.sourceTypeIcon}
                            />
                          </ListItemIcon>
                        </Tooltip>
                        <span>
                          {recommendation.reason}
                          <Restrict atLeastRole={Role.Agent}>
                            - Match: {Math.round(recommendation.prob * 10000) / 100} %
                          </Restrict>
                        </span>
                      </div>
                    }
                  />
                </ListItem>
              ))
            )}
          </List>

          <div className={classes.actions}>
            <Button onClick={() => setOpenedSection(null)}>Fermer</Button>
          </div>
        </>
      )}
    </>
  );
};

export const mapStateToProps = createStructuredSelector({
  userProfile: selectUserProfileInContext()
});

export default compose<typeof React.Component>(withStyles(styles), connect(mapStateToProps))(RecommendationsPrompt);
