import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActWarningBox from './ActWarningBox';
import ActsWarningWithCodesAndStartTime from './ActWarningBox/ActsWarningWithCodesAndStartTime';

function SameNamWithin24HoursWarning({ currentNam, currentCodes, previous24HoursActs }) {
  if (Array.isArray(currentCodes) && currentCodes.find((code) => parseInt(code.code, 10) === 15120)) {
    return null;
  }

  const actsWithSameNam = previous24HoursActs.filter(
    (act) => currentNam && act.nam && act.nam.slice(0, 12) === currentNam.slice(0, 12)
  );

  return actsWithSameNam.length > 0 ? (
    <ActWarningBox
      acts={actsWithSameNam}
      DetailsComponent={ActsWarningWithCodesAndStartTime}
      message={
        <FormattedMessage id="act-form.nam-warning.same-nam-warning" values={{ number: actsWithSameNam.length }} />
      }
    />
  ) : null;
}

SameNamWithin24HoursWarning.propTypes = {
  currentCodes: PropTypes.array,
  currentNam: PropTypes.string,
  previous24HoursActs: PropTypes.array.isRequired
};

SameNamWithin24HoursWarning.defaultProps = {
  currentNam: undefined,
  currentCodes: undefined
};

export default SameNamWithin24HoursWarning;
