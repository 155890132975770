import PropTypes from 'prop-types';
import React from 'react';
import { useMount } from 'react-use';
import isDefined from '../../../../../shared/utils/isDefined';
import BasicField from '../../BasicField';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';

function SingleSelectCheckboxGroup({
  input,
  list,
  checkboxClasses,
  defaultValue,
  allowUnset,
  disabled = false,
  ...props
}) {
  useMount(() => {
    if (!isDefined(input.value) && defaultValue !== undefined) {
      input.onChange(defaultValue);
    }
  });

  return (
    <BasicField
      InputLabelProps={{
        shrink: true,
        style: { position: 'inherit' }
      }}
      {...props}
    >
      <CheckboxGroup
        buttonClass
        checkboxClasses={checkboxClasses}
        checkboxes={list}
        onCheckboxSelected={(newValue) => {
          if (isDefined(input.value) && input.value === newValue) {
            if (!allowUnset) return;
            input.onChange(null);
          } else {
            input.onChange(newValue);
          }
        }}
        isChecked={({ value, aliases }) => (aliases ? aliases.includes(input.value) : input.value === value)}
        disabled={disabled}
      />
    </BasicField>
  );
}

SingleSelectCheckboxGroup.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  checkboxClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.bool]),
  allowUnset: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

SingleSelectCheckboxGroup.defaultProps = {
  label: undefined,
  checkboxClasses: undefined,
  defaultValue: undefined,
  allowUnset: true
};

export default SingleSelectCheckboxGroup;
