import Act from 'shared/domain/activity/act/model/Act';
import moment from 'moment';

const chronicPainVisitTimeValidations = (act: Act) => {
  const startTime = moment(act.start);
  const endTime = moment(act.end);
  const duration = moment.duration(endTime.diff(startTime)).asMinutes();

  const LIMITS: Record<string, { minDuration: number | null; maxDuration: number | null }> = {
    '9257': {
      minDuration: 45,
      maxDuration: 90
    },
    '9258': {
      minDuration: 90,
      maxDuration: null
    },
    '9259': {
      minDuration: 15,
      maxDuration: 45
    },
    '9260': {
      minDuration: 45,
      maxDuration: null
    }
  };

  for (const { code } of act.codes) {
    const limit = LIMITS[code];

    if (!limit) continue;

    // If there is no maxDuration, minDuration is exclusive
    if (!limit.maxDuration && limit.minDuration && duration <= limit.minDuration) {
      return {
        codes: {
          _error: `La durée de la consultation avec le code ${code} doit être supérieure à ${limit.minDuration} minutes.`
        }
      };
    }

    if (limit.minDuration && duration < limit.minDuration) {
      return {
        codes: {
          _error: `La durée de la consultation avec le code ${code} doit être d'au moins ${limit.minDuration} minutes.`
        }
      };
    }
    if (limit.maxDuration && duration > limit.maxDuration) {
      return {
        codes: {
          _error: `La durée de la consultation avec le code ${code} ne peut pas excéder ${limit.maxDuration} minutes.`
        }
      };
    }
  }

  return {};
};

export default chronicPainVisitTimeValidations;
