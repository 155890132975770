import momentTimezone from 'moment-timezone';
import { extendMoment } from 'moment-range';
import Act, { ActCode, NatureType } from '../../domain/activity/act/model/Act';
import Place from '../../domain/place/model/Place';
import { dateIsDuringPlaceHoliday, dateIsDuringWeekend } from '../../utils/dateTime/dateValidations';

const moment = extendMoment(momentTimezone as any);

class ExceptionalSurgeryHoursService {
  public isExceptionalSurgery({ codes, date, end, place, start }: Act): boolean {
    return (
      this.isPlaceFilled(place) &&
      this.isDateWeekday(date, place) &&
      this.hasSurgeryCode(codes) &&
      this.isOverlappingExceptionalSurgeryHours(date, place.billByAct, { end, start })
    );
  }

  private isPlaceFilled(place: Place | {}): place is Place {
    return Object.keys(place).length > 0;
  }

  private isDateWeekday(date: number, place: Place): boolean {
    return !dateIsDuringPlaceHoliday(date, place) && !dateIsDuringWeekend(date);
  }

  private isOverlappingExceptionalSurgeryHours(
    date: number,
    billedByAct: boolean = false,
    { start, end }: { start: number; end?: number }
  ): boolean {
    if (!end) return false;

    const exceptionalSurgeryTimeRange = moment.range(
      moment(date).set('hour', 17),
      moment(date).set('hour', billedByAct ? 19 : 21)
    );
    const actTimeRange = moment.range(moment(start), moment(end));

    return exceptionalSurgeryTimeRange.overlaps(actTimeRange, { adjacent: false });
  }

  private hasSurgeryCode(codes: ActCode[]): boolean {
    return codes.some(({ natureType }) => natureType === NatureType.SURGERY);
  }
}

export default new ExceptionalSurgeryHoursService();
