import { get } from 'lodash';
import { UNKNOWN_PROFESSIONAL_REFERENCE } from '../../../../shared/ramq/requiredDetails/professionalReferenceConstants';
import { SingleLineField } from '../../../components/Form/Fields';

const summaryRender = (code) => [
  {
    name: 'Prof.',
    value: `${get(code[UNKNOWN_PROFESSIONAL_REFERENCE], 'firstName', '-')} ${get(
      code[UNKNOWN_PROFESSIONAL_REFERENCE],
      'lastName',
      '-'
    )}`
  }
];

const associatedFields = [
  {
    label: 'Nom du pharm.',
    getName: (index) => `codes[${index}].${UNKNOWN_PROFESSIONAL_REFERENCE}.lastName`,
    type: SingleLineField
  },
  {
    label: 'Prénom du pharm.',
    getName: (index) => `codes[${index}].${UNKNOWN_PROFESSIONAL_REFERENCE}.firstName`,
    type: SingleLineField
  }
];

const quebecPharmacist = { associatedFields, summaryRender };

export default quebecPharmacist;
