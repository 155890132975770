import RalphApi from 'application/services/ralph-api';

export default class StripeService {
  public static async createSubscriptionSession(email: string, priceId: string, recaptchaToken?: string) {
    const response = await RalphApi.request<{ clientSecret: string }>({
      endpoint: 'stripe/subscription-session',
      method: 'POST',
      authenticated: !recaptchaToken,
      body: { email, priceId },
      headers: recaptchaToken ? { 'recaptcha-token': recaptchaToken } : {}
    });

    return response.data;
  }

  public static async getSubscriptionSessionStatus(sessionId: string, recaptchaToken?: string) {
    const response = await RalphApi.request<{ status: string }>({
      endpoint: `stripe/subscription-session-status?session-id=${sessionId}`,
      method: 'GET',
      authenticated: false,
      headers: recaptchaToken ? { 'recaptcha-token': recaptchaToken } : {}
    });

    return response.data;
  }
}
