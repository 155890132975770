import { get } from 'lodash';
import specialtiesConfig from './config/specialties.config';
import { selectSpecialty } from '../app/containers/User/selectors';
import { getStore } from '../app/reduxStore/configureStore';
import specialtyFeaturesConfiguration from './specialtyFeaturesConfiguration';
import SpecialtyName from '../shared/core/doctor/domain/SpecialtyName';
import JSONConfigRepository, {
  SpecialtyJSONConfig
} from '../shared/domain/user/specialty/repository/JSON/JSONConfigRepository';
import SpecialtyFactory from '../shared/domain/user/specialty/factory/SpecialtyFactory';
import { SpecialtyConfigValue } from '../shared/domain/user/specialty/repository/ConfigRepository';

const SPECIALTIES_SUPPORTED_BY_FACTORY = [
  SpecialtyName.GENERAL_SURGEON,
  SpecialtyName.ORTHOPEDIC_SURGEON,
  SpecialtyName.OTOLARYNGOLOGIST
];

export function getFeatureConfig(featureName: string, fallback: SpecialtyConfigValue): SpecialtyConfigValue {
  const configRepository = new JSONConfigRepository(specialtiesConfig as SpecialtyJSONConfig);
  const specialtyFactory = new SpecialtyFactory(configRepository);
  const specialtyName: SpecialtyName = selectSpecialty()(getStore().getState());

  if (SPECIALTIES_SUPPORTED_BY_FACTORY.includes(specialtyName)) {
    const specialty = specialtyFactory.create(specialtyName);

    return specialty.getConfig(featureName);
  }

  return get(specialtyFeaturesConfiguration, [specialtyName, featureName], fallback);
}
