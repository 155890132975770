import moment from 'moment-timezone';
import { actFormFields } from 'app/containers/ActForm/constants';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { NatureType } from 'shared/core/activity/domain/Act';
import SpecialtyName from 'shared/core/doctor/domain/SpecialtyName';
import Act from 'shared/domain/activity/act/model/Act';
import RAMQCodes from 'shared/ramq/RamqCodes';

const NO_ERRORS = {};

const endTimeForSurgeryCodesBilledBySurgeonValidation = (act: Act): Record<string, unknown> => {
  if (act.end) return NO_ERRORS;

  if (!isUserSpecialtySurgeon()) return NO_ERRORS;

  if (!actContainsRAMQSurgeryCode(act)) return NO_ERRORS;

  const actStartTime = moment(act.start);

  const intervalLowerBound = moment(act.date).hour(17).startOf('hour');
  const intervalUpperBound = moment(act.date)
    .hour(act.place.billByAct ? 19 : 21)
    .startOf('hour');

  if (actStartTime.isBetween(intervalLowerBound, intervalUpperBound, undefined, '[]')) {
    return {
      [actFormFields.end]: 'Ce champ est requis'
    };
  }

  return NO_ERRORS;
};

const isUserSpecialtySurgeon = (): boolean => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  return [SpecialtyName.GENERAL_SURGEON, SpecialtyName.ORTHOPEDIC_SURGEON, SpecialtyName.OTOLARYNGOLOGIST].includes(
    userSpecialty
  );
};

const actContainsRAMQSurgeryCode = (act: Act): boolean =>
  (act.codes ?? []).some(({ code }) => {
    const ramqCode = RAMQCodes.get(code);

    if (!ramqCode) return false;

    return ramqCode.natureType === NatureType.SURGERY;
  });

export default endTimeForSurgeryCodesBilledBySurgeonValidation;
