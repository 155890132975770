import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GroupsPage from 'application/pages/groups/groups';
import GroupIndex from 'application/pages/groups/group';
import { GROUP_ID_PARAM } from 'application/routes';
import Role from 'shared/domain/authentication/Role';
import RestrictedOutlet from 'application/components/restricted-outlet';

const GroupsIndex = () => (
  <Routes>
    <Route element={<RestrictedOutlet atLeastRole={Role.Agent} />}>
      <Route index element={<GroupsPage />} />
    </Route>
    <Route path={`${GROUP_ID_PARAM}/*`} element={<GroupIndex />} />
  </Routes>
);

export default GroupsIndex;
