import { Collapse, StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { gql, useQuery } from '@apollo/client';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import { useSelector } from 'react-redux';
import { selectActDate } from 'app/containers/ActForm/selectors';
import { UserId } from '../../../../../shared/domain/user/User';
import AlertSeverity from '../../../../ui/shared/severity/AlertSeverity';
import { isValidNam } from '../../../../validations/act/nam/namValidation';
import Alert from '../../../Alert/Alert';
import CollapsableIcon from '../../../CollapsableIcon/CollapsableIcon';
import ActHistory from './ActHistory';
import messages from './messages';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';

export const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
  paddingTop: {
    paddingTop: 5
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  expand: {
    display: 'flex',
    justifyContent: 'end'
  }
});

interface Props {
  nam?: string;
  user: { id: UserId };
  classes?: any;
  intl?: any;
}

const SEARCH_PATIENT_ACTIVITIES_QUERY = gql`
  query FetchPatientRelatedActivities($query: GetActivitiesInput!) {
    activitiesResult(query: $query) {
      activities {
        id
        type
        date
        startedAt
        endedAt
        codes {
          code
        }
      }
    }
  }
`;

export interface PatientActivity {
  id: string;
  type: ActivityType;
  date: Date;
  startedAt: Date | null;
  endedAt: Date | null;
  codes: Array<{ code: string }>;
}

interface ActivitiesResultQuery {
  activitiesResult: {
    activities: PatientActivity[];
  };
}

export const ActsHistoryWithNam: React.FunctionComponent<Props> = ({ nam, user, classes, intl }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const actDate = useSelector(selectActDate());

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { data } = useQuery<ActivitiesResultQuery>(SEARCH_PATIENT_ACTIVITIES_QUERY, {
    variables: {
      query: {
        nams: [nam],
        userIds: [user.id],
        startDate: moment(actDate).startOf('day').subtract(3, 'years'),
        endDate: moment(actDate),
        statuses: [
          ActivityStatus.NEED_FIX,
          ActivityStatus.PAID,
          ActivityStatus.PROCESSING,
          ActivityStatus.SENT,
          ActivityStatus.WAITING
        ]
      }
    },
    skip: !isValidNam(nam)
  });

  if (!data || data.activitiesResult.activities.length === 0) return null;

  const { activities } = data.activitiesResult;

  return (
    <Alert severity={AlertSeverity.INFO} message={<FormattedMessage {...messages.actHistory} />}>
      {activities.length > 1 && (
        <CollapsableIcon
          expanded={expanded}
          onClick={handleExpandClick}
          label={intl.formatMessage(messages.seeMore)}
          selected={false}
        />
      )}
      <div className={classes.paddingTop}>
        <ActHistory act={activities[0]} />
        {activities.map((act, index) => {
          if (index > 0) {
            return (
              <Collapse key={`collapse-${act.id}`} in={expanded} timeout="auto" unmountOnExit>
                <div className={classNames(classes.borderTop, classes.paddingTop)}>
                  <ActHistory key={`act-history-${act.id}`} act={act} />
                </div>
              </Collapse>
            );
          }

          return null;
        })}
      </div>
    </Alert>
  );
};

export default compose(injectIntl, withStyles(styles))(ActsHistoryWithNam);
