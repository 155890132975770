import SingleSelectCheckboxGroup from 'app/components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { PlaceAutocompleteField, SingleLineField } from '../../../components/Form/Fields';
import { AssociatedField } from '../requiredDetail.type';

const ORIGIN_LOCATION_OF_REMOTE_CONSULTATION = 'originLocationOfRemoteConsultation';

const associatedFields: AssociatedField[] = [
  {
    getName: (index) => `codes[${index}].${ORIGIN_LOCATION_OF_REMOTE_CONSULTATION}.place`,
    label: 'Lieu',
    shouldFieldBeHidden: ({ originLocationOfRemoteConsultation }) =>
      originLocationOfRemoteConsultation?.type !== 'physical',
    type: PlaceAutocompleteField
  },
  {
    getName: (index) => `codes[${index}].${ORIGIN_LOCATION_OF_REMOTE_CONSULTATION}.postalCode`,
    label: 'Code postal',
    shouldFieldBeHidden: ({ originLocationOfRemoteConsultation }) =>
      originLocationOfRemoteConsultation?.type !== 'geographical',
    type: SingleLineField
  },
  {
    getName: (index) => `codes[${index}].${ORIGIN_LOCATION_OF_REMOTE_CONSULTATION}.type`,
    label: "Lieu d'origine la consultation à distance",
    list: [
      { label: 'Lieu RAMQ', value: 'physical' },
      { label: 'Lieu géographique', value: 'geographical' }
    ],
    type: SingleSelectCheckboxGroup
  }
];

export default { associatedFields };
