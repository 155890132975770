import PropTypes from 'prop-types';
import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import { GEOGRAPHICAL_LOCATION_TYPE, PHYSICAL_LOCATION_TYPE } from '../../../shared/ramq/location/constants';
import isDefined from '../../../shared/utils/isDefined';
import InputDialogTrigger from '../../components/Dialogs/DialogTrigger/InputDialogTrigger/InputDialogTrigger';
import SingleSelectCheckboxGroup from '../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { SingleLineField } from '../../components/Form/Fields';
import FormElement from '../../components/Form/FormElement/FormElement';
import { displayPlace } from '../../components/Form/utils/fieldTransformations';
import PlacesDialog from '../PlacesPreferencesDialog/PlacesDialog';

function TravelPlaceInformation({ meta, input }) {
  const selectedPlaceType = input.value?.type;
  const placeNumber = input.value && input.value?.number;
  const translate = useTranslate();
  return (
    <>
      <div style={{ marginBottom: 8 }}>
        <SingleSelectCheckboxGroup
          list={[
            {
              label: translate('travel-expenses.ramq-location'),
              value: PHYSICAL_LOCATION_TYPE
            },
            {
              label: translate('travel-expenses.geographical-location'),
              value: GEOGRAPHICAL_LOCATION_TYPE
            }
          ]}
          meta={meta}
          input={{
            onChange: (type) => input.onChange({ type }),
            value: input.value?.type
          }}
        />
      </div>
      {(selectedPlaceType === PHYSICAL_LOCATION_TYPE ||
        (selectedPlaceType === GEOGRAPHICAL_LOCATION_TYPE && isDefined(placeNumber))) && (
        <InputDialogTrigger
          label={translate('act-form.location')}
          input={input}
          meta={{}} // Because we want validations to appear on the checkboxes and not twice
          formatValueFunction={displayPlace}
          DialogComponent={PlacesDialog}
        />
      )}
      {selectedPlaceType === GEOGRAPHICAL_LOCATION_TYPE && !isDefined(placeNumber) && (
        <FormElement
          name={`${input.name}.zipCode`}
          component={SingleLineField}
          label={translate('travel-expenses.postal-code')}
          inputProps={{ maxLength: '6' }}
        />
      )}
    </>
  );
}

TravelPlaceInformation.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default TravelPlaceInformation;
