import { set } from 'lodash';
import SingleSelectCheckboxGroup from '../../../components/Form/Checkbox/SingleSelectCheckboxGroup/SingleSelectCheckboxGroup';
import { SingleLineField } from '../../../components/Form/Fields';
import { combineErrors } from '../../../validations/act/sendValidation/sendValidation';
import { isNotAValidValue } from '../../../validations/common/reduxFormValidation';

export const onlyOneValueIsFilled = (code, codeValue) =>
  code.code === codeValue &&
  ((isNotAValidValue(code.authorizationNumber) && isNotAValidValue(code.breastReductionContext)) ||
    (!isNotAValidValue(code.authorizationNumber) && !isNotAValidValue(code.breastReductionContext)));

const code1434HasNoFieldWithValidValue = (code) => onlyOneValueIsFilled(code, '1434');

export const AUTHORISATION_NUMBER_ERROR = "Entrez un numéro d'autorisation ou choisissez l'une des options suivantes";
export const noValidAuthorizationFieldError = (index) =>
  set(
    { elementIdToScrollTo: `codes[${index}].authorizationNumber` },
    `codes[${index}].authorizationNumber`,
    AUTHORISATION_NUMBER_ERROR
  );

const OnlyOneFieldMustBeFilled = (act) => {
  const codesInError = act.codes.map((code, index) =>
    code1434HasNoFieldWithValidValue(code) ? noValidAuthorizationFieldError(index) : {}
  );
  return codesInError.reduce(combineErrors, {});
};

const associatedValidations = [OnlyOneFieldMustBeFilled];

const getContextLabel = (context) => (context === 'pcma' ? 'pcma' : '> 150g');

const summaryRender = (code) => [
  {
    name: code.breastReductionContext ? getContextLabel(code.breastReductionContext) : 'autorisation',
    value: code.breastReductionContext ? 'Oui' : code.authorizationNumber
  }
];

const associatedFields = [
  {
    label: 'No Autorisation',
    getName: (index) => `codes[${index}].authorizationNumber`,
    notRequired: true,
    type: SingleLineField
  },
  {
    label: '',
    getName: (index) => `codes[${index}].breastReductionContext`,
    type: SingleSelectCheckboxGroup,
    notRequired: true,
    list: [
      { label: 'Post-chirurgie mammaire assuré', value: 'pcma' },
      { label: '> 150g', value: 'moreThan150g' }
    ]
  }
];

const reductionMammaire150g = { associatedFields, associatedValidations, summaryRender };

export default reductionMammaire150g;
