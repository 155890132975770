import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import Icon from 'application/components/icon';
import OptionTiles from 'application/components/option-tiles';
import useTranslate from 'application/hooks/use-translate';
import Button from 'application/components/button';

interface StripePlan {
  id: string;
  priceId: string;
  name: string;
  description: string;
  bullets: string[];
  monthlyPrice: number;
}

const STRIPE_PLANS_QUERY = gql`
  query AppSignupPlans {
    plans {
      id
      priceId
      name
      description
      bullets
      monthlyPrice
    }
  }
`;

interface Props {
  onPriceIdSelected: (priceId: string) => void;
}

const SettingsSubscriptionSignupPlans = ({ onPriceIdSelected }: Props) => {
  const translate = useTranslate('pages.settings.details.subscription.signup');
  const { data, loading } = useQuery<{ plans: StripePlan[] }>(STRIPE_PLANS_QUERY);
  const [selectedPriceId, setSelectedPriceId] = useState<string | null>(null);

  if (loading || !data) {
    return (
      <div className="w-full py-12 text-center text-4xl">
        <Icon loading />
      </div>
    );
  }

  const plansMap = new Map(data.plans.map((plan) => [plan.priceId, plan]));

  return (
    <>
      <OptionTiles
        className="w-full flex-col gap-4 p-4"
        value={selectedPriceId}
        options={Array.from(plansMap.keys())}
        onChange={setSelectedPriceId}
        renderOption={(stripePriceId) => {
          const plan = plansMap.get(stripePriceId)!;

          return (
            <>
              <div className="text-sm font-bold text-primary-900">{plan.name}</div>
              <div className="font-bold text-primary-900">
                <span className="text-2xl">{plan.monthlyPrice / 100}</span>
                <sup className="relative top-[-10px]">{translate('price-suffix')}</sup>
              </div>
              <div className="text-sm leading-snug text-neutral-500">{plan.description}</div>
              <ul className="list-inside list-disc text-justify text-xs text-neutral-500">
                {plan.bullets.map((bullet) => (
                  <li key={bullet}>{bullet}</li>
                ))}
              </ul>
            </>
          );
        }}
      />
      <div className="flex w-full justify-end p-4 pt-0">
        <Button
          type="button"
          icon="arrow-right"
          theme="primary"
          disabled={!selectedPriceId}
          onClick={() => selectedPriceId && onPriceIdSelected(selectedPriceId)}
        >
          {translate('next')}
        </Button>
      </div>
    </>
  );
};

export default SettingsSubscriptionSignupPlans;
