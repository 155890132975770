import BillingType from '../../domain/billing/model/BillingType';
import { MixteSummaryRAMQPlace } from './MixteSummary.types';
import MixteSummaryDayLine from './MixteSummaryDayLine.model';

type SpecialConsideration = 'A' | 'B';

export default class MixteSummary {
  private _billingType: string;

  private _complementaryInformation: string;

  private _days: MixteSummaryDayLine[];

  private _externalNumber: number;

  private _lastSuccessDeferredPayment: number;

  private _periodFrom: number;

  private _periodTo: number;

  private _place: MixteSummaryRAMQPlace;

  private _type: string;

  private _poolNumber?: string;

  constructor(
    billingType: BillingType,
    lastSuccessDeferredPayment: number,
    complementaryInformation: string,
    externalNumber: number,
    periodFrom: number,
    periodTo: number,
    place: MixteSummaryRAMQPlace,
    days: MixteSummaryDayLine[] = [],
    poolNumber?: string
  ) {
    this._billingType = billingType;
    this._days = days;
    this._type = 'mixteSummary';
    this._lastSuccessDeferredPayment = lastSuccessDeferredPayment;
    this._complementaryInformation = complementaryInformation;
    this._externalNumber = externalNumber;
    this._periodFrom = periodFrom;
    this._periodTo = periodTo;
    this._place = place;
    this._poolNumber = poolNumber;
  }

  public get billingType(): string {
    return this._billingType;
  }

  public get complementaryInformation(): string {
    return this._complementaryInformation;
  }

  public get days(): MixteSummaryDayLine[] {
    return this._days;
  }

  public get externalNumber(): number {
    return this._externalNumber;
  }

  public get lastSuccessDeferredPayment(): number {
    return this._lastSuccessDeferredPayment;
  }

  public get periodFrom(): number {
    return this._periodFrom;
  }

  public get periodTo(): number {
    return this._periodTo;
  }

  public get place(): MixteSummaryRAMQPlace {
    return this._place;
  }

  public get type(): string {
    return this._type;
  }

  public get poolNumber(): string | undefined {
    return this._poolNumber;
  }

  public get totalDuration(): string {
    const addUpDuration = (total: number, dayLine: MixteSummaryDayLine) => total + parseInt(dayLine.duration, 10);

    return this._days.reduce(addUpDuration, 0).toString();
  }

  public get totalPerDiems(): string {
    const addUpPerDiem = (total: number, dayLine: MixteSummaryDayLine) => total + parseInt(dayLine.perDiem, 10);

    return this._days.reduce(addUpPerDiem, 0).toString();
  }

  get specialConsiderations(): SpecialConsideration[] {
    // Documentation : https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/manuels/155-brochure-2-specialistes/GuideSpec_br2.pdf

    const specialConsiderations: SpecialConsideration[] = [];

    /**
     * LETTRE B : Refacturation après annulation ou refus de paiement.
     * Dans ce cas, inscrire un B dans cette case et inscrire le numéro d’identification de la demande de
     * paiement qui a fait l’objet du refus de paiement et la date de l’état de compte sur lequel il figure dans la
     * case RENSEIGNEMENTS COMPLÉMENTAIRES.
     */
    if (this.complementaryInformation?.length > 0) specialConsiderations.push('B');

    /** NOTE :
     * Si plus d’une lettre doit être utilisée, toujours inscrire les lettres en respectant l’ordre alphabétique
     */
    return specialConsiderations.sort();
  }
}
