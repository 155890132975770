import { findIndex } from 'lodash';
import RAMQCodes from '../../../../shared/ramq/RamqCodes';
import intensiveCareSupportRole1 from '../../requiredDetails/IntensiveCareRole1StartTime/IntensiveCareRole1StartTime';
import { getStore } from '../../../reduxStore/configureStore';
import { selectSpecialty } from '../../../containers/User/selectors';

const intensiveCarePatientSupportCodes = ['9097', '9098'];

const excludedCodesFromLogic = ['900', '929'];

const isRole1AlongWithIntensiveCarePatientSupportCode = (actCode, codeList, { date }) => {
  if (intensiveCarePatientSupportCodes.includes(actCode.code) || excludedCodesFromLogic.includes(actCode.code)) {
    return false;
  }

  const userSpecialty = selectSpecialty()(getStore().getState());
  const possibleRolesForCode = RAMQCodes.getRolesForCodeEffectiveOnAndForSpecialty(actCode.code, date, userSpecialty);

  if (!possibleRolesForCode.includes('1')) {
    return false;
  }

  if (findIndex(codeList, ({ code }) => intensiveCarePatientSupportCodes.includes(code)) !== -1) {
    return true;
  }

  return false;
};

const addRole1StartTimeFieldForIntensiveCareUnitSupportIfNeeded = (currentCode, codeList, act) =>
  isRole1AlongWithIntensiveCarePatientSupportCode(currentCode, codeList, act)
    ? intensiveCareSupportRole1.associatedFields
    : [];

export default addRole1StartTimeFieldForIntensiveCareUnitSupportIfNeeded;
