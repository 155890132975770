import { createSelector } from '@reduxjs/toolkit';
import { getUser, selectPreferences } from '../../../containers/User/selectors';

const selectSpecialtyDiagnosticCodes = (state) => state.specialtyDiagnosticCodes;
const selectSpecialtyDiagnosticCodesLoaded = (state) => state.specialtyDiagnosticCodesLoaded;

const selectSpecialtyDiagnosticCodesFromPreferences = createSelector(selectPreferences, selectSpecialtyDiagnosticCodes);

const selectSpecialtyDiagnosticCodesLoadedFromPreference = createSelector(
  selectPreferences,
  selectSpecialtyDiagnosticCodesLoaded
);

export const selectSpecialtyDiagnosticCodesFromUser = () =>
  createSelector(getUser, selectSpecialtyDiagnosticCodesFromPreferences);

export const selectSpecialtyDiagnosticCodesLoadedFromUser = () =>
  createSelector(getUser, selectSpecialtyDiagnosticCodesLoadedFromPreference);
