import { buildRegexMatchingWords, removeAccentAndLowercase } from '../../../../utils/wordFormatUtilities';

export const getSearchResultsOnObjects = (
  searchedValue,
  codes,
  searchedPropertyName,
  limit,
  initiallyFull = false,
  updateMaxSearchReachedAction = () => ({})
) => {
  updateMaxSearchReachedAction(false);
  const results = [];
  const filteredSearchValue = searchedValue.trim();

  if (filteredSearchValue.length === 0) {
    return initiallyFull ? codes : results;
  }

  const searchValueWithoutAccent = removeAccentAndLowercase(filteredSearchValue);
  const searchKeywordsRegex = buildRegexMatchingWords(searchValueWithoutAccent);

  for (let i = 0; i < codes.length; i += 1) {
    if (searchKeywordsRegex.test(codes[i][searchedPropertyName])) {
      results.push(codes[i]);
      if (limit && results.length === limit) {
        updateMaxSearchReachedAction(true);
        break;
      }
    }
  }
  return results;
};

export default getSearchResultsOnObjects;
