import {
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SEARCH_SUCCESS,
  GET_USERS_SUCCESS,
  SEARCH_USERS,
  SEARCH_USERS_ERROR,
  SEARCH_USERS_SUCCESS,
  USERS_SEARCH,
  SEARCHED_USER_IDS_CHANGED
} from './actions';

const initialState = {
  users: [],
  loading: true,
  filteredUserIds: null,
  searching: false,
  usersMeta: {},
  searchedUserIds: []
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCHED_USER_IDS_CHANGED:
      return {
        ...state,
        searchedUserIds: action.userIds
      };
    case GET_USERS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SEARCH_USERS:
      return {
        ...state,
        searching: true,
        error: false
      };
    case USERS_SEARCH:
      return {
        ...state,
        searching: true,
        error: false
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users
      };
    case GET_USERS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.searchResult.users,
        usersMeta: action.searchResult.usersMeta,
        searching: false
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searching: false,
        filteredUserIds: action.userIds
      };
    case SEARCH_USERS_ERROR:
    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}

export default usersReducer;
