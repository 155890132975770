import { createErrorMapWithEveryValidationFunction, requiredFieldValidations } from '../common/reduxFormValidation';

const placeSignatoryValidation = (signatory) => {
  if (!signatory.billByAct) {
    return createErrorMapWithEveryValidationFunction(
      signatory,
      requiredFieldValidations(['firstName', 'lastName', 'annexe19'])
    );
  }
  return {};
};

export default placeSignatoryValidation;
