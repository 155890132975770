import _, { get } from 'lodash';
import isDefined from '../../utils/isDefined';
import MixteSummaryDayLineActivity from './MixteSummaryDayLineActivity.model';
import MixteSummaryDayLine from './MixteSummaryDayLine.model';
import MixteSummaryDayLineSchedule from './MixteSummaryDayLineSchedule.model';
import LegacyMixteDaysComputator from './MixteDaysComputator.legacy';
import LatestMixteDaysComputator from './MixteDaysComputator.latest';
import NewStructureMixteConstraint from '../../domain/ramq/mixte/contraint/NewStructureMixteContraint';
import { ComputedMixteDay } from './MixteDaysComputator.types';
import { MixteDay } from '../../domain/collection/activity/Mixte.types';
import HOSPITAL_ACTIVITY_AREA_MAP from '../../ramq/domainValues/activityAreas';

export default class MixteSummaryDayLineMaker {
  public static make(mixteDays: MixteDay[]): MixteSummaryDayLine[] {
    if (mixteDays.length === 0) {
      return [];
    }

    const mixteSummaryDayLines = MixteSummaryDayLineMaker.prepareMixteDays(mixteDays).reduce(
      MixteSummaryDayLineMaker.makeMixteSummaryDayLine,
      new Map()
    );

    return [...mixteSummaryDayLines.values()];
  }

  private static prepareMixteDays(mixteDays: MixteDay[]): ComputedMixteDay[] {
    const [legacyMixteDays, latestMixteDays] = _.chain(mixteDays)
      .filter(MixteSummaryDayLineMaker.isDayNotEmpty)
      .sort(MixteSummaryDayLineMaker.dayComparator)
      .partition(MixteSummaryDayLineMaker.partitionMixteDays)
      .value();

    const computedLegacyMixteDays = new LegacyMixteDaysComputator(legacyMixteDays).compute();
    const computedLatestMixteDays = new LatestMixteDaysComputator(latestMixteDays).compute();

    return [...computedLegacyMixteDays, ...computedLatestMixteDays];
  }

  private static isDayNotEmpty(day: MixteDay): boolean {
    const perDiems = get(day, 'perdiems', []);

    return perDiems.length > 0 || (isDefined(day.start) === true && isDefined(day.end) === true);
  }

  private static dayComparator(dayA: MixteDay, dayB: MixteDay): number {
    const dateA = dayA.start || dayA.date;
    const dateB = dayB.start || dayB.date;

    return dateA - dateB;
  }

  private static partitionMixteDays(day: MixteDay): boolean {
    return !NewStructureMixteConstraint.isSatisfiedBy('mixte', day.date);
  }

  private static createNewDayLine(index: number, mixteDays: ComputedMixteDay): MixteSummaryDayLine {
    return new MixteSummaryDayLine(index, new MixteSummaryDayLineSchedule(mixteDays.am, mixteDays.date, mixteDays.pm));
  }

  private static makeMixteSummaryDayLine(
    dayLines: Map<string, MixteSummaryDayLine>,
    mixteDay: ComputedMixteDay
  ): Map<string, MixteSummaryDayLine> {
    const dayLineKey = `${mixteDay.date}.${+mixteDay.am}.${+mixteDay.pm}`;
    const dayLine = dayLines.get(dayLineKey) || MixteSummaryDayLineMaker.createNewDayLine(dayLines.size + 1, mixteDay);
    const { dispensatoryArea = HOSPITAL_ACTIVITY_AREA_MAP.generalCare, code, duration } = mixteDay;

    dayLine.addActivity(
      new MixteSummaryDayLineActivity(
        code,
        dispensatoryArea,
        duration,
        dayLine.index * 3 - 3 + 1 + dayLine.activities.length
      )
    );

    dayLines.set(dayLineKey, dayLine);

    return dayLines;
  }
}
