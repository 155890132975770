import React, { useCallback, useMemo } from 'react';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import FormRow from '../../Form/FormRow/FormRow';
import SelectedContextElements from '../NewContextElementsSelector/SelectedContextElements/SelectedContextElements';
import ContextElement from '../../../favorite/contextElement/domain/ContextElement';

export interface Props extends WrappedFieldProps {}

export const SelectedRamqContextElements: React.FunctionComponent<Props> = ({ input }: Props) => {
  const getRamqContextElementInputValue: ContextElement[] = useMemo(() => {
    const ramqContextElementsInputValue = input.value;

    if (ramqContextElementsInputValue === '') {
      return [];
    }

    return ramqContextElementsInputValue;
  }, [input]);

  const handleCodeDelete = useCallback(
    (index: number): void => {
      const ramqContextElementsInputValue = getRamqContextElementInputValue;

      ramqContextElementsInputValue.splice(index, 1);

      input.onChange(ramqContextElementsInputValue);
    },
    [getRamqContextElementInputValue, input]
  );

  return getRamqContextElementInputValue.length > 0 ? (
    <FormRow>
      <SelectedContextElements contextElements={getRamqContextElementInputValue} onDelete={handleCodeDelete} />
    </FormRow>
  ) : null;
};

export default SelectedRamqContextElements;
