import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Role from 'shared/domain/authentication/Role';
import Restrict from 'application/components/restrict';
import AppHeaderContainer from '../AppHeaderContainer/AppHeaderContainer';
import messages from '../messages';
import { selectUserProfileInContext } from '../../../containers/User/selectors';

export function SimpleHeader({ titleHeaderIntlId, currentUserProfile }) {
  return (
    <AppHeaderContainer>
      <Typography type="title" color="inherit" noWrap>
        <FormattedMessage {...messages[titleHeaderIntlId]} />
        <Restrict atLeastRole={Role.Agent}>
          <span>
            {currentUserProfile.firstName && ` (${currentUserProfile.firstName} ${currentUserProfile.lastName})`}
          </span>
        </Restrict>
      </Typography>
    </AppHeaderContainer>
  );
}

SimpleHeader.propTypes = {
  titleHeaderIntlId: PropTypes.string.isRequired,
  currentUserProfile: PropTypes.object
};

SimpleHeader.defaultProps = {
  currentUserProfile: {}
};

export const mapStateToProps = createStructuredSelector({
  currentUserProfile: selectUserProfileInContext()
});

export default connect(mapStateToProps)(SimpleHeader);
