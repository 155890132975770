import { List, ListItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as React from 'react';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';
import ClickableDetectedNam from './ClickableDetectedNam';
import { selectNam } from '../../selectors';

function SingleChoiceNamsSelector({ nams, onSelectNam }) {
  const selectedNam = useSelector(selectNam());

  return (
    <List>
      {uniq(nams).map((nam) => (
        <ListItem key={`list-item-${nam}`}>
          <ClickableDetectedNam
            key={nam}
            nam={nam}
            onSelectNam={(seletedNam) => onSelectNam(seletedNam)}
            selectedNam={selectedNam}
          />
        </ListItem>
      ))}
    </List>
  );
}

SingleChoiceNamsSelector.propTypes = {
  nams: PropTypes.array.isRequired,
  onSelectNam: PropTypes.func.isRequired
};

export default SingleChoiceNamsSelector;
