import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updatePeriod } from '../../../../../PeriodsBilling/actions';
import AdministrativeNotes from '../../../../../../adminConsole/ui/components/AdministrativeNotes/AdministrativeNotes';
import { AdministrativeNoteType } from '../../../../../../../shared/core/activity/domain/AdministrativeNote';

export const styles = (theme) => ({
  extraSpacingDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  commentsGrid: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  gridItems: {
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  correction: {
    '& > * *': {
      color: '#e53935bf'
    }
  }
});

const renderOnlyIfActivityHasComment = (activity) => activity.corrections || activity.adminNotes || null;
export function ActivityCommentLists({ activity, onUpdateAdminNotes, classes }) {
  return (
    renderOnlyIfActivityHasComment(activity) && (
      <Grid container className={classes.commentsGrid}>
        <Divider className={classes.extraSpacingDivider} />
        <Grid item sm={6} xs={12} className={classes.gridItems}>
          <AdministrativeNotes
            activity={activity}
            notes={activity.corrections}
            onUpdate={onUpdateAdminNotes('corrections')}
            type={AdministrativeNoteType.NEED_FIX}
          />
        </Grid>
        <Grid item sm={6} xs={12} className={classes.gridItems}>
          <AdministrativeNotes
            activity={activity}
            notes={activity.adminNotes}
            onUpdate={onUpdateAdminNotes('adminNotes')}
            type={AdministrativeNoteType.DEFAULT}
          />
        </Grid>
      </Grid>
    )
  );
}

ActivityCommentLists.propTypes = {
  activity: PropTypes.object.isRequired,
  onUpdateAdminNotes: PropTypes.func.isRequired
};

export const mapDispatchToProps = (dispatch, props) => ({
  onUpdateAdminNotes: (type) => (adminNotes) => {
    dispatch(updatePeriod(props.activity, { [type]: adminNotes }));
  }
});

export default compose(withStyles(styles), connect(null, mapDispatchToProps))(ActivityCommentLists);
