import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Field } from 'redux-form';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import MultiActFormPatient from 'app/act/multiAct/ui/component/MultiActForm/MultiActFormPatient';
import useTranslate from 'application/hooks/use-translate';

export const styles = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}px`
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1)
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing(1)}px 0`
  }
});

export function PatientFields({ label, fields: reduxFormFields, meta: reduxFormMetas, visitCodeOptions, classes }) {
  const translate = useTranslate();
  return (
    <>
      <div className={classes.header}>
        <Typography id="label" variant="body2">
          {label} ({reduxFormFields.length})
        </Typography>
      </div>
      {reduxFormFields.map((fieldName, fieldIndex, fields) => (
        <Field
          name={fieldName}
          key={fieldName}
          component={({ input, meta }) => (
            <>
              <MultiActFormPatient
                value={input.value}
                visitCodeOptions={visitCodeOptions}
                onChange={input.onChange}
                onRemove={() => fields.remove(fieldIndex)}
              />
              {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
            </>
          )}
        />
      ))}
      {reduxFormMetas.error && <FormHelperText error>{reduxFormMetas.error}</FormHelperText>}
      <div className={classes.footer}>
        <Button
          id="add-button"
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => reduxFormFields.push({})}
        >
          <AddIcon className={classes.icon} />
          {translate('global.add')}
        </Button>
      </div>
    </>
  );
}

PatientFields.propTypes = {
  visitCodeOptions: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired
};

export default withStyles(styles)(PatientFields);
