import { createSelector } from '@reduxjs/toolkit';
import { getUser, selectPreferences } from '../../../containers/User/selectors';
import ContextElement from '../domain/ContextElement';
import Category from '../../shared/domain/Category';

const selectSpecialtyContextElements = (state): Category<ContextElement>[] => state.specialtyContextElements;
const selectSpecialtyContextElementsLoaded = (state): Category<ContextElement>[] =>
  state.specialtyContextElementsLoaded;

const selectSpecialtyContextElementsFromPreferences = createSelector(selectPreferences, selectSpecialtyContextElements);

const selectSpecialtyContextElementsLoadedFromPreferences = createSelector(
  selectPreferences,
  selectSpecialtyContextElementsLoaded
);

export const selectSpecialtyContextElementsFromUser = () =>
  createSelector(getUser, selectSpecialtyContextElementsFromPreferences);

export const selectSpecialtyContextElementsLoadedFromUser = () =>
  createSelector(getUser, selectSpecialtyContextElementsLoadedFromPreferences);
