import React from 'react';
import useTranslate from 'application/hooks/use-translate';
import Icon from 'application/components/icon';

const NoDataAvailableState = () => {
  const translate = useTranslate('reports');

  return (
    <div className="flex h-96 flex-col items-center justify-center gap-4 bg-base-200">
      <Icon icon="empty-set" className="text-4xl text-gray-400" />

      <span className="w-64 text-center text-gray-400">{translate('no-data-available')}</span>
    </div>
  );
};

export default NoDataAvailableState;
