import { get, head } from 'lodash';
import { RamqExchange, RamqExchangeLine, RamqExchangeType } from '../../ramq/constants';
import isDefined from '../../utils/isDefined';
import ActAmountComputator from './ActAmountComputator';
import { compareMostRecentRamqExchange } from '../computeExpectedAmountUtil';
import { isRamqExchangeLineSuccess } from '../../ramq/ramqCommonUtils';

export default class DefaultActAmountComputator extends ActAmountComputator {
  compute() {
    const ramqExchange = get(this.activity, 'ramqExchange', [])
      .filter(({ type }: { type: RamqExchangeType }) => type !== RamqExchangeType.PAYMENT_EXPLANATION_RESPONSE)
      .sort(compareMostRecentRamqExchange);

    const latestRamqExchange = head(ramqExchange) as RamqExchange;

    if (isDefined(latestRamqExchange) && latestRamqExchange.outcome !== 'failure') {
      const { lines = [] } = latestRamqExchange;

      return this.sumLineAmounts(lines);
    }

    return '0.00';
  }

  private sumLineAmounts(lines: RamqExchangeLine[]): string {
    return lines
      .filter(isRamqExchangeLineSuccess)
      .reduce((acc, { amount }) => acc + parseFloat(amount), 0)
      .toFixed(2);
  }
}
