import { get } from 'lodash';
import IntensiveCareDiagnosticWithPatientContextField from '../../../components/Form/ActSpecificField/Diagnostics/IntensiveCareDiagnosticWithPatientContextField';

const summaryRender = (code) => [{ name: 'diagnostic', value: get(code, 'diagnostic.code', '-') }];

const associatedFields = [
  {
    label: 'Diagnostic',
    getName: (index) => `codes[${index}].diagnostic`,
    type: IntensiveCareDiagnosticWithPatientContextField
  }
];

const intensiveCareDiagnostic = { associatedFields, summaryRender };

export default intensiveCareDiagnostic;
