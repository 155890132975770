import { call, put, takeLatest } from 'redux-saga/effects';
import { reduxSagaFireBase } from '../../../server/Firebase';
import queryBuilder from '../../../shared/collection/QueryBuilder';
import { ramqCallLogsCollectionRef } from '../../firebase/collection/collectionReferences';
import { GET_RAMQ_CALL_LOG, getRamqCallLogFailure, getRamqCallLogSuccess } from './actions';

export function* getRamqLog({ logId }) {
  try {
    const logQuery = queryBuilder.withBaseQuery(ramqCallLogsCollectionRef()).withId(logId).build();
    const ramqLog = yield call(reduxSagaFireBase.firestore.getCollection, logQuery);
    yield put(getRamqCallLogSuccess(ramqLog.data()));
  } catch (e) {
    yield put(getRamqCallLogFailure(e));
  }
}

export default function* watchRamqCallLog() {
  yield takeLatest(GET_RAMQ_CALL_LOG, getRamqLog);
}
