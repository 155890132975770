import { get } from 'lodash';
import { AM_PERDIEM, PM_PERDIEM } from '../../../../shared/periods/common/constants';
import {
  AssociatedField,
  RequiredDetail,
  RequiredDetailSummary
} from 'app/codeSpecificLogic/requiredDetails/requiredDetail.type';
import { MultiCheckbox } from 'app/components/Form/Fields';

export const AM_PM_LIST = [
  { value: AM_PERDIEM, label: AM_PERDIEM },
  { value: PM_PERDIEM, label: PM_PERDIEM }
];

const summaryRender = (code): RequiredDetailSummary[] => [
  { name: 'perdiem', value: get(code, 'amPm', '-').toString() }
];

const associatedFields: AssociatedField[] = [
  {
    getName: (index) => `codes[${index}].amPm`,
    notRequired: false,
    type: MultiCheckbox,
    list: AM_PM_LIST
  }
];

const amPm: RequiredDetail = { associatedFields, summaryRender };

export default amPm;
