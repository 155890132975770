import { RequiredDetail, RequiredDetails } from '../requiredDetail.type';
import unitNumberMeasurementElement from '../UnitNumberMeasurementElement/unitNumberMeasurementElement';
import endTimeRequired from '../EndTimeRequired/endTimeRequired';
import telemedicineOrRemote from '../TelemedicineOrRemote/telemedicineOrRemote';
import canceledActivities from '../CanceledActivities/canceledActivities';
import particularSituation from '../ParticularSituation/particularSituation';
import { ActCode } from './ActCode';

export default class ActCode42189 implements ActCode {
  getRequiredDetails(): RequiredDetails {
    return [
      unitNumberMeasurementElement,
      endTimeRequired as RequiredDetail,
      telemedicineOrRemote,
      canceledActivities,
      particularSituation
    ];
  }
}
